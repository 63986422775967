import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { DeviceActions } from '@mona/device/data-access-device';
import { RegisterDeviceResult } from '@mona/models';
import { AsyncActionState } from '@mona/store';
import { TelemedicineFeatureState } from '../entities';
import { TelemedicineSetupAction } from '../state';

/**
 * Telemedicine setup service
 */
@Injectable({ providedIn: 'root' })
export class TelemedicineSetupService {
    /**
     * Constructor
     *
     * @param store Store<AppState>
     */
    constructor(private store: Store<TelemedicineFeatureState>) {}

    /**
     * Gets the register device action
     */
    getRegisterDeviceAction(): Observable<AsyncActionState<RegisterDeviceResult>> {
        return this.store.select(state => state.telemedicine.registerDeviceAction);
    }

    /**
     * Gets register device result
     */
    getRegisterDeviceResult(): Observable<RegisterDeviceResult> {
        return this.store.select(state => state.telemedicine.registerDeviceResult);
    }

    /**
     * Gets check is device activated async action
     */
    getCheckIsDeviceActivatedAction(): Observable<AsyncActionState<RegisterDeviceResult>> {
        return this.store.select(state => state.telemedicine.checkIsDeviceActivatedAction);
    }

    /**
     * Checks if the device is registered
     *
     * @param deviceMac string
     */
    async isDeviceRegistered(deviceMac: string): Promise<boolean> {
        this.store.dispatch(TelemedicineSetupAction.checkIsDeviceActivated.clearAction());
        return await new Promise((resolve, reject) => {
            this.getCheckIsDeviceActivatedAction()
                .pipe(
                    filter(action => action.finished),
                    take(1),
                    map(action => action.succeeded),
                )
                .subscribe(isRegistered => resolve(isRegistered));

            this.checkIsDeviceActivated(deviceMac);
        });
    }

    /**
     * Checks if the device is activated
     *
     * @param deviceMac string
     */
    async isDeviceActivated(deviceMac: string): Promise<boolean> {
        return await new Promise((resolve, reject) => {
            this.getCheckIsDeviceActivatedAction()
                .pipe(
                    filter(action => action.finished),
                    take(1),
                    map(action => action.succeeded && action.result.isActivated),
                )
                .subscribe(isActivated => resolve(isActivated));

            this.checkIsDeviceActivated(deviceMac);
        });
    }

    /**
     * Generate telemedicine device keys
     */
    generateTelemedicineDeviceKeys(): void {
        this.store.dispatch(DeviceActions.generateTelemedicineDeviceKeys());
    }

    /**
     * Register a device at backend
     *
     * @param hospitalCode number
     * @param deviceMac string
     */
    registerDevice(hospitalCode: string, deviceMac: string): void {
        this.store.dispatch(TelemedicineSetupAction.registerDevice.action({ hospitalCode, deviceMac }));
    }

    /**
     * Checks device is activated
     *
     * @param deviceMac deviceMac
     */
    checkIsDeviceActivated(deviceMac: string): void {
        this.store.dispatch(TelemedicineSetupAction.checkIsDeviceActivated.action({ deviceMac }));
    }

    /**
     * Clears register device action state
     */
    clearRegisterDeviceAction(): void {
        this.store.dispatch(TelemedicineSetupAction.registerDevice.clearAction());
    }
}
