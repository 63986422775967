import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { getRedirectPath } from '@environment';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { DeviceSetupStatus } from '@mona/models';
import { Logger } from '@mona/shared/logger';
import { PLATFORM, Platform } from '@mona/shared/utils';
import { DeviceFacade } from '../application';

/**
 * Guard to check if Device registered
 *
 * We always redirect to setup if app runs on the device, so this guaranties:
 * - cancels navigation if registered, and redirects to default by license
 * - continues navigation if device is not registered
 */
@Injectable({
    providedIn: 'root',
})
export class DeviceRegisteredGuard {
    private readonly logger = new Logger('DEVICE');
    /**
     * @hideconstructor
     * @param deviceFacade
     * @param config
     * @param router
     * @param platform
     */
    constructor(
        private deviceFacade: DeviceFacade,
        private config: ConfigService,
        private router: Router,
        @Inject(PLATFORM) public platform: Platform,
    ) {}

    /**
     * Method deciding if a route can be activated
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const redirectPath = getRedirectPath(this.config.config);
        const skipStatuses = [DeviceSetupStatus.Pending];
        if (!this.platform.isElectron) {
            this.router.navigateByUrl(redirectPath);
            return false;
        }
        return this.deviceFacade.setupStatus$.pipe(
            filter(status => status && !skipStatuses.includes(status)),
            take(1),
            map(status => {
                if (status === DeviceSetupStatus.RegistrationComplete && state.url.includes('Network')) {
                    // INFO: allow user to access network step from settings
                    return true;
                } else if (status === DeviceSetupStatus.RegistrationComplete) {
                    this.logger.warn('Registration complete, redirect to default feature route');
                    this.router.navigateByUrl(redirectPath);
                    return false;
                }
                this.logger.warn('Registration is missing or imcomplete, proceed to Setup');
                return true;
            }),
        );
    }

    /**
     * Method deciding if a route can be loaded
     *
     * @param route
     */
    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        const redirectPath = getRedirectPath(this.config.config);
        if (!this.platform.isElectron) {
            this.router.navigateByUrl(redirectPath);
            return false;
        }
        return true;
    }
}
