import { NgModule } from '@angular/core';
import { EncounterDateFilterModule } from './encounter-date-filter';
import { EncounterInfoModule } from './encounter-info';
import { EncounterPipesModule } from './encounter-pipes';
import { FrequencyTimesModule } from './frequency-times';
import { MedicationModule } from './medication';
import { MedicationAutocompleteModule } from './medication-autocomplete';
import { PatientPipesModule } from './patient-pipes';
import { ProcedureModule } from './procedure';
import { WorkflowsModule } from './workflows';

const MODULES = [
    FrequencyTimesModule,
    MedicationModule,
    MedicationAutocompleteModule,
    ProcedureModule,
    PatientPipesModule,
    WorkflowsModule,
    EncounterInfoModule,
    EncounterPipesModule,
    EncounterDateFilterModule,
];

/**
 * Pdms shared UI module
 */
@NgModule({
    exports: MODULES,
})
export class PdmsSharedModule {}
