import { ApiModels } from '@mona/api';
import { PrescriptionFrequencyTime } from '@mona/models';

/**
 * Transforms api prescription frequency time
 *
 * @param apiPrescriptionFrequencyTime ApiModels.FrequencyTime
 */
export const transformApiPrescriptionFrequencyTime = (
    apiPrescriptionFrequencyTime: ApiModels.FrequencyTime,
): PrescriptionFrequencyTime => {
    const model = ApiModels.FrequencyTime.DTO.toModel(apiPrescriptionFrequencyTime) as PrescriptionFrequencyTime;
    model.id = (apiPrescriptionFrequencyTime as any).id; // INFO: openapi mistake, there is id
    return model;
};

/**
 * Transform api prescription frequency time
 *
 * @param apiPrescriptionFrequencyTimes ApiModels.FrequencyTime[]
 */
export const transformApiPrescriptionFrequencyTimes = (
    apiPrescriptionFrequencyTimes: ApiModels.FrequencyTime[],
): PrescriptionFrequencyTime[] => {
    return apiPrescriptionFrequencyTimes.map((apiPrescriptionFrequency, index) =>
        transformApiPrescriptionFrequencyTime(apiPrescriptionFrequency),
    );
};
