import { createReducer, on } from '@ngrx/store';
import { PatientOutput } from '@mona/models';
import { SucceededAction } from '@mona/store';
import { OutputsMap } from '../../entities';
import { BalanceActions } from '../actions';

/**
 * The initial state
 */
export const initialOutputsMapState: OutputsMap = null;

/**
 * Groups outputs by code
 *
 * @param outputs PatientOutput[]
 */
const groupOutputs = (outputs: PatientOutput[]): OutputsMap => {
    return outputs.reduce((outputsMap, output) => {
        const codeOutputs = outputsMap[output.code];

        if (codeOutputs) {
            codeOutputs.push(output);
        } else {
            outputsMap[output.code] = [output];
        }

        return outputsMap;
    }, {} as OutputsMap);
};

/**
 * Reducer for outputs succeeded loading
 *
 * @param state VitalSignsMap
 * @param action SucceededAction<VitalSign[]>
 */
export const reduceLoadOutputsSucceeded = (state: OutputsMap, action: SucceededAction<PatientOutput[]>): OutputsMap => {
    // sort outputs
    const sortedOutputs = [...action.payload];
    sortedOutputs.sort((prev, next) => {
        return new Date(prev.date).getTime() - new Date(next.date).getTime();
    });

    // convert to object map by grouping by code
    return groupOutputs(sortedOutputs);
};

/**
 * Reducer for clear action
 */
export const reduceOutputsClear = (): OutputsMap => {
    return initialOutputsMapState;
};

/**
 * Outputs map reducer
 */
export const outputsMapReducer = createReducer(
    initialOutputsMapState,

    on(BalanceActions.loadOutputsAction.succeededAction, reduceLoadOutputsSucceeded),

    on(BalanceActions.loadOutputsAction.clearAction, reduceOutputsClear),
);
