/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostListener,
    Inject,
    InjectionToken,
    OnDestroy,
    OnInit,
    Optional,
    Renderer2,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatListItem } from '@angular/material/list';
import { MatMenuItem } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { MatTab } from '@angular/material/tabs';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { isObservable, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { Logger } from '@mona/shared/logger';
import { isFunction, takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { DrawerNavItemComponent } from '../components/drawer/drawer-body/nav-item';
import { BaseCanDisable } from '../components/forms/mixins';
import { CanDisable } from '../components/forms/models';
import { InfoListItemComponent } from '../components/info-list-item';
import { UiOverlayContainerService } from '../components/overlay-container';

/** @internal */
export type UiDisableWhenToken = Observable<boolean>;
/** @internal */
export type UiDisableWhenCheckPermissionToken = (p: string | string[]) => Promise<boolean>;
/** @internal */
export type UiDisableWhenCheckPermissionTriggerToken = () => Observable<any>;
/** UI disable-when token */
export const UI_DISABLE_WHEN = new InjectionToken<UiDisableWhenToken>('Ui disabled when token', {
    factory: () => of(false), // default - emulate `show`
});
/** UI disable-when has no permission token */
export const UI_DISABLE_WHEN_CHECK_PERMISSION = new InjectionToken<UiDisableWhenCheckPermissionToken>(
    'Ui disabled when has no permission token',
    {
        providedIn: 'root',
        factory: () => () => Promise.resolve(true), // default - emulate `has`
    },
);
/** UI disable-when should trigger check permission */
export const UI_DISABLE_WHEN_CHECK_TRIGGER = new InjectionToken<Observable<boolean>>(
    'UI disable-when should trigger check permission',
    { providedIn: 'root', factory: () => of() },
);

/**
 * UiDisableWhen Directive
 */
@TakeUntilDestroy
@Directive({
    selector: '[uiDisableWhen]',
    inputs: ['disabled: uiDisableWhen', 'checkPermission'],
})
export class UiDisableWhenDirective extends BaseCanDisable implements CanDisable, OnInit, OnDestroy {
    private readonly logger = new Logger('UI');
    checkPermission: string | string[];
    /** HTMLElement */
    get element(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    /**
     * Host listener for mousedown
     *
     * @param event MouseEvent
     */
    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown', ['$event'])
    onMousedown(event: MouseEvent) {
        if (this.disabled) {
            event.cancelable && event.preventDefault();
            const message = this.translateService.instant('warnings.areaRestricted');
            const content = message;
            this.overlayService
                .open({
                    content,
                    origin: this.elementRef.nativeElement,
                    data: undefined,
                    configuration: {
                        panelClass: ['bg-dark', 'p-2'],
                        isResizable: false,
                        isDraggable: false,
                        disposeOnNavigation: true,
                        height: 72,
                        maxWidth: 200,
                    },
                })
                .afterOpened$.subscribe(() => {
                    this.logger.log('custom overlay opened:', message);
                });
        }
    }

    /**
     * Constructor
     *
     * @param disableWhenObs
     * @param checkPermissionFn
     * @param checkPermissionTrigger
     * @param controlContainer
     * @param matButton
     * @param matSelect
     * @param matTab
     * @param matListItem
     * @param matMenuItem
     * @param matRipple
     * @param matTooltip
     * @param drawerNavItem
     * @param uiListItem
     * @param document
     * @param elementRef
     * @param renderer
     * @param overlayService
     * @param translateService
     * @param cdRef
     */
    constructor(
        @Optional() @Inject(UI_DISABLE_WHEN) private disableWhenObs: UiDisableWhenToken,
        @Optional()
        @Inject(UI_DISABLE_WHEN_CHECK_PERMISSION)
        private checkPermissionFn: UiDisableWhenCheckPermissionToken,
        @Inject(UI_DISABLE_WHEN_CHECK_TRIGGER)
        private checkPermissionTrigger: UiDisableWhenCheckPermissionTriggerToken,
        @Optional() private controlContainer: ControlContainer,
        @Optional() private matButton: MatButton,
        @Optional() private matSelect: MatSelect,
        @Optional() private matTab: MatTab,
        @Optional() private matListItem: MatListItem,
        @Optional() private matMenuItem: MatMenuItem,
        @Optional() private matRipple: MatRipple,
        @Optional() private matTooltip: MatTooltip,
        @Optional() private drawerNavItem: DrawerNavItemComponent,
        @Optional() private uiListItem: InfoListItemComponent,
        @Inject(DOCUMENT) private document: Document,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private overlayService: UiOverlayContainerService,
        private translateService: TranslateService,
        public cdRef: ChangeDetectorRef,
    ) {
        super(cdRef);
    }

    /**
     * Check once, then if trigger(s) is emitted
     */
    ngOnInit(): void {
        this.check();
        // 1st check for observable
        if (isObservable(this.disableWhenObs)) {
            this.disableWhenObs.pipe(takeUntilDestroy(this)).subscribe(() => {
                this.check();
            });
        }
        // 2nd check for permission
        if (isFunction(this.checkPermissionTrigger)) {
            this.checkPermissionTrigger()
                .pipe(takeUntilDestroy(this))
                .subscribe(() => {
                    this.check();
                });
        }
    }

    /** @ignore */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnDestroy(): void {}

    /**
     * Run disabled checks
     *
     * Hear is allowed to use async to convert to promise, cause we need to check in order, and only once
     */
    async check() {
        // 1st check for observable
        if (isObservable(this.disableWhenObs)) {
            const disabled = await this.disableWhenObs.pipe(take(1)).toPromise();
            this.disabled = disabled;
        }
        // 2nd check for permission
        if (this.checkPermission && isFunction(this.checkPermissionFn)) {
            const hasPermission = await this.checkPermissionFn(this.checkPermission);
            // do not enable if already disabled by 1st check
            if (!this.disabled && !hasPermission) {
                this.disabled = true;
            }
        }
    }

    /**
     * {@link mixinDisabled}.onDisabledChange
     *
     * @param v
     */
    onDisabledChange(v: boolean): void {
        if (v) {
            this.renderer.setAttribute(this.element, 'aria-disabled', 'true');
            this.renderer.setAttribute(this.element, 'disabled', 'true');
            this.renderer.addClass(this.element, 'disabled');
            this.controlContainer?.control?.root?.disable();
        } else {
            this.renderer.removeAttribute(this.element, 'aria-disabled');
            this.renderer.removeAttribute(this.element, 'disabled');
            this.renderer.removeClass(this.element, 'disabled');
            this.controlContainer?.control?.root?.enable();
        }
        if (this.matButton) {
            this.renderer.removeAttribute(this.element, 'disabled');
            // this.matButton.disabled = v;
        }
        if (this.matMenuItem) {
            this.matMenuItem.disabled = v;
        }
        if (this.matSelect) {
            this.matSelect.disabled = v;
        }
        if (this.matTab) {
            this.matTab.disabled = v;
        }
        if (this.matListItem) {
            this.matListItem.disabled = v;
        }
        if (this.matRipple) {
            this.matRipple.disabled = v;
        }
        if (this.drawerNavItem) {
            this.drawerNavItem.disabled = v;
            this.drawerNavItem.triggerChangeDetection();
        }
        if (this.uiListItem) {
            this.uiListItem.disabled = v;
        }
    }
}
