<form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [isEnabled]="isBedSideMode && isActive"
    (ngSubmit)="keyboardEnterClicked($event)"
>
    <div class="app-valuable-form__row">
        <mat-form-field
            class="app-input-field app-valuable-form__field"
            appearance="fill"
            data-testid="ff-valuableAmountField"
        >
            <mat-label>{{ 'apps.admission.valuable.valuableAmount.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.valuableAmount"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-valuableAmountTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.valuableAmount"></ui-validation-message>
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="app-input-field app-valuable-form__field"
            appearance="fill"
            data-testid="ff-valuableNameField"
        >
            <mat-label>{{ 'apps.admission.valuable.valuableName.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.valuableName"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-valuableNameTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.valuableName"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
