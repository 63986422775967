/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityState } from '@ngrx/entity';
import { BaseProcedurePrescription, Procedure, ProcedurePrescription } from '@mona/models';

export const proceduresStateKey = 'proceduresData';
export const proceduresFeatureKey = 'procedures';
export const procedurePrescriptionsFeatureKey = 'procedurePrescriptions';

/**
 * Procedures Entity State
 */
export interface ProceduresAdministrationState extends EntityState<Procedure> {
    /**
     * Error
     */
    error: any;
}

/**
 * Procedures Entity State
 */
export interface ProceduresPrescriptionState extends EntityState<ProcedurePrescription> {
    /**
     * Error
     */
    error: any;

    /**
     * Is Loading
     */
    isLoading: boolean;
}

/**
 * PrescriptionSetProcedure Entity State
 */
export interface PrescriptionSetProcedureState extends EntityState<BaseProcedurePrescription> {
    /**
     * Error
     */
    error: any;

    /**
     * Is Loading
     */
    isLoading: boolean;
}

/**
 * Procedures Entity State
 *
 * combines `procedures` & `procedurePrescriptions` feature states
 */
export interface ProceduresState {
    /**
     * ProceduresPrescriptionState
     */
    procedurePrescriptions: ProceduresPrescriptionState;
    /**
     * ProceduresAdministrationState
     */
    procedures: ProceduresAdministrationState;
}
