import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AppError, MEDIA_ERRORS, UtilsModule } from '@mona/shared/utils';
// Circular depe here, odemidov pls try to fix
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getRouteConfigForDialogComponent } from '@mona/ui/components';
import { MicButtonComponent } from '../mic-button/mic-button.component';

/**
 * Camera dialog component
 */
@Component({
    standalone: true,
    selector: 'mona-camera-dialog',
    templateUrl: './camera-dialog.component.html',
    styleUrls: ['./camera-dialog.component.scss'],
    imports: [UtilsModule, MatDialogModule, MatButtonModule, MatIconModule, MicButtonComponent],
})
export class CameraDialogComponent implements OnInit, OnDestroy {
    /**
     * Holds a reference to the local video element
     */
    @ViewChild('localVideo') localVideo: ElementRef<HTMLVideoElement>;

    /**
     * Media error
     */
    mediaError: AppError;
    module;
    /**
     * Is video captured
     */
    isVideoCaptured: boolean;

    /**
     * Stream constraints
     */
    private mediaConstraints: MediaStreamConstraints = {
        audio: false,
        video: { width: 1280, height: 720 },
    };

    /**
     * Stream
     */
    private stream: MediaStream;

    /**
     * NG hook
     */
    async ngOnInit(): Promise<void> {
        await this.createStream();
        await this.registerDeviceChangingListener();
    }

    /**
     * NG hook
     */
    ngOnDestroy() {
        if (this.stream) {
            for (const track of this.stream.getTracks()) {
                track.stop();
            }
        }

        navigator.mediaDevices.ondevicechange = null;
    }

    /**
     * Creates stream
     */
    async createStream(): Promise<void> {
        // Create stream using camera for video
        try {
            await this.retryToShowCameraWithWait(5, 1000);
            this.mediaError = null;
        } catch (error) {
            this.mediaError = {
                errorCode: MEDIA_ERRORS.CAN_NOT_GET_MEDIA,
                originalError: error,
            };
            console.warn('Could not get user media', error);
        }
    }

    /**
     * Register change devices event
     */
    async registerDeviceChangingListener(): Promise<void> {
        navigator.mediaDevices.ondevicechange = async () => {
            await this.createStream();
        };
    }

    /**
     * Add retrying logic to camera enabling
     * @param maxRetries
     * @param delayBetweenRetries
     */
    async retryToShowCameraWithWait(maxRetries: number, delayBetweenRetries: number) {
        for (let attemptNumber = 1; attemptNumber <= maxRetries; attemptNumber++) {
            try {
                console.log(`Attempt ${attemptNumber}`);
                await this.showCamera();
                if (!this.stream) {
                    throw 'No device detected';
                }
                return;
            } catch (error) {
                console.error(`Attempt ${attemptNumber} failed:`, error);

                if (attemptNumber < maxRetries) {
                    console.log(`Retrying in ${delayBetweenRetries / 1000} seconds...`);
                    await new Promise(resolve => setTimeout(resolve, delayBetweenRetries));
                } else {
                    console.error('Max retries reached. Operation failed.');
                }
            }
        }
    }

    /**
     * Output camera stream on the screen
     */
    async showCamera(): Promise<void> {
        const stream = await navigator.mediaDevices.getUserMedia(this.mediaConstraints);
        this.stream = stream;
        if (this.stream) {
            this.localVideo.nativeElement.srcObject = this.stream;
            this.isVideoCaptured = true;
        }
    }

    /**
     * Show error details
     */
    showErrorDetails(): void {
        // FIXME: after
        // this.errorDetailsDialogService.openDialog(this.mediaError);
    }
}

export const CAMERA_DIALOG_ROUTES = getRouteConfigForDialogComponent(CameraDialogComponent, {
    config: {
        panelClass: 'mona-camera-dialog',
    },
});
