import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { prescriptionSetsFeatureKey } from './entities';
import { PrescriptionSetEffects, prescriptionSetReducer } from './state';

/**
 * INFO: add comment
 */
@NgModule({
    imports: [
        StoreModule.forFeature(prescriptionSetsFeatureKey, prescriptionSetReducer),
        EffectsModule.forFeature([PrescriptionSetEffects]),
    ],
    providers: [PrescriptionSetEffects],
})
export class DataAccessPrescriptionSetsModule {}
