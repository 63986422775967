/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { HealthState } from '../../entities';

export const changeOnline = createAction('API:ONLINE_STATUS_CHANGED', props<{ isOnline: boolean }>());
export const connectionReestablished = createAction('API:CONNECTION_REESTABLISHED');
export const resetApiHealth = createAction('API:RESET_HEALTH_PER_URL', props<{ serverUrl: string }>());
export const loadApiHealth = createAction('API:LOAD_HEALTH', props<{ serverUrl: string }>());
export const loadApiHealthSuccess = createAction(
    'API:LOAD_HEALTH_SUCCESS',
    props<{ serverUrl: string; healthState: HealthState }>(),
);
export const loadApiHealthFailure = createAction(
    'API:LOAD_HEALTH_FAILURE',
    props<{ serverUrl?: string; error: any }>(),
);
export const checkApiHealthWS = createAction(
    'API:CONNECT_HEALTH_WS',
    props<{ serverUrl: string; attempts?: number }>(),
);
export const loadWsHealthSuccess = createAction(
    'API:LOAD_WS_HEALTH_SUCCESS',
    props<{ serverUrl: string; healthState: HealthState }>(),
);
export const loadWsHealthFailure = createAction(
    'API:LOAD_WS_HEALTH_FAILURE',
    props<{ serverUrl?: string; error: any }>(),
);
export const resetApiHealthWS = createAction('API:RESET_HEALTH_WS');
export const initPeriodicCheck = createAction('API:INIT_PERIODIC_CHECK');
