import { Directive, HostBinding } from '@angular/core';
import { ConfigService } from '@mona/config';

/**
 * INFO: add comment
 */
@Directive({
    selector: '[uiKeyboardSpacing]',
})
export class UiKeyboardSpacingDirective {
    @HostBinding('class.keyboard-spacing') keyboardSpacing: boolean;

    /**
     * Constructor
     * @param configService
     */
    constructor(private configService: ConfigService) {
        this.keyboardSpacing = this.configService.get('isBedSide');
    }
}
