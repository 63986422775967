import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { environment } from '@environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfigLoader, ConfigStaticLoader } from './models';
import { ConfigPipeModule } from './pipes';
import { ConfigService } from './services';
import { ConfigEffects, CONFIG_REDUCER_TOKEN, fromConfig } from './state';

/**
 * Config initializer factory
 *
 * @param config
 */
export function initializerFactory(config: ConfigService): () => Observable<unknown> | Promise<unknown> | void {
    return () => {
        return config.init();
    };
}
/** Default config factory */
export const configFactory = () => new ConfigStaticLoader(environment);

/** Providers */
const PROVIDERS: Provider[] = [
    {
        provide: APP_INITIALIZER,
        useFactory: initializerFactory,
        deps: [ConfigService],
        multi: true,
    },
    ConfigService,
    {
        provide: CONFIG_REDUCER_TOKEN,
        useFactory: () => fromConfig.reducer,
    },
];

/**
 *
 * Setting up ConfigModule to use ConfigMergeLoader
 *
 * ConfigMergeLoader requires one or more loaders of type ConfigLoader to load application
 * settings by executing specified loaders in parallel and in series.
 *
 * Import ConfigModule using the mapping '@mona/config' and append ConfigModule.forRoot(  {...}) within the imports property of app.module.
 * Import ConfigMergeLoader using the mapping '@mona/config'.
 * Note: Considering the app.module is the core module in Angular application.
 *
 * @example ```ts
    export function configFactory(http: HttpClient): ConfigLoader { const remoteConfigLoader = new ConfigHttpLoader(http, 'http://mysite.com/api/settings'); // API ENDPOINT (remote)
    const localConfigLoader = new ConfigHttpLoader(http, './config.local.json'); // API
    ENDPOINT (local)
    return new ConfigMergeLoader([remoteConfigLoader, localConfigLoader]); // PARALLEL EXECUTION
    }
 
        export function configFactorySeries(http: HttpClient): ConfigLoader { const localConfigLoader
        = new ConfigHttpLoader(http, './config.local.json'); // API ENDPOINT (local)
 
        return new ConfigMergeLoader([localConfigLoader]) .next((res: any) => new
            ConfigHttpLoader(http, res['apiEndpoint'] + 'api/settings')); // SERIES EXECUTION
        }
    ```
 */
@NgModule({
    imports: [
        HttpClientModule,
        StoreModule.forFeature(fromConfig?.configFeatureKey || 'config', CONFIG_REDUCER_TOKEN),
        EffectsModule.forFeature([ConfigEffects]),
        ConfigPipeModule,
    ],
})
export class ConfigModule {
    /**
     * forRoot
     *
     * @param configuredProvider
     */
    static forRoot(
        configuredProvider: any = {
            provide: ConfigLoader,
            useFactory: configFactory,
        },
    ): ModuleWithProviders<ConfigModule> {
        return {
            ngModule: ConfigModule,
            providers: [configuredProvider, ...PROVIDERS],
        };
    }

    /**
     * Constructor
     *
     * @param parentModule
     * @param storemodule
     */
    constructor(@Optional() @SkipSelf() parentModule: ConfigModule, @Optional() @SkipSelf() storemodule: StoreModule) {
        if (parentModule) {
            throw new Error(
                `ConfigModule.forRoot() called twice. Lazy loaded modules should use ConfigModule.forChild() instead.`,
            );
        }
        if (!storemodule) {
            // throw new Error('Storemodule should be loaded before');
        }
    }
}
