import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { practitionersFeatureKey } from './entities';
import { PractitionersEffects, practitionersReducer, PRACTITIONERS_REDUCER_TOKEN } from './state';

/**
 * DataAccess Practitioners Module
 */
@NgModule({
    imports: [
        //
        CommonModule,
        StoreModule.forFeature(practitionersFeatureKey, PRACTITIONERS_REDUCER_TOKEN),
        EffectsModule.forFeature([PractitionersEffects]),
    ],
    providers: [
        {
            provide: PRACTITIONERS_REDUCER_TOKEN,
            useFactory: () => practitionersReducer,
        },
    ],
})
export class DataAccessPractitionersModule {
    /** @ignore */
    constructor(@Optional() @SkipSelf() parentModule: DataAccessPractitionersModule) {
        if (parentModule) {
            const msg = `DataAccessPractitionersModule has already been loaded.
                Import DataAccessPractitionersModule once, only, in the root AppModule.`;
            throw new Error(msg);
        }
    }
}
