import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomApiModels, WsService } from '@mona/api';
import { TokenStorageService } from '@mona/auth';
import { ConfigService } from '@mona/config';
import { DataUpdateMessage, DataUpdateMessageModel } from '@mona/models';
import { transformApiDataUpdateMessage } from './transforms/data-update.transforms';

/**
 * PDMS updates ws service - stream listens for Encounter, Ward, Bed update messages
 */
@Injectable({ providedIn: 'root' })
export class PdmsUpdatesWsService extends WsService<
    CustomApiModels.DataUpdateMessage,
    DataUpdateMessage<DataUpdateMessageModel>
> {
    /**
     * Constructor
     *
     * @param configService ConfigService
     * @param tokenStorage
     */
    constructor(private configService: ConfigService, private tokenStorage: TokenStorageService) {
        super();
    }

    /**
     * Connects to ws for encounter updates
     */
    connect(): Observable<DataUpdateMessage<DataUpdateMessageModel>> {
        return of(this.tokenStorage.getAccessToken()).pipe(
            switchMap(accessToken => {
                const url = `${this.configService.get('api.coreWsUrl')}/ws/pdms/general/`;
                this.setConfig(url, accessToken);
                return super.connect();
            }),
            map(message => transformApiDataUpdateMessage(message as CustomApiModels.DataUpdateMessage)),
            catchError(e => EMPTY),
        );
    }
}
