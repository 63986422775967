import { Directive, HostBinding, Input } from '@angular/core';
import { isEmpty, isEmptyObject } from '@mona/shared/utils';
import { UiTableCell, UiTableCellValue, UiTableColumn, UiTableRow } from '../models';

/**
 * Ui table cell host directive which is used to provide cell context for any table cell component
 *
 * @example```html
 * <ng-template uiTableCellHost let-cellContext="$implcit" let-column="column" let-row="row" let-rowIdx="rowIdx" let-colIdx="colIdx">
 *    <some-cell-host [cellContext]="cellContext"></some-cell-host>
 * </ng-template>
 * ```
 */
@Directive({
    selector: '[uiTableCellHost]',
})
export class UiTableCellHost<T extends UiTableRow, C extends UiTableCellValue, D extends AnyObject>
    implements UiTableCell
{
    /** UiTableRow instance */
    row!: T;
    /** UiTableRow Idx */
    @HostBinding('attr.data-rowIdx')
    rowIdx!: number;
    /** UiTable Column instance */
    column!: UiTableColumn;
    /** UiTable cell width */
    @HostBinding('style.width.px')
    width!: number;
    /** UiTable Column Idx */
    @HostBinding('attr.data-colIdx')
    colIdx!: number;
    /** UiTable Cell Value */
    cell?: C | undefined;
    /** Cell context */
    @Input() set cellContext(context: UiTableCell) {
        if (!isEmpty(context)) {
            this.row = context.row as T;
            this.rowIdx = context.rowIdx;
            this.column = context.column;
            this.colIdx = context.colIdx;
            this.cell = context.cell as C;
            this.width = context.width || context.column?.width;
        }
    }

    /** additional table data  */
    protected _tableData: D = {} as D;
    /** additional table data  */
    @Input() set tableData(value: D) {
        if (!isEmptyObject(value)) {
            this._tableData = value;
        }
    }
    /**
     * Table data
     */
    get tableData(): D {
        return this._tableData;
    }

    /** set class(map) for cell template */
    @HostBinding('class') get classes(): string {
        return 'mat-column-value';
    }

    /**
     * Get classes string from class map object
     *
     * @param classMap
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    protected getClassesString(classMap: object) {
        const classArray = Object.keys(classMap).reduce((arr, key) => {
            if (classMap[key]) {
                arr.push(key);
            }
            return arr;
        }, []);

        return classArray.join(' ');
    }
}
