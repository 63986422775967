import { ApiModels } from '@mona/api';
import { VitalSign } from '@mona/models';

/**
 * Transforms api vital sign
 *
 * @param apiVitalSign apiVitalSign
 */
export const transformApiVitalSign = (apiVitalSign: ApiModels.VitalSign): VitalSign => {
    return ApiModels.VitalSign.DTO.toModel(apiVitalSign);
};

/**
 * Transform api vital signs
 *
 * @param apiVitalSigns apiVitalSigns
 */
export const transformApiVitalSigns = (apiVitalSigns: ApiModels.VitalSign[]): VitalSign[] => {
    return apiVitalSigns.map(apiVitalSign => transformApiVitalSign(apiVitalSign));
};
