import { MonoTypeOperatorFunction, Observable, OperatorFunction, ReplaySubject } from 'rxjs';
import { debounce, map, materialize, scan, startWith } from 'rxjs/operators';
import { isEmpty } from '../types';

/* eslint-disable jsdoc/require-jsdoc */
/**
 * Suspense interface
 */
export interface Suspense<T> {
    result: undefined | T;
    error: undefined | any;
    finished: boolean;
    inProgress: boolean;
    succeeded: boolean;
}
/* eslint-enable jsdoc/require-jsdoc */

/** Suspense initial state */
export const SUSPENSE_INITIAL_STATE: Suspense<any> = {
    result: undefined,
    error: undefined,
    finished: false,
    inProgress: true,
    succeeded: undefined,
};

/**
 * @description creates a derivated state from the source observable.
 * @example source$.pipe(suspensify())
 * @returns Observable<Suspense<T>>
 */
export function suspensify<T, R = Suspense<T>>(): OperatorFunction<T, R>;
/* eslint-disable-next-line jsdoc/require-jsdoc */
export function suspensify<T, R>(projector: (data: Suspense<T>) => R): OperatorFunction<T, R>;
/* eslint-disable-next-line jsdoc/require-jsdoc */
export function suspensify<T, R = Suspense<T>>(
    projector?: (data: Suspense<T>) => R,
): OperatorFunction<T, R | Suspense<T>> {
    return (source$: Observable<T>): Observable<R | Suspense<T>> => {
        return source$.pipe(_suspensify(projector), _coalesceFirstEmittedValue());
    };
}
/* eslint-disable-next-line jsdoc/require-jsdoc */
function _coalesceFirstEmittedValue<T>(): MonoTypeOperatorFunction<T> {
    return (source$: Observable<T>): Observable<T> => {
        return new Observable<T>(observer => {
            const isReadySubject = new ReplaySubject<unknown>(1);

            const subscription = source$
                .pipe(
                    /* Wait for all synchronous processing to be done. */
                    debounce(() => isReadySubject),
                )
                .subscribe(observer);

            /* Sync emitted values have been processed now.
             * Mark source as ready and emit last computed state. */
            isReadySubject.next(undefined);

            return () => subscription.unsubscribe();
        });
    };
}
/* eslint-disable-next-line jsdoc/require-jsdoc */
function _suspensify<T, R = Suspense<T>>(): OperatorFunction<T, R>;
function _suspensify<T, R>(projector: (data: Suspense<T>) => R): OperatorFunction<T, R>;
function _suspensify<T, R = Suspense<T>>(projector?: (data: Suspense<T>) => R): OperatorFunction<T, R | Suspense<T>> {
    return (source$: Observable<T>): Observable<R | Suspense<T>> => {
        const result$ = source$.pipe(
            materialize(),
            scan((state = SUSPENSE_INITIAL_STATE, notification) => {
                /* On complete, merge `finished: true & inProgress: false`
                 * with the current state. */
                if (notification.kind === 'C') {
                    return {
                        ...state,
                        finished: true,
                        inProgress: false,
                        succeeded: true,
                    };
                }

                /* Mark as finished on error as complete is only triggered on success. */
                const finished = notification.kind === 'E';

                return {
                    result: notification.value,
                    error: notification.error,
                    finished,
                    inProgress: false,
                    succeeded: !isEmpty(notification.value),
                };
            }, undefined),
            startWith(SUSPENSE_INITIAL_STATE),
        );

        return projector != null ? result$.pipe(map(projector)) : result$;
    };
}
