import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { FrequencyTimesModule } from '../frequency-times';
import { MedicationAutocompleteModule } from '../medication-autocomplete';
import { NotGivenReasonComponent } from '../not-given-reason';
import {
    BloodControlsComponent,
    BaseMedicationFormComponent,
    MedicationItemComponent,
    MedicationListComponent,
    MedicationListAsTableComponent,
} from './components';

const COMPONENTS = [
    BloodControlsComponent,
    BaseMedicationFormComponent,
    MedicationItemComponent,
    MedicationListComponent,
    MedicationListAsTableComponent,
];
/**
 * MedicationModule
 *
 * @description contains all neccessary components to search, add, edit, display (standard) medications
 */
@NgModule({
    imports: [
        /* prettier-ignore */
        UiModule,
        KeyboardModule,
        FrequencyTimesModule,
        MedicationAutocompleteModule,
        NotGivenReasonComponent,
    ],
    declarations: COMPONENTS,
    exports: [...COMPONENTS, MedicationAutocompleteModule, NotGivenReasonComponent],
})
export class MedicationModule {}
