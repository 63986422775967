import { coerceNumberProperty } from '@angular/cdk/coercion';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule, _CoalescedStyleScheduler, _COALESCED_STYLE_SCHEDULER } from '@angular/cdk/table';
import { CdkPopoverEditModule, EditEventDispatcher } from '@angular/cdk-experimental/popover-edit';
import { NgModule, Provider } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '@environment';
import { ConfigService } from '@mona/config';
import { PLATFORM, Platform, UtilsModule } from '@mona/shared/utils';
import { UiDirectivesModule } from '../../directives';
import { UiEmptyStateModule } from '../empty-state';
import { UiFormsModule } from '../forms';
import {
    UiTableComponent,
    UiTableFilterComponent,
    UiVHSPagerComponent,
    UiVHSTableComponent,
    VHSVirtualScrollViewport,
} from './components';
import {
    UiTableRowDef,
    UiTableCellContext,
    UiTableCellDef,
    UiTableCellHost,
    UiTableCellRef,
    UiVHSEditable,
    UiVHSEditEventDispatcher,
    UiVHSEditServices,
    UiVHSMatMenuTrigger,
    UiVHSPopoverEdit,
    VHSTableDirective,
    VhsTableEditLens,
    VhsTableOverrideDirective,
    VhsWrapperScrollable,
    UiTableCellSvg,
    UiTableRowR,
    VHS_VIEWPORT_DIMENSIONS_GETTER,
    viewportDimensionsFactory,
} from './directives';
import { UiCellGroupValuePipe, UiTableFilterOptionsPipe } from './pipes';
import {
    getTableScrollSTrategyTypeFromString,
    TableScrollStrategyEnum,
    TABLE_SCROLL_PAGE_SIZE,
    TABLE_SCROLL_STRATEGY_TYPE,
} from './strategies';

const DIRECTIVES = [
    UiTableCellDef,
    UiTableCellRef,
    UiTableCellContext,
    UiTableCellHost,
    UiTableCellSvg,
    VhsWrapperScrollable,
    VHSTableDirective,
    VhsTableOverrideDirective,
    VhsTableEditLens,
    UiVHSEditable,
    UiVHSPopoverEdit,
    UiVHSMatMenuTrigger,
    UiTableRowDef,
];
const COMPONENTS = [
    /* prettier-ignore */
    VHSVirtualScrollViewport,
    UiTableComponent,
    UiTableFilterComponent,
    UiVHSPagerComponent,
    UiVHSTableComponent,
    UiTableRowR,
];

const PIPES = [UiTableFilterOptionsPipe, UiCellGroupValuePipe];

/**
 * Scroll strategy provider factory
 *
 * @param platform
 */
export function scrollStrategyFactory(platform: Platform): TableScrollStrategyEnum {
    const strategyType = platform.isElectron
        ? environment.tableScrollStrategyType
        : (localStorage['tableScrollStrategyType'] ??= environment.tableScrollStrategyType);
    return getTableScrollSTrategyTypeFromString(strategyType);
}

/**
 * Table scroll page size factory
 *
 * @param platform
 * @param configService
 */
export function tableScrollPageSizeFactory(platform: Platform, configService: ConfigService): number {
    const tableScrollPageSize =
        (platform.isElectron
            ? configService.get('tableScrollPageSize')
            : (localStorage['tableScrollPageSize'] ??= configService.get('tableScrollPageSize'))) || 5000;
    return coerceNumberProperty(tableScrollPageSize);
}

const PROVIDERS: Provider[] = [
    { provide: TABLE_SCROLL_STRATEGY_TYPE, useFactory: scrollStrategyFactory, deps: [PLATFORM] },
    { provide: TABLE_SCROLL_PAGE_SIZE, useFactory: tableScrollPageSizeFactory, deps: [PLATFORM, ConfigService] },
    { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
    { provide: EditEventDispatcher, useClass: UiVHSEditEventDispatcher },
    UiVHSEditServices,
    { provide: VHS_VIEWPORT_DIMENSIONS_GETTER, useFactory: viewportDimensionsFactory },
];

/**
 * Ui Table Module
 */
@NgModule({
    imports: [
        UtilsModule,
        UiDirectivesModule,
        CdkTableModule,
        CdkPopoverEditModule,
        MatCommonModule,
        MatBadgeModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatRippleModule,
        MatSortModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTableModule,
        MatTooltipModule,
        MatMenuModule,
        MatPaginatorModule,
        PortalModule,
        ScrollingModule,
        UiEmptyStateModule,
        UiFormsModule,
    ],
    declarations: [...DIRECTIVES, ...COMPONENTS, ...PIPES],
    exports: [...DIRECTIVES, ...COMPONENTS, ...PIPES, MatTableModule, MatSortModule, MatPaginatorModule],
    providers: PROVIDERS,
})
export class UiTableModule {}
