import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isWithinInterval, set } from 'date-fns';
import { from, of, interval } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { IpcMainEvent } from '@mona/events';
import { MonaRpcService } from '@mona/rpc';
import { Theme } from '@mona/ui';
import { UiActions } from '../actions';
import { UiSelectors } from '../selectors';
import { UiState } from '../state';

/** Configuration of the night shift start and end times */
const SHIFT_CONFIG = {
    summertime: {
        nightStart: { hour: 22, minute: 0 },
        dayStart: { hour: 6, minute: 0 },
    },
    wintertime: {
        nightStart: { hour: 18, minute: 0 },
        dayStart: { hour: 8, minute: 0 },
    },
};

/**
 * Effect class for UI effects
 */
@Injectable()
export class UiEffects implements OnInitEffects {
    /** Renderer2 instance */
    private renderer: Renderer2;
    /** Current theme */
    currentTheme$ = this.store$.select(UiSelectors.selectTheme);
    /** Set theme effect */
    setTheme$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UiActions.setTheme),
                tap(({ theme }) => this.applyTheme(theme)),
                switchMap(({ theme }) =>
                    from(this.rpcService.invoke<string>(IpcMainEvent.SET_OSK_THEME, theme)).pipe(
                        catchError(err => {
                            console.error('SET_OSK_THEME', err);
                            return of<string>(null);
                        }),
                    ),
                ),
            ),
        { dispatch: false },
    );
    /** Toggle theme effect */
    toggleTheme$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.toggleTheme),
            withLatestFrom(this.currentTheme$),
            map(([, current]) => UiActions.setTheme({ theme: current === Theme.light ? Theme.dark : Theme.light })),
        ),
    );

    /**
     * Creates an instance of UiEffects.
     *
     * @param {Store<UiState>} store$
     * @param {Actions} actions$
     * @param {Document} document
     * @param {Window} window
     * @param {RendererFactory2} rendererFactory
     * @param {MonaRpcService} rpcService
     */
    constructor(
        private store$: Store<UiState>,
        private actions$: Actions,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private rendererFactory: RendererFactory2,
        private rpcService: MonaRpcService,
    ) {
        this.renderer = this.rendererFactory.createRenderer(this.document.defaultView, null);
    }

    /** Dispatches a custom action after the effect has been added. You can listen to this action in the rest of the application to execute something after the effect is registered.*/
    ngrxOnInitEffects() {
        const hourInMilliseconds = 60 * 60 * 1000;
        let theme = this.isNightShift() ? Theme.dark : this.window.sessionStorage['theme'] || Theme.light;

        interval(hourInMilliseconds).subscribe(() => {
            theme = this.isNightShift() ? Theme.dark : Theme.light;
            this.store$.dispatch(UiActions.setTheme({ theme }));
        });

        return UiActions.setTheme({ theme });
    }

    /**
     * Applies the app theme class to the body
     *
     * @param theme App Theme
     */
    private applyTheme(theme: Theme) {
        const other = theme === Theme.light ? Theme.dark : Theme.light;
        this.renderer.removeClass(this.document.body, `theme-mona-${other}`);
        this.renderer.addClass(this.document.body, `theme-mona-${theme}`);
        this.window.sessionStorage['theme'] = theme;
    }

    /**
     * Get if is night shift
     */
    private isNightShift(): boolean {
        function isDstInUse(d: Date): boolean {
            const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
            const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
            return Math.max(jan, jul) !== d.getTimezoneOffset();
        }

        const now = new Date();
        const { dayStart, nightStart } = isDstInUse(now) ? SHIFT_CONFIG.summertime : SHIFT_CONFIG.wintertime;
        const dayInterval = {
            start: set(new Date(), { hours: dayStart.hour, minutes: dayStart.minute }),
            end: set(new Date(), { hours: nightStart.hour, minutes: nightStart.minute }),
        };
        return !isWithinInterval(now, dayInterval);
    }
}
