import { MediaData } from '@mona/models';

/**
 * Provides interface to services which implements work with {@link MediaStream} & {@link MediaDevices}
 *
 * @export
 * @abstract
 * @class MediaDataService
 */
export abstract class MediaDataService {
    /**
     * Stream
     */
    abstract readonly stream: MediaStream;
    /**
     * Replace audio video track
     */
    abstract replaceAudioVideoTrack: (data: MediaDeviceInfo) => void;

    /**
     * Update audio output
     */
    abstract updateAudioOutput: (data: MediaDeviceInfo) => void;
    /**
     * Get media data
     *
     * @param blink
     */
    getMediaData: (blink: boolean) => MediaData;
}

/** Contains service to comunicate with MediaDevices */
export type MediaDataServiceContainer = {
    service: MediaDataService;
};
