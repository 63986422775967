import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TableDataItem, ChangeLogEntry, ChangeLogModel } from '@mona/models';
import { IsItemChangedPipe } from './is-item-changed.pipe';

/**
 * Detects if cell has staged changes
 */
@Pipe({
    name: 'isCellChanged',
})
@Injectable({
    providedIn: 'root',
})
export class IsCellChangedPipe implements PipeTransform {
    /**
     * Constructor
     *
     * @param isItemChangedPipe IsItemChangedPipe
     */
    constructor(private isItemChangedPipe: IsItemChangedPipe) {}

    /**
     * Transform implementation
     *
     * @param cell TableDataItem
     * @param columnTime string
     * @param changes ChangeLogEntry<ChangeLogModel>[]
     */
    transform(cell: TableDataItem<any>, columnTime: string, changes: ChangeLogEntry<ChangeLogModel>[]): boolean {
        const isItemHasChanges =
            cell.values &&
            cell.values[columnTime] &&
            this.isItemChangedPipe.transform(cell.values[columnTime].id as string, changes);
        const isChildrenHasChanges = cell.children?.find(
            child =>
                child.values[columnTime] &&
                this.isItemChangedPipe.transform(child.values[columnTime].id as string, changes),
        );

        return isItemHasChanges || !!isChildrenHasChanges;
    }
}
