/* eslint-disable jsdoc/require-jsdoc, @typescript-eslint/no-explicit-any */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import {
    BaseMedication,
    BaseProcedurePrescription,
    ChangeLogEntry,
    MedicationPrescription,
    PrescriptionSet,
    ProcedurePrescription,
} from '@mona/models';

export const loadPrescriptionSets = createAction('[PrescriptionSet/API] Load PrescriptionSets');
export const loadPrescriptionSetsSuccess = createAction(
    '[PrescriptionSet/API] Load PrescriptionSets Success',
    props<{ prescriptionSets: PrescriptionSet[] }>(),
);
export const loadPrescriptionSetsFailure = createAction(
    '[PrescriptionSet/API] Load PrescriptionSets Failure',
    props<{ error: any }>(),
);
export const loadPrescriptionSetById = createAction(
    '[PrescriptionSet/API] Load PrescriptionSet By ID',
    props<{ id: string }>(),
);
export const loadPrescriptionSetByIdSuccess = createAction(
    '[PrescriptionSet/API] Load PrescriptionSet By ID Success',
    props<{ prescriptionSet: PrescriptionSet }>(),
);
export const loadPrescriptionSetByIdFailure = createAction(
    '[PrescriptionSet/API] Load PrescriptionSet By ID Failure',
    props<{ error: any }>(),
);
export const addPrescriptionSet = createAction('[PrescriptionSet/API] Add PrescriptionSet', props<{ name: string }>());

export const addPrescriptionSetSuccess = createAction(
    '[PrescriptionSet/API] Add PrescriptionSet Success',
    props<{ prescriptionSet: PrescriptionSet }>(),
);
export const addPrescriptionSetFailure = createAction(
    '[PrescriptionSet/API] Add PrescriptionSet Failure',
    props<{ error: any }>(),
);
export const updatePrescriptionSet = createAction(
    '[PrescriptionSet/API] Update PrescriptionSet',
    props<{ update: Update<PrescriptionSet> }>(),
);
export const updatePrescriptionSetSuccess = createAction(
    '[PrescriptionSet/API] Update PrescriptionSet Success',
    props<{ update: Update<PrescriptionSet> }>(),
);
export const updatePrescriptionSetFailure = createAction(
    '[PrescriptionSet/API] Update PrescriptionSet Failure',
    props<{ error: any }>(),
);
export const deletePrescriptionSet = createAction(
    '[PrescriptionSet/API] Delete PrescriptionSet',
    props<{ id: string }>(),
);
export const deletePrescriptionSetSuccess = createAction(
    '[PrescriptionSet/API] Delete PrescriptionSet Success',
    props<{ id: string }>(),
);
export const deletePrescriptionSetFailure = createAction(
    '[PrescriptionSet/API] Delete PrescriptionSet Failure',
    props<{ error: any }>(),
);
export const clearPrescriptionSets = createAction('[PrescriptionSet/API] Clear PrescriptionSets');
export const createPrescriptionsBulk = createAction(
    '[PrescriptionSet/API] Create Prescriptions Bulk',
    props<{ prescriptionSet: PrescriptionSet }>(),
);
export const createPrescriptionsBulkSuccess = createAction(
    '[PrescriptionSet/API] Create Prescriptions Bulk Success',
    props<{ result: ChangeLogEntry<MedicationPrescription | ProcedurePrescription>[] }>(),
);
export const createPrescriptionsBulkFailure = createAction(
    '[PrescriptionSet/API] Create Prescriptions Bulk Failure',
    props<{ error: any }>(),
);

export const upsertPrescriptionSetMedication = createAction(
    '[PrescriptionSet/API] Upsert PrescriptionSet Medication',
    props<{ setId: string; standardMedication: BaseMedication }>(),
);

export const deletePrescriptionSetMedication = createAction(
    '[PrescriptionSet/API] Delete PrescriptionSet Medication',
    props<{ setId: string; standardMedicationSetId: string }>(),
);

export const deletePrescriptionSetProcedure = createAction(
    '[PrescriptionSet/API] Delete PrescriptionSet Procedure',
    props<{ setId: string; procedureId: string }>(),
);

export const upsertPrescriptionSetProcedure = createAction(
    '[PrescriptionSet/API] Upsert PrescriptionSet Procedure',
    props<{ setId: string; procedure: BaseProcedurePrescription }>(),
);

export const upsertActivePrescriptionSetAsCopy = createAction(
    '[PrescriptionSet/API] Upsert Active Prescription Set Medication',
    props<{ prescriptionSet: PrescriptionSet }>(),
);

export const clearActivePrescriptionSet = createAction('[PrescriptionSet/API] Clear Active Prescription Set');

export const loadAndSetActivePrescriptionSet = createAction(
    '[PrescriptionSet/API] Load And Set Active Prescription Set',
    props<{ id: string }>(),
);
