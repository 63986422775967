import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Diagnosis, VerificationStatus } from '@mona/models';
import { DiagnosesItemForm } from '../../models';

/**
 * Diagnosis dialog
 */
@Component({
    selector: 'app-diagnosis-dialog',
    templateUrl: './diagnosis-dialog.component.html',
    styleUrls: ['./diagnosis-dialog.component.scss'],
})
export class DiagnosisDialogComponent {
    /**
     * Diagnoses form
     */
    diagnosisForm = new FormGroup<DiagnosesItemForm>({
        text: new FormControl<string>('', Validators.required),
        status: new FormControl<VerificationStatus>(VerificationStatus.CONFIRMED, Validators.required),
        icd10Code: new FormControl<string>(null),
    });

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<AnamnesisDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<DiagnosisDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            diagnosis: Diagnosis;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-diagnosis-dialog');

        if (data.diagnosis.id) {
            this.diagnosisForm.patchValue({
                text: data.diagnosis.description,
                status: data.diagnosis.verificationStatus,
                icd10Code: data.diagnosis.icd10Code || null,
            });
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.diagnosisForm.markAsTouched();

        if (this.diagnosisForm.valid) {
            this.dialogRef.close({
                id: this.data.diagnosis.id,
                description: this.diagnosisForm.controls.text.value,
                verificationStatus: this.diagnosisForm.controls.status.value,
                icd10Code: this.diagnosisForm.controls.icd10Code.value,
                role: this.data.diagnosis.role,
            } as Diagnosis);
        }
    }
}
