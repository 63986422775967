import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Encounter, LabValue, PageableModel, ParamsModel } from '@mona/models';
import { transformApiLabValues } from './transforms';

const baseApi = `/pdms`;

/**
 * API abstraction layer for the lab values API
 */
@Injectable({
    providedIn: 'root',
})
export class LabValuesApi {
    private apiV1 = `${baseApi}/lab-values/`;
    private apiV2 = `${baseApi}/v2/lab-values/`;

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get lab values
     *
     * @param encounterId EntityId<Encounter>
     */
    getLabValues(encounterId: EntityId<Encounter>): Observable<LabValue[]> {
        return this.http
            .get<ApiModels.LabValue[]>(this.apiV1, {
                params: {
                    encounter_id: encounterId as string,
                },
            })
            .pipe(map(apiLabValues => transformApiLabValues(apiLabValues)));
    }

    /**
     * Get Pageable Lab Values
     *
     * @param params
     * @param url
     */
    getPageableLabValues(params?: ParamsModel, url: string = this.apiV2): Observable<PageableModel<LabValue>> {
        return this.http.get<PageableModel<ApiModels.LabValue>>(url, { params }).pipe(
            map(data => {
                return {
                    ...data,
                    results: transformApiLabValues(data.results),
                };
            }),
        );
    }
}
