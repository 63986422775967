import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PrescriptionTypeEnum } from '@mona/models';

/** AddPrescriptionBarComponent */
@Component({
    selector: 'app-add-prescription-bar',
    templateUrl: './add-prescription-bar.component.html',
    styleUrls: ['./add-prescription-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPrescriptionBarComponent {
    /** Has changes */
    @Input() entityType = PrescriptionTypeEnum.Medication;
    /** Has changes */
    @Input() hasChanges = false;
    /** Emit add event */
    @Output() emitAdd = new EventEmitter();
    /** Emit confirm event */
    @Output() emitConfirm = new EventEmitter();

    /**
     * Get text by entity type
     *
     * @param key
     */
    getTextByEntityType(key: string): string {
        return `apps.patient.prescriptions.${this.entityType}Table.${key}`;
    }

    /**
     * confirmMedications
     *
     * @param event
     */
    onConfirm(event: Event | undefined) {
        event?.preventDefault();
        this.emitConfirm.emit();
    }
}
