<mat-dialog-content class="mona-history-entry-dialog__content">
    <div>
        <h2 mat-dialog-title class="mona-history-entry-dialog__title">
            {{ typeTranslationMap[data.type] | translate }}
        </h2>
    </div>

    <form [formGroup]="entryForm">
        <mat-form-field class="app-input-field mona-history-entry-dialog__field" appearance="fill">
            <textarea
                [formControl]="entryForm.controls.text"
                required
                matInput
                cdkAutosizeMaxRows="14"
                cdkAutosizeMinRows="2"
                #textarea
                cdkTextareaAutosize
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="entryForm.controls.text"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </form>

    <div class="mona-history-entry-dialog__actions">
        <button mat-button class="mona-history-entry-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'apps.patient.overview.dialogCancel' | translate }}
        </button>
        <button color="primary" mat-raised-button (click)="submitChange()">
            {{ 'apps.patient.overview.dialogSave' | translate }}
        </button>
    </div>
</mat-dialog-content>
