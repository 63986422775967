import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Optional,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { AbstractControl, ControlContainer, FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ScrollToContainer } from '@mona/keyboard';
import { Logger } from '@mona/shared/logger';
import {
    compactObject,
    generateUUID,
    getFormValidationErrors,
    hasFormArray,
    isAbstractControl,
    isEmpty,
    isFormGroupDirective,
    patchFormGroupValue,
} from '@mona/shared/utils';
import { UiDynamicFormsErrorTemplateDirective } from '../../components';
import { BaseCanDisable } from '../../mixins';
import { CanDisable, CUSTOM_SUBMIT_EVENT_NAME, UiDynamicElementConfig } from '../../models';
import { UiDynamicFormsService } from '../../services';

/**
 * UiDynamicFormsComponent
 */
@Component({
    selector: 'ui-form',
    templateUrl: './dynamic-forms.component.html',
    styleUrls: ['./dynamic-forms.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['disabled'],
})
export class UiDynamicFormsComponent extends BaseCanDisable implements CanDisable, AfterContentInit, OnDestroy {
    private logger = new Logger('UI:FORM');
    private _dynamicForm: UntypedFormGroup;
    private _renderedElements: UiDynamicElementConfig[] = [];
    private _elements: UiDynamicElementConfig[];
    private _patched = false;
    private _submitted = false;
    private templateMap: Map<string, TemplateRef<any>> = new Map<string, TemplateRef<any>>();
    private destroy$: Subject<any> = new Subject();
    private destroyControl$: Subject<string> = new Subject();

    @ContentChildren(UiDynamicFormsErrorTemplateDirective, { descendants: true })
    errorTemplates: QueryList<UiDynamicFormsErrorTemplateDirective>;

    /**
     * HTMLElement to scroll to input element when keyboard is overlaying it
     */
    @Input() scrollContainer: ScrollToContainer;

    @Input()
    set elements(elements: UiDynamicElementConfig[]) {
        if (Array.isArray(elements)) {
            this._elements = elements;
        } else {
            this._elements = [];
        }
        this.rerenderElements();
    }
    /**
     * elements: {@link UiDynamicElementConfig}
     * JS Object that will render the elements depending on its config.
     * [name] property is required.
     */
    get elements(): UiDynamicElementConfig[] {
        return this._renderedElements;
    }

    /**
     * Getter property for dynamic [FormGroup].
     */
    get form(): UntypedFormGroup {
        return this._dynamicForm;
    }
    @Input() set form(value: UntypedFormGroup) {
        if (isAbstractControl(value)) {
            this._dynamicForm = value;
        }
    }

    /** Sets initial form value */
    @Input() set initialValue(value: AnyObject) {
        if (this.form && !this._patched && !isEmpty(compactObject(value))) {
            patchFormGroupValue(this.form, value);
            this._patched = true;
        }
    }

    private formName = '';
    /**
     * Form `name` attribute
     *
     * @readonly
     */
    get name() {
        return this.formName;
    }
    @Input() set name(value: string) {
        this.formName = value || generateUUID();
        if (!this.form['name']) {
            this.form['name'] = this.formName;
        }
    }

    private _groupName = '';
    /**
     * Form `groupName` attribute
     *
     * Sets parent group for dynamic form
     */
    get groupName() {
        return this._groupName;
    }
    @Input() set groupName(value: string) {
        if (value && !this.groupName) {
            this._groupName = value;
            this.setParentForm(value);
        }
    }

    /**
     * Getter property for [valid] of dynamic [FormGroup].
     */
    get valid(): boolean {
        if (this.form) {
            return this.form.valid;
        }
        return false;
    }

    /**
     * Getter property for [value] of dynamic [FormGroup].
     */
    get value(): any {
        if (this.form) {
            return this.form.value;
        }
        return {};
    }

    /**
     * Getter array for errors of dynamic [FormGroup].
     */
    get errors(): AnyObject[] {
        if (this.form) {
            return getFormValidationErrors(this.form);
        }
        return [];
    }

    /**
     * Getter property for [controls] of dynamic [FormGroup].
     */
    get controls(): { [key: string]: AbstractControl } {
        if (this.form) {
            return this.form.controls;
        }
        return {};
    }

    /**
     * Reports whether the form submission has been triggered.
     */
    get submitted(): boolean {
        return this._submitted;
    }

    @ViewChild('formRef', { static: true, read: ElementRef }) private formRef: ElementRef;
    /** HTMLFormElement */
    get formElement() {
        return this.formRef?.nativeElement as HTMLFormElement;
    }
    @ViewChild('formSubmitRef', { static: true, read: ElementRef }) private formSubmitRef: ElementRef;
    /** HTMLFormElement */
    get formSubmitElement() {
        return this.formSubmitRef?.nativeElement as HTMLButtonElement;
    }

    @Output() formSubmit = new EventEmitter<any>();

    private unlistener: () => void;

    /**
     * Constructor
     *
     * @param cdRef
     * @param ngZone
     * @param renderer
     * @param controlContainer
     * @param dynamicFormsService
     */
    constructor(
        public readonly cdRef: ChangeDetectorRef,
        private readonly ngZone: NgZone,
        private readonly renderer: Renderer2,
        @Optional() protected controlContainer: ControlContainer,
        private dynamicFormsService: UiDynamicFormsService,
    ) {
        super(cdRef);

        this.assignFormInstance(controlContainer);
    }

    /**
     * Lifecycle
     */
    ngAfterContentInit(): void {
        this.updateErrorTemplates();
        this.listenFormEvents();
    }

    /**
     * Lifecycle
     */
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.destroyControl$.complete();
        this.unlistener();
    }

    /**
     * Refreshes the form and rerenders all validator/element modifications.
     */
    resetForm(): void {
        this._submitted = false;
        this.form?.reset();
        this.form?.parent?.reset();
        this.form?.markAsPristine();
        this.form?.markAsUntouched();
    }

    /**
     * Refreshes the form and rerenders all validator/element modifications.
     */
    refresh(): void {
        this.rerenderElements();
        this.updateErrorTemplates();
    }

    /**
     * Getter method for error template references
     *
     * @param name
     */
    getErrorTemplateRef(name: string): TemplateRef<any> {
        return this.templateMap.get(name);
    }

    /**
     * On disabled change
     *
     * @param v
     */
    onDisabledChange(v: boolean): void {
        v ? this.form?.disable() : this.form?.enable();
        this.cdRef.markForCheck();
    }

    /**
     * prevent default to intercept submit and pass to custom output event
     *
     * @param event
     */
    onSubmitForm(event?: Event) {
        if (this.submitted) {
            return;
        }
        event?.preventDefault();
        event?.stopPropagation();
        this._submitted = true;
        this.formSubmit.emit();
        this.logger.debug('"formSubmit" emitted');
    }

    /**
     * Assign form instance from parent controlContainer directive
     *
     * @param controlContainer
     */
    private assignFormInstance(controlContainer: ControlContainer) {
        if (
            isFormGroupDirective(controlContainer) &&
            !!controlContainer?.control &&
            !hasFormArray(controlContainer?.control)
        ) {
            this._dynamicForm = controlContainer.control as UntypedFormGroup;
        } else {
            this._dynamicForm = new UntypedFormGroup({});
        }
    }

    /** Propagate `submit` event up to consumers, also outside zone */
    private listenFormEvents() {
        this.ngZone.runOutsideAngular(() => {
            // 1. Listen for custom event from child elements
            // 2. Propagate `submit` event up to consumers
            this.unlistener = this.renderer.listen('document', CUSTOM_SUBMIT_EVENT_NAME, (event: Event) => {
                event.stopPropagation();
                if (this.form.valid) {
                    // this.formSubmitElement.click();
                    this.formSubmit.emit(this.form.getRawValue());
                }
            });

            // fromEvent(this.formElement, 'submit')
            //     .pipe(takeUntil(this.destroy$), mapTo(this.form.value), debug('formSubmit'))
            //     .subscribe(this.formSubmit);
        });
    }

    /**
     * Loads error templates and sets them in a map for faster access.
     */
    private updateErrorTemplates(): void {
        this.templateMap = new Map<string, TemplateRef<any>>();
        for (const errorTemplate of this.errorTemplates.toArray()) {
            this.templateMap.set(errorTemplate.uiDynamicFormsError, errorTemplate.templateRef);
        }
    }
    /**
     * Set parent form if has `groupName` and no parent was set
     *
     * @param value
     */
    private setParentForm(value: string): void {
        if (!value) {
            return;
        }
        const form = this.controlContainer?.control?.get(value) as AbstractControl;
        if (!form || !!this.form.parent) {
            return;
        }
        if (form instanceof FormGroup) {
            this._dynamicForm = form;
            if (!this.form.parent) {
                this.form.setParent(form.parent as FormGroup);
            }
        } else if (form instanceof FormArray) {
            if (!form.length && !!this.form.controls) {
                form.push(this.form);
            }
        }
        // console.log('DEBUG: dynamic-forms.component.ts => setParent', this.dynamicForm.controls);
    }

    /**
     * Rerender elements
     */
    private rerenderElements(): void {
        this.groupName && this.setParentForm(this.groupName);
        this.clearRemovedElements();
        this._renderedElements = [];
        const duplicates: string[] = [];
        this._elements.forEach((elem: UiDynamicElementConfig) => {
            this.dynamicFormsService.validateDynamicElementName(elem.name);
            if (duplicates.indexOf(elem.name) > -1) {
                throw new Error(`Dynamic element name: "${elem.name}" is duplicated`);
            }
            duplicates.push(elem.name);
            const dynamicElement: AbstractControl = this.form.get(elem.name);
            if (!dynamicElement) {
                this.form.addControl(elem.name, this.dynamicFormsService.createFormControl(elem, this.form.disabled));
                this.subscribeToControlStatusChanges(elem.name);
            } else {
                if (elem.disabled) {
                    dynamicElement.disable();
                } else {
                    dynamicElement.enable();
                }
                if (elem.validators?.length) {
                    dynamicElement.setValidators(this.dynamicFormsService.createValidators(elem));
                }
                if (elem.default && dynamicElement.value !== elem.default) {
                    dynamicElement.setValue(elem.default);
                }
                dynamicElement.markAsPristine();
                dynamicElement.markAsUntouched();
            }
            // copy objects so they are only changes when calling this method
            this._renderedElements.push(Object.assign({}, elem));
        });
        // call a change detection since the whole form might change
        this.cdRef.detectChanges();
        timer()
            .toPromise()
            .then(() => {
                // call a markForCheck so elements are rendered correctly in OnPush
                this.cdRef.markForCheck();
            });
    }

    private clearRemovedElements(): void {
        this._renderedElements = this._renderedElements.filter(
            (renderedElement: UiDynamicElementConfig) =>
                !this._elements.some((element: UiDynamicElementConfig) => element.name === renderedElement.name),
        );
        // remove elements that were removed from the array
        this._renderedElements.forEach((elem: UiDynamicElementConfig) => {
            this.destroyControl$.next(elem.name);
            this.form.removeControl(elem.name);
        });
    }

    private subscribeToControlStatusChanges(elementName: string): void {
        const control: AbstractControl = this.controls[elementName];

        const controlDestroyed$: Observable<any> = this.destroyControl$.pipe(
            filter((destroyedElementName: string) => destroyedElementName === elementName),
        );

        control.statusChanges.pipe(takeUntil(this.destroy$), takeUntil(controlDestroyed$)).subscribe(() => {
            this.cdRef.markForCheck();
        });
    }
}
