/* eslint-disable @typescript-eslint/member-ordering, jsdoc/require-jsdoc,  @angular-eslint/component-selector, @typescript-eslint/no-var-requires */
import { addMinutes } from 'date-fns';
import { fillArray } from '@mona/shared/utils';
import { UiTableColumn, UiTableRow, UiTableDataSource } from '../models';

export const TEST_DATE = new Date('2023-01-01');
export const TEST_DATE_ISO = TEST_DATE.toISOString();

export const getTestRows = (length = 1): UiTableRow[] =>
    fillArray(
        length,
        (i: number) =>
            ({
                id: i.toString(),
                name: 'tr.' + i.toString(),
                code: i.toString(),
                dataType: i === 0 ? 'list' : 'any',
                values:
                    i % 2
                        ? Array.from({ length: 1000 }).reduce((acc, curr, idx) => {
                              if (idx > 100) {
                                  acc[addMinutes(TEST_DATE, idx).toISOString()] = {
                                      value: idx,
                                  };
                              }
                              return acc;
                          }, {})
                        : {},
                children:
                    i == 9
                        ? [
                              //
                              { id: '1', name: 'Child #' + 1 },
                              { id: '2', name: 'Child #' + 2 },
                          ]
                        : [],
            } as UiTableRow),
    );

export const getTestColumns = (length = 1000): UiTableColumn[] =>
    fillArray(
        length,
        (i: number) =>
            new UiTableColumn({
                name: 'td.' + i.toString(),
                headerText: 'th.' + i.toString(),
                meta: { isoDateString: addMinutes(TEST_DATE, i).toISOString() },
                isActive: i === length - 10,
                dataAccessorKey: 'meta.isoDateString',
            }),
    );
export const getTestDataSource = (rowsCount = 1) =>
    new UiTableDataSource(getTestRows(rowsCount), undefined, (item: any) => item?.code);
