import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { filter, first } from 'rxjs/operators';
import { FilterableDataSource, isEmptyObject, UniqueSet } from '@mona/shared/utils';
import { UiDynamicElementConfig } from '../../models';

/**
 * UiDynamicCheckboxComponent
 */
@Component({
    selector: 'ui-dynamic-checkbox',
    styleUrls: ['./dynamic-checkbox.component.scss'],
    templateUrl: './dynamic-checkbox.component.html',
})
export class UiDynamicCheckboxComponent implements AfterViewInit {
    control: UntypedFormControl;

    label = '';

    valueKey = 'value';

    name = '';

    hint = '';

    customClass = '';

    required = false;

    multiple = false;

    dataSource: FilterableDataSource<any> = undefined;

    selectedValues: SelectionModel<UiDynamicElementConfig['dataSource']>;

    /** @ignore */
    constructor() {
        this.selectedValues = new SelectionModel<UiDynamicElementConfig['dataSource']>(true);
        this.selectedValues['_selection'] = new UniqueSet(); // INFO: overrirde internal set with uniqueset to compare objects
    }

    /**
     * Lifecycle hook
     */
    ngAfterViewInit() {
        // Display initial value for multiple
        if (this.multiple) {
            this.control.valueChanges
                .pipe(
                    filter(v => !isEmptyObject(v)),
                    first(),
                )
                .subscribe(value => {
                    if (Array.isArray(value)) {
                        value.forEach(v => this.selectedValues.select(v, v));
                    }
                });

            this.selectedValues.changed.pipe().subscribe(change => {
                this.control.setValue(change.source.selected, { onlySelf: true });
            });
        }
    }
}
