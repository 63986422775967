import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UtilsModule } from '@mona/shared/utils';
import {
    CallEffects,
    callFeatureReducer,
    CALL_FEATURE_REDUCER,
    TelemedicineConfigEffects,
    telemedicineFeatureReducer,
    TelemedicineSetupEffects,
    TELEMEDICINE_FEATURE_REDUCER,
} from './state';

/**
 * DataAccessTelemedicineModule
 */
@NgModule({
    imports: [
        UtilsModule,
        StoreModule.forFeature('telemedicine', TELEMEDICINE_FEATURE_REDUCER),
        StoreModule.forFeature('call', CALL_FEATURE_REDUCER),
        EffectsModule.forFeature([TelemedicineSetupEffects, TelemedicineConfigEffects, CallEffects]),
    ],
    providers: [
        { provide: TELEMEDICINE_FEATURE_REDUCER, useValue: telemedicineFeatureReducer },
        { provide: CALL_FEATURE_REDUCER, useValue: callFeatureReducer },
    ],
})
export class DataAccessTelemedicineModule {}
