import { Injectable, OnDestroy } from '@angular/core';
import { endOfDay, endOfToday, isSameDay } from 'date-fns';
import { combineLatest, fromEvent, isObservable, NEVER, Observable, of } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    publishReplay,
    refCount,
    skip,
    startWith,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { generateDateTimesDataByList, getTimeColumns, roundToNearestInterval } from '../helpers/date-columns.helper';
import { initWorker } from '../helpers/worker.helper';
import {
    DateColumn,
    DateRangeInterval,
    DateTimeColumnsResponse,
    EntriesInterval,
    EntriesIntervalNames,
} from '../models';
import { ClockService } from './clock.service';

/**
 * Date interval service
 */
@Injectable({ providedIn: 'root' })
export class DateIntervalService implements OnDestroy {
    /**
     * Possible intervals with translation keys
     */
    static intervalTranslations: Record<EntriesIntervalNames, string> = {
        [EntriesInterval.MINUTES_5]: 'apps.patient.entitiesInterval.minutes5',
        [EntriesInterval.MINUTES_30]: 'apps.patient.entitiesInterval.minutes30',
        [EntriesInterval.HOUR_1]: 'apps.patient.entitiesInterval.hour1',
    } as any;
    /** WebWorker instance */
    private worker: Worker;
    /** WebWorker messages Map */
    readonly workerMessagesMap: Map<string, Observable<DateTimeColumnsResponse>> = new Map();

    /**
     * Constructor
     *
     * @param clockService ClockService
     */
    constructor(private clockService: ClockService) {
        initWorker(this);
    }

    /** LifeCycle */
    ngOnDestroy(): void {
        this.clear();
    }

    /**
     * Resolves table dates data
     *
     * @param interval$ Observable<EntriesInterval>
     * @param startDate$
     * @param endDate
     * @param takeUntilTrigger$
     */
    getTableDateData(
        interval$: Observable<EntriesInterval>,
        startDate$: Observable<Date>,
        endDate?: Date,
        takeUntilTrigger$?: Observable<any>,
    ): Observable<DateTimeColumnsResponse> {
        return combineLatest([interval$, startDate$, this.getTimeChangeTrigger(interval$)]).pipe(
            filter(
                ([selectedInterval, startTime, timeChangeTrigger]) =>
                    !!selectedInterval && !!startTime && !!timeChangeTrigger,
            ),
            switchMap(([selectedInterval, startTime]) => {
                this.worker.postMessage({
                    selectedInterval,
                    startTime,
                    endTime: endDate || endOfToday(),
                    withAdditionalIntervals: true,
                });
                return this.getDateTimeColumnsResponse(selectedInterval, startTime);
            }),
            // debug('getTableDateData'),
            takeUntil(isObservable(takeUntilTrigger$) ? takeUntilTrigger$ : NEVER),
            // do not recalculate days each time new subscriber is added
            publishReplay(1),
            refCount(),
        );
    }

    /**
     * Resolves table dates data for single date
     *
     * @param interval$ Observable<EntriesInterval>
     * @param startDate$ Observable<Date>
     */
    getSingleDateTableDateData(
        interval$: Observable<EntriesInterval>,
        startDate$: Observable<Date>,
    ): Observable<DateTimeColumnsResponse> {
        return combineLatest([interval$, startDate$, this.getTimeChangeTrigger(interval$)]).pipe(
            // ignore empty values
            filter(
                ([selectedInterval, startTime, timeChangeTrigger]) =>
                    !!selectedInterval && !!startTime && !!timeChangeTrigger,
            ),
            switchMap(([selectedInterval, startDate, timeChangeTrigger]) => {
                startDate.setHours(0, 0, 0, 0);
                const start = startDate,
                    end = endOfDay(start),
                    range = {
                        start,
                        end,
                    };

                return of({
                    selectedInterval,
                    dateColumns: [new DateColumn(range.start, [], true)],
                    timeColumns: getTimeColumns(range, selectedInterval),
                });
            }),
            // do not recalculate days each time new subscriber is added
            publishReplay(1),
            refCount(),
        );
    }

    /**
     * Resolves table dates data for list of dates triggered by dates or current date
     *
     * @param datesList Observable<string[]>
     */
    getTableDateDataByDatesList(datesList: Observable<string[]>): Observable<DateTimeColumnsResponse> {
        return combineLatest([datesList, this.clockService.now$]).pipe(
            switchMap(([datesList, currentTime]) => {
                const response = generateDateTimesDataByList(datesList, currentTime);
                return of(response);
            }),
            // do not recalculate days each time new subscriber is added
            publishReplay(1),
            refCount(),
        );
    }

    /**
     * Resolves table dates data for by date range interval
     *
     * @param range
     * @param selectedInterval
     */
    getTableDateDataByDateRange(
        range: DateRangeInterval,
        selectedInterval: EntriesInterval,
    ): Observable<DateTimeColumnsResponse> {
        const uId = 'range_' + range.start.getTime() + '_' + range.end.getTime();
        let obs$ = this.workerMessagesMap.get(uId);
        if (!obs$) {
            const dateTimeColumnsResponse: DateTimeColumnsResponse = {
                uId,
                selectedInterval,
                dateColumns: [new DateColumn(range.start)],
                timeColumns: getTimeColumns(range, selectedInterval),
            };
            obs$ = of(dateTimeColumnsResponse);
            this.workerMessagesMap.set(uId, obs$);
        }
        return obs$;
    }

    /** LifeCycle */
    clear(): void {
        this.workerMessagesMap.clear();
    }

    /**
     * Table dates data recalculation trigger
     * Triggers in the moment when there should be additional cells added according to selected interval
     *
     * @param interval$ Observable<EntriesInterval>
     */
    getTimeChangeTrigger(interval$?: Observable<EntriesInterval>): Observable<Date> {
        return this.clockService.now$.pipe(
            // skip first check as it is already triggered on init by startWith
            skip(1),
            withLatestFrom(interval$),
            filter(([endTime, selectedInterval]) => {
                const minutes = endTime.getMinutes();

                if (selectedInterval === EntriesInterval.MINUTES_5) {
                    return minutes % 5 === 0;
                } else if (selectedInterval === EntriesInterval.MINUTES_30) {
                    return minutes % 30 === 0;
                } else {
                    // for other cases just trigger each hour
                    return minutes === 0;
                }
            }),
            map(([endTime]) => endTime),
            startWith(new Date()),
        );
    }

    /**
     * Get worker message response of calculated date/time columns,
     * filtered by current  **unique** selected interval, made of interval name & start time value
     *
     * @param selectedInterval EntriesInterval
     * @param startTime
     */
    private getDateTimeColumnsResponse(selectedInterval: EntriesInterval, startTime: Date) {
        const uId = selectedInterval + '_' + startTime.getTime();
        let obs$ = this.workerMessagesMap.get(uId);
        if (!obs$) {
            obs$ = fromEvent<TypedMessageEvent<DateTimeColumnsResponse>>(this.worker, 'message').pipe(
                map(({ data }) => Object.assign(data, { selectedInterval }) as DateTimeColumnsResponse),
                filter(data => data.uId === uId),
            );
            this.workerMessagesMap.set(uId, obs$);
        }
        return obs$;
    }
}
