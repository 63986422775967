import { ApiModels } from '@mona/api';
import { PersistChangesResult } from '@mona/models';

/**
 * Transforms API change log entries
 * @param result ChangeLogEntryPersist
 */
export const transformApiPersistChangesResult = (result: ApiModels.ChangeLogEntryPersist): PersistChangesResult => {
    return {
        succeeded: result.succeeded,
        failed: result.failed,
    };
};
