import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DischargeReason } from '@mona/models';
import { DischargeReasonDialogForm } from './discharge-reason-dialog.model';

/**
 * Discharge reason component
 */
@Component({
    selector: 'discharge-reason-dialog',
    templateUrl: './discharge-reason-dialog.component.html',
    styleUrls: ['./discharge-reason-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DischargeReasonDialogComponent implements OnInit {
    /** Show/hide other reason input flag */
    isShowTextBox = false;

    /** Discharge reason form */
    dischargeReasonForm: FormGroup<DischargeReasonDialogForm> = new FormGroup<DischargeReasonDialogForm>({
        dischargeReason: new FormControl<DischargeReason>(null),
        customText: new FormControl<string>(null),
    });

    availableDischargeReasons: DischargeReason[] = this.data.availableDischargeReasons;

    /**
     * Constructor
     *
     * @param dialogRef
     * @param data
     */
    constructor(
        private dialogRef: MatDialogRef<DischargeReasonDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            availableDischargeReasons: DischargeReason[];
        },
    ) {}

    /**
     * Ng hook
     */
    ngOnInit(): void {
        const defaultReason = this.availableDischargeReasons.find(dr => dr.isDefault);
        this.dischargeReasonForm.get('dischargeReason').setValue(defaultReason);
    }

    /**
     * Send discharge reason on discharge dialog
     */
    ngSubmit() {
        if (this.dischargeReasonForm.valid) {
            const formValue = this.dischargeReasonForm.getRawValue();
            this.dialogRef.close(formValue);
        }
    }

    /**
     * Show/hide other reason input
     *
     * @param value DischargeReason
     */
    chooseOtherReason(value: DischargeReason) {
        this.isShowTextBox = value.showTextbox;
        if (value.showTextbox) {
            this.dischargeReasonForm.get('customText').addValidators([Validators.required, Validators.minLength(3)]);
            this.dischargeReasonForm.get('customText').updateValueAndValidity();
        } else {
            this.dischargeReasonForm.get('customText').clearValidators();
            this.dischargeReasonForm.get('customText').reset();
        }
    }
}
