import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { differenceInCalendarDays, isAfter } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeLogEntry, MedicationPrescription } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    ChangeLogService,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import {
    applyLastChangedByToPrescriptions,
    getEncounterViewSelectedDate,
    confirmDialogData,
    getUnconfirmedMedPrescriptions,
    withCurrentEncounterId,
} from '@mona/pdms/data-access-combined';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { Logger } from '@mona/shared/logger';
import { toSuccessActionResult, uniqArrayAttrs } from '@mona/shared/utils';
import { DialogData, DialogService, MessageService } from '@mona/ui';
import { DataAccessMedicationsFacade } from '../../application';
import { MedicationsState } from '../../entities';
import { MedicationPrescriptionsApi } from '../../infrastructure';
import {
    addMedicationPrescriptionChanges,
    handleAddMedicationPrescription,
    handleConfirmMedicationPrescriptionChanges,
    handleDeleteMedicationPrescription,
    loadMedicationPrescriptions,
    loadMedicationPrescriptionsFailure,
    processMedicationPrescriptions,
    upsertMedicationPrescriptions,
} from '../actions/medication-prescriptions.action';
import { selectMedicationPrescriptionAll } from '../selectors';

/**
 * MedicationsEffects
 */
@Injectable()
export class MedicationPrescriptionsEffects implements OnInitEffects {
    private logger = new Logger('PDMS:MedicationPrescriptionsEffects');
    changeLogMap$ = this.store.select(ChangeLogSelectors.getChangesMap);
    medicationPrescriptionAll$ = this.store.select(selectMedicationPrescriptionAll);
    selectedDate$: Observable<Date> = getEncounterViewSelectedDate();

    /* Load medication prescriptions */
    loadMedicationPrescriptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadMedicationPrescriptions),
            withCurrentEncounterId(),
            switchMap(([, encounterId]) =>
                this.medicationPrescriptionsApi.getMedicationPrescriptions(encounterId).pipe(
                    map(medicationPrescriptions =>
                        processMedicationPrescriptions({
                            medicationPrescriptions,
                        }),
                    ),
                    catchError(error => {
                        this.logger.error('Error loading medication prescriptions', error);
                        return of(loadMedicationPrescriptionsFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    /** process medication prescriptions from API */
    processMedicationPrescriptions$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(processMedicationPrescriptions),
                map(action => action.medicationPrescriptions),
                withLatestFrom(this.changeLogMap$, this.practitionersFacade.practitionersMap$),
                tap(([data, changesMap, practitionersMap]) => {
                    const medicationsCodesToLoad = uniqArrayAttrs(data, 'code') as string[];
                    if (medicationsCodesToLoad.length) {
                        this.terminologyService.loadMedicationCategories();
                        this.terminologyService.loadMedications(medicationsCodesToLoad);
                    }

                    const changes: ChangeLogEntry<MedicationPrescription>[] =
                        changesMap['MedicationPrescription'] || [];

                    if (changes.length) {
                        data = applyInstancesChanges(data, changes);
                    }

                    const medicationPrescriptions = applyLastChangedByToPrescriptions(data, practitionersMap);

                    this.store.dispatch(upsertMedicationPrescriptions({ medicationPrescriptions }));
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(
                    this.medicationPrescriptionAll$,
                    this.changeLogMap$,
                    this.practitionersFacade.practitionersMap$,
                    this.selectedDate$,
                ),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes: ChangeLogEntry<MedicationPrescription>[] =
                        changesMap['MedicationPrescription'] || [];

                    if (changes.length) {
                        let medicationPrescriptions = applyInstancesChanges(data, changes);

                        medicationPrescriptions = applyLastChangedByToPrescriptions(
                            medicationPrescriptions,
                            practitionersMap,
                        );

                        this.store.dispatch(
                            upsertMedicationPrescriptions({
                                medicationPrescriptions,
                            }),
                        );
                    }
                }),
                tap(([action, data, changesMap, practitionersMap, activeDate]) => {
                    if (action.payload?.['model'] === 'MedicationPrescription') {
                        const startDate = action?.payload?.['payload']?.startDate;

                        if (startDate) {
                            const prescribedForFuture = differenceInCalendarDays(startDate, activeDate) > 0;

                            if (prescribedForFuture) {
                                this.dialogService.showConfirmDialog(confirmDialogData()).pipe(take(1)).subscribe();
                            }
                        }
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.medicationPrescriptionAll$,
                    this.changeLogMap$,
                    this.practitionersFacade.practitionersMap$,
                ),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes = changesMap['MedicationPrescription'] || [];

                    if (changes.length) {
                        const removeUpdateData = getPersistedChangesData(data, changes);

                        removeUpdateData.toUpdate = applyLastChangedByToPrescriptions(
                            removeUpdateData.toUpdate,
                            practitionersMap,
                        );

                        this.store.dispatch(
                            addMedicationPrescriptionChanges({
                                toUpdateEntities: removeUpdateData.toUpdate || [],
                                toRemoveIds: removeUpdateData.toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    handleAddMedicationPrescription$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(handleAddMedicationPrescription),
                switchMap(({ medicationPrescription }) => {
                    const medicationPrescriptionToCreate = {
                        ...medicationPrescription,
                        isStopped: false,
                        isApproved: false,
                    };

                    // don`t send unit rate if rate is empty
                    if (medicationPrescription.unitRate && !medicationPrescription.rate) {
                        medicationPrescriptionToCreate.unitRate = null;
                    }

                    this.changeLogService.createMedicationPrescription(medicationPrescriptionToCreate);
                    return this.changeLogService.getSaveChangeAction().pipe(
                        toSuccessActionResult(),
                        take(1),
                        withLatestFrom(this.terminologyService.getMedicationCategories()),
                        tap(([, medicationCategories]) => {
                            const categoryName = medicationCategories.find(
                                c => c.code === medicationPrescription.category,
                            )?.displayName;
                            const message = `${medicationPrescription.description} has been added to ${categoryName}`;
                            this.messageService.successToast(message);
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    handleMedicationPrescriptionDelete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(handleDeleteMedicationPrescription),
                switchMap(({ medicationPrescription }) => {
                    return this.dialogService
                        .showConfirmDialog(this.deleteMedicationPrescriptionDialogData)
                        .pipe(switchMap(confirmed => (confirmed ? of(medicationPrescription) : of(null))));
                }),
                tap(medicationPrescription => {
                    if (!medicationPrescription) {
                        return;
                    }

                    const updatedMedications = [
                        {
                            ...medicationPrescription,
                            isStopped: true,
                            isApproved: false,
                        },
                    ];

                    // NOTE: we set end date as delete date to finish medication
                    const { endDate } = medicationPrescription;
                    const now = new Date();
                    if (!endDate || (endDate && isAfter(endDate, now))) {
                        updatedMedications[0].endDate = now;
                    }

                    this.changeLogService.updateMedicationPrescriptions(updatedMedications);
                }),
            ),
        { dispatch: false },
    );

    handleMedicationPrescriptionsConfirm$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(handleConfirmMedicationPrescriptionChanges),
                withLatestFrom(
                    this.medicationsFacade.medicationPrescriptionsUnconfirmed$,
                    this.medicationsFacade.currentPractitioner$,
                ),
                tap(([, medicationPrescriptions, currentPractitioner]) => {
                    const toConfirm = getUnconfirmedMedPrescriptions(medicationPrescriptions, currentPractitioner);

                    this.changeLogService.updateMedicationPrescriptions(
                        toConfirm.map(medicationPrescription => ({
                            ...medicationPrescription,
                            isApproved: true,
                        })),
                    );
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store Store
     * @param actions$ Actions
     * @param medicationPrescriptionsApi MedicationPrescriptionsApi
     * @param changeLogService ChangeLogService
     * @param dialogService DialogService
     * @param terminologyService TerminologyService
     * @param messageService MessageService
     * @param practitionersFacade PractitionersFacade
     * @param medicationsFacade DataAccessMedicationsFacade
     */
    constructor(
        private store: Store<MedicationsState>,
        private actions$: Actions,
        private medicationPrescriptionsApi: MedicationPrescriptionsApi,
        private changeLogService: ChangeLogService,
        private dialogService: DialogService,
        private terminologyService: TerminologyService,
        private messageService: MessageService,
        private practitionersFacade: PractitionersFacade,
        private medicationsFacade: DataAccessMedicationsFacade,
    ) {}

    /**
     * Data for confirm delete dialog
     */
    private get deleteMedicationPrescriptionDialogData(): DialogData {
        return {
            title: 'apps.patient.prescriptions.medicationTable.delete.title',
            description: 'apps.patient.prescriptions.medicationTable.delete.message',
            cancelBtn: 'apps.patient.prescriptions.medicationTable.delete.cancel',
            confirmBtn: 'apps.patient.prescriptions.medicationTable.delete.confirm',
        };
    }

    /**
     * MedicationPrescriptionsEffects -> OnInitEffects
     */
    ngrxOnInitEffects(): Action {
        console.log('TODO: MedicationPrescriptionsEffects implements OnInitEffects');
        return { type: 'EMPTY' };
    }
}
