<div class="sub-item" [class.active]="subItem.key === activeSubItemId" (click)="handleEvent.emit(subItem)">
    <img
        *ngIf="subItem.svgIcon && !showDefaultIcon"
        [ngSrc]="baseUrl + subItem.svgIcon"
        [width]="40"
        [height]="40"
        alt="icon"
        (error)="showDefaultIcon = true"
    />
    <mat-icon *ngIf="!subItem.svgIcon || showDefaultIcon" class="ui-drawer-nav-item-expand-icon">grid_view</mat-icon>
    <span>{{ subItem.title }}</span>
</div>
