import { beforeMethod } from 'kaop-ts';
import { NgxPermissionsService } from 'ngx-permissions';
import { RootInjector } from '@mona/shared/utils';
import { UI_DISABLE_WHEN_CHECK_PERMISSION } from '@mona/ui';

/**
 * Property decorator to check if the user has the required permission to execute the method
 *
 * @param permission string | string[]
 * @param defaultValue T
 */
export function WithPermissions<T extends any>(permission: string | string[], defaultValue: T) {
    return beforeMethod(meta => {
        const service = RootInjector.get(NgxPermissionsService);

        if (!service || !permission) {
            return;
        }

        if (typeof permission === 'string') {
            permission = [permission];
        }

        for (const p of permission) {
            if (!service.getPermission(p)) {
                meta.result = defaultValue;
                meta.prevent();

                return;
            }
        }
    });
}
