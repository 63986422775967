import { inject, Inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { from, fromEvent, merge, Observable, of } from 'rxjs';
import { mapTo, startWith, take, tap } from 'rxjs/operators';
import { IpcMainEvent } from '@mona/events';
import { MonaRpcService } from '@mona/rpc';

/**
 * INFO: add comment
 */
export type ConnectivityStatus = 'none' | 'portal' | 'limited' | 'full' | 'unknown';

/** Match offline Connectivity Status  */
const OFFLINE_STATUSES: ConnectivityStatus[] = ['none', 'unknown', 'limited'];

/**
 * Network service root provider token
 */
export const NETWORK = new InjectionToken<NetworkService>('Network', {
    providedIn: 'root',
    factory: () => new NetworkService(inject(WINDOW), inject(MonaRpcService)),
});
/**
 * Network service
 *
 * Provides sync & async values of current network online status & connectivity status
 */
export class NetworkService {
    /** Observable to listen when Internet connection availability changes */
    onlineChanges$: Observable<boolean>;

    /** Connectivity status given by OS Network Manager  */
    connectivity: ConnectivityStatus = 'full';

    /**
     * Check if browser connection is available
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine
     * @see https://developer.gnome.org/NetworkManager/stable/nmcli.html
     */
    get online(): boolean {
        return this.window.navigator.onLine && this.connected;
    }

    /**
     * Check if network connection is available
     *
     * @see https://developer.gnome.org/NetworkManager/stable/nmcli.html
     */
    get connected(): boolean {
        return NetworkService.matchConnectivityStatusOnline(this.connectivity);
    }

    /**
     * Match online Connectivity Status
     *
     * @param connectivity
     */
    static matchConnectivityStatusOnline(connectivity: ConnectivityStatus): boolean {
        return !OFFLINE_STATUSES.includes(connectivity);
    }

    /**
     * Constructor
     *
     * @param window
     * @param  rpcService rpcService
     */
    constructor(@Inject(WINDOW) private window: Window, private rpcService: MonaRpcService) {
        /* Continious stream, we don't need to unsubscribe */
        this.onlineChanges$ = merge(
            fromEvent(this.window, 'online').pipe(mapTo(true)),
            fromEvent(this.window, 'offline').pipe(mapTo(false)),
        ).pipe(startWith(this.online));

        this.getNetworkConnectivity().pipe(take(1)).subscribe();
    }

    /**
     * Calls `getNetworkConnectivity` on node layer
     *
     * @returns ConnectivityStatus - string represents connectivity status given by OS Network Manager
     */
    getNetworkConnectivity(): Observable<ConnectivityStatus> {
        if (this.rpcService.getIsLinux()) {
            return from(this.rpcService.invoke<ConnectivityStatus>(IpcMainEvent.GET_NETWORK_CONNECTIVITY)).pipe(
                tap(connectivity => {
                    this.connectivity = connectivity;
                }),
            );
        }
        return of('full');
    }
}
