import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { FrequencyTimesModule } from '../frequency-times';
import { BaseProcedurePrescriptionFormComponent, ProcedureListComponent } from './components';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [BaseProcedurePrescriptionFormComponent, ProcedureListComponent],
    imports: [UiModule, KeyboardModule, FrequencyTimesModule],
    exports: [BaseProcedurePrescriptionFormComponent, ProcedureListComponent],
})
export class ProcedureModule {}
