import { ApiModels } from '@mona/api';
import { AdmissionType, Anamnesis } from '@mona/models';

/**
 * Transforms api anamnesis
 *
 * @param apiAnamnesis apiAnamnesis
 */
export const transformApiAnamnesis = (apiAnamnesis: ApiModels.Anamnesis): Anamnesis => {
    return ApiModels.Anamnesis.DTO.toModel(apiAnamnesis);
};

/**
 * Transform api anamneses
 *
 * @param apiAnamneses apiAnamneses
 */
export const transformApiAnamneses = (apiAnamneses: ApiModels.Anamnesis[]): Anamnesis[] => {
    return apiAnamneses.map(anamnesis => transformApiAnamnesis(anamnesis));
};
