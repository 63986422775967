import { NgModule } from '@angular/core';
import { UtilsModule } from '@mona/shared/utils';
import { MaterialModule } from '../../material.module';
import { DrawerBodyModule } from './drawer-body/drawer-body.module';
import { DrawerFooterModule } from './drawer-footer/drawer-footer.module';
import { DrawerHeaderModule } from './drawer-header/drawer-header.module';
import { DrawerLayoutModule } from './drawer-layout/drawer-layout.module';
import { DrawerNavBarModule } from './drawer-navbar';
import { DrawerSubheaderModule } from './drawer-subheader/drawer-subheader.module';
import { DrawerComponent } from './drawer.component';

/**
 * UiDrawerModule
 */
@NgModule({
    declarations: [DrawerComponent],
    imports: [
        UtilsModule,
        MaterialModule,
        DrawerBodyModule,
        DrawerFooterModule,
        DrawerHeaderModule,
        DrawerLayoutModule,
        DrawerNavBarModule,
        DrawerSubheaderModule,
    ],
    exports: [
        DrawerComponent,
        DrawerBodyModule,
        DrawerFooterModule,
        DrawerHeaderModule,
        DrawerLayoutModule,
        DrawerNavBarModule,
        DrawerSubheaderModule,
    ],
})
export class UiDrawerModule {}
