import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { ConfigService } from '@mona/config';
import { BaseErrors, Suspense, SUSPENSE_INITIAL_STATE } from '@mona/shared/utils';
import { WsEndpointsList, WS_ENDPOINTS_LIST } from '../tokens';
import { WsService } from './base-ws.service';

/**
 * Core health ws service
 */
@Injectable({ providedIn: 'root' })
export class ApiHealthWsService {
    reconnectInterval = 3000;
    reconnectAttempts = 0;

    private wsMap = new Map<string, WsService<any>>();
    private apiStateMap = new Map<string, BehaviorSubject<Suspense<void>>>([]);

    /**
     * Constructor
     *
     * @param configService ConfigService
     * @param wsEnpoints
     */
    constructor(
        private configService: ConfigService,
        @Inject(WS_ENDPOINTS_LIST) public readonly wsEnpoints: WsEndpointsList[],
    ) {
        this.configService.configLoaded$.subscribe(() => {
            this.wsEnpoints.forEach(wsApi => {
                const serverUrl = this.configService.get(wsApi) as string;
                this.setUrlMapsValues(serverUrl);
            });
        });
    }

    /**
     * Get state
     *
     * @param serverUrl
     */
    getState$(serverUrl: string): Observable<Suspense<void>> {
        const state = this.apiStateMap.get(serverUrl);
        return state?.asObservable() || of({ finished: true, inProgress: false, succeeded: true } as any);
    }

    /**
     * Checks connection
     *
     * @param serverUrl
     * @param retryAttemps
     */
    checkConnection(serverUrl: string, retryAttemps = 0): Observable<Suspense<any>> {
        let checkingState = this.apiStateMap.get(serverUrl);

        if (!checkingState) {
            this.setUrlMapsValues(serverUrl);
            checkingState = this.apiStateMap.get(serverUrl);
        }

        checkingState.next(SUSPENSE_INITIAL_STATE);
        const wsService = this.wsMap.get(serverUrl);
        wsService.setConfig(
            `${serverUrl}/ws/anonymous/`,
            undefined,
            {
                deserializer: msg => msg as any,
            },
            this.reconnectInterval,
            retryAttemps,
        );
        wsService.connect().subscribe(
            state => {
                checkingState.next({
                    ...SUSPENSE_INITIAL_STATE,
                    succeeded: true,
                    finished: true,
                    inProgress: false,
                });
            },
            error => {
                checkingState.next({
                    ...SUSPENSE_INITIAL_STATE,
                    succeeded: false,
                    finished: true,
                    inProgress: false,
                    error: {
                        errorCode: BaseErrors.WEBSOCKET,
                        originalError: error,
                    },
                });
            },
        );
        return checkingState.asObservable();
    }

    /**
     * Resets checking state
     */
    reset(): void {
        this.wsMap.forEach(ws => ws.disconnect());
        this.apiStateMap.forEach(s => s.next(SUSPENSE_INITIAL_STATE));
    }

    /**
     * Set wsMap and apiStateMap values if not exist
     * @param serverUrl
     */
    private setUrlMapsValues(serverUrl: string): void {
        this.wsMap.set(serverUrl, new WsService());
        this.apiStateMap.set(serverUrl, new BehaviorSubject(SUSPENSE_INITIAL_STATE));
    }
}
