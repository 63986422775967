import { AfterViewInit, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith, tap, withLatestFrom } from 'rxjs/operators';
import { VaccineCode } from '@mona/models';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { VaccinationForm } from '../../models';

/**
 * Vaccination Status Form
 */
@Component({
    selector: 'app-vaccination-form',
    templateUrl: './vaccination-form.component.html',
    styleUrls: ['./vaccination-form.component.scss'],
})
export class VaccinationFormComponent implements AfterViewInit {
    /**
     * Form group
     */
    @Input() formGroup: FormGroup<VaccinationForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is form active / visible
     */
    @Input() isActive: boolean;

    /**
     * Max textarea rows count size
     */
    @Input() textareaMaxRows = 40;

    /**
     * Control for vaccine search
     */
    @Input() vaccineSearchText = new FormControl<string | VaccineCode>('');

    /**
     * Keyboard enter clicked
     */
    @Output() keyboardEnterClicked: EventEmitter<void> = new EventEmitter();

    /**
     * Rendered autocomplete controls
     * Prepared as ViewChildren in case that later there will be more autocompletes
     */
    @ViewChildren(MatAutocomplete) private autocompletes: QueryList<MatAutocomplete>;

    /**
     * Current data
     */
    currentDate = new Date();

    /**
     * Search results for the autocomplete
     */
    foundVaccineCodes$ = this.vaccineSearchText.valueChanges.pipe(
        startWith(''),
        withLatestFrom(this.terminologyService.getVaccineCodes()),
        map(([searchValue, vaccineCodes]) =>
            vaccineCodes.filter(vaccineCode => {
                const searchText = typeof searchValue === 'string' ? searchValue : searchValue.displayName;
                return vaccineCode.displayName.toLowerCase().includes(searchText?.toLowerCase());
            }),
        ),
    );

    /**
     * Constructor
     *
     * @param terminologyService
     */
    constructor(private terminologyService: TerminologyService) {}

    /** AfterViewInit */
    ngAfterViewInit() {
        if (this.formGroup?.value?.vaccinationAgainst) {
            this.vaccineSearchText.patchValue(this.formGroup?.value?.vaccinationAgainst as any);
            this.formGroup.controls.vaccinationAgainst.patchValue(this.formGroup?.value?.vaccinationAgainst);
        }
    }

    /**
     * Adds an autocomplete item as new entry
     *
     * @param $event MatAutocompleteSelectedEvent
     */
    onNewItemAutoCompleteSelect($event: MatAutocompleteSelectedEvent) {
        this.formGroup.patchValue({ vaccinationAgainst: $event.option.value }, { emitEvent: false });
        this.autocompletes.toArray().forEach(autocomplete => {
            autocomplete.showPanel = false;
        });
    }

    /**
     * Formats the output for autocomplete
     *
     * @param result VaccineCode
     */
    autoCompleteDisplayFn(result: VaccineCode): string {
        return result?.displayName;
    }
}
