import { environment } from '@environment';
import { addMinutes, eachDayOfInterval, set, endOfTomorrow, isBefore, isWithinInterval } from 'date-fns';
import { Merge } from 'type-fest';
import { MedicationPrescription, PrescriptionFrequencyTime } from '@mona/models';
import { convertUTCHourToLocalHour } from '@mona/shared/date';
import { isNumber } from '@mona/shared/utils';

/**
 * Generates set of iso dates which should contain suggestions for prescriptions
 *
 * @param prescriptions MedicationPrescription[]
 * @param frequencyTimes PrescriptionFrequencyTime[]
 */
export const generatePrescriptionSuggestions = (
    prescriptions: Merge<
        Pick<MedicationPrescription, 'id' | 'startDate' | 'endDate' | 'frequency' | 'frequencyTimes' | 'isStopped'>,
        { isRevoked?: boolean }
    >[],
    frequencyTimes: PrescriptionFrequencyTime[],
): Map<string, Set<string>> => {
    const map = new Map();

    if (!frequencyTimes?.length || !prescriptions?.length) {
        return map;
    }

    prescriptions.forEach(prescription => {
        let filteredFrequencyTimes = frequencyTimes.filter(
            ft => ft.prescriptionFrequencyCode === prescription.frequency,
        );

        if (!filteredFrequencyTimes?.length && prescription.frequency !== environment.customFrequencyCode) {
            return;
        }

        if (prescription.isStopped || prescription.isRevoked) {
            return;
        }

        // INFO: this is hack because API returns repeatAfterMinutes for items which should not have it
        if (filteredFrequencyTimes?.length > 1) {
            filteredFrequencyTimes.map(ft => ({ ...ft, repeatAfterMinutes: null }));
        }

        // Create ft values for custom frequency
        if (prescription.frequency === environment.customFrequencyCode) {
            filteredFrequencyTimes = prescription.frequencyTimes.map(ft => {
                const [hour, minute] = ft.split(':').map(Number);
                return { hour, minute } as PrescriptionFrequencyTime;
            });
        }

        const start = prescription.startDate;
        const end = prescription.endDate || endOfTomorrow();

        const suggestions: string[] = [];
        if (isBefore(end, start)) {
            return;
        }

        const days = eachDayOfInterval({ start, end });
        const offset = new Date().getTimezoneOffset() / 60;

        for (const day of days) {
            let stopIfFrequencyWithRepeatedMins = false;
            for (const ft of filteredFrequencyTimes) {
                let next = set(day, {
                    hours: ft.hour - offset,
                    // TODO: subtract offset from hours in 2.2.0 MonaOS version
                    // MERGE-MARK

                    minutes: ft.minute,
                    seconds: 0,
                    milliseconds: 0,
                });

                if (ft.repeatAfterMinutes > 0 && isBefore(next, start)) {
                    while (isBefore(next, start)) {
                        next = addMinutes(next, ft.repeatAfterMinutes);
                    }
                }

                if (isWithinInterval(next, { start, end })) {
                    // INFO: do not remove check for repeatAfterMinutes > 0, because API returns 0 for items which should not have it
                    // INFO: do not remove check for repeatAfterMinutes < 1440, because API returns 1440 for items which should not have it which happens to be 24 hours
                    if (isNumber(ft.repeatAfterMinutes) && ft.repeatAfterMinutes > 0 && ft.repeatAfterMinutes <= 1440) {
                        do {
                            suggestions.push(next.toISOString());
                            next = addMinutes(next, ft.repeatAfterMinutes);
                        } while (next <= end);
                        stopIfFrequencyWithRepeatedMins = true;
                    }
                    // Case. when prescription should be given less than once per day
                    else if (isNumber(ft.repeatAfterMinutes) && ft.repeatAfterMinutes > 1440) {
                        let currDate = set(start, {
                            // TODO: subtract offset from hours in 2.2.0 MonaOS version // ask OD if it's still needed
                            hours: convertUTCHourToLocalHour(ft.hour),
                            minutes: ft.minute,
                            seconds: 0,
                            milliseconds: 0,
                        });

                        while (isBefore(currDate, end)) {
                            if (currDate >= start && currDate <= end) {
                                suggestions.push(currDate.toISOString());
                            }
                            currDate = addMinutes(currDate, ft.repeatAfterMinutes);
                        }
                    }
                    // Case, when prescription should be given only once and not occur anymore from that point
                    else if (isNumber(ft.repeatAfterMinutes) && ft.repeatAfterMinutes === 0 && ft.hour) {
                        suggestions.push(
                            set(start, {
                                // TODO: subtract offset from hours in 2.2.0 MonaOS version // ask OD if it's still needed
                                hours: convertUTCHourToLocalHour(ft.hour),
                                minutes: ft.minute,
                                seconds: 0,
                                milliseconds: 0,
                            }).toISOString(),
                        );
                    } else {
                        suggestions.push(next.toISOString());
                    }
                }
            }

            if (stopIfFrequencyWithRepeatedMins) {
                break;
            }
        }

        map.set(prescription.id, new Set(suggestions.sort()));
    });
    return map;
};
