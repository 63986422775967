import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { UtilsModule } from '@mona/shared/utils';
import { UiDatePickerComponent } from './date-picker.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [UiDatePickerComponent],
    imports: [UtilsModule, MatInputModule, MatDatepickerModule],
    exports: [UiDatePickerComponent],
})
export class UiDatePickerModule {}
