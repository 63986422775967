import { createAction, props } from '@ngrx/store';
import { Patient } from '@mona/models';

/**
 * Load all admission patients
 */
export const loadAllAdmissionPatients = createAction('PATIENTS_ADMISSION:LOAD_ALL_ADMISSION_PATIENTS');

/**
 * Load all admission patients success
 */
export const loadAllAdmissionPatientsSuccess = createAction(
    'PATIENTS_ADMISSION:LOAD_ALL_ADMISSION_PATIENTS_SUCCEEDED',
    props<{ patients: Patient[] }>(),
);

/**
 * Load all admission patients failed
 */
export const loadAllAdmissionPatientsFailure = createAction(
    'PATIENTS_ADMISSION:LOAD_ALL_ADMISSION_PATIENTS_FAILED',
    props<{ error: unknown }>(),
);

/**
 * Search patient action
 */
export const clearSearchPatientAdmission = createAction('PATIENTS_ADMISSION:SEARCH_PATIENT_CLEAR');

/**
 * Search patient action
 */
export const searchFilterChanged = createAction(
    'PATIENTS_ADMISSION:SEARCH_FILTER_CHANGED',
    props<{ searchQuery: string }>(),
);
