import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { resetEncounterViewSelectedDate } from '@mona/pdms/data-access-combined';
import { DataAccessMedicationsFacade } from '@mona/pdms/data-access-medications';
import { DataAccessProceduresFacade } from '@mona/pdms/data-access-procedures';
import { takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { DrawerNavItem, DrawerContentComponent, UiTabBarModule } from '@mona/ui';

/**
 * Prescription pages wrapper
 */
@TakeUntilDestroy
@Component({
    selector: 'pdms-prescriptions-page-wrapper',
    template: `
        <ui-drawer-content>
            <ui-tab-bar
                ui-drawer-content-nav
                [tabs]="tabs"
                [iconPosition]="'after'"
                [(tabIndex)]="selectedTabIndex"
            ></ui-tab-bar>
            <router-outlet ui-drawer-content-main></router-outlet>
        </ui-drawer-content>
    `,
    imports: [RouterModule, DrawerContentComponent, MatButtonModule, MatIconModule, UiTabBarModule, TranslateModule],
    standalone: true,
})
export class PrescriptionsPageWrapperComponent implements OnInit, OnDestroy {
    selectedTabIndex: number;
    showDeleted = false;

    tabs: DrawerNavItem[] = [
        { title: 'apps.patient.prescriptions.medication', link: './medications/list' },
        { title: 'apps.patient.prescriptions.procedures', link: './procedures/list' },
    ];

    /**
     * Sets selected UI date
     */
    resetSelectedDateFn = resetEncounterViewSelectedDate();

    /**
     * constructor
     *
     * @param dataAccessMedicationsFacade DataAccessMedicationsFacade
     * @param dataAccessProceduresFacade DataAccessProceduresFacade
     */
    constructor(
        private dataAccessMedicationsFacade: DataAccessMedicationsFacade,
        private dataAccessProceduresFacade: DataAccessProceduresFacade,
    ) {}

    /**
     * ngOnInit
     */
    ngOnInit(): void {
        this.resetSelectedDateFn();
        /* Listen to user change and reload data as user roles may differ
            (confirmed by nurse/doctor, applicable to device only as in web components destroy on logout) */
        this.dataAccessMedicationsFacade.currentPractitioner$
            .pipe(takeUntilDestroy(this))
            .subscribe(() => this._loadInitialData());

        combineLatest([
            this.dataAccessMedicationsFacade.medicationPrescriptionsUnconfirmed$,
            this.dataAccessProceduresFacade.procedurePrescriptionsUnconfirmed$,
        ])
            .pipe(takeUntilDestroy(this))
            .subscribe(unconfirmedArr => {
                this.tabs = this.tabs.map((tab, index) => {
                    return {
                        ...tab,
                        svgIcon: unconfirmedArr[index].length ? 'alert-octagon' : 'shield-check',
                        statusColor: unconfirmedArr[index].length ? 'accent' : 'success',
                    };
                });
            });
    }

    /**
     * ngOnDestroy
     */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnDestroy(): void {}

    /**
     * Load initial data(medication and procedure prescriptions)
     */
    private _loadInitialData(): void {
        this.dataAccessMedicationsFacade.loadMedicationPrescriptions();
        this.dataAccessProceduresFacade.loadProcedurePrescriptions();
    }
}
