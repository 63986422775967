/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/member-ordering */
import { BooleanInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Renderer2,
    Self,
    ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { merge, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    skipWhile,
    takeUntil,
} from 'rxjs/operators';
import { Medication } from '@mona/models';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { isEmpty, isObject, uiPure } from '@mona/shared/utils';
import { MessageService } from '@mona/ui';

const DEBOUNCE_TIME = 250;

/**
 * Medication search autocomplete component
 * Performs search and provides itself as form control to be reused in forms
 */
@Component({
    selector: 'app-medication-autocomplete',
    templateUrl: './medication-autocomplete.component.html',
    styleUrls: ['./medication-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MatFormFieldControl, useExisting: MedicationAutocompleteComponent }],
})
// , MatFormFieldControl<any>
export class MedicationAutocompleteComponent
    implements OnInit, MatFormFieldControl<Medication>, ControlValueAccessor, AfterViewInit, OnDestroy
{
    /** next Id generator */
    static nextId = 0;

    /**
     * Show reset button
     */
    @Input() hideResetBtn = false;

    /**
     * INFO: add comment
     */
    @Input()
    get value(): Medication | null {
        return this.inputControl.value as any;
    }
    /**
     * Allows Angular to update the inputControl.
     * Update the model and changes needed for the view here.
     */
    set value(value: Medication) {
        if (value) {
            if (isObject(value) && value.displayName) {
                this.inputControl.setValue(value);
                // this.renderer.setProperty(this._autocompleteInput.nativeElement, 'value', value.displayName);
            } else if (typeof value === 'string' && value !== this.value?.displayName) {
                this._prevValue = value;
                this.inputControl.setValue({
                    displayName: value,
                    categoryCode: undefined,
                    id: '',
                });
            }
            this.stateChanges.next();
        }
    }
    /** _ @ignore */
    private _prevValue: string;
    /**
     * INFO: add comment
     */
    @Input()
    get required() {
        return this._required;
    }
    /** required */
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    /** _ @ignore */
    private _required = false;
    /** Placeholder text */
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder =
            plh || this.translateService.instant('apps.settings.medications.addDialog.searchPlaceholder');
        this.stateChanges.next();
    }
    /** _ @ignore */
    private _placeholder: string;
    /** Text for No-results label */
    @Input() noResultsLabel = this.translateService.instant('apps.settings.medications.addDialog.emptyTitle');
    /** Text for searching label */
    @Input() isSearchingLabel = this.translateService.instant('apps.admission.preMedication.newItem.hint');
    /** Allow not existing */
    @Input() allowNonExisting = true;
    /** Allow not existing */
    @Input() skipCreateReal = false;
    /** Is autocomplete readonly */
    @Input() readonly = false;
    /** Show options icons  */
    @Input() showOptionsIcons = false;
    /** Show options info  */
    @Input() showOptionsInfo = false;
    /** Search by which API */
    @Input() searchBy: 'medications' | 'medication-groups' = 'medications';
    /** Should auto focus on init */
    @Input() autoFocus = false;
    /** Is autocomplete disabled */
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.inputControl.disable() : this.inputControl.enable();
        this.stateChanges.next();
    }
    /** _ @ignore */
    private _disabled = false;
    /** Is in BedSideMode */
    @Input() isBedSideMode = false;
    /** Length to trigger search */
    @Input()
    set lengthToTriggerSearch(value: number) {
        this._lengthToTriggerSearch = coerceNumberProperty(value, 0);
    }
    /** _lengthToTriggerSearch @internal */
    private _lengthToTriggerSearch = 3;
    /** Inner form control to link input text changes to mat autocomplete */
    inputControl: FormControl<Medication | string> = new FormControl<Medication | string>(null, this.validators);
    /** noResults */
    noResults = false;
    /** isSearching */
    isSearching = false;
    /** Whether the control is touched. */
    touched = false;
    /** Whether the control is focused. */
    focused = false;
    /** Whether the control is empty. */
    get empty() {
        return !this.autocompleteInput.value.length;
    }
    /** Whether the control is in an error state. */
    get errorState(): boolean {
        return this.inputControl.invalid && this.touched;
    }
    /**
     * We'll need to emit on the stateChanges stream when that happens, and as we continue
     * flushing out these properties we'll likely find more places we need to emit. We should also make sure to complete stateChanges when our component is destroyed.
     */
    stateChanges = new Subject<void>();
    /** searchMedicationsAction */
    private searchMedicationsAction$ = this.terminologyService.getSearchMedicationsAction().pipe(shareReplay(1));
    /** createMedicationsAction */
    private createMedicationsAction$ = this.terminologyService.getCreateMedicationAction().pipe(shareReplay(1));
    /** Destroy subject */
    private destroy$ = new Subject();
    /** Autocomplete selected event */
    @Output() autocompleteSelected = new EventEmitter<Medication | null>();
    /** Search results for the autocomplete */
    @Output() medicationsOptions = this.searchMedicationsAction$.pipe(
        filter(state => !state.inProgress),
        map(state => {
            // Also remove duplicates here
            const options =
                state.result?.reduce((acc, item) => {
                    if (!acc.find(i => i.displayName === item.displayName)) {
                        acc.push(item);
                    }
                    return acc;
                }, [] as Medication[]) || [];
            this.noResults = this.isMinLength(this.autocompleteInput.value) && !options.length;
            return options;
        }),
    );
    /** Status of the autocomplete search */
    @Output() searchInProgress = merge(this.searchMedicationsAction$, this.createMedicationsAction$).pipe(
        skipWhile(() => this.isMinLength(this.inputControl.value as string)),
        map(state => state?.inProgress),
    );
    /** Autocomplete input reference */
    @ViewChild('autocompleteInput', { static: true }) autocompleteInputElementRef: ElementRef;
    /** Autocomplete input element */
    get autocompleteInput(): HTMLInputElement {
        return this.autocompleteInputElementRef.nativeElement;
    }
    /** Parent form control */
    get parentControl(): AbstractControl {
        return this.ngControl?.control;
    }
    /** Default validators */
    private get validators(): ValidatorFn[] {
        const validatorsArray = [];
        // tslint:disable-next-line: curly
        if (this.required) {
            validatorsArray.push(Validators.required);
        }
        // if (!this.allowNonExisting) validatorsArray.push(CustomValidators.requireMatch); // TODO: dynamically add `requireMatch` validations
        return validatorsArray;
    }
    /** Component css class */
    @HostBinding('class.medication-autocomplete') componentCssClass = true;
    /** HostBinding id */
    @HostBinding() id = `medication-autocomplete-${MedicationAutocompleteComponent.nextId++}`;
    /** HostBinding floating class */
    @HostBinding('class.floating') get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    /** The <mat-form-field> will add a class based on this type that can be used to easily apply special styles to a <mat-form-field> that contains a specific type of control. */
    controlType = 'medication-autocomplete';

    /**
     * Constructor
     *
     * @param _formField
     * @param ngControl
     * @param parentFormField
     * @param _elementRef
     * @param renderer
     * @param terminologyService
     * @param messageService
     * @param translateService
     */
    constructor(
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public parentFormField: MatFormField,
        private _elementRef: ElementRef<HTMLElement>,
        private renderer: Renderer2,
        private terminologyService: TerminologyService,
        private messageService: MessageService,
        private translateService: TranslateService,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    /** Lifecycle */
    ngOnInit() {
        this.initControl();
        this.watchInputValue();
    }

    /**
     * INFO: add comment
     */
    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.autoFocus) {
                this.autocompleteInputElementRef.nativeElement.focus();
                this.onFocusIn(null);
            }
        }, 10);
    }

    /**
     * Lifecycle
     */
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.terminologyService.clearSearchMedications();
        this.terminologyService.clearCreateMedication();
        this.stateChanges.complete();
    }

    /**
     * onFocusIn
     *
     * @param event
     */
    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    /**
     * onFocusOut
     *
     * @param event
     */
    onFocusOut(event: FocusEvent) {
        // Check for BedSideMode is needed as the on screen keyboard blurs the input in every keystroke.
        if (!this.isBedSideMode) {
            if (this.preventBlur) {
                return;
            }
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        } else {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    /**
     * onContainerClick
     *
     * @param event
     */
    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef.nativeElement.querySelector('input').focus();
        }
    }

    /**
     * writeValue implementation
     *
     * @param value
     */
    writeValue(value: Medication | null): void {
        if (this.isBedSideMode) {
            return;
        }
        this.value = value;
    }

    /**
     * registerOnChange @ignore
     *
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * registerOnTouched @ignore
     *
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * setDisabledState @ignore
     *
     * @param isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Method linked to the mat-autocomplete `[displayWith]` input.
     * This is how result name is printed in the input box.
     *
     * @param result
     */
    displayFn(result: any): string | undefined {
        return typeof result === 'string' ? result : result?.displayName || '';
    }

    /**
     * Track by code (used for table data source)
     *
     * @param index number
     * @param item terminology model which has code
     */
    trackByFn(index, item): string {
        return item?.id || index;
    }

    /**
     * On autocomplete select:
     * 1) if medication was not found & allow creation of new one
     * 2) if medication was not found
     *
     * @param root0
     * @param root0.option
     * @param root0.source
     */
    onAutoCompleteSelect({ option, source }: MatAutocompleteSelectedEvent) {
        if (option.id === 'no-results') {
            this.disabled = true;
            if (this.allowNonExisting) {
                this.createNewMedication(String(this.inputControl.value));
            } else {
                this.inputControl.clearValidators();
                this.inputControl.updateValueAndValidity();
            }
        } else {
            this.value = option.value;
            if (this.isBedSideMode) {
                // setTimeout is dealing with on screen keyboard
                setTimeout(async () => {
                    this.preventBlur = true;
                    this.autocompleteInputElementRef.nativeElement.blur();
                    this.autocompleteSelected.next(this.value);
                    this.onChange(this.value);
                });
            } else {
                this.renderer.setProperty(this.autocompleteInput, 'value', this.value.displayName);
                this.onChange(this.value);
                this.autocompleteSelected.next(this.value);
            }
        }
        this.stateChanges.next();
        this.terminologyService.clearSearchMedications();
    }

    /** Reset autocomplete input and formControl itself */
    onAutocompleteReset() {
        this.resetAll();
    }

    /**
     * deside if add option should be shown
     *
     * @param isSearchInProgress boolean
     * @param searchValue
     */
    @uiPure
    shouldShowAddOption(isSearchInProgress: boolean, searchValue: any): boolean {
        return !isSearchInProgress && searchValue?.length && (this.noResults || this.allowNonExisting);
    }

    /** Reset autocomplete input and formControl itself */
    private resetAll() {
        this.inputControl.reset();
        this.parentControl.enable();
        this.parentControl.reset();
        this.parentControl.markAsDirty();
        this.parentControl.updateValueAndValidity();
        this.terminologyService.clearSearchMedications();
    }

    /** Init internal form control */
    private initControl() {
        if (this.parentControl) {
            // Set validators for the outer ngControl equals to the inner
            const validators = this.parentControl.validator
                ? [this.parentControl.validator]
                : this.inputControl.validator;
            this.parentControl.setValidators(validators);
            this.inputControl.setValidators(validators);
            // Update outer ngControl status
            this.parentControl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** Watch input value & Trigger autocomplete search */
    private watchInputValue() {
        this.inputControl.valueChanges
            .pipe(
                /* prettier-ignore */
                debounceTime(DEBOUNCE_TIME),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe(value => {
                if (typeof value === 'string' && !this.disabled) {
                    this.triggerSearchMedicationsByName(value, this.searchBy);
                }
            });
    }

    /**
     * Trigger search medications by name
     *
     * @param value
     * @param searchBy
     */
    private triggerSearchMedicationsByName(value: string, searchBy?: 'medications' | 'medication-groups') {
        if (typeof value === 'string' && !this.disabled) {
            if (this.isMinLength(value)) {
                this.terminologyService.searchMedicationsByName(value, searchBy);
            } else {
                this.terminologyService.clearSearchMedications();
            }
        }
    }

    /**
     * Create medication
     *
     * @param displayName
     */
    private createNewMedication(displayName: string) {
        const payload = {
            displayName,
        };
        if (this.skipCreateReal) {
            this.value = payload as any;
            this.onChange(this.value);
            this.autocompleteSelected.next(this.value);
            this.updateValueAndValidity();
            return;
        }
        if (!isEmpty(payload)) {
            this.terminologyService.createMedication(payload, this.searchBy);
            this.createMedicationsAction$
                .pipe(
                    filter(state => !state.inProgress && state.finished && !!state.result),
                    map(state => state.result),
                    catchError(err => {
                        this.messageService.errorToast(
                            this.translateService.instant('apps.settings.medications.addDialog.createMedication.error'),
                        );
                        return of(null);
                    }),
                )
                .subscribe(newMedication => {
                    if (newMedication) {
                        this.value = newMedication;
                        this.onChange(this.value);
                        this.autocompleteSelected.next(this.value);
                        this.updateValueAndValidity();
                        this.messageService.successToast(
                            this.translateService.instant(
                                'apps.settings.medications.addDialog.createMedication.success',
                            ),
                        );
                    }
                });
        }
    }

    /**
     * is value minLength
     *
     * @param value
     */
    private isMinLength(value: string) {
        return value?.length >= this._lengthToTriggerSearch;
    }

    /** Update control(s) value and validity */
    private updateValueAndValidity() {
        this.inputControl.clearValidators();
        this.inputControl.updateValueAndValidity();
    }

    onChange = (_: any) => {};
    onTouched = () => {};
    /**
     * INFO: add comment
     *
     * @param ids
     */
    setDescribedByIds(ids: string[]): void {}
    static ngAcceptInputType_disabled: BooleanInput;
    static ngAcceptInputType_required: BooleanInput;
    private preventBlur: boolean;
}
