import { createFeatureSelector, createSelector } from '@ngrx/store';
import { patientsAdmisionFeatureKey, PatientsAdmissionState } from '../../entities/patient-admission.state';
import { selectIds, selectEntities, selectAll } from '../reducers';

export const selectPatientsAdmissionState = createFeatureSelector<PatientsAdmissionState>(patientsAdmisionFeatureKey);

export const selectPatientsAdmissionStateIsLoading = createSelector(selectPatientsAdmissionState, x => x.isLoading);
export const selectPatientsAdmissionIds = createSelector(
    selectPatientsAdmissionState,
    selectIds as (state) => string[],
);

export const selectPatientsAdmissionEntities = createSelector(selectPatientsAdmissionState, selectEntities);

export const selectAllPatientsAdmission = createSelector(selectPatientsAdmissionState, selectAll);

export const selectPatientsAdmissionFilter = createSelector(selectPatientsAdmissionState, x => x.filter);

/**
 * Select patient by id
 *
 * @param patientId
 */
export const selectPatientById = (patientId?: string) =>
    createSelector(selectAllPatientsAdmission, patients => patients.filter(p => p.id === patientId)?.shift());
