import { EntityState } from '@ngrx/entity';
import { ReportType } from '@mona/models';

export const reportsFeatureKey = 'reports';

/**
 * Reports State
 */
export interface ReportsState extends EntityState<ReportType> {
    /**
     * selected id
     */
    selectedReportId?: string | null;
    /**
     * error
     */
    error?: any;
    /**
     * loading
     */
    isLoading: boolean;
}
