import { coerceElement } from '@angular/cdk/coercion';
import { Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { EditEventDispatcher, PopoverEditClickOutBehavior } from '@angular/cdk-experimental/popover-edit';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Optional,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    TrackByFunction,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions, RippleRenderer, RippleTarget } from '@angular/material/core';
import { MatMenu } from '@angular/material/menu';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatColumnDef, MatFooterRowDef, MatHeaderRowDef, MatRowDef, MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { RxConcurrentStrategyNames, RxStrategyProvider } from '@rx-angular/cdk/render-strategies';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { isFunction, isNullOrUndefined, PLATFORM, Platform, uiPure } from '@mona/shared/utils';
import { UiDynamicElementConfig } from '../../../forms';
import { UiTableCellContext, UiVHSMatMenuTrigger } from '../../directives';
import {
    cellValueGetterFn,
    UiTableCell,
    UiTableCellMenu,
    UiTableColumn,
    UiTableDataSource,
    UiTableEvent,
    UiTableRow,
} from '../../models';
/**
 * RowWhenPredicate Function
 *
 * compares if rows should be rendered as default for mat-table
 */
export type RowWhenPredicateFn = (row: any) => boolean;

function getPaginatorTranslations(translateService: TranslateService) {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = translateService.instant('matPaginator.itemsPerPageLabel');
    paginatorIntl.firstPageLabel = translateService.instant('matPaginator.firstPageLabel');
    paginatorIntl.lastPageLabel = translateService.instant('matPaginator.lastPageLabel');
    paginatorIntl.nextPageLabel = translateService.instant('matPaginator.nextPageLabel');
    paginatorIntl.previousPageLabel = translateService.instant('matPaginator.previousPageLabel');
    paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        const startIndex = page * pageSize;

        return translateService.instant('matPaginator.rangeLabel', { count: startIndex, length });
    };

    return paginatorIntl;
}

/**
 * Table component that accepts column and row definitions in its content to be registered to the  table. Wraps a material table component for definition and behavior reuse.
 */
@Component({
    selector: 'ui-table',
    templateUrl: './ui-table.component.html',
    styleUrls: ['./ui-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: MatPaginatorIntl, useFactory: getPaginatorTranslations, deps: [TranslateService] }],
})
export class UiTableComponent implements AfterViewInit, OnChanges, OnDestroy {
    /** Reference to columns  */
    @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
    /** Reference to rows  */
    @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<UiTableRow>>;
    /** Reference to rows  */
    @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
    /** Reference to rows  */
    @ContentChildren(MatFooterRowDef) footerRowDefs: QueryList<MatFooterRowDef>;
    /** Reference to table */
    @ViewChild(MatTable) table: MatTable<UiTableRow>;
    /** Reference to CdkScrollable */
    @ViewChild('scrollable') scrollable: ElementRef;
    /** Reference to TemplatePortal */
    @ViewChild('buttonsTemplatePortal') buttonsTemplatePortal: TemplatePortal<any>;
    /** Reference to cell menu */
    @ViewChild('cellMenu') cellMenuRef: MatMenu;
    /** Reference to cell menu */
    @ViewChild('multipleValuesMenu') multipleValuesMenuRef: MatMenu;
    /** Reference to paginator */
    @ViewChild(MatPaginator) paginator: MatPaginator;
    /** Fixed layout */
    @Input() fixedLayout = true;
    /** Column width */
    @Input() defaultColumnWidth = 100;
    /** Column width */
    @Input() columnWidth = 100;
    /** Sticky column width */
    @Input() stickyColumnWidth = 100;
    /** Stick header row */
    @Input() stickyHeader = true;
    /** Columns data  */
    @Input() columns: UiTableColumn[] = [];
    /** Addtional Top Header columns data  */
    @Input() headerColumns: UiTableColumn[] = [];
    /** Data Source */
    @Input() dataSource: UiTableDataSource<UiTableRow>;
    /** Columns tracking function */
    @Input() columnsTrackBy: TrackByFunction<UiTableRow>;
    /** Rows tracking function */
    @Input() rowsTrackBy: TrackByFunction<UiTableRow>;
    /** Column cell TemplateRef to be rendered instead default cell text */
    @Input() columnCellTemplate: TemplateRef<any>;
    /** Editable */
    @Input() editable: boolean;
    /** Is cell clickable, will emit tableEvent */
    @Input() cellClickable = false;
    /** Is cell clickable, will emit tableEvent */
    @Input() cellMenuItems: UiTableCellMenu[];
    /** Is cell clickable, will emit tableEvent */
    @Input() cellMenuTriggerWhenFn: AnyFn;
    /** Cell value getter function - default */
    @Input() cellValueGetter: typeof cellValueGetterFn = cellValueGetterFn;
    /** Editable */
    @Input() cellEditFormConfig: { [key: string]: UiDynamicElementConfig[] };
    /** Editable */
    @Input() cellEditFormConfigGetter: (...args) => UiDynamicElementConfig[];
    /** Editable */
    @Input() editClickOutBehavior: PopoverEditClickOutBehavior = 'submit';
    /** Editable */
    @Input() editIgnoreSubmitUnlessValid = true;
    /** Is row with menu, will emit tableEvent */
    @Input() rowMenuItems: UiTableCellMenu[];
    /** Has Grouped Rows, will handle collapsing rows */
    @Input() hasGroupDataRows = false;
    /** Group cell TemplateRef to be rendered instead default cell text */
    @Input() groupCellTemplate: TemplateRef<any>;
    /** Multiple values cell TemplateRef to be rendered instead default cell text */
    @Input() multipleValuesTemplate: TemplateRef<any>;
    /** Is row clickable, will emit tableEvent */
    @Input() rowClickable = false;
    /** Data row class to be applied to whole row */
    @Input() rowClass = '';
    /** Active column predicate function, compares if rows should be rendered as default for mat-table */
    @Input() rowWhenPredicateFn: RowWhenPredicateFn;
    /** rowClassGetterFn */
    @Input() rowClassGetterFn: AnyFn;
    /** Hide thead if no data */
    @Input() hideTheadIfNoData = false;
    /** No data row to be shown */
    @Input() showNoData = false;
    /** No data config to be transfered to ui-empty-state component */
    @Input() noDataConfig: any = {};
    /** No data row template reference (mat-table functionality) */
    @Input() noDataRowTemplate: TemplateRef<any>;
    /** Is loading */
    @Input() isLoading?: boolean;
    /** Is striped table */
    @Input() isStriped = true;
    /** Is table borderless */
    @Input() isBorderless = false;
    /** Hide header row */
    @Input() showHeaderRow = true;
    /** Name cell TemplateRef to be rendered instead default cell text */
    @Input() nameCellTemplate: TemplateRef<any>;
    /** Name header cell template */
    @Input() nameHeaderCellTemplate: TemplateRef<any>;
    /** Name footer cell template */
    @Input() nameFooterCellTemplate: TemplateRef<any>;
    /** Show pagination  */
    @Input() withPagination = false;
    /** Default page size  */
    @Input() pageSize = 10;
    /** Flag to show if table should be sorted on init */
    @Input() shouldSortOnInit = false;
    /** Sorting order */
    @Input() sortOrder: 'asc' | 'desc';
    /** Sorting field */
    @Input() sortField: string;
    /** Universal table event emitter */
    @Output() tableEvent = new EventEmitter<UiTableEvent>();
    /** Column names array  */
    displayedColumns: string[] = [];
    /** Selected (clicked) row */
    selectedRow = null;

    /** unsubscrive when lyfecycle */
    protected destroy$ = new Subject();
    /** Header columns to headerRowDefs mapper */
    /* prettier-ignore */
    readonly headerColumnsMapper = (headerColumns: UiTableColumn[]) => {
        return headerColumns.map(hc => hc.name);
    }

    /**
     * Constructor
     *
     * @param editEventDispatcher
     * @param rxStrategyProvider
     * @param cdRef
     * @param viewContainerRef
     * @param translateService
     * @param platform
     * @param ngZone
     * @param overlay
     * @param sort
     * @param rippleConfig
     */
    constructor(
        public editEventDispatcher: EditEventDispatcher<any>,
        protected rxStrategyProvider: RxStrategyProvider,
        protected cdRef: ChangeDetectorRef,
        protected viewContainerRef: ViewContainerRef,
        protected translateService: TranslateService,
        @Inject(PLATFORM) public platform: Platform,
        protected ngZone: NgZone,
        protected overlay: Overlay,
        @Optional() protected sort?: MatSort, // `MatSort` is optionally injected,
        @Optional() @Inject(MAT_RIPPLE_GLOBAL_OPTIONS) protected rippleConfig?: RippleGlobalOptions,
    ) {}

    /**
     * If columns were changed, re-calculate visible table columns & re-render
     *
     * @internal
     */
    ngOnChanges({ columns, editable }: SimpleChanges): void {
        if (columns?.currentValue.length || !isNullOrUndefined(editable?.currentValue)) {
            this.onColumnsChanged();
            this.triggerChangeDetection();
        }
    }

    /**
     * Add custom columns & rows to table, update visible columns
     */
    ngAfterViewInit() {
        this.dataSource.sort = this.sort; // TODO: enable sort for external columns
        this.dataSource.paginator = this.paginator;
        if (this.shouldSortOnInit) {
            this.sortTableOnOpen();
        }
        this.registerDefs();
        this.onColumnsChanged();
        this.triggerChangeDetection();
    }

    /** Lifecycle hook */
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Trigger change detection
     *
     * @param strategy "immediate" | "userBlocking" | "normal" | "low" | "idle"
     */
    protected triggerChangeDetection(strategy: RxConcurrentStrategyNames = 'normal'): void {
        this.rxStrategyProvider.scheduleCD(this.cdRef, {
            strategy,
        });
    }

    /**
     * Re-calculate visible columns after rnge changed, Scrolls if needed
     *
     * @param range
     */
    protected onColumnsChanged(range?: any);
    /** @ignore */
    protected onColumnsChanged() {
        this.displayedColumns = [
            ...this.columns.map(c => c.name),
            ...(this.columnDefs?.toArray() || []).map(c => c.name),
            ...(this.rowMenuItems?.length ? ['rowMenuColumn'] : []),
        ];
    }

    /**
     * Detects if row should be rendered as default row, default mat-table functionality
     *
     * @param idx number
     * @param row any
     */
    /* prettier-ignore */
    defaultWhen = (idx: number, row: any): boolean => {
        if (this.rowWhenPredicateFn) {
            return this.rowWhenPredicateFn(row);
        }
        return this.hasGroupDataRows ? !row.isGroup : true;
    }

    /**
     * etects if row should be rendered as collapsible row, additional mat-table functionality
     *
     * @param idx number
     * @param row any
     */
    /* prettier-ignore */
    whenIsGroup = (idx: number, row: any): boolean => {
        if (!this.hasGroupDataRows) {
            return false;
        }
        return !!row.isGroup;
    }

    /**
     * Emits Table event
     *
     * @param sort
     */
    onSortChange(sort: Sort): void {
        this.emitTableEvent({
            action: 'sort',
            sort: { column: sort.active, direction: sort.direction },
        });
    }

    /**
     * Emits Table event
     *
     * @param event
     * @param cellContext
     */
    handleCellClick(event: Event, cellContext: UiTableCellContext): void {
        event.stopPropagation();
        const { row, column, elementRef } = cellContext;
        const target = event.currentTarget as HTMLElement;
        const disabled = isFunction(column.disabled) ? column.disabled(row, column) : column.disabled;
        // console.log('handleCellClick - elementRef', elementRef)

        if (this.cellClickable) {
            // this.selectedCellRef = elementRef.nativeElement;
            // this.selectedCellElement = target;
            if (isFunction(this.cellMenuTriggerWhenFn) && this.cellMenuTriggerWhenFn(row, column)) {
                if (this.multipleValuesTemplate) {
                    this.triggerMultipleValuesClick(elementRef, cellContext);
                } else {
                    this.triggerMenuClick(elementRef, cellContext);
                }
            } else if (!disabled) {
                this.triggerRippleClick(elementRef);
                if (this.editable && this.cellEditFormConfig && this.cellEditFormConfig[row.dataType]) {
                    this.triggerEditClick(elementRef);
                } else {
                    this.emitTableEvent(
                        {
                            action: 'cellClick',
                            data: { column, row, target },
                        },
                        true,
                    );
                }
            }
        }
    }

    /**
     * Emits cell menu event
     *
     * @param item
     * @param cellContext
     * @param menu
     */
    handleCellMenuClick(
        item: UiTableCellMenu,
        { row, column, colIdx, cell, elementRef }: UiTableCellContext,
        menu: MatMenu,
    ): void {
        // open popover if edit click
        if ((this.cellClickable || this.rowMenuItems.length) && item.reason === 'edit') {
            this.triggerEditClick(elementRef?.nativeElement);
        }
        // close menu after click
        if (menu instanceof MatMenu) {
            menu.closed.emit('keydown');
        }
        //emit event
        this.emitTableEvent(
            {
                action: 'cellMenu',
                reason: item.reason,
                data: { column, colIdx, row, cell, target: elementRef?.nativeElement },
            },
            true,
        );
    }

    /**
     * Handle cell edit
     *
     * @param cellContext
     * @param form
     */
    handleCellEdit({ row, column, colIdx }: UiTableCell, form: AbstractControl) {
        if (!form?.valid) {
            return;
        }
        this.emitTableEvent(
            {
                action: 'cellEdit',
                reason: 'submit',
                data: { colIdx, column, row, cell: form.value },
            },
            true,
        );
    }

    /** Emits NoData click event */
    handleNoDataClick() {
        this.emitTableEvent({
            action: 'noDataClick',
        });
    }

    /**
     * emits the rowClickEvent when a row is clicked
     * if clickable is true and selectable is false then select the row
     *
     * @param row
     * @param rowIdx
     * @param event
     */
    handleRowClick(row: any, rowIdx: number, event: Event): void {
        if (this.rowClickable) {
            this.selectedRow = this.selectedRow === row ? null : row;
            // ignoring linting rules here because attribute it actually null or not there

            const srcElement: any = event.srcElement || event.currentTarget;
            const element: HTMLElement = event.target as HTMLElement;
            if (
                srcElement.getAttribute('stopRowClick') === null &&
                element.tagName.toLowerCase() !== 'mat-pseudo-checkbox'
            ) {
                this.emitTableEvent({
                    action: 'rowClick',
                    data: { row, rowIdx },
                });
            }
        }
    }

    /**
     * emits the `groupCellClick` when a cell is clicked
     *
     * @param row
     * @param event
     */
    handleGroupCellClick(row: any, event: Event) {
        this.emitTableEvent({
            action: 'groupCellClick',
            data: { row },
        });
        event.stopPropagation();
    }

    /**
     * Detects if row should add a class
     *
     * @param row any
     */
    rowClassGetter = (row: { values: any }): string | string[] => {
        if (!isFunction(this.rowClassGetterFn)) {
            return '';
        }
        return this.rowClassGetterFn(row) as string | string[];
    };

    /**
     * Check menu item disabled
     *
     * @param item
     * @param cellContext
     */
    @uiPure
    checkMenuItemDisabled(item: UiTableCellMenu, { row, column }: UiTableCellContext): boolean {
        if (isFunction(item.disabled)) {
            return item.disabled(row, column);
        }
        return false;
    }

    /**
     * Check menu item disabled
     *
     * @param item
     * @param cellContext
     */
    @uiPure
    checkMenuItemHidden(item: UiTableCellMenu, { row, column, cell }: UiTableCellContext): boolean {
        if (isFunction(item.hidden)) {
            return item.hidden(row, column, cell);
        }
        return false;
    }

    /** Register any custom column && row defs to the table */
    protected registerDefs(): void {
        this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
        this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
        this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
        this.footerRowDefs.forEach(footerRowDef => this.table.addFooterRowDef(footerRowDef));
    }

    /**
     * Trigger menu click - optimized for performance
     * by creating dynamic {@link MatMenuTrigger} and assigning props & elemntref once
     * instead of rendering trigger for each `<td>` element
     *
     * @param element
     */
    private triggerRippleClick(element: ElementRef): void {
        const rippleTarget: RippleTarget = {
            /** Configuration for ripples that are launched on pointer down. */
            rippleConfig: {
                persistent: true,
                centered: true,
                terminateOnPointerUp: true,
                animation: { enterDuration: 250, exitDuration: 250 },
            },
            /** Whether ripples on pointer down should be disabled. */
            rippleDisabled: false,
        };
        const rippleRenderer = new RippleRenderer(rippleTarget, this.ngZone, element, this.platform);
        rippleRenderer['_containerElement'] = rippleRenderer['_containerElement'] || element.nativeElement; // fails in jest
        rippleRenderer.fadeInRipple(2, 2, rippleTarget.rippleConfig);
        rippleRenderer.fadeOutAll();
    }

    /**
     * Trigger menu click - optimized for performance
     * by creating dynamic {@link MatMenuTrigger} and assigning props & elemntref once
     * instead of rendering trigger for each `<td>` element
     *
     * @param element
     * @param cellContext
     */
    private triggerMenuClick(element: ElementRef, cellContext: UiTableCellContext): void {
        const ref: ComponentRef<UiVHSMatMenuTrigger> = this.viewContainerRef.createComponent(UiVHSMatMenuTrigger);
        ref.instance['_element'] = element;
        ref.instance.menu = this.cellMenuRef;
        ref.instance.menuData = cellContext;
        ref.instance.restoreFocus = false;
        ref.instance.toggleMenu();
        ref.instance.menuClosed.pipe(take(1)).subscribe(() => ref.destroy());
    }

    /**
     * @param element
     * @param cellContext
     */
    private triggerMultipleValuesClick(element: ElementRef, cellContext: UiTableCellContext): void {
        // const overlayRef = this.overlay.create({width: 100, height: 100});
        // const userProfilePortal = new ComponentPortal(UserProfile);
        // overlayRef.attach(this.multipleValuesMenuRef);

        const ref: ComponentRef<UiVHSMatMenuTrigger> = this.viewContainerRef.createComponent(UiVHSMatMenuTrigger);
        ref.instance['_element'] = element;
        ref.instance.menu = this.multipleValuesMenuRef;
        ref.instance.menuData = cellContext;
        ref.instance.restoreFocus = false;
        ref.instance.menu.overlapTrigger = true;
        // ref.instance._openedBy = 'mouse';
        ref.instance.toggleMenu();
        ref.instance.menuClosed.pipe(take(1)).subscribe(() => ref.destroy());
    }

    /**
     * Trigger edit click
     *
     * @param element
     */
    private triggerEditClick(element: ElementRef): void {
        this.editEventDispatcher.editing.next(coerceElement(element));
    }

    /**
     * Emit table event
     *
     * @param tableEvent
     * @param inZone
     */
    private emitTableEvent(tableEvent: UiTableEvent, inZone = false) {
        if (inZone) {
            this.ngZone.run(() => this.tableEvent.emit(tableEvent));
        } else {
            this.tableEvent.emit(tableEvent);
        }
    }

    /**
     * Emit table event
     */
    private sortTableOnOpen(): void {
        this.dataSource.sort.active = this.sortField;
        this.dataSource.sort.direction = this.sortOrder;
        this.dataSource.sort.sortChange.emit();
    }
}
