import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouteReuseStrategy, RouterModule } from '@angular/router';
import { CustomReuseStrategy } from '@mona/core';
import { MONA_ROUTES } from './routes';
/**
 * AppRoutingModule
 */
@NgModule({
    imports: [
        RouterModule.forRoot(MONA_ROUTES, {
            useHash: true,
            initialNavigation: 'disabled',
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'disabled',
            enableTracing: coerceBooleanProperty(localStorage['enableTracing']),
        }),
    ],
    exports: [RouterModule],
    providers: [
        //
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    ],
})
export class AppRoutingModule {}
