import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { isBoolean } from '@mona/shared/utils';

/**
 * Directive to toggle visibility of an element
 *
 * Uses the `display: none` style to hide the element instead of
 * the `d-none` class or 'cdk-visually-hidden'
 * because they do not hide form elements from being navigated with tab key
 *
 * @example
 * <div [visibleIf]="condition">Visible</div>
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[visibleIf]',
})
export class VisibleIfDirective {
    @Input() set visibleIf(value: boolean) {
        if (isBoolean(value) && value) {
            this.renderer.removeStyle(this.elRef.nativeElement, 'display');
        } else {
            this.renderer.setStyle(this.elRef.nativeElement, 'display', 'none');
        }
    }
    /**
     * @param elRef
     * @param renderer
     * @hideconstructor
     */
    constructor(private elRef: ElementRef, private renderer: Renderer2) {}
}
