/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApiModels } from '@mona/api';
import { FhirConfig, FhirConfigApiModel, FhirConfigEntry } from '@mona/models';

/**
 * Transforms API fhir config
 *
 * @param apiFhirConfig
 */
export const transformApiFhirConfig = (apiFhirConfig: FhirConfigApiModel): FhirConfig => {
    return {
        diagnoses: transformApiFhirConfigEntry(apiFhirConfig.diagnoses!),
        labValues: transformApiFhirConfigEntry(apiFhirConfig.lab_values!),
        medication: transformApiFhirConfigEntry(apiFhirConfig.medication!),
    };
};

/**
 * Transforms API fhir config entry
 *
 * @param apiFhirConfigEntry ApiModels.FhirConfigEntry
 */
export const transformApiFhirConfigEntry = (apiFhirConfigEntry: AnyObject): FhirConfigEntry => {
    return apiFhirConfigEntry
        ? {
              displayNameSingular: apiFhirConfigEntry['display_name_singular'],
              displayNamePlural: apiFhirConfigEntry['display_name_plural'],
          }
        : ({} as FhirConfigEntry);
};
