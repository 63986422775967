import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * SVG logo component with ability to override fill (color)
 */
@Component({
    selector: 'mona-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
    /** To switch between 2 types: Mona as 'head' or Mona as 'text' */
    @Input() type: 'head' | 'text' = 'head';
}
