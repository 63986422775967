/* eslint-disable @typescript-eslint/member-ordering */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Optional,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { pick, takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { isEmptyView } from '@mona/ui/utils';
import { Animations } from '../../../../animations';
import { UiMessagePriorityEnum, UiMessageType } from '../../models';
import { MessageService } from '../../services';

/**
 * UiMessage container
 */
@Directive({
    selector: '[uiMessageContainer]',
})
export class UiMessageContainerDirective {
    /** @ignore */
    constructor(public viewContainer: ViewContainerRef) {}
}

/**
 * UiMessageComponent
 */
@TakeUntilDestroy
@Component({
    selector: 'ui-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Animations.collapseAnimation],
})
export class UiMessageComponent implements OnInit, AfterViewInit {
    private _opened = true;
    private _hidden = false;
    private _animating = false;
    private _initialized = false;

    @ViewChild(UiMessageContainerDirective, { static: true }) _childElement?: UiMessageContainerDirective;
    @ViewChild(TemplateRef) _template!: TemplateRef<any>;
    @ViewChild('action', { static: true }) actionEl: ElementRef;

    isEmpty = (el: ElementRef): boolean => isEmptyView(el);

    /**
     * Binding host class
     */
    @HostBinding('class.ui-message') cmpClass = true;

    /**
     * Binding host to uiCollapse animation
     */
    @HostBinding('@collapse')
    get collapsedAnimation(): any {
        return { value: !this._opened, duration: 100 };
    }

    /**
     * Detach element when close animation is finished to set animating state to false
     * hidden state to true and detach element from DOM
     */
    @HostListener('@collapse.done')
    animationDoneListener(): void {
        //  && !this.snackBarRef
        if (!this._opened) {
            this._hidden = true;
            this._detach();
        }
        this._animating = false;
        this.cdr.markForCheck();
    }

    /**
     * Message index number (if in a list)
     */
    @Input() index: number;

    private _id = Date.now().toString();
    /** Attribute id */
    get id(): string {
        return this._id;
    }
    @Input() set id(value: string) {
        if (value) {
            this._id = value;
        } else {
            this._id = Date.now().toString();
        }
    }
    /** Attribute HostBinding */
    @HostBinding('attr.id') @HostBinding('attr.data-testid') get _attr_id(): string {
        return this._id;
    }

    /**
     * If message is closable.
     */
    @Input() closable = true;

    private _type: UiMessageType = 'info';

    @Input() set type(_type: UiMessageType) {
        if (_type) {
            this._type = _type;
            Object.assign(this, pick(this.service.getMessagePropsFromType(_type), 'icon', 'title'));
            this.renderer.removeClass(this.elRef.nativeElement, `ui-message--warn`);
            this.renderer.addClass(this.elRef.nativeElement, `ui-message--${_type}`);
            this.cdr.markForCheck();
        }
    }
    /**
     * Sets the type of the message.
     */
    get type(): UiMessageType {
        return this._type;
    }
    /**
     * Sets the priority of the message.
     */
    @Input() priority?: UiMessagePriorityEnum;
    /**
     * Sets the title of the message.
     */
    @Input() title?: string;

    /**
     * Sets the description of the message.
     */
    @Input() description?: string;

    /**
     * Sets the action title of the message.
     */
    @Input() action?: string;

    /**
     * The icon to be displayed before the title.
     * Defaults to `info_outline` icon
     */
    @Input() icon?: string = 'info_outline';

    /**
     * opened?: boolean
     *
     * Shows or hiddes the message depending on its value.
     * Defaults to 'true'.
     */
    @Input() set opened(opened: boolean) {
        if (this._initialized) {
            if (opened) {
                this.open();
            } else {
                this.close();
            }
        } else {
            this._opened = opened;
        }
    }
    /**
     * opened?: boolean
     */
    get opened(): boolean {
        return this._opened;
    }

    @Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    /**
     * Hidden
     */
    set hidden(value: boolean) {
        if (value !== this._hidden) {
            this._hidden = value;
            this.cdr.markForCheck();
        }
    }
    /**
     * Binding Hidden host class
     */
    @HostBinding('class.ui-message--hidden') get hidden() {
        return this._hidden;
    }

    /**
     * Is current message
     */
    get isCurrent(): boolean {
        return this.service?.currentIndex === this.index;
    }

    /**
     * Creates an instance of UiMessageComponent.
     *
     * @param {Renderer2} renderer
     * @param {ChangeDetectorRef} cdr
     * @param {ElementRef} elRef
     * @param {MessageService} service
     * @param snackBarRef
     */
    constructor(
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private elRef: ElementRef,
        private service: MessageService,
        @Optional() private snackBarRef: MatSnackBarRef<any>,
    ) {}

    /**
     * Subscribe to changes in lifecycle
     */
    ngOnInit() {
        if (this.service && !this.snackBarRef) {
            this.service.changes.pipe(takeUntilDestroy(this)).subscribe(index => {
                this.hidden = !this.isCurrent;
            });
        }
    }

    /**
     * Initializes the component and attaches the content.
     */
    ngAfterViewInit(): void {
        Promise.resolve(undefined).then(() => {
            if (this._opened) {
                this._attach();
            }
            this._initialized = true;
        });
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, jsdoc/require-jsdoc, @typescript-eslint/no-empty-function
    ngOnDestroy(): void {}

    /**
     * Renders the message on screen
     * Validates if there is an animation currently and if its already opened
     */
    open(): void {
        if (!this._opened && !this._animating) {
            this._opened = true;
            this._attach();
            if (this.service) {
                this.service.open();
            }
            this._startAnimationState();
            this.openedChange.emit(true);
        }
    }

    /**
     * Removes the message content from screen.
     * Validates if there is an animation currently and if its already closed
     */
    close(): void {
        if (this._opened && !this._animating) {
            this._opened = false;
            if (this.service) {
                this.service.close(this.isCurrent);
            }
            this._startAnimationState();
            this.openedChange.emit(false);
        }
    }

    /**
     * Toggles between open and close depending on state.
     */
    toggle(): void {
        if (this._opened) {
            this.close();
        } else {
            this.open();
        }
        this.openedChange.emit(this._opened);
    }

    /**
     * Method to set the state before starting an animation
     */
    private _startAnimationState(): void {
        this._animating = true;
        this._hidden = false;
        this.cdr.markForCheck();
    }

    /**
     * Method to attach template to DOM
     */
    private _attach(): void {
        this._childElement?.viewContainer.createEmbeddedView(this._template);
        this.cdr.markForCheck();
    }

    /**
     * Method to detach template from DOM
     */
    private _detach(): void {
        this._childElement?.viewContainer.clear();
        this.cdr.markForCheck();
    }
}
