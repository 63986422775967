import { EntriesIntervalNames, ENTRIES_INTERVAL, EXTENDED_INTERVAL_TIMES_MAP, INTERVAL_TIMES_MAP } from '../models';

/**
 * Adds or substracts time intervval to/from provided date, plain javascript
 *
 * @param selectedInterval
 * @param date
 * @param add - add or substract
 * @param ext - use extended itervals
 * @param isRounded
 */
export function addOrSubstractInterval(
    selectedInterval: EntriesIntervalNames,
    date: Date,
    add = true,
    ext = false,
    isRounded = false,
) {
    const resultDate = new Date(date);
    const sign = add ? 1 : -1;
    // arguments for time manipulation depend on interval
    const interval = ext ? EXTENDED_INTERVAL_TIMES_MAP.get(selectedInterval) : INTERVAL_TIMES_MAP.get(selectedInterval);

    switch (selectedInterval) {
        case ENTRIES_INTERVAL.MINUTES_5:
            resultDate.setMinutes(resultDate.getMinutes() + (isRounded ? interval[0] : 0) * sign);
            break;
        case ENTRIES_INTERVAL.MINUTES_30:
            resultDate.setMinutes(resultDate.getMinutes() + (isRounded ? interval[0] : 0) * sign);
            break;
        case ENTRIES_INTERVAL.HOUR_1:
        case ENTRIES_INTERVAL.HOUR_6:
            resultDate.setHours(resultDate.getHours() + (isRounded ? interval[0] : 0) * sign);
            break;
        case ENTRIES_INTERVAL.DAY_1:
            resultDate.setDate(resultDate.getDate() + interval[0] * sign);
            break;
    }
    return resultDate;
}

/**
 * Add milisecond
 *
 * @param value Date
 * @param ms
 */
export function addMilisecond(value: Date, ms = 1): Date {
    const newValue = new Date(value);
    newValue.setMilliseconds(ms);
    return newValue;
}

/**
 * Add second
 *
 * @param value Date
 * @param ms
 */
export function addSecond(value: Date, ms = 1): Date {
    value.setSeconds(ms);
    return value;
}
