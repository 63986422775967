import { map, startWith, tap } from 'rxjs/operators';
import { takeUntilDestroy } from '@mona/shared/utils';
import { CVA } from '../models';
import { extractDirtyChanges, extractTouchedChanges, patchObjectMethodWith } from './extract-control-changes';

type CVAType = Pick<CVA, 'ngControl' | 'viewModel' | 'cdRef' | 'componentDestroy'>;

/**
 * Syncs the outer and inner controls for validity, errors, dirty, and touched states.
 * Used in components that implement ControlValueAccessor
 * @param cva - partial {@link CVA} object
 */
export function syncOuterToInnerErrors(cva: CVAType) {
    if (!cva.ngControl || !cva.ngControl.statusChanges) {
        return;
    }

    cva.ngControl.statusChanges
        .pipe(
            startWith(cva.ngControl.status),
            map(() => cva.ngControl.errors),
            tap(errors => {
                cva.viewModel.setErrors(errors, { emitEvent: false });
                cva.cdRef.markForCheck();
            }),
            takeUntilDestroy(cva),
        )
        .subscribe();
}

/**
 * Syncs the outer and inner controls for validity, errors, dirty, and touched states.
 * Used in components that implement ControlValueAccessor
 * @param cva - partial {@link CVA} object
 */
export function syncOuterAndInnerTouched(cva: CVAType) {
    if (!cva.ngControl.control) {
        return;
    }

    const outerControl = cva.ngControl.control;
    const touched$ = extractTouchedChanges(outerControl);

    touched$
        .pipe(
            startWith(outerControl.touched),
            tap(isTouched => {
                if (isTouched) {
                    cva.viewModel.markAsTouched({ onlySelf: true });
                } else {
                    cva.viewModel.markAsUntouched({ onlySelf: true });
                }
                cva.cdRef.markForCheck();
            }),
            takeUntilDestroy(cva),
        )
        .subscribe();

    // inner to outer
    patchObjectMethodWith(cva.viewModel, 'markAsTouched', args => {
        if (!args?.onlySelf) {
            outerControl.markAsTouched();
        }
    });
}

/**
 * Syncs the outer and inner controls for validity, errors, dirty, and touched states.
 * Used in components that implement ControlValueAccessor
 * @param cva - partial {@link CVA} object
 */
export function syncOuterAndInnerDirty(cva: CVAType) {
    if (!cva.ngControl.control) {
        return;
    }

    const outerControl = cva.ngControl.control;
    const dirty$ = extractDirtyChanges(outerControl);

    dirty$
        .pipe(
            startWith(outerControl.dirty),
            tap(isDirty => {
                if (isDirty) {
                    cva.viewModel.markAsDirty({ onlySelf: true });
                } else {
                    cva.viewModel.markAsPristine({ onlySelf: true });
                }
                cva.cdRef.markForCheck();
            }),
            takeUntilDestroy(cva),
        )
        .subscribe();

    // inner to outer
    patchObjectMethodWith(cva.viewModel, 'markAsDirty', args => {
        if (!args?.onlySelf) {
            outerControl.markAsDirty();
        }
    });
}
