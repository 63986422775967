/* eslint-disable no-empty, jsdoc/require-jsdoc */
import { environment, MONA_CONFIG_FILENAME } from '@environment';
import Ajv, { ValidateFunction as AjvValidateFunction } from 'ajv';
import ajvFormats from 'ajv-formats';
import { JSONSchema } from 'json-schema-typed';
import { merge } from 'lodash/fp';
import { BehaviorSubject } from 'rxjs';
import * as semver from 'semver';
import { get as _get, isNullOrUndefined, isUndefined, set as _set, unset as _unset } from '@mona/shared/utils';
import { BeforeEachMigrationCallback, ConfigOptions, Migrations } from './config.types';

const createPlainObject = <T = Record<string, unknown>>(): T => {
    return Object.create(null);
};

const checkValueType = (key: string, value: unknown): void => {
    const nonJsonTypes = new Set(['undefined', 'symbol', 'function']);

    const type = typeof value;

    if (nonJsonTypes.has(type)) {
        throw new TypeError(
            `Setting a value of type \`${type}\` for key \`${key}\` is not allowed as it's not supported by JSON`,
        );
    }
};

const INTERNAL_KEY = '__internal__';
const MIGRATION_KEY = `${INTERNAL_KEY}.migrations.version`;

/**
 * General config handling
 *
 * @class Config
 * @implements {Iterable<[keyof T, T[keyof T]]>}
 * @template T
 */
export class Config<T extends Record<string, any> = Record<string, unknown>>
    implements Iterable<[keyof T, T[keyof T]]>
{
    /** Conf `store` */
    private readonly _store = new BehaviorSubject<T>(null);
    /** Conf `store` as Observable */
    readonly store$ = this._store.asObservable();
    readonly _validator?: AjvValidateFunction;
    readonly _options: Readonly<Partial<ConfigOptions<T>>>;
    readonly _defaultValues: Partial<T> = {};

    constructor(partialOptions: Readonly<Partial<ConfigOptions<T>>> = {}) {
        const options: Partial<ConfigOptions<T>> = {
            configName: MONA_CONFIG_FILENAME,
            clearInvalidConfig: true,
            ...partialOptions,
        };

        this._options = options;

        if (options.schema) {
            if (typeof options.schema !== 'object') {
                throw new TypeError('The `schema` option must be an object.');
            }

            const ajv = new Ajv({
                allErrors: true,
                useDefaults: true,
            });
            ajvFormats(ajv);

            const schema: JSONSchema = {
                type: 'object',
                properties: options.schema,
            };

            this._validator = ajv.compile(schema);

            for (const [key, value] of Object.entries<JSONSchema>(options.schema)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (value?.default) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this._defaultValues[key as keyof T] = value.default;
                }
            }
        }

        if (options.defaults) {
            this._defaultValues = {
                ...this._defaultValues,
                ...options.defaults,
            };
        }

        const store = Object.assign(createPlainObject(), this._defaultValues);
        this._validate(store);
        this._store.next(store as T);

        if (options.migrations) {
            if (!options.projectVersion) {
                options.projectVersion = semver.valid(semver.coerce(environment.version));
            }

            if (!options.projectVersion) {
                throw new Error('Project version could not be inferred. Please specify the `projectVersion` option.');
            }

            this._migrate(options.migrations, options.projectVersion, options.beforeEachMigration);
        }
    }

    /**
     * Get nested config
     *
     * @param path string separated with dots or array of keys
     * @param defaultValue - The default value if the item does not exist.
     */
    get<K extends Path<T>>(path: K, defaultValue?: unknown): PathValue<T, K> {
        const result = _get(this.store, path);
        // eslint-disable-next-line curly
        if (isUndefined(result)) return defaultValue as any;
        return result;
    }

    /**
     * Set an item or multiple items at once.
     *
     * @param {key|object} - You can use [dot-notation](https://github.com/sindresorhus/dot-prop) in a key to access nested properties. Or a hashmap of items to set at once.
     * @param value - Must be JSON serializable. Trying to set the type `undefined`, `function`, or `symbol` will result in a `TypeError`.
     */
    set<Key extends keyof T>(key: Key, value?: T[Key]): void;
    set(key: string, value: unknown): void;
    set(object: DeepPartial<T>): void;
    set<Key extends keyof T>(key: DeepPartial<T> | Key | string, value?: T[Key] | unknown): void {
        if (typeof key !== 'string' && typeof key !== 'object') {
            throw new TypeError(`Expected \`key\` to be of type \`string\` or \`object\`, got ${typeof key}`);
        }

        if (typeof key !== 'object' && value === undefined) {
            throw new TypeError('Use `delete()` to clear values');
        }

        if (this._containsReservedKey(key)) {
            throw new TypeError(
                `Please don't use the ${INTERNAL_KEY} key, as it's used to manage this module internal operations.`,
            );
        }

        const { store } = this;

        if (typeof key === 'object') {
            Object.entries(key).forEach(([k, v]) => checkValueType(k, v));
            this.store = merge(store, key);
        } else {
            checkValueType(key, value);
            this.store = _set(store, key, value);
        }
    }

    /**
     * Check if an item exists.
     *
     * @param key - The key of the item to check.
     */
    has<Key extends keyof T>(key: Key | string): boolean {
        return !!_get(this.store, key as string);
    }

    /**
     * Reset items to their default values, as defined by the `defaults` or `schema` option.
     *
     *	@see `clear()` to reset all items.
     *	@param keys - The keys of the items to reset.
     */
    reset<Key extends keyof T>(...keys: Key[]): void {
        for (const key of keys) {
            if (!isNullOrUndefined(this._defaultValues[key])) {
                this.set(key, this._defaultValues[key]);
            }
        }
    }

    /**
     * Delete an item.
     *
     *	@param path - The key of the item to delete.
     */
    delete<K extends Path<T>>(path: K): void {
        const { store } = this;
        this.store = _unset(store, path) as T;
    }

    /**
     * Delete all items.
     *
     *	This resets known items to their default values, if defined by the `defaults` or `schema` option.
     */
    clear(): void {
        this.store = createPlainObject();

        for (const key of Object.keys(this._defaultValues)) {
            this.reset(key);
        }
    }

    get size(): number {
        return Object.keys(this.store).length;
    }

    get store(): T {
        try {
            const data = this._store.getValue();
            this._validate(data);
            return Object.assign(createPlainObject(), data);
        } catch (error: any) {
            if (error?.code === 'ENOENT') {
                return createPlainObject();
            }

            if (this._options.clearInvalidConfig && error.name === 'SyntaxError') {
                return createPlainObject();
            }

            throw error;
        }
    }

    set store(value: T) {
        this._validate(value);
        this._store.next(value);
    }

    *[Symbol.iterator](): IterableIterator<[keyof T, T[keyof T]]> {
        for (const [key, value] of Object.entries(this.store)) {
            yield [key, value];
        }
    }

    /**
     * Function to serialize the config object to a UTF-8 string when writing the config file.
     *
     *	You would usually not need this, but it could be useful if you want to use a format other than JSON.
     *
     * @param value
     * @default value => JSON.stringify(value, null, '\t')
     */
    serialize: (value: T) => string = value => JSON.stringify(value, undefined, '\t');
    /**
     * Function to deserialize the config object from a UTF-8 string when reading the config file.
     *
     *	You would usually not need this, but it could be useful if you want to use a format other than JSON.
     *
     * @param value
     * @default JSON.parse
     */
    deserialize: (text: string) => T = value => JSON.parse(value);

    private _validate(data: T | unknown): void {
        if (!this._validator) {
            return;
        }

        const valid = this._validator(data);
        if (valid || !this._validator.errors) {
            return;
        }

        const errors = this._validator.errors.map(
            ({ instancePath, message = '' }) => `\`${instancePath.slice(1)}\` ${message}`,
        );
        throw new Error('Config schema violation: ' + errors.join('; '));
    }

    private _migrate(
        migrations: Migrations<T>,
        versionToMigrate: string,
        beforeEachMigration?: BeforeEachMigrationCallback<T>,
    ): void {
        let previousMigratedVersion: string;
        try {
            previousMigratedVersion = _get(this.store, MIGRATION_KEY as any) || '0.0.0';
        } catch (e) {
            previousMigratedVersion = '0.0.0';
        }

        const newerVersions = Object.keys(migrations).filter(candidateVersion =>
            this._shouldPerformMigration(candidateVersion, previousMigratedVersion, versionToMigrate),
        );

        let storeBackup = { ...this.store };

        for (const version of newerVersions) {
            try {
                if (beforeEachMigration) {
                    beforeEachMigration(this, {
                        fromVersion: previousMigratedVersion,
                        toVersion: version,
                        finalVersion: versionToMigrate,
                        versions: newerVersions,
                    });
                }

                const migration = migrations[version];
                migration(this);

                const store = _set(this.store, MIGRATION_KEY, version);
                this._store.next(store);

                previousMigratedVersion = version;
                storeBackup = { ...this.store };
            } catch (error) {
                this.store = storeBackup;

                throw new Error(
                    `Something went wrong during the migration! Changes applied to the store until this failed migration will be restored. ${
                        error as string
                    }`,
                );
            }
        }

        if (
            this._isVersionInRangeFormat(previousMigratedVersion) ||
            !semver.eq(previousMigratedVersion, versionToMigrate)
        ) {
            const store = _set(this.store, MIGRATION_KEY, versionToMigrate);
            this._store.next(store);
        }
    }

    private _containsReservedKey(key: string | DeepPartial<T>): boolean {
        if (typeof key === 'object') {
            const firsKey = Object.keys(key)[0];

            if (firsKey === INTERNAL_KEY) {
                return true;
            }
        }

        if (typeof key !== 'string') {
            return false;
        }

        if (key.startsWith(`${INTERNAL_KEY}.`)) {
            return true;
        }

        return false;
    }

    private _isVersionInRangeFormat(version: string): boolean {
        return semver.clean(version) === null;
    }

    private _shouldPerformMigration(
        candidateVersion: string,
        previousMigratedVersion: string,
        versionToMigrate: string,
    ): boolean {
        if (this._isVersionInRangeFormat(candidateVersion)) {
            if (previousMigratedVersion !== '0.0.0' && semver.satisfies(previousMigratedVersion, candidateVersion)) {
                return false;
            }

            return semver.satisfies(versionToMigrate, candidateVersion);
        }

        if (semver.lte(candidateVersion, previousMigratedVersion)) {
            return false;
        }

        if (semver.gt(candidateVersion, versionToMigrate)) {
            return false;
        }

        return true;
    }
}
