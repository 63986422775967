import { Pipe, PipeTransform } from '@angular/core';

/**
 * Typed mapping function.
 */
export type UiMapper<T, G> = (item: T, ...args: any[]) => G;

/**
 * Pipe to transform a value with a function
 */
@Pipe({ name: 'uiMapper' })
export class UiMapperPipe implements PipeTransform {
    /**
     * Maps object to an arbitrary result through a mapper function
     *
     * @param value an item to transform
     * @param mapper a mapping function
     * @param args arbitrary number of additional arguments
     */
    transform<T, G>(value: T, mapper: UiMapper<T, G>, ...args: any[]): G {
        return mapper(value, ...args);
    }
}
