<div class="add-prescription-bar mat-elevation-z4">
    <button
        #button
        mat-flat-button
        color="primary"
        class="add-prescription-bar__add"
        (click)="emitAdd.emit()"
        data-testid="btn-add-prescription"
        uiDisableWhen
        checkPermission="prescription_medication_edit"
    >
        <mat-icon class="app-procedure-prescriptions__add">add</mat-icon>
        <span>{{ 'apps.patient.prescriptions.addPrescription.' + entityType + '.title' | translate }}</span>
    </button>
    <div
        class="add-prescription-bar__confirm"
        [ngClass]="button.disabled ? 'bg-white' : hasChanges ? 'bg-accent' : 'bg-success'"
    >
        <ng-container [ngSwitch]="hasChanges">
            <ng-container *ngSwitchCase="true">
                <mat-checkbox
                    *ngIf="hasChanges"
                    color="primary"
                    uiDisableWhen
                    class="add-prescription-bar__checkbox"
                    [checkPermission]="['prescription_medication_edit', 'prescription_medication_approval']"
                    (click)="onConfirm($event)"
                    data-testid="cb-confirm"
                >
                    {{ getTextByEntityType('confirmMessage') | translate }}
                </mat-checkbox>
                <span class="float-right">
                    {{ getTextByEntityType('confirmHint') | translate }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="false">
                <mat-icon class="add-prescription-bar__icon" *ngIf="!hasChanges">done_all</mat-icon>
                {{ getTextByEntityType('allChangesConfirmed') | translate }}
            </ng-container>
        </ng-container>
    </div>
</div>
