import { OnInit, Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatRadioGroup } from '@angular/material/radio';
import { Observable } from 'rxjs';
import { compareDeepEqual, FilterableDataSource, isFunction } from '@mona/shared/utils';
import { UiDynamicElementConfig } from '../../models';

/**
 * UiDynamicRadioComponent
 */
@Component({
    selector: 'ui-dynamic-radio',
    styleUrls: ['./dynamic-radio.component.scss'],
    templateUrl: './dynamic-radio.component.html',
})
export class UiDynamicRadioComponent implements OnInit {
    control: UntypedFormControl;

    label = '';

    valueKey = 'value';

    hint = '';

    name = '';

    required: boolean = undefined;

    dataSource: FilterableDataSource<any> = undefined;

    selectionChange: AnyFn = undefined;

    multiple: boolean = undefined;

    placeholder = '';

    customClass = '';

    vertical: boolean = undefined;

    @ViewChild(MatRadioGroup, { static: true }) radioGroup: MatRadioGroup;

    /** Lifecycle */
    ngOnInit(): void {
        this.radioGroup['_updateSelectedRadioFromValue'] = _updateSelectedRadioFromValue.bind(this.radioGroup);
    }

    /**
     * INFO: add comment
     *
     * @param selection
     */
    onChange(selection: any) {
        if (isFunction(this.selectionChange)) {
            this.selectionChange(selection[this.valueKey] || selection);
        }
    }
}

/** Updates the `selected` radio button from the internal _value state. */
function _updateSelectedRadioFromValue() {
    // If the value already matches the selected radio, do nothing.
    const isAlreadySelected = this._selected !== null && compareDeepEqual(this._selected.value, this._value);
    if (this._radios && !isAlreadySelected) {
        this._selected = null;
        this._radios.forEach(radio => {
            radio.checked = compareDeepEqual(this.value, radio.value);
            if (radio.checked) {
                this._selected = radio;
            }
        });
    }
}
