import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Encounter, EncounterHistoryEntry } from '@mona/models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { transformApiPatientHistory } from '../../../../data-access-changelog/src/lib/infrastructure/transforms/change-log.transforms';

/**
 * API abstraction layer for the patient histories API
 */
@Injectable({
    providedIn: 'root',
})
export class PatientHistoriesApi {
    apiBase = '/pdms/patient-histories/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}
    /**
     
    /**
     * Get patient history
     *
     * @param encounterId EntityId<Encounter>
     */
    getPatientHistories(encounterId: EntityId<Encounter>): Observable<EncounterHistoryEntry[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (encounterId) {
            params.encounter_id = `${encounterId}`;
        }

        return this.http
            .get<ApiModels.PatientHistory[]>(this.apiBase, {
                params,
            })
            .pipe(
                map(histories => {
                    return histories.map<any>(ApiModels.PatientHistory.DTO.toModel);
                }),
                catchError(error => of([])),
            );
    }

    /**
     * Get patient history
     *
     * @param historyId EntityId<EncounterHistoryEntry>
     */
    getPatientHistory(historyId: EntityId<EncounterHistoryEntry>): Observable<EncounterHistoryEntry> {
        return this.http
            .get<ApiModels.PatientHistory>(`${this.apiBase}${historyId}/`)
            .pipe(map(apiPatientHistories => transformApiPatientHistory(apiPatientHistories)));
    }
}
