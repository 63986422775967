// tslint:disable: component-selector no-inputs-metadata-property no-host-metadata-property
import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * The loading overlay component. Implemented by {##link UiLoadingOverlayDirective}
 *
 * @example
 * See {##link UiLoadingOverlayDirective}
 */
@Component({
    selector: 'ui-loading-overlay',
    styleUrls: ['./loading-overlay.component.scss'],
    inputs: ['color', 'diameter'],
    host: {
        role: 'progressbar',
    },
    templateUrl: './loading-overlay.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'uiLoadingOverlay',
})
export class UiLoadingOverlayComponent {
    /** Color */
    color!: string;
    /** Diameter */
    diameter!: number;
    /** Overlay class */
    overlayClass!: string;
}
