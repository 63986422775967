import { ApiModels } from '@mona/api';
import { VentilationMode } from '@mona/models';

/**
 * Transforms api ventilation mode
 * @param apiVentilationMode VentilationParameter
 */
export const transformApiVentilationMode = (apiVentilationMode: ApiModels.VentilationMode): VentilationMode => {
    return {
        id: apiVentilationMode.code,
        code: apiVentilationMode.code,
        displayName: apiVentilationMode.display_name,
    };
};

/**
 * Transform api ventilation modes
 * @param apiVentilationModes ApiModels.PdmsVentilationParameter[]
 */
export const transformApiVentilationModes = (apiVentilationModes: ApiModels.VentilationMode[]): VentilationMode[] => {
    return apiVentilationModes.map(apiVentilationMode => transformApiVentilationMode(apiVentilationMode));
};
