import { ApiModels } from '@mona/api';
import {
    NotificationActionType,
    NotificationNavigateAction,
    NotificationResolution,
    MonaNotification,
} from '@mona/models';

/**
 * Transforms API notification
 *
 * @param apiNotification ApiModels.Notification
 */
export const transformApiNotification = (apiNotification: ApiModels.Notification): MonaNotification => {
    return {
        id: apiNotification.id,
        streamId: apiNotification.stream_id,
        source: apiNotification.source,
        title: apiNotification.title,
        text: apiNotification.text,
        actionType: apiNotification.action_type as NotificationActionType,
        navigateAction: apiNotification.navigate_action as NotificationNavigateAction,
    };
};

/**
 * Transforms API notification
 *
 * @param apiNotifications ApiModels.Notification[]
 */
export const transformApiNotifications = (apiNotifications: ApiModels.Notification[]): MonaNotification[] => {
    return apiNotifications.map(apiNotification => transformApiNotification(apiNotification));
};
