import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { fromTaskList, TaskListEffects, DailyGoalsEffects } from './state';
import { taskListReducerMap, taskListStateKey } from './state/reducers';

/** DataAccessTaskListModule */
@NgModule({
    imports: [
        StoreModule.forFeature(taskListStateKey, taskListReducerMap),
        EffectsModule.forFeature([TaskListEffects, DailyGoalsEffects]),
    ],
})
export class DataAccessTaskListModule {}
