import { Platform } from '@angular/cdk/platform';
import { Component, HostBinding, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LOCAL_STORAGE, MEDIA_DEVICES } from '@ng-web-apis/common';
import { MediaData, MediaDataForm, MediaDevicesMap, MediaTypes } from '@mona/models';
import { takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { MediaDataService, MediaDataServiceContainer } from '../media-data.service';

/**
 * Confirm dialog component
 */
@TakeUntilDestroy
@Component({
    selector: 'telemedicine-settings-dialog',
    templateUrl: './settings-dialog.component.html',
    styleUrls: ['./settings-dialog.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class SettingsDialogComponent {
    /**
     * Adds material fonts
     */
    @HostBinding('class.mat-typography')
    matTypographyClass = true;

    /**
     * Adds material theme
     */
    @HostBinding('class.theme-mona-light')
    monaLightThemeClass = true;

    /**
     * Audio and video input settings form
     */
    form: FormGroup<MediaDataForm> = new FormGroup<MediaDataForm>({
        audioInput: new FormControl<string>(null, Validators.required),
        audioOutput: new FormControl<string>(null, Validators.required),
        videoInput: new FormControl<string>(null, Validators.required),
    });

    /**
     * Data map for mapping stream device to available devices
     */
    availableDevices: MediaDevicesMap = {
        audioInput: {},
        audioOutput: {},
        videoInput: {},
    };

    /** MediaDataService reference */
    private service: MediaDataService;

    /**
     * Constructor
     *
     * @param dialogRef dialogReg
     * @param data MAT_DIALOG_DATA
     * @param platform Platform
     * @param storage LOCAL_STORAGE
     * @param mediaDevices mediaDevices
     */
    constructor(
        private dialogRef: MatDialogRef<SettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MediaDataServiceContainer,
        public platform: Platform,
        @Inject(LOCAL_STORAGE) private storage: Storage,
        @Inject(MEDIA_DEVICES) private mediaDevices: MediaDevices,
    ) {
        this.dialogRef.addPanelClass('telemedicine-settings-dialog');
        this.service = data.service;
        this.updateFormValues();
        this.watchFormChanges();
    }

    /**
     * On destroy
     */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnDestroy(): void {
        // empty
    }

    /**
     * Update the form values based on the current stream data
     */
    updateFormValues(): void {
        this.mediaDevices.enumerateDevices().then((data: MediaDeviceInfo[]) => {
            data.forEach((entry: MediaDeviceInfo) => {
                if (entry.kind === MediaTypes.AUDIO_INPUT) {
                    this.availableDevices.audioInput[entry.deviceId] = entry;
                } else if (entry.kind === MediaTypes.AUDIO_OUTPUT) {
                    this.availableDevices.audioOutput[entry.deviceId] = entry;
                } else if (entry.kind === MediaTypes.VIDEO_INPUT) {
                    this.availableDevices.videoInput[entry.deviceId] = entry;
                }
            });
            const mediaData: MediaData = this.service.getMediaData(this.platform.BLINK);
            const newFormValue: MediaData = { ...mediaData };
            const persistedMediaData: MediaData = JSON.parse(this.storage.getItem('persistedMediaData'));

            if (!persistedMediaData) {
                this.storage.setItem('persistedMediaData', JSON.stringify(mediaData));
            } else {
                for (const key in persistedMediaData) {
                    if (key in persistedMediaData && persistedMediaData[key] in this.availableDevices[key]) {
                        newFormValue[key] = persistedMediaData[key];
                    }
                }
            }

            this.form.patchValue(
                {
                    audioInput: newFormValue.audioInput,
                    videoInput: newFormValue.videoInput,
                    audioOutput: newFormValue.audioOutput || 'default',
                },
                {
                    emitEvent: false,
                },
            );
        });
    }

    /** Watch form changes */
    watchFormChanges(): void {
        this.form.controls.audioInput.valueChanges.pipe(takeUntilDestroy(this)).subscribe(deviceId => {
            this.service.replaceAudioVideoTrack(this.availableDevices.audioInput[deviceId]);
        });
        this.form.controls.videoInput.valueChanges.pipe(takeUntilDestroy(this)).subscribe(deviceId => {
            this.service.replaceAudioVideoTrack(this.availableDevices.videoInput[deviceId]);
        });
        this.form.controls.audioOutput.valueChanges.pipe(takeUntilDestroy(this)).subscribe(deviceId => {
            this.service.updateAudioOutput(this.availableDevices.audioOutput[deviceId]);
        });
        this.form.valueChanges.pipe(takeUntilDestroy(this)).subscribe(formValue => {
            this.storage.setItem('persistedMediaData', JSON.stringify(formValue));
        });
    }

    /**
     * Triggered when the form was submitted
     */
    onSubmit(): void {
        if (this.form.invalid) {
            return;
        }
    }
}
