import { TranslateService } from '@ngx-translate/core';
import { WorkflowAnswerOption, WorkflowQuestion } from '@mona/models';
import { UiDynamicElement, UiDynamicElementConfig } from '@mona/ui';

/**
 * Get questionnaire form config
 *
 * @param questions
 * @param questionnaireId
 * @param selectionChange optional
 * @param translateService optional
 */
export function getQuestionnaireFormConfig(
    questions: WorkflowQuestion[],
    questionnaireId: string,
    selectionChange?: AnyFn,
    translateService?: TranslateService,
): Map<string, UiDynamicElementConfig[]> {
    const formConfigElements = new Map<string, UiDynamicElementConfig[]>();

    questions.forEach(question => {
        const FIXME_HARDCODED_TYPES = ['tiss28', 'saps2']; // FIXME: remove after BE fix
        const questionConfig: UiDynamicElementConfig = {
            id: question.id,
            type: question.itemType as UiDynamicElement,
            name: question.id,
            required: question['required'] || true,
            label: question.title,
            // hint: q.description,
        };

        const dataSource: { value: WorkflowAnswerOption; label: string }[] =
            question.answerOptions?.map(a => ({
                value: a,
                label: a.display,
                isDefault: question.answerSuggestion?.code === a.code,
            })) || [];

        switch (question.itemType) {
            case 'checkbox':
                formConfigElements.set(question.id, [
                    {
                        ...questionConfig,
                        multiple: true,
                        dataSource,
                        customConfig: {
                            customClass: '',
                        },
                    },
                ]);
                break;
            case 'radio':
                formConfigElements.set(question.id, [
                    {
                        ...questionConfig,
                        // FIXME: remove after BE fix
                        type: FIXME_HARDCODED_TYPES.includes(questionnaireId)
                            ? UiDynamicElement.Select
                            : (question.itemType as UiDynamicElement),
                        dataSource,
                        label: null,
                        appearance: 'fill',
                        customConfig: {
                            vertical: true,
                            selectionChange: selectionChange,
                        },
                    },
                ]);
                break;
            case 'select':
                formConfigElements.set(question.id, [
                    {
                        ...questionConfig,
                        placeholder: question.title,
                        dataSource,
                        customConfig: {
                            customClass: 'app-input-field',
                        },
                    },
                ]);
                break;

            case 'datetimepicker':
                formConfigElements.set(question.id, [
                    {
                        ...questionConfig,
                        label: translateService.instant('dateRangeDialog.label.selectDate'),
                        customConfig: {
                            valueKey: 'display',
                            customClass: 'app-input-field',
                        },
                    },
                ]);
                break;

            case 'datepicker':
                formConfigElements.set(question.id, [
                    {
                        ...questionConfig,
                        label: translateService.instant('dateRangeDialog.label.selectDate'),
                        customConfig: {
                            valueKey: 'display',
                            customClass: 'app-input-field',
                        },
                    },
                ]);
                break;
            case 'textarea':
            case 'text':
                formConfigElements.set(question.id, [
                    {
                        ...questionConfig,
                        flex: 150,
                        customConfig: {
                            valueKey: 'display',
                            customClass: 'app-input-field',
                        },
                    },
                ]);
                break;
            default:
                break;
        }
    });

    return formConfigElements;
}
