import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import {
    MaintenanceComponent,
    OnScreenKeypadButtonComponent,
    OnScreenKeypadComponent,
    SignInPinComponent,
    SignInRfidComponent,
    SignInWelcomeComponent,
} from './components';
import { LockScreenRoutingModule } from './lock-screen-routing.module';
import { LockScreenComponent } from './lock-screen.component';

/**
 * LockScreenModule
 */
@NgModule({
    declarations: [
        MaintenanceComponent,
        OnScreenKeypadButtonComponent,
        OnScreenKeypadComponent,
        SignInPinComponent,
        SignInRfidComponent,
        SignInWelcomeComponent,
        //
        LockScreenComponent,
    ],
    imports: [UiModule, LockScreenRoutingModule, KeyboardModule],
    exports: [LockScreenComponent],
})
export class LockScreenModule {}
