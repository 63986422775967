import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlContainer, Validators } from '@angular/forms';
import { KeyboardModule } from '@mona/keyboard';
import { MedicationAdministration, MedicationPrescription, PrescriptionNotGivenReason } from '@mona/models';
import { TakeUntilDestroy, pick, takeUntilDestroy } from '@mona/shared/utils';
import { UiModule } from '@mona/ui';
import { NotGivenReason, NotGivenReasonForm, buildNotGivenReasonControls } from './not-given-reason-controls';

/**
 * Not given reason form controls component
 */
@TakeUntilDestroy
@Component({
    selector: 'mona-not-given-reason-form',
    templateUrl: './not-given-reason-form.component.html',
    styleUrls: ['./not-given-reason-form.component.scss'],
    standalone: true,
    imports: [UiModule, KeyboardModule],
})
export class NotGivenReasonComponent implements OnInit, OnDestroy {
    /** Show prescription documenting time */
    @Input({ required: true }) prescriptionTime: string;
    /** Show prescription documenting time */
    @Input({ required: true }) prescriptionNotGivenReasons: PrescriptionNotGivenReason[];
    /** Initial data resolved if edit mode - MedicationPrescription or MedicationAdministration */
    @Input({ required: true }) initialPayload: MedicationPrescription;

    @Input() formType: 'medications' | 'procedures' = 'medications';
    /** form of MedicationForm */
    formGroup: NotGivenReasonForm;
    /** The controls of the form */
    get controls(): NotGivenReasonForm['controls'] {
        return this.formGroup?.controls;
    }

    /**
     * Constructor
     *
     * @param controlContainer ControlContainer
     */
    constructor(@Optional() private controlContainer: ControlContainer) {}

    /** Lifecycle Hook */
    ngOnInit() {
        if (this.controlContainer.control) {
            this.formGroup = this.controlContainer.control as any;
        } else {
            this.formGroup = buildNotGivenReasonControls();
        }

        this.setReasonForm();
        this.subscribeOnStatusChange();
    }

    /** Lifecycle Hook */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnDestroy() {}

    /**
     * set reason form
     */
    private setReasonForm(): void {
        const reason = pick(this.initialPayload as MedicationAdministration, 'status', 'statusReason');
        const isPreDefinedReason = this.prescriptionNotGivenReasons.find(({ text }) => text === reason.statusReason);

        const notAdministeredReason: Partial<NotGivenReason> = {
            status: reason.status === 'not-done',
            reason:
                reason.status === 'not-done'
                    ? isPreDefinedReason || this.prescriptionNotGivenReasons.find(({ showTextbox }) => showTextbox)
                    : null,
            reasonAdditional: reason.status === 'not-done' && !isPreDefinedReason ? reason.statusReason : null,
        };

        this.formGroup.patchValue(notAdministeredReason);
    }

    /**
     * subscribe on main control (status) change
     */
    private subscribeOnStatusChange(): void {
        this.formGroup.controls?.status?.valueChanges
            ?.pipe(takeUntilDestroy(this))
            .subscribe((isNotGivenPresciption: boolean) => {
                if (isNotGivenPresciption) {
                    this.formGroup.controls.reason.setValidators(Validators.required);
                } else {
                    this.formGroup.controls.reason.removeValidators(Validators.required);
                    this.formGroup.patchValue({ reason: null, reasonAdditional: null });
                }

                this.formGroup.controls.reason.updateValueAndValidity();
            });

        this.formGroup.controls.reason.valueChanges
            .pipe(takeUntilDestroy(this))
            .subscribe((reason: PrescriptionNotGivenReason) => {
                if (reason?.showTextbox) {
                    this.formGroup.controls.reasonAdditional.setValidators(Validators.required);
                } else {
                    this.formGroup.controls.reasonAdditional.removeValidators(Validators.required);
                    this.formGroup.patchValue({ reasonAdditional: null });
                }

                this.formGroup.controls.reasonAdditional.updateValueAndValidity();
            });
    }

    trackByReason = (_, item) => item.text;

    /**
     * Function to compare the option values with the selected values
     *
     * @param o1
     * @param o2
     */
    compareFn(o1: PrescriptionNotGivenReason, o2: PrescriptionNotGivenReason) {
        return o1?.text === o2?.text;
    }
}
