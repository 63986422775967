import { createReducer, on } from '@ngrx/store';
import { EntryControl } from '@mona/models';
import { FormActions, SucceededAction } from '@mona/store';
import { DateShiftEntryControls, EntryControlForm } from '../../entities';
import { CareReportsAction } from '../actions/care-reports.action';

/**
 * The initial state
 */
export const initialDateShiftEntryControlsState: DateShiftEntryControls = {};

/**
 * Reducer for entry controls succeeded loading
 *
 * @param state EntryControlsMap
 * @param action SucceededAction<EntryControl[]>
 */
export const reduceLoadEntryControlsSucceeded = (
    state: DateShiftEntryControls,
    action: SucceededAction<EntryControl[]>,
): DateShiftEntryControls => {
    return action.payload.reduce((dateShiftEntryControls, entryControl) => {
        return {
            ...dateShiftEntryControls,
            [`${entryControl.date.toDateString()}${entryControl.shift}`]: entryControl.id,
        };
    }, {});
};

/**
 * Reducer for clear action
 */
export const reduceEntryControlsClear = (): DateShiftEntryControls => {
    return initialDateShiftEntryControlsState;
};

/**
 * Date & shift entry controls reducer
 */
export const dateShiftEntryControlsReducer = createReducer(
    initialDateShiftEntryControlsState,

    on(CareReportsAction.loadEntryControlsAction.succeededAction, reduceLoadEntryControlsSucceeded),

    on(CareReportsAction.loadEntryControlsAction.clearAction, reduceEntryControlsClear),
);

/**
 * Entry control form reducer
 */
export const entryControlFormReducer = createReducer<EntryControlForm>(
    {} as EntryControlForm,

    on(FormActions.formUpdateAction, (state, action) => {
        if (action.path === 'currentEncounterData.careReports.entryControlForm') {
            return {
                ...action.value,
            };
        }
    }),
    on(FormActions.formSuccessAction, (state, action) => {
        if (action.path === 'currentEncounterData.careReports.entryControlForm') {
            return {} as EntryControlForm;
        }
    }),
    on(FormActions.formResetAction, (state, action) => {
        if (action.path === 'currentEncounterData.careReports.entryControlForm') {
            return {} as EntryControlForm;
        }
    }),
);
