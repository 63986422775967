import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Allergy } from '@mona/models';
import { AllergiesForm, AllergyItemForm } from '../../models';

/**
 * Allergy dialog
 */
@Component({
    selector: 'app-allergy-dialog',
    templateUrl: './allergy-dialog.component.html',
    styleUrls: ['./allergy-dialog.component.scss'],
})
export class AllergyDialogComponent {
    /**
     * Allergies form
     */
    allergiesForm = new FormGroup<AllergiesForm>({
        items: new FormArray<FormGroup<AllergyItemForm>>([
            new FormGroup<AllergyItemForm>({
                text: new FormControl<string>(null, Validators.required),
            }),
        ]),
    });

    /**
     * Allergy control
     */
    allergyControl = this.allergiesForm.controls.items.controls[0].controls.text;

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<AnamnesisDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<AllergyDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            allergy: Allergy;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-allergy-dialog');
        this.allergyControl.setValue(data.allergy.allergenic);
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.allergiesForm.markAsTouched();

        if (this.allergiesForm.valid) {
            this.dialogRef.close({
                id: this.data.allergy.id,
                allergenic: this.allergyControl.value,
            } as Allergy);
        }
    }
}
