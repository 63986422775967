import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { WoundStatus } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { WoundStatusState } from '../entities';
import { WoundStatusAction, selectWoundAll, selectWoundStatusEntities, selectWoundStatusIsLoading } from '../state';

/**
 * WoundStatusFacade
 */
@Injectable({ providedIn: 'root' })
export class WoundStatusFacade {
    /** Get Wound Status */
    readonly woundStatus$: Observable<WoundStatus[]> = this.store.select(selectWoundAll);

    /** wound status map */
    readonly woundStatusMap$: Observable<Record<string, WoundStatus>> = this.store.select(selectWoundStatusEntities);

    /** is wounds data loading */
    readonly isLoading$ = this.store.select(selectWoundStatusIsLoading);

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService
     */
    constructor(private store: Store<WoundStatusState>, private changeLogService: ChangeLogService) {}

    /** load wound status data */
    loadWoundStatusData(): void {
        this.store.dispatch(WoundStatusAction.loadWoundStatus());
    }

    /**
     * INFO: add comment
     * @param wound
     */
    createWound(wound: Partial<WoundStatus>): void {
        this.changeLogService.createWound(wound);
    }

    /**
     * INFO: add comment
     * @param wound
     */
    updateWound(wound: Partial<WoundStatus>): void {
        this.changeLogService.updateWound(wound);
    }

    /**
     * INFO: add comment
     * @param id
     */
    removeWound(id: string): void {
        this.changeLogService.removeWound(id);
    }

    /**
     * Clear wound store
     */
    clearWoundStatus(): void {
        this.store.dispatch(WoundStatusAction.clearWoundStatus());
    }
}
