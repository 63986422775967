import { ApiModels } from '@mona/api';
import { VentilationProcedure } from '@mona/models';

/**
 * Transforms api ventilation procedures
 * @param apiVentilationProcedure ApiModels.VentilationProcedure
 */
export const transformApiVentilationProcedure = (
    apiVentilationProcedure: ApiModels.VentilationProcedure,
): VentilationProcedure => {
    return {
        id: apiVentilationProcedure.id,
        mode: apiVentilationProcedure.mode,
        startDate: new Date(apiVentilationProcedure.start_date),
        endDate: apiVentilationProcedure.end_date ? new Date(apiVentilationProcedure.end_date) : null,
    };
};

/**
 * Transform api ventilation procedures
 * @param apiVentilationProcedures ApiModels.VentilationProcedure[]
 */
export const transformApiVentilationProcedures = (
    apiVentilationProcedures: ApiModels.VentilationProcedure[],
): VentilationProcedure[] => {
    return apiVentilationProcedures.map(apiVentilationProcedure =>
        transformApiVentilationProcedure(apiVentilationProcedure),
    );
};
