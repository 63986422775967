import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FhirConfigEffects, fromFhir } from './state';

/**
 * DataAccessFhirModule
 */
@NgModule({
    imports: [
        StoreModule.forFeature(fromFhir.fhirConfigFeatureKey, fromFhir.reducer),
        EffectsModule.forFeature([FhirConfigEffects]),
    ],
})
export class DataAccessFhirModule {}
