import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppError, OnChange } from '@mona/shared/utils';
import { LoginPayloadCreds, LoginPayloadCredsForm } from '../../../../models';

/**
 * LogInForm Component
 */
@Component({
    selector: 'mona-log-in-form',
    templateUrl: './log-in-form.component.html',
    styleUrls: ['./log-in-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogInFormComponent implements OnChanges {
    readonly loginForm = new FormGroup<LoginPayloadCredsForm>(
        {
            username: new FormControl<string>('', { validators: [Validators.required] }),
            password: new FormControl<string>('', { validators: [Validators.required] }),
        },
        { updateOn: 'change' },
    );

    @Input() isLoading: boolean;

    /**
     * Partially reset form
     */
    @OnChange(function (this: LogInFormComponent, error: AppError) {
        if (error) {
            this.loginForm.get('password').reset();
            this.loginForm.get('password').markAsPristine();
        }
    })
    @Input()
    loginError?: AppError;

    /** Title translation */
    @Input() title: string;
    /** Submit title translation */
    @Input() submitTitle: string;

    @Output() submitForm = new EventEmitter<LoginPayloadCreds>();

    errorMessage: string;

    /**
     * Submit form
     */
    submit() {
        if (this.loginForm.valid) {
            this.submitForm.emit(this.loginForm.getRawValue());
        }
    }

    /**
     * INFO: add comment
     */
    ngOnChanges(): void {
        if (this.loginError) {
            this.errorMessage = this.loginError?.originalError?.error?.error?.code
                ? this.translateService.instant(`errors.auth.${this.loginError.originalError.error.error.code}`)
                : this.loginError.message;
        }
    }

    /**
     * INFO: add comment
     * @param translateService
     */
    constructor(private translateService: TranslateService) {}
}
