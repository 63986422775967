/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ProcedurePrescription } from '@mona/models';

export const loadProcedurePrescriptions = createAction('[ProcedurePrescription/API] Load ProcedurePrescriptions');

export const processProcedurePrescriptions = createAction(
    '[ProcedurePrescription/API] Process ProcedurePrescriptions',
    props<{ procedurePrescriptions: ProcedurePrescription[] }>(),
);

export const loadProcedurePrescriptionsFailure = createAction(
    '[ProcedurePrescription/API] Load ProcedurePrescriptions Failure',
    props<{ error: any }>(),
);

export const addProcedurePrescription = createAction(
    '[ProcedurePrescription/API] Add ProcedurePrescription',
    props<{ procedurePrescription: ProcedurePrescription }>(),
);

export const upsertProcedurePrescription = createAction(
    '[ProcedurePrescription/API] Upsert ProcedurePrescription',
    props<{ procedurePrescription: ProcedurePrescription }>(),
);

export const addProcedurePrescriptions = createAction(
    '[ProcedurePrescription/API] Add ProcedurePrescriptions',
    props<{ procedurePrescriptions: ProcedurePrescription[] }>(),
);

export const upsertProcedurePrescriptions = createAction(
    '[ProcedurePrescription/API] Upsert ProcedurePrescriptions',
    props<{ procedurePrescriptions: ProcedurePrescription[] }>(),
);

export const updateProcedurePrescription = createAction(
    '[ProcedurePrescription/API] Update ProcedurePrescription',
    props<{ update: Update<ProcedurePrescription> }>(),
);

export const updateProcedurePrescriptions = createAction(
    '[ProcedurePrescription/API] Update ProcedurePrescriptions',
    props<{ updates: Update<ProcedurePrescription>[] }>(),
);

export const handleDeleteProcedurePrescription = createAction(
    '[ProcedurePrescription/API] Handle Delete ProcedurePrescription',
    props<{ procedurePrescription: ProcedurePrescription }>(),
);

export const clearProcedurePrescriptions = createAction('[ProcedurePrescription/API] Clear ProcedurePrescriptions');

export const handleConfirmChanges = createAction('[ProcedurePrescription/API] Handle Confirm Changes');

export const addProcedurePrescriptionsChanges = createAction(
    '[ProcedurePrescription/API] Add Changes',
    props<{ toUpdateEntities: ProcedurePrescription[]; toRemoveIds: string[] }>(),
);
