import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSerialized } from '../models';

/**
 * Custom Router State Serializer
 *
 * @see https://v12.ngrx.io/guide/router-store/configuration#custom-router-state-serializer
 */
export class CustomRouterSerializer implements RouterStateSerializer<RouterStateSerialized> {
    /**
     * serialize implementation
     *
     * @param routerState
     */
    serialize(routerState: RouterStateSnapshot): RouterStateSerialized {
        let route = routerState.root;
        // eslint-disable-next-line prefer-const
        let { params, queryParams, data, outlet } = route;
        const { url } = routerState;

        while (route.firstChild) {
            route = route.firstChild;
            params = { ...params, ...route.params };
            data = { ...data, ...route.data };
        }

        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return { url, params, queryParams, data, outlet };
    }
}
