/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { MonaTask, TaskIdsList, TaskList, TaskListFilter } from '@mona/models';

export namespace TaskListActions {
    export const loadTasks = createAction('TASK_LIST:LOAD_TASKS');
    export const loadTasksSuccess = createAction(
        'TASK_LIST:LOAD_TASKS_SUCCESS',
        props<{ data: TaskList<TaskIdsList>; tasks?: MonaTask[] }>(),
    );

    export const loadTasksFailure = createAction('TASK_LIST:LOAD_TASKS_FAILURE', props<{ error: any }>());

    export const updateTasks = createAction('TASK_LIST:UPSERT_TASKS', props<{ updateTasks: Update<MonaTask>[] }>());

    export const toggleIsLoading = createAction('TASK_LIST:TOGGLE_IS_LOADING', props<{ isLoading: boolean }>());

    export const setFilter = createAction('TASK_LIST:SET_FILTER', props<{ filter: TaskListFilter }>());

    export const clearTaskListState = createAction('TASK_LIST:CLEAR_TASK_LIST');

    export const setOverdueTime = createAction('TASK_LIST:SET_OVERDUE_TIME', props<{ overdueTime: number }>());

    export const upsertCompleteOnDemandMedPrescriptions = createAction(
        'TASK_LIST:UPSERT_COMPLETE_ON_DEMAND_MED_PRESCRIPTIONS',
        props<{ completedOnDemandMedPrIds: string[] }>(),
    );
}
