import { createReducer, on } from '@ngrx/store';
import { BasicCareProcedure } from '@mona/models';
import { groupBy } from '@mona/shared/utils';
import { SucceededAction } from '@mona/store';
import { BasicCareProceduresMap } from '../../entities';
import { CareReportsAction } from '../actions';

/**
 * The initial state
 */
export const initialBasicCareProceduresMapState: BasicCareProceduresMap = null;

/**
 * Reducer for basic care procedures succeeded loading
 *
 * @param state BasicCareProceduresMap
 * @param action SucceededAction<BasicCareProcedure[]>
 */
export const reduceLoadBasicCareProceduresSucceeded = (
    state: BasicCareProceduresMap,
    action: SucceededAction<BasicCareProcedure[]>,
): BasicCareProceduresMap => {
    // sort procedures
    const sortedProcedures: BasicCareProcedure[] = [...action.payload];
    sortedProcedures.sort((prev, next) => {
        return new Date(prev.date).getTime() - new Date(next.date).getTime();
    });

    // convert to object map by grouping by code
    return groupBy(sortedProcedures, bcp => bcp.code, null, null) as BasicCareProceduresMap;
};

/**
 * Reducer for clear action
 */
export const reduceBasicCareProceduresClear = (): BasicCareProceduresMap => {
    return initialBasicCareProceduresMapState;
};

/**
 * Basic care procedures map reducer
 */
export const basicCareProceduresMapReducer = createReducer(
    initialBasicCareProceduresMapState,

    on(CareReportsAction.loadBasicCareProceduresAction.succeededAction, reduceLoadBasicCareProceduresSucceeded),

    on(CareReportsAction.loadBasicCareProceduresAction.clearAction, reduceBasicCareProceduresClear),
);
