import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    BaseMedication,
    BaseProcedurePrescription,
    MedicationCategory,
    PrescriptionSet,
    PrescriptionSetMedication,
    PrescriptionTypeEnum,
} from '@mona/models';
import { DataAccessPrescriptionSetsFacade } from '@mona/pdms/data-access-prescription-set';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import {
    MedicationFormTypeEnum,
    ProcedureFormTypeEnum,
    buildBaseMedicationForm,
    buildBaseProcedurePrescriptionForm,
} from '@mona/pdms/shared';
import { convertLocalHoursToUTC } from '@mona/shared/date';
import { isEmpty, NAVIGATION, NavigationService, TakeUntilDestroy, UiMapper, uiPure } from '@mona/shared/utils';
import { DialogData, DialogService } from '@mona/ui';

/** add prescription sidenav mat tabs enum */
export enum TabsEnum {
    PrescriptionCreateOrPrescriptionsSets = 0,
    PrescriptionSets,
    SelectPrescriptionSet,
    AddEditPrescription,
}

/** AddPrescriptionSetComponent */
@TakeUntilDestroy
@Component({
    selector: 'mona-add-prescription-sidenav',
    templateUrl: './add-prescription-sidenav.component.html',
    styleUrls: ['./add-prescription-sidenav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPrescriptionSidenavComponent implements OnInit, OnDestroy {
    /** Type of entity component was open for */
    entityType: PrescriptionTypeEnum;
    /** tabs values */
    readonly tabsEnum = TabsEnum;
    /** Holds list of available prescriptions sets */
    readonly prescriptionsSets$: Observable<PrescriptionSet[]> = this.prescriptionSetsFacade.prescriptionSets$;
    /** Is loading */
    readonly isLoading$: Observable<boolean> = this.prescriptionSetsFacade.isLoading$;
    /** mat tab - selectd tab */
    private _selectedTab$ = new BehaviorSubject(0);
    /** procedure categories */
    readonly procedureCategories$ = this.terminologyService.getProcedureCategories();
    /** Frequency array */
    readonly prescriptionFrequencies$ = this.terminologyService.getPrescriptionFrequencies();
    /** Frequency times array */
    readonly prescriptionFrequencyTimes$ = this.terminologyService.getPrescriptionFrequencyTimes();
    /** medicationCategories */
    readonly medicationCategories$ = this.terminologyService.getMedicationCategories();
    /** bloodAdministrations */
    readonly bloodAdministrations$ = this.terminologyService.getBloodAdministrations();
    /** medicationSolutions */
    readonly medicationSolutions$ = this.terminologyService.getMedicationSolutions();
    /** medicationDosageForms */
    readonly medicationDosageForms$ = this.terminologyService.getDosageForms();
    /** medicationUnits */
    readonly medicationUnits$ = this.terminologyService.getMedicationUnits();
    /** medicationAdministrationMethods */
    readonly medicationAdministrationMethods$ = this.terminologyService.getMedicationAdministrationMethods();
    /** procedure form group */
    readonly procedureFormGroup = buildBaseProcedurePrescriptionForm();
    /** medication form group */
    readonly medicationFormGroup = buildBaseMedicationForm();
    /** Selected prescription set */
    readonly selectedPrescriptionSet$ = this.prescriptionSetsFacade.activePrescriptionSet$;
    /** Selected tab */
    translationKeys: { title: string; new: string };
    /** selected prescription set procedure */
    selectedSetProcedure!: BaseProcedurePrescription;
    /** selected prescription set medication */
    selectedSetMedication!: BaseMedication;

    /** Selected tab */
    get selectedTab$(): Observable<number> {
        return this._selectedTab$.asObservable();
    }

    /**
     * Map array of set medications data to actual medications array
     *
     * @param data
     */
    mapToMedications: UiMapper<PrescriptionSetMedication[], BaseMedication[]> = data => {
        return data?.map(psm => psm.standardMedicationSet) || [];
    };

    /**
     * Constructor
     *
     * @param prescriptionSetsFacade
     * @param terminologyService
     * @param navigationService
     * @param route
     * @param dialogService
     */
    constructor(
        private prescriptionSetsFacade: DataAccessPrescriptionSetsFacade,
        private terminologyService: TerminologyService,
        @Inject(NAVIGATION) private navigationService: NavigationService,
        private route: ActivatedRoute,
        private dialogService: DialogService,
    ) {
        this.entityType = this.route.snapshot.data?.entityType || PrescriptionTypeEnum.Medication;
    }

    /** ngOnInit */
    ngOnInit(): void {
        this.prescriptionSetsFacade.loadPrescriptionSets();
    }

    /** ngOnDestroy */
    ngOnDestroy(): void {
        this.prescriptionSetsFacade.clearActivePrescriptionSet();
    }

    /** Close dialog */
    onClose(): void {
        this.navigationService.closeAllOutlets();
    }
    /**
     * Get card title
     *
     * @param tabIndex
     */
    @uiPure
    getCardTitle(tabIndex: TabsEnum): string {
        switch (tabIndex) {
            case TabsEnum.PrescriptionCreateOrPrescriptionsSets:
                return `apps.patient.prescriptions.addPrescription.${this.entityType}.title`;
            case TabsEnum.PrescriptionSets:
            case TabsEnum.SelectPrescriptionSet:
                return 'apps.settings.prescriptionSets.add';
            case TabsEnum.AddEditPrescription:
                if (this.selectedSetMedication) {
                    return 'apps.patient.prescriptions.addPrescription.editMedicationPrescriptionSet';
                } else if (this.selectedSetProcedure) {
                    return 'apps.patient.prescriptions.addPrescription.editProcedurePrescriptionSet';
                }
                return '';
            default:
                return '';
        }
    }

    /**
     * Get list title
     */
    getListTitle(): string {
        return `apps.patient.prescriptions.addPrescription.${this.entityType}.new`;
    }

    /**
     * Get selected prescription set empty
     *
     * @param set
     */
    @uiPure
    isPrescriptionSetEmpty(set: PrescriptionSet): boolean {
        return !set?.medications?.length && !set?.procedures?.length;
    }

    /**
     * check if active edit entity form is valid
     *
     * @param medicationFormInvalid
     * @param procedureFormInvalid
     */
    @uiPure
    isFormInvalid(medicationFormInvalid: boolean, procedureFormInvalid: boolean): boolean {
        if (this.selectedSetMedication) {
            return medicationFormInvalid;
        } else if (this.selectedSetProcedure) {
            return procedureFormInvalid;
        }
        return false;
    }

    /**
     * Navigate to medication or procedure form
     */
    navigateToPrescriptionForm() {
        const path = ['pdms', this.entityType, 'edit', ''];
        const data = {
            type:
                this.entityType === PrescriptionTypeEnum.Medication
                    ? MedicationFormTypeEnum.MedicationPrescription
                    : ProcedureFormTypeEnum.ProcedurePrescription,
        };
        this.navigationService.navigateToAuxilaryOutlet(path, 'side', { data });
    }

    /**
     * Activate inner tab
     *
     * @param tabIndex
     * @param set
     */
    activateTab(tabIndex: number, set?: PrescriptionSet): void {
        this._selectedTab$.next(tabIndex);

        switch (tabIndex) {
            //  On select prescription set option - activate tab 1
            case TabsEnum.PrescriptionSets:
                this.prescriptionSetsFacade.loadPrescriptionSets();
                this.prescriptionSetsFacade.clearActivePrescriptionSet();
                break;
            // On select exact prescription set activate tab 2
            case TabsEnum.SelectPrescriptionSet:
                this.selectedSetMedication = null;
                this.selectedSetProcedure = null;

                if (set) {
                    this.prescriptionSetsFacade.upsertActivePrescriptionSet(set);
                }
                break;
            default:
                break;
        }
    }

    /**
     * Create multiple medication prescriptions
     *
     * @param set
     */
    createPrescriptionsFromSet(set: PrescriptionSet): void {
        if (isEmpty(set)) {
            return;
        }
        this.prescriptionSetsFacade.createPrescriptionsBulk(set).subscribe(() => {
            this.navigationService.closeAllOutlets();
        });
    }

    /**
     * edit procedure
     *
     * @param procedure
     * @param set
     */
    openAddOrEditProcedure(procedure: BaseProcedurePrescription, set: PrescriptionSet): void {
        this.selectedSetProcedure = procedure;
        this.procedureFormGroup.patchValue(this.selectedSetProcedure);
        this.activateTab(TabsEnum.AddEditPrescription, set);
    }

    /**
     * open edit medication
     *
     * @param medication
     * @param set
     */
    openEditMedication(medication: BaseMedication, set: PrescriptionSet): void {
        this.selectedSetMedication = medication;
        this.medicationFormGroup.patchValue(this.selectedSetMedication);
        this.activateTab(TabsEnum.AddEditPrescription, set);
    }

    /**
     * edit procedure in active prescription set
     *
     * @param set
     */
    editPrescription(set: PrescriptionSet): void {
        const setClone = structuredClone(set);

        if (this.selectedSetMedication && this.medicationFormGroup.valid) {
            const medIdToFind = this.selectedSetMedication.id;
            const updatedMed = this.medicationFormGroup.value;

            setClone.medications = setClone.medications.map(med => {
                if (med.standardMedicationSet.id === medIdToFind) {
                    // NOTE: we need only to change medication.categoryCode,
                    // medication.displayName should remain the same
                    const medication = structuredClone(med.standardMedicationSet.medication);
                    medication.categoryCode = updatedMed.medication.categoryCode;

                    return {
                        ...med,
                        standardMedicationSet: {
                            ...updatedMed,
                            medication,
                        },
                    };
                }

                return med;
            });
        } else if (this.selectedSetProcedure && this.procedureFormGroup.valid) {
            const procIdToFind = this.selectedSetProcedure.id;
            const updatedProc = this.procedureFormGroup.value;

            setClone.procedures = setClone.procedures.map(proc => (proc.id === procIdToFind ? updatedProc : proc));
        }

        this.activateTab(TabsEnum.SelectPrescriptionSet);
        this.prescriptionSetsFacade.upsertActivePrescriptionSet(setClone);
    }

    /**
     * delete procedure
     *
     * @param procedure
     * @param set
     */
    deleteProcedure(procedure: BaseProcedurePrescription, set: PrescriptionSet): void {
        this.dialogService.showConfirmDialog(this.getDeleteFromSetDialogData('procedure')).subscribe(confirmed => {
            if (confirmed) {
                const setClone = structuredClone(set);
                setClone.procedures = setClone.procedures.filter(p => p.id !== procedure.id);
                this.prescriptionSetsFacade.upsertActivePrescriptionSet(setClone);
            }
        });
    }

    /**
     * delete medication
     *
     * @param medication
     * @param set
     */
    deleteMedication(medication: BaseMedication, set: PrescriptionSet): void {
        this.dialogService.showConfirmDialog(this.getDeleteFromSetDialogData('medication')).subscribe(confirmed => {
            if (confirmed) {
                const setClone = structuredClone(set);
                setClone.medications = setClone.medications.filter(m => m.standardMedicationSet.id !== medication.id);
                this.prescriptionSetsFacade.upsertActivePrescriptionSet(setClone);
            }
        });
    }

    /**
     * medication category change
     *
     * @param event
     * @param medCategories
     */
    medicationCategoryChange(event: MatSelectChange, medCategories: MedicationCategory[]): void {
        if (!medCategories) {
            return;
        }

        this.medicationFormGroup.controls.medication.patchValue({
            categoryCode: event.value,
            displayName: medCategories?.find(c => c.code === event.value)?.displayName,
            id: medCategories?.find(c => c.code === event.value)?.id,
        });
    }

    private getDeleteFromSetDialogData(prescriptionType: 'medication' | 'procedure'): DialogData {
        return {
            title: `apps.settings.prescriptionSet.${prescriptionType}Table.delete.title`,
            description: `apps.settings.prescriptionSet.${prescriptionType}Table.delete.message`,
            cancelBtn: `apps.settings.prescriptionSet.${prescriptionType}Table.delete.cancel`,
            confirmBtn: `apps.settings.prescriptionSet.${prescriptionType}Table.delete.confirm`,
        };
    }
}
