// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.11
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-09-21T12:31:08.347525+03:00[Europe/Chisinau]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating WoundStatus model structure.
 * @see #/components/schemas/WoundStatus - reference to schema in open-api-spec.yaml
 * @since 2024-09-21T12:31:08.347525+03:00[Europe/Chisinau]
 * @version 1.6.11
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WoundStatus {
    /**
     * Wound Status location.
     *
     */
    location: string;
    /**
     * Wound Status description.
     *
     */
    description: string;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * WoundStatus unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WoundStatus
 */
export namespace WoundStatus {
    /**
     * WoundStatus Frontend Model with camelCase properties
     * @since 2024-09-21T12:31:08.347525+03:00[Europe/Chisinau]
     * @version 1.6.11
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Wound Status location.
         * @type string (`dataFormat` is missing)
         */
        location: string;
        /**
         * Wound Status description.
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * WoundStatus unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WoundStatus DTO
     * Transforms WoundStatus model from/to API object
     * @since 2024-09-21T12:31:08.347525+03:00[Europe/Chisinau]
     * @version 1.6.11
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WoundStatus model */
        static toModel(obj: WoundStatus): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WoundStatus model */
        static toApi(obj: Model): WoundStatus {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WoundStatus;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
