// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validation the premedication of admission.
 * @see #/components/schemas/AdmissionPreMedicationRequest - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionPreMedicationRequest {
    /**
     * Description for Medication.
     *
     */
    description?: string;
    /**
     * Medication Code.
     * @dataFormat uuid
     */
    code?: string;
    /**
     * The code of the prescription frequency that describes how often the administration should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
     * @dataFormat uuid
     */
    frequency?: string;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    dose?: number;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit?: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionPreMedicationRequest
 */
export namespace AdmissionPreMedicationRequest {
    /**
     * AdmissionPreMedicationRequest Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Description for Medication.
         * @type string (`dataFormat` is missing)
         */
        description?: string;
        /**
         * Medication Code.
         * @dataFormat uuid
         */
        code?: string;
        /**
         * The code of the prescription frequency that describes how often the administration should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
         * @dataFormat uuid
         */
        frequency?: string;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        dose?: number;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unit?: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionPreMedicationRequest DTO
     * Transforms AdmissionPreMedicationRequest model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionPreMedicationRequest model */
        static toModel(obj: AdmissionPreMedicationRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionPreMedicationRequest model */
        static toApi(obj: Model): AdmissionPreMedicationRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionPreMedicationRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
