import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurgeryPrescription } from '@mona/models';
import { SurgeryPrescriptionForm } from '../../models';

/**
 * Surgery dialog
 */
@Component({
    selector: 'app-surgery-prescription-dialog',
    templateUrl: './surgery-prescription-dialog.component.html',
    styleUrls: ['./surgery-prescription-dialog.component.scss'],
})
export class SurgeryPrescriptionDialogComponent {
    /**
     * surgery form
     */
    surgeryForm = new FormGroup<SurgeryPrescriptionForm>({
        surgeryPrescription: new FormControl<string>('', Validators.required),
    });

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<SurgeryPrescriptionDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<SurgeryPrescriptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            surgery: SurgeryPrescription;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-surgery-prescription-dialog');

        if (data.surgery.id) {
            this.surgeryForm.patchValue(data.surgery);
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.surgeryForm.markAsTouched();

        if (this.surgeryForm.valid) {
            this.dialogRef.close({
                id: this.data.surgery.id,
                surgeryPrescription: this.surgeryForm.controls.surgeryPrescription.value,
            } as SurgeryPrescription);
        }
    }
}
