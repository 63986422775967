<fieldset [formGroup]="timeZoneForm" class="timezone-form">
    <legend class="mat-h3">
        {{ 'oobe.steps.regionAndKeyboard.timezoneTitle' | translate }}
        <small *ngIf="!isLinux" class="text-warn">(DEBUG: Only Linux)</small>
    </legend>

    <mat-form-field data-testid="ff-timeZone">
        <mat-label>{{ 'oobe.steps.regionAndKeyboard.country' | translate }}</mat-label>
        <mat-select
            [formControl]="timeZoneForm.controls.timezone"
            panelClass="scrollbar-visible"
            data-testid="zoneSelect"
        >
            <mat-option
                *ngFor="let entry of availableTimezones | keyvalue"
                [value]="entry.key"
                [attr.data-testid]="entry.key"
            >
                {{ entry.key }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field id="ff-city">
        <mat-label>{{ 'oobe.steps.regionAndKeyboard.timezone' | translate }}</mat-label>
        <mat-select [formControl]="timeZoneForm.controls.city" panelClass="scrollbar-visible" data-testid="citySelect">
            <mat-option
                *ngFor="let city of availableTimezones[timeZoneForm.controls.timezone.value]"
                [value]="city"
                [attr.data-testid]="city"
            >
                {{ city | titlecase }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</fieldset>
