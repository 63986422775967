import { HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { environment } from '@environment';
import { Logger } from '@mona/shared/logger';
import { AppError, stringify } from '@mona/shared/utils';

/**
 * Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
export interface AppErrorHandler extends ErrorHandler {
    /**
     *
     * Handling all errors, print logs, etc
     *
     * @param error
     */
    handleApiError(error: Error & AppError): void;
}

/**
 * Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler implements AppErrorHandler {
    readonly logger = new Logger('MONA');

    /**
     *
     * Handling all errors, print logs, etc
     *
     * @param error
     */
    handleApiError(error: AppError): void {
        if (error.status === 0) {
            error.message = `network is probably offline net::ERR_CONNECTION_REFUSED`;
        }
        if (error.name === 'TimeoutError') {
            error.status = HttpStatusCode.RequestTimeout;
            error.message = `no response in timeout of ${environment.httpTimeout}ms net::ERR_CONNECTION_TIMED_OUT`;
        }
        switch (error.status) {
            case HttpStatusCode.Unauthorized:
                // handled by aut inerceptor
                break;
            case HttpStatusCode.Conflict:
            case HttpStatusCode.GatewayTimeout:
                break;
            case HttpStatusCode.InternalServerError:
            case HttpStatusCode.NotFound:
            default:
                // this._dialogService.showToast({ status: 'danger', text, title, });
                this.handleError(error as any);
                break;
        }
    }

    /**
     * Provides a hook for centralized exception handling.
     *
     * The default implementation of `ErrorHandler` prints error messages to the `console`. To
     * intercept error handling, write a custom exception handler that replaces this default as
     * appropriate for your app.
     *
     * @param error
     */
    handleError(error: Error & AppError): void {
        if (isDevMode()) {
            super.handleError(error);
        } else {
            this.logger.error(error.message, stringify(error.originalError || error));
        }
    }
}
