import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Medication, PrescriptionFrequency } from '@mona/models';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { isEmptyObject, OnChange } from '@mona/shared/utils';
import { buildPreMedicationFormGroup, PreMedicationsItemForm } from '../../models';

/**
 * Single pre medication form
 */
@Component({
    selector: 'app-pre-medication-form',
    templateUrl: './pre-medication-form.component.html',
    styleUrls: ['./pre-medication-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreMedicationFormComponent implements OnInit {
    /**
     * Constructor
     *
     * @param controlContainer ControlContainer
     * @param terminologyService TerminologyService
     */
    constructor(
        @Optional() private controlContainer: ControlContainer,
        private terminologyService: TerminologyService,
    ) {}
    /**
     * The form
     */
    formGroup: FormGroup<PreMedicationsItemForm>;
    /**
     * The controls of the form
     */
    get controls(): FormGroup<PreMedicationsItemForm>['controls'] {
        return this.formGroup.controls;
    }

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is medication field required
     */
    @Input() isRequired = true;

    /**
     * Is edit mode
     */
    /* prettier-ignore */
    @OnChange(function(this: PreMedicationFormComponent, value: boolean) {
        if (coerceBooleanProperty(value)) {
            this.formGroup?.enable();
        } else {
            this.formGroup?.disable();
        }
    })
    @Input() isEditMode = true;

    /**
     * Frequency dictionary
     */
    preMedicationFrequencies$: Observable<PrescriptionFrequency[]> =
        this.terminologyService.getPrescriptionFrequencies();

    /**
     * Lidecycle Hook
     */
    ngOnInit() {
        this.terminologyService.loadPrescriptionFrequencies();
        if (this.controlContainer.control) {
            this.formGroup = this.controlContainer.control as any;
            const currentValue = this.formGroup.getRawValue();
            if (!isEmptyObject(currentValue)) {
                this.formGroup.patchValue({
                    medication: {
                        id: currentValue.code,
                        code: currentValue.code,
                        displayName: currentValue.description,
                    },
                });
                this.controls.medication.disable();
            }
        } else {
            this.formGroup = buildPreMedicationFormGroup();
        }
    }

    /**
     * Adds an autocomplete item as new entry
     *
     * @param value
     */
    onMedicationAutoCompleteSelect(value: Medication) {
        this.formGroup.patchValue({
            code: value.code,
            description: value.displayName,
        });
        this.formGroup.controls.medication.disable();
    }

    /**
     * Track by code (used for table data source)
     *
     * @param index number
     * @param item terminology model which has code
     */
    trackByCode(index, item): string {
        return item.code;
    }
}
