import { isWithinInterval } from 'date-fns';
import { FilteredTaskList, MonaTask, PrescriptionsGroup, TaskList, TaskListFilter } from '@mona/models';

/**
 * filter task list
 *
 * @param tasks
 * @param overdueTime
 * @param filter
 */
export function filterTaskList(
    tasks: MonaTask[],
    overdueTime: number,
    filter?: TaskListFilter,
): FilteredTaskList<PrescriptionsGroup> {
    const index = findLastOverdueTaskIndex(tasks, overdueTime);
    const overdueTasks = tasks.slice(0, index + 1);
    const actualTasks = tasks.slice(index + 1);
    const actualFilteredTasks = filterActualTasks(actualTasks, filter);
    const overdue = groupTasksByTypeAndTimestamp(overdueTasks);
    const actual = groupTasksByTypeAndTimestamp(actualFilteredTasks);

    return { overdue, actual } as FilteredTaskList<PrescriptionsGroup>;
}

/**
 * group tasks by type and timestamp
 *
 * @param tasks
 * @returns
 */
export function groupTasksByTypeAndTimestamp(tasks: MonaTask[]): TaskList<PrescriptionsGroup> {
    const grouped: TaskList<PrescriptionsGroup> = {};

    tasks.forEach(task => {
        if (!grouped[task.timestamp]) {
            grouped[task.timestamp] = {
                medicationPrescriptions: [],
                procedurePrescriptions: [],
            };
        }

        grouped[task.timestamp][task.type].push(task);
    });

    return grouped;
}

/**
 * filter actual tasks
 *
 * @param tasks
 * @param filter
 * @returns
 */
export function filterActualTasks(tasks: MonaTask[], filter?: TaskListFilter): MonaTask[] {
    if (!filter) {
        return tasks;
    }

    return tasks.filter(task => {
        const taskDate = new Date(task.timestamp);
        return isWithinInterval(taskDate, {
            start: filter.startDate,
            end: filter.endDate,
        });
    });
}

/**
 * find last overdue task index
 *
 * @param tasks
 * @param overdueTime
 */
export function findLastOverdueTaskIndex(tasks: MonaTask[], overdueTime: number): number {
    const targetTime = new Date().getTime() - overdueTime * 60 * 1000;
    let left = 0;
    let right = tasks.length - 1;
    let index = -1;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const midTime = new Date(tasks[mid].timestamp).getTime();

        if (midTime < targetTime) {
            index = mid;
            left = mid + 1;
        } else {
            right = mid - 1;
        }
    }

    return index;
}
