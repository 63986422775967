<mat-dialog-content class="mona-server-url-dialog__content">
    <div>
        <h2 mat-dialog-title class="mona-server-url-dialog__title">
            {{ data.title | translate }}
        </h2>
        <p class="mona-server-url-dialog__description">
            {{ data.description | translate }}
        </p>

        <form [formGroup]="serverUrlForm" (ngSubmit)="apply()">
            <div>
                <mat-form-field class="app-input-field mona-server-url-dialog__field" appearance="fill">
                    <mat-label>{{ data.label | translate }}</mat-label>
                    <input [formControl]="serverUrlForm.controls.url" matInput required />
                    <mat-hint *ngIf="data.hintLabel">
                        {{ data.hintLabel | translate }}: {{ serverUrlForm.get('wsUrl').value }}
                    </mat-hint>
                    <mat-error>
                        <ui-validation-message [control]="serverUrlForm.controls.url"></ui-validation-message>
                    </mat-error>
                </mat-form-field>
            </div>
            <ng-content></ng-content>
        </form>
    </div>

    <div class="mona-server-url-dialog__footer">
        <ng-container *ngIf="serverUrlForm.dirty">
            <div
                class="mona-server-url-dialog__verify"
                [class.mona-server-url-dialog__verify--failed]="
                    !isLoading && serverUrlForm?.controls?.url?.errors?.urlUnavailable
                "
                [class.mona-server-url-dialog__verify--pending]="isLoading"
                [class.mona-server-url-dialog__verify--success]="
                    !isLoading && !serverUrlForm?.controls?.url?.errors?.urlUnavailable
                "
            >
                <ng-container *ngIf="!isLoading && serverUrlForm?.controls?.url?.errors?.urlUnavailable">
                    <mat-icon class="mona-server-url-dialog__verify-icon">warning</mat-icon>
                    {{ 'apps.settings.device.serverUrlVerifying.failed' | translate }}
                </ng-container>

                <ng-container *ngIf="!isLoading && !serverUrlForm?.controls?.url?.errors">
                    <mat-icon class="mona-server-url-dialog__verify-icon">check_circle</mat-icon>
                    {{ 'apps.settings.device.serverUrlVerifying.success' | translate }}
                </ng-container>

                <ng-container *ngIf="isLoading">
                    <mat-spinner
                        class="mona-server-url-dialog__spinner"
                        diameter="30"
                        mode="indeterminate"
                    ></mat-spinner>
                    {{ 'apps.settings.device.serverUrlVerifying.pending' | translate }}
                </ng-container>
            </div>
        </ng-container>

        <div class="mona-server-url-dialog__actions">
            <button mat-button class="mona-server-url-dialog__cancel" color="primary" mat-dialog-close>
                {{ data.cancelBtn | translate }}
            </button>
            <button
                mat-raised-button
                color="primary"
                cdkFocusInitial
                [disabled]="
                    !serverUrlForm.valid ||
                    (verifyServerUrlAction$ | async)?.inProgress ||
                    (verifyServerUrlAction$ | async)?.error
                "
                (click)="apply()"
            >
                {{ data.confirmBtn | translate }}
            </button>
        </div>
    </div>
</mat-dialog-content>
