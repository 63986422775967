import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { AllergiesApi } from '../../infrastructure';
import { AllergiesAction } from '../actions';

/**
 * Allergies effects
 */
@Injectable()
export class AllergiesEffects {
    /* Effect Declarations */

    /**
     * Load allergies effect
     */

    loadAllergies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AllergiesAction.loadAllergiesAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.allergiesApi.getAllergies(action.encounterId),
                    AllergiesAction.loadAllergiesAction,
                ),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param allergiesApi AllergiesApi
     */
    constructor(private actions$: Actions, private allergiesApi: AllergiesApi) {}
}
