import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import { ConfigService } from '@mona/config';
import { Bed, Ward } from '@mona/models';
import { BedsSelectors, WardsSelectors } from '@mona/pdms/data-access-wards';
import { AppError } from '@mona/shared/utils';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { CallApi, TelemedicinePractitionersApi } from '../../infrastructure';
import { CallActions } from '../actions';

/**
 * Effect class for call effects
 */
@Injectable()
export class CallEffects {
    /**
     * Selector for current bed id
     */
    private currentBed$: Observable<Bed> = this.store.select(BedsSelectors.selectSelectedBed);

    /**
     * Selector for current ward id
     */
    private currentWard$: Observable<Ward> = this.store.select(WardsSelectors.selectSelectedWard);

    /**
     * Create session effect
     */

    createSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CallActions.createSession.action),
            concatLatestFrom(() => [this.authService.user$, this.currentWard$, this.currentBed$]),
            switchMap(([, user, ward, bed]) => {
                if (!user) {
                    return of(
                        CallActions.createSession.failedAction({
                            error: new AppError('device is not registered or user unauthorized, should skip call init'),
                        }),
                    );
                }
                const location = ward && bed ? `${ward?.name}, ${bed?.name}` : '';
                return makeDefaultAsyncActionEffect(
                    this.telemedicinePractitionersApi
                        .registerPractitioner(user, this.configService.get('telemedicineHospitalCode'))
                        .pipe(switchMap(() => this.callApi.createSession(user.id, location))),
                    CallActions.createSession,
                );
            }),
        ),
    );

    /**
     * Load session effect
     */

    loadSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CallActions.loadSession.action),
            withLatestFrom(this.store.select((state: any) => state.call.session)),
            switchMap(([, sessionId]) =>
                makeDefaultAsyncActionEffect(this.callApi.getSession(sessionId), CallActions.loadSession),
            ),
        ),
    );

    /**
     * Create session succeeded effect
     */

    createSessionSucceeded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CallActions.createSession.succeededAction),
                tap(() => {
                    // FIXME: Can not use RouterActions.navigateAction
                    // Because when running without ngZone for some reason does not work correctly
                    this.ngZone.run(() => this.router.navigate(['/telemedicine/call'])).then();
                }),
            ),
        {
            dispatch: false,
        },
    );

    /**
     * Constructor
     *
     * @param store
     * @param actions$ Actions
     * @param callApi CallApi
     * @param telemedicinePractitionersApi TelemedicinePractitionersApi
     * @param configService ConfigService
     * @param authService
     * @param router Router
     * @param ngZone NgZone
     */
    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private callApi: CallApi,
        private telemedicinePractitionersApi: TelemedicinePractitionersApi,
        private configService: ConfigService,
        private authService: AuthService,
        private router: Router,
        private ngZone: NgZone,
    ) {}
}
