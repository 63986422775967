import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { WithPermissions } from '@mona/auth';
import { TaskIdsList, TaskList } from '@mona/models';

/**
 * API abstraction layer for the notifications API
 */
@Injectable({
    providedIn: 'root',
})
export class TaskListApi {
    /** api base url */
    private readonly apiBase = '/pdms/task-lists/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get task list data
     *
     * @param encounterId string
     */
    // not using DynamicPdmsPermissionEnum.TASK_LIST_ACCESS bc it's being added AFTER services initialization
    // TODO: maybe provide in lazy module so DynamicPermissions are initialized and can be used here
    @WithPermissions<Observable<ApiModels.TasksListApiModel>>(
        ['care_procedures_edit', 'inputs_outputs_edit'],
        of({} as ApiModels.TasksListApiModel),
    )
    getTaskListData(encounterId: string): Observable<TaskList<TaskIdsList>> {
        return this.http
            .get<ApiModels.TasksListApiModel>(this.apiBase, {
                params: {
                    encounter_id: encounterId as string,
                },
            })
            .pipe(
                map(apiTaskList => {
                    const taskList = {} as TaskList<TaskIdsList>;
                    for (const key in apiTaskList) {
                        taskList[key] = {
                            medicationPrescriptions: apiTaskList[key]['medication_prescriptions'],
                            procedurePrescriptions: apiTaskList[key]['procedure_prescriptions'],
                        };
                    }

                    return taskList;
                }),
            );
    }
}
