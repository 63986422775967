import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Patient } from '@mona/models';
import { PatientsActions } from '../actions';

/**  EntityAdapter<Patient> */
export const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>({
    selectId: (patient: Patient) => patient.parentEncounterId,
    sortComparer: (a: Patient, b: Patient) => a.patientNumber.localeCompare(b.patientNumber),
});

interface PatientsState extends EntityState<Patient> {
    isLoading: boolean;
}

export const initialState: PatientsState = adapter.getInitialState({
    isLoading: false,
});
export const patientsReducer = createReducer(
    initialState,
    on(PatientsActions.searchPatient, state => ({ ...state, isLoading: true })),
    on(PatientsActions.searchPatientSuccess, (state, action) => ({
        ...adapter.setAll(action.patients, state),
        error: null,
        isLoading: false,
    })),
    on(PatientsActions.searchPatientFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
    on(PatientsActions.clearSearchPatient, state => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
