import {
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationCategoryConfig,
    MedicationDosageForm,
    MedicationItem,
    MedicationSolution,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
    PrescriptionFrequencyTimesArray,
} from '@mona/models';
import { paddToTwoSymbols } from '@mona/shared/date';
import { isEmpty } from '@mona/shared/utils';

/**
 * Get medication category prop value
 *
 * @param item
 * @param categories
 * @param key
 */
export function getMedicationCategory<
    M extends MedicationItem,
    C extends MedicationCategory,
    K extends PropertyNames<MedicationCategory>,
>(item: M, categories: C[], key: K = 'displayName' as K): C[K] | undefined {
    if (!item || isEmpty(item)) {
        return undefined;
    }
    return categories?.find(mc => mc.code === item.category)?.[key];
}

/**
 * Get medication solution name
 *
 * @param item
 * @param medicationSolutions
 */
export function getMedicationSolution(item, medicationSolutions = []): string {
    if (!item || isEmpty(item)) {
        return '';
    }
    return medicationSolutions.find(ms => ms.code === item.solutionCode)?.displayName;
}

/**
 * Get medication frequency name
 *
 * @param item
 * @param medicationFrequencies
 */
export function getMedicationFrequency(item, medicationFrequencies = []): string {
    if (!item || isEmpty(item)) {
        return '';
    }

    const frequencyTimesCount = item.frequencyTimes?.length;
    return (
        medicationFrequencies?.find(mf => mf.code === item.frequency)?.displayName +
        (frequencyTimesCount ? ` (${frequencyTimesCount}x)` : '')
    );
}

/**
 * Get medication administration method name
 *
 * @param item
 * @param medicationAdministrationMethods
 */
export function getMedicationAdministrationMethod(item, medicationAdministrationMethods = []): string {
    if (!item || isEmpty(item)) {
        return '';
    }
    return medicationAdministrationMethods.find(ma => ma.code === item.method)?.displayName;
}

/**
 * Get medication dosage form name
 *
 * @param item
 * @param medicationDosageForms
 */
export function getMedicationDosageForm(item, medicationDosageForms = []): string {
    if (!item || isEmpty(item)) {
        return '';
    }
    return medicationDosageForms.find(df => df.code === item.dosageForm)?.displayName;
}

/**
 * Get formatted medication data
 *
 * @returns {string} `Amlodipin 12 ml, 5% Dextrose, 50 ml, zur Nacht, Subcutan`
 * @param medicationItem
 * @param medicationAdministrationMethods
 * @param medicationSolutions
 * @param medicationFrequencies
 * @param medicationCategories
 * @param medicationDosageForms
 * @param fieldKey
 */
export function getFormattedMedicationData(
    medicationItem: MedicationItem,
    medicationAdministrationMethods: MedicationAdministrationMethod[],
    medicationSolutions: MedicationSolution[],
    medicationFrequencies: PrescriptionFrequency[],
    medicationCategories?: MedicationCategory[],
    medicationDosageForms?: MedicationDosageForm[],
    fieldKey?: string, // e.g. `required_create`
): string {
    if (isEmpty(medicationItem)) {
        return '';
    }

    const output: any[] = [];

    const fieldConfig: MedicationCategoryConfig = getMedicationCategory(
        medicationItem,
        medicationCategories,
        'fieldConfig',
    );

    if (medicationItem.amount) {
        output.push(medicationItem.amount + ' ' + medicationItem.unitAmount);
    }
    if (medicationItem.solutionCode) {
        const solution = getMedicationSolution(medicationItem, medicationSolutions);
        if (!fieldKey || fieldConfig?.solution?.[fieldKey]) {
            output.push(solution);
        }
    }
    if (medicationItem.volume) {
        output.push(medicationItem.volume + ' ' + medicationItem.unitVolume);
    }
    if (medicationItem.rate) {
        output.push(medicationItem.rate + ' ' + medicationItem.unitRate);
    }
    if (medicationItem.frequency) {
        const frequency = getMedicationFrequency(medicationItem, medicationFrequencies);
        output.push(frequency);
    }
    if (medicationItem.method) {
        const method = getMedicationAdministrationMethod(medicationItem, medicationAdministrationMethods);
        if (!fieldKey || fieldConfig?.method?.[fieldKey]) {
            output.push(method);
        }
    }
    if (medicationItem.dosageForm) {
        const dosageForm = getMedicationDosageForm(medicationItem, medicationDosageForms);
        if (!fieldKey || fieldConfig?.dosageForm?.[fieldKey]) {
            output.push(dosageForm);
        }
    }

    const formattedString = output.join(', ');
    return formattedString;
}

/**
 *  Get sorted and formatted times array for specific frequency
 *
 * @private
 * @param frequencyCode
 * @param frequencyTimes
 */
export function getFrequencyTimesByCode(
    frequencyCode: string,
    frequencyTimes: PrescriptionFrequencyTime[],
): PrescriptionFrequencyTimesArray {
    const times = frequencyTimes
        .filter(time => time.prescriptionFrequencyCode === frequencyCode)
        .map(time => {
            // under the hood we are using hh:mm:ss time format because of BE
            const t = paddToTwoSymbols(time.hour) + ':' + paddToTwoSymbols(time.minute) + ':00';
            return t;
        });
    // remove duplicates (yes, they can come from API) and sort
    return [...new Set(times)].sort();
}
