import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RfidEmulatorComponent } from './rfid-emulator.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [RfidEmulatorComponent],
    exports: [RfidEmulatorComponent],
    imports: [CommonModule],
})
export class RfidEmulatorModule {}
