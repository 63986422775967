import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { UtilsModule } from '@mona/shared/utils';
import { UiDirectivesModule } from '../../../../directives';
import { UiAutocompleteComponent } from './autocomplete.component';

/** @internal */
@NgModule({
    imports: [
        UiDirectivesModule,
        UtilsModule,
        OverlayModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
    ],
    declarations: [UiAutocompleteComponent],
    exports: [UiAutocompleteComponent],
})
export class UiAutocompleteModule {}
