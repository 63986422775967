import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { makeAsyncActionReducer } from '@mona/store';
import { NetworkManagerState } from '../../entities';
import { NetworkManagerActions } from '../actions';

/**
 * Network manager reducer map
 */
export const networkManagerReducerMap: ActionReducerMap<NetworkManagerState> = {
    macAddress: createReducer(
        null,
        on(NetworkManagerActions.getMacAddressSuccess, (state, { macAddress }) => macAddress),
    ),
    details: createReducer(
        null,
        on(NetworkManagerActions.getNetworkDetailsSuccess, (state, { details }) => details),
    ),
    loadWifiEndpointsAction: makeAsyncActionReducer(NetworkManagerActions.loadWifiEndpoints),
    connectToWifiEndpointAction: makeAsyncActionReducer(NetworkManagerActions.connectToWifiEndpoint),
    loadManuelNetworkSettings: makeAsyncActionReducer(NetworkManagerActions.loadManualConnectionSettings),
    manualConnectionSettings: createReducer(
        null,
        on(NetworkManagerActions.getManualConnectionSettingsSuccess, (state, { manualConnectionSettings }) => ({
            ...state,
            manualConnectionSettings,
        })),
    ),
};
