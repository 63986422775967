/**
 * Glasgow Coma Scale - Best Verbal Response enum
 */
export enum BestVerbalResponse {
    ORIENTED = 5,
    CONFUSED = 4,
    INAPPROPRIATE_WORDS = 3,
    INCOMPREHENSIBLE_SOUNDS = 2,
    NO_RESPONSE = 1,
}
