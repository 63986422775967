import { ApiModels } from '@mona/api';
import { Valuables } from '@mona/models';

/**
 * Transforms API Valuable
 * @param apiValuable ApiModels.Valuables
 */
export const transformApiValuable = (apiValuable: ApiModels.Valuables): Valuables => {
    return ApiModels.Valuables.DTO.toModel(apiValuable);
};

/**
 * Transforms API Valuables
 * @param apiValuables ApiModels.Valuables[]
 */
export const transformApiValuables = (apiValuables: ApiModels.Valuables[]): Valuables[] => {
    return apiValuables.map(apiValuables => transformApiValuable(apiValuables));
};
