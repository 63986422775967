import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeLogEntry, Patient, Stay } from '@mona/models';
import { PersonalDataForm } from '../../models';

/**
 * INFO: add comment
 */
@Component({
    selector: 'mona-personal-data-dialog',
    templateUrl: './personal-data-dialog.component.html',
    styleUrls: ['./personal-data-dialog.component.scss'],
})
export class PersonalDataDialogComponent {
    /**
     * INFO: add comment
     *
     * @param dialogRef
     * @param data
     */
    constructor(
        private dialogRef: MatDialogRef<PersonalDataDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            personalDataForm: FormGroup<PersonalDataForm>;
            staysList: Stay[];
            isActive: boolean;
            isCaseChanged: boolean;
            stageChanges: ChangeLogEntry<Patient>[];
        },
    ) {}

    /**
     * Submit change
     */
    submitChange(): void {
        this.data.personalDataForm.markAsTouched();
        const dirtyControlKeys = Object.entries(this.data.personalDataForm.controls)
            .filter((entryControl: [string, FormControl]) => entryControl[1].dirty)
            .map((entryControl: [string, FormControl]) => entryControl[0]);

        if (this.data.personalDataForm.valid) {
            this.dialogRef.close({
                formValue: this.data.personalDataForm.getRawValue(),
                dirtyControlKeys,
            });
        }
    }
}
