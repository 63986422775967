// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { Stay } from './stay';

/**
 * Serializer for validating PatientSearchResult model structure.
 * @see #/components/schemas/PatientSearch - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PatientSearch {
    /**
     * The first or given name.
     *
     */
    first_name: string;
    /**
     * The last or family name.
     *
     */
    last_name: string;
    /**
     * The prefix to the name, such as \"Dr.\" or \"Prof.\".
     *
     */
    prefix?: string | null;
    /**
     * The patient number.
     *
     */
    patient_number: string;
    /**
     * Defines the gender fo the patient.  * `male` - MALE * `female` - FEMALE * `other` - OTHER * `unknown` - UNKNOWN
     *
     */
    gender: PatientSearch.GenderEnum;
    /**
     * The date of birth (and time, if available) of the patient.
     * @dataFormat date-time
     */
    date_of_birth: string;
    /**
     * Whether or not the patient is deceased.
     *
     */
    deceased: boolean;
    /**
     * The date of death (if the `deceased` property is true).
     * @dataFormat date-time
     */
    date_of_death?: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Patient unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * Parent encounter id.
     * @dataFormat uuid
     */
    parent_encounter_id: string;
    /**
     * The case id of the parent encounter
     *
     */
    readonly case_id: string;
    /**
     * Parent encounter status
     *
     */
    parent_encounter_status: string;
    /**
     * List of stays for the parent encounter
     *
     */
    stays_list?: Array<Stay>;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PatientSearch
 */
export namespace PatientSearch {
    export type GenderEnum = 'male' | 'female' | 'other' | 'unknown';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum,
        Other: 'other' as GenderEnum,
        Unknown: 'unknown' as GenderEnum,
    };

    /**
     * PatientSearch Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The first or given name.
         * @type string (`dataFormat` is missing)
         */
        firstName: string;
        /**
         * The last or family name.
         * @type string (`dataFormat` is missing)
         */
        lastName: string;
        /**
         * The prefix to the name, such as \"Dr.\" or \"Prof.\".
         * @type string (`dataFormat` is missing)
         */
        prefix?: string | null;
        /**
         * The patient number.
         * @type string (`dataFormat` is missing)
         */
        patientNumber: string;
        /**
         * Defines the gender fo the patient.  * `male` - MALE * `female` - FEMALE * `other` - OTHER * `unknown` - UNKNOWN
         * @type string (`dataFormat` is missing)
         */
        gender: PatientSearch.GenderEnum;
        /**
         * The date of birth (and time, if available) of the patient.
         * @dataFormat date-time
         */
        @TransformDate() dateOfBirth: Date;
        /**
         * Whether or not the patient is deceased.
         * @type boolean (`dataFormat` is missing)
         */
        deceased: boolean;
        /**
         * The date of death (if the `deceased` property is true).
         * @dataFormat date-time
         */
        @TransformDate() dateOfDeath?: Date;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Patient unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * Parent encounter id.
         * @dataFormat uuid
         */
        parentEncounterId: string;
        /**
         * The case id of the parent encounter
         * @type string (`dataFormat` is missing)
         */
        readonly caseId: string;
        /**
         * Parent encounter status
         * @type string (`dataFormat` is missing)
         */
        parentEncounterStatus: string;
        /**
         * List of stays for the parent encounter
         * @type Array<Stay> (`dataFormat` is missing)
         */
        @Type(() => Stay.Model) staysList?: Array<Stay.Model>;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PatientSearch DTO
     * Transforms PatientSearch model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PatientSearch model */
        static toModel(obj: PatientSearch): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PatientSearch model */
        static toApi(obj: Model): PatientSearch {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PatientSearch;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
