import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MonaTask } from '@mona/models';
import { ChangeLogAction, ChangeLogSelectors, mergeChangesOnModelId } from '@mona/pdms/data-access-changelog';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { notEmpty } from '@mona/shared/utils';
import { TaskListState } from '../../entities';
import {
    TaskListApi,
    createTasksList,
    getCompleteOnDemandMedPrescriptions,
    getUpdateTasksToComplete,
} from '../../infrastructure';
import { TaskListActions } from '../actions/task-list.actions';

/**
 * Task ListEffects effects
 */
@Injectable()
export class TaskListEffects {
    /**
     * Load task list effect
     */
    loadTaskList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TaskListActions.loadTasks),
            withCurrentEncounterId(),
            switchMap(([, encounterId]) =>
                this.taskListApi.getTaskListData(encounterId).pipe(
                    map(taskIdsLists => {
                        const tasks = createTasksList(taskIdsLists);
                        return TaskListActions.loadTasksSuccess({ data: taskIdsLists, tasks });
                    }),
                    catchError(error => of(TaskListActions.loadTasksFailure({ error }))),
                ),
            ),
        ),
    );

    onPersistChanges$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(this.store.select(ChangeLogSelectors.getChangesMap).pipe(notEmpty())),
                tap(([, changesMap]) => {
                    const updateTasks: Update<MonaTask>[] = getUpdateTasksToComplete(changesMap);
                    if (updateTasks.length) {
                        this.store.dispatch(TaskListActions.updateTasks({ updateTasks }));
                    }
                }),
                tap(([, changesMap]) => {
                    const completedOnDemandMedPrIds = getCompleteOnDemandMedPrescriptions(
                        changesMap['MedicationAdministration'],
                    );

                    if (completedOnDemandMedPrIds.length) {
                        this.store.dispatch(
                            TaskListActions.upsertCompleteOnDemandMedPrescriptions({ completedOnDemandMedPrIds }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store
     * @param actions$
     * @param taskListApi
     */
    constructor(
        private store: Store<{ taskList: TaskListState }>,
        private actions$: Actions,
        private taskListApi: TaskListApi,
    ) {}
}
