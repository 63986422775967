<ng-container [ngSwitch]="type">
    <svg *ngSwitchDefault class="mona-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.8 114">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    class="mona-logo-fill"
                    d="M37.53,89a1.7,1.7,0,0,0-2.39,2c2.42,8,8.74,17.54,11.9,22a2,2,0,0,0,3.38.16c4.88-5.07,13.7-3.63,20.48-1s13.6.33,14.74-2.84A358.05,358.05,0,0,1,37.57,89.05Z"
                />
                <path
                    class="mona-logo-fill"
                    d="M106.78,42.49A269,269,0,0,1,54,13.34c-6.36-4.8-4-7.4-8-11.59A6.14,6.14,0,0,0,37.19,2a6.25,6.25,0,0,0,.26,8.83c3.22,3,8.49,2.82,12.28,7,5.44,7.2,5,14.61,13.54,20.69,6.53,4.68,16.15,9.43,22.94,12.4,2.52,1.1,6.16,2.64,9.54,4,4.09,1.62,7.72,2.94,7.72,2.94A31.13,31.13,0,0,0,106.78,42.49Z"
                />
                <path
                    class="mona-logo-fill"
                    d="M12.39,7.58A7.2,7.2,0,1,0,2,17.55c4.39,4.57,8.83,1.48,18.26,8.8,3.49,3.15,5.66,6.81,11.35,16.53,7.44,12.72,23.76,20.67,41,27.95s24.76,9.63,24.76,9.63c-.27-1.35-.52-2.79-.79-4.14-1.17-6.93,1.71-10.17,4-12.68a323.18,323.18,0,0,1-76.81-41.1C15.39,16,16.77,12.14,12.39,7.58Z"
                />
                <path
                    class="mona-logo-fill"
                    d="M98.82,87.79A408,408,0,0,1,30.67,57.42C17.58,49.87,20.12,46,15.41,43.28A6.25,6.25,0,1,0,9.06,54c4.73,2.76,7.61,1.53,14.22,5.49h0l1,.62c10.27,6.28,4.49,16.52,16.29,23.23,6.15,3.5,23,10.68,23,10.68s15.53,6.37,24.26,9.12l.66-4c3.41.09,3.14-2.34,3.1-3.78s.31-2.7,2.34-3.06a15.56,15.56,0,0,1,3.55,0,1.82,1.82,0,0,0,1.94-2.2C99.23,89.37,99,88.56,98.82,87.79Z"
                />
                <path
                    class="mona-logo-fill"
                    d="M70,10c2.1,3.8,6.76,8.63,12.16,12.51,9.4,6.74,23.62,12.59,23.62,12.59a27.83,27.83,0,0,0-3.24-10.66c-2.66-5.89-13.69-15.71-27-20.74-3.07-1.16-6.25-2.21-7.68-2.57A1.06,1.06,0,0,0,66.5,2.27C66.69,3.69,68,6.44,70,10Z"
                />
            </g>
        </g>
    </svg>
    <svg
        *ngSwitchCase="'text'"
        class="mona-logo-text"
        width="126"
        height="27"
        viewBox="0 0 126 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            class="mona-logo-fill"
            d="M0 26.0454V0.510696H4.06062L4.47182 3.57485H4.83162C6.47643 1.19162 8.78944 0 11.7707 0C15.1631 0 17.4932 1.49803 18.7611 4.4941H19.0695C20.7486 1.49803 23.3871 0 26.9851 0C29.5209 0 31.5597 0.76604 33.1017 2.29812C34.6437 3.8302 35.4148 6.04321 35.4148 8.93713V26.0454H30.8915V9.80531C30.8915 7.89872 30.3947 6.45176 29.4009 5.46442C28.4072 4.44303 27.1736 3.93234 25.7001 3.93234C23.9868 3.93234 22.599 4.54517 21.5367 5.77084C20.4744 6.96245 19.9433 8.52858 19.9433 10.4692V26.0454H15.4201V10.1628C15.4201 8.15407 14.9232 6.62199 13.9295 5.56656C12.97 4.47708 11.7364 3.93234 10.2286 3.93234C8.7209 3.93234 7.3845 4.4941 6.21943 5.61763C5.05435 6.70711 4.47182 8.29025 4.47182 10.3671V26.0454H0Z"
            fill="white"
        />
        <path
            class="mona-logo-fill"
            d="M54.1405 26.5561C50.4397 26.5561 47.3557 25.2963 44.8884 22.7769C42.4555 20.2235 41.239 17.0572 41.239 13.278C41.239 9.4308 42.4555 6.26451 44.8884 3.77913C47.3214 1.25971 50.4054 0 54.1405 0C57.8413 0 60.9253 1.25971 63.3925 3.77913C65.8597 6.29855 67.0933 9.46485 67.0933 13.278C67.0933 17.0912 65.8597 20.2575 63.3925 22.7769C60.9596 25.2963 57.8756 26.5561 54.1405 26.5561ZM48.0753 19.917C49.6515 21.7215 51.6733 22.6237 54.1405 22.6237C56.6077 22.6237 58.6294 21.7215 60.2057 19.917C61.8163 18.0785 62.6215 15.8655 62.6215 13.278C62.6215 10.6565 61.8163 8.44346 60.2057 6.63901C58.6294 4.83456 56.6077 3.93234 54.1405 3.93234C51.639 3.93234 49.6173 4.83456 48.0753 6.63901C46.5333 8.44346 45.7623 10.6565 45.7623 13.278C45.7623 15.8655 46.5333 18.0785 48.0753 19.917Z"
            fill="white"
        />
        <path
            class="mona-logo-fill"
            d="M73.0345 26.0454V0.510696H77.0951L77.5063 3.57485H77.8661C79.6137 1.19162 82.0981 0 85.3191 0C88.129 0 90.3906 0.868179 92.104 2.60454C93.8173 4.34089 94.674 6.80924 94.674 10.0096V26.0454H90.2022V10.9799C90.2022 8.63072 89.6368 6.87734 88.5059 5.71977C87.4094 4.52815 85.9702 3.93234 84.1883 3.93234C82.3037 3.93234 80.7102 4.57922 79.4081 5.87297C78.1402 7.13268 77.5063 9.00523 77.5063 11.4906V26.0454H73.0345Z"
            fill="white"
        />
        <path
            class="mona-logo-fill"
            d="M124.098 22.9301C124.646 22.9301 125.28 22.7939 126 22.5216V25.7389C125.006 26.1135 124.013 26.3007 123.019 26.3007C120.038 26.3007 118.256 25.0921 117.673 22.6748C115.754 25.2623 112.893 26.5561 109.089 26.5561C106.554 26.5561 104.498 25.9432 102.921 24.7176C101.379 23.4578 100.608 21.6364 100.608 19.2531C100.608 16.938 101.396 15.1335 102.973 13.8398C104.583 12.512 106.708 11.7119 109.346 11.4395L117.005 10.7246V9.29462C117.005 7.52422 116.542 6.14534 115.617 5.158C114.692 4.17066 113.373 3.67699 111.659 3.67699C108.575 3.67699 106.725 5.29419 106.108 8.52858H101.636C101.91 6.00916 102.921 3.96639 104.669 2.40026C106.451 0.800086 108.781 0 111.659 0C114.641 0 117.022 0.81711 118.804 2.45133C120.62 4.08555 121.528 6.40069 121.528 9.39676V20.1213C121.528 21.9939 122.385 22.9301 124.098 22.9301ZM117.005 16.24V13.993L110.786 14.6058C109.038 14.7761 107.65 15.2357 106.622 15.9847C105.628 16.7337 105.132 17.7721 105.132 19.0999C105.132 20.4277 105.56 21.4151 106.417 22.062C107.273 22.6748 108.37 22.9812 109.706 22.9812C111.659 22.9812 113.356 22.4365 114.795 21.347C116.268 20.2235 117.005 18.5211 117.005 16.24Z"
            fill="white"
        />
    </svg>
</ng-container>
