import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

/**
 * On screen keypad
 */
@Component({
    selector: 'mona-on-screen-keypad',
    templateUrl: './on-screen-keypad.component.html',
    styleUrls: ['./on-screen-keypad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnScreenKeypadComponent {
    /**
     * Can not submit if invalid
     */
    @Input() isInvalid: boolean;

    /**
     * Indicates whether rfid auth method enabled
     */
    @Input() isRfidEnabled: boolean;

    /**
     * Rfid button is clicked output
     */
    @Output() rfidButtonClicked: EventEmitter<void> = new EventEmitter();

    /**
     * Number is clicked output
     */
    @Output() numClicked: EventEmitter<number> = new EventEmitter();

    /**
     * Host css class
     */
    @HostBinding('class.mona-on-screen-keypad') cmpClass = true;

    /**
     * Binds disable class and indicates if it is loading now
     */
    @HostBinding('class.mona-on-screen-keypad--disabled')
    @Input()
    isLoading: boolean;
}
