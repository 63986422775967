/**
 * View State of Setup Screen
 */
export enum DeviceSetupStatus {
    /**
     * Device activation not checked
     */
    NotChecked = 'NotChecked',
    /**
     * The device code prefix must be set
     */
    DeviceCodePrefix = 'DeviceCodePrefix',
    /**
     * Device Registration pending
     */
    Pending = 'Pending',
    /**
     * Waiting for Admin Activation
     */
    AwaitingActivation = 'AwaitingActivation',
    /**
     * Registration failed
     */
    RegistrationFailed = 'RegistrationFailed',
    /**
     * Registration complete
     */
    RegistrationComplete = 'RegistrationComplete',
    /**
     * Under Maintenance
     */
    Maintenance = 'Maintenance',
}
