import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { ConfigService } from '@mona/config';
import { AuthService } from '../../../../services';
import { AuthActions } from '../../../../state';

// FIXME: make dumb

/**
 * Sign in with PIN component
 */
@Component({
    selector: 'mona-sign-in-pin',
    templateUrl: './sign-in-pin.component.html',
    styleUrls: ['./sign-in-pin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInPinComponent {
    /**
     * Pin control
     * Pin can not be number because otherwise it is impossible to use leading zero
     */
    pinControl = new FormControl<string>(null, {
        validators: [Validators.required, Validators.pattern(/^[0-9]{6,}$/)],
    });

    /**
     * Pin form
     */
    pinForm = new FormGroup({
        pin: this.pinControl,
    });

    /**
     * Indicates whether rfid auth method enabled
     */
    isRfidEnabled: boolean = this.configService.get('isRfidEnabled');

    /**
     * isAuthInProgress$
     */
    isAuthInProgress$ = this.authService.isLoading$;

    /**
     * pinSubmit event emitter
     */
    @Output() pinSubmit = new EventEmitter<string>();

    /**
     * Constructor
     *
     * @param authService AuthService AuthService
     * @param configService ConfigService
     * @param actionsObserver
     */
    constructor(
        private authService: AuthService,
        private configService: ConfigService,
        private actionsObserver: ActionsSubject,
    ) {}

    /**
     * NG hook
     */
    ngOnInit() {
        this.handleRegisterError();
    }

    /**
     * Handle form submit
     */
    async handleSubmit() {
        if (this.pinForm.valid && this.pinForm.enabled) {
            this.pinControl.disable();
            this.pinSubmit.emit(this.pinForm.value.pin);
        }
    }

    /**
     * Removes last number from entered pin
     */
    onRemoveClick(): void {
        this.pinControl.setValue(`${this.pinControl.value}`.slice(0, -1) || null);
    }

    /**
     * Handles keypad number click
     *
     * @param num num
     */
    onNumClick(num: number): void {
        this.pinControl.setValue(this.pinControl.value ? `${this.pinControl.value}${num}` : `${num}`);
    }

    /**
     * Clear auth error and switch view state on lock screen
     */
    switchToRfid(): void {
        this.authService.clearError();
    }

    /**
     * Subscribes to sign in errors
     */
    private handleRegisterError(): void {
        this.actionsObserver.pipe(ofType(AuthActions.registerRfidFailure)).subscribe(() => {
            this.pinControl.enable();
        });
    }
}
