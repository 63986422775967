/* eslint-disable @angular-eslint/no-output-rename, @angular-eslint/no-input-rename, @angular-eslint/no-outputs-metadata-property, @angular-eslint/no-inputs-metadata-property, @typescript-eslint/member-ordering, @typescript-eslint/no-non-null-assertion, @angular-eslint/directive-selector */
/// <reference types="resize-observer-browser" />
import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { CdkScrollable, CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CdkTable } from '@angular/cdk/table';
import {
    AfterContentInit,
    Directive,
    forwardRef,
    Inject,
    InjectionToken,
    Input,
    isDevMode,
    OnChanges,
    OnDestroy,
    Optional,
    Renderer2,
    Self,
    SimpleChanges,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { fromEvent, Observable, Observer } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';
import { isBoolean, noop, TakeUntilDestroy } from '@mona/shared/utils';
import { filterScrolledByDirection } from '@mona/ui/services';
import {
    DEFAULT_COLUMN_BUFFER,
    DEFAULT_COLUMN_WIDTH,
    DEFAULT_STICKY_COLUMN_WIDTH,
    getTableScrollSTrategyTypeFromString,
    TableScrollStrategyEnum,
    TABLE_SCROLL_STRATEGY_TYPE,
    VHSTableScrollByColumnStrategy,
    VHSTableScrollByViewportStrategy,
    VHSTableScrollEagerStrategy,
    VHSTableScrollStrategy,
} from '../strategies';

/**
 * Mimics `CdkScrollable` but with filtered scroll event
 */
@Directive({
    selector: '[vhsWrapperScrollable]',
    exportAs: 'vhsWrapperScrollable',
})
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export class VhsWrapperScrollable extends CdkScrollable {
    /** Returns observable that emits when a scroll event is fired on the host element. */
    protected override _elementScrolled: Observable<Event> = new Observable((observer: Observer<Event>) =>
        this.ngZone.runOutsideAngular(() =>
            fromEvent<Event>(this.elementRef.nativeElement, 'scroll', { passive: true })
                .pipe(auditTime(10), filterScrolledByDirection('scrollTop'), takeUntil(this['_destroyed']))
                .subscribe(observer),
        ),
    );
}

/**
 * Provider factory for `VHSTableScrollStrategy` that simply extracts the already created
 * `VHSTableScrollStrategy` from the given directive.
 *
 * @param strategy
 */
export function factoryProvider(strategy: TableScrollStrategyEnum): VHSTableScrollStrategy {
    const strategyMap = {
        default: VHSTableScrollByColumnStrategy,
        viewport: VHSTableScrollByViewportStrategy,
        eager: VHSTableScrollEagerStrategy,
    };
    strategy = getTableScrollSTrategyTypeFromString(strategy);
    return new strategyMap[strategy]();
}

/**
 * Default viewport dimensions function'
 */
export const viewportDimensionsFactory = () => (el: HTMLElement) => ({
    width: el.offsetWidth,
    height: el.offsetHeight,
});

/** Get viewport dimensions function */
export const VHS_VIEWPORT_DIMENSIONS_GETTER = new InjectionToken('VHS_VIEWPORT_DIMENSIONS_GETTER');

export const VHS_TABLE_DIRECTIVE_PROVIDERS = [
    {
        provide: VIRTUAL_SCROLL_STRATEGY,
        useFactory: factoryProvider,
        deps: [forwardRef(() => TABLE_SCROLL_STRATEGY_TYPE)],
    },
];

/** A directive, which initializes virtual scroll strategy for fixed-size columns horizontal scrolling in mat-table */
@TakeUntilDestroy
@Directive({
    selector: 'cdk-virtual-scroll-viewport[columnWidth], vhs-viewport[columnWidth], ui-vhs-viewport[columnWidth]',
    providers: VHS_TABLE_DIRECTIVE_PROVIDERS,
    exportAs: 'columnWidth',
})
export class VHSTableDirective implements OnChanges, AfterContentInit, OnDestroy {
    /** _columnWidth */
    private _columnWidth = DEFAULT_COLUMN_WIDTH;
    /** The size of the columns in the list (in pixels). */
    @Input() get columnWidth(): number {
        return this._columnWidth;
    }
    /** Setter for that getter */
    set columnWidth(value: number) {
        if (typeof value !== 'undefined') {
            this._columnWidth = coerceNumberProperty(value);
        }
    }

    /** The number of buffered columns rendered beyond the viewport. */
    @Input() columnBuffer = DEFAULT_COLUMN_BUFFER;

    /**
     * The width of the sticky column in the table (in pixels). Defaults to 100.
     */
    @Input()
    set stickyColumnWidth(value: number) {
        if (typeof value !== 'undefined') {
            this._stickyColumnWidth = coerceNumberProperty(value);
        }
    }
    /** getter for that prop */
    get stickyColumnWidth(): number {
        return this._stickyColumnWidth;
    }
    /** _stickyColumnWidth */
    private _stickyColumnWidth = DEFAULT_STICKY_COLUMN_WIDTH;

    /** scrollTo duration ms, defaults to 0 to disable animation */
    @Input() scrollToDuration = 0;

    /** Find parent element - 1 element above 'ui-vhs-table' */
    get parentContainerEl(): HTMLElement {
        return this.viewport.elementRef?.nativeElement?.parentElement?.parentElement?.parentElement;
    }

    /** Event listener that will be used to handle container resize events. */
    private _resizeObserver: ResizeObserver;

    /**
     * Constructor
     *
     * @param viewport
     * @param renderer
     * @param _scrollStrategy
     * @param getViewportDimensions
     */
    constructor(
        @Optional()
        @Inject(CdkVirtualScrollViewport)
        private viewport: CdkVirtualScrollViewport & { width: number; height: number },
        private renderer: Renderer2,
        @Self()
        @Inject(VIRTUAL_SCROLL_STRATEGY)
        public readonly _scrollStrategy: VHSTableScrollStrategy,
        @Inject(VHS_VIEWPORT_DIMENSIONS_GETTER) private getViewportDimensions,
    ) {
        if (!this.viewport && isDevMode()) {
            throw Error(
                `VHSTableDirective: must be used inside "ui-vhs-viewport". Please check if you have correct selector`,
            );
        }
    }

    /**
     * Lifecycle hook
     *
     * @ignore
     */
    ngOnChanges({ columnWidth, columnBuffer }: SimpleChanges): void {
        if (!this.columnWidth && isDevMode()) {
            throw Error(`VHSTableDirective: 'columnWidth'  must be greater than 0`);
        }
        if (
            columnWidth?.currentValue !== columnWidth?.previousValue ||
            columnBuffer?.currentValue !== columnBuffer?.previousValue
        ) {
            this._scrollStrategy.updateColumnWidths(
                columnWidth?.currentValue || this.columnWidth,
                columnBuffer?.currentValue || this.columnBuffer,
            );
        }
    }

    /** Lifecycle hook */
    ngAfterContentInit() {
        this.setViewportDimensions();
        this.setTableContainerResizeObserver();
    }

    /** Lifecycle hook */
    ngOnDestroy(): void {
        this._resizeObserver.disconnect();
    }

    /**
     * setup container ResizeObserver and set Viewport width & height. Runs outside of the zone.
     * @
     */
    setTableContainerResizeObserver() {
        if (this.parentContainerEl && !this._resizeObserver) {
            this._resizeObserver = new ResizeObserver(entries => {
                // also will run once on first table render
                this.setViewportDimensions();
            });
            this._resizeObserver.observe(this.parentContainerEl);
        }
    }

    /**
     * Automatically set viewport dimensions based on
     * computed dimensions of parent container element
     */
    setViewportDimensions() {
        const parentEl: HTMLElement = this.parentContainerEl;
        const viewportEl: HTMLElement = this.viewport.elementRef.nativeElement;
        const leftTableWrapperEl = parentEl?.querySelector('#leftTableWrapper');
        const { width, height } = this.getViewportDimensions(this.parentContainerEl);

        if (isNaN(width) || isNaN(height) || (this.viewport.width == width && this.viewport.height == height)) {
            return; // exit if incorrect values OR nothing changed
        }

        if (width === 0) {
            // Warn instead of throwing error as this can happen when parent component was not destroyed (by intention, e.g custom router reuse strategy)
            console.warn(`VHSTableDirective: parent container width must be greater than 0`);
        }

        this.viewport.width = width;
        this.viewport.height = height;

        /**
         * Old way was to assign static width in pixels
         * New way is to use `calc(100% - 100)` to calculate viewport width based on parent container width
         */
        this.renderer.setStyle(viewportEl, 'width', `calc(100% - ${this.stickyColumnWidth}px)`);
        // TODO: consider changing height with CSS and/or calc
        this.renderer.setStyle(viewportEl, 'height', this.viewport.height + 'px');
        // Set styles for vertical scroll to equal the height of the viewport with visible scrollbars
        if (leftTableWrapperEl) {
            this.renderer.setStyle(leftTableWrapperEl, 'max-height', this.viewport.height - 12 + 'px');
            this.renderer.setStyle(leftTableWrapperEl, 'margin-bottom', 12 + 'px');
        }
        // Update the viewport dimensions and re-render.
        if (this.viewport) {
            this.viewport.checkViewportSize();
        }
    }

    /**
     * Toggle resize observer on demand
     * @param active
     */
    toggleResizeObserver(active: boolean) {
        isBoolean(active)
            ? this._resizeObserver?.unobserve(this.parentContainerEl)
            : this._resizeObserver?.observe(this.parentContainerEl);
    }

    /** @internal */
    static ngAcceptInputType_columnWidth: NumberInput;
    /** @internal */
    static ngAcceptInputType_stickyColumnWidth: NumberInput;
}

/**
 * Overrides some CDK Table functionality to improve performance
 *
 * @todo: enable the recycle view repeater strategy, which reduces rendering latency.
 */
@Directive({
    selector: 'table[mat-table-override]',
})
export class VhsTableOverrideDirective {
    /** @ignore */
    constructor(@Optional() private table: MatTable<any>) {
        if (!this.table) {
            console.warn(
                'VhsTableOverrideDirective: must be used inside mat-table. Please check if you have correct selector',
            );
        }
        this.overrideMatTable();
    }

    /**
     * Overrides CDK column sticky styles as sticky name & overlay columns are added via 2nd adjust table and thus unnecessary style calculation is not needed
     */
    private overrideMatTable(): void {
        if (!this.table) {
            return;
        }
        (this.table as CdkTable<any>)['_addStickyColumnStyles'] = noop;
        this.table.updateStickyColumnStyles = () => noop;
    }
}
