import { ApiModels } from '@mona/api';

/**
 * Practitioner role set
 */
export type PractitionerRole = ApiModels.Practitioner.RoleEnum;
/**
 * Practitioner role set const, copy of {@link ApiModels.Practitioner.RoleEnum }
 */
export const PractitionerRole = {
    Doctor: 'doctor' as PractitionerRole,
    Nurse: 'nurse' as PractitionerRole,
    Admin: 'admin' as PractitionerRole,
};

/**
 * Model representing a practitioner
 */
export interface Practitioner extends Entity {
    /**
     * The user's display name
     */
    displayName: string;

    /**
     * The user's first name
     */
    firstName: string;

    /**
     * The user's last name
     */
    lastName: string;

    /**
     * The user's profile image
     */
    profileImageUrl?: string;

    /**
     * Practitioner phone number
     */
    phone?: string;

    /**
     * Practitioner prefix
     */
    prefix?: string;

    /**
     * Practitioner role
     */
    role?: PractitionerRole[];
}
