/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, ContentChildren, ElementRef, Input, QueryList, ViewEncapsulation } from '@angular/core';
import { MatMenuItem } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { ConfigService } from '@mona/config';
import { ExternalResource } from '@mona/models';
import { isEmptyView } from '@mona/ui/utils';

/**
 * [DropdownToolbar Component](https://brightlayer-ui-components.github.io/angular/?path=/info/components-dropdown-toolbar--readme)
 *
 * The `<ui-dropdown-toolbar>` component is used to display a toolbar with a dropdown.
 */
@Component({
    selector: 'ui-dropdown-toolbar',
    encapsulation: ViewEncapsulation.None,
    template: `
        <mat-toolbar fxLayout="{{ fxLayout }}" class="ui-dropdown-toolbar-content" [color]="color">
            <div class="ui-dropdown-toolbar-icon-wrapper">
                <ng-content select="[ui-nav-icon]"></ng-content>
            </div>
            <div
                class="ui-dropdown-toolbar-text-content-container"
                *ngLet="{ currentExternalResource: currentExternalResource$ | async } as $"
            >
                <div class="ui-dropdown-toolbar-title mat-headline-6" data-testid="ui-toolbar-title">
                    <!-- prettier-ignore -->
                    <span class="ui-dropdown-toolbar-title-wrapper"><ng-content select="[ui-toolbar-title]"></ng-content></span>
                    <div
                        *ngIf="
                            title === 'apps.patient.externalResources.pageTitle' && !!$.currentExternalResource;
                            else defaultHeader
                        "
                        class="ui-dropdown-toolbar-title--external"
                    >
                        <div class="icon">
                            <img
                                *ngIf="$.currentExternalResource?.icon"
                                [ngSrc]="baseUrl + $.currentExternalResource?.icon"
                                [width]="60"
                                [height]="60"
                                alt="icon"
                            />
                            <mat-icon *ngIf="!$.currentExternalResource?.icon" [inline]="true">grid_view</mat-icon>
                        </div>
                        {{ (currentExternalResource$ | async)?.name }}
                    </div>
                    <ng-template #defaultHeader>
                        <span class="ui-dropdown-toolbar-title-default" [style.fontSize]="titleSize + 'px'">
                            {{ title | translate }}
                        </span>
                    </ng-template>
                </div>
                <div
                    class="ui-dropdown-toolbar-subtitle-container mat-subtitle-1"
                    [matMenuTriggerFor]="menuItems?.length ? dropdownToolbarMenu! : null"
                    #menuTrigger="matMenuTrigger"
                >
                    <span class="ui-dropdown-toolbar-subtitle mat-subtitle-1" data-testid="ui-toolbar-subtitle">
                        <!-- prettier-ignore -->
                        <span class="ui-dropdown-toolbar-subtitle-wrapper"><ng-content select="[ui-toolbar-subtitle]"></ng-content></span>
                        <!-- prettier-ignore -->
                        <span class="ui-dropdown-toolbar-subtitle-default">{{ subtitle | translate }}</span>
                        <mat-icon
                            class="ui-dropdown-toolbar-subtitle-icon"
                            [class.d-none]="!menuItems?.length"
                            [class.rotated-dropdown-arrow]="menuTrigger.menuOpen"
                        >
                            arrow_drop_down
                        </mat-icon>
                    </span>
                </div>
            </div>
            <ui-spacer></ui-spacer>
            <ng-container #actionsVc>
                <ng-content select="[ui-toolbar-actions]"></ng-content>
            </ng-container>
            <mat-menu #dropdownToolbarMenu="matMenu" [overlapTrigger]="false" class="ui-dropdown-toolbar-menu-wrapper">
                <ng-content select="[ui-toolbar-menu]"></ng-content>
            </mat-menu>
        </mat-toolbar>
    `,
    styleUrls: ['./dropdown-toolbar.component.scss'],
    host: {
        class: 'ui-dropdown-toolbar',
        '[class.ui-dropdown-toolbar--snap]': 'sticky',
    },
})
export class DropdownToolbarComponent {
    /** Flag to have sticky position */
    @Input() sticky: boolean;
    /** The text to display for title */
    @Input() fxLayout: 'row' | 'column' = 'row';
    /** The text to display for title */
    @Input() title: string;
    /** The text to display subtitle */
    @Input() subtitle: string;
    /** The text to display subtitle */
    @Input() currentExternalResource$: Observable<ExternalResource | null>;
    /** Mat toolbar color variant */
    @Input() color: 'primary' | 'accent' | 'warn' | undefined = undefined;
    /** The size of title text */
    @Input() titleSize = 20;
    /** Projected Menu items */
    @ContentChildren(MatMenuItem) menuItems: QueryList<MatMenuItem>;
    /**
     * isEmptyView
     *
     * @param el
     */
    isEmpty = (el: ElementRef): boolean => isEmptyView(el);
    /** base url */
    baseUrl: string = this.configService.get('api.baseUrl');

    /**
     * Constructor
     *
     * @param configService ConfigService
     */
    constructor(private configService: ConfigService) {}
}
