import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    BaseProcedurePrescription,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
    ProcedureCategory,
} from '@mona/models';
import {
    getMedicationFrequency,
    PrescriptionTerminologyGetter,
    PRESCRIPTION_TERMINOLOGY_GETTER,
} from '@mona/pdms/data-access-combined';
import { TakeUntilDestroy, takeUntilDestroy, uiPure } from '@mona/shared/utils';
import { UiTableCellMenu, UiTableDataSource, UiTableEvent, UiTableRow } from '@mona/ui';

/**
 * Prescription set procedures component
 */
@TakeUntilDestroy
@Component({
    selector: 'mona-procedure-list',
    styleUrls: ['./procedure-list.component.scss'],
    templateUrl: './procedure-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcedureListComponent implements OnInit, OnDestroy {
    /** Procedures array */
    @Input() set data(data: BaseProcedurePrescription[]) {
        const dataSource = new UiTableDataSource(data);
        dataSource.trackBy = (idx, row) => row.id;
        this.dataSource = dataSource;
    }

    /** Show as list */
    @Input() showAsList = false;

    /** Row clickable */
    @Input() rowClickable = false;

    /** Show delete */
    @Input() showDelete = true;

    /** Show no data */
    @Input() showNoData = true;

    /** Show no data */
    @Input() showTableHeader = true;

    /** Hide actions column row */
    @Input() showActionsColumn = true;

    /** Table rows striped */
    @Input() tableStriped = true;

    /** Table rows borderless */
    @Input() tableBorderless = false;

    /** Is loading */
    @Input() isLoading?: boolean;

    /** show actions */
    @Input() showActions = false;

    /** No data message block config */
    @Input() noDataConfig = {
        title: 'apps.settings.prescriptionSet.procedureTable.emptyTitle',
        subTitle: 'apps.settings.prescriptionSet.procedureTable.emptyDescription',
        ctaTitle: 'apps.settings.prescriptionSet.procedureTable.addProcedure',
    };

    /** Selected medication */
    get selected(): BaseProcedurePrescription {
        return this._selected;
    }

    @Input() set selected(value: BaseProcedurePrescription) {
        this._selected = value;
    }

    /** Select event */
    @Output() readonly selectedChange = new EventEmitter();

    /** Edit event */
    @Output() readonly edit = new EventEmitter();

    /** Delete event */
    @Output() readonly delete = new EventEmitter();

    getMedicationFrequency = getMedicationFrequency;

    /** Data source of procedures */
    dataSource: UiTableDataSource<BaseProcedurePrescription>;
    /** Frequency array */
    prescriptionFrequencies: PrescriptionFrequency[];
    /** Frequency times array */
    prescriptionFrequencyTimes: PrescriptionFrequencyTime[];
    /** Medications categories */
    procedureCategories: ProcedureCategory[];

    /** row menu items */
    readonly rowMenuItems: UiTableCellMenu[] = [
        {
            text: 'apps.patient.balance.inputs.edit',
            reason: 'edit',
            icon: 'edit',
        },
        {
            text: 'apps.patient.balance.inputs.delete',
            reason: 'delete',
            icon: 'delete',
        },
    ];

    /** selected procedure */
    private _selected: BaseProcedurePrescription;

    /**
     * Constructor
     *
     * @param cdRef ChangeDetectorRef,
     * @param medicationTerminologyGetter
     */
    constructor(
        private cdRef: ChangeDetectorRef,
        @Inject(PRESCRIPTION_TERMINOLOGY_GETTER) private medicationTerminologyGetter: PrescriptionTerminologyGetter,
    ) {}

    /** Lifecycle */
    ngOnInit(): void {
        if (this.showAsList) {
            this.rowClickable = true;
            this.showTableHeader = false;
            this.showActionsColumn = false;
            this.tableStriped = false;
            this.tableBorderless = true;
            this.showNoData = false;
        }

        this.getTerminologyData();
    }

    /** Lifecycle */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnDestroy() {
        // EMPTY
    }

    /**
     * On table event handler
     *
     * @param event Table event
     */
    onTableEvent(
        // tslint:disable-next-line: completed-docs
        event: UiTableEvent & { data: { row: BaseProcedurePrescription } },
    ) {
        const { row } = event.data;
        if (event.action === 'cellMenu') {
            /** Handle table cell menu click */
            switch (event.reason) {
                case 'edit':
                    this.edit.emit(row);
                    break;
                case 'delete':
                    this.delete.emit(row);
                    break;
                default:
                    console.warn(event);
                    break;
            }
        }
    }

    /**
     * Get row class
     *
     * @internal
     */
    @uiPure
    rowClassGetterFn(selected: BaseProcedurePrescription) {
        return (row: UiTableRow) => {
            return selected?.id === row.id ? 'ui-table-tr--selected' : 'ui-table-tr--default';
        };
    }

    /**
     * Handle Table event
     *
     * @param event UiTableEvent
     */
    handleTableEvent(event: UiTableEvent) {
        switch (event.action) {
            case 'rowClick':
                this._selected = event.data.row as any;
                this.selectedChange.emit(this.selected);
                this.cdRef.markForCheck();
                break;
            case 'noDataClick':
                this.edit.emit();
                break;
            default:
                break;
        }
    }

    /**
     * Get category display name
     *
     * @param code string
     */
    @uiPure
    getProcedureCategory(code: string): string {
        return this.procedureCategories.find(category => category.code === code)?.displayName || '';
    }

    /** Get terminology data once and assign to sync properties to avoid many async pipes */
    private getTerminologyData() {
        this.medicationTerminologyGetter()
            .pipe(takeUntilDestroy(this))
            .subscribe(({ prescriptionFrequencies, prescriptionFrequencyTimes, procedureCategories }) => {
                this.procedureCategories = procedureCategories;
                this.prescriptionFrequencies = prescriptionFrequencies;
                this.prescriptionFrequencyTimes = prescriptionFrequencyTimes;

                this.cdRef.markForCheck();
            });
    }
}
