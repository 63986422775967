<div
    class="ui-table-wrapper ui-table-wrapper--virtual-scroll"
    [class.ui-table-wrapper--scroll-background]="!dataSource.empty && showScrollBackground"
>
    <!-- Scroll Buttons aka Pager -->
    <ui-vhs-pager
        *ngIf="shouldShowPager(viewport.itemsInViewport, columns)"
        data-testid="vhsPager"
        [class.d-none]="viewport.isStrategyDefault() || dataSource.empty"
        [pageSize]="viewport.itemsInViewport"
        [length]="columns?.length"
        [scrollToIndex]="scrollToIndex"
        [showMoreButtons]="true"
        (page)="onPageEvent($event)"
    ></ui-vhs-pager>

    <!-- Table empty state -->
    <ng-container
        [ngTemplateOutlet]="noDataRowTemplate || defaultNoDataRowTemplate"
        *ngIf="dataSource.empty && showNoData && !isLoading"
    ></ng-container>
    <ng-template #defaultNoDataRowTemplate>
        <ui-empty-state
            class="ui-table__empty"
            [title]="noDataConfig?.title"
            [subTitle]="noDataConfig?.subTitle"
            [ctaTitle]="noDataConfig?.ctaTitle"
            [ctaPermission]="noDataConfig?.ctaPermission"
            (ctaClick)="handleNoDataClick()"
        ></ui-empty-state>
    </ng-template>
    <!-- --------------------------------------------------------------- -->
    <!-- 1st table: emulate sticky column -->
    <!-- --------------------------------------------------------------- -->
    <div
        #leftTableWrapper
        vhsWrapperScrollable
        id="leftTableWrapper"
        class="ui-table--column-wrapper"
        [style.width.px]="stickyColumnWidth + (withStickyData ? 100 : 0)"
    >
        <table
            #leftTable
            id="leftTable"
            class="ui-table ui-table--column"
            mat-table
            [dataSource]="dataSource"
            [trackBy]="rowsTrackBy"
            [style.width.px]="stickyColumnWidth + (withStickyData ? 100 : 0)"
        >
            <!-- Default Name Column - The group row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="name">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    class="ui-table-th ui-table-th--filter ui-table--sticky"
                    [style.width.px]="stickyColumnWidth"
                >
                    <ng-container
                        *ngIf="!filterDisabled"
                        [ngTemplateOutlet]="nameHeaderCellTemplate || defaultNameHeaderCellTemplate"
                    ></ng-container>
                    <ng-template #defaultNameHeaderCellTemplate>
                        <!--  -->
                        <ui-table-filter
                            [optionTemplate]="filterOptionTemplate"
                            [options]="
                                dataSource.data
                                    | uiTableFilterOptions: filterOptionLabelMapper:dataSource.filteredCodesDataAccessor
                            "
                            (filterChange)="dataSource.filterByCode($event)"
                        ></ui-table-filter>
                    </ng-template>
                </th>

                <td
                    mat-cell
                    *matCellDef="let row"
                    class="ui-table-td ui-table-td--name mat-column-name"
                    [style.width.px]="stickyColumnWidth"
                    (click)="nameCellClickFn && nameCellClickFn($event, row)"
                >
                    <ng-container
                        [ngTemplateOutlet]="nameCellTemplate || defaultNameCellTemplate"
                        [ngTemplateOutletContext]="{ $implicit: row }"
                    ></ng-container>
                    <ng-template #defaultNameCellTemplate>
                        <span>{{ row | dataAccessor: 'name' }}</span>
                    </ng-template>
                </td>
                <!-- Invisible footer -->
                <td
                    *matFooterCellDef
                    mat-footer-cell
                    matRipple
                    [matRippleDisabled]="!footerRipple"
                    class="ui-table-td ui-table-td--footer ui-table--sticky"
                >
                    <ng-container
                        [ngTemplateOutlet]="nameFooterCellTemplate || defaultNameFooterCellTemplate"
                    ></ng-container>
                    <ng-template #defaultNameFooterCellTemplate>
                        <!--  -->
                    </ng-template>
                </td>
            </ng-container>

            <ng-container *ngIf="withStickyData" matColumnDef="overlayColumn">
                <!-- Get last column which has row values, and render it's cell template  -->
                <th mat-header-cell *matHeaderCellDef class="ui-table-th ui-table-th--overlay"></th>
                <td
                    [attr.data-testid]="'row.' + rowId + '.00'"
                    [attr.data-rowindex]="rowId"
                    mat-cell
                    #cellContext="uiCellContext"
                    [uiCellContext]="{
                        rowIdx: rowId,
                        row: row,
                        colIdx: undefined,
                        column: getLastValueColumnForOverlay(columns, row, scrollToIndex),
                        width: columnWidth
                    }"
                    *uiCellDef="
                        let row;
                        let rowId = index;
                        template: overlayCellTemplate;
                        emptyTemplate: overlayCellTemplate
                    "
                    class="ui-table-td ui-table-td--overlay"
                >
                    <ng-container
                        *rxIf="
                            shouldShowLastColumnCell(row.lookup, cellContext.column, scrollToIndex, itemsInViewport);
                            strategy: 'idle'
                        "
                    >
                        <ng-container *uiCellRef></ng-container>
                    </ng-container>
                </td>
                <ng-container mat-footer-cell *matFooterCellDef></ng-container>
            </ng-container>

            <!-- Default Overlay cell template -->
            <ng-template #overlayCellTemplate let-row="row" let-col="column">
                <div
                    class="ui-table-td--overlay__text"
                    [style.top.px]="row.isGroup && row.isExpanded ? -32 : undefined"
                >
                    <span>{{ overlayCellValueGetter(row, col) }}</span>
                </div>
            </ng-template>

            <!-- Default Group Column - The group row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="groupColumn">
                <td
                    mat-cell
                    *matCellDef="let row"
                    class="ui-table-td ui-table-td--group"
                    [style.width.px]="stickyColumnWidth"
                >
                    <div
                        class="ui-table-td--group-cell"
                        [class.ui-table-td--group-cell--collapsed]="dataSource.collapsedRows.has(row.code)"
                        [style.maxWidth.px]="stickyColumnWidth"
                        (click)="dataSource.filterByGroup(row.code)"
                        mat-ripple
                    >
                        <ng-container
                            [ngTemplateOutlet]="groupCellTemplate || defaultGroupCellTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row }"
                        ></ng-container>
                        <ng-template #defaultGroupCellTemplate>
                            <span>{{ row | dataAccessor: 'name' }}</span>
                        </ng-template>
                    </div>
                </td>
                <!-- Invisible footer -->
                <td *matFooterCellDef [style.display]="'none'" mat-footer-cell></td>
            </ng-container>
            <!--  -->
            <!-- Default Header Row -->
            <tr
                class="ui-table-tr ui-table-tr--header"
                mat-header-row
                *matHeaderRowDef="stickyColumns; sticky: stickyHeader"
                [ngClass]="{ 'ui-table-tr--double': headerColumns?.length }"
            ></tr>
            <!-- Default Data Row -->
            <tr
                class="ui-table-tr"
                [ngClass]="rowClassGetter ? rowClassGetter(row) : rowClass"
                mat-row
                *matRowDef="let row; let i = index; columns: stickyColumns; when: defaultWhen"
            ></tr>
            <!-- Group Row -->
            <tr
                class="ui-table-tr ui-table-tr--group"
                mat-row
                *matRowDef="let row; columns: ['groupColumn']; when: whenIsGroup"
            ></tr>
            <tr
                class="ui-table-tr ui-table-tr--footer"
                mat-footer-row
                *matFooterRowDef="stickyColumns; sticky: true"
            ></tr>
        </table>
    </div>
    <!-- --------------------------------------------------------------- -->
    <!-- A viewport that virtualizes table scrolling with the help of CdkVirtualForOf. -->
    <!-- --------------------------------------------------------------- -->
    <ui-vhs-viewport
        #viewport
        #viewportDir="columnWidth"
        id="vhsViewport"
        class="ui-table-viewport scrollbar-visible"
        [class.ui-table-viewport--has-top-header]="headerColumns?.length"
        [columnWidth]="columnWidth"
        [columnBuffer]="columnBuffer"
        [stickyColumnWidth]="stickyColumnWidth"
        (scrolledIndexChange)="scrollToIndex = $event"
        (verticalScrolled)="synchronizeScrollTop($event, 0)"
        (offsetChange)="offset = $event"
        [style.marginLeft.px]="withStickyData ? -100 : 0"
    >
        <!-- --------------------------------------------------------------- -->
        <!-- 3rd Top header table: additional header columns without virtual scroll -->
        <!-- This will be projected inside vhs-viewport to skip virtual rendering -->
        <!-- --------------------------------------------------------------- -->
        <table
            #headerTable
            id="headerTable"
            class="ui-table ui-table--top-header"
            mat-table
            [dataSource]="[]"
            *ngIf="headerColumns?.length"
        >
            <!-- Top header cells -->
            <ng-container
                *ngFor="
                    let thCol of headerColumns;
                    trackBy: columnsTrackBy;
                    let tIdx = index;
                    let last = last;
                    let count = count
                "
                matColumnDef="{{ thCol.name }}"
            >
                <th
                    [attr.data-testid]="'th-top.' + tIdx"
                    mat-header-cell
                    *matHeaderCellDef
                    class="ui-table-th ui-table-th--top-header"
                    [class.ui-table-th--sticky]="stickyHeader"
                    [ngClass]="thCol.class"
                    [style.width.px]="columnWidth * thCol.colspan"
                    [style.minWidth.px]="columnWidth * thCol.colspan"
                >
                    <div class="ui-table-th__inner">
                        <div class="ui-table-th__inner-content">
                            <ng-container [ngSwitch]="thCol.format?.name">
                                <span *ngSwitchCase="'date'">
                                    {{ thCol | dataAccessor: thCol?.dataAccessorKey | date: thCol.format.option }}
                                </span>
                                <ng-container *ngSwitchDefault>
                                    <span>{{ thCol.headerText || thCol.name }}</span>
                                </ng-container>
                            </ng-container>
                            <mat-icon class="ui-table-th__dts-icon" [inline]="true" *ngIf="thCol.meta.isDstChangeDay">
                                update
                            </mat-icon>
                        </div>
                    </div>
                </th>
            </ng-container>
            <!-- Top Header Row -->
            <ng-container>
                <tr
                    class="ui-table-tr ui-table-tr--top-header"
                    mat-header-row
                    *matHeaderRowDef="headerColumns | uiMapper: headerColumnsMapper; sticky: stickyHeader"
                ></tr>
            </ng-container>
        </table>
        <!-- --------------------------------------------------------------- -->
        <!-- 2nd Main table: virtual horizontal scroll columns -->
        <!-- --------------------------------------------------------------- -->
        <table
            #mainTable
            id="mainTable"
            class="ui-table ui-table--virtual"
            mat-table
            mat-table-override
            ui-editable
            [dataSource]="dataSource"
            [trackBy]="dataSource.trackBy"
            [class.ui-table--has-top-header]="headerColumns?.length"
            [class.ui-table--striped]="isStriped"
            [class.ui-table--borderless]="isBorderless"
        >
            <!-- Default Column -->
            <ng-container
                *cdkVirtualFor="
                    let col of columns;
                    let colId = index;
                    let firstCol = first;
                    let lastCol = last;
                    trackBy: columnsTrackBy;
                    templateCacheSize: 0
                "
                matColumnDef="{{ col.name }}"
            >
                <th
                    [attr.data-testid]="'th.' + colId"
                    [attr.data-colindex]="colId"
                    mat-header-cell
                    *matHeaderCellDef
                    class="ui-table-th"
                    [class.th-dts]="col.isDtsShift"
                    [class.ui-table-th--active]="activeColumnPredicateFn(col)"
                    [class.ui-table-th--sticky]="stickyHeader"
                    [style.width.px]="columnWidth"
                    [innerHtml]="(col.headerText || col.name) + (col.isDtsShift ? ' (DST)' : '')"
                ></th>
                <td
                    [attr.data-testid]="'row.' + rowId + '.col.' + colId"
                    [attr.data-rowindex]="rowId"
                    [attr.data-rowtype]="row.dataType"
                    [attr.data-colindex]="colId"
                    [attr.data-hasvalue]="!!cellContext.cell"
                    mat-cell
                    *uiCellDef="
                        let row;
                        let rowId = index;
                        let odd = odd;
                        template: columnCellTemplate || defaultCellTemplate;
                        emptyTemplate: emptyCellTemplate;
                        activeTemplate: activeCellTemplate
                    "
                    #cellContext="uiCellContext"
                    [uiCellContext]="{
                        rowIdx: rowId,
                        row: row,
                        colIdx: colId,
                        column: col,
                        cell: cellValueGetter(row, col),
                        disabled: col.disabled?.(row, col),
                        width: columnWidth,
                        formConfig: editable && cellEditFormConfig && cellEditFormConfig[row.dataType]
                    }"
                    (click.zoneless)="handleCellClick($event, cellContext)"
                    [ngClass]="cellClassGetter(cellContext)"
                    [ngStyle]="{ 'width.px': columnWidth }"
                    [uiPopoverEditDisabled]="!editable || !cellEditFormConfig || !cellEditFormConfig[row.dataType]"
                    [uiPopoverEdit]="cellEditTemplate"
                    [uiPopoverEditContext]="editable ? cellContext : null"
                    [uiPopoverDouble]="!!cellValueGetter(row, col)"
                >
                    <!-- Custom cell template entry - cell template will be rendered here dynamically -->
                    <ng-container *uiCellRef></ng-container>
                </td>
                <td *matFooterCellDef class="ui-table-td--empty" mat-footer-cell></td>
            </ng-container>

            <!-- Empty Column -This column is added only if total columns length is less than 4, to properly set width for all columns -->
            <ng-container *ngIf="columns?.length <= 4 || withEmptyColumnsAtTheEnd" matColumnDef="emptyColumn">
                <th mat-header-cell *matHeaderCellDef class="ui-table-td ui-table--empty"></th>
                <td mat-cell *matCellDef [attr.data-testid]="'emptyColumn'" class="ui-table-td ui-table--empty">
                    &nbsp;
                </td>
            </ng-container>

            <!-- Default Header Row -->
            <tr
                #headerRow
                class="ui-table-tr ui-table-tr--header"
                [attr.data-testid]="'headerRow'"
                mat-header-row
                *matHeaderRowDef="displayedColumns"
            ></tr>
            <!-- Default Data Row -->
            <tr
                [attr.id]="i"
                [attr.data-code]="row.code"
                [attr.data-rowtype]="row.dataType"
                [attr.data-testid]="'row.' + i"
                ui-row
                [row]="row"
                *uiRowDef="let row = $implicit; let i = index; columns: displayedColumns; when: defaultWhen"
                (click.zoneless)="handleRowClick(row, i, $event)"
                [ngClass]="rowClassGetter ? rowClassGetter(row) : rowClass"
            ></tr>
            <!-- Group Row -->
            <tr
                class="ui-table-tr ui-table-tr--group"
                [attr.data-testid]="'row.' + i"
                mat-row
                *matRowDef="let row; let i = index; columns: []; when: whenIsGroup"
            ></tr>
            <tr
                class="ui-table-tr"
                [attr.data-testid]="'footerRow'"
                *matFooterRowDef="[]; sticky: stickyHeader"
                mat-footer-row
            ></tr>
        </table>
    </ui-vhs-viewport>

    <!-- <bullet-list-content-loader
        *ngIf="true"
        viewBox="{{ '0 0 ' + viewportDir.width + ' ' + viewportDir.height }}"
        class="ui-table-loader"
    ></bullet-list-content-loader> -->

    <!-- Default cell template -->
    <ng-template #defaultCellTemplate let-row="row" let-col="col">
        <span>{{ row | dataAccessor: col?.dataAccessorKey || 'name' }}</span>
    </ng-template>

    <!-- Default Active cell template -->
    <ng-template #activeCellTemplate>
        <mat-icon class="ui-table-td__icon ui-table-td__icon-add">add</mat-icon>
    </ng-template>

    <!-- Custom cell Edit template - This edit is defined in the cell and can implicitly access element -->
    <ng-template #cellEditTemplate let-cellContext="$implicit">
        <div>
            <ui-form
                #cellEditForm
                ngForm
                class="ui-table-td--edit-form"
                [class.ui-table-td--edit-form--borderless]="cellContext.row.dataType === 'ventilation-mode'"
                [name]="'cellform_row_' + cellContext.rowIdx + '_col_' + cellContext.colIdx"
                [elements]="cellContext.formConfig"
                [initialValue]="cellContext.cell"
                uiEditLens
                [uiEditLensClickOutBehavior]="editClickOutBehavior"
                [uiEditLensIgnoreSubmitUnlessValid]="editIgnoreSubmitUnlessValid"
                [scrollContainer]="null"
                (formSubmit)="handleCellEdit(cellContext, cellEditForm.form)"
                (ngSubmit)="handleCellEdit(cellContext, cellEditForm.form)"
            >
                <div mat-edit-actions class="d-none">
                    <button mat-button type="submit">Confirm</button>
                    <button mat-button cdkEditRevert>Revert</button>
                    <button mat-button cdkEditClose>Close</button>
                </div>
            </ui-form>
        </div>
    </ng-template>

    <!-- Cell menu -->
    <mat-menu
        #cellMenu="matMenu"
        [overlapTrigger]="false"
        hasBackdrop="true"
        yPosition="above"
        class="ui-table__menu-panel"
        backdropClass="ui-table__menu-overlay"
    >
        <ng-template matMenuContent let-cellContext="$implicit">
            <button
                mat-menu-item
                class="ui-table__menu-item"
                *ngFor="let item of cellMenuItems"
                [disabled]="checkMenuItemDisabled(item, cellContext)"
                [class.d-none]="checkMenuItemHidden(item, cellContext)"
                [ngClass]="item.class"
                (click.zoneless)="$event.stopPropagation(); handleCellMenuClick(item, cellContext, cellMenu)"
            >
                <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
                {{ item.text | translate }}
            </button>
        </ng-template>
    </mat-menu>

    <!-- Multiple values overlay menu -->
    <mat-menu
        #multipleValuesMenu="matMenu"
        [overlapTrigger]="false"
        hasBackdrop="true"
        yPosition="above"
        class="ui-table__menu-panel"
        backdropClass="ui-table__menu-overlay"
    >
        <ng-template matMenuContent let-cellContext="$implicit">
            <ng-container
                [ngTemplateOutlet]="multipleValuesTemplate"
                [ngTemplateOutletContext]="{ $implicit: multipleValuesMenu }"
            ></ng-container>
        </ng-template>
    </mat-menu>
</div>
