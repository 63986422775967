import { formatISO } from 'date-fns';
import { paddToTwoSymbols } from '../helpers/date-utils.helper';
import { isDstChangeDay } from '../helpers/is-dst-change-day.helper';
import { EntriesInterval } from './durations';

/**
 * Describes the date column for the table component
 */
export interface BaseDateColumn {
    /** Date */
    date: Date;
    /** Date as iso string */
    isoDateString: string;
    /** Date as ms */
    value?: number; // FIXME: find which tests use this field
}

/**
 * Describes the time column for the table component
 */
export interface TimeColumn extends BaseDateColumn {
    /** HH:mm */
    time: string;
    /** Is in current time */
    isNow?: boolean;
    /** Is DST shift */
    isDtsShift?: boolean;
}

/** @ignore */
export class TimeColumn implements TimeColumn {
    /** @internal */
    constructor(date: Date, isChangedToWinterTime = false) {
        const isoDateString = date.toISOString();
        this.date = date;
        this.value = date.getTime();
        this.isoDateString = isoDateString;
        this.time = `${paddToTwoSymbols(date.getHours())}:${paddToTwoSymbols(date.getMinutes())}`;
        this.isNow = false;
        this.isDtsShift = isChangedToWinterTime;
    }
}

/**
 * Describes the date column for the table component
 */
export interface DateColumn extends BaseDateColumn {
    /**
     * Columns that this day takes. At hourly zoom level this should be 24
     */
    colspan: number;
    /**
     * Indicates Daylight Saving Time change on this day
     */
    isDstChangeDay: boolean;
    /**
     * Date time entries
     */
    children: TimeColumn[];
}

/** @ignore */
export class DateColumn implements DateColumn {
    /** @internal */
    constructor(date: Date, children = [], forceIsoZ = false) {
        const isoDateString = forceIsoZ ? formatISO(date).split('+')[0] + '.000Z' : date.toISOString();
        this.date = date;
        this.value = date.getTime();
        this.isoDateString = isoDateString;
        this.children = children;
        this.colspan = children.length || 1;
        this.isDstChangeDay = isDstChangeDay(date);
    }
}

/**
 * DateTime Columns response from (worker) date/time calculation
 */
export interface DateTimeColumnsResponse {
    /** Selected interval */
    selectedInterval: EntriesInterval;
    /** array of  TimeColumn */
    timeColumns: TimeColumn[];
    /** array of  DateColumn */
    dateColumns: DateColumn[];
    /** **unique** selected interval, made of interval name & start time value */
    uId?: string;
}
