<form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [isEnabled]="isBedSideMode && isActive"
    (ngSubmit)="keyboardEnterClicked($event)"
>
    <div class="app-surgery-prescription-form__row">
        <mat-form-field
            class="app-input-field app-surgery-prescription-form__field"
            appearance="fill"
            data-testid="ff-surgeryPrescription"
        >
            <mat-label>{{ 'apps.admission.surgeryPrescription.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.surgeryPrescription"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-surgeryPrescriptionTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.surgeryPrescription"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
