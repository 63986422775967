import { Component, Inject, OnInit } from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CustomValidators, suspensify, Suspense } from '@mona/shared/utils';
import { ApiModels } from '../../api-models';
import { ApiHealthFacade, HttpService } from '../../services';
import { ServerUrlDialogData } from './server-url-dialog-data.model';

/**
 * Server url dialog component
 */
@Component({
    selector: 'mona-server-url-dialog',
    templateUrl: './server-url-dialog.component.html',
    styleUrls: ['./server-url-dialog.component.scss'],
})
export class ServerUrlDialogComponent implements OnInit {
    /**
     * Form
     */
    serverUrlForm: FormGroup<{
        url: FormControl<string>;
        wsUrl: FormControl<string>;
    }>;

    /**
     * Verify entered server url async action
     */
    verifyServerUrlAction$: Observable<Suspense<ApiModels.HealthSettings.Model>>;

    /**
     * Is loading
     */
    isLoading: boolean;

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<ServerUrlDialogComponent>
     * @param data
     * @param apiHealthFacade
     * @param http
     */
    constructor(
        private dialogRef: MatDialogRef<ServerUrlDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ServerUrlDialogData,
        private apiHealthFacade: ApiHealthFacade,
        private http: HttpService,
    ) {
        dialogRef.addPanelClass('mona-server-url-dialog');

        this.verifyServerUrlAction$ = this.apiHealthFacade.healthStateMap$.pipe(suspensify());

        this.serverUrlForm = new FormGroup({
            url: new FormControl<string>('', {
                validators: [Validators.required, data.isWsUrl ? CustomValidators.wsUrl() : CustomValidators.url()],
            }),
            wsUrl: new FormControl<string>(''),
        });

        if (data.url) {
            this.serverUrlForm.patchValue({
                url: data.url,
            });
        }
        if (data.wsUrl) {
            this.serverUrlForm.patchValue({
                wsUrl: data.wsUrl,
            });
        }
        if (!data.isWsUrl) {
            // Initialize wsUrl
            this.serverUrlForm.patchValue({
                wsUrl: data.url.replace(/^http/, 'ws'),
            });
        }
    }

    /**
     * NG hook
     */
    ngOnInit(): void {
        if (!this.data.isWsUrl) {
            // Subscribe to server url value changes to verify
            this.serverUrlForm.controls.url.valueChanges
                .pipe(
                    tap(() => {
                        if (!this.serverUrlForm.controls.url.invalid) {
                            this.serverUrlForm.controls.url.setAsyncValidators(this.urlAvailable());
                        } else {
                            this.serverUrlForm.controls.url.setAsyncValidators(null);
                            this.isLoading = false;
                        }
                        this.serverUrlForm.controls.url.updateValueAndValidity({ emitEvent: false });
                    }),
                    tap(value => {
                        if (!this.serverUrlForm.controls.url.invalid && !this.serverUrlForm.controls.url.errors) {
                            this.serverUrlForm.patchValue({
                                wsUrl: value.replace(/^http/, 'ws'),
                            });
                        }
                    }),
                    debounceTime(500),
                    distinctUntilChanged(),
                    takeUntil(this.dialogRef.beforeClosed()),
                )
                .subscribe();
        }

        // Check only close
        this.dialogRef.beforeClosed().subscribe(() => {
            if (this.serverUrlForm.controls.wsUrl.valid) {
                this.apiHealthFacade.loadApiHealth(this.serverUrlForm.controls.wsUrl.value.trim());
                // check url is set on close
            }
        });
    }

    /**
     * Check server availability
     *
     * @param serverUrl string
     */
    checkServerAvailability(serverUrl: string): Observable<any> {
        return this.http.get(`${serverUrl}/api/health/`);
    }

    /**
     * Async validator for entered url
     * Used subject to add debounce and avoid memory leaks
     */
    urlAvailable(): AsyncValidatorFn {
        const subject = new BehaviorSubject<string>('');
        const debouncedInput$ = subject.asObservable().pipe(
            take(1),
            distinctUntilChanged(),
            debounceTime(500),
            tap(() => (this.isLoading = true)),
            switchMap(url =>
                this.checkServerAvailability(url).pipe(
                    map(_ => null),
                    catchError(result => of(result ? { urlUnavailable: true } : null)),
                ),
            ),
            tap(() => (this.isLoading = false)),
        );
        return (control: AbstractControl): Observable<ValidationErrors> => {
            subject.next(control.value);
            return debouncedInput$;
        };
    }

    /**
     * Apply url
     */
    apply(): void {
        this.serverUrlForm.markAsTouched();

        if (this.serverUrlForm.valid) {
            this.dialogRef.close({
                url: this.serverUrlForm.controls.url.value,
                wsUrl: this.serverUrlForm.controls.wsUrl.value,
            });
        }
    }
}
