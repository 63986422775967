import { HorizontalConnectionPos, OverlayConfig, VerticalConnectionPos } from '@angular/cdk/overlay';

export const CUSTOM_PANEL_CLASS = 'ui-overlay-container';

/**
 * Configuration for the overlay container
 */
export interface UiOverlayContainerConfiguration extends OverlayConfig {
    /**
     * Wether the overlay should be positioned globaly on the screen.
     *
     * Default: false
     */
    useGlobalPositionStrategy?: boolean;

    /**
     * Horizontal dimension of a connection point on the perimeter of the origin.
     *
     * Default: 'center'
     */
    originX?: HorizontalConnectionPos;

    /**
     * Vertical dimension of a connection point on the perimeter of the origin.
     *
     * Default: 'bottom'
     */
    originY?: VerticalConnectionPos;

    /**
     * Horizontal dimension of a connection point on the perimeter of the overlay element.
     *
     * Default: 'center'
     */
    overlayX?: HorizontalConnectionPos;

    /**
     * Vertical dimension of a connection point on the perimeter of the overlay element.
     *
     * Default: 'top'
     */
    overlayY?: VerticalConnectionPos;

    /**
     * Offset along the X axis in px.
     *
     * Default: 0
     */
    offsetX?: number;

    /**
     * Offset along the Y axis in px.
     *
     * Default: 10
     */
    offsetY?: number;

    /**
     * Wether the overlay is draggable.
     *
     * Default: false
     */
    isDraggable?: boolean;

    /**
     * Wether the overlay is resizable.
     *
     * Default: true
     */
    isResizable?: boolean;

    /**
     * Wether the overlay should close when clicking outside.
     *
     * Default: false
     */
    disableBackdropClose?: boolean;
}

/**
 * Default overlay container configuration
 */
export const DEFAULT_OVERLAY_CONFIGURATION: UiOverlayContainerConfiguration = {
    hasBackdrop: true,
    backdropClass: 'cdk-overlay-transparent-backdrop',
    panelClass: CUSTOM_PANEL_CLASS,
    useGlobalPositionStrategy: false,
    width: '400px',
    height: '500px',
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetX: 0,
    offsetY: 10,
    isDraggable: false,
    isResizable: false,
    disableBackdropClose: false,
};
