import { NgModule } from '@angular/core';
import { UiTemplateRefDirective } from './template-registry.directive';
import { UiTemplateRefPipe } from './template-registry.pipe';

/** UiTemplateRefModule */
@NgModule({
    declarations: [UiTemplateRefDirective, UiTemplateRefPipe],
    exports: [UiTemplateRefDirective, UiTemplateRefPipe],
})
export class UiTemplateRefModule {}
