import { Directive, ElementRef, Input } from '@angular/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { UiCalendarCell, UiCalendarCellBase } from './calendar-cell';

/**
 * Cell definition for a {@link MatCalendarCell}
 * Captures the context & cell-specific properties.
 */
@Directive({ selector: '[uiCalendarCellDef]' })
export class UiCalendarCellDef implements UiCalendarCellBase {
    value: number;
    displayValue: string;
    ariaLabel: string;
    active: boolean;
    enabled: boolean;
    cssClasses: MatCalendarCellCssClasses;
    compareValue: number;
    rawValue: Date;

    @Input() set uiCalendarCell(value: UiCalendarCell) {
        Object.assign(this, value);
    }
    /** @internal */
    constructor(public elementRef: ElementRef<HTMLElement>) {}
}
