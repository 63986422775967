import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { RouterActions } from '../actions';

/**
 * Effect class for router effects
 */
@Injectable()
export class RouterEffects {
    /**
     * Navigate action effect
     */

    navigate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouterActions.navigateAction),
                tap(({ path, query: queryParams, extras, force }) => {
                    const navigationExtras: NavigationExtras = {
                        ...extras,
                    };
                    if (queryParams) {
                        navigationExtras.queryParams = queryParams;
                    }
                    if (coerceBooleanProperty(force)) {
                        // FIXME:
                        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    }
                    this.router.navigate(path, navigationExtras);
                }),
            ),
        { dispatch: false },
    );

    /**
     * Back action effect
     */

    navigateBack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouterActions.backAction),
                tap(() => this.location.back()),
            ),
        { dispatch: false },
    );

    /**
     * Forward action effect
     */

    navigateForward$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouterActions.forwardAction),
                tap(() => this.location.forward()),
            ),
        { dispatch: false },
    );

    /**
     * Service constructor
     *
     * @param actions$ actions$
     * @param router router
     * @param location
     */
    constructor(private actions$: Actions, private router: Router, private location: Location) {}
}
