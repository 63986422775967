import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environment';
import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    pluck,
    switchMap,
    take,
    takeUntil,
    tap,
    timeout,
    withLatestFrom,
} from 'rxjs/operators';
import { AUTH_SIGNIN_DIALOG_ID, AuthService } from '@mona/auth';
import { ConfigService } from '@mona/config';
import { Logger } from '@mona/shared/logger';
import {
    AppError,
    isEmptyObject,
    NAVIGATION,
    NavigationService,
    Platform,
    PLATFORM,
    Suspense,
} from '@mona/shared/utils';
import { RouterSelectors } from '@mona/store';
import { ApiHealthState, HealthState } from '../../entities';
import { ApiHealthService, ApiHealthWsService } from '../../services';
import * as ApiActions from '../actions/api.actions';
import * as ApiSelectors from '../selectors/api.selectors';

/**
 * ApiEffects
 */
@Injectable()
export class ApiEffects implements OnInitEffects {
    private readonly logger = new Logger('MONA');
    private lastFailedUrl = '';

    /** Core server url */
    private get coreWsServerUrl(): string {
        return this.configService.get('api.coreWsUrl');
    }

    /** Core HealthState */
    private get coreHealthState$(): Observable<HealthState> {
        return this.configService.select('api.baseUrl').pipe(
            switchMap(serverUrl => this.store$.select(ApiSelectors.selectApiHealthStateByUrl(serverUrl))),
            filter(s => {
                if (isEmptyObject(s)) {
                    return false;
                }
                if (s.hasOwnProperty('isLoading')) {
                    return !s.isLoading;
                }
            }),
        );
    }

    /** Core WS HealthState */
    private get coreWSHealthState$(): Observable<HealthState> {
        return this.configService
            .select('api.coreWsUrl')
            .pipe(switchMap(coreWsUrl => this.store$.select(ApiSelectors.selectApiHealthStateByUrl(coreWsUrl))));
    }

    /** Load API health once */
    loadApiHealth$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ApiActions.loadApiHealth),
            concatMap(({ serverUrl }) =>
                this.apiHealthService.loadApiHealth(serverUrl).pipe(
                    timeout(environment.httpTimeout),
                    map((healthState: HealthState) => ApiActions.loadApiHealthSuccess({ healthState, serverUrl })),
                    catchError((error, caught) => {
                        return of(ApiActions.loadApiHealthFailure({ serverUrl, error }));
                    }),
                ),
            ),
        );
    });

    /** Load API health failure */
    changeOnline$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ApiActions.changeOnline),
                concatLatestFrom(() => this.coreWSHealthState$.pipe(pluck('error'))),
                tap(([{ isOnline }, error]) => {
                    if (isOnline && error) {
                        this.store$.dispatch(ApiActions.connectionReestablished());
                    } else if (!isOnline) {
                        this.store$.dispatch(
                            ApiActions.loadWsHealthFailure({
                                serverUrl: this.coreWsServerUrl,
                                error: new AppError({ message: 'ERR_INTERNET_DISCONNECTED', code: 'base.offline' }),
                            }),
                        );
                    }
                }),
            );
        },
        { dispatch: false },
    );

    /** Load API ws health once */
    checkApiHealthWS$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ApiActions.checkApiHealthWS),
            concatMap(({ serverUrl, attempts }) => {
                return this.apiHealthWsService.checkConnection(serverUrl, attempts).pipe(
                    filter(({ inProgress }) => !inProgress),
                    take(1),
                    map((wsState: Suspense<any>) => {
                        const healthState: HealthState = {
                            isConnected: !wsState.error,
                        };
                        return wsState.error
                            ? ApiActions.loadWsHealthFailure({ serverUrl, error: wsState.error })
                            : ApiActions.loadWsHealthSuccess({ healthState, serverUrl });
                    }),
                );
            }),
        );
    });

    /** dispatch `connectionReestablished` after health back online */
    loadWsHealthSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ApiActions.loadWsHealthSuccess),
            tap(() => {
                if (
                    this.navigationService.currentUrl.includes('lock-screen') &&
                    !this.dialog.getDialogById(AUTH_SIGNIN_DIALOG_ID) &&
                    !this.platform.isElectron
                ) {
                    this.authService.authenticate();
                }

                const isDialogOpened = this.navigationService.hasOpenOutlet('dialog', 'diagnostics');
                if (isDialogOpened) {
                    this.navigationService.closeOutlet('dialog', null);
                    this.dialog.closeAll();
                }
            }),
            map(payload => ApiActions.loadApiHealthSuccess(payload)),
        );
    });

    /**
     * reacts on API health failure
     *
     * shows dialog only for base (core) url and for routes without skip attrs
     */
    loadWsHealthFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ApiActions.loadWsHealthFailure),
            concatLatestFrom(() => [
                // INFO: joins ONLINE state for feature to not block if JUST OFFLINE
                this.store$.select(ApiSelectors.selectApiHealthIsOnline),
                this.store$.select(RouterSelectors.selectUrl),
                this.store$.select(RouterSelectors.selectRouteData),
            ]),
            tap(([health, online, currentUrl, routeData]) => {
                this.lastFailedUrl = health.serverUrl;
                const isCoreWsUrl = this.configService.get('api.coreWsUrl') === health.serverUrl;
                const isDialogOpened = this.navigationService.hasOpenOutlet('dialog', 'diagnostics');
                if (!isCoreWsUrl || isDialogOpened || !currentUrl || !!routeData['allowOffline']) {
                    return;
                }
                if (this.dialog.getDialogById(AUTH_SIGNIN_DIALOG_ID)) {
                    this.authService.authenticateClose(null);
                }
                this.dialog.closeAll();
                this.navigationService.navigateToAuxilaryOutlet(['diagnostics', 'server-unavailable'], 'dialog', {
                    data: {
                        closeOnNavigation: false,
                    },
                });
            }),
            map(([health]) => ApiActions.loadApiHealthFailure(health)),
        );
    });

    /**
     * Loads API health periodically
     *
     * 1) Triggered by `init` & `connectionReestablished` actions
     * 2) Waits for config being loaded & network online status
     * 3) Timer runs only if online
     */
    initPeriodicCheck$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ApiActions.initPeriodicCheck, ApiActions.connectionReestablished),
            switchMap(() => this.configService.configLoaded$),
            withLatestFrom(this.configService.select('api'), this.store$.select(ApiSelectors.selectApiHealthIsOnline)),
            switchMap(([, api, online]) => {
                if (!online) {
                    return of({ type: 'API:INIT_PERIODIC_CHECK_SKIP' });
                }
                const { coreWsUrl: serverUrl } = api;
                this.logger.log('Should init timer to fetch API health');
                return timer(1000, environment.healthCheckTimeout).pipe(
                    map(() => ApiActions.checkApiHealthWS({ serverUrl, attempts: 3 })),
                );
            }),
        );
    });

    /** Force close SERVER_UNAVAILABLE_DIALOG if present */
    connectionReestablished$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ApiActions.connectionReestablished),
                tap(action => {
                    const isDialogOpened = this.navigationService.hasOpenOutlet('dialog', 'diagnostics');
                    if (isDialogOpened) {
                        this.navigationService.closeOutlet('dialog', null);
                        this.dialog.closeAll();
                    }
                }),
            ),
        { dispatch: false },
    );

    resetApiHealthWS$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ApiActions.resetApiHealthWS),
                tap(() => this.apiHealthWsService.reset()),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param actions$
     * @param store$
     * @param apiHealthService
     * @param apiHealthWsService
     * @param configService
     * @param dialog
     * @param authService
     * @param platform
     * @param navigationService
     */
    constructor(
        private actions$: Actions,
        private store$: Store<{ api: ApiHealthState }>,
        private apiHealthService: ApiHealthService,
        private apiHealthWsService: ApiHealthWsService,
        private configService: ConfigService,
        private dialog: MatDialog,
        private authService: AuthService,
        @Inject(PLATFORM) public platform: Platform,
        @Inject(NAVIGATION) private navigationService: NavigationService,
    ) {}

    /** On init effects */
    ngrxOnInitEffects(): Action {
        return ApiActions.initPeriodicCheck();
    }
}
