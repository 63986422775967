import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DataUpdateMessage, LabValue, PageableModel, ParamsModel } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { EncounterDataState, LabValuesMapByCode } from '../entities';
import {
    LabValuesAction,
    selectAllLabValues,
    selectAllLabValuesGroupByCode,
    selectLabValuesEntriesDates,
    selectLabValuesIsLoading,
    selectLabValuesPageableData,
    selectLabValuesStartDate,
} from '../state';

/**
 * Encounter lab values service
 */
@Injectable({ providedIn: 'root' })
export class LabValuesService {
    /**
     * Lab values load action
     */
    isLoading$: Observable<boolean> = this.store.select(selectLabValuesIsLoading);

    /**
     * Constructor
     *
     * @param store Store<EncounterFeatureState>
     * @param changeLogService ChangeLogService
     */
    constructor(private store: Store<EncounterDataState>, private changeLogService: ChangeLogService) {}

    /**
     * Lab values start date
     */
    getStartDate(): Observable<Date> {
        return this.store.select(selectLabValuesStartDate);
    }

    /**
     * Lab values entries dates array
     */
    getEntriesDates(): Observable<string[]> {
        return this.store.select(selectLabValuesEntriesDates);
    }

    /**
     * Lab values
     */
    getLabValues(): Observable<LabValue[]> {
        return this.store.select(selectAllLabValues);
    }

    /**
     * Select all lab values group by code
     */
    getLabValuesGroupByCode(): Observable<LabValuesMapByCode> {
        return this.store.select(selectAllLabValuesGroupByCode);
    }

    /**
     * Get Pageable Data
     */
    getPageableData(): Observable<PageableModel<LabValue>> {
        return this.store.select(selectLabValuesPageableData);
    }

    /**
     * Set Loading
     *
     * @param isLoading
     */
    setLoading(isLoading: boolean): void {
        this.store.dispatch(LabValuesAction.setLoadingAction({ isLoading }));
    }

    /**
     * Load lab values signs
     *
     * @param params
     * @param url
     */
    loadLabValues(params?: ParamsModel, url?: string): void {
        this.store.dispatch(LabValuesAction.loadLabValues({ params, url }));
    }

    /**
     * Clear lab values
     */
    clearLabValues(): void {
        this.store.dispatch(LabValuesAction.clearLabValues());
    }

    /**
     * Update LabValues
     *
     * @param updateMessage DataUpdateMessage<LabValue>
     */
    updateLabValues(updateMessage: DataUpdateMessage<LabValue>): void {
        const dataItem = {
            ...updateMessage.payload,
            id: updateMessage.modelId,
        } as LabValue;
        const data = [dataItem];
        this.store.dispatch(LabValuesAction.upsertLabValues({ data }));
    }
}
