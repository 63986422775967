import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { MedicationsState } from '../../entities';
import { medicationPrescriptionReducer } from './medication-prescription.reducer';
import { standardMedicationReducer } from './standard-medication.reducer';

export const MEDICATIONS_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<MedicationsState>>(
    'Medications reducer token',
);

export const medicationsReducer: ActionReducerMap<MedicationsState> = {
    medicationPrescriptions: medicationPrescriptionReducer,
    standardMedications: standardMedicationReducer,
};
