// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Create serializer for WorkflowType.
 * @see #/components/schemas/WorkflowTypeCreateRequest - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowTypeCreateRequest {
    /**
     * The type of the workflow type.
     *
     */
    workflow_type: string;
    /**
     * The questionnaire id.
     *
     */
    questionnaire_id: string;
    /**
     * The visibility for current values column.
     *
     */
    show_current_values?: boolean;
    /**
     * Determines if scores are attached to the answers
     *
     */
    show_scores?: boolean;
    /**
     * The visibility for the workflow type.
     *
     */
    is_visible?: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowTypeCreateRequest
 */
export namespace WorkflowTypeCreateRequest {
    /**
     * WorkflowTypeCreateRequest Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The type of the workflow type.
         * @type string (`dataFormat` is missing)
         */
        workflowType: string;
        /**
         * The questionnaire id.
         * @type string (`dataFormat` is missing)
         */
        questionnaireId: string;
        /**
         * The visibility for current values column.
         * @type boolean (`dataFormat` is missing)
         */
        showCurrentValues?: boolean;
        /**
         * Determines if scores are attached to the answers
         * @type boolean (`dataFormat` is missing)
         */
        showScores?: boolean;
        /**
         * The visibility for the workflow type.
         * @type boolean (`dataFormat` is missing)
         */
        isVisible?: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowTypeCreateRequest DTO
     * Transforms WorkflowTypeCreateRequest model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowTypeCreateRequest model */
        static toModel(obj: WorkflowTypeCreateRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowTypeCreateRequest model */
        static toApi(obj: Model): WorkflowTypeCreateRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowTypeCreateRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
