/**
 * Animation function
 *
 * @param t number
 */
export function easeInOutQuad(t: number): number {
    if (0 >= t && t >= 1) {
        throw new Error('Input must be between 0 and 1 inclusive.');
    }

    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

/**
 * Clamps a value between two inclusive limits
 *
 * @param value number
 * @param min lower limit
 * @param max upper limit
 */
export function clamp(value: number, min: number, max: number): number {
    if (isNaN(value) || isNaN(min) || isNaN(max) || max <= min) {
        throw new Error(`Invalid arguments for "clamp": ${value}, ${min}, ${max}`);
    }

    return Math.min(max, Math.max(min, value));
}

/**
 * Checks if `n` is between `start` and up to, but not including, `end`. If start is greater than end the params are swapped to support negative ranges.
 *
 * Differences from lodash:
 * - `start` and `end` are both required
 * - does not coerce falsey bounds to `0`
 *
 * @param value
 * @param start
 * @param end
 */
export function inRange(value: number, start: number, end: number): boolean {
    return value >= Math.min(start, end) && value < Math.max(start, end);
}

/**
 * The Math.random() function returns a floating-point, pseudo-random number in the range 0–1 (inclusive of 0, but not 1) with approximately uniform distribution over that range — which you can then scale to your desired range. The implementation selects the initial seed to the random number generation algorithm; it cannot be chosen or reset by the user.
 *
 * @param max
 * @returns number
 * @example
 * getRandomNumber(1) // Returns 1
 */
export function getRandomNumber(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
}
