import { Pipe, PipeTransform } from '@angular/core';
import { startOfDay } from 'date-fns';
import { EncounterHistoryEntry } from '@mona/models';
import { groupBy } from '@mona/shared/utils';

/**
 * Groups history items by days
 */
@Pipe({
    name: 'historyByDate',
})
export class HistoryByDatePipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param historyItems history items grouped by date
     */
    transform(historyItems: EncounterHistoryEntry[]): [Date, EncounterHistoryEntry[]][] {
        if (!historyItems?.length) {
            return null;
        }

        const groupedHistoryItems = groupBy(historyItems, item => startOfDay(item.date), true, false);
        return Object.entries(groupedHistoryItems)
            .map(item => [new Date(item[0]), item[1]] as [Date, EncounterHistoryEntry[]])
            .sort((a, b) => b[0].getTime() - a[0].getTime());
    }
}
