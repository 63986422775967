import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppError, MEDIA_ERRORS } from '@mona/shared/utils';
import { DialogData } from '../../models';
import { BaseDialogComponent } from '../abstract-dialog.component';

type ErrorDialogData = DialogData & { error: AppError; isCustomErrorMessage: boolean; hasContactInformation: boolean };

/**
 * Error details dialog component
 */
@Component({
    selector: 'mona-dialog',
    templateUrl: './error-details-dialog.component.html',
    styleUrls: ['./error-details-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDetailsDialogComponent extends BaseDialogComponent<ErrorDialogData> implements OnInit {
    /**
     * Is media error
     */
    isMediaError: boolean;

    /**
     * Media devices
     * Shown in case of media error
     */
    mediaDevices: MediaDeviceInfo[];

    /**
     * Media devices error
     */
    mediaDevicesError: any;

    /**
     * parsedError
     */
    get parsedError(): any {
        const { originalError = {} } = this.data.error;
        let { failedItems = null }: { failedItems?: { id: string; reason: string }[] } = originalError;
        if (failedItems) {
            failedItems = failedItems.map(errorItem => {
                let reason = errorItem.reason;
                try {
                    reason = JSON.parse(errorItem.reason);
                } catch (e) {
                    reason = errorItem.reason;
                }
                return {
                    ...errorItem,
                    reason,
                };
            });
            Object.assign(originalError, { failedItems });
        }
        return originalError;
    }

    /**
     * NG hook
     */
    ngOnInit() {
        this.isMediaError = this.data.error.errorCode === MEDIA_ERRORS.CAN_NOT_GET_MEDIA;
        if (this.isMediaError) {
            navigator.mediaDevices
                .enumerateDevices()
                .then(devices => {
                    this.mediaDevices = devices;
                    // eslint-disable-next-line no-restricted-syntax
                    console.debug(devices);
                })
                .catch(err => {
                    this.mediaDevices = err;
                    console.error(err);
                });
        }
    }
}
