import { ApiModels } from '@mona/api';
import { DailyGoal } from '@mona/models';

/**
 * Transforms api DailyGoal
 *
 * @param apiDailyGoal ApiModels.DailyGoals
 */
export const transformApiDailyGoal = (apiDailyGoal: ApiModels.DailyGoal): DailyGoal => {
    const changed = ApiModels.DailyGoal.DTO.toModel(apiDailyGoal);
    changed['date'] = changed.lastChangedAt;
    delete changed.lastChangedAt;

    return changed;
};
/**
 * Transform api DailyGoals
 *
 * @param apiDailyGoals ApiModels.DailyGoals[]
 */
export const transformApiDailyGoals = (apiDailyGoals: ApiModels.DailyGoal[]): DailyGoal[] => {
    return apiDailyGoals.map(apiDailyGoal => transformApiDailyGoal(apiDailyGoal));
};
