import { UiEchartsOption } from './chart.model';

/**
 * Get line chart theme options
 */
export const UI_ECHARTS_THEME_CONFIG = {
    dark: {
        darkMode: true,
        xAxis: { splitLine: { lineStyle: { color: '#403c69' } } },
        yAxis: { splitLine: { lineStyle: { color: '#403c69' } } },
        textStyle: { color: '#fff' },
    },
    light: {
        darkMode: false,
        xAxis: { splitLine: { lineStyle: { color: '#ebebeb' } } },
        yAxis: { splitLine: { lineStyle: { color: '#ebebeb' } } },
        textStyle: { color: 'rgba(0, 0, 0, 0.87)' },
    },
};

/**
 * Get line chart options
 */
export function getLineChartOptions(): UiEchartsOption {
    const defaultOptions: UiEchartsOption = {
        animation: false,
        legend: { show: false },
        grid: {
            left: '12px',
            right: '2%',
            bottom: '24px',
            top: '24px',
            containLabel: true,
        },
        dataZoom: [
            {
                type: 'inside',
                id: 'insideX',
                xAxisIndex: 0,
                startValue: undefined,
                endValue: undefined,
                zoomOnMouseWheel: false,
                moveOnMouseMove: false,
                moveOnMouseWheel: false,
                yAxisIndex: 0,
                filterMode: 'none',
                rangeMode: ['value', 'value'],
                throttle: 10,
            },
        ],
        tooltip: {
            show: false,
            trigger: 'axis',
            triggerOn: 'click',
            axisPointer: {
                animation: false,
            },
        },
        xAxis: {
            show: true,
            silent: true,
            animation: false,
            triggerEvent: false,
            type: 'time',
            min: 'dataMin',
            max: 'dataMax',
            axisLabel: {
                width: 80,
                showMaxLabel: true,
                formatter: '{HH}:{mm}',
            },
            splitLine: { show: true },
        },
        yAxis: {
            show: true,
            silent: true,
            triggerEvent: false,
            animation: false,
            type: 'value',
            max: function (value) {
                return value.max + 30;
            },
            splitLine: { show: true },
            boundaryGap: [0, '20%'], // percentage of adding empty values to the end of the axis
        },
    };

    return defaultOptions;
}

/**
 * Formatter callback function factory - always return HH:mm and only for DAY interval return dd.MM
 * @param interval
 */
export function getLineChartAxisFormatter(interval: number): (value: number, index: number) => string {
    return function (value: number, index: number): string {
        if (interval === 24 * 60 * 60 * 1000) {
            return '{dd}.{MM}';
        }
        return '{HH}:{mm}';
    };
}

/**
 * Converts a hex color to an rgb or rgba value
 *
 * @param hex Hex code
 * @param alpha transparency
 */
export function getChartColorRGB(hex = '#000000', alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
}
