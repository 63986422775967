<ui-info-list-item
    [attr.data-testid]="item.id || item.code"
    [attr.title]="item.description"
    [divider]="divider"
    [dense]="true"
    iconAlign="center"
>
    <mat-icon
        ui-left-content
        *ngIf="!inline"
        class="mat-icon--bicolor mona-medication__icon mona-medication-item__icon"
        [svgIcon]="icon"
    ></mat-icon>
    <div ui-title class="mona-medication-item__title">
        {{ $any(item).description }}
        <ng-container *ngIf="!inline">{{ details }}</ng-container>
        <ng-content select=".mona-medication-item--suffix"></ng-content>
    </div>
    <div ui-subtitle class="mona-medication-item__subtitle">
        <ng-container *ngIf="!inline">{{ category }}</ng-container>
        <ng-container *ngIf="inline">({{ details }})</ng-container>
    </div>
    <div ui-right-content>
        <ng-content select=".mona-medication-item--actions"></ng-content>
    </div>
</ui-info-list-item>
