// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.fe1.stage.monatest.icu/auto-open-api-spec.yaml 1.3.6
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { AdmissionAllergyIntolerance } from './admissionAllergyIntolerance';
import { AdmissionPreMedication } from './admissionPreMedication';
import { AdmissionVitalSigns } from './admissionVitalSigns';
import { AdmissionPatient } from './admissionPatient';
import { AdmissionDiagnosis } from './admissionDiagnosis';
import { AdmissionPhysicalExamination } from './admissionPhysicalExamination';
import { AdmissionAnamnesis } from './admissionAnamnesis';

/**
 * Serializer for validating patient admission data.
 * @see #/components/schemas/PatientAdmission - reference to schema in open-api-spec.yaml
 * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
 * @version 1.3.6
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PatientAdmission {
    /**
     * The RFID to authenticate the user.
     *
     */
    rfid: string;
    /**
     * Bed unique id.
     * @dataFormat uuid
     */
    bed_id: string;
    /**
     * The identifying description of the patient\'s reason for the encounter.
     *
     */
    admission_diagnosis: string;
    /**
     * Patient payload used for admission.
     *
     */
    patient?: AdmissionPatient | null;
    /**
     * The unique id of an existing patient.
     * @dataFormat uuid
     */
    patient_id?: string;
    /**
     * Vital signs payload used for admission.
     *
     */
    vital_signs: AdmissionVitalSigns | null;
    /**
     * Anamnesis payload for admission.
     *
     */
    anamnesis: AdmissionAnamnesis | null;
    /**
     * Physical examination payload for admission.
     *
     */
    physical_examination: AdmissionPhysicalExamination | null;
    /**
     * Diagnosis payload for admission.
     *
     */
    diagnoses: Array<AdmissionDiagnosis>;
    /**
     * AllergyIntolerance for Admission
     *
     */
    allergies_intolerances: Array<AdmissionAllergyIntolerance>;
    /**
     * PreMedication for Admission
     *
     */
    pre_medication?: Array<AdmissionPreMedication>;
    /**
     * Parent encounter id.
     * @dataFormat uuid
     */
    parent_encounter?: string;
    /**
     * Case id.
     *
     */
    case_id?: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PatientAdmission
 */
export namespace PatientAdmission {
    /**
     * PatientAdmission Frontend Model with camelCase properties
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The RFID to authenticate the user.
         * @type string (`dataFormat` is missing)
         */
        rfid: string;
        /**
         * Bed unique id.
         * @dataFormat uuid
         */
        bedId: string;
        /**
         * The identifying description of the patient\'s reason for the encounter.
         * @type string (`dataFormat` is missing)
         */
        admissionDiagnosis: string;
        /**
         * Patient payload used for admission.
         * @type AdmissionPatient (`dataFormat` is missing)
         */
        @Type(() => AdmissionPatient.Model) patient?: AdmissionPatient.Model | null;
        /**
         * The unique id of an existing patient.
         * @dataFormat uuid
         */
        patientId?: string;
        /**
         * Vital signs payload used for admission.
         * @type AdmissionVitalSigns (`dataFormat` is missing)
         */
        @Type(() => AdmissionVitalSigns.Model) vitalSigns: AdmissionVitalSigns.Model | null;
        /**
         * Anamnesis payload for admission.
         * @type AdmissionAnamnesis (`dataFormat` is missing)
         */
        @Type(() => AdmissionAnamnesis.Model) anamnesis: AdmissionAnamnesis.Model | null;
        /**
         * Physical examination payload for admission.
         * @type AdmissionPhysicalExamination (`dataFormat` is missing)
         */
        @Type(() => AdmissionPhysicalExamination.Model) physicalExamination: AdmissionPhysicalExamination.Model | null;
        /**
         * Diagnosis payload for admission.
         * @type Array<AdmissionDiagnosis> (`dataFormat` is missing)
         */
        @Type(() => AdmissionDiagnosis.Model) diagnoses: Array<AdmissionDiagnosis.Model>;
        /**
         * AllergyIntolerance for Admission
         * @type Array<AdmissionAllergyIntolerance> (`dataFormat` is missing)
         */
        @Type(() => AdmissionAllergyIntolerance.Model) allergiesIntolerances: Array<AdmissionAllergyIntolerance.Model>;
        /**
         * PreMedication for Admission
         * @type Array<AdmissionPreMedication> (`dataFormat` is missing)
         */
        @Type(() => AdmissionPreMedication.Model) preMedication?: Array<AdmissionPreMedication.Model>;
        /**
         * Parent encounter id.
         * @dataFormat uuid
         */
        parentEncounter?: string;
        /**
         * Case id.
         * @type string (`dataFormat` is missing)
         */
        caseId?: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PatientAdmission DTO
     * Transforms PatientAdmission model from/to API object
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PatientAdmission model */
        static toModel(obj: PatientAdmission): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PatientAdmission model */
        static toApi(obj: Model): PatientAdmission {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PatientAdmission;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
