/* eslint-disable @typescript-eslint/member-ordering */
import { ArrayDataSource, DataSource } from '@angular/cdk/collections';
import { TrackByFunction } from '@angular/core';
import { BehaviorSubject, combineLatest, isObservable, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { isEmpty, isFunction, isNullOrUndefined } from '../types';

/** FilterPredicateFn */
export type FilterPredicateFn<T> = (data: T, filter: string) => boolean;
/** FilterPredicateFn */
export type SearchPredicateFn<T> = (query: string) => Observable<T[]>;
/** SortingDataAccessorFn */
export type SortingDataAccessorFn<T> = (data: T, filter: string) => string | number;

/**
 * Checks if a data object matches the data source's filter string. By default, each data object
 * is converted to a string of its properties and returns true if the filter has
 * at least one occurrence in that string. By default, the filter string has its whitespace
 * trimmed and the match is case-insensitive. May be overridden for a custom implementation of
 * filter matching.
 *
 * @param data Data object used to check against the filter.
 * @param filter Filter string that has been set on the data source.
 * @returns Whether the filter matches against the data
 */
export const filterDataByString = (data: AnyValue, filter: string): boolean => {
    // Transform the data into a lowercase string of all property values.
    const dataStr = Object.keys(data as unknown as Record<string, any>)
        .reduce((currentTerm: string, key: string) => {
            // Use an obscure Unicode character to delimit the words in the concatenated string.
            // This avoids matches where the values of two columns combined will match the user's query
            // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
            // that has a very low chance of being typed in by somebody in a text field. This one in
            // particular is "White up-pointing triangle with dot" from
            // https://en.wikipedia.org/wiki/List_of_Unicode_characters
            return currentTerm + (data as unknown as Record<string, any>)[key] + '◬';
        }, '')
        .toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) != -1;
};

/**
 * Data source that accepts a client-side data array and includes native support of filtering and searching
 *
 * Allows for filter customization by overriding filterTermAccessor,
 * which defines how data is converted to a string for filter matching.
 */
export class FilterableDataSource<T> extends DataSource<T> {
    /** Subscriptions holder */
    private _sourceSubscription: Subscription;
    /** Stream that emits when a new data array is set on the data source. */
    private readonly _data: BehaviorSubject<T[]>;

    /** Stream emitting render data to the component (depends on ordered data changes). */
    private readonly _renderData = new BehaviorSubject<T[]>([]);

    /** Stream that emits when a new filter string is set on the data source. */
    private readonly _filter = new BehaviorSubject<string>('');

    /** Stream that emits when a new filter string is set on the data source. */
    private readonly _inProgress = new BehaviorSubject<boolean>(false);

    /**
     * Subscription to the changes that should trigger an update to the component's rendered itemss, such
     * as filtering, sorting, pagination, or base data changes.
     */
    _renderChangesSubscription: Subscription | null = null;

    /**
     * The filtered set of data that has been matched by the filter string, or all the data if there
     * is no filter. Useful for knowing the set of data the component represents.
     * For example, a 'selectAll()' function would likely want to select the set of filtered data
     * shown to the user rather than all the data.
     */
    filteredData: T[];

    /** Array of data that should be rendered by the table, where each object represents one row. */
    get data() {
        return this._data.value;
    }

    set data(data: T[]) {
        if (isObservable(data)) {
            data.pipe().subscribe(this._data);
        } else {
            data = Array.isArray(data) ? data : [];
            this._data.next(data);
        }
        // Normally the `filteredData` is updated by the re-render
        // subscription, but that won't happen if it's inactive.
        if (!this._renderChangesSubscription) {
            this.filterData(data);
        }
    }

    /**
     * Filter term that should be used to filter out objects from the data array. To override how
     * data objects match to this filter string, provide a custom function for filterPredicate.
     */
    get filter(): string {
        return this._filter.value;
    }

    set filter(filter: string) {
        this._filter.next(filter);
        // Normally the `filteredData` is updated by the re-render
        // subscription, but that won't happen if it's inactive.
        if (!this._renderChangesSubscription) {
            this.filterData(this.data);
        }
    }

    /** Indicates if search is in progress */
    get inProgress(): boolean {
        return this._inProgress.value;
    }

    /** Indicates if search is in progress */
    get inProgress$(): Observable<boolean> {
        return this._inProgress.asObservable();
    }

    /** Indicates if search is in progress */
    get isEmpty(): boolean {
        return this.filteredData.length == 0;
    }

    trackByFn: TrackByFunction<any> = (index, item) => {
        return item.id || index;
    };

    /** @ignore */
    constructor(
        initialData?: T[] | Observable<T[]>,
        filterPredicate?: FilterPredicateFn<T>,
        searchPredicate?: SearchPredicateFn<T>,
    ) {
        super();
        this._data = new BehaviorSubject<T[]>((initialData as T[]) || []);
        if (initialData) {
            this.data = initialData as any;
        }
        if (isFunction(filterPredicate)) {
            this.filterPredicate = filterPredicate;
        }
        if (isFunction(searchPredicate)) {
            this.searchPredicate = searchPredicate;
        }
        this.updateChangeSubscription();
    }

    /**
     * Subscribe to changes that should trigger an update to the consumer's rendered itemss. When the
     * changes occur, process the current state of the filter, sort, and pagination along with
     * the provided base data and send it to the component for rendering.
     */
    updateChangeSubscription() {
        // Watch for base data or filter changes to provide a filtered set of data.
        const filteredData: Observable<T[]> = combineLatest([this._data, this._filter]).pipe(
            map(([data]) => this.filterData(data)),
        );
        // Watch for filtered data changes and send the result to the component to render.
        this._renderChangesSubscription?.unsubscribe();
        this._renderChangesSubscription = filteredData.subscribe(data => this._renderData.next(data));
    }

    /**
     * Used by Consumers of this DataSource. Called when it connects to the data source.
     */
    connect() {
        if (!this._renderChangesSubscription) {
            this.updateChangeSubscription();
        }

        return this._renderData;
    }

    /**
     * Used by Consumers of this DataSource. Called when it disconnects from the data source.
     */
    disconnect() {
        this._sourceSubscription?.unsubscribe();
        this._renderChangesSubscription?.unsubscribe();
        this._renderChangesSubscription = null;
    }

    /**
     * Returns a filtered data array where each filter object contains the filter string within
     * the result of the filterTermAccessor function. If no filter is set, returns the data array
     * as provided.
     *
     * @param data
     */
    filterData(data: T[]): T[] {
        if (isFunction(this.searchPredicate) && !isEmpty(this.filter)) {
            try {
                this._inProgress.next(true);
                this.filteredData = [];
                this.searchPredicate(this.filter)
                    .pipe(take(1))
                    .subscribe(data => {
                        this.filteredData = data;
                        this._inProgress.next(false);
                        return this.filterData;
                    });
                return;
            } catch (error) {
                return [];
            }
        }
        // If there is a filter string, filter out data that does not contain it.
        // Each data object is converted to a string using the function defined by filterTermAccessor.
        // May be overridden for customization.
        this.filteredData =
            this.filter == null || this.filter === ''
                ? data
                : data.filter(obj => this.filterPredicate(obj, this.filter));

        return this.filteredData;
    }

    /**
     * Clear
     */
    clear() {
        this.filter = '';
    }

    /**
     * If there is a filter string, filter out data that does not contain it.
     * Each data object isconverted to a string using the function defined by filterTermAccessor.
     * May be overridden for customization
     *
     * By Default uses {@link filterDataByString}
     */
    filterPredicate = filterDataByString;

    /**
     * Search - NOT IMPLEMENTED, this needs to be overriden by subclasses if needed
     *
     * @param query
     */
    searchPredicate: SearchPredicateFn<T>;
}
