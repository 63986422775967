import { Pipe, PipeTransform } from '@angular/core';
import { ChangeLogEntry, Patient } from '@mona/models';

/**
 * Resolves if patient field has staged changes
 */
@Pipe({
    name: 'isPatientFieldChanged',
})
export class IsPatientFieldChangedPipe implements PipeTransform {
    /**
     * Transform implementation
     * @param fieldName keyof Patient
     * @param changes ChangeLogEntry<Patient>
     */
    transform(fieldName: keyof Patient, changes: ChangeLogEntry<Patient>[]): boolean {
        // eslint-disable-next-line no-prototype-builtins
        return !!changes?.find(change => change.payload.hasOwnProperty(fieldName));
    }
}
