<h2 mat-dialog-title class="mona-dialog__title">
    {{ data.title | translate }}
    <button
        class="mona-dialog__close"
        mat-dialog-close
        mat-icon-button
        type="button"
        id="dialog-form-submit"
        data-testid="dialog-form-submit"
        title="{{ data.cancelBtn | translate }}"
    >
        <mat-icon>close</mat-icon>
    </button>
</h2>

<ui-form
    mat-dialog-content
    [attr.id]="DIALOG_FORM_ID + (!data.elements ? '_' : '')"
    [elements]="data.elements"
    [initialValue]="data.initialValue"
    [scrollContainer]="undefined"
    class="mona-dialog__form scrollbar-visible"
    [class.d-none]="!data.elements"
></ui-form>

<div
    mat-dialog-content
    [attr.id]="DIALOG_FORM_ID + (!data.component ? '_' : '')"
    class="mona-dialog__form scrollbar-visible"
    [class.d-none]="!data.component"
>
    <ng-template cdkPortalOutlet></ng-template>
</div>

<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">{{ data.cancelBtn | translate }}</button>
    <button
        mat-raised-button
        [mat-dialog-close]="form.value"
        color="primary"
        [disabled]="form.pristine || (form.dirty && form.invalid)"
        cdkFocusInitial
        id="dialog-form-submit"
        data-testid="dialog-form-submit"
    >
        {{ data.confirmBtn | translate }}
    </button>
</div>
