import { createFeatureSelector, createSelector } from '@ngrx/store';
import { prescriptionSetAdapter, prescriptionSetsFeatureKey, PrescriptionSetState } from '../../entities';

export const selectPrescriptionSetsState = createFeatureSelector<PrescriptionSetState>(prescriptionSetsFeatureKey);

export const selectSelectedPrescriptionSetLoading = createSelector(
    selectPrescriptionSetsState,
    state => state.isLoading,
);

export const selecteActivePrescriptionSet = createSelector(
    selectPrescriptionSetsState,
    state => state.activePrescriptionSet,
);

/**
 * INFO: add comment
 * @param id
 */
export const selectedPrescriptionSetById = (id: string) =>
    createSelector(selectPrescriptionSetsState, state => state.entities[id]);

export const {
    selectIds,
    selectEntities: selectPrescriptionSetsMap,
    selectAll: selectPrescriptionSets,
    selectTotal,
} = prescriptionSetAdapter.getSelectors(selectPrescriptionSetsState);
