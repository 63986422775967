import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouteWithData } from '@environment';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from '@mona/auth';
import { FeatureFlagsGuard } from '@mona/flags';
import { NavigateClearOutletsGuard } from '@mona/shared/utils';
import { DrawerSizeEnum, UiEmptyComponent } from '@mona/ui';
import { PdmsShellComponent } from './pdms-shell.component';

/**
 * PdmsShell Routing Module
 */
@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'pdms', // FIXME: lazyload
                canActivate: [FeatureFlagsGuard, AuthGuard, NavigateClearOutletsGuard],
                canActivateChild: [NavigateClearOutletsGuard],
                data: {
                    checkFeature: 'pdms',
                },
                component: PdmsShellComponent,
                children: [
                    {
                        path: 'wards',
                        data: {
                            animation: 'WARDS',
                            layoutConfig: {
                                collapsible: true,
                                hasSidenav: false,
                                hasNavbar: true,
                                hasAppbar: false,
                            },
                        },
                        loadChildren: () => import('@mona/pdms/wards').then(m => m.PdmsWardsModule),
                    },
                    {
                        path: 'patients',
                        children: [
                            {
                                path: 'search',
                                data: {
                                    animation: 'PATIENTS',
                                    layoutConfig: {
                                        hasSidenav: false,
                                        hasNavbar: true,
                                        hasAppbar: false,
                                        canGoBack: 'sideNav',
                                        title: 'apps.admission.screenTitle',
                                        subtitle: '',
                                        size: DrawerSizeEnum.MEDIUM,
                                        bgImageUrl: 'assets/images/admission.svg',
                                    },
                                    permissions: {
                                        only: 'patient_admission_edit', // FIXME: use new permissions for search access
                                    },
                                },
                                loadChildren: () =>
                                    import('@mona/pdms/patient-admission-search').then(
                                        m => m.PdmsPatientAdmissionSearchModule,
                                    ),
                            },
                            {
                                path: 'review',
                                data: {
                                    animation: 'PATIENTS',
                                    layoutConfig: {
                                        hasSidenav: true,
                                        hasNavbar: true,
                                        hasAppbar: false,
                                        canGoBack: null,
                                        title: 'apps.patient.patientSearch.screenTitle',
                                        subtitle: 'apps.patient.patientSearch.screenReviewSubTitle',
                                        size: DrawerSizeEnum.MEDIUM,
                                        bgImageUrl: 'assets/images/admission.svg',
                                    },
                                },
                                loadChildren: () =>
                                    import('@mona/pdms/patient-search').then(m => m.PdmsPatientSearchModule),
                            },
                            // {
                            //     path: 'admission',
                            //     canActivate: [NgxPermissionsGuard],
                            //     data: {
                            //         layoutConfig: {
                            //             hasSidenav: true,
                            //             hasNavbar: true,
                            //             hasAppbar: false,
                            //             canGoBack: 'sideNav',
                            //             title: 'apps.admission.screenTitle',
                            //             subtitle: '',
                            //             size: DrawerSizeEnum.LARGE,
                            //             bgImageUrl: 'assets/images/admission.svg',
                            //         },
                            //         permissions: {
                            //             only: 'patient_admission_edit',
                            //         },
                            //     },
                            //     loadChildren: () => import('@mona/pdms/admission').then(m => m.PdmsAdmissionModule),
                            // },
                        ],
                    },
                    {
                        path: 'encounter',
                        loadChildren: () => import('@mona/pdms/encounter').then(m => m.PdmsEncounterModule),
                    },
                    {
                        // FIXME: this was needed to reload encounter on sameurl navigation
                        path: 'empty',
                        component: UiEmptyComponent,
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'wards',
                    },
                ],
            },
        ] as RouteWithData[]),
    ],
})
export class PdmsShellRoutingModule {}
