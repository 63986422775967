import { ApiModels } from '@mona/api';
import { PreMedication } from '@mona/models';

/**
 * Transforms API pre-medication
 * @param apiPreMedication ApiModels.PreMedication
 */
export const transformApiPreMedication = (apiPreMedication: ApiModels.Premedication): PreMedication => {
    return {
        id: apiPreMedication.id,
        description: apiPreMedication.description,
        code: apiPreMedication.code,
        dose: +apiPreMedication.dose || undefined, // 0,
        unit: apiPreMedication.unit || undefined, // 'not set',
        frequency: apiPreMedication.frequency || undefined, // 'not set',
        patientId: apiPreMedication.patient_id,
        encounterId: apiPreMedication.encounter_id,
        authorId: apiPreMedication.author_id,
    };
};

/**
 * Transforms API pre medications
 * @param apiPreMedications ApiModels.PreMedication[]
 */
export const transformApiPreMedications = (apiPreMedications: ApiModels.Premedication[]): PreMedication[] => {
    return apiPreMedications.map(apiPreMedication => transformApiPreMedication(apiPreMedication));
};
