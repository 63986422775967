import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValuableForm } from '../../models';

/**
 * Valuable Status Form
 */
@Component({
    selector: 'app-valuable-form',
    templateUrl: './valuable-form.component.html',
    styleUrls: ['./valuable-form.component.scss'],
})
export class ValuableFormComponent {
    /**
     * Form group
     */
    @Input() formGroup: FormGroup<ValuableForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is form active / visible
     */
    @Input() isActive: boolean;

    /**
     * Max textarea rows count size
     */
    @Input() textareaMaxRows = 32;

    /**
     * Keyboard enter clicked
     */
    @Output() keyboardEnterClicked: EventEmitter<void> = new EventEmitter();
}
