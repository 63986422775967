// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Used to generate api-spec with StandardMedicationSetSerializer.
 * @see #/components/schemas/StandardMedicationSetRequest - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface StandardMedicationSetRequest {
    /**
     * Mona medication unique code.
     * @dataFormat uuid
     */
    medication_code: string;
    /**
     * loinc code
     *
     */
    frequency: string;
    frequency_times: Array<string>;
    /**
     * The code-value from a code system (LOINC, SNOMED, Custom).
     *
     */
    method?: string | null;
    amount?: number | null;
    unit_amount?: string | null;
    solution_code?: string | null;
    volume?: number | null;
    unit_volume?: string | null;
    rate?: number | null;
    unit_rate?: string | null;
    /**
     * Medication Dosage Form Id.
     * @dataFormat uuid
     */
    dosage_form?: string | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for StandardMedicationSetRequest
 */
export namespace StandardMedicationSetRequest {
    /**
     * StandardMedicationSetRequest Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Mona medication unique code.
         * @dataFormat uuid
         */
        medicationCode: string;
        /**
         * loinc code
         * @type string (`dataFormat` is missing)
         */
        frequency: string;
        frequencyTimes: Array<string>;
        /**
         * The code-value from a code system (LOINC, SNOMED, Custom).
         * @type string (`dataFormat` is missing)
         */
        method?: string | null;
        amount?: number | null;
        unitAmount?: string | null;
        solutionCode?: string | null;
        volume?: number | null;
        unitVolume?: string | null;
        rate?: number | null;
        unitRate?: string | null;
        /**
         * Medication Dosage Form Id.
         * @dataFormat uuid
         */
        dosageForm?: string | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * StandardMedicationSetRequest DTO
     * Transforms StandardMedicationSetRequest model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend StandardMedicationSetRequest model */
        static toModel(obj: StandardMedicationSetRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend StandardMedicationSetRequest model */
        static toApi(obj: Model): StandardMedicationSetRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as StandardMedicationSetRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
