import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { KeyboardModule } from '@mona/keyboard';
import { UtilsModule } from '@mona/shared/utils';
import { UiSpacerModule } from '../../../utils';
import { UiDatePickerModule } from '../date-picker/date-picker.module';
import { UiDateTimePickerComponent } from './date-time-picker.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [UiDateTimePickerComponent],
    imports: [
        UtilsModule,
        UiDatePickerModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatTooltipModule,
        NgxMaskModule.forChild(),
        UiSpacerModule,
    ],
    exports: [UiDateTimePickerComponent],
})
export class UiDateTimePickerModule {}
