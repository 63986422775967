import { ApiModels } from '@mona/api';
import { MedicationAdministration } from '@mona/models';
import { compactObject } from '@mona/shared/utils';

/**
 * Transforms api medication administrations
 *
 * @param apiMedicationAdministration ApiModels.MedicationAdministration
 */
export const transformApiMedicationAdministration = (
    apiMedicationAdministration: ApiModels.MedicationAdministration,
): MedicationAdministration => {
    const administration = {
        id: apiMedicationAdministration.id as any,
        patientId: apiMedicationAdministration.patient_id as any,
        encounterId: apiMedicationAdministration.encounter_id as any,
        prescriptionId: apiMedicationAdministration.prescription_id as any,
        category: apiMedicationAdministration.category,
        code: apiMedicationAdministration.code,
        description: apiMedicationAdministration.description,
        dosageForm: apiMedicationAdministration.dosage_form,
        amount: apiMedicationAdministration.amount,
        unitAmount: apiMedicationAdministration.unit_amount,
        unitAmountCode: apiMedicationAdministration.unit_amount_code,
        rate: apiMedicationAdministration.rate,
        unitRate: apiMedicationAdministration.unit_rate,
        unitRateCode: apiMedicationAdministration.unit_rate_code,
        volume: apiMedicationAdministration.volume,
        unitVolume: apiMedicationAdministration.unit_volume,
        unitVolumeCode: apiMedicationAdministration.unit_volume_code,
        solutionCode: apiMedicationAdministration.solution_code,
        method: apiMedicationAdministration.method,
        startDate: apiMedicationAdministration.start_date && new Date(apiMedicationAdministration.start_date),
        endDate: apiMedicationAdministration.end_date && new Date(apiMedicationAdministration.end_date),
        status: apiMedicationAdministration.status,
        statusReason: apiMedicationAdministration.status_reason,
        bloodBatchNumber: apiMedicationAdministration.blood_batch_number,
        bloodAdministrationReason: apiMedicationAdministration.blood_administration_reason as string,
        lastChangedAt: new Date(apiMedicationAdministration.last_changed_at),
        lastChangedBy: apiMedicationAdministration.last_changed_by,
    };

    return compactObject(administration) as MedicationAdministration;
};

/**
 * Transform api medication administrations
 *
 * @param apiMedicationAdministrations ApiModels.MedicationAdministration[]
 */
export const transformApiMedicationAdministrations = (
    apiMedicationAdministrations: ApiModels.MedicationAdministration[],
): MedicationAdministration[] => {
    return apiMedicationAdministrations.map(apiMedicationAdministration =>
        transformApiMedicationAdministration(apiMedicationAdministration),
    );
};
