/**
 * Checks if Daylight Saving Time change happens on this day
 *
 * @tutorial https://stackoverflow.com/a/30280636/4115894
 * @param d start of day
 */
export function isDstInUse(d: Date): boolean {
    const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();
}

/**
 * Checks if Daylight Saving Time change happens on this day
 *
 * @param date start of day
 */
export const isDstChangeDay = (date: Date): boolean => {
    return date.getTimezoneOffset() !== new Date(new Date(date).setHours(23, 59, 59, 999)).getTimezoneOffset();
};
