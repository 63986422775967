import type { NavigationEnd, NavigationStart, Params, RouterState } from '@angular/router';
import { isNullOrUndefined } from '../types';
import { escapeRegExp } from './string.helpers';

type OutletName = 'side' | 'dialog' | 'overlay';

/**
 * Match auxilary outlet route by navigation url string
 *
 * @param url
 * @param outlet
 */
export function outletRouteMatcher(url: string, outlet: OutletName = 'side') {
    const reg1 = new RegExp(escapeRegExp(`(${outlet}:`));
    const reg2 = new RegExp(escapeRegExp(`//${outlet}:`));
    return !!(url.match(reg1) || url.match(reg2));
}

/**
 * Get activated route by path
 *
 * @param routerState
 * @param path
 */
export function getActivatedRouteByPath(routerState: RouterState, path = '') {
    return routerState.root.children.find(r => r.routeConfig?.path === path);
}

/**
 * Is route new by id
 *
 * @param params
 */
export function isNewById(params: Params) {
    return !isNullOrUndefined(params['id']) && params.id === 'new';
}

/**
 * Hide route backlink by params
 *
 * @param params
 */
export function hideBacklinkByParams(params: Params): boolean {
    return params?.['id'] === 'new' || params?.['id'] === 'current';
}
