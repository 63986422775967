/* eslint-disable @angular-eslint/no-inputs-metadata-property, @typescript-eslint/ban-ts-comment */
import { Directionality } from '@angular/cdk/bidi';
import {
    CdkScrollable,
    CdkVirtualForOf,
    CdkVirtualScrollable,
    CdkVirtualScrollRepeater,
    CdkVirtualScrollViewport,
    ScrollDispatcher,
    ViewportRuler,
    VIRTUAL_SCROLL_STRATEGY,
    VIRTUAL_SCROLLABLE,
} from '@angular/cdk/scrolling';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    NgZone,
    OnInit,
    Optional,
    Output,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { animationFrameScheduler, BehaviorSubject, fromEvent, Observable, Observer, of, Subject } from 'rxjs';
import {
    auditTime,
    concatMap,
    debounceTime,
    distinctUntilChanged,
    elementAt,
    filter,
    finalize,
    map,
    pairwise,
    switchMap,
    takeUntil,
    throttleTime,
} from 'rxjs/operators';
import { filterScrolledByDirection, ScrollService, SimpleScrollToOptions } from '../../../../services';
import {
    DEFAULT_COLUMN_WIDTH,
    TableScrollStrategyEnum,
    TABLE_SCROLL_STRATEGY_TYPE,
    VHSTableScrollStrategy,
} from '../../strategies';

/** Scroll delta values  */
interface ScrollDelta {
    /** horizontal diff in px */
    deltaX: number;
    /** vertical diff in px */
    deltaY: number;
    /** Event */
    event?: Event;
}

/**
 * Check columns equal
 *
 * @param a
 * @param b
 */
const columnsEqualFn = (a: readonly any[], b: readonly any[]): boolean =>
    a?.length == b?.length && a[0]?.name == b[0]?.name;

/**
 * An extended  {@link CdkVirtualScrollViewport} that virtualizes its horizontal scrolling with the help of `CdkVirtualForOf`
 * and 2 different {@link VHSTableScrollStrategy} implementations: scroll by viewport only (prevents mouse/touch events), or scroll by default scroll events
 *
 * @augments CdkVirtualScrollViewport
 *
 * We extend original CdkVirtualScrollViewport to prevent `scroll` event listeners
 * and manually control horizontal scroll & events that trigger ChangeDetection
 *
 * INFO: we do a lot of `@ts-ignore` here to use private properties and methods from super class CdkVirtualScrollViewport that were not publicly exposed, and we don't want to duplicate functionality
 */
@Component({
    selector: 'ui-vhs-viewport',
    templateUrl: './vhs-viewport.component.html',
    styleUrls: ['./vhs-viewport.component.scss'],
    host: {
        role: 'region',
    },
    inputs: ['columnWidth', 'stickyColumnWidth'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: CdkVirtualScrollViewport,
            useExisting: VHSVirtualScrollViewport,
        },
    ],
})
// @ts-ignore
export class VHSVirtualScrollViewport extends CdkVirtualScrollViewport implements OnInit {
    /** The direction the viewport scrolls. */
    private _orientation = 'horizontal';
    /** The width of the viewport to be set */
    width: number;
    /** The height of the viewport to be set */
    height: number;
    /** column width binding from directive */
    columnWidth!: number;
    /** sticky column width binding from directive */
    stickyColumnWidth!: number;
    /** Number of items in viewport as Subject, calculated on viewport `setTotalContentSize` method */
    private _itemsInViewport = new BehaviorSubject(0);
    /** Number of items in viewport  */
    get itemsInViewport(): number {
        return this._itemsInViewport.getValue();
    }
    /** Number of items in viewport as Observable  */
    get itemsInViewport$(): Observable<number> {
        return this._itemsInViewport.pipe(distinctUntilChanged());
    }
    /** Currently attached CdkVirtualScrollRepeater. */
    get forOf(): CdkVirtualForOf<any> {
        return this['_forOf'];
    }
    /** Number of rendered items */
    get renderedItemsLength(): number {
        return (this.forOf as any)?._renderedItems?.length;
    }
    /** Observable that emits when a scroll event is fired on the host element */
    override _elementScrolled = new Observable((observer: Observer<Event>) =>
        this.ngZone.runOutsideAngular(() =>
            fromEvent(this.elementRef.nativeElement, 'scroll', { passive: true })
                .pipe(takeUntil(this._destroyed))
                .subscribe(observer),
        ),
    );
    /** Stream that emits scroll event for vertical scrolling event */
    @Output() verticalScrolled: EventEmitter<Event> = this._elementScrolled.pipe(
        auditTime(0),
        filterScrolledByDirection('scrollTop'),
    ) as EventEmitter<Event>;
    /** Stream that emits scroll event for horizontal scrolling event */
    @Output() horizontalScrolled: EventEmitter<Event> = this._elementScrolled.pipe(
        filterScrolledByDirection('scrollLeft'),
    ) as EventEmitter<Event>;
    /** Hotizontal offset of rendered content to start of vireport */
    @Output() offsetChange: EventEmitter<number> = new EventEmitter();
    /** ⚠️ Mirrors `cdkVirtualForOf`, uses `BehaviorSubject` to always emit first pair */
    private readonly _columnsChanged = new BehaviorSubject<ReadonlyArray<any>>([]);
    /** 🔃 Emits [prev, curr] pair whenever the data in the cdkVirtualForOf dataSource changes */
    get columnsChanged(): Observable<[readonly any[], readonly any[]]> {
        return this._columnsChanged.pipe(pairwise());
    }
    /** Audit time for scroll event throtling */
    private auditTimeMs = 50;
    /** Unsubscribe Subject */
    private readonly _destroyed = new Subject<void>();
    /** Unsubscribe Subject */
    private readonly _isScrolling = new Subject<boolean>();
    /** Unsubscribe Subject */
    readonly isScrolling$ = this._isScrolling.asObservable();

    /**
     * Constructor
     *
     * @param elementRef
     * @param _changeDetectorRef
     * @param ngZone
     * @param _scrollStrategy
     * @param dir
     * @param scrollDispatcher
     * @param viewportRuler
     * @param scrollService
     * @param renderer
     * @param scrollStrategyType
     * @param scrollable
     */
    constructor(
        public readonly elementRef: ElementRef<HTMLElement>,
        _changeDetectorRef: ChangeDetectorRef,
        ngZone: NgZone,
        @Optional()
        @Inject(VIRTUAL_SCROLL_STRATEGY)
        protected _scrollStrategy: VHSTableScrollStrategy,
        @Optional() dir: Directionality,
        scrollDispatcher: ScrollDispatcher,
        viewportRuler: ViewportRuler,
        @Inject(ScrollService) protected scrollService: ScrollService,
        private renderer: Renderer2,
        @Inject(TABLE_SCROLL_STRATEGY_TYPE) private readonly scrollStrategyType: TableScrollStrategyEnum,
        @Optional() @Inject(VIRTUAL_SCROLLABLE) public scrollable: CdkVirtualScrollable,
    ) {
        super(elementRef, _changeDetectorRef, ngZone, _scrollStrategy, dir, scrollDispatcher, viewportRuler, null);

        // @ts-expect-error: Used in order to bypass
        this.scrollable = this;
    }

    /** Lifecycle @override */
    override ngOnInit() {
        this.ngZone.runOutsideAngular(() =>
            Promise.resolve().then(() => {
                this._measureViewportSize();
                (this as any)._scrollStrategy.attach(this);

                /**
                 * ℹ️ Re-assign observable & add event listeners
                 * based on scroll strategy type
                 */

                if (this.isStrategyViewport()) {
                    this.auditTimeMs = 0;
                    this.horizontalScrolled = new EventEmitter<Event>();
                    this.handleWheelEvents();
                    this.handleSwipeEvents();
                    /** Set `touch-action: pan-y` css style for viewport scroll */
                    this.renderer.setStyle(this.elementRef.nativeElement, 'touch-action', 'pan-y');
                }

                /*
                 * Filter only **horizontal** scroll event fired on the host element,
                 * subscribe to `elementScrolled` & re-emit to scrollStrategy
                 */
                this.horizontalScrolled
                    .pipe(
                        // tap(() => this._isScrolling.next(true)),
                        debounceTime(this.auditTimeMs, animationFrameScheduler),
                    )
                    .subscribe(event => {
                        this._scrollStrategy.onContentScrolled();
                        // this._isScrolling.next(false)
                    });
                (this as any)._markChangeDetectionNeeded();
            }),
        );
    }

    /**
     * Attaches a `CdkVirtualScrollRepeater` to this viewport - `super`.
     * Custom - starts mirroring datastream to public `columnsChanged` observable
     *
     * @param forOf
     * @override
     */
    override attach(forOf: CdkVirtualScrollRepeater<any>) {
        super.attach(forOf);
        this.forOf.dataStream.subscribe(this._columnsChanged);
    }

    /**
     * Scrolls to 1 "page" (size of the viewport) left or right
     *
     * @param pageCount direction or horizontal delta
     * @returns Observable from {@link ScrollService.scrollTo} because we need to switchmap to this later
     */
    scrollViewport(pageCount = 1): Observable<any> {
        if (pageCount === 0) {
            return of();
        }
        const _startX = Math.ceil(this.elementRef.nativeElement.scrollLeft);
        const left = Math.max(0, _startX + (this.itemsInViewport - 1) * this.columnWidth * pageCount);
        return this.scrollTo({ left });
    }

    /**
     * Scrolls to the specified offsets using {@link ScrollService.scrollTo}
     * Emits event to trigger viewport & scrollStrategy calculations
     *
     * @param simpleScrollToOptions SimpleScrollToOptions
     * @param simpleScrollToOptions.left
     * @param simpleScrollToOptions.duration
     */
    scrollTo({ left, duration = 500 }: SimpleScrollToOptions) {
        return this.scrollService
            .scrollTo(this.elementRef.nativeElement, {
                top: this.elementRef.nativeElement.scrollTop,
                left,
                duration,
            })
            .pipe(
                finalize(() => {
                    this.horizontalScrolled.next?.();
                }),
            );
    }

    /**
     * Scrolls to the offset for the given index.
     *
     * @param index The index of the element to scroll to.
     */
    override scrollToIndex(index: number) {
        if (index < 0) {
            return;
        }
        index = Math.min(this.getDataLength(), index);
        const left = Math.max(index - 1, 0) * (this.columnWidth || DEFAULT_COLUMN_WIDTH);
        this.scrollTo({ left, duration: 0 }).subscribe();
    }

    /**
     * Measure the viewport size is overriden to not get the viewport size from clientWidth
     *
     * @override
     */
    override _measureViewportSize() {
        const viewportSize = this.getViewportSize();
        if (!this.width || (this.width > 0 && viewportSize === this.width)) {
            return;
        }

        this['_viewportSize'] = this.width;
    }

    /**
     * Sets the total size of all content (in pixels), including content that is not currently
     * rendered.
     *
     * @param size
     */
    override setTotalContentSize(size: number): void {
        super.setTotalContentSize(size);
        this._measureViewportSize();
    }

    /** Update the viewport dimensions and re-render - is overriden to assign itemsInViewport for use in scrollStrategy & table */
    override checkViewportSize() {
        this._measureViewportSize();
        const actualViewportSize = this.getViewportSize() - this.stickyColumnWidth;
        const itemsInViewport = Math.ceil(Math.max(0, actualViewportSize) / this.columnWidth);
        this._itemsInViewport.next(itemsInViewport);
        this._scrollStrategy.onDataLengthChanged();
    }

    /**
     * Sets the offset from the start of the viewport to either the start or end of the rendered data
     * (in pixels).
     *
     * @param offset
     * @param to
     */
    override setRenderedContentOffset(offset: number, to?: 'to-start' | 'to-end'): void {
        super.setRenderedContentOffset(offset, to);
        this.offsetChange.emit(offset);
    }

    /** Handle wheel events */
    private handleWheelEvents() {
        const onWheel$ = fromEvent<
            WheelEvent & {
                wheelDeltaX?: number;
                wheelDeltaY?: number;
            }
        >(this.elementRef.nativeElement, 'wheel', { passive: false });
        const onWheelSub = onWheel$
            .pipe(
                throttleTime(150),
                filter(event => !!event.wheelDeltaX),
                map(event => ({
                    deltaX: event.wheelDeltaX ?? 0,
                    deltaY: event.wheelDeltaY ?? 0,
                    event,
                })),
                switchMap(({ deltaX, event }) => {
                    event?.cancelable && event.preventDefault();
                    return this.scrollViewport(-Math.sign(deltaX));
                }),
                takeUntil(this._destroyed),
            )
            .subscribe();
    }

    /** Is strategy default  */
    isStrategyDefault(): boolean {
        return this.scrollStrategyType === TableScrollStrategyEnum.default;
    }
    /** Is strategy viewport  */
    isStrategyViewport(): boolean {
        return this.scrollStrategyType === TableScrollStrategyEnum.viewport;
    }

    /** Handle swipe events */
    private handleSwipeEvents() {
        const onTouchStart$ = fromEvent<TouchEvent>(this.elementRef.nativeElement, 'touchstart');
        const onTouchMove$ = fromEvent<TouchEvent>(this.elementRef.nativeElement, 'touchmove');
        const onTouchEnd$ = fromEvent<TouchEvent>(this.elementRef.nativeElement, 'touchend');
        const onTouchSub = onTouchStart$
            .pipe(
                auditTime(0, animationFrameScheduler),
                // Move starts with direction: Pair the move start events with the 3rd subsequent move event,
                // but only if no end event happens in between
                concatMap(startEvent =>
                    onTouchMove$.pipe(
                        elementAt(3),
                        map(event => {
                            const deltaX = event.touches[0].pageX - startEvent.touches[0].pageX;
                            const deltaY = event.touches[0].pageY - startEvent.touches[0].pageY;
                            return {
                                deltaX,
                                deltaY,
                                event,
                            };
                        }),
                        takeUntil(onTouchEnd$),
                    ),
                ),
                // Horizontal move starts: Keep only those move start events,
                // where the 3rd subsequent move event is rather horizontal than vertical
                filter(scrollEvent => Math.abs(scrollEvent.deltaX) >= Math.abs(scrollEvent.deltaY)),
                switchMap(({ deltaX, event }) => {
                    event?.cancelable && event.preventDefault();
                    return this.scrollViewport(-Math.sign(deltaX));
                }),
                takeUntil(this._destroyed),
            )
            .subscribe();
    }
}
