<fieldset class="mona-medication__form mona-medication__form-fieldset" [formGroup]="formGroup">
    <!-- AMOUNT -->
    <mat-form-field
        id="amount"
        class="app-input-field app-input-field--value"
        [visibleIf]="getFieldStatusFromConfig('amount', selectedCategoryCode, medicationCategories)"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.amount.label' | translate }}
        </mat-label>
        <input
            #amountInput
            nextTabElementSelector=".base-medication-volume"
            matInput
            matInputCommaDot
            data-type="number"
            class="app-set-medication__input"
            [placeholder]="'apps.settings.medications.fields.amount.label' | translate"
            [formControl]="controls.amount"
        />
        <mat-error>
            <ui-validation-message
                [control]="controls.amount"
                [enrichedTemplateForMinValue]="minValueTemplate"
                [enrichedTemplateForMaxValue]="maxValueTemplate"
            ></ui-validation-message>

            <ng-template #minValueTemplate>
                <mat-icon class="mona-server-url-dialog__verify-icon">warning</mat-icon>
                {{ 'formValidation.min' | translate: { minValue: 0.01 } }}
            </ng-template>
            <ng-template #maxValueTemplate>
                <mat-icon class="mona-server-url-dialog__verify-icon">warning</mat-icon>
                {{ 'formValidation.max' | translate: { maxValue: 999999.99 } }}
            </ng-template>
        </mat-error>
    </mat-form-field>
    <mat-form-field
        id="unitAmount"
        class="app-input-field app-input-field--unit"
        [visibleIf]="getFieldStatusFromConfig('amount', selectedCategoryCode, medicationCategories)"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.unitAmount.label' | translate }}
        </mat-label>
        <mat-select
            id="unitAmount"
            [formControl]="controls.unitAmount"
            panelClass="app-input-select-panel scrollbar-visible"
            [value]="controls.unitAmount.value"
        >
            <mat-option [value]="null" disabled>
                {{ 'apps.settings.medications.fields.unitAmount.desctiption' | translate }}
            </mat-option>
            <mat-option
                *ngFor="
                    let item of medicationUnits | uiMapper: filterMedicationUnits:selectedCategoryCode;
                    let i = index;
                    trackBy: trackUnitsFn
                "
                [value]="item.unit"
            >
                {{ item.unit }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.unitAmount"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- DOSAGE FORM -->
    <mat-form-field
        id="dosageForm"
        class="app-input-field app-input-field--fullwidth"
        appearance="fill"
        [visibleIf]="getFieldStatusFromConfig('dosageForm', selectedCategoryCode, medicationCategories)"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.dosageForm.label' | translate }}
        </mat-label>
        <mat-select [formControl]="controls.dosageForm" panelClass="app-input-select-panel scrollbar-visible">
            <mat-option disabled>
                {{ 'apps.settings.medications.fields.dosageForm.label' | translate }}
            </mat-option>
            <mat-option *ngFor="let dosageForm of medicationDosageForms" [value]="dosageForm.code">
                {{ dosageForm.displayName }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.unitVolume"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- VOLUME -->
    <mat-form-field
        [visibleIf]="getFieldStatusFromConfig('volume', selectedCategoryCode, medicationCategories)"
        class="app-input-field app-input-field--value"
        id="volume"
        appearance="fill"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.volume.label' | translate }}
        </mat-label>
        <input
            #volumeInput
            nextTabElementSelector=".base-medication-rate"
            class="base-medication-volume"
            [formControl]="controls.volume"
            matInputCommaDot
            data-type="number"
            matInput
        />
        <mat-error>
            <ui-validation-message
                [control]="controls.volume"
                [enrichedTemplateForMinValue]="minValueTemplate"
                [enrichedTemplateForMaxValue]="maxValueTemplate"
            ></ui-validation-message>

            <ng-template #minValueTemplate>
                <mat-icon class="mona-server-url-dialog__verify-icon">warning</mat-icon>
                {{ 'formValidation.min' | translate: { minValue: 0.01 } }}
            </ng-template>
            <ng-template #maxValueTemplate>
                <mat-icon class="mona-server-url-dialog__verify-icon">warning</mat-icon>
                {{ 'formValidation.max' | translate: { maxValue: 999999.99 } }}
            </ng-template>
        </mat-error>
    </mat-form-field>

    <mat-form-field
        id="unitVolume"
        [visibleIf]="getFieldStatusFromConfig('volume', selectedCategoryCode, medicationCategories)"
        class="app-input-field app-input-field--unit"
        appearance="fill"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.unitVolume.label' | translate }}
        </mat-label>
        <mat-select
            id="unitVolume"
            [formControl]="controls.unitVolume"
            panelClass="app-input-select-panel scrollbar-visible"
        >
            <mat-option disabled>
                {{ 'apps.settings.medications.fields.unitVolume.desctiption' | translate }}
            </mat-option>
            <mat-option
                *ngFor="
                    let unit of medicationUnits | uiMapper: filterMedicationUnits:selectedCategoryCode;
                    let i = index;
                    trackBy: trackUnitsFn
                "
                [value]="unit.unit"
            >
                {{ unit.unit }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.unitVolume"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- RATE -->
    <mat-form-field
        id="rate"
        class="app-input-field app-input-field--value"
        appearance="fill"
        [visibleIf]="getFieldStatusFromConfig('rate', selectedCategoryCode, medicationCategories)"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.rate.label' | translate }}
        </mat-label>
        <input
            #rateInput
            class="base-medication-rate"
            [formControl]="controls.rate"
            matInput
            matInputCommaDot
            data-type="number"
        />
        <mat-error>
            <ui-validation-message [control]="controls.rate"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <mat-form-field
        id="unitRate"
        class="app-input-field app-input-field--unit"
        appearance="fill"
        [visibleIf]="getFieldStatusFromConfig('rate', selectedCategoryCode, medicationCategories)"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.unitRate.label' | translate }}
        </mat-label>
        <mat-select
            id="unitRate"
            [formControl]="controls.unitRate"
            panelClass="app-input-select-panel scrollbar-visible"
        >
            <mat-option [value]="null" disabled>
                {{ 'apps.settings.medications.fields.unitRate.desctiption' | translate }}
            </mat-option>
            <mat-option
                *ngFor="
                    let unit of medicationUnits
                        | uiMapper: filterMedicationUnits:selectedCategoryCode
                        | uiMapper: filterRateUnits;
                    let i = index;
                    trackBy: trackUnitsFn
                "
                [value]="unit.unit"
            >
                {{ unit.unit }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message
                [control]="controls.unitRate"
                [enrichedTemplateForMinValue]="minValueTemplate"
                [enrichedTemplateForMaxValue]="maxValueTemplate"
            ></ui-validation-message>

            <ng-template #minValueTemplate>
                <mat-icon class="mona-server-url-dialog__verify-icon">warning</mat-icon>
                {{ 'formValidation.min' | translate: { minValue: 0.01 } }}
            </ng-template>
            <ng-template #maxValueTemplate>
                <mat-icon class="mona-server-url-dialog__verify-icon">warning</mat-icon>
                {{ 'formValidation.max' | translate: { maxValue: 999999.99 } }}
            </ng-template>
        </mat-error>
    </mat-form-field>

    <!-- FREQUENCY -->
    <mat-form-field
        id="frequency"
        class="app-input-field app-input-field--fullwidth"
        appearance="fill"
        [visibleIf]="getFieldStatusFromConfig('frequency', selectedCategoryCode, medicationCategories)"
    >
        <mat-label>
            {{ 'apps.settings.prescriptionSet.medicationTable.frequency' | translate }}
        </mat-label>
        <mat-select
            [formControl]="controls.frequency"
            [placeholder]="'apps.settings.prescriptionSet.medicationTable.frequency' | translate"
            panelClass="app-input-select-panel scrollbar-visible"
            class="app-set-medication__select"
        >
            <mat-option [value]="null" disabled>
                {{ 'apps.settings.medications.fields.frequency.desctiption' | translate }}
            </mat-option>
            <mat-option *ngFor="let item of medicationFrequencies; trackBy: trackByCode" [value]="item.code">
                {{ item.displayName }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.frequency"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- CUSTOM FREQUENCY SECTION -->
    <mona-frequency-times
        id="frequency-times"
        class="mona-medication__form--frequency-times"
        [visibleIf]="
            (getFieldStatusFromConfig('frequency', selectedCategoryCode, medicationCategories) &&
                !!controls.frequencyTimes.value?.length) ||
            controls.frequency.value === CustomFrequencyCode
        "
        [disabled]="controls.frequency.status === 'DISABLED'"
        [formControl]="controls.frequencyTimes"
        [frequencyTimesList]="medicationFrequencyTimes"
        [customTimes]="controls.frequencyTimes.value"
        [frequencyCode]="controls.frequency.value"
        (setCustomFrequency)="setCustomFrequency()"
        [uiValidator]="validateFrequency(controls.frequency)"
        [createdAt]="startDate"
    >
        <ui-validation-message [control]="controls.frequencyTimes"></ui-validation-message>
    </mona-frequency-times>

    <!-- ADMINISTRATION -->
    <mat-form-field
        [visibleIf]="getFieldStatusFromConfig('method', selectedCategoryCode, medicationCategories)"
        id="method"
        class="app-input-field app-input-field--fullwidth"
        appearance="fill"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.methodOfAdministration.label' | translate }}
        </mat-label>
        <mat-select
            id="method"
            [formControl]="controls.method"
            [placeholder]="'apps.settings.medications.fields.methodOfAdministration.label' | translate"
            panelClass="app-input-select-panel scrollbar-visible"
            class="app-set-medication__select"
        >
            <mat-option [value]="null" disabled>
                {{ 'apps.settings.medications.fields.methodOfAdministration.desctiption' | translate }}
            </mat-option>
            <mat-option *ngFor="let item of medicationAdministrationMethods; trackBy: trackByCode" [value]="item.code">
                {{ item.displayName }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.method"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- SOLUTION -->
    <mat-form-field
        [visibleIf]="getFieldStatusFromConfig('solutionCode', selectedCategoryCode, medicationCategories)"
        id="solutionCode"
        class="app-input-field app-input-field--fullwidth"
        appearance="fill"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.solution.label' | translate }}
        </mat-label>
        <mat-select
            id="solution"
            [formControl]="controls.solutionCode"
            [placeholder]="'apps.settings.medications.fields.solution.label' | translate"
            panelClass="app-input-select-panel scrollbar-visible"
            class="app-set-medication__select"
        >
            <mat-option [value]="null" disabled>
                {{ 'apps.settings.medications.fields.solution.desctiption' | translate }}
            </mat-option>
            <mat-option *ngFor="let item of medicationSolutions; trackBy: trackByCode" [value]="item.code">
                {{ item.displayName }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.solutionCode"></ui-validation-message>
        </mat-error>
    </mat-form-field>
</fieldset>
