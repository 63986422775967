import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ReportType } from '@mona/models';
import { ReportsActions, ReportsSelectors } from '../state';

/**
 * Reports facade service
 */
@Injectable({
    providedIn: 'root',
})
export class ReportsFacade {
    /** Is loading */
    isLoading$: Observable<boolean> = this.store.select(ReportsSelectors.selectReportsStateIsLoading);
    /**
     * Get reports ids
     */
    reportsIds$: Observable<string[]> = this.store.select<string[]>(ReportsSelectors.selectReportsIds);
    /**
     * Get reports
     */
    reports$: Observable<ReportType[]> = this.store.select(ReportsSelectors.selectAllReports);
    /**
     * Get reports map
     */
    reportsMap$: Observable<EntityMap<ReportType>> = this.store.select(ReportsSelectors.selectReportsEntities);

    /**
     * Constructor
     *
     * @param store store
     */
    constructor(private store: Store<any>) {}

    /**
     * Dispatch load all reports
     *
     */
    loadReports(): void {
        this.store.dispatch(ReportsActions.loadReports());
    }

    /**
     * Dispatch full export report
     *
     */
    exportFullReport(): void {
        this.store.dispatch(ReportsActions.exportFullReport());
    }

    /**
     * Clear report
     */
    clearReports(): void {
        this.store.dispatch(ReportsActions.clearReports());
    }
}
