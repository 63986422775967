import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Patient } from '@mona/models';
import { pick } from '@mona/shared/utils';
import { PatientsAdmissionSelectors, PatientsAdmissionActions } from '../state';

type SearchFields = keyof Patient;

/**
 * Patients facade
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessPatientsAdmissionFacade {
    /** Is loading */
    isLoading$: Observable<boolean> = this.store.select(
        PatientsAdmissionSelectors.selectPatientsAdmissionStateIsLoading,
    );
    /*** Get current patient admission search filter*/
    searchFilter$: Observable<string> = this.store.select(PatientsAdmissionSelectors.selectPatientsAdmissionFilter);
    /** Get practitioners map */
    filteredPatients$: Observable<Patient[]> = combineLatest(
        this.store.select(PatientsAdmissionSelectors.selectAllPatientsAdmission),
        this.searchFilter$.pipe(map(searchText => searchText?.toLowerCase())),
    ).pipe(
        map(([allPatients, filter]) => {
            if (!filter) {
                return allPatients;
            }

            return allPatients.filter(patient => {
                const SEARCH_FIELDS: SearchFields[] = ['firstName', 'lastName', 'caseId', 'patientNumber'];

                return Object.values(pick(patient, ...SEARCH_FIELDS)).some((propertyValue: SearchFields) =>
                    propertyValue?.toLowerCase()?.includes(filter),
                );
            });
        }),
    );

    /**
     * Get patient by id
     *
     * @param id
     */
    getPatientById$ = (id): Observable<Patient> => this.store.select(PatientsAdmissionSelectors.selectPatientById(id));

    /**
     * Constructor
     *
     * @param {Store} store - store
     */
    constructor(private store: Store<any>) {}

    /**
     * Load patients for admission
     */
    loadAllAdmissionPatients(): void {
        this.store.dispatch(PatientsAdmissionActions.loadAllAdmissionPatients());
    }

    /**
     * Clears patients
     */
    clearPatientSearchResult() {
        this.store.dispatch(PatientsAdmissionActions.clearSearchPatientAdmission());
    }

    /**
     * Search filter changed
     * @param searchQuery
     */
    searchFilterChanged(searchQuery: string) {
        this.store.dispatch(PatientsAdmissionActions.searchFilterChanged({ searchQuery }));
    }
}
