import { ApiModels } from '@mona/api';
import { WoundStatus } from '@mona/models';

/**
 * Transforms API apiWoundStatus
 * @param apiWound ApiModels.WoundStatus
 */
export const transformApiWound = (apiWound: ApiModels.WoundStatus): WoundStatus => {
    return ApiModels.WoundStatus.DTO.toModel(apiWound);
};

/**
 * Transforms API apiWoundStatus
 * @param apiWounds ApiModels.WoundStatus[]
 */
export const transformApiWounds = (apiWounds: ApiModels.WoundStatus[]): WoundStatus[] => {
    return apiWounds.map(apiWound => transformApiWound(apiWound));
};
