import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Bed, Ward } from '@mona/models';
import { transformApiBeds } from './transforms';

/**
 * API abstraction layer for the beds API
 */
@Injectable({
    providedIn: 'root',
})
export class BedsApi {
    apiBase = '/pdms/beds/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get beds
     *
     * @param wardId
     */
    getBeds(wardId?: string): Observable<Bed[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (wardId) {
            params.ward_id = `${wardId}`;
        }

        return this.http
            .get<ApiModels.Bed[]>(this.apiBase, {
                params,
            })
            .pipe(map(beds => transformApiBeds(beds)));
    }
}
