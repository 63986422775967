// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.fe1.stage.monatest.icu/auto-open-api-spec.yaml 1.3.6
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validation the diagnosis of admission.
 * @see #/components/schemas/AdmissionDiagnosis - reference to schema in open-api-spec.yaml
 * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
 * @version 1.3.6
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionDiagnosis {
    /**
     * Diagnosis text.
     *
     */
    text: string;
    /**
     * The code of diagnosis.
     *
     */
    code?: string;
    /**
     * The status of the Diagnosis.  * `unconfirmed` - UNCONFIRMED * `provisional` - PROVISIONAL * `differential` - DIFFERENTIAL * `confirmed` - CONFIRMED * `refuted` - REFUTED * `entered-in-error` - ERROR
     *
     */
    status: AdmissionDiagnosis.StatusEnum;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionDiagnosis
 */
export namespace AdmissionDiagnosis {
    export type StatusEnum =
        | 'unconfirmed'
        | 'provisional'
        | 'differential'
        | 'confirmed'
        | 'refuted'
        | 'entered-in-error';
    export const StatusEnum = {
        Unconfirmed: 'unconfirmed' as StatusEnum,
        Provisional: 'provisional' as StatusEnum,
        Differential: 'differential' as StatusEnum,
        Confirmed: 'confirmed' as StatusEnum,
        Refuted: 'refuted' as StatusEnum,
        EnteredInError: 'entered-in-error' as StatusEnum,
    };

    /**
     * AdmissionDiagnosis Frontend Model with camelCase properties
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Diagnosis text.
         * @type string (`dataFormat` is missing)
         */
        text: string;
        /**
         * The code of diagnosis.
         * @type string (`dataFormat` is missing)
         */
        code?: string;
        /**
         * The status of the Diagnosis.  * `unconfirmed` - UNCONFIRMED * `provisional` - PROVISIONAL * `differential` - DIFFERENTIAL * `confirmed` - CONFIRMED * `refuted` - REFUTED * `entered-in-error` - ERROR
         * @type string (`dataFormat` is missing)
         */
        status: AdmissionDiagnosis.StatusEnum;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionDiagnosis DTO
     * Transforms AdmissionDiagnosis model from/to API object
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionDiagnosis model */
        static toModel(obj: AdmissionDiagnosis): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionDiagnosis model */
        static toApi(obj: Model): AdmissionDiagnosis {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionDiagnosis;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
