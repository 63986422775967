import { Component, Input, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * Validation message component
 */
@Component({
    selector: 'ui-validation-message',
    templateUrl: './validation-message.component.html',
    styleUrls: ['./validation-message.component.scss'],
})
export class UiFormValidationMessageComponent {
    /**
     * Control
     */
    @Input() control: AbstractControl;

    /**
     * Enriched template for min value
     */
    @Input() enrichedTemplateForMinValue: TemplateRef<any>;

    /**
     * Enriched template for max value
     */
    @Input() enrichedTemplateForMaxValue: TemplateRef<any>;
}
