import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiModels, WsService } from '@mona/api';
import { TokenStorageService } from '@mona/auth';
import { ConfigService } from '@mona/config';
import { Encounter, MonaNotification } from '@mona/models';
import { transformApiNotification } from './transforms';

/**
 * Websocket service for notifications
 */
@Injectable({ providedIn: 'root' })
export class NotificationsWsService extends WsService<MonaNotification> {
    /**
     * Constructor
     *
     * @param configService ConfigService
     * @param tokenStorage
     */
    constructor(private configService: ConfigService, private tokenStorage: TokenStorageService) {
        super();
    }

    /**
     * Connects to ws for notifications
     *
     * @param encounterId EntityId<Encounter>
     */
    connect(encounterId: EntityId<Encounter>): Observable<MonaNotification> {
        return of(this.tokenStorage.getAccessToken()).pipe(
            switchMap(accessToken => {
                const url = `${this.configService.get('api.coreWsUrl')}/ws/notifications/${encounterId}/`;
                this.setConfig(url, accessToken);
                return super.connect();
            }),
            map((message: ApiModels.Notification) => transformApiNotification(message)),
            catchError(e => EMPTY),
        );
    }
}
