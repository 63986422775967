import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FhirConfig } from '@mona/models';
import { omit } from '@mona/shared/utils';
import { FhirConfigState } from '../../entities';
import * as fromFhirConfig from '../reducers/fhir-config.reducer';

export const selectFhirConfigState = createFeatureSelector<FhirConfigState>(fromFhirConfig.fhirConfigFeatureKey);

export const selectFhirConfigIsLoading = createSelector(selectFhirConfigState, x => x.isLoading);
export const selectFhirConfig = createSelector(selectFhirConfigState, x => omit(x, 'isLoading') as FhirConfig);
