import { InjectionToken } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducer, ActionReducerMap, createReducer, on } from '@ngrx/store';
import { ReportType } from '@mona/models';
import { ReportsState } from '../../entities';
import * as ReportsActions from '../actions/reports.actions';

/**  EntityAdapter<Report> */
export const adapter: EntityAdapter<ReportType> = createEntityAdapter<ReportType>({
    selectId: (report: ReportType) => report.id,
    sortComparer: (a: ReportType, b: ReportType) => a.displayName.localeCompare(b.displayName),
});

const initialState: ReportsState = adapter.getInitialState({
    selectedReportId: null,
    error: null,
    isLoading: false,
});

export const reportsReducer = createReducer(
    initialState,
    on(ReportsActions.loadReports, (state, action) => ({ ...state, error: null, isLoading: true })),
    on(ReportsActions.loadReportsFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
    on(ReportsActions.loadReportsSuccess, (state, action) => ({
        ...adapter.upsertMany(action.reports, state),
        error: null,
        isLoading: false,
    })),
    on(ReportsActions.clearReports, state => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const PRACTITIONERS_REDUCER_TOKEN = new InjectionToken<ActionReducer<ReportsState>>('Reports Reducer');
