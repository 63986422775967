/* eslint-disable jsdoc/require-jsdoc, @typescript-eslint/ban-types, no-empty, curly */
function addLeadingZeros(number: number, targetLength: number): string {
    const sign = number < 0 ? '-' : '';
    let output = Math.abs(number).toString();
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return sign + output;
}

export function formatISO(date: Date, format: 'extended' | 'basic' = 'basic'): string {
    const originalDate = new Date(date);

    if (isNaN(originalDate.getTime())) {
        throw new RangeError('Invalid time value');
    }

    // eslint-disable-next-line prefer-const
    let representation = 'complete';

    let result = '';
    let tzOffset = '';

    const dateDelimiter = format === 'extended' ? '-' : '';
    const timeDelimiter = format === 'extended' ? ':' : '';

    // Representation is either 'date' or 'complete'
    if (representation !== 'time') {
        const day = addLeadingZeros(originalDate.getDate(), 2);
        const month = addLeadingZeros(originalDate.getMonth() + 1, 2);
        const year = addLeadingZeros(originalDate.getFullYear(), 4);

        // yyyyMMdd or yyyy-MM-dd.
        result = `${year}${dateDelimiter}${month}${dateDelimiter}${day}`;
    }

    // Representation is either 'time' or 'complete'
    if (representation !== 'date') {
        // Add the timezone.
        const offset = originalDate.getTimezoneOffset();

        if (offset !== 0) {
            const absoluteOffset = Math.abs(offset);
            const hourOffset = addLeadingZeros(Math.floor(absoluteOffset / 60), 2);
            const minuteOffset = addLeadingZeros(absoluteOffset % 60, 2);
            // If less than 0, the sign is +, because it is ahead of time.
            const sign = offset < 0 ? '+' : '-';

            tzOffset = `${sign}${hourOffset}:${minuteOffset}`;
        } else {
            tzOffset = 'Z';
        }

        const hour = addLeadingZeros(originalDate.getHours(), 2);
        const minute = addLeadingZeros(originalDate.getMinutes(), 2);
        const second = addLeadingZeros(originalDate.getSeconds(), 2);

        // If there's also date, separate it with time with 'T'
        const separator = result === '' ? '' : 'T';

        // Creates a time string consisting of hour, minute, and second, separated by delimiters, if defined.
        const time = [hour, minute, second].join(timeDelimiter);

        // HHmmss or HH:mm:ss.
        result = `${result}${separator}${time}${tzOffset}`;
    }

    return result;
}
