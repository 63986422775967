<mat-dialog-content
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    class="pip-call-dialog__content"
>
    <video #remoteVideo [autoplay]="true" [loop]="true" class="pip-call-dialog__remote-video"></video>

    <div *ngIf="hasCallPartner && remoteVideoDisabled" class="pip-call-dialog__remote-video-off">
        <mat-icon>videocam_off</mat-icon>
    </div>

    <div *ngIf="!hasCallPartner" class="pip-call-dialog__no-call-partner">
        <mat-icon>phone_disabled</mat-icon>
    </div>

    <button (click)="hangUp()" [color]="'warn'" class="pip-call-dialog__hang-up" mat-mini-fab type="button">
        <mat-icon>call_end</mat-icon>
    </button>

    <button (click)="fullscreen()" [color]="'primary'" class="pip-call-dialog__fullscreen" mat-mini-fab type="button">
        <mat-icon>aspect_ratio</mat-icon>
    </button>

    <div class="pip-call-dialog__actions">
        <button (click)="startScreenCapture()" *ngIf="!isScreenCapture" [color]="'primary'" mat-mini-fab type="button">
            <mat-icon>cast</mat-icon>
        </button>

        <button (click)="stopScreenCapture()" *ngIf="isScreenCapture" [color]="'accent'" mat-mini-fab type="button">
            <mat-icon>cast_connected</mat-icon>
        </button>

        <button
            (click)="toggleMicrophone()"
            [color]="microphoneEnabled ? 'primary' : 'accent'"
            mat-mini-fab
            type="button"
        >
            <mat-icon *ngIf="microphoneEnabled">mic</mat-icon>
            <mat-icon *ngIf="!microphoneEnabled">mic_off</mat-icon>
        </button>

        <button
            (click)="toggleVideo()"
            [color]="videoEnabled ? 'primary' : 'accent'"
            [disabled]="isScreenCapture"
            mat-mini-fab
            type="button"
        >
            <mat-icon *ngIf="videoEnabled">videocam</mat-icon>
            <mat-icon *ngIf="!videoEnabled">videocam_off</mat-icon>
        </button>
    </div>
</mat-dialog-content>
