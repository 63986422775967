import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { startOfDay, subDays } from 'date-fns';

/**
 * Resolves title for history day
 */
@Pipe({
    name: 'historyDateTitle',
})
export class HistoryDateTitlePipe implements PipeTransform {
    /**
     * INFO: add comment
     *
     * @param translateService
     * @param locale
     */
    constructor(private translateService: TranslateService, @Inject(LOCALE_ID) private locale: string) {}

    /**
     * Transform implementation
     *
     * @param itemDate Date
     * @param now Date
     */
    transform(itemDate: Date, now: Date = new Date()): string {
        const currentDay = startOfDay(now).getTime();
        const itemDateDay = startOfDay(itemDate).getTime();

        if (currentDay === itemDateDay) {
            // Current day has no label
            return null;
        }

        const yesterdayDay = subDays(startOfDay(currentDay), 1).getTime();

        if (itemDateDay === yesterdayDay) {
            // yesterday has text label
            return this.translateService.instant('apps.patient.overview.history.yesterday');
        }

        return formatDate(new Date(itemDateDay), 'mediumDate', this.locale);
    }
}
