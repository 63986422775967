/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { TherapyLimitations } from '@mona/models';

export namespace TherapyLimitationsActions {
    export const loadTherapyLimitations = createAction('ENCOUNTER:LOAD_THERAPY_LIMITATIONS');
    export const loadTherapyLimitationsSuccess = createAction(
        'ENCOUNTER:LOAD_THERAPY_LIMITATIONS_SUCCESS',
        props<{ data: TherapyLimitations[] }>(),
    );
    export const loadTherapyLimitationsFailure = createAction(
        'ENCOUNTER:LOAD_THERAPY_LIMITATIONS_FAILURE',
        props<{ error: any }>(),
    );

    export const updateTherapyLimitations = createAction(
        'ENCOUNTER:UPDATE_THERAPY_LIMITATIONS',
        props<{ update: Update<TherapyLimitations>[] }>(),
    );

    export const upsertTherapyLimitations = createAction(
        'ENCOUNTER:UPSERT_THERAPY_LIMITATIONS',
        props<{ data: TherapyLimitations[] }>(),
    );

    export const clearTherapyLimitations = createAction('ENCOUNTER:CLEAR_THERAPY_LIMITATIONS');

    export const addTherapyLimitationsChanges = createAction(
        'ENCOUNTER:ADD_CHANGES',
        props<{ toUpdateEntities: TherapyLimitations[]; toRemoveIds: string[] }>(),
    );
}
