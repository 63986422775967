import { createAction, props } from '@ngrx/store';
import { Encounter, LabValue, PageableModel, ParamsModel } from '@mona/models';

/**
 * Lab values actions
 */
export class LabValuesAction {
    static setLoadingAction = createAction('ENCOUNTER:SET_LAB_VALUES_LOADING', props<{ isLoading: boolean }>());

    static loadLabValues = createAction(
        'ENCOUNTER:LOAD_LAB_VALUES_PAGINATED',
        props<{ params?: ParamsModel & { encounter_id?: EntityId<Encounter> }; url?: string }>(),
    );
    static loadLabValuesSuccess = createAction(
        'ENCOUNTER:LOAD_LAB_VALUES_PAGINATED_SUCCESS',
        props<PageableModel<LabValue>>(),
    );
    static loadLabValuesFailed = createAction(
        'ENCOUNTER:LOAD_LAB_VALUES_PAGINATED_FAILED',
        props<{ error: unknown }>(),
    );
    static upsertLabValues = createAction('ENCOUNTER:UPSERT_LAB_VALUES', props<{ data: LabValue[] }>());

    static removeLabValues = createAction('ENCOUNTER:REMOVE_LAB_VALUES', props<{ ids: string[] }>());

    static clearLabValues = createAction('ENCOUNTER:CLEAR_LAB_VALUES');

    static addChanges = createAction(
        'ENCOUNTER:ADD_LAB_VALUES_CHANGES',
        props<{ toUpdateEntities: LabValue[]; toRemoveIds: string[] }>(),
    );
}
