import { ApiModels } from '@mona/api';
import { BasicCareProcedure } from '@mona/models';

/**
 * Transforms api basic care procedure
 * @param apiBasicCareProcedure ApiModels.BasicCareProcedure
 */
export const transformApiBasicCareProcedure = (apiBasicCareProcedure: ApiModels.BasicCare): BasicCareProcedure => {
    return {
        id: apiBasicCareProcedure.id,
        date: new Date(apiBasicCareProcedure.date),
        durationMinutes: apiBasicCareProcedure.duration_minutes,
        code: apiBasicCareProcedure.code,
        description: apiBasicCareProcedure.description,
        responsibleNurseId: apiBasicCareProcedure.responsible_nurse_id,
        note: apiBasicCareProcedure.note,
        lastChangedAt: new Date(apiBasicCareProcedure.last_changed_at),
        lastChangedBy: apiBasicCareProcedure.last_changed_by,
    };
};

/**
 * Transform api basic care procedures
 * @param apiBasicCareProcedures ApiModels.BasicCareProcedure[]
 */
export const transformApiBasicCareProcedures = (
    apiBasicCareProcedures: ApiModels.BasicCare[],
): BasicCareProcedure[] => {
    return apiBasicCareProcedures.map(apiBasicCareProcedure => transformApiBasicCareProcedure(apiBasicCareProcedure));
};
