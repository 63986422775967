import { Component, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * UiDynamicSliderComponent
 */
@Component({
    selector: 'ui-dynamic-slider',
    styleUrls: ['./dynamic-slider.component.scss'],
    templateUrl: './dynamic-slider.component.html',
})
export class UiDynamicSliderComponent {
    control: UntypedFormControl;

    label = '';

    required: boolean = undefined;

    name = '';

    hint = '';

    min: number = undefined;

    max: number = undefined;

    /**
     * Constructor
     *
     * @param _changeDetectorRef
     */
    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    /**
     * handleBlur
     */
    handleBlur(): void {
        setTimeout(() => {
            this._changeDetectorRef.markForCheck();
        });
    }
}
