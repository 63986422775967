// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { StandardMedicationMedicine } from './standardMedicationMedicine';

/**
 * Serializer for StandardMedicationSet model.
 * @see #/components/schemas/PrescriptionSetMedicationStandardMedicationSet - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PrescriptionSetMedicationStandardMedicationSet {
    readonly id: string;
    /**
     * Mona medication unique code.
     * @dataFormat uuid
     */
    medication_code: string;
    readonly medication: StandardMedicationMedicine | null;
    /**
     * The Prescription Frequency Id
     * @dataFormat uuid
     */
    frequency: string;
    /**
     * Frequency times for the custom frequencies.
     *
     */
    frequency_times?: string | null;
    /**
     * The code-value from a code system (LOINC, SNOMED, Custom).
     *
     */
    method?: string | null;
    amount?: number | null;
    unit_amount?: string | null;
    solution_code?: string | null;
    volume?: number | null;
    unit_volume?: string | null;
    rate?: number | null;
    unit_rate?: string | null;
    /**
     * Medication Dosage Form Id.
     * @dataFormat uuid
     */
    dosage_form?: string | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PrescriptionSetMedicationStandardMedicationSet
 */
export namespace PrescriptionSetMedicationStandardMedicationSet {
    /**
     * PrescriptionSetMedicationStandardMedicationSet Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        readonly id: string;
        /**
         * Mona medication unique code.
         * @dataFormat uuid
         */
        medicationCode: string;
        @Type(() => StandardMedicationMedicine.Model) readonly medication: StandardMedicationMedicine.Model | null;
        /**
         * The Prescription Frequency Id
         * @dataFormat uuid
         */
        frequency: string;
        /**
         * Frequency times for the custom frequencies.
         * @type string (`dataFormat` is missing)
         */
        frequencyTimes?: string | null;
        /**
         * The code-value from a code system (LOINC, SNOMED, Custom).
         * @type string (`dataFormat` is missing)
         */
        method?: string | null;
        amount?: number | null;
        unitAmount?: string | null;
        solutionCode?: string | null;
        volume?: number | null;
        unitVolume?: string | null;
        rate?: number | null;
        unitRate?: string | null;
        /**
         * Medication Dosage Form Id.
         * @dataFormat uuid
         */
        dosageForm?: string | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PrescriptionSetMedicationStandardMedicationSet DTO
     * Transforms PrescriptionSetMedicationStandardMedicationSet model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PrescriptionSetMedicationStandardMedicationSet model */
        static toModel(obj: PrescriptionSetMedicationStandardMedicationSet): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PrescriptionSetMedicationStandardMedicationSet model */
        static toApi(obj: Model): PrescriptionSetMedicationStandardMedicationSet {
            const newObj = instanceToPlain(
                plainToInstance(Model, obj),
            ) as PrescriptionSetMedicationStandardMedicationSet;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
