import { Component } from '@angular/core';

/**
 * Reusable view to display No-data (Empty table) message
 */
@Component({
    selector: 'ui-empty',
    template: '<div [uiLoadingOverlay]="true"></div>',
    styles: [
        `
            :host {
                width: 100%;
                height: 100%;
            }
        `,
    ],
})
export class UiEmptyComponent {}
