import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { addMinutes, format, isWithinInterval } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ControlsOf } from '@mona/models';
import { DateRangeInterval, generate30MinutesRangesFor1Day, isSwitchHourOfDst } from '@mona/shared/date';
import { isEmpty, notEmpty, UtilsModule } from '@mona/shared/utils';
import { UiDynamicElementConfig, UiFormsModule } from '@mona/ui';
import { DATE_RANGE_FORM_CONFIG } from './date-range-dialog.config';

type DateRangePayload = {
    day: Date;
    range: DateRangeInterval;
};

type DateRangeForm = FormGroup<ControlsOf<DateRangePayload>>;

/**
 * Shows dialog with date range form
 */
@Component({
    selector: 'pdms-date-range-dialog',
    standalone: true,
    imports: [UtilsModule, UiFormsModule, MatDialogModule, MatButtonModule, TranslateModule],
    template: `
        <h2 mat-dialog-title>{{ 'dateRangeDialog.title' | translate }}</h2>
        <mat-dialog-content>
            <p [innerHTML]="'dateRangeDialog.subtitle' | translate"></p>
            <ui-form
                class="app-vital-signs__range-form"
                ngForm
                #f="ngForm"
                name="viewModeRangeForm"
                [elements]="formConfig"
            ></ui-form>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button data-testid="btn-viewModeRangeCancel" mat-button mat-dialog-close>
                {{ 'dateRangeDialog.cancel' | translate }}
            </button>
            <button
                data-testid="btn-viewModeRangeSubmit"
                type="submit"
                mat-raised-button
                color="primary"
                [disabled]="f.invalid"
                (click)="submitForm(f.value)"
            >
                {{ 'dateRangeDialog.confirm' | translate }}
            </button>
        </mat-dialog-actions>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PdmsDateRangeDialog implements OnInit, AfterViewInit {
    /** reference to dynamic form */
    @ViewChild(NgForm, { static: true }) private ngForm: NgForm;
    /**
     * FormGroup
     */
    get formGroup(): DateRangeForm {
        return this.ngForm.form;
    }
    formConfig: UiDynamicElementConfig[] = undefined;
    selectedDateRange: DateRangeInterval = undefined;
    startDate: Date = undefined;

    private data: {
        formConfig: any;
        selectedDateRange?: DateRangeInterval;
        startDate?: Date;
    } = inject(MAT_DIALOG_DATA) || {};

    private dialogRef = inject(MatDialogRef);

    private dateRanges = new BehaviorSubject([]);

    private translateService = inject(TranslateService);

    /**
     * Lifecycle hook - init form
     */
    ngOnInit(): void {
        this.startDate = this.data.startDate;
        this.selectedDateRange = this.data.selectedDateRange;
        this.initFormConfig();
    }

    /**
     * Lifecycle hook - subscribe to form changes
     */
    ngAfterViewInit(): void {
        this.formGroup
            .get('day')
            .valueChanges.pipe(
                debounceTime(100),
                notEmpty(),
                distinctUntilChanged((a, b) => a.getDay() === b.getDay()),
            )
            .subscribe(date => {
                const ranges = this.getDateRangeIntervalsOptions(date);
                this.dateRanges.next(ranges);
                this.formGroup.get('range').reset();
                this.formGroup.get('range').updateValueAndValidity();
            });
    }

    /**
     * Parse form value and close dialog with selected date range
     *
     * @param formValue
     */
    submitForm(formValue: DateRangePayload): void {
        if (isEmpty(formValue)) {
            return;
        }
        this.selectedDateRange = formValue.range;
        this.dialogRef.close(this.selectedDateRange);
    }

    /**
     * Gets default form config
     * and applies min, max and default values
     */
    private initFormConfig(): void {
        if (this.formConfig) {
            return;
        }
        const [day, range]: UiDynamicElementConfig[] = DATE_RANGE_FORM_CONFIG;
        day.label = this.translateService.instant(day.label);
        day.placeholder = this.translateService.instant(day.placeholder);
        day.min = this.startDate;
        day.max = new Date();
        day.default = this.selectedDateRange?.start || new Date();
        const options = this.getDateRangeIntervalsOptions(day.default);
        this.dateRanges.next(options);
        range.label = this.translateService.instant(range.label);
        range.placeholder = this.translateService.instant(range.placeholder);
        range.dataSource = this.dateRanges.asObservable();
        range.default = (
            options.find(({ value }) => isWithinInterval(addMinutes(day.default, 1), value)) || options[0]
        ).value;
        this.formConfig = [day, range];
    }

    /**
     * Gets 30 min range intervals for given date
     *
     * @param date
     */
    private getDateRangeIntervalsOptions(date: Date) {
        return generate30MinutesRangesFor1Day(date).map(value => {
            return {
                label: `${format(value.start, 'HH:mm')} - ${format(value.end, 'HH:mm')} ${
                    isSwitchHourOfDst(value.start) ? ' (DST)' : ''
                }`,
                value,
            };
        });
    }
}
