import { Injectable } from '@angular/core';
import { MatDateFormats } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Sets material datepicker formats from language file
 */
@Injectable()
export class DynamicDatepickerLocale implements MatDateFormats {
    /**
     * Display configuration for mat date picker based on date-fns
     */
    get display() {
        return {
            dateInput: this.translateService.instant('matDatePickerFormats.display.dateInput') || 'dd/MM/yyyy',
            monthYearLabel: this.translateService.instant('matDatePickerFormats.display.monthYearLabel') || 'MMM yyyy',
            dateA11yLabel: this.translateService.instant('matDatePickerFormats.display.dateA11yLabel') || 'dd/MM/yyyy',
            monthYearA11yLabel:
                this.translateService.instant('matDatePickerFormats.display.monthYearA11yLabel') || 'MMMM yyyy',
        };
    }

    /**
     * Input parsing for mat date picker based on date-fns
     */
    get parse() {
        return {
            dateInput: this.translateService.instant('matDatePickerFormats.parse.dateInput') || 'dd/MM/yyyy',
        };
    }

    /**
     * Constructor
     *
     * @param translateService NGX Translate service
     */
    constructor(private translateService: TranslateService) {}
}
