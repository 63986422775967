import { ApiModels } from '@mona/api';
import { VaccinationStatus } from '@mona/models';

/**
 * Transforms API apiVaccinationStatus
 * @param apiVaccination ApiModels.VaccinationStatus
 */
export const transformApiVaccination = (apiVaccination: ApiModels.VaccinationStatus): VaccinationStatus => {
    return ApiModels.VaccinationStatus.DTO.toModel(apiVaccination);
};

/**
 * Transforms API apiVaccinationStatus
 * @param apiVaccinations ApiModels.VaccinationStatus[]
 */
export const transformApiVaccinations = (apiVaccinations: ApiModels.VaccinationStatus[]): VaccinationStatus[] => {
    return apiVaccinations.map(apiVaccination => transformApiVaccination(apiVaccination));
};
