import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { LegendItem } from '../../models';

/**
 * UiChart legend component
 */
@Component({
    selector: 'ui-chart-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UiEchartsLegendComponent {
    private _legendItems: LegendItem[] = [];
    /** Host CSS Class */
    @HostBinding('class.ui-chart-legend') componentCssClass = true;
    /**
     * Legend items(selection list items)
     */
    get legendItems(): LegendItem[] {
        return this._legendItems;
    }
    @Input() set legendItems(value: LegendItem[]) {
        if (!value) {
            return;
        }
        this._legendItems = value;
    }

    /**
     * Selected codes
     */
    @Input() selectedCodes: Set<string>;

    /**
     * Legend item selected
     */
    @Output() legendItemSelected = new EventEmitter<LegendItem>();

    /**
     * Toggles selection state of a code
     *
     * @param event MatSelectionListChange
     */
    onLegendChange(event: MatSelectionListChange) {
        const item = event.options?.[0]?.value as LegendItem;
        this.legendItemSelected.emit(item);
    }
}
