import { TypedAction } from '@ngrx/store/src/models';
import { FailedAction, SucceededAction } from '../models';
import { AsyncActionState } from '../state/async-action.state';

/**
 * Helper class for async action state reducing
 */
export class AsyncActionReducerHelper<R> {
    /**
     * Initial async action state
     */
    static readonly initialState: AsyncActionState = {
        inProgress: false,
        succeeded: false,
        finished: false,
        result: null,
        error: null,
    };

    /**
     * Called when action started
     *
     * @param state state
     */
    initActionReducer(state: AsyncActionState<R>): AsyncActionState<R> {
        return {
            ...AsyncActionReducerHelper.initialState,
            inProgress: true,
        };
    }

    /**
     * Triggered when async action succeeded
     *
     * @param state state
     * @param action action
     */
    succeededActionReducer(
        state: AsyncActionState<R>,
        action: SucceededAction<R> & TypedAction<any>,
    ): AsyncActionState<R> {
        return {
            ...AsyncActionReducerHelper.initialState,
            succeeded: true,
            finished: true,
            result: action.payload || null,
        };
    }

    /**
     * Triggered when async action fails
     *
     * @param state state
     * @param action action
     */
    failedActionReducer(state: AsyncActionState<R>, action: FailedAction & TypedAction<any>): AsyncActionState<R> {
        return {
            ...AsyncActionReducerHelper.initialState,
            succeeded: false,
            finished: true,
            error: action.error || null,
        };
    }

    /**
     * Resets state
     *
     * @param state state
     */
    clearActionReducer(state: AsyncActionState<R>): AsyncActionState<R> {
        return AsyncActionReducerHelper.initialState;
    }
}
