<div class="mona-auth-form mona-auth-form--rfid">
    <div class="mona-auth-form__rfid-trigger">
        <div *ngIf="isLoading; else scanIcon" class="mona-auth-form-welcome__rfid-spinner">
            <mat-spinner color="primary" diameter="64"></mat-spinner>
        </div>

        <ng-template #scanIcon>
            <mat-icon [inline]="true" class="mona-auth-form__rfid-icon text-accent">wifi_tethering</mat-icon>
        </ng-template>
    </div>

    <div class="text-primary mona-auth-form__title">
        {{ 'rfidDialog.title' | translate }}
    </div>

    <div class="mona-auth-form__template">
        <ng-content></ng-content>
    </div>

    <div class="mona-auth-form__image">
        <img alt="rifd" src="assets/images/show_badge.svg" />
    </div>
</div>
