import { noop } from '@mona/shared/utils';
import { VHSTableScrollStrategy } from './vhs-table-scroll-abstract.strategy';

/**
 * A custom scroll strategy that updates Viewport rendered columns to render based on column width and buffer size BY SCROLLING BY COLUMN
 */
export class VHSTableScrollByColumnStrategy extends VHSTableScrollStrategy {
    /** @ignore */
    initLoading = noop;
    /** Update rendered range */
    updateRenderedRange() {
        if (!this.viewport || !this.itemsInViewport) {
            return;
        }

        const viewportSize = this.viewport.getViewportSize(); // not updated after columnschanged
        const dataLength = this.viewport.getDataLength(); // updated after columnschanged
        const scrollOffset = this.viewport.measureScrollOffset();
        const currentRange = this.viewport.getRenderedRange();

        // skip when not able to 'measureScrollOffset' but current range was present
        /* if (currentRange.end > 0 && !scrollOffset) {
            return;
        } */

        // the index of the first item that would be at least partially visible in the viewport
        const firstVisibleIndex = Math.floor(Math.ceil(scrollOffset) / this.columnWidth);
        this._scrolledIndexChange.next(firstVisibleIndex);

        // set new range when dataLength is less then current range
        if (dataLength < currentRange.end || (dataLength > 1 && dataLength <= this.columnBuffer)) {
            const correctRange = {
                start: 0,
                end: dataLength,
            };
            const correctOffset = 0;
            this.viewport.setRenderedRange(correctRange);
            this.viewport.setRenderedContentOffset(correctOffset);
            return;
        }

        // if the buffer size is 10 but the first visible item is at, say, index 7
        // then the buffered items must be 7, because we don't have 10 items available to buffer.
        const bufferedItems = Math.min(this.columnBuffer, firstVisibleIndex);

        const itemsToRender = this.itemsInViewport + this.columnBuffer;

        // Clamp the new range between 0 and dataLength
        const newStart = Math.max(0, firstVisibleIndex - bufferedItems);
        const newEnd = Math.min(dataLength, firstVisibleIndex + itemsToRender);

        // skip if same range
        if (
            (currentRange.end >= 0 &&
                newEnd === dataLength &&
                this.previousVisibleIndex &&
                this.previousVisibleIndex === firstVisibleIndex) ||
            (newStart === currentRange.start && newEnd === currentRange.end)
        ) {
            return;
        }

        const newRange = {
            start: newStart,
            end: newEnd,
        };
        const newOffset = this.columnWidth * (firstVisibleIndex - bufferedItems);

        const isHasNoRange = newRange.start === 0 && newRange.end === 0;

        const shouldUpdateRenderedRange =
            (!isHasNoRange &&
                // if new range end is less than itemsInViewport
                currentRange.end >= 0 &&
                currentRange.end - firstVisibleIndex - this.itemsInViewport < this.itemsInViewport) ||
            // if new range start is less than itemsInViewport
            (newRange.start !== currentRange.start && firstVisibleIndex - currentRange.start < this.itemsInViewport);

        if (shouldUpdateRenderedRange) {
            this.previousVisibleIndex = firstVisibleIndex;
            this.viewport.setRenderedRange(newRange);
            this.viewport.setRenderedContentOffset(newOffset);
        }
    }
}
