import { ApiModels } from '@mona/api';
import { VentilationParameter } from '@mona/models';

/**
 * Transforms api ventilation parameter
 * @param apiVentilationParameter VentilationParameter
 */
export const transformApiVentilationParameter = (
    apiVentilationParameter: ApiModels.PdmsVentilationParameter,
): VentilationParameter => {
    return ApiModels.PdmsVentilationParameter.DTO.toModel(apiVentilationParameter);
};

/**
 * Transform api ventilation parameters
 * @param apiVentilationParameters ApiModels.PdmsVentilationParameter[]
 */
export const transformApiVentilationParameters = (
    apiVentilationParameters: ApiModels.PdmsVentilationParameter[],
): VentilationParameter[] => {
    return apiVentilationParameters.map(apiVentilationParameter =>
        transformApiVentilationParameter(apiVentilationParameter),
    );
};
