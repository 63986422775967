import { ApiModels } from '../api-models';

export const apiFeatureKey = 'api';

/**
 * Model representing the health settings
 */
export interface HealthState extends ApiModels.HealthSettings.Model {
    /**
     * Whether the terminal version is outdated
     */
    isVersionOutdated?: boolean;

    /**
     * Whether the terminal version is incompatible with core
     */
    isVersionIncompatible?: boolean;
    /**
     * Loading
     */
    isLoading?: boolean;
    /**
     * Health endpoint is reachable
     */
    isConnected: boolean;
    /**
     * Error
     */
    error?: any;
    /**
     * Last checked at
     */
    lastCheckedAt?: Date;
}

/**
 * Map representing health settings by server
 */
export interface HealthStateMap {
    [serverUrl: string]: HealthState;
}

/**
 * API health state
 */
export interface ApiHealthState {
    /**
     * Health state
     */
    healthState: HealthStateMap;

    /**
     * Online
     */
    isOnline: boolean;
}
