import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_REDIRECT_PATH } from '@environment';

/**
 * Error Page Component
 */
@Component({
    selector: 'mona-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
    /**
     * Constructor
     *
     * @param router
     */
    constructor(private router: Router) {}

    /**
     * INFO: add comment
     */
    navigateTo() {
        this.router.navigate([DEFAULT_REDIRECT_PATH]);
    }
}
