/* eslint-disable @angular-eslint/no-empty-lifecycle-method, prefer-rest-params,@typescript-eslint/no-empty-function */
import { Directive, OnDestroy, Type } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Async component decorator
 * used for rxjs subscriptions management
 * Should be used in combination with
 * AsyncComponent base class
 *
 * @deprecated use {@link TakeUntilDestroy} instead
 */
export function Async() {
    return function (constructor: Type<AsyncComponent>) {
        const originalOnDestroy = constructor.prototype['ngOnDestroy'];
        constructor.prototype['ngOnDestroy'] = function () {
            if (this['subscription']) {
                this['subscription'].unsubscribe();
            }
            originalOnDestroy.apply(this, arguments);
        };
    };
}

/* eslint-enable @typescript-eslint/dot-notation */

/**
 * Async component base class
 * should be used in combinations with Async decorator
 *
 * @deprecated use {@link TakeUntilDestroy} instead
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AsyncComponent implements OnDestroy {
    /**
     * Holds component subscriptions
     */
    private subscription: Subscription = new Subscription();

    /**
     * Your subscriptions should be wrapped with this method
     *
     * @param sub Subscription
     */
    async(sub: Subscription) {
        this.subscription.add(sub);
    }

    /**
     * Angular destroy life-cycle method
     */
    ngOnDestroy() {}
}
