import { ActionCreator } from '@ngrx/store';
import { NotAllowedCheck, TypedAction } from '@ngrx/store/src/models';
import { Observable, UnaryFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError, BaseErrors } from '@mona/shared/utils';
import { ErrorActions } from '../actions';
import { FailedAction } from '../models';

/**
 * Handle effect error
 * Mostly used with Failed action of AsyncActionState
 *
 * @param type type
 * @param expectedErrorCodes (do not show unexpected error alert for these codes)
 * @param ignoreUnexpectedErrors (do not show unexpected error alert at all)
 */
export function handleEffectError<
    T extends string,
    C extends ActionCreator<T, (props: FailedAction & NotAllowedCheck<AppError>) => FailedAction & TypedAction<T>>,
    A,
>(
    type: C,
    expectedErrorCodes?: string[],
    ignoreUnexpectedErrors = false,
): UnaryFunction<Observable<A>, Observable<A | TypedAction<string> | (FailedAction & TypedAction<T>)>> {
    return catchError((err: AppError, caught: Observable<A>) => {
        const actions: (TypedAction<string> | (FailedAction & TypedAction<T>))[] = [
            type({
                error: err,
            }),
        ];

        // we should always handle offline, it does not make sense to not handle it
        if ((ignoreUnexpectedErrors || expectedErrorCodes?.includes(err.errorCode)) && err.status !== 0) {
            return actions;
        }
        actions.unshift(
            ErrorActions.handleUnexpectedServerError({
                ...err,
                errorCode: err.status === 0 ? BaseErrors.OFFLINE : err.errorCode,
            }),
        );
        return actions;
    });
}
