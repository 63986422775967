import { Directive, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../models';

/**
 * Base Dialog Component
 */
@Directive()
export abstract class BaseDialogComponent<D = DialogData> {
    /**
     * Constructor
     *
     * @param data DialogData
     * @param dialogRef
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: D, public dialogRef: MatDialogRef<BaseDialogComponent>) {}
}
