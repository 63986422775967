/* eslint-disable @typescript-eslint/ban-types, curly */
import { Pipe, PipeTransform } from '@angular/core';
import { get, isFunction } from '@mona/shared/utils';
import { UiTableRow } from '../models';
import { FilterOptionsModel } from '../models/filter-option.intf';

/**
 * Filter Options Keys Model
 */
/* eslint-disable jsdoc/require-jsdoc */
export interface FilterOptionsKeysModel {
    labelKey: string;
    valueKey: string | null;
}
/* eslint-enable jsdoc/require-jsdoc */

/**
 * Filter Options Keys Enum
 */
export enum FilterOptionsKeysEnum {
    Name = 'name',
    DisplayName = 'displayName',
    Description = 'description',
    Code = 'code',
}

/**
 * Ui Table Filter Options Pipe
 */
@Pipe({
    name: 'uiTableFilterOptions',
})
export class UiTableFilterOptionsPipe<T extends UiTableRow> implements PipeTransform {
    /**
     * Ui Table Filter Options Pipe
     *
     * @param data
     * @param filterOptionLabelMapper
     * @param filteredCodesDataAccessor
     */
    transform(
        data: T[] = [],
        filterOptionLabelMapper?: (...args) => string,
        filteredCodesDataAccessor?: string,
    ): FilterOptionsModel[] {
        const processedIds = new Set<string>();

        return data.reduce((acc: FilterOptionsModel[], row: T) => {
            const codeDataAccessor = filteredCodesDataAccessor || FilterOptionsKeysEnum.Code;
            const code = get(row, codeDataAccessor as Path<{}>);

            if (row.skipFilter) return acc;
            if (row.dataType === 'group' && !row.children) return acc;
            if (codeDataAccessor && processedIds.has(code)) return acc;

            const option: FilterOptionsModel = {
                [codeDataAccessor]: code,
                label: this.getOptionLabel(row),
                value: this.getOptionValue(row, filteredCodesDataAccessor),
            };

            if (isFunction(filterOptionLabelMapper)) {
                option.hintText = filterOptionLabelMapper(row);
            }

            filteredCodesDataAccessor && processedIds.add(code);

            return [...acc, option];
        }, []);
    }

    private getOptionLabel(item: T): string {
        const label: string =
            item?.[FilterOptionsKeysEnum.Name] ||
            item?.[FilterOptionsKeysEnum.DisplayName] ||
            item?.[FilterOptionsKeysEnum.Description];
        if (!label) {
            throw new Error('Default Label KEYS does not exist');
        }
        return label;
    }

    private getOptionValue(item: T, accessor?: string): string {
        const value = get(item, (accessor || FilterOptionsKeysEnum.Code) as Path<{}>);

        if (!value) {
            throw new Error('Default Value KEY does not exist');
        }
        return value;
    }
}
