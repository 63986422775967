import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, IS_TELEMEDICINE_CONTEXT } from '@mona/api';
import { Practitioner } from '@mona/models';

/**
 * API abstraction layer for the telemedicine practitioners API
 */
@Injectable({
    providedIn: 'root',
})
export class TelemedicinePractitionersApi /* extends TelemedicineApi */ {
    apiBase = '/telemedicine/practitioners/';
    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Ensures that practitioner is registered for telemedicine
     *
     * @param practitioner
     * @param hospitalCode
     */
    registerPractitioner(practitioner: Practitioner, hospitalCode: string): Observable<void> {
        const context = new HttpContext().set(IS_TELEMEDICINE_CONTEXT, true);
        const payload: {
            id: EntityId<Practitioner>;
            prefix?: string;
            first_name: string;
            last_name: string;
            phone?: string;
            hospital_id: string;
        } = {
            id: practitioner.id,
            first_name: practitioner.firstName,
            last_name: practitioner.lastName,
            hospital_id: hospitalCode,
        };

        if (practitioner.prefix) {
            payload.prefix = practitioner.prefix;
        }

        if (practitioner.phone) {
            payload.phone = practitioner.phone;
        }

        return this.http.post<void>(this.apiBase, payload, { context });
    }
}
