import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { ChangelogTagsComponent, LogInFormComponent, RfidFormComponent } from './components';
import { SignInComponent } from './containers';
import { SignInRoutingModule } from './sign-in-routing.module';

/**
 * SignInModule
 */
@NgModule({
    declarations: [LogInFormComponent, RfidFormComponent, SignInComponent, ChangelogTagsComponent],
    imports: [
        //
        UiModule,
        KeyboardModule,
        SignInRoutingModule,
    ],
})
export class SignInModule {}
