// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for CareCheckType Model Structure.
 * @see #/components/schemas/CareCheckType - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface CareCheckType {
    /**
     * The mona code for the care check type
     * @dataFormat uuid
     */
    readonly code: string;
    readonly display_name: string;
    /**
     * flag to switch if this care check type is visible
     *
     */
    is_visible?: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for CareCheckType
 */
export namespace CareCheckType {
    /**
     * CareCheckType Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The mona code for the care check type
         * @dataFormat uuid
         */
        readonly code: string;
        readonly displayName: string;
        /**
         * flag to switch if this care check type is visible
         * @type boolean (`dataFormat` is missing)
         */
        isVisible?: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * CareCheckType DTO
     * Transforms CareCheckType model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend CareCheckType model */
        static toModel(obj: CareCheckType): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend CareCheckType model */
        static toApi(obj: Model): CareCheckType {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as CareCheckType;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
