import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { RelativeContact } from '@mona/models';
import { RelativeContactsState } from '../../entities';
import { RelativeContactsActions } from '../actions';

export const relativeContactsFeatureKey = 'relativeContacts';

export const relativeContactsAdapter = createEntityAdapter<RelativeContact>();

export const initialState: RelativeContactsState = relativeContactsAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const reducer = createReducer(
    initialState,
    on(RelativeContactsActions.loadRelativeContacts, state => ({ ...state, isLoading: true })),
    on(RelativeContactsActions.loadRelativeContactsSuccess, (state, { relativeContacts }) =>
        relativeContactsAdapter.setAll(relativeContacts, {
            ...state,
            isLoading: false,
        }),
    ),
    on(RelativeContactsActions.loadRelativeContactsFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),
    on(RelativeContactsActions.deleteRelativeContactSuccess, (state, { id }) =>
        relativeContactsAdapter.removeOne(id, {
            ...state,
            isLoading: false,
        }),
    ),
    on(RelativeContactsActions.deleteRelativeContactFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),

    on(RelativeContactsActions.upsertRelativeContact, (state) => ({
        ...state,
        isLoading: true
    })),
    on(RelativeContactsActions.upsertRelativeContactSuccess, (state, { relativeContact }) =>
        relativeContactsAdapter.upsertOne(relativeContact, {
            ...state,
            isLoading: false,
        }),
    ),
    on(RelativeContactsActions.upsertRelativeContactFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),
);
