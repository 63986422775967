// write angular filter pipe

import { Pipe, PipeTransform } from '@angular/core';
import { identity } from '../helpers';
import { isFunction, isObject } from '../types';

/**
 * FilterPipe is a pipe that filters an array of objects by a predicate
 *
 * @example
 * <div *ngFor="let item of items | filter: filterByCodeFn">
 */
@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
    /**
     * Should accept array-like values and filter them by predicate
     *
     * @param array
     * @param predicate
     */
    transform<T extends AnyObject, K extends keyof T, U extends ((v: T[K]) => unknown) | undefined>(
        array: Array<T> | ReadonlyArray<T>,
        predicate: U,
    ): Array<T> | ReadonlyArray<T> {
        let filteredArray = [];
        if (!Array.isArray(array) || !isObject(array[0]) || !isFunction(predicate)) {
            return array;
        }
        filteredArray = array.filter(predicate || identity) as ReturnType<typeof predicate>[];

        return filteredArray;
    }
}
