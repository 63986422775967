import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { ConversationOptionsComponent } from './conversation-options.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [ConversationOptionsComponent],
    exports: [ConversationOptionsComponent],
    imports: [CommonModule, MatRippleModule],
})
export class ConversationOptionsModule {}
