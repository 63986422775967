import { ActionReducer, createReducer, on } from '@ngrx/store';
import { ApiActions } from '@mona/api';
import { DeviceSetupStatus } from '@mona/models';
import { SetupState } from '../../entities';
import { SetupActions } from '../actions';

/**
 * Initial Device setup status value
 */
const initialState: SetupState = {
    isActivated: undefined,
    isRegistrationSent: false,
    status: DeviceSetupStatus.NotChecked,
    isLoading: false,
    error: null,
};

/**
 * Partial Device setup status reducer
 */
export const deviceSetupReducer: ActionReducer<SetupState> = createReducer(
    initialState,
    on(SetupActions.clearDeviceActivatedStatus, () => initialState),
    on(SetupActions.registerDevice, SetupActions.checkIsDeviceActivated, state => ({
        ...state,
        status: DeviceSetupStatus.Pending,
        isLoading: true,
        error: null,
    })),
    on(SetupActions.registerDeviceFailure, SetupActions.checkIsDeviceActivatedFailure, (state, { error }) => ({
        ...state,
        status: DeviceSetupStatus.RegistrationFailed,
        isActivated: false,
        isLoading: false,
        error,
    })),
    on(ApiActions.loadApiHealthSuccess, (state, { healthState }) => ({
        ...state,
        status: healthState.maintenance ? DeviceSetupStatus.Maintenance : state.status,
    })),
    on(SetupActions.registerDeviceSuccess, SetupActions.checkIsDeviceActivatedSuccess, (state, { isActivated }) => ({
        ...state,
        isRegistrationSent: true,
        status: isActivated ? DeviceSetupStatus.RegistrationComplete : DeviceSetupStatus.AwaitingActivation,
        isActivated,
        isLoading: false,
        error: null,
    })),
);
