import { NgForOf } from '@angular/common';
import { Component, ElementRef, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeyboardModule } from '@mona/keyboard';
import { ProcedureCategory } from '@mona/models';
import { BaseProcedureAdministrationForm, buildBaseProcedureAdministrationForm } from '@mona/pdms/shared';
import { UiModule } from '@mona/ui';

/**
 * INFO: add comment
 */
@Component({
    standalone: true,
    selector: 'mona-base-procedure-administration-form',
    imports: [KeyboardModule, NgForOf, TranslateModule, UiModule],
    template: `
        <fieldset class="mona-medication__form mona-medication__form-fieldset" [formGroup]="formGroup" appKeyboardForm>
            <!-- DESCRIPTION-->
            <mat-form-field class="app-input-field app-input-field--fullwidth" appearance="fill">
                <mat-label>
                    {{ 'apps.patient.prescriptions.procedureTable.description' | translate }}
                </mat-label>
                <textarea
                    matInput
                    appKeyboardInput
                    [formControl]="controls.description"
                    [placeholder]="'apps.patient.prescriptions.procedureTable.description' | translate"
                    class="mona-medication__form-textarea"
                    rows="4"
                ></textarea>
                <mat-error>
                    <ui-validation-message [control]="controls.description"></ui-validation-message>
                </mat-error>
            </mat-form-field>

            <!-- INSTRUCTION-->
            <mat-form-field class="app-input-field app-input-field--fullwidth" appearance="fill">
                <mat-label>
                    {{ 'apps.settings.medications.fields.instruction.label' | translate }}
                </mat-label>
                <textarea
                    matInput
                    appKeyboardInput
                    [formControl]="controls.instructions"
                    [placeholder]="'apps.patient.prescriptions.procedureTable.instruction' | translate"
                    class="mona-medication__form-textarea"
                    rows="4"
                ></textarea>
                <mat-error>
                    <ui-validation-message [control]="controls.instructions"></ui-validation-message>
                </mat-error>
            </mat-form-field>

            <!-- DURATION -->
            <mat-form-field id="type" class="app-input-field app-input-field--fullwidth">
                <mat-label>{{ 'apps.patient.careReports.procedureDialog.duration' | translate }}</mat-label>
                <mat-select
                    [formControl]="controls.durationMinutes"
                    panelClass="app-input-select-panel scrollbar-visible"
                >
                    <mat-option [value]="''">N/A</mat-option>
                    <mat-option *ngFor="let duration of durationsOptions" [value]="duration.value">
                        {{ duration.name }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    <ui-validation-message [control]="controls.durationMinutes"></ui-validation-message>
                </mat-error>
            </mat-form-field>

            <!-- NOTE -->
            <mat-form-field id="type" class="app-input-field app-input-field--fullwidth">
                <mat-label>
                    {{ 'apps.patient.careReports.procedureDialog.note' | translate }}
                </mat-label>
                <textarea
                    matInput
                    appKeyboardInput
                    [formControl]="controls.note"
                    [placeholder]="'apps.patient.prescriptions.procedureTable.instruction' | translate"
                    class="mona-medication__form-textarea"
                    rows="4"
                ></textarea>
                <mat-error>
                    <ui-validation-message [control]="controls.note"></ui-validation-message>
                </mat-error>
            </mat-form-field>
        </fieldset>
    `,
})
export class BaseProcedureAdministrationFormComponent {
    /** ElementRef */
    @Input() submitBtnRef: ElementRef;
    /** Frequency dictionary */
    @Input() procedureCategories: ProcedureCategory[] = [];
    /** Procedure form  */
    formGroup: BaseProcedureAdministrationForm;
    /** The controls of the form */
    get controls(): BaseProcedureAdministrationForm['controls'] {
        return this.formGroup.controls;
    }
    /**
     * Possible duration options
     */
    durationsOptions = this.generateDurationOptions();
    /**
     * Constructor
     *
     * @param controlContainer
     * @param translateService
     */
    constructor(@Optional() private controlContainer: ControlContainer, private translateService: TranslateService) {}

    /**
     * INFO: add comment
     */
    ngOnInit() {
        if (this.controlContainer?.control) {
            this.formGroup = this.controlContainer.control as any;
        } else {
            this.formGroup = buildBaseProcedureAdministrationForm();
        }
    }

    /**
     * Generates possible duration options
     */
    generateDurationOptions(): {
        value: number;
        name: string;
    }[] {
        return Array.from({ length: 30 }, (_, i) => i + 1).map(numberMinutes => {
            return {
                value: numberMinutes,
                name: `${numberMinutes} ${this.translateService.instant(
                    `apps.patient.careReports.procedureDialog.${numberMinutes === 1 ? 'minute' : 'minutes'}`,
                )}`,
            };
        });
    }
}
