import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RelativeContactsState } from '../../entities';
import * as fromRelativeContacts from '../reducers/relative-contacts.reducer';

export const selectRelativeContactsState = createFeatureSelector<RelativeContactsState>(
    fromRelativeContacts.relativeContactsFeatureKey,
);

export const {
    selectIds: selectRelativeContactIds,
    selectEntities: selectRelativeContactEntities,
    selectAll: selectRelativeContactAll,
    selectTotal: selectRelativeContactTotal,
} = fromRelativeContacts.relativeContactsAdapter.getSelectors(selectRelativeContactsState);

export const selectIsLoading = createSelector(selectRelativeContactsState, state => state.isLoading);
