import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    DataUpdateMessage,
    PageableModel,
    ParamsModel,
    VentilationParameter,
    VentilationProcedure,
} from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { EntriesInterval } from '@mona/shared/date';
import { EncounterDataState, VentilationParametersMap, VentilationParametersMapByCode } from '../entities';
import {
    selectVentilationInterval,
    selectVentilationIsLoading,
    selectVentilationParamebersCodesToShow,
    selectVentilationParametersGroupByCode,
    selectVentilationParametersMap,
    selectVentilationParametersPageableData,
    selectVentilationParametersStartDate,
    selectVentilationProcedures,
    VentilationAction,
} from '../state';

/**
 * Ventilation service
 */
@Injectable({ providedIn: 'root' })
export class VentilationService {
    isLoading$: Observable<boolean> = this.store.select(selectVentilationIsLoading);

    /**
     * Constructor
     *
     * @param store Store
     * @param changeLogService ChangeLogService
     */
    constructor(private store: Store<EncounterDataState>, private changeLogService: ChangeLogService) {}

    //#region Selectors

    /**
     * Get selected interval
     */
    getInterval(): Observable<EntriesInterval> {
        return this.store.select(selectVentilationInterval);
    }

    /**
     * Get Start Date
     */
    getStartDate(): Observable<Date> {
        return this.store.select(selectVentilationParametersStartDate);
    }

    /**
     * Get Ventilation Parameter State
     */
    getPageableData(): Observable<PageableModel<VentilationParameter>> {
        return this.store.select(selectVentilationParametersPageableData);
    }

    /**
     * Ventilation parameters grouped map
     */
    getVentilationParametersMapByCode(): Observable<VentilationParametersMapByCode> {
        return this.store.select(selectVentilationParametersGroupByCode);
    }

    /** Ventilation paramebers codes to show in the table */
    getVentilationParametersCodesToShow(): Observable<string[]> {
        return this.store.select(selectVentilationParamebersCodesToShow);
    }

    /**
     * Ventilation parameters
     */
    getVentilationParameters(): Observable<VentilationParametersMap> {
        return this.store.select(selectVentilationParametersMap);
    }

    /**
     * Ventilation procedures
     */
    getVentilationProcedures(): Observable<VentilationProcedure[]> {
        return this.store.select(selectVentilationProcedures);
    }

    //#endregion Selectors

    //#region Actions

    /**
     * Sets interval
     *
     * @param interval EntriesInterval
     */
    setInterval(interval: EntriesInterval): void {
        this.store.dispatch(VentilationAction.setIntervalAction({ interval }));
    }

    /**
     * Set Loading
     *
     * @param isLoading
     */
    setLoading(isLoading: boolean): void {
        this.store.dispatch(VentilationAction.setLoadingAction({ isLoading }));
    }

    /**
     * Load ventilation parameters
     *
     * @param params
     * @param url
     */
    loadVentilationParameters(params?: ParamsModel, url?: string): void {
        this.store.dispatch(VentilationAction.loadVentilationParameters({ params, url }));
    }

    /**
     * Load ventilation procedures
     */
    loadVentilationProcedures(): void {
        this.store.dispatch(VentilationAction.loadVentilationProcedures());
    }

    /**
     * Clear ventilation parameters & procedures
     */
    clearVentilationData(): void {
        this.store.dispatch(VentilationAction.clearVentilationData());
    }

    /**
     * Update ventilation
     *
     * @param updateMessage
     */
    updateVentilation(updateMessage: DataUpdateMessage<VentilationParameter>): void {
        const dataItem = {
            ...updateMessage.payload,
            id: updateMessage.modelId,
        } as VentilationParameter;
        const data = [dataItem];
        this.store.dispatch(VentilationAction.upsertVentilationParameters({ data }));
    }

    //#endregion Actions
}
