import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
    MedicationGroup,
    MedicationCategory,
    MedicationUnit,
    MedicationAdministrationMethod,
    MedicationSolution,
    PrescriptionFrequency,
    MedicationDosageForm,
    PrescriptionFrequencyTime,
    ProcedureCategory,
} from '@mona/models';

/** Get combined medications terminology data */
export type PrescriptionTerminologyData = {
    medicationGroups: MedicationGroup[];
    medicationCategories: MedicationCategory[];
    procedureCategories: ProcedureCategory[];
    medicationUnits: MedicationUnit[];
    medicationAdministrationMethods: MedicationAdministrationMethod[];
    medicationSolutions: MedicationSolution[];
    prescriptionFrequencies: PrescriptionFrequency[];
    prescriptionFrequencyTimes: PrescriptionFrequencyTime[];
    medicationDosageForms: MedicationDosageForm[];
};
/** Get terminology data once and assign to sync properties to avoid many async pipes */
export type PrescriptionTerminologyGetter = (...args) => Observable<PrescriptionTerminologyData>;
/** Get terminology data once and assign to sync properties to avoid many async pipes */
export const PRESCRIPTION_TERMINOLOGY_GETTER = new InjectionToken<PrescriptionTerminologyGetter>(
    'MedicationTerminologyGetter',
    {
        providedIn: 'root',
        factory: (): PrescriptionTerminologyGetter => () => of([] as any),
    },
);

export const KEY_FIRST = 'first';
export const KEY_LAST = 'last';
