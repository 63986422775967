import { Component, HostBinding, OnInit } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Async, AsyncComponent } from '@mona/shared/utils';
import { VoiceRecordingService } from '@mona/voice/data-access-conversation';

/**
 * Hey Mona button
 */
@Async()
@Component({
    selector: 'mona-hey-button',
    templateUrl: './hey-mona-button.component.html',
    styleUrls: ['./hey-mona-button.component.scss'],
})
export class HeyMonaButtonComponent extends AsyncComponent implements OnInit {
    /**
     * Host css class
     */
    @HostBinding('class.mona-hey-button') cmpClass = true;

    /**
     * Indicates that terminal is currently on call
     */
    isOnCall$: Observable<boolean> = NEVER;

    /**
     * constructor
     *
     * @param voiceRecordingService VoiceRecordingService
     */
    constructor(private voiceRecordingService: VoiceRecordingService) {
        super();
    }

    /**
     * Ng Hook
     */
    ngOnInit() {
        // Activate / Deactivate Microphone based on call status
        // This is needed in case of a picture in picture call
        this.async(
            this.isOnCall$
                .pipe(
                    finalize(() => {
                        this.releaseMicrophone();
                    }),
                )
                .subscribe(isOnCall => {
                    if (isOnCall) {
                        this.releaseMicrophone();
                    } else {
                        // Start listening to mic
                        this.voiceRecordingService.captureMicrophone();
                    }
                }),
        );
    }

    /**
     * Release mic
     */
    private releaseMicrophone() {
        // Stop listening to mic when subscription is gone
        this.voiceRecordingService.releaseMicrophone();
    }
}
