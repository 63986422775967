import { Component, Input } from '@angular/core';
import { User } from '../../../../models';

/**
 * Welcome view of lock screen
 */
@Component({
    selector: 'mona-sign-in-welcome',
    templateUrl: './sign-in-welcome.component.html',
    styleUrls: ['./sign-in-welcome.component.scss'],
})
export class SignInWelcomeComponent {
    /**
     * isLoading
     */
    @Input() isLoading = false;

    /**
     * The register rfid action
     */
    @Input() user!: User;
}
