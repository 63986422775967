import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { Encounter, VentilationProcedure } from '@mona/models';
import { transformApiVentilationProcedures } from './transforms';

/**
 * API abstraction layer for the Ventilation Procedures Api
 */
@Injectable({
    providedIn: 'root',
})
export class VentilationProceduresApi {
    apiBase = '/pdms/ventilation-procedures/';

    /**
     * Constructor
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get ventilation procedures
     * @param encounterId EntityId<Encounter>
     */
    getVentilationProcedures(encounterId: EntityId<Encounter>): Observable<VentilationProcedure[]> {
        const params = {
            encounter_id: `${encounterId}`,
        };

        return this.http
            .get<ApiModels.VentilationProcedure[]>(this.apiBase, {
                params,
            })
            .pipe(map(apiVentilationProcedures => transformApiVentilationProcedures(apiVentilationProcedures)));
    }
}
