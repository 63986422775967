/* eslint-disable @angular-eslint/directive-selector */
import { ChangeDetectorRef, Directive, HostListener, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { CustomValidators, isNullOrUndefined } from '@mona/shared/utils';
import { BaseCVA } from '../mixins';
import { CVA } from '../models';
/**
 * Format number input directive
 *
 * Note: value is and SHOULD BE always number, but input value is formatted with comma for german locale
 */
@Directive({
    selector: 'input[matInputCommaDot]',
    providers: [{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
})
export class MatInputCommaDotDirective extends BaseCVA implements CVA, OnInit {
    /**
     * Constructor
     *
     * @param cdRef
     * @param localeId
     */
    constructor(cdRef: ChangeDetectorRef, @Inject(LOCALE_ID) private localeId: string) {
        super(cdRef);
    }

    /**
     * Override input type
     */
    ngOnInit(): void {
        this.overrideInputType(this.hostElm as HTMLInputElement);

        if (this.isNumberType && this.ngControl?.control) {
            this.ngControl.control.addValidators(CustomValidators.decimal());
        }
    }

    /**
     * Writes to ng form on input
     *
     * @param input input
     */
    @HostListener('input', ['$event.target'])
    onInputChange({ value }): void {
        this.writeValue(value);
    }

    /**
     * Writes a value with COMMA to the native DOM element.
     *
     * NOTE: we can use here IntlFormat with correct locale
     *
     * @param value value
     */
    writeValue(value: any): void {
        if (isNullOrUndefined(value)) {
            (this.hostElm as HTMLInputElement).value = value;

            return;
        }

        if (!this.isNumberType) {
            this.value = value;
            this.cdRef.detectChanges();
            (this.hostElm as HTMLInputElement).value = value;

            return;
        }

        const numberValue = `${value}`.replace(/[^\d,-.]+/g, '');
        const viewValue =
            numberValue && this.shouldReplaceSeparator ? numberValue?.replace('.', ',') : numberValue.replace(',', '.');

        this.value = numberValue && this.shouldReplaceSeparator ? numberValue.replace(',', '.') : numberValue;
        this.cdRef.detectChanges();
        (this.hostElm as HTMLInputElement).value = viewValue;
    }

    private get shouldReplaceSeparator(): boolean {
        return `${this.localeId}` === 'de-DE';
    }

    private get isNumberType(): boolean {
        return this.hostElm?.dataset?.['type'] === 'number';
    }

    private overrideInputType(input: HTMLInputElement): void {
        input.setAttribute('type', 'text');
        input.setAttribute('inputmode', 'decimal');
    }
}
