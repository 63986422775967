<mat-dialog-content class="app-diagnosis-dialog__content">
    <div>
        <h2 mat-dialog-title class="app-diagnosis-dialog__title">
            {{
                data.diagnosis.id
                    ? ('apps.patient.overview.editDiagnosis' | translate)
                    : ('apps.patient.overview.addDiagnosis' | translate)
            }}
        </h2>
        <app-single-diagnosis-form
            [formGroup]="diagnosisForm"
            [canChangeDiagnosis]="!data.diagnosis.id"
            [isBedSideMode]="data.isBedSideMode"
        >
            >
        </app-single-diagnosis-form>
    </div>

    <div class="app-diagnosis-dialog__actions">
        <button mat-button class="app-diagnosis-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'apps.patient.overview.dialogCancel' | translate }}
        </button>
        <button mat-raised-button color="primary" cdkFocusInitial (click)="submitChange()">
            {{ 'apps.patient.overview.dialogSave' | translate }}
        </button>
    </div>
</mat-dialog-content>
