import { createReducer, on } from '@ngrx/store';
import { prescriptionSetAdapter, prescriptionSetInitialState } from '../../entities';
import { PrescriptionSetActions } from '../actions';

export const prescriptionSetReducer = createReducer(
    prescriptionSetInitialState,
    // load
    on(PrescriptionSetActions.loadPrescriptionSets, PrescriptionSetActions.loadPrescriptionSetById, state => ({
        ...state,
        isLoading: true,
    })),
    on(PrescriptionSetActions.loadPrescriptionSetByIdSuccess, (state, action) =>
        prescriptionSetAdapter.upsertOne(action.prescriptionSet, { ...state, isLoading: false }),
    ),
    on(PrescriptionSetActions.loadPrescriptionSetsSuccess, (state, action) =>
        prescriptionSetAdapter.addMany(action.prescriptionSets, { ...state, isLoading: false }),
    ),
    on(PrescriptionSetActions.loadPrescriptionSetsFailure, (state, action) => ({
        ...state,
        error: action.error,
        isLoading: false,
    })),
    on(
        PrescriptionSetActions.upsertActivePrescriptionSetAsCopy,
        (state, { prescriptionSet: activePrescriptionSet }) => ({
            ...state,
            activePrescriptionSet,
        }),
    ),
    // add new
    on(PrescriptionSetActions.addPrescriptionSetSuccess, (state, action) =>
        prescriptionSetAdapter.addOne(action.prescriptionSet, { ...state, isLoading: false }),
    ),
    on(PrescriptionSetActions.addPrescriptionSetFailure, (state, action) => ({
        ...state,
        error: action.error,
        isLoading: false,
    })),
    // update
    on(PrescriptionSetActions.updatePrescriptionSetSuccess, (state, action) =>
        prescriptionSetAdapter.updateOne(action.update, { ...state, isLoading: false }),
    ),
    on(PrescriptionSetActions.updatePrescriptionSetFailure, (state, action) => ({
        ...state,
        error: action.error,
        isLoading: false,
    })),
    // delete
    on(PrescriptionSetActions.deletePrescriptionSetSuccess, (state, action) =>
        prescriptionSetAdapter.removeOne(action.id, state),
    ),
    on(PrescriptionSetActions.deletePrescriptionSetFailure, (state, action) => ({
        ...state,
        error: action.error,
        isLoading: false,
    })),
    // clear
    on(PrescriptionSetActions.clearPrescriptionSets, state => prescriptionSetAdapter.removeAll(state)),
    on(PrescriptionSetActions.clearActivePrescriptionSet, state => ({ ...state, activePrescriptionSet: null })),
);
