import { Pipe, PipeTransform } from '@angular/core';
import { UiTableRow } from '../models';

/**
 * Generates group value string
 */
@Pipe({
    name: 'groupValue',
})
export class UiCellGroupValuePipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param row UiTableRow with values
     * @param key string
     */
    transform(row: UiTableRow, key: string): string {
        const lastChildrenIndex = row.children?.length - 1;
        return (
            row.children?.reduce((result, child, index) => {
                const value = child.values[key]?.value;
                result += value || '-';

                if (index !== lastChildrenIndex) {
                    result += '/';
                }

                return result;
            }, '') || ''
        );
    }
}
