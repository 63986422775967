import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, Inject, OnInit, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Logger } from '@mona/shared/logger';
import { getFormValidationErrors } from '@mona/shared/utils';
import { UiDynamicElementConfig } from '../../models';
import { UiDynamicFormsComponent } from '../dynamic-forms/dynamic-forms.component';

/**
 * Component with form group
 */
export interface ComponentWithFormGroup {
    /**
     * UntypedFormGroup
     */
    formGroup: UntypedFormGroup;
}

/**
 * Dialog data
 */
export interface UiDynamicFormDialogData {
    /** Title */
    title: string;
    /** Description */
    description?: string;
    /** innerHtml */
    innerHtml?: string;
    /** Cancel button label */
    cancelBtn: string;
    /** Confirm button label */
    confirmBtn: string;
    /** Force show close button even if `disableClose` option is present */
    enableClose?: boolean;
    /** Dynamic config */
    elements?: UiDynamicElementConfig[];
    /** Dynamic component */
    component?: Type<ComponentWithFormGroup>;
    /** Initial value */
    formGroup?: FormGroup<any>;
    /** Initial value */
    initialValue?: AnyObject;
}

/**
 * Dynamic form dialog component
 */
@Component({
    selector: 'ui-form-dialog',
    templateUrl: './dynamic-form-dialog.component.html',
    styleUrls: ['./dynamic-form-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UiDynamicFormDialogComponent<D = any, R = any> implements OnInit {
    /** CDK Portal */
    @ViewChild(CdkPortalOutlet, { static: true }) readonly cdkPortalOutlet: CdkPortalOutlet;
    /** Form component */
    @ViewChild(UiDynamicFormsComponent, { static: true }) readonly formComponent: UiDynamicFormsComponent;
    /** UntypedFormGroup */
    private _form: UntypedFormGroup;
    /** UntypedFormGroup */
    get form(): UntypedFormGroup {
        return this._form;
    }
    /** ComponentPortal instance */
    private componentPortal: ComponentPortal<any>;

    readonly DIALOG_FORM_ID = 'dialog-form';

    private readonly logger = new Logger('UI');

    /**
     * Constructor
     *
     * @param data
     * @param dialogRef
     * @param cdRef
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: UiDynamicFormDialogData,
        public readonly dialogRef: MatDialogRef<UiDynamicFormDialogComponent>,
        private cdRef: ChangeDetectorRef,
    ) {
        dialogRef.addPanelClass('ui-form-dialog');
    }

    /**
     * use ngOnInit lifecycle hook to initialize form data using material dialog data
     */
    ngOnInit() {
        if (this.data.elements) {
            this._form = this.formComponent.form;
        } else if (this.data.component) {
            this.componentPortal = new ComponentPortal(this.data.component);
            const component = this.cdkPortalOutlet.attach(this.componentPortal);
            if (this.data.formGroup) {
                component.instance.formGroup = this.data.formGroup;
            }
            this._form = component.instance.formGroup;
        }

        if (this.data.initialValue) {
            this.form.patchValue(this.data.initialValue);
        }

        this.cdRef.markForCheck();
    }

    /** Show form errors */
    showFormErrors() {
        if (this.form.invalid) {
            const errors = getFormValidationErrors(this.form);
            this.logger.log('FormValidationError', errors);
        }
    }
}
