import { ElementRef } from '@angular/core';
import { get, isEmptyObject, isNullOrUndefined } from '@mona/shared/utils';
import { UiDynamicElementConfig } from '../../forms';
import { UiTableColumn } from './column.intf';
import { UiTableRow } from './row.intf';

/**
 * UiTable cell value
 */
export interface UiTableCellValue extends Record<string, any> {
    /** Id */
    id?: string;
    /** Optional numeric or string value */
    value?: any;
}

/**
 * UiTable cell context
 */
export interface UiTableCell {
    /** Row */
    row: UiTableRow;
    /** Row idx */
    rowIdx: number;
    /** Column */
    column: UiTableColumn;
    /** Cell */
    cell?: UiTableCellValue;
    /** Cell has value*/
    hasValue?: boolean;
    /** Cell is disabled  */
    disabled?: boolean;
    /** Col idx */
    colIdx: number;
    /** Cell width */
    width?: number;
    /** Cell height */
    height?: number;
    /** Dynamic Form Config */
    formConfig?: UiDynamicElementConfig[];
}

/**
 * Cell context to be assigned to template
 */
export class UiTableCell implements UiTableCell {
    /** Context */
    $implicit?: UiTableCell = null;
    /** ElementRef */
    elementRef?: ElementRef<any>;
}

/**
 * Default cell value getter for table rows which have values as object
 *
 * @param row
 * @param col
 */
export function cellValueGetterFn<T extends UiTableRow>(row: T, col: UiTableColumn | undefined): UiTableCellValue {
    let cell = undefined;
    if (isNullOrUndefined(col) || isNullOrUndefined(col.dataAccessorKey) || isEmptyObject(col.meta)) {
        return cell;
    }
    if (!isEmptyObject(row.values)) {
        // eslint-disable-next-line @typescript-eslint/ban-types
        cell = row.values[get(col as {}, col.dataAccessorKey as Path<{}>)];
    }
    return cell;
}

/**
 * Show menu if cell value
 *
 * @param row
 * @param col
 */
export function cellMenuTriggerWhenFn<T extends UiTableRow>(row: T, col: UiTableColumn | undefined): boolean {
    return !isEmptyObject(cellValueGetterFn(row, col));
}
