// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.fe1.stage.monatest.icu/auto-open-api-spec.yaml 1.3.6
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validation the premedication of admission.
 * @see #/components/schemas/AdmissionPreMedication - reference to schema in open-api-spec.yaml
 * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
 * @version 1.3.6
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionPreMedication {
    /**
     * Description for Medication.
     *
     */
    description?: string;
    /**
     * Medication Code.
     * @dataFormat uuid
     */
    code?: string;
    /**
     * The code of the prescription frequency that describes how often the administration should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
     * @dataFormat uuid
     */
    frequency?: string;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    dose?: number;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit?: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionPreMedication
 */
export namespace AdmissionPreMedication {
    /**
     * AdmissionPreMedication Frontend Model with camelCase properties
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Description for Medication.
         * @type string (`dataFormat` is missing)
         */
        description?: string;
        /**
         * Medication Code.
         * @dataFormat uuid
         */
        code?: string;
        /**
         * The code of the prescription frequency that describes how often the administration should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
         * @dataFormat uuid
         */
        frequency?: string;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        dose?: number;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unit?: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionPreMedication DTO
     * Transforms AdmissionPreMedication model from/to API object
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionPreMedication model */
        static toModel(obj: AdmissionPreMedication): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionPreMedication model */
        static toApi(obj: Model): AdmissionPreMedication {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionPreMedication;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
