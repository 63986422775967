/**
 * Status of update operation
 */
export enum UpdateStatus {
    /**
     * Idle
     */
    IDLE = 'IDLE',
    /**
     * Searching
     */
    SEARCHING = 'SEARCHING',
    /**
     * NoUpdate
     */
    NO_UPDATE = 'NO_UPDATE',
    /**
     * Downloading
     */
    DOWNLOADING = 'DOWNLOADING',
    /**
     * ReadyToInstall
     */
    READY_TO_INSTALL = 'READY_TO_INSTALL',
    /**
     * Error
     */
    ERROR = 'ERROR',
}
