import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CallConfig } from '@mona/models';
import { AsyncActionState } from '@mona/store';
import { TelemedicineFeatureState } from '../entities';
import { TelemedicineConfigAction } from '../state';

/**
 * Telemedicine config service
 */
@Injectable({ providedIn: 'root' })
export class TelemedicineConfigService {
    /**
     * Constructor
     *
     * @param store Store<AppState>
     */
    constructor(private store: Store<TelemedicineFeatureState>) {}

    /**
     * Get load call config async action
     */
    getLoadConfigAction(): Observable<AsyncActionState<CallConfig>> {
        return this.store.select(state => state.telemedicine.loadCallConfigAction);
    }

    /**
     * Load config
     */
    loadConfig(): void {
        this.store.dispatch(TelemedicineConfigAction.loadCallConfig.action());
    }

    /**
     * Clears load config action state
     */
    clearLoadConfigAction(): void {
        this.store.dispatch(TelemedicineConfigAction.loadCallConfig.clearAction());
    }
}
