import { InjectionToken } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducer, ActionReducerMap, createReducer, on } from '@ngrx/store';
import { Practitioner } from '@mona/models';
import { PractitionersState } from '../../entities';
import * as PractitionersActions from '../actions/practitioners.actions';

/**  EntityAdapter<Practitioner> */
export const adapter: EntityAdapter<Practitioner> = createEntityAdapter<Practitioner>({
    selectId: (practitioner: Practitioner) => practitioner.id,
    sortComparer: (a: Practitioner, b: Practitioner) => a.displayName.localeCompare(b.displayName),
});

const initialState: PractitionersState = adapter.getInitialState({
    selectedPractitionerId: null,
    error: null,
    isLoading: false,
});

export const practitionersReducer = createReducer(
    initialState,
    /*   on(PractitionersActions.upsertPractitioner,
    (state, action) => adapter.upsertOne(action.practitioner, state)
  ), */
    on(PractitionersActions.loadPractitioners, (state, action) => ({ ...state, error: null, isLoading: true })),
    on(PractitionersActions.loadPractitionersFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
    on(PractitionersActions.loadPractitionersSuccess, (state, action) => ({
        ...adapter.upsertMany(action.practitioners, state),
        error: null,
        isLoading: false,
    })),
    on(PractitionersActions.clearPractitioners, state => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const PRACTITIONERS_REDUCER_TOKEN = new InjectionToken<
  ActionReducer<PractitionersState>
>('Practitioners Reducer');
