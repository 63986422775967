// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for the list Practitioners view.
 * @see #/components/schemas/Practitioner - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Practitioner {
    /**
     * Practitioner-ID - Synced with ID of Practitioner in CDR (FHIR)
     * @dataFormat uuid
     */
    readonly id: string;
    /**
     * Practitioner first name.
     *
     */
    first_name?: string | null;
    /**
     * Practitioner last name.
     *
     */
    last_name?: string | null;
    /**
     * Prefix for Practitioner name.
     *
     */
    prefix?: string | null;
    /**
     * roles assumed by this practitioner.
     *
     */
    readonly role: Array<Practitioner.RoleEnum>;
    /**
     * Practitioner gender.  * `MALE` - male * `FEMALE` - female * `OTHER` - other * `UNKNOWN` - unknown
     *
     */
    gender?: Practitioner.GenderEnum | null;
    /**
     * The phone number of the practitioner.
     *
     */
    phone?: string | null;
    readonly image: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Practitioner
 */
export namespace Practitioner {
    export type RoleEnum = 'doctor' | 'nurse' | 'admin';
    export const RoleEnum = {
        Doctor: 'doctor' as RoleEnum,
        Nurse: 'nurse' as RoleEnum,
        Admin: 'admin' as RoleEnum,
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER' | 'UNKNOWN' | '' | 'null';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum,
        Unknown: 'UNKNOWN' as GenderEnum,
        Empty: '' as GenderEnum,
        Null: 'null' as GenderEnum,
    };

    /**
     * Practitioner Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Practitioner-ID - Synced with ID of Practitioner in CDR (FHIR)
         * @dataFormat uuid
         */
        readonly id: string;
        /**
         * Practitioner first name.
         * @type string (`dataFormat` is missing)
         */
        firstName?: string | null;
        /**
         * Practitioner last name.
         * @type string (`dataFormat` is missing)
         */
        lastName?: string | null;
        /**
         * Prefix for Practitioner name.
         * @type string (`dataFormat` is missing)
         */
        prefix?: string | null;
        /**
         * roles assumed by this practitioner.
         * @type Array<string> (`dataFormat` is missing)
         */
        readonly role: Array<Practitioner.RoleEnum>;
        /**
         * Practitioner gender.  * `MALE` - male * `FEMALE` - female * `OTHER` - other * `UNKNOWN` - unknown
         * @type string (`dataFormat` is missing)
         */
        gender?: Practitioner.GenderEnum | null;
        /**
         * The phone number of the practitioner.
         * @type string (`dataFormat` is missing)
         */
        phone?: string | null;
        readonly image: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Practitioner DTO
     * Transforms Practitioner model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Practitioner model */
        static toModel(obj: Practitioner): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Practitioner model */
        static toApi(obj: Model): Practitioner {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Practitioner;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
