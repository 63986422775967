/**
 * Auto lock options
 * NOTE: value is a number of milliseconds
 */
export enum AutoLock {
    MINUTE_1 = 60000,
    MINUTE_3 = 180000,
    MINUTE_5 = 300000,
    MINUTE_10 = 600000,
    NEVER = 0,
}
