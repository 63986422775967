/* eslint-disable jsdoc/require-jsdoc */
import { ApiModels } from '@mona/api';

type UserRole = ApiModels.Practitioner.RoleEnum;
/**
 * Mona User = {@link ApiModels.Practitioner}
 *
 * Transforms API Practitioner
 */
export class User {
    id: string;
    displayName: string;
    firstName: string;
    lastName: string;
    profileImageUrl?: string;
    phone?: string;
    prefix?: string;
    role?: UserRole[];
    pin?: string;
    rfid?: string;

    constructor(data: ApiModels.Practitioner) {
        this.id = data.id;
        this.lastName = data.last_name;
        this.firstName = data.first_name;
        this.displayName = `${data.prefix || ''} ${data.first_name} ${data.last_name}`?.trim();
        this.profileImageUrl = data.image;
        this.phone = data.phone;
        this.prefix = data.prefix;
        this.role = data.role;
        this.pin = data['pin'];
        this.rfid = data['rfid'];
    }
}
