import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsPatientFieldChangedPipe } from './is-patient-field-changed.pipe';
import { PatientAgePipe } from './patient-age.pipe';
import { PatientDisplayNamePipe } from './patient-display-name.pipe';

const PIPES = [PatientAgePipe, PatientDisplayNamePipe, IsPatientFieldChangedPipe];

/**
 * INFO: add comment
 */
@NgModule({
    declarations: PIPES,
    exports: PIPES,
    imports: [CommonModule],
    providers: [...PIPES],
})
export class PatientPipesModule {}
