/* eslint-disable @angular-eslint/component-selector */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter, map, pluck, take } from 'rxjs/operators';
import { FhirConfig, FhirConfigEntry, SearchDiagnosisResult, VerificationStatus } from '@mona/models';
import { DataAccessFhirConfigFacade } from '@mona/pdms/data-access-fhir';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { Async, AsyncComponent, OnChange, SimpleChange } from '@mona/shared/utils';
import { DiagnosesForm, DiagnosesItemForm } from '../../models';

/**
 * Diagnoses form
 */
@Async()
@Component({
    selector: 'app-diagnoses-form',
    templateUrl: './diagnoses-form.component.html',
    styleUrls: ['./diagnoses-form.component.scss'],
})
export class DiagnosesFormComponent extends AsyncComponent implements AfterViewInit, OnInit {
    /**
     * The form for added items
     */
    @Input() formGroup: FormGroup<DiagnosesForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is form active / visible
     */
    @OnChange(function (this: DiagnosesFormComponent, value: boolean, change: SimpleChange<boolean>) {
        if (value === false && change.previousValue === true) {
            this.addDiagnosis();
        }
    })
    @Input()
    isActive: boolean;

    /**
     * Is read only
     */
    @Input() isReadOnly: boolean;

    /**
     * Reference to the new item input html element
     */
    @ViewChild('newItemInput') newItemInput: ElementRef<HTMLInputElement>;

    /**
     * Reference to the new diagnosis status select
     */
    @ViewChild('newEntryStatus', { read: MatSelect }) newEntryStatusSelect: MatSelect;

    /**
     * The related next button
     */
    @ContentChild('diagnosisNextBtn', { read: ElementRef }) private nextButton: ElementRef<HTMLButtonElement>;

    /**
     * The internal form group for new items
     */
    newItem = new FormGroup<DiagnosesItemForm>({
        text: new FormControl<string>(null, Validators.required),
        status: new FormControl<VerificationStatus>(VerificationStatus.CONFIRMED),
        icd10Code: new FormControl<string>(null),
    });

    /**
     * Available verification states
     */
    verificationStates = Object.keys(VerificationStatus)
        .map(key => VerificationStatus[key])
        .filter(value => typeof value === 'string')
        .map((state: string) => {
            return {
                enumMember: state,
                label: this.translateService.instant('verificationStatus.' + state.replace(/-/g, '').toLowerCase()),
            };
        });

    /**
     * Min length for autocomplete
     */
    autoCompleteMinLength = 3;

    /**
     * Detects if adding on blur should be currently ignored.
     * A case for dropdown icd10code selection
     */
    preventBlurAdding: boolean;

    /**
     * Rendered autocomplete controls
     * Prepared as ViewChildren in case that later there will be more autocompletes
     */
    @ViewChildren(MatAutocomplete) private autocompletes: QueryList<MatAutocomplete>;

    /**
     * Search results for the autocomplete
     */
    foundDiagnoses$ = this.terminologyService.getSearchDiagnosisAction().pipe(
        map(state => {
            const result = state?.result && [...state.result];
            const controlValue = this.newItem.controls.text.value;

            if (result && !result.find(item => item.displayName === controlValue)) {
                result.unshift({
                    displayName: controlValue,
                    code: null,
                });
            }

            return result;
        }),
    );

    /**
     * Status of the autocomplete search
     */
    searchDiagnosisInProgress$ = this.terminologyService
        .getSearchDiagnosisAction()
        .pipe(map(state => state && state.inProgress));

    /**
     * Diagnoses fhir config
     */
    diagnosesFhirConfig$: Observable<FhirConfigEntry> = this.fhirConfigFacade.fhirConfig$.pipe(
        filter<FhirConfig>(Boolean),
        pluck('diagnoses'),
    );

    /**
     * Constructor
     *
     * @param translateService TranslateService
     * @param terminologyService TerminologyService
     * @param changeDetectorRef ChangeDetectorRef
     * @param fhirConfigFacade FhirConfigFacade,
     */
    constructor(
        private translateService: TranslateService,
        private terminologyService: TerminologyService,
        private changeDetectorRef: ChangeDetectorRef,
        private fhirConfigFacade: DataAccessFhirConfigFacade,
    ) {
        super();
    }

    /**
     * NG Hook
     */
    ngOnInit(): void {
        // New item autocomplete field
        this.async(
            this.newItem.controls.text.valueChanges.pipe(debounceTime(400)).subscribe(value => {
                if (value && value.length >= this.autoCompleteMinLength) {
                    this.terminologyService.searchDiagnosis(value);
                } else {
                    this.clearAutoCompleteResults();
                }
            }),
        );
    }

    /**
     * NG Hook
     */
    ngAfterViewInit(): void {
        // Sets validators based on array status so that we need at least one diagnosis to continue with the wizard
        this.async(
            this.formGroup.controls.items.statusChanges.subscribe(status => {
                if (status === 'INVALID' || status === 'PENDING') {
                    this.newItem.controls.text.setValidators(Validators.required);
                } else {
                    this.newItem.controls.text.clearValidators();
                }
            }),
        );

        if (this.nextButton) {
            // handling late validation case
            this.async(
                fromEvent(this.nextButton.nativeElement, 'click').subscribe(() => {
                    setTimeout(() => {
                        if (this.isActive) {
                            this.nextButton.nativeElement.click();
                        }
                    }, 600);
                }),
            );
        }
    }

    /**
     * Removes a diagnosis from the array
     *
     * @param index index of the item that should be removed
     * @param event MouseEvent
     */
    async removeDiagnosis(index: number, event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.formGroup.controls.items.removeAt(index);
        this.formGroup.controls.items.updateValueAndValidity();
        this.changeDetectorRef.detectChanges();
    }

    /**
     * React on native blur event
     */
    onNewItemNativeBlur(): void {
        // Check for BedSideMode is needed as the on screen keyboard blurs the input in every keystroke.
        if (this.isBedSideMode === false) {
            // Set timeout is needed to prevent conflict when clicking autocomplete - 2 items are added as a bug
            setTimeout(() => {
                if (this.preventBlurAdding) {
                    return;
                }
            });
        }
    }

    /**
     * Triggered when add button is clicked
     */
    onAddClicked(): void {
        // Triggering add button is another problem with ability to detect currently focused input
        setTimeout(() => {
            // Close all open autocomplete panels
            this.clearAutoCompleteResults();
            this.autocompletes.toArray().forEach(autocomplete => {
                autocomplete.showPanel = false;
            });
            this.newItemInput.nativeElement.blur();
        }, 100);
    }

    /**
     * Adds a new diagnosis to the array.
     */
    async addDiagnosis(): Promise<void> {
        if (this.preventBlurAdding) {
            return;
        }

        // Waits for status select close before adding new input otherwise wrong select stays focused
        if (this.newEntryStatusSelect?.focused) {
            await this.newEntryStatusSelect.openedChange.pipe(take(1)).toPromise();
            // blurs to avoid wrong focus after adding new
            this.newEntryStatusSelect._elementRef.nativeElement.blur();
        }

        if (this.newItem.value.text && this.newItem.value.text.trim().length > 0) {
            // Add the new item to the list of allergies
            this.addArrayItem(this.newItem.value);
        }
    }

    /**
     * Adds a new form control item for an diagnosis
     *
     * @param item DiagnosesItemForm
     */
    addArrayItem(item: any): void {
        // There is no validator set on the form control to ensure, that the field is not displayed in error state on
        // submit
        // Create a new form group with validator
        const itemToAdd = new FormGroup<DiagnosesItemForm>({
            text: new FormControl<string>(null, Validators.required),
            status: new FormControl<VerificationStatus>(null),
            icd10Code: new FormControl<string>(null),
        });

        // Fill new form group
        itemToAdd.reset(item);

        // Disable text editing when predefined diagnosis is selected
        if (item.icd10Code) {
            itemToAdd.controls.text.disable();
        }

        // Add to list
        this.formGroup.controls.items.push(itemToAdd);
        this.formGroup.controls.items.updateValueAndValidity();

        // Reset the new item form
        this.newItem.reset({
            status: VerificationStatus.CONFIRMED,
            text: null,
            icd10Code: null,
        });

        // Close all open autocomplete panels
        this.autocompletes.toArray().forEach(autocomplete => {
            autocomplete.showPanel = false;
        });

        this.preventBlurAdding = false;
    }

    /**
     * Called on form submit.
     * Adds a new diagnosis on form submit
     *
     * @param $event event
     */
    onSubmit($event?: Event): void {
        // If this is a real form submit dont act as normal
        if ($event) {
            $event.preventDefault();
        }

        // Add the new diagnosis if filled out
        this.addDiagnosis();
    }

    /**
     * Formats the output for autocomplete
     *
     * @param result DiagnosisSearchResult | string
     */
    autoCompleteDisplayFn(result: SearchDiagnosisResult | string): string {
        return typeof result === 'string' ? result : result?.displayName || '';
    }

    /**
     * Adds an autocomplete item as new entry
     *
     * @param $event MatAutocompleteSelectedEvent
     */
    onNewItemAutoCompleteSelect($event: MatAutocompleteSelectedEvent): void {
        if (this.isBedSideMode && this.isActive) {
            // dealing with on screen keyboard
            setTimeout(async () => {
                this.preventBlurAdding = true;
                this.newItemInput.nativeElement.blur();
                this.addArrayItem({
                    icd10Code: $event.option.value.code,
                    text: $event.option.value.displayName,
                    status: this.newItem.value.status,
                });

                this.clearAutoCompleteResults();
            });
        } else {
            this.preventBlurAdding = true;
            this.addArrayItem({
                icd10Code: $event.option.value.code,
                text: $event.option.value.displayName,
                status: this.newItem.value.status,
            });

            this.clearAutoCompleteResults();
        }
    }

    /**
     * Clears the auto complete search results
     */
    clearAutoCompleteResults(): void {
        this.terminologyService.clearSearchDiagnoses();
    }
}
