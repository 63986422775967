import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { fromNotifications, NotificationsEffects } from './state';

/** DataAccessNotificationsModule */
@NgModule({
    imports: [
        StoreModule.forFeature(fromNotifications.notificationsFeatureKey, fromNotifications.reducer),
        EffectsModule.forFeature([NotificationsEffects]),
    ],
})
export class DataAccessNotificationsModule {}
