import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ExternalResourcesEffects, fromExternalResources } from './state';

/** DataAccessExternalResourcesModule */
@NgModule({
    imports: [
        StoreModule.forFeature(fromExternalResources.externalResourcesFeatureKey, fromExternalResources.reducer),
        EffectsModule.forFeature([ExternalResourcesEffects]),
    ],
})
export class DataAccessExternalResourcesModule {}
