/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Procedure } from '@mona/models';

export const loadProcedures = createAction('LOAD_PROCEDURES');

export const loadProceduresSuccess = createAction('LOAD_PROCEDURES_SUCCESS', props<{ procedures: Procedure[] }>());

export const loadProceduresFailure = createAction('LOAD_PROCEDURES_FAILURE', props<{ error: Error }>());

export const upsertProcedures = createAction('[Procedure/API] Upsert Procedures', props<{ procedures: Procedure[] }>());

export const updateProcedures = createAction(
    '[Procedure/API] Update Procedures',
    props<{ updates: Update<Procedure>[] }>(),
);

export const deleteProcedures = createAction('[Procedure/API] Delete Procedures', props<{ ids: string[] }>());

export const clearProcedures = createAction('[Procedure/API] Clear Procedures');

export const addProceduresChanges = createAction(
    '[Procedures/API] Add Changes',
    props<{ toUpdateEntities: Procedure[]; toRemoveIds: string[] }>(),
);

export const upsertProcedure = createAction('[Procedure/API] Upsert Procedure', props<{ procedure: Procedure }>());
