// See https://github.com/zhaosiyang/property-watch-decorator for usage notes. This is a fork of open source lib
import { Constructor } from 'type-fest';

/**
 * Simple change custom interface
 */
export interface SimpleChange<T> {
    /**
     * Is first change
     */
    firstChange: boolean;

    /**
     * Previous value
     */
    previousValue: T;

    /**
     * Current/new value
     */
    currentValue: T;

    /**
     * Is first change as a method
     */
    isFirstChange: () => boolean;
}

/**
 * Callback function type
 */
export type CallBackFunction<T> = (value: T, change?: SimpleChange<T>) => void;

/**
 * On change custom decorator
 *
 * @param callback CallBackFunction<T> | string
 */
export function OnChange<T = Constructor<any>>(callback: CallBackFunction<T> | FunctionPropertyNames<T>) {
    const cachedValueKey = Symbol();
    const isFirstChangeKey = Symbol();

    return (target: any, key: PropertyKey) => {
        const callBackFn: CallBackFunction<T> = typeof callback === 'string' ? target[callback] : callback;
        if (!callBackFn) {
            throw new Error(`Cannot find method ${callback.toString()} in class ${target.constructor.name}`);
        }

        Object.defineProperty(target, key, {
            /* eslint-disable object-shorthand */
            set: function (value) {
                // change status of "isFirstChange"
                this[isFirstChangeKey] = this[isFirstChangeKey] === undefined;

                // No operation if new value is same as old value
                if (!this[isFirstChangeKey] && this[cachedValueKey] === value) {
                    return;
                }

                const oldValue = this[cachedValueKey];
                this[cachedValueKey] = value;
                const simpleChange: SimpleChange<T> = {
                    firstChange: this[isFirstChangeKey],
                    previousValue: oldValue,
                    currentValue: this[cachedValueKey],
                    isFirstChange: () => this[isFirstChangeKey],
                };
                callBackFn.call(this, this[cachedValueKey], simpleChange);
            },
            get: function () {
                return this[cachedValueKey];
            },
            /* eslint-enable object-shorthand */
        });
    };
}
