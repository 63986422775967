import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpRequestLike, WsService } from '@mona/api';
import { ConfigService } from '@mona/config';
import { IpcMainEvent } from '@mona/events';
import { CallWsMessage } from '@mona/models';
import { MonaRpcService } from '@mona/rpc';

/**
 * Call websocket service
 */
@Injectable({
    providedIn: 'root',
})
export class CallWsService extends WsService<CallWsMessage> {
    private tokenGetter: () => Promise<string> = () =>
        this.rpcService.invoke<string>(IpcMainEvent.GET_JWT_TOKEN, {
            isTelemedicine: true,
        });

    /**
     * Constructor
     *
     * @param configService ConfigService
     * @param rpcService
     */
    constructor(private configService: ConfigService, private rpcService: MonaRpcService) {
        super();
    }

    /**
     * Connects to ws for call
     *
     * @param sessionId
     */
    connect(sessionId: string): Observable<any> {
        const req: HttpRequestLike = {
            url: `${this.configService.get('api.telemedicineWsUrl')}/ws/sessions/${sessionId}/`,
        };
        return from(this.tokenGetter()).pipe(
            switchMap(accessToken => {
                this.setConfig(req.url, accessToken, null, 3000, 3);
                return super.connect();
            }),
        );
    }
}
