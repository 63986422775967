/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { CdkRow, CdkRowDef } from '@angular/cdk/table';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Directive,
    ElementRef,
    Input,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { UiTableRow } from '../models';

/**
 * Data row definition for the mat-table.
 * Captures the data row's template and other properties such as the columns to display and
 * a when predicate that describes when this row should be used.
 */
@Directive({
    selector: '[uiRowDef]',
    providers: [{ provide: CdkRowDef, useExisting: UiTableRowDef }],
    inputs: ['columns: uiRowDefColumns', 'when: uiRowDefWhen'],
})
export class UiTableRowDef<T> extends CdkRowDef<T> {}

/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
    selector: 'ui-row, tr[ui-row]',
    template: `
        <ng-container cdkCellOutlet></ng-container>
    `,
    host: {
        class: 'mat-row ui-table-tr',
        role: 'row',
    },
    // See note on CdkTable for explanation on why this uses the default change detection strategy.
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'uiRow',
    providers: [{ provide: CdkRow, useExisting: UiTableRowR }],
})
export class UiTableRowR extends CdkRow implements AfterViewInit {
    @Input() row: UiTableRow;

    /** @internal */
    constructor(private elRef: ElementRef, private render: Renderer2) {
        super();
    }

    /** @internal */
    ngAfterViewInit(): void {
        // TODO: use renderer to manipulate row element - if needed
    }
}
