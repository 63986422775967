// External modules
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
// Angular CDK
// Angular material
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// Components and directives

const DEFAULT_PROVIDERS = [];

/**
 * KeyboardModule
 */
@NgModule({
    imports: [
        // Angular modules
        CommonModule,
        OverlayModule,
        // Forms
        FormsModule,
        ReactiveFormsModule,
        // Cdk modules
        PortalModule,
        // Material modules
        MatButtonModule,
        MatCommonModule,
        MatIconModule,
        MatInputModule,
        MatRippleModule,
    ],
})
export class KeyboardModule {
    /**
     * forRoot
     *
     * @param configuredProviders
     */
    static forRoot(configuredProviders: Provider[] = DEFAULT_PROVIDERS): ModuleWithProviders<KeyboardModule> {
        return {
            ngModule: KeyboardModule,
            providers: [...configuredProviders],
        };
    }
}
