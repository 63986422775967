<div class="error">
    <div class="error__image">
        <img src="assets/images/mona-lock.svg" alt="mona-lock.svg" />
    </div>
    <div class="error__title">{{ 'auth.error.access.title' | translate }}</div>
    <div class="error__description">{{ 'auth.error.access.description' | translate }}</div>
    <button
        id="btnNavigateTo"
        mat-raised-button
        color="primary"
        class="error__button"
        (click)="navigateTo()"
        data-testid="btnNavigateTo"
    >
        {{ 'auth.error.access.navigate' | translate }}
    </button>
</div>
