import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasCellValuePipe } from './has-cell-value.pipe';
import { HasContentChangesPipe } from './has-content-changes.pipe';
import { HasDayValuesPipe } from './has-day-values.pipe';
import { HasDiagnosisContentChangesPipe } from './has-diagnosis-content-changes.pipe';
import { HasPrevPipe } from './has-prev.pipe';
import { HistoryByDatePipe } from './history-by-date.pipe';
import { HistoryDateTitlePipe } from './history-date-title.pipe';
import { IsCellChangedPipe } from './is-cell-changed.pipe';
import { IsCellRemovedPipe } from './is-cell-removed.pipe';
import { IsCellSuggestedPipe } from './is-cell-suggested.pipe';
import { IsItemChangedPipe } from './is-item-changed.pipe';
import { TableCellWidthPipe } from './table-cell-width.pipe';

const PIPES = [
    HasContentChangesPipe,
    IsItemChangedPipe,
    HasDiagnosisContentChangesPipe,
    HistoryByDatePipe,
    HistoryDateTitlePipe,
    TableCellWidthPipe,
    IsCellRemovedPipe,
    IsCellChangedPipe,
    HasCellValuePipe,
    HasDayValuesPipe,
    IsCellSuggestedPipe,
    HasPrevPipe,
];

/**
 * EncounterPipesModule
 */
@NgModule({
    imports: [CommonModule],
    declarations: PIPES,
    exports: PIPES,
    providers: PIPES,
})
export class EncounterPipesModule {}
