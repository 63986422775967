import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { transformApiDeviceResponse } from '@mona/device/data-access-device';
import { RegisterDeviceResult } from '@mona/models';

/**
 * API Service for the telemedicine API
 */
@Injectable({
    providedIn: 'root',
})
export class TelemedicineSetupApi {
    apiBase = '/telemedicine/auth/devices/';
    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Registers the device at core
     *
     * @param hospitalCode string
     * @param publicKey string
     * @param deviceMac string
     */
    registerDevice(hospitalCode: string, publicKey: string, deviceMac: string): Observable<RegisterDeviceResult> {
        const payload: ApiModels.DeviceRegistrationRequest & any = {
            hospital_code: hospitalCode,
            public_key: publicKey,
            id: deviceMac.toLowerCase(),
        };

        return this.http
            .post<ApiModels.DeviceResponse>(this.apiBase, payload)
            .pipe(map(result => transformApiDeviceResponse(result)));
    }

    /**
     * Check if device is activated
     *
     * @param deviceMac string
     */
    checkIsDeviceActivated(deviceMac: string): Observable<RegisterDeviceResult> {
        return this.http
            .get<ApiModels.DeviceResponse>(`${this.apiBase}${deviceMac.toLowerCase()}/`)
            .pipe(map(result => transformApiDeviceResponse(result)));
    }
}
