<mat-dialog-content data-testid="dc-availableDischargeReasons" class="pending-changes-dialog-content">
    <h2 mat-dialog-title>
        {{ 'pendingChangesDialog.title' | translate }}
    </h2>
    <div class="pending-changes-dialog__content">
        <div class="pending-changes-dialog__avatar-container">
            <div diameter="56" class="pending-changes-dialog__avatar">
                <!-- TODO: Add image of real user who have made changes(new API endpoint needed for this) -->
                <img alt="" class="pending-changes-dialog__avatar-image" />
            </div>

            <mat-icon class="pending-changes-dialog__avatar-alert-icon" [svgIcon]="'alert'"></mat-icon>
        </div>

        <div class="pending-changes-dialog__text">
            <p class="pending-changes-dialog__text-paragraph_1">
                {{
                    (data?.hasPermission
                        ? 'pendingChangesDialog.textWithAccess.paragraph_1'
                        : 'pendingChangesDialog.textWithoutAccess.paragraph_1'
                    ) | translate
                }}
            </p>
            <p class="pending-changes-dialog__text-paragraph_2">
                {{
                    (data?.hasPermission
                        ? 'pendingChangesDialog.textWithAccess.paragraph_2'
                        : 'pendingChangesDialog.textWithoutAccess.paragraph_2'
                    ) | translate
                }}
            </p>
            <p *ngIf="data?.hasPermission" class="pending-changes-dialog__text-paragraph_3">
                {{ 'pendingChangesDialog.textWithAccess.paragraph_3' | translate }}
            </p>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div
        class="pending-changes-dialog-actions"
        [style.justify-content]="data.hasPermission ? 'space-between' : 'flex-end'"
    >
        <div>
            <button
                color="primary"
                mat-button
                mat-dialog-close
                (click)="closeDialogWithType(pendingChangesEmiType.LOGOUT)"
            >
                {{ 'pendingChangesDialog.logOut' | translate }}
            </button>
        </div>
        <div>
            <button
                *ngIf="data?.hasPermission"
                color="primary"
                mat-button
                mat-dialog-close
                (click)="closeDialogWithType(pendingChangesEmiType.REVIEW)"
            >
                {{ 'pendingChangesDialog.reviewChanges' | translate }}
            </button>
            <button
                color="primary"
                class="mat-dialog-actions__action-btn"
                mat-raised-button
                [mat-dialog-close]="true"
                (click)="closeDialogWithType(pendingChangesEmiType.DISCARD)"
            >
                {{ 'pendingChangesDialog.discardChanges' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>
