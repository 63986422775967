import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { VaccinationStatus, VaccineCode } from '@mona/models';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { VaccinationForm } from '../../models';

/**
 * Vaccination dialog
 */
@Component({
    selector: 'app-vaccination-dialog',
    templateUrl: './vaccination-dialog.component.html',
    styleUrls: ['./vaccination-dialog.component.scss'],
})
export class VaccinationDialogComponent {
    /**
     * Vaccination form
     */
    vaccinationForm = new FormGroup<VaccinationForm>({
        vaccinationDate: new FormControl<Date>(null, Validators.required),
        vaccinationAgainst: new FormControl<VaccineCode>(null, Validators.required),
        verificationManufacturer: new FormControl<string>(''),
    });

    /**
     * All available vaccineCodes
     */
    vaccineCodes$ = this.terminologyService.getVaccineCodes();

    /**
     * Control for vaccine search
     */
    vaccineSearchText = new FormControl<string | VaccineCode>('');

    /**
     * Constructor
     *
     * @param terminologyService TerminologyService
     * @param dialogRef MatDialogRef<VaccinationDialogComponent>
     * @param data dialog data
     */
    constructor(
        private terminologyService: TerminologyService,
        private dialogRef: MatDialogRef<VaccinationDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            vaccination: VaccinationStatus;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-vaccination-dialog');

        if (data.vaccination.id) {
            this.vaccineCodes$.pipe(take(1)).subscribe((vaccineCodes: VaccineCode[]) => {
                const selectedVaccinationCode = vaccineCodes.find(
                    ({ code }) => data.vaccination?.vaccinationAgainst === code,
                ) as VaccineCode;
                this.vaccinationForm.patchValue({ ...data.vaccination, vaccinationAgainst: selectedVaccinationCode });
            });
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.vaccinationForm.markAsTouched();
        // To avoid free text in input
        if (
            typeof this.vaccineSearchText.value === 'string' &&
            this.vaccineSearchText.value !== this.vaccinationForm.controls.vaccinationAgainst.value?.displayName
        ) {
            return;
        }

        if (this.vaccinationForm.valid) {
            this.dialogRef.close({
                id: this.data.vaccination.id,
                vaccinationDate: this.vaccinationForm.controls.vaccinationDate.value,
                vaccinationAgainst: this.vaccinationForm.controls.vaccinationAgainst.value?.code,
                verificationManufacturer: this.vaccinationForm.controls.verificationManufacturer.value,
            } as VaccinationStatus);
        }
    }
}
