import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UtilsModule } from '@mona/shared/utils';
import { DrawerHeaderModule } from '../drawer/drawer-header/drawer-header.module';
import { UiInfoListItemModule } from '../info-list-item';
import { UserMenuAvatarComponent } from './user-menu-avatar.component';
import { UserMenuHeaderComponent } from './user-menu-header/user-menu-header.component';
import { UserMenuComponent } from './user-menu.component';

/**
 * UserMenuModule
 */
@NgModule({
    declarations: [UserMenuComponent, UserMenuAvatarComponent, UserMenuHeaderComponent],
    imports: [
        UtilsModule,
        DrawerHeaderModule,
        UiInfoListItemModule,
        OverlayModule,
        MatBottomSheetModule,
        MatCardModule,
        MatToolbarModule,
    ],
    exports: [UserMenuComponent, UserMenuAvatarComponent, UserMenuHeaderComponent],
})
export class UiUserMenuModule {}
