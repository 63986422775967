import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    skipWhile,
    switchMap,
    take,
    tap,
    timeout,
} from 'rxjs/operators';
import { ApiHealthFacade } from '@mona/api';
import { ConfigActions, ConfigHttpLoader, ConfigService } from '@mona/config';
import { DeviceSetupStatus, RegisterDeviceResult } from '@mona/models';
import { Logger } from '@mona/shared/logger';
import { AppError, notEmpty, PLATFORM, Platform, onlyLoaded, isNullOrUndefined } from '@mona/shared/utils';
import { RouterActions } from '@mona/store';
import { DialogService } from '@mona/ui';
import { DeviceState } from '../../entities';
import { SetupApi } from '../../infrastructure';
import { SetupActions } from '../actions';
import { DeviceSelectors, SetupSelectors } from '../selectors';

/**
 * Setup effects
 */
@Injectable()
export class SetupEffects implements OnInitEffects {
    private readonly logger = new Logger('DEVICE');
    /**
     * Register device effect
     */
    registerDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.registerDevice),
            concatLatestFrom(() => [
                this.store$.select(DeviceSelectors.selectDeviceKeys),
                this.store$.select(DeviceSelectors.selectDeviceMacAddress),
            ]),
            switchMap(([, { sshKey }, deviceMac]) => {
                if (!sshKey && !deviceMac) {
                    return of(SetupActions.registerDeviceFailure({ error: new AppError('Register device failed') }));
                }

                return this.setupApi.registerDevice(sshKey.publicKey, deviceMac).pipe(
                    map((result: RegisterDeviceResult) => SetupActions.registerDeviceSuccess({ ...result })),
                    catchError(error => of(SetupActions.registerDeviceFailure({ error }))),
                );
            }),
        ),
    );

    /**
     * Check is device activated
     * - run only on the device
     * - run only after config was loaded and base url available
     * - run only if API health check for base url was successful
     */
    checkIsDeviceActivated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.checkIsDeviceActivated),
            skipWhile(() => !this.platform.isElectron),
            switchMap(() => this.configService.select('api.coreWsUrl').pipe(notEmpty())),
            switchMap(serverUrl => {
                const isConnected = this.apiHealthFacade.online;

                return isConnected
                    ? this.apiHealthFacade.healthStateByServer$(serverUrl).pipe(notEmpty(), onlyLoaded(), take(1))
                    : of({
                          isConnected,
                          error: 'errors.offline',
                      });
            }),
            concatLatestFrom(() => [
                this.store$.select(DeviceSelectors.selectDeviceMacAddress).pipe(notEmpty()),
                this.store$.select(SetupSelectors.selectDeviceRegistrationSent),
            ]),
            switchMap(([health, deviceMac, isRegistrationSent]) => {
                if (!health.isConnected) {
                    return of(SetupActions.checkIsDeviceActivatedFailure({ error: health.error }));
                }
                return this.setupApi.checkIsDeviceActivated(deviceMac).pipe(
                    timeout(environment.httpTimeout),
                    map(result => {
                        if (!result.isActivated && !isRegistrationSent) {
                            return SetupActions.registerDevice();
                        }
                        return SetupActions.checkIsDeviceActivatedSuccess({ ...result });
                    }),
                    catchError((error: AppError) => of(SetupActions.checkIsDeviceActivatedFailure({ error }))),
                );
            }),
        ),
    );

    /**
     * If device is not activated -  send `auth/device/register` POST
     */
    onActivated$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SetupActions.checkIsDeviceActivatedSuccess),
                concatLatestFrom(() =>
                    this.store$
                        .select(SetupSelectors.selectDeviceSetupStatus)
                        .pipe(filter(status => status !== DeviceSetupStatus.AwaitingActivation)),
                ),
                tap(([{ isActivated }, status]) => {
                    if (!isActivated && status !== DeviceSetupStatus.RegistrationFailed) {
                        this.store$.dispatch(SetupActions.registerDevice());
                    }
                }),
            ),
        { dispatch: false },
    );

    deviceActivatedFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SetupActions.checkIsDeviceActivatedFailure, SetupActions.registerDeviceFailure),
                tap(({ error }) => {
                    if (error.errorCode == 'device.not_found') {
                        this.logger.log('Setup: request for "/auth/devices/" failed because device was not found');
                        this.store$.dispatch(SetupActions.registerDevice());
                    }
                    if (error.httpError && (error.status == HttpStatusCode.Forbidden || !error.status)) {
                        this.logger.log(
                            'Setup: request for "/auth/devices/" failed, navigate to Setup Screen, adn close all open outlets',
                        );
                        // Remove any dialogs if present
                        this.dialogService.closeAll();
                        const outlets = { dialog: null, side: null, overlay: null };
                        // Check if setup wizard was started by checking if lastSetupWizardStep is set
                        const lastSetupWizardStep = this.configService.get('lastSetupWizardStep');
                        const shouldRedirectToActivation =
                            !isNullOrUndefined(lastSetupWizardStep) &&
                            !['RegionAndKeyboard', 'Network', 'Diagnostics'].includes(lastSetupWizardStep);
                        this.store$.dispatch(
                            RouterActions.navigateAction({
                                path: ['/device/setup', { outlets }],
                                query: { step: shouldRedirectToActivation && 'Activation' },
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Register should also react on core api url change, and send registration request
     *
     * Also update config from remote when url changes
     */
    onConfigChange$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ConfigActions.updateConfig),
                map(({ config }) => config?.api?.coreWsUrl),
                distinctUntilChanged(),
                pairwise(),
                tap(([prevUrl, newUrl]) => {
                    if (newUrl && newUrl !== prevUrl) {
                        const remoteLoader = new ConfigHttpLoader(this.http, newUrl + '/configuration/licenses');
                        this.configService.updateFromRemote(remoteLoader);
                        this.store$.dispatch(SetupActions.clearDeviceActivatedStatus());
                        this.store$.dispatch(SetupActions.registerDevice());
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param http
     * @param store$
     * @param actions$ Actions
     * @param setupApi SetupApi
     * @param configService
     * @param dialogService
     * @param apiHealthFacade
     * @param platform
     */
    constructor(
        private http: HttpClient,
        private store$: Store<{ device: DeviceState }>,
        private actions$: Actions,
        private setupApi: SetupApi,
        private configService: ConfigService,
        private dialogService: DialogService,
        private apiHealthFacade: ApiHealthFacade,
        @Inject(PLATFORM) public platform: Platform,
    ) {}

    /**
     * Implement this interface to dispatch a custom action after
     * the effect has been added. You can listen to this action
     * in the rest of the application to execute something after
     * the effect is registered.
     */
    ngrxOnInitEffects(): Action {
        return SetupActions.checkIsDeviceActivated();
    }
}
