import { createAction, props } from '@ngrx/store';
import { Encounter, BasicCareProcedure, Procedure, EntryControl } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';
import { AsyncAction, SucceededAction, FailedAction } from '@mona/store';
import { CareChecksMap, GlasgowComaScalesMap } from '../../entities';

/**
 * Care reports actions
 */
export class CareReportsAction {
    /**
     * Load basic care procedures action
     */
    static loadBasicCareProceduresAction: AsyncAction<
        {
            encounterId?: EntityId<Encounter>;
        },
        BasicCareProcedure[]
    > = {
        action: createAction(
            'ENCOUNTER:LOAD_BASIC_CARE_PROCEDURES',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction(
            'ENCOUNTER:LOAD_BASIC_CARE_PROCEDURES_SUCCEEDED',
            props<SucceededAction<BasicCareProcedure[]>>(),
        ),

        failedAction: createAction('ENCOUNTER:LOAD_BASIC_CARE_PROCEDURES_FAILED', props<FailedAction>()),

        clearAction: createAction('ENCOUNTER:LOAD_BASIC_CARE_PROCEDURES_CLEAR'),
    };

    /**
     * Load procedures action
     */
    static loadProceduresAction: AsyncAction<
        {
            encounterId: EntityId<Encounter>;
        },
        Procedure[]
    > = {
        action: createAction(
            'ENCOUNTER:LOAD_PROCEDURES',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction('ENCOUNTER:LOAD_PROCEDURES_SUCCEEDED', props<SucceededAction<Procedure[]>>()),

        failedAction: createAction('ENCOUNTER:LOAD_PROCEDURES_FAILED', props<FailedAction>()),

        clearAction: createAction('ENCOUNTER:LOAD_PROCEDURES_CLEAR'),
    };

    /**
     * Load entry controls action
     */
    static loadEntryControlsAction: AsyncAction<
        {
            encounterId?: EntityId<Encounter>;
        },
        EntryControl[]
    > = {
        action: createAction(
            'ENCOUNTER:LOAD_ENTRY_CONTROLS',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction(
            'ENCOUNTER:LOAD_ENTRY_CONTROLS_SUCCEEDED',
            props<SucceededAction<EntryControl[]>>(),
        ),

        failedAction: createAction('ENCOUNTER:LOAD_ENTRY_CONTROLS_FAILED', props<FailedAction>()),

        clearAction: createAction('ENCOUNTER:LOAD_ENTRY_CONTROLS_CLEAR'),
    };

    /**
     * Set selected interval
     */
    static setIntervalAction = createAction(
        'ENCOUNTER:SET_CARE_REPORTS_INTERVAL',
        props<{
            interval: EntriesInterval;
        }>(),
    );

    /**
     * Set UI end date
     */
    static setEndDateAction = createAction(
        'ENCOUNTER:SET_CARE_REPORTS_END_DATE',
        props<{
            date: Date | null;
        }>(),
    );

    /**
     * Set new entry control action
     */
    static setNewEntryControlAction = createAction(
        'ENCOUNTER:SET_NEW_ENTRY_CONTROL',
        props<{
            entryControl: EntryControl;
        }>(),
    );

    /**
     * Set persisted entry control after changes saved
     */
    static setPersistedEntryControls = createAction(
        'ENCOUNTER:SET_PERSISTED_ENTRY_CONTROLS',
        props<{
            date: Date | null;
        }>(),
    );

    /**
     * Clear new entry control action
     */
    static clearNewEntryControlAction = createAction('ENCOUNTER:CLEAR_NEW_ENTRY_CONTROL');

    /**
     * Set new care checks action
     */
    static setNewCareChecksAction = createAction(
        'ENCOUNTER:SET_NEW_CARE_CHECKS',
        props<{
            careChecksMap: CareChecksMap;
        }>(),
    );

    /**
     * Clear new care checks action
     */
    static clearNewCareChecksAction = createAction('ENCOUNTER:CLEAR_NEW_CARE_CHECKS');

    /**
     * Set new glasgow coma scales action
     */
    static setNewGlasgowComaScalesAction = createAction(
        'ENCOUNTER:SET_NEW_GLASGOW_COMA_SCALES',
        props<{
            glasgowComaScalesMap: GlasgowComaScalesMap;
        }>(),
    );

    /**
     * Clear new glasgow coma scales action
     */
    static clearNewGlasgowComaScalesAction = createAction('ENCOUNTER:CLEAR_NEW_GLASGOW_COMA_SCALES');
}
