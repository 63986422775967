import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsModule } from '@mona/shared/utils';
import { UiLogoModule } from '../../logo';
import { UiSpacerModule } from '../../utils';
import { DrawerNavbarComponent } from './drawer-navbar.component';

/**
 * DrawerNavBarModulee
 */
@NgModule({
    declarations: [DrawerNavbarComponent],
    imports: [
        UtilsModule,
        MatBadgeModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatTooltipModule,
        UiLogoModule,
        UiSpacerModule,
    ],
    exports: [DrawerNavbarComponent],
})
export class DrawerNavBarModule {}
