import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { beforeMethod } from 'kaop-ts';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { WithLogger } from '@mona/shared/logger';
import { AppError, isFunction, noop, RootInjector } from '@mona/shared/utils';
import { ConfirmDialogComponent, ErrorDetailsDialogComponent } from '../components';
import { DEFAULT_DIALOG_CONFIG, DEFAULT_DISCARD_DIALOG_DATA, DialogData } from '../models';

type MethodsList = FunctionPropertyNames<DialogService>;

/**
 * Shows the confirm discard changes dialog
 */
@Injectable({
    providedIn: 'root',
})
@WithLogger<typeof DialogService>({
    scope: 'UI',
    loggedMethodsNames: [
        'closeAll',
        'showConfirmDialog',
        'showDiscardChangesDialog',
        'showErrorDialog',
        'open',
    ] as MethodsList[],
})
export class DialogService {
    /** Reference to a dialog opened via the MatDialog service. */
    private _dialogRef: MatDialogRef<any>;
    /** Keeps track of the currently-open dialogs. */
    get openDialogs(): MatDialogRef<any>[] {
        return this.dialog.openDialogs;
    }
    /** Reference to a dialog opened via the MatDialog service. */
    get dialogRef(): MatDialogRef<any> {
        return this._dialogRef;
    }
    set dialogRef(value: MatDialogRef<any>) {
        this._dialogRef = value;
    }
    private dialogCount = 0;
    private dialogRefsSet = new WeakSet();
    /**
     * Constructor
     *
     * @param document Document
     * @param dialog MatDialog
     */
    constructor(@Inject(DOCUMENT) private document: Document, private dialog: MatDialog) {}

    /**
     * Closes all of the currently-open dialogs.
     * Emit body click event to close the overlay
     */
    closeAll() {
        this.dialog.closeAll();

        // internal `OverlayOutsideClickDispatcher` listens click events to close the overlay
        // https://github.com/angular/components/blob/f384c24b94/src/cdk/overlay/dispatchers/overlay-outside-click-dispatcher.ts#L85
        this.document.body.click();
    }
    /**
     * Finds an open dialog by its id.
     *
     * @param id ID to use when looking up the dialog.
     */
    getDialogById(id: string) {
        return this.dialog.getDialogById(id);
    }

    /**
     * Show confirm dialog
     *
     * @param data
     * @param options
     */
    showConfirmDialog(data?: Partial<DialogData>, options?: Partial<MatDialogConfig>): Observable<boolean> {
        const config: Partial<MatDialogConfig> = {
            data: {
                imgSrc: 'assets/images/question.svg',
                ...data,
            },
            ...DEFAULT_DIALOG_CONFIG,
            ...options,
        };
        return this.open(ConfirmDialogComponent, null, config).pipe(map(Boolean));
    }

    /**
     * Shows the discard changes dialog
     *
     * @param data
     */
    showDiscardChangesDialog(data?: Partial<DialogData>): Observable<boolean> {
        const config: Partial<MatDialogConfig> = {
            data: {
                ...DEFAULT_DISCARD_DIALOG_DATA,
                imgSrc: 'assets/images/discard.svg',
                ...data,
            },
            ...DEFAULT_DIALOG_CONFIG,
            panelClass: ['mona-dialog', 'mona-dialog--discard'],
        };
        return this.open(ConfirmDialogComponent, null, config).pipe(map(Boolean));
    }
    /**
     * Shows the error dialog
     *
     * @param error
     * @param isCustomErrorMessage
     * @param hasContactInformation
     */
    showErrorDialog(
        error?: Partial<AppError>,
        isCustomErrorMessage = false,
        hasContactInformation = false,
    ): Observable<boolean> {
        const config: Partial<MatDialogConfig> = {
            data: {
                error,
                isCustomErrorMessage,
                hasContactInformation,
            },
            ...DEFAULT_DIALOG_CONFIG,
            disableClose: false,
            panelClass: ['mona-dialog', 'mona-dialog--error'],
        };
        return this.open(ErrorDetailsDialogComponent, null, config).pipe(map(Boolean));
    }

    /**
     * Shows custom dialog
     *
     * @param componentOrTemplateRef
     * @param data
     * @param options
     */
    open<T = any, R = any>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        data?: Record<string, any>,
        options?: Partial<MatDialogConfig>,
    ): Observable<R> {
        const name = isFunction(componentOrTemplateRef)
            ? componentOrTemplateRef['prototype'].constructor.name
            : 'TemplateRef-' + this.dialogCount++;
        const config: Partial<MatDialogConfig> = {
            id: `dialog-${name.toLowerCase()}`,
            panelClass: ['mona-dialog', 'mona-dialog--custom'],
            data,
            ...DEFAULT_DIALOG_CONFIG,
            ...options,
        };
        const dialogRef = this.dialog.open(componentOrTemplateRef, config);
        dialogRef.afterOpened().subscribe(() => {
            // this.logger.log('DialogService::open', name);
            this.dialogRefsSet.add(dialogRef);
            this.dialogRef = dialogRef;
        });
        dialogRef.afterClosed().subscribe(noop, noop, () => {
            // this.logger.log('DialogService::close', name);
            this.dialogRef = null;
            this.dialogRefsSet.delete(dialogRef);
        });

        return dialogRef.afterClosed();
    }
}

/**
 * ShowConfirmDialog decorator
 *
 * @param data
 * @param options
 */
export const ShowConfirmDialog = <T = any>(data?: Partial<DialogData>, options?: Partial<MatDialogConfig<T>>) => {
    return beforeMethod(function (meta) {
        const service: DialogService = RootInjector.get(DialogService);

        if (!service) {
            return;
        }

        service.showConfirmDialog(data, options).subscribe(res => meta.commit(res));
    });
};

/**
 * OpenDialog decorator
 *
 * @param componentOrTemplateRef
 * @param data
 * @param options
 */
export const OpenDialog = <T = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    data?: Record<string, any>,
    options?: Partial<MatDialogConfig<T>>,
) => {
    return beforeMethod(function (meta) {
        const service: DialogService = RootInjector.get(DialogService);

        if (!service) {
            return;
        }

        service.open(componentOrTemplateRef, data, options).subscribe(res => meta.commit(res));
    });
};
