import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { RouteWithData } from '@environment';
import { NavigateClearOutletsGuard } from '@mona/shared/utils';
import { AuthHostComponent } from './auth.component';

const routes: RouteWithData[] = [
    {
        path: 'auth',
        children: [
            {
                path: 'error',
                pathMatch: 'full',
                data: {
                    layoutConfig: {
                        hasNavbar: true,
                        hasAppbar: false,
                        hasSidenav: false,
                        title: '',
                        subtitle: '',
                    },
                },
                loadChildren: () => import('./components/error-page').then(m => m.ErrorPageModule),
            },
        ],
    },
    {
        path: 'auth',
        children: [
            {
                path: 'lock-screen',
                pathMatch: 'full',
                data: {
                    layoutConfig: {
                        hasNavbar: false,
                        hasAppbar: false,
                        hasSidenav: false,
                        hasSidenavBar: false,
                    },
                },
                loadChildren: () => import('./components/lock-screen').then(m => m.LockScreenModule),
                canActivate: [NavigateClearOutletsGuard], // FIXME: because og this guard we're loosing window.history.state
            },
        ],
    },
    {
        component: AuthHostComponent,
        path: 'auth',
        outlet: 'dialog',
        children: [
            {
                path: 'sign-in',
                loadChildren: () => import('./components/sign-in').then(m => m.SignInModule),
            },
        ],
    },
    {
        component: AuthHostComponent,
        path: 'auth',
        outlet: 'overlay',
        children: [
            {
                path: 'lock-screen',
                data: {
                    layoutConfig: {
                        hasNavbar: false,
                        hasAppbar: false,
                        hasSidenav: false,
                        hasSidenavBar: false,
                    },
                },
                loadChildren: () => import('./components/lock-screen').then(m => m.LockScreenModule),
                canActivate: [],
            },
        ],
    },
];

/**
 * AuthRoutingModule
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
