// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { VentilationComponent } from './ventilationComponent';

/**
 * Serializes Ventilation Parameters from database.
 * @see #/components/schemas/TerminologyVentilationParameter - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface TerminologyVentilationParameter {
    /**
     * The code of the ventilation parameter
     *
     */
    code: string;
    /**
     * The display name of the ventilation parameter
     *
     */
    display_name: string;
    /**
     * The type of the ventilation parameter  * `integer` - Ganzzahl * `decimal` - Decimal * `group` - Group
     *
     */
    type: TerminologyVentilationParameter.TypeEnum;
    /**
     * The unit of the ventilation parameter
     *
     */
    unit: string;
    /**
     * The visibility of the ventilation parameter
     *
     */
    is_visible?: boolean;
    readonly components: Array<VentilationComponent>;
    /**
     * Whether ventilation parameter is automated.
     *
     */
    readonly is_automated: boolean;
    /**
     * Whether ventilation parameter should always be displayed.
     *
     */
    is_always_visible?: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for TerminologyVentilationParameter
 */
export namespace TerminologyVentilationParameter {
    export type TypeEnum = 'integer' | 'decimal' | 'group';
    export const TypeEnum = {
        Integer: 'integer' as TypeEnum,
        Decimal: 'decimal' as TypeEnum,
        Group: 'group' as TypeEnum,
    };

    /**
     * TerminologyVentilationParameter Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The code of the ventilation parameter
         * @type string (`dataFormat` is missing)
         */
        code: string;
        /**
         * The display name of the ventilation parameter
         * @type string (`dataFormat` is missing)
         */
        displayName: string;
        /**
         * The type of the ventilation parameter  * `integer` - Ganzzahl * `decimal` - Decimal * `group` - Group
         * @type string (`dataFormat` is missing)
         */
        type: TerminologyVentilationParameter.TypeEnum;
        /**
         * The unit of the ventilation parameter
         * @type string (`dataFormat` is missing)
         */
        unit: string;
        /**
         * The visibility of the ventilation parameter
         * @type boolean (`dataFormat` is missing)
         */
        isVisible?: boolean;
        @Type(() => VentilationComponent.Model) readonly components: Array<VentilationComponent.Model>;
        /**
         * Whether ventilation parameter is automated.
         * @type boolean (`dataFormat` is missing)
         */
        readonly isAutomated: boolean;
        /**
         * Whether ventilation parameter should always be displayed.
         * @type boolean (`dataFormat` is missing)
         */
        isAlwaysVisible?: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * TerminologyVentilationParameter DTO
     * Transforms TerminologyVentilationParameter model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend TerminologyVentilationParameter model */
        static toModel(obj: TerminologyVentilationParameter): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend TerminologyVentilationParameter model */
        static toApi(obj: Model): TerminologyVentilationParameter {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as TerminologyVentilationParameter;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
