<div class="ui-dynamic-input-wrapper">
    <mat-form-field
        [id]="name"
        class="ui-dynamic-input-field"
        [ngClass]="customClass"
        [appearance]="appearance"
        [hideRequiredMarker]="hideRequiredMarker"
        [floatLabel]="floatLabel"
    >
        <mat-label *ngIf="label">{{ label }}</mat-label>
        <ui-autocomplete
            #uiAutocomplete
            [formControl]="control"
            [placeholder]="placeholder || label"
            [required]="required"
            [attr.name]="name"
            [attr.data-testid]="name"
            panelClass="ui-dynamic-select-panel scrollbar-visible {{ panelClass }}"
            [name]="name"
            [dataSource]="dataSource"
            [lengthToTriggerSearch]="lengthToTriggerSearch"
            [labelKey]="labelKey"
            [valueKey]="valueKey"
            [showProgress]="true"
            [hideResetBtn]="hideResetBtn"
            [allowNonExisting]="allowNonExisting"
            [clearAfterSearch]="clearAfterSearch"
            [clearWithConfirmation]="clearWithConfirmation"
            [displayTemplate]="labelTemplate | uiTemplateRef"
            [addNewTemplate]="addNewTemplate | uiTemplateRef"
            (autocompleteOptionSelected)="autocompleteOptionSelected($event, form)"
            (autocompleteOptionAddNew)="autocompleteOptionAddNewHandler($event, form)"
        ></ui-autocomplete>
        <mat-hint *ngIf="hint || hintTemplate">
            <ng-container
                [ngTemplateOutlet]="(hintTemplate | uiTemplateRef) || defaultHintTemplate"
                [ngTemplateOutletContext]="{ $implicit: selectedOption || form.getRawValue() }"
            ></ng-container>
            <ng-template #defaultHintTemplate>{{ hint }}</ng-template>
        </mat-hint>
        <mat-error>
            <ng-template
                [ngTemplateOutlet]="errorMessageTemplate | uiTemplateRef"
                [ngTemplateOutletContext]="{ control: control, errors: control?.errors }"
            ></ng-template>
            <span [hidden]="hideValidationMessage">
                <ui-validation-message [control]="control"></ui-validation-message>
            </span>
        </mat-error>
    </mat-form-field>
</div>
