import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { startOfToday, isAfter } from 'date-fns';
import { Observable, combineLatest, interval, of } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    shareReplay,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { currentUser$ } from '@mona/auth';
import {
    FilteredTaskList,
    MedicationAdministration,
    MonaTask,
    Procedure,
    TaskListFilter,
    TaskType,
} from '@mona/models';
import { ChangeLogService, applyInstancesChanges } from '@mona/pdms/data-access-changelog';
import { getEncounterStartDate, getEncounterViewSelectedDate } from '@mona/pdms/data-access-combined';
import { BalanceService, EncounterService } from '@mona/pdms/data-access-encounter-data';
import { DataAccessMedicationsFacade } from '@mona/pdms/data-access-medications';
import { PractitionerShiftsService } from '@mona/pdms/data-access-practitioners';
import { DataAccessProceduresFacade } from '@mona/pdms/data-access-procedures';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { compareDeepEqual, notEmpty } from '@mona/shared/utils';
import { TaskListState } from '../entities';
import {
    applyPrescriptionEntitiesToTasks,
    applyTasksChanges,
    extendPractitionerShiftsWithDateFields,
    extendTaskListFiltersWithStartEndFields,
    filterTaskList,
} from '../infrastructure';
import { TaskListActions, TaskListSelectors } from '../state';

/**
 * Task List store facade
 */
@Injectable({ providedIn: 'root' })
export class DataAccessTaskListFacade {
    /** recalculate interval for what tasks are already overdue - every 1min */
    private readonly recalculationInterval = 1 * 60 * 1000;

    /** task list filter */
    readonly taskListFilter$ = this.store.select(TaskListSelectors.selectFilter);

    /** overdue time */
    readonly overdueTime$ = this.store.select(TaskListSelectors.selectOverdueTime);

    /** encounter start date */
    readonly encounterStartDate$ = getEncounterStartDate();

    /** Selected date */
    readonly activeDate$: Observable<Date> = getEncounterViewSelectedDate();

    /** medication categories map */
    medicationCategoriesMap$ = this.medicationsFacade.medicationCategoriesMap$;

    /** practitioner shifts */
    readonly practitionerShifts$ = this.terminologyService.getPractitionerShifts().pipe(
        distinctUntilChanged((prev, curr) => compareDeepEqual(prev, curr)),
        map(shifts => this.practitionerShiftsService.generateShiftsForDay(shifts, startOfToday(), null, new Date())),
    );

    /** task list filters */
    readonly taskListFilters$: Observable<TaskListFilter[]> = combineLatest([
        this.practitionerShifts$.pipe(map(extendPractitionerShiftsWithDateFields)),
        this.terminologyService.getTaskListShiftFilters().pipe(
            distinctUntilChanged((prev, curr) => compareDeepEqual(prev, curr)),
            withLatestFrom(this.overdueTime$),
            map(([filters, overdueTime]) => extendTaskListFiltersWithStartEndFields(filters, overdueTime)),
        ),
    ]).pipe(
        map(([practitionerShifts, taskListFilters]) => practitionerShifts.concat(taskListFilters)),
        tap(filters => filters[0] && this.setFilter(filters[0])),
    );

    /** on demand prescriptions */
    readonly onDemandMedicationPrescriptions$ = this.medicationsFacade.medicationPrescriptions$.pipe(
        map(this.filterPrescriptionsByOnDemandFrequency),
        map(medicationPrescriptions =>
            medicationPrescriptions.filter(
                prescription =>
                    !prescription.isStopped &&
                    (!prescription.endDate || isAfter(prescription.endDate, new Date())) &&
                    isAfter(new Date(), prescription.startDate),
            ),
        ),
    );

    readonly medicationAdministrationChanges$ = this.changeLogService.getModelChanges('MedicationAdministration');

    readonly completedOnDemandMedications$ = this.store.select(
        TaskListSelectors.selectCompletedOnDemandMedPrescriptionIds,
    );

    /** is task list data loading */
    readonly isLoading$ = this.store.select(TaskListSelectors.selectIsLoading);

    /** procedure prescriptions map */
    readonly procedurePrescriptionsMap$ = this.proceduresFacade.procedurePrescriptionsMap$;

    /** medication prescrptions map */
    readonly medicationPrescriptionsMap$ = this.medicationsFacade.medicationPrescriptionsMap$;

    /** medication administration by prescription id */
    readonly groupedByPrescriptionMedicationAdministrations$ =
        this.balanceService.getGroupedMedicationAdministrationsWithChanges();

    /** all plain tasks */
    readonly tasks$ = this.store.select(TaskListSelectors.selectTasksAll);

    /** tasks with prescription entities */
    readonly tasksWithPrescriptionEntities$: Observable<MonaTask[]> = combineLatest([
        this.store.select(TaskListSelectors.selectTasksAll),
        this.medicationsFacade.medicationPrescriptionsMap$,
        this.proceduresFacade.procedurePrescriptionsMap$,
        this.medicationsFacade.medicationCategoriesMap$,
        this.proceduresFacade.procedurePrescriptionCategoriesMap$,
    ]).pipe(
        distinctUntilChanged((a, b) => compareDeepEqual(a, b)),
        map(([tasks, medPrescriptionsMap, procPrescriptionsMap, medCategoriesMap, procCategoriesMap]) => {
            return applyPrescriptionEntitiesToTasks(
                tasks,
                medPrescriptionsMap,
                procPrescriptionsMap,
                medCategoriesMap,
                procCategoriesMap,
            );
        }),
        shareReplay(1),
    );

    /** tasks with prescription entities and changes binded to tasks */
    readonly tasksWithData$: Observable<MonaTask[]> = combineLatest([
        this.tasksWithPrescriptionEntities$,
        this.changeLogService.getModelChanges('Procedure'),
        this.changeLogService.getModelChanges('MedicationAdministration'),
    ]).pipe(map(([tasks, pCh, maCh]) => applyTasksChanges(tasks, pCh, maCh)));

    /** filtered tasks overdue/actual + overdueTime + task list filter */
    readonly filteredTasks$: Observable<FilteredTaskList> = combineLatest([
        this.tasksWithData$,
        this.overdueTime$,
        this.taskListFilter$,
    ]).pipe(map(([tasks, overdueTime, filter]) => filterTaskList(tasks, overdueTime, filter)));

    /** filtered tasks with refresh interval */
    readonly filteredTasksWithInterval$: Observable<FilteredTaskList> = interval(this.recalculationInterval).pipe(
        startWith(this.filteredTasks$),
        switchMap(() => this.filteredTasks$),
    );

    /** overdue counter */
    readonly overdueTasksCounter$: Observable<number> = this.filteredTasksWithInterval$.pipe(
        map(({ overdue }) => {
            let count = 0;

            Object.values(overdue).forEach(group => {
                count += group[TaskType.MedicationPrescriptions]?.filter(task => !task.isCompleted)?.length || 0;
                count += group[TaskType.ProcedurePrescriptions]?.filter(task => !task.isCompleted)?.length || 0;
            });

            return count;
        }),
    );

    readonly practitionerId$ = currentUser$().pipe(
        map(user => user.id),
        shareReplay(),
    );

    readonly practitioners$ = this.medicationsFacade.practitioners$;

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService
     * @param proceduresFacade
     * @param medicationsFacade
     * @param terminologyService
     * @param practitionerShiftsService
     * @param balanceService
     * @param encounterService
     */
    constructor(
        private store: Store<TaskListState>,
        private changeLogService: ChangeLogService,
        private proceduresFacade: DataAccessProceduresFacade,
        private medicationsFacade: DataAccessMedicationsFacade,
        private terminologyService: TerminologyService,
        private practitionerShiftsService: PractitionerShiftsService,
        private balanceService: BalanceService,
        private encounterService: EncounterService,
    ) {}

    /**
     * load task list data
     *
     *  @param initial - initial load
     */
    loadTaskListData(initial = false): void {
        this.loadTaskList();
        if (!initial) {
            this.medicationsFacade.loadMedicationPrescriptions();
            this.proceduresFacade.loadProcedurePrescriptions();
            this.encounterService.encounterId$
                .pipe(take(1))
                .subscribe(encounterId => this.balanceService.loadMedicationAdministrations(encounterId));
        }
    }

    /**
     * load task list
     *
     * @param debounceTime - to delay the execution becuase API don't send us newest data
     */
    async loadTaskList(debounceTime = 0): Promise<void> {
        // NOTE: API just don't have the freshest data without any delays
        await new Promise(resolve => setTimeout(resolve, debounceTime));
        this.store.dispatch(TaskListActions.loadTasks());
    }

    /** load task list with 1 sec delay */
    loadTaskListWith1SecDelay(): void {
        this.loadTaskList(1000);
    }

    /** get updated changes for medication administration */
    getUpdatedChangesForMedicationAdministration(): Observable<MedicationAdministration[]> {
        return this.changeLogService
            .getModelChanges('MedicationAdministration')
            .pipe(map(changes => applyInstancesChanges<MedicationAdministration>([], changes)));
    }

    /**
     * create medication administration change
     *
     * @param payload
     */
    createMedicationAdministrationChange(payload: MedicationAdministration): void {
        this.changeLogService.createMedicationAdministration(payload);
    }

    /**
     * create medication administration changes
     * @param payload
     */
    createNonRunningMedicationAdministrationChanges(payload: MedicationAdministration[]): void {
        this.changeLogService.createNonRunningMedicationAdministrations(payload);
    }

    /**
     * create procedure change
     *
     * @param payload
     */
    createProcedureChange(payload: Procedure): void {
        this.changeLogService.createProcedure(payload);
    }

    /**
     * create procedure changes
     * @param payload
     */
    createProcedureChanges(payload: Procedure[]): void {
        this.changeLogService.createProcedures(payload);
    }

    /**
     * discard changes
     *
     * @param ids
     */
    deleteChanges(ids: string[]): void {
        this.changeLogService.discardChanges(ids);
    }

    /**
     * set filter
     *
     * @param filter
     */
    setFilter(filter: TaskListFilter): void {
        this.store.dispatch(TaskListActions.setFilter({ filter }));
    }

    /**
     * toggle is loading
     * @param isLoading
     */
    toggleIsLoading(isLoading: boolean): void {
        this.store.dispatch(TaskListActions.toggleIsLoading({ isLoading }));
    }

    /**
     * clear task list state
     */
    clearTaskListState(): void {
        this.store.dispatch(TaskListActions.clearTaskListState());
    }

    /**
     * set overdue time
     * @param overdueTime
     */
    setOverdueTime(overdueTime: number) {
        this.store.dispatch(TaskListActions.setOverdueTime({ overdueTime }));
    }

    /**
     * filter prescriptions by on demand frequency
     * @param prescriptions
     */
    private filterPrescriptionsByOnDemandFrequency<T extends { frequency: string }>(prescriptions: T[]): T[] {
        return prescriptions.filter(p => p.frequency === environment.noRequiredFrequencyCode);
    }
}
