import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TherapyLimitationsType } from '@mona/models';
import { TherapyLimitationsState } from '../../entities';
import { TherapyLimitationsActions } from '../actions';

export const therapyLimitationsAdapter: EntityAdapter<TherapyLimitationsType> =
    createEntityAdapter<TherapyLimitationsType>();

export const initialState: TherapyLimitationsState = therapyLimitationsAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const therapyLimitationsReducer = createReducer(
    initialState,
    on(TherapyLimitationsActions.loadTherapyLimitations, state => ({ ...state, isLoading: true })),
    on(TherapyLimitationsActions.loadTherapyLimitationsSuccess, (state, { data }) =>
        therapyLimitationsAdapter.setAll(data, {
            ...state,
            error: null,
            isLoading: false,
        }),
    ),
    on(TherapyLimitationsActions.loadTherapyLimitationsFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(TherapyLimitationsActions.updateTherapyLimitations, (state, action) =>
        therapyLimitationsAdapter.updateMany(action.update, state),
    ),
    on(TherapyLimitationsActions.upsertTherapyLimitations, (state, action) =>
        therapyLimitationsAdapter.upsertMany(action.data, state),
    ),
    on(TherapyLimitationsActions.clearTherapyLimitations, state => therapyLimitationsAdapter.removeAll(state)),
    on(TherapyLimitationsActions.addTherapyLimitationsChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = therapyLimitationsAdapter.removeMany(toRemoveIds, state);

        return therapyLimitationsAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
