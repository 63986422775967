import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { PrescriptionSet, BaseProcedurePrescription } from '@mona/models';
import { transformApiPrescriptionSetProcedures, transformToApiPrescriptionSetProcedure } from './transforms';

/**
 * API abstraction layer for the prescription set procedures API
 */
@Injectable({
    providedIn: 'root',
})
export class PrescriptionSetProceduresApi {
    apiBase = '/pdms/prescription-set-procedures/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get prescription set procedures
     *
     * @param setId EntityId<PrescriptionSet>
     */
    getPrescriptionSetProcedures(setId: EntityId<PrescriptionSet>): Observable<BaseProcedurePrescription[]> {
        return this.http
            .get<ApiModels.PrescriptionSetProcedure[]>(this.apiBase, {
                params: {
                    prescription_set_id: setId as string,
                },
            })
            .pipe(map(apiProcedures => transformApiPrescriptionSetProcedures(apiProcedures)));
    }

    /**
     * Create prescription set procedure
     *
     * @param setId EntityId<PrescriptionSet>
     * @param setProcedure Partial<PrescriptionSetProcedure>
     */
    createPrescriptionSetProcedure(
        setId: EntityId<PrescriptionSet>,
        setProcedure: BaseProcedurePrescription,
    ): Observable<void> {
        const { id, ...payload } = transformToApiPrescriptionSetProcedure(setProcedure, setId);
        return this.http.post<void>(this.apiBase, payload);
    }

    /**
     * Update prescription set procedure
     *
     * @param id string
     * @param setId string
     * @param setProcedure Partial<PrescriptionSetProcedure>
     */
    updatePrescriptionSetProcedure(
        id: string,
        setId: string,
        setProcedure: BaseProcedurePrescription,
    ): Observable<any /* void */> {
        return this.http.put<void>(
            `${this.apiBase}${id}/`,
            transformToApiPrescriptionSetProcedure(setProcedure, setId),
        );
    }

    /**
     * Delete prescription set procedure
     *
     * @param id string
     */
    deletePrescriptionSetProcedure(id: string): Observable<void> {
        return this.http.delete<void>(`${this.apiBase}${id}/`);
    }
}
