import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { Encounter, PreMedication } from '@mona/models';
import { transformApiPreMedications } from './transforms';

/**
 * API abstraction layer for the pre medications API
 */
@Injectable({
    providedIn: 'root',
})
export class PreMedicationsApi {
    apiBase = '/pdms/pre-medications/';

    /**
     * Constructor
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get pre medications
     * @param encounterId
     */
    getPreMedications(encounterId?: EntityId<Encounter>): Observable<PreMedication[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (encounterId) {
            params.encounter_id = `${encounterId}`;
        }

        return this.http
            .get<ApiModels.Premedication[]>(this.apiBase, {
                params,
            })
            .pipe(map(preMedications => transformApiPreMedications(preMedications)));
    }
}
