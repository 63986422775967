<form (ngSubmit)="onSubmit($event)" *ngIf="formGroup" [formGroup]="formGroup" [isEnabled]="isBedSideMode && isActive">
    <!-- Existing items -->
    <div *ngFor="let form of formGroup?.controls.items.controls; let i = index" class="diagnosis-row">
        <ng-container *ngIf="form.controls as diagnosis">
            <mat-form-field class="app-input-field" data-testid="ff-diagnosisStatus">
                <mat-label>{{ 'apps.admission.diagnoses.items.status' | translate }}</mat-label>
                <mat-select
                    [formControl]="diagnosis.status"
                    panelClass="app-input-select-panel scrollbar-visible"
                    data-testid="ff-diagnosisStatusSelect"
                >
                    <mat-option
                        *ngFor="let state of verificationStates"
                        [value]="state.enumMember"
                        [attr.data-testid]="'uiDiagnosisStatusSelect_' + state.enumMember"
                    >
                        {{ state.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="app-input-field" data-testid="ff-diagnosisText">
                <mat-label>
                    {{
                        diagnosis.icd10Code.value
                            ? (diagnosesFhirConfig$ | async)?.displayNameSingular + ': ' + diagnosis.icd10Code.value
                            : ('apps.admission.diagnoses.items.label' | translate)
                    }}
                </mat-label>
                <input [formControl]="diagnosis.text" matInput required data-testid="ff-diagnosisTextInput" />

                <button
                    *ngIf="!isReadOnly"
                    (click)="removeDiagnosis(i, $event)"
                    mat-icon-button
                    matSuffix
                    type="button"
                    data-testid="btn-remove"
                >
                    <mat-icon>close</mat-icon>
                </button>
                <mat-error>
                    <ui-validation-message [control]="diagnosis.text"></ui-validation-message>
                </mat-error>
            </mat-form-field>
        </ng-container>
    </div>

    <!-- New Item -->
    <ng-container *ngIf="!isReadOnly">
        <div *ngIf="newItem.controls as controls" class="diagnosis-row">
            <mat-form-field class="app-input-field" data-testid="ff-status">
                <mat-label>{{ 'apps.admission.diagnoses.newItem.status' | translate }}</mat-label>
                <mat-select
                    #newEntryStatus
                    [formControl]="controls.status"
                    panelClass="app-input-select-panel scrollbar-visible"
                    data-testid="ff-statusSelect"
                >
                    <mat-option
                        *ngFor="let state of verificationStates"
                        [value]="state.enumMember"
                        [attr.data-testid]="'ff-statusSelect_' + state.enumMember"
                    >
                        {{ state.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="app-input-field" data-testid="ff-controlsText">
                <mat-label>{{ 'apps.admission.diagnoses.newItem.label' | translate }}</mat-label>
                <input
                    #newItemInput
                    (blur)="onNewItemNativeBlur()"
                    (keydown.enter)="addDiagnosis()"
                    [required]="formGroup.controls.items.invalid"
                    [formControl]="controls.text"
                    [matAutocomplete]="auto"
                    matAutocompletePosition="auto"
                    matInput
                    data-testid="ff-textInput"
                />

                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="onNewItemAutoCompleteSelect($event)"
                    [displayWith]="autoCompleteDisplayFn"
                    class="app-input-select-panel scrollbar-visible"
                    data-testid="ff-foundDiagnosesAutocomplete"
                >
                    <mat-option
                        *ngFor="let option of foundDiagnoses$ | async"
                        [value]="option"
                        [attr.data-testid]="'ff-foundDiagnosesAutocomplete_' + option"
                    >
                        <div class="autocomplete-option">
                            <div>{{ option.displayName }}</div>
                            <small class="text-muted">{{ option.code }}</small>
                        </div>
                    </mat-option>
                </mat-autocomplete>

                <mat-hint>{{ 'apps.admission.diagnoses.newItem.hint' | translate }}</mat-hint>

                <span matSuffix>
                    <mat-spinner
                        *ngIf="searchDiagnosisInProgress$ | async; else addButton"
                        [diameter]="24"
                    ></mat-spinner>
                    <ng-template #addButton>
                        <button
                            *ngIf="controls.text.value"
                            (click)="onAddClicked()"
                            mat-icon-button
                            type="button"
                            data-testid="btn-on-add"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-template>
                </span>

                <mat-error>
                    <ui-validation-message [control]="controls.text"></ui-validation-message>
                </mat-error>
            </mat-form-field>
        </div>
    </ng-container>

    <ng-content></ng-content>
</form>
