<mat-dialog-content class="app-overview__edit-dialog__content">
    <div>
        <h2 mat-dialog-title class="app-overview__edit-dialog__title">
            {{ 'apps.patient.overview.personalData' | translate }}
        </h2>

        <app-personal-data-form
            [formGroup]="data.personalDataForm"
            [isCardAppearance]="false"
            [staysList]="data.staysList"
            [isCaseChanged]="data.isCaseChanged"
            [stagedChanges]="data.stageChanges"
            (keyboardEnterClicked)="submitChange()"
        ></app-personal-data-form>
    </div>

    <div class="app-overview__edit-dialog__actions">
        <button mat-button color="primary" mat-dialog-close>
            {{ 'apps.patient.overview.dialogCancel' | translate }}
        </button>
        <button mat-raised-button color="primary" cdkFocusInitial (click)="submitChange()">
            {{ 'apps.patient.overview.dialogSave' | translate }}
        </button>
    </div>
</mat-dialog-content>
