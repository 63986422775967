import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, NEVER } from 'rxjs';
import { delay, switchMap, take, tap } from 'rxjs/operators';
import { UPDATER, UpdatesService } from '@mona/core';
import { UpdateStatus } from '@mona/models';
import { DialogData } from '@mona/ui';

/**
 * Confirm dialog component
 */
@Component({
    selector: 'app-update-dialog',
    templateUrl: './update-dialog.component.html',
    styleUrls: ['./update-dialog.component.scss'],
})
export class UpdateDialogComponent implements OnInit {
    /**
     * Possible states of the update client
     */
    updateStates = UpdateStatus;

    /**
     * Current status of update client
     */
    updateStatus$ = new BehaviorSubject<UpdateStatus>(UpdateStatus.SEARCHING);

    /**
     * Constructor
     *
     * @param dialogRef dialogRef
     * @param updatesService
     * @param data data
     */
    constructor(
        public dialogRef: MatDialogRef<UpdateDialogComponent>,
        @Inject(UPDATER) private updatesService: UpdatesService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        dialogRef.addPanelClass('app-update-dialog');
        dialogRef.disableClose = true;
    }

    /**
     * NG Hook
     */
    ngOnInit() {
        this.searchForUpdates();
    }

    /**
     * Searches for updates
     */
    searchForUpdates() {
        NEVER.pipe(
            tap(() => {
                this.updatesService.checkForUpdate();
                this.updateStatus$.next(UpdateStatus.SEARCHING);
            }),
            switchMap(() => this.updatesService.updateAvailable$),
            tap(result => {
                if (result) {
                    this.updateStatus$.next(UpdateStatus.DOWNLOADING);
                    this.updateWhenDownloaded();
                } else {
                    this.updateStatus$.next(UpdateStatus.NO_UPDATE);
                }
            }),
        );
    }

    /**
     * Updates the device when downloaded
     */
    updateWhenDownloaded() {
        // May use also state of heathCheck isDevice update ready
        // But prefer to be independent to make possible use out of app shell in future if needed
        this.updatesService.updateReady$
            // Delay is needed as we check for a file exists in background, should not cause to many IO operations
            // Call happens every 1000ms until device is ready to reboot
            .pipe(delay(1000), take(1))
            .subscribe(ready => {
                if (!ready) {
                    this.updateWhenDownloaded();
                } else {
                    this.updateStatus$.next(UpdateStatus.READY_TO_INSTALL);
                    this.updatesService.activateUpdate();
                }
            });
    }
}
