import { InjectionToken } from '@angular/core';
import { Action, ActionReducerMap, createReducer, on } from '@ngrx/store';
import { ChangeLogEntry, ChangeLogModel } from '@mona/models';
import { makeAsyncActionReducer, SucceededAction } from '@mona/store';
import { ChangeLog, ChangeLogMap, ChangeLogState } from '../../entities';
import { ChangeLogAction } from '../actions/change-log.action';

/**
 * The initial state
 */
export const initialChangeLogState: ChangeLog = {
    changesMap: null,
    sortedChanges: null,
};

/**
 * Groups changes by model
 *
 * @param changes ChangeLogEntry[]
 */
export const groupChanges = (changes: ChangeLogEntry<ChangeLogModel>[]): ChangeLogMap => {
    return changes.reduce((changesMap, change) => {
        const modelChanges = changesMap[change.model];

        if (modelChanges) {
            modelChanges.push(change);
        } else {
            changesMap[change.model] = [change];
        }

        return changesMap;
    }, {} as ChangeLogMap);
};

/**
 * Sorts changes by created at - ASC
 *
 * @param changes ChangeLogEntry<ChangeLogModel>[]
 */
export const sortChanges = (changes: ChangeLogEntry<ChangeLogModel>[]): ChangeLogEntry<ChangeLogModel>[] => {
    const sortedChanges = [...changes];
    sortedChanges.sort((prev, next) => {
        return new Date(prev.createdAt).getTime() - new Date(next.createdAt).getTime();
    });

    return sortedChanges;
};

/**
 * Reducer for the load changes succeeded action
 *
 * @param state ChangeLog
 * @param action SucceededAction<ChangeLogEntry[]>
 */
export const reduceLoadChangesSucceeded = (
    state: ChangeLog,
    action: SucceededAction<ChangeLogEntry<ChangeLogModel>[]>,
): ChangeLog => {
    // returns empty map if no changes
    if (!action.payload?.length) {
        return {
            changesMap: {},
            sortedChanges: [],
        };
    }

    // sort changes
    const sortedChanges = sortChanges(action.payload);
    // convert to object map by grouping by model
    return {
        sortedChanges,
        changesMap: groupChanges(sortedChanges),
    };
};

/**
 * Reducer for the save change succeeded action
 *
 * @param state ChangeLog
 * @param action SucceededAction<ChangeLogEntry>
 */
export const reduceSaveChangeSucceeded = (
    state: ChangeLog,
    action: SucceededAction<ChangeLogEntry<ChangeLogModel>>,
): ChangeLog => {
    const currentModelChanges = state.changesMap && state.changesMap[action.payload.model];
    const updatedMap = state.changesMap
        ? {
              ...state.changesMap,
          }
        : {};

    if (currentModelChanges) {
        updatedMap[action.payload.model] = [...currentModelChanges, action.payload];
    } else {
        updatedMap[action.payload.model] = [action.payload];
    }

    return {
        sortedChanges: [...(state.sortedChanges || []), action.payload],
        changesMap: updatedMap,
    };
};

/**
 * Reducer for the save changes succeeded action
 *
 * @param state ChangeLog
 * @param action SucceededAction<ChangeLogEntry[]>
 */
export const reduceSaveChangesSucceeded = (
    state: ChangeLog,
    action: SucceededAction<ChangeLogEntry<ChangeLogModel>[]>,
): ChangeLog => {
    const updatedMap = state.changesMap
        ? {
              ...state.changesMap,
          }
        : {};

    const sortedChanges = sortChanges(action.payload);

    for (const change of sortedChanges) {
        const currentModelChanges = updatedMap[change.model];

        if (currentModelChanges) {
            updatedMap[change.model] = [...currentModelChanges, change];
        } else {
            updatedMap[change.model] = [change];
        }
    }

    return {
        sortedChanges: [...(state.sortedChanges || []), ...sortedChanges],
        changesMap: updatedMap,
    };
};

/**
 * Reducer for the persist changes succeeded action
 *
 * @param state ChangeLog
 * @param action SucceededAction<ChangeLogEntry[]>
 */
export const reducePersistChangesSucceeded = (state: ChangeLog, action: { ids: string[] }): ChangeLog => {
    const updatedChanges = [...state.sortedChanges].filter(change => !action.ids.includes(change.id));

    return {
        sortedChanges: updatedChanges,
        changesMap: groupChanges(updatedChanges),
    };
};

/**
 * Reducer for the discard changes succeeded action
 */
export const reduceDiscardChangesSucceeded = (): ChangeLog => {
    return initialChangeLogState;
};

/**
 * Reducer injection token definition
 */
export const CHANGELOG_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ChangeLogState, Action>>(
    'ChangelogReducerToken',
);

/**
 * Change log reducer
 */
export const changeLogReducer = createReducer(
    initialChangeLogState,

    on(ChangeLogAction.loadChangesAction.succeededAction, reduceLoadChangesSucceeded),

    on(ChangeLogAction.saveChangeAction.succeededAction, reduceSaveChangeSucceeded),

    on(ChangeLogAction.saveChangesAction.succeededAction, reduceSaveChangesSucceeded),

    on(ChangeLogAction.clearPersistedSucceededModels, reducePersistChangesSucceeded),

    on(ChangeLogAction.discardChangesAction.succeededAction, reduceDiscardChangesSucceeded),
);

/**
 * Change log reducers
 */
export const changeLogReducerMap: ActionReducerMap<ChangeLogState> = {
    changeLog: changeLogReducer,
    loadChangesAction: makeAsyncActionReducer(ChangeLogAction.loadChangesAction),
    saveChangeAction: makeAsyncActionReducer(ChangeLogAction.saveChangeAction),
    saveChangesAction: makeAsyncActionReducer(ChangeLogAction.saveChangesAction) as any /* FIXME: */,
    persistChangesAction: makeAsyncActionReducer(ChangeLogAction.persistChangesAction),
    discardChangesAction: makeAsyncActionReducer(ChangeLogAction.discardChangesAction),
};
