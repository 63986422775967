import { Injector, Type, InjectionToken, InjectFlags } from '@angular/core';
import { ReplaySubject } from 'rxjs';

/**
 * Root Injector solution
 *
 * @example ```ts
 * platformBrowserDynamic.bootstrapModule(AppModule).then((ngModuleRef) => {
 *  RootInjector.setInjector(ngModuleRef.injector);
 * });
 * ```
 */
export class RootInjector {
    private static rootInjector: Injector;
    private static readonly $injectorReady = new ReplaySubject();
    static readonly injectorReady$ = RootInjector.$injectorReady.asObservable();

    /**
     * Sets root injector instance
     *
     * @param injector
     */
    static setInjector(injector: Injector) {
        if (this.rootInjector) {
            return;
        }

        this.rootInjector = injector;
        this.$injectorReady.next(true);
    }

    /**
     * Retrieves an instance from the injector based on the provided token.
     *
     * @param token
     * @param notFoundValue
     * @param flags
     */
    static get<T>(token: Type<T> | InjectionToken<T>, notFoundValue?: T, flags?: InjectFlags): T {
        try {
            return this.rootInjector.get(token, notFoundValue, flags);
        } catch (e) {
            console.error(
                `Error getting ${token} from RootInjector. This is likely due to RootInjector is undefined. Please check RootInjector.rootInjector value.`,
            );
            return null;
        }
    }
}
