export const WEB_BROWSER_DEFAULT_POSITION_PARAMS = {
    x: 478,
    y: 145,
    width: 1378,
    height: 899,
};

export const WEB_BROWSER_FULLSCREEN_POSITION_PARAMS = {
    x: 98,
    y: 41,
    width: 1822,
    height: 1040,
};
