import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SurgeryPrescriptionType } from '@mona/models';
import { SurgeryPrescriptionState } from '../../entities';
import { SurgeryPrescriptionAction } from '../actions';

export const surgeryPrescriptionAdapter: EntityAdapter<SurgeryPrescriptionType> =
    createEntityAdapter<SurgeryPrescriptionType>();

export const initialState: SurgeryPrescriptionState = surgeryPrescriptionAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const surgeryPrescriptionReducer = createReducer(
    initialState,
    on(SurgeryPrescriptionAction.loadSurgeryPrescription, state => ({ ...state, isLoading: true })),
    on(SurgeryPrescriptionAction.loadSurgeryPrescriptionSuccess, (state, { data }) =>
        surgeryPrescriptionAdapter.setAll(data, {
            ...state,
            error: null,
            isLoading: false,
        }),
    ),
    on(SurgeryPrescriptionAction.loadSurgeryPrescriptionFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(SurgeryPrescriptionAction.updateSurgeryPrescription, (state, action) =>
        surgeryPrescriptionAdapter.updateMany(action.update, state),
    ),
    on(SurgeryPrescriptionAction.upsertSurgeryPrescription, (state, action) =>
        surgeryPrescriptionAdapter.upsertMany(action.data, state),
    ),
    on(SurgeryPrescriptionAction.clearSurgeryPrescription, state => surgeryPrescriptionAdapter.removeAll(state)),
    on(SurgeryPrescriptionAction.addSurgeryPrescriptionChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = surgeryPrescriptionAdapter.removeMany(toRemoveIds, state);

        return surgeryPrescriptionAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
