import { ApiModels } from '@mona/api';
import { MedicationDosageForm } from '@mona/models';

/**
 * Transforms api discharge
 *
 * @param apiDosageForm
 */
export const transformApiDosageForm = (apiDosageForm: ApiModels.DosageForm): MedicationDosageForm => {
    const model = ApiModels.DosageForm.DTO.toModel(apiDosageForm);
    return { ...model, id: model.code };
};

/**
 * Transform api reports
 *
 * @param apiDosageForms any
 */
export const transformApiDosageForms = (apiDosageForms: any[]): MedicationDosageForm[] => {
    return apiDosageForms.map(apiDosageForm => transformApiDosageForm(apiDosageForm));
};
