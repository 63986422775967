<div class="w-questionnaire-container" [formGroup]="form" uiDisableWhen checkPermission="workflows_edit">
    <mat-table
        class="scrollbar-visible workflow-questionnaire-scroller"
        uiKeyboardSpacing
        [dataSource]="questions"
        [trackBy]="trackByQuestionId"
        id="workflow-questionnaire-scroller"
    >
        <!-- Check column template -->
        <ng-container matColumnDef="question">
            <mat-header-cell
                class="question-col"
                *matHeaderCellDef
                [style.max-width.%]="displayedColumns === 3 ? 30 : 50"
            >
                {{ 'apps.patient.workflows.details.check' | translate }}
            </mat-header-cell>

            <mat-cell class="question-col" *matCellDef="let element">
                <div *ngIf="element.title">
                    <b>{{ element.title }}:</b>
                </div>

                <div>{{ element.description }}</div>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="score-col">
                {{ 'apps.patient.workflows.details.score' | translate }}
            </mat-footer-cell>
        </ng-container>

        <!-- Current column template -->
        <ng-container matColumnDef="currentValue">
            <mat-header-cell class="current-col" *matHeaderCellDef>
                {{ 'apps.patient.workflows.details.current' | translate }}
            </mat-header-cell>

            <mat-cell class="current-col" *matCellDef="let element">
                {{ element.currentValue }}
            </mat-cell>

            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!-- Condition column template -->
        <ng-container matColumnDef="answer">
            <mat-header-cell class="answer-col" *matHeaderCellDef>
                {{ 'apps.patient.workflows.details.condition' | translate }}

                <div class="suggestion-label" *ngIf="questionnaireMode !== 'view'">
                    <div class="suggestion-mark"></div>

                    {{ 'apps.patient.workflows.details.suggestionHint' | translate }}
                </div>
            </mat-header-cell>

            <mat-cell class="answer-col" *matCellDef="let element">
                <ui-form
                    [name]="element?.itemType + '-' + element?.id"
                    [elements]="questionnaireFormConfig.get(element.id)"
                    [errorMessageTemplate]="errorMessageTemplate"
                    class="answer-form"
                >
                    <ng-template
                        let-control="control"
                        [uiDynamicFormsError]="questionnaireFormConfig.get(element.id)?.name"
                    >
                        <ui-validation-message [control]="control"></ui-validation-message>
                    </ng-template>
                </ui-form>
            </mat-cell>

            <mat-footer-cell *matFooterCellDef class="score-col score-value">
                {{ totalScore }}
                <em *ngIf="form.dirty">*</em>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <ng-container *ngIf="isWithScore">
            <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
        </ng-container>
    </mat-table>
</div>
