<div uiMessageContainer></div>
<ng-template>
    <div class="ui-message-wrapper">
        <mat-icon class="ui-message-icon" *ngIf="icon">{{ icon }}</mat-icon>
        <div class="ui-message-titles">
            <span *ngIf="title" class="ui-message-title">{{ title | translate }}</span>
            <span *ngIf="description" class="ui-message-description">: {{ description | translate }}</span>
        </div>
        <ui-spacer></ui-spacer>
        <!-- prettier-ignore -->
        <div #action class="ui-message-action-wrapper"><ng-content select="[ui-message-action]"></ng-content></div>
        <button
            mat-icon-button
            type="button"
            class="close"
            *ngIf="isEmpty(actionEl) && closable && priority !== 0"
            (click)="close()"
            [attr.data-testid]="'uiMessage-close'"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-template>
