// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.fe1.stage.monatest.icu/auto-open-api-spec.yaml 1.3.6
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating vital signs payload used for admission.
 * @see #/components/schemas/AdmissionVitalSigns - reference to schema in open-api-spec.yaml
 * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
 * @version 1.3.6
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionVitalSigns {
    /**
     * The mass in kg of the patient.
     * @dataFormat double
     */
    weight_in_kg: number;
    /**
     * The height in cm of the patient.
     * @dataFormat double
     */
    height_in_cm: number;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionVitalSigns
 */
export namespace AdmissionVitalSigns {
    /**
     * AdmissionVitalSigns Frontend Model with camelCase properties
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The mass in kg of the patient.
         * @dataFormat double
         */
        weightInKg: number;
        /**
         * The height in cm of the patient.
         * @dataFormat double
         */
        heightInCm: number;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionVitalSigns DTO
     * Transforms AdmissionVitalSigns model from/to API object
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionVitalSigns model */
        static toModel(obj: AdmissionVitalSigns): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionVitalSigns model */
        static toApi(obj: Model): AdmissionVitalSigns {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionVitalSigns;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
