import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, switchMap, tap } from 'rxjs/operators';
import { DeviceActions, DeviceSelectors, DeviceState } from '@mona/device/data-access-device';
import { isEmpty } from '@mona/shared/utils';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { TelemedicineSetupApi } from '../../infrastructure';
import { TelemedicineSetupAction } from '../actions/telemedicine-setup.action';

/**
 * Setup effects
 */
@Injectable()
export class TelemedicineSetupEffects {
    /* Effect Declarations */

    /**
     * Register device effect
     */

    registerDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TelemedicineSetupAction.registerDevice.action),
            concatLatestFrom(() => this.store$.select(DeviceSelectors.selectDeviceKeys)),
            filter(([, keys]) => !isEmpty(keys.telemedicineSshKey)),
            switchMap(([{ hospitalCode, deviceMac }, { telemedicineSshKey }]) =>
                makeDefaultAsyncActionEffect(
                    this.telemedicineSetupApi.registerDevice(hospitalCode, telemedicineSshKey.publicKey, deviceMac),
                    TelemedicineSetupAction.registerDevice,
                ),
            ),
        ),
    );

    /**
     * Check is device activated
     */

    checkIsDeviceActivated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TelemedicineSetupAction.checkIsDeviceActivated.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.telemedicineSetupApi.checkIsDeviceActivated(action.deviceMac),
                    TelemedicineSetupAction.checkIsDeviceActivated,
                    null,
                    true,
                ),
            ),
        ),
    );

    /**
     * Effect to react to device succeeded registration
     */

    registerDeviceSucceeded$ = createEffect(
        () => this.actions$.pipe(ofType(TelemedicineSetupAction.registerDevice.succeededAction)),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store$
     * @param actions$ Actions
     * @param telemedicineSetupApi TelemedicineSetupApi
     */
    constructor(
        private store$: Store<{ device: DeviceState }>,
        private actions$: Actions,
        private telemedicineSetupApi: TelemedicineSetupApi,
    ) {}
}
