import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PractitionerRole } from '@mona/models';
import { PractitionersState, practitionersFeatureKey } from '../../entities';
import * as fromPractitioners from '../reducers/practitioner.reducer';

export const selectPractitionersState = createFeatureSelector<PractitionersState>(practitionersFeatureKey);

export const selectPractitionersStateIsLoading = createSelector(selectPractitionersState, s => s.isLoading);

export const selectPractitionersIds = createSelector(
    selectPractitionersState,
    fromPractitioners.selectIds as (state) => string[],
);

export const selectPractitionersEntities = createSelector(selectPractitionersState, fromPractitioners.selectEntities);

export const selectAllPractitioners = createSelector(selectPractitionersState, fromPractitioners.selectAll);

/**
 * Select practitioner by id
 *
 * @param id
 */
export const selectPractitionerById = (id: string) =>
    createSelector(selectPractitionersEntities, practitioners => practitioners[id]);

/**
 * Select all practitioners by role
 *
 * @param role
 */
export const selectAllPractitionersByRole = (role: PractitionerRole) =>
    createSelector(selectAllPractitioners, practitioners => {
        return practitioners?.filter(p => p.role.includes(role));
    });

export const selectPractitionersTotal = createSelector(selectPractitionersState, fromPractitioners.selectTotal);
