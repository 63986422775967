import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from '@mona/shared/utils';
import {
    encounterDataFeatureKey,
    EncounterFeatureState,
    VentilationParameterState,
    VentilationState,
} from '../../entities';
import { adapterVentilationParameter, adapterVentilationProcedure } from '../reducers/ventilation.reducer';

export const { selectEntities: parameterEntities, selectAll: parameterAll } =
    adapterVentilationParameter.getSelectors();

export const { selectEntities: procedureEntities, selectAll: procedureAll } =
    adapterVentilationProcedure.getSelectors();

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectVentilationState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.ventilation,
);

export const selectVentilationInterval = createSelector(
    selectVentilationState,
    (state: VentilationState) => state.interval,
);

export const selectVentilationParameterState = createSelector(
    selectVentilationState,
    (state: VentilationState) => state.parameters,
);

export const selectVentilationProcedureState = createSelector(
    selectVentilationState,
    (state: VentilationState) => state.procedures,
);

export const selectVentilationParametersStartDate = createSelector(
    selectVentilationParameterState,
    (state: VentilationParameterState) => state.startDate,
);

export const selectVentilationParametersPageableData = createSelector(
    selectVentilationParameterState,
    (state: VentilationParameterState) => state.pageableData,
);

export const selectVentilationParameters = createSelector(selectVentilationParameterState, parameterAll);

export const selectVentilationParametersGroupByCode = createSelector(selectVentilationParameters, all =>
    groupBy(all, a => a.code, false, false),
);

export const selectVentilationParamebersCodesToShow = createSelector(
    selectVentilationParametersPageableData,
    data => data?.codes || [],
);

export const selectVentilationParametersMap = createSelector(selectVentilationParameterState, parameterEntities);

export const selectVentilationProcedures = createSelector(selectVentilationProcedureState, procedureAll);

export const selectVentilationProcedureMap = createSelector(selectVentilationProcedureState, procedureEntities);

export const selectVentilationIsLoading = createSelector(selectVentilationState, state => state.isLoading);
