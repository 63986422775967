import { ApiModels } from '@mona/api';
import { ReportType } from '@mona/models';

/**
 * Transforms api report
 *
 * @param apiReportType ApiModels.ReportType
 */
export const transformApiReportsType = (apiReportType: ApiModels.ReportType): ReportType => {
    return {
        id: apiReportType.id as string,
        reportType: apiReportType.report_type as string,
        name: apiReportType.name as string,
        displayName: apiReportType.display_name as string,
    };
};

/**
 * Transform api reports
 *
 * @param apiExportReports ApiModels.ReportType[]
 */
export const transformApiReportTypes = (apiExportReports: ApiModels.ReportType[]): ReportType[] => {
    return apiExportReports.map(apiExportReport => transformApiReportsType(apiExportReport));
};
