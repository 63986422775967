import { createReducer, on } from '@ngrx/store';
import { bedsAdapter, BedsState } from '../../entities';
import { BedsActions } from '../actions';

/**
 * Wards reducer map
 */
export const initialState: BedsState = bedsAdapter.getInitialState({
    assignedBedId: null,
    selectedId: null,
    isLoading: false,
});

export const bedsReducer = createReducer(
    initialState,
    on(BedsActions.load, state => ({ ...state, isLoading: true })),
    on(BedsActions.loadSuccess, (state, { beds }) => bedsAdapter.setAll(beds, { ...state, isLoading: false })),
    on(BedsActions.upsertBed, (state, { bed }) => bedsAdapter.upsertOne(bed, { ...state })),
    on(BedsActions.removeBed, (state, { bed }) => bedsAdapter.removeOne(bed.id, { ...state })),
    on(BedsActions.clear, state => bedsAdapter.removeAll({ ...state })),
    on(BedsActions.selectBed, (state, { bedId, bed }) => ({ ...state, selectedId: bedId })),
    on(BedsActions.setAssignedBedId, (state, { bedId }) => ({ ...state, assignedBedId: bedId || null })),
);
