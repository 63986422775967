import { CdkCellDef } from '@angular/cdk/table';
import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UiTableCell } from '../models';

/**
 * Cell definition for the ui-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
    selector: '[uiCellDef]',
    inputs: [
        'cellTemplate: uiCellDefTemplate',
        'emptyTemplate: uiCellDefEmptyTemplate',
        'activeTemplate: uiCellDefActiveTemplate',
    ],
    providers: [{ provide: CdkCellDef, useExisting: UiTableCellDef }],
})
export class UiTableCellDef extends CdkCellDef {
    /** Cell template */
    cellTemplate: TemplateRef<any>;
    /** Empty cell template */
    emptyTemplate: TemplateRef<any>;
    /** Active cell template */
    activeTemplate: TemplateRef<any>;
}

/**
 * Cell context definition for the vhs-table.
 * Captures all cell-specific properties.
 */
@Directive({
    selector: '[uiCellContext]',
    exportAs: 'uiCellContext',
})
export class UiTableCellContext extends UiTableCell {
    /** @internal */
    constructor(public elementRef: ElementRef<any>) {
        super();
    }
    /** Extended context */
    @Input() set uiCellContext(value: UiTableCell) {
        this.$implicit = value;
        this.$implicit.elementRef = this.elementRef;
        // this.$implicit.height = this.height = this.elementRef.nativeElement.clientHeight;
        Object.assign(this, value);
    }
}

/**
 * Cell template directive
 *
 * Creates embedded view & assigns cell context (row, column instances & other refs)
 */
@Directive({
    selector: '[uiCellRef]',
})
export class UiTableCellRef implements OnInit {
    /**
     * Constructor
     *
     * @param vcr
     * @param cellContext
     * @param cellDef
     */
    constructor(
        private vcr: ViewContainerRef,
        private cellContext: UiTableCellContext,
        private cellDef: UiTableCellDef,
    ) {
        if (!cellContext || !cellDef) {
            throw new Error('Should not be used outside `vhs-table` and outside `*uiCellDef`');
        }
    }

    /** Gets cell context from parent directive, creates cell view with given template */
    ngOnInit() {
        if (this.cellContext.cell) {
            this.vcr.createEmbeddedView(this.cellDef.cellTemplate, this.cellContext);
        } else if (!this.cellContext.disabled && !this.cellContext.row.readonly && this.cellContext.column.isActive) {
            this.vcr.createEmbeddedView(this.cellDef.activeTemplate, this.cellContext);
        } else if (this.cellDef.emptyTemplate) {
            this.vcr.createEmbeddedView(this.cellDef.emptyTemplate, this.cellContext);
        }
    }
}
