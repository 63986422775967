import { InjectionToken } from '@angular/core';

/** @internal */
export type KeyboardLocaleFn = () => string;
export const KEYBOARD_LOCALE_GETTER = new InjectionToken<KeyboardLocaleFn>('Keyboard locale token', {
    providedIn: 'root',
    factory: () => keyboardLocaleFn,
});
/** @internal  */
export function keyboardLocaleFn() {
    return 'en-GB';
}

/** @internal */
export type KeyboardEnabledFn = () => boolean;

export const KEYBOARD_ENABLED_GETTER = new InjectionToken<KeyboardEnabledFn>('Keyboard enabled token', {
    providedIn: 'root',
    factory: () => keyboardEnabledFn,
});
/** @internal  */
export function keyboardEnabledFn() {
    return false;
}
