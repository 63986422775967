/* eslint-disable @typescript-eslint/no-inferrable-types, @typescript-eslint/member-ordering,@typescript-eslint/no-empty-interface, prefer-rest-params, no-restricted-syntax */
import { defu } from 'defu';
import {
    ClassLoggerOptions,
    colorize,
    Constructor,
    FunctionLoggerOptions,
    getLogTime,
    getMonkeyPatchMethod,
    isTestEnvironment,
    keysOfNonArray,
    LOGGER_DEFAULT_OPTIONS_CLASS,
    noop,
} from './utils';

/**
 * A logger service that provide the same functions as {@link console}.
 * The logger is binded to the console, so the Web Console shows the correct file and line number of the original call.
 */
export interface Logger extends Partial<Console> {
    /**
     * Returns the log level.
     */
    level: LoggerLevel;
}

/**
 * The available log levels.
 */
export enum LoggerLevel {
    OFF = 0,
    ERROR = 1,
    WARN = 2,
    INFO = 3,
    DEBUG = 4,
    LOG = 5,
    VERBOSE = Number.POSITIVE_INFINITY,
}

/**
 * A logger service that provide the same functions as {@link console}.
 * The logger is binded to the console, so the Web Console shows the correct file and line number of the original call.
 */
export class Logger implements Logger {
    /**
     * Outputs a message to the Web Console.
     *
     * @param message A JavaScript string containing zero or more substitution strings.
     * @param optionalParams A list of JavaScript objects to output OR JavaScript objects with which to replace substitution strings within message.
     */
    log: (message?: any, ...optionalParams: any[]) => void;

    /**
     * Outputs a debugging message to the Web Console.
     *
     * @param message A JavaScript string containing zero or more substitution strings.
     * @param optionalParams A list of JavaScript objects to output OR JavaScript objects with which to replace substitution strings within message.
     */
    debug: (message?: any, ...optionalParams: any[]) => void;

    /**
     * Outputs an informational message to the Web Console.
     *
     * @param message A JavaScript string containing zero or more substitution strings.
     * @param optionalParams A list of JavaScript objects to output OR JavaScript objects with which to replace substitution strings within message.
     */
    info: (message?: any, ...optionalParams: any[]) => void;

    /**
     * Outputs a warning message to the Web Console.
     *
     * @param message A JavaScript string containing zero or more substitution strings.
     * @param optionalParams A list of JavaScript objects to output OR JavaScript objects with which to replace substitution strings within message.
     */
    warn: (message?: any, ...optionalParams: any[]) => void;

    /**
     * Outputs an error message to the Web Console.
     *
     * @param message A JavaScript string containing zero or more substitution strings.
     * @param optionalParams A list of JavaScript objects to output OR JavaScript objects with which to replace substitution strings within message.
     */
    error: (message?: any, ...optionalParams: any[]) => void;

    /**
     * Creates a new inline group in the Web Console log.
     *
     * @param groupTitle An optional title for the group.
     */
    group: (groupTitle?: string) => void;

    /**
     * Creates a new inline group in the Web Console log that is initially collapsed.
     *
     * @param groupTitle An optional title for the group.
     */
    groupCollapsed: (groupTitle?: string) => void;

    /**
     * Exits the current inline group in the Web Console.
     */
    groupEnd: () => void;

    /**
     * Starts a timer you can use to track how long an operation takes. It works only with log {@link Level} equal or higher than DEBUG.
     *
     * @param timerName The name to give the new timer. This will identify the timer.
     */
    time: (timerName?: string) => void;

    /**
     * Stops a timer that was previously started by calling {@link Logger.time}. It works only with log {@link Level} equal or higher than DEBUG.
     *
     * @param timerName The name of the timer to stop. Once stopped, the elapsed time is automatically displayed in the Web Console.
     */
    timeEnd: (timerName?: string) => void;

    /**
     * The log level.
     */
    level: LoggerLevel;

    /**
     * Constructor
     *
     * @param scope
     */
    constructor(scope: string = 'MONA') {
        if (isTestEnvironment() && process?.env?.DEBUG !== 'logger') {
            this.level = LoggerLevel.OFF;
        } else {
            this.level = Number(process?.env?.LOGGER_LEVEL || LoggerLevel.LOG);
        }

        // console.log
        if (this.level >= LoggerLevel.LOG && console && console.log) {
            this.log = console.log.bind(console, colorize(`[LOG::${getLogTime()}::${scope}]`, 'reset'));
        } else {
            this.log = noop;
        }

        // console.debug
        if (this.level >= LoggerLevel.DEBUG && console && console.debug) {
            this.debug = console.debug.bind(console, colorize(`[DEBUG::${getLogTime()}::${scope}]`, 'cyan'));
        } else {
            this.debug = noop;
        }

        // console.info
        if (this.level >= LoggerLevel.INFO && console && console.info) {
            (this.info = console.info.bind(console)), colorize(`[INFO::${getLogTime()}::${scope}]`, 'blue');
        } else {
            this.info = noop;
        }

        // console.warn
        if (this.level >= LoggerLevel.WARN && console && console.warn) {
            this.warn = console.warn.bind(console, colorize(`[WARN::${getLogTime()}::${scope}]`, 'yellow'));
        } else {
            this.warn = noop;
        }

        // console.error
        if (this.level >= LoggerLevel.ERROR && console && console.error) {
            this.error = console.error.bind(console, colorize(`[ERROR::${getLogTime()}::${scope}]`, 'red'));
        } else {
            this.error = noop;
        }

        // console.group
        if (this.level > LoggerLevel.OFF && console && console.group) {
            this.group = console.group.bind(console);
        } else {
            this.group = noop;
        }

        // console.groupCollapsed
        if (this.level > LoggerLevel.OFF && console && console.groupCollapsed) {
            this.groupCollapsed = console.groupCollapsed.bind(console);
        } else {
            this.groupCollapsed = noop;
        }

        // console.groupEnd
        if (this.level > LoggerLevel.OFF && console && console.groupEnd) {
            this.groupEnd = console.groupEnd.bind(console);
        } else {
            this.groupEnd = noop;
        }

        // console.time
        if (this.level >= LoggerLevel.DEBUG && console && console.time) {
            this.time = console.time.bind(console);
        } else {
            this.time = noop;
        }

        // console.timeEnd
        if (this.level >= LoggerLevel.DEBUG && console && console.timeEnd) {
            this.timeEnd = console.timeEnd.bind(console);
        } else {
            this.timeEnd = noop;
        }
    }
}

// TODO: finish typing
type MethodsList<T> = Cast<FunctionPropertyNames<T>, string>[];

/**
 * Decorate a class with a logger property
 *
 * @param options
 */
export function WithLogger<C extends Constructor<any>>(options: ClassLoggerOptions<C> = {}) {
    options = defu(options, LOGGER_DEFAULT_OPTIONS_CLASS);
    if (options.scope) {
        options.methodOptions.scope = options.scope;
    }
    return (constructor: C) => {
        constructor.prototype.logger = new Logger(options.scope) as Logger;
        // INFO: we don't want to log in test environment
        if (isTestEnvironment() && process.env.DEBUG !== 'logger') {
            return;
        }
        options.methodOptions.logFunction = constructor.prototype.logger.debug.bind(constructor.prototype.logger);
        keysOfNonArray(constructor.prototype)
            .filter((methodName: string): boolean => {
                if (methodName === 'constructor' || methodName === 'logger') {
                    return false;
                }
                if (!options.loggedMethodsNames?.length) {
                    return true;
                }
                const { included, excluded } = options.loggedMethodsNames.reduce(
                    (acc, name: string) => {
                        name.startsWith('!') ? acc.excluded.push(name.slice(1)) : acc.included.push(name);
                        return acc;
                    },
                    { included: [], excluded: [] },
                );
                // if the method name is in the list of INCLUDED logged methods AND NOT in the list of EXCLUDED logged methods
                return included.length ? included.includes(methodName) : !excluded.includes(methodName);
            })
            .forEach((methodName: string): void => {
                const originalMethod = constructor.prototype[methodName];

                // set by method logger decorator for disabling the method log
                if (
                    !originalMethod ||
                    typeof originalMethod !== 'function' ||
                    originalMethod.__loggerMonkeyPatchCompleted === true
                ) {
                    return;
                }

                constructor.prototype[methodName] = getMonkeyPatchMethod(
                    originalMethod,
                    methodName,
                    options.methodOptions,
                );
            });
    };
}

/**
 * Class with a logger property
 */
export interface WithLogger {
    /** Logger */
    readonly logger?: Logger;
}

/**
 * Decorator to print console log for a method\
 *
 * @param options FunctionLoggerOptions
 */
export function LogMethod(
    options: FunctionLoggerOptions & {
        threshold?: number;
        ignoreProperties?: string[];
        interceptProperties?: { [p: string]: AnyFn };
    } = {},
) {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        // INFO: keep as function to use this and arguments
        descriptor.value = getMonkeyPatchMethod(originalMethod, key, options);
        return descriptor;
    };
}
