import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BalanceValue, ChangeLogEntry, ChangeLogModel, ChangeLogOperation, Encounter } from '@mona/models';
import { ChangeLogAction, ChangeLogSelectors, mergeDataWithPersistedChanges } from '@mona/pdms/data-access-changelog';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { MedicationAdministrationsApi } from '@mona/pdms/data-access-medications';
import { EntriesInterval, EntriesIntervalInMinutes } from '@mona/shared/date';
import { isEmpty, notEmpty, isNumber } from '@mona/shared/utils';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { BalanceService } from '../../application';
import { BalanceApi, OutputsApi } from '../../infrastructure';
import { BalanceActions, BalanceValuesActions } from '../actions';
import {
    selectBalanceInterval,
    selectBalanceIsLoading,
    selectBalanceValuesEntities,
    selectBalanceValuesFromCache,
} from '../selectors';

/**
 * Balance effects
 */
@Injectable()
export class BalanceEffects {
    toggleLoadingEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BalanceActions.setIntervalAction, BalanceActions.setActiveTable),
                concatLatestFrom(() => this.store$.select(selectBalanceIsLoading)),
                tap(([, balanceIsLoading]) => {
                    if (balanceIsLoading) {
                        return; // do not need to toggle loading if already is
                    }
                    this.store$.dispatch(BalanceActions.setIsLoadingAction({ isLoading: true }));
                    setTimeout(() => {
                        this.store$.dispatch(BalanceActions.setIsLoadingAction({ isLoading: false }));
                    }, 500);
                }),
            ),
        { dispatch: false },
    );
    /** Listen change persist success & reload balance entitites */
    onChangeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.balanceService.getMedicationAdministrations(),
                    this.store$.select(ChangeLogSelectors.getChangesMap).pipe(notEmpty()),
                ),
                withCurrentEncounterId(),
                tap(([[{ payload: persistResult }, data, changesMap], encounterId]) => {
                    const {
                        Output: output,
                        MedicationAdministration: medicationAdministration,
                        BalanceTarget: balanceTarget,
                    } = changesMap;
                    if (!isEmpty(output)) {
                        this.store$.dispatch(BalanceActions.loadOutputsAction.action({ encounterId }));
                    }

                    const succeededMedicationAdministrationChanges = medicationAdministration?.filter(c =>
                        persistResult.succeeded.includes(c.id),
                    );

                    if (!isEmpty(succeededMedicationAdministrationChanges)) {
                        const medicationAdministrations = mergeDataWithPersistedChanges(
                            data,
                            succeededMedicationAdministrationChanges,
                        );

                        this.store$.dispatch(
                            BalanceActions.loadMedicationAdministrationsSuccess({
                                medicationAdministrations,
                            }),
                        );
                    }

                    // NOTE: always reload  on change as balance calculations are server side
                    if (!isEmpty(output) || !isEmpty(medicationAdministration) || !isEmpty(balanceTarget)) {
                        this.store$.dispatch(BalanceValuesActions.loadBalanceValues({ force: true }));
                        this.store$.dispatch(BalanceValuesActions.clearBalanceValuesCache());
                    }
                }),
            ),
        { dispatch: false },
    );
    /** Listen to change save log action */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.saveChangeAction.succeededAction),
                withLatestFrom(
                    this.store$.select(selectBalanceValuesEntities),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, balanceValuesMapByDate, changesMap]) => {
                    const changes = changesMap['BalanceTarget'] || [];
                    const balanceValues = this.mapChangeLogEntriesToBalanceValuesSlice(balanceValuesMapByDate, changes);

                    if (balanceValues?.length) {
                        this.store$.dispatch(
                            BalanceValuesActions.upsertBalanceValues({
                                balanceValues,
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );
    /**
     * Load outputs effect
     */
    loadOutputs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BalanceActions.loadOutputsAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.outputsApi.getOutputs(action.encounterId),
                    BalanceActions.loadOutputsAction,
                ),
            ),
        ),
    );
    /**
     * Load medication administrations effect
     */
    loadMedicationAdministrations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BalanceActions.loadMedicationAdministrations),
            switchMap(action =>
                this.medicationAdministrationsApi.getMedicationAdministrations(action.encounterId).pipe(
                    map(medicationAdministrations =>
                        BalanceActions.loadMedicationAdministrationsSuccess({ medicationAdministrations }),
                    ),
                    catchError(error => of(BalanceActions.loadMedicationAdministrationsFailed({ error }))),
                ),
            ),
        ),
    );
    /** Selector for Balance Interval  */
    private balanceInterval$: Observable<EntityId<Encounter>> = this.store$.select(selectBalanceInterval);
    /**
     * Load balance values effect
     */
    loadBalanceValues$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BalanceValuesActions.loadBalanceValues),
            withLatestFrom(this.balanceInterval$, this.store$.select(selectBalanceValuesFromCache)),
            withCurrentEncounterId(),
            delay(250),
            switchMap(([[{ force }, interval, cachedValue], encounterId]) => {
                const fetchDataFromAPI = this.balanceApi
                    .getBalanceValues(encounterId, EntriesIntervalInMinutes[interval])
                    .pipe(
                        catchError(error => {
                            this.store$.dispatch(BalanceValuesActions.loadBalanceValuesFailure({ error }));
                            return of([]);
                        }),
                    );
                return !force && !isEmpty(cachedValue) ? of(cachedValue) : fetchDataFromAPI;
            }),
            withLatestFrom(this.store$.select(ChangeLogSelectors.getChangesMap)),
            map(([data, changesMap]) => {
                const changes = changesMap['BalanceTarget'] || [];

                if (!changes.length) {
                    return data;
                }
                return this.mapChangeLogEntriesToBalanceValuesSlice(data, changes);
            }),
            withLatestFrom(this.balanceInterval$),
            map(([balanceValues, interval]) =>
                BalanceValuesActions.loadBalanceValuesSuccess({ balanceValues, interval: interval as EntriesInterval }),
            ),
        );
    });

    /**
     * Constructor
     *
     * @param store$
     * @param actions$ Actions
     * @param outputsApi OutputsApi
     * @param medicationAdministrationsApi MedicationAdministrationsApi
     * @param balanceApi BalanceApi
     * @param balanceService BalanceService
     */
    constructor(
        private store$: Store,
        private actions$: Actions,
        private outputsApi: OutputsApi,
        private medicationAdministrationsApi: MedicationAdministrationsApi,
        private balanceApi: BalanceApi,
        private balanceService: BalanceService,
    ) {}

    /**
     * map change log entries to balance values slice
     *
     * @param data BalanceValue[] | { [key: string]: BalanceValue }
     * @param changes ChangeLogEntry<ChangeLogModel>[]
     */
    private mapChangeLogEntriesToBalanceValuesSlice(
        data: BalanceValue[] | { [key: string]: BalanceValue },
        changes: ChangeLogEntry<ChangeLogModel>[],
    ): BalanceValue[] {
        if (!changes.length) {
            return;
        }

        const balanceValues: BalanceValue[] = Array.isArray(data) ? [...data] : Object?.values(data || {});

        changes.forEach(change => {
            let dateKey: string;

            if (change.operation === ChangeLogOperation.Create) {
                dateKey = change.payload.date.toISOString().split('.')[0] + 'Z';
            } else {
                dateKey = balanceValues.find(bv => bv.balanceTargetId === change.modelId)?.date;
            }

            const balanceValue: BalanceValue = balanceValues.find(bv => bv.date === dateKey);

            const newValue: BalanceValue = {
                date: dateKey,
                target: isNumber(change.payload?.value) ? change.payload?.value : balanceValue?.target,
                balanceTargetId: change.modelId || balanceValue?.balanceTargetId,
                current: balanceValue?.current || null,
                input: balanceValue?.input || null,
                output: balanceValue?.output || null,
                total: balanceValue?.total || null,
                hasChanges: true,
            };

            const indexOfExisted = balanceValues.findIndex(v => v.date === dateKey);

            if (change.operation === ChangeLogOperation.Delete) {
                newValue['isStageRemoved'] = true;
            }

            if (indexOfExisted === -1) {
                balanceValues.push(newValue);
            } else {
                balanceValues[indexOfExisted] = newValue;
            }
        });

        return balanceValues;
    }

    /**
     * get date from model id
     *
     * @param modelId string
     */
    private extractISODateFromUuid(modelId: string): string {
        const lightFormattedDateFromId = modelId.substr(-12, 12);
        const year = lightFormattedDateFromId.substr(0, 4);
        const month = lightFormattedDateFromId.substr(4, 2);
        const day = lightFormattedDateFromId.substr(6, 2);
        const hour = lightFormattedDateFromId.substr(8, 2);
        const minute = lightFormattedDateFromId.substr(10, 2);

        return new Date(`${year}-${month}-${day} ${hour}:${minute}`).toISOString();
    }
}
