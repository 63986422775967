// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Base Serializer for all Serializer implementations in PDMS.
 * @see #/components/schemas/WorkflowResponseList - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowResponseList {
    /**
     * Workflow unique ID.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The questionnaire url of the Workflow-Questionnaire-Response.
     * @dataFormat uri
     */
    questionnaire_url: string;
    /**
     * The datetime of the Workflow-Questionnaire-Response.
     * @dataFormat date-time
     */
    date: string;
    /**
     * Is the Workflow completed
     *
     */
    is_completed: boolean;
    /**
     * The last changed datetime of the Workflow.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * Practitioner who changed the workflow last.
     * @dataFormat uuid
     */
    last_changed_by: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowResponseList
 */
export namespace WorkflowResponseList {
    /**
     * WorkflowResponseList Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Workflow unique ID.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The questionnaire url of the Workflow-Questionnaire-Response.
         * @dataFormat uri
         */
        questionnaireUrl: string;
        /**
         * The datetime of the Workflow-Questionnaire-Response.
         * @dataFormat date-time
         */
        @TransformDate() date: Date;
        /**
         * Is the Workflow completed
         * @type boolean (`dataFormat` is missing)
         */
        isCompleted: boolean;
        /**
         * The last changed datetime of the Workflow.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * Practitioner who changed the workflow last.
         * @dataFormat uuid
         */
        lastChangedBy: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowResponseList DTO
     * Transforms WorkflowResponseList model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowResponseList model */
        static toModel(obj: WorkflowResponseList): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowResponseList model */
        static toApi(obj: Model): WorkflowResponseList {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowResponseList;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
