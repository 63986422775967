import { Direction, Directionality } from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Portal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Observable, Subscription } from 'rxjs';
import { WithLogger } from '@mona/shared/logger';
import { NAVIGATION, NavigationService, OutletState } from '@mona/shared/utils';
import { LayoutConfig } from '../drawer-layout.model';
import { DrawerService } from '../drawer.service';
import { DrawerLayoutVariantType } from '../drawer.type';
import { StateListener } from '../state-listener.component';

/**
 * [DrawerLayout Component](https://brightlayer-ui-components.github.io/angular/?path=/info/components-drawer--readme)
 *
 * The `<ui-drawer-layout>` component is a wrapper around the [Angular Material Sidenav](https://material.angular.io/components/sidenav/overview) that adds specific Brightlayer UI functionality and styling.
 * The `<ui-drawer-layout>` component is used to provide the appropriate resizing behavior for your main application content when used in conjunction with a Brightlayer UI `<ui-drawer>`.
 * It accepts a drawer and content as child elements;
 */
@Component({
    selector: 'ui-drawer-layout',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./drawer-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-content select="[ui-navbar]"></ng-content>
        <div
            id="ui-drawer-fab"
            class="ui-drawer-layout-fab"
            [style.left.rem]="
                (isCollapsed() ? getCollapsedWidth() - toRem(widthNavBar) : toRem(width - widthNavBar * 2)) + toRem(68)
            "
            data-testid="ui-drawer-fab"
            *ngIf="fabPortal$ | async as fabPortal"
        >
            <ng-container [cdkPortalOutlet]="fabPortal"></ng-container>
        </div>
        <mat-sidenav-container class="ui-drawer-layout-content" autosize="false">
            <mat-sidenav
                class="ui-drawer-layout-sidenav"
                [fixedInViewport]="false"
                [disableClose]="true"
                [class.ui-drawer-layout-smooth]="variant !== 'temporary'"
                [class.mat-elevation-z6]="!hasSideBorder()"
                [style.width.rem]="!layoutConfig?.hasSidenav ? 0 : isCollapsed() ? getCollapsedWidth() : toRem(width)"
                [style.padding-left.px]="layoutConfig?.hasNavbar && layoutConfig?.hasSidenav ? widthNavBar : 0"
                [mode]="getMode()"
                [opened]="isDrawerVisible()"
                [class._d-none]="!layoutConfig?.hasSidenav"
            >
                <ng-content select="[ui-drawer]"></ng-content>
            </mat-sidenav>
            <div
                id="main-content"
                class="ui-drawer-layout-nav-content"
                [class.ui-drawer-layout-smooth]="variant !== 'temporary'"
                [style.marginLeft.rem]="layoutConfig?.hasSidenav ? getContentMargin() : 0"
                [style.marginRight.px]="isRightOpen() && modeRight === 'side' ? widthRight : 0"
            >
                <ui-dropdown-toolbar
                    data-testid="ui-toolbar"
                    *ngIf="layoutConfig?.hasAppbar"
                    [title]="layoutConfig?.title"
                    [subtitle]="layoutConfig?.subtitle"
                    [currentExternalResource$]="layoutConfig?.selectedExternalResource$"
                    [color]="undefined"
                    [sticky]="true"
                >
                    <button
                        data-testid="ui-goBack"
                        ui-nav-icon
                        mat-icon-button
                        (click)="goBack()"
                        [class.d-invisible]="layoutConfig?.canGoBack !== 'appBar'"
                    >
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <span ui-toolbar-title *ngIf="toolbarTitlePortal$ | async as toolbarTitlePortal">
                        <ng-container [cdkPortalOutlet]="toolbarTitlePortal"></ng-container>
                    </span>
                    <span ui-toolbar-subtitle *ngIf="toolbarSubTitlePortal$ | async as toolbarSubTitlePortal">
                        <ng-container [cdkPortalOutlet]="toolbarSubTitlePortal"></ng-container>
                    </span>
                    <div
                        ui-toolbar-actions
                        *ngIf="toolbarActionsPortal$ | async as toolbarActionsPortal"
                        data-testid="ui-toolbar-actions"
                        style="display: flex; margin: 0 -8px"
                    >
                        <ng-container [cdkPortalOutlet]="toolbarActionsPortal"></ng-container>
                    </div>
                </ui-dropdown-toolbar>
                <ng-content select="[ui-content]"></ng-content>
            </div>
            <div style="display:none; font-size: 1rem" #remElement></div>
            <!-- / RIGHT_SIDENAV / -->
            <mat-sidenav
                class="ui-drawer-layout-sidenav ui-drawer-layout-sidenav--right"
                #rightSideNav
                position="end"
                [opened]="isRightOpen()"
                [fixedInViewport]="true"
                [mode]="modeRight"
                [disableClose]="disableClose"
                [autoFocus]="autoFocusRight"
                (closed)="handleRightSidenavCloseEvent()"
            >
                <button
                    class="sidenav-close"
                    mat-icon-button
                    style="margin: 0 4px"
                    (click)="drawerService.setRightSideNavOpen(false)"
                    *ngIf="false"
                >
                    <mat-icon>close</mat-icon>
                </button>
                <router-outlet
                    name="side"
                    (activate)="handleRightSidenavActivateEvent(true)"
                    (deactivate)="handleRightSidenavActivateEvent(false)"
                ></router-outlet>
            </mat-sidenav>
            <!-- / RIGHT_SIDENAV / -->
        </mat-sidenav-container>
    `,
    host: {
        class: 'ui-drawer-layout',
    },
})
@WithLogger({
    scope: 'UI',
    loggedMethodsNames: ['ngAfterViewInit', 'handleRightSidenavCloseEvent', 'handleRightSidenavActivateEvent'],
})
export class DrawerLayoutComponent extends StateListener implements OnInit, AfterViewInit, OnChanges {
    /**
     * Controls how the Drawer behaves and appears on the screen.  Can be `permanent` | `peristent` | `temporary` | `rail`.
     *
     * `permanent` - `Drawer` appears on the side of the screen and cannot be dismissed.
     *
     * `persistent` - `Drawer` appears on the side of the screen and responds to the `open` @Input. When closed, only shows each `NavItem` icon.
     *
     * `temporary` - `Drawer` slides in from the side of the screen and appears as an overlay.
     *
     * `rail` - `Drawer` appears on the side of the screen as a slim element, supporting icons and text.
     *
     * @default temporary
     */
    @Input() variant: DrawerLayoutVariantType = 'temporary';
    /**
     * Drawer pixel width
     *
     * @default 350
     */
    @Input() width = 350;
    /**
     * Drawer pixel width
     *
     * @default 518
     */
    @Input() widthRight = 518;
    /**
     * Navbar pixel width
     *
     * @default 518
     */
    @Input() widthNavBar = 96;
    /** Navbar config */
    @Input() layoutConfig: LayoutConfig = {} as any;
    /** Event triggered on 'temporary' variant backdrop click */
    @Output() backdropClick: EventEmitter<void> = new EventEmitter();

    @ViewChild('remElement') remElement: ElementRef;
    @ViewChild('rightSideNav') private rightSideNav!: MatSidenav;

    /** Toolbar FAB portal */
    fabPortal$: Observable<Portal<any>> = this.drawerService.getDrawerPortal('fab');
    /** Toolbar actions portal */
    toolbarActionsPortal$: Observable<Portal<any>> = this.drawerService.getDrawerPortal('toolbarActions');
    /** Toolbar title portal */
    toolbarTitlePortal$: Observable<Portal<any>> = this.drawerService.getDrawerPortal('toolbarTitle');
    /** Toolbar subtitle portal */
    toolbarSubTitlePortal$: Observable<Portal<any>> = this.drawerService.getDrawerPortal('toolbarSubTitle');

    isRtl = false;
    remSizePx: number;
    dirChangeSubscription = Subscription.EMPTY;

    modeRight: MatDrawerMode = 'over';
    disableClose = false;
    closeRight = false;
    autoFocusRight = false;

    content: HTMLElement;

    /**
     * Constructor
     *
     * @param drawerService
     * @param cdRef
     * @param _dir
     * @param navigationService
     */
    constructor(
        drawerService: DrawerService,
        private cdRef: ChangeDetectorRef,
        private readonly _dir: Directionality,
        @Inject(NAVIGATION) private navigationService: NavigationService,
    ) {
        super(drawerService, cdRef);
        this.dirChangeSubscription = _dir.change.subscribe((direction: Direction) => {
            this.isRtl = direction === 'rtl';
            cdRef.detectChanges();
        });
    }

    /**
     * Lifecycle
     */
    ngOnInit(): void {
        this.isRtl = this._dir.value === 'rtl';
    }

    /**
     * Lifecycle
     */
    ngAfterViewInit(): void {
        this.isRtl = this._dir.value === 'rtl';
    }

    /**
     * Lifecycle
     */
    ngOnChanges(): void {
        this.drawerService.setDrawerVariant(this.variant);
        this.changeDetector.detectChanges();
    }

    /**
     * Lifecycle
     */
    ngOnDestroy(): void {
        this.unsubscribeListeners();
        this.dirChangeSubscription.unsubscribe();
    }

    /** Handle right sidenav close event  */
    handleRightSidenavCloseEvent() {
        this.cdRef.markForCheck();
    }

    /**
     * Handle right sidenav outlet activate event
     *
     * @param opened
     */
    handleRightSidenavActivateEvent(opened: boolean) {
        const state = this.navigationService.currentLocationState as OutletState;
        const { disableClose = true, mode = 'over' } = state;
        this.modeRight = mode;
        this.disableClose = coerceBooleanProperty(disableClose);
        this.drawerService.setRightSideNavOpen(opened);
    }

    /**
     * Get drawer mode
     */
    getMode(): MatDrawerMode {
        return this.variant === 'temporary' ? 'over' : 'side';
    }

    /**
     * Convert to Rem
     *
     * @param px
     */
    toRem(px: number): number {
        px = this.layoutConfig.hasNavbar ? px + this.widthNavBar : px; // If has NavBar
        if (this.remElement && this.remElement.nativeElement) {
            const style = getComputedStyle(this.remElement.nativeElement);
            this.remSizePx = Number(style.fontSize.split('px')[0]);
        }
        return px / (this.remSizePx || 16);
    }

    /**
     * Has side border
     */
    hasSideBorder(): boolean {
        return this.drawerService.hasSideBorder();
    }

    /**
     * Close drawer
     */
    closeDrawer(): void {
        this.drawerService.setDrawerOpen(false);
        this.backdropClick.emit();
    }

    /**
     * Is the drawer seen on the screen and expanded.
     */
    isDrawerVisible(): boolean {
        if (this.variant === 'temporary') {
            return this.isOpen();
        }
        return true;
    }

    /**
     * Get content margin
     */
    getContentMargin(): number {
        if (this.variant === 'temporary') {
            return 0;
        }
        return this.isCollapsed() ? this.getCollapsedWidth() : this.toRem(this.width);
    }

    /**
     * Get collapsed width
     */
    getCollapsedWidth(): number {
        return this.variant === 'rail' && !this.drawerService.isRailCondensed()
            ? 5.75 + this.toRem(16) // Rail (default)
            : 1.5 + this.toRem(32); //  Rail (condensed) || closed persistent
    }

    /**
     * Is the drawer condensed.
     */
    isCollapsed(): boolean {
        if (this.variant === 'rail') {
            return true;
        } // Rail is always collapsed.
        if (this.variant === 'persistent') {
            return !this.isOpen();
        }
        return false;
    }

    /** Navigates back in the platform's history */
    goBack() {
        this.navigationService.goBack();
    }
}
