import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { DeviceFacade } from '@mona/device/data-access-device';
import { Timezone, TimezonesMap } from '@mona/models';
import { PLATFORM, Platform, takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { TimeZoneForm } from '../../time-zone-form.model';

/**
 * Reusable TimeZone Form Component
 *
 * INFO: previously this form was in StepRegionComponent, also it was automatically saving settings to system (configurable now)
 */
@TakeUntilDestroy
@Component({
    selector: 'app-time-zone-form',
    templateUrl: './time-zone-form.component.html',
    styleUrls: ['./time-zone-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeZoneFormComponent implements OnInit, OnDestroy {
    /** Should save to system on form change */
    @Input() shouldSaveOnChange = true;
    /** Output form value (optional, form is saving) */
    @Output() submitted = new EventEmitter<{ timezone: string; city: string }>();
    /** Time zone form */
    timeZoneForm: FormGroup<TimeZoneForm> = new FormGroup<TimeZoneForm>({
        timezone: new FormControl<string>(null, Validators.required),
        city: new FormControl<string>(null, Validators.required),
    });

    /** Runs on linux */
    isLinux = this.platform.isLinux;

    /** The timezones provided by the os */
    availableTimezones: TimezonesMap;

    /** Current os time zone */
    currentSystemTimezone$: Observable<Timezone>;

    /**
     * constructor
     *
     * @param controlContainer ControlContainer
     * @param platform
     * @param deviceFacade DeviceFacade
     */
    constructor(
        @Optional() private controlContainer: ControlContainer,
        @Inject(PLATFORM) private platform: Platform,
        private deviceFacade: DeviceFacade,
    ) {}

    /** NgHook */
    ngOnInit(): void {
        this.initForm();
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, jsdoc/require-jsdoc
    ngOnDestroy(): void {
        //
    }

    /**
     * Initializes the form
     */
    private initForm() {
        if (this.controlContainer?.control) {
            this.timeZoneForm = this.controlContainer.control as FormGroup<TimeZoneForm>;
        }
        this.timeZoneForm.disable();
        // keep form disabled if not linux
        if (!this.isLinux) {
            return;
        }

        // we only have to initialize the properties as they are only used on linux
        if (this.isLinux) {
            this.availableTimezones = this.deviceFacade.getTimezones();
            this.currentSystemTimezone$ = this.deviceFacade.timeZone$;
        }

        this.subscribeToTimeZone();
        this.subscribeToFormValue();
    }

    /**
     * Initialize form based on current timezone
     * then
     * Reset the cities based on new region change
     */
    private subscribeToTimeZone() {
        // Update the cities based on the selected region
        this.currentSystemTimezone$
            .pipe(
                distinctUntilChanged(),
                take(1),
                tap((current: Timezone) => {
                    this.timeZoneForm.patchValue({
                        timezone: current.zone,
                        city: current.city,
                    });
                    this.timeZoneForm.enable();
                }),
                switchMap(() => this.timeZoneForm.controls.timezone.valueChanges),
                tap(() => this.timeZoneForm.controls.city.reset()),
                takeUntilDestroy(this),
            )
            .subscribe();
    }

    /**
     * Watch the TimeZone Form valueChanges, emit to output
     * (skip 1st change from current setting patch and skip invalid values)
     */
    private subscribeToFormValue(): void {
        this.timeZoneForm.valueChanges
            .pipe(
                distinctUntilChanged(),
                filter(() => !!this.timeZoneForm.valid),
                tap(({ city, timezone }) => {
                    this.submitted.emit({ city, timezone });
                    if (this.shouldSaveOnChange) {
                        this.deviceFacade.setTimezone({ zone: timezone, city });
                    }
                }),
                takeUntilDestroy(this),
            )
            .subscribe();
    }
}
