import { TypedAction } from '@ngrx/store/src/models';
import { LoadEntitiesSucceededAction, SucceededAction } from '../models';

/**
 * Helper class for entity array reducing
 */
export class EntityArrayReducerHelper<E extends Entity> {
    /**
     * Add all entities
     * @param state state
     * @param action action
     */
    reduceLoadEntitiesSucceededAction(state: E[], action: LoadEntitiesSucceededAction<E> & TypedAction<any>): E[] {
        return action.payload;
    }

    /**
     * Add newly loaded entities to existing
     * @param state state
     * @param action action
     */
    reduceLoadEntitiesSucceededWithExistingAction(
        state: E[],
        action: LoadEntitiesSucceededAction<E> & TypedAction<any>,
    ): E[] {
        const existingEntities = state.filter(entity => !action.payload.find(newEntity => newEntity.id === entity.id));
        return [...existingEntities, ...action.payload];
    }

    /**
     * Add entity
     * @param state state
     * @param action action
     */
    reduceAddUpdateEntityAction(state: E[], action: SucceededAction<E> & TypedAction<any>): E[] {
        const existingEntityIndex = state.findIndex(entity => entity.id === action.payload.id);

        if (existingEntityIndex !== -1) {
            const changedArray = [...state];
            changedArray.splice(existingEntityIndex, 1, action.payload);
            return changedArray;
        }

        return [...state, action.payload];
    }

    /**
     * Remove all entities
     * @param state state
     */
    reduceLoadEntitiesClearAction(state: E[]): E[] {
        return [];
    }
}
