<mat-tab-group
    mat-stretch-tabs
    mat-align-tabs="start"
    [disableRipple]="true"
    [dynamicHeight]="false"
    [(selectedIndex)]="selectedTabIndex"
    animationDuration="250ms"
    backgroundColor="primary"
    *rxLet="config$; let config"
>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon data-testid="btn-run">monitor_heart</mat-icon>
            {{ 'oobe.steps.diagnostics.title' | translate }}
        </ng-template>
        <ng-template matTabContent>
            <mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate" color="accent"></mat-progress-bar>
            <mat-list class="mona-diagnostics__list" *rxLet="healthStateMap$; let healthStateMap">
                <!-- CORE API URL -->
                <mona-diagnostics-item
                    class="mona-diagnostics__item"
                    [error]="healthStateMap[config.api.baseUrl]?.error"
                    [isLoading]="healthStateMap[config.api.baseUrl]?.isLoading"
                    [inProgressTitle]="'diagnostics.checkingCore'"
                    [succeededTitle]="'diagnostics.coreAvailable'"
                    [failedTitle]="'diagnostics.coreNotAvailable'"
                    [requiredHint]="'diagnostics.requiredForCoreHint'"
                    [url]="config.api.baseUrl"
                    [hasChangeBtn]="allowChangeUrls"
                    (showErrorDetails)="showErrorDetails($event)"
                    (changeButtonClicked)="changeCoreUrl()"
                ></mona-diagnostics-item>

                <mona-diagnostics-item
                    class="mona-diagnostics__item"
                    [error]="healthStateMap[config.api.coreWsUrl]?.error"
                    [isLoading]="healthStateMap[config.api.coreWsUrl]?.isLoading"
                    [inProgressTitle]="'diagnostics.checkingCoreWS'"
                    [succeededTitle]="'diagnostics.coreWSAvailable'"
                    [failedTitle]="'diagnostics.coreWSNotAvailable'"
                    [requiredHint]="'diagnostics.requiredForCoreHint'"
                    [url]="config.api.coreWsUrl"
                    (showErrorDetails)="showErrorDetails($event)"
                ></mona-diagnostics-item>

                <mona-diagnostics-item
                    class="mona-diagnostics__item"
                    *ngIf="!isCoreOnlyCheck && hasTelemedicineCheck"
                    [error]="healthStateMap[config.api.telemedicineUrl]?.error"
                    [isLoading]="healthStateMap[config.api.telemedicineUrl]?.isLoading"
                    [inProgressTitle]="'diagnostics.checkingTelemedicine'"
                    [succeededTitle]="'diagnostics.telemedicineAvailable'"
                    [failedTitle]="'diagnostics.telemedicineNotAvailable'"
                    [requiredHint]="'diagnostics.requiredForTelemedicineHint'"
                    [url]="config.api.telemedicineUrl"
                    [hasChangeBtn]="allowChangeUrls"
                    (showErrorDetails)="showErrorDetails($event)"
                    (changeButtonClicked)="changeTelemedicineUrl()"
                ></mona-diagnostics-item>

                <mona-diagnostics-item
                    class="mona-diagnostics__item"
                    *ngIf="!isCoreOnlyCheck && hasTelemedicineCheck"
                    [error]="healthStateMap[config.api.telemedicineWsUrl]?.error"
                    [isLoading]="healthStateMap[config.api.telemedicineWsUrl]?.isLoading"
                    [inProgressTitle]="'diagnostics.checkingTelemedicineWS'"
                    [succeededTitle]="'diagnostics.telemedicineWSAvailable'"
                    [failedTitle]="'diagnostics.telemedicineWSNotAvailable'"
                    [requiredHint]="'diagnostics.requiredForTelemedicineHint'"
                    [url]="config.api.telemedicineWsUrl"
                    (showErrorDetails)="showErrorDetails($event)"
                ></mona-diagnostics-item>

                <ui-info-list-item *rxLet="online$; let online">
                    <mat-icon ui-icon [ngClass]="online ? 'text-success' : 'text-accent'">
                        {{ online ? 'check_circle' : 'warning' }}
                    </mat-icon>
                    <div ui-title>
                        {{
                            (online ? 'diagnostics.internetAvailable' : 'diagnostics.internetNotAvailable') | translate
                        }}
                    </div>
                    <div ui-subtitle class="text-muted">
                        {{ 'diagnostics.requiredForCoreHint' | translate }}
                    </div>
                </ui-info-list-item>
            </mat-list>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="!isCoreOnlyCheck && featureFlagService.is('mona')">
        <ng-template mat-tab-label>
            <mat-icon>display_settings</mat-icon>
            {{ 'diagnostics.manualDiagnostics' | translate }}
        </ng-template>
        <ng-template matTabContent>
            <mat-list class="mona-diagnostics__list mona-diagnostics__list--manual">
                <mona-diagnostics-item-manual
                    class="mona-diagnostics__item"
                    (click)="checkRfidReader()"
                    [isChecked]="!!rfidTestSerial"
                    [checkLabel]="'diagnostics.checkRFID'"
                    [checkedLabel]="'diagnostics.rfidChecked'"
                    [checkedParams]="{ rfid: rfid }"
                    [notCheckedLabel]="'diagnostics.rfidNotChecked'"
                    data-testid="checkRfidReader"
                ></mona-diagnostics-item-manual>

                <mona-diagnostics-item-manual
                    class="mona-diagnostics__item"
                    (click)="checkCamera()"
                    [isChecked]="isCameraChecked"
                    [checkLabel]="'diagnostics.checkCamera'"
                    [checkedLabel]="'diagnostics.cameraChecked'"
                    [notCheckedLabel]="'diagnostics.cameraNotChecked'"
                    data-testid="checkCamera"
                ></mona-diagnostics-item-manual>

                <mona-diagnostics-item-manual
                    class="mona-diagnostics__item"
                    (click)="checkSound()"
                    [isChecked]="isSoundChecked"
                    [checkLabel]="'diagnostics.checkSound'"
                    [checkedLabel]="'diagnostics.soundChecked'"
                    [notCheckedLabel]="'diagnostics.soundNotChecked'"
                    data-testid="checkSound"
                ></mona-diagnostics-item-manual>
            </mat-list>
        </ng-template>
    </mat-tab>
</mat-tab-group>
