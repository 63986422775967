/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { WoundStatus } from '@mona/models';

export namespace WoundStatusAction {
    export const loadWoundStatus = createAction('ENCOUNTER:LOAD_WOUND_STATUS');
    export const loadWoundStatusSuccess = createAction(
        'ENCOUNTER:LOAD_WOUND_STATUS_SUCCESS',
        props<{ data: WoundStatus[] }>(),
    );
    export const loadWoundStatusFailure = createAction('ENCOUNTER:LOAD_WOUND_STATUS_FAILURE', props<{ error: any }>());

    export const updateWoundStatus = createAction(
        'ENCOUNTER:UPDATE_WOUND_STATUS',
        props<{ update: Update<WoundStatus>[] }>(),
    );

    export const upsertWoundStatus = createAction('ENCOUNTER:UPSERT_WOUND_STATUS', props<{ data: WoundStatus[] }>());

    export const clearWoundStatus = createAction('ENCOUNTER:CLEAR_WOUND_STATUS');

    export const addWoundStatusChanges = createAction(
        'ENCOUNTER:ADD_CHANGES',
        props<{ toUpdateEntities: WoundStatus[]; toRemoveIds: string[] }>(),
    );
}
