import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validator, Validators } from '@angular/forms';
import { environment } from '@environment';
import { distinctUntilChanged } from 'rxjs/operators';
import { PrescriptionFrequency, PrescriptionFrequencyTime, PrescriptionFrequencyTimesArray } from '@mona/models';
import { getFrequencyTimesByCode } from '@mona/pdms/data-access-combined';
import { convertLocalHourToUTCHour, convertUTCHoursToLocalTime } from '@mona/shared/date';
import { isEmpty, OnChange, SimpleChange, takeUntilDestroy, uiPure } from '@mona/shared/utils';
import { BaseCVACanDisable, CanDisable, CVA } from '@mona/ui';

const patternHHmm = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/; // 13:00
const patternHHmmss = /(?:\d|0\d|1\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/; // 13:00:00

export const FREQUENCY_TIMES_LIMIT = 12;

/** Frequency Times Control Component */
@Component({
    selector: 'mona-frequency-times',
    templateUrl: './frequency-times.component.html',
    styleUrls: ['./frequency-times.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['disabled'],
})
export class FrequencyTimesComponent
    extends BaseCVACanDisable
    implements CVA, CanDisable, Validator, OnInit, OnDestroy
{
    /**
     * Frequency code
     *
     * @listens SimpleChange and calls {@link resetValueAndValidity}
     */

    @OnChange('resetValueAndValidity')
    @Input()
    frequencyCode: PrescriptionFrequency['code'] = '';

    @OnChange('validateTimesInput')
    @Input()
    customTimes: string[] = undefined;

    /** patent obj created at */
    @Input() createdAt: string;

    @Input() frequencyTimesList: PrescriptionFrequencyTime[] = [];

    @Output() setCustomFrequency: EventEmitter<void> = new EventEmitter<void>();

    /** Benutzerdefiert in German */
    readonly customFrequencyCode = environment.customFrequencyCode;
    /** Bei Bedarf in German */
    readonly noRequiredFrequencyCode = environment.noRequiredFrequencyCode;
    /** Kontinuierlich in German */
    readonly continuousFrequencyCode = environment.continuousFrequencyCode;

    readonly customTimesLimit: number = environment.customFrequencyTimeLimit || FREQUENCY_TIMES_LIMIT;

    /** Internal FormGroup */
    readonly viewModelControl = new FormGroup<{
        timeInput: FormControl<string>;
        times: FormControl<string[]>;
    }>({
        timeInput: new FormControl<string>('', Validators.pattern(patternHHmm)),
        times: new FormControl(
            [],
            [Validators.required, Validators.minLength(1), Validators.maxLength(this.customTimesLimit)],
        ),
    });

    /**
     * Controls
     */
    get controls() {
        return this.viewModelControl.controls;
    }

    /**
     * Detects if times limit reached
     */
    get hasMaximumLength(): boolean {
        return this.customTimesLimit <= this.controls.times.value?.length;
    }

    /**
     * Set of conditions when btn should be disabled
     */
    get addBtnDisabled(): boolean {
        return !this.controls.timeInput.value || this.controls.timeInput.invalid || this.hasMaximumLength;
    }

    /**
     * Constructor
     *
     * @param cdRef
     */
    constructor(public cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

    /**
     * Lifecycle
     */
    ngOnInit(): void {
        super.ngOnInit();
        this.initComponentControl();
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function, jsdoc/require-jsdoc
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    /**
     * Validator that requires the length of the control's value to be greater than or equal
     * to the provided minimum length. See `Validators.minLength` for additional information.
     *
     * @param control
     */
    validate(control: AbstractControl<any, any>): ValidationErrors {
        if (!control.value || (isEmpty(control.value) && control.pristine)) {
            // don't validate empty values to allow optional controls
            // don't validate values without `length` property
            return null;
        }

        if (control.value.length < 1) {
            return { minlength: { requiredLength: 1, actualLength: control.value.length } };
        }

        if (control.value.length > this.customTimesLimit) {
            return { maxlength: { requiredLength: this.customTimesLimit, actualLength: control.value.length } };
        }

        return null;
    }

    /**
     * Validate custom times array to match pattern `HH:mm:00`
     *
     * @param value
     * @param change
     */
    validateTimesInput(value: string[], change?: SimpleChange<string[]>): void {
        if (!Array.isArray(value) || !value.length) {
            return;
        }

        if (value.some(time => !patternHHmmss.test(time))) {
            throw new Error(`Invalid time format: some of [${value}] doesn't match "HH:mm:00"`);
        }
    }

    /**
     * onDisabledChange
     *
     * @param disabled
     */
    onDisabledChange(disabled: boolean): void {
        if (disabled) {
            this.viewModelControl.disable({ emitEvent: false });
        } else {
            this.viewModelControl.enable({ emitEvent: false });
        }
    }

    /**
     * Add custom time
     *
     * @param event
     */
    addTime(event: SubmitEvent): void {
        event.preventDefault();
        event.stopPropagation();

        // convertUTCHoursToLocalTime;
        let time: string = convertLocalHourToUTCHour(this.controls.timeInput.value);
        const existingTimes: string[] = this.controls.times.value || [];

        if (!time || !patternHHmm.test(time)) {
            return;
        }

        this.setCustomFrequencyCode();

        // under the hood we are using hh:mm:ss time format because of BE
        time = time.replace(/(:\d*)/, ':00') + ':00';

        if (existingTimes.includes(time) || this.hasMaximumLength) {
            this.controls.timeInput.reset();
            return;
        }

        this.viewModelControl.setValue({
            timeInput: null,
            times: existingTimes.concat(time).sort(),
        });

        if (this.hasMaximumLength) {
            this.controls.timeInput.disable();
        }
    }

    /**
     * Remove custom time
     *
     * @param index
     * @param time
     */
    removeTime(index: number, time: string): void {
        this.setCustomFrequencyCode();
        this.controls.times.setValue(this.controls.times.value.filter((_, i) => i !== index));
    }

    /**
     * Reset times value if switched from custom frequency to a standard one
     *
     * Runs on frequencyCode change in `ngOnChanges` lifecycle hook
     *
     * @param value
     * @param simpleChange
     */
    resetValueAndValidity(value: string, simpleChange?: SimpleChange<string>): void {
        if (isEmpty(this.frequencyCode) && isEmpty(this.frequencyTimesList)) {
            return;
        }

        if (value === this.noRequiredFrequencyCode || value === this.continuousFrequencyCode) {
            this.controls.times.setValue(null);
        } else {
            this.controls.times.setValue(this.getTimesValue());
        }
    }

    /**
     * track by fn
     * @param index
     * @param item
     */
    trackByFn(index: number, item: string): string {
        return item;
    }

    /**
     * Subscribe to times changes
     */
    private initComponentControl(): void {
        this.controls.times.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroy(this)).subscribe(value => {
            this.writeValue(value);
            this.onTimesChanged(value);
        });
    }

    /**
     *  Get current times array
     *
     * @private
     */
    private getTimesValue(): PrescriptionFrequencyTimesArray {
        const standardFrequencyTimes = getFrequencyTimesByCode(this.frequencyCode, this.frequencyTimesList);
        return (!isEmpty(standardFrequencyTimes) && standardFrequencyTimes) || this.customTimes;
    }

    /**
     * Calculate if times limit reached
     * - disable input if limit reached
     * - set error if no times selected
     *
     * @param value string[]
     */
    private onTimesChanged(value: string[]): void {
        if (this.hasMaximumLength) {
            this.controls.timeInput.disable();
        } else {
            this.controls.timeInput.enable();
        }
        this.controls.times.updateValueAndValidity();
    }

    private setCustomFrequencyCode(): void {
        // if (this.frequencyCode !== this.customFrequencyCode) {
        this.setCustomFrequency.emit();
        // }
    }

    /**
     * convertUTCHoursToLocalTimeFn
     *
     * @param times
     */
    @uiPure
    convertUTCHoursToLocalTimeFn(times: string[]): string[] {
        return convertUTCHoursToLocalTime(times || [], this.createdAt);
    }
}
