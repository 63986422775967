import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UtilsModule } from '@mona/shared/utils';
import { UiSpacerModule } from '../utils';
import { AppBarComponent } from './app-bar.component';

/**
 * UiAppBarModule
 */
@NgModule({
    declarations: [AppBarComponent],
    imports: [UtilsModule, MatToolbarModule, UiSpacerModule, MatIconModule],
    exports: [AppBarComponent],
})
export class UiAppBarModule {}
