// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating Patient model structure.
 * @see #/components/schemas/Patient - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Patient {
    /**
     * The first or given name.
     *
     */
    first_name: string;
    /**
     * The last or family name.
     *
     */
    last_name: string;
    /**
     * The prefix to the name, such as \"Dr.\" or \"Prof.\".
     *
     */
    prefix?: string | null;
    /**
     * The patient number.
     *
     */
    patient_number: string;
    /**
     * Defines the gender fo the patient.  * `male` - MALE * `female` - FEMALE * `other` - OTHER * `unknown` - UNKNOWN
     *
     */
    gender: Patient.GenderEnum;
    /**
     * The date of birth (and time, if available) of the patient.
     * @dataFormat date-time
     */
    date_of_birth: string;
    /**
     * Whether or not the patient is deceased.
     *
     */
    deceased: boolean;
    /**
     * The date of death (if the `deceased` property is true).
     * @dataFormat date-time
     */
    date_of_death?: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Patient unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Patient
 */
export namespace Patient {
    export type GenderEnum = 'male' | 'female' | 'other' | 'unknown';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum,
        Other: 'other' as GenderEnum,
        Unknown: 'unknown' as GenderEnum,
    };

    /**
     * Patient Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The first or given name.
         * @type string (`dataFormat` is missing)
         */
        firstName: string;
        /**
         * The last or family name.
         * @type string (`dataFormat` is missing)
         */
        lastName: string;
        /**
         * The prefix to the name, such as \"Dr.\" or \"Prof.\".
         * @type string (`dataFormat` is missing)
         */
        prefix?: string | null;
        /**
         * The patient number.
         * @type string (`dataFormat` is missing)
         */
        patientNumber: string;
        /**
         * Defines the gender fo the patient.  * `male` - MALE * `female` - FEMALE * `other` - OTHER * `unknown` - UNKNOWN
         * @type string (`dataFormat` is missing)
         */
        gender: Patient.GenderEnum;
        /**
         * The date of birth (and time, if available) of the patient.
         * @dataFormat date-time
         */
        @TransformDate() dateOfBirth: Date;
        /**
         * Whether or not the patient is deceased.
         * @type boolean (`dataFormat` is missing)
         */
        deceased: boolean;
        /**
         * The date of death (if the `deceased` property is true).
         * @dataFormat date-time
         */
        @TransformDate() dateOfDeath?: Date;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Patient unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Patient DTO
     * Transforms Patient model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Patient model */
        static toModel(obj: Patient): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Patient model */
        static toApi(obj: Model): Patient {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Patient;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
