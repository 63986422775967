import { Pipe, PipeTransform } from '@angular/core';
import { ChangeLogEntry, ChangeLogModel, Diagnosis, DiagnosisRole } from '@mona/models';

/**
 * Resolves if diagnoses block by specific type has staged changes
 */
@Pipe({
    name: 'hasDiagnosisContentChanges',
})
export class HasDiagnosisContentChangesPipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param diagnosesChanges ChangeLogEntry<ChangeLogModel>[]
     * @param diagnoses EntityMap<Diagnosis>
     * @param type 'current' | 'progress'
     */
    transform(
        diagnosesChanges: ChangeLogEntry<ChangeLogModel>[],
        diagnoses: EntityMap<Diagnosis>,
        type: 'current' | 'progress',
    ): boolean {
        return !!diagnosesChanges?.find((change: ChangeLogEntry<Diagnosis>) => {
            // role of newly create diagnosis or existing one
            const role = change.payload?.role || diagnoses[change.modelId]?.role;
            return type === 'current' ? role === DiagnosisRole.AD : role !== DiagnosisRole.AD;
        });
    }
}
