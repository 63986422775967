import { InjectionToken } from '@angular/core';
import { Action, ActionReducerMap, createReducer, on } from '@ngrx/store';
import { ConfigType } from '../../models';
import * as ConfigActions from '../actions/config.actions';

export const configFeatureKey = 'config';

/**
 * Config State
 */
export interface ConfigState {
    /**
     * Config
     */
    config: ConfigType;
    /**
     * Loading
     */
    isLoading: boolean;
    /**
     * Error
     */
    error?: any;
}

export const initialState: ConfigState = {
    config: null,
    isLoading: false,
    error: null,
};

export const reducer = createReducer(
    initialState,

    on(ConfigActions.initConfig, ConfigActions.updateConfigSuccess, (state, { config }) => ({
        config: config as ConfigType,
        isLoading: false,
        error: null,
    })),
    on(ConfigActions.updateConfigFailure, (state, { error }) => ({ ...state, isLoading: false, error })),
);

export const CONFIG_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ConfigState>>('ConfigState reducer token');
