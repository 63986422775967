import { ApiModels } from '@mona/api';
import { TaskListFilter } from '@mona/models';

/**
 * Transforms api task list filter
 *
 * @param apiTaskListFilter
 */
export const transformApiTaskListFilter = (apiTaskListFilter: ApiModels.TaskListFilter): TaskListFilter => {
    return ApiModels.TaskListFilter.DTO.toModel(apiTaskListFilter);
};

/**
 * Transform api task list filters
 *
 * @param apiTaskListFilters any
 */
export const transformApiTaskListFilters = (apiTaskListFilters: ApiModels.TaskListFilter[]): TaskListFilter[] => {
    return apiTaskListFilters.map(apiTaskListFilter => transformApiTaskListFilter(apiTaskListFilter));
};
