import { EntityState } from '@ngrx/entity';
import { Practitioner } from '@mona/models';

export const practitionersFeatureKey = 'practitioners';

/**
 * Practitioners State
 */
export interface PractitionersState extends EntityState<Practitioner> {
    /**
     * selected id
     */
    selectedPractitionerId?: string | null;
    /**
     * error
     */
    error?: any;
    /**
     * loading
     */
    isLoading: boolean;
}
