import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reportsFeatureKey } from './entities';
import { PRACTITIONERS_REDUCER_TOKEN, ReportsEffects, reportsReducer } from './state';

/**
 * DataAccess Reports Module
 */
@NgModule({
    imports: [
        StoreModule.forFeature(reportsFeatureKey, PRACTITIONERS_REDUCER_TOKEN),
        EffectsModule.forFeature([ReportsEffects]),
    ],
    providers: [
        {
            provide: PRACTITIONERS_REDUCER_TOKEN,
            useFactory: () => reportsReducer,
        },
    ],
})
export class DataAccessReportsModule {
    /** @ignore */
    constructor(@Optional() @SkipSelf() parentModule: DataAccessReportsModule) {
        if (parentModule) {
            const msg = `DataAccessReportsModule has already been loaded.
                Import DataAccessReportsModule once, only, in the root AppModule.`;
            throw new Error(msg);
        }
    }
}
