/* eslint-disable jsdoc/require-jsdoc */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VitalSign } from '@mona/models';
import { groupBy } from '@mona/shared/utils';
import { encounterDataFeatureKey, EncounterFeatureState, VitalSignsMap, VitalSignsState } from '../../entities';
import { vitalSignsAdapter } from '../reducers';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectVitalsState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.vitalSigns,
);

export const {
    selectIds: selectAllVitalsIds,
    selectEntities: selectAllVitalsMap,
    selectAll: selectAllVitals,
    selectTotal: selectAllVitalsTotal,
} = vitalSignsAdapter.getSelectors(selectVitalsState);

export const selectAllVitalsMapByCode = createSelector(selectAllVitals, vitalSigns => {
    if (!vitalSigns?.length) {
        return {};
    }
    return groupBy(vitalSigns, v => v.code, null, null) as VitalSignsMap;
});

export const selectVitalsInterval = createSelector(selectVitalsState, state => state.interval);

export const selectVitalsStartDate = createSelector(selectVitalsState, (state: VitalSignsState) => state.startDate);

export const selectVitalsDateRange = createSelector(selectVitalsState, state => state.dateRange);

export const selectVitalsIsLoading = createSelector(selectVitalsState, state => state.isLoading);

export const selectVitalsViewMode = createSelector(selectVitalsState, state => state.viewMode);

export const selectVitalsCodes = createSelector(selectVitalsState, state => state.codes);

export const selectAllVitalsChanged = createSelector(selectAllVitals, vitalSigns =>
    vitalSigns.filter((v: VitalSign & { hasChanges?: boolean }) => !!v.hasChanges),
);

export const selectVitalSignsParametersPageableData = createSelector(
    selectVitalsState,
    (state: VitalSignsState) => state.pageableData,
);
