import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Ward } from '@mona/models';
import { transformApiWards } from './transforms';

/**
 * API abstraction layer for the wards API
 */
@Injectable({
    providedIn: 'root',
})
export class WardsApi {
    apiBase = '/pdms/wards/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get wards
     */
    getWards(): Observable<Ward[]> {
        return this.http.get<ApiModels.Ward[]>(this.apiBase).pipe(map(wards => transformApiWards(wards)));
    }
}
