// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { WorkflowUpdateItemRequest } from './workflowUpdateItemRequest';

/**
 * Serializer for validating and updating Workflow/Smart workflows.
 * @see #/components/schemas/WorkflowUpdateRequest - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowUpdateRequest {
    /**
     * The items of Workflow.
     *
     */
    questions: Array<WorkflowUpdateItemRequest>;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowUpdateRequest
 */
export namespace WorkflowUpdateRequest {
    /**
     * WorkflowUpdateRequest Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The items of Workflow.
         * @type Array<WorkflowUpdateItemRequest> (`dataFormat` is missing)
         */
        @Type(() => WorkflowUpdateItemRequest.Model) questions: Array<WorkflowUpdateItemRequest.Model>;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowUpdateRequest DTO
     * Transforms WorkflowUpdateRequest model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowUpdateRequest model */
        static toModel(obj: WorkflowUpdateRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowUpdateRequest model */
        static toApi(obj: Model): WorkflowUpdateRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowUpdateRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
