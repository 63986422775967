import { combineReducers } from '@ngrx/store';
import { bedsReducer } from './beds.reducer';
import { wardsReducer } from './wards.reducer';

export const WARDS_DATA_REDUCERS_TOKEN = 'wardsData';

export const wardsDataReducer = combineReducers({
    wards: wardsReducer,
    beds: bedsReducer,
});
