<!-- TODO: enable after App-level Alert -->
<!-- <ui-alerts>
    <ui-message
        #uiMessage
        *ngFor="let alert of alerts$ | async; let idx = index"
        [index]="idx"
        [id]="alert.id"
        [closable]="alert.closable"
        [type]="alert.type"
        [priority]="alert.priority"
        [title]="alert.title"
        [description]="alert.description"
        [icon]="alert.icon && alert.icon"
        (openedChange)="handleAlertClose(alert.id, alert.reason)"
        [attr.data-testid]="'uiAlert-' + idx"
    >
        <button
            *ngIf="alert.action"
            mat-stroked-button
            ui-message-action
            (click)="uiMessage.close()"
            [attr.data-testid]="'uiAlert-' + idx + '-action'"
        >
            {{ alert.action.title | translate }}
        </button>
    </ui-message>
</ui-alerts> -->
<!--  -->
<ui-drawer-layout
    *rxLet="layoutConfig$; let layoutConfig"
    [width]="layoutConfig?.size | uiMapper: getDrawerSize"
    [variant]="variant"
    [layoutConfig]="layoutConfig"
>
    <ui-drawer-navbar ui-navbar [items]="layoutConfig?.navbarItems" *rxIf="layoutConfig?.hasNavbar">
        <ui-user-menu
            *rxIf="user$; let user"
            ui-drawer-navbar-user
            [avatarImage]="user?.profileImageUrl"
            [avatarValue]="user?.profileImageUrl ? null : user?.firstName[0] + user?.lastName[0]"
            [open]="false"
        ></ui-user-menu>
    </ui-drawer-navbar>
    <ui-drawer ui-drawer [open]="state.open" [sideBorder]="sideBorder">
        <ui-drawer-header
            [show]="layoutConfig?.hasSidenavBar"
            [title]="layoutConfig?.title"
            [subtitle]="layoutConfig?.subtitle"
        >
            <button
                ui-title-buttons-left
                data-testid="ui-leftSidenav-drawerHeader-goBack"
                mat-icon-button
                (click)="goBack()"
                *rxIf="!layoutConfig.hasAppBar && layoutConfig?.canGoBack === 'sideNav'"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>
        </ui-drawer-header>
        <ui-drawer-body
            [title]="layoutConfig?.title"
            [subtitle]="layoutConfig?.subtitle"
            [items]="layoutConfig?.items"
            [size]="layoutConfig?.size"
            [background]="layoutConfig?.bgImageUrl"
            [selectedExternalResource$]="layoutConfig.selectedExternalResource$"
            [collapsible]="false"
        ></ui-drawer-body>
    </ui-drawer>
    <div ui-content id="ui-content" class="ui-drawer-layout-container scrollbar-visible">
        <!-- Main outlet -->
        <router-outlet></router-outlet>
    </div>
</ui-drawer-layout>

<div class="ui-alert-message" *rxLet="lastAlert$; let lastAlert">
    <ui-message
        *rxIf="!!lastAlert"
        [type]="lastAlert?.type"
        [title]="lastAlert?.title | translate"
        [description]="lastAlert?.description"
        [icon]="lastAlert?.icon"
        [closable]="false"
        class="global-encounter-state"
    ></ui-message>
</div>

<mona-rfid-emulator
    *ifFeatureFlag="':mona'"
    [enabled]="('emulateRFIDReader' | config) || isDevMode"
></mona-rfid-emulator>

<span style="position: fixed; height: 0px; width: 0px">
    <ui-dialog-container></ui-dialog-container>
</span>
