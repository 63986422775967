// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializes Notifications from database.
 * @see #/components/schemas/Notification - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Notification {
    /**
     * ID of Notification.
     * @dataFormat uuid
     */
    id: string;
    /**
     * Stream ID of Notification.
     * @dataFormat uuid
     */
    stream_id: string;
    /**
     * Source of Notification.
     *
     */
    source: string;
    /**
     * Title of Notification.
     *
     */
    title: string;
    /**
     * Text of Notification.
     *
     */
    text: string;
    /**
     * Action Type of Notification.
     *
     */
    action_type: string;
    /**
     * Navigate Action of Notification.
     *
     */
    navigate_action: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Notification
 */
export namespace Notification {
    /**
     * Notification Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * ID of Notification.
         * @dataFormat uuid
         */
        id: string;
        /**
         * Stream ID of Notification.
         * @dataFormat uuid
         */
        streamId: string;
        /**
         * Source of Notification.
         * @type string (`dataFormat` is missing)
         */
        source: string;
        /**
         * Title of Notification.
         * @type string (`dataFormat` is missing)
         */
        title: string;
        /**
         * Text of Notification.
         * @type string (`dataFormat` is missing)
         */
        text: string;
        /**
         * Action Type of Notification.
         * @type string (`dataFormat` is missing)
         */
        actionType: string;
        /**
         * Navigate Action of Notification.
         * @type string (`dataFormat` is missing)
         */
        navigateAction: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Notification DTO
     * Transforms Notification model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Notification model */
        static toModel(obj: Notification): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Notification model */
        static toApi(obj: Model): Notification {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Notification;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
