import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@mona/shared/utils';
import { UiDirectivesModule, UiInfoListItemModule } from '@mona/ui';
import { AddPrescriptionBarComponent } from './add-prescription-bar.component';

/** AddPrescriptionBar */
@NgModule({
    imports: [UtilsModule, UiDirectivesModule, UiInfoListItemModule, MatButtonModule, MatIconModule, MatCheckboxModule],
    exports: [AddPrescriptionBarComponent],
    declarations: [AddPrescriptionBarComponent],
})
export class AddPrescriptionBarModule {}
