import { EntityState } from '@ngrx/entity';
import { Bed, Encounter } from '@mona/models';

export const encountersFeatureKey = 'encountersData';

/**
 * Relocate encounter data for UI state handling
 */
export interface RelocateEncounterData {
    /**
     * Previous bed
     */
    fromBedId: EntityId<Bed>;
    /**
     * New bed
     */
    bedId: EntityId<Bed>;
    /**
     *  Encounter which is relocated
     */
    encounter: Encounter;
}

/**
 * Encounters state
 */
export interface EncountersState extends EntityState<Encounter> {
    /**
     * assigned encounter id
     */
    assignedEncounterId?: string | null;
    /**
     * selected id
     */
    selectedId: string | null;
    /**
     * error
     */
    error?: any;
    /**
     * Encounters is loading
     */
    isLoading: boolean;
    /**
     * Relocate encounter data
     */
    relocateEncounterData?: RelocateEncounterData;
}
