import { createAction, props } from '@ngrx/store';
import { CallSession } from '@mona/models';
import { AsyncAction, FailedAction, SucceededAction } from '@mona/store';

/**
 * Call actions
 */
export class CallActions {
    /**
     * Action to create a call session
     */
    static createSession: AsyncAction<void, CallSession> = {
        action: createAction('CALL:CREATE_SESSION'),

        succeededAction: createAction('CALL:CREATE_SESSION_SUCCEEDED', props<SucceededAction<CallSession>>()),

        failedAction: createAction('CALL:CREATE_SESSION_FAILED', props<FailedAction>()),

        clearAction: createAction('CALL:CREATE_SESSION_CLEAR'),
    };

    /**
     * Action to load a call session
     */
    static loadSession: AsyncAction<void, CallSession> = {
        action: createAction('CALL:LOAD_SESSION'),

        succeededAction: createAction('CALL:LOAD_SESSION_SUCCEEDED', props<SucceededAction<CallSession>>()),

        failedAction: createAction('CALL:LOAD_SESSION_FAILED', props<FailedAction>()),

        clearAction: createAction('CALL:LOAD_SESSION_CLEAR'),
    };
}
