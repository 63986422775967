import { NgModule } from '@angular/core';
import { ActiveCallDialogModule } from './active-call-dialog';
import { PipCallDialogModule } from './pip-call-dialog';

/**
 * Telemedicine Shared Module
 */
@NgModule({
    exports: [ActiveCallDialogModule, PipCallDialogModule],
})
export class TelemedicineSharedModule {}
