import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TherapyLimitations } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { TherapyLimitationsActions, TherapyLimitationsState } from '@mona/pdms/data-access-encounter-data';
import {
    selectTherapyLimitationsIsLoading,
    selectTherapyLimitationsAll,
    selectTherapyLimitationsEntities,
} from '../state';

/**
 * Notifications store service
 */
@Injectable({ providedIn: 'root' })
export class TherapyLimitationsFacade {
    /** Get therapy limitations */
    readonly therapyLimitations$: Observable<TherapyLimitations[]> = this.store.select(selectTherapyLimitationsAll);

    /** therapy limitations map */
    readonly therapyLimitationsMap$: Observable<Record<string, TherapyLimitations>> = this.store.select(
        selectTherapyLimitationsEntities,
    );

    /** is therapy limitations data loading */
    readonly isLoading$ = this.store.select(selectTherapyLimitationsIsLoading);

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService
     */
    constructor(private store: Store<TherapyLimitationsState>, private changeLogService: ChangeLogService) {}

    /** load therapy limitations data */
    loadTherapyLimitationsData(): void {
        this.store.dispatch(TherapyLimitationsActions.loadTherapyLimitations());
    }

    /**
     * INFO: add comment
     * @param therapyLimitation
     */
    createTherapyLimitation(therapyLimitation: Partial<TherapyLimitations>): void {
        this.changeLogService.createTherapyLimitation(therapyLimitation);
    }

    /**
     * INFO: add comment
     * @param therapyLimitation
     */
    updateTherapyLimitation(therapyLimitation: Partial<TherapyLimitations>): void {
        this.changeLogService.updateTherapyLimitation(therapyLimitation);
    }

    /**
     * INFO: add comment
     * @param id
     */
    removeTherapyLimitation(id: string): void {
        this.changeLogService.removeTherapyLimitation(id);
    }

    /**
     * Clear therapy limitations store
     */
    clearTherapyLimitations(): void {
        this.store.dispatch(TherapyLimitationsActions.clearTherapyLimitations());
    }
}
