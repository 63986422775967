/* eslint-disable @angular-eslint/directive-selector, jsdoc/require-jsdoc */
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

export class NgLetContext {
    $implicit: any = null;
    ngLet: any = null;
}
/**
 * We often use *ngIf="stream$ | async as stream" to subscribe to an observable property and
 * rename it to a template variable. But with nested template, *ngIf might remove your template which may not be expected.
 *
 * `*ngLet` just hold a reference to the result of `async` pipe in a template variable and
 * don't have any special logic like structure directives such as `*ngIf` or `*ngFor`
 * so it run faster and very handy.
 * You can also subscribe to multiple observable separately with `*ngLet` like this:
 *
 * @example ```html
 * <ng-container
 *   ngLet="{
 *         device: device$ | async,
 *         date: filterDate$ | async
 *       } as options">
 *   <pick-date
 *     [registeredAt]="options.device?.registeredAt"
 *     [firstDate]="options.date?.from"
 *     [secondDate]="options.date?.to"
 *   ></pick-date>
 * </ng-container>
 * ```
 */
@Directive({
    selector: '[ngLet]',
})
export class NgLetDirective implements OnInit {
    private _context = new NgLetContext();

    @Input()
    set ngLet(value: any) {
        this._context.$implicit = this._context.ngLet = value;
    }

    constructor(private _vcr: ViewContainerRef, private _templateRef: TemplateRef<NgLetContext>) {}

    ngOnInit() {
        this._vcr.createEmbeddedView(this._templateRef, this._context);
    }
}
