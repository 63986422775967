import { ApiModels } from '@mona/api';
import { PatientSex, Patient } from '@mona/models';

/**
 * Transforms API Encounter Patient
 *
 * @param apiPatient apiPatient
 */
export const transformApiEncounterPatient = (apiPatient: ApiModels.PatientInEncounter): Patient => {
    return {
        id: apiPatient.id,
        patientNumber: apiPatient.patient_number,
        prefix: apiPatient.prefix,
        firstName: apiPatient.first_name,
        lastName: apiPatient.last_name,
        dateOfBirth: new Date(apiPatient.date_of_birth),
        gender: apiPatient.gender as PatientSex,
    };
};

/**
 * Transforms API Patient
 *
 * @param apiPatient apiPatient
 */
export const transformApiPatient = (apiPatient: ApiModels.PatientSearch): Patient => {
    return ApiModels.PatientSearch.DTO.toModel(apiPatient);
};

/**
 * Transforms API Encounters
 *
 * @param apiPatients apiPatients
 */
export const transformApiEncounterPatients = (apiPatients: ApiModels.PatientInEncounter[]): Patient[] => {
    return apiPatients?.map(encounter => transformApiEncounterPatient(encounter));
};

/**
 * INFO: add comment
 *
 * @param apiPatients
 */
export const transformApiPatients = (apiPatients: ApiModels.PatientSearch[]): Patient[] => {
    return apiPatients?.map(patient => transformApiPatient(patient));
};
