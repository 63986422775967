import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { forwardRef, Inject, resolveForwardRef } from '@angular/core';
import { environment } from '@environment';
import { EMPTY } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { Logger } from '@mona/shared/logger';
import { stringify } from '@mona/shared/utils';
import { ConfigLoader } from '../models';

export const CONFIG_CONTEXT = new HttpContextToken<boolean>(() => false);
const CONFIG_ENDPOINT_TIMEOUT = 1000;

/**
 * Config Http Loader
 */
export class ConfigHttpLoader implements ConfigLoader {
    private logger: Logger = new Logger('CONFIG');
    /**
     * Constructor
     *
     * @param http
     * @param endpoint
     * @param projector
     */
    constructor(
        @Inject(forwardRef(() => HttpClient)) private readonly http: HttpClient,
        private readonly endpoint: string = '/',
        private readonly projector?: AnyFn,
    ) {}

    /**
     * Load settings
     */
    load(): Promise<any> {
        const http = resolveForwardRef(this.http);
        this.logger.log(`try load config from "${this.endpoint}"`);
        return http
            .get(this.endpoint, { context: new HttpContext().set(CONFIG_CONTEXT, true) })
            .pipe(
                timeout(CONFIG_ENDPOINT_TIMEOUT),
                map(res => (this.projector ? this.projector(res) : res)),
                tap(() => this.logger.log(`load config success from "${this.endpoint}"`)),
                catchError(error => {
                    const message = `load config error from "${this.endpoint}":`;
                    let details = stringify(error);
                    if (error.status === 0) {
                        details = `network is probably offline net::ERR_CONNECTION_REFUSED`;
                    }
                    if (error.name === 'TimeoutError') {
                        details = `no response in timeout of ${CONFIG_ENDPOINT_TIMEOUT}ms net::ERR_CONNECTION_TIMED_OUT`;
                    }
                    this.logger.error(message, details);
                    return EMPTY;
                }),
            )
            .toPromise();
    }
}
