/* eslint-disable no-var */
import pkg from '@package';

type Environment = any;

const { version, mona, dependencies } = pkg;

function _isNumberValue(value) {
    return !isNaN(parseFloat(value)) && !isNaN(Number(value));
}

function coerceNumber(value, fallbackValue = 0) {
    return _isNumberValue(value) ? Number(value) : fallbackValue;
}

function coerceBoolean(value) {
    return value != null && value !== 'false';
}

function coerceStringArray(value, separator: string | RegExp = /\s+/) {
    const result = [];
    if (value != null) {
        const sourceValues = Array.isArray(value) ? value : ``.split(separator);
        for (const sourceValue of sourceValues) {
            const trimmedString = ``.trim();
            if (trimmedString) {
                result.push(trimmedString);
            }
        }
    }
    return result;
}

/**
 * Default settings inherited by all environments
 *
 */
export const buildEnvironment = (): Environment => {
    return {
        platform: process.platform || 'aix',
        production: process.env.NODE_ENV === 'production',
        logLevel: coerceNumber(process.env.LOGGER_LEVEL, 5),
        versions: Object.assign({}, process.versions, { angular: dependencies['@angular/core'] }),
        appName: process.env.MONA_APP_NAME || mona.appName,
        manufacturerInformation: process.env.MONA_MANUFACTURER_INFORMATION || mona.manufacturerInformation,
        udiDi: process.env.MONA_UDI_DI || mona.udiDi,
        version,
        softwareVersion: process.env.MONA_OS_VERSION || mona.softwareVersion,
        buildNumber: process.env.BUILD_NUMBER || 'local',
        releaseDate: process.env.MONA_RELEASE_TIMESTAMP || mona.releaseDateUnixTimestamp,
        httpTimeout: 30000,
        healthCheckTimeout: 45000,
        reconnectInterval: coerceNumber(process.env.MONA_HTTP_TIMEOUT) || 5000,
        reconnectAttempts: coerceNumber(process.env.MONA_RECONNECT_ATTEMPTS) || 5,
        terminologyRefreshTime: coerceNumber(process.env.MONA_TERMINOLOGY_REFRESH_TIME) || 1,
        tableScrollStrategyType: process.env.MONA_TABLE_SCROLL_STRATEGY_TYPE || 'default',
        tableUseWorkerToProcessData: coerceBoolean(process.env.MONA_TABLE_USE_WORKER_TO_PROCESS_DATA),
        monaPdmsPatientPrefix: process.env.MONA_PDMS_PATIENT_PREFIX || 'm0na_',
        customFrequencyCode: 'fac33ecb-a967-4ba9-8986-2e19758209a5',
        noRequiredFrequencyCode: 'feadd04c-14ae-43f8-983c-71eaf431cd90',
        continuousFrequencyCode: '69e41769-f677-485b-9fe9-5704b18203a1',
        customFrequencyTimeLimit: 12,
        disableLockScreenAtStartup: false,
    };
};
