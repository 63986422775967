/* eslint-disable import/order */
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import type { DesktopCapturerSource, IpcRenderer } from 'electron';
import { fromEvent, Observable } from 'rxjs';
import { filter, finalize, share, shareReplay, switchMap } from 'rxjs/operators';
import { IpcMainEvent, IpcRendererEvent } from '@mona/events';
import { MonaRpcService } from './rpc.abstract.service';

declare global {
    interface Window {
        electron: {
            readonly process: Partial<NodeJS.Process>;
            readonly ipcRenderer: IpcRenderer;
            readonly heyMonaTransferResolve: () => void;
            readonly reload: AnyFn;
        };
    }
}

/**
 * Communicates to the electron shell
 */
@Injectable({ providedIn: 'root' })
export class MonaRpcElectronService extends MonaRpcService {
    /**
     * Communicate asynchronously from a renderer process to the main process.
     */
    protected readonly ipcRenderer: IpcRenderer;

    /**
     * Possible values: 'aix', 'darwin', 'freebsd', 'linux', 'openbsd', 'sunos', and 'win32'
     */
    readonly env: Record<string, string>;
    readonly platform: NodeJS.Platform;
    readonly versions: NodeJS.ProcessVersions;

    /**
     * Constructor
     *
     * @param window
     */
    constructor(@Inject(WINDOW) protected window: Window) {
        super(window);
        // Conditional imports
        if (this.getIsElectron()) {
            this.env = this.window.electron.process.env;
            this.platform = this.window.electron.process.platform;
            this.versions = this.window.electron.process.versions;
            this.ipcRenderer = this.window.electron.ipcRenderer;
        }
    }

    /**
     * Get desktop capturer sources
     *
     * @param opts
     */
    getDesktopCapturerSources(opts): Promise<DesktopCapturerSource[]> {
        return this.invoke<DesktopCapturerSource[]>(IpcMainEvent.DESKTOP_CAPTURER_GET_SOURCES, opts);
    }

    /**
     * Sends a fire and forget event to node.
     *
     * @template T Expected result type
     * @param event The event handler registered at the main thread
     * @param payload The payload to send to the event handler
     */
    send(event: IpcMainEvent, payload?: any) {
        // Catch calling from web instance
        this.ensurePlatform('send');

        // Call node
        this.ipcRenderer.send(event, payload);
    }

    /**
     * Calls node in a synchronous way. Warning: This is thread blocking!
     *
     * @template T Expected result type
     * @param event The event handler registered at the main thread
     * @param payload The payload to send to the event handler
     */
    sendSync<T>(event: IpcMainEvent, payload?: any): T {
        // Catch calling from web instance
        this.ensurePlatform('sendSync');

        // Call node
        return this.ipcRenderer.sendSync(event, payload);
    }

    /**
     * Calls node in an asynchronous
     *
     * @template T Expected result type
     * @param event The event handler registered at the main thread
     * @param payload The payload to send to the event handler
     */
    invoke<T>(event: IpcMainEvent, payload?: any): Promise<T> {
        // Catch calling from web instance
        this.ensurePlatform('invoke');

        // Call node
        return this.ipcRenderer.invoke(event, payload);
    }

    /**
     * Call custom invoke to recieve correct error object & stack
     *
     * @template T Expected result type
     * @param event The event handler registered at the main thread
     * @param payload The payload to send to the event handler
     */
    // tslint:disable-next-line: completed-docs
    invokeWithCustomErrors<T>(event: IpcMainEvent, payload?: any): Promise<T> {
        // Catch calling from web instance
        this.ensurePlatform('invoke');

        // Call custom invoke to handle error stack
        return this.ipcRenderer['invokeWithCustomErrors'](this.ipcRenderer, event, payload);
    }

    /**
     * Listens to events from the node main thread
     *
     * @deprecated Using MonaRpcService.listen might result in a thread blocking! Consider using invoke instead.
     * @param event The event that is expected from the main thread
     * @returns T Expected result type as Observable
     */
    listen<T>(event: IpcRendererEvent): Observable<T> {
        console.warn('Using MonaRpcService.listen might result in a thread blocking! Consider using invoke instead.');

        // Catch calling from web instance
        this.ensurePlatform('listen');

        // Convert node event into observable
        return new Observable<T>(observer => {
            this.startNodeListener<T>(event, payload => {
                observer.next(payload);
            });
        }).pipe(
            shareReplay(),
            finalize(() => {
                this.stopNodeListener(event);
            }),
        );
    }

    /**
     * Subscribe to Electron messaging
     *
     * @param eventName
     */
    onMessage<T>(eventName: IpcRendererEvent): Observable<T> {
        const inboundMessages$ = fromEvent<MessageEvent>(window, 'message').pipe(
            // event.source === window means the message is coming from the Electron preload script
            filter(event => event.source === window && event.data === eventName),
            switchMap(event => {
                const [port] = event.ports;
                // Once we have the port, we can communicate directly with the main process.
                return new Observable<T>(observer => {
                    port.onmessage = event => {
                        console.log('from main process:', event.data);
                        //   port.postMessage(event.data * 2)
                        observer.next(event.data);
                    };
                }).pipe(
                    share(),
                    finalize(() => {
                        // port.close()
                    }),
                );
            }),
            share(),
        );

        return inboundMessages$;
    }

    /**
     * Starts listening to events raised by the main thread.
     *
     * @template T Expected result type
     * @param event The event that is expected from the main thread
     * @param callback A callback method that should be executed upon an event
     */
    private startNodeListener<T>(event: IpcRendererEvent, callback: NodeListenerCallback<T>): void {
        // Catch calling from web instance
        this.ensurePlatform('startNodeListener');

        // Listen to node event
        this.ipcRenderer.on(event, (e, arg) => {
            callback(arg);
        });
    }

    /**
     * Stops listening to events raised by the main thread
     *
     * @param event Name of the event
     */
    private stopNodeListener(event: IpcRendererEvent) {
        // Catch calling from web instance
        this.ensurePlatform('stopNodeListener');

        // Call node
        this.ipcRenderer.removeAllListeners(event);
    }

    /**
     * Catch calling from web instance
     *
     * @param method The method name that was invoked
     */
    ensurePlatform(method: string): void {
        // Catch calling from web instance
        if (!this.getIsElectron()) {
            throw new Error(`${method} must be called when running in electron shell`);
        }
    }

    /**
     * Reloads the app
     * If we serve it locally app is defined, if it is a prod build it is remote.app
     */
    reload() {
        super.reload();
        // Make sure session storage is clear,
        // otherwise app-component detects a window refresh and does not navigate to start page
        this.window.electron.reload();
    }

    /**
     * Returns true if running in electron shell, otherwise false
     */
    private getIsElectron(): boolean {
        return !!this.window.electron;
    }
}
