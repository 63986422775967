import { ChangeLogModelKey } from '@mona/models';

export const EncounterMenuItemsChangelogMap: { [key: string]: ChangeLogModelKey[] } = {
    overview: [
        'Anamnesis',
        'AllergyIntolerance',
        'Diagnosis',
        'Patient',
        'PatientHistory',
        'PhysicalExamination',
        'PreMedication',
        'InfectionStatus',
        'WoundStatus',
        'SurgeryPrescription',
        'VaccinationStatus',
        'TherapyLimitations',
    ],
    taskList: ['MedicationAdministration', 'Procedure', 'DailyGoal'],
    vitalSigns: ['VitalSign'],
    ventilation: ['VentilationParameter', 'VentilationProcedure'],
    balance: ['BalanceTarget', 'MedicationAdministration', 'Output'],
    prescriptions: ['MedicationPrescription', 'ProcedurePrescription'],
    labValues: ['LabValue'],
    careReports: ['Procedure', 'BasicCare', 'EntryControl'],
    workflows: ['WorkflowQuestionnaire', 'WorkflowQuestionnaireResponse'],
};
