import { PractitionerRole } from './practitioner.model';

/**
 * Prescription Badge
 *
 * asterisk - A flag to show asterisk when prescription was changed
 * by doctor or changes where confirmed but not saved yet
 *
 * exclamation-mark - A flag to show icon when nurse created prescription
 * untill someone confirm correctness of prescriptions
 *
 * shield-check - A flag to show icon when prescription was approved and saved
 */
export type PrescriptionBadge = 'asterisk' | 'exclamation-mark' | 'shield-check';
/**
 * Prescription Badge Values
 */
export const PrescriptionBadgeValues: Record<string, PrescriptionBadge> = {
    asterisk: 'asterisk',
    exclamationMark: 'exclamation-mark',
    shieldCheck: 'shield-check',
};

/**
 * Prescription additional FE fields model
 */
export interface PrescriptionAdditionalFields {
    /**
     * FE only property if has changes
     */
    hasChanges?: boolean;
    /**
     * FE only property to have a last change by role
     */
    lastChangedByRole?: PractitionerRole;
    /**
     * FE only property to have a last change by role
     */
    lastChangedByName?: string;
    /**
     * Prescription badge
     */
    badge?: PrescriptionBadge;
}
