import { EntityState } from '@ngrx/entity';
import { Patient } from '@mona/models';

export const patientsAdmisionFeatureKey = 'patientsAdmissionData';

/**
 * Patients State
 */
export interface PatientsAdmissionState extends EntityState<Patient> {
    /**
     * selected id
     */
    selectedPatientId?: string | null;
    /**
     * error
     */
    error?: any;
    /**
     * loading
     */
    isLoading: boolean;
    /**
     * filter
     */
    filter: string;
}
