<form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [isEnabled]="isBedSideMode && isActive"
    (ngSubmit)="keyboardEnterClicked($event)"
>
    <div class="app-therapy-limitation-form__row">
        <mat-form-field
            class="app-input-field app-therapy-limitation-form__field"
            appearance="fill"
            data-testid="ff-therapyLimitationRejectedText"
        >
            <mat-label>{{ 'apps.admission.therapyLimitation.rejectedActions.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.rejectedActions"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-therapyLimitationRejectedTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.rejectedActions"></ui-validation-message>
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="app-input-field app-therapy-limitation-form__field"
            appearance="fill"
            data-testid="ff-therapyLimitationDocumented"
        >
            <mat-label>{{ 'apps.admission.therapyLimitation.documented.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.documented"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-therapyLimitationDocumentedTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.documented"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
