<fieldset [formGroup]="formGroup" class="pre-medication__form">
    <!-- use common mediaction-search component -->
    <div class="row">
        <mat-form-field class="app-input-field app-input-field--fullwidth" data-testid="ff-medication">
            <mat-label>
                {{ 'apps.patient.prescriptions.medication' | translate }}
            </mat-label>
            <app-medication-autocomplete
                #medicationAutocomplete
                [formControl]="controls.medication"
                [placeholder]="'apps.admission.preMedication.newItem.label' | translate"
                [allowNonExisting]="true"
                [skipCreateReal]="true"
                (autocompleteSelected)="onMedicationAutoCompleteSelect($event)"
                [searchBy]="'medications'"
                [required]="isRequired"
                [autoFocus]="true"
                data-testid="ff-medicationAutocomplete"
            ></app-medication-autocomplete>
            <mat-hint *ngIf="!controls.medication.disabled && !!!controls.medication.value">
                {{ 'apps.admission.preMedication.newItem.hint' | translate }}
            </mat-hint>
            <mat-error>
                <ui-validation-message [control]="controls.description"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row" [class.d-none]="!controls.medication.value">
        <!-- dose -->
        <mat-form-field class="app-input-field" data-testid="ff-dose">
            <mat-label>
                {{ 'apps.settings.prescriptionSet.medicationTable.doses' | translate }}
            </mat-label>
            <input
                matInput
                required
                matInputCommaDot
                class="app-set-medication__input"
                [placeholder]="'apps.settings.prescriptionSet.medicationTable.doses' | translate"
                [formControl]="controls.dose"
                data-testid="ff-doseInput"
                data-type="number"
            />
            <mat-error>
                <ui-validation-message [control]="controls.dose"></ui-validation-message>
            </mat-error>
        </mat-form-field>
        <!-- unit -->
        <mat-form-field class="app-input-field" appearance="fill" data-testid="ff-unit">
            <mat-label>
                {{ 'apps.settings.medications.customMedication.unit.label' | translate }}
            </mat-label>
            <input [formControl]="controls.unit" matInput required data-testid="ff-unitInput" />
            <mat-error>
                <ui-validation-message [control]="controls.unit"></ui-validation-message>
            </mat-error>
        </mat-form-field>
        <!-- freq -->
        <mat-form-field class="app-input-field" appearance="fill" data-testid="ff-frequency">
            <mat-label>
                {{ 'apps.settings.prescriptionSet.medicationTable.frequency' | translate }}
            </mat-label>
            <mat-select
                [formControl]="controls.frequency"
                [placeholder]="'apps.settings.prescriptionSet.medicationTable.frequency' | translate"
                required
                panelClass="app-input-select-panel scrollbar-visible"
                class="app-set-medication__select"
                data-testid="ff-frequencySelect"
            >
                <mat-option
                    *ngFor="let item of preMedicationFrequencies$ | async; trackBy: trackByCode"
                    [value]="item.code"
                    [attr.data-testid]="'ff-frequencySelect_' + item.code"
                >
                    {{ item.displayName }}
                </mat-option>
            </mat-select>
            <mat-error>
                <ui-validation-message [control]="controls.frequency"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
</fieldset>
