/* eslint-disable @typescript-eslint/ban-types */
import { InjectionToken } from '@angular/core';
import { fromEvent, interval, merge, NEVER, Observable, Subject, Subscription, SubscriptionLike, timer } from 'rxjs';
import { filter, map, repeat, takeUntil, throttleTime } from 'rxjs/operators';
import { Logger } from '@mona/shared/logger';
import { noop, stringify } from '../helpers';

/**
 * Idle Service as Token
 *
 * @description
 *
 * Some applications may need to detect if a user is idle and perform certain actions when * this happens like warning them about this inactivity or logging them out of the * application.
 */
export const IDLE = new InjectionToken<any>('IdleService', {
    providedIn: 'root',
    factory: () => new IdleService(),
});

/**
 * Idle Service
 *
 * @description
 *
 * Some applications may need to detect if a user is idle and perform certain actions when * this happens like warning them about this inactivity or logging them out of the * application.
 *
 *
 * Usage
 * @example```typescript
 * import { IdleService, IdleEvents } from '@mona/shared/utils';
 *
 * idleService.configure({
 *   msToIdle: 10 * 60 * 1000, // 10 minutes
 *   secToTimeout: 5, // notify before timeout
 *   autoResume: true,
 *   listenFor: 'click mousemove',
 * });
 *
 * idleService.on(IdleEvents.UserIsBack, () => {
 *   console.log('User is back!');
 * });
 *
 * idleService.on(IdleEvents.UserHasTimedOut, () => {
 *   console.log('User has timed out!');
 * });
 *
 * idleService.on(IdleEvents.TimeoutWarning, countdown => {
 *   console.log(`User has ${countdown} seconds to come back!`);
 * });
 *
 * idleService.on(IdleEvents.UserIsIdle, () => {
 *   console.log('User has become idle!');
 * });
 *
 * idleService.on(IdleEvents.UserIsActive, () => {
 *   console.log('User is active');
 * });
 *
 * idleService.start();
 * ```
 *
 * Configuration
 *
 * You can configure the service to change the default timers and other options by calling * the configure method.
 * @example```typescript
 *
 * idleService.configure({
 *   msToIdle: 10,
 *   secToTimeout: 5,
 *   autoResume: true,
 *   listenFor: 'click mousemove',
 * });
 * ```
 */
export class IdleService {
    private _state = new IdleState();
    /** Represents the current state of the user. */
    get state(): IdleState {
        return this._state;
    }
    /** Default options for the idle service. */
    private options: IdleOptions = {
        enabled: true,
        msToIdle: 10 * 60000,
        secToTimeout: 30,
        autoResume: true,
        listenFor: 'keydown mousewheel mousedown touchstart touchmove scroll',
    };
    private userIsActive$: Observable<{}> = NEVER;
    private interruptions$: Observable<{}> = NEVER;
    private idleTimer$: Observable<number> = NEVER;
    private userInactivityTimer$: Observable<number> = NEVER;
    private timedOut$ = new Subject<null>();
    private subscriptions: SubscriptionLike[] = [];
    private eventEmitter$ = new Subject<IdleServiceEvent>();

    private logger: Logger = new Logger('MONA::IdleService');

    /**
     * Resets the service.
     */
    reset = this.start;

    /** @ignore */
    constructor() {
        this.rebuildObservables(this.options);
    }

    /**
     * Configures the service with the given parameters.
     *
     * @param options New options to configure the service. You can just pass the needed keys.
     */
    configure(options: Partial<IdleOptions>): void {
        this.options = { ...this.options, ...options };
        this.rebuildObservables(this.options);
        this.logDebug('configured', this.options);
    }

    /**
     * Starts watching user activity.
     */
    start(): void {
        if (!this.options.enabled) {
            return;
        }

        if (this.state.isRunning) {
            this.stop();
        }

        this.eventEmitter$.next(new IdleServiceEvent(IdleEventEnum.UserIsIdle));

        this._state.isRunning = true;

        this.subscriptions.push(
            this.userIsActive$.subscribe(event =>
                this.eventEmitter$.next(new IdleServiceEvent(IdleEventEnum.UserIsActive)),
            ),
            this.userInactivityTimer$.subscribe(val => {
                this._state.userInactivityTime = val + 1;
            }),
            this.idleTimer$.pipe().subscribe(() => {
                this.eventEmitter$.next(new IdleServiceEvent(IdleEventEnum.UserIsIdle));
                this.startTimeoutCountdown();
            }),
        );

        this.logDebug('started');
    }

    /**
     * Pause service
     */
    pause(): void {
        this._state.paused = true;
        this.logDebug('paused');
    }

    /**
     * Resume service
     */
    resume(): void {
        this._state.paused = false;
        this.logDebug('resume');
    }

    /**
     * Stops the service from running.
     * Service can be restarted by calling the start() method.
     *
     * @param timedOut Specifies if the service was stopped because of the user being timedout.
     */
    stop(timedOut = false): void {
        if (!this.options.enabled || !this.state.isRunning) {
            return;
        }
        this._state.isRunning = false;
        this._state.isIdle = false;
        this._state.hasTimedout = timedOut;
        this.timedOut$.next();
        this.unsubscribeAll();

        this.logDebug('stopped');
    }

    /**
     * Starts the timeout countdown.
     * If the user performs a valid action, the countdown stops.
     */
    startTimeoutCountdown(): void {
        if (!this.options.enabled) {
            return;
        }
        this._state.isIdle = true;
        let countdown = this.options.secToTimeout;
        interval(1000)
            .pipe(
                filter(() => {
                    return !this.state.paused;
                }),
                takeUntil(this.interruptions$),
            )
            .subscribe({
                next: () => {
                    countdown--;
                    this.eventEmitter$.next(new IdleServiceEvent(IdleEventEnum.TimeoutWarning, countdown));

                    if (countdown == 0) {
                        this.stop(true);
                        this.eventEmitter$.next(new IdleServiceEvent(IdleEventEnum.UserHasTimedOut));
                    }
                },
                error: noop,
                complete: () => {
                    if (this.state.isIdle) {
                        this._state.isIdle = false;
                        this.eventEmitter$.next(new IdleServiceEvent(IdleEventEnum.UserIsBack));
                    }

                    if (!this.options.autoResume) {
                        this.unsubscribeAll();
                    }
                },
            });
    }

    /**
     * Listens to a particular idle service event.
     *
     * @param eventType Event to listen to.
     * @param action What the event listener should do when the event is triggered.
     */
    on(eventType: IdleEventEnum, action: (value) => void): Subscription {
        return this.eventEmitter$
            .pipe(
                filter(event => event.eventType === eventType),
                map(event => event.value),
            )
            .subscribe(action);
    }

    /**
     * Builds the needed observables for the service.
     * This includes timers and event listeners.
     *
     * @param IdleOptions
     */
    private rebuildObservables({ enabled, listenFor, msToIdle }: IdleOptions): void {
        if (!enabled || msToIdle <= 0) {
            return;
        }
        const observables = listenFor.split(' ').map(ev => fromEvent(document, ev).pipe(throttleTime(500)));
        this.userIsActive$ = merge(...observables);
        this.interruptions$ = merge(this.userIsActive$, this.timedOut$);
        this.idleTimer$ = timer(msToIdle).pipe(takeUntil(this.userIsActive$), repeat());
        this.userInactivityTimer$ = interval(1000).pipe(takeUntil(this.interruptions$), repeat());
    }

    /**
     * Removes all the current observable subscriptions.
     */
    private unsubscribeAll(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private logDebug(action: string, data?: any) {
        this.logger.log(action, stringify(data || this.state));
    }
}

/**
 * Creates a new idle service event.
 */
export class IdleServiceEvent {
    /**
     * @param eventType Name of the event.
     * @param value Any value to pass as part of the event.
     */
    constructor(public eventType: IdleEventEnum, public value?: any) {}
}

/**
 * Idle Events Enum
 */
export enum IdleEventEnum {
    UserIsActive,
    UserIsIdle,
    UserIsBack,
    TimeoutWarning,
    UserHasTimedOut,
}

/**
 * Specifies the options for the idle service.
 */
export interface IdleOptions {
    /** Should be enabled */
    enabled: boolean;
    /** Inactive time in miliseconds that the user needs to be considered idle.  */
    msToIdle: number;
    /** Inactive time in seconds needed for the user to be considered timed out *AFTER* the user has been considered idle.   */
    secToTimeout: number;
    /** Specifies if the service should auto resume itself after the user is considered idle.  */
    autoResume: boolean;
    /**
     * listenFor DOM events to listen for the user to be considered active.
     *
     * @default 'keydown mousewheel mousedown touchstart touchmove scroll'
     */
    listenFor: string;
}

/**
 * Represents the current state of the user.
 */
export class IdleState {
    /**
     *
     * @param isRunning Specifies if the service is running.
     * @param isIdle Specifies if the user is idle.
     * @param hasTimedout Specifies if the user has timed out.
     * @param paused Specifies if the timer should prevented by any blocker.
     * @param userInactivityTime Number of seconds the user has been inactive.
     */
    constructor(
        public isRunning = false,
        public isIdle = false,
        public hasTimedout = false,
        public paused = false,
        public userInactivityTime = 0,
    ) {}
}
