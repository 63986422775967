import { CustomApiModels } from '@mona/api';
import { CallSession, CallStatus } from '@mona/models';

/**
 * Transforms api call session
 *
 * @param apiCallSession CustomApiModels.CallSession
 */
export const transformApiCallSession = (apiCallSession: CustomApiModels.CallSession): CallSession => {
    return {
        id: apiCallSession.id,
        status: apiCallSession.status as CallStatus,
        internalParticipantName: null,
        externalParticipantName: apiCallSession.external_participant_name,
    };
};
