import { SetOptional } from 'type-fest';
import { ApiModels } from '@mona/api';
import { BaseMedication } from '@mona/models';

/**
 * Transforms api medication prescription
 *
 * @param apiMedication ApiModels.MedicationPrescription
 */
export const transformApiStandardMedication = (apiMedication: ApiModels.StandardMedicationSet): BaseMedication => {
    return ApiModels.StandardMedicationSet.DTO.toModel(apiMedication) as unknown as BaseMedication;
};

/**
 * Transforms api medication
 *
 * @param payload
 */
export const transformToApiStandardMedications = (
    payload: SetOptional<BaseMedication, 'id'>,
): ApiModels.StandardMedicationSet => {
    const apiModel = ApiModels.StandardMedicationSet.DTO.toApi(payload as any) as ApiModels.StandardMedicationSet;
    return apiModel;
};

/**
 * Transform api medication prescriptions
 *
 * @param apiMedications ApiModels.MedicationPrescription[]
 */
export const transformApiStandardMedications = (
    apiMedications: ApiModels.StandardMedicationSet[],
): BaseMedication[] => {
    return apiMedications.map(apiMedication => transformApiStandardMedication(apiMedication));
};
