<div class="ui-dynamic-datepicker-wrapper">
    <mat-form-field
        [attr.id]="name"
        class="mat-form-field-type-ui-date-picker"
        [ngClass]="customClass"
        [appearance]="appearance"
    >
        <mat-label *ngIf="label">{{ label }}</mat-label>
        <ui-date-picker
            [touchUi]="touchUi"
            [name]="name"
            [formControl]="control"
            [value]="control?.value"
            [placeholder]="placeholder || label"
            [required]="required"
            [min]="min"
            [max]="max"
        ></ui-date-picker>
        <mat-hint>{{ hint }}</mat-hint>
        <mat-error>
            <ng-template
                [ngTemplateOutlet]="errorMessageTemplate"
                [ngTemplateOutletContext]="{ control: control, errors: control?.errors }"
            ></ng-template>
            <ui-validation-message [control]="control"></ui-validation-message>
        </mat-error>
    </mat-form-field>
</div>
