// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating AllergyIntolerance model structure.
 * @see #/components/schemas/AllergyIntolerance - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AllergyIntolerance {
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * AllergyIntolerance value.
     *
     */
    allergenic: string;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * AllergyIntolerance unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AllergyIntolerance
 */
export namespace AllergyIntolerance {
    /**
     * AllergyIntolerance Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * AllergyIntolerance value.
         * @type string (`dataFormat` is missing)
         */
        allergenic: string;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * AllergyIntolerance unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AllergyIntolerance DTO
     * Transforms AllergyIntolerance model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AllergyIntolerance model */
        static toModel(obj: AllergyIntolerance): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AllergyIntolerance model */
        static toApi(obj: Model): AllergyIntolerance {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AllergyIntolerance;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
