<ng-container *ngIf="dateRange">
    <button
        mat-icon-button
        class="ui-calendar-slider__control"
        [disabled]="!previousEnabled()"
        (click)="previousClicked()"
        data-testid="calendar-btn-previous"
        [attr.title]="'commonStrings.keys.prevPageLabel' | translate"
    >
        <mat-icon>arrow_left</mat-icon>
    </button>
    <div class="ui-calendar-slider__list-wrapper" (contextmenu)="openDatePicker($event)">
        <ul class="ui-calendar-slider__list" [ngStyle]="getTranslateX(activeDateIndex)">
            <li class="ui-calendar-slider__list-item">
                <ng-container *ngTemplateOutlet="cell; context: { $implicit: startDay }"></ng-container>
            </li>
            <!-- INFO: without `trackBy` to properly detect `active` style -->
            <li *ngFor="let day of calendarCells$ | async" class="ui-calendar-slider__list-item">
                <ng-container *ngTemplateOutlet="cell; context: { $implicit: day }"></ng-container>
            </li>
            <li class="ui-calendar-slider__list-item">
                <ng-container *ngTemplateOutlet="cell; context: { $implicit: endDay }"></ng-container>
            </li>
        </ul>
        <ng-container *ngIf="useDatePicker">
            <input
                matInput
                [matDatepicker]="datepicker"
                [value]="activeDate"
                (dateChange)="activeDate = $event.value"
                [min]="dateRange.start"
                [max]="dateRange.end"
                style="visibility: hidden; width: 0; height: 0"
            />
            <mat-datepicker #datepicker xPosition="end" yPosition="below" [dateClass]="dateClass">
                <!-- TODO: if needed -->
                <!-- <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" (click)="todayClicked()">Today</button>
                </mat-datepicker-actions> -->
            </mat-datepicker>
        </ng-container>
    </div>
    <button
        mat-icon-button
        class="ui-calendar-slider__control"
        [disabled]="!nextEnabled()"
        (click)="nextClicked()"
        data-testid="calendar-btn-next"
        [attr.title]="'commonStrings.keys.nextPageLabel' | translate"
    >
        <mat-icon>arrow_right</mat-icon>
    </button>
</ng-container>

<ng-template #cell let-day="$implicit">
    <a
        uiCalendarCellDef
        [uiCalendarCell]="day"
        [ngClass]="day.cssClasses"
        (click)="cellClicked(day, $event)"
        [attr.disabled]="!day.enabled"
        [attr.title]="day.rawValue | date"
        [attr.data-testid]="'calendar-day-' + day.value"
    >
        <!-- <mat-icon class="ui-calendar-slider__day-badge cdk-visually-hidden" svgIcon="alert-octagon"></mat-icon> -->
        <!-- <span class="ui-calendar-slider__day-asterisk cdk-visually-hidden">&nbsp;*</span> -->
        <div class="ui-calendar-slider__day-number">{{ day.rawValue | date: 'dd' }}</div>
        <div class="ui-calendar-slider__day-title">{{ day.rawValue | date: 'EEEEEE' }}</div>
    </a>
</ng-template>
