<div class="mona-sign-in-rfid">
    <div class="mona-sign-in-rfid__text">{{ 'lockScreen.rfid.scanBadge' | translate }}</div>

    <div class="mona-sign-in-rfid__splitter">{{ 'lockScreen.rfid.or' | translate }}</div>

    <button class="mona-sign-in-rfid__btn" (click)="openSignInDialog.emit()" [disabled]="isLoading$ | async">
        {{ 'lockScreen.rfid.btn.login-with-credentials' | translate }}
    </button>

    <div class="mona-sign-in-rfid__icon">
        <mat-spinner
            *ngIf="isLoading$ | async; else scanIcon"
            class="mona-sign-in-rfid__icon__spinner"
            diameter="56"
        ></mat-spinner>

        <ng-template #scanIcon>
            <img alt="arrows-down" src="assets/icons/arrows-down.svg" class="mona-sign-in-rfid__icon__arrows" />
        </ng-template>
    </div>
</div>
