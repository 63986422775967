import { FormControl, FormGroup } from '@angular/forms';
import { PrescriptionNotGivenReason } from '@mona/models';

/**
 * Prescription not given reason
 */
export type NotGivenReasonForm = FormGroup<{
    status: FormControl<boolean>;
    reason: FormControl<PrescriptionNotGivenReason>;
    reasonAdditional: FormControl<string>;
}>;

/**
 * Prescription not given reason
 */
export type NotGivenReason = {
    status: boolean;
    reason: PrescriptionNotGivenReason;
    reasonAdditional: string;
};

/** Build prescription not given reason form */
export const buildNotGivenReasonControls = (): NotGivenReasonForm => {
    return new FormGroup<any>({
        status: new FormControl<boolean>(false),
        reason: new FormControl<PrescriptionNotGivenReason>(null),
        reasonAdditional: new FormControl<string>(null),
    });
};
