import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UiMessage } from '@mona/ui';
import { MessageActions } from '../actions';
import { MessagesState } from '../state';

export const messagesFeatureKey = 'messages';

export const messagesAdapter: EntityAdapter<UiMessage> = createEntityAdapter<UiMessage>({
    // sortComparer: (a, b) => a.id < b.id
});

const initialState: MessagesState = messagesAdapter.getInitialState({
    selectedId: null,
});

export const messageReducer = createReducer(
    initialState,
    on(MessageActions.addMessage, (state, action) => messagesAdapter.addOne(action.message, state)),
    on(MessageActions.selectMessage, (state, action) => ({ ...state, selectedId: action.id })),
    on(MessageActions.upsertMessage, (state, action) => messagesAdapter.upsertOne(action.message, state)),
    on(MessageActions.addMessages, (state, action) => messagesAdapter.addMany(action.messages, state)),
    on(MessageActions.upsertMessages, (state, action) => messagesAdapter.upsertMany(action.messages, state)),
    on(MessageActions.updateMessage, (state, action) => messagesAdapter.updateOne(action.message, state)),
    on(MessageActions.updateMessages, (state, action) => messagesAdapter.updateMany(action.messages, state)),
    on(MessageActions.deleteMessage, (state, action) => messagesAdapter.removeOne(action.id, state)),
    on(MessageActions.deleteMessages, (state, action) => messagesAdapter.removeMany(action.ids, state)),
    on(MessageActions.loadMessages, (state, action) => messagesAdapter.setAll(action.messages, state)),
    on(MessageActions.clearMessages, (state, action) => {
        const mIds: string[] = Object.entries(state.entities)
            .filter(([, m]) => m.priority !== 0)
            .map(([id]) => id);
        return action.all ? messagesAdapter.removeAll(state) : messagesAdapter.removeMany(mIds, state);
    }),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = messagesAdapter.getSelectors();
