import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { IpcMainEvent } from '@mona/events';
import { ConnectionStatusType, ManualConnectionSettings, WifiEntry } from '@mona/models';
import { MonaRpcService } from '@mona/rpc';
import { SyncStorageService, SYNC_STORAGE } from '@mona/shared/utils';
import { handleEffectError } from '@mona/store';
import { NetworkManagerActions } from '../actions';

/**
 * Network manager effects
 */
@Injectable()
export class NetworkManagerEffects implements OnInitEffects {
    /**
     * Returns active network device information
     */
    getMacAddress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkManagerActions.getMacAddress),
            // switchMap(() => this.appRef.isStable),
            switchMap(() => from(this.rpcService.invoke<string>(IpcMainEvent.GET_MAC_ADDRESS))),
            map(macAddress => NetworkManagerActions.getMacAddressSuccess({ macAddress })),
        ),
    );

    /**
     * Persist device id
     */
    getMacAddressSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NetworkManagerActions.getMacAddressSuccess),
                tap(({ macAddress }) => this.storage.setItem('mona.device_id', macAddress)),
            ),
        { dispatch: false },
    );

    /**
     * Load wifi endpoints effect
     */

    loadWifiEndpoints$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkManagerActions.loadWifiEndpoints.action),
            switchMap(() =>
                from(this.rpcService.invoke<WifiEntry[]>(IpcMainEvent.SCAN_WIFI)).pipe(
                    switchMap((result: WifiEntry[]) => {
                        return [
                            NetworkManagerActions.loadWifiEndpoints.succeededAction({
                                payload: result,
                            }),
                        ];
                    }),
                    handleEffectError(NetworkManagerActions.loadWifiEndpoints.failedAction, null, true),
                ),
            ),
        ),
    );

    /**
     * Connect to wifi effect
     */

    connectToWifiEndpoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkManagerActions.connectToWifiEndpoint.action),
            switchMap(({ ssid, passphrase }) => {
                return from(
                    this.rpcService.invoke<ConnectionStatusType>(IpcMainEvent.CONNECT_WIFI, {
                        ssid,
                        passphrase,
                    }),
                );
            }),
            switchMap((result: string) => {
                return [
                    NetworkManagerActions.connectToWifiEndpoint.succeededAction({
                        payload: result,
                    }),
                ];
            }),
            handleEffectError(NetworkManagerActions.connectToWifiEndpoint.failedAction, null, true),
        ),
    );

    /**
     * Load manuel connection settings effect
     */

    loadManualConnectionSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkManagerActions.loadManualConnectionSettings.action),
            switchMap(({ deviceType }) =>
                from(
                    this.rpcService.invoke<ManualConnectionSettings>(IpcMainEvent.GET_NETWORK_SETTINGS, deviceType),
                ).pipe(
                    switchMap((result: ManualConnectionSettings) => {
                        return [
                            NetworkManagerActions.loadManualConnectionSettings.succeededAction({
                                payload: result,
                            }),
                        ];
                    }),
                    handleEffectError(NetworkManagerActions.loadManualConnectionSettings.failedAction, null, true),
                ),
            ),
        ),
    );

    /**
     * Set Manual Connection
     */
    setManualConnection$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NetworkManagerActions.setManualConnection),
                tap(({ data }) => this.rpcService.invoke<boolean>(IpcMainEvent.SET_MANUAL_CONNECTION, data)),
            ),
        { dispatch: false },
    );

    /**
     * Returns the manual connection settings
     */
    getManualConnectionSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkManagerActions.getManualConnectionSettings),
            switchMap(({ connectionType }) =>
                from(
                    this.rpcService.invoke<ManualConnectionSettings>(IpcMainEvent.GET_NETWORK_SETTINGS, connectionType),
                ),
            ),
            map(manualConnectionSettings =>
                NetworkManagerActions.getManualConnectionSettingsSuccess({ manualConnectionSettings }),
            ),
        ),
    );

    /**
     * Returns active network device information
     */
    getNetworkDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkManagerActions.getNetworkDetails),
            switchMap(() => from(this.rpcService.invoke<string>(IpcMainEvent.GET_NETWORK_DETAILS))),
            map(details => NetworkManagerActions.getNetworkDetailsSuccess({ details })),
        ),
    );

    /**
     * Invoke reset DHCP
     */
    invokeResetDHCP$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NetworkManagerActions.invokeResetDHCP),
                tap(() => this.rpcService.invoke<boolean>(IpcMainEvent.RESET_DHCP)),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param rpcService
     * @param storage
     */
    constructor(
        private actions$: Actions,
        private rpcService: MonaRpcService,
        @Inject(SYNC_STORAGE) private storage: SyncStorageService,
    ) {}

    /** Lifecycle */
    ngrxOnInitEffects(): Action {
        return NetworkManagerActions.getMacAddress();
    }
}
