import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PageableModel, VentilationParameter, VentilationProcedure } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    ChangeLogState,
    getChangesData,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { Logger } from '@mona/shared/logger';
import { VentilationParametersApi, VentilationProceduresApi } from '../../infrastructure';
import { VentilationAction } from '../actions';
import { selectVentilationParameters, selectVentilationProcedures } from '../selectors';

/**
 * Ventilation effects
 */
@Injectable()
export class VentilationEffects {
    private logger = new Logger();
    /**
     * Load ventilation parameters effect
     */
    loadVentilationParameters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VentilationAction.loadVentilationParameters),
            withCurrentEncounterId(),
            switchMap(([{ params, url }, encounter_id]) =>
                this.ventilationParametersApi.getPageableVentilationParameters({ ...params, encounter_id }, url).pipe(
                    catchError(error => {
                        this.logger.error('Error loading VentilationParameter', error);
                        return of({ results: [], codes: [] } as PageableModel<VentilationParameter>);
                    }),
                ),
            ),
            concatLatestFrom(() => this.store$.select(ChangeLogSelectors.getChangesMap)),
            map(([data, changesMap]) => {
                const changes = changesMap['VentilationParameter'] || [];

                if (changes.length) {
                    data.results = applyInstancesChanges<VentilationParameter>(data.results, changes, true);
                }

                return VentilationAction.loadVentilationParametersSuccess(data);
            }),
            catchError(error => of(VentilationAction.loadVentilationParametersFailed({ error }))),
        ),
    );

    /**
     * Load ventilation procedures effect
     */
    loadVentilationProcedures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VentilationAction.loadVentilationProcedures),
            withCurrentEncounterId(),
            concatMap(([, encounter_id]) =>
                this.ventilationProceduresApi.getVentilationProcedures(encounter_id).pipe(
                    catchError(error => {
                        this.logger.error('Error loading VentilationProcedure', error);
                        return of([]);
                    }),
                ),
            ),
            concatLatestFrom(() => this.store$.select(ChangeLogSelectors.getChangesMap)),
            map(([data, changesMap]) => {
                const changes = changesMap['VentilationProcedure'] || [];
                if (changes.length) {
                    data = applyInstancesChanges<VentilationProcedure>(data, changes);
                }
                return VentilationAction.loadVentilationProceduresSuccess({ data });
            }),
            catchError(error => of(VentilationAction.loadVentilationProceduresFailed({ error }))),
        ),
    );

    /** Listen save change success & add one - realistic update */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(
                    this.store$.select(selectVentilationParameters),
                    this.store$.select(selectVentilationProcedures),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, parameters, procedures, changesMap]) => {
                    const parameterChanges = changesMap['VentilationParameter'] || [];
                    const procedureChanges = changesMap['VentilationProcedure'] || [];

                    if (parameterChanges.length) {
                        const data = getChangesData(parameters, parameterChanges);
                        this.store$.dispatch(VentilationAction.upsertVentilationParameters({ data }));
                    }
                    if (procedureChanges.length) {
                        const data = getChangesData(procedures, procedureChanges);
                        this.store$.dispatch(VentilationAction.upsertVentilationProcedures({ data }));
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onPersistChangeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                    this.store$.select(selectVentilationProcedures),
                    this.store$.select(selectVentilationParameters),
                ),
                tap(([, changesMap, procedures, parameters]) => {
                    const parameterChanges = changesMap['VentilationParameter'] || [];

                    if (parameterChanges.length) {
                        const { toRemove, toUpdate } = getPersistedChangesData(parameters, parameterChanges);

                        this.store$.dispatch(
                            VentilationAction.addParameterChanges({
                                toUpdateEntities: toUpdate || [],
                                toRemoveIds: toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }

                    const procedureChanges = changesMap['VentilationProcedure'] || [];

                    if (procedureChanges.length) {
                        const { toRemove, toUpdate } = getPersistedChangesData(procedures, procedureChanges);

                        this.store$.dispatch(
                            VentilationAction.addProcedureChanges({
                                toUpdateEntities: toUpdate || [],
                                toRemoveIds: toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param ventilationParametersApi VentilationParametersApi
     * @param ventilationProceduresApi VentilationProceduresApi
     * @param store$
     */
    constructor(
        private actions$: Actions,
        private ventilationParametersApi: VentilationParametersApi,
        private ventilationProceduresApi: VentilationProceduresApi,
        private store$: Store<{ changelogData: ChangeLogState }>,
    ) {}
}
