/* eslint-disable @angular-eslint/no-inputs-metadata-property, @angular-eslint/directive-selector, @angular-eslint/no-outputs-metadata-property */
import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import type {
    ECharts,
    EChartsOption,
    init as initFnType,
    Payload,
    registerTheme as registerThemeFnType,
    SetOptionOpts,
} from 'echarts';
import { asyncScheduler, Observable, Subject, Subscription } from 'rxjs';
import { switchMap, throttleTime } from 'rxjs/operators';
import { Get } from 'type-fest';
import { Logger } from '@mona/shared/logger';
import { get } from '@mona/shared/utils';
import {
    UiEchartsConfig,
    UiEChartsInitOpts,
    UiEchartsOption,
    UiEchartsScaleTick,
    UiEchartsSeriesOption,
    UiEchartsThemeOption,
    UI_ECHARTS_CONFIG,
    UI_ECHARTS_THEME_CONFIG,
} from '../models';
import { ChangeFilterV2 } from '../utils';

/**
 * Angular directive for [Apache ECharts (incubating)](https://github.com/apache/incubator-echarts)
 */
@Directive({
    selector: 'echarts, [echarts]',
    exportAs: 'echarts',
    standalone: false,
})
export class UiEchartsDirective implements OnChanges, OnDestroy, OnInit, AfterViewInit {
    @Input() options: EChartsOption | null = null;
    @Input() theme: string | UiEchartsThemeOption | null = null;
    @Input() initOpts: UiEChartsInitOpts | null = null;
    @Input() merge: EChartsOption | null = null;
    @Input() overrideTicks = false;
    @Input() set ticks(values: UiEchartsScaleTick[] | null) {
        this._ticks = values ?? [];
        this._ticks?.unshift({ value: values?.[0]?.value || new Date().getTime() });
    }
    @Input() dataZoomWindow: {
        startValue?: number | string | Date;
        endValue?: number | string | Date;
    } = {};
    @Input() autoResize = true;
    @Input() loading = false;
    @Input() loadingType = 'default';
    @Input() loadingOpts: object | null = {
        text: '',
        showSpinner: false,
        zlevel: 1,
    };
    @Input() noDataLabel = 'No Data';

    // ngx-echarts events
    @Output() chartInit = new EventEmitter<any>();
    @Output() optionsError = new EventEmitter<Error>();

    // echarts mouse events
    @Output() chartClick = this.createLazyEvent('click');
    @Output() chartDblClick = this.createLazyEvent('dblclick');
    @Output() chartMouseDown = this.createLazyEvent('mousedown');
    @Output() chartMouseMove = this.createLazyEvent('mousemove');
    @Output() chartMouseUp = this.createLazyEvent('mouseup');
    @Output() chartMouseOver = this.createLazyEvent('mouseover');
    @Output() chartMouseOut = this.createLazyEvent('mouseout');
    @Output() chartGlobalOut = this.createLazyEvent('globalout');
    @Output() chartContextMenu = this.createLazyEvent('contextmenu');

    // echarts mouse events
    @Output() chartLegendSelectChanged = this.createLazyEvent('legendselectchanged');
    @Output() chartLegendSelected = this.createLazyEvent('legendselected');
    @Output() chartLegendUnselected = this.createLazyEvent('legendunselected');
    @Output() chartLegendScroll = this.createLazyEvent('legendscroll');
    @Output() chartDataZoom = this.createLazyEvent('datazoom');
    @Output() chartDataRangeSelected = this.createLazyEvent('datarangeselected');
    @Output() chartTimelineChanged = this.createLazyEvent('timelinechanged');
    @Output() chartTimelinePlayChanged = this.createLazyEvent('timelineplaychanged');
    @Output() chartRestore = this.createLazyEvent('restore');
    @Output() chartDataViewChanged = this.createLazyEvent('dataviewchanged');
    @Output() chartMagicTypeChanged = this.createLazyEvent('magictypechanged');
    @Output() chartPieSelectChanged = this.createLazyEvent('pieselectchanged');
    @Output() chartPieSelected = this.createLazyEvent('pieselected');
    @Output() chartPieUnselected = this.createLazyEvent('pieunselected');
    @Output() chartMapSelectChanged = this.createLazyEvent('mapselectchanged');
    @Output() chartMapSelected = this.createLazyEvent('mapselected');
    @Output() chartMapUnselected = this.createLazyEvent('mapunselected');
    @Output() chartAxisAreaSelected = this.createLazyEvent('axisareaselected');
    @Output() chartFocusNodeAdjacency = this.createLazyEvent('focusnodeadjacency');
    @Output() chartUnfocusNodeAdjacency = this.createLazyEvent('unfocusnodeadjacency');
    @Output() chartBrush = this.createLazyEvent('brush');
    @Output() chartBrushEnd = this.createLazyEvent('brushend');
    @Output() chartBrushSelected = this.createLazyEvent('brushselected');
    @Output() chartRendered = this.createLazyEvent('rendered');
    @Output() chartFinished = this.createLazyEvent('finished');

    public animationFrameID = null;
    private _chart: ECharts;
    /**
     * ECharts instance
     */
    get chart(): ECharts {
        return this._chart;
    }
    private echarts: any;
    private themesRegistred = false;
    private resizeOb: ResizeObserver;
    private resize$ = new Subject<void>();
    private resizeSub: Subscription;
    private initChartTimer?: number;
    private changeFilter = new ChangeFilterV2();
    private logger = new Logger('UI:CHART');
    private _ticks: UiEchartsScaleTick[] | null = [];

    /**
     * Constructor
     *
     * @param config
     * @param el
     * @param ngZone
     */
    constructor(
        @Inject(UI_ECHARTS_CONFIG) private config: UiEchartsConfig,
        private el: ElementRef,
        private ngZone: NgZone,
    ) {
        this.echarts = config.echarts;
    }

    /**
     * Lifecycle
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        this.changeFilter.doFilter(changes);
    }

    /**
     * Lifecycle
     */
    ngOnInit() {
        if (!window.ResizeObserver) {
            throw new Error('please install a polyfill for ResizeObserver');
        }
        this.resizeSub = this.resize$
            .pipe(throttleTime(100, asyncScheduler, { leading: false, trailing: true }))
            .subscribe(() => this.resize());

        if (this.autoResize) {
            this.resizeOb = this.ngZone.runOutsideAngular(
                () =>
                    new window.ResizeObserver(() => {
                        this.animationFrameID = window.requestAnimationFrame(() => this.resize$.next());
                    }),
            );
            this.resizeOb.observe(this.el.nativeElement);
        }

        this.changeFilter.notFirstAndEmpty<any>('options', opt => this.onOptionsChange(opt));
        this.changeFilter.notFirstAndEmpty<any>('merge', opt => this.setOption(opt));
        this.changeFilter.notFirstAndEmpty<any>('ticks', opt => this.updateTicks(opt));
        this.changeFilter.notFirstAndEmpty<any>('dataZoomWindow', opt => this.updateDataZoomWindow(opt));
        this.changeFilter.has<boolean>('loading', v => this.toggleLoading(!!v));
        this.changeFilter.has<string>('theme', v => this.toggleDarkMode(v as any));
    }

    /**
     * Lifecycle
     */
    ngOnDestroy() {
        window.clearTimeout(this.initChartTimer);
        if (this.resizeSub) {
            this.resizeSub.unsubscribe();
        }
        if (this.animationFrameID) {
            window.cancelAnimationFrame(this.animationFrameID);
        }
        if (this.resizeOb) {
            this.resizeOb.unobserve(this.el.nativeElement);
        }
        this.changeFilter.dispose();
        this.dispose();
    }

    /**
     * Lifecycle
     */
    ngAfterViewInit() {
        this.initChartTimer = window.setTimeout(() => this.initChart());
    }

    /**
     * dispose
     */
    dispose() {
        if (this.chart) {
            if (!this.chart.isDisposed()) {
                this.chart.dispose();
            }
            this._chart = null;
        }
    }

    /**
     * resize chart
     */
    resize() {
        if (this.chart) {
            this.chart.resize();
        }
    }

    /**
     * toggleLoading
     *
     * @param loading
     */
    toggleLoading(loading: boolean) {
        if (this.chart) {
            loading ? this.chart.showLoading(this.loadingType, this.loadingOpts) : this.chart.hideLoading();
        }
    }

    /**
     * Get chart option partial by path
     * @param path
     */
    getOption(path: string): UiEchartsOption | UiEchartsOption[] {
        if (this.chart) {
            return get(this.chart.getOption(), path) as UiEchartsOption;
        }
        return undefined;
    }

    /**
     * Usage:
     * chart.setOption(option, notMerge, lazyUpdate);
     * chart.setOption(option, {
     *     notMerge: ...,
     *     lazyUpdate: ...,
     *     silent: ...
     * });
     *
     * @param option
     * @param opts opts or notMerge.
     * @param opts.notMerge Default `false`.
     * @param opts.lazyUpdate Default `false`. Useful when setOption frequently.
     * @param opts.silent Default `false`.
     * @param opts.replaceMerge Default undefined.
     */
    setOption<Opt extends EChartsOption>(option: Opt, opts?: boolean | SetOptionOpts): void {
        if (this.chart) {
            try {
                this.chart.setOption(option, opts as any);
                this.logger.debug('setOption', option);
            } catch (e) {
                this.logger.error(e);
                this.optionsError.emit(e);
            }
        }
    }

    /**
     * @param payload
     * @param opt If pass boolean, means opt.silent
     * @param opt.silent Default `false`. Whether trigger events.
     * @param opt.flush Default `undefined`.
     *        true: Flush immediately, and then pixel in canvas can be fetched
     *            immediately. Caution: it might affect performance.
     *        false: Not flush.
     *        undefined: Auto decide whether perform flush.
     */
    dispatchAction(
        payload: Payload,
        opt?:
            | boolean
            | {
                  silent?: boolean;
                  flush?: boolean | undefined;
              },
    ): void {
        if (this.chart) {
            this.chart.dispatchAction(payload, opt);
        }
    }

    /**
     * Update chart ticks -- force
     * @param values
     */
    updateTicks(values: UiEchartsScaleTick[]) {
        if (values?.length && this.overrideTicks && this.chart) {
            this.ticks = values;
        }
    }

    /**
     * Update data zoom window
     */
    updateDataZoomWindow({ startValue, endValue }) {
        if (!startValue || !endValue) {
            return;
        }
        if (this.chart) {
            this.chart.dispatchAction(
                {
                    type: 'dataZoom',
                    startValue,
                    endValue,
                },
                { silent: false },
            );
        }
    }

    /**
     * Toggle dark mode (for grid and elements, but also for series which have areaStyle.color)
     * @param theme
     */
    toggleDarkMode(theme: 'dark' | 'light') {
        if (theme) {
            const themeConfig = UI_ECHARTS_THEME_CONFIG[theme];
            const series = (this.getOption('series') as unknown as UiEchartsSeriesOption[])?.map(so => {
                // find series wich we need to tggle background to match chart background
                // this should be 1 series per pair, with opacity 1
                if (so.areaStyle?.color && so.areaStyle.opacity === 1) {
                    so.areaStyle.color = theme === 'dark' ? '#373267' : 'white';
                }
                return so;
            });
            this.logger.debug('toggleDarkMode', theme, series);
            this.setOption({ ...themeConfig, series });
        }
    }

    /**
     * dispose old chart and create a new one.
     */
    async refreshChart() {
        this.logger.debug('refreshChart');
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { startValue, endValue }: UiEchartsOption = (this.getOption('dataZoom') as UiEchartsOption[])?.[0];
        this.dispose();
        await this.initChart();
        this.updateDataZoomWindow({ startValue, endValue });
    }

    private createChart() {
        const dom = this.el.nativeElement;

        if (window && window.getComputedStyle) {
            const prop = window.getComputedStyle(dom, null).getPropertyValue('height');
            if ((!prop || prop === '0px') && (!dom.style.height || dom.style.height === '0px')) {
                dom.style.height = '400px';
            }
        }

        // here a bit tricky: we check if the echarts module is provided as function returning native import('...') then use the promise
        // otherwise create the function that imitates behaviour above with a provided as is module
        return this.ngZone.runOutsideAngular(() => {
            const load = typeof this.echarts === 'function' ? this.echarts : () => Promise.resolve(this.echarts);

            return load().then(
                ({ init, registerTheme }: { init: typeof initFnType; registerTheme: typeof registerThemeFnType }) => {
                    if (!this.themesRegistred) {
                        try {
                            registerTheme('dark', UI_ECHARTS_THEME_CONFIG.dark);
                            registerTheme('light', UI_ECHARTS_THEME_CONFIG.light);
                            this.logger.log('registerTheme success');
                        } catch (error) {
                            this.logger.log('registerTheme error');
                        }
                    }

                    return init(dom, this.theme, this.initOpts);
                },
            );
        });
    }

    private async initChart() {
        await this.onOptionsChange(this.options);

        if (this.merge && this.chart) {
            this.setOption(this.merge);
        }
    }

    private overrideChart() {
        if (this.overrideTicks && this.chart) {
            const xAxis = this._chart['getModel']().getComponent('xAxis', 0);
            const scale = xAxis?.axis?.scale;
            if (!scale) {
                return;
            }
            scale.__proto__.getTicks = () => {
                return this._ticks;
            };
            this.logger.debug('overrideChart:ticks', this.ticks);
        }
    }

    private async onOptionsChange(opt: any) {
        if (!opt) {
            return;
        }

        if (this.chart) {
            this.setOption(this.options, true);
        } else {
            this._chart = await this.createChart();
            this.chartInit.emit(this.chart);
            this.setOption(this.options, true);
            this.overrideChart();
        }
    }

    // allows to lazily bind to only those events that are requested through the `@Output` by parent components
    // see https://stackoverflow.com/questions/51787972/optimal-reentering-the-ngzone-from-eventemitter-event for more info
    private createLazyEvent<T>(eventName: Get<Parameters<ECharts['on']>, '[0]'>): EventEmitter<T> {
        return this.chartInit.pipe(
            switchMap(
                (chart: any) =>
                    new Observable(observer => {
                        chart.on(eventName, (data: T) => this.ngZone.run(() => observer.next(data)));
                        return () => {
                            if (this.chart) {
                                if (!this.chart.isDisposed()) {
                                    chart.off(eventName);
                                }
                            }
                        };
                    }),
            ),
        ) as EventEmitter<T>;
    }
}
