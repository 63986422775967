<form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [isEnabled]="isBedSideMode && isActive"
    (keydown.enter)="keyboardEnterClicked.emit()"
>
    <div *ngIf="formGroup.controls.admissionType" class="app-anamnesis-form__row">
        <mat-form-field
            class="app-input-field app-anamnesis-form__field"
            appearance="fill"
            data-testid="ff-admissionType"
        >
            <mat-label>{{ 'apps.admission.anamnesis.typeLabel' | translate }}</mat-label>
            <mat-select
                [formControl]="formGroup.controls.admissionType"
                required
                panelClass="app-input-select-panel scrollbar-visible"
                data-testid="ff-admissionTypeSelect"
            >
                <mat-option
                    *ngFor="let type of admissionTypeOptions"
                    [value]="type.value"
                    [attr.data-testid]="'uiAdmissionTypeSelect_' + type.value"
                >
                    {{ type.labelKey | translate }}
                </mat-option>
            </mat-select>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.admissionType"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="app-anamnesis-form__row">
        <mat-form-field
            class="app-input-field app-anamnesis-form__field app-anamnesis-form__textarea"
            appearance="fill"
            data-testid="ff-anamnesisText"
        >
            <mat-label>{{ 'apps.admission.anamnesis.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.text"
                required
                [cdkAutosizeMaxRows]="textareaMaxRows"
                matInput
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-anamnesisTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.text"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
