<mat-dialog-content class="app-procedure-dialog__content">
    <div>
        <h2 mat-dialog-title class="app-procedure-dialog__title">
            {{ data.title | translate }}
        </h2>
    </div>

    <div class="app-procedure-dialog__text" *ngIf="data.description">
        <h4 class="app-procedure-dialog__text-title">
            {{ 'apps.patient.careReports.procedureDialog.description' | translate }}
        </h4>
        <p class="app-procedure-dialog__text-content">
            {{ data.description | translate }}
        </p>
    </div>

    <div class="app-procedure-dialog__text" *ngIf="data.instruction">
        <h4 class="app-procedure-dialog__text-title">
            {{ 'apps.patient.careReports.procedureDialog.instruction' | translate }}
        </h4>
        <p class="app-procedure-dialog__text-content">
            {{ data.instruction }}
        </p>
    </div>

    <form [formGroup]="procedureForm">
        <div class="app-procedure-dialog__row">
            <mat-form-field
                class="app-input-field app-procedure-dialog__field app-procedure-dialog__field--duration"
                appearance="fill"
            >
                <mat-label>{{ 'apps.patient.careReports.procedureDialog.duration' | translate }}</mat-label>
                <mat-select
                    [formControl]="procedureForm.controls.durationMinutes"
                    panelClass="app-input-select-panel scrollbar-visible"
                >
                    <mat-option [value]="''">N/A</mat-option>
                    <mat-option *ngFor="let duration of durationsOptions" [value]="duration.value">
                        {{ duration.name }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    <ui-validation-message [control]="procedureForm.controls.durationMinutes"></ui-validation-message>
                </mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="app-input-field app-procedure-dialog__field" appearance="fill">
            <mat-label>{{ 'apps.patient.careReports.procedureDialog.note' | translate }}</mat-label>
            <textarea
                class="app-procedure-dialog__note"
                [formControl]="procedureForm.controls.note"
                matInput
            ></textarea>
        </mat-form-field>
    </form>

    <ng-container *ngIf="data.procedure?.lastChangedBy">
        <div
            *ngIf="(practitioners$ | async)[data.procedure.lastChangedBy] as practitioner"
            class="app-procedure-dialog__last-change"
        >
            {{
                'apps.patient.careReports.procedureDialog.lastChange'
                    | translate
                        : {
                              date: (data.procedure.lastChangedAt | date: 'MMMM d, h:mm'),
                              practitioner: practitioner.displayName
                          }
            }}
        </div>
    </ng-container>

    <div class="app-procedure-dialog__actions">
        <button mat-button class="app-procedure-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'apps.patient.careReports.procedureDialog.cancel' | translate }}
        </button>
        <button color="primary" mat-raised-button (click)="submitChange()">
            {{ 'apps.patient.careReports.procedureDialog.save' | translate }}
        </button>
    </div>
</mat-dialog-content>
