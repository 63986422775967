// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { VitalSign } from './vitalSign';
import { PatientInEncounter } from './patientInEncounter';
import { Stay } from './stay';

/**
 * Serializer for validating Encounter model structure.
 * @see #/components/schemas/Encounter - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Encounter {
    /**
     * The identifying description of the patient\'s reason for the encounter.
     *
     */
    admission_diagnosis: string;
    /**
     * The identifying description of the patient\'s discharge reason for the encounter.
     *
     */
    discharge_reason?: string;
    /**
     * The id of the encounter\'s patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The case id of the child encounter
     *
     */
    readonly case_id: string;
    /**
     * The id of the ward that is currently associated to this encounter
     * @dataFormat uuid
     */
    ward_id: string;
    /**
     * The id of the bed that is currently associated to this encounter
     * @dataFormat uuid
     */
    bed_id: string;
    /**
     * Whether the encounter is active or not
     *
     */
    readonly active: boolean;
    /**
     * Whether the encounter needs to go through admission
     *
     */
    readonly needs_admission: boolean;
    /**
     * The datetime when the encounter starts.
     * @dataFormat date-time
     */
    start_date: string;
    /**
     * The related patient if `include_patient` is set to true
     *
     */
    patient?: PatientInEncounter | null;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Encounter unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The datetime when the encounter ends.
     * @dataFormat date-time
     */
    end_date?: string;
    /**
     * List of patient stays
     *
     */
    readonly stays_list: Array<Stay>;
    /**
     * Height and weight for encounter
     *
     */
    readonly vital_signs: Array<VitalSign>;
    /**
     * The case id of the parent encounter
     *
     */
    readonly hospital_case_id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Encounter
 */
export namespace Encounter {
    /**
     * Encounter Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The identifying description of the patient\'s reason for the encounter.
         * @type string (`dataFormat` is missing)
         */
        admissionDiagnosis: string;
        /**
         * The identifying description of the patient\'s discharge reason for the encounter.
         * @type string (`dataFormat` is missing)
         */
        dischargeReason?: string;
        /**
         * The id of the encounter\'s patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The case id of the child encounter
         * @type string (`dataFormat` is missing)
         */
        readonly caseId: string;
        /**
         * The id of the ward that is currently associated to this encounter
         * @dataFormat uuid
         */
        wardId: string;
        /**
         * The id of the bed that is currently associated to this encounter
         * @dataFormat uuid
         */
        bedId: string;
        /**
         * Whether the encounter is active or not
         * @type boolean (`dataFormat` is missing)
         */
        readonly active: boolean;
        /**
         * Whether the encounter needs to go through admission
         * @type boolean (`dataFormat` is missing)
         */
        readonly needsAdmission: boolean;
        /**
         * The datetime when the encounter starts.
         * @dataFormat date-time
         */
        @TransformDate() startDate: Date;
        /**
         * The related patient if `include_patient` is set to true
         * @type PatientInEncounter (`dataFormat` is missing)
         */
        @Type(() => PatientInEncounter.Model) patient?: PatientInEncounter.Model | null;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Encounter unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The datetime when the encounter ends.
         * @dataFormat date-time
         */
        @TransformDate() endDate?: Date;
        /**
         * List of patient stays
         * @type Array<Stay> (`dataFormat` is missing)
         */
        @Type(() => Stay.Model) readonly staysList: Array<Stay.Model>;
        /**
         * Height and weight for encounter
         * @type Array<VitalSign> (`dataFormat` is missing)
         */
        @Type(() => VitalSign.Model) readonly vitalSigns: Array<VitalSign.Model>;
        /**
         * The case id of the parent encounter
         * @type string (`dataFormat` is missing)
         */
        readonly hospitalCaseId: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Encounter DTO
     * Transforms Encounter model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Encounter model */
        static toModel(obj: Encounter): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Encounter model */
        static toApi(obj: Model): Encounter {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Encounter;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
