import { Host, Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { isString } from '@mona/shared/utils';
import { UiTemplateRegistry } from './template-registry';

/**
 * UiTemplateRefPipe
 */
@Pipe({
    name: 'uiTemplateRef',
})
export class UiTemplateRefPipe implements PipeTransform {
    /**
     * Constructor
     *
     * @param registry
     */
    constructor(private registry: UiTemplateRegistry) {}

    /**
     * Returns template if found
     *
     * @param templateName
     */
    transform(templateName: string | TemplateRef<any> | undefined): TemplateRef<any> | undefined {
        if (isString(templateName)) {
            return this.registry.templates.get(templateName);
        }
        return templateName;
    }
}
