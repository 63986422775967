import { NgModule } from '@angular/core';
import { ConversationInputModule } from './conversation-input';
import { ConversationMessagesModule } from './conversation-messages';
import { ConversationModeModule } from './conversation-mode-toggle';
import { ConversationOptionsModule } from './conversation-options';
import { HeyMonaButtonModule } from './hey-mona-button';
import { RealTimeSpeechModule } from './real-time-speech';
import { SmartLabsTableModule } from './smart-labs-table';
import { SuggestionsModule } from './suggestions';

/**
 * INFO: add comment
 */
@NgModule({
    exports: [
        ConversationInputModule,
        ConversationMessagesModule,
        ConversationModeModule,
        ConversationOptionsModule,
        HeyMonaButtonModule,
        RealTimeSpeechModule,
        SuggestionsModule,
        SmartLabsTableModule,
    ],
})
export class VoiceSharedModule {}
