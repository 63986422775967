import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { DischargeReasonDialogComponent } from './discharge-reason-dialog.component';

/**
 * Discharge reason dialog module
 */
@NgModule({
    declarations: [DischargeReasonDialogComponent],
    exports: [DischargeReasonDialogComponent],
    imports: [UiModule, KeyboardModule],
})
export class DischargeReasonDialogModule {}
