import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ValuablesType } from '@mona/models';
import { ValuablesState } from '../../entities';
import { ValuablesAction } from '../actions';

export const valuablesAdapter: EntityAdapter<ValuablesType> = createEntityAdapter<ValuablesType>();

export const initialState: ValuablesState = valuablesAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const valuablesReducer = createReducer(
    initialState,
    on(ValuablesAction.loadValuables, state => ({ ...state, isLoading: true })),
    on(ValuablesAction.loadValuablesSuccess, (state, { data }) =>
        valuablesAdapter.setAll(data, {
            ...state,
            error: null,
            isLoading: false,
        }),
    ),
    on(ValuablesAction.loadValuablesFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(ValuablesAction.updateValuables, (state, action) => valuablesAdapter.updateMany(action.update, state)),
    on(ValuablesAction.upsertValuables, (state, action) => valuablesAdapter.upsertMany(action.data, state)),
    on(ValuablesAction.clearValuables, state => valuablesAdapter.removeAll(state)),
    on(ValuablesAction.addValuablesChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = valuablesAdapter.removeMany(toRemoveIds, state);

        return valuablesAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
