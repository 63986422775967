<nav
    #mtnb
    class="ui-tab-bar__nav"
    mat-tab-nav-bar
    [color]="color"
    [tabPanel]="tabPanel"
    [disablePagination]="true"
    [class.ui-tab-bar__nav--stretched]="stretched"
    [class.ui-tab-bar__nav--stepper]="stepper"
>
    <ng-template ngFor let-tab let-idx="index" let-last="last" let-first="first" [ngForOf]="tabs">
        <div *ngIf="stepper && !first" class="mat-stepper-horizontal-line"></div>
        <a
            mat-tab-link
            #mtl="matTabLink"
            [routerLink]="tab.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="tab.link ? rla.isActive : idx === tabIndex"
            (isActiveChange)="onActiveChange(tab, idx, rla.isActive)"
            queryParamsHandling="preserve"
            (click)="tabLinkClick(tab, idx)"
            [ngClass]="tab.class"
            [class.mat-step-label]="stepper"
            [style.flexDirection]="iconPosition === 'before' ? 'row-reverse' : 'row'"
            [disabled]="disabled || getTabDisabledState(tab, idx)"
            [attr.data-testid]="'uiTab_' + (tab.title | translate) || idx"
        >
            <span>{{ tab.title | translate }}</span>
            <mat-icon
                *ngIf="tab.icon || tab.svgIcon"
                [svgIcon]="tab.svgIcon || undefined"
                [ngClass]="tab.statusColor ? 'text-' + tab.statusColor : ''"
                [class.text-muted]="disabled || getTabDisabledState(tab, idx)"
                [class.icon-before]="iconPosition === 'before'"
                [style.margin]="iconPosition === 'before' ? '0 8px 0 0' : '0 0 0 8px'"
            >
                {{ tab.icon }}
            </mat-icon>
        </a>
    </ng-template>

    <mat-tab-nav-panel #tabPanel>
        <div class="ui-tab-bar__actions-block">
            <ng-content select=".ui-tab-bar__actions"></ng-content>
        </div>
    </mat-tab-nav-panel>
</nav>
