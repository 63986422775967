import { CustomApiModels } from '@mona/api';
import { DataUpdateMessage, DataUpdateMessageOperation, Encounter } from '@mona/models';
import { transformApiEncounter } from '@mona/pdms/data-access-encounters';

/**
 * Transforms API live data update message for the Encounter subscription
 *
 * @param message CustomApiModels.DataUpdateMessage
 */
export const transformApiEncountersUpdateMessage = (
    message: CustomApiModels.DataUpdateMessage,
): DataUpdateMessage<Encounter> => {
    return {
        model: message.model,
        modelId: message.model_id,
        operation: message.operation as DataUpdateMessageOperation,
        payload: transformApiEncounter(message.payload),
    };
};
