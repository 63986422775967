/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/directive-selector, no-extra-boolean-cast */
import { Directive, ElementRef, Optional } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

/**
 * Form element injector directive
 *
 * @see https://stackoverflow.com/a/49462046/4115894
 */
@Directive({
    selector: '[ngModel], [formControl]', // or 'input, select, textarea' - but then your controls won't be handled and also checking for undefined would be necessary
})
export class UiFormElementInjectorDirective {
    /**
     * @ignore
     * @param elementRef
     * @param control
     * @param model
     */
    constructor(
        private elementRef: ElementRef,
        private control: NgControl,
        @Optional() private model: NgModel,
        @Optional() private matField: MatFormField,
    ) {
        if (model) {
            (model.control as any).elementRef = elementRef;
            (model.control as any).nativeElement = elementRef.nativeElement;
        } else if (control) {
            (control as any).elementRef = elementRef;
            (control as any).nativeElement = elementRef.nativeElement;
        } else if (matField) {
            (matField._control as any).elementRef = matField._elementRef;
            (matField._control as any).nativeElement = elementRef.nativeElement;
        }
    }
}
