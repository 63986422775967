// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating Stay model structure.
 * @see #/components/schemas/Stay - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Stay {
    /**
     * Encounter unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The datetime when the encounter starts.
     * @dataFormat date-time
     */
    start_date: string;
    /**
     * The datetime when the encounter ends.
     * @dataFormat date-time
     */
    end_date?: string;
    /**
     * Whether the encounter is active or not
     *
     */
    readonly active: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Stay
 */
export namespace Stay {
    /**
     * Stay Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Encounter unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The datetime when the encounter starts.
         * @dataFormat date-time
         */
        @TransformDate() startDate: Date;
        /**
         * The datetime when the encounter ends.
         * @dataFormat date-time
         */
        @TransformDate() endDate?: Date;
        /**
         * Whether the encounter is active or not
         * @type boolean (`dataFormat` is missing)
         */
        readonly active: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Stay DTO
     * Transforms Stay model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Stay model */
        static toModel(obj: Stay): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Stay model */
        static toApi(obj: Model): Stay {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Stay;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
