import { Pipe, PipeTransform } from '@angular/core';
import { ChangeLogModelKey } from '@mona/models';
import { ChangeLogMap } from '@mona/pdms/data-access-changelog';

/**
 * Indicates if particular model block its content changes
 */
@Pipe({
    name: 'hasContentChanges',
})
export class HasContentChangesPipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param changesMap ChangeLogMap
     * @param modelName ChangeLogModelKey
     */
    transform(changesMap: ChangeLogMap, modelName: ChangeLogModelKey): boolean {
        return !!(changesMap && changesMap[modelName]?.length);
    }
}
