<h2 class="mona-sign-in-pin__title">{{ 'lockScreen.pin.title' | translate }}</h2>

<form (ngSubmit)="handleSubmit()" [formGroup]="pinForm" class="mona-sign-in-pin__form">
    <div class="mona-sign-in-pin__input-wrapper">
        <input
            [formControl]="pinControl"
            [placeholder]="'lockScreen.pin.pinPlaceholder' | translate"
            class="mona-sign-in-pin__input"
            type="text"
        />
        <button
            (click)="onRemoveClick()"
            *ngIf="pinControl.value"
            class="mona-sign-in-pin__input-button"
            mat-icon-button
            matSuffix
            type="button"
        >
            <mat-icon [inline]="true" class="mona-sign-in-pin__input-icon">backspace</mat-icon>
        </button>
    </div>
    <mona-on-screen-keypad
        (numClicked)="onNumClick($event)"
        (rfidButtonClicked)="switchToRfid()"
        [isInvalid]="pinForm.invalid"
        [isLoading]="isAuthInProgress$ | async"
        [isRfidEnabled]="isRfidEnabled"
    ></mona-on-screen-keypad>
</form>
