import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TerminologyEffects, terminologyFeatureKey, terminologyMetaReducer, terminologyReducer } from './state';

/**
 * DataAccessTerminologyModule
 */
@NgModule({
    imports: [
        StoreModule.forFeature(terminologyFeatureKey, terminologyReducer, { metaReducers: [terminologyMetaReducer] }),
        EffectsModule.forFeature([TerminologyEffects]),
    ],
})
export class DataAccessTerminologyModule {}
