import { Pipe, PipeTransform } from '@angular/core';
import { DateColumn } from '@mona/shared/date';

/**
 * Calculates expected table cell width
 */
@Pipe({
    name: 'tableCellWidth',
})
export class TableCellWidthPipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param dateCell DateColumn
     */
    transform(dateCell: DateColumn): string {
        return dateCell.colspan * (dateCell.colspan === 1 ? 164 : 100) + 'px';
    }
}
