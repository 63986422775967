import { InjectionToken } from '@angular/core';
import { TerminalConfig } from '@mona/models';
import { noop } from '@mona/shared/utils';

/**
 * Config updater function - do smth with config when it was updated
 */
export type ConfigUpdater = (config: TerminalConfig) => void;
/**
 * Config updater token
 */
export const CONFIG_UPDATER = new InjectionToken<ConfigUpdater>('Config updater function', {
    providedIn: 'root',
    factory: () => noop,
});
