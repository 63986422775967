<div class="ui-chart-legend__wrapper scrollbar-visible" data-testid="chart-legend">
    <mat-selection-list
        #legend
        [multiple]="true"
        data-testid="chart-legend-list"
        class="ui-chart-legend__list"
        (selectionChange)="onLegendChange($event)"
    >
        <mat-list-option
            *ngFor="let item of legendItems; let index = index"
            class="ui-chart-legend__item"
            checkboxPosition="before"
            [uiLegendItemColor]="index"
            [legendItem]="item"
            [selected]="selectedCodes?.has(item.id)"
            [value]="item"
        >
            {{ item.name | translate }}
        </mat-list-option>
    </mat-selection-list>
</div>
