import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, defaultIfEmpty, distinctUntilChanged, map, publishReplay, refCount } from 'rxjs/operators';
import {
    BalanceValue,
    DataUpdateMessage,
    DataUpdateMessageOperation,
    Encounter,
    MedicationAdministration,
    PatientOutput,
    SortOrderEnum,
} from '@mona/models';
import {
    applyGroupedEntitiesStagedChanges,
    applyInstancesChanges,
    ChangeLogService,
} from '@mona/pdms/data-access-changelog';
import { EntriesInterval } from '@mona/shared/date';
import { compareDeepEqual, groupBy } from '@mona/shared/utils';
import { AsyncActionState } from '@mona/store';
import {
    BalanceTableEnum,
    encounterDataFeatureKey,
    EncounterDataState,
    MedicationAdministrationsMap,
    OutputsMap,
} from '../entities';
import {
    BalanceActions,
    BalanceValuesActions,
    EncounterAction,
    selectAllBalanceValues,
    selectBalanceActiveTable,
    selectBalanceIsLoading,
} from '../state';

/**
 * Balance service
 */
@Injectable({ providedIn: 'root' })
export class BalanceService {
    /**
     * Constructor
     *
     * @param store Store<EncounterDataState>
     * @param changeLogService ChangeLogService
     */
    constructor(private store: Store<EncounterDataState>, private changeLogService: ChangeLogService) {}

    /**
     * Get selected interval
     */
    getInterval(): Observable<EntriesInterval> {
        return this.store.select((state: EncounterDataState) => state[encounterDataFeatureKey].balance.interval);
    }

    /**
     * Outputs grouped map
     */
    getGroupedOutputs(): Observable<OutputsMap> {
        return this.store.select((state: EncounterDataState) => state[encounterDataFeatureKey].balance.outputsMap);
    }

    /**
     * Outputs load action
     */
    getLoadOutputsAction(): Observable<AsyncActionState<PatientOutput[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].balance.outputs.loadAction,
        );
    }

    /**
     * Outputs
     */
    getOutputsMap(): Observable<EntityMap<PatientOutput>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].balance.outputs.entities,
        );
    }

    /**
     * Grouped outputs with staged changes
     */
    getGroupedOutputsWithChanges(): Observable<OutputsMap> {
        return combineLatest([
            this.getGroupedOutputs(),
            this.getOutputsMap(),
            this.changeLogService.getModelChanges('Output'),
        ]).pipe(
            map(([groupedOutputs, outputsMap, changes]) =>
                applyGroupedEntitiesStagedChanges(groupedOutputs, outputsMap, changes),
            ),
        );
    }

    /**
     * Outputs
     */
    getOutputs(): Observable<PatientOutput[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].balance.outputs.rawEntities,
        );
    }

    /**
     * Outputs
     */
    getOutputsWithChanges(): Observable<PatientOutput[]> {
        return combineLatest([this.getOutputs(), this.changeLogService.getModelChanges('Output')]).pipe(
            map(([outputs, changes]) => applyInstancesChanges<PatientOutput>(outputs, changes as any)),
        );
    }

    /**
     * Medication administrations grouped map
     */
    getGroupedMedicationAdministrations(): Observable<MedicationAdministrationsMap> {
        return this.getMedicationAdministrationsMap().pipe(
            map(medAdministrations => groupBy(Object.values(medAdministrations), m => m.prescriptionId, false, false)),
        );
    }

    /**
     * Medication administrations
     */
    getMedicationAdministrationsMap(): Observable<EntityMap<MedicationAdministration>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].balance.medicationAdministrations.entities,
        );
    }

    /**
     * Grouped medication administrations with staged changes
     */
    getGroupedMedicationAdministrationsWithChanges(): Observable<MedicationAdministrationsMap> {
        return combineLatest([
            this.getGroupedMedicationAdministrations(),
            this.getMedicationAdministrationsMap(),
            this.changeLogService.getModelChanges('MedicationAdministration'),
        ]).pipe(
            map(([groupedMedicationAdministrations, medicationAdministrationsMap, changes]) =>
                applyGroupedEntitiesStagedChanges(
                    groupedMedicationAdministrations,
                    medicationAdministrationsMap,
                    changes,
                    'prescriptionId',
                    'startDate',
                    null,
                    SortOrderEnum.asc,
                ),
            ),
            distinctUntilChanged((a, b) => compareDeepEqual(a, b)),
            defaultIfEmpty({}),
            // do not execute each time new subscriber is added
            publishReplay(1),
            refCount(),
        );
    }

    /**
     * Medication administrations
     */
    getMedicationAdministrations(): Observable<MedicationAdministration[]> {
        return this.store
            .select((state: EncounterDataState) => {
                const medicationAdministrations: MedicationAdministration[] = Object.values(
                    state[encounterDataFeatureKey].balance.medicationAdministrations.entities,
                );

                return medicationAdministrations;
            })
            .pipe(debounceTime(1000));
    }

    /**
     * Medication administrations with changes
     */
    getMedicationAdministrationsWithChanges(): Observable<MedicationAdministration[]> {
        return combineLatest([
            this.getMedicationAdministrations(),
            this.changeLogService.getModelChanges('MedicationAdministration'),
        ]).pipe(
            map(([medicationAdministrations, changes]) =>
                applyInstancesChanges<MedicationAdministration>(medicationAdministrations, changes),
            ),
        );
    }

    /**
     * Balance values ALL
     */
    getBalanceValues(): Observable<BalanceValue[]> {
        return this.store.select(selectAllBalanceValues);
    }

    /**
     * Balance values is loading
     */
    getBalanceValuesLoading(): Observable<boolean> {
        return this.store.select(selectBalanceIsLoading);
    }

    /**
     * Get active table index
     */
    getActiveTable(): Observable<BalanceTableEnum> {
        return this.store.select(selectBalanceActiveTable);
    }

    /**
     * Set active table index
     *
     * @param idx BalanceTableEnum
     */
    setActiveTable(idx: BalanceTableEnum): void {
        this.store.dispatch(BalanceActions.setActiveTable({ idx }));
    }

    /**
     * Sets interval
     *
     * @param interval EntriesInterval
     */
    setInterval(interval: EntriesInterval): void {
        this.store.dispatch(BalanceActions.setIntervalAction({ interval }));
    }

    /**
     * Load outputs
     *
     * @param encounterId EntityId<Encounter>
     */
    loadOutputs(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(BalanceActions.loadOutputsAction.action({ encounterId }));
    }

    /**
     * Clear outputs
     */
    clearOutputs(): void {
        this.store.dispatch(BalanceActions.loadOutputsAction.clearAction());
    }

    /**
     * Load medication administrations
     *
     * @param encounterId EntityId<Encounter>
     */
    loadMedicationAdministrations(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(BalanceActions.loadMedicationAdministrations({ encounterId }));
    }

    /**
     * Clear medication administrations
     */
    clearMedicationAdministrations(): void {
        this.store.dispatch(BalanceActions.clearMedicationAdministrations());
    }

    /**
     * Load balance targets
     * @param force
     */
    loadBalanceValues(force = false): void {
        this.store.dispatch(BalanceValuesActions.loadBalanceValues({ force }));
    }

    /**
     * Clear balance targets
     */
    clearBalanceValues(): void {
        this.store.dispatch(BalanceValuesActions.clearBalanceValues());
    }

    /** Handle No-data CTA click and navigate to Procedures */
    navigateToPrescriptions() {
        this.store.dispatch(EncounterAction.navigateInsideSelectedEncounter({ slug: 'prescriptions/medications' }));
    }

    /**
     * upsert medication administration
     *
     * @param medicationAdministration
     */
    upsertMedicationAdministartion(medicationAdministration: MedicationAdministration): void {
        this.store.dispatch(BalanceActions.upsertMedicationAdministration({ medicationAdministration }));
    }

    /**
     * upsert medication administration
     *
     * @param medicationAdministration
     */
    addMedicationAdministartion(medicationAdministration: MedicationAdministration): void {
        this.store.dispatch(BalanceActions.addMedicationAdministration({ medicationAdministration }));
    }

    /**
     * delete medication administration
     *
     * @param id
     */
    deleteMedicationAdministration(id: EntityId<MedicationAdministration>): void {
        this.store.dispatch(BalanceActions.deleteMedicationAdministration({ id }));
    }

    /**
     * handle medication administration data update message
     *
     * @param updateMessage
     */
    handleMedicationAdministrationDataUpdateMessage(updateMessage: DataUpdateMessage<MedicationAdministration>): void {
        if (updateMessage.operation === DataUpdateMessageOperation.Delete) {
            this.deleteMedicationAdministration(updateMessage.modelId);
        } else if (updateMessage.operation === DataUpdateMessageOperation.Update) {
            console.log('upsertMedicationAdministartion', updateMessage);
            this.upsertMedicationAdministartion(updateMessage.payload as MedicationAdministration);
        } else if (updateMessage.operation === DataUpdateMessageOperation.Create) {
            console.log('addMedicationAdministartion', updateMessage);
            this.addMedicationAdministartion(updateMessage.payload as MedicationAdministration);
        }
    }
}
