import { Action, createReducer, on } from '@ngrx/store';
import { AuthState, TokenStatus, User } from '../../models';
import { AuthActions } from '../actions';

export const authFeatureKey = 'auth';

/**
 * The initial state
 */
export const initialAuthUserState: AuthState = {
    activatedRFIDCard: false,
    isAuthenticated: false,
    accessTokenStatus: TokenStatus.PENDING,
    refreshTokenStatus: TokenStatus.PENDING,
    isLoading: undefined,
    error: undefined,
    user: undefined,
    rfid: undefined,
    permissions: [],
    roles: [],
};

/**
 * Reducer for the SucceededAction<RegisterRfidResult>
 * Sets the authUser property to the loaded user
 *
 * @param state AuthUser
 * @param action SucceededAction<AuthUser>
 */
export const reduceUpdateAuthUserOnRfidRegister = (state: AuthState, action: User) => {
    return { ...state, user: action };
};

const reducer = createReducer(
    initialAuthUserState,

    on(
        AuthActions.scanRfid,
        AuthActions.emulateRfid,
        (state, action): AuthState => ({
            ...state,
            rfid: action.rfid,
            activatedRFIDCard: false,
        }),
    ),

    /**
     * Login
     */
    on(
        AuthActions.logInWithCredentials,
        AuthActions.logInWithRfid,
        (state): AuthState => ({
            ...state,
            isLoading: true,
            error: null,
            accessTokenStatus: TokenStatus.VALIDATING,
            activatedRFIDCard: false,
        }),
    ),

    /**
     * Verify user togle loading
     */
    on(AuthActions.verifyRfidSuccess, state => ({
        ...state,
        isLoading: false,
    })),

    /**
     * Refresh token
     */
    on(
        AuthActions.refreshTokenRequest,
        (state): AuthState => ({
            ...state,
            isLoading: true,
            error: null,
            refreshTokenStatus: TokenStatus.VALIDATING,
        }),
    ),

    /**
     * Login & Refresh token
     */
    on(
        AuthActions.loginSuccess,
        AuthActions.refreshTokenSuccess,
        (state, action): AuthState => ({
            ...state,
            isAuthenticated: true,
            rfid: action.rfid,
            isLoading: false,
            error: null,
            accessTokenStatus: TokenStatus.VALID,
            refreshTokenStatus: TokenStatus.VALID,
            activatedRFIDCard: false,
        }),
    ),
    on(
        AuthActions.loginFailure,
        AuthActions.refreshTokenFailure,
        (state, action): AuthState => ({
            ...state,
            isAuthenticated: false,
            isLoading: false,
            error: action.error,
            accessTokenStatus: TokenStatus.INVALID,
            refreshTokenStatus: TokenStatus.INVALID,
        }),
    ),

    /**
     *  Log out
     */
    on(
        AuthActions.logOutSuccess,
        (state): AuthState => ({
            ...initialAuthUserState,
            rfid: '',
        }),
    ),

    /**
     * Auth user
     */
    on(
        AuthActions.setAuthUser,
        (state, { user }): AuthState => ({
            ...state,
            isLoading: false,
            // 🛈 we had scanned rfid in stream
            // so, make latest scanned rfidf as current user rfid
            user: { ...user, rfid: state.rfid || user.rfid },
            roles: user.role,
        }),
    ),

    /**
     *  Register RFID
     */
    on(AuthActions.registerRfid, (state): AuthState => ({ ...state, isLoading: true, error: null })),
    on(
        AuthActions.registerRfidSuccess,
        (state, action): AuthState => ({
            ...state,
            user: action.user,
            isLoading: false,
            error: null,
            activatedRFIDCard: true,
        }),
    ),
    on(
        AuthActions.verifyRfidFailure,
        AuthActions.registerRfidFailure,
        AuthActions.loadPermissionsFailed,
        (state, action): AuthState => ({
            ...state,
            isLoading: false,
            error: action.error,
            activatedRFIDCard: false,
        }),
    ),
    /**
     *  Load permissions
     */
    on(AuthActions.loadPermissions, state => ({ ...state, isLoading: true, permissions: [] })),
    on(AuthActions.loadPermissionsSuccess, (state, { permissions }) => ({ ...state, isLoading: false, permissions })),
    // clear error
    on(AuthActions.clearAuthError, state => ({ ...state, error: null })),
);

/**
 * Auth reducer
 *
 * @param state
 * @param action
 */
export function authReducer(state: AuthState | undefined, action: Action): AuthState {
    return reducer(state, action);
}
