/* eslint-disable @typescript-eslint/member-ordering, jsdoc/require-jsdoc */
import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { defer, fromEvent, Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { generateUUID } from '../helpers';
import { isNullOrUndefined } from '../types';

/**
 * INFO: add comment
 */
@Injectable({ providedIn: 'root' })
export class StorageListener {
    /**
     * Listen to window storage events.
     *
     * @returns
     * An Observable which listens to window storage events,
     * shared to add a single handler to window object
     */
    readonly storage$: Observable<StorageEvent> = defer(() => fromEvent<StorageEvent>(window, 'storage').pipe(share()));
}

export abstract class SyncStorage implements Storage {
    constructor(protected readonly storage: Storage) {}

    get length(): number {
        return this.storage.length;
    }

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string {
        return this.afterGet(this.storage.getItem(key));
    }

    key(index: number): string {
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, this.beforeSet(value));
    }

    protected beforeSet(value: string): string {
        return value;
    }

    protected afterGet(value: string): string {
        return value;
    }

    protected static stringify(value: any): string {
        return JSON.stringify(value);
    }

    protected static parse(text: string): any {
        try {
            return JSON.parse(text) || null;
        } catch (e) {
            return text;
        }
    }
}

/** Sync Storage Injectable */
export const STORAGE_SECRET = new InjectionToken<string>('Storage Secret', { providedIn: 'root', factory: () => '' });
/** Sync Storage Injectable */
export const SYNC_STORAGE = new InjectionToken<Storage>('Sync Storage Service', {
    providedIn: 'root',
    factory: () => new SyncStorageService(inject(WINDOW), inject(STORAGE_SECRET)),
});
/** Sync Storage Service */
export class SyncStorageService extends SyncStorage {
    private get uid() {
        let uid = this._window.localStorage.getItem('_capuid');
        if (uid) {
            return uid;
        }
        uid = generateUUID();
        this._window.localStorage.setItem('_capuid', uid);
        return uid;
    }

    private get secret() {
        return this.uid + this._secret;
    }

    /**
     * INFO: add comment
     *
     * @param _window
     * @param _secret
     */
    constructor(protected _window: Window, protected _secret: string) {
        // private readonly storageListenerService: StorageListener
        super(_window.localStorage);
    }

    // protected beforeSet(value: string): string {
    //     if (isNullOrUndefined(value)) return value;
    //     return AES.encrypt(value, this.secret).toString();
    // }

    // protected afterGet(value: string): string {
    //     if (isNullOrUndefined(value)) return value;
    //     try {
    //         return AES.decrypt(value, this.secret).toString(cryptoEnc);
    //     } catch (error: any) {
    //         console.error(error);
    //         this.clear();
    //     }
    // }

    /**
     * TODO:
     * Listens for `localStorage` StorageEvent events
     *
     * @returns
     * An Observable which listens to `window` storage events, and filters
     * those which relevant to `localStorage`
     */

    /* get asObservable$(): Observable<StorageEvent> {
    return this.storageListenerService.storage$.pipe(
      filter(event => event?.storageArea === localStorage)
    );
  } */
}
