import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    CanDisable,
    HasInitialized,
    HasTabIndex,
    mixinDisabled,
    mixinInitialized,
    mixinTabIndex,
} from '@angular/material/core';
import { BehaviorSubject, isObservable } from 'rxjs';
import { WithLogger } from '@mona/shared/logger';
import { isFunction, uiPure } from '@mona/shared/utils';
import { DrawerNavItem } from '../drawer/drawer-nav-item.model';

// Boilerplate for applying mixins to TabBar.
const _TabBarBase = mixinInitialized(mixinTabIndex(mixinDisabled(class {})));
// const _TabBarBase = applyMixins(class {}, [mixinInitialized, mixinTabIndex, mixinDisabled]);

/**
 * UI TabBar Component
 *
 * @example ```html
 * <ui-tab-bar
 * [tabs]="tabs"
 * [tabIndex]="tabIndex"
 * (tabIndexChange)="tabIndexChange($event)"
 * >
 * <router-outlet></router-outlet>
 * </ui-tab-bar>
 * ```
 */
@Component({
    selector: 'ui-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['disabled', 'tabIndex'],
})
@WithLogger({
    scope: 'UI',
    methodOptions: {
        withArgs: true,
    },
    loggedMethodsNames: ['onActiveChange', 'tabLinkClick'],
})
export class TabBarComponent extends _TabBarBase implements CanDisable, HasInitialized, HasTabIndex, OnInit {
    /** Tab should like stepper */
    @Input() stepper = false;
    /** Tab should strecth to full width */
    @Input() stretched = false;
    /** Tab icon position */
    @Input() iconPosition: 'before' | 'after' = 'before';
    /** Mat toolbar color variant */
    @Input() color: 'primary' | 'accent' | 'warn' | undefined = undefined;
    /** Projected Menu items */
    @Input() tabs: DrawerNavItem[] = [];
    /** Selected Tab Index */
    @Input() tabIndex = 0;
    /** Selected Tab Index Change */
    @Output() tabIndexChange = new BehaviorSubject<number>(null);
    /** Host CSS Class */
    @HostBinding('class.ui-tab-bar') componentCssClass = true;

    /**
     * Mark the class as initialized
     */
    ngOnInit(): void {
        this._markInitialized();
    }

    /**
     * Set selected tab on router link activation
     * Ignore if event is not active or tabIndex is not null
     *
     * @param tab
     * @param idx
     * @param isActive status  of RouterLinkActive directive
     */
    onActiveChange(tab: DrawerNavItem, idx: number, isActive: boolean): void {
        if (!isActive || (idx === this.tabIndex && this.initialized)) {
            return;
        }
        this.setTabIndex(idx);
    }

    /**
     * Set selected tab on click
     * Ignore if tab has link
     *
     * @param tab
     * @param idx
     */
    tabLinkClick(tab: DrawerNavItem, idx: number): void {
        if (tab.link || idx === this.tabIndex) {
            return;
        }

        if (isFunction(tab.click)) {
            const result = tab.click(tab.data);
            if (tab.validateClick && isObservable(result)) {
                result.subscribe(res => {
                    res && this.setTabIndex(idx);
                    return;
                });
            } else {
                this.setTabIndex(idx);
            }
            return;
        }

        this.setTabIndex(idx);
    }

    /**
     * Set selected tab index
     *
     * @param idx
     */
    setTabIndex(idx: number): void {
        this.tabIndex = idx;
        this.tabIndexChange.next(idx);
    }

    /**
     * Get tab disabled state
     * @param tab
     * @param idx
     */
    @uiPure
    getTabDisabledState(tab: DrawerNavItem, idx?: number): boolean {
        if (tab.disabled) {
            return true;
        }
        if (isFunction(tab.disableWhen)) {
            return tab.disableWhen(tab.data);
        }
        return false;
    }
}
