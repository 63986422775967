import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import {
    ApplicationRef,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    HostBinding,
    Inject,
    Injector,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { UiLoadingOverlayComponent } from './loading-overlay.component';

/**
 * A spinner overlay to demonstrate loading for async data.
 *
 * @example
 * <div [uiLoadingOverlay]="true"></div>
 */
// tslint:disable-next-line: directive-selector
@Directive({ selector: '[uiLoadingOverlay]' })
export class UiLoadingOverlayDirective implements OnInit, OnDestroy {
    /**
     * A reference to the portal
     */
    private readonly loadingOverlayPortal: ComponentPortal<UiLoadingOverlayComponent>;

    /**
     * Reference to our portal host
     */
    private readonly bodyPortalHost: DomPortalOutlet;
    /**
     * Should loading be set
     */
    private shouldSet: boolean;

    /** Color */
    @Input() color = 'primary';
    /** Diameter */
    @Input() diameter = 64;
    /** Overlay class */
    @Input() overlayClass: string;
    /**
     * Show or hide the loading overlay
     */
    @Input()
    set uiLoadingOverlay(value: boolean) {
        this.shouldSet = value && this.bodyPortalHost && !this.bodyPortalHost.hasAttached();

        if (this.shouldSet) {
            const cmpRef = this.bodyPortalHost.attach(this.loadingOverlayPortal);
            cmpRef.instance.color = this.color;
            cmpRef.instance.diameter = this.diameter;
            cmpRef.instance.overlayClass = this.overlayClass;
        } else {
            this.bodyPortalHost.detach();
        }
    }

    /**
     * Alias the position back onto the component as a style attribute
     */
    @HostBinding('style.position') position!: string;

    /**
     * Constructor
     *
     * @param  _window Window
     * @param  elementRef Element reference
     * @param  componentFactoryResolver Component factory resolver
     * @param  appRef App reference
     * @param  injector Injector
     */
    constructor(
        // tslint:disable-next-line: variable-name
        @Inject(WINDOW) private _window: Window,
        private elementRef: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
    ) {
        // Create the body portal host
        this.bodyPortalHost = new DomPortalOutlet(
            this.elementRef.nativeElement,
            this.componentFactoryResolver,
            this.appRef,
            this.injector,
        );

        // Create the component portal
        this.loadingOverlayPortal = new ComponentPortal(UiLoadingOverlayComponent);
    }

    /**
     * Determine and set the needed position
     */
    ngOnInit(): void {
        // Determine the CSS position of the element
        const position = this._window.getComputedStyle(this.elementRef.nativeElement).getPropertyValue('position');
        this.position = this.determinePosition(position);
    }

    /**
     * Destroy the portal host if it exists
     */
    ngOnDestroy(): void {
        if (this.bodyPortalHost && this.bodyPortalHost.hasAttached()) {
            this.bodyPortalHost.detach();
        }
    }

    /**
     * Return the correct position
     *
     * @param position
     */
    private determinePosition(position: string): string {
        return position === 'relative' || position === 'absolute' ? position : 'relative';
    }
}
