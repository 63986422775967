<form *ngIf="formGroup" [formGroup]="formGroup" (keydown.enter)="onNewItemAutoCompleteSelect($event)">
    <div class="diagnosis-row">
        <mat-form-field class="app-input-field">
            <mat-label>{{ 'apps.admission.diagnoses.items.status' | translate }}</mat-label>
            <mat-select [formControl]="formGroup.controls.status" panelClass="app-input-select-panel scrollbar-visible">
                <mat-option *ngFor="let state of verificationStates" [value]="state.enumMember">
                    {{ state.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="app-input-field">
            <mat-label>
                {{
                    formGroup.controls.icd10Code.value
                        ? (diagnosesFhirConfig$ | async)?.displayNameSingular +
                          ': ' +
                          formGroup.controls.icd10Code.value
                        : ('apps.admission.diagnoses.items.label' | translate)
                }}
            </mat-label>
            <input [required]="true" [formControl]="formGroup.controls.text" [matAutocomplete]="auto" matInput />

            <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onNewItemAutoCompleteSelect($event)"
                [displayWith]="autoCompleteDisplayFn"
                class="app-input-select-panel scrollbar-visible"
            >
                <mat-option *ngFor="let option of foundDiagnoses$ | async" [value]="option">
                    <div class="autocomplete-option">
                        <div>{{ option.displayName }}</div>
                        <small class="text-muted">{{ option.code }}</small>
                    </div>
                </mat-option>
            </mat-autocomplete>

            <button
                *ngIf="canChangeDiagnosis && formGroup.controls.icd10Code.value"
                (click)="resetDiagnosis()"
                mat-icon-button
                matSuffix
                type="button"
            >
                <mat-icon>close</mat-icon>
            </button>

            <mat-hint *ngIf="canChangeDiagnosis && !formGroup.controls.text.disabled">
                {{ 'apps.admission.diagnoses.newItem.hint' | translate }}
            </mat-hint>

            <span matSuffix>
                <mat-spinner *ngIf="searchDiagnosisInProgress$ | async" [diameter]="24"></mat-spinner>
            </span>

            <mat-error>
                <ui-validation-message [control]="formGroup.controls.text"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
</form>
