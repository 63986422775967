/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { encounterDataFeatureKey, EncounterFeatureState, WoundStatusState } from '../../entities';
import { woundStatusAdapter } from '../reducers/wound-status.reducer';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectWoundStatusState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.woundStatus,
);

export const {
    selectIds: selectWoundIds,
    selectEntities: selectWoundEntities,
    selectAll: selectWoundAll,
    selectTotal: selectWoundTotal,
} = woundStatusAdapter.getSelectors(selectWoundStatusState);

export const selectWoundStatusEntities = createSelector(
    selectWoundStatusState,
    (state: WoundStatusState) => state.entities,
);

export const selectWoundStatusIsLoading = createSelector(
    selectWoundStatusState,
    (state: WoundStatusState) => state.isLoading,
);
