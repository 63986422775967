/**
 * The verification status to support or decline the clinical status of the condition or diagnosis.
 */
export enum VerificationStatus {
    /**
     * There is sufficient diagnostic and/or clinical evidence to treat this as a confirmed condition.
     */
    CONFIRMED = 'confirmed',
    /**
     * One of a set of potential (and typically mutually exclusive) diagnoses asserted to further guide the diagnostic process and preliminary treatment.
     */
    DIFFERENTIAL = 'differential',
    /**
     * The statement was entered in error and is not valid.
     */
    ERROR = 'entered-in-error',
    /**
     * This is a tentative diagnosis - still a candidate that is under consideration.
     */
    PROVISIONAL = 'provisional',
    /**
     * This condition has been ruled out by diagnostic and clinical evidence.
     */
    REFUTED = 'refuted',
    /**
     * There is not sufficient diagnostic and/or clinical evidence to treat this as a confirmed condition.
     */
    UNCONFIRMED = 'unconfirmed',
}
