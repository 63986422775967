import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { BalanceValue } from '@mona/models';
import { TerminologyActions } from '@mona/pdms/data-access-terminology';
import { EntriesInterval } from '@mona/shared/date';
import { BalanceValueState } from '../../entities';
import { BalanceActions, BalanceValuesActions } from '../actions';

export const balanceValuesAdapter: EntityAdapter<BalanceValue> = createEntityAdapter<BalanceValue>({
    selectId: (item: BalanceValue) => item.date,
});

const initialState: BalanceValueState = balanceValuesAdapter.getInitialState({
    cachedBalanceValues: {} as Record<EntriesInterval, BalanceValue[]>,
});

export const balanceValuesReducer = createReducer(
    initialState,

    on(BalanceValuesActions.upsertBalanceValues, (state, action) =>
        balanceValuesAdapter.upsertMany(action.balanceValues, state),
    ),

    on(BalanceValuesActions.loadBalanceValuesFailure, state => {
        return balanceValuesAdapter.removeAll(state);
    }),

    on(BalanceValuesActions.loadBalanceValuesSuccess, (state, action) => {
        const { balanceValues: results } = action;
        return balanceValuesAdapter.setAll(results, {
            ...state,
            cachedBalanceValues: {
                ...state.cachedBalanceValues,
                [action.interval]: results,
            },
        });
    }),

    on(BalanceValuesActions.clearBalanceValuesCache, state => ({
        ...state,
        cachedBalanceValues: {} as any,
    })),

    on(BalanceValuesActions.clearBalanceValues, () => initialState),
);

/**
 * Balance loading reducer - ALL nested states
 */
export const balanceLoadingReducer = createReducer<boolean, Action>(
    true,
    on(BalanceActions.setIsLoadingAction, (state, { isLoading }) => isLoading),
    on(
        BalanceValuesActions.loadBalanceValues,
        //
        BalanceActions.loadOutputsAction.action,
        BalanceActions.loadMedicationAdministrations,
        TerminologyActions.loadMedicationsAction.action,
        () => true,
    ),
    on(
        BalanceValuesActions.loadBalanceValuesSuccess,
        BalanceValuesActions.loadBalanceValuesFailure,
        BalanceValuesActions.clearBalanceValues,
        //
        BalanceActions.loadOutputsAction.succeededAction,
        BalanceActions.loadMedicationAdministrationsSuccess,
        BalanceActions.loadMedicationAdministrationsFailed,
        BalanceActions.loadOutputsAction.failedAction,
        BalanceActions.loadOutputsAction.clearAction,
        TerminologyActions.loadMedicationsAction.succeededAction,
        TerminologyActions.loadMedicationsAction.failedAction,
        () => false,
    ),
);
