import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorPageComponent } from './error-page.component';

/**
 * INFO: add comment
 */
@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: ErrorPageComponent,
            },
        ]),
    ],
    exports: [RouterModule],
})
export class ErrorPageRoutingModule {}
