<label *ngIf="!label" [id]="name">{{ label }}</label>
<div class="ui-dynamic-radio-wrapper" [class.ui-dynamic-radio-wrapper--vertical]="vertical">
    <mat-radio-group
        [id]="name"
        #radioGroup
        [formControl]="control"
        [value]="control.value"
        [required]="required"
        [attr.name]="name"
        [attr.data-testid]="name"
        [ngClass]="customClass"
    >
        <mat-radio-button
            #radioButton
            *ngFor="let selection of dataSource.filteredData; let i = index"
            [value]="selection[valueKey] || selection"
            class="ui-dynamic-element-option"
            (click)="onChange(selection)"
            [class.ui-dynamic-element-option--default]="!radioButton.checked && $any(selection).isDefault"
            [attr.data-testid]="name + '_option_' + i"
        >
            {{ $any(selection).label || selection }}
        </mat-radio-button>
    </mat-radio-group>
    <span class="mat-hint ui-dynamic-element-hint">{{ hint }}</span>
</div>
