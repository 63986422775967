import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Patient } from '@mona/models';
import { transformApiEncounterPatients, transformApiPatients } from './transforms';

/**
 * API abstraction layer for the patients API
 */
@Injectable({
    providedIn: 'root',
})
export class PatientsApi {
    apiBase = '/pdms/patients/';
    apiPatientSearch = '/pdms/patient-search';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get patients
     *
     * @param patientNumber
     */
    getPatients(patientNumber?: string): Observable<Patient[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (patientNumber) {
            params.patient_number = `${patientNumber}`;
        }

        return this.http
            .get<ApiModels.Patient[]>(this.apiBase, {
                params,
            })
            .pipe(map(apiPatients => transformApiEncounterPatients(apiPatients)));
    }

    /**
     * Search patient
     *
     * Query params to be send to API endpoint:
     *
     * `is_icu_admittable` - Patient can be admitted (Parent encounter in-progress status, no active
     * Patient Encounter) → needs to be used in patient search for admission
     *
     * `is_icu_reviewable` - Patient can be reviewed (at least 1 finished Patient Encounter and all
     * Parent encounter status) → needs to be used in patient search for review
     *
     * @param searchQuery
     * @param isAdmissionSearch
     */
    getSearchPatient(searchQuery: string, isAdmissionSearch: boolean): Observable<Patient[]> {
        const paramsObj: Record<string, any> = {
            search_query: searchQuery,
        };
        if (isAdmissionSearch) {
            paramsObj.is_icu_admittable = true;
        } else {
            paramsObj.is_icu_reviewable = true;
        }
        const params = HttpService.buildHttpParams(paramsObj);

        return this.http
            .get<ApiModels.PatientSearch[]>(this.apiPatientSearch, {
                params,
            })
            .pipe(map(apiPatients => transformApiPatients(apiPatients)));
    }
}
