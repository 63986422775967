<form *ngIf="formGroup" [formGroup]="formGroup" [isEnabled]="isBedSideMode && isActive">
    <div class="app-admission-diagnosis-form__row">
        <mat-form-field class="app-admission-diagnosis-form__field" appearance="fill" data-testid="ff-anamnesisText">
            <mat-label>{{ 'apps.admission.admissionDiagnosis.textLabel' | translate }}</mat-label>
            <input
                type="text"
                [formControl]="formGroup.controls.text"
                required
                matInput
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-anamnesisTextarea"
            />
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.text"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
