import { ApiModels } from '@mona/api';
import { Procedure } from '@mona/models';

/**
 * Transforms api procedure
 *
 * @param apiProcedure ApiModels.Procedure
 */
export const transformApiProcedure = (apiProcedure: ApiModels.Procedure): Procedure => {
    const model = ApiModels.Procedure.DTO.toModel(apiProcedure);
    return model;
};
/**
 * Transform api procedures
 *
 * @param apiProcedures ApiModels.Procedure[]
 */
export const transformApiProcedures = (apiProcedures: ApiModels.Procedure[]): Procedure[] => {
    return apiProcedures.map(apiProcedure => transformApiProcedure(apiProcedure));
};
