<form [formGroup]="dischargeReasonForm" (ngSubmit)="ngSubmit()">
    <mat-dialog-content data-testid="dc-availableDischargeReasons" class="discharge-dialog-content">
        <h2 mat-dialog-title>
            {{ 'apps.patient.dischargeReasonList.dischargeReasonListHeader' | translate }}
        </h2>

        <button class="discharge-dialog-content__close" mat-dialog-close mat-icon-button type="button">
            <mat-icon>close</mat-icon>
        </button>

        <h4 class="discharge-dialog-content__sub-header">
            {{ 'apps.patient.dischargeReasonList.dischargeReasonListSubHeader' | translate }}
        </h4>
        <div class="discharge-reason-list">
            <mat-radio-group formControlName="dischargeReason" (change)="chooseOtherReason($event.value)">
                <mat-radio-button
                    color="primary"
                    *ngFor="let dischargeReason of availableDischargeReasons; let i = index"
                    [checked]="dischargeReason.isDefault"
                    [value]="dischargeReason"
                >
                    {{ dischargeReason.text }}
                </mat-radio-button>
            </mat-radio-group>
            <div class="other-reason-input-wrapper">
                <mat-form-field
                    *ngIf="isShowTextBox"
                    class="other-reason-input-wrapper__other-reason-input"
                    appearance="fill"
                >
                    <input
                        matInput
                        formControlName="customText"
                        [placeholder]="'apps.patient.dischargeReasonList.otherReasonInputPlaceholder' | translate"
                    />
                    <mat-error>
                        <ui-validation-message
                            [control]="dischargeReasonForm.get('otherReason')"
                        ></ui-validation-message>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button color="primary" mat-button mat-dialog-close data-testid="btn-close">
            {{ 'apps.patient.dischargeReasonList.cancel' | translate }}
        </button>
        <button
            color="primary"
            class="mat-dialog-actions__action-btn"
            mat-raised-button
            type="submit"
            mat-dialog-close
            [disabled]="!dischargeReasonForm.valid"
            data-testid="btn-send-discharge-reason"
        >
            {{ 'apps.patient.dischargeReasonList.discharge' | translate }}
        </button>
    </mat-dialog-actions>
</form>
