/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { encounterDataFeatureKey, EncounterFeatureState, InfectionStatusState } from '../../entities';
import { infectionStatusAdapter } from '../reducers/infection-status.reducer';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectInfectionStatusState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.infections,
);

export const {
    selectIds: selectInfectionIds,
    selectEntities: selectInfectionEntities,
    selectAll: selectInfectionAll,
    selectTotal: selectInfectionTotal,
} = infectionStatusAdapter.getSelectors(selectInfectionStatusState);

export const selectInfectionsStatusEntities = createSelector(
    selectInfectionStatusState,
    (state: InfectionStatusState) => state.entities,
);

export const selectInfectionsIsLoading = createSelector(
    selectInfectionStatusState,
    (state: InfectionStatusState) => state.isLoading,
);
