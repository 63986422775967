import { ApiModels } from '@mona/api';
import { LabValueType } from '@mona/models';

/**
 * Transforms api lab value type
 *
 * @param apiLabValueType ApiModels.LabValueType
 */
export const transformApiLabValueType = (apiLabValueType: ApiModels.LabValueType): LabValueType => {
    const model = ApiModels.LabValueType.DTO.toModel(apiLabValueType);
    return {
        ...model,
        id: model.code,
    };
};

/**
 * Transform api lab value types
 *
 * @param apiLabValueTypes  ApiModels.LabValueType[]
 */
export const transformApiLabValueTypes = (apiLabValueTypes: ApiModels.LabValueType[]): LabValueType[] => {
    return apiLabValueTypes.map(apiLabValueType => transformApiLabValueType(apiLabValueType));
};
