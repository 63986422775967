/* eslint-disable @angular-eslint/component-selector, @angular-eslint/no-output-rename, @angular-eslint/no-input-rename, @angular-eslint/no-outputs-metadata-property, @angular-eslint/no-inputs-metadata-property, @typescript-eslint/member-ordering, @typescript-eslint/no-non-null-assertion, @angular-eslint/directive-selector, @typescript-eslint/no-empty-function, jsdoc/require-jsdoc, @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/ban-ts-comment */
import { FocusTrapFactory } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { FlexibleConnectedPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { CdkScrollable, ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { _CoalescedStyleScheduler, _COALESCED_STYLE_SCHEDULER } from '@angular/cdk/table';
import {
    CdkEditable,
    CdkEditControl,
    CdkPopoverEdit,
    EditEventDispatcher,
    EditRef,
    FocusDispatcher,
    PopoverEditPositionStrategyFactory,
} from '@angular/cdk-experimental/popover-edit';
import {
    AfterContentInit,
    AfterViewInit,
    Component,
    Directive,
    ElementRef,
    forwardRef,
    Inject,
    Injectable,
    Input,
    NgZone,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { _MatMenuTriggerBase } from '@angular/material/menu';
import { WINDOW } from '@ng-web-apis/common';
import { fromEvent, NEVER } from 'rxjs';
import { delay, first, switchMap, take, tap } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { Platform, PLATFORM } from '@mona/shared/utils';
import { UiVHSTableComponent } from '../components';

const POPOVER_EDIT_HOST_BINDINGS = {
    '[attr.tabindex]': 'disabled ? null : 0',
    class: 'mat-popover-edit-cell',
    '[attr.aria-haspopup]': '!disabled',
};
const POPOVER_EDIT_INPUTS = [
    'template: uiPopoverEdit',
    'context: uiPopoverEditContext',
    // 'colspan: uiPopoverEditColspan',
    'disabled: uiPopoverEditDisabled',
];
const EDIT_PANE_CLASS = 'mat-edit-pane';
const EDIT_PANE_SELECTOR = `.${EDIT_PANE_CLASS}, .mat-edit-pane`;
export const CELL_SELECTOR = '.cdk-cell, .mat-mdc-cell, td';

/** Used to react on keyboard opening and re-position cell edit popover */
const KEYBOARD_ANIMATION_DELAY_MS = 500;

// -----------------------------------------------------------------------------
// OVERRIDE CDK EDIT DIRECTIVES
// -----------------------------------------------------------------------------

/**
 * A directive that must be attached to enable editability on a table.
 * It is responsible for setting up delegated event handlers and providing the
 * EditEventDispatcher service for use by the other edit directives.
 *
 * @override
 */
@Directive({
    selector: 'table[ui-editable]',
})
export class UiVHSEditable extends CdkEditable implements AfterViewInit {
    constructor(
        protected readonly elementRef: ElementRef,
        protected readonly editEventDispatcher: EditEventDispatcher<EditRef<unknown>>,
        protected readonly focusDispatcher: FocusDispatcher,
        protected readonly ngZone: NgZone,
    ) {
        super(elementRef, editEventDispatcher, focusDispatcher, ngZone);
    }

    override ngAfterViewInit(): void {
        // do not initialize hover listeners
    }
}

/**
 * Attaches an ng-template to a cell and shows it when instructed to by the
 * EditEventDispatcher service.
 * Makes the cell focusable.
 *
 * Implements multiple overrides to handle keyboard events & repositioning
 *
 * @override
 */
@Directive({
    selector: '[uiPopoverEdit]',
    host: POPOVER_EDIT_HOST_BINDINGS,
    inputs: POPOVER_EDIT_INPUTS,
})
// @ts-ignore // to override `_getPositionStrategy`
export class UiVHSPopoverEdit<C> extends CdkPopoverEdit<C> {
    @Input() uiPopoverDouble = false;

    private cdkScrollable: CdkScrollable;

    constructor(
        services: UiVHSEditServices,
        elementRef: ElementRef,
        viewContainerRef: ViewContainerRef,
        @Inject(PLATFORM) public platform: Platform,
        private configService: ConfigService,
    ) {
        super(services, elementRef, viewContainerRef);
    }

    protected override panelClass(): string {
        return EDIT_PANE_CLASS;
    }

    override ngAfterViewInit(): void {
        super.ngAfterViewInit();
        // assign scrollable container to override `_getPositionStrategy`
        this.cdkScrollable = this.viewContainerRef.injector.get(UiVHSTableComponent)?.leftTableWrapper;
    }

    private override _getPositionStrategy(): FlexibleConnectedPositionStrategy {
        const positionStrategy = this.services.positionFactory
            // @ts-ignore // to access member
            .positionStrategyForCells(this._getOverlayCells()) as FlexibleConnectedPositionStrategy;

        return positionStrategy.withScrollableContainers([this.cdkScrollable]);
    }

    private override _createEditOverlay(): void {
        this.configService
            .select('isBedSide')
            .pipe(
                tap(isOskOn => {
                    const isElectronAndOskOn = this.platform.isElectron && isOskOn;
                    const activeCellRangeToBottom =
                        window.innerHeight -
                        (this._getPositionStrategy()._origin as HTMLElement).getBoundingClientRect().bottom;
                    const keyboardHeight = 384;
                    const shouldAddMarginForFloatingCell =
                        isElectronAndOskOn && activeCellRangeToBottom < keyboardHeight;

                    this.overlayRef = this.services.overlay.create({
                        disposeOnNavigation: true,
                        panelClass: [
                            this.uiPopoverDouble ? 'double' : '',
                            'mat-edit-pane',
                            shouldAddMarginForFloatingCell ? 'resize-on-keyboard-top' : '',
                        ],
                        positionStrategy: this._getPositionStrategy(),
                        scrollStrategy: this.services.overlay.scrollStrategies.block(),
                        direction: this.services.directionality,
                    });

                    this.initFocusTrap();
                    this.overlayRef.overlayElement.setAttribute('aria-role', 'dialog');
                }),
                switchMap(() => this.overlayRef.detachments()),
                take(1),
            )
            .subscribe(() => this.closeEditOverlay());
    }
}

/**
 * A component that attaches to a form within the edit.
 * It coordinates the form state with the table-wide edit system and handles
 * closing the edit when the form is submitted or the user clicks
 * out.
 *
 * Implements multiple overrides to handle keyboard events & repositioning
 *
 * @override
 */
@Directive({
    selector: 'form[uiEditLens], ui-form[uiEditLens]',
    host: {
        class: 'mat-edit-lens',
    },
    inputs: [
        'clickOutBehavior: uiEditLensClickOutBehavior',
        'preservedFormValue: uiEditLensPreservedFormValue',
        'ignoreSubmitUnlessValid: uiEditLensIgnoreSubmitUnlessValid',
    ],
    outputs: ['preservedFormValueChange: uiEditLensPreservedFormValueChange'],
    providers: [EditRef],
})
export class VhsTableEditLens<FormValue> extends CdkEditControl<FormValue> implements OnInit {
    /** @internal */
    constructor(
        elementRef: ElementRef,
        editRef: EditRef<FormValue>,
        private ngZone: NgZone,
        @Inject(WINDOW) protected window: Window,
    ) {
        super(elementRef, editRef);
    }

    /** @internal */
    override ngOnInit() {
        super.ngOnInit();
        //  Watch for keyboard hide event to close the edit
        this.ngZone.runOutsideAngular(() => {
            fromEvent(this.window, 'keyboard:hide')
                .pipe(first(), delay(0))
                .subscribe(() => {
                    this.editRef.close();
                });
        });
    }

    /**
     * Called on click anywhere in the document.
     * If the click was outside of the lens, trigger the specified click out behavior.
     *
     * @override
     * @param evt
     */
    override handlePossibleClickOut(evt: Event): void {
        // check if active element is form input or textarea or keyboard key
        if (
            !!(document.activeElement as HTMLInputElement).form ||
            document.activeElement.classList.contains('mat-keyboard-key')
        ) {
            return;
        }
        super.handlePossibleClickOut(evt);
    }
}

// -----------------------------------------------------------------------------
// OVERRIDE CDK EDIT SERVICES
// -----------------------------------------------------------------------------

/**
 * Custom {@link EditEventDispatcher} which doesn't track row hover events
 *
 * @override
 */
@Injectable()
// @ts-ignore // to override hover observers
export class UiVHSEditEventDispatcher extends EditEventDispatcher<any> {
    override registerRowWithHoverContent(row: Element): void {}
    override deregisterRowWithHoverContent(row: Element): void {}
    override hoverOrFocusOnRow(row: Element) {
        return NEVER;
    }
    private override _hoveredContentStateDistinct = NEVER;
}

/**
 * Custom {@link EditServices} whith CoalescedStyleScheduler
 *
 * @override
 */
@Injectable()
export class UiVHSEditServices {
    constructor(
        readonly directionality: Directionality,
        readonly editEventDispatcher: EditEventDispatcher<EditRef<unknown>>,
        readonly focusDispatcher: FocusDispatcher,
        readonly focusTrapFactory: FocusTrapFactory,
        readonly ngZone: NgZone,
        readonly overlay: Overlay,
        readonly positionFactory: PopoverEditPositionStrategyFactory,
        readonly scrollDispatcher: ScrollDispatcher,
        readonly viewportRuler: ViewportRuler,
        @Inject(forwardRef(() => _COALESCED_STYLE_SCHEDULER))
        readonly cdkStyleScheduler: _CoalescedStyleScheduler,
    ) {}
}

/**
 * Empty comonent derived from {@link MatMenuTrigger} directive to create dynamic element that should trigger a `mat-menu`.
 *
 * @override
 */
@Component({
    template: '',
    selector: `uiMenuTriggerFor`,
    host: {
        class: 'mat-menu-trigger',
    },
    inputs: [
        'menu:uiMenuTriggerFor',
        'matMenuTriggerData:uiMenuTriggerData',
        'matMenuTriggerRestoreFocus:uiMenuTriggerRestoreFocus',
    ],
    exportAs: 'uiMenuTrigger',
})
export class UiVHSMatMenuTrigger extends _MatMenuTriggerBase implements AfterContentInit {
    override ngAfterContentInit() {
        // skip
    }
}
