/* eslint-disable no-useless-escape */
import { Injectable, Provider, SkipSelf, Optional, Type } from '@angular/core';
import { Validators, ValidatorFn, UntypedFormControl, AbstractControl } from '@angular/forms';
import {
    UiDynamicCheckboxComponent,
    UiDynamicDatepickerComponent,
    UiDynamicInputComponent,
    UiDynamicRadioComponent,
    UiDynamicSelectComponent,
    UiDynamicSliderComponent,
    UiDynamicTextareaComponent,
    UiDynamicDateTimePickerComponent,
    UiDynamicListComponent,
    UiDynamicAutocompleteComponent,
    UiDynamicButtonComponent,
} from '../components';
import {
    DYNAMIC_ELEMENT_NAME_REGEX,
    UiDynamicElement,
    UiDynamicElementConfig,
    UiDynamicElementValidator,
    UiDynamicType,
} from '../models';

/**
 * UiDynamicFormsService
 */
@Injectable()
export class UiDynamicFormsService {
    /**
     * Method to validate if the [name] is a proper element name.
     * Throws error if name is not valid.
     *
     * @param name
     */
    validateDynamicElementName(name: string): void {
        if (!DYNAMIC_ELEMENT_NAME_REGEX.test(name)) {
            throw new Error('Dynamic element name: "${name}" is not valid.');
        }
    }

    /**
     * Gets component to be rendered depending on [UiDynamicElement | UiDynamicType]
     * Throws error if it does not exists or not supported.
     *
     * @param element
     */
    getDynamicElement(element: UiDynamicElement | UiDynamicType | Type<any>): any {
        switch (element) {
            case UiDynamicType.Text:
            case UiDynamicType.Number:
            case UiDynamicElement.Input:
            case UiDynamicElement.Password:
                return UiDynamicInputComponent;
            case UiDynamicElement.Textarea:
                return UiDynamicTextareaComponent;
            case UiDynamicType.Boolean:
            case UiDynamicElement.Checkbox:
                return UiDynamicCheckboxComponent;
            case UiDynamicElement.Slider:
                return UiDynamicSliderComponent;
            case UiDynamicType.Array:
            case UiDynamicElement.Autocomplete:
                return UiDynamicAutocompleteComponent;
            case UiDynamicElement.Select:
                return UiDynamicSelectComponent;
            case UiDynamicElement.List:
                return UiDynamicListComponent;
            case UiDynamicElement.Radio:
                return UiDynamicRadioComponent;

            case UiDynamicElement.Datepicker:
            case UiDynamicType.Date:
                return UiDynamicDatepickerComponent;
            case UiDynamicElement.Datetimepicker:
                return UiDynamicDateTimePickerComponent;
            case UiDynamicElement.Button:
                return UiDynamicButtonComponent;
            default:
                throw new Error(`Error: type ${element} does not exist or not supported.`);
        }
    }

    /**
     * Creates form control for element depending [IUiDynamicElementConfig] properties.
     *
     * @param config
     * @param parentDiabled
     */
    createFormControl(config: UiDynamicElementConfig, parentDiabled = false): UntypedFormControl {
        const validator: ValidatorFn = this.createValidators(config);
        return new UntypedFormControl({ value: config.default, disabled: config.disabled || parentDiabled }, validator);
    }

    /**
     * Creates form validationdepending [IUiDynamicElementConfig] properties.
     *
     * @param config
     */
    createValidators(config: UiDynamicElementConfig): ValidatorFn {
        let validator: ValidatorFn;
        if (config.required) {
            validator = Validators.required;
        }
        if (config.max || config.max === 0) {
            validator = Validators.compose([validator, Validators.max(parseFloat(config.max))]);
        }
        if (config.min || config.min === 0) {
            validator = Validators.compose([validator, Validators.min(parseFloat(config.min))]);
        }
        if (config.maxLength || config.maxLength === 0) {
            validator = Validators.compose([validator, Validators.maxLength(parseFloat(config.maxLength))]);
        }
        if (config.minLength || config.minLength === 0) {
            validator = Validators.compose([validator, Validators.minLength(parseFloat(config.minLength))]);
        }
        // Add provided custom validators to the validator function
        if (config.validators) {
            config.validators.forEach((validatorConfig: UiDynamicElementValidator) => {
                validator = Validators.compose([validator, validatorConfig.validator]);
            });
        }
        return validator;
    }
}

/**
 * INFO: add comment
 *
 * @param parent
 */
export function DYNAMIC_FORMS_PROVIDER_FACTORY(parent: UiDynamicFormsService): UiDynamicFormsService {
    return parent || new UiDynamicFormsService();
}

export const DYNAMIC_FORMS_PROVIDER: Provider = {
    // If there is already a service available, use that. Otherwise, provide a new one.
    provide: UiDynamicFormsService,
    deps: [[new Optional(), new SkipSelf(), UiDynamicFormsService]],
    useFactory: DYNAMIC_FORMS_PROVIDER_FACTORY,
};
