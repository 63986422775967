import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { makeAsyncActionReducer } from '@mona/store';
import { TelemedicineState } from '../../entities';
import { TelemedicineConfigAction } from '../actions/telemedicine-config.action';
import { TelemedicineSetupAction } from '../actions/telemedicine-setup.action';
import { telemedicineRegisterDeviceResultReducer } from './telemedicine-register-device-result.reducer';

/**
 * Telemedicine feature reducer token
 */
export const TELEMEDICINE_FEATURE_REDUCER = new InjectionToken<ActionReducerMap<TelemedicineState>>(
    'TELEMEDICINE_FEATURE_REDUCER',
);

/**
 * Telemedicine feature reducer
 */
export const telemedicineFeatureReducer: ActionReducerMap<TelemedicineState> = {
    registerDeviceAction: makeAsyncActionReducer(TelemedicineSetupAction.registerDevice),
    checkIsDeviceActivatedAction: makeAsyncActionReducer(TelemedicineSetupAction.checkIsDeviceActivated),
    loadCallConfigAction: makeAsyncActionReducer(TelemedicineConfigAction.loadCallConfig),
    registerDeviceResult: telemedicineRegisterDeviceResultReducer,
} as any;
