<div class="pre-medications" [formGroup]="formGroup">
    <!-- Loop with template -->
    <ng-template
        ngFor
        let-item
        [ngForOf]="formGroup?.controls.items.controls"
        [ngForTemplate]="formFields"
    ></ng-template>

    <!-- New Item  template (with `ngForContext`)-->
    <ng-template #formFields let-item let-i="index">
        <div class="pre-medications__form-wrapper">
            <app-pre-medication-form
                [isRequired]="isRequired"
                [isEditMode]="!isReadOnly"
                [formGroup]="item"
            ></app-pre-medication-form>
            <!--  -->
            <button
                *ngIf="!isReadOnly && item.valid"
                color="primary"
                (click)="removeItem(i, $event)"
                mat-icon-button
                matSuffix
                type="button"
                class="pre-medications__btn pre-medications__btn--delete"
                data-testid="btn-remove"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </ng-template>
    <!--  -->
    <button
        *ngIf="!isReadOnly"
        color="primary"
        [disabled]="formGroup.controls.items.invalid"
        (click)="addItem()"
        mat-icon-button
        matSuffix
        type="button"
        class="pre-medications__btn pre-medications__btn--add"
        data-testid="btn-add"
    >
        <mat-icon>add</mat-icon>
        {{ 'apps.admission.preMedication.newItem.label' | translate }}
    </button>
</div>
<ng-content></ng-content>
