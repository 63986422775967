/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AsyncReturnType } from 'type-fest';

type AbstractControlMethodName = 'markAsTouched' | 'markAsUntouched' | 'markAsDirty' | 'markAsPristine';

type MethodsMap = Partial<Record<AbstractControlMethodName, boolean>>;

type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

/**
 * Patches the method to first execute the provided function and then
 * the original functionality
 * @param obj Object with the method of interest
 * @param methodName Method name to patch
 * @param fn Function to execute before the original functionality
 */
export function patchObjectMethodWith<T, K extends FunctionPropertyNames<T>, F extends PickFunctionProperties<T>[K]>(
    obj: T,
    methodName: K,
    fn: F,
) {
    const originalFn = (obj[methodName] as any).bind(obj) as F;

    function updatedFn(...args: ArgumentsType<F>) {
        (fn as any)(...args);
        (originalFn as any)(...args);
    }

    obj[methodName] = updatedFn as unknown as T[K];
}

/**
 * Extract a touched changed observable from an abstract control
 * @param control AbstractControl
 * @usage
 * ```
 * const formControl = new FormControl();
 * const touchedChanged$ = extractTouchedChanges(formControl);
 * ```
 */
export function extractTouchedChanges(control: AbstractControl): Observable<boolean> {
    const methods: MethodsMap = {
        markAsTouched: true,
        markAsUntouched: false,
    };
    return extractMethodsIntoObservable(control, methods).pipe(distinctUntilChanged());
}

/**
 * Extract a dirty changed observable from an abstract control
 * @param control AbstractControl
 * @usage
 * ```
 * const formControl = new FormControl();
 * const dirtyChanged$ = extractDirtyChanges(formControl);
 * ```
 */
export function extractDirtyChanges(control: AbstractControl): Observable<boolean> {
    const methods: MethodsMap = {
        markAsDirty: true,
        markAsPristine: false,
    };
    return extractMethodsIntoObservable(control, methods).pipe(distinctUntilChanged());
}

function extractMethodsIntoObservable(control: AbstractControl, methods: MethodsMap) {
    const changes$ = new Subject<boolean>();

    Object.keys(methods).forEach(methodName => {
        const emitValue = methods[methodName as keyof MethodsMap];

        patchObjectMethodWith(control, methodName as FunctionPropertyNames<AbstractControl>, () => {
            changes$.next(emitValue as boolean);
        });
    });

    return changes$.asObservable();
}
