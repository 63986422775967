<!-- Custom placeholder, please do not use [placeholder] here -->
<label
    class="ui-table-filter__placeholder"
    [innerHTML]="'commonStrings.placeholders.filter' | translate: { count: value.length, total: options.length }"
    *ngIf="!value.length"
></label>
<!--  -->
<mat-select
    [formControl]="control"
    data-testid="table-filter"
    multiple
    class="ui-table-filter__select"
    [panelClass]="'ui-table-filter__panel scrollbar-visible'"
    (selectionChange)="onFilterChange($event)"
>
    <mat-select-trigger
        data-testid="table-filter-trigger"
        class="ui-table-filter__label"
        [innerHTML]="'commonStrings.placeholders.filter' | translate: { count: value.length, total: options.length }"
    ></mat-select-trigger>
    <mat-checkbox
        id="selectAll"
        data-testid="table-filter-selectAll"
        class="d-block select-all text-foreground"
        color="primary"
        (change)="onSelectAllOptions()"
        [checked]="isAllSelected(value, options)"
        [indeterminate]="!isAllSelected(value, options) && value.length !== 0"
    >
        {{ 'apps.patient.overview.history.types.all' | translate }}
    </mat-checkbox>
    <mat-option
        *ngFor="let item of options; let i = index"
        [value]="item"
        [id]="item.value"
        [attr.data-testid]="'table-filter-option-' + i"
    >
        <ng-container
            [ngTemplateOutlet]="optionTemplate || defaultTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-container>
        <ng-template #defaultTemplate>
            <span>
                {{ item?.label }}
                <span *ngIf="item?.hintText" class="text-muted">{{ item?.hintText }}</span>
            </span>
        </ng-template>
    </mat-option>
</mat-select>
