/**
 * Init worker
 *
 * ⚠️ That this is only available in ESM. Worker in CommonJS syntax is not supported by either webpack or Node.js.
 *
 * @param service
 * @see https://webpack.js.org/guides/web-workers/#syntax
 */
export function initWorker<D>(service: D & { workerMessagesMap: Map<any, any> }): void {
    if (typeof Worker === 'undefined') {
        return;
    }
    (service as any).worker = new Worker(new URL('@mona/workers/generate-date-times.worker.ts', import.meta.url), {
        type: 'module',
        name: 'generate-date-times',
    });
}
