import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SmartLabColumnsPipe } from './pipes/smart-lab-columns.pipe';
import { SmartLabsTableComponent } from './smart-labs-table.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [SmartLabsTableComponent, SmartLabColumnsPipe],
    exports: [SmartLabsTableComponent, SmartLabColumnsPipe],
    imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule],
})
export class SmartLabsTableModule {}
