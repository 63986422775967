<mat-dialog-content class="mona-sound-dialog__content">
    <h2 mat-dialog-title class="mona-sound-dialog__title">{{ 'soundDialog.title' | translate }}</h2>

    <div class="mona-sound-dialog__rec mat-elevation-z2" *ngIf="stream && !isSoundCaptured">
        <mona-mic-button
            class="mona-sound-dialog__rec-button"
            [isPulsing]="isRecording"
            (clicked)="toggleRecording()"
        ></mona-mic-button>
        <h3 class="mona-sound-dialog__rec-title">{{ 'soundDialog.recordLabel' | translate }}</h3>
    </div>

    <div class="mona-sound-dialog__result mat-elevation-z2" [class.mona-sound-dialog__result--hidden]="!isSoundCaptured">
        <audio class="mona-sound-dialog__audio" controls controlsList="nodownload" #localAudio></audio>
        <h3 class="mona-sound-dialog__result-title">{{ 'soundDialog.resultLabel' | translate }}</h3>
    </div>

    <div *ngIf="mediaError" class="mona-sound-dialog__error">
        <mat-icon class="text-warn">warning</mat-icon>
        <div class="mona-sound-dialog__error-text">{{ 'soundDialog.micFailed' | translate }}</div>
        <button mat-button class="mona-sound-dialog__error-details" color="primary" (click)="showErrorDetails()">
            {{ 'soundDialog.failedDetails' | translate }}
        </button>
    </div>

    <div class="mona-sound-dialog__actions">
        <button mat-button class="mona-sound-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'soundDialog.cancel' | translate }}
        </button>
        <button [disabled]="!isSoundCaptured" mat-raised-button color="primary" [mat-dialog-close]="true">
            {{ 'soundDialog.confirm' | translate }}
        </button>
    </div>
</mat-dialog-content>
