import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppError } from '@mona/shared/utils';

/**
 * Diagnostics item component
 */
@Component({
    selector: 'mona-diagnostics-item',
    templateUrl: './diagnostics-item.component.html',
    styleUrls: ['./diagnostics-item.component.scss'],
})
export class DiagnosticsItemComponent {
    /**
     * Loading
     */
    @Input() isLoading: boolean;
    /**
     * Error
     */
    @Input() error?: any;

    /**
     * Checking title
     */
    @Input() inProgressTitle: string;

    /**
     * Succeeded title
     */
    @Input() succeededTitle: string;

    /**
     * Failed title
     */
    @Input() failedTitle: string;

    /**
     * Url
     */
    @Input() url: string;

    /**
     * Has change button
     */
    @Input() hasChangeBtn: boolean;

    /**
     * Show error details
     */
    @Output() showErrorDetails: EventEmitter<AppError> = new EventEmitter();

    /**
     * Emits when change url button clicked
     */
    @Output() changeButtonClicked: EventEmitter<void> = new EventEmitter();

    /**
     * Required hint
     */
    @Input() requiredHint: string;
}
