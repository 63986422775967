/** Config Loader */
export abstract class ConfigLoader {
    /**
     * Load settings
     */
    abstract load(): Promise<any>;
}

/** Config Static Loader */
export class ConfigStaticLoader implements ConfigLoader {
    /**
     * Constructor
     *
     * @param providedSettings
     */
    constructor(private readonly providedSettings?: any) {}

    /**
     * Load settings
     */
    load(): Promise<any> {
        return Promise.resolve(this.providedSettings);
    }
}
