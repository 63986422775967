import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService , ApiModels } from '@mona/api';
import { BasicCareProcedure, Encounter } from '@mona/models';
import { transformApiBasicCareProcedures } from './transforms';

/**
 * API abstraction layer for the basic care procedures
 */
@Injectable({
    providedIn: 'root',
})
export class BasicCareProceduresApi {
    apiBase = '/pdms/basic-care-procedures/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get basic care procedures
     *
     * @param encounterId
     */
    getBasicCareProcedures(encounterId: EntityId<Encounter>): Observable<BasicCareProcedure[]> {
        return this.http
            .get<ApiModels.BasicCare[]>(this.apiBase, {
                params: {
                    encounter_id: `${encounterId}`,
                },
            })
            .pipe(map(apiBasicCareProcedures => transformApiBasicCareProcedures(apiBasicCareProcedures)));
    }
}
