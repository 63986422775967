import { addDays, addHours, isWithinInterval, subMinutes } from 'date-fns';
import { PractitionerShift, TaskListFilter } from '@mona/models';

/**
 * extend practitioner shifts with date fields and map to ${@link TaskListFilter}
 *
 * 1. Filters should mapped to {@link TaskListFilter} type
 * 2. Active filter should be the first one
 * 3. Other filters should be filters which are after active filter(including tomorrow but without duplicates)
 *
 * @param shifts
 */
export function extendPractitionerShiftsWithDateFields(shifts: PractitionerShift[]): TaskListFilter[] {
    if (!shifts?.length) {
        return [];
    }
    const filters: TaskListFilter[] = shifts.map(s => ({
        displayName: s.shift,
        interval: s.start, // NOTE: not actually needed
        startDate: s.startDate,
        endDate: s.endDate,
    }));

    const tomorrowFilters: TaskListFilter[] = filters.map(s => ({
        ...s,
        startDate: addDays(s.startDate, 1),
        endDate: addDays(s.endDate, 1),
    }));

    let activeShiftIdx = filters.findIndex(f => isWithinInterval(new Date(), { start: f.startDate, end: f.endDate }));

    if (activeShiftIdx === -1) {
        activeShiftIdx = 0;
    }

    const finalFilters: TaskListFilter[] = [];

    // add everything after active shift(included)
    for (let i = activeShiftIdx; i < filters.length; i++) {
        finalFilters.push(filters[i]);
    }

    // add everything from tomorrow until active shift(excluded)
    for (let i = 0; i < activeShiftIdx; i++) {
        finalFilters.push(tomorrowFilters[i]);
    }

    return finalFilters;
}

/**
 * extend task list filters with start and end date field
 *
 * @param filters
 * @param overdueTime
 */
export function extendTaskListFiltersWithStartEndFields(
    filters: TaskListFilter[],
    overdueTime: number,
): TaskListFilter[] {
    if (!filters?.length) {
        return [];
    }

    const now = subMinutes(new Date(), overdueTime);

    return filters.map(f => ({
        ...f,
        startDate: now,
        endDate: addHours(now, +f.interval.split(':')[0]),
    })) as TaskListFilter[];
}
