<div class="ui-dynamic-select-wrapper">
    <mat-form-field [attr.data-testid]="name" [id]="name" class="ui-dynamic-select-field" [ngClass]="customClass">
        <mat-label *ngIf="label || placeholder">{{ label || placeholder }}</mat-label>
        <mat-select
            [formControl]="control"
            [placeholder]="label || placeholder"
            [required]="required"
            [attr.name]="name"
            [attr.data-testid]="name"
            [multiple]="multiple"
            [value]="control?.value | dataAccessor: valueKey"
            [compareWith]="compareFn"
            panelClass="ui-dynamic-select-panel scrollbar-visible {{ panelClass }}"
            [disableOptionCentering]="true"
            (selectionChange)="submitOnSelect ? form.ngSubmit.emit() : {}"
        >
            <mat-option *ngIf="showEmptyOption" [attr.data-testid]="name + '_empty'" disabled>
                {{ placeholder }}
            </mat-option>
            <mat-option
                *ngFor="let selection of dataSource.filteredData; let i = index"
                [value]="selection[valueKey] || selection"
                [attr.data-testid]="name + '_option_' + i"
            >
                {{ selection[labelKey] || selection }}
            </mat-option>
        </mat-select>
        <mat-hint>{{ hint }}</mat-hint>
        <mat-error [hidden]="hideValidationMessage">
            <ng-template
                [ngTemplateOutlet]="errorMessageTemplate"
                [ngTemplateOutletContext]="{ control: control, errors: control?.errors }"
            ></ng-template>
            <ui-validation-message [control]="control"></ui-validation-message>
        </mat-error>
    </mat-form-field>
</div>
