import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UtilsModule } from '@mona/shared/utils';
import { UiAppBarModule } from '../../app-bar';
import { UiDropdownToolbarModule } from '../../dropdown-toolbar';
import { UiThreeLinerModule } from '../../three-liner';
import { UiSpacerModule } from '../../utils';
import { DrawerNavBarModule } from '../drawer-navbar';
import { DrawerContentComponent } from './content/drawer-content.component';
import { DrawerLayoutComponent } from './drawer-layout.component';
import {
    DrawerFabTemplateDirective,
    DrawerPortalsComponent,
    DrawerTemplateDirective,
    DrawerHeaderTemplateDirective,
    DrawerToolbarActionsTemplateDirective,
    DrawerToolbarSubTitleTemplateDirective,
    DrawerToolbarTitleTemplateDirective,
} from './portals/drawer-portals.component';

const COMPONENTS = [
    DrawerLayoutComponent,
    DrawerPortalsComponent,
    //
    DrawerTemplateDirective,
    DrawerHeaderTemplateDirective,
    DrawerFabTemplateDirective,
    DrawerToolbarTitleTemplateDirective,
    DrawerToolbarSubTitleTemplateDirective,
    DrawerToolbarActionsTemplateDirective,
];

/**
 * DrawerLayoutModule
 */
@NgModule({
    declarations: COMPONENTS,
    imports: [
        UtilsModule,
        PortalModule,
        MatSidenavModule,
        MatIconModule,
        MatButtonModule,
        DrawerNavBarModule,
        UiAppBarModule,
        UiDropdownToolbarModule,
        UiSpacerModule,
        UiThreeLinerModule,
        DrawerContentComponent,
    ],
    exports: [...COMPONENTS, DrawerContentComponent],
})
export class DrawerLayoutModule {}
