/**
 * Possible call ws events
 */
export enum CallWsEvent {
    /**
     * Triggered from server when all callees available
     */
    ALL_CALLEES_AVAILABLE = 'ALL_CALLEES_AVAILABLE',

    /**
     * Send/receive ice candidates
     */
    SEND_ICE_CANDIDATES = 'SEND_ICE_CANDIDATES',

    /**
     * Send web rtc offer
     */
    WEB_RTC_OFFER = 'WEB_RTC_OFFER',

    /**
     * Receive web rtc answer
     */
    WEB_RTC_ANSWER = 'WEB_RTC_ANSWER',

    /**
     * Message from server when another callee left
     */
    CALLEE_LEFT = 'CALLEE_LEFT',

    /**
     * Should be triggered to set call on hold
     */
    ON_HOLD = 'ON_HOLD',

    /**
     * Should be triggered to hang up
     */
    HANG_UP = 'HANG_UP',

    /**
     * Toggle Video On / Off
     */
    TOGGLE_VIDEO = 'TOGGLE_VIDEO',

    /**
     * Toggle screen share
     */
    TOGGLE_SCREEN_SHARE = 'TOGGLE_SCREEN_SHARE',
}
