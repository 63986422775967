/* eslint-disable @angular-eslint/no-output-native, @typescript-eslint/member-ordering */
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router, RouterLinkActive } from '@angular/router';
import { isFunction } from '@mona/shared/utils';
import { Animations } from '@mona/ui/animations';
import { MessageService } from '@mona/ui/components/message';
import { UiPopoverComponent } from '@mona/ui/components/overlay-container';
import { isEmptyView } from '../../../../utils/utils';
import { DrawerNavItem } from '../../drawer-nav-item.model';
import { DrawerService } from '../../drawer.service';
import { StateListener } from '../../state-listener.component';

/**
 * INFO: add comment
 */
export type ActiveItemBackgroundShape = 'round' | 'square';

/**
 * [DrawerNavItem Component](https://brightlayer-ui-components.github.io/angular/?path=/info/components-drawer--readme)
 *
 * The `<ui-drawer-nav-item>` is a wrapper to the `<ui-info-list-item>` that is meant to be used within a `<ui-nav-group>`.
 */
@Component({
    selector: 'ui-drawer-nav-item',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./drawer-nav-item.component.scss'],
    template: `
        <ng-container *ngIf="!hidden && depth">
            <ng-template #navIcon>
                <ng-content select="[ui-icon]"></ng-content>
            </ng-template>
            <div
                class="ui-drawer-nav-item-content"
                #rla="routerLinkActive"
                [routerLink]="link"
                routerLinkActive="ui-drawer-nav-item-active"
                (isActiveChange)="onRouterLinkActive($event)"
                queryParamsHandling="merge"
                #navItem
                [class.ui-drawer-nav-item-active]="selected || rla.isActive"
                [class.ui-drawer-nav-item-compact]="false"
                [attr.satPopoverAnchor]="popover"
                [attr.data-testid]="'module' + title"
            >
                <div
                    matRipple
                    [class.ui-drawer-nav-item-active-highlight]="selected || rla.isActive"
                    [class.ui-drawer-nav-item-active-round]="
                        activeItemBackgroundShape === 'round' && isOpen() && !isRail()
                    "
                    [class.ui-drawer-nav-item-active-square]="
                        activeItemBackgroundShape === 'square' || !isOpen() || isRail()
                    "
                >
                    <ui-info-list-item
                        *ngIf="!isRail()"
                        (click)="selectItem($event)"
                        [dense]="true"
                        [statusColor]="statusColor"
                        [chevron]="chevron && isOpen()"
                        [hidePadding]="hidePadding"
                        [divider]="divider ? 'full' : undefined"
                        [disabled]="disabled"
                        [class.ui-drawer-nav-item-no-icon-closed]="isEmpty(iconEl) && !isOpen()"
                    >
                        <ng-container ui-icon #icon>
                            <ng-container *ngTemplateOutlet="navIcon"></ng-container>
                        </ng-container>
                        <div
                            ui-title
                            [class.ui-drawer-nav-item-depth-1]="depth === 1"
                            [class.ui-drawer-nav-item-depth-2]="depth === 2"
                            [class.ui-drawer-nav-item-depth-3]="depth === 3"
                        >
                            <span>{{ title | translate }}</span>
                            <span *ngIf="hasMark" class="ui-drawer-nav-item-asterisk-icon ui-drawer-nav-item-has-mark">
                                *
                            </span>
                            <span *ngIf="counter" class="ui-drawer-nav-item-counter">
                                <span class="ui-drawer-nav-item-counter-content" [style.backgroundColor]="counterColor">
                                    {{ counter > 99 ? '99+' : counter }}
                                </span>
                            </span>
                            <mat-icon class="ui-drawer-nav-item-validation" *ngIf="validationError">error</mat-icon>
                        </div>
                        <div ui-subtitle>{{ subtitle | translate }}</div>
                        <div ui-right-content *ngIf="hasChildren && isOpen()">
                            <div #expandIcon *ngIf="!expanded">
                                <ng-content select="[ui-expand-icon]"></ng-content>
                            </div>
                            <div #collapseIcon *ngIf="expanded">
                                <ng-content select="[ui-collapse-icon]"></ng-content>
                            </div>
                            <mat-icon
                                *ngIf="isEmpty(collapseIconEl) && isEmpty(expandIconEl)"
                                class="ui-drawer-nav-item-expand-icon"
                                [class.ui-drawer-nav-item-expanded]="expanded"
                            >
                                {{ depth > 1 ? 'arrow_drop_down' : 'expand_more' }}
                            </mat-icon>
                        </div>

                        <div ui-right-content *ngIf="disabled && isOpen()">
                            <mat-icon class="ui-drawer-nav-item-disabled-icon">lock</mat-icon>
                        </div>

                        <div ui-right-content *ngIf="!!subItems?.length">
                            <mat-icon class="ui-drawer-nav-item-disabled-icon">
                                {{ isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
                            </mat-icon>
                        </div>
                    </ui-info-list-item>

                    <div class="ui-drawer-nav-item-rail-container" *ngIf="isRail()">
                        <div (click)="selectItem($event)" class="ui-drawer-nav-item-rail">
                            <ng-container *ngTemplateOutlet="navIcon"></ng-container>
                            <div class="ui-drawer-nav-item-rail-text">{{ title | translate }}</div>
                        </div>
                        <mat-divider *ngIf="divider"></mat-divider>
                    </div>

                    <div
                        *ngIf="!!subItems?.length"
                        class="sub-items"
                        [@collapse]="{ value: isExpanded, params: { duration: 250 } }"
                    >
                        <ui-drawer-nav-subitem
                            *ngFor="let subItem of subItems"
                            [subItem]="subItem"
                            [activeSubItemId]="activeSubItemId"
                            (handleEvent)="handleSubItemEvent(subItem)"
                        ></ui-drawer-nav-subitem>
                    </div>
                </div>
            </div>

            <!-- Nested Nav Items -->
            <!-- New implementation of sub items was added above, because below logic doesn't work well -->
            <mat-accordion displayMode="flat" class="ui-drawer-nested-nav-item" *ngIf="!isRail()">
                <mat-expansion-panel #matExpansionPanel>
                    <ng-content select="[ui-drawer-nav-item]"></ng-content>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    `,
    animations: [Animations.collapseAnimation],
    host: {
        class: 'ui-drawer-nav-item',
    },
})
export class DrawerNavItemComponent
    extends StateListener
    implements Omit<DrawerNavItem, 'items'>, AfterContentInit, AfterViewInit, OnChanges
{
    /**
     * Sets the active item background shape
     *
     * `square` - Background shape takes the entire height of width of the NavItem.
     * `round` - Background shape has a rounded corner towards the end of the NavItem.
     *
     * @default square
     */
    @Input() activeItemBackgroundShape: ActiveItemBackgroundShape = 'square';
    /**
     * Sets whether to show a chevron icon on the left side of a `NavItem`
     *
     * @default false
     */
    @Input() chevron = false;
    /**
     * Whether to show a dividing line below each NavItem
     *
     * @default false
     */
    @Input() divider = false;
    /**
     * Sets whether to show nested nav items
     *
     * @default false
     */
    @Input() expanded = false;
    /** Sets whether to show/hide padding whenever a NavItem does not have an icon */
    @Input() hidePadding: boolean;
    /**
     * Sets whether to hide the nav item
     *
     * @default false
     */
    @Input() hidden = false;
    /**
     * Function to set whether to hide the nav item
     */
    @Input() hideWhen: DrawerNavItem['hideWhen'];
    /**
     * Sets whether to disable the nav item
     *
     * @default false
     */
    @Input() disabled = false;
    /**
     * Sets whether there are unsaved changes for item
     *
     * @default false
     */
    @Input() hasMark = false;

    /** sub items */
    @Input() subItems!: Array<Omit<DrawerNavItem, 'icon'>>;

    /** title counter */
    @Input() counter!: number;

    /** counter color */
    @Input() counterColor!: string;
    /**
     * Function to set whether to disable the nav item
     */
    @Input() disableWhen: DrawerNavItem['disableWhen'];
    /**
     * Sets whether to show/hide Angular ripple animation effect onClick
     *
     * @default true
     */
    @Input() ripple = true;
    /** Sets whether an item is selected.  A selected item will have the `activeBackgroundShape` applied to it and appear different from all other NavItems.*/
    @Input() selected: boolean;
    /** Left border color */
    @Input() statusColor: string;
    /** Text to display as a subtitle */
    @Input() subtitle: string;
    /** Text to display as a title */
    @Input() title: string;
    /** Text to display as popover when access is forbidden */
    @Input() forbiddenText = 'Text to display as popover when access is forbidden';
    /** Link to route */
    @Input() link: string | string[];
    /** activeSubItemId */
    @Input() activeSubItemId: string;
    /** Is validation error on tab */
    @Input() validationError = false;

    /** Event triggered on nav item select */
    @Output() select: EventEmitter<string> = new EventEmitter<string>();

    @ContentChildren(DrawerNavItemComponent, { descendants: false }) nestedNavItems;
    @ViewChild('expandIcon') expandIconEl: ElementRef;
    @ViewChild('icon') iconEl: ElementRef;
    @ViewChild('collapseIcon') collapseIconEl: ElementRef;
    @ViewChild('navItem') navItemEl: ElementRef;
    @ViewChild('matExpansionPanel') matExpansionPanel: MatExpansionPanel;
    @ViewChild('rla') rla: RouterLinkActive;
    @ViewChild('popover') popover: UiPopoverComponent;

    isEmpty = (el: ElementRef): boolean => isEmptyView(el);

    /** Controls the expansion icon. */
    hasChildren = false;
    /** isExpanded */
    isExpanded = false;
    /** Each navigation item in the drawer is assigned a unique id; this is used later when iterating through potential nav item children. */
    id: number;
    /**
     * The depth of the navigation item when appearing within a nested structure.
     *  Depth is populated by iterating through the Drawer navigation tree.  See DrawerNavGroupComponent for details.
     */
    depth: number;

    /**
     * INFO: add comment
     *
     * @param drawerService
     * @param changeDetectorRef
     * @param router
     * @param messageService
     */
    constructor(
        drawerService: DrawerService,
        changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private messageService: MessageService,
    ) {
        super(drawerService, changeDetectorRef);
        this.id = drawerService.createNavItemID();
        this.drawerService.emitNewNavItemCreated();
        this.drawerService.drawerOpenChanges().subscribe(() => {
            this.handleExpand();
        });
        this.drawerService.drawerActiveItemChanges().subscribe(() => {
            if (this.navItemEl) {
                this.manageActiveItemTreeHighlight();
            }
        });
    }

    /**
     * INFO: add comment
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selected) {
            this.drawerService.emitChangeActiveItemEvent();
        }
        if (changes.expanded !== undefined && !changes.expanded.isFirstChange() && this.matExpansionPanel) {
            this.handleExpand();
        }
        if (isFunction(changes.disableWhen?.currentValue)) {
            this.disabled = !!this.disableWhen();
        }
    }

    /**
     * triggerChangeDetection
     */
    triggerChangeDetection(): void {
        this.changeDetector.markForCheck();
    }

    /**
     * INFO: add comment
     */
    ngAfterViewInit(): void {
        this.handleExpand();
    }

    /**
     * INFO: add comment
     */
    ngAfterContentInit(): void {
        // If ContentChildren is self-inclusive (ng version < 9), filter self out using service-generated NavItem ID.
        this.nestedNavItems = this.nestedNavItems.filter((item: DrawerNavItemComponent) => item.id !== this.id);
    }

    /**
     * INFO: add comment
     */
    handleExpand(): void {
        if (!this.matExpansionPanel) {
            return;
        }

        setTimeout(() => {
            // Persistent drawers will only expand if they the drawer is opened.
            // Temporary drawers will always have any expansion panels opened.
            if (this.expanded && (this.isOpen() || this.drawerService.getDrawerVariant() === 'temporary')) {
                this.matExpansionPanel.open();
            } else {
                this.matExpansionPanel.close();
            }
        });
    }

    /**
     * Handle click if disabled
     *
     * @param event
     */
    handledisableWhen(event: Event) {
        if (isFunction(this.disableWhen) && this.disableWhen()) {
            this.disabled = true;
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            // this.popover.toggle(); // FIXME: was satPopover
        }
    }

    /**
     * Is router link active
     *
     * @param event
     */
    onRouterLinkActive(event?: boolean) {
        if (event) {
            // this.selectItem();
        }
    }

    /**
     * INFO: add comment
     * @param subItem
     */
    handleSubItemEvent(subItem: Omit<DrawerNavItem, 'icon'>): void {
        this.messageService.infoToast('apps.patient.externalResources.cautionNotification');
        this.router.navigate([this.link], { queryParams: { activeSubItemId: subItem.key } });
        subItem.click();
    }

    /**
     * Is rail
     */
    isRail(): boolean {
        return this.drawerService.getDrawerVariant() === 'rail';
    }

    /**
     * Is rail condensed
     */
    isRailCondensed(): boolean {
        return this.drawerService.isRailCondensed();
    }

    /**
     * Show tooltip on rail hover
     */
    showTooltipOnRailHover(): boolean {
        return !this.drawerService.isDisableRailTooltip();
    }

    /** Whenever the selected item in the drawer state changes, we need to check to see we should apply a hierarchical highlight. */
    manageActiveItemTreeHighlight(): void {
        if (!this.navItemEl) {
            return;
        }

        this.navItemEl.nativeElement.classList.remove('ui-drawer-nav-item-active-tree');
        if (this.drawerService.hasDisableActiveItemParentStyles()) {
            return;
        }

        // Add tree highlighting for selected items
        if (this.selected && this.depth > 1) {
            let parent = this.navItemEl.nativeElement.parentNode;
            while (parent.parentNode) {
                parent = parent.parentNode;
                const navItem = parent.firstElementChild;
                if (navItem.classList.contains('ui-drawer-nav-item-content')) {
                    navItem.classList.add('ui-drawer-nav-item-active-tree');
                }
            }
        }
    }

    /**
     * Listen for drawer changes
     */
    listenForDrawerChanges(): void {
        this.drawerOpenListener = this.drawerService.drawerOpenChanges().subscribe(() => {
            // Two detections are required to get the custom icons to work.
            // First tick causes the icons to container to reappear.
            // Second tick handles isEmpty function calls.
            this.changeDetector.detectChanges();
            this.changeDetector.detectChanges();
        });
    }

    /**
     * A top-level navigation item has a depth of 1.
     *
     * @param parentDepth
     */
    incrementDepth(parentDepth: number): void {
        if (parentDepth === 0) {
            this._setNavItemDefaults();
        } else {
            this._setNestedNavItemDefaults();
        }
        this.depth = parentDepth + 1;
        this.hasChildren = this.nestedNavItems && this.nestedNavItems.length > 0;
        this.changeDetector.detectChanges();
    }

    /** Sets default state values for non-nested nav items. Invoked by DrawerNavGroupComponent on content init. */
    private _setNavItemDefaults(): void {
        if (this.divider === undefined) {
            this.divider = true;
        }
        if (this.hidePadding === undefined) {
            this.hidePadding = true;
        }
    }

    /** Sets default state values for nested nav items. */
    private _setNestedNavItemDefaults(): void {
        if (this.divider === undefined) {
            this.divider = false;
        }
        if (this.hidePadding === undefined) {
            this.hidePadding = false;
        }
    }

    /**
     * Select item
     *
     * @param event
     */
    selectItem(event: Event): void {
        this.handledisableWhen(event);
        this.drawerService.select(this.hasChildren);
        this.select.emit();
        if (this.hasChildren) {
            this.toggleNestedNavItems();
            this.changeDetector.detectChanges();
        }

        if (this.subItems?.length) {
            this.isExpanded = !this.isExpanded;
        }
    }

    /**
     * Toggle nested nav items
     */
    toggleNestedNavItems(): void {
        this.expanded = !this.expanded;
        this.handleExpand();
    }
}
