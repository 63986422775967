import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { EMPTY, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

/**
 * This token is used to provide a shared Observable based on `window.requestIdleCallback`
 */
export const REQUEST_IDLE_CALLBACK = new InjectionToken<Observable<DOMHighResTimeStamp>>(
    'Shared Observable based on `window.requestIdleCallback`',
    {
        factory: () => {
            const { requestIdleCallback, cancelIdleCallback } = inject(WINDOW) as Window;
            if (!requestIdleCallback) {
                return EMPTY;
            }
            const requestIdleCallback$ = new Observable<DOMHighResTimeStamp>(subscriber => {
                let id = NaN;
                const callback = (deadline: IdleDeadline) => {
                    subscriber.next(deadline.timeRemaining());
                    id = requestIdleCallback(callback);
                };

                return () => cancelIdleCallback(id);
            });

            return requestIdleCallback$.pipe(share());
        },
    },
);
