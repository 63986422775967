import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { VaccinationStatusType } from '@mona/models';
import { VaccinationStatusState } from '../../entities';
import { VaccinationsStatusAction } from '../actions';

export const vaccinationStatusAdapter: EntityAdapter<VaccinationStatusType> =
    createEntityAdapter<VaccinationStatusType>();

export const initialState: VaccinationStatusState = vaccinationStatusAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const vaccinationStatusReducer = createReducer(
    initialState,
    on(VaccinationsStatusAction.loadVaccinations, state => ({ ...state, isLoading: true })),
    on(VaccinationsStatusAction.loadVaccinationsSuccess, (state, { data }) =>
        vaccinationStatusAdapter.setAll(data, {
            ...state,
            error: null,
            isLoading: false,
        }),
    ),
    on(VaccinationsStatusAction.loadVaccinationsFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(VaccinationsStatusAction.updateVaccinations, (state, action) =>
        vaccinationStatusAdapter.updateMany(action.update, state),
    ),
    on(VaccinationsStatusAction.upsertVaccinations, (state, action) =>
        vaccinationStatusAdapter.upsertMany(action.data, state),
    ),
    on(VaccinationsStatusAction.clearVaccinations, state => vaccinationStatusAdapter.removeAll(state)),
    on(VaccinationsStatusAction.addVaccinationsChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = vaccinationStatusAdapter.removeMany(toRemoveIds, state);

        return vaccinationStatusAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
