import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { LegendItem, UI_CHART_COLORS } from '../models';

/**
 * UiChartColor directive
 */
@Directive({
    selector: 'mat-list-option[uiLegendItemColor]',
})
export class UiLegendItemColorDirective implements AfterViewInit {
    /** Legend item color */
    @Input() uiLegendItemColor: number;
    /** Checkbox selector */
    @Input() selector = '.mdc-checkbox__background';
    /** Legend item */
    @Input() legendItem: LegendItem;
    /** Is mat-list-option selected */
    @Input() set selected(isSelected: boolean) {
        // not working w/o timeout(no checkbox el)
        setTimeout(() => this.setChecboxBgColor(isSelected), 0);
    }
    /**
     * constructor
     *
     * @param matListOptionEl
     */
    constructor(private matListOptionEl: ElementRef) {}

    /** After view init */
    ngAfterViewInit(): void {
        this.setCheckboxInitialColoring();
    }

    /** Get checkbox element */
    get checkboxEl(): HTMLElement {
        return this.matListOptionEl.nativeElement.querySelector(this.selector);
    }

    /** Get color */
    get color(): string {
        return this.legendItem?.color || UI_CHART_COLORS[this.uiLegendItemColor];
    }

    /** Set checkbox color */
    private setCheckboxInitialColoring(): void {
        if (this.checkboxEl) {
            this.checkboxEl.style.color = this.color;
            this.checkboxEl.style['border-color'] = this.color;
        }
    }

    /**
     * Set checkbox background
     *
     * @param isSelected
     */
    private setChecboxBgColor(isSelected: boolean): void {
        if (this.checkboxEl) {
            this.checkboxEl.style.background = isSelected ? this.color : 'transparent';
        }
    }
}
