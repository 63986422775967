import { EntityState } from '@ngrx/entity';
import { PatientOutput, MedicationAdministration, BalanceValue } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';
import { EntitiesState } from '@mona/store';
import { OutputsMap } from '../models';

/**
 * EntityState<BalanceValue>
 */
export interface BalanceValueState extends EntityState<BalanceValue> {
    /** cached balance values */
    cachedBalanceValues: Record<EntriesInterval, any>;
}

/** Balance active table enum */
export enum BalanceTableEnum {
    Inputs = 0,
    Outputs = 1,
    BalanceTargets = 2,
}

/**
 * Balance state
 */
export interface BalanceState {
    /**
     * Active table
     */
    activeTable: BalanceTableEnum;
    /**
     * Is loading
     */
    isLoading: boolean;
    /**
     * Selected interval for displayed vital signs
     */
    interval: EntriesInterval;

    /**
     * Outputs
     */
    outputs: EntitiesState<PatientOutput>;

    /**
     * Outputs sorted by date and grouped by type
     */
    outputsMap: OutputsMap;

    /**
     * Medication administrations
     */
    medicationAdministrations: EntityState<MedicationAdministration>;

    /**
     * Balance values
     */
    balanceValues: BalanceValueState;
}
