import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Makes safe resource url from base 64
 */
@Pipe({
    name: 'safeImage',
})
export class SafeImagePipe implements PipeTransform {
    /**
     * Constructor
     * @param sanitizer DomSanitizer
     */
    constructor(private sanitizer: DomSanitizer) {}

    /**
     * Implementation
     * @param base6Src string
     */
    transform(base6Src: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${base6Src}`);
    }
}
