import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { Bed, Ward, WardWithBeds } from '@mona/models';
import { DataAccessWardsState } from '../entities';
import {
    BedsActions,
    BedsSelectors,
    selectAllBedsWithEncounters,
    selectAllWardsWithBeds,
    selectBedByIdWithWard,
    selectEmptyBeds,
    WardsActions,
    WardsSelectors,
} from '../state';

/**
 * Wards service
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessWardsFacade {
    /** All Wards */
    wards$: Observable<Ward[]> = this.store.select(WardsSelectors.selectAllWards);
    /** Selected Ward */
    selectedWard$: Observable<Ward> = this.store.select(WardsSelectors.selectSelectedWard);
    /** Selected Ward Id */
    selectedWardId$: Observable<EntityId<Ward>> = this.store.select(WardsSelectors.selectSelectedWardId);
    /** Selected Bed Id */
    assignedWardId$: Observable<EntityId<Ward>> = this.store.select(WardsSelectors.selectAssignedWardId);
    /** All Beds */
    beds$: Observable<Bed[]> = this.store.select(BedsSelectors.selectAllBeds);
    /** Selected Bed */
    selectedBed$: Observable<Bed> = this.store.select(BedsSelectors.selectSelectedBed);
    /** Selected Bed Id */
    selectedBedId$: Observable<EntityId<Bed>> = this.store.select(BedsSelectors.selectSelectedBedId);
    /** Selected Bed Id */
    assignedBedId$: Observable<EntityId<Bed>> = this.store.select(BedsSelectors.selectAssignedBedId);
    /** Beds by selected ward */
    bedsWithEncounters$: Observable<Bed[]> = this.store.select(selectAllBedsWithEncounters);
    /** Get empty beds - without encounters end not current selected or assigned */
    emptyBeds$: Observable<Bed[]> = this.store.select(selectEmptyBeds);
    /** Is loading */
    isLoading$: Observable<boolean> = merge(
        this.store.select(BedsSelectors.selectBedsIsLoading),
        this.store.select(WardsSelectors.selectWardsIsLoading),
    );

    /**
     * Constructor
     *
     * @param {Store} store - store
     */
    constructor(private store: Store<{ wardsData: DataAccessWardsState }>) {}

    //#region Selectors

    /**
     * Get bed with ward
     *
     * @param bedId bedId
     */
    getBedWithWard(bedId: string): Observable<Bed> {
        return this.store.select(selectBedByIdWithWard(bedId));
    }

    /**
     * Get wards with all OR only empty beds
     *
     * @param onlyEmpty - without encounters end not current selected or assigned
     */
    getWardsWithBeds(onlyEmpty?: boolean): Observable<WardWithBeds[]> {
        return this.store.select(selectAllWardsWithBeds(onlyEmpty));
    }

    //#endregion Selectors

    //#region Actions

    /**
     * Triggers wards loading
     */
    loadWards() {
        this.store.dispatch(WardsActions.load());
    }

    /**
     * Update the wards state by extending current state with payload from Subscription message
     *
     * @param ward
     */
    insertOrUpdateWard(ward: Ward) {
        this.store.dispatch(WardsActions.upsertWard({ ward }));
    }

    /**
     * Remove specific Ward (from state)
     *
     * @param ward
     */
    removeWard(ward: Ward) {
        this.store.dispatch(WardsActions.removeWard({ ward }));
    }

    /**
     * Triggers wards loading
     *
     * @param wardId
     */
    selectWard(wardId?: EntityId<Ward>) {
        WardsSelectors.selectSelectedWard.release();
        this.store.dispatch(WardsActions.selectWard({ wardId }));
    }

    /**
     * Set assigned ward
     *
     * @param wardId
     */
    setAssignedWard(wardId?: EntityId<Ward>) {
        this.store.dispatch(WardsActions.setAssignedWardId({ wardId }));
    }

    /**
     * Triggers beds loading
     *
     * @param wardId
     */
    loadBeds(wardId?: EntityId<Ward>) {
        this.store.dispatch(BedsActions.load({ wardId }));
    }

    /**
     * Update the Beds state by extending current state with payload from Subscription message
     *
     * @param bed
     */
    insertOrUpdateBed(bed: Bed) {
        this.store.dispatch(BedsActions.upsertBed({ bed }));
    }

    /**
     * Remove specific Bed (from state)
     *
     * @param bed
     */
    removeBed(bed: Bed) {
        this.store.dispatch(BedsActions.removeBed({ bed }));
    }

    /**
     * Set selected bed
     *
     * @param bedId
     * @param bed
     * @param isReviewMode
     * @param fromEncounter
     */
    selectBed(bedId: string, bed?: Bed, isReviewMode?: boolean, fromEncounter?: string) {
        this.store.dispatch(BedsActions.selectBed({ bedId, bed, isReviewMode, fromEncounter }));
    }

    /**
     * Set assigned bed
     *
     * @param bedId
     */
    setAssignedBed(bedId?: EntityId<Bed>) {
        this.store.dispatch(BedsActions.setAssignedBedId({ bedId }));
    }

    //#endregion Actions
}
