import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { exhaustMap, map, switchMap } from 'rxjs/operators';
import { ApiModels, getHttpHeaderByKey, HttpService, replaceUrlProtocol } from '@mona/api';
import { Practitioner, PrescriptionSet } from '@mona/models';
import { transformApiPrescriptionSet, transformApiPrescriptionSets } from './transforms';

/**
 * API abstraction layer for the prescription sets API
 */
@Injectable({
    providedIn: 'root',
})
export class PrescriptionSetsApi {
    apiBase = '/pdms/prescription-sets/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get prescription sets
     */
    getPrescriptionSets(): Observable<PrescriptionSet[]> {
        return this.http
            .get<ApiModels.PrescriptionSet[]>(this.apiBase)
            .pipe(map(apiPrescriptionSets => transformApiPrescriptionSets(apiPrescriptionSets)));
    }

    /**
     * Get specific prescription set by url
     *
     * @param id string
     */
    getPrescriptionSetById(id: string): Observable<PrescriptionSet> {
        return this.http
            .get<ApiModels.PrescriptionSet>(`${this.apiBase}${id}/`)
            .pipe(map(apiPrescriptionSet => transformApiPrescriptionSet(apiPrescriptionSet)));
    }

    /**
     * Create prescription set
     *
     * TODO: makes unnecessary action to fetch result of POST/PUT because of API
     *
     * @param name string
     * @param practitionerId EntityId<Practitioner>
     */
    createPrescriptionSet(name: string, practitionerId: EntityId<Practitioner>): Observable<PrescriptionSet> {
        return this.http
            .request<ApiModels.PrescriptionSet>('POST', this.apiBase, {
                body: {
                    name,
                    last_changed_by: practitionerId,
                },
                observe: 'response',
                responseType: 'json',
            })
            .pipe(
                map(({ headers, url }) => ({ headers, url })),
                exhaustMap(({ headers, url }) => {
                    const resourseUrl = replaceUrlProtocol(getHttpHeaderByKey(headers, 'location'), url);
                    return this.http.get<ApiModels.PrescriptionSet>(resourseUrl);
                }),
                map(apiPrescriptionSet => transformApiPrescriptionSet(apiPrescriptionSet)),
            );
    }

    /**
     * Update prescription set
     *
     * TODO: makes unnecessary action to fetch result of POST/PUT because of API
     *
     * @param id EntityId<PrescriptionSet>
     * @param name string
     * @param practitionerId EntityId<Practitioner>
     */
    updatePrescriptionSet(
        id: EntityId<PrescriptionSet>,
        name: string,
        practitionerId: EntityId<Practitioner>,
    ): Observable<PrescriptionSet> {
        return this.http
            .request<ApiModels.PrescriptionSet>('PUT', `${this.apiBase}${id}/`, {
                body: {
                    name,
                    last_changed_by: practitionerId,
                },
                observe: 'response',
            })
            .pipe(
                map(({ headers, url }) => ({ headers, url })),
                switchMap(({ headers, url }) => {
                    const resourseUrl = replaceUrlProtocol(getHttpHeaderByKey(headers, 'location'), url);
                    return this.http.get<ApiModels.PrescriptionSet>(resourseUrl);
                }),
                map(apiPrescriptionSet => transformApiPrescriptionSet(apiPrescriptionSet)),
            );
    }

    /**
     * Removes prescription set
     *
     * @param id EntityId<PrescriptionSet>
     */
    removePrescriptionSet(id: EntityId<PrescriptionSet>): Observable<void> {
        return this.http.delete<void>(`${this.apiBase}${id}/`);
    }

    // =========================================================================

    /**
     * Create prescription set medication
     *
     * @param payload
     */
    upsertPrescriptionSetMedication(payload: any): Observable<any> {
        return this.http.request<ApiModels.PrescriptionSet>('POST', this.apiBase, {
            body: {},
            observe: 'response',
            responseType: 'json',
        });
    }
}
