import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@mona/shared/utils';
import { UiButtonExtensionModule } from '@mona/ui';
import { ActiveCallDialog } from './active-call-dialog.component';

/**
 * SoundDialogModule
 */
@NgModule({
    declarations: [ActiveCallDialog],
    exports: [ActiveCallDialog],
    imports: [
        //
        UtilsModule,
        UiButtonExtensionModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
    ],
})
export class ActiveCallDialogModule {}
