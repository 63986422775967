/* eslint-disable @typescript-eslint/ban-types */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Constructor } from 'type-fest';
import { isEmpty } from '@mona/shared/utils';
import { CanDisable } from '../models';

/**
 * Mixin to augment a component or directive with a `disabled` property.
 *
 * @param base
 */
export function mixinDisabled<T extends Constructor<{ cdRef?: ChangeDetectorRef; stateChanges?: Subject<any> }>>(
    base: T,
): Constructor<CanDisable> & T {
    return class extends base {
        private _disabled = false;

        constructor(...args: any[]) {
            super(...args);
        }

        get disabled(): boolean {
            return this._disabled;
        }
        set disabled(value: boolean) {
            if (!isEmpty(value)) {
                const newValue: boolean = coerceBooleanProperty(value);
                if (this._disabled !== newValue) {
                    this._disabled = newValue;
                    this.onDisabledChange(this._disabled);
                    this.stateChanges?.next();
                }
            }
        }

        onDisabledChange(v: boolean): void {
            /** NOT IMPLEMENTED, this needs to be overriden by subclasses if needed */
        }
    };
}
