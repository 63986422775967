import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    ViewChild,
} from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { environment } from '@environment';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {
    BaseMedication,
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationDosageForm,
    MedicationSolution,
    MedicationUnit,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
} from '@mona/models';
import {
    getFormControlsMap,
    isEmptyObject,
    OnChange,
    takeUntilDestroy,
    TakeUntilDestroy,
    uiPure,
} from '@mona/shared/utils';
import {
    BaseMedicationForm,
    buildBaseMedicationForm,
    configRequiredFields,
    getFieldStatusFromConfig,
    validateFrequency,
} from '../../models';

const FIELDS_TO_RESET = ['amount', 'unitAmount', 'volume', 'unitVolume', 'rate', 'unitRate', 'solutionCode'];
const NUMERICAL_FIELDS_TO_RESET = ['amount', 'volume', 'rate'];

/**
 * Basic Medication Fom Component
 */
@TakeUntilDestroy
@Component({
    selector: 'mona-base-medication-form',
    templateUrl: './base-medication-form.component.html',
    styleUrls: ['./base-medication-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseMedicationFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('amountInput') amountInput: ElementRef;
    /** form of MedicationForm */
    formGroup: BaseMedicationForm;
    /** scrollContainer ElementRef */
    @Input() scrollContainer: ElementRef;
    /** Should focus on init */
    @Input() shouldFocusOnInit = false;
    /** Is loading */
    @OnChange(function (this: BaseMedicationFormComponent, value: boolean) {
        if (coerceBooleanProperty(value)) {
            this.formGroup?.enable();
        } else {
            this.formGroup?.disable();
        }
    })
    @Input()
    isLoading: boolean;

    /** start date of medication  */
    @Input() startDate: string;

    /** Selected category code */
    @OnChange<BaseMedicationFormComponent>('resetValuesOnChange')
    @Input()
    selectedCategoryCode: string;
    /** Medications administration methods */
    @Input() medicationAdministrationMethods: MedicationAdministrationMethod[];
    /** Medications units */
    @Input() medicationUnits: MedicationUnit[];
    /** Dosage forms */
    @Input() medicationDosageForms: MedicationDosageForm[];
    /** Medications solutions */
    @Input() medicationSolutions: MedicationSolution[];
    /** Frequency dictionary */
    @Input() medicationFrequencies: PrescriptionFrequency[] = [];
    /** Frequency dictionary */
    @Input() medicationFrequencyTimes: PrescriptionFrequencyTime[] = [];
    /** Blood administrations */
    @Input() bloodAdministrations: any[] = [];
    /** Medication categories */
    @Input() medicationCategories: MedicationCategory[] = [];
    /** If need to update form value without parent formgroup */
    @Input() initialValue: Partial<BaseMedication> = {};

    /** The controls of the form */
    get controls(): BaseMedicationForm['controls'] {
        return this.formGroup?.controls;
    }

    /** Get controls of the form as 1st level map */
    get allControls(): Map<string, Readonly<AbstractControl>> {
        return getFormControlsMap(this.formGroup);
    }

    getFieldStatusFromConfig = getFieldStatusFromConfig;
    validateFrequency = validateFrequency;

    readonly CustomFrequencyCode: string = environment.customFrequencyCode;
    readonly NoRequiredFrequencyCode: string = environment.noRequiredFrequencyCode;
    readonly ContinuousFrequencyCode: string = environment.continuousFrequencyCode;

    /**
     * Constructor
     *
     * @param controlContainer ControlContainer
     * @param cdr
     */
    constructor(@Optional() private controlContainer: ControlContainer, private cdr: ChangeDetectorRef) {}

    /**
     * Lifecycle Hook
     */
    ngOnInit(): void {
        if (this.controlContainer?.control) {
            this.formGroup = this.controlContainer.control as any;
            configRequiredFields(this.formGroup, this.selectedCategoryCode, this.medicationCategories);
        } else {
            this.formGroup = buildBaseMedicationForm();
        }

        if (!isEmptyObject(this.initialValue) && this.formGroup.pristine) {
            this.formGroup.patchValue(this.initialValue);
        }

        this.controls.medicationCode.valueChanges
            .pipe(
                distinctUntilChanged(),
                tap(() => this.resetValuesOnChange()),
                takeUntilDestroy(this),
            )
            .subscribe();
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function, jsdoc/require-jsdoc
    ngOnDestroy(): void {}

    /**
     * Lifecycle Hook
     */
    ngAfterViewInit() {
        if (this.shouldFocusOnInit) {
            setTimeout(() => {
                this.amountInput.nativeElement.focus();
            }, 500);
        }
    }

    /**
     * Track by code
     *
     * @param index number
     * @param item terminology model which has code
     */
    trackByCode(index, item): string {
        return item.code;
    }

    /**
     * Track by id
     *
     * @param index number
     * @param item terminology model which has code
     */
    trackUnitsFn(index, item): string {
        return item.id || index;
    }

    /**
     * Filter medication units based on selected category
     *
     * @param units
     * @param categoryCode
     */
    @uiPure
    filterMedicationUnits(units: MedicationUnit[], categoryCode: string): MedicationUnit[] {
        return categoryCode && units.some(u => u.categoryCode === categoryCode)
            ? units.filter(unit => unit.categoryCode === categoryCode)
            : units;
    }

    /**
     * Filter medication units for rate unit field
     *
     * @param units
     */
    @uiPure
    filterRateUnits(units: MedicationUnit[]): MedicationUnit[] {
        return units?.filter(u => u.unit === 'ml/h');
    }

    /** Resets other fields if medication code or category changes */
    resetValuesOnChange(): void {
        if (!this.controls) {
            return;
        }

        configRequiredFields(this.formGroup, this.selectedCategoryCode, this.medicationCategories);
        // NOTE: deal with this, because it is clearing standard medication value what was set
        if (this.formGroup.controls.clearRequiredFields.value) {
            // To trigger validation for the next value after reset happens (999 - is a random number, just has to be more than 1 digit)
            NUMERICAL_FIELDS_TO_RESET.forEach(field => this.controls[field].setValue(999));
            FIELDS_TO_RESET.forEach(field => this.controls[field].reset());
            /** Wasn't able to find better solution to {@link https://clinomic.atlassian.net/browse/MD-7307 this issue} */

            this.setRateUnit();
            this.setFrequencyByRate();
        }

        this.formGroup.controls.clearRequiredFields.setValue(true, { emitEvent: false });
    }

    /** Preselect continuous frequency for categories with rate field */
    private setFrequencyByRate(): void {
        if (getFieldStatusFromConfig('rate', this.selectedCategoryCode, this.medicationCategories)) {
            const continuousFrequency = this.medicationFrequencies.find(mf => mf.code === 'continuous');
            this.controls.frequency.setValue(continuousFrequency?.code);
        } else {
            this.controls.frequency.reset();
        }
    }

    /**
     * Set custom frequency
     */
    setCustomFrequency(): void {
        this.controls.frequency.patchValue(environment.customFrequencyCode);
        this.controls.frequency.markAsDirty();
        this.cdr.detectChanges();
    }

    /**
     * Checks if the frequency times should be displayed.
     */
    isShowFrequencyTimes() {
        const frequencyTimes = this.controls.frequencyTimes.value;
        const frequencyCode = this.controls.frequency.value;

        return (
            (frequencyTimes?.length && frequencyCode !== this.CustomFrequencyCode) ||
            frequencyCode === this.CustomFrequencyCode
        );
    }

    /** Set rate unit if there is only 1 option available */
    private setRateUnit(): void {
        const isRate = this.getFieldStatusFromConfig('unitRate', this.selectedCategoryCode, this.medicationCategories);

        if (isRate) {
            const filteredUnits = this.filterMedicationUnits(this.medicationUnits, this.selectedCategoryCode);
            const rateUnits = this.filterRateUnits(filteredUnits);

            if (rateUnits?.length === 1) {
                this.controls.unitRate.setValue(rateUnits[0].unit);
            }
        }
    }
}
