import { createAction, props } from '@ngrx/store';
import { Bed } from '@mona/models';

/** Set selected bed id */
export const setAssignedBedId = createAction('WARDS:SET_ASSIGNED_BED', props<{ bedId: EntityId<Bed> | null }>());
/** Set selected bed id */
export const selectBed = createAction('WARDS:SELECTED_BED', props<{ bedId: EntityId<Bed>; bed?: Bed; isReviewMode?: boolean, fromEncounter?: string }>());
/** Load action */
export const load = createAction('WARDS:LOAD_BEDS', props<{ wardId: string }>());
/** Load success action */
export const loadSuccess = createAction('WARDS:LOAD_BEDS_SUCCEEDED', props<{ beds: Bed[] }>());
/** Load failure action */
export const loadFailure = createAction('WARDS:LOAD_BEDS_FAILED', props<{ error: unknown }>());
/** Clear action */
export const clear = createAction('WARDS:LOAD_BEDS_CLEAR');
/** Upsert bed action */
export const upsertBed = createAction('WARDS:UPSERT_BED', props<{ bed: Bed }>());
/** Remove bed action */
export const removeBed = createAction('WARDS:REMOVE_BED', props<{ bed: Bed }>());
