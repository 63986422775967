import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { MonaMessageComponent } from './mona-message/mona-message.component';
import { PractitionerMessageComponent } from './practitioner-message/practitioner-message.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [PractitionerMessageComponent, MonaMessageComponent],
    exports: [PractitionerMessageComponent, MonaMessageComponent],
    imports: [UiModule, KeyboardModule],
})
export class ConversationMessagesModule {}
