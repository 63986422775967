/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskListState } from '../../entities';
import { taskListStateKey, TaskListStateMap } from '../reducers';
import { taskListFeatureKey, tasksAdapter } from '../reducers/task-list.reducer';

export const selectTaskListFeatureState = createFeatureSelector<TaskListStateMap>(taskListStateKey);

const selectTaskListState = createSelector(selectTaskListFeatureState, state => state[taskListFeatureKey]);

export const {
    selectIds: selectTaskIds,
    selectEntities: selectTaskEntities,
    selectAll: selectTasksAll,
    selectTotal: selectTasksTotal,
} = tasksAdapter.getSelectors(selectTaskListState);

export const selectTaskListData = createSelector(selectTaskListState, (state: TaskListState) => state.taskList);

export const selectIsLoading = createSelector(selectTaskListState, (state: TaskListState) => state.isLoading);

export const selectFilter = createSelector(selectTaskListState, (state: TaskListState) => state.filter);

export const selectOverdueTime = createSelector(selectTaskListState, (state: TaskListState) => state.overdueTime);

export const selectCompletedOnDemandMedPrescriptionIds = createSelector(
    selectTaskListState,
    (state: TaskListState) => state.completedOnDemandMedPrIds,
);
