import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PageableModel, TableViewModeEnum, VitalSign } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';
import { VitalSignsState } from '../../entities';
import { VitalSignsAction } from '../actions';

export const vitalSignsAdapter: EntityAdapter<VitalSign> = createEntityAdapter<VitalSign>({
    selectId: (item: VitalSign) => item.id,
});

const initialState: VitalSignsState = vitalSignsAdapter.getInitialState({
    viewMode: TableViewModeEnum.Interval,
    isLoading: false,
    interval: EntriesInterval.HOUR_1,
    dateRange: null,
    startDate: null,
    pageableData: null,
    codes: [],
});

export const vitalSignsReducer = createReducer(
    initialState,
    on(VitalSignsAction.resetViewMode, state => initialState),
    // reset date range to null when interval is set
    on(VitalSignsAction.setInterval, (state, { interval }) => ({
        ...state,
        interval,
        dateRange: null,
        viewMode: TableViewModeEnum.Interval,
    })),
    // reset interval to null when date range is set
    on(VitalSignsAction.setDateRange, (state, { dateRange }) => ({
        ...state,
        interval: dateRange ? null : state.interval,
        dateRange,
        viewMode: dateRange ? TableViewModeEnum.Range : TableViewModeEnum.Interval,
    })),

    on(VitalSignsAction.setLoadingAction, (state, { isLoading }) => ({ ...state, isLoading })),

    on(VitalSignsAction.loadVitalSigns, state => ({
        ...state,
        isLoading: true,
    })),

    on(VitalSignsAction.loadVitalSignsFailed, state => ({
        ...state,
        isLoading: false,
    })),

    on(
        VitalSignsAction.loadVitalSignsSuccess,
        (
            state,
            action: PageableModel<VitalSign> & {
                /**
                 * INFO: add comment
                 */
                codes: string[];
            },
        ) => {
            const { results, ...pageableData } = action;
            const { codes, ...pageable } = pageableData;
            const updatedState = {
                ...state,
                pageableData: pageable,
                codes: pageableData.codes,
                isLoading: false,
            };

            if (state.viewMode === TableViewModeEnum.Interval) {
                updatedState.startDate = results?.at(-1)?.date;
                return vitalSignsAdapter.upsertMany(results, updatedState);
            }

            return vitalSignsAdapter.upsertMany(results, updatedState);
        },
    ),

    on(VitalSignsAction.upsertVitalSigns, (state, { data }) =>
        vitalSignsAdapter.upsertMany(data, { ...state, isLoading: false }),
    ),

    on(VitalSignsAction.removeVitalSigns, (state, { ids }) =>
        vitalSignsAdapter.removeMany(ids, { ...state, isLoading: false }),
    ),
    on(VitalSignsAction.addChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = vitalSignsAdapter.removeMany(toRemoveIds, state);

        return vitalSignsAdapter.upsertMany(toUpdateEntities, newState);
    }),
    on(VitalSignsAction.clearVitalSigns, () => vitalSignsAdapter.removeAll(initialState)),
);
