import { createReducer, on } from '@ngrx/store';
import { FhirConfigState } from '../../entities';
import * as FhirConfigActions from '../actions/fhir-config.actions';

export const fhirConfigFeatureKey = 'fhirConfig';

export const initialState: FhirConfigState = {} as FhirConfigState;

export const reducer = createReducer(
    initialState,

    on(FhirConfigActions.loadFhirConfigs, state => ({ ...state, isLoading: true })),
    on(FhirConfigActions.loadFhirConfigsSuccess, (state, { data }) => ({ ...data, isLoading: false, error: null })),
    on(FhirConfigActions.loadFhirConfigsFailure, (state, { error }) => ({ ...state, isLoading: false, error })),
);
