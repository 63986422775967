import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { addDays, startOfDay } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import {
    DataUpdateMessage,
    DataUpdateMessageOperation,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
    PrescriptionNotGivenReason,
    Procedure,
    ProcedurePrescription,
} from '@mona/models';
import {
    PrescriptionTerminologyGetter,
    PRESCRIPTION_TERMINOLOGY_GETTER,
    getEncounterStartDate,
} from '@mona/pdms/data-access-combined';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { ClockService } from '@mona/shared/date';
import { someIsLoading } from '@mona/shared/utils';
import { ProceduresState } from '../entities';
import {
    ProcedurePrescriptionsActions,
    ProceduresActions,
    selectProcedureAll,
    selectProcedureMapByPrescriptionId,
    selectProcedurePrescriotionsDateRange,
    selectProcedurePrescriptioFilteredByDateAndValue,
    selectProcedurePrescriptionAll,
    selectProcedurePrescriptionById,
    selectProcedurePrescriptionEntities,
    selectProcedurePrescriptionMapByCategory,
    selectProcedurePrescriptionsError,
    selectProcedurePrescriptionsFiltered,
    selectProcedurePrescriptionsIsLoading,
    selectProceduresError,
} from '../state';

/**
 * Prescriptions facade service
 */
@Injectable({ providedIn: 'root' })
export class DataAccessProceduresFacade {
    /** Current practitioner */
    currentPractitioner$ = this.authService.user$;
    /** Encounter start date */
    encounterStartDate$: Observable<Date> = getEncounterStartDate();

    /**
     * Procedure prescriptions date range (min start max end)
     */
    procedurePrescriptionsDateRange$: Observable<{ start: Date; end: Date }> = this.store.select(
        selectProcedurePrescriotionsDateRange,
    );

    /**
     * Procedure prescriptions ALL
     */
    procedurePrescriptions$: Observable<ProcedurePrescription[]> = this.store.select(selectProcedurePrescriptionAll);

    /** Procedure prescriptions map */
    procedurePrescriptionsMap$: Observable<Record<string, ProcedurePrescription>> = this.store.select(
        selectProcedurePrescriptionEntities,
    );

    /**
     * Procedure prescriptions error
     */
    procedurePrescriptionsError$: Observable<Error> = this.store.select(selectProcedurePrescriptionsError);

    /**
     * Resolves prescriptions by categories
     *
     * @param prescriptions ProcedurePrescription[]
     */
    procedurePrescriptionsMapByCategory$: Observable<EntitiesMap<ProcedurePrescription>> = this.store.select(
        selectProcedurePrescriptionMapByCategory,
    );

    /**
     * Procedure prescriptions filtered by selected date & show deleted
     */
    procedurePrescriptionsFiltered$: Observable<ProcedurePrescription[]> = this.store.select(
        selectProcedurePrescriptionsFiltered,
    );

    /**
     * Procedure prescriptions filtered
     * + has category
     * + relevant for selected date
     * + are not stopped or has documented value
     */
    procedurePrescriptionsFilteredByDateAndValue$: Observable<ProcedurePrescription[]> = this.store.select(
        selectProcedurePrescriptioFilteredByDateAndValue,
    );

    /**
     * Procedure prescriptions which have unconfirmed prescriptions
     */
    procedurePrescriptionsUnconfirmed$: Observable<ProcedurePrescription[]> = this.procedurePrescriptions$.pipe(
        map(prescriptions => prescriptions.filter(p => !p.isApproved)),
    );

    /**
     * Procedures ALL
     */
    procedures$: Observable<Procedure[]> = this.store.select(selectProcedureAll);

    /**
     * Procedures errors
     */
    proceduresError$: Observable<any> = this.store.select(selectProceduresError);

    /**
     * Procedures map by prescription_id
     */
    proceduresMapByPrescriptionId$: Observable<{ [id: string]: Procedure[] }> = this.store.select(
        selectProcedureMapByPrescriptionId,
    );

    /**
     * Frequency dictionary
     */
    prescriptionFrequencies$: Observable<PrescriptionFrequency[]> =
        this.terminologyService.getPrescriptionFrequencies();

    /** Procedure categories as array */
    procedureCategories$ = this.terminologyService.getProcedureCategories();

    /** Frequency times dictionary */
    prescriptionFrequencyTimes$: Observable<PrescriptionFrequencyTime[]> =
        this.terminologyService.getPrescriptionFrequencyTimes();

    /**
     * Procedure prescriptions categories map
     */
    procedurePrescriptionCategoriesMap$ = this.terminologyService.getProcedureCategoriesMap();

    /** Prescription not given reason */
    prescriptionNotGivenReasons$: Observable<PrescriptionNotGivenReason[]> =
        this.terminologyService.getPrescriptionNotGivenReasons();

    practitionersMap$ = this.practitionersFacade.practitionersMap$;

    /**
     * Is loading
     */
    isLoading$: Observable<boolean> = combineLatest([
        this.store.select(selectProcedurePrescriptionsIsLoading).pipe(map(inProgress => ({ inProgress }))),
        this.terminologyService.getLoadProcedureCategoriesAction(),
        this.terminologyService.getLoadPrescriptionFrequenciesAction(),
        this.terminologyService.getLoadCareCheckTypes(),
    ]).pipe(someIsLoading());

    /**
     * Constructor
     *
     * @param store Store<EncounterDataState>
     * @param authService AuthService
     * @param clockService ClockService
     * @param terminologyService TerminologyService
     * @param practitionersFacade
     * @param medicationTerminologyGetter
     */
    constructor(
        private store: Store<ProceduresState>,
        private authService: AuthService,
        private clockService: ClockService,
        private terminologyService: TerminologyService,
        private practitionersFacade: PractitionersFacade,
        @Inject(PRESCRIPTION_TERMINOLOGY_GETTER)
        public readonly medicationTerminologyGetter: PrescriptionTerminologyGetter,
    ) {}

    /**
     * Get UI end date
     * TBD: Last prescribed until or current date + 1
     *
     * @deprecated
     */
    getEndDate(): Observable<Date> {
        return combineLatest([
            // this.getProcedurePrescriptions().pipe(filter<ProcedurePrescription[]>(Boolean)),
            this.clockService.now$.pipe(
                map(date => addDays(startOfDay(date), 1)),
                distinctUntilChanged((prev, current) => prev.getTime() === current.getTime()),
            ),
            this.procedurePrescriptionsDateRange$,
        ]).pipe(
            map(([nextDay, dateRange]) => {
                const latestPrescriptionDateStart: Date | null = dateRange.end && startOfDay(dateRange.end);

                if (latestPrescriptionDateStart && latestPrescriptionDateStart.getTime() > nextDay.getTime()) {
                    return latestPrescriptionDateStart;
                }

                return nextDay;
            }),
        );
    }

    /**
     * Load procedure prescriptions
     *
     */
    loadProcedurePrescriptions(): void {
        this.store.dispatch(ProcedurePrescriptionsActions.loadProcedurePrescriptions());
    }

    /**
     * Load procedures
     *
     */
    loadProcedures(): void {
        this.store.dispatch(ProceduresActions.loadProcedures());
    }

    /**
     * Clear Procedure Prescriptions
     */
    clearProcedurePrescriptions() {
        this.store.dispatch(ProcedurePrescriptionsActions.clearProcedurePrescriptions());
    }

    /**
     * Handle confirm changes for procedure prescriptions
     */
    handleConfirmChangesForProcedurePrescriptions(): void {
        this.store.dispatch(ProcedurePrescriptionsActions.handleConfirmChanges());
    }

    /**
     * INFO: add comment
     *
     * @param id
     */
    getProcedurePrescriptionById(id: string) {
        return this.store.select(selectProcedurePrescriptionById(id));
    }

    /**
     * Clear Procedures
     */
    clearProcedures() {
        this.store.dispatch(ProceduresActions.clearProcedures());
    }

    /**
     * Create procedure
     *
     * @param procedurePrescription
     */
    handleCreateProcedurePrescription(procedurePrescription: ProcedurePrescription) {
        this.store.dispatch(ProcedurePrescriptionsActions.addProcedurePrescription({ procedurePrescription }));
    }

    /**
     * Create procedure
     *
     * @param id
     * @param procedurePrescription
     */
    handleUpdateProcedurePrescription(id: string, procedurePrescription: Partial<ProcedurePrescription>) {
        this.store.dispatch(
            ProcedurePrescriptionsActions.updateProcedurePrescription({
                update: { id, changes: procedurePrescription },
            }),
        );
    }

    /**
     * Update change log with procedure prescriptions
     *
     * @param procedurePrescription ProcedurePrescription
     */
    handleDeleteProcedurePrescription(procedurePrescription: ProcedurePrescription): void {
        this.store.dispatch(ProcedurePrescriptionsActions.handleDeleteProcedurePrescription({ procedurePrescription }));
    }

    /**
     * process procedure prescriptions
     *
     * @param procedurePrescriptions
     */
    processProcedurePrescriptions(procedurePrescriptions: ProcedurePrescription[]): void {
        this.store.dispatch(ProcedurePrescriptionsActions.processProcedurePrescriptions({ procedurePrescriptions }));
    }

    /**
     * upsert procedure
     *
     * @param procedure
     */
    upsertProcedure(procedure: Procedure): void {
        this.store.dispatch(ProceduresActions.upsertProcedure({ procedure }));
    }

    /**
     * delete procedure facade method
     *
     * @param id
     */
    deleteProcedure(id: string): void {
        this.store.dispatch(ProceduresActions.deleteProcedures({ ids: [id] }));
    }

    /**
     * upsert procedure prescription
     *
     * @param procedurePrescription
     */
    upsertProcedurePrescription(procedurePrescription: ProcedurePrescription): void {
        this.store.dispatch(ProcedurePrescriptionsActions.upsertProcedurePrescription({ procedurePrescription }));
    }

    /**
     * handle procedure data update message
     *
     * @param updateMessage
     */
    handleProcedureDataUpdateMessage(updateMessage: DataUpdateMessage<Procedure>): void {
        if (updateMessage.operation === DataUpdateMessageOperation.Delete) {
            this.deleteProcedure(updateMessage.modelId);
        } else {
            this.upsertProcedure(updateMessage.payload as Procedure);
        }
    }

    /**
     * handle procedure prescription data udpate message
     *
     * @param updateMessage
     */
    handleProcedurePrescriptionDataUpdateMessage(updateMessage: DataUpdateMessage<ProcedurePrescription>): void {
        // NOTE: we can not remove prescriptions only mark them as revoked
        this.processProcedurePrescriptions([updateMessage.payload as ProcedurePrescription]);
    }
}
