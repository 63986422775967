import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '@mona/api';
import { FhirConfig, FhirConfigApiModel } from '@mona/models';
import { transformApiFhirConfig } from './transforms';

/**
 * API abstraction layer for the fhir config API
 */
@Injectable({
    providedIn: 'root',
})
export class FhirConfigApi {
    apiBase = '/api/fhir/config/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get fhir config
     */
    getFhirConfig(): Observable<FhirConfig> {
        return this.http.get<FhirConfigApiModel>(this.apiBase).pipe(map(config => transformApiFhirConfig(config)));
    }
}
