import { createSelector } from '@ngrx/store';
import { isSameDay } from 'date-fns';
import { isPresctiptionDateSameDate, selectencounterViewSelectedDate } from '@mona/pdms/data-access-combined';
import { selectTerminologyState } from '@mona/pdms/data-access-terminology';
import { selectProcedurePrescriptionAll } from './procedure-prescriptions.selectors';
import { selectProcedureMapByPrescriptionId } from './procedures.selectors';

// =============================================================================
// COMBINED SELECTORS
// =============================================================================

/**
 * Filters prescriptions
 * + has category
 * + relevant for selected date
 * + are not stopped or has documented value
 */
export const selectProcedurePrescriptioFilteredByDateAndValue = createSelector(
    selectencounterViewSelectedDate,
    selectProcedurePrescriptionAll,
    selectProcedureMapByPrescriptionId,
    selectTerminologyState,
    (selectedDate, procedurePrescriptions, procedureMapByPrescriptionId, terminologyState) => {
        /** TODO: use selectors from Terminology when available */
        const categoriesMap = terminologyState.procedureCategories.entities;

        const filteredProcedurePrescriptions = procedurePrescriptions
            // 1. Filter out prescriptions which may have deleted categories or are stopped
            // 2. Filter out prescriptions which are not relevant for selected date
            .filter(prescription => {
                // unknown category
                if (!categoriesMap[prescription.category]) {
                    return false;
                }

                const matchesDate = isPresctiptionDateSameDate(prescription, selectedDate);

                // show only if there is inputs which are relevant for selected date
                const existingProcedure = procedureMapByPrescriptionId[prescription.id]?.find(p =>
                    isSameDay(p.date, selectedDate),
                );

                // if procedure prescriptions revoked but have procedures for the day
                // -> don't remove at that day
                if (!existingProcedure && prescription.isRevoked) {
                    return false;
                } else {
                    return matchesDate || existingProcedure;
                }
            });

        return filteredProcedurePrescriptions;
    },
);
