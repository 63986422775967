/**
 * Glasgow Coma Scale - Best Motor Response enum
 */
export enum BestMotorResponse {
    OBEYS_COMMAND = 6,
    LOCALISED_PAIN = 5,
    WITHDRAWAL_FROM_PAIN = 4,
    FLEXION = 3,
    EXTENSION = 2,
    NO_RESPONSE = 1,
}
