import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@mona/shared/logger';

/**
 * Dynamic locale loader helper
 */
export class DynamicLocaleId extends String {
    /**
     * Class constructor
     *
     * @param service NGX Translate service
     */
    constructor(protected service: TranslateService) {
        super('');
    }

    /**
     * INFO: add comment
     */
    valueOf(): string {
        return this.toString();
    }

    /**
     * To string implementation
     */
    toString() {
        return this.service.currentLang || this.service.defaultLang;
    }
}

// -----------------------------------------------------------------------------
// DYNAMIC LOCALE LOADER
// -----------------------------------------------------------------------------

/**
 * The only langs from currently used by Mona which exists with country code in angular
 */
const MONA_COUNTRY_LOCALES_ANGULAR_HAS = ['en-GB', 'pt-PT'];

/**
 * Dynamic locale loader factory, which gets called on APP_INITIALIZER step
 * and imports & registers LocaleData dynamically
 * from set off supported languages provided in environment
 *
 * (dynamic imports allow us to load our code on demand asynchronously).
 *
 * (webpack magic strings are used to get correct bundle for locales)
 *
 * @param availableLocaleIds
 * @tutorial https://medium.com/angular-in-depth/dynamic-import-of-locales-in-angular-b994d3c07197
 * @see https://stackoverflow.com/a/61060895/4115894
 */
export function localesInitializerFactory(availableLocaleIds: string[]) {
    const logger = new Logger('I18N');
    return () =>
        Promise.all(
            availableLocaleIds.map(localeId => {
                // Removing country code for not MONA_COUNTRY_LOCALES_ANGULAR_HAS langs before loading angular locale
                const localeKey = MONA_COUNTRY_LOCALES_ANGULAR_HAS.includes(localeId)
                    ? localeId
                    : localeId.split('-')[0];
                return import(
                    /* webpackInclude: /(de|en-GB|el|fr|it|nl|lv|es|pt-PT|ro)\.mjs$/ */
                    /* webpackMode: "lazy-once" */
                    /* webpackChunkName: "i18n-all" */
                    `../../../../../node_modules/@angular/common/locales/${localeKey}.mjs`
                )
                    .then(module => {
                        logger.debug(`registerLocaleData success for ${JSON.stringify(availableLocaleIds)}`);
                        registerLocaleData(module.default);
                    })
                    .catch(error => {
                        logger.warn('registerLocaleData error for', localeId, { error });
                        return Promise.resolve();
                    });
            }),
        );
}
