import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { EncounterService } from '@mona/pdms/data-access-encounter-data';
import { DialogData, DialogService } from '@mona/ui';
import { RelativeContactsState } from '../../entities';
import { RelativeContactsApi } from '../../infrastructure';
import { RelativeContactsActions } from '../actions';

/** Relative contacts effects */
@Injectable()
export class RelativeContactsEffects {
    loadRelativeContacts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelativeContactsActions.loadRelativeContacts),
            withCurrentEncounterId(),
            switchMap(([, encounterId]) => {
                return this.relativeContactsApi.getRelativeContacts(encounterId).pipe(
                    map(relativeContacts => RelativeContactsActions.loadRelativeContactsSuccess({ relativeContacts })),
                    catchError(error => of(RelativeContactsActions.loadRelativeContactsFailure({ error }))),
                );
            }),
        ),
    );

    handleDeleteRelativeContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelativeContactsActions.handleDeleteRelativeContact),
            switchMap(({ id }) =>
                this.dialogService
                    .showConfirmDialog(this.deleteRelativeContactDialogData)
                    .pipe(
                        switchMap(confirmed =>
                            confirmed ? of(RelativeContactsActions.deleteRelativeContact({ id })) : EMPTY,
                        ),
                    ),
            ),
        ),
    );

    deleteRelativeContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelativeContactsActions.deleteRelativeContact),
            switchMap(({ id }) =>
                this.relativeContactsApi.deleteRelativeContact(id).pipe(
                    map(() => RelativeContactsActions.deleteRelativeContactSuccess({ id })),
                    catchError(error => of(RelativeContactsActions.deleteRelativeContactFailure({ error }))),
                ),
            ),
        ),
    );

    upsertRelativeContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelativeContactsActions.upsertRelativeContact),
            withCurrentEncounterId(),
            switchMap(([{ relativeContact }, encounterId]) =>
                this.encounterService.getPatientIdFromEncounter(encounterId).pipe(
                    switchMap(patientId =>
                        this.relativeContactsApi.upsertRelativeContact(relativeContact, patientId).pipe(
                            map(updatedRelativeContact =>
                                RelativeContactsActions.upsertRelativeContactSuccess({
                                    relativeContact: updatedRelativeContact,
                                }),
                            ),
                            catchError(error => of(RelativeContactsActions.upsertRelativeContactFailure({ error }))),
                        ),
                    ),
                ),
            ),
        ),
    );

    /**
     * constructor
     *
     * @param store
     * @param actions$
     * @param relativeContactsApi
     * @param dialogService
     * @param encounterService
     */
    constructor(
        private store: Store<RelativeContactsState>,
        private actions$: Actions,
        private relativeContactsApi: RelativeContactsApi,
        private dialogService: DialogService,
        private encounterService: EncounterService,
    ) {}

    private get deleteRelativeContactDialogData(): DialogData {
        return {
            // TODO: add translations
            // title: 'apps.patient.relativeContacts.delete.title',
            // description: 'apps.patient.relativeContacts.delete.message',
            // cancelBtn: 'apps.patient.relativeContacts.delete.cancel',
            // confirmBtn: 'apps.patient.relativeContacts.delete.confirm',
            title: 'Delete Relative Contact',
            description: 'Are you sure you want to delete relative contact',
            confirmBtn: 'Delete',
            cancelBtn: 'Cancel',
        };
    }
}
