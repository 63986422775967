import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NgControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { startOfToday } from 'date-fns';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { toDate } from '@mona/shared/date';
import { WithLogger } from '@mona/shared/logger';
import { isNullOrUndefined, TakeUntilDestroy, takeUntilDestroy } from '@mona/shared/utils';
import { DynamicControlValue } from '../../models';
import { UiDatePickerComponent } from '../date-picker/date-picker.component';

const componentName = 'ui-date-time-picker';
/**
 * DateTimePicker Component
 */
@TakeUntilDestroy
@WithLogger({
    scope: 'UI',
    methodOptions: {
        withArgs: true,
    },
    loggedMethodsNames: ['onDateTimeChange', 'constructTimeStringFromDate', 'getTimeNumericValuesFromDate'],
})
@Component({
    selector: 'ui-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    providers: [
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
        {
            provide: NG_VALIDATORS,
            useExisting: UiDateTimePickerComponent,
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: UiDateTimePickerComponent,
        },
        {
            // This implementation has been made thanks to the official documentation. See:
            // https://v7.material.angular.io/guide/creating-a-custom-form-field-control
            provide: MatFormFieldControl,
            useExisting: UiDateTimePickerComponent,
        },
    ],
    host: {
        class: componentName,
    },
    encapsulation: ViewEncapsulation.None,
})
export class UiDateTimePickerComponent
    implements MatFormFieldControl<Date>, ControlValueAccessor, Validator, OnInit, OnDestroy
{
    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    private static nextId = 0;

    /**
     * Part of {@link MatFormFieldControl} API
     *
     * @ignore
     */
    @HostBinding()
    public id = `ui-date-time-picker${UiDateTimePickerComponent.nextId++}`;

    /**
     * Part of {@link MatFormFieldControl} API
     *
     * @ignore
     */
    @HostBinding('attr.aria-describedby')
    public describedBy = '';

    /**
     * Part of {@link MatFormFieldControl} API
     *
     * @ignore
     */
    @HostBinding('class.floating')
    public get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    /**
     * Source date to bound to the DateTimePicker
     */
    @Input()
    public get value(): Date | null {
        return this._value;
    }

    public set value(value: Date | DynamicControlValue | string | null) {
        if (value) {
            const dateValue = new Date(value['display'] ? value['display'] : value);
            this.dateTimeFormGroup.setValue({
                date: new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate()),
                time: this.constructTimeStringFromDate(dateValue),
            });
        }
        this._value = value && value['display'] ? value['display'] : value;
        this.stateChanges.next();
    }

    /**
     * @ignore
     * @internal
     */
    private _value: Date | null = null;

    /**
     * Show only Time input
     */
    @Input() timeOnly = false;
    /**
     * Placeholder / label for Time
     */
    @Input() timelabel = '';
    /**
     * Placeholder / label for Date
     */
    @Input()
    public get placeholder(): string {
        return this._placeholder;
    }

    public set placeholder(value: string) {
        this.originalPlaceholder = value || '';
        // Handle translation internally because mat-form-field uses the value of `@Input public
        // placeholder` to display the label / placeholder
        this._placeholder = this.originalPlaceholder
            ? this.translateService.instant(this.originalPlaceholder)
            : this.originalPlaceholder;
        this.stateChanges.next();
    }

    /**
     * @ignore
     * @internal
     */
    private _placeholder = '';

    /**
     * Determines if DateTimePicker is required
     */
    @Input()
    public get required(): boolean {
        return this._required;
    }

    public set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        if (this._required) {
            this.dateTimeFormGroup.controls['date'].setValidators([Validators.required]);
            this.dateTimeFormGroup.controls['time'].setValidators([Validators.required]);
        } else {
            this.dateTimeFormGroup.controls['date'].clearValidators();
            this.dateTimeFormGroup.controls['time'].clearValidators();
        }
        this.updateValueAndValidity();
    }

    /**
     * @ignore
     * @internal
     */
    private _required = false;

    /**
     * Determines if DateTimePicker is disabled
     */
    @Input()
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this._disabled) {
            this.dateTimeFormGroup.disable();
        } else {
            this.dateTimeFormGroup.enable();
        }
    }

    /**
     * @ignore
     * @internal
     */
    private _disabled = false;

    /**
     * Input for {@link uiDatePickerComponent}
     */
    @Input()
    public pickerId = '';

    /**
     * Input for {@link uiDatePickerComponent}
     */
    @Input()
    public name = '';

    /**
     * touchUi attribute for material
     */
    @Input()
    touchUi = false;

    /**
     * Maximum date of the date picker
     *
     * Supported types: `Date | undefined | null`
     */
    @Input()
    set max(value: Date | null) {
        if (isNullOrUndefined(value)) {
            this._max = null;
        } else {
            this._max = toDate(value);
            this.updateValueAndValidity();
        }
    }

    /**
     * Maximum date of the date picker
     */
    get max(): Date | null {
        return this._max;
    }

    private _max: Date | null = null;

    /**
     * Minimum date of the date picker
     *
     * Supported types: `Date | undefined | null`
     */
    @Input()
    set min(value: Date | null) {
        if (isNullOrUndefined(value)) {
            this._min = null;
        } else {
            this._min = toDate(value);
            if (this.value && this._min > this.value) {
                this.value = this._min;
            }
            this.updateValueAndValidity();
        }
    }

    /**
     * Minimum date of the date picker
     */
    get min(): Date | null {
        return this._min;
    }

    _min: Date | null = null;

    /**
     * Output that will emit a specific date whenever the selection has changed
     */
    @Output()
    public readonly dateTimeChange = new EventEmitter<Date | null>();

    @Output()
    public timeHasChanged: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Reference to the time input embedded in this component
     */
    @ViewChild('timeInput', { static: true })
    public timeInput!: ElementRef<HTMLInputElement>;

    /**
     * Reference to the ui date picker embedded in this component
     */
    @ViewChild(UiDatePickerComponent, { static: true })
    public datePicker!: UiDatePickerComponent;

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    public ngControl: NgControl | null = null;

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    public controlType = componentName;

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    public stateChanges = new Subject<void>();

    /**
     * Part of {@link MatFormFieldControl} API
     *
     * @ignore
     */
    public focused = false;

    /**
     * The current error state
     *
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    get errorState(): boolean {
        return (
            (this.dateTimeFormGroup.touched && this.dateTimeFormGroup.invalid) ||
            this.value?.toString() == 'Invalid Date'
        );
    }

    /**
     * @ignore
     * @internal
     * Original placeholder translation key to keep in memory to translate again when language
     * changes.
     */
    private originalPlaceholder = '';

    /**
     * @ignore
     * @internal
     */
    private translateOnLangChangeSubscription!: Subscription;

    /**
     * @ignore
     */
    public dateTimeFormGroup: UntypedFormGroup;

    /**
     * INFO: add comment
     */
    public get childControl() {
        return this.dateTimeFormGroup?.controls?.time;
    }

    /**
     * Default date in case no date is defined
     */
    public defaultDate = startOfToday();

    /**
     * Default time (in a Date object form) in case no time is defined.
     *
     * IMPORTANT: Although it is a Date object, only the time part will be used.
     */
    public defaultTime = startOfToday();

    readonly autofilled: boolean;
    readonly empty: boolean;
    readonly userAriaDescribedBy: string;

    /**
     * @ignore
     * @internal
     * The registered callback function called when an input event occurs on the input element.
     */
    private _onChange: (_: Date | null) => void = (_: Date | null) => {
        /*noop*/
    };

    /**
     * @ignore
     * @internal
     * The registered callback function called when a blur event occurs on the input element.
     */
    private _onTouched: () => void = () => {
        /*noop*/
    };

    /**
     * Class constructor
     *
     * @param _fb
     * @param _fm
     * @param matFormField
     * @param injector
     * @param cdRef
     * @param translateService
     * @param renderer
     * @param elementRef
     */
    public constructor(
        private _fb: UntypedFormBuilder,
        private _fm: FocusMonitor,
        private matFormField: MatFormField,
        private injector: Injector,
        private cdRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private renderer: Renderer2,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        this.dateTimeFormGroup = this._fb.group({
            date: new UntypedFormControl(undefined),
            time: new UntypedFormControl(undefined),
        });

        this._fm.monitor(elementRef, true).subscribe((origin: FocusOrigin) => {
            // when the element is blurred, the emitted 'origin' is null
            if (origin === null) {
                this.dateTimeFormGroup.controls['date'].markAsTouched();
                this.dateTimeFormGroup.controls['time'].markAsTouched();

                (this as WithLogger).logger.debug('FocusMonitor: element is blurred');
                this.onDateTimeChange();
            }

            this.focused = !!origin && !this.disabled;
            this.stateChanges.next();
        });
    }

    /**
     * Component lifecycle hook
     */
    public ngOnInit(): void {
        this.ngControl = this.injector.get<NgControl>(NgControl, null);

        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }

        // the parent node of the _connectionContainerRef is the 'div.form-field-wrapper' which
        // defines the final width of the form field
        // const parentNode: HTMLElement = this.renderer.parentNode(
        //     this.matFormField._connectionContainerRef.nativeElement,
        // );
        // if (parentNode) {
        //     this.renderer.addClass(parentNode, `${componentName}-form-field-wrapper`);
        // }

        this.dateTimeFormGroup
            .get('time')
            .valueChanges.pipe(
                distinctUntilChanged(),
                debounceTime(50),
                tap(this.timeControlValueChangesHandler),
                takeUntilDestroy(this),
            )
            .subscribe();
    }

    /**
     * timeControlValueChangesHandler
     *
     * @param timeValue
     */
    timeControlValueChangesHandler(timeValue: string): void {
        const timeControl = this.dateTimeFormGroup?.get('time');
        if (!timeControl) {
            return;
        }

        // check if minutes first number is greater than 5 and add 0 before it
        if (timeValue?.length === 3 && timeValue[2] >= '6') {
            const newTime = timeValue.slice(0, 2) + '0' + timeValue.slice(-1);
            timeControl.setValue(newTime);
        }
        if (timeValue?.length === 4) {
            this.stateChanges.next();
            this.updateValueAndValidity();
        }
    }

    /*
    IMPORTANT: the '_onValidatorChange()' callback from Validator API should not be called here to
    update the validity of the control because it triggers a valueChange event! therefore we call
    'updateValueAndValidity()' manually on the control instead and without emitting the valueChanges
    event :)
    */
    private updateValueAndValidity() {
        if (this.ngControl && this.ngControl.control) {
            this.ngControl.control.updateValueAndValidity({ emitEvent: false });
        }
        this.stateChanges.next();
        this.cdRef.detectChanges();
    }

    /**
     * Component lifecycle hook
     */
    public ngOnDestroy(): void {
        this.stateChanges.complete();
        this._fm.stopMonitoring(this.elementRef.nativeElement);

        if (this.translateOnLangChangeSubscription) {
            this.translateOnLangChangeSubscription.unsubscribe();
        }
    }

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    public setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    public onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elementRef.nativeElement.querySelector('input').focus();
        }
    }

    /**
     * Part of {@link ControlValueAccessor} API Sets the "value" property on the input element.
     * @ignore
     * @internal
     */
    public writeValue(value: Date): void {
        this.value = value;
    }

    /**
     * Part of {@link ControlValueAccessor} API Registers a function to be called when the control
     * value changes.
     * @ignore
     * @internal
     */
    public registerOnChange(fn: (val: Date | null) => void): void {
        this._onChange = fn;
    }

    /**
     * Part of {@link ControlValueAccessor} API Registers a function to be called when the control is
     * touched.
     * @ignore
     * @internal
     */
    public registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    /**
     * Part of {@link ControlValueAccessor} API Sets the "disabled" property on the input element.
     * @ignore
     * @internal
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.stateChanges.next();
    }

    /**
     * Part of {@link Validator} API
     * @ignore
     * @internal
     */
    public registerOnValidatorChange(_fn: () => void): void {
        // we don't need to keep a reference to the callback function (i.e. in a '_onValidatorChange'
        // property) because such callback, when it is called to update the validity of the control,
        // it triggers a valueChange event too!
    }

    /**
     * Part of {@link Validator} API
     * @ignore
     * @internal
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return { ...this.datePicker.validate(control), ...this.dateTimeFormGroup.errors };
    }

    /**
     * Handle change of time input
     */
    public onDateTimeChange(): void {
        // Update the form control value
        const dateTime = this.constructDateTime();
        console.log('dateTime', dateTime);
        this.value = dateTime;
        this._onTouched();
        this._onChange(dateTime);
        this.dateTimeChange.emit(dateTime);
        this.stateChanges.next();
    }

    /**
     * Construct the date time model based on the internal form controls for date and time
     */
    public constructDateTime(): Date | null {
        const date = this.dateTimeFormGroup.getRawValue().date;
        const time = this.dateTimeFormGroup.getRawValue().time;
        let hours = time?.substring(0, 2) || 0;
        let minutes = time?.substring(2, 4) || 0;

        if (time && !date) {
            this.timeHasChanged.emit();
        }

        if (time && date) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
        }

        if (!time && date) {
            // NOTE: change time control value only when focused element is not the time control input
            if (document.activeElement !== this.timeInput.nativeElement) {
                this.dateTimeFormGroup.controls['time'].setValue(this.constructTimeStringFromDate(this.defaultDate));
                hours = 0;
                minutes = 0;
            }

            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
        }

        return null;
    }

    /**
     * Construct a time string (with the format "HH:mm:ss:SSS") from the given date
     *
     * @param dateTime
     */
    public constructTimeStringFromDate(dateTime: Date): string {
        // Prev implamantation had additional values, but we need atm only HH:mm
        // `0${dateTime.getSeconds()}`, `00${dateTime.getMilliseconds()}`,
        return [
            /* prettier-ignore */
            `0${dateTime.getHours()}`,
            `0${dateTime.getMinutes()}`,
        ]
            .map((timePart: string, index: number) => {
                const numberOfChars = index === 3 ? 3 : 2; // for milliseconds 3 chars should be taken
                return timePart.substring(timePart.length - numberOfChars);
            })
            .join('');
    }

    /**
     * Return an array with the numeric values of the time part of the given date
     *
     * @param dateTime
     */
    public getTimeNumericValuesFromDate(dateTime: Date): number[] {
        // Prev implamantation had additional values, but we need atm only HH:mm
        // dateTime.getSeconds(), dateTime.getMilliseconds(),
        return [
            /* prettier-ignore */
            dateTime.getHours(),
            dateTime.getMinutes(),
        ];
    }
    /**
     * Focus the time input field
     *
     * @param event
     */
    public focusTimeInput(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.timeInput.nativeElement.focus();
        this.dateTimeFormGroup.controls['time'].setValue('');
    }

    /**
     * Clears the values of the internal form controls for date and time
     */
    public clearDateTime(): void {
        this.dateTimeFormGroup.reset();
        this._onTouched();
        this._onChange(null);
        this.dateTimeChange.emit(null);
    }
}
