import { ApiModels } from '@mona/api';
import { labValueColors, VitalSignCodes, VitalSignColorCodeMap, VitalSignType } from '@mona/models';

/**
 * Transforms api vital sign type
 *
 * @param apiVitalSignType ApiModels.VitalSignType
 * @param idx
 */
export const transformApiVitalSignType = (apiVitalSignType: ApiModels.VitalSignType, idx: number): VitalSignType => {
    /* Get color from HARDCODED vital map or from palette */

    const hardcodedSignColor = VitalSignColorCodeMap.get(apiVitalSignType.code as VitalSignCodes);
    const fallbackSignColor =
        idx <= labValueColors.length ? labValueColors[idx] : labValueColors[idx - labValueColors.length];

    const rawModel = ApiModels.VitalSignType.DTO.toModel(apiVitalSignType);
    const model: VitalSignType = {
        ...rawModel,
        id: apiVitalSignType.code,
        chartColorLightMode: hardcodedSignColor || rawModel.chartColorLightMode || fallbackSignColor,
        chartColorDarkMode: hardcodedSignColor || rawModel.chartColorDarkMode || fallbackSignColor,
        color: hardcodedSignColor || fallbackSignColor,
    };

    return model;
};

/**
 * Transform api vital sign types
 *
 * @param apiVitalSignTypes  ApiModels.VitalSignType[]
 */
export const transformApiVitalSignTypes = (apiVitalSignTypes: ApiModels.VitalSignType[]): VitalSignType[] => {
    return apiVitalSignTypes.map((apiVitalSign, i) => transformApiVitalSignType(apiVitalSign, i));
};
