import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import { PendingChangesEmitType } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { WithLogger } from '@mona/shared/logger';
import { toSuccessActionResult } from '@mona/shared/utils';
import { DialogService } from '@mona/ui';
import { PendingChangesDialogComponent } from './pending-changes-dialog.component';

/**
 * ChangesGuard
 
 * Show dialog if has changes always show "logout" and "discard" buttons Check permission if
 * can edit in this section if true, show review button After discarding changes allow access to the route
 * after logout click disable access to the route and logout
 */
@Injectable({ providedIn: 'root' })
@WithLogger({
    loggedMethodsNames: ['canActivate'],
})
export class ChangesGuard {
    /**
     * constructor
     *
     * @param changeLogService
     * @param dialogService
     * @param authService
     */
    constructor(
        private changeLogService: ChangeLogService,
        private dialogService: DialogService,
        private authService: AuthService,
    ) {}

    /**
     * canActivate - Check if user has changes and show dialog
     *
     * @param route
     */
    canActivate(
        route: ActivatedRouteSnapshot,
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.changeLogService.loadChanges(route.params.encounterId);

        return this.changeLogService.getLoadChangesAction().pipe(
            toSuccessActionResult(true),
            withLatestFrom(this.authService.user$),
            tap(([sortedChanges, user]) => {
                if (sortedChanges?.length > 0 && sortedChanges[0].practitionerId !== user.id) {
                    const hasPermission = this.changeLogService.checkPendingChangesModelsPermissions(sortedChanges);

                    this.dialogService
                        .open(PendingChangesDialogComponent, {
                            hasPermission,
                        })
                        .pipe(
                            tap((type: PendingChangesEmitType) => {
                                if (type === PendingChangesEmitType.DISCARD) {
                                    this.changeLogService.discardChanges();
                                } else if (type === PendingChangesEmitType.LOGOUT) {
                                    this.authService.logOut();
                                }
                            }),
                        )
                        .subscribe();
                }
            }),
            mapTo(true),
            catchError(error => {
                (this as WithLogger).logger.error(error);
                return of(true);
            }),
        );
    }
}
