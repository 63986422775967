import { ApiModels } from '@mona/api';
import { Practitioner } from '@mona/models';

/**
 * Transforms API Practitioner
 *
 * @param practitioner apiPractitioner
 */
export const transformApiPractitioner = (practitioner: ApiModels.Practitioner): Practitioner => {
    return {
        id: practitioner.id,
        lastName: practitioner.last_name,
        firstName: practitioner.first_name,
        displayName: `${practitioner.prefix || ''} ${practitioner.first_name} ${practitioner.last_name}`?.trim(),
        profileImageUrl: practitioner.image,
        phone: practitioner.phone,
        prefix: practitioner.prefix,
        role: practitioner.role,
    };
};

/**
 * Transforms API Practitioners
 *
 * @param practitioners apiPractitioners
 */
export const transformApiPractitioners = (practitioners: ApiModels.Practitioner[]): Practitioner[] => {
    return practitioners?.map(encounter => transformApiPractitioner(encounter));
};
