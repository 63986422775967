import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { addDays, startOfDay } from 'date-fns';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, OperatorFunction, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { Encounter } from '@mona/models';
import { DateIntervalService, DateRangeInterval, EntriesInterval } from '@mona/shared/date';
import { get, notEmpty } from '@mona/shared/utils';
import { selectRouteParam } from '@mona/store';

const selectEncountersState = createFeatureSelector('encountersData');
const selectCurrentEncounterState = createFeatureSelector('currentEncounterData');

const selectSelectedEncounter = createSelector(
    selectEncountersState,
    selectRouteParam('encounterId'),
    (encounters: EntityState<any>, encounterId) => encounters?.entities?.[encounterId],
);

/** Encounter View Selected Date */
export const selectencounterViewSelectedDate = createSelector(
    selectCurrentEncounterState,
    (state: { selectedDate: Date }) => {
        return state?.selectedDate;
    },
);

/** Encounter View Selected Date */
export const selectEncounterReviewMode = createSelector(
    selectCurrentEncounterState,
    (state: { isReviewMode: boolean }) => {
        return state?.isReviewMode;
    },
);

/**
 * `isReviewMode` as factory based on Angular `inject` mechabism
 */
export function isReviewMode(): Observable<boolean> {
    const store = inject(Store);
    return store.select(selectEncounterReviewMode).pipe(distinctUntilChanged());
}

/**
 * Shows if user is able to edit patient info
 */
export function isAbleToEdit(): Observable<boolean> {
    const permissionsService = inject(NgxPermissionsService);
    return isReviewMode().pipe(map(isReview => !isReview || !!permissionsService.getPermission('review_mode_edit')));
}

/**
 * Select current encounter property
 *
 * @param prop
 */
export function selectCurrentEncounterProp<P extends Path<Encounter>>(prop: P) {
    return createSelector(selectSelectedEncounter, (encounter: Encounter) => get(encounter, prop));
}

/**
 * Get current encounter property as an observable
 *
 * @param prop
 */
export function getCurrentEncounterProp<P extends Path<Encounter>>(prop: P): Observable<PathValue<Encounter, P>> {
    const store = inject(Store);
    return store.select(selectCurrentEncounterProp(prop));
}

/**
 * Encounter Start Date
 *
 * @param asStartOfDay
 * @usageNotes uses {@link inject} to get the store, so use in constructor only
 */
export const getEncounterStartDate = (asStartOfDay = false) => {
    const store = inject(Store);
    return store.select(selectCurrentEncounterProp('startDate')).pipe(
        notEmpty(),
        map(date => (asStartOfDay ? startOfDay(date) : date)),
    );
};

/**
 * Encounter View Selected Date
 * used to filter all views which use day calendar
 *
 * @usageNotes uses {@link inject} to get the store, so use in constructor only
 */
export const getEncounterViewSelectedDate = () => {
    const store = inject(Store);
    return store.select(selectencounterViewSelectedDate).pipe(distinctUntilChanged());
};

/**
 * Encounter View Date range
 * used to filter all views which use day calendar
 *
 * @param daysInFuture
 * @usageNotes uses {@link inject} to get the store, so use in constructor only
 */
export const getEncounterViewDateRange = (daysInFuture = 1): Observable<DateRangeInterval> => {
    const store = inject(Store);
    const dateIntervalService = inject(DateIntervalService);
    return combineLatest([
        store.select(selectCurrentEncounterProp('startDate')),
        dateIntervalService.getTimeChangeTrigger(of(EntriesInterval.DAY_1)),
    ]).pipe(
        map(([startDate]) => startDate),
        notEmpty(),
        map(startDate => ({
            start: startDate,
            end: addDays(new Date(), daysInFuture),
        })),
    );
};

/**
 * 🤖 Operator to join Current Encounter Id to the source
 */
export function withCurrentEncounterId<T>(): OperatorFunction<T, [T, string]> {
    const store = inject(Store);
    return withLatestFrom(
        store.select(selectRouteParam('encounterId')).pipe(
            notEmpty(),
            // TODO: throw error if encounterId is empty
            /* mergeMap((encounterId: string | undefined) => isEmptyString(encounterId) ? throwError(new Error('Router parameter for `encounterId` not found')) : encounterId, ), */
        ),
    );
}
