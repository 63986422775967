/* eslint-disable jsdoc/require-jsdoc,  @typescript-eslint/member-ordering */
import { isDataSource } from '@angular/cdk/collections';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    Optional,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
    compareDeepEqual,
    FilterableDataSource,
    isEmpty,
    isNullOrUndefined,
    isObject,
    isString,
    takeUntilDestroy,
} from '@mona/shared/utils';
import { Animations } from '@mona/ui/animations';
import { BaseCVAMaterial } from '../../mixins';
import { CVA, MatFormFieldControlEx } from '../../models';

const AUTOCOMPLETE_DEBOUNCE_TIME = 250;
const AUTOCOMPLETE_CLEAR_OVERLAY_POSITIONS = [
    new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
];

/** Autocomplete Component */
@Component({
    selector: 'ui-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: UiAutocompleteComponent }],
    inputs: ['value', 'placeholder', 'disabled', 'required'],
    animations: [Animations.fadeInOut],
})
export class UiAutocompleteComponent extends BaseCVAMaterial implements MatFormFieldControlEx<any>, CVA {
    static nextId = 0;

    /** Inner form control to link input text changes to mat autocomplete */
    viewModel: FormControl<any> = new FormControl<any>(null, []);

    /** Filterable DataSource instance */
    private _dataSource: FilterableDataSource<any> = new FilterableDataSource();
    /** Filterable DataSource */
    get dataSource(): FilterableDataSource<any> {
        return this._dataSource;
    }
    @Input() set dataSource(data: any[] | Observable<any[]> | FilterableDataSource<any>) {
        if (isEmpty(data)) {
            return;
        }
        this._dataSource.disconnect();
        if (!isDataSource(data)) {
            this._dataSource = new FilterableDataSource(data);
        } else {
            this._dataSource = data;
        }
        this._dataSource.connect();
    }

    @Input() name = '';
    @Input() labelKey: string;
    @Input() valueKey: string;
    @Input() hasSearchButton = false;
    @Input() showProgress = true;
    @Input() lengthToTriggerSearch = 3;
    @Input() clearWithConfirmation = false;
    @Input() clearAfterSearch = false;
    @Input() hideResetBtn = false;
    @Input() allowNonExisting = true;
    @Input() noResultsLabel = 'commonStrings.search.noResults';
    @Input() isSearchingLabel = 'commonStrings.search.isSearching';
    @Input() addNewLabel = 'commonStrings.search.addNew';
    @Input() panelClass?: string;
    @Input() displayTemplate?: TemplateRef<any>;
    @Input() addNewTemplate?: TemplateRef<any>;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() autocompleteOptionSelected = new EventEmitter();
    @Output() autocompleteOptionAddNew = new EventEmitter();

    /** Autocomplete panel reference */
    @ViewChild('autocomplete', { static: true }) autocomplete: MatAutocomplete;
    /** Autocomplete input reference */
    @ViewChild('autocompleteInput', { static: true }) autocompleteInputElementRef: ElementRef;
    /** Autocomplete input reference */
    @ViewChild('autocompleteReset', { static: true }) autocompleteResetRef: MatButton;
    /** Autocomplete input element */
    get autocompleteInput(): HTMLInputElement {
        return this.autocompleteInputElementRef.nativeElement;
    }

    get errorState(): boolean {
        return this.viewModel.invalid && this.touched;
    }

    /** HostBinding id */
    @HostBinding() id = `ui-autocomplete-${UiAutocompleteComponent.nextId++}`;
    /** HostBinding floating class */
    @HostBinding('class.floating') get shouldLabelFloat() {
        return this.focused || !!this.autocompleteInput.value?.length;
    }
    /** The <mat-form-field> will add a class based on this type that can be used to easily apply special styles to a <mat-form-field> that contains a specific type of control. */
    controlType = 'autocomplete';

    /** Where to render confirmation overlay relative to the clear button */
    positions = AUTOCOMPLETE_CLEAR_OVERLAY_POSITIONS;

    isClearOverlayOpened = false;
    selectedOption: any;
    query = '';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    @Input() set value(v: AnyObject) {
        if (isNullOrUndefined(v)) {
            this.setInputValue('');
            return;
        }

        if (compareDeepEqual(this.value, v)) {
            return;
        }
        const { inputValue, formValue } = this.processValue(v, this.labelKey, this.valueKey);
        if (!inputValue || !formValue) {
            return;
        }
        this.viewModel.setValue(inputValue, { emitEvent: false });
        super.value = formValue;
        // this.onChange(this.value);
    }

    /**
     * Constructor
     *
     * @param _formField
     * @param cdRef
     */
    constructor(@Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField, cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

    /** Lifecycle */
    ngOnInit() {
        super.ngOnInit();
        this.watchInputValue();
    }

    /**
     * Method linked to the mat-autocomplete `[displayWith]` input.
     * This is how result name is printed in the input box.
     *
     * @param result
     */
    displayFn(result: any): string | undefined {
        return typeof result === 'string' ? result : result?.[this.labelKey] || '';
    }

    onContainerClick(event: MouseEvent): void {
        if (this.disabled) {
            return;
        }
        if (event.target instanceof HTMLInputElement) {
            this.autocompleteInput.focus();
        }
    }

    /**
     * Trigger local search by filtering datasource data OR
     * trigger remote search
     *
     * All handled by {@link FilteredDataSource}
     *
     * @param force
     */
    filterData(force?: boolean) {
        this.query = this.autocompleteInput.value;

        // empty query is not allowed for autocomplete
        if (this.isQueryEmpty(this.query) && this.lengthToTriggerSearch !== 0) {
            this.dataSource.filter = '';
            return;
        }

        if (force || this.isMinLength(this.query)) {
            this.dataSource.filter = this.query;
        }
    }

    autocompleteSelected($event: MatAutocompleteSelectedEvent) {
        this.selectedOption = $event.option.value;

        if (this.autocompleteInput) {
            this.setInputValue(this.selectedOption);
            this.query = this.autocompleteInput.value;
        }

        this.writeValue(this.selectedOption);
        this.autocompleteOptionSelected.emit(this.selectedOption);
        this.elRef.nativeElement.blur();
        // this.focused = false;
        this.stateChanges.next();

        this.dataSource.filter = this.query;
        if (this.clearAfterSearch) {
            this.resetAll();
        }
        this.autocompleteResetRef?.focus();
    }

    autocompleteOptionAdded() {
        if (this.query) {
            this.writeValue(this.query as any);
        }

        this.autocompleteOptionSelected.emit(this.value);
        this.autocompleteResetRef?.focus();
    }

    /** Reset autocomplete input and formControl itself */
    autocompleteReseted() {
        this.resetAll();
    }

    setDisabledState(isDisabled: boolean): void {
        this.onDisabledChange(isDisabled);
    }

    onDisabledChange(v: boolean): void {
        v ? this.viewModel.disable() : this.viewModel.enable();
    }

    /**
     * Dismiss panel via escape
     *
     * @param e
     */
    dismissViaEscape(e: KeyboardEvent): void {
        if (e?.key?.toLowerCase() === 'escape') {
            this.isClearOverlayOpened = false;
            this.stateChanges.next();
        }
    }

    private setInputValue(value: string | Record<string, string>) {
        const stringValue = isString(value) ? value : value[this.labelKey || 'name'];
        this.autocompleteInput.value = stringValue;
        this.cdRef.detectChanges();
    }

    /**
     * Process selected value and get values for input element and form
     *
     * - if `valueKey` is provided - we expect `formValue` to be string
     * - if `valueKey` is not provided - we expect `formValue` to be  object if v is object,
     * otherwise create object if `labelKey` provided
     *
     * @param value
     * @param labelKey
     * @param valueKey
     */
    private processValue(
        value: AnyValue | AnyObject,
        labelKey: string,
        valueKey: string,
    ): { inputValue: string; formValue: any } {
        let inputValue: string, formValue: any;
        if (isObject(value)) {
            inputValue = value[labelKey];
            formValue = value[valueKey] || value;
        } else if (typeof value === 'string' && labelKey) {
            inputValue = value;
            formValue = value[valueKey] || {
                [labelKey]: value,
            };
        }
        return { inputValue, formValue };
    }

    /** Reset autocomplete input and formControl itself */
    private resetAll() {
        this.viewModel.reset(undefined, { emitEvent: false });
        this.viewModel.markAsPristine();
        this.viewModel.setErrors(null);
        // this.ngControl.control?.enable();
        // this.ngControl.control?.reset();
        this.touched = false;
        this.query = '';
        this.setInputValue('');
        this.dataSource.filter = undefined;
        this.selectedOption = null;
        this.writeValue(null);
    }

    /** Watch input value & Trigger autocomplete search */
    private watchInputValue() {
        this.viewModel.valueChanges
            .pipe(
                /* prettier-ignore */
                debounceTime(AUTOCOMPLETE_DEBOUNCE_TIME),
                distinctUntilChanged(),
                takeUntilDestroy(this),
            )
            .subscribe(value => {
                if (isString(value) && !this.disabled) {
                    this.filterData();
                }
            });
    }

    private isQueryEmpty(query: string): boolean {
        return query.length <= 0;
    }

    private isMinLength(query: string) {
        return query?.length >= this.lengthToTriggerSearch;
    }
}
