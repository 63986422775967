import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { addDays, isAfter, isBefore } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { DailyGoal } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { DailyGoalsState } from '../entities';
import { DailyGoalsSelectors } from '../state';
import { DailyGoalsActions } from '../state/actions/daily-goals.actions';
import { selectDailyGoalAll, selectDailyGoalsEntities } from '../state/selectors/daily-goals.selectors';

/**
 * Notifications store service
 */
@Injectable({ providedIn: 'root' })
export class DataAccessDailyGoalsFacade {
    /** Get daily goals map */
    readonly dailyGoals$: Observable<{ active: DailyGoal[]; inactive: DailyGoal[] }> = this.store
        .select(selectDailyGoalAll)
        .pipe(
            map(dailyGoals => {
                const todayResetTime = this.getGoalsResetDate();
                const activeBoundaries = isAfter(new Date(), todayResetTime)
                    ? {
                          start: todayResetTime,
                          end: addDays(todayResetTime, 1),
                      }
                    : {
                          start: addDays(todayResetTime, -1),
                          end: todayResetTime,
                      };

                return dailyGoals.reduce(
                    (acc, dailyGoal) => {
                        const isActiveGoal =
                            isAfter(dailyGoal.date, activeBoundaries.start) &&
                            isBefore(dailyGoal.date, activeBoundaries.end);

                        acc[isActiveGoal ? 'active' : 'inactive'].push(dailyGoal);
                        return acc;
                    },
                    { active: [], inactive: [] },
                );
            }),
        );

    /** daily goals map */
    readonly dailyGoalsMap$: Observable<Record<string, DailyGoal>> = this.store.select(selectDailyGoalsEntities);

    /** is daily goals data loading */
    readonly isLoading$ = this.store.select(DailyGoalsSelectors.selectIsLoading);

    private _resetDate: Date;

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService
     * @param configService
     */
    constructor(
        private store: Store<DailyGoalsState>,
        private changeLogService: ChangeLogService,
        private configService: ConfigService,
    ) {}

    /** load daily goals data */
    loadDailyGoalsData(): void {
        this.store.dispatch(DailyGoalsActions.loadDailyGoals());
    }

    /**
     * Returns the reset hour in local time for goals calculation.
     * If the reset hour is already set, returns the stored value.
     */
    getGoalsResetDate(): Date {
        if (!this._resetDate) {
            const balanceResetUtcHour = this.configService.get('balanceCalculationResetTime');
            const utcHours = balanceResetUtcHour ? +balanceResetUtcHour.split(':')[0] : 5; // 5 a.m fallback
            this._resetDate = new Date(new Date().setUTCHours(utcHours, 0, 0));
        }
        return this._resetDate;
    }

    /**
     * INFO: add comment
     * @param goal
     */
    createDailyGoal(goal: Partial<DailyGoal>): void {
        this.changeLogService.createDailyGoal(goal);
    }

    /**
     * INFO: add comment
     * @param goal
     */
    updateDailyGoal(goal: Partial<DailyGoal>): void {
        this.changeLogService.updateDailyGoal(goal);
    }

    /**
     * INFO: add comment
     * @param id
     */
    removeDailyGoal(id: string): void {
        this.changeLogService.removeDailyGoal(id);
    }

    /**
     * Clear daily goals store
     */
    clearDailyGoals(): void {
        this.store.dispatch(DailyGoalsActions.clearDailyGoals());
    }
}
