import { SetOptional } from 'type-fest';
import { ApiModels } from '@mona/api';
import { WorkflowAnswerOption } from './workflow-answer-option.model';

/**
 * Possible question types type
 */
export type WorkflowQuestionTypeEnum =
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'textarea'
    | 'datepicker'
    | 'datetimepicker'
    | 'text';

/**
 * Possible question types enum
 */
export const WorkflowQuestionTypesEnum = {
    Checkbox: 'checkbox' as WorkflowQuestionTypeEnum,
    Radio: 'radio' as WorkflowQuestionTypeEnum,
    Select: 'select' as WorkflowQuestionTypeEnum,
    Textarea: 'textarea' as WorkflowQuestionTypeEnum,
    Datepicker: 'datepicker' as WorkflowQuestionTypeEnum,
    Datetimepicker: 'datetimepicker' as WorkflowQuestionTypeEnum,
    Text: 'text' as WorkflowQuestionTypeEnum,
};

/**
 * Workflow question
 */
export interface WorkflowQuestion extends SetOptional<ApiModels.WorkflowQuestionnaireQuestion.Model, 'id'> {}
