<div class="mona-lock-screen__content">
    <div class="mona-lock-screen__content-column">
        <mona-logo
            class="mona-lock-screen__logo"
            type="text"
            matTooltip="Build: {{ version }}"
            matTooltipPosition="after"
            matTooltipClass="bg-primary"
        ></mona-logo>
        <div class="mona-lock-screen__info">
            <div class="mona-lock-screen__time" (dblclick)="onTimeDoubleClick()">
                {{ time$ | async }}
            </div>
            <div class="mona-lock-screen__date">
                {{ date$ | async }}
            </div>
            <div class="mona-lock-screen__location">
                <ng-container
                    [cdkPortalOutlet]="lockScreenPortalService.lockScreenLocationPortal$ | async"
                ></ng-container>
            </div>
        </div>
    </div>
    <div class="mona-lock-screen__content-column">
        <div class="mona-lock-screen__top-container">
            <div class="mona-lock-screen__notifications">
                <ng-container [cdkPortalOutlet]="lockScreenPortalService.lockScreenInfoPortal$ | async"></ng-container>
            </div>
            <div class="mona-lock-screen__ce-label">
                <mat-icon *ngIf="isPdmsEnabled" svgIcon="ce-number"></mat-icon>
            </div>
        </div>
        <div [ngSwitch]="viewState$ | async" class="mona-lock-screen__sign-in">
            <mona-maintenance *ngSwitchCase="viewStates.MAINTENANCE"></mona-maintenance>
            <mona-sign-in-rfid
                *ngSwitchCase="viewStates.RFID"
                (openSignInDialog)="openSignInDialog()"
            ></mona-sign-in-rfid>
            <mona-sign-in-pin *ngSwitchCase="viewStates.PIN" (pinSubmit)="onPinSubmit($event)"></mona-sign-in-pin>
            <mona-sign-in-welcome
                [isLoading]="isLoading$ | async"
                [user]="user$ | async"
                *ngSwitchCase="viewStates.WELCOME"
            ></mona-sign-in-welcome>
            <div *ngSwitchDefault></div>
        </div>
    </div>
</div>

<video autoplay muted loop class="mona-lock-screen__bg-video">
    <source src="assets/videos/lock-screen.mp4" type="video/mp4" />
    Your browser does not support HTML5 video.
</video>
