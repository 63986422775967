import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { DrawerNavItem } from '../../drawer-nav-item.model';
import { DrawerService } from '../../drawer.service';
import { StateListener } from '../../state-listener.component';
import { DrawerNavItemComponent } from '../nav-item';

/**
 * INFO: add comment
 */
export type DrawerNavGroup = {
    /**
     * INFO: add comment
     */
    divider?: boolean;
    /**
     * INFO: add comment
     */
    title?: string;
    /**
     * INFO: add comment
     */
    items: DrawerNavItem[];
};

/**
 * [DrawerNavGroup Component](https://brightlayer-ui-components.github.io/angular/?path=/info/components-drawer--readme)
 *
 * A `<ui-drawer-nav-group>` is used inside of the `<ui-drawer-body>` to organize links and content.
 * Each group consists of an (optional) group title and a series of NavItems.
 */
@Component({
    selector: 'ui-drawer-nav-group',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styles: [
        `
            .ui-drawer-nav-group-content .mat-mdc-list-base {
                font-weight: 600;
                padding-top: 0;
            }
            .ui-drawer-nav-group-content .ui-drawer-nav-group-title {
                font-weight: 600;
                height: 3rem;
                line-height: 3rem;
                padding: 0 16px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
            }
            .ui-drawer-nav-group-content .ui-drawer-nav-group-title-closed {
                visibility: hidden;
            }
        `,
    ],
    template: `
        <div class="ui-drawer-nav-group-content">
            <div
                *ngIf="title"
                class="ui-drawer-nav-group-title mat-overline"
                [class.ui-drawer-nav-group-title-closed]="!isOpen()"
            >
                {{ title }}
            </div>
            <ng-content select="ui-title-content"></ng-content>
            <mat-divider *ngIf="divider"></mat-divider>
            <mat-nav-list>
                <ng-content select="ui-drawer-nav-item"></ng-content>
            </mat-nav-list>
        </div>
    `,
    host: {
        class: 'ui-drawer-nav-group',
    },
})
export class DrawerNavGroupComponent extends StateListener implements Omit<DrawerNavGroup, 'items'>, AfterViewInit {
    /** Whether to show a dividing line below the title */
    @Input() divider = false;
    /** Component to render a group title */
    @Input() title: string;
    @ContentChildren(DrawerNavItemComponent) navItems;

    /**
     * INFO: add comment
     *
     * @param drawerService
     * @param changeDetectorRef
     */
    constructor(drawerService: DrawerService, changeDetectorRef: ChangeDetectorRef) {
        super(drawerService, changeDetectorRef);
    }

    /**
     * Whenever a new navigation item is created async or conditionally,
     * re-iterate through the navigation tree to assign the correct depth and top-level or nested state defaults to it.
     */
    ngAfterViewInit(): void {
        this._initializeNavItemDefaults(0, this.navItems);

        // This is only called for async or conditionally loaded items.
        this.drawerService.drawerNewNavItemCreated().subscribe(() => {
            setTimeout(() => {
                this._initializeNavItemDefaults(0, this.navItems);
            });
        });
    }

    private _initializeNavItemDefaults(depth: number, navItems: DrawerNavItemComponent[]): void {
        for (const item of navItems) {
            item.incrementDepth(depth);
            this._initializeNavItemDefaults(depth + 1, item.nestedNavItems);
        }
    }
}
