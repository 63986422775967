import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { WithPermissions } from '@mona/auth';
import { DynamicPdmsPermissionEnum, Encounter, MedicationPrescription } from '@mona/models';
import { transformApiMedicationPrescriptions } from './transforms';

/**
 * API abstraction layer for the medication prescriptions API
 */
@Injectable({
    providedIn: 'root',
})
export class MedicationPrescriptionsApi {
    apiBase = '/pdms/medication-prescriptions/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get medication prescriptions
     *
     * @param encounterId EntityId<Encounter>
     */
    @WithPermissions<Observable<MedicationPrescription[]>>(DynamicPdmsPermissionEnum.INPUT_OUTPUTS_ACCESS, of([]))
    getMedicationPrescriptions(encounterId: EntityId<Encounter>): Observable<MedicationPrescription[]> {
        return this.http
            .get<ApiModels.MedicationPrescription[]>(this.apiBase, {
                params: {
                    encounter_id: encounterId as string,
                },
            })
            .pipe(map(apiMedications => transformApiMedicationPrescriptions(apiMedications)));
    }
}
