import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Bed } from '@mona/models';

/**
 * Beds state
 */
export interface BedsState extends EntityState<Bed> {
    /**
     * Assigned bed
     */
    assignedBedId?: EntityId<Bed>;
    /**
     * selected id
     */
    selectedId: string | null;
    /**
     * Beds is loading
     */
    isLoading: boolean;
}

export const bedsAdapter: EntityAdapter<Bed> = createEntityAdapter<Bed>({
    selectId: (bed: Bed) => bed.id,
    sortComparer: (a: Bed, b: Bed) => (a.name > b.name ? 1 : -1),
});
