import { ApiModels } from '@mona/api';
import { ProcedureCategory } from '@mona/models';
import { compactObject } from '@mona/shared/utils';

/**
 * Transforms api procedure category
 * @param apiCategory ApiModels.ProcedureCategory
 */
export const transformApiProcedureCategory = (apiCategory: ApiModels.ProcedureCategory): ProcedureCategory => {
    return compactObject({
        id: apiCategory.code,
        code: apiCategory.code,
        displayName: apiCategory.display_name,
    }) as any;
};

/**
 * Transforms api procedure categories
 * @param apiCategories (ApiModels.ProcedureCategory | ApiModels.ProcedureCategory)[]
 */
export const transformApiProcedureCategories = (apiCategories: ApiModels.ProcedureCategory[]): ProcedureCategory[] => {
    return apiCategories.map(apiCategory => transformApiProcedureCategory(apiCategory));
};
