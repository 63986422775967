<div class="ui-table-wrapper scrollbar-visible" #scrollable>
    <table
        id="mainTable"
        mat-table
        class="ui-table"
        [class.ui-table--layout-auto]="!fixedLayout"
        [class.ui-table--striped]="isStriped"
        [class.ui-table--borderless]="isBorderless"
        [dataSource]="dataSource"
        [trackBy]="dataSource.trackBy"
    >
        <ng-content></ng-content>

        <!-- Top Header Column -->
        <ng-container *ngIf="headerColumns?.length">
            <!-- Top header cells -->
            <ng-container
                *ngFor="let thCol of headerColumns; trackBy: columnsTrackBy; let tIdx = index; let count = count"
                matColumnDef="{{ thCol.name }}"
            >
                <th
                    [colSpan]="thCol.colspan"
                    [attr.id]="'ui-table-th-top_' + tIdx"
                    mat-header-cell
                    *matHeaderCellDef
                    class="ui-table-th ui-table-th--top-header"
                    [class.ui-table-th--sticky]="stickyHeader"
                    [ngClass]="thCol.class"
                    [style.width.px]="columnWidth * thCol.colspan"
                >
                    <div class="ui-table-th__inner">
                        <div class="ui-table-th__inner-content">
                            <ng-container [ngSwitch]="thCol.format?.name">
                                <span *ngSwitchCase="'date'">
                                    {{ thCol | dataAccessor: thCol.dataAccessorKey | date: thCol.format.option }}
                                </span>
                                <ng-container *ngSwitchDefault>
                                    <span>{{ thCol.headerText || thCol.name }}</span>
                                </ng-container>
                            </ng-container>
                            <mat-icon class="ui-table-th__dts-icon" [inline]="true">update</mat-icon>
                        </div>
                    </div>
                </th>
            </ng-container>
        </ng-container>

        <!-- Default Column -->
        <ng-container
            *ngFor="let col of columns; let colId = index; trackBy: columnsTrackBy"
            matColumnDef="{{ col.name }}"
        >
            <th
                [attr.id]="'th_col_' + colId"
                [attr.data-colindex]="colId"
                mat-header-cell
                *matHeaderCellDef
                class="ui-table-th"
                [class.ui-table-th--hidden]="showNoData && hideTheadIfNoData"
                [ngClass]="['w-' + col.width || columnWidth]"
            >
                {{ col.headerText || col.name | translate }}
            </th>
            <td
                [attr.id]="'td_row_' + rowId + '_col_' + colId"
                [attr.data-rowindex]="rowId"
                [attr.data-colindex]="colId"
                [attr.data-hasvalue]="col.hasValue && col.hasValue(row, col)"
                mat-cell
                #cellContext="uiCellContext"
                [uiCellContext]="{
                        rowIdx: rowId,
                        row: row,
                        colIdx: colId,
                        column: col,
                        cell: cellValueGetter(row, col),
                        disabled: col.disabled?.(row, col),
                        width: columnWidth,
                        formConfig: editable && cellEditFormConfig && cellEditFormConfig[row.dataType]
                    }"
                *uiCellDef="
                    let row;
                    let rowId = index;
                    let odd = odd;
                    let last = last;
                    template: columnCellTemplate || defaultCellTemplate
                "
                (click.zoneless)="handleCellClick($event, cellContext)"
                class="ui-table-td"
                [class.ui-table-td--odd]="odd"
                [class.ui-table-td--has-value]="!!cellContext.cell"
                [class.ui-table-td--disabled]="cellContext.disabled"
                [ngClass]="col.class"
            >
                <!-- Custom cell template -->
                <ng-container *uiCellRef></ng-container>
            </td>
        </ng-container>

        <ng-container *ngIf="rowMenuItems?.length" matColumnDef="rowMenuColumn">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="ui-table-th ui-table-td--menu-button"
                [class.d-none]="isLoading"
            >
                <!-- TODO: add default header text -->
            </th>
            <td mat-cell *matCellDef="let row" class="ui-table-td ui-table-td--menu-button" [class.d-none]="isLoading">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="rowMenu"
                    [matMenuTriggerData]="{
                        $implicit: {
                            row: row,
                            col: null,
                            cell: null
                        }
                    }"
                    aria-label="row menu"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Default Header Row -->
        <ng-container>
            <tr
                class="ui-table-tr ui-table-tr--header"
                [class.d-none]="!showHeaderRow"
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: stickyHeader"
            ></tr>
        </ng-container>

        <!-- Default Data Row -->
        <tr
            class="ui-table-tr"
            [ngClass]="rowClassGetter ? rowClassGetter(row) : rowClass"
            mat-row
            *matRowDef="let row; let i = index; columns: displayedColumns; when: defaultWhen"
            (click.zoneless)="handleRowClick(row, i, $event)"
        ></tr>

        <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
        <ng-container *ngIf="showNoData">
            <tr class="ui-table-tr ui-table-tr--ui-empty-state" [class.d-none]="isLoading" *matNoDataRow>
                <ui-empty-state
                    [noDataTemplate]="noDataRowTemplate"
                    [title]="noDataConfig?.title"
                    [subTitle]="noDataConfig?.subTitle"
                    [ctaTitle]="noDataConfig?.ctaTitle"
                    [ctaPermission]="noDataConfig?.ctaPermission"
                    (ctaClick)="handleNoDataClick()"
                ></ui-empty-state>
            </tr>
        </ng-container>
    </table>
</div>

<mat-paginator *ngIf="withPagination" [pageSize]="pageSize"></mat-paginator>

<!-- Default cell template -->
<ng-template #defaultCellTemplate let-cellContext="$implicit" let-row="row" let-col="column">
    <span data-cellTemplate="default">{{ row | dataAccessor: col.dataAccessorKey || 'name' }}</span>
</ng-template>

<mat-menu
    #cellMenu="matMenu"
    [overlapTrigger]="false"
    yPosition="above"
    class="ui-table__menu-panel"
    backdropClass="ui-table__menu-overlay"
>
    <ng-template matMenuContent let-cellContext="$implicit">
        <button
            mat-menu-item
            class="ui-table__menu-item"
            *ngFor="let item of cellMenuItems"
            [disabled]="checkMenuItemDisabled(item, cellContext)"
            [class.d-none]="checkMenuItemHidden(item, cellContext)"
            (click.zoneless)="handleCellMenuClick(item, cellContext, cellMenu)"
        >
            <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
            {{ item.text | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #rowMenu="matMenu" [overlapTrigger]="false" xPosition="before">
    <ng-template matMenuContent let-cellContext="$implicit">
        <button
            mat-menu-item
            class="ui-table__menu-item"
            *ngFor="let item of rowMenuItems"
            [disabled]="checkMenuItemDisabled(item, cellContext)"
            [class.d-none]="checkMenuItemHidden(item, cellContext)"
            (click.zoneless)="handleCellMenuClick(item, cellContext, rowMenu)"
        >
            <mat-icon *ngIf="item.icon" [class.text-muted]>{{ item.icon }}</mat-icon>
            {{ item.text | translate }}
        </button>
    </ng-template>
</mat-menu>
