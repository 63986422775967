<div class="ui-dynamic-list-wrapper">
    <mat-selection-list
        [formControl]="control"
        [attr.name]="name"
        [multiple]="multiple"
        [compareWith]="compareFn"
        class="ui-dynamic-list-field scrollbar-visible"
        [ngClass]="customClass"
        (selectionChange)="submitOnSelect ? form.ngSubmit.emit() : {}"
    >
        <mat-list-option
            class="ui-dynamic-list-option"
            *ngFor="let selection of dataSource.filteredData; let i = index"
            [value]="selection[valueKey] || selection"
            [attr.data-testid]="name + '_option_' + i"
        >
            {{ selection[labelKey] || selection }}
        </mat-list-option>
    </mat-selection-list>
</div>
