<div class="ui-dynamic-textarea-wrapper">
    <mat-form-field [id]="name" class="ui-dynamic-textarea-field" [ngClass]="customClass">
        <mat-label>{{ label }}</mat-label>
        <textarea
            matInput
            [formControl]="control"
            [value]="control?.value | dataAccessor: valueKey"
            [placeholder]="placeholder || label"
            [required]="required"
            [attr.name]="name"
            [attr.data-testid]="name"
            [attr.minLength]="minLength"
            [attr.maxLength]="maxLength"
            [rows]="rows"
            [cdkAutosizeMaxRows]="rows"
            cdkAutosizeMinRows="2"
            cdkTextareaAutosize
        ></textarea>
        <mat-hint>{{ hint }}</mat-hint>
        <mat-error>
            <ng-template
                [ngTemplateOutlet]="errorMessageTemplate"
                [ngTemplateOutletContext]="{ control: control, errors: control?.errors }"
            ></ng-template>
            <ui-validation-message [control]="control"></ui-validation-message>
        </mat-error>
    </mat-form-field>
</div>
