import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UiMessage, UiMessageDefault } from '../../models';

/**
 * UiSnackbar Component
 */
@Component({
    selector: 'ui-snackbar',
    template: `
        <ui-message
            #uiMessage
            [closable]="false"
            [title]="data.title && data.title"
            [description]="data.description"
            [icon]="data.icon && data.icon"
            [type]="data.type"
            (openedChange)="handleSnackbarClose($event)"
        >
            <button
                *ngIf="data.action"
                mat-stroked-button
                ui-message-action
                (click)="uiMessage.close()"
                [attr.data-testid]="'toastBtn-' + data.action.title"
            >
                {{ data.action.title || 'commonStrings.keys.aknowledge' | translate }}
            </button>
        </ui-message>
    `,
    host: { class: 'ui-snackbar' },
    encapsulation: ViewEncapsulation.None,
})
export class UiSnackbarComponent {
    /**
     * Constructor
     *
     * @param data
     * @param snackbarRef
     */
    constructor(@Inject(MAT_SNACK_BAR_DATA) readonly data: UiMessage, public snackbarRef: MatSnackBarRef<UiMessage>) {
        Object.assign({ ...UiMessageDefault }, data);
    }

    /**
     * Handle snackbar close
     *
     * @param opened
     */
    handleSnackbarClose(opened: boolean) {
        if (!opened) {
            this.data.action ? this.snackbarRef.dismissWithAction() : this.snackbarRef.dismiss();
        }
    }
}
