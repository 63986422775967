import { ElementRef, Inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment, WEBRTC_CONFIG_DEFAULT } from '@environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay, exhaustMap, filter, skip, take } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { DeviceFacade } from '@mona/device/data-access-device';
import { CallConfig, CallSession, CallWsEvent, CallWsMessage, WebRtcMediaConfig } from '@mona/models';
import { MonaRpcService } from '@mona/rpc';
import { Logger } from '@mona/shared/logger';
import { get, MEDIA_ERRORS } from '@mona/shared/utils';
import {
    MediaDataService,
    PipCallDialogComponent,
    PipCallDialogComponentCloseReason,
    PipDialogService,
} from '@mona/telemedicine/shared';
import { DialogService, MessageService } from '@mona/ui';
import { CallWsService } from '../infrastructure';
import { CallService } from './call.service';

export const TELEMEDICINE_DIALOG_ID = 'telemedicine-pip';

/**
 * Call control service
 */
@Injectable({
    providedIn: 'root',
})
export class CallWebrtcService implements MediaDataService {
    /**
     * Is the camera on
     */
    cameraActive$ = new BehaviorSubject<boolean>(true);

    /**
     * Is the mic on
     */
    microphoneActive$ = new BehaviorSubject<boolean>(true);

    /**
     * Is call partner joined
     */
    hasCallPartner$ = new BehaviorSubject<boolean>(false);

    /**
     * Has call partner video disabled
     */
    hasCallPartnerVideoDisabled$ = new BehaviorSubject<boolean>(false);

    /**
     * If the call is running in PIP mode
     */
    isPip$ = new BehaviorSubject<boolean>(false);

    /**
     * If the call is using screen capture
     */
    isScreenCapture$ = new BehaviorSubject(false);

    /**
     * Reference to the pip video dialog
     */
    pipDialog: MatDialogRef<PipCallDialogComponent>;

    /**
     * Local audio sender reference for the call
     */
    audioSender: RTCRtpSender;

    /**
     * Local video sender reference for the call
     */
    videoSender: RTCRtpSender;

    private _stream: MediaStream;

    /**
     * Stream
     */
    get stream(): MediaStream {
        return this._stream;
    }
    set stream(value: MediaStream) {
        this._stream = value;
    }

    /**
     * Screen Capture Stream
     */
    private screenCaptureStream: MediaStream;

    /**
     * Remote stream
     */
    private remoteStream: MediaStream;

    /**
     * Holds a reference to the local video element
     */
    private localVideoElement: ElementRef<HTMLVideoElement>;

    /**
     * Holds a reference to the remote video element
     */
    private remoteVideo: ElementRef<HTMLVideoElement>;

    /**
     * Call config
     */
    private callConfig: CallConfig;

    /**
     * Rtc peer connection
     */
    private rtcPeerConnection: RTCPeerConnection;

    /**
     * Service subscriptions holder
     */
    private sessionSubscriptionsHolder: Subscription = new Subscription();

    /**
     * PIP subscriptions holder
     */
    private pipSubscriptionsHolder: Subscription = new Subscription();

    /**
     * Tracks camera status for starting and ending screen share
     */
    private cameraActiveBeforeScreenShare = false;

    private readonly logger = new Logger('TELEMEDICINE');

    /**
     * Constructor
     *
     * @param rpcService
     * @param callWsService CallWsService
     * @param callService CallService
     * @param messageService MessageService
     * @param deviceFacade DeviceFacade
     * @param dialogService DialogService
     * @param pipDialogService PipDialogService
     * @param configService
     * @param router
     */
    constructor(
        private rpcService: MonaRpcService,
        private callWsService: CallWsService,
        private callService: CallService,
        private messageService: MessageService,
        private deviceFacade: DeviceFacade,
        private dialogService: DialogService,
        private pipDialogService: PipDialogService,
        private configService: ConfigService,
        private router: Router,
    ) {}

    /**
     * Is picture in picture active
     */
    getIsPip$() {
        return this.isPip$;
    }

    /**
     * Has Calling partner
     */
    getHasCallPartner$() {
        return this.hasCallPartner$;
    }

    /**
     * Get setting from configured WebRtc Media Config
     *
     * @param path
     */
    getWebRtcMediaConfig<K extends Path<WebRtcMediaConfig>>(path: K): PathValue<WebRtcMediaConfig, K> {
        return get(WEBRTC_CONFIG_DEFAULT as WebRtcMediaConfig, path);
    }

    /**
     * Inits call
     *
     * @param localVideo ElementRef<HTMLVideoElement>
     * @param remoteVideo ElementRef<HTMLVideoElement>
     * @param callConfig CallConfig
     */
    async initCall(
        localVideo: ElementRef<HTMLVideoElement>,
        remoteVideo: ElementRef<HTMLVideoElement>,
        callConfig: CallConfig,
    ): Promise<void> {
        this.deviceFacade.invokePreCallScript();

        this.localVideoElement = localVideo;
        this.remoteVideo = remoteVideo;
        this.callConfig = callConfig;

        this.deviceFacade.isCameraActivated$.pipe(filter(Boolean), take(1)).subscribe(async () => {
            this.onCameraLoaded();
        });
    }

    private async onCameraLoaded(): Promise<void> {
        await this.createStream();

        navigator.mediaDevices.ondevicechange = async () => {
            await this.createStream();
        };

        this.sessionSubscriptionsHolder.add(
            this.callWsService.wsError$.subscribe(err => {
                this.messageService.errorToast('diagnostics.telemedicineWSNotAvailable', err);
                this.router.navigate(['/telemedicine']);
            }),
        );

        this.sessionSubscriptionsHolder.add(
            this.callWsService.connection$.subscribe(connection => {
                if (connection) {
                    this.messageService.successToast('diagnostics.telemedicineWSAvailable');
                }
            }),
        );

        this.sessionSubscriptionsHolder.add(
            this.callWsService.wsMessages$.subscribe(message => {
                this.handleWsEvents(message);
            }),
        );

        await this.callService
            .getCallSession()
            .pipe(
                filter<CallSession>(Boolean),
                take(1),
                exhaustMap(session => this.callWsService.connect(session.id)),
            )
            .toPromise();
    }

    /**
     * Inits call
     *
     * @param localVideo ElementRef<HTMLVideoElement>
     * @param remoteVideo ElementRef<HTMLVideoElement>
     * @param callConfig CallConfig
     */
    async restoreActiveCall(
        localVideo: ElementRef<HTMLVideoElement>,
        remoteVideo: ElementRef<HTMLVideoElement>,
        callConfig: CallConfig,
    ): Promise<void> {
        this.localVideoElement = localVideo;
        this.remoteVideo = remoteVideo;
        this.callConfig = callConfig;

        this.localVideoElement.nativeElement.srcObject = this.stream;
        this.remoteVideo.nativeElement.srcObject = this.remoteStream;
    }

    /**
     * Enables Pip
     */
    enablePip() {
        // DEV Hack: Don't open dialog twice
        if (this.pipDialog) {
            return;
        }

        // Track pip mode
        this.isPip$.next(true);

        // Open dialog
        this.dialogService.open<PipCallDialogComponent, PipCallDialogComponentCloseReason>(
            PipCallDialogComponent,
            {
                videoEnabled: this.cameraActive$.getValue(),
                microphoneEnabled: this.microphoneActive$.getValue(),
                remoteVideoDisabled: this.hasCallPartnerVideoDisabled$.getValue(),
                hasCallPartner: this.hasCallPartner$.getValue(),
                isScreenCapture: this.isScreenCapture$.getValue(),
            },
            {
                id: TELEMEDICINE_DIALOG_ID,
                minWidth: undefined,
                minHeight: undefined,
                panelClass: 'pip-call-dialog__panel',
                hasBackdrop: false,
                closeOnNavigation: false,
                position: {
                    bottom: '42px',
                    left: '142px',
                },
            },
        );

        this.pipDialog = this.dialogService.getDialogById(TELEMEDICINE_DIALOG_ID);

        // Dialog close listener
        this.pipDialog.afterClosed().subscribe((reason: PipCallDialogComponentCloseReason) => {
            // Handle specific close event reasons
            if (reason === PipCallDialogComponentCloseReason.HANG_UP) {
                this.hangUpFromPip();
            }

            // Unsubscribe temp subscriptions
            this.pipSubscriptionsHolder.unsubscribe();
            this.pipSubscriptionsHolder = new Subscription();
            this.isPip$.next(false);
            this.pipDialog = null;

            // In case screen capture is still active, disable it
            if (this.isScreenCapture$.getValue()) {
                this.stopScreenCapture();
            }
        });

        // Dialog open listener, works just like afterViewInit in dialog
        this.pipDialog.afterOpened().subscribe(() => {
            this.updatePipRemoteStream();

            this.pipSubscriptionsHolder.add(
                this.hasCallPartner$.pipe(skip(1)).subscribe(hasCallPartner => {
                    this.pipDialogService.hasCallPartnerChange.emit(hasCallPartner);
                }),
            );

            this.pipSubscriptionsHolder.add(
                this.hasCallPartnerVideoDisabled$.subscribe(value => {
                    this.pipDialogService.remoteVideoToggled.emit(value);
                }),
            );

            this.pipSubscriptionsHolder.add(
                this.isScreenCapture$.subscribe(value => {
                    this.pipDialogService.isScreenCaptureChange.emit(value);
                }),
            );

            this.pipSubscriptionsHolder.add(
                this.pipDialogService.toggleCamera.subscribe(() => {
                    this.toggleCamera();
                }),
            );

            this.pipSubscriptionsHolder.add(
                this.pipDialogService.toggleMic.subscribe(() => {
                    this.toggleMicrophone();
                }),
            );

            this.pipSubscriptionsHolder.add(
                this.pipDialogService.startScreenCapture.subscribe(() => {
                    this.startScreenCapture();
                }),
            );

            this.pipSubscriptionsHolder.add(
                this.pipDialogService.stopScreenCapture.subscribe(() => {
                    this.stopScreenCapture();
                }),
            );
        });
    }

    /**
     * Stops call
     */
    async stopCall() {
        this.sessionSubscriptionsHolder?.unsubscribe();
        this.sessionSubscriptionsHolder = new Subscription();
        this.callWsService.disconnect();
        this.stopExistingStream();
        this.stopExistingScreenCaptureStream();
        if (this.rtcPeerConnection) {
            this.rtcPeerConnection.close();
        }
        this.resetData();
        if (this.isPip$.getValue()) {
            this.pipDialogService.hungUp.emit();
        }
        this.deviceFacade.invokePostCallScript();
    }

    /**
     * Handles hang up from PIP modal
     */
    hangUpFromPip() {
        // Track pip mode
        this.isPip$.next(false);
        // Remove instance reference
        this.pipDialog = null;
        // Send Hang up event
        this.sendHangUp();
        // Clear the session and states
        this.callService.clearCreateCallSession();
        // Hang up
        this.stopCall();
    }

    /**
     * Notifies ws about setting call on hold
     */
    sendOnHold(): void {
        this.callWsService.send(new CallWsMessage(CallWsEvent.ON_HOLD));
    }

    /**
     * Notifies ws about hanging up
     */
    sendHangUp(): void {
        this.callWsService.send(new CallWsMessage(CallWsEvent.HANG_UP));
    }

    /**
     * Turns microphone on or off
     */
    toggleMicrophone(): void {
        const enabled = !this.microphoneActive$.getValue();

        // Set auto stream for video call
        for (const track of this.stream.getAudioTracks()) {
            track.enabled = enabled;
            // Replace the sender track
            // Needed in case of a device change like screen share toggle
            this.audioSender.replaceTrack(track);
        }

        this.microphoneActive$.next(enabled);
    }

    /**
     * Turns camera on or off
     */
    async toggleCamera(): Promise<void> {
        const enabled = !this.cameraActive$.getValue();
        for (const video of this.stream.getVideoTracks()) {
            video.enabled = enabled;
        }
        this.cameraActive$.next(enabled);
        this.callWsService.send(
            new CallWsMessage(CallWsEvent.TOGGLE_VIDEO, {
                is_enabled: enabled,
            }),
        );
    }

    /**
     * Turns camera on or off
     *
     * @param enabled boolean
     */
    async setCameraEnabled(enabled: boolean): Promise<void> {
        for (const video of this.stream.getVideoTracks()) {
            video.enabled = enabled;
        }
        this.cameraActive$.next(enabled);
        this.callWsService.send(
            new CallWsMessage(CallWsEvent.TOGGLE_VIDEO, {
                is_enabled: enabled,
            }),
        );
    }

    /**
     * Inits ws events listening
     */
    private async handleWsEvents({ event, data }: CallWsMessage) {
        switch (event) {
            // Create and send offer when both calees joined
            case CallWsEvent.ALL_CALLEES_AVAILABLE:
                // Sync session once all callees available
                this.callService.loadCurrentSession();

                this.rtcPeerConnection = new RTCPeerConnection({
                    iceServers: this.callConfig.iceServers,
                });
                this.addLocalTracks(this.rtcPeerConnection);
                this.rtcPeerConnection.ontrack = this.setRemoteStream.bind(this);
                this.rtcPeerConnection.onicecandidate = this.sendIceCandidate.bind(this);
                await this.createOffer(this.rtcPeerConnection);
                break;
            // Set remote description when answer is retrieved
            case CallWsEvent.WEB_RTC_ANSWER:
                this.rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(data));
                this.setRtpSenderMaxBitrate(this.audioSender, this.getWebRtcMediaConfig('audio.maxBitrate'));
                this.setRtpSenderMaxBitrate(this.videoSender, this.getWebRtcMediaConfig('video.maxBitrate'));
                break;

            // Add ice candidate when retrieved
            case CallWsEvent.SEND_ICE_CANDIDATES: {
                // ICE candidate configuration.
                const candidate = new RTCIceCandidate({
                    sdpMLineIndex: data.label,
                    candidate: data.candidate,
                });
                this.rtcPeerConnection.addIceCandidate(candidate);
                break;
            }

            case CallWsEvent.CALLEE_LEFT:
                this.remoteVideo.nativeElement.srcObject = null;
                this.remoteStream = null;
                this.hasCallPartner$.next(false);
                this.updatePipRemoteStream();

                // Sync session once callee left
                this.callService.loadCurrentSession();
                break;

            // Set remote description when answer is retrieved
            case CallWsEvent.TOGGLE_VIDEO:
                this.hasCallPartnerVideoDisabled$.next(!data.is_enabled);
                break;
            default:
                break;
        }
    }

    /**
     * starts Screen Capture stream
     */
    async startScreenCapture() {
        await this.createScreenCaptureStream();
        this.setVideoTrackToScreenShare();

        // We must enable the camera when screen share starts
        this.cameraActiveBeforeScreenShare = this.cameraActive$.getValue();
        await this.setCameraEnabled(true);

        this.isScreenCapture$.next(true);
        this.callWsService.send(new CallWsMessage(CallWsEvent.TOGGLE_SCREEN_SHARE, { is_enabled: true }));
    }

    /**
     * stops Screen Capture stream
     */
    async stopScreenCapture() {
        await this.createStream();
        this.setVideoTrackToCamera();

        // We must restore the camera status when screen share ends
        if (!this.cameraActiveBeforeScreenShare) {
            await this.setCameraEnabled(false);
        }

        this.isScreenCapture$.next(false);
        this.callWsService.send(new CallWsMessage(CallWsEvent.TOGGLE_SCREEN_SHARE, { is_enabled: false }));
    }

    /**
     * Sets video track to camera
     */
    setVideoTrackToCamera() {
        // We have to replace both, the audio and the video. If we do only one of them, the other one does not work
        // anymore
        this.stream.getAudioTracks().forEach(track => {
            track.enabled = this.microphoneActive$.getValue();
            this.audioSender.replaceTrack(track);
        });
        this.stream.getVideoTracks().forEach(track => {
            track.enabled = this.cameraActive$.getValue();
            this.videoSender.replaceTrack(track);
        });

        if (this.localVideoElement) {
            this.localVideoElement.nativeElement.srcObject = this.stream;
        }
    }

    /**
     * Sets video track to screen share
     */
    setVideoTrackToScreenShare() {
        // We have to replace both, the audio and the video. If we do only one of them, the other one does not work
        // anymore
        this.stream.getAudioTracks().forEach(track => {
            track.enabled = this.microphoneActive$.getValue();
            this.audioSender.replaceTrack(track);
        });
        this.screenCaptureStream.getVideoTracks().forEach(track => {
            this.videoSender.replaceTrack(track);
        });

        if (this.localVideoElement) {
            this.localVideoElement.nativeElement.srcObject = this.screenCaptureStream;
        }
    }

    /**
     * Creates stream from screen capture
     */
    async createScreenCaptureStream(): Promise<void> {
        // Get screen share sources
        const sources = await this.rpcService.getDesktopCapturerSources({ types: ['window', 'screen'] });

        // Find mona window in sources
        const monaTerminalSource = sources.find(
            desktopCapturerSource => desktopCapturerSource.name === environment.appName,
        );

        // Stop old stream
        this.stopExistingScreenCaptureStream();

        // Declare stream
        let stream: MediaStream;

        try {
            // Grab stream
            stream = (await (navigator.mediaDevices as any).getUserMedia({
                audio: false,
                video: {
                    mandatory: {
                        chromeMediaSource: 'desktop',
                        chromeMediaSourceId: monaTerminalSource.id,
                        minWidth: 1280,
                        maxWidth: 1920,
                        minHeight: 720,
                        maxHeight: 1080,
                    },
                },
            })) as MediaStream;
        } catch (error) {
            this.logger.warn('Could not get screen media', error);
            this.messageService.errorToast('apps.telemedicine.cannotGetScreenCapture', {
                errorCode: MEDIA_ERRORS.CAN_NOT_GET_MEDIA,
                originalError: error,
            });
        }

        this.screenCaptureStream = stream;
    }

    /**
     * Creates stream
     */
    async createStream(): Promise<void> {
        this.stopExistingStream();
        await this.retryToShowCameraWithWait(5, 1000);
    }

    /**
     * Add retrying logic to camera enabling
     * @param maxRetries
     * @param delayBetweenRetries
     */
    async retryToShowCameraWithWait(maxRetries: number, delayBetweenRetries: number) {
        for (let attemptNumber = 1; attemptNumber <= maxRetries; attemptNumber++) {
            try {
                console.log(`Attempt ${attemptNumber}`);
                await this.showCamera();
                if (!this.stream) {
                    throw 'No device detected';
                }
                return;
            } catch (error) {
                console.error(`Attempt ${attemptNumber} failed:`, error);

                if (attemptNumber < maxRetries) {
                    console.log(`Retrying in ${delayBetweenRetries / 1000} seconds...`);
                    await new Promise(resolve => setTimeout(resolve, delayBetweenRetries));
                } else {
                    console.error('Max retries reached. Operation failed.');
                }
            }
        }
    }

    /**
     * Output camera stream on the screen
     */
    async showCamera(): Promise<void> {
        try {
            const mediaConstraints: MediaStreamConstraints = {
                audio: this.getWebRtcMediaConfig('audio.constraints'),
                video: this.getWebRtcMediaConfig('video.constraints'),
            };
            this.logger.log('Use MediaStreamConstraints', mediaConstraints);
            this.stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
            if (this.stream) {
                this.localVideoElement.nativeElement.srcObject = this.stream;
            }
        } catch (error) {
            this.stream = null;
            this.logger.warn('Could not get user media', error);
        }
    }

    /**
     * Clear device changing listener
     */
    clearCameraSubscription(): void {
        navigator.mediaDevices.ondevicechange = null;
    }

    /**
     * Stops existing stream
     */
    private stopExistingStream(): void {
        if (this.stream) {
            for (const track of this.stream.getTracks()) {
                track.stop();
            }
        }
    }

    /**
     * Stops existing stream
     */
    private stopExistingScreenCaptureStream(): void {
        if (this.screenCaptureStream) {
            for (const track of this.screenCaptureStream.getTracks()) {
                track.stop();
            }
        }
    }

    /**
     * Set RTP sender maximun bitrate
     *
     * @param sender RTCRtpSender
     * @param maxBitrate number
     */
    private setRtpSenderMaxBitrate(sender: RTCRtpSender, maxBitrate: number): void {
        const parameters = sender.getParameters();
        parameters.encodings[0].maxBitrate = maxBitrate;
        sender
            .setParameters(parameters)
            .then(() => {
                this.logger.log(`Set ${sender.track.kind} maxBitrate = ${maxBitrate}`);
            })
            .catch(e => this.logger.error(e));
    }

    /**
     * Adds local tracks to connection
     *
     * @param rtcPeerConnection RTCPeerConnection
     */
    private addLocalTracks(rtcPeerConnection: RTCPeerConnection): void {
        const audioContentHint = this.getWebRtcMediaConfig('audio.contentHint');
        const audioTrack = this.stream.getAudioTracks()[0];
        audioTrack['contentHint'] = audioContentHint;
        this.audioSender = rtcPeerConnection.addTrack(audioTrack, this.stream);
        this.logger.log(`Set audio contentHint = ${audioContentHint}`);

        const videoContentHint = this.getWebRtcMediaConfig('video.contentHint');
        const videoTrack = this.stream.getVideoTracks()[0];
        videoTrack['contentHint'] = videoContentHint;
        this.videoSender = rtcPeerConnection.addTrack(videoTrack, this.stream);
        this.logger.log(`Set video contentHint = ${videoContentHint}`);
    }

    /**
     * Sets remote stream
     *
     * @param event event
     */
    private setRemoteStream(event): void {
        this.remoteVideo.nativeElement.srcObject = event.streams[0];
        this.remoteStream = event.streams[0];
        this.hasCallPartner$.next(true);
        this.updatePipRemoteStream();
    }

    /**
     * Sends ICE candidate to ws
     *
     * @param event event
     */
    private sendIceCandidate(event): void {
        if (event.candidate) {
            this.callWsService.send(
                new CallWsMessage(CallWsEvent.SEND_ICE_CANDIDATES, {
                    label: event.candidate.sdpMLineIndex,
                    candidate: event.candidate.candidate,
                }),
            );
        }
    }

    /**
     * Creates and sends RTC offer
     *
     * @param rtcPeerConnection RTCPeerConnection
     */
    private async createOffer(rtcPeerConnection: RTCPeerConnection): Promise<void> {
        let sessionDescription;
        try {
            sessionDescription = await rtcPeerConnection.createOffer();
            rtcPeerConnection.setLocalDescription(sessionDescription);
        } catch (error) {
            this.logger.error(error);
        }

        this.callWsService.send(new CallWsMessage(CallWsEvent.WEB_RTC_OFFER, sessionDescription));
    }

    /**
     * Resets all properties
     */
    private resetData(): void {
        this.cameraActive$.next(true);
        this.microphoneActive$.next(true);
        this.hasCallPartner$.next(false);
        this.isScreenCapture$.next(false);
        this.stream = null;
        this.remoteStream = null;
        this.localVideoElement = null;
        this.remoteVideo = null;
        this.rtcPeerConnection = null;
        this.sessionSubscriptionsHolder = new Subscription();
        this.isPip$.next(false);
        if (this.pipDialog) {
            this.pipDialog.close();
        }
        this.pipDialog = null;
    }

    /**
     * Updates remote stream in pip modal
     */
    private updatePipRemoteStream() {
        this.pipDialogService.setRemoteStream.emit(this.remoteStream);
    }
    // TODO: implemented empty methods for common interface. do we need it actually?
    replaceAudioVideoTrack: (data: MediaDeviceInfo) => void;
    updateAudioOutput: (data: MediaDeviceInfo) => void;
    getMediaData: (includeAudioOutput: boolean) => null;
}
