import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { startOfToday } from 'date-fns';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { getEncounterStartDate, isAbleToEdit, isReviewMode } from '../selectors';
/**
 * Sets Encounter View Selected Date
 *
 * @returns function
 */
export const setEncounterViewSelectedDate: () => (selectedDate: Date) => void = () => {
    const store = inject(Store);

    return (selectedDate: Date) => {
        store.dispatch({ type: 'ENCOUNTER:SET_SELECTED_DATE', selectedDate });
    };
};

/** set selected date only for review mode */
export const setEncounterSelectedDateInReviewMode = () => {
    const store = inject(Store);
    const isReviewmode$ = isReviewMode();
    const encounterStartDate$ = getEncounterStartDate(true);

    return () => {
        combineLatest([isReviewmode$, encounterStartDate$])
            .pipe(
                take(1),
                filter(([reviewmode]) => reviewmode),
            )
            .subscribe(([, selectedDate]) => {
                store.dispatch({ type: 'ENCOUNTER:SET_SELECTED_DATE', selectedDate });
            });
    };
};

/** reset encounter selected date depending on review mode  */
export const resetEncounterViewSelectedDate = () => {
    const store = inject(Store);
    const isReviewmode$ = isReviewMode();
    const encounterStartDate$ = getEncounterStartDate(true);

    return () => {
        combineLatest([isReviewmode$, encounterStartDate$])
            .pipe(
                take(1),
                map(([reviewMode, encounterStartDate]) => {
                    let date = startOfToday();
                    if (reviewMode) {
                        date = encounterStartDate;
                    }
                    return date;
                }),
            )
            .subscribe(selectedDate => {
                store.dispatch({ type: 'ENCOUNTER:SET_SELECTED_DATE', selectedDate });
            });
    };
};
