import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isSameDay } from 'date-fns';
import { Procedure } from '@mona/models';
import { selectencounterViewSelectedDate } from '@mona/pdms/data-access-combined';
import { groupBy } from '@mona/shared/utils';
import { ProceduresState, proceduresStateKey } from '../../entities';
import { procedureAdapter } from '../reducers/procedure.reducer';

/**
 * Feature state
 *
 * @param state
 * state
 */
const selectFeatureProceduresState = createFeatureSelector<ProceduresState>(proceduresStateKey);

export const selectProceduresState = createSelector(
    selectFeatureProceduresState,
    (state: ProceduresState) => state.procedures,
);

export const selectProceduresError = createSelector(selectProceduresState, state => state.error);

export const {
    selectIds: selectProcedureIds,
    selectEntities: selectProcedureEntities,
    selectAll: selectProcedureAll,
    selectTotal: selectProcedureTotal,
} = procedureAdapter.getSelectors(selectProceduresState);

/**
 * Procedures filtered by selected date
 *
 * When scrolling the dates within the calendar (e.g. 17th January):
 * - Practitioner is able to see only the procedures that are active (procedures with start_date =< 17th Jan and end_date >= 17th Jan) for the selected day in calendar.
 * - Practitioner is not able to see the inactive procedures (start_date > 17th Jan or end_date < 17th Jan). These inactive procedures are hidden from view.
 */
export const selectProceduresFilteredByToday = createSelector(
    selectProcedureAll,
    selectencounterViewSelectedDate,
    (entities, selectedDate) => {
        return entities.filter((procedure: Procedure) => {
            return isSameDay(selectedDate, procedure.date);
        });
    },
);

/**
 * Procedures map by group code
 */
export const selectProcedureMapByPrescriptionId = createSelector(selectProcedureAll, entities => {
    return groupBy(entities, a => a.prescriptionId, false, false);
});

/**
 * Procedures map by group code
 */
export const selectProcedureMapByCategory = createSelector(selectProcedureAll, entities => {
    return groupBy(entities, a => a.category, false, false);
});

/**
 * Procedures filtered by selected date and unconfirmed
 *
 * @see `handleIfMedicationShouldBeConfirmed`
 */
export const selectProcedureUnconfirmed = createSelector(selectProcedureAll, entities => {
    return entities.filter((entity: Procedure) => entity);
});

/**
 * Procedures filtered by selected date and unconfirmed
 */
export const selectProcedureHasChanges = createSelector(selectProcedureAll, entities => {
    return entities.filter((entity: Procedure) => entity.hasChanges);
});
