import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { UiEchartsLegendComponent, UiEchartsLineTimeComponent } from './components';
import { UiEchartsDirective, UiLegendItemColorDirective } from './directives';
import { UiEchartsConfig, UI_ECHARTS_CONFIG } from './models';

/** Echarts Module */
@NgModule({
    imports: [CommonModule, MatListModule, MatSelectModule, TranslateModule],
    declarations: [
        //
        UiEchartsDirective,
        UiEchartsLegendComponent,
        UiLegendItemColorDirective,
        UiEchartsLineTimeComponent,
    ],
    exports: [UiEchartsDirective, UiEchartsLegendComponent, UiEchartsLineTimeComponent],
})
export class UiEchartsModule {
    /**
     * ModuleWithProviders
     *
     * @param config
     */
    static forRoot(config: UiEchartsConfig): ModuleWithProviders<UiEchartsModule> {
        return {
            ngModule: UiEchartsModule,
            providers: [{ provide: UI_ECHARTS_CONFIG, useValue: config }],
        };
    }
    /**
     * ModuleWithProviders
     */
    static forChild(): ModuleWithProviders<UiEchartsModule> {
        return {
            ngModule: UiEchartsModule,
        };
    }
}
