/* eslint-disable jsdoc/require-jsdoc, @typescript-eslint/ban-types, no-empty, curly */
import { wrapCallSite } from '../vendors/source-map-support';
import { callsites } from './callsites';
import { colorize } from './colorize';
import { formatISO } from './format';
import { getLocationString, isFunction, isInBrowser, isPromiseLike, isSubscribable, safeStringify } from './utils';

export type Class<T, Arguments extends unknown[] = any[]> = Constructor<T, Arguments> & { prototype: T };
export type Constructor<T, Arguments extends unknown[] = any[]> = new (...arguments_: Arguments) => T;
export type LogFunction = Console['debug'];

export interface FormatAndLogFunction {
    (time: string | Date, className: string, functionName: string, args: string[], props: string[]): void;
}

export interface FunctionLoggerOptions {
    scope?: string;
    withArgs?: boolean | string[];
    withClassProperties?: string[];
    timeTrashold?: number;
    logFunction?: LogFunction;
    formatAndLogFunction?: FormatAndLogFunction;
}

export interface ClassLoggerOptions<C extends Constructor<any>> {
    scope?: string;
    methodOptions?: FunctionLoggerOptions;
    loggedMethodsNames?: any[];
}

const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
const ARGUMENT_NAMES = /([^\s,]+)/g;

export const LOGGER_DEFAULT_OPTIONS_FN: FunctionLoggerOptions = {
    scope: 'MONA',
    withArgs: false,
    withClassProperties: [],
    timeTrashold: 50,
};
export const LOGGER_DEFAULT_OPTIONS_CLASS = {
    scope: 'MONA',
    methodOptions: LOGGER_DEFAULT_OPTIONS_FN,
};

export function getClassName(instance: Constructor<any>): string {
    return instance.constructor ? instance.constructor.name : null;
}

export function getSerializedArguments(argValues: any[], func: Function, options: FunctionLoggerOptions): string {
    const obj: any = {};
    const fnStr = func.toString().replace(STRIP_COMMENTS, '');
    const argNames: string[] | null = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
    if (argNames === null) return null;

    const requiredArgNames = options.withArgs instanceof Array ? options.withArgs : argNames;

    requiredArgNames
        .map((argName: string) => {
            return argNames.indexOf(argName);
        })
        .forEach((argNameIndex: number) => {
            if (argNameIndex === -1 || argNameIndex >= argValues.length) return;
            obj[argNames[argNameIndex]] = argValues[argNameIndex];
        });

    return safeStringify(obj);
}

export function getSerializedProperties(paths: string[], targetInstance: Constructor<any>): string {
    const obj: any = {};
    if (targetInstance != null) {
        for (const path of paths) {
            obj[path] = targetInstance[path];
        }
    }
    return safeStringify(obj);
}

export function getLogTime(): string {
    return formatISO(new Date());
}

export const prepareLogMessage = (
    targetInstance: Constructor<any>,
    functionName: string,
    originalFunction: Function,
    functionArgsVals: any[],
    functionResult: unknown,
    execTime: number,
    line: string,
    options: FunctionLoggerOptions,
) => {
    const className = getClassName(targetInstance);
    const prefix = `${options.scope}::${className}::${functionName}`;

    const args = options.withArgs ? getSerializedArguments(functionArgsVals, originalFunction, options) : null;
    const props = options.withClassProperties?.length
        ? getSerializedProperties(options.withClassProperties, targetInstance)
        : null;
    let result = '';

    if (
        options.withArgs &&
        !isFunction(functionResult) &&
        !isSubscribable(functionResult) &&
        !isPromiseLike(functionResult)
    ) {
        result = safeStringify(functionResult);
    }

    /**
     * Dynamic message block for console log
     *
     * @see formatting - https://developer.chrome.com/docs/devtools/console/format-style/
     */
    let message = colorize(`[DEBUG::${getLogTime()}::${prefix}] `, 'cyan');
    message += args?.length ? colorize(`Args: ${args} `, 'magenta') : '';
    message += props?.length ? colorize(`Props: ${props} `, 'yellow') : '';
    message += result?.length ? colorize(`Result: ${result} `, 'green') : '';
    message += options.timeTrashold < execTime ? colorize(`Exec: ${execTime}ms `, 'red') : '';
    message += line ? colorize(`Line: ${line}`, 'grey') : '';

    return message;
};

export function getMonkeyPatchMethod(
    originalMethod: Function,
    methodName: string,
    options: FunctionLoggerOptions,
): Function {
    return function (...args) {
        let result: unknown;
        let line = '';
        const t0 = performance.now();
        try {
            // eslint-disable-next-line prefer-rest-params
            result = originalMethod.apply(this, arguments);
            return result;
        } finally {
            const execTime = performance.now() - t0;
            line = getFileLine(2);
            const message = prepareLogMessage(this, methodName, originalMethod, args, result, execTime, line, options);
            // TODO: try to use util.inspect
            /* const fm = inspect(originalMethod, {
                colors: true,
                showHidden: false,
                depth: 1,
            }); */
            console.debug.call(console, message);
        }
    };
}

/**
 * Get the line/filename detail from a Webkit stack trace.
 *
 * @param lineIDx
 */
export function getFileLine(lineIDx = 2): string {
    let externalFileLine = '';
    try {
        const _: NodeJS.CallSite[] = callsites();
        const __ = _.map(cs => {
            return {
                name: cs.getFileName(),
                line: cs.getLineNumber(),
                methodName: cs.getMethodName(),
                functionName: cs.getFunctionName(),
            };
        });
        let stack = _?.at(lineIDx);
        stack = wrapCallSite(stack);

        const result = {
            this: stack.getTypeName(),
            name: stack.getFileName(),
            line: stack.getLineNumber(),
            methodName: stack.getMethodName(),
            functionName: stack.getFunctionName(),
        };

        externalFileLine = `${result.name}:${result.line}`;
        if (isInBrowser() && getLocationString().includes('localhost:4200')) {
            externalFileLine = externalFileLine
                // .replace('.', 'webpack://')
                .replace('http://localhost:4200', 'webpack://');
        }
        if (isInBrowser() && externalFileLine.startsWith('file://')) {
            externalFileLine = externalFileLine.replace('app.asar', 'app');
        }
    } catch (error) {
        console.log(error);
        return '';
    }

    return externalFileLine;
}
