/**
 * View state for lock screen
 */
export enum LockScreenViewState {
    /** UNKNOWN */
    UNKNOWN,
    /**
     * RFID
     */
    RFID,
    /**
     * PIN
     */
    PIN,
    /**
     * Welcome
     */
    WELCOME,
    /**
     * MAINTENANCE
     */
    MAINTENANCE,
}
