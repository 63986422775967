import { ArrayDataSource, isDataSource } from '@angular/cdk/collections';
import { CdkPortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Directive,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChanges,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, ControlContainer, NgForm, UntypedFormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { FilterableDataSource, TakeUntilDestroy } from '@mona/shared/utils';
import { BaseCVACanDisable } from '../mixins';
import { CanDisable, CVA, UiDynamicElement, UiDynamicElementCustomConfig, UiDynamicType } from '../models';
import { UiDynamicFormsService } from '../services';

/** @ignore */
@Directive({ selector: '[uiDynamicFormsError]ng-template' })
export class UiDynamicFormsErrorTemplateDirective extends CdkPortal {
    @Input() uiDynamicFormsError: string;
    /**
     * Constructor
     *
     * @param templateRef
     * @param viewContainerRef
     */
    constructor(public templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
        super(templateRef, viewContainerRef);
    }
}

/** @ignore */
@Directive({
    selector: '[uiDynamicContainer]',
})
export class UiDynamicElementDirective {
    /**
     * Constructor
     *
     * @param viewContainer
     */
    constructor(public viewContainer: ViewContainerRef) {}
}

/**
 * UiDynamicElementComponent
 */
@TakeUntilDestroy
@Component({
    selector: 'ui-dynamic-element',
    template: `
        <div uiDynamicContainer></div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UiDynamicFormsService],
})
export class UiDynamicElementComponent
    extends BaseCVACanDisable
    implements CVA, CanDisable, OnInit, OnChanges, OnDestroy
{
    private instance: any;

    /**
     * Sets form control of the element.
     */
    @Input() dynamicControl: UntypedFormControl | AbstractControl;

    /**
     * Sets id to control.
     */
    @Input() id = undefined;
    /**
     * Sets id to control.
     */
    @Input() hidden = undefined;

    /**
     * Sets appearance
     */
    @Input() appearance: MatFormFieldAppearance = 'fill';
    /**
     * Sets label to be displayed.
     */
    @Input() label = '';

    /**
     * Sets hint to be displayed.
     */
    @Input() hint = '';

    /**
     * Sets name to be displayed as attribute.
     */
    @Input() name = '';

    /**
     * Sets type or element of element to be rendered.
     * Throws error if does not exist or no supported.
     */
    @Input() type: UiDynamicElement | UiDynamicType | Type<any> = undefined;

    /**
     * Sets required validation checkup (if supported by element).
     */
    @Input() required: boolean = undefined;

    /**
     * Sets the placeholder message
     */
    @Input() placeholder = '';

    /**
     * Hide required marker
     */
    @Input() hideRequiredMarker: boolean = undefined;

    /**
     * Sets min validation checkup (if supported by element).
     */
    @Input() min: number = undefined;

    /**
     * Sets max validation checkup (if supported by element).
     */
    @Input() max: number = undefined;

    /**
     * Sets minLength validation checkup (if supported by element).
     */
    @Input() minLength: number = undefined;

    /**
     * Sets maxLength validation checkup (if supported by element).
     */
    @Input() maxLength: number = undefined;

    /**
     * Sets dataSource for array elements (if supported by element).
     */
    @Input() dataSource: any[] | Observable<any[]> | ArrayDataSource<any> = undefined;

    /** LabelKey */
    @Input() labelKey: string = undefined;

    /** ValueKey */
    @Input() valueKey: string = undefined;

    /**
     * Sets multiple property for array elements (if supported by element).
     */
    @Input() multiple: boolean = undefined;

    /**
     * Hide validation message.
     */
    @Input() hideValidationMessage: boolean = undefined;

    /**
     * Submit form on value change
     */
    @Input() submitFormOnValueChange: boolean = undefined;

    /**
     * Sets any additional properties on custom component.
     */
    @Input() customConfig: UiDynamicElementCustomConfig;

    /**
     * Sets error message template so it can be injected into dynamic components.
     */
    @Input() errorMessageTemplate: TemplateRef<any> = undefined;

    @ViewChild(UiDynamicElementDirective, { static: true }) childElement: UiDynamicElementDirective;

    /**
     * INFO: add comment
     */
    @HostBinding('attr.max')
    get maxAttr(): any {
        return this.max;
    }

    /**
     * INFO: add comment
     */
    @HostBinding('attr.min')
    get minAttr(): any {
        return this.min;
    }

    /**
     * Constructor
     *
     * @param dynamicFormsService
     * @param ngForm
     * @param controlContainer
     * @param cdRef
     */
    constructor(
        private dynamicFormsService: UiDynamicFormsService,
        @Optional() public readonly ngForm: NgForm,
        @Optional() public readonly controlContainer: ControlContainer,
        cdRef: ChangeDetectorRef,
    ) {
        super(cdRef);
    }

    /**
     * Lifecycle
     */
    ngOnInit(): void {
        const component: any =
            this.type instanceof Type ? this.type : this.dynamicFormsService.getDynamicElement(this.type);
        const ref: ComponentRef<any> = this.childElement.viewContainer.createComponent(component);
        this.childElement.viewContainer.insert(ref.hostView);
        this.instance = ref.instance;
        this.instance.form = this.ngForm || this.controlContainer.control;
        this.instance.control = this.dynamicControl;
        this.instance.id = this.id;
        this.instance.appearance = this.appearance;
        this.instance.label = this.label;
        this.instance.hint = this.hint;
        this.instance.name = this.name;
        this.instance.type = this.type;
        this.instance.value = this.value;
        this.instance.required = this.required;
        this.instance.hideRequiredMarker = this.hideRequiredMarker;
        this.instance.min = this.min;
        this.instance.max = this.max;
        this.instance.minLength = this.minLength;
        this.instance.maxLength = this.maxLength;
        this.instance.dataSource = isDataSource(this.dataSource)
            ? this.dataSource
            : this.dataSource &&
              new FilterableDataSource(
                  this.dataSource,
                  this.customConfig?.filterPredicate,
                  this.customConfig?.searchPredicate,
              );
        this.instance.dataSource && this.instance.dataSource.connect();
        this.instance.autocompleteGetter = (this as any).autocompleteGetter;
        this.instance.multiple = this.multiple;
        this.instance.disabled = this.disabled;
        this.instance.errorMessageTemplate = this.errorMessageTemplate;
        this.instance.placeholder = this.placeholder;
        this.instance.hideValidationMessage = this.hideValidationMessage;
        this.instance.submitFormOnValueChange = this.submitFormOnValueChange;
        if (ref.instance.elementRef) {
            // INFO: extend control with elementRef
            this.instance.control.elementRef = ref.instance.elementRef;
        }
        if (this.customConfig) {
            Object.getOwnPropertyNames(this.customConfig).forEach((key: string) => {
                this.instance[key] = this.customConfig[key];
            });
        }
    }

    /**
     * Reassign any inputs that have changed
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (this.instance) {
            for (const prop of Object.keys(changes)) {
                this.instance[prop] = changes[prop].currentValue;
            }
        }
    }

    // eslint-disable-next-line jsdoc/require-jsdoc
    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.instance?.dataSource?.disconnect();
    }
}
