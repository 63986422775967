import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { BaseErrors, omit } from '@mona/shared/utils';
import { DialogService, MessageService } from '@mona/ui';
import { ErrorActions } from '../actions';

/**
 * Effect class for error effects
 */
@Injectable()
export class ErrorEffects {
    /**
     * Handle unexpected error effect
     */
    handleUnexpectedError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ErrorActions.handleUnexpectedServerError),
                tap(action => {
                    const appError = omit(action, 'type');
                    if (appError.errorCode === BaseErrors.OFFLINE) {
                        this.messageService.errorToast('errors.offline');
                    } else if (appError.status !== 401) {
                        this.messageService
                            .errorToast('errors.unknown', appError, {
                                duration: 5000,
                                data: {
                                    action: {
                                        title: 'errorDetailsDialog.toastText',
                                        reason: 'errorDetails',
                                    },
                                },
                            })
                            .subscribe(({ dismissedByAction }) => {
                                if (dismissedByAction) {
                                    this.dialogService.showErrorDialog(appError);
                                }
                            });
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Service constructor
     *
     * @param actions$
     * @param messageService
     * @param dialogService
     */
    constructor(
        private actions$: Actions,
        private messageService: MessageService,
        private dialogService: DialogService,
    ) {}
}
