import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClockService } from './services';

// Date providers
const PROVIDERS = [ClockService];

/**
 * INFO: add comment
 */
@NgModule({
    imports: [CommonModule],
})
export class DateModule {
    /**
     * INFO: add comment
     */
    static forRoot(): ModuleWithProviders<DateModule> {
        return {
            ngModule: DateModule,
            providers: PROVIDERS,
        };
    }
}
