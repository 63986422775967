import { Component, Inject, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute as _ActivatedRoute, Route, RouterOutlet } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '@mona/shared/logger';
import { NAVIGATION, NavigationService, noop } from '@mona/shared/utils';
import { DialogData } from '../models';
import { DialogService } from '../services';

type ActivatedRoute = _ActivatedRoute & {
    data: BehaviorSubject<{ dialog: DialogRouteData }>;
};

type DialogRouteData = {
    data?: DialogData;
    config?: MatDialogConfig;
};

/**
 * Get boilerplate route config for any "dialog" component
 * @param component
 * @param dialogRouteData
 */
export const getRouteConfigForDialogComponent = (
    component: Type<any>,
    dialogRouteData: DialogRouteData,
): (Route & { data: { dialog: DialogRouteData } })[] => [
    {
        path: '',
        component,
        data: {
            dialog: dialogRouteData,
        },
    },
];

/**
 * Container to dynamically render MatDialog via router path's any component passed to outlet `dialog`
 */
@Component({
    selector: 'ui-dialog-container',
    /* prettier-ignore */
    template: `<router-outlet #ro="outlet" name="dialog" (activate)="activate(ro)" (deactivate)="deactivate()"></router-outlet>`,
})
export class UiDialogContainer implements OnInit {
    private logger = new Logger('UI');
    private _currentDialogId: string;

    /**
     * Current dialog id
     */
    get currentDialogId(): string {
        return this._currentDialogId;
    }

    /** Outlet reference to be able to override to prevent rendering */
    @ViewChild(RouterOutlet, { static: true }) private routerOutlet: RouterOutlet;
    /**
     * Constructor
     * @param dialogService
     * @param navigationService
     */
    constructor(
        private dialogService: DialogService,
        @Inject(NAVIGATION) private navigationService: NavigationService,
    ) {}

    // eslint-disable-next-line
    ngOnInit(): void {
        this.overrideRouterOutlet();
    }

    /**
     * Handle outlet activate to open modal with component from route
     * @param routerOutletData
     */
    activate(routerOutletData: RouterOutlet) {
        const { isActivated } = routerOutletData;
        if (!isActivated) {
            this.logger.error('DialogContainer:activate', 'dialog route config is incorrect');
            return;
        }
        const activatedRoute = (routerOutletData.activatedRoute.firstChild ||
            routerOutletData.activatedRoute) as ActivatedRoute;

        try {
            const { component, data: data$ } = activatedRoute;
            const {
                dialog: { config, data },
            } = data$.getValue();
            const name = component.name;
            this._currentDialogId = `dialog-outlet-${name.toLowerCase()}`;
            this.logger.log('DialogContainer:activate', name, data);
            this.dialogService.open(component, data, { ...config, id: this.currentDialogId }).subscribe(result => {
                this.navigationService.hasOpenOutlet('dialog') &&
                    this.navigationService.closeOutlet('dialog', { result });
            });
        } catch (error) {
            this.logger.error('DialogContainer:activate', error);
        }
    }

    /**
     * Handle outlet deactivate, and force close any dialogref if present
     */
    deactivate() {
        this.logger.log('DialogContainer:deactivate');
        this.dialogService.getDialogById(this.currentDialogId)?.close();
    }

    /**
     * Override router outlet inner viewref methods to prevent rendering below outlet
     */
    private overrideRouterOutlet() {
        (this.routerOutlet['location'] as ViewContainerRef).insert = noop;
        (this.routerOutlet['location'] as ViewContainerRef).detach = noop;
    }
}
