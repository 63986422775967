import { Component, Input } from '@angular/core';

/** The changelog tags component */
@Component({
    selector: 'mona-changelog-tags',
    templateUrl: './changelog-tags.component.html',
    styleUrls: ['./changelog-tags.component.scss'],
})
export class ChangelogTagsComponent {
    /** change log tags */
    @Input() changeLogTags: string[] = [];
    /** auth modal type */
    @Input() tagsTitle: string;
}
