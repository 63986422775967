/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { TerminalConfig } from '@mona/models';
import { Config } from '../../models';

export const initConfig = createAction('CONFIG:INIT', props<{ config: TerminalConfig }>());
export const initConfigFailure = createAction('CONFIG:INIT_FAILURE', props<{ error?: any }>());
export const updateConfig = createAction('CONFIG:UPDATE', props<{ config: DeepPartial<TerminalConfig> }>());
export const updateConfigSuccess = createAction('CONFIG:UPDATE_SUCCESS', props<{ config: TerminalConfig }>());
export const updateConfigFailure = createAction('CONFIG:UPDATE_FAILURE', props<{ error?: any }>());
