import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { PhysicalExaminationApi } from '../../infrastructure';
import { PhysicalExaminationAction } from '../actions';

/**
 * Physical examination effects
 */
@Injectable()
export class PhysicalExaminationEffects {
    /* Effect Declarations */

    /**
     * Load physical examination effect
     */

    loadPhysicalExamination$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PhysicalExaminationAction.loadPhysicalExaminationAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.physicalExaminationApi
                        .getPhysicalExaminations(action.encounterId)
                        .pipe(map(physicalExaminations => physicalExaminations)),
                    PhysicalExaminationAction.loadPhysicalExaminationAction,
                ),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param physicalExaminationApi PhysicalExaminationApi
     */
    constructor(private actions$: Actions, private physicalExaminationApi: PhysicalExaminationApi) {}
}
