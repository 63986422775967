/* eslint jsdoc/require-param: 0 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TimeZoneForm } from '../../time-zone-form.model';

/**
 * TimeZoneFormDialogComponent
 */
@Component({
    selector: 'app-time-zone-form-dialog',
    templateUrl: './time-zone-form-dialog.component.html',
    styleUrls: ['./time-zone-form-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeZoneFormDialogComponent {
    /** Form Value */
    formValue: TimeZoneForm;
    /**
     * Constructor
     * @param dialogRef MatDialogRef<ServerUnavailableDialogComponent>
     */
    constructor(public dialogRef: MatDialogRef<TimeZoneFormDialogComponent>) {
        dialogRef.addPanelClass('app-time-zone-form-dialog');
        dialogRef.disableClose = true;
    }

    /** handle submitted form */
    handleSubmitted(formValue: TimeZoneForm) {
        this.formValue = formValue;
    }
}
