import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { isEmptyView } from '@mona/ui/utils';

/**
 * [Card Component]
 *
 * The `<ui-card>` component is a wrapper around the `<mat-card>` that can be resized as the page is resized
 */
@Component({
    selector: 'ui-card',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ui-card',
        '[class.ui-card__full]': 'fullHeight',
    },
    template: `
        <section class="ui-card__wrapper">
            <mat-card class="ui-card__card" [uiLoadingOverlay]="isLoading">
                <mat-card-header class="ui-card__header">
                    <button
                        [visibleIf]="showBackBtn"
                        mat-icon-button
                        class="ui-card__action ui-card__action--close"
                        (click.zoneless)="backClick.emit()"
                        [style.order]="1"
                    >
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <h2 mat-mdc-card-title *ngIf="title">{{ title }}</h2>
                    <ng-container *ngIf="!title">
                        <ng-content select="[ui-card-header]"></ng-content>
                    </ng-container>
                    <ng-content select="[ui-card-header-actions]"></ng-content>
                    <button
                        [visibleIf]="showCloseBtn"
                        mat-icon-button
                        ui-card-close
                        (click.zoneless)="closeClick.emit()"
                        class="ui-card__action ui-card__action--close"
                        data-testid="sidenav-close"
                        [style.order]="3"
                        [style.zIndex]="1000"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-card-header>
                <mat-card-content class="ui-card__content">
                    <ng-content select="[ui-card-content]"></ng-content>
                </mat-card-content>
                <mat-card-actions class="ui-card__actions" align="end">
                    <ng-content select="[ui-card-actions]"></ng-content>
                </mat-card-actions>
            </mat-card>
        </section>
    `,
})
export class UiCardComponent {
    @Input() isLoading!: boolean;
    /** Variant with full height */
    @Input() fullHeight!: boolean;
    /** The text to show on the second line */
    @Input() subtitle?: string;
    /** The text to show on the first line */
    @Input() title!: string;
    /** Show back button */
    @Input() showBackBtn!: boolean;
    /** Show close button */
    @Input() showCloseBtn!: boolean;
    /** Event Emitter  */
    @Output() closeClick = new EventEmitter<void>();
    /** Event Emitter  */
    @Output() backClick = new EventEmitter<void>();

    isEmpty = (el: ElementRef): boolean => isEmptyView(el);
}
