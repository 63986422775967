import { ApiModels } from '@mona/api';
import { PrescriptionSet } from '@mona/models';

/**
 * Transforms api prescription sets
 *
 * @param apiPrescriptionSet  ApiModels.PrescriptionSet
 */
export const transformApiPrescriptionSet = (apiPrescriptionSet: ApiModels.PrescriptionSet): PrescriptionSet => {
    const model = ApiModels.PrescriptionSet.DTO.toModel(apiPrescriptionSet);
    // TODO: map medications to correct interface
    return model as PrescriptionSet;
};

/**
 * Transform api prescription sets
 *
 * @param apiPrescriptionSets ApiModels.PrescriptionSet[]
 */
export const transformApiPrescriptionSets = (apiPrescriptionSets: ApiModels.PrescriptionSet[]): PrescriptionSet[] => {
    return apiPrescriptionSets.map(apiPrescriptionSet => transformApiPrescriptionSet(apiPrescriptionSet));
};
