/* eslint-disable jsdoc/require-jsdoc */
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Merge } from 'type-fest';
import { BaseMedication, ControlsOf, Medication, MedicationCategory, MedicationPrescription } from '@mona/models';
import { PrescriptionTerminologyData } from '@mona/pdms/data-access-combined';
import { CustomValidators } from '@mona/shared/utils';
import { NotGivenReason, NotGivenReasonForm } from '../../not-given-reason';
import { BaseMedicationForm, buildBaseMedicationForm } from './base-medication-form';
import { BloodControls } from './blood-controls';

/**
 * MedicationFormType Enum
 */
export enum MedicationFormTypeEnum {
    BaseMedication = 'BaseMedication',
    SetMedication = 'SetMedication',
    MedicationPrescription = 'MedicationPrescription',
    MedicationAdministration = 'MedicationAdministration',
}

/**
 * Medication form resolved data
 */
export interface MedicationFormResolvedData extends PrescriptionTerminologyData {
    type: MedicationFormTypeEnum;
    payload: MedicationPrescription | null;
    encounterStartDate: Date | null;
    isTaskListMode: boolean;
    isOnDemandMedication: boolean;
}

/**
 *  Medication form payload
 */
export interface MedicationFormPayload {
    /** ℹ️ Medication Terminology Autocomplete */
    autocompleteMedication: Pick<Medication, 'id' | 'code' | 'displayName' | 'categoryCode'>;
    medicationCategory?: MedicationCategory;
    /** ℹ️ Base Medication payload, contains all common fields */
    standardMedication: BaseMedication;
    bloodControls?: BloodControls;
    notAdministeredReason?: NotGivenReason;
    instructions?: string;
    startDate?: string | Date;
    endDate?: string | Date;
    administrationTime?: string | Date;
}

/**
 *  Medication form controls
 */
export type MedicationFormControls = Merge<
    ControlsOf<MedicationFormPayload>,
    { standardMedication: BaseMedicationForm; notAdministeredReason?: NotGivenReasonForm }
>;
/**
 *  medication form interface
 */
export type MedicationForm = FormGroup<MedicationFormControls>;

/** Build medication form */
export const buildMedicationForm = (): MedicationForm => {
    return new FormGroup<MedicationFormControls>({
        /** ⚠️ NOTE: cast to any to handle control-as-group initialization */
        autocompleteMedication: new FormControl(null, [Validators.required, CustomValidators.requireMatch]) as any,
        /** ⚠️ NOTE: cast to any to handle control-as-group initialization */
        medicationCategory: new FormControl<MedicationCategory>(null) as any,
        standardMedication: buildBaseMedicationForm(),
        instructions: new FormControl<string>('', Validators.maxLength(256)),
    });
};
