import { forwardRef, Inject, Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, mapTo, pluck } from 'rxjs/operators';
import { IpcMainEvent } from '@mona/events';
import { MonaRpcService } from '@mona/rpc';
import { ApiHealthState, HealthState } from '../entities';
import { ApiActions, ApiSelectors } from '../state';
import { NETWORK, NetworkService } from './network.service';

/**
 * API health facade
 */
@Injectable({
    providedIn: 'root',
})
export class ApiHealthFacade {
    /** Network connection status */
    online$: Observable<boolean> = this.store.select(ApiSelectors.selectApiHealthIsOnline);
    /** Network connection status */
    get connected() {
        return this.network.connected;
    }
    /** Network connection status */
    get online() {
        return this.network.online;
    }
    /** API health state */
    healthStateMap$ = this.store.select(ApiSelectors.selectApiHealthState);
    /**
     * Connection reestablished subject
     * Used for ws reconnecting and data sync
     */
    connectionReestablished$: Observable<boolean> = this.actionsObserver$.pipe(
        ofType(ApiActions.connectionReestablished),
        mapTo(true),
    );

    /**
     * Return API HealthState object by server as $
     *
     * @param serverUrl
     */
    healthStateByServer$(serverUrl: string): Observable<HealthState> {
        return this.healthStateMap$.pipe(pluck(serverUrl));
    }

    /**
     * Return is in maintenance by server
     *
     * @param serverUrl
     */
    isInMaintenance$(serverUrl: string): Observable<boolean> {
        return this.healthStateMap$.pipe(pluck(serverUrl, 'maintenance'));
    }

    /**
     * Return if version is outdated by server
     *
     * @param serverUrl
     */
    isVersionOutdated$(serverUrl: string): Observable<boolean> {
        return this.healthStateMap$.pipe(pluck(serverUrl, 'isVersionOutdated'));
    }

    /**
     * Return if version is incompatible by server
     *
     * @param serverUrl
     */
    isVersionIncompatible$(serverUrl: string): Observable<boolean> {
        return this.healthStateMap$.pipe(pluck(serverUrl, 'isVersionIncompatible'));
    }

    /**
     * Constructor
     *
     * @param network
     * @param rpcService
     * @param store
     * @param actionsObserver$
     */
    constructor(
        @Inject(forwardRef(() => NETWORK)) private network: NetworkService,
        private rpcService: MonaRpcService,
        private store: Store<{ api: ApiHealthState }>,
        private actionsObserver$: ActionsSubject,
    ) {
        // INFO: starts listening & toggling Online state
        combineLatest([this.network.onlineChanges$, this.network.getNetworkConnectivity()])
            .pipe(map(([online, connectivity]) => online && NetworkService.matchConnectivityStatusOnline(connectivity)))
            .subscribe(isOnline => {
                this.store.dispatch(ApiActions.changeOnline({ isOnline }));
            });
    }

    /**
     * Set online status
     *
     * @param isOnline
     */
    changeOnline(isOnline: boolean) {
        this.store.dispatch(ApiActions.changeOnline({ isOnline }));
    }

    /**
     * Load API health by url
     *
     * @param serverUrl
     */
    loadApiHealth(serverUrl: string) {
        this.store.dispatch(ApiActions.loadApiHealth({ serverUrl }));
    }

    /**
     * Clear core health settings result
     */
    clearApiHealth() {
        // TODO
    }

    /**
     * Resets checking state
     *
     * @param serverUrl
     * @param attempts
     */
    checkApiHealthWS(serverUrl: string, attempts = 0): void {
        this.store.dispatch(ApiActions.checkApiHealthWS({ serverUrl, attempts }));
    }

    /**
     * Resets checking state
     */
    resetApiHealthWS(): void {
        this.store.dispatch(ApiActions.resetApiHealthWS());
    }

    /**
     * Turn on camera on diagnostics check
     */
    turnOnCameraForCheck(): void {
        this.rpcService.invoke<string>(IpcMainEvent.RUN_PRE_CALL);
    }

    /**
     * Turn off camera on diagnostics check
     */
    turnOffCameraAfterCheck(): void {
        this.rpcService.invoke<string>(IpcMainEvent.RUN_POST_CALL);
    }
}
