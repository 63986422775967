import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    FormGroupDirective,
    FormRecord,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { compact, compactObject, compareDeepEqual, keys } from '../helpers';
import { isEmpty, isFunction, isNull, isObject } from '../types';

/**
 * Determine if the item is an AbstractControl
 *
 * @param x - The item to test
 * @returns The result
 * @example
 * isAbstractControl(new FormControl()) // Returns: true
 * isAbstractControl('hi')              // Returns: false
 */
export const isAbstractControl =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (x: Record<string, any>): x is AbstractControl => !!x && x.hasOwnProperty('valueChanges');

/**
 * Determine if the item is an AbstractControl
 *
 * @param obj
 */
export const isFormGroupDirective = (obj: AnyObject): obj is FormGroupDirective => !!obj && obj.hasOwnProperty('form');

/**
 * Determine if the item has FormArray
 *
 * @param fg UntypedFormGroup
 */
export const hasFormArray = (fg: UntypedFormGroup) => {
    return Object.values(fg?.controls).some(control => control instanceof FormArray);
};

/**
 * Return the value of a FormControl within a FormGroup
 *
 * @param form - The FormGroup that contains the control
 * @param controlName - The name of the control
 * @returns The value
 * @example
 * getFormControlValue(myFormGroup, 'myControl');
 * getFormControlValue<boolean>(myFormGroup, 'myControl');
 */
export function getFormControlValue<T>(form: UntypedFormGroup, controlName: string): T | undefined {
    if (!form || !controlName) {
        return undefined;
    }
    const control = form.get(controlName);
    return !isNull(control) && isAbstractControl(control) ? (control.value as T) : undefined;
}

/**
 * Set the value of a FormControl
 *
 * @param form - The FormGroup
 * @param controlName - The name of the control
 * @param controlValue - The value to set the control to
 * @example
 * setFormControlValue<number>(myForm, 'budget', 50);
 */
export function setFormControlValue<T>(form: UntypedFormGroup, controlName: string, controlValue: T): void {
    if (!form || !controlName) {
        return;
    }
    const control = form.get(controlName);

    if (control) {
        control.setValue(controlValue);
    }
}

/**
 * Pathes the value of a FormGroup
 *
 * @param form - The FormGroup
 * @param payload - The value as object to set the control to
 * @param options
 * @example
 * patchFormGroupValue(myForm, { str: 'xxx', obj: { a: 0, }, arr: [ { a: 0, }, { b: 0, }, ], });
 */
export function patchFormGroupValue<T extends Record<string, any>>(
    form: FormRecord, // FormGroup<ControlsOf<T>>
    payload: T,
    options: {
        onlyExistingFields?: boolean;
        onlySelf?: boolean;
        emitEvent?: boolean;
        validators?: { [key: string]: ValidatorFn | ValidatorFn[] };
    } = {
        onlyExistingFields: false,
        onlySelf: true,
        emitEvent: false,
    },
): void {
    if (!form || isEmpty(compactObject(payload)) || compareDeepEqual(form.getRawValue(), payload)) {
        return;
    }
    const { validators, ...patchOptions } = options;
    // iterate object entries to find array and properly patch formArray or add formControl
    keys(payload).forEach((key: string) => {
        let value = payload[key];
        if (Array.isArray(value)) {
            value = structuredClone(compact(value));
            const fa: UntypedFormArray = form.get(key) as UntypedFormArray;
            const arrayValue = fa?.getRawValue() || [];
            const isEqual = compareDeepEqual(arrayValue, value);
            // TODO: cover with test
            if (!fa || isEqual) {
                return;
            }
            value.forEach(v => {
                let fg: AbstractControl;
                if (isObject(v)) {
                    fg = new FormGroup({});
                    keys(v).forEach((kk: string) => {
                        (fg as FormGroup).addControl(kk, new FormControl(v[kk]));
                    });
                } else {
                    // TODO: maybe check for array needed
                    fg = new FormControl(v);
                }
                fa.push(fg);
            });
        } else if (isObject(value)) {
            patchFormGroupValue(form.get(key) as FormGroup, value, options);
        } else {
            if (form instanceof FormControl) {
                patchFormGroupValue(form.parent.get(key) as FormGroup, value, options);
            } else if (!form.get(key) && !options.onlyExistingFields) {
                const fc = new FormControl(value);
                form.addControl(key, fc);
            } else {
                form.patchValue({ [key]: value }, patchOptions);
            }
        }
    });
    // iterate validators entries and assign validators to formArray or formControl if needed
    validators &&
        keys(validators).forEach((key: string) => {
            const vldtr = validators[key];
            const control: AbstractControl = form.get(key);
            if (isAbstractControl(control) && (isFunction(vldtr) || Array.isArray(vldtr))) {
                if (control instanceof FormArray) {
                    control.controls.forEach(cc => {
                        if (cc instanceof FormGroup) {
                            // iterate validators entries and assign validators to each control of form group if needed
                            Object.values(cc.controls).forEach(ccc => {
                                ccc.addValidators(vldtr);
                                ccc.updateValueAndValidity();
                            });
                        } else if (cc instanceof FormControl) {
                            cc.addValidators(vldtr);
                        }
                    });
                }
                control.addValidators(vldtr);
                control.updateValueAndValidity();
            }
        });
}

/**
 * Get controls of the form as 1st level map
 *
 * @param form
 */
export function getFormControlsMap(form: UntypedFormGroup): Map<string, Readonly<UntypedFormControl>> {
    const result = new Map<string, Readonly<UntypedFormControl>>();
    keys(form.controls).forEach(key => {
        const fc = form.get(key);
        if (fc instanceof UntypedFormGroup) {
            const subControls = getFormControlsMap(fc);
            subControls.forEach((value, key) => {
                result.set(key, value);
            });
        } else {
            result.set(key, fc as UntypedFormControl);
        }
    });

    return result;
}

/**
 * Get form validation errors
 *
 * @param form
 */
export function getFormValidationErrors(form: UntypedFormGroup): Record<string, string>[] {
    const errors: Record<string, string>[] = [];
    for (const [key, control] of getFormControlsMap(form)) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors) {
            keys(controlErrors).forEach(keyError => {
                errors.push({
                    control: key,
                    error: keyError,
                    // value: controlErrors[keyError],
                });
            });
        }
    }

    return errors;
}
