<div
    class="ui-dynamic-slider-wrapper mat-form-field mat-form-field-can-float mat-form-field-should-float"
    [class.mat-focused]="slider._isActive"
>
    <span class="mat-form-field-label-wrapper">
        <label class="mat-form-field-label mat-primary ui-slider-label">
            {{ label }}
            <span *ngIf="required && !control?.disabled" class="mat-form-field-required-marker">*</span>
        </label>
    </span>
    <div class="ui-dynamic-slider-field">
        <mat-slider
            #slider
            class="ui-dynamic-slider"
            [formControl]="control"
            [attr.name]="name"
            [min]="min"
            [max]="max"
            thumbLabel
            tickInterval="auto"
            [required]="required"
            (blur)="handleBlur()"
        ></mat-slider>
    </div>
    <span class="mat-hint ui-dynamic-element-hint">{{ hint }}</span>
</div>
