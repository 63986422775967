import { ApiModels } from '@mona/api';

type __HistoryEntryType = ApiModels.PatientHistory.TypeEnum | 'event';
/**
 * Encounter history entry type
 * @deprecated
 */
export enum _HistoryEntryType {
    PHYSICIAN_REPORT = 'physician-report',
    SENIOR_PHYSICIAN_ORDER = 'senior-physician-order',
    EVENT = 'event',
    SPEECH_THERAPY_REPORT = 'speech-therapy-report',
    PHYSICAL_THERAPY_REPORT = 'physical-therapy-report',
    NEUROLOGY_REPORT = 'neurology-report',
    CARE_REPORT = 'care-report',
}

/**
 * Encounter history entry type copy of {@link ApiModels.PatientHistory.TypeEnum }
 *
 * IMPORTANT: Copy is needed to prevent JIT compliation error
 */
export type HistoryEntryType =
    | 'physician-report'
    | 'speech-therapy-report'
    | 'physical-therapy-report'
    | 'neurology-report'
    | 'care-report'
    | 'senior-physician-order'
    | 'event';
export const HistoryEntryType = {
    PhysicianReport: 'physician-report' as HistoryEntryType,
    SpeechTherapyReport: 'speech-therapy-report' as HistoryEntryType,
    PhysicalTherapyReport: 'physical-therapy-report' as HistoryEntryType,
    NeurologyReport: 'neurology-report' as HistoryEntryType,
    CareReport: 'care-report' as HistoryEntryType,
    SeniorPhysicianOrder: 'senior-physician-order' as HistoryEntryType,
    Event: 'event',
};
