import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigService } from '@mona/config';
import { DrawerNavItem } from '@mona/ui';

/**
 * ExternalResourcesHeaderComponent
 */
@Component({
    selector: 'ui-drawer-nav-subitem',
    templateUrl: './drawer-nav-subitem.component.html',
    styleUrls: ['./drawer-nav-subitem.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerNavSubItemComponent {
    /** external resource data */
    @Input() public subItem!: Omit<DrawerNavItem, 'icon'>;
    /** active sub item id */
    @Input() public activeSubItemId!: string;
    /** open resource emitter */
    @Output() public handleEvent: EventEmitter<Omit<DrawerNavItem, 'icon'>> = new EventEmitter<
        Omit<DrawerNavItem, 'icon'>
    >();

    showDefaultIcon!: boolean;

    /** base url */
    baseUrl: string = this.configService.get('api.baseUrl');

    /**
     * INFO: add comment
     * @param configService
     */
    constructor(private configService: ConfigService) {}
}
