/* eslint-disable jsdoc/require-jsdoc */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataAccessWardsState, wardsAdapter } from '../../entities';
import { WARDS_DATA_REDUCERS_TOKEN } from '../reducers';

const wardsEntitySelectors = wardsAdapter.getSelectors();
export const selectDataWardsFeature = createFeatureSelector<DataAccessWardsState>(WARDS_DATA_REDUCERS_TOKEN);
const selectWardsState = createSelector(selectDataWardsFeature, x => x.wards);

export const selectAllWards = createSelector(selectWardsState, wardsEntitySelectors.selectAll);

export const selectWardsEntities = createSelector(selectWardsState, wardsEntitySelectors.selectEntities);

export const selectWardsIsLoading = createSelector(selectWardsState, x => x.isLoading);

export const selectSelectedWardId = createSelector(selectWardsState, x => x.selectedId);

export const selectAssignedWardId = createSelector(selectWardsState, x => x.assignedWardId);

export const selectSelectedWard = createSelector(selectWardsEntities, selectSelectedWardId, (all, wardId) => {
    const selectedWard = all[wardId];
    return selectedWard;
});
