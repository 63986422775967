import { coerceElement } from '@angular/cdk/coercion';
import { ListRange } from '@angular/cdk/collections';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { DecimalPipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { isAfter, isBefore } from 'date-fns';
import { animationFrameScheduler, BehaviorSubject, Subject } from 'rxjs';
import { delay, distinctUntilChanged, skip, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { isEmpty, isEmptyObject, noop, notEmpty, tapOnce, uiPure } from '@mona/shared/utils';
import { UiTableCellContext, VHSTableDirective, VhsWrapperScrollable } from '../../directives';
import {
    UiTableCellValue,
    UiTableColumn,
    UiTableDataSource,
    UiTableRow,
    VhsListRange,
    VhsScrollDirectionEnum,
} from '../../models';
import { DEFAULT_COLUMN_BUFFER, DEFAULT_COLUMN_WIDTH, DEFAULT_STICKY_COLUMN_WIDTH } from '../../strategies';
import { UiTableComponent } from '../ui-table/ui-table.component';
import { VHSVirtualScrollViewport } from '../vhs-viewport/vhs-viewport.component';

/**
 * Table component that accepts column and row definitions in its content to be registered to the table.
 * Wraps a material table component for definition and behavior reuse.
 *
 * This component renders columns in horizontal virtual scroll using cdkVirtualFor and custom directive and 2 different ScrollStrategies
 */
@Component({
    selector: 'ui-vhs-table',
    templateUrl: './vhs-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiVHSTableComponent extends UiTableComponent implements OnInit, OnChanges, AfterViewInit {
    /** Reference to 'column' table wrapper which holds sticky column */
    @ViewChild('leftTableWrapper', { read: VhsWrapperScrollable, static: true })
    readonly leftTableWrapper: CdkScrollable;
    /** Reference to left table which replaces sticky column */
    @ViewChild('leftTable') readonly leftTable: MatTable<any>;
    /** Reference to 'header' table */
    @ViewChild('headerTable', { read: ElementRef }) readonly headerTable: ElementRef;
    /** Reference to main table */
    @ViewChild('mainTable', { static: true }) readonly table: MatTable<any>;
    /** Reference to CdkVirtualScrollViewport  */
    @ViewChild(VHSVirtualScrollViewport, { static: true }) readonly viewport: VHSVirtualScrollViewport;
    /** Reference to VHSTableDirective  */
    @ViewChild(VHSTableDirective, { static: true }) readonly viewportDirective: VHSTableDirective;
    /** Data Source */
    @Input() dataSource: UiTableDataSource<UiTableRow>;
    /** Column width */
    @Input() columnWidth = DEFAULT_COLUMN_WIDTH;
    /** Sticky column width */
    @Input() stickyColumnWidth = DEFAULT_STICKY_COLUMN_WIDTH;
    /** Template cache size input from CdkVirtualScrollViewport, 0 is prefered for our use case thus we don't need to trigger cdRef manually */
    @Input() columnsTemplateCacheSize = 0;
    /** The number of buffered columns rendered beyond the viewport. */
    @Input() columnBuffer = DEFAULT_COLUMN_BUFFER;
    /** Scroll to column index on start */
    @Input() scrollToIndex: number;
    /** Scroll to column index 2-way binding */
    @Output() scrollToIndexChange = new EventEmitter<number>();
    /** scrollTo duration ms, defaults to 0 to disable animation */
    @Input() scrollToDuration = 0;
    /** Scroll to active index on start */
    @Input() scrollToActiveIndexOnStart = false;
    /** Show scroll buttons  */
    @Input() showScrollButtons = false;
    /** Custom empty cell template */
    @Input() emptyCellTemplate: TemplateRef<any> | undefined;
    /** scroll on columns changed */
    @Input() scrollOnColumnsChangeFlag = false;
    /**
     * Prevent scroll on columns change
     *
     * @default true - by default prevent all tables to scroll to last (means active) columns on columns change
     */
    @Input() updateScrollOnChange = false;
    /** Show sticky data as overlay cell on row's left side  */
    @Input() withStickyData = false;
    /** Show empty columns at the end  */
    @Input() withEmptyColumnsAtTheEnd = false;
    /** Hide filter by name thead cell & component  */
    @Input() filterDisabled = false;
    /** Filter option lable mapper */
    @Input() filterOptionLabelMapper: (...args) => string;
    /** Custom Filter option template */
    @Input() filterOptionTemplate?: TemplateRef<any>;
    /** Apply mat-ripple to footer cell */
    @Input() footerRipple = false;
    /** Name cell click handler */
    @Input() nameCellClickFn: AnyFn;
    /** Show table grid when table is empty while scrolling */
    @Input() showScrollBackground = false;
    /** Offset List Range Condition */
    @Input() offsetRangeCondition: VhsListRange = {
        start: 0,
        end: 35,
        scrollDirection: VhsScrollDirectionEnum.Left,
    };
    /** Emits whenever the rendered range changes. */
    @Output() renderedRangeChange = new BehaviorSubject<VhsListRange>({
        start: 0,
        end: 0,
        scrollDirection: undefined,
    });
    /** Emits whenever the offset condition rendered range changes. */
    @Output() offsetRangeConditionChange: EventEmitter<VhsListRange> = new EventEmitter<VhsListRange>();
    private _scrolledIndexChange = new BehaviorSubject<number>(0);
    /** Emits when the index of the first element visible in the viewport changes. */
    @Output() scrolledIndexChange = this._scrolledIndexChange.pipe(skip(1));
    /** Emits amount of visible columns the viewport when changed. */
    @Output() itemsInViewportChange = new Subject<number>();
    /** Static amount of visible columns the viewport */
    get itemsInViewport(): number {
        return this.viewport?.itemsInViewport;
    }
    /** Horizontal offset from cdkVirtualScroll viewport */
    offset = 0;
    /** Sticky column(s) - for now, one column `['name']` */
    stickyColumns: string[] = ['name'];
    /**
     * Index to scroll on change
     *
     * When screen with the table is opened, scroll position should be automatically
     * set to current time (or so-called "now" column)
     * Same should happen when interval is changed in the tables which has interval change
     */
    scrollToIndexOnStart: number;
    /** flag for scrolling on start */
    scrolled = false;

    private synchronizeScrollTopFactor: number;

    private previousRange: VhsListRange;

    private previousIndex = 0;

    /**
     * Watch for inputs changes
     *
     * @param changes
     */
    override ngOnChanges(changes: SimpleChanges): void {
        /** Check if table should be scrolled to some index on change */
        const { scrollToIndex = {} as SimpleChange, withStickyData = {} as SimpleChange, columns } = changes;
        if (columns?.currentValue?.length && this.columnDefs?.toArray()) {
            this.displayedColumns = [
                ...this.columns.map(c => c.name),
                ...(this.columnDefs?.toArray() || []).map(c => c.name),
                ...(this.rowMenuItems?.length ? ['rowMenuColumn'] : []),
            ];
            this.triggerChangeDetection();
        }
        if (
            !isNaN(scrollToIndex.currentValue) &&
            scrollToIndex.currentValue > 0
            // && scrollToIndex.currentValue !== scrollToIndex.previousValue
        ) {
            this.scrolled = false;
            this.scrollToIndexOnStart = scrollToIndex.currentValue;
            if (this.updateScrollOnChange) {
                this.viewport.scrollToIndex(Math.max(0, scrollToIndex.currentValue));
            }
        }
        // Handle overlay column config
        if (!withStickyData.previousValue && !!withStickyData.currentValue) {
            this.stickyColumns.push('overlayColumn');
        }
        if (!!withStickyData.previousValue && !withStickyData.currentValue) {
            this.stickyColumns = ['name'];
        }
    }

    /**
     * Start listening changes from viewport to update visible columns & data
     */
    ngOnInit(): void {
        /**
         * Scroll table  after columns were changed in viewport
         * ℹ️ Important: via scroll we trigger renderedRangeChange on the vieport and get `onColumnsChanged` called automatically
         */
        this.viewport.columnsChanged
            .pipe(delay(0, animationFrameScheduler), takeUntil(this.destroy$))
            .subscribe(([prev, curr]) => {
                this.updateViewport(true);
                if (this.scrollOnColumnsChangeFlag) {
                    this.scrollOnColumnsChange(prev, curr);
                }
            });
    }

    /**
     * Add custom columns to table
     * Subscribe to renered range & other updates
     */
    override ngAfterViewInit() {
        /* instead of `registerDefs()`, register only columnDef to 1st table for sticky columns */
        this.columnDefs.forEach(columnDef => {
            this.leftTable.addColumnDef(columnDef);
        });

        this.viewport.scrolledIndexChange
            .pipe(
                skipWhile(() => !this.viewport.itemsInViewport),
                tap(index => {
                    const newRange = this.renderedRangeChange.getValue();
                    if (this.isOffsetConditionRangeChange(index)) {
                        this.offsetRangeConditionChange.emit(newRange);
                    }
                    this.previousRange = newRange;
                    this.previousIndex = index;
                }),
            )
            .subscribe();

        this.viewport.renderedRangeStream
            .pipe(
                skipWhile(() => !this.viewport.itemsInViewport),
                distinctUntilChanged((a, b) => a.start === b.start && a.end === b.end),
                tap(range => {
                    this.onColumnsChanged(range);
                    const newRange: VhsListRange = this.getScrollDirection(range);
                    this.renderedRangeChange.next(newRange);
                }),
                takeUntil(this.destroy$),
            )
            .subscribe();

        // re-emit scrolledIndex change from Viewport
        this.viewport.scrolledIndexChange
            .pipe(
                distinctUntilChanged(),
                skipWhile(() => !this.renderedRangeChange.value.end),
                takeUntil(this.destroy$),
            )
            .subscribe(this._scrolledIndexChange);

        // re-emit itemsInViewport change from Viewport
        this.viewport.itemsInViewport$
            .pipe(notEmpty(), distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe(this.itemsInViewportChange);

        /** Subscribe to observable that emits when a vertical scroll event is fired on the host element to synchronize `ScrollTop` */
        this.leftTableWrapper
            .elementScrolled()
            .pipe(
                tapOnce(() => {
                    if (!this.synchronizeScrollTopFactor) {
                        this.synchronizeScrollTopFactor = this.getSynchronizeScrollTopFactor(
                            this.leftTableWrapper.getElementRef(),
                            this.viewport.getElementRef(),
                        );
                    }
                }),
                tap(() => this.withStickyData && this.toggleOverlayColumnValues()),
                tap(event => this.synchronizeScrollTop(event, 1)),
                takeUntil(this.destroy$),
            )
            .subscribe();

        /** Subscribe to a subject that indicates which table cell is currently editing (unless it is disabled). */
        this.editEventDispatcher.editing
            .pipe(
                tap(event => {
                    noop(); // TODO: blur
                }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Update the viewport dimensions and re-render.
     *
     * @param force
     */
    updateViewport(force = false) {
        this.viewport.checkViewportSize();
        if (force) {
            this.onColumnsChanged(this.renderedRangeChange.getValue());
        }
    }

    /**
     * Merge custom column definitions and columns input sliced by rendered range
     *
     * @param range
     */
    override onColumnsChanged(range: ListRange): void {
        const { start, end } = range;
        if (start > end || end === 0 || end - start > this.columnBuffer * 5) {
            return;
        }

        this.displayedColumns = this.columns.slice(start, end).map(c => c.name);

        if (this.columns.length <= 4 || this.withEmptyColumnsAtTheEnd) {
            this.displayedColumns.push('emptyColumn');
        }
    }

    /**
     * Scroll table  after columns were changed in viewport
     * ℹ️ Important: via scroll we trigger renderedRangeChange on the vieport and get {@link onColumnsChanged } called automatically
     *
     * @param prev
     * @param curr
     */
    scrollOnColumnsChange(prev: readonly UiTableColumn[] = [], curr: readonly UiTableColumn[]) {
        this.viewport.checkViewportSize();
        const currScrollIndex = this._scrolledIndexChange.getValue();
        const itemsInViewport = this.viewport.itemsInViewport;
        // amount of new columns added kind of before 0 index, e.g. 87 new columns were added
        const addedColumnsLength = curr.length - prev.length;
        /** Scroll to specific column ON START, ONCE  */
        if (prev.length <= 1 && curr.length > itemsInViewport && !this.scrolled) {
            let idx = this.scrollToIndexOnStart;
            if (this.scrollToActiveIndexOnStart) {
                // idx = findLastIndex(curr, this.activeColumnPredicateFn);
                idx = curr.length;
            }
            if (idx) {
                this.viewport.scrollToIndex(idx);
                this.scrolled = true;
            }
        }
        this.updateViewport(!this.scrollToIndex);
        /** Scroll to added column when pagination changed columns  */
        if (prev.length > itemsInViewport && curr.length > itemsInViewport && addedColumnsLength >= 1) {
            // because scrollstrategy scrolled alredy to index 13 of new length we scroll back to new index which is addition of new added columns
            this.viewport.scrollToIndex(addedColumnsLength + currScrollIndex + 1);
        }
    }

    /**
     * Scrolls to n (1) page (size of the viewport)
     *
     * Direction (left or right) is decided by the sign of the argument in viewport component scrollViewport method
     *
     * @param event PageEvent data from MatPaginator
     */
    onPageEvent(event: Partial<PageEvent>): void {
        if (isEmptyObject(event) || !event.pageSize) {
            return;
        }
        // Pass count of pages to scroll by dividing page size by items in viewport
        // and limiting it to smallest value as we may want to scroll **HALF** of the viewport
        const pageCount = Math.min(event.pageSize / this.viewport.itemsInViewport, 1);
        this.viewport.scrollViewport(pageCount).subscribe();
    }

    /**
     * Synchronize scrollTop between tables
     *
     * @param event
     * @param elementIdx
     */
    synchronizeScrollTop(event: Event, elementIdx: number): void {
        const elm1 = coerceElement<HTMLElement>(
            elementIdx ? this.viewport.elementRef : this.leftTableWrapper.getElementRef(),
        );
        const elm2 = coerceElement<HTMLElement>(
            elementIdx ? this.leftTableWrapper.getElementRef() : this.viewport.elementRef,
        );
        // TODO: synchronizeScrollTopFactor was used before, should use "1" until fixed
        elm1.scrollTop = Math.min((event.target as HTMLElement).scrollTop, Math.round(elm2.scrollTop * 1));
    }

    /**
     * Calculate a factor for scroll areas with different height
     *
     * @param elm1
     * @param elm2
     */
    getSynchronizeScrollTopFactor = (elm1: any, elm2: any) => {
        elm1 = coerceElement(elm1) as HTMLElement;
        elm2 = coerceElement(elm2) as HTMLElement;
        return (elm2.scrollHeight - elm2.clientHeight || 1) / (elm1.scrollHeight - elm1.clientHeight || 1);
    };

    /**
     * Get last column with not-empty value in row which is not visible & should be shown as overlay
     *
     * If table configured with `withStickyData` and row has `lookup` property
     *
     * @param  columns row id number
     * @param  row row instance
     * @param  scrollToIndex scroll index number
     * @returns column UiTableColumn
     */
    @uiPure
    getLastValueColumnForOverlay(columns: UiTableColumn[], row: UiTableRow, scrollToIndex: number): UiTableColumn {
        if (!row?.lookup) {
            return;
        }

        let column = { meta: {} } as UiTableColumn; // safe empty column-like

        if (!columns?.length) {
            return column;
        }

        for (let i = scrollToIndex; i >= 0; i--) {
            const c = columns[i];

            const found = row.children?.length
                ? row.children?.find(child => this.cellValueGetter(child, c))
                : this.cellValueGetter(row, c);

            if (found) {
                // NOTE: method to check ended running rate inputs
                const overlayColumnValueValidatorFn = (
                    value: UiTableRow | UiTableCellValue,
                    currColumn: UiTableColumn,
                    row: UiTableRow,
                ): boolean => {
                    // eslint-disable-next-line no-inner-declarations
                    function isRange(item = {}): boolean {
                        return ['ml/h'].includes(item?.['unitRate']) && !!item?.['rate'];
                    }

                    if (isRange(value) && currColumn?.name && row?.['relatedPrescription']?.endDate) {
                        return isBefore(new Date(currColumn?.name), row?.['relatedPrescription']?.endDate);
                    }

                    return true;
                };

                if (!overlayColumnValueValidatorFn(found, columns?.[scrollToIndex], row)) {
                    break;
                }
            }

            column = columns[i];
            break;
        }

        return column;
    }

    /**
     * deside if pager should be shown
     *
     * @param itemsInViewport number
     * @param columns UiTableColumn[]
     */
    @uiPure
    shouldShowPager(itemsInViewport: number, columns: UiTableColumn[]): boolean {
        return itemsInViewport > 0 && columns?.length > itemsInViewport * 2;
    }

    /**
     * deside if last column cell should be shown
     *
     * @param rowLookup boolean
     * @param tableColumn UiTableColumn
     * @param scrollToIndex number
     * @param itemsInViewport number
     */
    @uiPure
    shouldShowLastColumnCell(
        rowLookup: boolean,
        tableColumn: UiTableColumn,
        scrollToIndex: number,
        itemsInViewport: number,
    ): boolean {
        return rowLookup && tableColumn && !!scrollToIndex;
    }

    /**
     * Transform cell value, used in overlay cell template
     *
     * @param row
     * @param col
     */
    overlayCellValueGetter(row: UiTableRow, col: UiTableColumn): string {
        let cell = this.cellValueGetter(row, col);
        if (Array.isArray(cell)) {
            // FIXME: remove this when ventilation getter is fixed
            cell = { value: cell };
        }
        if (isEmpty(cell?.value)) {
            return '';
        }
        if (row.lookupValueSkipFn && row.lookupValueSkipFn(row, col, cell)) {
            return '';
        }

        const locale = this.translateService.currentLang || this.translateService.defaultLang;
        const transformedValue = isNaN(cell.value) ? cell.value : new DecimalPipe(locale).transform(cell.value);

        return Array.isArray(cell.value) ? cell.value.join('/') : transformedValue;
    }

    /**
     * Active column predicate function, compares id column and its cells should be marked with css class `--active`
     *
     * @param col
     */
    @uiPure
    activeColumnPredicateFn(col: UiTableColumn) {
        return col.isActive;
    }

    /**
     * NgClass getter for a `td` element
     *
     * @param cellContext
     */
    @uiPure
    cellClassGetter(cellContext: UiTableCellContext) {
        return {
            'ui-table-td': true,
            'ui-table-td--active': cellContext.column.isActive,
            'ui-table-td--odd': cellContext.rowIdx % 2 !== 0,
            'ui-table-td--has-value': !!cellContext.cell,
            'ui-table-td--no-value': !cellContext.cell,
            'ui-table-td--first': cellContext.colIdx === 0,
            'ui-table-td--disabled': cellContext.disabled,
            'mat-ripple': this.cellClickable && !cellContext.disabled,
        };
    }

    private getScrollDirection(range: ListRange): VhsListRange {
        return range?.start < this.previousRange?.start || range?.end < this.previousRange?.end
            ? { ...range, scrollDirection: VhsScrollDirectionEnum.Left }
            : { ...range, scrollDirection: VhsScrollDirectionEnum.Right };
    }

    private isOffsetConditionRangeChange(index: number): boolean {
        return index === 0 && this.previousIndex !== 0;
    }

    /**
     * toggle overlay column values
     */
    private toggleOverlayColumnValues(): void {
        const TABLE_HEADER_HEIGHT = 112;

        const toTopOfTheScreen =
            this.viewport.elementRef.nativeElement.getBoundingClientRect().top + TABLE_HEADER_HEIGHT;

        const overlayCells = this.leftTableWrapper
            .getElementRef()
            .nativeElement.querySelectorAll('tr .ui-table-td--overlay');

        if (!overlayCells) {
            return;
        }
        // NOTE: Hide overlay cells when scrolling up/down
        Array.from(overlayCells).forEach((cell: HTMLElement) => {
            const cellTop = cell.getBoundingClientRect().top;
            cell.style.visibility = cellTop < toTopOfTheScreen ? 'hidden' : 'visible';
        });
    }
}
