import { InjectionToken } from '@angular/core';
import { ActionReducerMap, combineReducers, createReducer, on } from '@ngrx/store';
import { DeviceState } from '../../entities';
import { DeviceActions } from '../actions';
import { networkManagerReducerMap } from './network-manager.reducer';
import { deviceSetupReducer } from './setup.reducer';

/**
 * Feature ActionReducerMap definition
 */
export const deviceReducerMap: ActionReducerMap<DeviceState> = {
    keys: createReducer(
        {} as any,
        on(DeviceActions.getKeysSuccess, (state, { keys }) => keys),
    ),
    timeZone: createReducer(
        null,
        on(DeviceActions.getCurrentTimezoneSuccess, DeviceActions.setTimezone, (state, { value }) => value),
    ),
    brightness: createReducer(
        null,
        on(DeviceActions.getBrightnessSuccess, DeviceActions.setBrightness, (state, { value }) => value),
    ),
    network: combineReducers(networkManagerReducerMap),
    setup: deviceSetupReducer,
    isCameraActivated: createReducer(
        false,
        on(DeviceActions.toggleCameraSuccess, (state, { value }) => value),
    ),
};

/**
 * Reducer injection token definition
 */
export const DEVICE_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<DeviceState>>('Device Reducers');

/**
 * INFO: add comment
 */
export function deviceReducersFactory(): ActionReducerMap<DeviceState> {
    // map of reducers
    return deviceReducerMap;
}
