import { WEB_BROWSER_DEFAULT_POSITION_PARAMS, WEB_BROWSER_FULLSCREEN_POSITION_PARAMS } from '../constants';

/**
 *	Use migrations to perform operations to the store whenever a version is changed.
 *
 *	The `migrations` object should consist of a key-value pair of `'version': handler`. The `version` can also be a [semver range](https://github.com/npm/node-semver#ranges).
 *
 *	Note: The version the migrations use refers to the __project version__ by default. If you want to change this behavior, specify the `projectVersion` option.
 *
 *	@example
 *	```
 *	import Conf = require('conf');
 *
 *	const store = new Conf({
 *		migrations: {
 *			'0.0.1': store => {
 *				store.set('debugPhase', true);
 *			},
 *			'1.0.0': store => {
 *				store.delete('debugPhase');
 *				store.set('phase', '1.0.0');
 *			},
 *			'1.0.2': store => {
 *				store.set('phase', '1.0.2');
 *			},
 *			'>=2.0.0': store => {
 *				store.set('phase', '>=2.0.0');
 *			}
 *		}
 *	});
 *	```
 */
export const MIGRATIONS = {
    // MAD
    '1.1.12': store => {
        store.set('skipNetworkOnLinux', true);
    },
    '1.2.5': store => {
        store.delete('skipNetworkOnLinux');
    },
    // HER
    '1.3.0': store => {
        store.delete('version');
    },
    '1.3.1': store => {
        store.delete('version');
        store.delete('ENV');
        store.delete('webRtcMedia');
        store.delete('disableLockScreenAtStartup');
        store.set('features.pdms', !!store.get('isPdmsEnabled') || true);
        store.delete('isPdmsEnabled');
        store.set('features.telemedicine', !!store.get('isTelemedicineEnabled') || false);
        store.delete('isTelemedicineEnabled');
        store.set('features.voice', !!store.get('isVoiceEnabled'));
        store.delete('isVoiceEnabled');
    },
    '1.3.2': store => {
        // ENOVACOM MD-6431
        store.set('enovacom', {
            baseUrl: process?.env?.ENOVACOM_URL || 'http://localhost:8181',
            loginHeader: 'X-EAS-LOGIN',
            loginHeaderValue: 'nurse',
            tokenHeader: 'X-EAS-TOKEN',
            authPath: '/ws-token/api/auth',
        });
    },
    '2.0.0': store => {
        store.delete('api.sttUrl');
        store.delete('api.ttsUrl');
    },
    '2.0.6': store => {
        store.set('keyboardLocale', 'en-GB');
    },
    '2.1.11': store => {
        store.set('tableScrollPageSize', 1000);
        // Huge number to make sure everything will be loaded at once
        store.set('tableScrollPageSizeVentilation', 100000);
    },
    '2.1.14': store => {
        store.delete('tableScrollPageSizeVentilation');
        store.set('tableScrollPageSize', 5000);
    },
    '2.5.33': store => {
        store.set('webBrowserPositionParams', WEB_BROWSER_DEFAULT_POSITION_PARAMS);
        store.set('webBrowserFullScreenParams', WEB_BROWSER_FULLSCREEN_POSITION_PARAMS);
    },
};
