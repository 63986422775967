import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { ChangeLogEntry, ChangeLogModel, ChangeLogOperation, Encounter, PersistChangesResult } from '@mona/models';
import { transformToApiChangeLogEntry } from './transforms/change-log-creation.transforms';
import { transformApiChangeLogEntries, transformApiChangeLogEntry } from './transforms/change-log.transforms';
import { transformApiPersistChangesResult } from './transforms/persist-changes.transforms';

/**
 * API abstraction layer for the change log API
 */
@Injectable({ providedIn: 'root' })
export class ChangeLogApi {
    apiBase = '/pdms/changes/';
    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Load changes
     *
     * @param encounterId EntityId<Encounter>
     */
    loadChanges(encounterId: EntityId<Encounter>): Observable<ChangeLogEntry<ChangeLogModel>[]> {
        return this.http
            .get<ApiModels.ChangeLogEntry[]>(this.apiBase, {
                params: {
                    encounter_id: encounterId as string,
                },
            })
            .pipe(map(entries => transformApiChangeLogEntries(entries)));
    }

    /**
     * Save changes
     *
     * @param change ChangeLogEntry
     * @param encounterId EntityId<Encounter>
     */
    saveChange(
        change: ChangeLogEntry<ChangeLogModel>,
        encounterId: EntityId<Encounter>,
    ): Observable<ChangeLogEntry<ChangeLogModel>> {
        const apiChange = transformToApiChangeLogEntry(change, encounterId);
        if (change.operation === ChangeLogOperation.Delete) {
            delete apiChange.payload;
        }
        return this.http
            .post<ApiModels.ChangeLogEntry>(this.apiBase, apiChange)
            .pipe(map(entry => transformApiChangeLogEntry(entry)));
    }

    /**
     * Persist changes
     *
     * @param rfid string
     * @param encounterId EntityId<Encounter>
     */
    persistChanges(rfid: string, encounterId: EntityId<Encounter>): Observable<PersistChangesResult> {
        return this.http
            .post<ApiModels.ChangeLogEntryPersist>(`${this.apiBase}persist/`, {
                rfid,
                encounter_id: encounterId,
            })
            .pipe(map(result => transformApiPersistChangesResult(result)));
    }

    /**
     * Discards changes
     *
     * @param rfid string
     * @param encounterId EntityId<Encounter>
     * @param ids string[]
     */
    discardChanges(rfid: string, encounterId: EntityId<Encounter>, ids: string[]): Observable<void> {
        let url = `${this.apiBase}discard/`;

        if (ids?.length) {
            const params = new HttpParams().set('model_ids', ids.join(','));
            url += `?${params.toString()}`;
        }

        return this.http.post<void>(url, {
            rfid,
            encounter_id: encounterId,
        });
    }
}
