<mat-card
    class="mona-medication-list__wrapper"
    [class.mona-medication-list__wrapper--as-list]="showAsList"
    [uiLoadingOverlay]="isLoading"
>
    <form class="mona-medication-list__search" [formGroup]="searchForm" *ngIf="showTableHeader">
        <input
            class="mona-medication-list__search-input"
            [formControl]="searchForm.controls.query"
            type="text"
            [placeholder]="'apps.settings.prescriptionSets.search' | translate"
        />
        <mat-icon *ngIf="!searchForm.controls.query.value; else clearSearch" class="mona-medication-list__search-icon">
            search
        </mat-icon>

        <ng-template #clearSearch>
            <mat-icon (click)="searchForm.reset()" class="mona-medication-list__search-icon">close</mat-icon>
        </ng-template>
    </form>

    <div class="mona-medication-list__container scrollbar-visible">
        <mona-medication-item
            *ngFor="let item of dataSource.filteredData; let i = index"
            [item]="item"
            [divider]="!showAsList"
            [selectable]="selectable"
            [selected]="getItemSelected(item)"
            (click)="selectable && selection.toggle(item)"
            [medicationCategories]="medicationCategories"
            [medicationSolutions]="medicationSolutions"
            [medicationAdministrationMethods]="medicationAdministrationMethods"
            [medicationFrequencies]="medicationFrequencies"
            [medicationDosageForms]="medicationDosageForms"
            matRipple
            [matRippleDisabled]="!selectable"
        >
            <div class="mona-medication-item--actions" [visibleIf]="!showAsList">
                <button mat-icon-button color="primary" *ngIf="showEdit" (click)="edit.emit(item.id)">
                    <mat-icon class="mat-icon--bicolor mona-medication__icon">edit</mat-icon>
                </button>
                <button
                    mat-icon-button
                    color="primary"
                    *ngIf="showDelete && !deletedMedicationsSet?.has(item.id)"
                    (click)="delete.emit(item.id)"
                >
                    <mat-icon class="mat-icon--bicolor mona-medication__icon">delete</mat-icon>
                </button>
            </div>
        </mona-medication-item>
        <p class="text-center" *ngIf="!dataSource.filteredData.length && searchForm.controls.query.value">
            {{ 'commonStrings.messages.searchNoResult' | translate: { query: searchForm.controls.query.value } }}
        </p>
        <ui-empty-state
            *ngIf="dataSource.empty && !showAsList && !searchForm.controls.query.value"
            [title]="noDataConfig.title | translate"
            [subTitle]="noDataConfig.subTitle | translate"
            [ctaTitle]="noDataConfig.ctaTitle | translate"
            (ctaClick)="edit.emit()"
        ></ui-empty-state>
    </div>
</mat-card>
