/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { BalanceValue, Encounter, MedicationAdministration, PatientOutput } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';
import { AsyncAction, FailedAction, SucceededAction } from '@mona/store';
import { BalanceTableEnum } from '../../entities';

/**
 * Balance actions
 */
export namespace BalanceActions {
    export const setIntervalAction = createAction(
        'BALANCE:SET_BALANCE_INTERVAL',
        props<{ interval: EntriesInterval }>(),
    );

    export const setActiveTable = createAction('BALANCE:SET_ACTIVE_TABLE', props<{ idx: BalanceTableEnum }>());

    export const setIsLoadingAction = createAction('BALANCE:SET_IS_LOADING', props<{ isLoading: boolean }>());

    export const upsertMedicationAdministration = createAction(
        'BALANCE:UPSERT_MEDICATION_ADMINISTRATION',
        props<{ medicationAdministration: MedicationAdministration }>(),
    );

    export const addMedicationAdministration = createAction(
        'BALANCE:ADD_MEDICATION_ADMINISTRATION',
        props<{ medicationAdministration: MedicationAdministration }>(),
    );

    export const deleteMedicationAdministration = createAction(
        'BALANCE:DELETE_MEDICATION_ADMINISTRATION',
        props<{ id: string }>(),
    );

    /**
     * Load outputs action
     *
     * @deprecated
     */
    export const loadOutputsAction: AsyncAction<
        {
            encounterId: EntityId<Encounter>;
        },
        PatientOutput[]
    > = {
        action: createAction(
            'BALANCE:LOAD_OUTPUTS',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction('BALANCE:LOAD_OUTPUTS_SUCCEEDED', props<SucceededAction<PatientOutput[]>>()),

        failedAction: createAction('BALANCE:LOAD_OUTPUTS_FAILED', props<FailedAction>()),

        clearAction: createAction('BALANCE:LOAD_OUTPUTS_CLEAR'),
    };

    export const loadMedicationAdministrations = createAction(
        'BALANCE:LOAD_MEDICATION_ADMINISTRATIONS',
        props<{ encounterId: string }>(),
    );

    export const loadMedicationAdministrationsSuccess = createAction(
        'BALANCE:LOAD_MEDICATION_ADMINISTRATIONS_SUCCESS',
        props<{ medicationAdministrations: MedicationAdministration[] }>(),
    );

    export const loadMedicationAdministrationsFailed = createAction(
        'BALANCE:LOAD_MEDICATION_ADMINISTRATIONS_FAILED',
        props<{ error: any }>(),
    );

    export const clearMedicationAdministrations = createAction('BALANCE:CLEAR_MEDICATION_ADMINISTRATIONS');
}

export namespace BalanceValuesActions {
    /**
     * Load BalanceValues (force - determine if the data should be loaded from the server or from cache)
     */
    export const loadBalanceValues = createAction('BALANCE:BALANCE_VALUE_LOAD', props<{ force: boolean }>());

    export const loadBalanceValuesSuccess = createAction(
        'BALANCE:BALANCE_VALUE_LOAD_SUCCESS',
        props<{ balanceValues: BalanceValue[]; interval: EntriesInterval }>(),
    );

    export const loadBalanceValuesFailure = createAction(
        'BALANCE:BALANCE_VALUE_LOAD_FAILURE',
        props<{ error: Error }>(),
    );

    export const clearBalanceValuesCache = createAction('BALANCE:BALANCE_VALUE_CLEAR_CACHE');

    export const updateBalanceValues = createAction(
        'BALANCE:BALANCE_VALUE_UPDATE_BALANCE_VALUES',
        props<{ balanceValues: Update<BalanceValue>[] }>(),
    );

    export const upsertBalanceValues = createAction(
        'BALANCE:BALANCE_VALUE_UPSERT_BALANCE_VALUES',
        props<{ balanceValues: BalanceValue[] }>(),
    );

    export const processBalanceValues = createAction(
        'BALANCE:BALANCE_VALUE_PROCESS_BALANCE_VALUES',
        props<{ balanceValues: BalanceValue[] }>(),
    );

    export const clearBalanceValues = createAction('BALANCE:BALANCE_VALUE_CLEAR_BALANCE_VALUES');
}
