import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../../services';

/**
 * Sign in with RFID component
 */
@Component({
    selector: 'mona-sign-in-rfid',
    templateUrl: './sign-in-rfid.component.html',
    styleUrls: ['./sign-in-rfid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInRfidComponent {
    /**
     * Host css class
     */
    @HostBinding('class.mona-sign-in-rfid') cmpClass = true;

    /**
     * open sign in dialog
     */
    @Output() openSignInDialog: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Sign in async action
     */
    isLoading$: Observable<boolean> = this.authService.isLoading$;

    /**
     * Constructor
     *
     * @param authService AuthService
     */
    constructor(private authService: AuthService) {}
}
