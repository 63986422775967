/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { PractitionerRole } from '@mona/models';

export const loadPractitioners = createAction(
    'PRACTITIONERS:LOAD_PRACTITIONERS',
    props<{
        ids?: string[];
        role?: PractitionerRole;
    }>(),
);
export const upsertPractitioners = createAction(
    'PRACTITIONERS:UPSERT_PRACTITIONERS',
    props<{
        practitioners: any;
    }>(),
);

export const loadPractitionersSuccess = createAction(
    'PRACTITIONERS:LOAD_PRACTITIONERS_SUCCEEDED',
    props<{
        practitioners: any;
    }>(),
);

export const loadPractitionersFailure = createAction(
    'PRACTITIONERS:LOAD_PRACTITIONERS_FAILED',
    props<{
        error: any;
    }>(),
);

export const clearPractitioners = createAction('PRACTITIONERS:LOAD_PRACTITIONERS_CLEAR');
