import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ConfigService, WEB_BROWSER_DEFAULT_POSITION_PARAMS } from '@mona/config';
import { IpcMainEvent } from '@mona/events';
import { EncounterService } from '@mona/pdms/data-access-encounter-data';
import { ExternalResourcesApi } from '@mona/pdms/data-access-external-resources';
import { MonaRpcService } from '@mona/rpc';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ExternalResourcesActions } from '../actions/external-resources.actions';

/**
 * Notifications effects
 */
@Injectable()
export class ExternalResourcesEffects {
    /** Load External Resources */
    loadExternalResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalResourcesActions.loadExternalResources),
            withLatestFrom(this.encounterService.getEncounter()),
            switchMap(([, encounter]) => this.externalResourcesApiService.getExternalResources(encounter)),
            map(data => ExternalResourcesActions.loadExternalResourcesSuccess({ data })),
            catchError(error => of(ExternalResourcesActions.loadExternalResourcesFailure({ error }))),
        ),
    );

    /**
     * Constructor
     *
     * @param actions$
     * @param externalResourcesApiService
     * @param rpcService
     * @param configService
     * @param encounterService
     */
    constructor(
        private actions$: Actions,
        private externalResourcesApiService: ExternalResourcesApi,
        private rpcService: MonaRpcService,
        private configService: ConfigService,
        private encounterService: EncounterService,
    ) {}

    browserNavigateBackAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalResourcesActions.browserNavigateBackAction),
            exhaustMap(() =>
                from(this.rpcService.invoke<any>(IpcMainEvent.BROWSER_NAVIGATE_BACK)).pipe(
                    catchError(err => {
                        console.error(err);
                        return of();
                    }),
                ),
            ),
            map(() => ExternalResourcesActions.browserNavigateBackActionSuccess()),
        ),
    );

    browserNavigateForwardAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalResourcesActions.browserNavigateForwardAction),
            exhaustMap(() =>
                from(this.rpcService.invoke<any>(IpcMainEvent.BROWSER_NAVIGATE_FORWARD)).pipe(
                    catchError(err => {
                        console.error(err);
                        return of();
                    }),
                ),
            ),
            map(() => ExternalResourcesActions.browserNavigateForwardActionSuccess()),
        ),
    );

    browserReloadAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalResourcesActions.browserReloadAction),
            exhaustMap(() =>
                from(this.rpcService.invoke<any>(IpcMainEvent.BROWSER_RELOAD)).pipe(
                    catchError(err => {
                        console.error(err);
                        return of();
                    }),
                ),
            ),
            map(() => ExternalResourcesActions.browserReloadActionSuccess()),
        ),
    );

    browserEndSessionAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalResourcesActions.browserEndSessionAction),
            exhaustMap(() =>
                from(this.rpcService.invoke<any>(IpcMainEvent.BROWSER_END_SESSION)).pipe(
                    catchError(err => {
                        console.error(err);
                        return of();
                    }),
                ),
            ),
            map(() => ExternalResourcesActions.browserEndSessionActionSuccess()),
        ),
    );

    browserViewResize = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalResourcesActions.browserViewResize),
            exhaustMap(payload =>
                from(this.rpcService.invoke<any>(IpcMainEvent.BROWSER_VIEW_RESIZE, payload)).pipe(
                    catchError(err => {
                        console.error(err);
                        return of();
                    }),
                    map(() => ExternalResourcesActions.browserViewResizeSuccess()),
                ),
            ),
        ),
    );

    browserSetWindowShownAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalResourcesActions.browserSetWindowShownAction),
            exhaustMap(() =>
                from(
                    this.rpcService.invoke<any>(
                        IpcMainEvent.BROWSER_SET_WINDOW_SHOWN,
                        this.configService.get('webBrowserPositionParams') || WEB_BROWSER_DEFAULT_POSITION_PARAMS,
                    ),
                ).pipe(
                    catchError(err => {
                        console.error(err);
                        return of();
                    }),
                ),
            ),
            map(() => ExternalResourcesActions.browserSetWindowShownActionSuccess()),
        ),
    );

    browserSetWindowHiddenAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalResourcesActions.browserSetWindowHiddenAction),
            exhaustMap(() =>
                from(this.rpcService.invoke<any>(IpcMainEvent.BROWSER_SET_WINDOW_HIDDEN)).pipe(
                    catchError(err => {
                        console.error(err);
                        return of();
                    }),
                ),
            ),
            map(() => ExternalResourcesActions.browserSetWindowHiddenActionSuccess()),
        ),
    );
}
