import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { WithLogger } from '@mona/shared/logger';
import { FeatureFlagsService, FEATURE_FLAGS } from './feature-flags.service';

/** License Guard */
@Injectable({ providedIn: 'root' })
@WithLogger({
    loggedMethodsNames: ['canActivate', 'canLoad'],
})
export class FeatureFlagsGuard {
    /**
     * Constructor
     *
     * @param featureFlagService
     */
    constructor(@Inject(FEATURE_FLAGS) private featureFlagService: FeatureFlagsService) {}

    /**
     * if a child route can be activated by having the corresponding feature eanbled
     *
     * @param route
     * @param state
     */
    // @LogMethod({ ignoreProperties: ['route'], interceptProperties: { state: (state: RouterStateSnapshot) => state.url }, })
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        await this.featureFlagService.ready$.toPromise();
        return this.featureFlagService.check(route.data?.checkFeature);
    }

    /**
     * if a route can be loaded by having the corresponding feature eanbled
     *
     * @param route
     * @param segments
     */
    // @LogMethod({ ignoreProperties: ['route'], interceptProperties: { segments: (segments: UrlSegment[]) => segments.join('/') }, })
    async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
        await this.featureFlagService.ready$.toPromise();
        return this.featureFlagService.check(route.data?.checkFeature);
    }
}
