import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { OnChange, SimpleChange, TakeUntilDestroy, takeUntilDestroy } from '@mona/shared/utils';
import { buildPreMedicationFormGroup, PreMedicationsForm, PreMedicationsItemForm } from '../../models';

/**
 * PreMedications form
 */
@TakeUntilDestroy
@Component({
    selector: 'app-pre-medications-form',
    templateUrl: './pre-medications-form.component.html',
    styleUrls: ['./pre-medications-form.component.scss'],
})
export class PreMedicationsFormComponent implements OnInit, OnDestroy {
    /**
     * The form for added items
     */
    formGroup: FormGroup<PreMedicationsForm>;

    /**
     * Is form active / visible
     */
    /* prettier-ignore */
    @OnChange(function(this: PreMedicationsFormComponent, value: boolean, change: SimpleChange<boolean>) {
        if (value && !change.firstChange && !this.newItem) {
            this.addItem();
        }
    })
    @Input() isActive: boolean;

    /**
     * Is read only
     */
    @Input() isReadOnly: boolean;

    /**
     * Indicates closing pre-medication step
     */
    @OnChange(function (this: PreMedicationsFormComponent, preMedicationClosed$: Subject<void>) {
        if (preMedicationClosed$) {
            this.preMedicationClosed$.pipe(takeUntilDestroy(this)).subscribe(() => {
                const latestItem = this.newItem;
                latestItem?.enable();
            });
        }
    })
    @Input()
    preMedicationClosed$: Subject<void>;

    /**
     * The last form group always empty for new items
     */
    get newItem() {
        return this.formGroup?.controls?.items?.at(this.formGroup.controls.items.controls.length - 1);
    }

    /**
     * Constructor
     *
     * @param controlContainer ControlContainer
     */
    constructor(@Optional() private controlContainer: ControlContainer) {}

    /**
     * NG Hook
     */
    ngOnInit() {
        if (this.controlContainer.control) {
            this.formGroup = this.controlContainer.control as any;
        }
    }

    /**
     * NG Hook
     */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnDestroy() {}

    /**
     * Adds a new form control item for an pre medications
     *
     * @param formValue
     */
    addItem(formValue?: any): void {
        const prev = this.newItem;
        prev?.disable();
        // Add to list, so we have NEW newitem
        const newItem = this.buildFormGroup(formValue);
        newItem.markAsPristine();
        newItem.markAsUntouched();
        this.formGroup.controls.items.push(newItem);
    }

    /**
     * Removes a pre medications from the array
     *
     * @param index index of the item that should be removed
     * @param event MouseEvent
     */
    removeItem(index: number, event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.formGroup.controls.items.removeAt(index);
    }

    /**
     * Build form group
     *
     * @param formValue
     */
    private buildFormGroup(formValue: any): FormGroup<PreMedicationsItemForm> {
        const formGroup = buildPreMedicationFormGroup();
        if (formValue) {
            formGroup.reset(formValue);
        }
        return formGroup;
    }
}
