import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfigService } from '@mona/config';
import { DataUpdateMessage, PageableModel, ParamsModel, TableViewModeEnum, VitalSign } from '@mona/models';
import { DateRangeInterval, EntriesInterval } from '@mona/shared/date';
import { EncounterDataState, VitalSignsMap } from '../entities';
import {
    selectAllVitals,
    selectAllVitalsChanged,
    selectAllVitalsMap,
    selectAllVitalsMapByCode,
    selectVitalsCodes,
    selectVitalsDateRange,
    selectVitalSignsParametersPageableData,
    selectVitalsInterval,
    selectVitalsIsLoading,
    selectVitalsStartDate,
    selectVitalsState,
    selectVitalsViewMode,
    VitalSignsAction,
} from '../state';

/**
 * Encounter vital signs service
 */
@Injectable({ providedIn: 'root' })
export class VitalSignService {
    /** Select vitals state */
    vitalSignsState$: Observable<any> = this.store.select(selectVitalsState);
    /** Select all vitals */
    vitalSigns$: Observable<VitalSign[]> = this.store.select(selectAllVitals);
    /** Select all vitals that are changed */
    vitalSignsChanged$: Observable<VitalSign[]> = this.store.select(selectAllVitalsChanged);
    /** Select all vitals map */
    vitalSignsMap$: Observable<EntityMap<VitalSign>> = this.store.select(selectAllVitalsMap);
    /** Select table view interval */
    selectedInterval$: Observable<EntriesInterval> = this.store.select(selectVitalsInterval);
    /** Select table view date range */
    selectedDateRange$: Observable<DateRangeInterval> = this.store.select(selectVitalsDateRange);
    /** Select table view mode */
    viewMode$: Observable<TableViewModeEnum> = this.store.select(selectVitalsViewMode);
    /** Select is loading */
    isLoading$: Observable<boolean> = this.store.select(selectVitalsIsLoading);
    /** Select all vitals grouped by code */
    vitalSignsByCode$: Observable<VitalSignsMap> = this.store.select(selectAllVitalsMapByCode);
    /** Select vitals start date */
    vitalSignsStartDate$: Observable<Date> = this.store.select(selectVitalsStartDate);

    /**
     * Constructor
     *
     * @param store Store<EncounterDataState>
     * @param configService
     */
    constructor(private store: Store<EncounterDataState>, public configService: ConfigService) {
        const defaultInterval = configService.get('defaultInterval');
        if (defaultInterval) {
            this.setInterval(EntriesInterval[defaultInterval]);
        }
    }

    /**
     * Reset view mode
     */
    resetViewMode(): void {
        this.store.dispatch(VitalSignsAction.resetViewMode());
    }

    /**
     * Sets interval
     *
     * @param interval HistoryEntryType
     */
    setInterval(interval: EntriesInterval): void {
        this.store.dispatch(VitalSignsAction.setInterval({ interval }));
    }

    /**
     * Set vital sign date range
     *
     * @param dateRange
     */
    setDateRange(dateRange: DateRangeInterval): void {
        this.store.dispatch(VitalSignsAction.setDateRange({ dateRange }));
    }

    /**
     * Set Loading
     *
     * @param isLoading
     */
    setLoading(isLoading: boolean): void {
        this.store.dispatch(VitalSignsAction.setLoadingAction({ isLoading }));
    }

    /**
     * Load vital signs
     *
     * @param params
     */
    loadVitalSigns(params?: ParamsModel): void {
        this.store.dispatch(VitalSignsAction.loadVitalSigns({ params }));
    }

    /**
     * Load vital signs
     *
     * @param updateMessage
     */
    addVitalSigns(updateMessage: DataUpdateMessage<VitalSign>): void {
        const dataItem = {
            ...updateMessage.payload,
            id: updateMessage.modelId,
        } as VitalSign;
        const data = [dataItem];
        this.store.dispatch(VitalSignsAction.upsertVitalSigns({ data }));
    }

    /**
     * Load vital signs
     *
     * @param updateMessage
     */
    updateVitalSign(updateMessage: DataUpdateMessage<VitalSign>): void {
        const dataItem = {
            ...updateMessage.payload,
            id: updateMessage.modelId,
        } as VitalSign;
        const data = [dataItem];
        this.store.dispatch(VitalSignsAction.upsertVitalSigns({ data }));
    }

    /**
     * Clear vital signs
     */
    clearVitalSigns(): void {
        this.store.dispatch(VitalSignsAction.clearVitalSigns());
    }

    /**
     * Get Vitals Parameter State
     */
    getPageableData(): Observable<PageableModel<VitalSign>> {
        return this.store.select(selectVitalSignsParametersPageableData);
    }

    /**
     * Get vitals codes data
     */
    getCodesData(): Observable<string[]> {
        return this.store.select(selectVitalsCodes);
    }
}
