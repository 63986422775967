import { serializeError } from 'serialize-error';

const getErrorPropValues = (o: AnyObject, prop: string) =>
    (res => (JSON.stringify(o, (key, value) => (key.match(prop) && res.push(value), value)), res))([]);

/**
 * If error has status unauthorized or  Forbidden
 *
 * @param err
 */
export const isErrorStatusUnauthorized = (err: any) =>
    // FIXME: electron compilation error if angular/common imcluded
    // err?.status === HttpStatusCode.Unauthorized || err?.status === HttpStatusCode.Forbidden;
    err?.status === 401 || err?.status === 403;

/**
 * If error has status is Conflict
 *
 * @param err
 */
export const isErrorStatusConflict = (err: any) =>
    // FIXME: electron compilation error if angular/common imcluded
    // err?.status === HttpStatusCode.Conflict
    err?.status === 409;

/**
 * If error has status is `0`
 *
 * Status code '0' can occur because of three reasons
 * 1) The Client cannot connect to the server
 * 2) The Client cannot receive the response within the timeout period
 * 3) The Request was "stopped(aborted)" by the Client.
 *
 * @param err
 */
export const isErrorStatus0 = (err: any) => err?.status === 0;

/**
 * PDMS error
 */
export class PdmsError extends Error {
    /** @ignore */
    constructor(public message: string, public data?: AnyObject) {
        super(message);
        this.data = data;
    }
}

/**
 * App error interface
 */
export interface AppError {
    /** Error name */
    name?: string;
    /** Error message */
    message?: string;
    /** Error code */
    errorCode?: string;
    /** Time stamp */
    timeStamp?: number;
    /** Is http error */
    httpError?: boolean;
    /** Http error status */
    status?: number;
    /** Original http error */
    originalError?: any;
    /** An action which triggered an error */
    initiator?:
        | {
              type: string;
          }
        | any;
}

/**
 * AppError
 */
export class AppError implements AppError {
    /** @ignore */
    constructor(error?) {
        error = serializeError(error);
        this.message = isErrorStatus0(error)
            ? BaseErrors.UNREACHABLE
            : getErrorPropValues(error, 'detail')?.[0] || error.message;
        this.status = error?.status;
        this.errorCode = isErrorStatus0(error)
            ? BaseErrors.UNREACHABLE
            : isErrorStatusUnauthorized(error)
            ? AuthErrors.UNATHORIZED
            : getErrorPropValues(error, 'code')?.[0] || BaseErrors.UNKNOWN;
        this.timeStamp = Date.now();
        this.httpError = error?.status !== undefined;
        this.originalError = error;
    }
}

/**
 * Base errors
 */
export const BaseErrors = {
    UNREACHABLE: 'errors.offline',
    UNKNOWN: 'errors.unknown',
    OFFLINE: 'errors.offline',
    WEBSOCKET: 'errors.websocket',
    WEBSOCKET_MAX_RETRIES: 'errors.websocketMaxRetries',
};

/**
 * Auth errors
 */
export const AuthErrors = {
    UNATHORIZED: 'auth.unathorized',
    BAD_CREDENTIALS: 'login_error.bad_creds',
    TOKEN_EXPIRED: 'token.expired',
    TOKEN_INVALID: 'token.invalid',
    DEVICE: 'device.not_found',
};

/**
 * Media errors
 */
export const MEDIA_ERRORS = {
    // Could not get user camera/mic for
    CAN_NOT_GET_MEDIA: 'media.can_not_get_media',
};

/**
 * An interface for async error alert service
 */
export interface AsyncActionErrorConfig {
    /**
     * Mapping between errors and
     * translate keys for errors texts
     */
    errorMessageMapping?: {
        [errorCode: string]: string;
    };

    /**
     * Normally unexpected errors are ignored
     * but if this field is provided errors which are out of errorMessageMapping handled as well
     */
    unexpectedErrorsMessage?: string;

    /**
     * List of error codes which should be ignored
     */
    errorsToIgnore?: string[];
}
