/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { VaccinationStatus } from '@mona/models';

export namespace VaccinationsStatusAction {
    export const loadVaccinations = createAction('ENCOUNTER:LOAD_VACCINATIONS');
    export const loadVaccinationsSuccess = createAction(
        'ENCOUNTER:LOAD_VACCINATIONS_SUCCESS',
        props<{ data: VaccinationStatus[] }>(),
    );
    export const loadVaccinationsFailure = createAction('ENCOUNTER:LOAD_VACCINATIONS_FAILURE', props<{ error: any }>());

    export const updateVaccinations = createAction(
        'ENCOUNTER:UPDATE_VACCINATIONS',
        props<{ update: Update<VaccinationStatus>[] }>(),
    );

    export const upsertVaccinations = createAction(
        'ENCOUNTER:UPSERT_VACCINATIONS',
        props<{ data: VaccinationStatus[] }>(),
    );

    export const clearVaccinations = createAction('ENCOUNTER:CLEAR_VACCINATIONS');

    export const addVaccinationsChanges = createAction(
        'ENCOUNTER:ADD_CHANGES',
        props<{ toUpdateEntities: VaccinationStatus[]; toRemoveIds: string[] }>(),
    );
}
