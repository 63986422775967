import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { noop } from '@mona/shared/utils';

/**
 * UiDynamicButton Component
 */
@Component({
    selector: 'ui-dynamic-button',
    template: `
        <button
            *ngIf="!icon"
            mat-button
            class="ui-dynamic-button"
            [class.mdc-button--raised]="primary"
            [class.mat-stroked-button]="!primary"
            [ngClass]="customClass"
            [color]="color"
            [title]="placeholder"
            [disabled]="form.disabled"
            [id]="name"
            (click)="onBtnClick($event, form)"
        >
            <ng-container *ngIf="label">{{ label | translate }}</ng-container>
        </button>
        <!--  -->
        <button
            *ngIf="icon"
            mat-icon-button
            class="ui-dynamic-button"
            [class.mat-stroked-button]="!primary"
            [ngClass]="customClass"
            [color]="color"
            [title]="placeholder"
            [disabled]="form.disabled"
            [id]="name"
            (click)="onBtnClick($event, form)"
        >
            <ng-container *ngIf="icon">
                <mat-icon>{{ icon }}</mat-icon>
            </ng-container>
        </button>
    `,
    styles: [
        `
            :host {
                display: flex;
                align-items: center;
            }
        `,
    ],
})
export class UiDynamicButtonComponent {
    control = new UntypedFormControl();

    @ViewChild(MatButton, { read: ElementRef, static: true }) elementRef: ElementRef;

    form: UntypedFormGroup;

    label = '';

    hint = '';

    placeholder = '';

    name = '';

    icon = '';

    customClass = '';

    color = '';

    primary: boolean;

    disabled: boolean;

    onBtnClick = (event, form) => noop;
}
