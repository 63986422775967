<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content class="app-settings-dialog__content">
        <div>
            <h2 mat-dialog-title class="app-settings-dialog__title">{{ 'settings.title' | translate }}</h2>
            <p class="app-settings-dialog__description">
                {{ 'settings.description' | translate }}
            </p>
        </div>

        <mat-form-field>
            <mat-icon matPrefix>mic</mat-icon>
            <mat-label>{{ 'settings.mic' | translate }}</mat-label>
            <mat-select required [formControl]="form.controls.audioInput" name="audioInput">
                <mat-option *ngFor="let entry of availableDevices.audioInput | keyvalue" [value]="entry.key">
                    {{ $any(entry.value).label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="platform.BLINK">
            <mat-icon matPrefix>speaker</mat-icon>
            <mat-label>{{ 'settings.speaker' | translate }}</mat-label>
            <mat-select required [formControl]="form.controls.audioOutput" name="audioOutput">
                <mat-option *ngFor="let entry of availableDevices.audioOutput | keyvalue" [value]="entry.key">
                    {{ $any(entry.value).label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-icon matPrefix>videocam</mat-icon>
            <mat-label>{{ 'settings.video' | translate }}</mat-label>
            <mat-select required [formControl]="form.controls.videoInput" name="videoInput">
                <mat-option *ngFor="let entry of availableDevices.videoInput | keyvalue" [value]="entry.key">
                    {{ $any(entry.value).label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="app-settings-dialog__actions">
            <button mat-raised-button color="primary" cdkFocusInitial [mat-dialog-close]="true" type="submit">
                {{ 'settings.action' | translate }}
            </button>
        </div>
    </mat-dialog-content>
</form>
