/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { MedicationPrescription } from '@mona/models';

export const loadMedicationPrescriptions = createAction('MEDICATIONS:LOAD_MEDICATION_PRESCRIPTIONS');

export const processMedicationPrescriptions = createAction(
    'MEDICATIONS:PROCESS_MEDICATION_PRESCRIPTIONS',
    props<{ medicationPrescriptions: MedicationPrescription[] }>(),
);

export const loadMedicationPrescriptionsFailure = createAction(
    'MEDICATIONS:LOAD_MEDICATION_PRESCRIPTIONS_FAILURE',
    props<{ error: any }>(),
);

export const addMedicationPrescription = createAction(
    'MEDICATIONS:ADD_MEDICATION_PRESCRIPTION',
    props<{ medicationPrescription: MedicationPrescription }>(),
);

export const upsertMedicationPrescription = createAction(
    'MEDICATIONS:UPSERT_MEDICATION_PRESCRIPTION',
    props<{ medicationPrescription: MedicationPrescription }>(),
);

export const addMedicationPrescriptions = createAction(
    'MEDICATIONS:ADD_MEDICATION_PRESCRIPTIONS',
    props<{ medicationPrescriptions: MedicationPrescription[] }>(),
);

export const upsertMedicationPrescriptions = createAction(
    'MEDICATIONS:UPSERT_MEDICATION_PRESCRIPTIONS',
    props<{ medicationPrescriptions: MedicationPrescription[] }>(),
);

export const updateMedicationPrescription = createAction(
    'MEDICATIONS:UPDATE_MEDICATION_PRESCRIPTION',
    props<{ medicationPrescription: Update<MedicationPrescription> }>(),
);

export const updateMedicationPrescriptions = createAction(
    'MEDICATIONS:UPDATE_MEDICATION_PRESCRIPTIONS',
    props<{ medicationPrescriptions: Update<MedicationPrescription>[] }>(),
);

export const deleteMedicationPrescription = createAction(
    'MEDICATIONS:DELETE_MEDICATION_PRESCRIPTION',
    props<{ id: string }>(),
);

export const deleteMedicationPrescriptions = createAction(
    'MEDICATIONS:DELETE_MEDICATION_PRESCRIPTIONS',
    props<{ ids: string[] }>(),
);

export const clearMedicationPrescriptions = createAction('MEDICATIONS:CLEAR_MEDICATION_PRESCRIPTIONS');

export const handleConfirmMedicationPrescriptionChanges = createAction('MEDICATIONS:HANDLE_CONFIRM');

export const handleDeleteMedicationPrescription = createAction(
    'MEDICATIONS:HANDLE_DELETE_MEDICATION_PRESCRIPTION',
    props<{ medicationPrescription: MedicationPrescription }>(),
);

export const handleAddMedicationPrescription = createAction(
    'MEDICATIONS:HANDLE_ADD_MEDICATION_PRESCRIPTION',
    props<{ medicationPrescription: MedicationPrescription }>(),
);

export const addMedicationPrescriptionChanges = createAction(
    'MEDICATIONS:ADD_MEDICATION_PRESCRIPTION_CHANGES',
    props<{ toUpdateEntities: MedicationPrescription[]; toRemoveIds: string[] }>(),
);
