import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { startOfMinute } from 'date-fns';
import { LabValue, PageableModel } from '@mona/models';
import { omit } from '@mona/shared/utils';
import { LabValuesState } from '../../entities';
import { LabValuesAction } from '../actions';

export const labValuesAdapter: EntityAdapter<LabValue> = createEntityAdapter<LabValue>({
    selectId: (item: LabValue) => item.id,
});

export const initialState: LabValuesState = labValuesAdapter.getInitialState({
    isLoading: false,
    startDate: null,
    pageableData: null,
});

export const labValuesReducer = createReducer(
    initialState,

    on(LabValuesAction.loadLabValues, state => ({
        ...state,
        isLoading: true,
    })),

    on(LabValuesAction.loadLabValuesSuccess, LabValuesAction.loadLabValuesFailed, state => ({
        ...state,
        isLoading: false,
    })),

    on(LabValuesAction.loadLabValuesSuccess, (state, action: PageableModel<LabValue>) => {
        const { results, ...pageableData } = omit(action, 'type') as PageableModel<LabValue>;

        return labValuesAdapter.upsertMany(results, {
            ...state,
            isLoading: false,
            pageableData,
            startDate: results?.at(-1)?.date,
        });
    }),

    on(LabValuesAction.upsertLabValues, (state, { data }) => {
        const date = startOfMinute(data?.at(0)?.date).toISOString();
        return labValuesAdapter.upsertMany(data, {
            ...state,
            isLoading: false,
        });
    }),

    on(LabValuesAction.removeLabValues, (state, { ids }) =>
        labValuesAdapter.removeMany(ids, { ...state, isLoading: false }),
    ),

    on(LabValuesAction.clearLabValues, state => labValuesAdapter.removeAll({ ...state, startDate: null })),

    on(LabValuesAction.addChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = labValuesAdapter.removeMany(toRemoveIds, state);

        return labValuesAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
