/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

/**
 * Directive to handle clicks outside NgZone
 *
 * @tutorial https://indepth.dev/posts/1434/running-event-listeners-outside-of-the-ngzone
 */
@Directive({
    selector: '[click.zoneless]',
})
export class UiClickZonelessDirective implements OnInit, OnDestroy {
    /** Emits click events */
    @Output('click.zoneless') clickZoneless = new EventEmitter<MouseEvent>();

    /** Listener holder */
    private teardownLogicFn: AnyFn;
    /**
     * Constructor
     *
     * @param  ngZone NgZone
     * @param  elRef ElementRef
     * @param  renderer Renderer2
     */
    constructor(private ngZone: NgZone, private elRef: ElementRef, private renderer: Renderer2) {}

    /** Lifecycle hook */
    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this.setupClickListener();
        });
    }

    /** Lifecycle hook */
    ngOnDestroy() {
        this.teardownLogicFn?.();
    }

    /** Setup click listener */
    private setupClickListener() {
        this.teardownLogicFn = this.renderer.listen(this.elRef.nativeElement, 'click', (event: MouseEvent) => {
            this.clickZoneless.emit(event);
        });
    }
}
