import { ApiModels } from '@mona/api';
import { RelationshipRoleType } from '@mona/models';

/**
 * Transforms api vaccine code
 *
 * @param apiRelationshipRoleType
 */
export const transformApiRelationshipRoleType = (
    apiRelationshipRoleType: ApiModels.RelationshipRoleType,
): RelationshipRoleType => {
    return ApiModels.RelationshipRoleType.DTO.toModel(apiRelationshipRoleType);
};

/**
 * Transform api vaccine codes
 *
 * @param apiRelationshipRoleTypes
 */
export const transformApiRelationshipRoleTypes = (
    apiRelationshipRoleTypes: ApiModels.RelationshipRoleType[],
): RelationshipRoleType[] => {
    return apiRelationshipRoleTypes.map(apiRelationshipRoleType =>
        transformApiRelationshipRoleType(apiRelationshipRoleType),
    );
};
