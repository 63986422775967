import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '../../models';
import * as fromAuth from '../reducers';

export const selectAuthState = createFeatureSelector<AuthState>(fromAuth.authFeatureKey);

export const selectIsLoading = createSelector(selectAuthState, x => x.isLoading);
export const selectError = createSelector(selectAuthState, x => x.error);
export const selectUser = createSelector(selectAuthState, (state: AuthState) => state.user);
export const selectIsAuthenticated = createSelector(selectAuthState, (state: AuthState) => state.isAuthenticated);
export const selectActivatedRFIDCard = createSelector(selectAuthState, (state: AuthState) => state.activatedRFIDCard);
export const selectUserRfid = createSelector(selectAuthState, (state: AuthState) => {
    return state.user?.rfid;
});
export const selectLastRfid = createSelector(selectAuthState, (state: AuthState) => {
    return state.rfid;
});
