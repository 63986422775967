import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeLogEntry, DailyGoal } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { applyLastChangedByToPrescriptions, withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { DailyGoalsApi } from '../../infrastructure/daily-goals.api';
import { DailyGoalsActions } from '../actions/daily-goals.actions';
import { selectDailyGoalAll } from '../selectors/daily-goals.selectors';

/**
 * Notifications effects
 */
@Injectable()
export class DailyGoalsEffects {
    changeLogMap$ = this.store.select(ChangeLogSelectors.getChangesMap);
    dailyGoalsAll$ = this.store.select(selectDailyGoalAll);

    /** Load Daily Goals */
    loadDailyGoals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DailyGoalsActions.loadDailyGoals),
            withCurrentEncounterId(),
            concatMap(([, encounterId]) => this.dailyGoalsApiService.getDailyGoals(encounterId)),
            withLatestFrom(this.changeLogMap$, this.practitionersFacade.practitionersMap$),
            map(([data, changesMap, practitionersMap]) => {
                const changes = changesMap['DailyGoal'] || [];

                if (changes.length) {
                    data = applyInstancesChanges(data, changes) as DailyGoal[];
                }

                data = applyLastChangedByToPrescriptions(data, practitionersMap);
                return DailyGoalsActions.loadDailyGoalsSuccess({ data });
            }),
            catchError(() => EMPTY),
        ),
    );

    /** Listen change save success & upsert entity + show message */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(this.dailyGoalsAll$, this.changeLogMap$, this.practitionersFacade.practitionersMap$),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes: ChangeLogEntry<DailyGoal>[] = changesMap['DailyGoal'] || [];

                    if (changes.length) {
                        let dailyGoals = applyInstancesChanges(data, changes);
                        dailyGoals = applyLastChangedByToPrescriptions(dailyGoals, practitionersMap);

                        this.store.dispatch(DailyGoalsActions.upsertDailyGoals({ data: dailyGoals }));
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(this.dailyGoalsAll$, this.changeLogMap$, this.practitionersFacade.practitionersMap$),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes = changesMap['DailyGoal'] || [];

                    if (changes.length) {
                        const removeUpdateData = getPersistedChangesData(data, changes);
                        removeUpdateData.toUpdate = applyLastChangedByToPrescriptions(
                            removeUpdateData.toUpdate,
                            practitionersMap,
                        );

                        this.store.dispatch(
                            DailyGoalsActions.addDailyGoalsChanges({
                                toUpdateEntities: removeUpdateData.toUpdate || [],
                                toRemoveIds: removeUpdateData.toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store
     * @param actions$
     * @param dailyGoalsApiService
     * @param practitionersFacade
     */
    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private dailyGoalsApiService: DailyGoalsApi,
        private practitionersFacade: PractitionersFacade,
    ) {}
}
