import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '@mona/api';
import { Encounter, ExternalResource } from '@mona/models';

/**
 * INFO: DailyGoalsApi
 */
@Injectable({ providedIn: 'root' })
export class ExternalResourcesApi {
    private readonly apiBase = '/api/external-links';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Loads daily goals
     * @param encounter
     */
    getExternalResources(encounter: Encounter): Observable<ExternalResource[]> {
        return this.http
            .get<ExternalResource[]>(this.apiBase, { params: { encounter_id: encounter.id } })
            .pipe(map(data => this.convertObjectToParameters(data, encounter)));
    }

    private convertObjectToParameters(data: ExternalResource[], encounter: Encounter): ExternalResource[] {
        const encounterData = {
            patientID: encounter.patient.id,
            patientNumber: encounter.patient.patientNumber,
            caseID: encounter.caseId,
            gender: encounter.patient.gender,
            firstName: encounter.patient.firstName,
            lastName: encounter.patient.lastName,
            wardId: encounter.wardId,
            bedId: encounter.bedId,
            dateOfBirth: encounter.patient.dateOfBirth,
            hospitalCaseId: encounter.hospitalCaseId,
            parentEncounterId: (encounter.patient as any)?.parentEncounterId, // FIXME: openapi
        };

        return data.map(item => {
            const queryParams = item.parameters ? '?' + new URLSearchParams(item.parameters).toString() : '';
            let changedUrl = item.url;

            Object.keys(encounterData).forEach(key => {
                changedUrl = changedUrl.replace(`{{${key}}}`, encounterData[key]);
            });

            return { ...item, url: changedUrl + queryParams };
        });
    }
}
