import { _Left, _Top } from '@angular/cdk/scrolling';
import { Inject, Injectable } from '@angular/core';
import { ANIMATION_FRAME, PERFORMANCE } from '@ng-web-apis/common';
import { defer, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { clamp, easeInOutQuad } from '@mona/shared/utils';

const SCROLL_TIME = 500;
/**
 * ScrollService Scroll Options
 */
export type SimpleScrollToOptions = _Top &
    _Left & {
        /** duration miliseconds */
        duration?: number;
    };

/**
 * A service for smooth scroll
 *
 * @example```ts
 * 	constructor(@Inject(ScrollService) private scrollService: ScrollService) { }
 *  ...
 *  this.scrollService.scroll$( this.elementRef.nativeElement, { left: 500 } ).subscribe();
 * ...
 * ```
 */
@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    /**
     * INFO: add comment
     *
     * @param performanceRef
     * @param animationFrame$
     */
    constructor(
        @Inject(PERFORMANCE) private readonly performanceRef: Performance,
        @Inject(ANIMATION_FRAME) private readonly animationFrame$: Observable<number>,
    ) {}
    /**
     * Target an Element to scroll to.
     *
     * @param element
     */
    scrollTo(
        element: Element,
        { top = element.scrollTop, left = element.scrollLeft, duration = SCROLL_TIME }: SimpleScrollToOptions,
    ): Observable<[number, number]> {
        if (duration < 0 || isNaN(top) || isNaN(left) || left < 0) {
            throw new Error('invalid arguments for scroll');
        }

        const initialTop = element.scrollTop;
        const initialLeft = element.scrollLeft;
        const deltaTop = top - initialTop;
        const deltaLeft = left - initialLeft;
        const observable = !duration
            ? of([top, left] as [number, number])
            : defer(() => of(this.performanceRef.now())).pipe(
                  switchMap(start => this.animationFrame$.pipe(map(now => now - start))),
                  takeUntil(timer(duration)),
                  map(elapsed => easeInOutQuad(clamp(elapsed / duration, 0, 1))),
                  map(
                      percent =>
                          [initialTop + deltaTop * percent, initialLeft + deltaLeft * percent] as [number, number],
                  ),
              );

        return observable.pipe(
            tap(([scrollTop, scrollLeft]) => {
                element.scrollTop = scrollTop;
                element.scrollLeft = scrollLeft;
            }),
        );
    }
}

/**
 * Pipe to filter scroll event stream by direction
 *
 * @param property string
 */
export function filterScrolledByDirection<T extends Event>(property: 'scrollLeft' | 'scrollTop') {
    let event: Event;
    return (source: Observable<T>): Observable<Event> =>
        source.pipe(
            tap(e => (event = e)),
            map(e => e.target[property]),
            startWith(null),
            pairwise(),
            filter(([prev, curr]) => curr >= 0 && prev !== curr),
            // debug('filterScrolledByDirection'),
            map(() => event),
            distinctUntilChanged(),
        );
}
