/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { BaseMedication } from '@mona/models';

export const loadStandardMedications = createAction('[StandardMedication/API] Load StandardMedications');

export const loadStandardMedicationsSuccess = createAction(
    '[StandardMedication/API] Load StandardMedications Success',
    props<{ standardMedications: BaseMedication[] }>(),
);

export const createStandardMedication = createAction(
    '[StandardMedication/API] Add StandardMedication',
    props<{ payload: BaseMedication & any }>(),
);

export const createStandardMedicationSuccess = createAction(
    '[StandardMedication/API] Add StandardMedication Success',
    props<{ standardMedication: BaseMedication }>(),
);

export const upsertStandardMedication = createAction(
    '[StandardMedication/API] Upsert StandardMedication',
    props<{ standardMedication: BaseMedication }>(),
);

export const upsertStandardMedications = createAction(
    '[StandardMedication/API] Upsert StandardMedications',
    props<{ standardMedications: BaseMedication[] }>(),
);

export const updateStandardMedication = createAction(
    '[StandardMedication/API] Update StandardMedication',
    props<{ update: Update<BaseMedication> }>(),
);

export const updateStandardMedications = createAction(
    '[StandardMedication/API] Update StandardMedications',
    props<{ update: Update<BaseMedication>[] }>(),
);

export const removeStandardMedication = createAction(
    '[StandardMedication/API] Delete StandardMedication',
    props<{ id: string }>(),
);

export const removeStandardMedicationSuccess = createAction(
    '[StandardMedication/API] Delete StandardMedication Success',
    props<{ id: string }>(),
);

export const removeStandardMedicationCancel = createAction('[StandardMedication/API] Delete StandardMedication Cancel');

export const removeStandardMedications = createAction(
    '[StandardMedication/API] Delete StandardMedications',
    props<{ ids: string[] }>(),
);

export const clearStandardMedications = createAction('[StandardMedication/API] Clear StandardMedications');

export const selectStandardMedication = createAction(
    '[StandardMedication/API] Select StandardMedication',
    props<{ id: string }>(),
);

export const failedStandardMedication = createAction(
    '[StandardMedication/API] Failed StandardMedication Operation',
    props<{ error: any }>(),
);
