import { ApplicationRef, Injectable, InjectionToken, isDevMode, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@environment';
import { BehaviorSubject, concat, EMPTY, from, interval, NEVER, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { IpcMainEvent } from '@mona/events';
import { MonaRpcService } from '@mona/rpc';
import { Logger } from '@mona/shared/logger';
import { takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';

/**
 * Updates Check Service
 */
export interface UpdatesService {
    /**
     * Is update service enabled
     */
    isEnabled: boolean;
    /**
     * Update available
     */
    readonly updateAvailable$: Readonly<Observable<any>>;
    /**
     * Update ready
     */
    readonly updateReady$: Readonly<Observable<any>>;
    /**
     * Update activated
     */
    readonly updateActivated$: Readonly<Observable<any>>;
    /**
     * Init periodical update check
     */
    init(): void;
    /**
     * Activate update
     */
    activateUpdate(): void;
    /**
     * Check for update
     */
    checkForUpdate(): void;
}
/** Updates Check Service Token */
export const UPDATER = new InjectionToken<UpdatesService>('Updates Check Service');

/**
 * Web UpdatesService
 *
 * @tutorial https://github.com/angular/angular/blob/3001716a2f/aio/src/app/sw-updates/sw-updates.service.ts
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 8 hours.
 * 3. Whenever an update is available, it activates the update.
 * @property
 * `updateActivated` {Observable<string>} - Emit the version hash whenever an update is activated.
 */
@TakeUntilDestroy
@Injectable({ providedIn: 'root' })
export class WebUpdatesService implements UpdatesService, OnDestroy {
    isEnabled = this.swu.isEnabled;
    private checkInterval = environment.updateCheckTimeout || 8 * 60 * 60 * 1000; // 8 hours;
    updateAvailable$: Observable<{ hash: string; appData?: any }>;
    updateReady$: Observable<{ hash: string; appData?: any }>;
    updateActivated$: Observable<{ hash: string; appData?: { versionMessage?: string } }>;

    private readonly logger = new Logger();

    /**
     * Creates an instance of WebUpdatesService.
     *
     * @param {ApplicationRef} appRef
     * @param {ConfigService} config
     * @param {SwUpdate} swu
     */
    constructor(private appRef: ApplicationRef, private config: ConfigService, private swu: SwUpdate) {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function, jsdoc/require-jsdoc
    ngOnDestroy(): void {}

    /**
     * Periodically check for updates (after the app is stabilized).
     */
    init() {
        if (isDevMode()) {
            this.updateAvailable$ = of(true) as any;
            this.updateReady$ = of(false) as any;
            this.updateActivated$ = of(false) as any;
            return;
        } else if (!this.isEnabled) {
            this.updateAvailable$ = NEVER.pipe(takeUntilDestroy(this));
            this.updateReady$ = NEVER.pipe(takeUntilDestroy(this));
            this.updateActivated$ = NEVER.pipe(takeUntilDestroy(this));
            return;
        }
        // Allow the app to stabilize first, before starting polling for updates with `interval()`
        const appIsStable = this.appRef.isStable.pipe(first(v => v));
        concat(appIsStable, interval(this.checkInterval))
            .pipe(
                tap(() => this.logger.log(`UPDATER: Checking for update...`)),
                takeUntilDestroy(this),
            )
            .subscribe(() => this.checkForUpdate());

        // Activate available updates.
        this.updateAvailable$ = this.swu.available.pipe(
            tap(evt => this.logger.log(`UPDATER: Update available: ${JSON.stringify(evt)}`)),
            tap(() => this.activateUpdate()),
            map(evt => evt.current),
            takeUntilDestroy(this),
        );
        this.updateReady$ = this.updateAvailable$;

        // Notify about activated updates.
        this.updateActivated$ = this.swu.activated.pipe(
            tap(evt => this.logger.log(`UPDATER: Update activated: ${JSON.stringify(evt)}`)),
            map(evt => evt.current),
            takeUntilDestroy(this),
        );
    }

    /**
     * Activate update
     */
    activateUpdate() {
        this.swu.activateUpdate();
    }

    /**
     * Check for update
     */
    checkForUpdate() {
        this.swu.checkForUpdate();
    }
}

/**
 * Mender UpdatesService
 *
 * @description
 * 1. Checks for available Mender updates once instantiated.
 * 2. Re-checks every minute.
 * 3. Whenever an update is available, ascks to activate the update.
 * @property
 * `updateActivated` {Observable<string>} - Emit the version hash whenever an update is activated.
 */
@TakeUntilDestroy
@Injectable({ providedIn: 'root' })
export class MenderUpdatesService implements UpdatesService {
    isEnabled = false;
    private checkInterval = environment.updateCheckTimeout || 60 * 1000; // 1 minute
    readonly updateAvailable$ = new BehaviorSubject<boolean>(false);
    readonly updateReady$ = new BehaviorSubject<boolean>(false);
    readonly updateActivated$ = new BehaviorSubject<boolean>(false);

    private readonly logger = new Logger();

    /**
     * Constructor
     *
     * @param appRef
     * @param config
     * @param rpcService
     */
    constructor(private appRef: ApplicationRef, private config: ConfigService, private rpcService: MonaRpcService) {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function, jsdoc/require-jsdoc
    ngOnDestroy(): void {}

    /**
     * Periodically check for updates (after the app is stabilized).
     */
    init() {
        if (!this.isEnabled) {
            return;
        }
        // Allow the app to stabilize first, before starting polling for updates with `interval()`
        interval(this.checkInterval)
            .pipe(
                tap(() => this.logger.log(`UPDATER: Checking for update...`)),
                switchMap(() => {
                    /* prettier-ignore */
                    return from(this.rpcService.invokeWithCustomErrors<boolean>(IpcMainEvent.CHECK_FOR_UPDATE_AVAILABLE),).pipe(tap((u) => {
                        this.updateAvailable$.next(u)
                        }));
                }),
                switchMap(() => {
                    /* prettier-ignore */
                    return from(this.rpcService.invokeWithCustomErrors<boolean>(IpcMainEvent.CHECK_FOR_UPDATE_READY)).pipe(tap((u) => {
                        this.updateReady$.next(u)
                        }));
                }),
                catchError(err => {
                    this.logger.error(`UPDATER:`, err);
                    return EMPTY;
                }),
                takeUntilDestroy(this),
            )
            .subscribe();
    }

    /**
     * Activate update
     */
    activateUpdate() {
        this.updateActivated$.next(true);
        this.updateActivated$.complete();
        this.rpcService.send(IpcMainEvent.REBOOT);
    }

    /**
     * Check for update
     */
    checkForUpdate() {
        if (!this.isEnabled) {
            return;
        }
        this.rpcService.invokeWithCustomErrors<boolean>(IpcMainEvent.CHECK_FOR_UPDATE);
    }
}
