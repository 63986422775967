import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { VaccinationStatus } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { VaccinationStatusState } from '../entities';
import {
    VaccinationsStatusAction,
    selectVaccinationAll,
    selectVaccinationsEntities,
    selectVaccinationsIsLoading,
} from '../state';

/**
 * VaccinationStatusFacade
 */
@Injectable({ providedIn: 'root' })
export class VaccinationStatusFacade {
    /** Get Vaccination Status */
    readonly vaccinations$: Observable<VaccinationStatus[]> = this.store.select(selectVaccinationAll);

    /** vaccinations map */
    readonly vaccinationsMap$: Observable<Record<string, VaccinationStatus>> =
        this.store.select(selectVaccinationsEntities);

    /** is Vaccinations data loading */
    readonly isLoading$ = this.store.select(selectVaccinationsIsLoading);

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService
     */
    constructor(private store: Store<VaccinationStatusState>, private changeLogService: ChangeLogService) {}

    /** load vaccinations data */
    loadVaccinationsData(): void {
        this.store.dispatch(VaccinationsStatusAction.loadVaccinations());
    }

    /**
     * INFO: add comment
     * @param vaccination
     */
    createVaccination(vaccination: Partial<VaccinationStatus>): void {
        this.changeLogService.createVaccination(vaccination);
    }

    /**
     * INFO: add comment
     * @param vaccination
     */
    updateVaccination(vaccination: Partial<VaccinationStatus>): void {
        this.changeLogService.updateVaccination(vaccination);
    }

    /**
     * INFO: add comment
     * @param id
     */
    removeVaccination(id: string): void {
        this.changeLogService.removeVaccination(id);
    }

    /**
     * Clear vaccination store
     */
    clearVaccination(): void {
        this.store.dispatch(VaccinationsStatusAction.clearVaccinations());
    }
}
