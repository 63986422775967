import { ApiModels } from '@mona/api';
import { BloodAdministrationType } from '@mona/models';

/**
 * Transforms api blood administrations
 *
 * @param apiBloodAdministration ApiModels.BloodAdministration
 */
export const transformApiBloodAdministration = (
    apiBloodAdministration: ApiModels.BloodAdministration,
): BloodAdministrationType => {
    return {
        id: apiBloodAdministration.code as string,
        code: apiBloodAdministration.code as string,
        displayName: apiBloodAdministration.display_name as string,
        reason: apiBloodAdministration.reason as string,
    };
};

/**
 * Transform api blood administrations
 *
 * @param apiBloodAdministrations ApiModels.BloodAdministration[]
 */
export const transformApiBloodAdministrations = (
    apiBloodAdministrations: ApiModels.BloodAdministration[],
): BloodAdministrationType[] => {
    return apiBloodAdministrations.map(apiBloodAdministration =>
        transformApiBloodAdministration(apiBloodAdministration),
    );
};
