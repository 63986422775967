import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Encounter, Valuables } from '@mona/models';
import { transformApiValuables } from '@mona/pdms/data-access-encounter-data';

/**
 * API abstraction layer for the valuables status API
 */
@Injectable({
    providedIn: 'root',
})
export class ValuablesApi {
    apiBase = '/pdms/valuables/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get valuables
     *
     * @param encounterId EntityId<Encounter>
     */
    getValuables(encounterId?: EntityId<Encounter>): Observable<Valuables[]> {
        const params: { [param: string]: string | string[] } = {};

        if (encounterId) {
            params.encounter_id = `${encounterId}`;
        }

        return this.http
            .get<ApiModels.Valuables[]>(this.apiBase, { params })
            .pipe(map(valuables => transformApiValuables(valuables)));
    }
}
