import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Patient } from '@mona/models';
import { transformApiPatients } from './transforms';

/**
 * API abstraction layer for the patients API
 */
@Injectable({
    providedIn: 'root',
})
export class PatientsAdmissionApi {
    apiPatientSearch = '/pdms/patient-search';
    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get patients for admission
     * @param ward_id
     */
    getPatientsForAdmission(ward_id: string): Observable<Patient[]> {
        return this.http
            .get<ApiModels.PatientSearch[]>(this.apiPatientSearch, { params: { ward_id } })
            .pipe(map(apiPatients => transformApiPatients(apiPatients)));
    }
}
