import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { PreMedicationsApi } from '../../infrastructure';
import { PreMedicationsAction } from '../actions';

/**
 * Pre medications effects
 */
@Injectable()
export class PreMedicationsEffects {
    /* Effect Declarations */

    /**
     * Load pre medications effect
     */

    loadPreMedications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PreMedicationsAction.loadPreMedicationsAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.preMedicationsApi.getPreMedications(action.encounterId),
                    PreMedicationsAction.loadPreMedicationsAction,
                ),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param preMedicationsApi PreMedicationsApi
     */
    constructor(private actions$: Actions, private preMedicationsApi: PreMedicationsApi) {}
}
