import { ApiModels } from '@mona/api';
import { BasicCareProcedureType } from '@mona/models';

/**
 * Transforms api basic care procedure type
 * @param apiBasicCareProcedureType ApiModels.BasicCareProcedure
 */
export const transformApiBasicCareProcedureType = (
    apiBasicCareProcedureType: ApiModels.BasicCareProcedure,
): BasicCareProcedureType => {
    return {
        id: apiBasicCareProcedureType.code,
        code: apiBasicCareProcedureType.code,
        displayName: apiBasicCareProcedureType.display_name,
        isGroup: apiBasicCareProcedureType.is_group,
        parent: apiBasicCareProcedureType.parent,
        durationSuggestedMinutes: apiBasicCareProcedureType.duration_suggested_minutes,
        alwaysVisible: apiBasicCareProcedureType.always_visible,
    };
};

/**
 * Transform api basic care procedure types
 * @param apiBasicCareProcedureTypes ApiModels.BasicCareProcedure[]
 */
export const transformApiBasicCareProcedureTypes = (
    apiBasicCareProcedureTypes: ApiModels.BasicCareProcedure[],
): BasicCareProcedureType[] => {
    return apiBasicCareProcedureTypes.map(apiBasicCareProcedureType =>
        transformApiBasicCareProcedureType(apiBasicCareProcedureType),
    );
};
