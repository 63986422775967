// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { AdmissionAllergyIntoleranceRequest } from './admissionAllergyIntoleranceRequest';
import { AdmissionAnamnesisRequest } from './admissionAnamnesisRequest';
import { AdmissionPhysicalExaminationRequest } from './admissionPhysicalExaminationRequest';
import { AdmissionPreMedicationRequest } from './admissionPreMedicationRequest';
import { AdmissionDiagnosisRequest } from './admissionDiagnosisRequest';
import { AdmissionVitalSignsRequest } from './admissionVitalSignsRequest';

/**
 * Serializer for validating patient admission data.
 * @see #/components/schemas/PatientAdmissionUpdateRequest - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PatientAdmissionUpdateRequest {
    /**
     * The RFID to authenticate the user.
     *
     */
    rfid: string;
    /**
     * Bed unique id.
     * @dataFormat uuid
     */
    bed_id?: string;
    /**
     * The identifying description of the patient\'s reason for the encounter.
     *
     */
    admission_diagnosis: string;
    /**
     * Vital signs payload used for admission.
     *
     */
    vital_signs?: AdmissionVitalSignsRequest | null;
    /**
     * Anamnesis payload for admission.
     *
     */
    anamnesis?: AdmissionAnamnesisRequest | null;
    /**
     * Physical examination payload for admission.
     *
     */
    physical_examination?: AdmissionPhysicalExaminationRequest | null;
    /**
     * Diagnosis payload for admission.
     *
     */
    diagnoses?: Array<AdmissionDiagnosisRequest>;
    /**
     * AllergyIntolerance for Admission
     *
     */
    allergies_intolerances?: Array<AdmissionAllergyIntoleranceRequest>;
    /**
     * PreMedication for Admission
     *
     */
    pre_medication?: Array<AdmissionPreMedicationRequest>;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PatientAdmissionUpdateRequest
 */
export namespace PatientAdmissionUpdateRequest {
    /**
     * PatientAdmissionUpdateRequest Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The RFID to authenticate the user.
         * @type string (`dataFormat` is missing)
         */
        rfid: string;
        /**
         * Bed unique id.
         * @dataFormat uuid
         */
        bedId?: string;
        /**
         * The identifying description of the patient\'s reason for the encounter.
         * @type string (`dataFormat` is missing)
         */
        admissionDiagnosis: string;
        /**
         * Vital signs payload used for admission.
         * @type AdmissionVitalSignsRequest (`dataFormat` is missing)
         */
        @Type(() => AdmissionVitalSignsRequest.Model) vitalSigns?: AdmissionVitalSignsRequest.Model | null;
        /**
         * Anamnesis payload for admission.
         * @type AdmissionAnamnesisRequest (`dataFormat` is missing)
         */
        @Type(() => AdmissionAnamnesisRequest.Model) anamnesis?: AdmissionAnamnesisRequest.Model | null;
        /**
         * Physical examination payload for admission.
         * @type AdmissionPhysicalExaminationRequest (`dataFormat` is missing)
         */
        @Type(() => AdmissionPhysicalExaminationRequest.Model)
        physicalExamination?: AdmissionPhysicalExaminationRequest.Model | null;
        /**
         * Diagnosis payload for admission.
         * @type Array<AdmissionDiagnosisRequest> (`dataFormat` is missing)
         */
        @Type(() => AdmissionDiagnosisRequest.Model) diagnoses?: Array<AdmissionDiagnosisRequest.Model>;
        /**
         * AllergyIntolerance for Admission
         * @type Array<AdmissionAllergyIntoleranceRequest> (`dataFormat` is missing)
         */
        @Type(() => AdmissionAllergyIntoleranceRequest.Model)
        allergiesIntolerances?: Array<AdmissionAllergyIntoleranceRequest.Model>;
        /**
         * PreMedication for Admission
         * @type Array<AdmissionPreMedicationRequest> (`dataFormat` is missing)
         */
        @Type(() => AdmissionPreMedicationRequest.Model) preMedication?: Array<AdmissionPreMedicationRequest.Model>;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PatientAdmissionUpdateRequest DTO
     * Transforms PatientAdmissionUpdateRequest model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PatientAdmissionUpdateRequest model */
        static toModel(obj: PatientAdmissionUpdateRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PatientAdmissionUpdateRequest model */
        static toApi(obj: Model): PatientAdmissionUpdateRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PatientAdmissionUpdateRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
