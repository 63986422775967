<div
    cdkDrag
    cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    [cdkDragDisabled]="!isDraggable"
    [cdkDragBoundary]="'body'"
    id="ui-popover"
    class="ui-popover"
>
    <ng-container [ngSwitch]="ngRenderMethod">
        <div *ngSwitchCase="'text'" [innerHTML]="content"></div>

        <ng-container *ngSwitchCase="'template'">
            <ng-container *ngTemplateOutlet="$any(content); context: { $implicit: context }"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'component'">
            <ng-container *ngComponentOutlet="$any(content); context: { $implicit: context }"></ng-container>
        </ng-container>
    </ng-container>
</div>
