import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
    APP_INITIALIZER,
    InjectionToken,
    LOCALE_ID,
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    FakeMissingTranslationHandler,
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { Logger } from '@mona/shared/logger';
import { deepCompactObject } from '@mona/shared/utils';
import i18n_CONFIG from './assets/i18n-config.json';
import { DynamicLocaleId, localesInitializerFactory } from './dynamic-localeId.helper';

export const i18n_ASSETS_PATH = new InjectionToken('i18nAssetsPath');
export const i18n_LOCALE_IDS: InjectionToken<string[]> = new InjectionToken('i18nLocaleIds');
export const i18n_KEYBOARD_LOCALE_IDS: InjectionToken<string[]> = new InjectionToken('i18nKeyboardLocaleIds');

type Translatable = { [key: string]: any };

/**
 * Custom loader for the translations.
 *
 * Removes empty nested values to properly fallback to default language
 */
export class CustomTranslateHttpLoader extends TranslateHttpLoader {
    private readonly logger = new Logger('I18N');
    /** @ignore */
    constructor(http: HttpClient, prefix?: string, suffix?: string) {
        super(http, prefix, suffix);
    }

    /** @ignore */
    getTranslation(lang: string): Observable<any> {
        return super.getTranslation(lang).pipe(
            map((res: Translatable) => deepCompactObject(res)),
            finalize(() => this.logger.debug(`Loaded translations for "${lang}"`)),
        );
    }
}

/**
 * A class that handles missing translations.
 */
export class CustomMissingTranslationHandler extends FakeMissingTranslationHandler {
    private readonly logger = new Logger('I18N');
    /**
     * A function that handles missing translations.
     *
     * @param params context for resolving a missing translation
     * @returns a value or an observable
     * If it returns a value, then this value is used.
     * If it return an observable, the value returned by this observable will be used (except if the method was "instant").
     * If it doesn't return then the key will be used as a value
     */
    handle(params: MissingTranslationHandlerParams) {
        // TODO // this.logger.warn(`i18n: Missing translation detected for key: info"${params.key}"`);
        return super.handle(params);
    }
}

/**
 * Root i18n Module
 */
@NgModule({
    imports: [HttpClientModule],
})
export class i18nModule {
    /**
     * static forRoot
     *
     * @param path
     */
    static forRoot(path = 'assets/i18n/'): ModuleWithProviders<i18nModule> {
        const { defaultLocaleId, availableLocaleIds, availableKeyboardLocaleIds } = i18n_CONFIG;

        return {
            ngModule: i18nModule,
            providers: [
                { provide: i18n_ASSETS_PATH, useValue: path },
                ...TranslateModule.forRoot({
                    defaultLanguage: defaultLocaleId,
                    loader: {
                        provide: TranslateLoader,
                        useClass: CustomTranslateHttpLoader,
                        deps: [HttpClient, i18n_ASSETS_PATH],
                    },
                    missingTranslationHandler: {
                        provide: MissingTranslationHandler,
                        useClass: CustomMissingTranslationHandler,
                        deps: [],
                    },
                    useDefaultLang: coerceBooleanProperty(localStorage?.['useDefaultFallbackLanguage'] ?? true), // changable in runtime
                }).providers,
                { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
                { provide: i18n_LOCALE_IDS, useValue: availableLocaleIds },
                { provide: i18n_KEYBOARD_LOCALE_IDS, useValue: availableKeyboardLocaleIds },
                {
                    provide: APP_INITIALIZER,
                    useFactory: localesInitializerFactory,
                    deps: [i18n_LOCALE_IDS],
                    multi: true,
                },
            ],
        };
    }

    /**
     * Constructor
     *
     * @param configService
     * @param translateService
     * @param parentModule
     */
    constructor(
        configService: ConfigService,
        translateService: TranslateService,
        @Optional() @SkipSelf() parentModule: i18nModule,
    ) {
        if (parentModule) {
            throw new Error('i18Module already loaded; import in root module only.');
        }

        configService.configLoaded$.subscribe(
            () => {
                translateService.use(configService.get('uiLanguage') || 'en-GB');
            },
            () => {
                translateService.use('en-GB');
            },
        );
    }
}
