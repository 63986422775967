/* eslint-disable jsdoc/require-jsdoc */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Encounter } from '@mona/models';
import { encountersFeatureKey, EncountersState } from '../../entities';
import { selectAll, selectEntities, selectIds } from '../reducers';

export const selectEncountersState = createFeatureSelector<EncountersState>(encountersFeatureKey);

export const selectEncountersIsLoading = createSelector(selectEncountersState, x => x.isLoading);

export const selectEncountersIds = createSelector(selectEncountersState, selectIds);

export const selectEncountersEntities = createSelector(selectEncountersState, selectEntities);

export const selectAllEncounters = createSelector(selectEncountersState, selectAll);

export const selectAllEncountersGroupedByBed = createSelector(selectAllEncounters, all =>
    all?.reduce((acc: { [bedId: string]: Encounter }, encounter) => {
        encounter.active && encounter?.bedId && (acc[encounter.bedId] = encounter);
        return acc;
    }, {}),
);

export const selectAssignedEncounterId = createSelector(selectEncountersState, s => s.assignedEncounterId);

export const selectSelectedEncounterId = createSelector(selectEncountersState, s => s.selectedId);

export const selectSelectedEncounter = createSelector(
    selectEncountersEntities,
    selectSelectedEncounterId,
    (all, encounterId) => all?.[encounterId],
);

export const selectEncounterById = encounterId => createSelector(selectEncountersEntities, all => all?.[encounterId]);

export const selectRelocateEncounterData = createSelector(selectEncountersState, x => x.relocateEncounterData);
