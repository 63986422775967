import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FloatLabelType, MatFormField, MatFormFieldAppearance } from '@angular/material/form-field';
import { compareDeepEqual, FilterableDataSource, isFunction } from '@mona/shared/utils';
import { emitSubmit } from '../../models';

/**
 * UiDynamicAutocomplete
 */
@Component({
    selector: 'ui-dynamic-autocomplete',
    styleUrls: ['./dynamic-autocomplete.component.scss'],
    templateUrl: './dynamic-autocomplete.component.html',
})
export class UiDynamicAutocompleteComponent {
    @ViewChild(MatFormField, { read: ElementRef, static: true }) elementRef: ElementRef;

    readonly form: UntypedFormGroup;

    control: UntypedFormControl & { elementRef: ElementRef } = new UntypedFormControl() as any;

    appearance: MatFormFieldAppearance;

    label = '';

    floatLabel: FloatLabelType = undefined;

    labelKey = 'label';

    labelTemplate: TemplateRef<any> = undefined;

    valueKey = 'value';

    hint = '';

    hintTemplate: TemplateRef<any> = undefined;

    addNewTemplate: TemplateRef<any> = undefined;

    type: string = undefined;

    required: boolean = undefined;

    hideResetBtn: boolean = undefined;

    name = '';

    dataSource: FilterableDataSource<any> = undefined;

    panelClass = '';

    allowNonExisting = false;

    compareFn = compareDeepEqual;

    errorMessageTemplate: TemplateRef<any> = undefined;

    hideValidationMessage: boolean = undefined;

    hideRequiredMarker: boolean = undefined;

    placeholder = '';

    customClass = '';

    lengthToTriggerSearch = 3;

    clearAfterSearch = undefined;

    clearWithConfirmation = false;

    submitFormOnValueChange: boolean = undefined;

    autocompleteOptionSelectedHandler: AnyFn;

    autocompleteOptionAddNewHandler: AnyFn;

    selectedOption = undefined;

    /**
     * Handle autocomplete option selected
     *
     * @param event
     * @param form
     */
    autocompleteOptionSelected(event, form) {
        this.selectedOption = event;
        if (isFunction(this.autocompleteOptionSelectedHandler)) {
            this.autocompleteOptionSelectedHandler(event, form);
        }
        if (this.submitFormOnValueChange) {
            emitSubmit(this.elementRef?.nativeElement);
        }
    }

    /**
     * Handle autocomplete option add new
     *
     * @param event
     * @param form
     */
    autocompleteOptionAddNew(event, form) {
        if (isFunction(this.autocompleteOptionSelectedHandler)) {
            this.autocompleteOptionSelectedHandler(event, form);
        }
    }
}
