import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SurgeryPrescription } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { SurgeryPrescriptionState } from '../entities';
import {
    SurgeryPrescriptionAction,
    selectSurgeryPrescriptionAll,
    selectSurgeryPrescriptionEntities,
    selectSurgeryPrescriptionIsLoading,
} from '../state';

/**
 * Surgery Prescription Facade
 */
@Injectable({ providedIn: 'root' })
export class SurgeryPrescriptionFacade {
    /** Get Surgery Prescription */
    readonly surgeryPrescription$: Observable<SurgeryPrescription[]> = this.store.select(selectSurgeryPrescriptionAll);

    /** Surgery Prescription map */
    readonly surgeryPrescriptionMap$: Observable<Record<string, SurgeryPrescription>> = this.store.select(
        selectSurgeryPrescriptionEntities,
    );

    /** is Surgery Prescription data loading */
    readonly isLoading$ = this.store.select(selectSurgeryPrescriptionIsLoading);

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService
     */
    constructor(private store: Store<SurgeryPrescriptionState>, private changeLogService: ChangeLogService) {}

    /** load Surgery Prescription data */
    loadSurgeryPrescriptionData(): void {
        this.store.dispatch(SurgeryPrescriptionAction.loadSurgeryPrescription());
    }

    /**
     * INFO: add comment
     * @param surgery
     */
    createSurgeryPrescription(surgery: Partial<SurgeryPrescription>): void {
        this.changeLogService.createSurgeryPrescription(surgery);
    }

    /**
     * INFO: add comment
     * @param surgery
     */
    updateSurgeryPrescription(surgery: Partial<SurgeryPrescription>): void {
        this.changeLogService.updateSurgeryPrescription(surgery);
    }

    /**
     * INFO: add comment
     * @param id
     */
    removeSurgeryPrescription(id: string): void {
        this.changeLogService.removeSurgeryPrescription(id);
    }

    /**
     * Clear surgery prescription store
     */
    clearSurgeryPrescription(): void {
        this.store.dispatch(SurgeryPrescriptionAction.clearSurgeryPrescription());
    }
}
