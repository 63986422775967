// start:ng42.barrel
import { EntityState } from '@ngrx/entity';
import { MedicationPrescription } from '@mona/models';
import { StandardMedicationstate } from './standard-medications.state';

export const medicationsFeatureKey = 'medicationsData';
export const medicationPrescriptionKey = 'medicationPrescriptions';
export const standardMedicationsFeatureKey = 'standardMedications';

/**
 * Medications Entity State
 */
export interface MedicationsPrescriptionState extends EntityState<MedicationPrescription> {
    /**
     * Error
     */
    error: any;
}

/**
 * Medications state
 */
export interface MedicationsState {
    /** Medication prescriptions */
    medicationPrescriptions: MedicationsPrescriptionState;
    /** Standard medication sets state */
    standardMedications: StandardMedicationstate; // TODO: refactor to ngrx entityadapter
}

export * from './standard-medications.state';
// end:ng42.barrel
