import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LOCALE_ID, ModuleWithProviders, NgModule, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDateFnsModule, DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
//
import { TranslateService } from '@ngx-translate/core';
import { de, enGB } from 'date-fns/locale';
import { DynamicDatepickerLocale } from './services';

/** Array of modules */
export const MATERIAL_MODULES = [
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSliderModule,
    //
    FlexLayoutModule,
    MatDateFnsModule,
];
/** Array of modules */
export const CDK_MODULES = [DragDropModule, ObserversModule, OverlayModule, PortalModule, ScrollingModule];
/** Wrapper Module for Angular Material */
@NgModule({
    // imports: [CDK_MODULES, MATERIAL_MODULES],
    exports: [CDK_MODULES, MATERIAL_MODULES],
})
export class MaterialModule {
    /** static method, to be used in SharedModule only */
    static forRoot(): ModuleWithProviders<MaterialModule> {
        return {
            ngModule: MaterialModule,
            providers: [
                // angular material
                {
                    provide: MAT_DATE_LOCALE,
                    useFactory: () => {
                        const localeId = inject(LOCALE_ID).toString();
                        switch (localeId) {
                            case 'de-DE':
                                return de;
                            case 'en-GB':
                            default:
                                return enGB;
                        }
                    },
                },
                {
                    provide: DateAdapter,
                    useClass: DateFnsAdapter,
                    deps: [MAT_DATE_LOCALE],
                },
                { provide: MAT_DATE_FORMATS, useClass: DynamicDatepickerLocale, deps: [TranslateService] },
                { provide: MAT_DATE_FNS_FORMATS, useClass: DynamicDatepickerLocale, deps: [TranslateService] },
                { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }, // standard, fill, outline
                { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000, horizontalPosition: 'end' } },
                { provide: MAT_TABS_CONFIG, useValue: { disablePagination: true } },
            ],
        };
    }
}
