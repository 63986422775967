import { Component, Input } from '@angular/core';

/**
 * The rfid dialog for write processes
 */
@Component({
    selector: 'mona-rfid-form',
    templateUrl: './rfid-form.component.html',
    styleUrls: ['./rfid-form.component.scss'],
})
export class RfidFormComponent {
    /**
     * Auth in progress
     */
    @Input() isLoading: boolean;
}
