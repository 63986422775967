import { createAction, props } from '@ngrx/store';
import { CallConfig } from '@mona/models';
import { AsyncAction, FailedAction, SucceededAction } from '@mona/store';

/**
 * Telemedicine Config actions
 */
export class TelemedicineConfigAction {
    /**
     * Get call config
     */
    static loadCallConfig: AsyncAction<void, CallConfig> = {
        action: createAction('TELEMEDICINE:LOAD_CALL_CONFIG'),

        succeededAction: createAction('TELEMEDICINE:LOAD_CALL_CONFIG_SUCCEEDED', props<SucceededAction<CallConfig>>()),

        failedAction: createAction('TELEMEDICINE:LOAD_CALL_CONFIG_FAILED', props<FailedAction>()),

        clearAction: createAction('TELEMEDICINE:LOAD_CALL_CONFIG_CLEAR'),
    };
}
