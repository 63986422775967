<span *ngIf="control?.hasError('required')">
    {{ 'formValidation.required' | translate }}
</span>
<span *ngIf="control?.hasError('pattern') || control?.hasError('mask')">
    {{ 'formValidation.pattern' | translate }}
</span>
<span *ngIf="control?.hasError('min') || control?.hasError('minlength')">
    <ng-container *ngIf="!enrichedTemplateForMinValue; else enrichedTemplateForMinValue">
        {{
            'formValidation.min'
                | translate: { minValue: control.errors['min']?.['min'] || control.errors['minlength']?.['requiredLength'] }
        }}
    </ng-container>
</span>
<span *ngIf="control?.hasError('max') || control?.hasError('maxlength')">
    <ng-container *ngIf="!enrichedTemplateForMaxValue; else enrichedTemplateForMaxValue">
        {{
            'formValidation.max'
                | translate: { maxValue: control.errors['max']?.['max'] || control.errors['maxlength']?.['requiredLength'] }
        }}
    </ng-container>
</span>
<span *ngIf="control?.hasError('existingPatient')">
    {{ 'formValidation.existingPatient' | translate }}
</span>
<span *ngIf="control?.hasError('patientIsAdmitted')">
    {{ 'formValidation.patientIsAdmitted' | translate }}
</span>
<span *ngIf="control?.hasError('requireMatch')">
    {{ 'formValidation.requireMatch' | translate }}
</span>
<span *ngIf="control?.hasError('minDate') || control?.hasError('matDatepickerMin')">
    {{ 'formValidation.minDate' | translate: { minValue: (control.errors['minDate']?.['min'] || control.errors['matDatepickerMin']?.['min']) } }}
</span>
<span *ngIf="control?.hasError('maxDate')">
    {{ 'formValidation.maxDate' | translate: { maxValue: control.errors['maxDate']?.['max'] } }}
</span>
<span *ngIf="control?.hasError('maxAfterDot')">
    {{ 'formValidation.maxAfterDot' | translate: { limit: control.errors['maxAfterDot']['limit'] } }}
</span>
<span *ngIf="control?.value?.toString() === 'Invalid Date'">
    {{ 'formValidation.checkCorrectTime' | translate }}
</span>
<span *ngIf="control?.hasError('dstEdge')">
    {{ 'formValidation.dstEdge' | translate }}
</span>
