import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Medication } from '@mona/models';
import { CustomValidators } from '@mona/shared/utils';

/** * Admission form for pre medications */
export interface PreMedicationsForm {
    /** Pre medications */
    items: FormArray<FormGroup<PreMedicationsItemForm>>;
}

/**
 * Represents an pre medication in the PreMedicationsForm
 */
export interface PreMedicationsItemForm {
    /**
     * Description
     */
    description: FormControl<string>;

    /**
     * Code
     */
    code: FormControl<string>;

    /**
     * Medication
     */
    medication: FormControl<Medication>;

    /**
     * Dose
     */
    dose: FormControl<number>;

    /**
     * Unit
     */
    unit: FormControl<string>;

    /**
     * Frequency
     */
    frequency: FormControl<string>;
}

/**
 * Build form group for PreMedicationsItemForm, exported for reuse
 *
 * @returns FormGroup
 */
export function buildPreMedicationFormGroup(): FormGroup<PreMedicationsItemForm> {
    const formGroup = new FormGroup<PreMedicationsItemForm & any>({
        description: new FormControl<string>('', [Validators.required]),
        code: new FormControl<string>(''),
        medication: new FormControl<Medication>(null, [Validators.required]),
        dose: new FormControl<number>(null, [
            Validators.required,
            Validators.min(0.01),
            Validators.max(999999.99),
            CustomValidators.decimal(),
        ]),
        unit: new FormControl<string>('', [Validators.required, Validators.pattern(/^[a-zA-Z]/)]),
        frequency: new FormControl<string>('', Validators.required),
    });
    return formGroup;
}
