import { Pipe, PipeTransform } from '@angular/core';
import { TableDataItem } from '@mona/models';

/**
 * Detects if cell has value
 */
@Pipe({
    name: 'hasCellValue',
})
export class HasCellValuePipe implements PipeTransform {
    /**
     * Pipe implementation
     *
     * @param cell TableDataItem
     * @param columnTime string (date iso string)
     */
    transform(cell: TableDataItem<any>, columnTime: string): boolean {
        return !!((cell.values && cell.values[columnTime]) || cell.children?.find(child => child.values[columnTime]));
    }
}
