/* eslint-disable jsdoc/require-jsdoc */
import { LoggerLevel } from '@mona/shared/logger';

/**
 * Message (or Snackbar) Status
 */
export class UiMessageStatus {
    /** Simple public message for end user. (what happened, what next) */
    text: string;
    /** Detailed private message to help with debugging */
    description?: string;
    /** Title of action to be performed on click */
    action?: string;
    /** Unique message code pinpoint to line of code */
    code?: string;
    /** Log level */
    level?: LoggerLevel;
    /** Color of message */
    color?: string;
    /** Log it to console */
    console?: boolean;
    /** Console logs only */
    consoleOnly?: boolean;
    /** Log it to remote server(s) */
    remote?: boolean;
}

export const UiMessageStatusDefault: UiMessageStatus = {
    text: null,
    description: null,
    code: null,
    level: 3,
    color: null,
    console: true,
    consoleOnly: false,
    remote: false,
};
