// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { PrescriptionSetMedicationStandardMedicationSet } from './prescriptionSetMedicationStandardMedicationSet';

/**
 * Serializer for PrescriptionSetMedicine Model Structure.
 * @see #/components/schemas/PrescriptionSetMedication - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PrescriptionSetMedication {
    /**
     * The Prescription Set Medication Id
     * @dataFormat uuid
     */
    readonly id: string;
    /**
     * Prescription Set ID
     * @dataFormat uuid
     */
    prescription_set_id: string;
    standard_medication_set: PrescriptionSetMedicationStandardMedicationSet;
    /**
     * The instructions for prescription set medication.
     *
     */
    instructions?: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PrescriptionSetMedication
 */
export namespace PrescriptionSetMedication {
    /**
     * PrescriptionSetMedication Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The Prescription Set Medication Id
         * @dataFormat uuid
         */
        readonly id: string;
        /**
         * Prescription Set ID
         * @dataFormat uuid
         */
        prescriptionSetId: string;
        @Type(() => PrescriptionSetMedicationStandardMedicationSet.Model)
        standardMedicationSet: PrescriptionSetMedicationStandardMedicationSet.Model;
        /**
         * The instructions for prescription set medication.
         * @type string (`dataFormat` is missing)
         */
        instructions?: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PrescriptionSetMedication DTO
     * Transforms PrescriptionSetMedication model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PrescriptionSetMedication model */
        static toModel(obj: PrescriptionSetMedication): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PrescriptionSetMedication model */
        static toApi(obj: Model): PrescriptionSetMedication {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PrescriptionSetMedication;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
