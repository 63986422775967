import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '@mona/shared/utils';
import { isConfigRequest } from '../helpers';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private appErrorHandler: ErrorHandler & { handleApiError?: AnyFn };
    /**
     * Constructor
     *
     * @param injector
     */
    constructor(private injector: Injector) {
        this.appErrorHandler = this.injector.get(ErrorHandler);
    }

    /**
     * Intercept
     *
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (isConfigRequest(request)) {
                    return throwError(err);
                }
                if (err instanceof HttpErrorResponse) {
                    const appError = new AppError(err);
                    this.appErrorHandler.handleApiError(appError);
                    return throwError(appError);
                }
                return throwError(err);
            }),
        );
    }
}
