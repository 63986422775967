import { Component, TemplateRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

/**
 * UiDynamicDatepickerComponent
 */
@Component({
    selector: 'ui-dynamic-datepicker',
    styleUrls: ['./dynamic-datepicker.component.scss'],
    templateUrl: './dynamic-datepicker.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class UiDynamicDatepickerComponent {
    control: UntypedFormControl = new UntypedFormControl();

    appearance: MatFormFieldAppearance = 'fill';

    label = '';

    valueKey = undefined;

    hint = '';

    name = '';

    type: string = undefined;

    required: boolean = undefined;

    min: Date = undefined;

    max: Date = undefined;

    errorMessageTemplate: TemplateRef<any> = undefined;

    placeholder = '';

    customClass = '';

    touchUi = false;
}
