/* eslint-disable @typescript-eslint/ban-types, jsdoc/require-jsdoc */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import type { MatFormFieldControl } from '@angular/material/form-field';
import type { Constructor } from 'type-fest';
import type { CanDisable, CVA, MatFormFieldControlEx } from '../models';

/**
 * Mixin to augment a component as {@link MatFormFieldControl}
 *
 * @param base
 */
export function mixinMatFormFieldControl<T extends Constructor<CVA & CanDisable>>(
    base: T,
): Constructor<MatFormFieldControlEx<any>> & T {
    return class extends base {
        static nextId = 0;

        id: string;
        controlType: string;

        _placeholder: string;
        _disabled = false;
        _required = false;
        _shouldLabelFloat = undefined;

        constructor(...args: any[]) {
            super(...args);
        }

        get required() {
            return this._required;
        }
        set required(req) {
            this._required = coerceBooleanProperty(req);
            this.stateChanges.next();
        }
        get placeholder() {
            return this._placeholder;
        }
        set placeholder(p: string) {
            this._placeholder = p;
            this.stateChanges.next();
        }
        get shouldLabelFloat() {
            return this._shouldLabelFloat;
        }
        set shouldLabelFloat(v: boolean) {
            this._shouldLabelFloat = v;
        }
        get empty(): boolean {
            return undefined;
        }
        get errorState(): boolean {
            return undefined;
        }

        focused = false;
        touched = false;

        onContainerClick(event: MouseEvent) {
            const attr = (event.target as Element).getAttribute('data-preventfocus');
            const preventfocus = coerceBooleanProperty(attr);
            if (!preventfocus) {
                this.elRef.nativeElement.querySelector('input')?.focus();
            }
        }

        onFocusIn(event: FocusEvent) {
            if (!this.focused) {
                this.focused = true;
                this.stateChanges.next();
            }
        }

        onFocusOut(event: FocusEvent) {
            if (!this.elRef.nativeElement.contains(event.relatedTarget as Element)) {
                this.touched = true;
                this.onTouched();
                this.focused = false;
                this.stateChanges.next();
            }
        }

        setDescribedByIds(ids: string[]) {
            ids?.length;
        }
    };
}
