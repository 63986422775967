import { WorkflowAnswer, WorkflowQuestion, WorkflowQuestionType } from '@mona/models';
import { isDate, isObject, isString } from '@mona/shared/utils';

/**
 * Get answers as values map
 *
 * @param answers
 * @param questions
 * @param questionTypes
 */
export function getAnswersAsValuesMap(
    answers: WorkflowAnswer[] = [],
    questions: WorkflowQuestion[] = [],
    questionTypes: WorkflowQuestionType[] = [],
): Map<string, WorkflowAnswer | WorkflowAnswer[]> {
    const answersAsValuesMap = answers.reduce((acc, { questionId, answer }) => {
        const { itemType } = questions.find(q => q.id === questionId);
        const { repeats } = questionTypes.find(qt => qt.name === itemType);
        if (repeats) {
            acc.set(questionId, (acc.get(questionId) || []).concat(answer));
        } else {
            acc.set(questionId, answer);
        }
        return acc;
    }, new Map());
    return answersAsValuesMap;
}

/**
 * Map form values to answers
 *
 * @param formValueMap
 */
export function mapFormValuesToAnswers(formValueMap: { [k: string]: any } = {}) {
    const newAnswers: WorkflowAnswer[] = [];
    formValueMap.forEach((value, questionId) => {
        if (Array.isArray(value)) {
            value.forEach(a => newAnswers.push({ answer: a, questionId }));
        } else if (isDate(value)) {
            newAnswers.push({
                answer: {
                    code: undefined,
                    display: value.toISOString(),
                },
                questionId,
            });
        } else if (isObject(value)) {
            newAnswers.push({
                answer: value as WorkflowAnswer['answer'],
                questionId,
            });
        } else if (isString(value)) {
            newAnswers.push({
                answer: {
                    code: undefined,
                    display: value,
                },
                questionId,
            });
        }
    });
    return newAnswers;
}
