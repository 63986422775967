import { createAction, props } from '@ngrx/store';
import { Ward } from '@mona/models';

/** Set selected ward id */
export const setAssignedWardId = createAction('WARDS:SET_ASSIGNED_WARD', props<{ wardId: EntityId<Ward> | null }>());
/**
 * Select ward action
 */
export const selectWard = createAction('WARDS:SELECT_WARD', props<{ wardId: EntityId<Ward> | null }>());
/**
 * Load action
 */
export const load = createAction('WARDS:LOAD_WARDS');

/**
 * Load success action
 */
export const loadSuccess = createAction('WARDS:LOAD_WARDS_SUCCEEDED', props<{ wards: Ward[] }>());

/**
 * Load failure action
 */
export const loadFailure = createAction('WARDS:LOAD_WARDS_FAILED', props<{ error: unknown }>());

/**
 * Clear action
 */
export const clear = createAction('WARDS:LOAD_WARDS_CLEAR');

/** Upsert ward action */
export const upsertWard = createAction('WARDS:UPSERT_WARD', props<{ ward: Ward }>());

/** Remove ward action */
export const removeWard = createAction('WARDS:REMOVE_WARD', props<{ ward: Ward }>());
