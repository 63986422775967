import { PractitionerRole } from '@mona/models';
import { User } from './user.model';

/**
 * If user has admin role
 *
 * @param user
 */
export function hasAdminRole(user: User) {
    return user?.role.some(role => role.toLowerCase() === PractitionerRole.Admin);
}
