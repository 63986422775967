/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { InfectionStatus } from '@mona/models';

export namespace InfectionStatusActions {
    export const loadInfectionStatus = createAction('ENCOUNTER:LOAD_INFECTION_STATUS');
    export const loadInfectionStatusSuccess = createAction(
        'ENCOUNTER:LOAD_INFECTION_STATUS_SUCCESS',
        props<{ data: InfectionStatus[] }>(),
    );
    export const loadInfectionStatusFailure = createAction(
        'ENCOUNTER:LOAD_INFECTION_STATUS_FAILURE',
        props<{ error: any }>(),
    );

    export const updateInfectionStatus = createAction(
        'ENCOUNTER:UPDATE_INFECTION_STATUS',
        props<{ update: Update<InfectionStatus>[] }>(),
    );

    export const upsertInfectionStatus = createAction(
        'ENCOUNTER:UPSERT_INFECTION_STATUS',
        props<{ data: InfectionStatus[] }>(),
    );

    export const clearInfectionStatus = createAction('ENCOUNTER:CLEAR_INFECTION_STATUS');

    export const addInfectionStatusChanges = createAction(
        'ENCOUNTER:ADD_CHANGES',
        props<{ toUpdateEntities: InfectionStatus[]; toRemoveIds: string[] }>(),
    );
}
