import { ApiModels } from '@mona/api';
import { InfectionStatus } from '@mona/models';

/**
 * Transforms API infection
 * @param apiInfection ApiModels.InfectionStatus
 */
export const transformApiInfection = (apiInfection: ApiModels.InfectionStatus): InfectionStatus => {
    return ApiModels.InfectionStatus.DTO.toModel(apiInfection);
};

/**
 * Transforms API infections
 * @param apiInfections ApiModels.InfectionStatus[]
 */
export const transformApiInfections = (apiInfections: ApiModels.InfectionStatus[]): InfectionStatus[] => {
    return apiInfections.map(apiInfection => transformApiInfection(apiInfection));
};
