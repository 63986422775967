// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.11
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-09-18T14:58:35.670970+03:00[Europe/Chisinau]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating InfectionStatus model structure.
 * @see #/components/schemas/InfectionStatus - reference to schema in open-api-spec.yaml
 * @since 2024-09-18T14:58:35.670970+03:00[Europe/Chisinau]
 * @version 1.6.11
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface InfectionStatus {
    /**
     * Infection name.
     *
     */
    infection_name: string;
    /**
     * Infection annotation.
     *
     */
    infection_annotation: string;
    /**
     * The status of the Infection.
     *
     */
    infection_status: string;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Diagnosis unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for InfectionStatus
 */
export namespace InfectionStatus {
    /**
     * InfectionStatus Frontend Model with camelCase properties
     * @since 2024-09-18T14:58:35.670970+03:00[Europe/Chisinau]
     * @version 1.6.11
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Infection name.
         * @type string (`dataFormat` is missing)
         */
        infectionName: string;
        /**
         * Infection annotation.
         * @type string (`dataFormat` is missing)
         */
        infectionAnnotation: string;
        /**
         * The status of the Infection.
         * @type string (`dataFormat` is missing)
         */
        infectionStatus: string;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Diagnosis unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * InfectionStatus DTO
     * Transforms InfectionStatus model from/to API object
     * @since 2024-09-18T14:58:35.670970+03:00[Europe/Chisinau]
     * @version 1.6.11
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend InfectionStatus model */
        static toModel(obj: InfectionStatus): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend InfectionStatus model */
        static toApi(obj: Model): InfectionStatus {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as InfectionStatus;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
