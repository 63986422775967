import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DrawerService } from './drawer.service';

/**
 * Drawer State Listener
 */
@Directive()
export class StateListener implements OnInit, OnDestroy {
    drawerOpenListener: Subscription;

    /**
     * Constructor
     *
     * @param drawerService
     * @param changeDetector
     */
    constructor(protected drawerService: DrawerService, protected changeDetector: ChangeDetectorRef) {}

    /**
     * INFO: add comment
     */
    public ngOnInit(): void {
        this.listenForDrawerChanges();
    }

    /**
     * INFO: add comment
     */
    public ngOnDestroy(): void {
        this.unsubscribeListeners();
    }

    /**
     * INFO: add comment
     */
    public isOpen(): boolean {
        return this.drawerService.isDrawerOpen();
    }

    /**
     * INFO: add comment
     */
    public isRightOpen(): boolean {
        return this.drawerService.isRightOpen();
    }

    /**
     * INFO: add comment
     */
    public isOpenOnHover(): boolean {
        return this.drawerService.isOpenOnHover();
    }

    /**
     * INFO: add comment
     */
    public unsubscribeListeners(): void {
        if (this.drawerOpenListener) {
            this.drawerOpenListener.unsubscribe();
        }
    }

    /**
     * INFO: add comment
     */
    listenForDrawerChanges(): void {
        this.drawerOpenListener = this.drawerService.drawerOpenChanges().subscribe(() => {
            this.changeDetector.detectChanges();
        });
    }
}
