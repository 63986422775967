import { ApiModels } from '@mona/api';
import { Bed } from '@mona/models';

/**
 * Transforms API Bed
 *
 * @param apiBed apiBed
 */
export const transformApiBed = (apiBed: ApiModels.Bed): Bed => {
    return ApiModels.Bed.DTO.toModel(apiBed);
};

/**
 * Transforms API Beds
 *
 * @param apiBeds apiBeds
 */
export const transformApiBeds = (apiBeds: ApiModels.Bed[]): Bed[] => {
    return apiBeds.map(ward => transformApiBed(ward));
};
