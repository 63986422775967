import pkg from '@package';
import { compare } from 'compare-versions';
import { ApiModels } from '../api-models';

type HealthSettings = ApiModels.HealthSettings.Model & {
    isVersionOutdated: boolean;
    isVersionIncompatible: boolean;
};

/**
 * Transforms API health settings
 *
 * @param apiHealthSettings CustomApiModels.HealthSettings
 */
export const transformApiHealthSettings = (apiHealthSettings: ApiModels.HealthSettings): HealthSettings => {
    let isVersionIncompatible = false;
    try {
        isVersionIncompatible = compare(apiHealthSettings.min_version, pkg.version, '>');
    } catch (error) {
        isVersionIncompatible = false;
    }

    const model: HealthSettings = Object.assign(ApiModels.HealthSettings.DTO.toModel(apiHealthSettings), {
        isVersionOutdated: false, // Will be set later by mender client
        isVersionIncompatible: isVersionIncompatible,
    });
    return model;
};
