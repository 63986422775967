import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { patientsAdmisionFeatureKey } from './entities/patient-admission.state';
import { PatientsAdmissionEffects } from './state/effects';
import { patientsAdmissionReducer } from './state/reducers';

/**
 * Patients admission module
 */
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(patientsAdmisionFeatureKey, patientsAdmissionReducer),
        EffectsModule.forFeature([PatientsAdmissionEffects]),
    ],
})
export class DataAccessPatientsAdmissionModule {}
