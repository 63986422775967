import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BaseMedication } from '@mona/models';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { DialogService, MessageService } from '@mona/ui';
import { StandardMedicationsApi } from '../../infrastructure';
import { StandardMedicationsActions } from '../actions';

/**
 * StandardMedications effects
 */
@Injectable()
export class StandardMedicationsEffects {
    /**
     * Load standard medications effect
     */
    loadStandardMedications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StandardMedicationsActions.loadStandardMedications),
            switchMap(() => this.standardMedicationsApi.getStandardMedications()),
            map(standardMedications =>
                StandardMedicationsActions.loadStandardMedicationsSuccess({ standardMedications }),
            ),
            catchError((error: unknown) => of(StandardMedicationsActions.failedStandardMedication({ error }))),
        ),
    );

    /**
     * Load standard medications succeeded effect
     */
    loadStandardMedicationsucceeded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(StandardMedicationsActions.loadStandardMedicationsSuccess),
                tap(() => this.terminologyService.loadPrescriptionTerminology()),
            ),
        { dispatch: false },
    );

    /**
     * Create standard medication effect
     */
    createStandardMedications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StandardMedicationsActions.createStandardMedication),
            switchMap(({ payload }) => this.standardMedicationsApi.createStandardMedication(payload)),
            map(standardMedication =>
                StandardMedicationsActions.createStandardMedicationSuccess({ standardMedication }),
            ),
            catchError((error: unknown) => of(StandardMedicationsActions.failedStandardMedication({ error }))),
        ),
    );

    /**
     * Create standard medication succeeded effect
     */
    createStandardMedicationsucceeded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(StandardMedicationsActions.createStandardMedicationSuccess),
                tap(() => {
                    this.messageService.successToast('apps.settings.medications.addDialog.createMedication.success');
                }),
            ),
        { dispatch: false },
    );

    /**
     * Update standard medication effect
     */
    updateStandardMedications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StandardMedicationsActions.updateStandardMedication),
            switchMap(({ update }) =>
                this.standardMedicationsApi.updateStandardMedication(update.changes as BaseMedication),
            ),
            map(standardMedication => StandardMedicationsActions.upsertStandardMedication({ standardMedication })),
            catchError((error: unknown) => of(StandardMedicationsActions.failedStandardMedication({ error }))),
        ),
    );

    /**
     * Remove standard medication effect
     */
    removeStandardMedications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StandardMedicationsActions.removeStandardMedication),
            switchMap(({ id }) =>
                this.dialogService
                    .showConfirmDialog({
                        description: 'apps.settings.medications.standardMedicationSets.confirmDelete.description',
                        title: 'apps.settings.medications.standardMedicationSets.confirmDelete.title',
                        cancelBtn: 'apps.patient.prescriptions.procedureTable.delete.cancel',
                        confirmBtn: 'apps.patient.prescriptions.procedureTable.delete.confirm',
                    })
                    .pipe(map(res => (res ? id : null))),
            ),
            switchMap(id => {
                if (!id) {
                    return of(StandardMedicationsActions.removeStandardMedicationCancel());
                }
                return this.standardMedicationsApi.removeStandardMedication(id).pipe(
                    map(() => StandardMedicationsActions.removeStandardMedicationSuccess({ id })),
                    catchError((error: unknown) => of(StandardMedicationsActions.failedStandardMedication({ error }))),
                );
            }),
        ),
    );

    /**
     * Remove standard medication succeeded effect
     */
    removeStandardMedicationsucceeded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(StandardMedicationsActions.removeStandardMedicationSuccess),
                tap(() => {
                    this.messageService.successToast('apps.settings.medications.standardMedicationSets.deleteSuccess');
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param store$
     * @param terminologyService
     * @param standardMedicationsApi
     * @param dialogService
     * @param messageService
     */
    constructor(
        private actions$: Actions,
        private store$: Store,
        private terminologyService: TerminologyService,
        private standardMedicationsApi: StandardMedicationsApi,
        private dialogService: DialogService,
        private messageService: MessageService,
    ) {}
}
