/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Procedure } from '@mona/models';
import { ProceduresAdministrationState } from '../../entities';
import { ProceduresActions } from '../actions';

export const procedureAdapter: EntityAdapter<Procedure> = createEntityAdapter<Procedure>();

/** Initial State */
export const initialState: ProceduresAdministrationState = procedureAdapter.getInitialState({
    isLoading: false,
    error: null,
});

export const reducer = createReducer(
    initialState,
    on(ProceduresActions.loadProceduresSuccess, (state, { procedures }) =>
        procedureAdapter.setAll(procedures, {
            ...state,
            error: null,
            isLoading: false,
        }),
    ),
    on(ProceduresActions.loadProceduresFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(ProceduresActions.upsertProcedures, (state, action) => procedureAdapter.upsertMany(action.procedures, state)),
    on(ProceduresActions.upsertProcedure, (state, action) => procedureAdapter.upsertOne(action.procedure, state)),
    on(ProceduresActions.updateProcedures, (state, action) => procedureAdapter.updateMany(action.updates, state)),
    on(ProceduresActions.deleteProcedures, (state, action) => procedureAdapter.removeMany(action.ids, state)),
    on(ProceduresActions.clearProcedures, state => procedureAdapter.removeAll(state)),
    on(ProceduresActions.addProceduresChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = procedureAdapter.removeMany(toRemoveIds, state);

        return procedureAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
