import { createFeatureSelector, createSelector } from '@ngrx/store';
import { startOfMinute } from 'date-fns';
import { groupBy, uniqArrayAttrs } from '@mona/shared/utils';
import { encounterDataFeatureKey, EncounterFeatureState, LabValuesState } from '../../entities';
import { labValuesAdapter } from '../reducers/lab-values.reducer';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectLabValuesState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.labValues,
);
export const { selectEntities: selectAllLabValuesMap, selectAll: selectAllLabValues } =
    labValuesAdapter.getSelectors(selectLabValuesState);

export const selectAllLabValuesGroupByCode = createSelector(selectAllLabValues, all =>
    groupBy(all, a => a.code, false, false),
);

export const selectLabValuesStartDate = createSelector(
    selectLabValuesState,
    (state: LabValuesState) => state.startDate,
);

export const selectLabValuesIsLoading = createSelector(
    selectLabValuesState,
    (state: LabValuesState) => state.isLoading,
);

export const selectLabValuesPageableData = createSelector(
    selectLabValuesState,
    (state: LabValuesState) => state.pageableData,
);
export const selectLabValuesEntriesDates = createSelector(selectAllLabValues, all => {
    const sortedEntriesDates: string[] = uniqArrayAttrs(all, 'date', d => startOfMinute(d).toISOString()).sort();
    return sortedEntriesDates;
});
