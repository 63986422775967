/* eslint-disable jsdoc/require-jsdoc */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from '@mona/shared/utils';
import { DataAccessWardsState, bedsAdapter } from '../../entities';
import { WARDS_DATA_REDUCERS_TOKEN } from '../reducers';

const bedsEntitySelectors = bedsAdapter.getSelectors();
const selectDataWardsFeature = createFeatureSelector<DataAccessWardsState>(WARDS_DATA_REDUCERS_TOKEN);

export const selectBedsState = createSelector(selectDataWardsFeature, x => x.beds);

export const selectAllBeds = createSelector(selectBedsState, bedsEntitySelectors.selectAll);

export const selectBedsIsLoading = createSelector(selectBedsState, x => x.isLoading);

export const selectBedsEntities = createSelector(selectBedsState, x => x.entities);

export const selectAssignedBedId = createSelector(selectBedsState, x => x.assignedBedId);

export const selectSelectedBedId = createSelector(selectBedsState, x => x.selectedId);

export const selectBedsGroupedByWardId = createSelector(selectAllBeds, beds =>
    groupBy(beds, b => b.wardId, false, false),
);

export const selectBedById = (bedId?) => createSelector(selectBedsEntities, beds => beds[bedId]);

export const selectSelectedBed = createSelector(selectBedsEntities, selectSelectedBedId, (beds, bedId) => beds[bedId]);
