/**
 * State of ventilation specific to ventilation table date cell
 */
export enum VentilationTableEntryState {
    /**
     * Indicates that ventilation started at this specific date/cell
     */
    START = 'START',

    /**
     * Indicates that ventilation ended at this specific date/cell
     */
    END = 'END',

    /**
     * Indicates that it is a cell where ventilation is currently active but there is no lines after this cell
     * It is NOW cell
     */
    END_ACTIVE = 'END_ACTIVE',

    /**
     * Indicates that ventilation was active at this specific date/cell
     */
    ACTIVE = 'ACTIVE',
}
