<form (ngSubmit)="onSubmit($event)" *ngIf="formGroup" [formGroup]="formGroup" [isEnabled]="isBedSideMode && isActive">
    <ng-container *ngFor="let allergyForm of formGroup?.controls.items.controls; let i = index">
        <mat-form-field *ngIf="allergyForm.controls as allergy" class="app-input-field" data-testid="ff-uiAllergyText">
            <mat-label>{{ 'apps.admission.allergies.items.label' | translate }}</mat-label>
            <input [formControl]="allergy.text" matInput required data-testid="ff-uiAllergyTextInput" />
            <button
                *ngIf="!isReadOnly"
                (click)="removeAllergy(i)"
                mat-icon-button
                matSuffix
                type="button"
                data-testid="btn-remove-allergy"
            >
                <mat-icon>close</mat-icon>
            </button>
            <mat-error>
                <ui-validation-message [control]="allergy.text"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="!isReadOnly">
        <ng-container *ngIf="newItem.controls as newItem">
            <mat-form-field class="app-input-field" data-testid="ff-uiAllergyNewItem">
                <mat-label>{{ 'apps.admission.allergies.newItem.label' | translate }}</mat-label>
                <input
                    #newItemInput
                    (blur)="onNewItemNativeBlur()"
                    (keydown.enter)="addAllergy()"
                    [formControl]="newItem.text"
                    matInput
                    data-testid="ff-uiAllergyNewItemInput"
                />
                <mat-hint>{{ 'apps.admission.allergies.newItem.hint' | translate }}</mat-hint>
                <button
                    *ngIf="newItem.text.value"
                    (click)="addAllergy()"
                    mat-icon-button
                    matSuffix
                    type="button"
                    data-testid="btn-add-allergy"
                >
                    <mat-icon>add</mat-icon>
                </button>
                <mat-error>
                    <ui-validation-message [control]="newItem.text"></ui-validation-message>
                </mat-error>
            </mat-form-field>
        </ng-container>
    </ng-container>

    <ng-content></ng-content>
</form>
