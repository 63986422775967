<fieldset class="mona-medication__form mona-medication__form-fieldset" [formGroup]="formGroup">
    <!-- TYPE -->
    <mat-form-field id="type" class="app-input-field app-input-field--fullwidth">
        <mat-label>
            {{ 'apps.patient.prescriptions.procedureTable.type' | translate }}
        </mat-label>
        <mat-select [formControl]="controls.category" panelClass="app-input-select-panel scrollbar-visible">
            <mat-option disabled>
                {{ 'apps.patient.prescriptions.procedureTable.type' | translate }}
            </mat-option>
            <mat-option *ngFor="let category of procedureCategories" [value]="category.code">
                {{ category.displayName }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.category"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- DESCRIPTION-->
    <mat-form-field
        class="app-input-field app-input-field--fullwidth mona-medication__form-textarea_field"
        appearance="fill"
    >
        <mat-label>
            {{ 'apps.patient.prescriptions.procedureTable.description' | translate }}
        </mat-label>
        <textarea
            matInput
            [formControl]="controls.description"
            [placeholder]="'apps.patient.prescriptions.procedureTable.description' | translate"
            class="mona-medication__form-textarea"
            rows="4"
        ></textarea>
        <mat-error>
            <ui-validation-message [control]="controls.description"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- INSTRUCTION-->
    <mat-form-field
        class="app-input-field app-input-field--fullwidth mona-medication__form-textarea_field"
        appearance="fill"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.instruction.label' | translate }}
        </mat-label>
        <textarea
            matInput
            nextTabElementSelector=".frequency-times__input"
            [formControl]="controls.instructions"
            [placeholder]="'apps.patient.prescriptions.procedureTable.instruction' | translate"
            class="mona-medication__form-textarea"
            rows="4"
        ></textarea>
        <mat-error>
            <ui-validation-message [control]="controls.instructions"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- FREQUENCY -->
    <mat-form-field id="frequency" class="app-input-field app-input-field--fullwidth" appearance="fill">
        <mat-label>
            {{ 'apps.settings.prescriptionSet.procedureTable.frequency' | translate }}
        </mat-label>
        <mat-select
            [formControl]="controls.frequency"
            [placeholder]="'apps.settings.prescriptionSet.procedureTable.frequency' | translate"
            panelClass="app-input-select-panel scrollbar-visible"
            class="app-set-medication__select"
        >
            <mat-option [value]="null" disabled>
                {{ 'apps.settings.prescriptionSet.procedureTable.frequency' | translate }}
            </mat-option>
            <mat-option *ngFor="let item of prescriptionFrequencies; trackBy: trackByCode" [value]="item.code">
                {{ item.displayName }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.frequency"></ui-validation-message>
        </mat-error>
    </mat-form-field>

    <!-- CUSTOM FREQUENCY SECTION -->
    <mona-frequency-times
        id="frequency-times"
        class="mona-medication__form--frequency-times"
        [formControl]="controls.frequencyTimes"
        [frequencyTimesList]="prescriptionFrequencyTimes"
        [customTimes]="controls.frequencyTimes.value"
        [frequencyCode]="controls.frequency.value"
        [disabled]="controls.frequency.disabled"
        [createdAt]="startDate"
        [uiValidator]="validateFrequency(controls.frequency)"
        (setCustomFrequency)="setCustomFrequency()"
    ></mona-frequency-times>
    <br />
</fieldset>
