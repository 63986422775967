/* eslint-disable @typescript-eslint/ban-ts-comment, prefer-const, prefer-spread, no-restricted-syntax */

// eslint-disable-next-line no-var
declare var jest;

/**
 * Wrapper function which handles `message` inside webworker
 *
 * @param fn
 */
const WRAPPER_FN = function (fn) {
    try {
        globalThis.process = process;
    } catch (error) {
        // @ts-ignore
        globalThis.process = {};
    }

    function isFunction(type) {
        return type === 'function';
    }

    function isTestEnvironment() {
        return (typeof jest !== 'undefined' && !!jest) || (process && !!process.env?.TEST);
    }

    function isEqualArgs(previousArgs: any[], args: any[]) {
        return previousArgs.length === args.length && args.every((arg, index) => arg === previousArgs[index]);
    }

    const CONSOLE_STYLES = {
        cyan: [36, 39],
        green: [32, 39],
        red: [31, 39],
    };

    function colorizeStart(style: keyof typeof CONSOLE_STYLES) {
        return style ? `\x1B[${CONSOLE_STYLES[style][0]}m` : '';
    }

    function colorizeEnd(style: keyof typeof CONSOLE_STYLES) {
        return style ? `\x1B[${CONSOLE_STYLES[style][1]}m` : '';
    }

    function colorize(str: string, style: keyof typeof CONSOLE_STYLES) {
        return colorizeStart(style) + str + colorizeEnd(style);
    }

    function addLeadingZeros(number: number, targetLength: number): string {
        const sign = number < 0 ? '-' : '';
        let output = Math.abs(number).toString();
        while (output.length < targetLength) {
            output = '0' + output;
        }
        return sign + output;
    }

    function getISOString(format: 'extended' | 'basic' = 'basic'): string {
        const originalDate = new Date();

        if (isNaN(originalDate.getTime())) {
            throw new RangeError('Invalid time value');
        }

        let representation = 'complete';

        let result = '';
        let tzOffset = '';

        const dateDelimiter = format === 'extended' ? '-' : '';
        const timeDelimiter = format === 'extended' ? ':' : '';

        // Representation is either 'date' or 'complete'
        if (representation !== 'time') {
            const day = addLeadingZeros(originalDate.getDate(), 2);
            const month = addLeadingZeros(originalDate.getMonth() + 1, 2);
            const year = addLeadingZeros(originalDate.getFullYear(), 4);

            // yyyyMMdd or yyyy-MM-dd.
            result = `${year}${dateDelimiter}${month}${dateDelimiter}${day}`;
        }

        // Representation is either 'time' or 'complete'
        if (representation !== 'date') {
            // Add the timezone.
            const offset = originalDate.getTimezoneOffset();

            if (offset !== 0) {
                const absoluteOffset = Math.abs(offset);
                const hourOffset = addLeadingZeros(Math.floor(absoluteOffset / 60), 2);
                const minuteOffset = addLeadingZeros(absoluteOffset % 60, 2);
                // If less than 0, the sign is +, because it is ahead of time.
                const sign = offset < 0 ? '+' : '-';

                tzOffset = `${sign}${hourOffset}:${minuteOffset}`;
            } else {
                tzOffset = 'Z';
            }

            const hour = addLeadingZeros(originalDate.getHours(), 2);
            const minute = addLeadingZeros(originalDate.getMinutes(), 2);
            const second = addLeadingZeros(originalDate.getSeconds(), 2);

            // If there's also date, separate it with time with 'T'
            const separator = result === '' ? '' : 'T';

            // Creates a time string consisting of hour, minute, and second, separated by delimiters, if defined.
            const time = [hour, minute, second].join(timeDelimiter);

            // HHmmss or HH:mm:ss.
            result = `${result}${separator}${time}${tzOffset}`;
        }

        return result;
    }

    let previousArgs = [];
    let previousResult;

    self.addEventListener('message', function (e) {
        const time = performance.now();
        const fnName = fn.name;
        const args = [...e.data];

        if (isEqualArgs(previousArgs, args)) {
            return finalize();
        }
        previousArgs = args;
        previousResult = fn.apply(null, args);

        finalize();

        function finalize() {
            if (previousResult && [typeof previousResult.then, typeof previousResult.catch].every(isFunction)) {
                previousResult.then(postMessage).catch(function (error) {
                    // throw an error using the `setTimeout` function
                    // because web worker doesn't emit ErrorEvent from promises
                    setTimeout(function () {
                        throw error;
                    }, 0);
                });
            } else {
                if (!isTestEnvironment()) {
                    const execTime = performance.now() - time;
                    console.debug.call(
                        console,
                        colorize(`[DEBUG::%s::%s]`, 'cyan') +
                            colorize(` Execution time: %dms`, execTime >= 50 ? 'red' : 'green'),
                        getISOString(),
                        `WORKER::${fnName}`,
                        execTime,
                    );
                }
                postMessage(previousResult);
            }
        }
    });
};
/**
 * Wrapper function which handles `message` inside webworker
 *
 * @returns string - `toString()` output
 */
export const WORKER_BLANK_FN = WRAPPER_FN.toString();
