import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { MessageService } from '@mona/ui';
import { ReportsApi } from '../../infrastructure';
import * as ReportsActions from '../actions/reports.actions';

/**
 * Export reports effects
 */
@Injectable({
    providedIn: 'root',
})
export class ReportsEffects {
    /** timezone value as string (example Germany/Berlin, Kiev/Ukraine) */
    private readonly timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

    /**
     * Load reports types effect
     */

    loadReportTypesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportsActions.loadReports),
            switchMap(() => this.reportsApi.getReportsList()),
            map(reports => ReportsActions.loadReportsSuccess({ reports })),
            catchError((error: unknown) => of(ReportsActions.loadReportsFailure({ error }))),
        ),
    );

    /**
     * Export Full report effect
     */

    exportFullReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportsActions.exportFullReport),
            switchMap(action => this.authService.authenticate().pipe(map(rfid => ({ ...action, rfid })))),
            filter(action => !!action.rfid),
            withCurrentEncounterId(),
            switchMap(([_, encounterId]) =>
                this.reportsApi
                    .exportFullReport(encounterId, this.timezoneString)
                    .pipe(map((file: File) => ReportsActions.exportFullReportSuccess({ file }))),
            ),
            catchError((error: unknown) => of(ReportsActions.exportFullReportFailed({ error }))),
        ),
    );

    /**
     * Export full report success effect
     */
    exportFullReportSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ReportsActions.exportFullReportSuccess),
                tap(data => {
                    this.messageService.successToast(this.translateService.instant('apps.reports.success'));
                }),
            ),
        { dispatch: false },
    );

    /**
     * Export full report failed effect
     */
    exportFullReportFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ReportsActions.exportFullReportFailed),
                tap(error => this.messageService.errorToast(this.translateService.instant('apps.reports.failed'))),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store Store
     * @param actions$ Actions
     * @param reportsApi AvailableReportsApi
     * @param authService AuthService
     * @param messageService MessageService
     * @param translateService TranslateService
     */
    constructor(
        private store: Store,
        private actions$: Actions,
        private reportsApi: ReportsApi,
        private authService: AuthService,
        private messageService: MessageService,
        private translateService: TranslateService,
    ) {}
}
