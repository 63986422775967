import { MONA_CONFIG_FILENAME } from '@environment';
import { CONFIG_CONTEXT } from '@mona/config';
import { HttpRequestLike, IS_TELEMEDICINE_CONTEXT } from '../tokens';

/**
 * Is auth devices request
 *
 * @param request HttpRequest
 */
export function isLocalRequest(request?: HttpRequestLike): boolean {
    return request?.url?.startsWith('assets');
}

/**
 * Is auth devices request
 *
 * @param request HttpRequest
 */
export function isDevicesRequest(request?: HttpRequestLike): boolean {
    return request?.url?.includes('/devices/');
}

/**
 * Is health request
 *
 * @param request HttpRequest
 */
export function isConfigRequest(request?: HttpRequestLike): boolean {
    return (
        request?.url?.includes('/api/terminal-configuration') ||
        request?.url?.includes('/configuration') ||
        request?.url?.includes(MONA_CONFIG_FILENAME) ||
        request?.context?.get(CONFIG_CONTEXT) === true ||
        request?.url.includes('/ws-token/api/auth')
    );
}

/**
 * Is health request
 *
 * @param request HttpRequest
 */
export function isHealthRequest(request?: HttpRequestLike): boolean {
    return request?.url?.includes('/api/health/');
}

/**
 * Is auth health request
 *
 * @param request HttpRequest
 */
export function isAuthHealthRequest(request?: HttpRequestLike): boolean {
    return request?.url?.includes('/api/auth-health/');
}

/**
 * Is auth login request
 *
 * @param request HttpRequest
 */
export function isAuthLoginRequest(request?: HttpRequestLike): boolean {
    return request?.url?.includes('/auth/user/login');
}

/**
 * Is token refresh request
 *
 * @param request HttpRequest
 */
export function isTokenRefreshRequest(request?: HttpRequestLike): boolean {
    return request?.url?.includes('/auth/user/refresh');
}

/**
 * Is auth login request
 *
 * @param request HttpRequest
 */
export function isRfidLoginRequest(request?: HttpRequestLike): boolean {
    return request?.url?.includes('/auth/user/rfid');
}

/**
 * Is permissions request
 *
 * @param request HttpRequest
 */
export function isPermissionsRequest(request?: HttpRequestLike): boolean {
    return request?.url?.includes('/practitioners/permissions/');
}
/**
 * Is telemedicine request
 *
 * @param request HttpRequest
 */
export function isTelemedicineRequest(request?: HttpRequestLike): boolean {
    return request?.url?.includes('telemedicine') || request?.context?.get(IS_TELEMEDICINE_CONTEXT) === true;
}
