import { MatSnackBarConfig } from '@angular/material/snack-bar';

/**
 * UiMessage Type enum
 */
export enum UiMessageTypeEnum {
    'error' = 'error',
    'warn' = 'warn',
    'success' = 'success',
    'info' = 'info',
    'default' = 'default',
}

/**
 * UiMessage Priority enum
 */
export enum UiMessagePriorityEnum {
    High,
    Medium,
    Low,
}
/**
 * UiMessage Type
 */
export type UiMessageType = keyof typeof UiMessageTypeEnum;

/**
 * UiMessageMap
 */
export interface UiMessage {
    /**
     * Id
     */
    id?: string | null;
    /**
     * Priority
     */
    priority?: UiMessagePriorityEnum;
    /**
     * Type
     */
    closable?: boolean;
    /**
     * Type
     */
    type: UiMessageType;
    /**
     * Title
     */
    title: string;
    /**
     * Subtitle
     */
    description?: string;
    /**
     * Icon
     */
    icon?: string;
    /**
     * Interpolate params
     */
    params?: AnyObject;
    /**
     * Action
     */
    action?: {
        title: string;
        reason?: string;
    };
}

/** UiMessage instance */
export class UiMessage implements UiMessage {
    id?: string = Date.now().toString();
    /** @ignore */
    constructor(data: UiMessage) {
        Object.assign(this, UiMessageDefault, data);
    }
}

export const UiMessageDefault: UiMessage = {
    id: null,
    type: UiMessageTypeEnum.success,
    closable: true,
    title: undefined,
    description: undefined,
    icon: undefined,
};

/** UiMessage SnackBar Config */
export type UiMessageSnackBarConfig = MatSnackBarConfig<Partial<UiMessage>> & { panelClass?: string[] };
