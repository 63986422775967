import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, QueryList, Renderer2, RendererFactory2 } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { afterMethod } from 'kaop-ts';
import { EMPTY, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { concatMap, finalize, pluck, share, switchMap, take, tap } from 'rxjs/operators';
import { ReadonlyDeep } from 'type-fest';
import { Logger } from '@mona/shared/logger';
import {
    AppError,
    AsyncActionErrorConfig,
    debug,
    isEmptyString,
    isString,
    notEmpty,
    RootInjector,
    Suspense,
} from '@mona/shared/utils';
import {
    UiOverlayContainerConfiguration,
    UiOverlayContainerContent,
    UiOverlayContainerService,
    UiPopoverRef,
} from '../../overlay-container';
import { UiMessageComponent, UiSnackbarComponent } from '../components';
import {
    UiMessage,
    UiMessageSnackBarConfig,
    UiMessageStatus,
    UiMessageStatusDefault,
    UiMessageType,
    UiMessageTypeEnum,
} from '../models';

/**
 * Ui messages service
 */
@Injectable({ providedIn: 'root' })
export class MessageService {
    private subscription: Subscription;
    private _allAlerts: QueryList<UiMessageComponent>;
    private _change = new Subject<number>();
    private _currentIndex: number;
    /** Snackbar Status object */
    private snackbarStatus: ReadonlyDeep<UiMessageStatus> = UiMessageStatusDefault;
    /** reference to MatSnackBarRef */
    private snackBarRef: MatSnackBarRef<UiSnackbarComponent> | undefined;
    /** Default snackbar config */
    readonly defaultSnackBarConfig: UiMessageSnackBarConfig = {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['ui-snackbar-container'],
    };
    /** Default icon shape */
    readonly defaultIconShape = 'info';
    /**
     * The Observable that lets other classes subscribe to changes
     */
    get changes(): Observable<number> {
        return this._change.asObservable();
    }

    /**
     * Current index
     */
    get currentIndex() {
        return this._currentIndex;
    }
    set currentIndex(index: number) {
        if (index !== this._currentIndex) {
            this._currentIndex = index;
            this._change.next(index);
        }
    }

    /**
     * Ensure we are only dealing with alerts that have not been closed yet
     */
    get activeAlerts() {
        return this._allAlerts && this._allAlerts.filter(alert => alert.opened);
    }

    /**
     * Current alert
     */
    get currentAlert(): UiMessageComponent {
        return this.activeAlerts && this.activeAlerts[this.currentIndex];
    }
    set currentAlert(alert: UiMessageComponent) {
        this.currentIndex = this.activeAlerts.indexOf(alert);
    }

    /**
     * Active alerts length
     */
    get totalAlerts() {
        return this.activeAlerts?.length || 0;
    }

    private renderer: Renderer2;
    private translationsLoaded = new ReplaySubject();
    private readonly logger = new Logger('MONA');

    /**
     * Constructor
     *
     * @param {MatSnackBar} snackBar - material snack bar
     * @param {TranslateService} translateService - translate service
     * @param {UiOverlayContainerService} overlayContainerService
     * @param {DOCUMENT} document - Document reference
     * @param {RendererFactory2} rendererFactory
     */
    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
        private overlayContainerService: UiOverlayContainerService,
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory: RendererFactory2,
    ) {
        this.renderer = this.rendererFactory.createRenderer(this.document.defaultView, null);
        //
        this.translateService.onDefaultLangChange.pipe(take(1)).subscribe(() => {
            this.translationsLoaded.next(true);
            this.translationsLoaded.complete();
        });
    }

    /**
     * INFO: add comment
     *
     * @param alerts
     */
    manage(alerts: QueryList<UiMessageComponent>) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this._allAlerts = alerts;
        // After receiving alerts' QueryList,
        // we are picking index 0 as current by default if a user hasn't any index
        this.currentIndex = typeof this._currentIndex === 'number' ? this._currentIndex : 0;
        // we have to also broadcast that initial index
        this._change.next(this.currentIndex);

        this.subscription = this._allAlerts.changes.subscribe(() => {
            if (this.currentIndex >= this._allAlerts.length) {
                this.currentIndex = Math.max(0, this._allAlerts.length - 1);
            }
            this.toggleBodyClasses();
        });
    }

    /**
     * Next message
     */
    next() {
        this._currentIndex = this.currentIndex === this.totalAlerts - 1 ? 0 : this.currentIndex + 1;
        this._change.next(this._currentIndex);
    }

    /**
     * Previous message
     */
    previous() {
        if (this.totalAlerts === 0) {
            return;
        }
        this._currentIndex = this.currentIndex === 0 ? this.totalAlerts - 1 : this.currentIndex - 1;
        this._change.next(this._currentIndex);
    }

    /**
     * Open message
     */
    open() {
        if (this.totalAlerts === 0) {
            return;
        }

        if (!this.currentAlert) {
            this._currentIndex = 0;
        }

        this._change.next(this._currentIndex);
    }

    /**
     * Close message
     *
     * @param isCurrentAlert
     */
    close(isCurrentAlert: boolean) {
        if (this.totalAlerts === 0) {
            this.toggleBodyClasses();
            return;
        }

        if (isCurrentAlert) {
            this._currentIndex = Math.max(0, this.currentIndex - 1);
        }

        this._change.next(this._currentIndex);
    }

    /**
     * INFO: add comment
     *
     * @param type
     */
    getMessagePropsFromType(type: UiMessageType): Pick<UiMessage, 'type' | 'title' | 'icon'> {
        const returnObj = { type, icon: '', title: '' };

        switch (type) {
            case UiMessageTypeEnum.warn:
                returnObj.icon = 'warning';
                returnObj.title = 'commonStrings.keys.warning';
                break;
            case UiMessageTypeEnum.error:
                returnObj.icon = 'error';
                returnObj.title = 'commonStrings.keys.error';
                break;
            case UiMessageTypeEnum.success:
                returnObj.icon = 'check_circle';
                returnObj.title = 'commonStrings.keys.success';
                break;
            case UiMessageTypeEnum.default:
                returnObj.title = '';
                break;
            default:
                returnObj.icon = this.defaultIconShape;
                returnObj.title = 'commonStrings.keys.info';
                break;
        }

        return returnObj;
    }

    /**
     * ℹ️ Show message as Info toast
     *
     * @param msg
     * @param {object} params - interpolate params
     * @param config
     */
    infoToast(msg: string, params?: AnyObject, config?: UiMessageSnackBarConfig): Observable<MatSnackBarDismiss> {
        return this.openSnackBar(msg, UiMessageTypeEnum.info, this.extendMessageData(params, config));
    }

    /**
     * ✅ Show message as Success toast
     *
     * @param msg
     * @param {object} params - interpolate params
     * @param config
     */
    successToast(msg: string, params?: AnyObject, config?: UiMessageSnackBarConfig): Observable<MatSnackBarDismiss> {
        return this.openSnackBar(msg, UiMessageTypeEnum.success, this.extendMessageData(params, config));
    }

    /**
     * ⚠️ Show message as Warn toast
     *
     * @param msg
     * @param {object} params - interpolate params
     * @param config
     */
    warnToast(msg: string, params?: AnyObject, config?: UiMessageSnackBarConfig): Observable<MatSnackBarDismiss> {
        return this.openSnackBar(msg, UiMessageTypeEnum.warn, this.extendMessageData(params, config));
    }

    /**
     * 🚫 Show message as Error toast
     *
     * @param msg
     * @param error
     * @param config
     */
    errorToast(msg: string, error?: AppError, config?: UiMessageSnackBarConfig): Observable<MatSnackBarDismiss> {
        if (error) {
            Object.assign({}, config?.data, {
                action: {
                    title: 'errorDetailsDialog.toastText',
                    reason: 'errorDetailsDialog',
                },
            });
        }
        return this.openSnackBar(msg, UiMessageTypeEnum.error, config).pipe(
            tap(({ dismissedByAction }) => {
                if (dismissedByAction) {
                    this.logger.log('errorToast: dismissedByAction');
                }
            }),
        );
    }

    /**
     * Handles async action with snackbar error and returns observable
     * Decided to return observable but not subscription
     * so component can add additional error handling actions by itself
     *
     * @deprecated
     * @todo remove in favor of effects
     * @param {Observable} asyncAction asyncAction
     * @param {AsyncActionErrorConfig} config - config
     */
    subscribeToAsyncActionError<T>(
        asyncAction: Observable<Suspense<T>>,
        config: AsyncActionErrorConfig,
    ): Observable<AppError> {
        return asyncAction.pipe(
            pluck<Suspense<T>, AppError | null>('error'),
            notEmpty(),
            tap((err: AppError) => {
                if (config.errorsToIgnore?.includes(err.errorCode)) {
                    return;
                }
                const msg = config.errorMessageMapping?.[err.errorCode] || config.unexpectedErrorsMessage;
                if (!msg) {
                    return;
                }
                this.errorToast(msg, msg === config.unexpectedErrorsMessage ? err : null);
            }),
        );
    }

    /**
     * Demonstrates ho to use the service to open the overlay unrelated to any origin element by using `UiOverlayContainerConfiguration.useGlobalPositionStrategy = true`
     *
     * @param {HTMLElement} containerElm - HTMLElement or ID of element
     * @param {string} content - message
     * @param {object} data - interpolation for translate message
     * @param {object} config partial {@link MatSnackBarConfig}
     */
    openPopover(
        containerElm: string | HTMLElement,
        content: UiOverlayContainerContent,
        data?: object,
        config: UiOverlayContainerConfiguration = {},
    ): UiPopoverRef<any, any> {
        if (isString(containerElm)) {
            containerElm = this.document.querySelector<HTMLElement>(`#${containerElm}`);
        }
        if (isString(content)) {
            content = this.translateService.instant(content, data);
        }
        const popoverRef = this.overlayContainerService.open({
            content,
            data,
            origin: containerElm,
            configuration: { ...config, useGlobalPositionStrategy: !containerElm },
        });

        popoverRef.afterOpened$.pipe(debug('popoverRef')).subscribe();

        return popoverRef;
    }

    /**
     * Extend message config `data` object
     *
     * @param params
     * @param config
     */
    private extendMessageData(params: AnyObject, config: UiMessageSnackBarConfig): Partial<UiMessageSnackBarConfig> {
        if (!config) {
            config = { data: {} };
        }
        if (params) {
            Object.assign(config.data, { params });
        }
        return config;
    }

    /**
     * Open snack bar from component
     *
     * @param msg
     * @param type
     * @param config
     */
    private openSnackBar(
        msg: string,
        type: UiMessageType,
        config?: UiMessageSnackBarConfig,
    ): Observable<MatSnackBarDismiss> {
        if (isEmptyString(msg)) {
            return EMPTY;
        }

        const snackBarConfig = {
            ...this.defaultSnackBarConfig,
            ...config,
        };
        let description = msg;

        const opened$ = this.translationsLoaded.pipe(
            concatMap(() => {
                description = this.translateService.instant(msg, config?.data?.params);
                const defaultMessageConfig = this.getMessagePropsFromType(type);
                snackBarConfig.data = Object.assign(config?.data || {}, {
                    ...defaultMessageConfig,
                    description,
                });
                this.snackBarRef = this.snackBar.openFromComponent(UiSnackbarComponent, snackBarConfig);
                return this.snackBarRef.afterOpened();
            }),
            tap(() => {
                if (this.snackbarStatus.console) {
                    this.logger.log(`MESSAGE:${type.toUpperCase()}`, description);
                }
            }),
            share(),
        );

        opened$.subscribe();

        return opened$.pipe(
            switchMap(() => this.snackBarRef.afterDismissed()),
            finalize(() => {
                this.snackBarRef = null;
            }),
        );
    }

    /** Add class to body to differentiate if has app level alerts */
    private toggleBodyClasses() {
        if (this.totalAlerts) {
            this.renderer.addClass(this.document.body, `ui-alerts--has-messages`);
        } else {
            this.renderer.removeClass(this.document.body, `ui-alerts--has-messages`);
        }
    }
}

/**
 * ShowConfirmDialog decorator
 *
 * @param message string or translation key, e.g. `'apps.settings.device.shutdown.title'`
 * @param interpolateParams
 * @param config
 */
export const ShowSuccessMessageAfter = (
    message: string,
    interpolateParams?: object,
    config: UiMessageSnackBarConfig = {},
) => {
    return afterMethod(function (meta) {
        const service: MessageService = RootInjector.get(MessageService);
        if (!service) {
            return;
        }
        // get first argument received by the decorated method if present
        // eslint-disable-next-line no-unsafe-optional-chaining
        const [result, ...args] = meta?.args;
        if (result) {
            service.successToast(message, interpolateParams, config);
        }
    });
};
