import { Component, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

/**
 * Reusable view to display No-data (Empty table) message
 */
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'ui-empty-state',
    templateUrl: './empty-state.component.html',
})
export class UiEmptyStateComponent {
    /** Template Reference  */
    @Input() noDataTemplate: TemplateRef<any>;
    /** Image url to be displayed */
    @Input() imgSrc = 'assets/images/workflows.svg';
    /** Additiona css class to be added */
    @Input() customClass: string;
    /** Title text to be displayed */
    @Input() title: string = this.translateService.instant('apps.patient.balance.inputs.noData.title');
    /** Subtitle text to be displayed */
    @Input() subTitle: string = this.translateService.instant('apps.patient.balance.inputs.noData.subtitle');
    /** CTA button title text to be displayed */
    @Input() ctaTitle: string = this.translateService.instant('apps.patient.balance.inputs.noData.ctaTitle');
    /** CTA button permission */
    @Input() ctaPermission: string;
    /** EventEmitter for CTA click */
    @Output() ctaClick = new EventEmitter<any>();
    /** Host class */
    @HostBinding('class.ui-empty-state') noDataClass = true;
    /**
     * Constructor function
     *
     * @param translateService TranslateService
     */
    constructor(private translateService: TranslateService) {}
}
