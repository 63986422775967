<div class="mat-mdc-paginator-range-actions">
    <button
        mat-icon-button
        type="button"
        color="primary"
        class="ui-vhs-pager__first"
        (click)="emitPageEvent(-pageSize / 2)"
        [attr.aria-label]="'commonStrings.keys.firstPageLabel' | translate"
        [matTooltip]="'commonStrings.keys.firstPageLabel' | translate"
        [matTooltipDisabled]="previousButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="previousButtonsDisabled()"
        data-testid="vhsPager-scrollFirst"
        *ngIf="showMoreButtons"
    >
        <!-- <mat-icon>exposure_neg_1</mat-icon> -->
        <mat-icon>replay_5</mat-icon>
    </button>
    <button
        mat-mini-fab
        type="button"
        color="primary"
        class="ui-vhs-pager__previous"
        (click)="emitPageEvent(-pageSize)"
        [attr.aria-label]="'commonStrings.keys.previousPageLabel' | translate"
        [matTooltip]="'commonStrings.keys.previousPageLabel' | translate"
        [matTooltipDisabled]="previousButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="previousButtonsDisabled()"
        data-testid="vhsPager-scrollPrev"
    >
        <mat-icon>arrow_left</mat-icon>
    </button>
    <button
        mat-mini-fab
        type="button"
        color="primary"
        class="ui-vhs-pager__next"
        (click)="emitPageEvent(pageSize)"
        [attr.aria-label]="'commonStrings.keys.nextPageLabel' | translate"
        [matTooltip]="'commonStrings.keys.nextPageLabel' | translate"
        [matTooltipDisabled]="nextButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="nextButtonsDisabled()"
        data-testid="vhsPager-scrollNext"
    >
        <mat-icon>arrow_right</mat-icon>
    </button>
    <button
        mat-icon-button
        type="button"
        color="primary"
        class="ui-vhs-pager__last"
        (click)="emitPageEvent(pageSize / 2)"
        [attr.aria-label]="'commonStrings.keys.lastPageLabel' | translate"
        [matTooltip]="'commonStrings.keys.lastPageLabel' | translate"
        [matTooltipDisabled]="nextButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="nextButtonsDisabled()"
        data-testid="vhsPager-scrollLast"
        *ngIf="showMoreButtons"
    >
        <!-- <mat-icon>exposure_plus_1</mat-icon> -->
        <mat-icon>forward_5</mat-icon>
    </button>
</div>
