import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Merge } from 'type-fest';
import { BaseMedication, ControlsOf, Medication } from '@mona/models';
import { CustomValidators } from '@mona/shared/utils';

/**
 * base medication form controls interface
 *
 * @augments BaseMedication
 */
export type BaseMedicationControls = Merge<
    ControlsOf<BaseMedication>,
    { frequencyTimes: FormControl<string[]>; clearRequiredFields: FormControl<boolean> }
>;
/**
 * base medication form group interface
 *
 * @augments BaseMedication
 */
export type BaseMedicationForm = FormGroup<BaseMedicationControls>;

const numberFieldsValidators = [CustomValidators.positiveDecimal(), Validators.min(0.01), Validators.max(999999.99)];

function baseMedicationEmptyValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        const controls = ['amount', 'volume', 'rate'];
        const empty = controls.some(c => !group.get(c).value);
        return empty ? { required: true } : null;
    };
}

/** Build base medication form */
export const buildBaseMedicationForm = (): BaseMedicationForm => {
    return new FormGroup<BaseMedicationControls>(
        {
            id: new FormControl<string>(''),
            /** ⚠️ NOTE: cast to any to handle autocomplete initialization */
            medication: new FormControl<string | Medication>(null, [Validators.required]) as any,
            medicationCode: new FormControl<string>(null, [Validators.required]),
            frequency: new FormControl<string>(null, [Validators.required]),
            frequencyTimes: new FormControl<string[]>([]),
            method: new FormControl<string>(''), // infusions & tablets
            amount: new FormControl<number>(null, numberFieldsValidators),
            unitAmount: new FormControl<string>(''),
            dosageForm: new FormControl<string>(''),
            solutionCode: new FormControl<string>(''),
            volume: new FormControl(null, numberFieldsValidators),
            unitVolume: new FormControl<string>(''),
            rate: new FormControl<number>(null, numberFieldsValidators),
            unitRate: new FormControl<string>(''),
            // NOTE: FE only field
            clearRequiredFields: new FormControl<boolean>(true),
        },
        [baseMedicationEmptyValidator],
    );
};
