import { createAction, props } from '@ngrx/store';
import { ManualConnection, ManualConnectionSettings, ManualConnectionType, WifiEntry } from '@mona/models';
import { AsyncAction, FailedAction, SucceededAction } from '@mona/store';

/**
 * Health check actions
 */
export class NetworkManagerActions {
    /**
     * Load wifi endpoints
     */
    static loadWifiEndpoints: AsyncAction<void, WifiEntry[]> = {
        action: createAction('NETWORK_MANAGER:LOAD_WIFI_ENDPOINTS'),

        succeededAction: createAction(
            'NETWORK_MANAGER:LOAD_WIFI_ENDPOINTS_SUCCEEDED',
            props<SucceededAction<WifiEntry[]>>(),
        ),

        failedAction: createAction('NETWORK_MANAGER:LOAD_WIFI_ENDPOINTS_FAILED', props<FailedAction>()),

        clearAction: createAction('NETWORK_MANAGER:LOAD_WIFI_ENDPOINTS_CLEAR'),
    };

    /**
     * Connect to wifi endpoint
     */
    static connectToWifiEndpoint: AsyncAction<
        /* prettier-ignore */
        // tslint:disable-next-line: completed-docs
        { ssid: string; passphrase: string; },
        string
    > = {
        action: createAction(
            'NETWORK_MANAGER:LOAD_CONNECT_TO_WIFI_ENDPOINT',
            props<{
                // tslint:disable-next-line: completed-docs
                ssid: string;
                // tslint:disable-next-line: completed-docs
                passphrase: string;
            }>(),
        ),

        succeededAction: createAction(
            'NETWORK_MANAGER:LOAD_CONNECT_TO_WIFI_ENDPOINT_SUCCEEDED',
            props<SucceededAction<string>>(),
        ),

        failedAction: createAction('NETWORK_MANAGER:LOAD_CONNECT_TO_WIFI_ENDPOINT_FAILED', props<FailedAction>()),

        clearAction: createAction('NETWORK_MANAGER:LOAD_CONNECT_TO_WIFI_ENDPOINT_CLEAR'),
    };

    /**
     * Load manual connection settings
     */
    static loadManualConnectionSettings: AsyncAction<
        {
            // tslint:disable-next-line: completed-docs
            deviceType: ManualConnectionType;
        },
        ManualConnectionSettings
    > = {
        action: createAction(
            'NETWORK_MANAGER:LOAD_MANUEL_CONNECTION_SETTINGS',
            props<{
                // tslint:disable-next-line: completed-docs
                deviceType: ManualConnectionType;
            }>(),
        ),

        succeededAction: createAction(
            'NETWORK_MANAGER:LOAD_MANUEL_CONNECTION_SETTINGS_SUCCEEDED',
            props<SucceededAction<ManualConnectionSettings>>(),
        ),

        failedAction: createAction('NETWORK_MANAGER:LOAD_MANUEL_CONNECTION_SETTINGS_FAILED', props<FailedAction>()),

        clearAction: createAction('NETWORK_MANAGER:LOAD_MANUEL_CONNECTION_SETTINGS_CLEAR'),
    };

    static getMacAddress = createAction('NETWORK_MANAGER:GET_MACADDRESS');
    static getMacAddressSuccess = createAction(
        'NETWORK_MANAGER:GET_MACADDRESS_SUCCESS',
        props<{ macAddress: string }>(),
    );
    static getManualConnectionSettings = createAction(
        'NETWORK_MANAGER:GET_MANUAL_CONNECTION_SETTINGS',
        props<{ connectionType: ManualConnectionType }>(),
    );
    static getManualConnectionSettingsSuccess = createAction(
        'NETWORK_MANAGER:GET_MANUAL_CONNECTION_SETTINGS_SUCCESS',
        props<{ manualConnectionSettings: ManualConnectionSettings }>(),
    );
    static setManualConnection = createAction(
        'NETWORK_MANAGER:SET_MANUAL_CONNECTION',
        props<{ data: ManualConnection }>(),
    );
    static getNetworkDetails = createAction('NETWORK_MANAGER:GET_NETWORK_DETAILS');
    static getNetworkDetailsSuccess = createAction(
        'NETWORK_MANAGER:GET_NETWORK_DETAILS_SUCCESS',
        props<{ details: string }>(),
    );
    static clearNetworkDetails = createAction('NETWORK_MANAGER:CLEAR_NETWORK_DETAILS');
    static invokeResetDHCP = createAction('NETWORK_MANAGER:INVOKE_RESET_DHCP');
}
