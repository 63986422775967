import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpService } from '@mona/api';
import { RelativeContact } from '@mona/models';
import { EncounterService } from '@mona/pdms/data-access-encounter-data';

/** Relative Contacts Api */
@Injectable({ providedIn: 'root' })
export class RelativeContactsApi {
    apiBase = '/pdms/fhir/RelatedPerson';

    /**
     * Constructor
     *
     * @param http
     * @param encounterService
     */
    constructor(private http: HttpService, private encounterService: EncounterService) {}

    /**
     * get relative contacts
     *
     * @param encounterId
     */
    getRelativeContacts(encounterId: string): Observable<RelativeContact[]> {
        return this.encounterService.getPatientIdFromEncounter(encounterId).pipe(
            switchMap(patientId => {
                if (!patientId) {
                    return of([]);
                }
                return this.http.get<any>(this.apiBase, { params: { patient: patientId } });
            }),
            map(response => {
                const resources = response.entry ? response.entry.map((entry: any) => entry.resource) : [];
                return resources.map(this.mapToRelativeContact);
            }),
        );
    }

    /**
     * Delete a relative contact by ID
     *
     * @param id
     */
    deleteRelativeContact(id: string): Observable<void> {
        return this.http.delete<void>(`${this.apiBase}/${id}/`);
    }
    /**
     * Create or update a relative contact
     *
     * @param relativeContact
     * @param patientId
     */
    upsertRelativeContact(relativeContact: RelativeContact, patientId: string): Observable<RelativeContact> {
        const resource = this.mapToRelatedPersonResource(relativeContact, patientId);

        if (relativeContact.id) {
            const url = `${this.apiBase}/${relativeContact.id}`;
            return this.http.put<any>(url, resource).pipe(map(response => this.mapToRelativeContact(response)));
        } else {
            return this.http
                .post<any>(this.apiBase, resource)
                .pipe(map(response => this.mapToRelativeContact(response)));
        }
    }

    /**
     * Map FHIR RelatedPerson resource to RelativeContact model
     *
     * @param resource
     */
    private mapToRelativeContact(resource: any): RelativeContact {
        return {
            id: resource.id,
            firstName: resource.name?.[0]?.given?.join(' ') || '',
            lastName: resource.name?.[0]?.family || '',
            relation: {
                code: resource.relationship?.[0]?.coding?.[0]?.code,
                display: resource.relationship?.[0]?.coding?.[0]?.display || '',
            },
            authorization: resource.extension?.find(
                extension => extension.url === 'https://fhir.mona.icu/StructureDefinition/mona-related-person',
            )?.valueCoding.code,
            emailAddresses: resource.telecom
                ? resource.telecom.filter((t: any) => t.system === 'email').map((t: any) => t.value)
                : [],
            phoneNumbers: resource.telecom
                ? resource.telecom.filter((t: any) => t.system === 'phone').map((t: any) => t.value)
                : [],
            main: false,
        };
    }

    /**
     * Map RelativeContact model to FHIR RelatedPerson resource
     *
     * @param relativeContact
     * @param patientId
     */
    private mapToRelatedPersonResource(relativeContact: RelativeContact, patientId: string): any {
        return {
            resourceType: 'RelatedPerson',
            id: relativeContact.id,
            name: [
                {
                    use: 'official',
                    family: relativeContact.lastName,
                    given: relativeContact.firstName ? relativeContact.firstName.split(' ') : [],
                },
            ],
            telecom: [
                ...(relativeContact.phoneNumbers
                    ? relativeContact.phoneNumbers.map(phone => ({ system: 'phone', value: phone }))
                    : []),
                ...(relativeContact.emailAddresses
                    ? relativeContact.emailAddresses.map(email => ({ system: 'email', value: email }))
                    : []),
            ],
            patient: {
                reference: `Patient/${patientId}`,
            },
            relationship: [
                {
                    coding: [
                        {
                            system: 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
                            code: relativeContact.relation.code,
                            display: relativeContact.relation.display,
                        },
                    ],
                },
            ],
            extension: [
                {
                    url: 'https://fhir.mona.icu/StructureDefinition/mona-related-person',
                    valueCoding: {
                        system: 'https://fhir.mona.icu/CodeSystem/mona-relationship-role-type',
                        code: relativeContact.authorization,
                    },
                },
            ],
        };
    }
}
