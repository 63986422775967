import { NgModule, Pipe, PipeTransform, Self, SkipSelf } from '@angular/core';
import { ConfigType, ConfigKeys } from '../models';
import { ConfigService } from '../services/config.service';

/**
 * Config Pipe
 *
 * TODO: try to make it `standalone`
 *
 * @example ```html
 *  <some-html>{{ 'api.baseUrl' | config }}</some-html>
 * ```
 */
@Pipe({ name: 'config', standalone: false })
export class ConfigPipe implements PipeTransform {
    /**
     * Constructor
     *
     * @param configService
     */
    constructor(@SkipSelf() private readonly configService: ConfigService) {}
    /**
     * An interface that is implemented by pipes in order to perform a transformation
     *
     * @param path
     */
    transform<K extends ConfigKeys>(path: K): PathValue<ConfigType, K> {
        return this.configService.get(path);
    }
}

/**
 * Config pipe module
 */
@NgModule({
    declarations: [ConfigPipe],
    exports: [ConfigPipe],
})
export class ConfigPipeModule {}
