import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isSameDay } from 'date-fns';
import { MedicationPrescription } from '@mona/models';
import { isPresctiptionDateSameDate, selectencounterViewSelectedDate } from '@mona/pdms/data-access-combined';
import { groupBy } from '@mona/shared/utils';
import { medicationPrescriptionKey, medicationsFeatureKey, MedicationsState } from '../../entities';
import { medicationPrescriptionAdapter } from '../reducers/medication-prescription.reducer';

/**
 * Feature state
 *
 * @param state
 * state
 */
const selectMedicationState = createFeatureSelector<MedicationsState>(medicationsFeatureKey);

const selectMedicationPrescriptionsState = createSelector(
    selectMedicationState,
    state => state[medicationPrescriptionKey],
);

export const {
    selectIds: selectMedicationPrescriptionIds,
    selectEntities: selectMedicationPrescriptionEntities,
    selectAll: selectMedicationPrescriptionAll,
    selectTotal: selectMedicationPrescriptionTotal,
} = medicationPrescriptionAdapter.getSelectors(selectMedicationPrescriptionsState);

export const selectMedicationPrescriotionsDates = createSelector(selectMedicationPrescriptionAll, entities => {
    const startDates = entities
        .reduce((acc, curr) => (acc.some(a => isSameDay(a.startDate, curr.startDate)) ? acc : [...acc, curr]), [])
        .map(e => e.startDate);
    return startDates;
});

/**
 * Medication prescriptions filtered by selected date
 *
 * When scrolling the dates within the calendar (e.g. 17th January):
 * - Practitioner is able to see only the procedures that are active (procedures with start_date =< 17th Jan and end_date >= 17th Jan) for the selected day in calendar.
 * - Practitioner is not able to see the inactive procedures (start_date > 17th Jan or end_date < 17th Jan). These inactive procedures are hidden from view.
 */
export const selectMedicationPrescriptionsFiltered = createSelector(
    selectMedicationPrescriptionAll,
    selectencounterViewSelectedDate,
    (entities, selectedDate) => {
        return entities.filter((prescription: MedicationPrescription) => {
            return isPresctiptionDateSameDate<MedicationPrescription>(prescription, selectedDate);
        });
    },
);

/**
 * Medication prescriptions map by group code
 */
export const selectMedicationPrescriptionMapByCategory = createSelector(selectMedicationPrescriptionAll, entities => {
    return groupBy(entities, a => a.category, false, false);
});

/**
 * Medication prescriptions error
 */
export const selectMedicationPrescriptionError = createSelector(
    selectMedicationPrescriptionsState,
    state => state.error,
);

/**
 * Medication prescriptions filtered by selected date and unconfirmed
 *
 * @see `handleIfMedicationPrescriptionShouldBeConfirmed`
 */
export const selectMedicationPrescriptionUnconfirmed = createSelector(selectMedicationPrescriptionAll, entities => {
    return entities.filter((prescription: MedicationPrescription) => !prescription.isApproved);
});

/**
 * Medication prescriptions filtered by selected date and unconfirmed
 */
export const selectMedicationPrescriptionHasChanges = createSelector(selectMedicationPrescriptionAll, entities => {
    return entities.filter((prescription: MedicationPrescription) => prescription.hasChanges);
});

/**
 * Medication prescriptions dates as days that have prescriptions which have stage changes
 */
export const selectMedicationPrescriptionHasChangesDates = createSelector(
    selectMedicationPrescriptionHasChanges,
    entities => {
        return entities.filter((prescription: MedicationPrescription) => {
            return prescription.hasChanges;
        });
    },
);
