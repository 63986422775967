import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { Encounter, MedicationAdministration } from '@mona/models';
import { transformApiMedicationAdministrations } from './transforms';

/**
 * API abstraction layer for the medication administrations API
 */
@Injectable({
    providedIn: 'root',
})
export class MedicationAdministrationsApi {
    apiBase = '/pdms/v2/medication-administrations/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get prescription set medications
     *
     * @param encounterId EntityId<Encounter>
     */
    getMedicationAdministrations(encounterId: EntityId<Encounter>): Observable<MedicationAdministration[]> {
        return this.http
            .get<{ results: ApiModels.MedicationAdministration[] }>(this.apiBase, {
                params: {
                    encounter_id: encounterId as string,
                },
            })
            .pipe(
                map((apiMedicationAdministrationsResponse: { results: ApiModels.MedicationAdministration[] }) =>
                    transformApiMedicationAdministrations(apiMedicationAdministrationsResponse.results),
                ),
            );
    }
}
