// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating AllergyIntolerance model structure.
 * @see #/components/schemas/Anamnesis - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Anamnesis {
    /**
     * The admission type of the anamnesis.  * `planned-surgical` - PLANNED_SURGICAL * `emergency-surgical` - EMERGENCY_SURGICAL * `general-medical` - GENERAL_MEDICAL
     *
     */
    admission_type: Anamnesis.AdmissionTypeEnum;
    /**
     * The text part of the anamnesis.
     *
     */
    text: string;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Anamnesis unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Anamnesis
 */
export namespace Anamnesis {
    export type AdmissionTypeEnum = 'planned-surgical' | 'emergency-surgical' | 'general-medical';
    export const AdmissionTypeEnum = {
        PlannedSurgical: 'planned-surgical' as AdmissionTypeEnum,
        EmergencySurgical: 'emergency-surgical' as AdmissionTypeEnum,
        GeneralMedical: 'general-medical' as AdmissionTypeEnum,
    };

    /**
     * Anamnesis Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The admission type of the anamnesis.  * `planned-surgical` - PLANNED_SURGICAL * `emergency-surgical` - EMERGENCY_SURGICAL * `general-medical` - GENERAL_MEDICAL
         * @type string (`dataFormat` is missing)
         */
        admissionType: Anamnesis.AdmissionTypeEnum;
        /**
         * The text part of the anamnesis.
         * @type string (`dataFormat` is missing)
         */
        text: string;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Anamnesis unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Anamnesis DTO
     * Transforms Anamnesis model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Anamnesis model */
        static toModel(obj: Anamnesis): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Anamnesis model */
        static toApi(obj: Model): Anamnesis {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Anamnesis;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
