/**
 * `nmcli` connection.type
 *
 * one can see the `TYPE_SPECIFIC_OPTIONS` list in the `nmcli` man page
 *
 * https://access.redhat.com/documentation/en-us/red_hat_enterprise_linux/7/html/networking_guide/sec-configuring_ip_networking_with_nmcli#sec-Understanding_the_nmcli_Options
 */
export type NmcliConnectionType =
    | 'adsl'
    | 'bond'
    | 'bond-slave'
    | 'bridge'
    | 'bridge-slave'
    | 'bluetooth'
    | 'cdma'
    | 'ethernet'
    | 'gsm'
    | 'infiniband'
    | 'olpc-mesh'
    | 'team'
    | 'team-slave'
    | 'vlan'
    | 'wifi'
    | 'wimax';

/**
 * `nmcli` connection.type, but limited to Mona device adapters
 */
export type NmcliConnectionTypeForMonaDevice = Extract<NmcliConnectionType, 'ethernet'> | 'wireless';

/**
 * Wifi entry which describes a ssid and its current signal strength
 */
export interface WifiEntry {
    /**
     * SSID
     */
    ssid: string;
    /**
     * Signal strength
     */
    signal: number;
    /**
     * Needs password or not
     */
    encrypted: boolean;

    /**
     * Whether connect or not
     */
    connected: boolean;
}

/**
 * Manual connection for setting a manual connection for wifi or ethernet
 */
export interface ManualConnection {
    /**
     * Connection type which can only be wirless or ethernet so far
     */
    type: NmcliConnectionTypeForMonaDevice;
    /**
     * Connection gateway
     */
    gateway: string;
    /**
     * Connection dns
     */
    dns: string;
    /**
     * Connection IP
     */
    ip: string;
    /**
     * Connection subnet mask
     */
    subnet: string;
    /**
     * Whether dhcp is turned on or off
     */
    dhcp?: boolean;
}

/**
 * Manual connection settings for a device
 */
export interface ManualConnectionSettings {
    /**
     * dhcp active / not active
     */
    dhcpStatus: boolean;
    /**
     * gateway
     */
    gateway: string;
    /**
     * dns
     */
    dns: string;
    /**
     * ip
     */
    ip: string;
    /**
     * subnet
     */
    subnet: string;
}

/**
 * Represents a wifi connection including ssid and password
 */
export interface WifiConnection {
    /**
     * SSID of wifi endpoint
     */
    ssid: string;
    /**
     * Passphrase for the wifi endpoint
     */
    passphrase: string;
}

/**
 * Enum of the possible connection types
 */
export enum ManualConnectionType {
    /**
     * ETHERNET
     */
    ETHERNET = 'ethernet',
    /**
     * WIRELESS
     */
    WIRELESS = 'wireless',
}

/**
 * Enum of connection status types
 */
export enum ConnectionStatusType {
    /**
     * CONNECTED
     */
    CONNECTED = 'connected',
    /**
     * NOT_CONNECTED
     */
    NOT_CONNECTED = 'not_connected',
}
