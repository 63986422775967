import { MatDialogConfig } from '@angular/material/dialog';
import { DialogData } from './dialog.model';

/** Default dialog data */
export const DEFAULT_DISCARD_DIALOG_DATA: DialogData = {
    title: 'discardSetChangesDialog.title',
    description: 'discardSetChangesDialog.description',
    additionalDescription: 'discardSetChangesDialog.warning',
    confirmBtn: 'discardSetChangesDialog.discard',
    cancelBtn: 'discardSetChangesDialog.keepEditing',
};
/** Default dialog options */
export const DEFAULT_DIALOG_CONFIG: Partial<MatDialogConfig> = {
    panelClass: 'mona-dialog',
    closeOnNavigation: true,
    disableClose: true,
    autoFocus: false,
    minHeight: 250,
    maxWidth: 924,
    minWidth: 740,
};
