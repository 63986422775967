import { createAction, props } from '@ngrx/store';
import { RegisterDeviceResult } from '@mona/models';
import { AsyncAction, FailedAction, SucceededAction } from '@mona/store';

/**
 * Telemedicine Setup actions
 */
export class TelemedicineSetupAction {
    /**
     * Register the device at core
     */
    static registerDevice: AsyncAction<
        {
            hospitalCode: string;
            deviceMac: string;
        },
        RegisterDeviceResult
    > = {
        action: createAction(
            'TELEMEDICINE:REGISTER_DEVICE',
            props<{
                hospitalCode: string;
                deviceMac: string;
            }>(),
        ),

        succeededAction: createAction(
            'TELEMEDICINE:REGISTER_DEVICE_SUCCEEDED',
            props<SucceededAction<RegisterDeviceResult>>(),
        ),

        failedAction: createAction('TELEMEDICINE:REGISTER_DEVICE_FAILED', props<FailedAction>()),

        clearAction: createAction('TELEMEDICINE:REGISTER_DEVICE_CLEAR'),
    };

    /**
     * Check device is activated
     */
    static checkIsDeviceActivated: AsyncAction<
        {
            deviceMac: string;
        },
        RegisterDeviceResult
    > = {
        action: createAction(
            'TELEMEDICINE:CHECK_IS_DEVICE_ACTIVATED',
            props<{
                deviceMac: string;
            }>(),
        ),

        succeededAction: createAction(
            'TELEMEDICINE:CHECK_IS_DEVICE_ACTIVATED_SUCCEEDED',
            props<SucceededAction<RegisterDeviceResult>>(),
        ),

        failedAction: createAction('TELEMEDICINE:CHECK_IS_DEVICE_ACTIVATED_FAILED', props<FailedAction>()),

        clearAction: createAction('TELEMEDICINE:CHECK_IS_DEVICE_ACTIVATED_CLEAR'),
    };
}
