import { NgModule } from '@angular/core';
import { DataAccessTelemedicineModule } from '@mona/telemedicine/data-access-mona';
import { TelemedicineShellRoutingModule } from './telemedicine-shell-routing.module';

/**
 * Telemedicine Shell Module
 */
@NgModule({
    imports: [DataAccessTelemedicineModule, TelemedicineShellRoutingModule],
})
export class TelemedicineShellModule {}
