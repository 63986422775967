/* eslint-disable jsdoc/require-jsdoc, @angular-eslint/directive-selector */
import { TemplatePortal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    Component,
    ContentChildren,
    Directive,
    forwardRef,
    inject,
    OnDestroy,
    QueryList,
    TemplateRef,
} from '@angular/core';
import { DrawerPortalName } from '../../drawer-layout.model';
import { DrawerService } from '../../drawer.service';

/**
 * @todo work without selector?
 */
@Directive({ selector: '_/_' })
export class DrawerTemplateDirective {
    public name: DrawerPortalName;
    constructor(public template: TemplateRef<any>) {}
}
@Directive({
    selector: '[uiDrawerFab]',
    providers: [{ provide: DrawerTemplateDirective, useExisting: forwardRef(() => DrawerFabTemplateDirective) }],
})
export class DrawerFabTemplateDirective extends DrawerTemplateDirective {
    name: DrawerPortalName = 'fab';
}
@Directive({
    selector: '[uiDrawerHeader]',
    providers: [{ provide: DrawerTemplateDirective, useExisting: forwardRef(() => DrawerHeaderTemplateDirective) }],
})
export class DrawerHeaderTemplateDirective extends DrawerTemplateDirective {
    name: DrawerPortalName = 'customHeader';
}
@Directive({
    selector: '[uiToolbarTitle]',
    providers: [
        { provide: DrawerTemplateDirective, useExisting: forwardRef(() => DrawerToolbarTitleTemplateDirective) },
    ],
})
export class DrawerToolbarTitleTemplateDirective extends DrawerTemplateDirective {
    name: DrawerPortalName = 'toolbarTitle';
}
@Directive({
    selector: '[uiToolbarSubTitle]',
    providers: [
        { provide: DrawerTemplateDirective, useExisting: forwardRef(() => DrawerToolbarSubTitleTemplateDirective) },
    ],
})
export class DrawerToolbarSubTitleTemplateDirective extends DrawerTemplateDirective {
    name: DrawerPortalName = 'toolbarSubTitle';
}
@Directive({
    selector: '[uiToolbarActions]',
    providers: [
        { provide: DrawerTemplateDirective, useExisting: forwardRef(() => DrawerToolbarActionsTemplateDirective) },
    ],
})
export class DrawerToolbarActionsTemplateDirective extends DrawerTemplateDirective {
    name: DrawerPortalName = 'toolbarActions';
}

/**
 * Projects content to different places in `ui-drawer-layout`
 */
@Component({
    selector: 'ui-drawer-portals',
    template: ``,
})
export class DrawerPortalsComponent implements AfterViewInit, OnDestroy {
    /** All templates provided by `DrawerTemplateDirective`s */
    @ContentChildren(DrawerTemplateDirective) private templates: QueryList<DrawerTemplateDirective>;

    private drawerService = inject(DrawerService);

    /**
     * NG Hook
     *
     * Initialises portals
     */
    ngAfterViewInit() {
        this.setDrawerPortals();
    }

    /**
     * NG Hook
     */
    ngOnDestroy() {
        this.setDrawerPortals(true);
    }

    // @LogMethod({ ignoreProperties: ['reset'] })
    private setDrawerPortals(reset = false) {
        this.templates.forEach(({ name, template }) => {
            const portalValue = reset ? undefined : new TemplatePortal(template, undefined, {});
            if (template) {
                this.drawerService.setDrawerPortal(name, portalValue);
            }
        });
        return this.drawerService.getDrawerPortalState();
    }
}
