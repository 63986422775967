import { ListRange } from '@angular/cdk/collections';

/**
 * Vhs Scroll Direction Enum
 */
export enum VhsScrollDirectionEnum {
    Left = 'left',
    Right = 'right',
}

/**
 * Vhs List Range
 */
export interface VhsListRange extends ListRange {
    /**
     * Scroll Direction
     */
    scrollDirection: VhsScrollDirectionEnum;
}
