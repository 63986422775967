import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomApiModels, HttpService, IS_TELEMEDICINE_CONTEXT } from '@mona/api';
import { CallSession, Practitioner } from '@mona/models';
import { transformApiCallSession } from './transforms/call.transforms';

/**
 * API abstraction layer for the call API
 */
@Injectable({
    providedIn: 'root',
})
export class CallApi /* extends TelemedicineApi */ {
    apiBase = '/telemedicine/sessions/';
    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get allergies
     *
     * @param practitionerId EntityId<Practitioner>
     * @param location string
     */
    createSession(practitionerId: EntityId<Practitioner>, location: string): Observable<CallSession> {
        const context = new HttpContext().set(IS_TELEMEDICINE_CONTEXT, true);
        const payload: {
            practitioner_id: string;
            location?: string;
        } = {
            practitioner_id: practitionerId as string,
        };

        if (location) {
            payload.location = location;
        }

        return this.http
            .post<CustomApiModels.CallSession>(this.apiBase, payload, { context })
            .pipe(map(callSession => transformApiCallSession(callSession)));
    }

    /**
     * Get session by id
     *
     * @param session CallSession
     */
    getSession(session: CallSession): Observable<CallSession> {
        const context = new HttpContext().set(IS_TELEMEDICINE_CONTEXT, true);
        return this.http
            .get<CustomApiModels.CallSession>(`${this.apiBase}${session.id}/`, { context })
            .pipe(map(callSession => transformApiCallSession(callSession)));
    }
}
