import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { BaseMedication } from '@mona/models';
import {
    transformApiStandardMedication,
    transformApiStandardMedications,
    transformToApiStandardMedications,
} from './transforms';

/**
 * API abstraction layer for the medication prescriptions API
 */
@Injectable({
    providedIn: 'root',
})
export class StandardMedicationsApi {
    apiBase = '/pdms/standard-medication/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get Standard medication set
     */
    getStandardMedications(): Observable<BaseMedication[]> {
        return this.http.get<ApiModels.StandardMedicationSet[]>(this.apiBase).pipe(
            map(apiMedications => {
                try {
                    return transformApiStandardMedications(apiMedications);
                } catch (error) {
                    return [];
                }
            }),
        );
    }

    /**
     * Get Standard medication set by medication code
     *
     * @param uuid
     * @param asGroup
     */
    getStandardMedicationsByMedication(uuid: string, asGroup = false): Observable<BaseMedication[]> {
        const params = {} as any;
        if (asGroup) {
            params.medication_group_id = uuid;
        } else {
            params.medication_code = uuid;
        }
        return this.http
            .get<ApiModels.StandardMedicationSet[]>(this.apiBase, {
                params,
            })
            .pipe(
                map(apiMedications => {
                    try {
                        return transformApiStandardMedications(apiMedications);
                    } catch (error) {
                        return [];
                    }
                }),
            );
    }

    /**
     * createStandardMedications
     *
     * @param payload
     */
    createStandardMedication(payload: Omit<BaseMedication, 'id'>) {
        const body = transformToApiStandardMedications(payload);

        return this.http
            .post<ApiModels.StandardMedicationSet>(this.apiBase, body)
            .pipe(map(apiMedication => transformApiStandardMedication(apiMedication)));
    }

    /**
     * updateStandardMedications
     *
     * @param payload
     */
    updateStandardMedication(payload: BaseMedication) {
        const body = transformToApiStandardMedications(payload);

        return this.http
            .put<ApiModels.StandardMedicationSet>(`${this.apiBase}${payload.id}`, body)
            .pipe(
                map((apiMedication: ApiModels.StandardMedicationSet) => transformApiStandardMedication(apiMedication)),
            );
    }

    /**
     * removeStandardMedications
     *
     * @param id
     */
    removeStandardMedication(id: EntityId<BaseMedication>) {
        return this.http.delete<void>(`${this.apiBase}${id}`);
    }
}
