/** Layout events enum   */
export enum LayoutEventType {
    LogOut = 'logout',
    DblCkickLogo = 'dblCkickLogo',
}

/**
 * Creates a new layout service event.
 */
export class LayoutEvent {
    /**
     * @param eventType Name of the event.
     * @param value Any value to pass as part of the event.
     */
    constructor(public eventType: LayoutEventType, public value?: any) {}
}
