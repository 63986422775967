/* eslint-disable @typescript-eslint/ban-types */
import { isDevMode } from '@angular/core';
import { Action, ActionReducer, INIT } from '@ngrx/store';
import { Logger } from '@mona/shared/logger';
import { noop, pick, stringify } from '@mona/shared/utils';
import { AppState } from './state/index.state';

/**
 * Amend the initial state with the stored state and return it
 *
 * @param initialState initialState
 * @returns Any
 */
export function mergeWithStoredState<T>(initialState): T {
    const storedData = { state: {} }; // getHmrData();
    return storedData && storedData.state
        ? {
              ...initialState,
              ...storedData.state,
          }
        : initialState;
}

/**
 * Initial state factory
 *
 * @param initialReducer initialReducer
 * @param reducerFactory reducerFactory
 */
export function initialStateFactory(initialReducer, reducerFactory) {
    return mergeWithStoredState<AppState>(reducerFactory(initialReducer)(undefined, { type: INIT }));
}

/**
 * A meta reducer used for clearing app state on logout
 *
 * @param reducer reducer
 */
export function clearState(reducer) {
    return function (state, action) {
        const assignedEncounterId = localStorage.getItem('pdms.assignedEncounterId');
        const isElectron = window.electron !== undefined;

        if (action.type === 'APP_STATE:CLEAR_STATE') {
            state = isElectron // TODO: do we need to clear specific if assigned encounter on/off ?
                ? { ...state }
                : pick(state, 'config', 'ui', 'api', 'auth', 'router', 'device', 'terminology');
        }

        return reducer(state, action);
    };
}

export const ACTIONS_BLOCK_LIST = ['@ngrx/store/init', '@ngrx/store-devtools/recompute'];

export const ACTIONS_SKIP_LIST = [
    '@ngrx/store/init',
    '@ngrx/effects/init',
    '@ngrx/store-devtools/recompute',
    '@ngrx/store/update-reducers',
    '@ngrx/router-store/navigation',
    '@ngrx/router-store/request',
    'API:LOAD_HEALTH',
    'API:LOAD_HEALTH_SUCCESS',
];

/**
 * console.log all actions
 *
 * @param reducer
 */
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    const logger = new Logger('STATE');
    return function (state: AppState, action: Action): AppState {
        let { type, ...payload } = action;
        if (ACTIONS_SKIP_LIST.includes(type) || type === 'EMPTY') {
            noop();
        } else {
            if (
                type === '@ngrx/router-store/navigated' ||
                type === '@ngrx/router-store/cancel' ||
                type === '@ngrx/router-store/error'
            ) {
                type = `ROUTER_${type.split('/')[2].toUpperCase()}`;
                payload = (payload as AnyObject)['payload']['event'];
            }
            if (
                type === 'ENCOUNTERS:SET_CURRENT_ENCOUNTER' ||
                type === 'WARDS:SELECTED_BED' ||
                type.toLowerCase().endsWith('success') ||
                (type.toLowerCase().endsWith('succeeded') && !type.includes('DEVICE') && !type.includes('CONFIG'))
            ) {
                // TODO: obfuscate sensitive data here
                payload = { __OBFUSCATED__: true };
            }
            if (type.toLowerCase().endsWith('failure') || type.toLowerCase().endsWith('failed')) {
                // delete payload['error']?.['originalError'];
                const { originalError, ...rest } = (payload as any).error;
                payload = rest;
            }
            logger.log(type + '::' + stringify(payload));
        }

        return reducer(state, action);
    };
}

export const META_REDUCERS = [clearState];

if (!isDevMode()) {
    META_REDUCERS.push(logger);
}
