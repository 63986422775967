import { VitalSignCodes } from './vital-sign-codes.enum';

export const VitalSignColorCodeMap: Map<VitalSignCodes, string> = new Map([
    [VitalSignCodes.NBIP, '#8024AB'],
    [VitalSignCodes.IBP, '#EF0000'],
    [VitalSignCodes.HEART_RATE, '#00B500'],
    [VitalSignCodes.OX_SAT, '#00D0E0'],
    [VitalSignCodes.CVP, '#9E9E9E'],
    [VitalSignCodes.TEMP, '#000000'],
    [VitalSignCodes.ICP, '#FFC700'],
    [VitalSignCodes.RESP_RATE, '#0047AB'],
    [VitalSignCodes.ABP, '#D10000'],
    [VitalSignCodes.ART, '#B30000'],
]);

export const SystolicSystemCodes = [
    VitalSignCodes.NIBP_SYS,
    VitalSignCodes.IBP_SYS,
    VitalSignCodes.ABP_SYS,
    VitalSignCodes.ART_SYS,
];

export const DiastolicSystemCodes = [
    VitalSignCodes.NIBP_DIA,
    VitalSignCodes.IBP_DIA,
    VitalSignCodes.ABP_DIA,
    VitalSignCodes.ART_DIA,
];

/** Should be excluded from table  */
export const VITALS_CODES_EXCLUDED = [
    VitalSignCodes.M_NIBP,
    VitalSignCodes.M_IBP,
    VitalSignCodes.M_ART,
    VitalSignCodes.M_ABP,
] as string[];
