import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { FrequencyTimesComponent } from './frequency-times.component';

/**
 * Frequency Times Module
 */
@NgModule({
    declarations: [FrequencyTimesComponent],
    imports: [UiModule, KeyboardModule],
    exports: [FrequencyTimesComponent],
})
export class FrequencyTimesModule {}
