import { Pipe, PipeTransform } from '@angular/core';
import { TableDataItem, VentilationParameter, VitalSign } from '@mona/models';
import { TimeColumn } from '@mona/shared/date';
import { HasCellValuePipe } from './has-cell-value.pipe';

/**
 * Detects if day contains some values
 */
@Pipe({
    name: 'hasDayValues',
})
export class HasDayValuesPipe implements PipeTransform {
    /**
     * Constructor
     *
     * @param hasCellValuePipe HasCellValuePipe
     */
    constructor(private hasCellValuePipe: HasCellValuePipe) {}

    /**
     * Transform implementation
     *
     * @param dayCell TableDataItem<VitalSign>
     * @param dayTimes TimeColumn[]
     */
    transform(dayCell: TableDataItem<VitalSign | VentilationParameter>, dayTimes: TimeColumn[]): boolean {
        return !!dayTimes.find(timeColumn => this.hasCellValuePipe.transform(dayCell, timeColumn.isoDateString));
    }
}
