import { createAction, props } from '@ngrx/store';
import {
    BasicCareProcedureType,
    BloodAdministrationType,
    CareCheckType,
    DischargeReason,
    GlasgowComaScaleDataFlowsValues,
    LabValueType,
    Medication,
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationDosageForm,
    MedicationGroup,
    MedicationSolution,
    MedicationUnit,
    OutputFactor,
    PractitionerShift,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
    PrescriptionNotGivenReason,
    ProcedureCategory,
    RelationshipRoleType,
    SearchDiagnosisResult,
    TaskListFilter,
    TerminologyDailyGoal,
    VaccineCode,
    VentilationMode,
    VentilationParameterType,
    VitalSignType,
} from '@mona/models';
import { AsyncAction, FailedAction, SucceededAction } from '@mona/store';
import { TerminologyStateKeys } from '../../entities';

// TODO: refactor to normal actions without AsyncAction meta
/**
 * @deprecated
 * terminology service actions
 */
export class TerminologyActions {
    static invalidateCache = createAction('TERMINOLOGY:CLEAR_CACHE');
    /**
     * Search diagnosis action
     */
    static searchDiagnosisAction: AsyncAction<{ term: string }, SearchDiagnosisResult[]> = {
        action: createAction('TERMINOLOGY:SEARCH_DIAGNOSIS', props<{ term: string }>()),

        succeededAction: createAction(
            'TERMINOLOGY:SEARCH_DIAGNOSIS_SUCCEEDED',
            props<SucceededAction<SearchDiagnosisResult[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:SEARCH_DIAGNOSIS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:SEARCH_DIAGNOSIS_CLEAR'),
    };

    /**
     * Load vital sign types action
     */
    static loadVitalSignTypesAction: AsyncAction<{ params?: any }, VitalSignType[]> = {
        action: createAction('TERMINOLOGY:LOAD_VITAL_SIGN_TYPES', props<{ params?: any }>()),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_VITAL_SIGN_TYPES_SUCCEEDED',
            props<SucceededAction<VitalSignType[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_VITAL_SIGN_TYPES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_VITAL_SIGN_TYPES_CLEAR'),
    };

    /**
     * Load lab value types action
     */
    static loadLabValueTypesAction: AsyncAction<{ params?: any }, LabValueType[]> = {
        action: createAction('TERMINOLOGY:LOAD_LAB_VALUE_TYPES', props<{ params?: any }>()),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_LAB_VALUE_TYPES_SUCCEEDED',
            props<SucceededAction<LabValueType[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_LAB_VALUE_TYPES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_LAB_VALUE_TYPES_CLEAR'),
    };

    /**
     * Search lab value types action
     */
    static searchLabValueTypesAction: AsyncAction<{ name?: string; codes?: string[] }, LabValueType[]> = {
        action: createAction('TERMINOLOGY:SEARCH_LAB_VALUE_TYPES', props<{ name?: string; codes?: string[] }>()),

        succeededAction: createAction(
            'TERMINOLOGY:SEARCH_LAB_VALUE_TYPES_SUCCEEDED',
            props<SucceededAction<LabValueType[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:SEARCH_LAB_VALUE_TYPES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:SEARCH_LAB_VALUE_TYPES_CLEAR'),
    };

    /**
     * Load ventilation parameter types action
     */
    static loadVentilationParameterTypesAction: AsyncAction<{ params?: any }, VentilationParameterType[]> = {
        action: createAction('TERMINOLOGY:LOAD_VENTILATION_PARAMETER_TYPES', props<{ params?: any }>()),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_VENTILATION_PARAMETER_TYPES_SUCCEEDED',
            props<SucceededAction<VentilationParameterType[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_VENTILATION_PARAMETER_TYPES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_VENTILATION_PARAMETER_TYPES_CLEAR'),
    };

    /**
     * Load ventilation modes action
     */
    static loadVentilationModesAction: AsyncAction<{ params?: any }, VentilationMode[]> = {
        action: createAction('TERMINOLOGY:LOAD_VENTILATION_MODES', props<{ params?: any }>()),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_VENTILATION_MODES_SUCCEEDED',
            props<SucceededAction<VentilationMode[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_VENTILATION_MODES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_VENTILATION_MODES_CLEAR'),
    };

    /**
     * Load medication categories action
     */
    static loadMedicationCategoriesAction: AsyncAction<void, MedicationCategory[]> = {
        action: createAction('TERMINOLOGY:LOAD_MEDICATION_CATEGORIES'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_MEDICATION_CATEGORIES_SUCCEEDED',
            props<SucceededAction<MedicationCategory[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_MEDICATION_CATEGORIES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_MEDICATION_CATEGORIES_CLEAR'),
    };
    /**
     * Load medication Groups action
     */
    static loadMedicationGroupsAction: AsyncAction<void, MedicationGroup[]> = {
        action: createAction('TERMINOLOGY:LOAD_MEDICATION_GROUPS'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_MEDICATION_GROUPS_SUCCEEDED',
            props<SucceededAction<MedicationGroup[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_MEDICATION_GROUPS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_MEDICATION_GROUPS_CLEAR'),
    };
    /**
     * Load medication categories action
     */
    static loadMedicationUnitsAction: AsyncAction<void, MedicationUnit[]> = {
        action: createAction('TERMINOLOGY:LOAD_MEDICATION_UNITS'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_MEDICATION_UNITS_SUCCEEDED',
            props<SucceededAction<MedicationUnit[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_MEDICATION_UNITS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_MEDICATION_UNITS_CLEAR'),
    };
    /**
     * Load medication categories action
     */
    static loadMedicationSolutionsAction: AsyncAction<void, MedicationSolution[]> = {
        action: createAction('TERMINOLOGY:LOAD_MEDICATION_SOLUTIONS'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_MEDICATION_SOLUTIONS_SUCCEEDED',
            props<SucceededAction<MedicationSolution[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_MEDICATION_SOLUTIONS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_MEDICATION_SOLUTIONS_CLEAR'),
    };

    /**
     * Load procedure categories action
     */
    static loadProcedureCategoriesAction: AsyncAction<void, ProcedureCategory[]> = {
        action: createAction('TERMINOLOGY:LOAD_PROCEDURE_CATEGORIES'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_PROCEDURE_CATEGORIES_SUCCEEDED',
            props<SucceededAction<ProcedureCategory[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_PROCEDURE_CATEGORIES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_PROCEDURE_CATEGORIES_CLEAR'),
    };

    /**
     * Load prescription frequencies action
     */
    static loadPrescriptionFrequenciesAction: AsyncAction<{ codes?: string[] }, PrescriptionFrequency[]> = {
        action: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_FREQUENCIES', props<{ codes?: string[] }>()),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_PRESCRIPTION_FREQUENCIES_SUCCEEDED',
            props<SucceededAction<PrescriptionFrequency[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_FREQUENCIES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_FREQUENCIES_CLEAR'),
    };

    /**
     * Load medication administration methods action
     */
    static loadMedicationAdministrationMethodsAction: AsyncAction<
        { codes?: string[] },
        MedicationAdministrationMethod[]
    > = {
        action: createAction('TERMINOLOGY:LOAD_MEDICATION_ADMINISTRATION_METHODS', props<{ codes?: string[] }>()),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_MEDICATION_ADMINISTRATION_METHODS_SUCCEEDED',
            props<SucceededAction<MedicationAdministrationMethod[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_MEDICATION_ADMINISTRATION_METHODS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_MEDICATION_ADMINISTRATION_METHODS_CLEAR'),
    };

    /**
     * Create medication action
     */
    static createMedicationAction: AsyncAction<
        { medication: Medication; createAs: 'medications' | 'medication-groups' },
        Medication
    > = {
        action: createAction(
            'TERMINOLOGY:CREATE_MEDICATION',
            props<{ medication: Medication; createAs: 'medications' | 'medication-groups' }>(),
        ),

        succeededAction: createAction('TERMINOLOGY:CREATE_MEDICATION_SUCCEEDED', props<SucceededAction<Medication>>()),

        failedAction: createAction('TERMINOLOGY:CREATE_MEDICATION_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:CREATE_MEDICATION_CLEAR'),
    };

    /**
     * Load medications action
     */
    static loadMedicationsAction: AsyncAction<{ codes?: string[] }, Medication[]> = {
        action: createAction('TERMINOLOGY:LOAD_MEDICATIONS', props<{ codes?: string[] }>()),

        succeededAction: createAction('TERMINOLOGY:LOAD_MEDICATIONS_SUCCEEDED', props<SucceededAction<Medication[]>>()),

        failedAction: createAction('TERMINOLOGY:LOAD_MEDICATIONS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_MEDICATIONS_CLEAR'),
    };

    /**
     * Search medications action
     */
    static searchMedicationsAction: AsyncAction<
        { name?: string; searchBy?: 'medications' | 'medication-groups' },
        Medication[]
    > = {
        action: createAction(
            'TERMINOLOGY:SEARCH_MEDICATIONS',
            props<{ name?: string; searchBy?: 'medications' | 'medication-groups' }>(),
        ),

        succeededAction: createAction(
            'TERMINOLOGY:SEARCH_MEDICATIONS_SUCCEEDED',
            props<SucceededAction<Medication[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:SEARCH_MEDICATIONS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:SEARCH_MEDICATIONS_CLEAR'),
    };

    /**
     * Load output factors action
     */
    static loadOutputFactorsAction: AsyncAction<void, OutputFactor[]> = {
        action: createAction('TERMINOLOGY:LOAD_OUTPUT_FACTORS'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_OUTPUT_FACTORS_SUCCEEDED',
            props<SucceededAction<OutputFactor[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_OUTPUT_FACTORS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_OUTPUT_FACTORS_CLEAR'),
    };

    /**
     * Load prescription frequency times action
     */
    static loadPrescriptionFrequencyTimesAction: AsyncAction<void, PrescriptionFrequencyTime[]> = {
        action: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_FREQUENCY_TIMES'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_PRESCRIPTION_FREQUENCY_TIMES_SUCCEEDED',
            props<SucceededAction<PrescriptionFrequencyTime[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_FREQUENCY_TIMES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_FREQUENCY_TIMES_CLEAR'),
    };

    /**
     * Load basic care procedure types action
     */
    static loadBasicCareProcedureTypesAction: AsyncAction<void, BasicCareProcedureType[]> = {
        action: createAction('TERMINOLOGY:LOAD_BASIC_CARE_PROCEDURE_TYPES'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_BASIC_CARE_PROCEDURE_TYPES_SUCCEEDED',
            props<SucceededAction<BasicCareProcedureType[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_BASIC_CARE_PROCEDURE_TYPES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_BASIC_CARE_PROCEDURE_TYPES_CLEAR'),
    };

    /**
     * Load practitioner shifts action
     */
    static loadPractitionerShiftsAction: AsyncAction<void, PractitionerShift[]> = {
        action: createAction('TERMINOLOGY:LOAD_PRACTITIONER_SHIFTS'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_PRACTITIONER_SHIFTS_SUCCEEDED',
            props<SucceededAction<PractitionerShift[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_PRACTITIONER_SHIFTS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_PRACTITIONER_SHIFTS_CLEAR'),
    };

    /**
     * Load care check types action
     */
    static loadCareCheckTypesAction: AsyncAction<{ params?: any }, CareCheckType[]> = {
        action: createAction('TERMINOLOGY:LOAD_CARE_CHECK_TYPES', props<{ params?: any }>()),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_CARE_CHECK_TYPES_SUCCEEDED',
            props<SucceededAction<CareCheckType[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_CARE_CHECK_TYPES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_CARE_CHECK_TYPES_CLEAR'),
    };

    /**
     * Load blood administration types action
     */
    static loadBloodAdministrationTypesAction: AsyncAction<void, BloodAdministrationType[]> = {
        action: createAction('TERMINOLOGY:LOAD_BLOOD_ADMINISTRATION_TYPES'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_BLOOD_ADMINISTRATION_TYPES_SUCCEEDED',
            props<SucceededAction<BloodAdministrationType[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_BLOOD_ADMINISTRATION_TYPES_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_BLOOD_ADMINISTRATION_TYPES_CLEAR'),
    };

    /**
     * Load discharge reasons action
     */
    static loadDischargeReasonsAction: AsyncAction<void, DischargeReason[]> = {
        action: createAction('DISCHARGE_REASON:LOAD_AVAILABLE_DISCHARGE_REASONS'),

        succeededAction: createAction(
            'DISCHARGE_REASON:LOAD_AVAILABLE_DISCHARGE_REASONS_SUCCEEDED',
            props<SucceededAction<DischargeReason[]>>(),
        ),

        failedAction: createAction('DISCHARGE_REASON:LOAD_AVAILABLE_DISCHARGE_REASONS_FAILED', props<FailedAction>()),

        clearAction: createAction('DISCHARGE_REASON:LOAD_AVAILABLE_DISCHARGE_REASONS_CLEAR'),
    };

    /**
     * Load dosage forms action
     */
    static loadDosageFormsAction: AsyncAction<void, MedicationDosageForm[]> = {
        action: createAction('DOSAGE_FORMS:LOAD_DOSAGE_FORMS'),

        succeededAction: createAction(
            'DOSAGE_FORMS:LOAD_DOSAGE_FORMS_SUCCEEDED',
            props<SucceededAction<MedicationDosageForm[]>>(),
        ),

        failedAction: createAction('DOSAGE_FORMS:LOAD_DOSAGE_FORMS_FAILED', props<FailedAction>()),

        clearAction: createAction('DOSAGE_FORMS:LOAD_DOSAGE_FORMS_CLEAR'),
    };

    /**
     * Load glasgow coma scale action
     */
    static loadGlasgowComaScaleAction: AsyncAction<void, GlasgowComaScaleDataFlowsValues> = {
        action: createAction('GLASGOW_COMA_SCALE:LOAD_GLASGOW_COMA_SCALE'),

        succeededAction: createAction(
            'GLASGOW_COMA_SCALE:LOAD_GLASGOW_COMA_SCALE_SUCCEEDED',
            props<SucceededAction<GlasgowComaScaleDataFlowsValues>>(),
        ),

        failedAction: createAction('GLASGOW_COMA_SCALE:LOAD_GLASGOW_COMA_SCALE_FAILED', props<FailedAction>()),

        clearAction: createAction('GLASGOW_COMA_SCALE:LOAD_GLASGOW_COMA_SCALE_CLEAR'),
    };

    static loadTaskListShiftFiltersAction: AsyncAction<void, TaskListFilter[]> = {
        action: createAction('TERMINOLOGY:LOAD_TASK_LIST_SHIFT_FILTERS'),
        succeededAction: createAction(
            'TERMINOLOGY:LOAD_TASK_LIST_SHIFT_FILTERS_SUCCEEDED',
            props<SucceededAction<TaskListFilter[]>>(),
        ),
        failedAction: createAction('TERMINOLOGY:LOAD_TASK_LIST_SHIFT_FILTERS_FAILED', props<FailedAction>()),
        clearAction: createAction('TERMINOLOGY:LOAD_TASK_LIST_SHIFT_FILTERS_CLEAR'),
    };

    static loadPrescriptionNotGivenReasonAction: AsyncAction<void, PrescriptionNotGivenReason[]> = {
        action: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_NOT_GIVEN_REASON'),
        succeededAction: createAction(
            'TERMINOLOGY:LOAD_PRESCRIPTION_NOT_GIVEN_REASON_SUCCEEDED',
            props<SucceededAction<PrescriptionNotGivenReason[]>>(),
        ),
        failedAction: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_NOT_GIVEN_REASON_FAILED', props<FailedAction>()),
        clearAction: createAction('TERMINOLOGY:LOAD_PRESCRIPTION_NOT_GIVEN_REASON_CLEAR'),
    };

    /**
     * Load daily goals terminology action
     */
    static loadDailyGoalsTerminologyAction: AsyncAction<void, TerminologyDailyGoal[]> = {
        action: createAction('TERMINOLOGY:LOAD_DAILY_GOALS'),

        succeededAction: createAction(
            'TERMINOLOGY:LOAD_DAILY_GOALS_SUCCEEDED',
            props<SucceededAction<TerminologyDailyGoal[]>>(),
        ),

        failedAction: createAction('TERMINOLOGY:LOAD_DAILY_GOALS_FAILED', props<FailedAction>()),

        clearAction: createAction('TERMINOLOGY:LOAD_DAILY_GOALS_CLEAR'),
    };

    static loadVaccineCodesAction: AsyncAction<void, VaccineCode[]> = {
        action: createAction('TERMINOLOGY:LOAD_VACCINE_CODES'),
        succeededAction: createAction(
            'TERMINOLOGY:LOAD_VACCINE_CODES_SUCCEEDED',
            props<SucceededAction<VaccineCode[]>>(),
        ),
        failedAction: createAction('TERMINOLOGY:LOAD_VACCINE_CODES_FAILED', props<FailedAction>()),
        clearAction: createAction('TERMINOLOGY:LOAD_VACCINE_CODES_CLEAR'),
    };

    static loadRelationshipRoleTypesAction: AsyncAction<void, RelationshipRoleType[]> = {
        action: createAction('TERMINOLOGY:LOAD_RELATIONSHIP_ROLE_TYPES'),
        succeededAction: createAction(
            'TERMINOLOGY:LOAD_RELATIONSHIP_ROLE_TYPES_SUCCEEDED',
            props<SucceededAction<RelationshipRoleType[]>>(),
        ),
        failedAction: createAction('TERMINOLOGY:LOAD_RELATIONSHIP_ROLE_TYPES_FAILED', props<FailedAction>()),
        clearAction: createAction('TERMINOLOGY:LOAD_RELATIONSHIP_ROLE_TYPES_CLEAR'),
    };
}
export const setTerminologyLoadedSuccess = createAction(
    'TERMINOLOGIES:SET_TERMINOLOGY_LOADED_SUCCESS',
    props<{
        /** terminologyName */ terminologyName: TerminologyStateKeys;
    }>(),
);
export const setTerminologyLastUpdated = createAction('TERMINOLOGIES:SET_TERMINOLOGY_LAST_UPDATED');
export const resetTerminologyLastUpdated = createAction('TERMINOLOGIES:RESET_TERMINOLOGY_LAST_UPDATED');
