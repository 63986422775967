import { isAfter } from 'date-fns';
import { DateRangeInterval } from '@mona/shared/date';
import { UiDynamicElement, UiDynamicElementConfig } from '@mona/ui';

/* eslint-disable jsdoc/require-jsdoc */
export const DATE_RANGE_FORM_CONFIG: UiDynamicElementConfig[] = [
    {
        name: 'day',
        label: 'dateRangeDialog.label.selectDate',
        placeholder: 'dateRangeDialog.label.selectDate',
        type: UiDynamicElement.Datepicker,
        required: true,
        flex: 50,
        customConfig: {
            customClass: ['app-input-field'],
            format: 'dd/MM/yyyy',
        },
    },
    {
        name: 'range',
        label: 'dateRangeDialog.label.selectTime',
        placeholder: 'dateRangeDialog.label.selectTime',
        type: UiDynamicElement.Select,
        dataSource: undefined,
        required: true,
        flex: 50,
        customConfig: {
            customClass: ['app-input-field'],
            disabledGetterFn: (selection: { value: DateRangeInterval }) => {
                return isAfter(selection.value?.start, new Date());
            },
            compareFn: (a: DateRangeInterval, b: DateRangeInterval) => {
                if (a && b) {
                    return a.start.getTime() === b.start.getTime();
                }
            },
        },
    },
];
/* eslint-enable jsdoc/require-jsdoc */
