/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { encounterDataFeatureKey, EncounterFeatureState, SurgeryPrescriptionState } from '../../entities';
import { surgeryPrescriptionAdapter } from '../reducers/surgery-prescription.reducer';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectSurgeryPrescriptionState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.surgeryPrescription,
);

export const {
    selectIds: selectSurgeryPrescriptionIds,
    selectEntities: selectSurgeryPrescriptionsEntities,
    selectAll: selectSurgeryPrescriptionAll,
    selectTotal: selectSurgeryPrescriptionTotal,
} = surgeryPrescriptionAdapter.getSelectors(selectSurgeryPrescriptionState);

export const selectSurgeryPrescriptionEntities = createSelector(
    selectSurgeryPrescriptionState,
    (state: SurgeryPrescriptionState) => state.entities,
);

export const selectSurgeryPrescriptionIsLoading = createSelector(
    selectSurgeryPrescriptionState,
    (state: SurgeryPrescriptionState) => state.isLoading,
);
