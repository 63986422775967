import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { changeLogFeatureKey } from './entities';
import { ChangeLogEffects, changeLogReducerMap, CHANGELOG_REDUCER_TOKEN } from './state';

/**
 * Data-access changelog module
 */
@NgModule({
    imports: [
        StoreModule.forFeature(changeLogFeatureKey, CHANGELOG_REDUCER_TOKEN),
        EffectsModule.forFeature([ChangeLogEffects]),
    ],
    providers: [
        {
            provide: CHANGELOG_REDUCER_TOKEN,
            useFactory: () => changeLogReducerMap,
        },
        // FIXME: TODO: don't need this interceptor atm
        // if we need it in the future, it should be changed to check whole payload not only lastChagedAt as it is not accurate
        // and can cause previnting valid requests like - grouped table values or some bulk(simultaneous) changelog requests
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ChangeLogRequestsInterceptor,
        //     multi: true,
        // },
    ],
})
export class DataAccessChangelogModule {
    /** @ignore */
    constructor(@Optional() @SkipSelf() parentModule: DataAccessChangelogModule) {
        if (parentModule) {
            const msg = `DataAccessChangelogModule has already been loaded.
                Import DataAccessChangelogModule once, only, in the root AppModule.`;
            throw new Error(msg);
        }
    }
}
