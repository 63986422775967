/* eslint-disable @angular-eslint/no-output-rename */
import { Component, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { MessageService } from '../../services';
import { UiMessageComponent } from '../message';

/** @ignore */
@Injectable()
export class PagerMatPaginatorIntl implements MatPaginatorIntl {
    changes = new Subject<void>();

    firstPageLabel = this.translateService.instant(`First page`);
    itemsPerPageLabel = this.translateService.instant(`Items per page:`);
    lastPageLabel = this.translateService.instant(`Last page`);
    nextPageLabel = this.translateService.instant(`commonStrings.keys.next`);
    previousPageLabel = this.translateService.instant(`commonStrings.keys.previous`);

    /** @ignore */
    constructor(private translateService: TranslateService) {}

    /** @ignore */
    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return '';
        }
        const amountPages = Math.ceil(length / pageSize);
        return `${page + 1} / ${amountPages}`;
    }
}
/**
 * UiAlertsPager Component
 */
@TakeUntilDestroy
@Component({
    selector: 'ui-alerts-pager',
    template: `
        <mat-paginator
            [class.d-none]="total <= 1"
            class="alerts-pager-control"
            [color]="undefined"
            [length]="total"
            [pageIndex]="currentAlertIndex"
            [pageSize]="1"
            [hidePageSize]="true"
            [showFirstLastButtons]="false"
            (page)="onPage($event)"
        ></mat-paginator>
    `,
    host: { '[class.ui-alerts-pager]': 'true' },
    providers: [{ provide: MatPaginatorIntl, useClass: PagerMatPaginatorIntl, deps: [TranslateService] }],
})
export class UiAlertsPagerComponent implements OnInit, OnDestroy {
    /**
     * Alerts count
     */
    get total(): number {
        return this.service.totalAlerts;
    }

    /**
     * Input/Output to support two way binding on current alert instance
     */
    @Input('uiCurrentAlert')
    set currentAlert(alert: UiMessageComponent) {
        if (alert) {
            this.service.currentAlert = alert;
        }
    }
    /**
     * Current alert
     */
    get currentAlert() {
        return this.service.currentAlert;
    }

    @Output('uiCurrentAlertChange') currentAlertChange = new EventEmitter<UiMessageComponent>(false);

    /**
     * Input/Output to support two way binding on current alert index
     */
    @Input('uiCurrentAlertIndex')
    set currentAlertIndex(index: number) {
        this.service.currentIndex = index;
    }
    /**
     * Current alert index
     */
    get currentAlertIndex() {
        return this.service.currentIndex;
    }

    @Output('uiCurrentAlertIndexChange') currentAlertIndexChange = new EventEmitter<number>();

    /** @ignore */
    constructor(public readonly service: MessageService) {}

    /**
     * Subscribe to changes to re-emit current
     */
    ngOnInit() {
        this.service.changes.pipe(takeUntilDestroy(this)).subscribe(index => {
            this.currentAlertIndexChange.emit(index);
            this.currentAlertChange.emit(this.service.activeAlerts[index]);
        });
    }

    /**
     * Event object that is emitted when the user selects a
     * different page size or navigates to another page.
     */
    onPage({ pageIndex, previousPageIndex }: PageEvent) {
        pageIndex - previousPageIndex > 0 ? this.service.next() : this.service.previous();
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, jsdoc/require-jsdoc, @typescript-eslint/no-empty-function
    ngOnDestroy(): void {}
}
