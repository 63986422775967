import { ChangeLogEntry, ChangeLogModel, PersistChangesResult } from '@mona/models';
import { AsyncActionState } from '@mona/store';

export const changeLogFeatureKey = 'changelogData';

/**
 * Change log map
 */
export interface ChangeLogMap {
    /**
     * Change log entry
     * modelName is of type ChangeLogModelKey
     */
    [modelName: string]: ChangeLogEntry<ChangeLogModel>[];
}

/**
 * Change log
 */
export interface ChangeLog {
    /**
     * Changes map
     */
    changesMap: ChangeLogMap;

    /**
     * Sorted changes
     */
    sortedChanges: ChangeLogEntry<ChangeLogModel>[];
}

/**
 * Change log state
 */
export interface ChangeLogState {
    /**
     * Change log
     */
    changeLog: ChangeLog;

    /**
     * Load change log action
     */
    loadChangesAction: AsyncActionState<ChangeLogEntry<ChangeLogModel>[]>;

    /**
     * Action to save a change
     */
    saveChangeAction: AsyncActionState<ChangeLogEntry<ChangeLogModel>>;

    /**
     * Action to save changes
     */
    saveChangesAction: AsyncActionState<ChangeLogEntry<ChangeLogModel>[]>;

    /**
     * Persist changes action
     */
    persistChangesAction: AsyncActionState<PersistChangesResult>;

    /**
     * Discard changes action
     */
    discardChangesAction: AsyncActionState<void>;
}
