import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { patientsFeatureKey } from './entities/patient.state';
import { PatientsEffects } from './state/effects';
import { patientsReducer } from './state/reducers/patients.reducer';

/**
 * INFO: add comment
 */
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(patientsFeatureKey, patientsReducer),
        EffectsModule.forFeature([PatientsEffects]),
    ],
})
export class DataAccessPatientsModule {}
