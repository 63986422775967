import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { UtilsModule } from '@mona/shared/utils';
import { DrawerNavGroupComponent } from './drawer-nav-group.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [DrawerNavGroupComponent],
    imports: [UtilsModule, MatDividerModule, MatListModule],
    exports: [DrawerNavGroupComponent],
})
export class DrawerNavGroupModule {}
