import { HttpContextToken, HttpRequest } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { SubscribableOrPromise } from 'rxjs';
import { SetOptional } from 'type-fest';

/** Token to mark request context as telemedicine  */
export const IS_TELEMEDICINE_CONTEXT = new HttpContextToken<boolean>(() => false);
/** Api HttpRequest-like argument */
export type HttpRequestLike = SetOptional<Pick<HttpRequest<any>, 'url' | 'method' | 'context'>, 'method' | 'context'>;
/**
 * Custom interceptor headers
 */
export const CUSTOM_INTERCEPTOR_HEADERS = new InjectionToken<Record<string, string>>('CustomInterceptorHeaders');
/**
 * WebSocket enpoints to check
 *
 * @example ```['api.coreWsUrl', 'api.telemedicineWsUrl']```
 */
export const WS_ENDPOINTS_LIST = new InjectionToken<WsEndpointsList[]>('WebSocket enpoints to check');
/** WsEndpointsList Type */
export type WsEndpointsList = 'api.coreWsUrl' | 'api.telemedicineWsUrl';
