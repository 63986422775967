import { ApiModels } from '@mona/api';
import { TerminologyDailyGoal } from '@mona/models';

/**
 * Transforms api discharge
 *
 * @param apiDailyGoal
 */
export const transformApiDailyGoal = (apiDailyGoal: ApiModels.TerminologyDailyGoal): TerminologyDailyGoal => {
    return ApiModels.TerminologyDailyGoal.DTO.toModel(apiDailyGoal);
};

/**
 * Transform api reports
 *
 * @param apiDailyGoals any
 */
export const transformApiDailyGoals = (apiDailyGoals: any[]): TerminologyDailyGoal[] => {
    return apiDailyGoals.map(apiDailyGoal => transformApiDailyGoal(apiDailyGoal));
};
