/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { mixinControlValueAccessor } from './control-value-accesor.mixin';
import { mixinDisableRipple } from './disable-ripple.mixin';
import { mixinDisabled } from './disabled.mixin';
import { mixinMatFormFieldControl } from './mat-form-field.mixin';

/**
 * Base component class with CdRef
 */
class BaseWithCdRef {
    constructor(public cdRef: ChangeDetectorRef) {}
}
/**
 * Base class as extended {@link ControlValueAccessor}
 *
 * Remove the `NG_VALUE_ACCESSOR` provider - we instead set the value accessor directly with `NgControl`
 */
const BaseCVA = mixinControlValueAccessor(BaseWithCdRef);
/**
 * Base class as extended {@link ControlValueAccessor}, no ngControl assignment
 */
const BaseCVASimple = mixinControlValueAccessor(BaseWithCdRef, true);
/**
 * Base class as disableable
 */
const BaseCanDisable = mixinDisabled(BaseWithCdRef);
/**
 * Base class as extended {@link ControlValueAccessor} with disabled state
 */
const BaseCVACanDisable = mixinDisabled(mixinControlValueAccessor(BaseWithCdRef));
/**
 * Base class as extended {@link ControlValueAccessor} as MatInput to augment a component as {@link MatFormFieldControl}
 */
const BaseCVAMaterial = mixinMatFormFieldControl(mixinDisabled(mixinControlValueAccessor(BaseWithCdRef)));

export {
    BaseCVA,
    BaseCVASimple,
    BaseCanDisable,
    BaseCVACanDisable,
    BaseCVAMaterial,
    mixinControlValueAccessor,
    mixinDisabled,
    mixinDisableRipple,
};
