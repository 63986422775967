import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { EnovacomParams } from '@mona/models';
// INFO: this `eslint-disable` is allowed, to avoid circular dependency caused by barrel files
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EnovacomComponent } from '@mona/pdms/enovacom';
import { DialogService, MessageService } from '@mona/ui';
import { EnovacomApi } from '../../infrasctructure';
import * as EnovacomActions from '../actions/enovacom.actions';

/**
 * Enovacom effects
 */
@Injectable()
export class EnovacomEffects {
    getToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EnovacomActions.getEnovacomToken),
            switchMap(({ bed, ward, caseId }) =>
                this.enovacomApi.getToken().pipe(
                    map(token => EnovacomActions.getEnovacomTokenSuccess({ token, ward, bed, caseId })),
                    catchError((error: unknown) => of(EnovacomActions.getEnovacomTokenFailure({ error }))),
                ),
            ),
        ),
    );

    getTokenSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(EnovacomActions.getEnovacomTokenSuccess),
                tap(params => {
                    const safeUrl = this._constructEnovacomUrl(params);
                    this.dialogService.open(
                        EnovacomComponent,
                        { safeUrl },
                        {
                            maxWidth: '100%',
                        },
                    );
                }),
            ),
        { dispatch: false },
    );

    getTokenFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(EnovacomActions.getEnovacomTokenFailure),
                tap(err => {
                    this.messageService.warnToast(
                        `Enovacom dialog can't be opened for this bed, ERROR: ${JSON.stringify(err)}`,
                    );
                }),
            ),
        { dispatch: false },
    );

    /**
     * constructor
     *
     * @param actions$
     * @param enovacomApi
     * @param dialogService
     * @param messageService
     * @param sanitizer
     * @param configService
     */
    constructor(
        private actions$: Actions,
        private enovacomApi: EnovacomApi,
        private dialogService: DialogService,
        private messageService: MessageService,
        private sanitizer: DomSanitizer,
        private configService: ConfigService,
    ) {}

    /**
     * Construct enovacom URL
     *
     * @param params EnovacomParams
     */
    private _constructEnovacomUrl(params: EnovacomParams): SafeResourceUrl {
        const { token, ward, bed, caseId } = params;
        const url = `${this.configService.get(
            'enovacom.baseUrl',
        )}/enovacom-patient-connect-integration/?tokenId=${token}#!/pointsOfCare/${ward}/iep/${
            environment.monaPdmsPatientPrefix
        }${caseId}?room=${ward}&bed=${bed}`;

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
