import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DrawerService } from './drawer.service';
import { StateListener } from './state-listener.component';

/**
 * [Drawer Component](https://brightlayer-ui-components.github.io/angular/?path=/info/components-drawer--readme)
 *
 * The `<ui-drawer>` is used to organize content (typically navigation links) within a `<ui-drawer-layout>`.
 * The `<ui-drawer>` includes helper components for `<ui-drawer-header>`, `<ui-drawer-subheader>`, `<ui-drawer-body>`, `<ui-drawer-nav-group>`, `<ui-drawer-footer>`, and `<ui-drawer-layout>` to help organize the content.
 */
@Component({
    selector: 'ui-drawer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    template: `
        <div
            class="ui-drawer-content-container"
            [class.ui-drawer-side-border]="sideBorder"
            [class.mat-elevation-z6]="!sideBorder"
            [class.ui-drawer-condensed-rail]="condensed"
            [class.ui-drawer-collapse]="!isOpen()"
            [class.ui-drawer-temp-variant]="isTemporaryVariant()"
        >
            <!-- Drawer is responsible for managing the styles between the 4 subsections -->
            <ng-content select="ui-drawer-header"></ng-content>
            <div class="ui-drawer-hover-area" (mouseenter)="hoverDrawer()" (mouseleave)="unhoverDrawer()">
                <ng-content select="ui-drawer-subheader"></ng-content>
                <ng-content select="ui-drawer-body"></ng-content>
                <ng-content select="ui-drawer-footer"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./drawer.component.scss'],
    host: {
        class: 'ui-drawer',
    },
})
export class DrawerComponent extends StateListener implements OnInit, OnChanges {
    /**
     * Enables a condensed view for the `rail` variant which removes NavItem labels and shows tooltips instead
     *
     * @default false
     */
    @Input() condensed = false;
    /**
     * Hides tooltips on hover for the `rail` variant
     *
     * @default false
     */
    @Input() disableRailTooltip = false;
    /**
     * If true, NavItems will not have a bold title when a child NavItem is selected
     *
     * @default false
     */
    @Input() disableActiveItemParentStyles = false;
    /** State for the drawer */
    @Input() open: boolean;
    /** Automatically open the drawer on hover when closed (persistent variant only) */
    @Input() openOnHover = true;
    /**
     * Delay in milliseconds before a hover event opens the drawer (persistent variant only)
     *
     * @default 500
     */
    @Input() openOnHoverDelay = 500;
    /**
     * Toggle a side border instead of shadow
     *
     * @default false
     */
    @Input() sideBorder = false;

    private hoverDelayTimeout: any;
    private drawerSelectionListener: Subscription;

    /**
     * Toggle drawer on keypress
     *
     * @param $event
     */
    @HostListener('window:keydown.control.\\', ['$event'])
    onKeyDown($event: any) {
        this.drawerService.toggleDrawer();
    }

    /**
     * Constructor
     *
     * @param drawerService
     * @param cdRef
     */
    constructor(public readonly drawerService: DrawerService, private cdRef: ChangeDetectorRef) {
        super(drawerService, cdRef);
    }

    /**
     * Lifecycle
     */
    ngOnInit(): void {
        this.drawerService.setDrawerOpen(this.open);
        this.listenForDrawerChanges();
        this.listenForDrawerSelection();
    }

    /**
     * This broadcasts changes to all of the drawer state listeners.
     */
    ngOnChanges(): void {
        this.drawerService.setSideBorder(this.sideBorder);
        this.drawerService.setDrawerOpen(this.open);
        this.drawerService.setIsCondensed(this.condensed);
        this.drawerService.setDisableRailTooltip(this.disableRailTooltip);
        this.drawerService.setDisableActiveItemParentStyles(this.disableActiveItemParentStyles);
        this.drawerService.setOpenOnHover(this.openOnHover);
    }

    /**
     * Hover drawer
     */
    hoverDrawer(): void {
        if (!this.open && this.openOnHover) {
            this.hoverDelayTimeout = setTimeout(() => {
                this.drawerService.setDrawerTempOpen(true);
                this.changeDetector.detectChanges();
            }, this.openOnHoverDelay);
        }
    }

    /**
     * Unhover drawer
     */
    unhoverDrawer(): void {
        if (this.openOnHover) {
            clearTimeout(this.hoverDelayTimeout);
            if (this.drawerService.isTempOpen()) {
                this.drawerService.setDrawerTempOpen(false);
                this.changeDetector.detectChanges();
            }
        }
    }

    // Close drawer on selection if drawer is only temporarily opened.
    // Expanding nested navitems when temporarily opened will not close the drawer.
    /**
     * Listen for drawer selection
     */
    listenForDrawerSelection(): void {
        this.drawerSelectionListener = this.drawerService.drawerSelectionChanges().subscribe(hasChildren => {
            if (this.drawerService.isTempOpen() && !hasChildren) {
                this.drawerService.setDrawerTempOpen(false);
                this.changeDetector.detectChanges();
            }
        });
    }

    /**
     * Is temporary variant
     */
    isTemporaryVariant(): boolean {
        return this.drawerService.getDrawerVariant() === 'temporary';
    }
}
