import { ApiModels } from '@mona/api';
import { VaccineCode } from '@mona/models';

/**
 * Transforms api vaccine code
 *
 * @param apiVaccineCode
 */
export const transformApiVaccineCode = (apiVaccineCode: ApiModels.VaccineCode): VaccineCode => {
    return ApiModels.VaccineCode.DTO.toModel(apiVaccineCode);
};

/**
 * Transform api vaccine codes
 *
 * @param apiVaccineCodes
 */
export const transformApiVaccineCodes = (apiVaccineCodes: ApiModels.VaccineCode[]): VaccineCode[] => {
    return apiVaccineCodes.map(apiTaskListFilter => transformApiVaccineCode(apiTaskListFilter));
};
