import { createSelector } from '@ngrx/store';
import { DeviceState, SetupState } from '../../entities';
import { selectDeviceState } from './device.selectors';

export const selectSetupState = createSelector(selectDeviceState, (state: DeviceState) => state.setup);

export const selectDeviceSetupStatus = createSelector(selectSetupState, (state: SetupState) => state.status);
export const selectDeviceRegistrationSent = createSelector(
    selectSetupState,
    (state: SetupState) => state.isRegistrationSent,
);
export const selectDeviceActivated = createSelector(selectSetupState, (state: SetupState) => state.isActivated);
export const selectDeviceSetupLoading = createSelector(selectSetupState, (state: SetupState) => state.isLoading);
export const selectDeviceSetupError = createSelector(selectSetupState, (state: SetupState) => state.error);
