import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { UiModule } from '@mona/ui';
import { MedicationModule } from '../medication';
import { ProcedureModule } from '../procedure';
import { AddPrescriptionSidenavComponent } from './add-prescription-sidenav.component';

/** AddPrescriptionBar */
@NgModule({
    imports: [UiModule, MedicationModule, ProcedureModule, MatTabsModule, MatRippleModule],
    exports: [AddPrescriptionSidenavComponent],
    declarations: [AddPrescriptionSidenavComponent],
})
export class AddPrescriptionSidenavModule {}
