<!-- Block shown in table row when there is no matching data that will be provided to the wrapper table. -->
<ng-container [ngTemplateOutlet]="noDataTemplate || defaultNoDataTemplate"></ng-container>
<ng-template #defaultNoDataTemplate>
    <figure class="ui-empty-state__container {{ customClass }}">
        <img [alt]="title | translate" [src]="imgSrc" class="ui-empty-state__img" />
        <figcaption class="ui-empty-state__title">
            {{ title | translate }}
        </figcaption>
        <h5 class="ui-empty-state__subtitle">{{ subTitle | translate }}</h5>
        <button
            *ngIf="ctaTitle"
            mat-raised-button
            color="primary"
            class="ui-empty-state__cta"
            (click)="ctaClick.emit()"
            uiDisableWhen
            [checkPermission]="ctaPermission"
        >
            {{ ctaTitle | translate }}
        </button>
    </figure>
</ng-template>
