import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { proceduresStateKey } from './entities';
import { proceduresReducerMap, ProceduresEffects, ProcedurePrescriptionsEffects } from './state';

/**
 * DataAccessProceduresModule
 *
 * combines `procedures` & `procedurePrescriptions` feature states
 */
@NgModule({
    imports: [
        StoreModule.forFeature(proceduresStateKey, proceduresReducerMap),
        EffectsModule.forFeature([ProceduresEffects, ProcedurePrescriptionsEffects]),
    ],
})
export class DataAccessProceduresModule {}
