import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Encounter, PageableModel, ParamsModel, VitalSign } from '@mona/models';
import { transformApiVitalSigns } from './transforms';

const baseApi = `/pdms`;

/**
 * API abstraction layer for the vital signs API
 */
@Injectable({
    providedIn: 'root',
})
export class VitalSignsApi {
    private readonly apiV1 = `${baseApi}/vital-signs/`;
    private readonly apiV2 = `${baseApi}/v2/vital-signs/`;

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get vital signs
     *
     * @deprecated
     * @param encounter_id EntityId<Encounter>
     * @param codes string[]
     */
    getVitalSigns(encounter_id: EntityId<Encounter>, codes?: string[]): Observable<VitalSign[]> {
        const params = HttpService.buildHttpParams({
            encounter_id,
            codes,
        });

        return this.http
            .get<ApiModels.VitalSign[]>(this.apiV1, { params })
            .pipe(map(apiVitalSign => transformApiVitalSigns(apiVitalSign)));
    }

    /**
     * Get Pageable Vital Signs
     *
     * @param params
     * @param nextUrl
     */
    getPageableVitalSigns(params?: ParamsModel, nextUrl = this.apiV2): Observable<any> {
        return this.http.get<PageableModel<ApiModels.VitalSign>>(nextUrl, { params: { ...params } }).pipe(
            map(data => {
                return {
                    ...data,
                    results: transformApiVitalSigns(data.results),
                };
            }),
            concatMap(res => {
                if (res.next) {
                    return this.getPageableVitalSigns(null, res.next).pipe(
                        map(nextData => ({ ...nextData, results: [...res.results, ...nextData.results] })),
                    );
                } else {
                    return of(res);
                }
            }),
        );
    }
}
