/**
Get callsites from the V8 stack trace API.
 
@borrows https://github.com/sindresorhus/callsites#readme
@returns An array of `CallSite` objects.
@example```
 
function unicorn() {
	log(callsites()[0].getFileName());
	//=> '/Users/sindresorhus/dev/callsites/test.js'
}
```
 */
export function callsites(): NodeJS.CallSite[] {
    const _stackTraceLimit = Error.stackTraceLimit;
    const _prepareStackTrace = Error.prepareStackTrace;
    Error.prepareStackTrace = (_, stack) => stack;
    /// TODO: eslint-disable-line unicorn/error-message
    const stack = new Error().stack.slice(1) as unknown as NodeJS.CallSite[];
    Error.prepareStackTrace = _prepareStackTrace;
    Error.stackTraceLimit = _stackTraceLimit;
    return stack;
}
