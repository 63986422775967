import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PendingChangesEmitType } from '@mona/models';

/**
 * INFO: Pending Changes Dialog Component
 */
@Component({
    selector: 'mona-pending-changes-dialog',
    templateUrl: './pending-changes-dialog.component.html',
    styleUrls: ['./pending-changes-dialog.component.scss'],
})
export class PendingChangesDialogComponent {
    alertIcon = 'assets/src/images/alert.svg';
    pendingChangesEmiType = PendingChangesEmitType;

    /**
     * constructor
     *
     * @param dialogRef
     * @param data
     */
    constructor(
        private dialogRef: MatDialogRef<PendingChangesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    /**
     * close dialog with type
     *
     * @param type PendingChangesEmitType
     */
    closeDialogWithType(type: PendingChangesEmitType): void {
        this.dialogRef.close(type);
    }
}
