import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@mona/shared/utils';

/**
 * Mic button
 */
@Component({
    standalone: true,
    selector: 'mona-mic-button',
    templateUrl: './mic-button.component.html',
    styleUrls: ['./mic-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UtilsModule, MatIconModule, MatRippleModule],
})
export class MicButtonComponent {
    /**
     * Is voice listening active now
     */
    @Input() isPulsing: boolean;

    /**
     * Triggered when button is tapped
     */
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
