<div #wrapper class="ui-autocomplete__wrapper" [class.ui-autocomplete__wrapper--disabled]="disabled">
    <!-- ------------------------------------------------------------------- -->
    <!-- Input search field -->
    <!-- ------------------------------------------------------------------- -->
    <input
        #autocompleteInput
        class="ui-autocomplete__input"
        type="text"
        [formControl]="viewModel"
        matInput
        [matAutocomplete]="autocomplete"
        matAutocompletePosition="auto"
        [placeholder]="placeholder | translate"
        [required]="required"
        (focusin)="onFocusIn($event)"
        (focusout)="onFocusOut($event)"
        [attr.data-testid]="'ui-autocomplete-' + name"
    />
    <button
        tabindex="-1"
        #autocompleteReset
        type="button"
        aria-label="Clear"
        mat-icon-button
        matSuffix
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        [disabled]="disabled"
        (click)="clearWithConfirmation ? (isClearOverlayOpened = true) : autocompleteReseted()"
        class="ui-autocomplete__icon ui-autocomplete__icon-close"
        [visibleIf]="!disabled && !hideResetBtn && viewModel.value && !dataSource.inProgress"
        [attr.data-testid]="'ui-autocomplete-clearButton'"
        data-preventfocus="true"
    >
        <mat-icon class="clear-icon" data-preventfocus="true">clear</mat-icon>
    </button>

    <mat-spinner
        [attr.data-testid]="'ui-autocomplete-inProgress'"
        class="ui-autocomplete__icon ui-autocomplete__icon-spinner"
        diameter="24"
        matSuffix
        *ngIf="showProgress && dataSource.inProgress"
    ></mat-spinner>
    <!-- ------------------------------------------------------------------- -->
    <!-- Autocomplete panel -->
    <!-- ------------------------------------------------------------------- -->
    <mat-autocomplete
        #autocomplete="matAutocomplete"
        autoActiveFirstOption="true"
        [displayWith]="displayFn"
        (optionSelected)="autocompleteSelected($event)"
        class="ui-autocomplete__panel scrollbar-visible"
    >
        <ng-container *ngIf="!dataSource.inProgress">
            <mat-option
                #matOption
                *ngFor="let item of dataSource.filteredData; trackBy: dataSource.trackByFn"
                [value]="item"
                class="ui-autocomplete__option"
                [attr.data-testid]="'ui-autocomplete-option-' + item.id || item[labelKey]"
            >
                <ng-template *ngIf="displayTemplate">
                    <ng-container *ngTemplateOutlet="displayTemplate; context: { $implicit: item }"></ng-container>
                </ng-template>
                <span *ngIf="!displayTemplate">
                    {{ item | dataAccessor: labelKey }}
                </span>
            </mat-option>
            <mat-option
                *ngIf="query?.length >= lengthToTriggerSearch && dataSource.isEmpty"
                disabled
                class="ui-autocomplete__option ui-autocomplete__option--empty"
                [attr.data-testid]="'ui-autocomplete-noResults'"
            >
                <span>{{ noResultsLabel | translate }}</span>
            </mat-option>
            <mat-option
                *ngIf="query?.length >= lengthToTriggerSearch && allowNonExisting"
                [value]="query"
                (click)="autocompleteOptionAdded()"
                class="ui-autocomplete__option ui-autocomplete__option--empty"
                [attr.data-testid]="'ui-autocomplete-addNew'"
            >
                <ng-container
                    *ngTemplateOutlet="addNewTemplate || defaultAddNewTemplate; context: { $implicit: query }"
                ></ng-container>
                <ng-template #defaultAddNewTemplate>
                    <mat-icon class="add-icon">add</mat-icon>
                    <span>{{ addNewLabel | translate }}</span>
                </ng-template>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <!-- ------------------------------------------------------------------- -->
    <!-- Confirmation Overlay -->
    <!-- ------------------------------------------------------------------- -->
    <ng-template
        cdkConnectedOverlay
        (overlayKeydown)="dismissViaEscape($event)"
        [cdkConnectedOverlayPush]="true"
        [cdkConnectedOverlayHasBackdrop]="false"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isClearOverlayOpened"
        [cdkConnectedOverlayPositions]="positions"
        [cdkConnectedOverlayViewportMargin]="16"
        [cdkConnectedOverlayBackdropClass]="'ui-autocomplete-overlay-backdrop'"
    >
        <mat-card
            [@fadeInOut]
            class="ui-autocomplete-clearOverlay mat-elevation-z8"
            [attr.data-testid]="'ui-autocomplete-clearOverlay-card'"
        >
            <mat-card-content>
                {{ 'discardSetChangesDialog.description' | translate }}
            </mat-card-content>
            <mat-card-actions>
                <button
                    mat-button
                    color="secondary"
                    (click)="isClearOverlayOpened = false"
                    [attr.data-testid]="'ui-autocomplete-clearOverlay-cancel'"
                >
                    {{ 'discardSetChangesDialog.keepEditing' | translate }}
                </button>
                <button
                    mat-button
                    (click)="autocompleteReseted(); isClearOverlayOpened = false"
                    [attr.data-testid]="'ui-autocomplete-clearOverlay-ok'"
                >
                    {{ 'discardSetChangesDialog.discard' | translate }}
                </button>
            </mat-card-actions>
        </mat-card>
    </ng-template>
</div>
