import { NgModule } from '@angular/core';
import { UiModule } from '@mona/ui';
import { PendingChangesDialogComponent } from './pending-changes-dialog.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [PendingChangesDialogComponent],
    exports: [PendingChangesDialogComponent],
    imports: [UiModule],
})
export class PendingChangesDialogModule {}
