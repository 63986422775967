export const ChangeLogPermissionsModel: { [key: string]: string } = {
    Anamnesis: 'patient_overview_edit',
    AllergyIntolerance: 'patient_overview_edit',
    BalanceTarget: 'inputs_outputs_edit',
    BasicCare: 'care_procedures_edit',
    Diagnosis: 'patient_overview_edit',
    Encounter: 'patient_overview_edit',
    EntryControl: 'care_procedures_edit',
    LabValue: 'lab_values_edit',
    MedicationAdministration: 'inputs_outputs_edit',
    MedicationPrescription: 'prescription_medication_edit',
    InfectionStatus: 'patient_overview_edit',
    Output: 'inputs_outputs_edit',
    Patient: 'patient_overview_edit',
    PatientHistory: 'patient_overview_edit',
    PhysicalExamination: 'patient_overview_edit',
    ProcedurePrescription: 'prescription_medication_edit',
    Procedure: 'care_procedures_edit',
    PreMedication: 'patient_overview_edit',
    SurgeryPrescription: 'patient_overview_edit',
    TherapyLimitations: 'patient_overview_edit',
    VaccinationStatus: 'patient_overview_edit',
    Valuables: 'patient_overview_edit',
    VentilationParameter: 'ventilation_edit',
    VentilationProcedure: 'ventilation_edit',
    VitalSign: 'vital_signs_edit',
    WorkflowQuestionnaire: 'workflows_edit',
    WorkflowQuestionnaireResponse: 'workflows_edit',
    WoundStatus: 'patient_overview_edit',
};
