import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@mona/shared/utils';
import { UiCalendarCellDef } from './calendar-cell.directive';
import { UiCalendarSliderComponent } from './calendar-slider.component';

/**
 * UI CalendarSlider Module
 */
@NgModule({
    declarations: [UiCalendarCellDef, UiCalendarSliderComponent],
    exports: [UiCalendarSliderComponent],
    imports: [UtilsModule, MatDatepickerModule, MatButtonModule, MatIconModule],
})
export class UiCalendarSliderModule {}
