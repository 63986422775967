// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.fe1.stage.monatest.icu/auto-open-api-spec.yaml 1.3.6
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validation the physical examination part of the admission payload
 * @see #/components/schemas/AdmissionPhysicalExamination - reference to schema in open-api-spec.yaml
 * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
 * @version 1.3.6
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionPhysicalExamination {
    /**
     * The text part of the physical examination
     *
     */
    text: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionPhysicalExamination
 */
export namespace AdmissionPhysicalExamination {
    /**
     * AdmissionPhysicalExamination Frontend Model with camelCase properties
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The text part of the physical examination
         * @type string (`dataFormat` is missing)
         */
        text: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionPhysicalExamination DTO
     * Transforms AdmissionPhysicalExamination model from/to API object
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionPhysicalExamination model */
        static toModel(obj: AdmissionPhysicalExamination): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionPhysicalExamination model */
        static toApi(obj: Model): AdmissionPhysicalExamination {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionPhysicalExamination;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
