import { ApiModels } from '@mona/api';
import { OutputFactor } from '@mona/models';

/**
 * Transforms api output factor
 * @param apiOutputFactor ApiModels.OutputFactor
 */
export const transformApiOutputFactor = (apiOutputFactor: ApiModels.OutputFactor): OutputFactor => {
    return {
        id: apiOutputFactor.code,
        code: apiOutputFactor.code,
        displayName: apiOutputFactor.display_name,
        type: apiOutputFactor.type,
        unit: apiOutputFactor.unit,
        parent: apiOutputFactor.parent,
        hasImpactOnBalance: apiOutputFactor.has_impact_on_balance,
    };
};

/**
 * Transform api output factors
 * @param apiOutputFactors ApiModels.OutputFactor[]
 */
export const transformApiOutputFactors = (apiOutputFactors: ApiModels.OutputFactor[]): OutputFactor[] => {
    return apiOutputFactors.map(apiOutputFactor => transformApiOutputFactor(apiOutputFactor));
};
