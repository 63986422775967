import { ChangeLogEntry, ChangeLogModel } from '@mona/models';
import { mergeChangesOnModelId } from '@mona/pdms/data-access-changelog';

/**
 * get complete on demand medication prescription ids
 *
 * @param medAdmCh
 * @returns
 */
export function getCompleteOnDemandMedPrescriptions(medAdmCh: ChangeLogEntry<ChangeLogModel>[]) {
    const mergedOnModelId = mergeChangesOnModelId(medAdmCh);
    return mergedOnModelId.filter(ch => ch.operation !== 'delete').map(ch => ch.payload?.prescriptionId);
}
