import { InjectionToken } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { Encounter } from '@mona/models';
import { pick } from '@mona/shared/utils';
import { EncountersState } from '../../entities';
import * as EncountersActions from '../actions';

const encountersAdapter: EntityAdapter<Encounter> = createEntityAdapter<Encounter>({
    selectId: (encounter: Encounter) => encounter?.id,
    sortComparer: (a: Encounter, b: Encounter) => (a?.admissionDiagnosis > b?.admissionDiagnosis ? 1 : -1),
});

const initialState: EncountersState = encountersAdapter.getInitialState({
    assignedEncounterId: null,
    selectedId: null,
    error: null,
    isLoading: false,
    relocateEncounterData: null,
});

export const encountersReducer = createReducer(
    initialState,
    on(EncountersActions.setAssignedEncounter, (state, { encounterId }) => ({
        ...state,
        assignedEncounterId: encounterId,
    })),
    on(EncountersActions.selectEncounter, (state, { encounterId }) => ({ ...state, selectedId: encounterId })),
    on(EncountersActions.loadEncounters, state => ({ ...state, isLoading: true })),
    on(EncountersActions.loadEncountersSuccess, (state, { encounters }) =>
        encountersAdapter.upsertMany(encounters, { ...state, isLoading: false }),
    ),
    on(EncountersActions.clearEncounters, state => encountersAdapter.removeAll({ ...state })),
    on(EncountersActions.loadSingleEncounter, state => ({ ...state, isLoading: true })),
    on(EncountersActions.loadSingleEncounterSuccess, EncountersActions.upsertEncounter, (state, { encounter }) =>
        encounter
            ? encountersAdapter.upsertOne(encounter, { ...state, isLoading: false })
            : { ...state, isLoading: false },
    ),
    on(EncountersActions.endEncounterSuccess, (state, { encounterId }) =>
        encountersAdapter.removeOne(encounterId, {
            ...state,
            isLoading: false,
            error: null,
        }),
    ),
    on(EncountersActions.removeEncounter, (state, { encounter: { id } }) =>
        encountersAdapter.removeOne(id, {
            ...state,
            isLoading: false,
        }),
    ),
    on(EncountersActions.relocateEncounter, (state, action) => ({
        ...state,
        relocateEncounterData: pick(action, 'fromBedId', 'bedId', 'encounter'),
    })),
    on(EncountersActions.relocateEncounterSuccess, (state, { encounterId, wardId, bedId }) =>
        encountersAdapter.updateOne(
            { id: encounterId, changes: { wardId, bedId } },
            {
                ...state,
                isLoading: false,
                relocateEncounterData: null,
                error: null,
            },
        ),
    ),
    on(
        EncountersActions.relocateEncounterFailed,
        EncountersActions.relocateEncounterClear as any,
        EncountersActions.endEncounterFailed,
        (state, { error = null }) => ({
            ...state,
            isLoading: false,
            relocateEncounterData: null,
            error,
        }),
    ),
);

export const ENCOUNTERS_REDUCER_TOKEN = new InjectionToken<ActionReducer<EncountersState>>('Encounters Reducer');

export const { selectIds, selectEntities, selectAll, selectTotal } = encountersAdapter.getSelectors();
