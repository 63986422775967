import { coerceArray, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { WorkflowAnswer, WorkflowAnswerOption, WorkflowQuestion, WorkflowQuestionType } from '@mona/models';
import { compactObject, isEmptyObject, isNullOrUndefined } from '@mona/shared/utils';
import { DialogService, UiDynamicElementConfig } from '@mona/ui';
import { getAnswersAsValuesMap, getQuestionnaireFormConfig, mapFormValuesToAnswers } from '../models';

/**
 * WorkflowQuestionnaireComponent
 */
@Component({
    selector: 'mona-workflow-questionnaire',
    templateUrl: './workflow-questionnaire.component.html',
    styleUrls: ['./workflow-questionnaire.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowQuestionnaireComponent implements OnInit, AfterViewInit, OnDestroy {
    destroy$ = new Subject();
    /** Displayed columns */
    displayedColumns: Array<'question' | 'currentValue' | 'answer'> = ['question', 'answer'];
    /** Questionnaire form config map */
    questionnaireFormConfig = new Map<string, UiDynamicElementConfig[]>();
    /** Is workflow with score */
    isWithScore: boolean;
    /** Total score */
    totalScore: number;
    /** Questionnaire form */
    readonly form = new UntypedFormGroup({});
    /** Questionnaire questions */
    private _questions: WorkflowQuestion[] = [];
    /** Questionnaire question type */
    private _questionTypes: WorkflowQuestionType[] = [];
    /** Questionnaire answers */
    private _answers: WorkflowAnswer[] = [];
    /** Questionnaire answers as Map */
    private _originalAnswersMap = new Map<string, WorkflowAnswer | WorkflowAnswer[]>();
    /** View or Edit mode */
    private _viewMode = false;
    /** Form intitalized flag */
    private _intitalized = false;
    /** Workflow questionnaire id */
    @Input() questionnaireId: string = undefined;
    /** Questionnaire questions */
    get questions(): WorkflowQuestion[] {
        return this._questions;
    }
    @Input() set questions(data: WorkflowQuestion[]) {
        if (data) {
            this._questions = coerceArray(data);
            this.isWithScore = this._questions.some(
                q => !isNullOrUndefined(q.answerOptions?.find(a => !isNullOrUndefined(a.score))),
            );
            const displayCurrentValue = this._questions.some(q => !isNullOrUndefined(q.currentValue));
            // Add currentValue column
            if (displayCurrentValue && !this.displayedColumns.includes('currentValue')) {
                this.displayedColumns.splice(1, 0, 'currentValue');
            }
        }
    }
    @Input() set questionTypes(data: WorkflowQuestionType[]) {
        if (data) {
            this._questionTypes = coerceArray(data);
        }
    }
    /** Questionnaire answers */
    @Input() set answers(data: WorkflowAnswer[]) {
        if (data) {
            this._answers = coerceArray(data);
            this.updateAnswersScore();
            this._originalAnswersMap = getAnswersAsValuesMap(this._answers, this._questions, this._questionTypes);
        }
    }
    /** Questionnaire answers change event */
    @Output() readonly answersChange = new EventEmitter<WorkflowAnswer[]>();
    /** View or Edit mode */
    get viewMode(): boolean {
        return this._viewMode;
    }
    @Input() set viewMode(value: boolean) {
        this._viewMode = coerceBooleanProperty(value);
        this.toggleFormDisabledState();
    }

    /**
     * Constructor
     *
     * @param cdRef
     * @param dialogService
     * @param translateService
     * @
     */
    constructor(
        private cdRef: ChangeDetectorRef,
        private dialogService: DialogService,
        private translateService: TranslateService,
    ) {}

    /** Lifecycle */
    ngOnInit(): void {
        this.questionnaireFormConfig = getQuestionnaireFormConfig(
            this.questions,
            this.questionnaireId,
            this.selectionChange.bind(this),
            this.translateService,
        );
        this.watchFormChanges();
    }

    /** Lifecycle */
    ngAfterViewInit(): void {
        this.initFormWithValues();
    }

    /** On destroy */
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Selection change process
     *
     * @param answerOption
     * @param answerOption.confirmation
     */
    selectionChange({ confirmation }: WorkflowAnswerOption): void {
        if (!isEmptyObject(confirmation)) {
            this.dialogService.showConfirmDialog({
                confirmBtn: 'confirmStagedChangesDialog.confirm',
                title: confirmation.title,
                description: confirmation.title,
            });
        }
    }

    /** Update unswers score */
    private updateAnswersScore(): void {
        this.totalScore = 0;

        for (const answer of this._answers) {
            this.totalScore += answer.answer?.score ?? 0;
        }
    }

    /** Init form with values */
    private initFormWithValues(): void {
        const formValue = Object.fromEntries(this._originalAnswersMap);

        this.form.patchValue(formValue, { emitEvent: false });
        this.form.markAsPristine();
    }

    private toggleFormDisabledState() {
        if (this.viewMode) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    /**
     * On form change collect value and emit to parent
     */
    private watchFormChanges() {
        this.form.valueChanges
            .pipe(
                distinctUntilChanged(),
                filter(value => Object.keys(value).length === this.questions.length),
                takeUntil(this.destroy$),
                skipWhile(() => this._viewMode),
                tap((formValue: { [k: string]: WorkflowAnswerOption[] }) => {
                    const formValueMap = new Map(Object.entries(compactObject(formValue)));
                    if (this.form.dirty) {
                        const newAnswers: WorkflowAnswer[] = mapFormValuesToAnswers(formValueMap);
                        if (!this.form.disabled) {
                            this.answersChange.emit(newAnswers);
                        }
                    }
                }),
            )
            .subscribe();
    }

    /**
     * Track by question id
     *
     * @param {number} index - index
     * @param {WorkflowQuestion} question - question
     */
    trackByQuestionId(index: number, question: WorkflowQuestion): string {
        return question.id;
    }

    /**
     * Get question answer
     *
     * @param {WorkflowQuestion} question - question
     */
    getQuestionAnswer(question: WorkflowQuestion): WorkflowAnswerOption {
        const answer = this._originalAnswersMap.get(question.id);
        return answer?.[0].answer || (answer as WorkflowAnswer)?.answer;
    }

    /**
     * Get suggested answer
     *
     * @param {WorkflowAnswerOption} option - option
     * @param {WorkflowQuestion} question - question
     */
    getSuggested(option: WorkflowAnswerOption, question: WorkflowQuestion) {
        if (!question.answerSuggestion || !!this.getQuestionAnswer(question)) {
            return false;
        }

        return question.answerSuggestion.code === option.code;
    }
}
