<ng-container *ngIf="!multiple">
    <div class="ui-dynamic-checkbox-wrapper" [ngClass]="customClass">
        <mat-checkbox
            [formControl]="control"
            [value]="control?.value"
            [id]="name"
            [name]="name"
            [required]="required"
            [attr.data-testid]="name"
            color="primary"
        >
            {{ label }}
        </mat-checkbox>
        <span class="mat-hint ui-dynamic-element-hint">{{ hint }}</span>
    </div>
</ng-container>
<ng-container *ngIf="multiple">
    <div class="ui-dynamic-checkbox-wrapper ui-dynamic-checkbox-wrapper--multiple" [ngClass]="customClass">
        <div *ngFor="let selection of dataSource.filteredData; let i = index">
            <mat-checkbox
                [id]="name + '_' + i"
                (change)="selectedValues.toggle(selection[valueKey])"
                [disabled]="control.disabled"
                [checked]="selectedValues.isSelected(selection[valueKey])"
                [required]="required"
                color="primary"
                [attr.data-testid]="name + '_option_' + i"
            >
                {{ $any(selection).label || selection }}
            </mat-checkbox>
        </div>
        <span class="mat-hint ui-dynamic-element-hint">{{ hint }}</span>
    </div>
</ng-container>
