import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { uiPure } from '@mona/shared/utils';
import { FilterOptionsModel } from '../../models/filter-option.intf';

/**
 * Table Name Column Filter Component
 */
@Component({
    selector: 'ui-table-filter',
    templateUrl: './ui-table-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ui-table-filter',
    },
    styles: [
        `
            :host {
                display: flex;
                align-items: center;
            }

            .select-all {
                padding: 12px;
            }
        `,
    ],
})
export class UiTableFilterComponent {
    private _options: FilterOptionsModel[] = [];
    /** Filter Options */
    get options(): FilterOptionsModel[] {
        return this._options;
    }
    @Input() set options(data: FilterOptionsModel[]) {
        // options = coerceArray(options);
        if (!Array.isArray(data) || !data.length || data.length == this._options?.length) {
            return;
        }
        this._options = data;
        this.control.setValue(data);
    }

    /** Custom Option template */
    @Input() optionTemplate?: TemplateRef<any>;

    /** Filter change event */
    @Output() filterChange: EventEmitter<string[]> = new EventEmitter();

    /** Filtered codes control */
    readonly control: FormControl<FilterOptionsModel[]> = new FormControl([]);
    /** Filtered codes value */
    get value() {
        return this.control.value;
    }

    /**
     * Toggles SelectAll checkbox options
     */
    onSelectAllOptions(): void {
        this.isAllSelected(this.control.value, this._options)
            ? this.control.setValue([])
            : this.control.setValue(this._options);

        this.onFilterChange();
    }

    /**
     * Is All Options Selected
     *
     * @param value
     * @param options
     */
    @uiPure
    isAllSelected(value: FilterOptionsModel[], options: FilterOptionsModel[]): boolean {
        return value?.length === options?.length;
    }

    /**
     * Handle {@link MatSelectChange}
     *
     * @param event
     */
    onFilterChange(event?: MatSelectChange): void {
        const values: string[] = this.value.map(({ value }: FilterOptionsModel) => value);
        this.filterChange.emit(values);
    }
}
