import { mergeWith as _mergeWith } from 'lodash/fp';

/**
 * Merge with
 *
 * @param object
 */
export const mergeWith = (object: any) => (source: Array<any>) =>
    _mergeWith((objValue: any, srcValue: any) => {
        if (Array.isArray(objValue)) {
            return srcValue;
        }
    })(object)(source);

/**
 * Merge series
 *
 * @param merged
 * @param current
 */
export const mergeSeries = async (merged: any, current: Promise<any>) => current.then(mergeWith(merged));
