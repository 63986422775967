import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { compareDeepEqual, FilterableDataSource } from '@mona/shared/utils';

/**
 * UiDynamicListComponent
 */
@Component({
    selector: 'ui-dynamic-list',
    styleUrls: ['./dynamic-list.component.scss'],
    templateUrl: './dynamic-list.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class UiDynamicListComponent implements OnInit {
    @ViewChild(MatSelectionList, { static: true }) readonly list: MatSelectionList;

    readonly form: NgForm;

    control: UntypedFormControl;

    label = '';

    labelKey = 'label';

    valueKey = 'value';

    hint = '';

    name = '';

    required: boolean = undefined;

    dataSource: FilterableDataSource<any> = undefined;

    multiple = false;

    errorMessageTemplate: TemplateRef<any> = undefined;

    placeholder = '';

    customClass = '';

    submitOnSelect = false;

    compareFn = compareDeepEqual;

    /** @internal */
    ngOnInit(): void {
        // FIXME: selection list accepts only array values
        if (this.control.value) {
            if (!Array.isArray(this.control.value)) {
                this.control.setValue([].concat(this.control), { emitEvent: false });
            }
        } else {
            this.control.setValue([], { emitEvent: false });
        }
    }
}
