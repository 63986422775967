import { Injectable, TemplateRef } from '@angular/core';

/**
 * Template Registry
 */
@Injectable({ providedIn: 'root' })
export class UiTemplateRegistry {
    /** Template Registry */
    readonly templates: Map<string, TemplateRef<any>> = new Map();
}
