import { Pipe, PipeTransform } from '@angular/core';
import { Patient } from '@mona/models';

/**
 * Patient display name pipe
 */
@Pipe({
    name: 'patientDisplayName',
})
export class PatientDisplayNamePipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param patient patient
     */
    transform(patient: Patient): string {
        const { firstName, lastName } = patient;
        return `${firstName} ${lastName}`;
    }
}
