import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Anamnesis } from '@mona/models';
import { AnamnesisForm } from '../../models';

/**
 * Anamnesis dialog component
 */
@Component({
    selector: 'app-anamnesis-dialog',
    templateUrl: './anamnesis-dialog.component.html',
    styleUrls: ['./anamnesis-dialog.component.scss'],
})
export class AnamnesisDialogComponent {
    /**
     * Anamnesis form
     */
    anamnesisForm = new FormGroup<AnamnesisForm>({
        text: new FormControl<string>('', [Validators.required]),
        admissionType: new FormControl<string>(null, [Validators.required]),
    });

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<AnamnesisDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<AnamnesisDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            anamnesis: Anamnesis;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-anamnesis-dialog');
        this.anamnesisForm.controls.text.setValue(this.data.anamnesis.text);
        this.anamnesisForm.controls.admissionType.setValue(this.data.anamnesis.admissionType);
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.anamnesisForm.markAsTouched();

        if (this.anamnesisForm.valid) {
            this.dialogRef.close({
                formValue: this.anamnesisForm.getRawValue(),
                isFormDirty: this.anamnesisForm.dirty,
            });
        }
    }
}
