import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { UtilsModule } from '@mona/shared/utils';
import { UiSpacerModule } from '../utils';
import { TabBarComponent } from './tab-bar.component';

/**
 * UiAppBarModule
 */
@NgModule({
    declarations: [TabBarComponent],
    imports: [UtilsModule, MatTabsModule, UiSpacerModule, MatIconModule],
    exports: [TabBarComponent],
})
export class UiTabBarModule {}
