/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { encounterDataFeatureKey, EncounterFeatureState, TherapyLimitationsState } from '../../entities';
import { therapyLimitationsAdapter } from '../reducers/therapy-limitations.reducer';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectTherapyLimitationsState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.therapyLimitations,
);

export const {
    selectIds: selectTherapyLimitationsIds,
    selectEntities: selectTherapyLimitationEntities,
    selectAll: selectTherapyLimitationsAll,
    selectTotal: selectTherapyLimitationsTotal,
} = therapyLimitationsAdapter.getSelectors(selectTherapyLimitationsState);

export const selectTherapyLimitationsEntities = createSelector(
    selectTherapyLimitationsState,
    (state: TherapyLimitationsState) => state.entities,
);

export const selectTherapyLimitationsIsLoading = createSelector(
    selectTherapyLimitationsState,
    (state: TherapyLimitationsState) => state.isLoading,
);
