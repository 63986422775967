import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@mona/shared/utils';
import { PipCallDialogComponent } from './pip-call-dialog.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [PipCallDialogComponent],
    imports: [
        //
        UtilsModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        DragDropModule,
    ],
    exports: [PipCallDialogComponent],
})
export class PipCallDialogModule {}
