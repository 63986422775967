import { Action, ActionCreator, ActionReducer, combineReducers, createReducer, on } from '@ngrx/store';
import { NotAllowedCheck, TypedAction } from '@ngrx/store/src/models';
import '../actions';
import { AsyncAction, SucceededAction } from '../models';
import { AsyncActionState } from '../state/async-action.state';
import { EntitiesState } from '../state/entities.state';
import { EntityState } from '../state/entity.state';
import { AsyncActionReducerHelper } from './async-action-reducer.helper';
import { EntityArrayReducerHelper } from './entity-array-reducer.helper';
import { EntityMapReducerHelper } from './entity-map-reducer.helper';
import { EntityReducerHelper } from './entity-reducer.helper';

/**
 * Create a reducer that tracks the state of an async action
 * @param action action
 */
export function makeAsyncActionReducer<R = any>(action: AsyncAction<Record<string, any> | void, R>) {
    const asyncActionReducerHelper = new AsyncActionReducerHelper<R>();

    const actions = [
        on(action.action, asyncActionReducerHelper.initActionReducer),

        on(action.succeededAction, asyncActionReducerHelper.succeededActionReducer),

        on(action.failedAction, asyncActionReducerHelper.failedActionReducer),
    ];

    if (action.clearAction) {
        actions.push(on(action.clearAction, asyncActionReducerHelper.clearActionReducer));
    }

    return createReducer(AsyncActionReducerHelper.initialState, ...actions) as ActionReducer<AsyncActionState<R>, Action>;
}

/**
 * Create a reducer that manages an entity map
 * @param action action
 * @param collectEntities boolean
 * @param addUpdateActions action
 */
export function makeEntityMapReducer<E extends Entity>(
    action: AsyncAction<any, E[]>,
    collectEntities?: boolean,
    addUpdateActions?: ActionCreator<
        string,
        (props: SucceededAction<E> & NotAllowedCheck<SucceededAction<E>>) => SucceededAction<E> & TypedAction<string>
    >[],
) {
    const entityMapReducerHelper = new EntityMapReducerHelper<E>();

    const actions = [
        on(
            action.succeededAction,
            collectEntities
                ? entityMapReducerHelper.reduceLoadEntitiesSucceededWithExistingAction
                : entityMapReducerHelper.reduceLoadEntitiesSucceededAction,
        ),
    ];

    if (action.clearAction) {
        actions.push(on(action.clearAction, entityMapReducerHelper.reduceLoadEntitiesClearAction));
    }

    if (addUpdateActions) {
        for (const addUpdateAction of addUpdateActions) {
            actions.push(on(addUpdateAction, entityMapReducerHelper.reduceAddUpdateEntityAction) as any);
        }
    }

    return createReducer({}, ...actions);
}

/**
 * Create a reducer that manages single entity
 * @param action action
 * @param initialState E
 */
export function makeEntityReducer<E extends Entity>(action: AsyncAction<any, E>, initialState: E) {
    const entityReducerHelper = new EntityReducerHelper<E>(initialState);

    const actions = [on(action.succeededAction, entityReducerHelper.reduceLoadEntitySucceededAction)];

    if (action.clearAction) {
        actions.push(on(action.clearAction, entityReducerHelper.reduceLoadEntityClearAction.bind(entityReducerHelper)));
    }

    return createReducer(initialState, ...actions);
}

/**
 * Create a reducer that manages an entity array
 * @param action action
 * @param collectEntities boolean
 * @param addUpdateActions actions
 */
export function makeEntityArrayReducer<E extends Entity>(
    action: AsyncAction<any, E[]>,
    collectEntities?: boolean,
    addUpdateActions?: ActionCreator<
        string,
        (props: SucceededAction<E> & NotAllowedCheck<SucceededAction<E>>) => SucceededAction<E> & TypedAction<string>
    >[],
) {
    const entityArrayReducerHelper = new EntityArrayReducerHelper<E>();

    const actions = [
        on(
            action.succeededAction,
            collectEntities
                ? entityArrayReducerHelper.reduceLoadEntitiesSucceededWithExistingAction
                : entityArrayReducerHelper.reduceLoadEntitiesSucceededAction,
        ),
    ];

    if (action.clearAction) {
        actions.push(on(action.clearAction, entityArrayReducerHelper.reduceLoadEntitiesClearAction));
    }

    if (addUpdateActions) {
        for (const addUpdateAction of addUpdateActions) {
            actions.push(on(addUpdateAction, entityArrayReducerHelper.reduceAddUpdateEntityAction) as any);
        }
    }

    return createReducer([], ...actions);
}

/**
 * Create a reducer that holds entities state and async action state
 * @param action action
 * @param collectEntities boolean
 * @param addUpdateActions actions
 */
export function makeEntitiesReducer<E extends Entity>(
    action: AsyncAction<any, E[]>,
    collectEntities?: boolean,
    addUpdateActions?: ActionCreator<
        string,
        (props: SucceededAction<E> & NotAllowedCheck<SucceededAction<E>>) => SucceededAction<E> & TypedAction<string>
    >[],
): ActionReducer<EntitiesState<E>, Action> {
    return combineReducers({
        entities: makeEntityMapReducer(action, collectEntities, addUpdateActions),
        rawEntities: makeEntityArrayReducer(action, collectEntities, addUpdateActions),
        loadAction: makeAsyncActionReducer(action),
    }) as any;
}

/**
 * Create a reducer that holds single entity state and async action state
 * @param action action
 * @param initialState E
 */
export function makeSingleEntityReducer<E extends Entity>(
    action: AsyncAction<any, E>,
    initialState: E = null,
): ActionReducer<EntityState<E>, Action> {
    return combineReducers({
        entity: makeEntityReducer(action, initialState),
        loadAction: makeAsyncActionReducer(action),
    }) as any;
}

/**
 * Create single prop reducer by providing setter action
 * @param initialState P
 * @param setterAction ActionCreator
 */
export function makePropertyReducer<P extends any, A extends Action = Action>(
    initialState: P,
    setterAction: ActionCreator<
        string,
        (props: SucceededAction<P> & NotAllowedCheck<SucceededAction<P>>) => SucceededAction<P> & TypedAction<string>
    >,
): ActionReducer<P, A> {
    return createReducer(
        initialState,
        on(setterAction, (state: P, action: SucceededAction<P>): P => action.payload) as any,
    );
}
