import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { MedicationAutocompleteComponent } from './medication-autocomplete.component';

/**
 * Medication autocomplete module
 *
 */
@NgModule({
    imports: [
        /* prettier-ignore */
        UiModule,
        KeyboardModule,
    ],
    declarations: [MedicationAutocompleteComponent],
    exports: [MedicationAutocompleteComponent],
})
export class MedicationAutocompleteModule {}
