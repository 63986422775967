/**
 * Notification navigate action
 */
export enum NotificationNavigateAction {
    BALANCE = 'balance',
    CARE_REPORT = 'care_report',
    LAB_VALUES = 'lab_values',
    ORDERS = 'orders',
    OVERVIEW = 'overview',
    VENTILATION = 'ventilation',
    VITAL_SIGNS = 'vital_signs',
}
/**
 * Notification navigate slug
 */
export enum NotificationNavigationMap {
    balance = 'balance',
    care_report = 'care-reports',
    lab_values = 'lab-values',
    orders = 'orders',
    overview = 'overview',
    ventilation = 'ventilation',
    vital_signs = 'vital-signs',
}
