import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { PrescriptionSet, PrescriptionSetMedication } from '@mona/models';
import { omit } from '@mona/shared/utils';
import { transformApiPrescriptionSetMedications, transformToApiPrescriptionSetMedication } from './transforms';

/**
 * API abstraction layer for the prescription set medications API
 */
@Injectable({
    providedIn: 'root',
})
export class PrescriptionSetMedicationsApi {
    apiBase = '/pdms/prescription-set-medications/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get prescription set medications
     *
     * @param setId EntityId<PrescriptionSet>
     */
    getPrescriptionSetMedications(setId: EntityId<PrescriptionSet>): Observable<PrescriptionSetMedication[]> {
        return this.http
            .get<ApiModels.PrescriptionSetMedication[]>(this.apiBase, {
                params: {
                    prescription_set_id: setId as string,
                },
            })
            .pipe(map(apiMedications => transformApiPrescriptionSetMedications(apiMedications)));
    }

    /**
     * Create prescription set medication
     *
     * @param prescriptionSetId
     * @param setMedicationId
     */
    createPrescriptionSetMedication(prescriptionSetId: string, setMedicationId: string): Observable<void> {
        const payload: ApiModels.PrescriptionSetMedication = {
            standard_medication_set: setMedicationId,
            prescription_set_id: prescriptionSetId,
        } as any;
        // if (prescriptionSetMedication.instruction) {
        //     apiMedication.instructions = prescriptionSetMedication.instruction;
        // }
        return this.http.post<void>(this.apiBase, payload);
    }

    /**
     * Update prescription set medication
     *
     * @param id EntityId<PrescriptionSetMedication>
     * @param setId EntityId<PrescriptionSet>
     * @param setMedication Partial<PrescriptionSetMedication>
     */
    updatePrescriptionSetMedication(
        id: EntityId<PrescriptionSetMedication>,
        setId: EntityId<PrescriptionSet>,
        setMedication: PrescriptionSetMedication,
    ): Observable<any> {
        return this.http.put<void>(
            `${this.apiBase}${id}/`,
            transformToApiPrescriptionSetMedication(setMedication, setId),
        );
    }

    /**
     * Delete prescription set medication
     *
     * @param id EntityId<PrescriptionSetMedication>
     */
    deletePrescriptionSetMedication(id: EntityId<PrescriptionSetMedication>): Observable<void> {
        return this.http.delete<void>(`${this.apiBase}${id}/`);
    }
}
