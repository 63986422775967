import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoryEntryType } from '@mona/models';

/**
 * Create/Edit history entry dialog
 */
@Component({
    selector: 'mona-history-entry-dialog',
    templateUrl: './history-entry-dialog.component.html',
    styleUrls: ['./history-entry-dialog.component.scss'],
})
export class HistoryEntryDialogComponent implements AfterViewInit {
    /**
     * The text area element
     */
    @ViewChild('textarea') textarea: ElementRef<HTMLTextAreaElement>;

    /**
     * History entry form
     */
    entryForm = new FormGroup<{ text: FormControl<string> }>({
        text: new FormControl<string>('', [Validators.required]),
    });

    /**
     * Translations map for type
     */
    readonly typeTranslationMap: { [key: string]: string } = {
        [HistoryEntryType.PhysicianReport]: 'apps.patient.overview.history.types.physicianReports',
        [HistoryEntryType.SeniorPhysicianOrder]: 'apps.patient.overview.history.types.seniorPhysicianOrders',
        [HistoryEntryType.Event]: 'apps.patient.overview.history.types.events',
        [HistoryEntryType.SpeechTherapyReport]: 'apps.patient.overview.history.types.speechTherapyReport',
        [HistoryEntryType.PhysicalTherapyReport]: 'apps.patient.overview.history.types.physicalTherapyReport',
        [HistoryEntryType.NeurologyReport]: 'apps.patient.overview.history.types.neurologyReport',
        [HistoryEntryType.CareReport]: 'apps.patient.overview.history.types.careReport',
    };

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<AnamnesisDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<HistoryEntryDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            text: string;
            type: HistoryEntryType;
        },
    ) {
        dialogRef.addPanelClass('app-anamnesis-dialog');
        if (data.text) {
            this.entryForm.controls.text.setValue(data.text);
        }
    }

    /**
     * NG Hook
     */
    ngAfterViewInit() {
        // Workaround for autofocus
        setTimeout(() => {
            this.textarea.nativeElement.focus();
        }, 200);
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.entryForm.controls.text.markAsTouched();

        if (this.entryForm.valid) {
            this.dialogRef.close({
                text: this.entryForm.controls.text.value,
            });
        }
    }
}
