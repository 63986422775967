import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PrescriptionSet } from '@mona/models';

export const prescriptionSetsFeatureKey = 'prescriptionSets';

export const prescriptionSetAdapter: EntityAdapter<PrescriptionSet> = createEntityAdapter<PrescriptionSet>({
    selectId: prescriptionSet => prescriptionSet.id,
});

export const prescriptionSetInitialState: PrescriptionSetState = prescriptionSetAdapter.getInitialState({
    isLoading: false,
});

/**
 * prescription set state
 */
export interface PrescriptionSetState extends EntityState<PrescriptionSet> {
    /** loading */
    isLoading: boolean;

    /** * active for editing prescription set */
    activePrescriptionSet?: PrescriptionSet;

    /** error */
    error?: null;
}
