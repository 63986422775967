import { EventData } from '@angular/cdk/testing';
import { ElementRef } from '@angular/core';

/**
 * Creates a fake event object with any desired event type.
 *
 * @param type - The event type
 * @param canBubble - Define if the event can bubble up the DOM
 * @param eventData
 * @returns The event
 * @example
 * createFakeEvent('focus');
 * createFakeEvent('focus', false, false);
 */
export function createUiEvent(type: string, canBubble = true, eventData?: EventData): Event {
    const event: Event = new Event(type, { bubbles: canBubble });
    Object.defineProperty(event, 'target', { value: eventData });
    return event;
}

/**
 * Utility to dispatch any event on a Node.
 *
 * @param node - The Node that should dispatch the event
 * @param event - The event to be dispatched
 * @returns The dispatched event
 * @example
 * dispatchEvent(myNativeElement, 'blur');
 */
export function dispatchUiEvent(node: Node | Window, event: Event): Event {
    node.dispatchEvent(event);
    return event;
}

/**
 * INFO: add comment
 */
export type ContentPair = {
    /**
     * INFO: add comment
     */
    selector: string;
    /**
     * INFO: add comment
     */
    ref: ElementRef;
};

/**
 * @param inputs
 * @param component
 */
export function requireInput<T>(inputs: Array<keyof T>, component: any): void {
    inputs.forEach((input: any) => {
        if (component[input] === undefined || component[input] === null || component[input] === '') {
            const name: string = component.constructor.name;
            // eslint-disable-next-line no-console
            console.warn(`Mona UI ${name} error: Property "${input}" is required.`);
        }
    });
}

/**
 * @param contentPairs
 * @param component
 */
export function requireContent(contentPairs: ContentPair[], component: any): void {
    contentPairs.forEach(contentPair => {
        if (!contentPair.ref.nativeElement.children || contentPair.ref.nativeElement.children.length === 0) {
            const name: string = component.constructor.name;
            // eslint-disable-next-line no-console
            console.warn(`Mona UI ${name} error: Selector "${contentPair.selector}" is required.`);
        }
    });
}

/**
 * @param el
 */
function hasChildren(el: ElementRef): boolean {
    return el.nativeElement.children && el.nativeElement.children.length > 0;
}

/**
 * @param el
 */
export function isEmptyView(el: ElementRef): boolean {
    if (!el || !el.nativeElement) {
        return true;
    }
    return !hasChildren(el);
}
