<ui-info-list-item>
    <div ui-title>
        {{ (isLoading ? inProgressTitle : error ? failedTitle : succeededTitle) | translate }}
    </div>
    <div *ngIf="url" class="text-muted" ui-subtitle>{{ url }}</div>
    <div *ngIf="requiredHint" class="text-muted" ui-info>
        {{ requiredHint | translate }}
    </div>
    <mat-icon *ngIf="!isLoading" ui-icon [ngClass]="!error ? 'text-success' : 'text-accent'">
        {{ !error ? 'check_circle' : 'warning' }}
    </mat-icon>
    <mat-spinner *ngIf="isLoading" ui-icon [color]="'primary'" [diameter]="24"></mat-spinner>
    <div ui-right-content>
        <button
            mat-button
            *ngIf="error as actionError"
            (click)="showErrorDetails.emit(actionError)"
            color="primary"
            data-testid="btn-show"
        >
            {{ 'diagnostics.errorDetails' | translate }}
        </button>
        <button
            mat-button
            *ngIf="hasChangeBtn"
            (click)="changeButtonClicked.emit()"
            color="primary"
            data-testid="btn-change"
        >
            {{ 'diagnostics.changeUrl' | translate }}
        </button>
    </div>
</ui-info-list-item>
