import { ApiModels } from '@mona/api';
import { PhysicalExamination } from '@mona/models';

/**
 * Transforms api physical examination
 * @param apiPhysicalExamination apiPhysicalExamination
 */
export const transformApiPhysicalExamination = (
    apiPhysicalExamination: ApiModels.PhysicalExamination,
): PhysicalExamination => {
    return {
        id: apiPhysicalExamination.id,
        text: apiPhysicalExamination.text,
    };
};

/**
 * Transform api physical examinations
 * @param apiPhysicalExaminations apiPhysicalExaminations
 */
export const transformApiPhysicalExaminations = (
    apiPhysicalExaminations: ApiModels.PhysicalExamination[],
): PhysicalExamination[] => {
    return apiPhysicalExaminations.map(apiPhysicalExamination =>
        transformApiPhysicalExamination(apiPhysicalExamination),
    );
};
