import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from '@mona/api';
import { BalanceValue } from '@mona/models';

/**
 * API abstraction layer for the balance targets
 */
@Injectable({
    providedIn: 'root',
})
export class BalanceApi {
    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Balance values
     *
     * @param encounter_id
     * @param interval - in minutes
     */
    getBalanceValues(encounter_id: string, interval: number): Observable<BalanceValue[]> {
        return this.http
            .get('/pdms/balances', {
                params: HttpService.buildHttpParams({ encounter_id, interval }),
            })
            .pipe(
                map(values => {
                    // FIXME: extract to transformer to match codebase even if its old
                    return values.map((balanceValue, i) => {
                        const mapped = {
                            ...balanceValue,
                            balanceTargetId: balanceValue.balance_target_id,
                        };

                        delete balanceValue['balance_target_id'];

                        return mapped;
                    }) as BalanceValue[];
                }),
                catchError(error => {
                    return of([]);
                }),
            );
    }
}
