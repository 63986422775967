import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of, forkJoin, from } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { IpcMainEvent } from '@mona/events';
import { TerminalKeysConfig, Timezone, TerminalConfig } from '@mona/models';
import { MonaRpcService } from '@mona/rpc';
import { Logger } from '@mona/shared/logger';
import { omit } from '@mona/shared/utils';
import { DeviceActions } from '../actions';

/**
 * Device Effects
 */
@Injectable({ providedIn: 'root' })
export class DeviceEffects implements OnInitEffects {
    private readonly logger = new Logger('DEVICE');

    initDeviceData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.initDeviceData),
            // switchMap(() => this.appRef.isStable),
            mergeMap(() => [
                //
                DeviceActions.getKeys(),
                DeviceActions.getBrightness(),
                DeviceActions.getCurrentTimezone(),
            ]),
        ),
    );

    getBrightness$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.getBrightness),
            exhaustMap(() =>
                from(this.rpcService.invoke<number>(IpcMainEvent.GET_CURRENT_BRIGHTNESS)).pipe(
                    catchError(err => {
                        this.logger.error(err);
                        return of(100);
                    }),
                ),
            ),
            map(value => DeviceActions.getBrightnessSuccess({ value })),
        ),
    );

    setBrightness$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.setBrightness),
            exhaustMap(({ value }) =>
                from(this.rpcService.invoke<number>(IpcMainEvent.SET_CURRENT_BRIGHTNESS, { value })).pipe(
                    catchError(err => {
                        this.logger.error(err);
                        return of();
                    }),
                ),
            ),
            map(() => DeviceActions.setBrightnessSuccess()),
        ),
    );

    sendAppReload$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.sendAppReload),
                switchMap(() => from(this.rpcService.sendSync<string>(IpcMainEvent.RELOAD))),
            ),
        { dispatch: false },
    );

    sendSystemReboot$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.sendSystemReboot),
                switchMap(() => from(this.rpcService.sendSync<string>(IpcMainEvent.REBOOT))),
            ),
        { dispatch: false },
    );

    sendSystemShutdown$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.sendSystemShutdown),
                switchMap(() => from(this.rpcService.sendSync<string>(IpcMainEvent.SHUTDOWN))),
            ),
        { dispatch: false },
    );

    /**
     * Runs the pre-call bash script
     */
    invokePreCallScript$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.invokePreCallScript),
            switchMap(() => from(this.rpcService.invoke<string>(IpcMainEvent.RUN_PRE_CALL))),
            map(() => DeviceActions.toggleCameraSuccess({ value: true })),
        ),
    );

    /**
     * Runs the post-call bash script
     */
    invokePostCallScript$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.invokePostCallScript),
            switchMap(() => from(this.rpcService.invoke<string>(IpcMainEvent.RUN_POST_CALL))),
            map(() => DeviceActions.toggleCameraSuccess({ value: false })),
        ),
    );

    /**
     * Runs the post-call bash script
     */
    resetConfig$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.resetConfig),
                switchMap(() => from(this.rpcService.invoke<string>(IpcMainEvent.RESET_CONFIG))),
            ),
        { dispatch: false },
    );

    getCurrentTimezone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.getCurrentTimezone),
            exhaustMap(() =>
                from(this.rpcService.invoke<Timezone>(IpcMainEvent.GET_CURRENT_TIMEZONE)).pipe(
                    catchError(err => {
                        this.logger.error(err);
                        return of<Timezone>(null);
                    }),
                ),
            ),
            map(value => DeviceActions.getCurrentTimezoneSuccess({ value })),
        ),
    );

    setTimezone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.setTimezone),
            switchMap(({ value }) =>
                from(this.rpcService.invoke<string>(IpcMainEvent.SET_TIME_ZONE, value)).pipe(
                    catchError(err => {
                        this.logger.error('SET_TIME_ZONE', err);
                        return of<Timezone>(null);
                    }),
                    map(() => DeviceActions.getCurrentTimezoneSuccess({ value })),
                ),
            ),
        ),
    );

    /**
     * Sets system language bash script
     */
    setOSKLanguage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.setOSKLanguage),
                switchMap(({ value }) =>
                    from(this.rpcService.invoke<string>(IpcMainEvent.SET_OSK_LANGUAGE, value)).pipe(
                        catchError(err => {
                            this.logger.error('SET_OSK_LANGUAGE', err);
                            return of<string>(null);
                        }),
                    ),
                ),
            ),
        { dispatch: false },
    );

    /**
     * Sets system keyboard availability bash script
     */
    setOSKAvailability$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DeviceActions.setOSKAvailability),
                switchMap(({ value }) =>
                    from(this.rpcService.invoke<string>(IpcMainEvent.SET_OSK_AVAILABILITY, value)).pipe(
                        catchError(err => {
                            this.logger.error('SET_OSK_AVAILABILITY', err);
                            return of<string>(null);
                        }),
                    ),
                ),
            ),
        { dispatch: false },
    );

    getKeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.getKeys),
            exhaustMap(() =>
                from(this.rpcService.invoke<TerminalKeysConfig>(IpcMainEvent.GET_KEYS)).pipe(
                    catchError(err => {
                        this.logger.error(err);
                        return of<TerminalKeysConfig>({} as TerminalKeysConfig);
                    }),
                ),
            ),
            map(({ sshKey, telemedicineSshKey, version }) => {
                return DeviceActions.getKeysSuccess({
                    keys: {
                        version,
                        sshKey: omit(sshKey, 'privateKey'),
                        telemedicineSshKey: omit(telemedicineSshKey, 'privateKey'),
                    },
                });
            }),
        ),
    );

    /**
     * Generates telemedicine key pair and reloads config
     */
    generateTelemedicineDeviceKeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActions.resetConfig, DeviceActions.generateTelemedicineDeviceKeys),
            switchMap(() => from(this.rpcService.invoke<string>(IpcMainEvent.GENERATE_TELEMEDICINE_DEVICE_KEYS))),
            map(() => DeviceActions.getKeys()),
        ),
    );

    /**
     * Creates an instance of DeviceEffects.
     *
     * @param {Actions} actions$
     * @param {MonaRpcService} rpcService
     * @param {ConfigService} config
     */
    constructor(private actions$: Actions, private rpcService: MonaRpcService, private config: ConfigService) {}

    /**
     * Implement this interface to dispatch a custom action after
     * the effect has been added. You can listen to this action
     * in the rest of the application to execute something after
     * the effect is registered.
     */
    ngrxOnInitEffects(): Action {
        this.setSystemKeyboardPreferences();
        return DeviceActions.initDeviceData();
    }

    /**
     * Set system keyboard preferences according to mona config
     */
    setSystemKeyboardPreferences(): void {
        this.config.config$
            .pipe(
                filter(Boolean),
                switchMap(({ keyboardLocale, isBedSide }: TerminalConfig) =>
                    forkJoin([
                        from(this.rpcService.invoke<string>(IpcMainEvent.SET_OSK_LANGUAGE, keyboardLocale)),
                        from(this.rpcService.invoke<string>(IpcMainEvent.SET_OSK_AVAILABILITY, isBedSide)),
                    ]),
                ),
                catchError(error => {
                    this.logger.error(`Failed to set keyboard preferences:`, error);
                    return of(error);
                }),
                take(1),
            )
            .subscribe();
    }
}
