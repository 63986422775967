<fieldset [formGroup]="formGroup" appKeyboardForm>
    <mat-checkbox class="mona-medication__form-checkbox" [formControl]="controls?.status">
        {{ 'apps.settings.' + formType + '.fields.reason.label' | translate: { time: prescriptionTime } }}
    </mat-checkbox>
    <mat-form-field
        *ngIf="controls?.status?.value"
        class="app-input-field app-input-field--fullwidth"
        appearance="fill"
    >
        <mat-label>
            {{ 'apps.settings.medications.fields.reason.selectLabel' | translate }}
        </mat-label>
        <mat-select
            [formControl]="controls?.reason"
            [placeholder]="'apps.settings.medications.fields.reason.selectLabel' | translate"
            panelClass="app-input-select-panel scrollbar-visible"
            class="app-set-medication__select"
            [compareWith]="compareFn"
        >
            <mat-option [value]="null" disabled>
                {{ 'apps.settings.medications.fields.reason.selectLabel' | translate }}
            </mat-option>
            <mat-option *ngFor="let reason of prescriptionNotGivenReasons; trackBy: trackByReason" [value]="reason">
                {{ reason.text }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="app-input-field app-input-field--fullwidth" *ngIf="controls?.reason?.value?.showTextbox">
        <mat-label>
            {{ 'apps.settings.medications.fields.reason.additionalInfo' | translate }}
        </mat-label>
        <input
            matInput
            appKeyboardInput
            class="app-set-medication__input"
            [placeholder]="'apps.settings.medications.fields.reason.additionalInfo' | translate"
            [formControl]="controls?.reasonAdditional"
        />
    </mat-form-field>
</fieldset>
