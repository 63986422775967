import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { FhirConfigApi } from '../../infrasctructure';
import * as FhirConfigActions from '../actions/fhir-config.actions';

/**
 * Fhir config effects
 */
@Injectable()
export class FhirConfigEffects {
    loadFhirConfigs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FhirConfigActions.loadFhirConfigs),
            switchMap(() => this.fhirConfigApi.getFhirConfig()),
            map(data => FhirConfigActions.loadFhirConfigsSuccess({ data })),
            catchError((error: unknown) => of(FhirConfigActions.loadFhirConfigsFailure({ error }))),
        );
    });

    /**
     * constructor
     *
     * @param actions$
     * @param fhirConfigApi
     */
    constructor(private actions$: Actions, private fhirConfigApi: FhirConfigApi) {}
}
