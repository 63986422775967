import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { differenceInCalendarDays, isAfter } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeLogEntry, ChangeLogOperation, ProcedurePrescription } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    ChangeLogService,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import {
    extendPrescriptionsLastChangedBy,
    confirmDialogData,
    getEncounterViewSelectedDate,
    withCurrentEncounterId,
} from '@mona/pdms/data-access-combined';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { Logger } from '@mona/shared/logger';
import { ConfirmDialogComponent, DialogData, DialogService, MessageService } from '@mona/ui';
import { DataAccessProceduresFacade } from '../../application';
import { ProceduresState } from '../../entities';
import { ProcedurePrescriptionsApi } from '../../infrastructure';
import { ProcedurePrescriptionsActions } from '../actions';
import {
    addProcedurePrescription,
    addProcedurePrescriptionsChanges,
    loadProcedurePrescriptions,
    loadProcedurePrescriptionsFailure,
    processProcedurePrescriptions,
    updateProcedurePrescription,
    upsertProcedurePrescriptions,
} from '../actions/procedure-prescription.actions';
import { selectProcedurePrescriptionAll } from '../selectors';

/**
 * ProceduresEffects
 */
@Injectable()
export class ProcedurePrescriptionsEffects implements OnInitEffects {
    private logger = new Logger('PDMS:ProcedurePrescriptionsEffects');
    changeLogMap$ = this.store.select(ChangeLogSelectors.getChangesMap);
    procedurePrescriptionAll$ = this.store.select(selectProcedurePrescriptionAll);
    selectedDate$: Observable<Date> = getEncounterViewSelectedDate();

    /* Load procedure prescriptions */
    loadProcedurePrescriptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadProcedurePrescriptions),
            withCurrentEncounterId(),
            switchMap(([, encounterId]) =>
                this.procedurePrescriptionsApi.getProcedurePrescriptions(encounterId).pipe(
                    map(procedurePrescriptions =>
                        processProcedurePrescriptions({
                            procedurePrescriptions,
                        }),
                    ),
                    catchError(error => {
                        this.logger.error('Error loading procedure prescriptions', error);
                        return of(ProcedurePrescriptionsActions.loadProcedurePrescriptionsFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    /** process medication prescriptions from API */
    processProcedurePrescriptions$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(processProcedurePrescriptions),
                map(action => action.procedurePrescriptions),
                withLatestFrom(this.changeLogMap$, this.practitionersFacade.practitionersMap$),
                tap(([data, changesMap, practitionersMap]) => {
                    const changes: ChangeLogEntry<ProcedurePrescription>[] = changesMap['ProcedurePrescription'] || [];

                    if (changes.length) {
                        data = applyInstancesChanges(data, changes);
                    }

                    const procedurePrescriptions = extendPrescriptionsLastChangedBy(data, practitionersMap);
                    this.store.dispatch(upsertProcedurePrescriptions({ procedurePrescriptions }));
                }),
            ),
        { dispatch: false },
    );

    /**
     * Add preocedure using ChangeLog
     * then it is added to reducer via `onChangeSaved` effect
     */
    addProcedurePrescription$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addProcedurePrescription),
                tap(({ procedurePrescription }) => {
                    this.changeLogService.createProcedurePrescription(procedurePrescription);
                }),
            ),
        { dispatch: false },
    );

    /**
     * Update preocedure using ChangeLog
     * then it is added to reducer via `onChangeSaved` effect
     */
    updateProcedurePrescription$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateProcedurePrescription),
                tap(({ update }) => {
                    const procedurePrescription = {
                        ...update.changes,
                        id: update.id as string,
                    };
                    this.changeLogService.updateProcedurePrescription(procedurePrescription);
                }),
            ),
        { dispatch: false },
    );

    /** Listen change save success & upsert entity + show message */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(
                    this.procedurePrescriptionAll$,
                    this.changeLogMap$,
                    this.practitionersFacade.practitionersMap$,
                    this.selectedDate$,
                ),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes: ChangeLogEntry<ProcedurePrescription>[] = changesMap['ProcedurePrescription'] || [];

                    if (changes.length) {
                        let procedurePrescriptions = applyInstancesChanges(data, changes);
                        procedurePrescriptions = extendPrescriptionsLastChangedBy(
                            procedurePrescriptions,
                            practitionersMap,
                        );

                        this.store.dispatch(
                            upsertProcedurePrescriptions({
                                procedurePrescriptions,
                            }),
                        );

                        this.messageService.successToast('apps.settings.messages.saveSuccess');
                    }
                }),
                tap(([action, data, changesMap, practitionersMap, activeDate]) => {
                    if (action.payload?.['model'] === 'ProcedurePrescription') {
                        const startDate = action?.payload?.['payload']?.startDate;

                        if (startDate) {
                            const prescribedForFuture = differenceInCalendarDays(startDate, activeDate) > 0;

                            if (prescribedForFuture) {
                                this.dialogService.showConfirmDialog(confirmDialogData()).pipe(take(1)).subscribe();
                            }
                        }
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.procedurePrescriptionAll$,
                    this.changeLogMap$,
                    this.practitionersFacade.practitionersMap$,
                ),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes = changesMap['ProcedurePrescription'] || [];

                    if (changes.length) {
                        const removeUpdateData = getPersistedChangesData(data, changes);
                        removeUpdateData.toUpdate = extendPrescriptionsLastChangedBy(
                            removeUpdateData.toUpdate,
                            practitionersMap,
                        );

                        this.store.dispatch(
                            addProcedurePrescriptionsChanges({
                                toUpdateEntities: removeUpdateData.toUpdate || [],
                                toRemoveIds: removeUpdateData.toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Handle remove procedure prescription
     */
    handleRemoveProcedurePrescription$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProcedurePrescriptionsActions.handleDeleteProcedurePrescription),
                switchMap(({ procedurePrescription }) => {
                    return this.dialogService
                        .open(ConfirmDialogComponent, this.getDeleteProcedurePrescriptionDialogData())
                        .pipe(switchMap(confirmed => (confirmed ? of(procedurePrescription) : of(null))));
                }),
                tap(procedurePrescription => {
                    if (!procedurePrescription) {
                        return;
                    }

                    const updatedPrescription = {
                        ...procedurePrescription,
                        isRevoked: true,
                        isApproved: false,
                    };

                    // NOTE: we set end date as delete date to finish medication
                    const { endDate } = procedurePrescription;
                    const now = new Date();
                    if (!endDate || (endDate && isAfter(endDate, now))) {
                        updatedPrescription.endDate = now;
                    }

                    this.changeLogService.updateProcedurePrescription(updatedPrescription);
                }),
            ),
        { dispatch: false },
    );

    confirmChanges$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProcedurePrescriptionsActions.handleConfirmChanges),
                withLatestFrom(this.proceduresFacade.procedurePrescriptionsUnconfirmed$),
                tap(([, procedurePrescriptions]) => {
                    const toConfirm: ProcedurePrescription[] = [];
                    for (const prescription of procedurePrescriptions) {
                        if (prescription.isApproved) {
                            continue;
                        }

                        toConfirm.push({ ...prescription, isApproved: true });
                    }

                    this.changeLogService.updateProcedurePrescriptions(toConfirm);
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store Store
     * @param actions$ Actions
     * @param procedurePrescriptionsApi ProcedurePrescriptionsApi
     * @param dialogService DialogService
     * @param changeLogService ChangeLogService
     * @param messageService MessageService
     * @param practitionersFacade PractitionersFacade
     * @param proceduresFacade DataAccessProceduresFacade
     */
    constructor(
        private store: Store<ProceduresState>,
        private actions$: Actions,
        private procedurePrescriptionsApi: ProcedurePrescriptionsApi,
        private dialogService: DialogService,
        private changeLogService: ChangeLogService,
        private messageService: MessageService,
        private practitionersFacade: PractitionersFacade,
        private proceduresFacade: DataAccessProceduresFacade,
    ) {}

    /**
     * ProcedurePrescriptionsEffects -> OnInitEffects
     */
    ngrxOnInitEffects(): Action {
        console.log('TODO: ProcedurePrescriptionsEffects implements OnInitEffects');
        return { type: 'EMPTY' };
    }

    private getProcedureEnding(q: number): string {
        return `procedure${q > 1 ? 's' : ''}`;
    }

    private showDetailedSuccessMessage(changes: ChangeLogEntry<ProcedurePrescription>[]) {
        const addChanges = [];
        const editChanges = [];
        const deleteChanges = [];

        // aggregate changes by modelId to notify about the latest operations only
        const reducedChanges = Array.from(
            changes
                .reduce((map, entry) => {
                    map.set(entry.modelId, entry);
                    return map;
                }, new Map())
                .values(),
        ) as any as ChangeLogEntry<ProcedurePrescription>[];
        reducedChanges.forEach(c => {
            c.operation === ChangeLogOperation.Create && addChanges.push(c);
            c.operation === ChangeLogOperation.Update && editChanges.push(c);
            c.operation === ChangeLogOperation.Update && c.payload.isRevoked && deleteChanges.push(c);
        });

        const createMessage = addChanges.length
            ? `${addChanges.length} ${this.getProcedureEnding(addChanges.length)} will be added`
            : '';
        const updateMessage = editChanges.length
            ? `${editChanges.length} ${this.getProcedureEnding(editChanges.length)} will be updated`
            : '';
        const deleteMessage = deleteChanges.length
            ? `${deleteChanges.length} ${this.getProcedureEnding(deleteChanges.length)} will be revoked`
            : '';
        const message = `${createMessage ? createMessage + '\n' : ''} ${updateMessage ? updateMessage + '\n' : ''} ${
            deleteMessage ? deleteMessage + '\n' : ''
        }`;
        this.messageService.successToast(message);
    }

    /**
     * Resolves data for confirm prescription procedure removing dialog
     */
    private getDeleteProcedurePrescriptionDialogData(): DialogData {
        return {
            title: 'apps.patient.prescriptions.procedureTable.delete.title',
            description: 'apps.patient.prescriptions.procedureTable.delete.message',
            cancelBtn: 'apps.patient.prescriptions.procedureTable.delete.cancel',
            confirmBtn: 'apps.patient.prescriptions.procedureTable.delete.confirm',
            imgSrc: 'assets/images/discard.svg',
        };
    }
}
