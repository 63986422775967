import { ApiModels } from '@mona/api';
import { PrescriptionNotGivenReason } from '@mona/models';

/**
 * Transform api prescription not given reason
 *
 * @param apiPrescriptionNotGivenReason
 */
export const transformApiPrescriptionNotGivenReason = (
    apiPrescriptionNotGivenReason: ApiModels.PrescriptionNotGivenReason,
): PrescriptionNotGivenReason => {
    return ApiModels.PrescriptionNotGivenReason.DTO.toModel(apiPrescriptionNotGivenReason);
};

/**
 * Transform api prescription not given reason
 *
 * @param apiPrescriptionNotGivenReasons
 */
export const transformApiPrescriptionNotGivenReasons = (
    apiPrescriptionNotGivenReasons,
): PrescriptionNotGivenReason[] => {
    return apiPrescriptionNotGivenReasons.map(apiPrescriptionNotGivenReason =>
        transformApiPrescriptionNotGivenReason(apiPrescriptionNotGivenReason),
    );
};
