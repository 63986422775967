import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { differenceInCalendarDays } from 'date-fns';
import { combineLatest, interval, Observable, of } from 'rxjs';
import { delayWhen, filter, map, startWith } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import {
    BaseMedication,
    BloodAdministrationType,
    Medication,
    MedicationAdministration,
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationDosageForm,
    MedicationPrescription,
    MedicationSolution,
    MedicationUnit,
    Practitioner,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
    PrescriptionNotGivenReason,
    PrescriptionSet,
} from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import {
    getEncounterViewDateRange,
    getEncounterViewSelectedDate,
    getUnconfirmedMedPrescriptions,
    PrescriptionTerminologyGetter,
    PRESCRIPTION_TERMINOLOGY_GETTER,
    getEncounterStartDate,
    isAbleToEdit,
} from '@mona/pdms/data-access-combined';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { notEmpty } from '@mona/shared/utils';
import { AppState } from '@mona/store';
import { DialogService, MessageService } from '@mona/ui';
import { medicationsFeatureKey, MedicationsState } from '../entities';
import {
    clearMedicationPrescriptions,
    handleAddMedicationPrescription,
    handleConfirmMedicationPrescriptionChanges,
    handleDeleteMedicationPrescription,
    loadMedicationPrescriptions,
    processMedicationPrescriptions,
    upsertMedicationPrescription,
} from '../state/actions';
import {
    selectMedicationPrescriotionsDates,
    selectMedicationPrescriptionAll,
    selectMedicationPrescriptionEntities,
    selectMedicationPrescriptionError,
    selectMedicationPrescriptionMapByCategory,
    selectMedicationPrescriptionsFiltered,
} from '../state/selectors';
import { DataAccessStandardMedicationsFacade } from './standard-medications.service';

type State = AppState & { [medicationsFeatureKey]: MedicationsState };

/**
 * Medication component facade service
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessMedicationsFacade {
    /** Is encounter in review mode */
    isAbleToEdit$: Observable<boolean> = isAbleToEdit();
    /** Current practitioner */
    currentPractitioner$: Observable<Practitioner> = this.authService.user$.pipe(notEmpty());
    /** Practitioners dictionary */
    practitioners$: Observable<EntityMap<Practitioner>> = this.practitionersFacade.practitionersMap$.pipe(notEmpty());
    /** Medications as map */
    medicationsMap$: Observable<EntityMap<Medication>> = this.terminologyService.getMedicationsMap();
    /** Medications groups */
    medicationGroups$: Observable<MedicationCategory[]> = this.terminologyService.getMedicationGroups();
    /** Frequency dictionary */
    medicationFrequencies$: Observable<PrescriptionFrequency[]> = this.terminologyService.getPrescriptionFrequencies();
    /** Frequency times dictionary */
    medicationFrequencyTimes$: Observable<PrescriptionFrequencyTime[]> =
        this.terminologyService.getPrescriptionFrequencyTimes();
    /** Medications categories with fields config */
    medicationCategories$: Observable<MedicationCategory[]> = this.terminologyService.getMedicationCategories();
    /** Medication categories display name map */
    medicationCategoriesMap$: Observable<EntityMap<MedicationCategory>> =
        this.terminologyService.getMedicationCategoriesMap();
    /** Medications categories */
    medicationAdministrationMethods$: Observable<MedicationAdministrationMethod[]> =
        this.terminologyService.getMedicationAdministrationMethods();
    /** Medications categories */
    medicationUnits$: Observable<MedicationUnit[]> = this.terminologyService.getMedicationUnits();
    /** Medications categories */
    medicationSolutions$: Observable<MedicationSolution[]> = this.terminologyService.getMedicationSolutions();
    /** Medications categories */
    bloodAdministrations$: Observable<BloodAdministrationType[]> = this.terminologyService.getBloodAdministrations();
    /** Dosage forms */
    dosageForms$: Observable<MedicationDosageForm[]> = this.terminologyService.getDosageForms();
    /** Prescription not given reason */
    prescriptionNotGivenReasons$: Observable<PrescriptionNotGivenReason[]> =
        this.terminologyService.getPrescriptionNotGivenReasons();
    /** Is loading (withh additional 500ms delay) */
    isLoadingMedicationPrescriptions$: Observable<boolean> = combineLatest([
        // this.getLoadMedicationPrescriptionsAction(), // FIXME
        this.changeLogService
            .getLoadChangesAction()
            .pipe(filter(action => !!action.result?.filter(change => change.model === 'MedicationPrescription'))),
    ]).pipe(
        map(actions => actions.some(action => action.inProgress)),
        delayWhen(loading => interval(loading ? 0 : 500)),
        startWith(true),
    );

    medicationPrescriptionError$ = this.store.select(selectMedicationPrescriptionError);

    /** Loading state aggregate */
    isLoadingStandardMedications$: Observable<boolean> = this.standardMedicationsFacade.isLoading$;

    /** Encounter start date */
    encounterStartDate$: Observable<Date> = getEncounterStartDate(true);

    /**
     * Currently selected date
     */
    selectedDate$: Observable<Date> = getEncounterViewSelectedDate();

    /**
     * Medication prescriptions date range (min start max end)
     */
    dateRange$: Observable<{ start: Date; end: Date }> = getEncounterViewDateRange();

    /**
     * Medication prescriptions ALL
     */
    medicationPrescriptions$: Observable<MedicationPrescription[]> = this.store.select(selectMedicationPrescriptionAll);

    /** medication prescriptions map */
    medicationPrescriptionsMap$: Observable<Record<string, MedicationPrescription>> = this.store.select(
        selectMedicationPrescriptionEntities,
    );

    /** Unique startDates of prescriptions */
    medicationPrescriotionsDates$: Observable<Date[]> = this.store.select(selectMedicationPrescriotionsDates);

    /**
     * Resolves prescriptions by categories
     *
     * @param prescriptions MedicationPrescription[]
     */
    medicationPrescriptionsMapByCategory$: Observable<{ [k: string]: MedicationPrescription[] }> = this.store.select(
        selectMedicationPrescriptionMapByCategory,
    );

    /**
     * Medication prescriptions filtered by selected date & show deleted
     */
    medicationPrescriptionsFiltered$: Observable<MedicationPrescription[]> = this.store.select(
        selectMedicationPrescriptionsFiltered,
    );

    /**
     * Medication prescriptions filtered
     * + has category
     * + relevant for selected date
     * + are not stopped or has documented value
     */
    medicationPrescriptionsFilteredByDateAndValue$: Observable<MedicationPrescription[]> = this.store.select(
        selectMedicationPrescriptionsFiltered,
    );

    /**
     * Medication prescriptions which have unconfirmed prescriptions
     */
    medicationPrescriptionsUnconfirmed$: Observable<MedicationPrescription[]> = combineLatest([
        this.currentPractitioner$,
        this.medicationPrescriptions$,
    ]).pipe(
        map(([practitioner, medicationPrescriptions]) =>
            getUnconfirmedMedPrescriptions(medicationPrescriptions, practitioner),
        ),
    );

    /**
     * Can add new medication prescription
     */
    canAddNewMedicationPrescription$: Observable<boolean> = this.selectedDate$.pipe(
        // prescribe sets only for today of future days
        map(activeDate => differenceInCalendarDays(activeDate, new Date()) > -1),
    );

    /**
     * Holds list of available prescriptions sets
     */
    prescriptionsSets$: Observable<PrescriptionSet[]> = of([]); /* this.prescriptionSetsFacade.getPrescriptionSets(); */

    /**
     * Constructor
     *
     * @param authService AuthService
     * @param store Store<State>
     * @param practitionersFacade PractitionersFacade
     * @param terminologyService TerminologyService
     * @param medicationTerminologyGetter
     * @param standardMedicationsFacade StandardMedicationsFacade
     * @param changeLogService ChangeLogService
     * @param dialogService DialogService
     * @param messageService MessageService
     */
    constructor(
        private authService: AuthService,
        private store: Store<State>,
        private practitionersFacade: PractitionersFacade,
        private terminologyService: TerminologyService,
        @Inject(PRESCRIPTION_TERMINOLOGY_GETTER)
        public readonly medicationTerminologyGetter: PrescriptionTerminologyGetter,
        private standardMedicationsFacade: DataAccessStandardMedicationsFacade,
        private changeLogService: ChangeLogService,
        private dialogService: DialogService,
        private messageService: MessageService,
    ) {}

    /**
     * Get standard medication set by id
     *
     * @param id string
     */
    getStandardMedication(id: string) {
        return this.standardMedicationsFacade.getStandardMedication(id);
    }

    /**
     * Medication prescription
     *
     * @param id EntityId<MedicationPrescription>
     */
    getMedicationPrescription(id: EntityId<MedicationPrescription>): Observable<MedicationPrescription> {
        return this.store.select(state => state[medicationsFeatureKey].medicationPrescriptions.entities[id]);
    }

    /**
     * Load medication prescriptions
     */
    loadMedicationPrescriptions(): void {
        this.store.dispatch(loadMedicationPrescriptions());
    }

    /**
     * Clear medication prescriptions
     */
    clearMedicationPrescriptions(): void {
        this.store.dispatch(clearMedicationPrescriptions());
    }

    /**
     * Confirm changes
     */
    handleConfirmMedicationPrescriptions(): void {
        this.store.dispatch(handleConfirmMedicationPrescriptionChanges());
    }

    /**
     * process medication prescriptions
     *
     * @param medicationPrescriptions
     */
    processMedicationPrescriptions(medicationPrescriptions: MedicationPrescription[]): void {
        this.store.dispatch(processMedicationPrescriptions({ medicationPrescriptions }));
    }

    /**
     * Removes medication prescription
     *
     * @param medicationPrescription MedicationPrescription
     */
    removeMedicationPrescription(medicationPrescription: MedicationPrescription): void {
        this.store.dispatch(handleDeleteMedicationPrescription({ medicationPrescription }));
    }

    /**
     * Update medication prescriptions
     *
     * @param updatedMedications MedicationPrescription[]
     */
    updateMedicationPrescriptions(updatedMedications: MedicationPrescription[]): void {
        this.changeLogService.updateMedicationPrescriptions(updatedMedications);
    }

    /**
     * Upsert medication prescriptions
     *
     * @param medicationPrescription
     */
    upsertMedicationPrescription(medicationPrescription: MedicationPrescription): void {
        this.store.dispatch(upsertMedicationPrescription({ medicationPrescription }));
    }

    /**
     * Search standard medication sets by medication code
     *
     * @param code string
     */
    searchStandardMedicationsByMedicationCode(code: string) {
        return this.standardMedicationsFacade.searchStandardMedicationsByMedicationCode(code);
    }

    /**
     * Search medications directly via api by params
     *
     * @param displayName string
     * @param id string
     * @param code string
     */
    searchMedicationsApiByParams(displayName: string, id: string, code: string) {
        return this.terminologyService.searchMedicationsApiByParams(displayName, id as string, code);
    }

    /**
     * Create standard medication set
     *
     * @param payload Omit<BaseMedication, 'id' | 'medication'>
     */
    createStandardMedication(payload: Omit<BaseMedication, 'id' | 'medication'>) {
        this.standardMedicationsFacade.createStandardMedication(payload);
    }

    /**
     * Update standard medication set
     *
     * @param id string
     * @param payload BaseMedication
     */
    updateStandardMedication(id: string, payload: BaseMedication) {
        this.standardMedicationsFacade.updateStandardMedication(id, payload);
    }

    /**
     * Add new medication prescription
     * If prescription is added for future date - show dialog
     *
     * @param payload MedicationPrescription
     */
    addMedicationPrescription(payload: MedicationPrescription) {
        this.store.dispatch(handleAddMedicationPrescription({ medicationPrescription: payload }));
    }

    /**
     * Update medication prescription
     *
     * @param payload
     * @param updateRate
     */
    updateMedicationPrescription(payload: MedicationPrescription, updateRate?: boolean) {
        this.changeLogService.updateMedicationPrescriptions([payload], updateRate);
    }

    /**
     * Update medication administration
     *
     * @param updatedAdministration
     */
    updateMedicationAdministration(updatedAdministration: MedicationAdministration) {
        this.changeLogService.updateMedicationAdministration(updatedAdministration.id, updatedAdministration);
    }

    /**
     * Update medication administration
     *
     * @param newAdministration
     */
    createMedicationAdministration(newAdministration: MedicationAdministration) {
        const payload: MedicationAdministration = {
            ...newAdministration,
            bloodAdministrationReason: newAdministration.bloodAdministrationReason?.toLowerCase(), // FIXME
        };
        this.changeLogService.createMedicationAdministration(payload);
    }
}
