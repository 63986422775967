import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { HeyMonaButtonComponent } from './hey-mona-button.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [HeyMonaButtonComponent],
    exports: [HeyMonaButtonComponent],
    imports: [CommonModule, MatRippleModule, MatIconModule],
})
export class HeyMonaButtonModule {}
