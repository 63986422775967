import { ApiModels } from '@mona/api';
import { DiagnosisRole, Diagnosis, VerificationStatus } from '@mona/models';

/**
 * Transforms API diagnosis
 *
 * @param apiDiagnosis ApiModels.Diagnosis
 */
export const transformApiDiagnosis = (apiDiagnosis: ApiModels.Diagnosis): Diagnosis => {
    return {
        id: apiDiagnosis.id,
        role: apiDiagnosis.role as DiagnosisRole,
        verificationStatus: apiDiagnosis.verification_status as VerificationStatus,
        description: apiDiagnosis.description,
        icd10Code: apiDiagnosis.icd_10_code,
    };
};

/**
 * Transforms API diagnoses
 *
 * @param apiDiagnoses ApiModels.Diagnosis[]
 */
export const transformApiDiagnoses = (apiDiagnoses: ApiModels.Diagnosis[]): Diagnosis[] => {
    return apiDiagnoses.map(apiDiagnosis => transformApiDiagnosis(apiDiagnosis));
};
