import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, concatMap, switchMap, exhaustMap } from 'rxjs/operators';
import { PractitionersApi } from '../../infrastructure';
import * as PractitionersActions from '../actions/practitioners.actions';
import * as PractitionersSelectors from '../selectors/practitioners.selectors';

/**
 * Practitioners effects
 */
@Injectable({ providedIn: 'root' })
export class PractitionersEffects {
    /* Effect Declarations */
    loadPractitioners$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PractitionersActions.loadPractitioners),
            concatLatestFrom(() => this.store.select(PractitionersSelectors.selectPractitionersIds)),
            concatMap(([{ ids, role }, selectedIds]) => {
                // if we need to load only practitioners if not loaded
                if (ids?.length && selectedIds?.length) {
                    const diffIds = ids.filter(x => !selectedIds.includes(x));
                    return diffIds?.length ? this.practitionersApi.getPractitioners(undefined, diffIds) : of([]);
                }
                // otherwise load from API with given params
                return this.practitionersApi.getPractitioners(role, ids);
            }),
            map(practitioners => PractitionersActions.loadPractitionersSuccess({ practitioners })),
            catchError(error => of(PractitionersActions.loadPractitionersFailure({ error }))),
        );
    });

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param store
     * @param practitionersApi PractitionersApi
     */
    constructor(private actions$: Actions, private store: Store<any>, private practitionersApi: PractitionersApi) {}
}
