import { DesktopCapturerSource } from 'electron';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Observable } from 'rxjs';
import { IpcMainEvent, IpcRendererEvent } from '@mona/events';

/**
 * Communicates to the electron shell
 */
@Injectable()
export abstract class MonaRpcService {
    /**
     * Communicate asynchronously from a renderer process to the main process.
     */
    protected readonly ipcRenderer: any; /* IpcRenderer */
    /** NodeJS.Env */
    readonly env: Record<string, string>;
    /**
     * Possible values: 'aix', 'darwin', 'freebsd', 'linux', 'openbsd', 'sunos', and 'win32'
     */
    readonly platform: NodeJS.Platform;
    /** NodeJS.ProcessVersions */
    readonly versions: NodeJS.ProcessVersions;

    /**
     * Constructor
     *
     * @param window
     */
    constructor(@Inject(WINDOW) protected window: Window) {}

    /**
     * Returns true on linux systems
     */
    getIsLinux(): boolean {
        return this.platform === 'linux';
    }

    /**
     * Get desktop capturer sources
     *
     * @param opts
     */
    abstract getDesktopCapturerSources(opts): Promise<DesktopCapturerSource[]>;

    /**
     * Sends a fire and forget event to node.
     *
     * @template T Expected result type
     * @param event The event handler registered at the main thread
     * @param payload The payload to send to the event handler
     */
    abstract send(event: IpcMainEvent, payload?: any): void;

    /**
     * Calls node in a synchronous way. Warning: This is thread blocking!
     *
     * @template T Expected result type
     * @param event The event handler registered at the main thread
     * @param payload The payload to send to the event handler
     */
    abstract sendSync<T>(event: IpcMainEvent, payload?: any): T;

    /**
     * Calls node in an asynchronous
     *
     * @template T Expected result type
     * @param event The event handler registered at the main thread
     * @param payload The payload to send to the event handler
     */
    abstract invoke<T>(event: IpcMainEvent, payload?: any): Promise<T>;

    /**
     * Call custom invoke to recieve correct error object & stack
     *
     * @template T Expected result type
     * @param event The event handler registered at the main thread
     * @param payload The payload to send to the event handler
     */
    // tslint:disable-next-line: completed-docs
    abstract invokeWithCustomErrors<T>(event: IpcMainEvent, payload?: any): Promise<T>;

    /**
     * Listens to events from the node main thread
     *
     * @template T Expected result type
     * @param event The event that is expected from the main thread
     * @deprecated Using MonaRpcService.listen might result in a thread blocking! Consider using invoke instead.
     */
    abstract listen<T>(event: IpcRendererEvent): Observable<T>;

    abstract onMessage<T>(eventName: IpcRendererEvent): Observable<T>;

    /**
     * Catch calling from web instance
     *
     * @param method The method name that was invoked
     */
    abstract ensurePlatform(method: string): void;

    /**
     * Reloads the app
     * If we serve it locally app is defined, if it is a prod build it is remote.app
     */
    reload() {
        // Make sure session storage is clear,
        // otherwise app-component detects a window refresh and does not navigate to start page
        this.window.sessionStorage.clear();
    }
}
