import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { UtilsModule } from '@mona/shared/utils';
import { DrawerSubheaderComponent } from './drawer-subheader.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [DrawerSubheaderComponent],
    imports: [UtilsModule, MatDividerModule],
    exports: [DrawerSubheaderComponent],
})
export class DrawerSubheaderModule {}
