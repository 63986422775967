// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializes Ventilation Modes from database.
 * @see #/components/schemas/VentilationMode - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface VentilationMode {
    /**
     * The code of the ventilation mode
     *
     */
    code: string;
    /**
     * The display name of the ventilation mode
     *
     */
    display_name: string;
    /**
     * The visibility of the ventilation mode
     *
     */
    is_visible?: boolean;
    /**
     * Defines the mode_type (Manual/Mechanical) of the ventilation mode  * `Manual` - Manual * `Mechanical` - Mechanical
     *
     */
    mode_type?: VentilationMode.ModeTypeEnum;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for VentilationMode
 */
export namespace VentilationMode {
    export type ModeTypeEnum = 'Manual' | 'Mechanical';
    export const ModeTypeEnum = {
        Manual: 'Manual' as ModeTypeEnum,
        Mechanical: 'Mechanical' as ModeTypeEnum,
    };

    /**
     * VentilationMode Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The code of the ventilation mode
         * @type string (`dataFormat` is missing)
         */
        code: string;
        /**
         * The display name of the ventilation mode
         * @type string (`dataFormat` is missing)
         */
        displayName: string;
        /**
         * The visibility of the ventilation mode
         * @type boolean (`dataFormat` is missing)
         */
        isVisible?: boolean;
        /**
         * Defines the mode_type (Manual/Mechanical) of the ventilation mode  * `Manual` - Manual * `Mechanical` - Mechanical
         * @type string (`dataFormat` is missing)
         */
        modeType?: VentilationMode.ModeTypeEnum;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * VentilationMode DTO
     * Transforms VentilationMode model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend VentilationMode model */
        static toModel(obj: VentilationMode): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend VentilationMode model */
        static toApi(obj: Model): VentilationMode {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as VentilationMode;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
