import { ApiModels } from '@mona/api';
import { ProcedurePrescription } from '@mona/models';

/**
 * Transforms api procedure prescription
 *
 * @param apiProcedure ApiModels.ProcedurePrescription
 */
export const transformApiProcedurePrescription = (
    apiProcedure: ApiModels.ProcedurePrescription,
): ProcedurePrescription => {
    const model = ApiModels.ProcedurePrescription.DTO.toModel(apiProcedure);
    return model;
};

/**
 * Transform api procedure prescriptions
 *
 * @param apiProcedures ApiModels.ProcedurePrescription[]
 */
export const transformApiProcedurePrescriptions = (
    apiProcedures: ApiModels.ProcedurePrescription[],
): ProcedurePrescription[] => {
    return apiProcedures.map(apiProcedure => transformApiProcedurePrescription(apiProcedure));
};
