import { Pipe, PipeTransform } from '@angular/core';
import { differenceInYears } from 'date-fns';

/**
 * Patient age pipe
 * Calculate age by gived date iso string
 */
@Pipe({
    name: 'patientAge',
})
export class PatientAgePipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param date date
     */
    transform(date: Date): number {
        return differenceInYears(new Date(), date);
    }
}
