import { NgModule } from '@angular/core';
import { UtilsModule } from '@mona/shared/utils';
import { ThreeLinerComponent } from './three-liner.component';

/**
 * ThreeLinerModule
 */
@NgModule({
    declarations: [ThreeLinerComponent],
    imports: [UtilsModule],
    exports: [ThreeLinerComponent],
})
export class UiThreeLinerModule {}
