// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating VentilationProcedure model structure.
 * @see #/components/schemas/VentilationProcedure - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface VentilationProcedure {
    /**
     * The date when the procedure ended.
     * @dataFormat date-time
     */
    end_date?: string;
    /**
     * The SNOMED code that represents the Ventilation-Mode.
     *
     */
    mode: string;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * The date when the procedure started.
     * @dataFormat date-time
     */
    start_date: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Ventilation-Procedure unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The identifier of the author - a practitioner. Could be empty if value is not entered manually.
     * @dataFormat uuid
     */
    author_id?: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for VentilationProcedure
 */
export namespace VentilationProcedure {
    /**
     * VentilationProcedure Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The date when the procedure ended.
         * @dataFormat date-time
         */
        @TransformDate() endDate?: Date;
        /**
         * The SNOMED code that represents the Ventilation-Mode.
         * @type string (`dataFormat` is missing)
         */
        mode: string;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * The date when the procedure started.
         * @dataFormat date-time
         */
        @TransformDate() startDate: Date;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Ventilation-Procedure unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The identifier of the author - a practitioner. Could be empty if value is not entered manually.
         * @dataFormat uuid
         */
        authorId?: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * VentilationProcedure DTO
     * Transforms VentilationProcedure model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend VentilationProcedure model */
        static toModel(obj: VentilationProcedure): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend VentilationProcedure model */
        static toApi(obj: Model): VentilationProcedure {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as VentilationProcedure;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
