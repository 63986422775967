/* eslint-disable no-restricted-syntax, @typescript-eslint/no-empty-function */
import { Direction } from '@angular/cdk/bidi';
import { ListRange } from '@angular/cdk/collections';
import { animationFrameScheduler, interval } from 'rxjs';
import { bufferWhen, finalize, takeWhile, tap } from 'rxjs/operators';
import { idle } from '@mona/shared/utils';
import { VHSTableScrollStrategy } from './vhs-table-scroll-abstract.strategy';

/**
 * Eager VirtualScrollStrategy for horizontal scrolling, with basic methods implementations
 */
export class VHSTableScrollEagerStrategy extends VHSTableScrollStrategy {
    /**
     * initEagerLoading
     *
     * @param dir
     */
    initLoading(dir: Direction = 'rtl'): void {
        if (!this.viewport || !this.itemsInViewport) {
            return;
        }
        const maxPreRenderedItems = this.columnBuffer * 5; // this.viewport.getDataLength() ?
        console.time('initLoading');
        interval(1000)
            .pipe(
                bufferWhen(() => idle()),
                tap(() => {
                    const { start, end } = this.viewport.getRenderedRange();
                    if (end === 0) {
                        this.viewport.setRenderedRange({
                            start: this.viewport.getDataLength() - this.columnBuffer,
                            end: this.viewport.getDataLength(),
                        });
                        this.viewport.setRenderedContentOffset(this.viewport['_totalContentSize'], 'to-end');
                        console.log(`VHSTableScrollEagerStrategy: rendered +${this.columnBuffer} table columns`);
                        return;
                    }
                    const range: ListRange = {
                        start: dir == 'rtl' ? start - this.columnBuffer : start,
                        end: dir == 'rtl' ? end : end + this.columnBuffer,
                    };
                    this.viewport.setRenderedRange(range);
                    this.viewport.setRenderedContentOffset(this.viewport['_totalContentSize'], 'to-end');
                    console.log(`VHSTableScrollEagerStrategy: total rendered ${this.columnBuffer} table columns`);
                }),
                takeWhile(() => {
                    const curr = this.viewport.renderedItemsLength;
                    return curr < maxPreRenderedItems;
                }),
                finalize(() => {
                    console.log(
                        `VHSTableScrollEagerStrategy: total rendered ${this.viewport.renderedItemsLength} table columns`,
                    );
                    console.timeEnd('initLoading');
                }),
            )
            .subscribe();
    }

    /** Calculate new range to render  */
    updateRenderedRange(): void {
        if (!this.viewport || !this.itemsInViewport) {
            return;
        }
        this._scrolledIndexChange.next(this.viewport.getDataLength() + 1);
    }

    /**
     * INFO: add comment
     */
    onRenderedOffsetChanged(): void {
        // this.viewport.setViewportScrollOffset(this.viewport['_totalContentSize']);
    }
}
