import { InjectionToken } from '@angular/core';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { ApiHealthState, HealthState, HealthStateMap } from '../../entities';
import * as ApiActions from '../actions/api.actions';

export const initialState: ApiHealthState = {
    healthState: {} as HealthStateMap,
    isOnline: true,
};

export const reducer = createReducer(
    initialState,
    on(ApiActions.changeOnline, (state, { isOnline }) => ({
        ...state,
        isOnline,
        // isConnected: isOnline ? state.isConnected : isOnline,
    })),
    on(ApiActions.loadApiHealth, ApiActions.checkApiHealthWS, (state, { serverUrl }) => {
        const serverHealthState = Object.assign({}, state.healthState[serverUrl], {
            error: null,
            isLoading: true,
        });
        return {
            ...state,
            healthState: {
                ...state.healthState,
                [serverUrl]: serverHealthState,
            },
        };
    }),
    on(ApiActions.loadApiHealthSuccess, (state, { serverUrl, healthState }) => {
        const serverHealthState = Object.assign({}, healthState, {
            error: null,
            isLoading: false,
            isConnected: true,
            lastCheckedAt: new Date(),
        });
        return {
            ...state,
            isOnline: true,
            healthState: {
                ...state.healthState,
                [serverUrl]: serverHealthState,
            },
        };
    }),
    on(ApiActions.loadApiHealthFailure, (state, { serverUrl, error }) => {
        const serverHealthState = Object.assign({}, state.healthState[serverUrl], {
            error,
            isLoading: false,
            isConnected: false,
            lastCheckedAt: new Date(),
        });
        return {
            ...state,
            isOnline: false,
            healthState: {
                ...state.healthState,
                [serverUrl]: serverHealthState,
            },
        };
    }),
    on(ApiActions.resetApiHealth, (state, { serverUrl }) => {
        const serverHealthState = {} as HealthState;
        return {
            ...state,
            healthState: {
                ...state.healthState,
                [serverUrl]: serverHealthState,
            },
        };
    }),
);

/** ApiHealthState reducer token */
export const API_HEALTH_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ApiHealthState>>(
    'ApiHealthState reducer token',
);
