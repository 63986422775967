<input
    matInput
    [id]="pickerId + '-input'"
    [matDatepicker]="picker"
    [value]="value"
    (dateInput)="onDateInput($event)"
    (dateChange)="onDateChange($event)"
    [matDatepickerFilter]="dateFilterFnWrapper"
    [disabled]="disabled"
    [min]="min"
    [max]="max"
    [name]="name"
    [required]="required"
    (blur)="onBlur()"
/>
<mat-datepicker [id]="pickerId" #picker></mat-datepicker>
<!-- This class is necessary to apply the mat-form-field styles on the `mat-datepicker-toggle` element. -->
<div class="mat-form-field-suffix">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
</div>
