import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InfectionStatuses } from '@mona/models';
import { InfectionForm } from '../../models';

/**
 * Infection Status Form
 */
@Component({
    selector: 'app-infection-form',
    templateUrl: './infection-form.component.html',
    styleUrls: ['./infection-form.component.scss'],
})
export class InfectionFormComponent {
    /**
     * Form group
     */
    @Input() formGroup: FormGroup<InfectionForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is form active / visible
     */
    @Input() isActive: boolean;

    /**
     * Max textarea rows count size
     */
    @Input() textareaMaxRows = 32;

    /**
     * Keyboard enter clicked
     */
    @Output() keyboardEnterClicked: EventEmitter<void> = new EventEmitter();

    /**
     * Infections verification states
     */
    infectionStates = Object.keys(InfectionStatuses)
        .map(key => InfectionStatuses[key])
        .filter(value => typeof value === 'string')
        .map((state: string) =>
            this.translateService.instant(
                'apps.admission.infectionStatus.availableStatuses.' + state.replace(/-/g, '').toLowerCase(),
            ),
        );

    /**
     * Constructor
     *
     * @param translateService
     */
    constructor(private translateService: TranslateService) {}
}
