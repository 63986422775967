import { EncounterHistoryEntry, HistoryEntryType } from '@mona/models';
import { EntitiesState } from '@mona/store';
import {
    AllergiesState,
    AnamnesisState,
    BalanceState,
    CareReportsState,
    DiagnosesState,
    LabValuesState,
    PhysicalExaminationState,
    PreMedicationsState,
    VentilationState,
    VitalSignsState,
    InfectionStatusState,
    TherapyLimitationsState,
    SurgeryPrescriptionState,
    VaccinationStatusState,
    WoundStatusState,
    ValuablesState,
} from './states';

export const encounterDataFeatureKey = 'currentEncounterData';

/**
 * Encounter feature state
 */
export interface EncounterFeatureState {
    /**
     * Selected Date
     *
     * @description used to filter ALL VIEWS which use DAY CALENDAR
     */
    selectedDate: Date | null;
    /**
     * History
     */
    encounterHistory: EntitiesState<EncounterHistoryEntry>;

    /**
     * Encounter history filter
     */
    encounterHistoryFilter: HistoryEntryType;

    /**
     * Anamnesis
     */
    anamnesis: AnamnesisState;

    /**
     * Allergies
     */
    allergies: AllergiesState;

    /**
     * Infections
     */
    infections: InfectionStatusState;

    /**
     * Valuables
     */
    valuables: ValuablesState;

    /**
     * vaccinations
     */
    vaccinations: VaccinationStatusState;

    /**
     * woundStatus
     */
    woundStatus: WoundStatusState;

    /**
     * surgeryPrescription
     */
    surgeryPrescription: SurgeryPrescriptionState;

    /**
     * TherapyLimitations
     */
    therapyLimitations: TherapyLimitationsState;

    /**
     * Diagnoses
     */
    diagnoses: DiagnosesState;

    /**
     * Pre medications
     */
    preMedications: PreMedicationsState;

    /**
     * Physical examinationState
     */
    physicalExamination: PhysicalExaminationState;

    /**
     * Vital signs
     */
    vitalSigns: VitalSignsState;

    /**
     * Lab values
     */
    labValues: LabValuesState;

    /**
     * Ventilation
     */
    ventilation: VentilationState;

    /**
     * Balance state
     */
    balance: BalanceState;

    /**
     * Care reports state
     */
    careReports: CareReportsState;

    /**
     * Encounters opened in review mode
     */
    isReviewMode?: boolean;
}
