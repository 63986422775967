/**
 * Table Scroll Strategy Type Enum
 *
 * @description doues not follow official documentation
 * https://www.typescriptlang.org/docs/handbook/enums.html#string-enums because we need to match
 * string from config which should be lowercase
 */
export enum TableScrollStrategyEnum {
    /**
     * A custom scroll strategy that updates Viewport rendered columns to render based on column width and buffer size BY SCROLLING BY COLUMN
     */
    default = 'default',
    /**
     * A custom scroll strategy that updates Viewport rendered columns to render based on column width and buffer size BY SCROLLING BY COLUMN
     */
    viewport = 'viewport',
    // TODO
    eager = 'eager',
}
