import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Ward } from '@mona/models';

/**
 * Wards state
 */
export interface WardsState extends EntityState<Ward> {
    /**
     * Assigned ward
     */
    assignedWardId?: EntityId<Ward>;
    /**
     * selected id
     */
    selectedId: string | null;
    /**
     * Wards is loading
     */
    isLoading: boolean;
    /**
     * Wards loading error
     */
    error?: any;
}

export const wardsAdapter: EntityAdapter<Ward> = createEntityAdapter<Ward>({
    selectId: (ward: Ward) => ward.id,
    sortComparer: (a: Ward, b: Ward) => (a.name > b.name ? 1 : -1),
});
