import { createReducer, on } from '@ngrx/store';
import { EnovacomState } from '../../entities';
import * as EnovacomActions from '../actions/enovacom.actions';

export const enovacomFeatureKey = 'enovacom';

export const initialState: EnovacomState = {} as EnovacomState;

export const reducer = createReducer(
    initialState,
    on(EnovacomActions.getEnovacomToken, state => ({ ...state, isTokenLoading: true })),
    on(EnovacomActions.getEnovacomTokenSuccess, (state, { token }) => ({ token, isTokenLoading: false, error: null })),
    on(EnovacomActions.getEnovacomTokenFailure, (state, { error }) => ({
        ...state,
        token: null,
        isTokenLoading: false,
        error,
    })),
);
