import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeLogAction, ChangeLogSelectors, ChangeLogState } from '@mona/pdms/data-access-changelog';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { isEmptyObject } from '@mona/shared/utils';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { BasicCareProceduresApi, EntryControlsApi } from '../../infrastructure';
import { CareReportsAction } from '../actions';

/**
 * Care reports effects
 */
@Injectable()
export class CareReportsEffects {
    /**
     * Load basic care procedures effect
     */
    loadBasicCareProcedures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CareReportsAction.loadBasicCareProceduresAction.action),
            withCurrentEncounterId(),
            switchMap(([, encounterId]) =>
                makeDefaultAsyncActionEffect(
                    this.basicCareProceduresApi.getBasicCareProcedures(encounterId),
                    CareReportsAction.loadBasicCareProceduresAction,
                ),
            ),
        ),
    );

    /**
     * Load entry controls effect
     */
    loadEntryControls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CareReportsAction.loadEntryControlsAction.action),
            withCurrentEncounterId(),
            switchMap(([, encounterId]) =>
                makeDefaultAsyncActionEffect(
                    this.entryControlsApi.getEntryControls(encounterId),
                    CareReportsAction.loadEntryControlsAction,
                ),
            ),
        ),
    );

    /** Listen change persist success & reload entry controls */
    onChangeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(this.store.select(ChangeLogSelectors.getChangesMap)),
                tap(([, changesMap]) => {
                    if (!isEmptyObject(changesMap['EntryControl'])) {
                        this.store.dispatch(CareReportsAction.loadEntryControlsAction.action({}));
                        this.store.dispatch(CareReportsAction.setPersistedEntryControls({ date: new Date() }));
                    }
                    if (!isEmptyObject(changesMap['BasicCare'])) {
                        this.store.dispatch(CareReportsAction.loadBasicCareProceduresAction.action({}));
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param basicCareProceduresApi BasicCareProceduresApi
     * @param store
     * @param entryControlsApi EntryControlsApi
     */
    constructor(
        private actions$: Actions,
        private basicCareProceduresApi: BasicCareProceduresApi,
        private store: Store<{ changelogData: ChangeLogState }>,
        private entryControlsApi: EntryControlsApi,
    ) {}
}
