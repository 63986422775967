import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import {
    UiDisableWhenCheckPermissionToken,
    UiDisableWhenCheckPermissionTriggerToken,
    UI_DISABLE_WHEN_CHECK_PERMISSION,
    UI_DISABLE_WHEN_CHECK_TRIGGER,
} from '@mona/ui';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthHostComponent } from './auth.component';
import { AuthService, TokenInterceptor } from './services';
import { authFeatureKey, authReducer, LoginEffects, LogoutEffects, RfidEffects } from './state';

/**
 * factory for {@link UiDisableWhenCheckPermissionToken}
 *
 * @param permissionsService
 */
export function hasPermissionFactory(permissionsService: NgxPermissionsService): UiDisableWhenCheckPermissionToken {
    return (p: string | string[]) => permissionsService.hasPermission(p);
}
/** @ignore */
export function hasPermissionTriggerFactory(
    permissionsService: NgxPermissionsService,
): UiDisableWhenCheckPermissionTriggerToken {
    return () => permissionsService.permissions$;
}

/**
 * Mona Auth Module
 */
@NgModule({
    imports: [
        AuthRoutingModule,
        NgxPermissionsModule.forRoot(),
        StoreModule.forFeature(authFeatureKey, authReducer),
        EffectsModule.forFeature([RfidEffects, LoginEffects, LogoutEffects]),
    ],
    declarations: [AuthHostComponent],
    exports: [NgxPermissionsModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (authService: AuthService) => () => authService.init(),
            deps: [AuthService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: UI_DISABLE_WHEN_CHECK_PERMISSION, useFactory: hasPermissionFactory, deps: [NgxPermissionsService] },
        {
            provide: UI_DISABLE_WHEN_CHECK_TRIGGER,
            useFactory: hasPermissionTriggerFactory,
            deps: [NgxPermissionsService],
        },
    ],
})
export class AuthModule {}
