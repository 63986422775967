/**
 * Admission type
 */
export enum AdmissionType {
    /**
     * General medical admission
     */
    GENERAL_MEDICAL = 'general-medical',

    /**
     * Planned surgical admission
     */
    PLANNED_SURGICAL = 'planned-surgical',

    /**
     * Emergency surgical admission
     */
    EMERGENCY_SURGICAL = 'emergency-surgical',
}
