// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Used to generate api-spec with PrescriptionSetProcedureSerializer.
 * @see #/components/schemas/PrescriptionSetProcedure - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PrescriptionSetProcedure {
    /**
     * The Prescription Set Procedure Id
     * @dataFormat uuid
     */
    readonly id: string;
    /**
     * Prescription Set ID
     * @dataFormat uuid
     */
    prescription_set_id: string;
    frequency_times: Array<string>;
    /**
     * loinc code
     *
     */
    frequency: string;
    /**
     * The code for procedure category
     *
     */
    category: string;
    /**
     * The Prescription Set Procedure description
     *
     */
    description: string;
    /**
     * The Prescription Set Procedure instructions
     *
     */
    instructions: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PrescriptionSetProcedure
 */
export namespace PrescriptionSetProcedure {
    /**
     * PrescriptionSetProcedure Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The Prescription Set Procedure Id
         * @dataFormat uuid
         */
        readonly id: string;
        /**
         * Prescription Set ID
         * @dataFormat uuid
         */
        prescriptionSetId: string;
        frequencyTimes: Array<string>;
        /**
         * loinc code
         * @type string (`dataFormat` is missing)
         */
        frequency: string;
        /**
         * The code for procedure category
         * @type string (`dataFormat` is missing)
         */
        category: string;
        /**
         * The Prescription Set Procedure description
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * The Prescription Set Procedure instructions
         * @type string (`dataFormat` is missing)
         */
        instructions: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PrescriptionSetProcedure DTO
     * Transforms PrescriptionSetProcedure model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PrescriptionSetProcedure model */
        static toModel(obj: PrescriptionSetProcedure): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PrescriptionSetProcedure model */
        static toApi(obj: Model): PrescriptionSetProcedure {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PrescriptionSetProcedure;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
