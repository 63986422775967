import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Bed, EnovacomParams } from '@mona/models';
import { EnovacomState } from '../entities';
import { EnovacomSelectors, EnovacomActions } from '../state';

/**
 * Enovacom settings service
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessEnovacomFacade {
    /**
     * Get enovacom token loading
     */
    isLoading$: Observable<boolean> = this.store.select(EnovacomSelectors.selectEnovacomTokenLoading);

    /**
     * Constructor
     *
     * @param store Store
     */
    constructor(private store: Store<EnovacomState>) {}

    /**
     * Get enovacom token
     *
     * @param payload EnovacomParams
     */
    getToken(payload: Omit<EnovacomParams, 'token'>): void {
        this.store.dispatch(EnovacomActions.getEnovacomToken(payload));
    }
}
