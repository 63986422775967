import { createFeatureSelector, createSelector } from '@ngrx/store';
import { addDays, max, min, startOfToday } from 'date-fns';
import { ProcedurePrescription } from '@mona/models';
import { isPresctiptionDateSameDate } from '@mona/pdms/data-access-combined';
import { groupBy, uniqArrayAttrs } from '@mona/shared/utils';
import { procedurePrescriptionsFeatureKey, ProceduresState, proceduresStateKey } from '../../entities';
import { proceduresPrescriptionAdapter } from '../reducers/procedure-prescription.reducer';

// FIXME: encounterDataFeatureKey
const selectEncounterState = createFeatureSelector('currentEncounterData');

/** Encounter View Selected Date */
const selectencounterViewSelectedDate = createSelector(
    selectEncounterState,
    (state: any /* EncounterFeatureState */) => {
        return state.selectedDate;
    },
);

/**
 * Feature state
 *
 * @param state
 * state
 */
const selectFeatureProceduresState = createFeatureSelector<ProceduresState>(proceduresStateKey);

export const selectProcedurePrescriptionsState = createSelector(
    selectFeatureProceduresState,
    (state: ProceduresState) => state[procedurePrescriptionsFeatureKey],
);

export const {
    selectIds: selectProcedurePrescriptionIds,
    selectEntities: selectProcedurePrescriptionEntities,
    selectAll: selectProcedurePrescriptionAll,
    selectTotal: selectProcedurePrescriptionTotal,
} = proceduresPrescriptionAdapter.getSelectors(selectProcedurePrescriptionsState);

export const selectProcedurePrescriptionsIsLoading = createSelector(
    selectProcedurePrescriptionsState,
    state => state.isLoading,
);

export const selectProcedurePrescriotionsDateRange = createSelector(selectProcedurePrescriptionAll, entities => {
    const startDates = uniqArrayAttrs(entities, 'startDate');
    const endDates = uniqArrayAttrs(entities, 'endDate');

    const start = startDates.length ? min(startDates as Date[]) : startOfToday();
    const end = endDates.length ? max(endDates as Date[]) : addDays(start, 1); // TODO: check

    return {
        start,
        end,
    };
});

/**
 * Procedure prescriptions filtered by selected date
 *
 * When scrolling the dates within the calendar (e.g. 17th January):
 * - Practitioner is able to see only the procedures that are active (procedures with start_date =< 17th Jan and end_date >= 17th Jan) for the selected day in calendar.
 * - Practitioner is not able to see the inactive procedures (start_date > 17th Jan or end_date < 17th Jan). These inactive procedures are hidden from view.
 */
export const selectProcedurePrescriptionsFiltered = createSelector(
    selectProcedurePrescriptionAll,
    selectencounterViewSelectedDate,
    (entities, selectedDate) => {
        return entities.filter((prescription: ProcedurePrescription) => {
            return isPresctiptionDateSameDate(prescription, selectedDate);
        });
    },
);

export const selectProcedurePrescriptionsError = createSelector(
    selectProcedurePrescriptionsState,
    state => state.error,
);

/**
 * INFO: add comment
 *
 * @param id
 */
export const selectProcedurePrescriptionById = (id: string) =>
    createSelector(selectProcedurePrescriptionsState, state => state.entities[id]);

/**
 * Procedure prescriptions map by group code
 */
export const selectProcedurePrescriptionMapByCategory = createSelector(selectProcedurePrescriptionAll, entities => {
    return groupBy(entities, a => a.category, false, false);
});

/**
 * Procedure prescriptions filtered by selected date and unconfirmed
 *
 * @see `handleIfMedicationPrescriptionShouldBeConfirmed`
 */
export const selectProcedurePrescriptionUnconfirmed = createSelector(selectProcedurePrescriptionAll, entities => {
    return entities.filter((prescription: ProcedurePrescription) => !prescription.isApproved);
});

/**
 * Procedure prescriptions filtered by selected date and unconfirmed
 */
export const selectProcedurePrescriptionHasChanges = createSelector(selectProcedurePrescriptionAll, entities => {
    return entities.filter((prescription: ProcedurePrescription) => prescription.hasChanges);
});

/**
 * Procedure prescriptions dates as days that have prescriptions which have stage changes
 */
export const selectProcedurePrescriptionHasChangesDates = createSelector(
    selectProcedurePrescriptionHasChanges,
    entities => {
        return entities.filter((prescription: ProcedurePrescription) => {
            return prescription.hasChanges; // TODO: check
        });
    },
);
