import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { interval, Observable, ReplaySubject } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';

/**
 * Clock service
 */
@Injectable()
export class ClockService {
    /**
     * Returns the current date value with an accuracy of 1 minute
     */
    readonly now$: ReplaySubject<Date> = new ReplaySubject(1);

    /**
     * Constructor
     *
     * @param locale string
     */
    constructor(@Inject(LOCALE_ID) private locale: string) {
        // Current point in time
        const time = new Date();

        // Seconds remaining until next full minute
        const secondsRemaining = (60 - time.getSeconds()) * 1000;

        // update time every minute
        interval(60000) // Every minute
            .pipe(
                // Ensure that the first value is not delayed
                startWith(() => new Date()),
                // Delay until the next minute starts
                delay(secondsRemaining),
                // Update to current time
                map(() => new Date()),
            )
            .subscribe(this.now$);

        // sets initial time
        this.now$.next(time);
    }

    /**
     * Returns now$ formatted as a string.
     *
     * @param format See https://angular.io/api/common/DatePipe
     */
    getFormatted(
        format: 'shortTime' | 'mediumTime' | 'shortDate' | 'mediumDate' | 'longDate' | 'fullDate',
    ): Observable<string> {
        return this.now$.pipe(map(date => formatDate(date, format, this.locale)));
    }
}
