import { createFeatureSelector, createSelector } from '@ngrx/store';
import { reportsFeatureKey, ReportsState } from '../../entities';
import * as fromReports from '../reducers/report.reducer';

export const selectReportsState = createFeatureSelector<ReportsState>(reportsFeatureKey);

export const selectReportsStateIsLoading = createSelector(selectReportsState, s => s.isLoading);

export const selectReportsIds = createSelector(selectReportsState, fromReports.selectIds as (state) => string[]);

export const selectReportsEntities = createSelector(selectReportsState, fromReports.selectEntities);

export const selectAllReports = createSelector(selectReportsState, fromReports.selectAll);

export const selectReportsTotal = createSelector(selectReportsState, fromReports.selectTotal);
