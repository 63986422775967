import { createAction, props } from '@ngrx/store';
import { Encounter, ChangeLogEntry, ChangeLogModel, PersistChangesResult } from '@mona/models';
import { AsyncAction, SucceededAction, FailedAction } from '@mona/store';

/**
 * Change log actions
 */
export class ChangeLogAction {
    /**
     * Load changes action
     *
     * TODO: remove `encounterId` params 'cause we can get from state
     */
    static loadChangesAction: AsyncAction<{ encounterId?: EntityId<Encounter> }, ChangeLogEntry<ChangeLogModel>[]> = {
        action: createAction('ENCOUNTER:LOAD_CHANGES', props<{ encounterId?: EntityId<Encounter> }>()),
        succeededAction: createAction(
            'ENCOUNTER:LOAD_CHANGES_SUCCEEDED',
            props<SucceededAction<ChangeLogEntry<ChangeLogModel>[]>>(),
        ),
        failedAction: createAction('ENCOUNTER:LOAD_CHANGES_FAILED', props<FailedAction>()),
        clearAction: createAction('ENCOUNTER:LOAD_CHANGES_CLEAR'),
    };

    /**
     * Save change action
     */
    static saveChangeAction: AsyncAction<{ change: ChangeLogEntry<ChangeLogModel> }, ChangeLogEntry<ChangeLogModel>> = {
        action: createAction('ENCOUNTER:SAVE_CHANGE', props<{ change: ChangeLogEntry<ChangeLogModel> }>()),
        succeededAction: createAction(
            'ENCOUNTER:SAVE_CHANGE_SUCCEEDED',
            props<SucceededAction<ChangeLogEntry<ChangeLogModel>>>(),
        ),
        failedAction: createAction('ENCOUNTER:SAVE_CHANGE_FAILED', props<FailedAction>()),
        clearAction: createAction('ENCOUNTER:SAVE_CHANGE_CLEAR'),
    };

    /**
     * Save multiple changes action
     */
    static saveChangesAction: AsyncAction<
        { changes: ChangeLogEntry<ChangeLogModel>[] },
        ChangeLogEntry<ChangeLogModel>[]
    > = {
        action: createAction('ENCOUNTER:SAVE_CHANGES', props<{ changes: ChangeLogEntry<ChangeLogModel>[] }>()),
        succeededAction: createAction(
            'ENCOUNTER:SAVE_CHANGES_SUCCEEDED',
            props<SucceededAction<ChangeLogEntry<ChangeLogModel>[]>>(),
        ),
        failedAction: createAction('ENCOUNTER:SAVE_CHANGES_FAILED', props<FailedAction>()),
        clearAction: createAction('ENCOUNTER:SAVE_CHANGES_CLEAR'),
    };

    /**
     * Persist changes action
     */
    static persistChangesAction: AsyncAction<void, PersistChangesResult> = {
        action: createAction('ENCOUNTER:PERSIST_CHANGES'),
        succeededAction: createAction(
            'ENCOUNTER:PERSIST_CHANGES_SUCCEEDED',
            props<SucceededAction<PersistChangesResult>>(),
        ),
        failedAction: createAction('ENCOUNTER:PERSIST_CHANGES_FAILED', props<FailedAction>()),
        clearAction: createAction('ENCOUNTER:PERSIST_CHANGES_CLEAR'),
    };

    /**
     * Discard changes action
     */
    static discardChangesAction: AsyncAction<{ ids: string[] }, void> = {
        action: createAction('ENCOUNTER:DISCARD_CHANGES', props<{ ids: string[] }>()),
        succeededAction: createAction('ENCOUNTER:DISCARD_CHANGES_SUCCEEDED'),
        failedAction: createAction('ENCOUNTER:DISCARD_CHANGES_FAILED', props<FailedAction>()),
        clearAction: createAction('ENCOUNTER:DISCARD_CHANGES_CLEAR'),
    };

    static clearPersistedSucceededModels = createAction(
        'ENCOUNTER:PERSIST_SUCCEEDED_CLEAR',
        props<{ ids: string[] }>(),
    );
}
