import { Portal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, pluck, takeUntil } from 'rxjs/operators';
import { LayoutEvent, LayoutEventType } from './drawer-layout-event.model';
import { LayoutConfig, DrawerPortals } from './drawer-layout.model';
import { DrawerLayoutVariantType } from './drawer.type';

/** This service is used to manage the state of a Drawer component, responds to user behavior and input settings. */
@Injectable({ providedIn: 'root' })
export class DrawerService implements OnDestroy {
    private renderer: Renderer2;
    private drawerOpen: boolean;
    private rightOpen: boolean;
    private disableActiveItemParentStyles: boolean;
    private variant: DrawerLayoutVariantType;
    private navItemCount = 0;
    private tempOpen = false;
    private isCondensed: boolean;
    private sideBorder: boolean;
    private disableRailTooltip: boolean;
    private openOnHover: boolean;
    /** Holds layout config */
    private layoutConfigSubject = new BehaviorSubject<LayoutConfig>({ navbarItems: [] });
    /** Holds portal templates */
    private drawerPortalSubject = new BehaviorSubject<DrawerPortals>(null);
    /** Layout event emitter */
    private eventEmitter$ = new Subject<LayoutEvent>();

    readonly destroy$ = new Subject();

    drawerOpenObs = new Subject<boolean>();
    drawerSelectObs = new Subject<boolean>();
    drawerActiveItemChangeObs = new Subject<boolean>();
    drawerNewNavItemInit = new Subject<void>();
    drawerRightOpenObs = new Subject<boolean>();

    layoutConfig$ = this.layoutConfigSubject.asObservable();

    /**
     * Constructor
     *
     * @param document
     * @param rendererFactory
     */
    constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(this.document.defaultView, null);
    }

    /** @internal */
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Listens to a particular idle service event.
     *
     * @param eventType Event to listen to.
     */
    emit(eventType: LayoutEventType): void {
        this.eventEmitter$.next({ eventType });
    }

    /**
     * Listens to a particular idle service event.
     *
     * @param eventType Event to listen to.
     * @param action What the event listener should do when the event is triggered.
     */
    on(eventType: LayoutEventType, action: (value) => void): Subscription {
        return this.eventEmitter$
            .pipe(
                filter(event => event.eventType === eventType),
                map(event => event.value),
                takeUntil(this.destroy$),
            )
            .subscribe(action);
    }

    /**
     * Sets the portal
     *
     * @param portalKey
     * @param portal Portal<any>
     */
    setDrawerPortal(portalKey: keyof DrawerPortals, portal: Portal<any>) {
        const portals = {
            ...this.drawerPortalSubject.getValue(),
            [portalKey]: portal,
        };
        this.drawerPortalSubject.next(portals);
    }

    /**
     * Gets the portal
     *
     * @param portalKey
     */
    getDrawerPortal(portalKey: keyof DrawerPortals): Observable<Portal<any>> {
        return this.drawerPortalSubject.pipe(pluck(portalKey));
    }

    /**
     * Gets the portal state
     */
    getDrawerPortalState(): DrawerPortals {
        return this.drawerPortalSubject.getValue();
    }

    /**
     * Clears the portal
     */
    clearDrawerPortal() {
        this.drawerPortalSubject.next(undefined);
    }

    /**
     * Gets layout config subject value
     *
     */
    getLayoutConfig(): LayoutConfig {
        return this.layoutConfigSubject.getValue();
    }

    /**
     * Sets layout config subject
     *
     * @param layoutConfig
     */
    setLayoutConfig(layoutConfig: Partial<LayoutConfig>) {
        const update = {
            ...this.layoutConfigSubject.getValue(),
            ...layoutConfig,
        };
        this.layoutConfigSubject.next(update);
    }

    /**
     * INFO: add comment
     */
    hasSideBorder(): boolean {
        return this.sideBorder;
    }

    /**
     * INFO: add comment
     *
     * @param sideBorder
     */
    setSideBorder(sideBorder: boolean): void {
        this.sideBorder = sideBorder;
    }

    /**
     * INFO: add comment
     */
    isOpenOnHover(): boolean {
        return this.openOnHover;
    }

    /**
     * INFO: add comment
     *
     * @param openOnHover
     */
    setOpenOnHover(openOnHover: boolean): void {
        this.openOnHover = openOnHover;
    }

    /**
     * INFO: add comment
     *
     * @param disableActiveItemParentStyles
     */
    setDisableActiveItemParentStyles(disableActiveItemParentStyles: boolean): void {
        this.disableActiveItemParentStyles = disableActiveItemParentStyles;
    }

    /**
     * INFO: add comment
     */
    hasDisableActiveItemParentStyles(): boolean {
        return this.disableActiveItemParentStyles;
    }

    /**
     * INFO: add comment
     *
     * @param open
     */
    setDrawerTempOpen(open: boolean): void {
        this.tempOpen = open;
        this.drawerOpenObs.next(this.isDrawerOpen());
    }

    /**
     * INFO: add comment
     *
     * @param condensed
     */
    setIsCondensed(condensed: boolean): void {
        this.isCondensed = condensed;
    }

    /**
     * INFO: add comment
     *
     * @param drawerOpen
     */
    setDrawerOpen(drawerOpen: boolean): void {
        this.drawerOpen = drawerOpen;
        this.toggleBodyClasses(drawerOpen);
        this.drawerOpenObs.next(this.isDrawerOpen());
    }

    /**
     * INFO: add comment
     */
    toggleDrawer(): void {
        this.drawerOpen = !this.drawerOpen;
        this.toggleBodyClasses(this.drawerOpen);
        this.drawerOpenObs.next(this.isDrawerOpen());
    }

    /**
     * Toggle body classes
     *
     * @param drawerOpen
     */
    private toggleBodyClasses(drawerOpen: boolean) {
        this.renderer[!drawerOpen ? 'removeClass' : 'addClass'](this.document.body, 'ui-drawer-layout-sidenav--opened');
        this.renderer[drawerOpen ? 'removeClass' : 'addClass'](this.document.body, 'ui-drawer-layout-sidenav--closed');
    }

    /**
     * INFO: add comment
     *
     * @param rightOpen
     */
    setRightSideNavOpen(rightOpen: boolean): void {
        this.rightOpen = rightOpen;
        this.drawerRightOpenObs.next(rightOpen);
    }

    /**
     * INFO: add comment
     *
     * @param disableRailTooltip
     */
    setDisableRailTooltip(disableRailTooltip: boolean): void {
        this.disableRailTooltip = disableRailTooltip;
    }

    /**
     * INFO: add comment
     */
    isDisableRailTooltip(): boolean {
        return this.disableRailTooltip;
    }

    /**
     * INFO: add comment
     *
     * @param variant
     */
    setDrawerVariant(variant: DrawerLayoutVariantType): void {
        this.variant = variant;
        this.drawerOpenObs.next(this.isDrawerOpen());
    }

    /**
     * INFO: add comment
     */
    getDrawerVariant(): DrawerLayoutVariantType {
        return this.variant;
    }

    /**
     * INFO: add comment
     */
    isDrawerOpen(): boolean {
        return (
            this.drawerOpen ||
            this.getDrawerVariant() === 'permanent' ||
            this.tempOpen ||
            this.getDrawerVariant() === 'rail'
        );
    }

    /**
     * INFO: add comment
     */
    isTempOpen(): boolean {
        return this.tempOpen;
    }

    /**
     * INFO: add comment
     */
    isRightOpen(): boolean {
        return this.rightOpen;
    }

    /**
     * INFO: add comment
     */
    isRailCondensed(): boolean {
        return this.isCondensed;
    }

    /**
     * INFO: add comment
     */
    drawerOpenChanges(): Observable<boolean> {
        return this.drawerOpenObs;
    }

    /**
     * INFO: add comment
     *
     * @param hasChildren
     */
    select(hasChildren: boolean): void {
        this.drawerSelectObs.next(hasChildren);
    }

    /**
     * INFO: add comment
     */
    drawerSelectionChanges(): Observable<boolean> {
        return this.drawerSelectObs;
    }

    /**
     * INFO: add comment
     */
    emitChangeActiveItemEvent(): void {
        this.drawerActiveItemChangeObs.next();
    }

    /**
     * INFO: add comment
     */
    drawerActiveItemChanges(): Observable<boolean> {
        return this.drawerActiveItemChangeObs;
    }

    /**
     * INFO: add comment
     */
    drawerNewNavItemCreated(): Observable<void> {
        return this.drawerNewNavItemInit;
    }

    /**
     * INFO: add comment
     */
    emitNewNavItemCreated(): void {
        this.drawerNewNavItemInit.next();
    }

    /** Each nav item has a unique id which is used to determine which item is selected. */
    createNavItemID(): number {
        return ++this.navItemCount;
    }
}
