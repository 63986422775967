// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validation the diagnosis of admission.
 * @see #/components/schemas/AdmissionDiagnosisRequest - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionDiagnosisRequest {
    /**
     * Diagnosis text.
     *
     */
    text: string;
    /**
     * The code of diagnosis.
     *
     */
    code?: string;
    /**
     * The status of the Diagnosis.  * `unconfirmed` - UNCONFIRMED * `provisional` - PROVISIONAL * `differential` - DIFFERENTIAL * `confirmed` - CONFIRMED * `refuted` - REFUTED * `entered-in-error` - ERROR
     *
     */
    status: AdmissionDiagnosisRequest.StatusEnum;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionDiagnosisRequest
 */
export namespace AdmissionDiagnosisRequest {
    export type StatusEnum =
        | 'unconfirmed'
        | 'provisional'
        | 'differential'
        | 'confirmed'
        | 'refuted'
        | 'entered-in-error';
    export const StatusEnum = {
        Unconfirmed: 'unconfirmed' as StatusEnum,
        Provisional: 'provisional' as StatusEnum,
        Differential: 'differential' as StatusEnum,
        Confirmed: 'confirmed' as StatusEnum,
        Refuted: 'refuted' as StatusEnum,
        EnteredInError: 'entered-in-error' as StatusEnum,
    };

    /**
     * AdmissionDiagnosisRequest Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Diagnosis text.
         * @type string (`dataFormat` is missing)
         */
        text: string;
        /**
         * The code of diagnosis.
         * @type string (`dataFormat` is missing)
         */
        code?: string;
        /**
         * The status of the Diagnosis.  * `unconfirmed` - UNCONFIRMED * `provisional` - PROVISIONAL * `differential` - DIFFERENTIAL * `confirmed` - CONFIRMED * `refuted` - REFUTED * `entered-in-error` - ERROR
         * @type string (`dataFormat` is missing)
         */
        status: AdmissionDiagnosisRequest.StatusEnum;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionDiagnosisRequest DTO
     * Transforms AdmissionDiagnosisRequest model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionDiagnosisRequest model */
        static toModel(obj: AdmissionDiagnosisRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionDiagnosisRequest model */
        static toApi(obj: Model): AdmissionDiagnosisRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionDiagnosisRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
