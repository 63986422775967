import { ActionReducerMap } from '@ngrx/store';
import { DailyGoalsState, TaskListState } from '../../entities';
import * as dailyGoals from './daily-goals.reducer';
import * as taskList from './task-list.reducer';

export const taskListStateKey = 'taskListState';

/**
 * INFO: TaskListStateMap
 */
export interface TaskListStateMap {
    /**
     * TaskListState
     */
    taskList: TaskListState;
    /**
     * DailyGoalsState
     */
    dailyGoals: DailyGoalsState;
}

export const taskListReducerMap: ActionReducerMap<TaskListStateMap> = {
    [taskList.taskListFeatureKey]: taskList.reducer,
    [dailyGoals.dailyGoalsFeatureKey]: dailyGoals.reducer,
};
