import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Logger } from '@mona/shared/logger';
import { MessageService } from '@mona/ui';
import { AuthService } from './auth.service';

/**
 * Auth Guard
 */
@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    private readonly logger = new Logger('AUTH');
    /**
     * Constructor
     *
     * @param authService
     * @param messageService
     * @param router
     */
    constructor(private authService: AuthService, private messageService: MessageService, private router: Router) {}

    /**
     * Decide if a route can be activated based on user present or not
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isAuthenticated$.pipe(
            take(1),
            tap(isLoggedIn => {
                if (!isLoggedIn) {
                    this.logger.debug('AuthGuard: Not authenticated, redirecting and adding redirect url...');
                    // TODO: show message only when needed
                    /* if (_state.id > 0) {
                        this.messageService.errorToast('auth.error.access.description');
                    } */
                    this.authService.logOut();
                }
            }),
        );
    }
}
