// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { MedicationCategoryFieldConfig } from './medicationCategoryFieldConfig';

export interface MedicationCategoryConfig {
    config_amount: MedicationCategoryFieldConfig;
    config_unit_amount: MedicationCategoryFieldConfig;
    config_method: MedicationCategoryFieldConfig;
    config_frequency: MedicationCategoryFieldConfig;
    config_instruction: MedicationCategoryFieldConfig;
    config_start_date: MedicationCategoryFieldConfig;
    config_end_date: MedicationCategoryFieldConfig;
    config_rate: MedicationCategoryFieldConfig;
    config_unit_rate: MedicationCategoryFieldConfig;
    config_volume: MedicationCategoryFieldConfig;
    config_unit_volume: MedicationCategoryFieldConfig;
    config_solution_code: MedicationCategoryFieldConfig;
    config_dosage_form: MedicationCategoryFieldConfig;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for MedicationCategoryConfig
 */
export namespace MedicationCategoryConfig {
    /**
     * MedicationCategoryConfig Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        @Type(() => MedicationCategoryFieldConfig.Model) configAmount: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configUnitAmount: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configMethod: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configFrequency: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configInstruction: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configStartDate: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configEndDate: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configRate: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configUnitRate: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configVolume: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configUnitVolume: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configSolutionCode: MedicationCategoryFieldConfig.Model;
        @Type(() => MedicationCategoryFieldConfig.Model) configDosageForm: MedicationCategoryFieldConfig.Model;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * MedicationCategoryConfig DTO
     * Transforms MedicationCategoryConfig model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend MedicationCategoryConfig model */
        static toModel(obj: MedicationCategoryConfig): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend MedicationCategoryConfig model */
        static toApi(obj: Model): MedicationCategoryConfig {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as MedicationCategoryConfig;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
