<h2 mat-dialog-title #title>{{ 'activeCallDialog.title' | translate }}</h2>

<mat-dialog-actions align="center">
    <button type="button" mat-flat-button color="warn" (click)="hangUp()">
        <mat-icon>call_end</mat-icon>
        {{ 'activeCallDialog.hangup' | translate }}
    </button>

    <button type="button" mat-flat-button color="success" uiButtonProgress [progress]="timer$ | async" (click)="hold()">
        <mat-icon>call_split</mat-icon>
        <span>{{ 'activeCallDialog.holdCall' | translate }}</span>
    </button>
</mat-dialog-actions>
