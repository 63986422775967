import { Injectable } from '@angular/core';
import { addDays, endOfDay, isWithinInterval, startOfDay, subDays, subMilliseconds } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveShiftData, PractitionerShift } from '@mona/models';
import { TerminologyService } from '@mona/pdms/data-access-terminology';

/**
 * Practitioner shifts service
 */
@Injectable({ providedIn: 'root' })
export class PractitionerShiftsService {
    /**
     * Constructor
     *
     * @param terminologyService TerminologyService
     */
    constructor(private terminologyService: TerminologyService) {}

    /**
     * Get available practitioner shifts
     */
    getPractitionerShifts(): Observable<PractitionerShift[]> {
        return this.terminologyService.getPractitionerShifts();
    }

    /**
     * Resolves active shifts data
     *
     * @param now
     */
    getActiveShiftData(now: Date): Observable<ActiveShiftData> {
        return this.getPractitionerShifts().pipe(
            map((shifts: PractitionerShift[]) => {
                const dayStart = startOfDay(now);
                const dayEnd = endOfDay(now);
                const activeDayShifts = this.generateShiftsForDay(shifts, dayStart, null, null);

                // Add the very first shift which starts prev day
                if (shifts[0].start !== '00:00:00') {
                    const veryFirstShift = this.generateVeryFirstDateShift(activeDayShifts[shifts.length - 1]);
                    activeDayShifts.unshift(veryFirstShift);
                }

                const activeShift = activeDayShifts.find(shift => {
                    const range = { start: shift.startDate, end: shift.endDate };
                    return isWithinInterval(now, range);
                });

                return {
                    dayStart,
                    dayEnd,
                    shiftStart: activeShift.startDate,
                    shiftEnd: activeShift.endDate,
                };
            }),
        );
    }

    /**
     * Generates shifts for provided date
     *
     * @param shifts PractitionerShift[]
     * @param date Date
     * @param prevShiftFeId string
     * @param originalEncounterStart Date
     */
    generateShiftsForDay(
        shifts: PractitionerShift[],
        date: Date,
        prevShiftFeId: string,
        originalEncounterStart: Date,
    ): PractitionerShift[] {
        // NOTE: if order is wrong they will not work properly
        const sortedShifts = [...shifts].sort((a, b) => a.start.localeCompare(b.start));

        return sortedShifts.map((shift, index) => {
            // Start of shift
            const startDate = this.getShiftTime(date, shift.start);

            // Start of next shift (same day or next day if current on is last)
            const nextShiftStart = sortedShifts[index + 1]
                ? this.getShiftTime(date, sortedShifts[index + 1].start)
                : this.getShiftTime(addDays(date, 1), sortedShifts[0].start);

            // End date is next shift minus 1 ms
            const endDate = subMilliseconds(nextShiftStart, 1);

            const prevShift = sortedShifts[index - 1];
            const prevFeId = prevShift ? date.toDateString() + prevShift.shift : prevShiftFeId;

            return {
                ...shift,
                feId: date.toDateString() + shift.shift,
                startDate,
                endDate,
                dayIndex: index,
                relatedDate: date.toDateString(),
                prevShiftFeId: prevFeId,
                isBeforeEncounterStart: originalEncounterStart ? endDate < originalEncounterStart : false,
            };
        });
    }

    /**
     * Resolves shift start datetime
     *
     * @param date Date
     * @param shiftTimeString string
     */
    private getShiftTime(date: Date, shiftTimeString: string): Date {
        const splitTime = shiftTimeString.split(':');
        const hour = +splitTime[0];
        const minute = +splitTime[1];
        const second = +(splitTime[2] || 0);
        const newDate = new Date(date);
        newDate.setHours(hour, minute, second, 0);
        return newDate;
    }

    /**
     * Resolves the very first shift of the day (which starts at prev day)
     *
     * @param lastDateShift PractitionerShift
     */
    private generateVeryFirstDateShift(lastDateShift: PractitionerShift): PractitionerShift {
        const veryFirstShiftStartDate = subDays(lastDateShift.startDate, 1);
        const veryFirstShiftEndDate = subDays(lastDateShift.endDate, 1);
        return {
            ...lastDateShift,
            feId: veryFirstShiftStartDate.toDateString() + lastDateShift.shift,
            startDate: veryFirstShiftStartDate,
            endDate: veryFirstShiftEndDate,
            relatedDate: veryFirstShiftStartDate.toDateString(),
            prevShiftFeId: null,
            isBeforeEncounterStart: false,
        };
    }
}
