import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { PatientsApi } from '../../infrastructure';
import { PatientsActions } from '../actions';

/**
 * Patients effects
 */
@Injectable()
export class PatientsEffects {
    searchPatient$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientsActions.searchPatient),
            exhaustMap(({ searchQuery, isAdmissionSearch }) =>
                this.patientsApi.getSearchPatient(searchQuery, isAdmissionSearch).pipe(
                    map(patients => PatientsActions.searchPatientSuccess({ patients })),
                    catchError(error => of(PatientsActions.searchPatientFailure({ error }))),
                ),
            ),
        );
    });

    /**
     * Constructor
     *
     * @param {Actions} actions$ - actions
     * @param {PatientsApi} patientsApi - patients api
     */
    constructor(private actions$: Actions, private patientsApi: PatientsApi) {}
}
