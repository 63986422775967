<div class="ui-dynamic-input-wrapper">
    <mat-form-field
        [id]="name"
        class="ui-dynamic-input-field"
        [ngClass]="customClass"
        [appearance]="appearance"
        [hideRequiredMarker]="hideRequiredMarker"
        [floatLabel]="floatLabel"
    >
        <mat-label *ngIf="label">{{ label }}</mat-label>
        <input
            matInput
            matInputCommaDot
            [formControl]="control"
            [value]="control?.value | dataAccessor: valueKey"
            [placeholder]="label && placeholder"
            [type]="type"
            [attr.data-type]="type"
            [required]="required"
            [attr.data-testid]="name"
            [attr.name]="name"
            [attr.min]="min"
            [attr.max]="max"
            [attr.minLength]="minLength"
            [attr.maxLength]="maxLength"
        />

        <!--        (blur)="isBedSideMode ? onBlur($event) : submitGroupChange($event)"-->
        <mat-hint>{{ hint }}</mat-hint>
        <mat-error>
            <ng-template
                [ngTemplateOutlet]="errorMessageTemplate"
                [ngTemplateOutletContext]="{ control: control, errors: control?.errors }"
            ></ng-template>
            <span [hidden]="hideValidationMessage">
                <ui-validation-message [control]="control"></ui-validation-message>
            </span>
        </mat-error>
    </mat-form-field>
</div>
