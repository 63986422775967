import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import {
    BaseMedication,
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationDosageForm,
    MedicationSolution,
    PrescriptionFrequency,
} from '@mona/models';
import { getMedicationFrequency } from '@mona/pdms/data-access-combined';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { TakeUntilDestroy } from '@mona/shared/utils';
import { UiTableCellMenu, UiTableDataSource, UiTableEvent } from '@mona/ui';

/** medication list with ui-table component */
@Component({
    selector: 'mona-medication-list-as-table',
    templateUrl: './medication-list-as-table.component.html',
    styleUrls: ['./medication-list-as-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
@TakeUntilDestroy
export class MedicationListAsTableComponent implements OnDestroy {
    /** table data source */
    @Input({
        // eslint-disable-next-line @angular-eslint/no-input-rename
        alias: 'data',
        required: true,
        transform: (data: BaseMedication[]) => {
            const dataSource = new UiTableDataSource(data);
            dataSource.trackBy = (idx, row) => row.id;

            return dataSource;
        },
    })
    dataSource: UiTableDataSource<BaseMedication>;

    /** show no data */
    @Input() showNoData = false;

    /** Is loading */
    @Input() isLoading?: boolean;
    /** Edit event */
    @Output() readonly edit = new EventEmitter();
    /** Delete event */
    @Output() readonly delete = new EventEmitter();

    /** No data message block config */
    readonly noDataConfig = {
        title: 'apps.settings.prescriptionSet.medicationTable.emptyTitle',
        subTitle: 'apps.settings.prescriptionSet.medicationTable.emptyDescription',
        ctaTitle: 'apps.settings.prescriptionSet.medicationTable.addMedication',
    };
    /** get medication frequency method */
    readonly getMedicationFrequency = getMedicationFrequency;
    /** medication dosage forms */
    readonly medicationDosageForms$: Observable<MedicationDosageForm[]> = this.terminologyService.getDosageForms();
    /** medication frequencies */
    readonly medicationFrequencies$: Observable<PrescriptionFrequency[]> =
        this.terminologyService.getPrescriptionFrequencies();
    /** medication category */
    readonly medicationCategories$: Observable<MedicationCategory[]> =
        this.terminologyService.getMedicationCategories();
    /** medicationSolutions */
    readonly medicationSolutions$: Observable<MedicationSolution[]> = this.terminologyService.getMedicationSolutions();
    /** medication administration methods */
    readonly medicationAdministrationMethods$: Observable<MedicationAdministrationMethod[]> =
        this.terminologyService.getMedicationAdministrationMethods();

    /** row menu items */
    readonly rowMenuItems: UiTableCellMenu[] = [
        {
            text: 'apps.patient.balance.inputs.edit',
            reason: 'edit',
            icon: 'edit',
        },
        {
            text: 'apps.patient.balance.inputs.delete',
            reason: 'delete',
            icon: 'delete',
        },
    ];

    /**
     * Constructor
     *
     * @param terminologyService
     */
    constructor(private readonly terminologyService: TerminologyService) {}

    /** Lifecycle */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnDestroy() {}

    /**
     * On table event handler
     *
     * @param event Table event
     */
    onTableEvent(event: UiTableEvent & { data: { row: BaseMedication } }) {
        const { row } = event.data;
        if (event.action === 'cellMenu') {
            /** Handle table cell menu click */
            switch (event.reason) {
                case 'edit':
                    this.edit.emit(row);
                    break;
                case 'delete':
                    this.delete.emit(row);
                    break;
                default:
                    console.warn(event);
                    break;
            }
        }
    }
}
