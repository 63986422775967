// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating PractitionerToken response body.
 * @see #/components/schemas/PractitionerTokenResponse - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PractitionerTokenResponse {
    /**
     * The practitioner bearer token.
     *
     */
    token: string;
    /**
     * The practitioner refresh token.
     *
     */
    refresh_token?: string;
    /**
     * Practitioner rfid.
     *
     */
    rfid: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PractitionerTokenResponse
 */
export namespace PractitionerTokenResponse {
    /**
     * PractitionerTokenResponse Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The practitioner bearer token.
         * @type string (`dataFormat` is missing)
         */
        token: string;
        /**
         * The practitioner refresh token.
         * @type string (`dataFormat` is missing)
         */
        refreshToken?: string;
        /**
         * Practitioner rfid.
         * @type string (`dataFormat` is missing)
         */
        rfid: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PractitionerTokenResponse DTO
     * Transforms PractitionerTokenResponse model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PractitionerTokenResponse model */
        static toModel(obj: PractitionerTokenResponse): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PractitionerTokenResponse model */
        static toApi(obj: Model): PractitionerTokenResponse {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PractitionerTokenResponse;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
