import { NgModule } from '@angular/core';
import { UiModule } from '@mona/ui';
import { RealTimeSpeechComponent } from './real-time-speech.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [RealTimeSpeechComponent],
    exports: [RealTimeSpeechComponent],
    imports: [UiModule],
})
export class RealTimeSpeechModule {}
