import { Pipe, PipeTransform } from '@angular/core';
import { get } from '../helpers';
import { isObject } from '../types';

/**
 * Access nested props in object
 */
@Pipe({ name: 'dataAccessor', pure: true })
export class DataAccessorPipe implements PipeTransform {
    /**
     * @param value some object
     * @param path string separated with dot
     * @param defaultValue
     */
    transform(value: AnyObject, path: string, defaultValue?: any): any {
        if (!path || !value || !isObject(value)) {
            return value;
        }
        /* eslint-disable */
        // @ts-ignore
        return get(value, path as Path<{}>) || defaultValue || value;
        /* eslint-disable */
    }
}
