/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES_MAP_BY_FEATURE } from '@environment';
import { map } from 'rxjs/operators';
import { notEmpty, takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { MessageService } from '@mona/ui';
import { hasAdminRole } from '../../models';
import { AuthService } from '../../services';

/**
 * RoleAccessWrapperComponent
 */
@TakeUntilDestroy
@Directive({
    selector: '[monaRbacWrapper]',
})
export class RoleAccessWrapperComponent implements OnDestroy {
    /**
     * Constructor
     *
     * @param router
     * @param authService
     * @param messageService
     * @param ngZone
     */
    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected messageService: MessageService,
        protected ngZone: NgZone,
    ) {
        this.authService.user$.pipe(notEmpty(), map(hasAdminRole), takeUntilDestroy(this)).subscribe(isAdmin => {
            if (!isAdmin) {
                this.messageService.errorToast('apps.settings.settingsAccessDeniedToast');
                const path = ROUTES_MAP_BY_FEATURE.SETTINGS;
                this.ngZone.run(() => {
                    setTimeout(() => {
                        this.router.navigateByUrl(path);
                    }, 0);
                });
            }
        });
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function, jsdoc/require-jsdoc
    ngOnDestroy(): void {}
}
