import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { ConversationModeToggleComponent } from './conversation-mode-toggle.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [ConversationModeToggleComponent],
    exports: [ConversationModeToggleComponent],
    imports: [CommonModule, MatButtonToggleModule, MatIconModule],
})
export class ConversationModeModule {}
