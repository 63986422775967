import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CamelizePipe } from './camelize.pipe';
import { DataAccessorPipe } from './data-accessor.pipe';
import { FilterPipe } from './filter.pipe';
import { InRangePipe } from './in-range.pipe';
import { KeyValueUnsortedPipe } from './keyvalue-unsorted.pipe';
import { UiMapperPipe } from './mapper.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeImagePipe } from './safe-image.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TruncatePipe } from './truncate.pipe';

const PIPES = [
    /* prettier-ignore */
    FilterPipe,
    CamelizePipe,
    DataAccessorPipe,
    UiMapperPipe,
    InRangePipe,
    SafeImagePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    TruncatePipe,
    KeyValueUnsortedPipe,
];

/**
 * Shared pipes module
 */
@NgModule({
    declarations: PIPES,
    imports: [CommonModule],
    exports: PIPES,
})
export class PipesModule {}
