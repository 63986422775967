import { createAction, props } from '@ngrx/store';
import { RelativeContact } from '@mona/models';

/** loadRelativeContacts */
export const loadRelativeContacts = createAction('RELATIVE_CONTACTS:LOAD_RELATIVE_CONTACTS');

/** load relative contacts success */
export const loadRelativeContactsSuccess = createAction(
    'RELATIVE_CONTACTS:LOAD_RELATIVE_CONTACTS_SUCCESS',
    props<{
        relativeContacts: RelativeContact[];
    }>(),
);

/** load relative contacts failure */
export const loadRelativeContactsFailure = createAction(
    'RELATIVE_CONTACTS:LOAD_RELATIVE_CONTACTS_FAILURE',
    props<{
        error: any;
    }>(),
);

/** delete relative contact */
export const handleDeleteRelativeContact = createAction(
    'RELATIVE_CONTACTS:HANDLE_DELETE_RELATIVE_CONTACT',
    props<{ id: RelativeContact['id'] }>(),
);

/** deleteRelativeContacts */
export const deleteRelativeContact = createAction(
    'RELATIVE_CONTACTS:DELETE_RELATIVE_CONTACTS',
    props<{ id: RelativeContact['id'] }>(),
);

/** laod relative contacts success */
export const deleteRelativeContactSuccess = createAction(
    'RELATIVE_CONTACTS:DELETE_RELATIVE_CONTACTS_SUCCESS',
    props<{ id: RelativeContact['id'] }>(),
);

/** laod relative contacts failure */
export const deleteRelativeContactFailure = createAction(
    'RELATIVE_CONTACTS:DELETE_RELATIVE_CONTACTS_FAILURE',
    props<{ error: any }>(),
);

/** Upsert relative contact action */
export const upsertRelativeContact = createAction(
    'RELATIVE_CONTACTS:UPSERT_RELATIVE_CONTACT',
    props<{ relativeContact: RelativeContact }>(),
);

/** Upsert relative contact success */
export const upsertRelativeContactSuccess = createAction(
    'RELATIVE_CONTACTS:UPSERT_RELATIVE_CONTACT_SUCCESS',
    props<{ relativeContact: RelativeContact }>(),
);

/** Upsert relative contact failure */
export const upsertRelativeContactFailure = createAction(
    'RELATIVE_CONTACTS:UPSERT_RELATIVE_CONTACT_FAILURE',
    props<{ error: any }>(),
);
