/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { encounterDataFeatureKey, EncounterFeatureState, VaccinationStatusState } from '../../entities';
import { vaccinationStatusAdapter } from '../reducers/vaccinations.reducer';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectVaccinationsState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.vaccinations,
);

export const {
    selectIds: selectVaccinationIds,
    selectEntities: selectVaccinationEntities,
    selectAll: selectVaccinationAll,
    selectTotal: selectVaccinationTotal,
} = vaccinationStatusAdapter.getSelectors(selectVaccinationsState);

export const selectVaccinationsEntities = createSelector(
    selectVaccinationsState,
    (state: VaccinationStatusState) => state.entities,
);

export const selectVaccinationsIsLoading = createSelector(
    selectVaccinationsState,
    (state: VaccinationStatusState) => state.isLoading,
);
