import { ApiModels } from '@mona/api';
import { RegisterDeviceResult } from '@mona/models';

/**
 * Transform API device response
 *
 * @param apiRegistrationResult ApiModels.DeviceRegistrationResponse
 */
export const transformApiDeviceResponse = (apiRegistrationResult: ApiModels.DeviceResponse): RegisterDeviceResult => {
    return {
        deviceMac: apiRegistrationResult.id,
        isActivated: apiRegistrationResult.is_active,
    };
};
