<mat-dialog-content class="app-pre-medication-dialog__content">
    <div>
        <h2 mat-dialog-title class="app-pre-medication-dialog__title">
            {{
                isEditMode
                    ? ('apps.patient.overview.editPreMedication' | translate)
                    : ('apps.patient.overview.addPreMedication' | translate)
            }}
        </h2>
        <app-pre-medication-form
            [formGroup]="preMedicationForm"
            [isBedSideMode]="data.isBedSideMode"
        ></app-pre-medication-form>
    </div>

    <div class="app-pre-medication-dialog__actions">
        <button mat-button class="app-pre-medication-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'apps.patient.overview.dialogCancel' | translate }}
        </button>
        <button
            mat-raised-button
            color="primary"
            [disabled]="preMedicationForm.invalid || preMedicationForm.pristine"
            (click)="submitChange()"
        >
            {{ 'apps.patient.overview.dialogSave' | translate }}
        </button>
    </div>
</mat-dialog-content>
