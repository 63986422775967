import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UtilsModule } from '@mona/shared/utils';
import { UiDirectivesModule } from '@mona/ui/directives';
import { UiLoadingOverlayModule } from '../loading-overlay';
import { UiEmptyStateComponent } from './empty-state.component';
import { UiEmptyComponent } from './empty.component';

/**
 * UiEmptyStateModule
 */
@NgModule({
    declarations: [UiEmptyStateComponent, UiEmptyComponent],
    imports: [UtilsModule, UiLoadingOverlayModule, MatButtonModule, UiDirectivesModule],
    exports: [UiEmptyStateComponent, UiEmptyComponent],
})
export class UiEmptyStateModule {}
