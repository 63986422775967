import { endOfYesterday, isAfter, isBefore, isSameDay } from 'date-fns';

/**
 * Partial with date range
 */
interface PartialDateRange {
    startDate: Date;
    endDate?: Date;
}

/**
 * If procedure prescription should be shown for selected date
 *
 * @param model T extends DateRange
 * @param selectedDate Date
 */
export function isPresctiptionDateSameDate<T extends PartialDateRange>(model: T, selectedDate: Date): boolean {
    const isStartDateBeforeOrSameAsSelectedDate =
        isSameDay(model.startDate, selectedDate) || isBefore(model.startDate, selectedDate);

    if (!model.endDate) {
        return isStartDateBeforeOrSameAsSelectedDate;
    }

    const isEndDateAfterOrSameAsSelectedDate =
        isSameDay(model.endDate, selectedDate) || isAfter(model.endDate, selectedDate);

    return isStartDateBeforeOrSameAsSelectedDate && isEndDateAfterOrSameAsSelectedDate;
}

/**
 * is prescription new
 *
 * @param startDate
 * @param endDate
 * @param selectedDate
 * @param showFullStay
 */
export function isNewPrescription(startDate: Date, endDate: Date, selectedDate: Date, showFullStay: boolean): boolean {
    // if selected date is today or in future
    if (isBefore(selectedDate, endOfYesterday())) {
        return false;
    }

    // if enddate is selected day or in past
    if (endDate && isBefore(endDate, new Date())) {
        return false;
    }

    const isStartInSelectedDate = isSameDay(startDate, selectedDate);
    return isStartInSelectedDate && !showFullStay;
}
