import { ConfigType } from '../models';

interface ApiHospitalConfig {
    hospital_id: string;
    address: string;
    balance_calculation_reset_time: string;
    enable_manual_allergy_creation: boolean;
    name: string;
    config: ConfigItem[];
    overdue_time: number;
}
interface ConfigItem {
    name: string;
    value: string;
    description: string;
}

/**
 * Transforms API hospital settings
 *
 * @param data ApiHospitalConfig
 */
export const transformApiHospitalConfig = (data: ApiHospitalConfig): Partial<ConfigType> => {
    const DEFAULT_OVERDUE_TIME = 30;

    if (!data?.config) {
        return {
            overdueTime: DEFAULT_OVERDUE_TIME,
        };
    }

    const config = data.config.reduce((config, confItem) => {
        if ('locale' === confItem.name) {
            config.hospitalLocale = confItem.value;
            config.isHospitalLocaleApplied = true;
        }
        if ('autoLock' === confItem.name) {
            config.autoLock = +confItem.value;
        }
        return config;
    }, {} as Partial<ConfigType>);

    // NOTE: ? here -> data?.enable_manual_allergy_creation can be removed
    // leave it here for now in case if in some env BE version wont have this prop
    // in hospital config
    config.enableManualAllergyCreation = data?.enable_manual_allergy_creation;
    config.balanceCalculationResetTime = data.balance_calculation_reset_time;

    // NOTE: can be zero, so check if not undefined
    config.overdueTime = data?.overdue_time !== undefined ? data?.overdue_time : DEFAULT_OVERDUE_TIME;

    return config;
};
