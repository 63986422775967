import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { InfectionStatus } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { InfectionStatusState } from '../entities';
import {
    InfectionStatusActions,
    selectInfectionAll,
    selectInfectionsStatusEntities,
    selectInfectionsIsLoading,
} from '../state';

/**
 * Infection status facade
 */
@Injectable({ providedIn: 'root' })
export class InfectionStatusFacade {
    /** Get infection status */
    readonly infectionStatus$: Observable<InfectionStatus[]> = this.store.select(selectInfectionAll);

    /** infection status map */
    readonly infectionStatusMap$: Observable<Record<string, InfectionStatus>> =
        this.store.select(selectInfectionsStatusEntities);

    /** is infection status data loading */
    readonly isLoading$ = this.store.select(selectInfectionsIsLoading);

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService
     */
    constructor(private store: Store<InfectionStatusState>, private changeLogService: ChangeLogService) {}

    /** load infections data */
    loadInfectionStatusData(): void {
        this.store.dispatch(InfectionStatusActions.loadInfectionStatus());
    }

    /**
     * INFO: add comment
     * @param infection
     */
    createInfection(infection: Partial<InfectionStatus>): void {
        this.changeLogService.createInfection(infection);
    }

    /**
     * INFO: add comment
     * @param infection
     */
    updateInfection(infection: Partial<InfectionStatus>): void {
        this.changeLogService.updateInfection(infection);
    }

    /**
     * INFO: add comment
     * @param id
     */
    removeInfection(id: string): void {
        this.changeLogService.removeInfection(id);
    }

    /**
     * Clear infections store
     */
    clearInfections(): void {
        this.store.dispatch(InfectionStatusActions.clearInfectionStatus());
    }
}
