/* eslint-disable @typescript-eslint/ban-ts-comment */
import { merge as _merge } from 'lodash/fp';
import { from as fromObservable, merge, onErrorResumeNext } from 'rxjs';
import { filter, reduce, share } from 'rxjs/operators';
import { Logger } from '@mona/shared/logger';
import { errorIfEmpty } from '@mona/shared/utils';
import { mergeWith } from '../helpers';
import { ConfigLoader } from '../models';

type LoaderFn = (...args) => ConfigLoader;

/**
 * Config Merge Loader
 */
export class ConfigMergeLoader implements ConfigLoader {
    private nextLoaders: LoaderFn[];

    private logger: Logger = new Logger('CONFIG');

    /**
     * Constructor
     *
     * @param loaders
     */
    constructor(private readonly loaders: Array<ConfigLoader> = []) {}

    /**
     * Load settings
     */
    load(): Promise<any> {
        if (Array.isArray(this.nextLoaders) && this.nextLoaders.length) {
            return this.mergeParallel().then(async (res: any) => {
                const all = await Promise.all(this.nextLoaders.map(nl => nl(res).load()));
                const merged = all.reduce((p, c) => _merge(p, c), res);
                return merged;
            });
        }

        return this.mergeParallel();
    }

    /**
     * Attach next loaders
     *
     * @param loaders
     */
    next(loaders: LoaderFn[]): ConfigMergeLoader {
        this.nextLoaders = loaders;

        return this;
    }

    private async mergeParallel(loadersToLoad = this.loaders): Promise<any> {
        const mergedSettings = onErrorResumeNext(
            loadersToLoad.map((loader: ConfigLoader) => fromObservable(loader.load())),
        ).pipe(
            filter((res: any) => res),
            share(),
        );

        return new Promise((resolve, reject) => {
            merge(mergedSettings, errorIfEmpty(mergedSettings), mergedSettings)
                .pipe(reduce((merged: any, current: any) => mergeWith(merged)(current), {}))
                .subscribe(resolve, () => {
                    this.logger.error('Loaders unreachable!');
                    resolve({});
                });
        });
    }
}
