/* eslint-disable jsdoc/require-jsdoc */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntriesInterval } from '@mona/shared/date';
import { BalanceState, encounterDataFeatureKey, EncounterFeatureState } from '../../entities';
import { balanceValuesAdapter } from '../reducers';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectBalanceState = createSelector(selectEncounterState, (state: EncounterFeatureState) => state.balance);

export const selectBalanceValuesState = createSelector(
    selectBalanceState,
    (state: BalanceState) => state.balanceValues,
);

export const selectBalanceActiveTable = createSelector(selectBalanceState, state => state.activeTable);

export const selectBalanceIsLoading = createSelector(selectBalanceState, state => state.isLoading);

export const selectBalanceInterval = createSelector(selectBalanceState, (state: BalanceState) => state.interval);

export const { selectAll: selectAllBalanceValues, selectEntities: selectBalanceValuesEntities } =
    balanceValuesAdapter.getSelectors(selectBalanceValuesState);

export const selectBalanceValuesFromCache = createSelector(
    selectBalanceInterval,
    selectBalanceValuesState,
    (interval: EntriesInterval, state) => {
        return state.cachedBalanceValues[interval];
    },
);
