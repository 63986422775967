import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppError } from '@mona/shared/utils';
import { Theme, UiMessage } from '@mona/ui';
import { ErrorActions, MessageActions, UiActions } from '../actions';
import { MessageSelectors, UiSelectors } from '../selectors';
import { AppState } from '../state';

/**
 * App state facade service
 */
@Injectable({
    providedIn: 'root',
})
export class AppStateFacade {
    /** Light or Dark theme */
    theme$ = this.store.select(UiSelectors.selectTheme);
    /** Select all app-level messages */
    messages$ = this.store.select(MessageSelectors.selectAllMessages);
    /** Is conversation dialog opened */
    isConversationDialogOpened$ = this.store.select(UiSelectors.selectConversationDialogOpened);

    /**
     * Service constructor
     *
     * @param store AppState
     */
    constructor(private store: Store<AppState>) {}

    /**
     * Sets the current theme and disabled the automatic theme by night shift timer
     *
     * @param theme App Theme
     */
    setTheme(theme: Theme): void {
        this.store.dispatch(UiActions.setTheme({ theme }));
    }

    /**
     * Toggles between dark and light theme
     */
    toggleTheme(): void {
        this.store.dispatch(UiActions.toggleTheme());
    }

    /**
     * Set is notifications side nav opened
     *
     * @param isOpened isOpened
     */
    setIsConversationOverlayOpened(isOpened: boolean): void {
        this.store.dispatch(UiActions.setIsConversationOverlayOpened({ isOpened }));
    }

    /**
     * Add app-level message
     *
     * @param message
     */
    addAlert(message: UiMessage): void {
        if (!message.id) {
            message.id = Date.now().toString();
        }
        this.store.dispatch(MessageActions.addMessage({ message }));
    }

    /**
     * Set selected app-level message
     *
     * @param id
     */
    setSelectedAlert(id: string): void {
        this.store.dispatch(MessageActions.deleteMessage({ id }));
    }

    /**
     * Remove app-level message
     *
     * @param id
     */
    removeAlert(id: string): void {
        this.store.dispatch(MessageActions.deleteMessage({ id }));
    }

    /**
     * Clear app-level messages
     *
     * @param all
     */
    clearAlerts(all = false): void {
        this.store.dispatch(MessageActions.clearMessages(all));
    }

    /**
     * Handle app error
     *
     * @param error
     */
    handleAppError(error: Error | AppError): void {
        const appError = error instanceof AppError ? error : new AppError(error);
        this.store.dispatch(ErrorActions.handleUnexpectedServerError(appError));
    }
}
