/* eslint-disable no-useless-escape */
import { Type } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { dispatchEvent } from '@mona/shared/utils';

/**
 * UiDynamicType
 */
export enum UiDynamicType {
    Text = 'text',
    Boolean = 'boolean',
    Number = 'number',
    Array = 'array',
    Date = 'date',
}

/**
 * UiDynamicElement
 */
export enum UiDynamicElement {
    Input = 'input',
    Datepicker = 'datepicker',
    Datetimepicker = 'datetimepicker',
    Password = 'password',
    Textarea = 'textarea',
    Slider = 'slider',
    Checkbox = 'checkbox',
    Radio = 'radio',
    Select = 'select',
    Autocomplete = 'autocomplete',
    List = 'list',
    Button = 'button',
}

/**
 * UiDynamicElementValidator
 */
export interface UiDynamicElementValidator {
    /** Validator */
    validator: ValidatorFn;
}

/**
 * Property values to be set in custom component
 */
export interface UiDynamicElementCustomConfig {
    [name: string]: any;
}

/**
 * UiDynamicElementConfig
 */
export interface UiDynamicElementConfig {
    /** Label */
    id?: string;
    /** Label */
    hidden?: boolean;
    /** MatFormFieldAppearance */
    appearance?: MatFormFieldAppearance;
    /** Label */
    label?: string;
    /** Name */
    name: string;
    /** Hint */
    hint?: string;
    /** Type */
    type: UiDynamicType | UiDynamicElement | Type<any>;
    /** Required */
    required?: boolean;
    /** Hide required marker */
    hideRequiredMarker?: boolean;
    /** Disabled */
    disabled?: boolean;
    /** Min */
    min?: any;
    /** Max */
    max?: any;
    /** Min length */
    minLength?: any;
    /** Max length */
    maxLength?: any;
    /** Selections */
    dataSource?:
        | string[]
        | {
              value: any;

              label: string;
          }[]
        | Observable<any[]>;
    /** LabelKey */
    labelKey?: string;
    /** ValueKey */
    valueKey?: string;
    /** Multiple */
    multiple?: boolean;
    /** Hide validation message */
    hideValidationMessage?: boolean;
    /** Default */
    default?: any;
    /** Flex */
    flex?: number;
    /** Validators */
    validators?: UiDynamicElementValidator[];
    /** Custom config */
    customConfig?: UiDynamicElementCustomConfig;
    /** Placeholder */
    placeholder?: string;
    /** Submit form on value change */
    submitFormOnValueChange?: boolean;
    /** Submit form on value change */
    submitRef?: any;
}

export const DYNAMIC_ELEMENT_NAME_REGEX = /^[^0-9][^\@]*$/;
export const CUSTOM_SUBMIT_EVENT_NAME = 'dynamicformsubmit';

/**
 * Emit custom submit event from dynamic element
 *
 * @param target
 */
export function emitSubmit(target: HTMLUnknownElement) {
    const event = new CustomEvent(CUSTOM_SUBMIT_EVENT_NAME, { bubbles: true });
    dispatchEvent(target, event);
}

/**
 * Interface for answer controls
 */
export interface DynamicControlValue {
    /**
     * INFO: add comment
     */
    display: string;
    /**
     * INFO: add comment
     */
    code: string;
}
