import { Pipe, PipeTransform } from '@angular/core';
import { ChangeLogEntry, ChangeLogModel } from '@mona/models';

/**
 * Resolves if item is changed
 */
@Pipe({
    name: 'isItemChanged',
})
export class IsItemChangedPipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param itemId string
     * @param changes ChangeLogEntry<ChangeLogModel>
     */
    transform(itemId: string, changes: ChangeLogEntry<ChangeLogModel>[]): boolean {
        return !!changes?.find(change => change.modelId === itemId);
    }
}
