import { buildEnvironment } from './environment.base';

/**
 * Production environment config
 */
export const environment = {
    ...buildEnvironment(),
    production: true,
    environment: 'PROD',
};
