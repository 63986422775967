import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@mona/shared/utils';
import { UiDirectivesModule } from '@mona/ui/directives';
import { UiLoadingOverlayModule } from '../loading-overlay';
import { UiSpacerModule } from '../utils';
import { UiCardComponent } from './card.component';

/**
 * UiCardModule
 */
@NgModule({
    declarations: [UiCardComponent],
    imports: [
        UtilsModule,
        UiDirectivesModule,
        UiSpacerModule,
        UiLoadingOverlayModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [MatCardModule, MatButtonModule, MatIconModule, UiCardComponent],
})
export class UiCardModule {}
