import { TypedAction } from '@ngrx/store/src/models';
import { LoadEntitiesSucceededAction, SucceededAction } from '../models';

/**
 * Helper class for entity map reducing
 */
export class EntityMapReducerHelper<E extends Entity> {
    /**
     * Add all entities
     * @param state state
     * @param action action
     */
    reduceLoadEntitiesSucceededAction(
        state: EntityMap<E>,
        action: LoadEntitiesSucceededAction<E> & TypedAction<any>,
    ): EntityMap<E> {
        return action.payload.reduce((map, entity) => {
            return {
                ...map,
                [entity.id]: entity,
            };
        }, {});
    }

    /**
     * Add newly loaded entities to existing
     * @param state state
     * @param action action
     */
    reduceLoadEntitiesSucceededWithExistingAction(
        state: EntityMap<E>,
        action: LoadEntitiesSucceededAction<E> & TypedAction<any>,
    ): EntityMap<E> {
        return action.payload.reduce((map, entity) => {
            return {
                ...map,
                [entity.id]: entity,
            };
        }, state);
    }

    /**
     * Add entity
     * @param state state
     * @param action action
     */
    reduceAddUpdateEntityAction(state: EntityMap<E>, action: SucceededAction<E> & TypedAction<any>): EntityMap<E> {
        return {
            ...state,
            [action.payload.id]: action.payload,
        };
    }

    /**
     * Remove all entities
     * @param state state
     */
    reduceLoadEntitiesClearAction(state: EntityMap<E>): EntityMap<E> {
        return {};
    }
}
