/* eslint-disable prefer-spread, @typescript-eslint/ban-types, @typescript-eslint/no-empty-function, @angular-eslint/no-empty-lifecycle-method, @angular-eslint/contextual-lifecycle, jsdoc/require-jsdoc */
import { InjectionToken } from '@angular/core';
import type {
    DataZoomComponentOption,
    EChartsOption,
    LegendComponentOption,
    LineSeriesOption,
    SeriesOption,
    TimelineComponentOption,
    ToolboxComponentOption,
    TooltipComponentOption,
    XAXisComponentOption,
    YAXisComponentOption,
} from 'echarts';

export interface UiEChartsInitOpts {
    locale?: string;
    renderer?: 'canvas' | 'svg'; // RendererType
    devicePixelRatio?: number;
    useDirtyRect?: boolean;
    useCoarsePointer?: boolean;
    pointerSize?: number;
    ssr?: boolean;
    width?: number | string;
    height?: number | string;
}

export interface UiEchartsOption extends EChartsOption {
    xAxis?: XAXisComponentOption;
    yAxis?: YAXisComponentOption;
    toolbox?: ToolboxComponentOption;
    tooltip?: TooltipComponentOption;
    // axisPointer?: AxisPointerOption | AxisPointerOption[];
    // timeline?: TimelineOption | SliderTimelineOption;
    legend?: LegendComponentOption;
    dataZoom?: DataZoomComponentOption[];
    series?: SeriesOption[];
    options?: EChartsOption[];
}

export type UiEchartsSeriesDataItemOption = TimelineComponentOption['data'];
export type UiEchartsSeriesOption = LineSeriesOption & {
    data: UiEchartsSeriesDataItemOption;
    /** used to properly assign boundary values for chart */
    dataPatched?: boolean;
    code?: string;
};
export type UiEchartsData = {
    series: UiEchartsSeriesOption[];
    empty?: boolean;
    intervalForXAxisFormatter?: number;
    preselectedChartCodes?: number;
    xAxisData: Date[];
};

/**
 * Theme Option
 */
export type UiEchartsThemeOption = Record<string, any>;

/**
 * ScaleTick
 */
export interface UiEchartsScaleTick {
    level?: number;
    value: number;
}

export interface UiEchartsConfig {
    echarts: any | (() => Promise<any>);
}

/**
 * This token is used to provide a Echarts as Observable after lazy import
 */
export const UI_ECHARTS_CONFIG = new InjectionToken<UiEchartsConfig>('UI_ECHARTS_CONFIG');

export const UI_ECHARTS_DEBOUNCETIME = 50;
