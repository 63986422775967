import { createReducer, on } from '@ngrx/store';
import { ExternalResourcesState } from '../../entities';
import { ExternalResourcesActions } from '../actions/external-resources.actions';

export const externalResourcesFeatureKey = 'externalResources';

export const initialState: ExternalResourcesState = {
    data: [],
    activeResource: null,
    error: null,
    isLoading: false,
    isFullScreen: false,
};

export const reducer = createReducer(
    initialState,
    on(ExternalResourcesActions.loadExternalResources, state => ({ ...state, isLoading: true })),
    on(ExternalResourcesActions.loadExternalResourcesSuccess, (state, { data }) => ({
        ...state,
        data,
        isLoading: false,
    })),
    on(ExternalResourcesActions.loadExternalResourcesFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(ExternalResourcesActions.clearExternalResources, state => ({
        ...state,
        data: [],
    })),
    on(ExternalResourcesActions.selectExternalResource, (state, { selected }) => ({
        ...state,
        activeResource: selected,
        isLoading: false,
    })),
    on(ExternalResourcesActions.clearSelectExternalResource, state => ({
        ...state,
        activeResource: null,
    })),
    on(ExternalResourcesActions.browserExitFullScreenAction, state => ({
        ...state,
        isFullScreen: false,
    })),
    on(ExternalResourcesActions.browserEnterFullScreenAction, state => ({
        ...state,
        isFullScreen: true,
    })),
);
