import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Logger } from '@mona/shared/logger';
import { AppError, compactObject } from '@mona/shared/utils';
import { ApiTokenResponse, hasAdminRole, TokenResponse, User } from '../models';

/**
 * API abstraction layer for the User authentication API
 */
@Injectable({ providedIn: 'root' })
export class AuthApi {
    private logger = new Logger('AUTH');

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Registers an rfid for a user
     *
     * @param rfid id string
     * @param pin pin code
     */
    registerRfiD(rfid: string, pin: string): Observable<User> {
        return this.http
            .post<ApiModels.Practitioner>(`/pdms/practitioners/register-rfid/`, {
                rfid,
                pin,
            } as ApiModels.PractitionerRegistrationRequest)
            .pipe(map(apiUser => new User(apiUser)));
    }

    /**
     * Authenticate using the rfid
     *
     * @param rfid Serial
     */
    verifyRfId(rfid: string): Observable<any> {
        const rfidVerified = new RegExp(`^[ -~]*$`).test(rfid) && rfid.length > 4;

        if (!rfidVerified) {
            return throwError('Wrong RFID');
        }

        return this.http.post<ApiModels.Practitioner>(`/pdms/practitioners/verify-rfid/`, { rfid }).pipe(
            map(apiUser => {
                const user = new User(apiUser);
                return {
                    rfid,
                    user,
                    isAdmin: hasAdminRole(user),
                };
            }),
        );
    }

    /**
     * Performs a request with user credentials
     * in order to get auth tokens
     *
     * @param {string} username
     * @param {string} password
     * @param {string} device_id
     * @returns Observable<AccessData>
     */
    loginWithCreds(username: string, password: string, device_id?: string): Observable<TokenResponse> {
        const payload = compactObject({
            username,
            password,
            device_id,
        });
        return this.http
            .post<ApiTokenResponse>(`/auth/user/login`, payload)
            .pipe(map(res => TokenResponse.toModel(res)));
    }

    /**
     * Performs a request with user RFID
     * in order to get auth tokens
     *
     * @param {string} rfid
     * @param {string} device_id
     * @returns Observable<AccessData>
     */
    loginWithRfid(rfid: string, device_id?: string): Observable<TokenResponse> {
        const payload = compactObject({
            rfid,
            device_id,
        });
        return this.http
            .post<ApiTokenResponse>(`/auth/user/rfid`, payload)
            .pipe(map(res => TokenResponse.toModel(res)));
    }

    /**
     * Asks for a new access token given
     * the stored refresh token
     *
     * @param refreshToken
     * @param rfid
     * @param device_id
     * @returns {Observable<TokenResponse>}
     */
    refreshToken(refreshToken: string, rfid: string, device_id?: string): Observable<TokenResponse> {
        if (!refreshToken) {
            return throwError(() => new Error('Refresh token does not exist'));
        }
        const payload = compactObject({
            rfid,
            device_id,
            refresh_token: refreshToken,
        });

        return this.http.post<ApiTokenResponse>(`/auth/user/refresh/`, payload).pipe(
            map(res => TokenResponse.toModel(res)),
            catchError(error => {
                this.logger.error(`'/auth/user/refresh/' request failed`, error);
                return throwError(error);
            }),
            finalize(() => {
                this.logger.debug(`'/auth/user/refresh/' request completed `);
            }),
        );
    }

    /**
     * Returns user based on id
     *
     * @param id
     * @returns {Observable<User>}
     */
    getAuthUser(id: string): Observable<User> {
        return this.http
            .get<ApiModels.Practitioner>(`/pdms/practitioners/${id}`)
            .pipe(map(practitioner => new User(practitioner)));
    }

    /**
     * Get practitioners permissions
     *
     * @param rfid id
     */
    getPermissions(rfid: string): Observable<string[]> {
        return this.http.post<{ name: string }[]>(`/pdms/practitioners/permissions/`, { rfid }).pipe(
            map(res => res.map(({ name }) => name).sort()),
            catchError(error => {
                throw new AppError(error);
            }),
        );
    }

    /**
     * Send logout request
     *
     * @returns {Observable<any>}
     */
    logout(): Observable<any> {
        return this.http.post<ApiTokenResponse>(`/auth/user/logout`, null).pipe(
            catchError(error => {
                throw new AppError(error);
            }),
        );
    }
}
