import { NgModule } from '@angular/core';
import { UtilsModule } from '@mona/shared/utils';
import { UiModule } from '@mona/ui';
import { TimeZoneFormComponent } from './components/time-zone-form/time-zone-form.component';
import { TimeZoneFormDialogComponent } from './components/time-zone-form-dialog/time-zone-form-dialog.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [TimeZoneFormComponent, TimeZoneFormDialogComponent],
    imports: [UiModule, UtilsModule],
    exports: [TimeZoneFormComponent, TimeZoneFormDialogComponent],
})
export class TimeZoneModule {}
