import { Injectable, NgZone } from '@angular/core';
import RecordRTC from 'recordrtc';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

/**
 * Handles voice recording for voice assistant
 */
@Injectable({
    providedIn: 'root',
})
export class VoiceRecordingService {
    /**
     * Stream for the microphone device
     */
    microphone: MediaStream;

    /**
     * Stream recorder
     */
    recorder: RecordRTC;

    /**
     * Recording options
     */
    recordingOptions: RecordRTC.Options = {
        type: 'audio',
        // Enforce support for wav files in chrome
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        checkForInactiveTracks: true,
        bufferSize: 4096,
        mimeType: 'audio/wav',
        sampleRate: 48000,
        // get intervals based blobs
        // value in milliseconds
        // as you might not want to make detect calls every seconds
        timeSlice: 50,
        // as soon as the stream is available
        ondataavailable: this.onStreamDataAvailable.bind(this),
    };

    /**
     * Milliseconds of buffer to be added to recording in the end
     * Ensures, that we don't loose the end of a word.
     */
    endRecordThreshold = 500;

    private _microphoneCaptured$ = new BehaviorSubject<boolean>(false);
    /**
     * Track if mic is being captured
     */
    get microphoneCaptured$(): Observable<boolean> {
        return this._microphoneCaptured$.asObservable();
    }
    /**
     * Track if mic is being captured
     */
    get isMicrophoneCaptured(): boolean {
        return this._microphoneCaptured$.getValue();
    }
    /**
     * Emits when a new blob is emitted from the stream
     */
    streamSubject$ = new Subject<ArrayBuffer>();
    /**
     * Track if we are recording the stream
     */
    private isRecording$ = new BehaviorSubject<boolean>(false);

    /**
     * constructor
     *
     * @param ngZone NgZone
     */
    constructor(private ngZone: NgZone) {}

    /**
     * Get is recording as observable
     */
    getIsRecording$(): Observable<boolean> {
        return this.isRecording$.asObservable();
    }

    /**
     * Get is recording as in time value
     */
    getIsRecording(): boolean {
        return this.isRecording$.getValue();
    }

    /**
     * Event handler for sliced streams
     *
     * @param stream Blob
     */
    onStreamDataAvailable(stream: Blob) {
        stream.arrayBuffer().then(arrayBuffer => {
            this.streamSubject$.next(arrayBuffer.slice(44));
        });
    }

    /**
     * Starts recording
     */
    startRecording() {
        if (this.recorder) {
            this.destroyRecorder();
        }

        this.recorder = this.createRecorder();
        this.recorder.startRecording();

        this.isRecording$.next(true);
    }

    /**
     * Stops recording
     *
     * @param immediateStop boolean
     */
    stopRecording(immediateStop = false) {
        if (this.recorder) {
            // Timeout to make sure that we capture a little longer
            setTimeout(
                () => {
                    // Workaround for recording timout auto dialog close
                    if (!this.recorder) {
                        // Make sure that rendering updates work
                        this.ngZone.run(() => {
                            this.isRecording$.next(false);
                        });
                        return;
                    }

                    this.recorder.stopRecording(() => {
                        // CODE REVIEW: Please keep in for later
                        // Example on how to get the audio as a blob
                        // this.audioElement.nativeElement.src = URL.createObjectURL(this.recorder.getBlob());
                        // this.downloadRecording(this.recorder.getBlob());

                        // We dont need the recorder anymore
                        this.recorder && this.destroyRecorder();

                        // Make sure that rendering updates work
                        this.ngZone.run(() => {
                            this.isRecording$.next(false);
                        });
                    });
                },
                !immediateStop ? this.endRecordThreshold : 0,
            );
        } else {
            // Catches edge case on fast toggle
            this.isRecording$.next(false);
        }
    }

    /**
     * Toggles the recorder
     */
    toggleRecording() {
        if (this.isRecording$.getValue()) {
            this.stopRecording();
        } else {
            this.startRecording();
        }
    }

    /**
     * // CODE REVIEW: Please keep in for later
     * Downloads the recorded audio file
     *
     * @param blob Blob
     */
    // downloadRecording(blob: Blob) {
    //     const fileName = 'record.wav';
    //     const a = document.createElement('a');
    //     a.setAttribute('download', fileName);
    //     a.setAttribute('href', window.URL.createObjectURL(blob));
    //     a.click();
    // }

    /**
     * Clear the recorder and it's buffer
     */
    destroyRecorder() {
        this.recorder.destroy();
        this.recorder = null;
        this.isRecording$.next(false);
    }

    /**
     * Activates the microphone
     */
    captureMicrophone() {
        if (!this.microphone) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: {
                        echoCancellation: false,
                    },
                })
                .then(mic => {
                    this.microphone = mic;
                    // Timeout to make UI look a little more smooth
                    setTimeout(() => {
                        this._microphoneCaptured$.next(true);
                    }, 0);
                })
                .catch(error => {
                    console.warn('Unable to capture your microphone. Please check console logs.');
                    console.error(error);
                    this._microphoneCaptured$.next(false);
                });
        }
    }

    /**
     * Deactivates the microphone
     */
    releaseMicrophone() {
        if (this.recorder) {
            this.destroyRecorder();
        }

        if (this.microphone) {
            this.microphone.getTracks().forEach(track => track.stop());
            this.microphone = null;
        }

        this._microphoneCaptured$.next(false);
    }

    /**
     * Creates recorder
     */
    createRecorder(): RecordRTC {
        return new RecordRTC(this.microphone, this.recordingOptions);
    }
}
