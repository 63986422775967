import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatPaginatorDefaultOptions, PageEvent } from '@angular/material/paginator';

/** Object that can be used to configure the default options for the paginator module. */
const VHS_PAGINATOR_OPTIONS: MatPaginatorDefaultOptions = {
    /** Number of items to display on a page. By default set to 11. */
    pageSize: 11,

    /** Whether to hide the page size selection UI from the user. */
    hidePageSize: true,

    /** Whether to show the first/last buttons UI to the user. */
    showFirstLastButtons: true,
};

/**
 * Component to provide events for paged information. Displays navigational button(s) to go to the previous or next page.
 *
 * Paginator instance requires:
 *
 * The number of items per page (default set to 11) The total number of items being paged The current
 * page index defaults to 0, but then is explicitly set via `pageIndex` if `scrollToIndexOnStart` is
 * set to true
 *
 * When the user interacts with the paginator, a `PageEvent` will be fired that can be used to update
 * any associated data view.
 */
@Component({
    selector: 'ui-vhs-pager',
    templateUrl: './vhs-pager.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'ui-vhs-pager' },
})
export class UiVHSPagerComponent {
    /** Current column scroll index */
    @Input() scrollToIndex: number;
    /** Total number of items being paged. */
    @Input() length: number;
    /** Number of items to display on a page. By default set to 11. */
    @Input() pageSize: number;
    /** Whether to show the first/last buttons UI to the user. */
    @Input() showMoreButtons: boolean;
    /** Emits an event notifying that a change of the paginator's properties has been triggered */
    @Output() page = new EventEmitter<Partial<PageEvent>>();

    /**
     * Is Next buttons disabled
     */
    nextButtonsDisabled(): boolean {
        return this.scrollToIndex >= this.length - this.pageSize;
    }

    /**
     * Is Previous buttons disabled
     */
    previousButtonsDisabled(): boolean {
        return this.scrollToIndex <= 0;
    }

    /**
     * Emits an event notifying that a change of the paginator's properties has been triggered.
     *
     * @param pageSize
     */
    emitPageEvent(pageSize) {
        this.page.emit({ pageSize });
    }
}
