import { NgModule } from '@angular/core';
import { UtilsModule } from '@mona/shared/utils';
import { UiCalendarSliderModule } from '@mona/ui';
import { EncounterDateFilterComponent } from './encounter-date-filter.component';

/**
 * Encounter date filter module
 */
@NgModule({
    declarations: [EncounterDateFilterComponent],
    exports: [EncounterDateFilterComponent],
    imports: [UtilsModule, UiCalendarSliderModule],
})
export class EncounterDateFilterModule {}
