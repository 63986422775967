<form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [isEnabled]="isBedSideMode && isActive"
    (ngSubmit)="keyboardEnterClicked($event)"
>
    <div class="app-wound-status-form__row">
        <mat-form-field
            class="app-input-field app-wound-status-form__field"
            appearance="fill"
            data-testid="ff-woundStatusLocation"
        >
            <mat-label>{{ 'apps.admission.woundStatus.location.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.location"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-woundLocationTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.location"></ui-validation-message>
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="app-input-field app-wound-status-form__field"
            appearance="fill"
            data-testid="ff-woundStatusDescription"
        >
            <mat-label>{{ 'apps.admission.woundStatus.description.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.description"
                required
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-woundDescriptionTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.description"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
