<mat-dialog-content>
    <div class="app-time-zone-form-dialog__content">
        <app-time-zone-form [shouldSaveOnChange]="false" (submitted)="handleSubmitted($event)"></app-time-zone-form>
    </div>
    <div class="app-time-zone-form-dialog__actions">
        <button mat-button class="app-time-zone-form-dialog__cancel" cdkFocusInitial [mat-dialog-close]="null">
            {{ 'apps.settings.device.sttUrlDialog.cancel' | translate }}
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="formValue" [disabled]="!formValue">
            {{ 'apps.settings.device.sttUrlDialog.confirm' | translate }}
        </button>
    </div>
</mat-dialog-content>
