import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UtilsModule } from '@mona/shared/utils';
import { UiDirectivesModule } from '../../directives';
import { UiSpacerModule } from '../utils';
import { DropdownToolbarComponent } from './dropdown-toolbar.component';

/**
 * DropdownToolbarModule
 */
@NgModule({
    declarations: [DropdownToolbarComponent],
    imports: [
        UtilsModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        UiSpacerModule,
        NgOptimizedImage,
        UiDirectivesModule,
    ],
    exports: [DropdownToolbarComponent],
})
export class UiDropdownToolbarModule {}
