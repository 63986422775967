<form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [isEnabled]="isBedSideMode && isActive"
    (ngSubmit)="keyboardEnterClicked($event)"
>
    <div class="app-vaccination-status-form__row">
        <mat-form-field
            class="app-input-field app-vaccination-status-form__field"
            appearance="fill"
            data-testid="ff-vaccinationDate"
        >
            <mat-label>{{ 'apps.admission.vaccination.vaccinationDate.textLabel' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.vaccinationDate"
                matInput
                required
                readonly
                (click)="picker.open()"
                [max]="currentDate"
                [matDatepicker]="picker"
                data-testid="ff-vaccinationDateTextarea"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [touchUi]="isBedSideMode"></mat-datepicker>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.vaccinationDate"></ui-validation-message>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="app-input-field" appearance="fill" data-testid="ff-vaccinationStatusText">
            <mat-label>{{ 'apps.admission.vaccination.vaccinationAgainst.textLabel' | translate }}</mat-label>
            <input [required]="true" [formControl]="vaccineSearchText" [matAutocomplete]="auto" matInput />

            <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="autoCompleteDisplayFn"
                (optionSelected)="onNewItemAutoCompleteSelect($event)"
                class="app-input-select-panel scrollbar-visible"
            >
                <mat-option *ngFor="let option of foundVaccineCodes$ | async" [value]="option">
                    <div class="autocomplete-option">
                        <div>{{ option.displayName }}</div>
                        <small class="text-muted">{{ option.code }}</small>
                    </div>
                </mat-option>
            </mat-autocomplete>

            <mat-error>
                <ui-validation-message [control]="formGroup.controls.vaccinationAgainst"></ui-validation-message>
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="app-input-field app-vaccination-status-form__field"
            appearance="fill"
            data-testid="ff-verificationManufacturer"
        >
            <mat-label>{{ 'apps.admission.vaccination.verificationManufacturer.textLabel' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.verificationManufacturer"
                matInput
                data-testid="ff-verificationManufacturerInput"
            />
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.verificationManufacturer"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
