import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { ConversationInputComponent } from './conversation-input.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [ConversationInputComponent],
    exports: [ConversationInputComponent],
    imports: [UiModule, KeyboardModule],
})
export class ConversationInputModule {}
