/* eslint-disable jsdoc/require-jsdoc */
import * as fromRouter from '@ngrx/router-store';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { RouterStateSerialized } from '../models';

/**
 * RouterState
 */
export interface RouterState {
    /**
     * RouterReducerState
     */
    router: fromRouter.RouterReducerState<RouterStateSerialized>;
}

export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<RouterStateSerialized>>('router');

export const selectRouterState = createSelector(selectRouter, router => router && router.state);
export const selectCurrentRoute = createSelector(selectRouter, router => router && router.state);
export const selectQueryParams = createSelector(selectCurrentRoute, route => route && route.queryParams);
export const selectQueryParam = (param: string) => createSelector(selectQueryParams, params => params && params[param]);
export const selectRouteParams = createSelector(selectCurrentRoute, route => route && route.params);
export const selectRouteParam = (param: string) => createSelector(selectRouteParams, params => params && params[param]);
export const selectRouteData = createSelector(selectCurrentRoute, route => route && route.data);
export const selectUrl = createSelector(selectRouterState, routerState => routerState && routerState.url);

export const selectSelectedId = selectRouteParam('id');
