import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BaseMedication } from '@mona/models';
import { StandardMedicationstate } from '../../entities';
import * as StandardMedicationActions from '../actions/standard-medication.actions';

export const standardMedicationAdapter: EntityAdapter<BaseMedication> = createEntityAdapter<BaseMedication>();

const initialState: StandardMedicationstate = standardMedicationAdapter.getInitialState({
    isLoading: false,
    selectedId: null,
});

export const standardMedicationReducer = createReducer(
    initialState,
    on(StandardMedicationActions.failedStandardMedication, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(StandardMedicationActions.selectStandardMedication, (state, action) => ({ ...state, selectedId: action.id })),
    on(
        StandardMedicationActions.createStandardMedication,
        StandardMedicationActions.updateStandardMedication,
        StandardMedicationActions.loadStandardMedications,
        StandardMedicationActions.removeStandardMedication,
        state => ({ ...state, isLoading: true }),
    ),
    on(StandardMedicationActions.createStandardMedicationSuccess, (state, action) =>
        standardMedicationAdapter.addOne(action.standardMedication, { ...state, isLoading: false }),
    ),
    on(StandardMedicationActions.upsertStandardMedication, (state, action) =>
        standardMedicationAdapter.upsertOne(action.standardMedication, { ...state, isLoading: false }),
    ),
    on(StandardMedicationActions.upsertStandardMedications, (state, action) =>
        standardMedicationAdapter.upsertMany(action.standardMedications, state),
    ),
    on(StandardMedicationActions.updateStandardMedication, (state, action) =>
        standardMedicationAdapter.updateOne(action.update, state),
    ),
    on(StandardMedicationActions.updateStandardMedications, (state, action) =>
        standardMedicationAdapter.updateMany(action.update, state),
    ),
    on(StandardMedicationActions.removeStandardMedicationSuccess, (state, action) =>
        standardMedicationAdapter.removeOne(action.id, { ...state, isLoading: false }),
    ),
    on(StandardMedicationActions.removeStandardMedicationCancel, state => ({ ...state, isLoading: false })),
    on(StandardMedicationActions.removeStandardMedications, (state, action) =>
        standardMedicationAdapter.removeMany(action.ids, state),
    ),
    on(StandardMedicationActions.loadStandardMedicationsSuccess, (state, action) =>
        standardMedicationAdapter.setAll(action.standardMedications, { ...state, isLoading: false }),
    ),
    on(StandardMedicationActions.clearStandardMedications, state =>
        standardMedicationAdapter.removeAll({ ...state, isLoading: false }),
    ),
);
