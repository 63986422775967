import { ApiModels } from '@mona/api';
import { Ward } from '@mona/models';

/**
 * Transforms API Ward
 *
 * @param apiWard apiWard
 */
export const transformApiWard = (apiWard: ApiModels.Ward): Ward => {
    return ApiModels.Ward.DTO.toModel(apiWard);
};

/**
 * Transforms API Wards
 *
 * @param apiWards apiWards
 */
export const transformApiWards = (apiWards: ApiModels.Ward[]): Ward[] => {
    return apiWards.map(ward => transformApiWard(ward));
};
