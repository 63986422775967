import { DataSource } from '@angular/cdk/collections';
import { AfterViewInit, Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';
import { compareDeepEqual, FilterableDataSource } from '@mona/shared/utils';

/**
 * UiDynamicSelectComponent
 */
@Component({
    selector: 'ui-dynamic-select',
    styleUrls: ['./dynamic-select.component.scss'],
    templateUrl: './dynamic-select.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class UiDynamicSelectComponent implements AfterViewInit {
    @ViewChild(MatSelect, { static: true }) readonly select: MatSelect;

    readonly form: NgForm;

    control: UntypedFormControl;

    appearance: MatFormFieldAppearance;

    label = '';

    labelKey = 'label';

    valueKey = 'value';

    hint = '';

    name = '';

    required: boolean = undefined;

    dataSource: FilterableDataSource<any> = undefined;

    multiple = false;

    errorMessageTemplate: TemplateRef<any> = undefined;

    placeholder = '';

    customClass = '';

    panelClass = '';

    opened = false;

    showEmptyOption = false;

    submitOnSelect = false;

    hideValidationMessage: boolean = undefined;

    hideRequiredMarker: boolean = undefined;

    compareFn = compareDeepEqual;

    /** @ignore */
    ngAfterViewInit(): void {
        if (this.opened) {
            setTimeout(() => {
                this.select.open();
            }, 0);
        }
    }
}
