<ui-info-list-item *ngIf="!isChecked; else checkedState" [attr.data-testid]="'diagnostic-item-' + checkLabel">
    <mat-icon class="text-accent manual-icon" ui-icon>warning</mat-icon>
    <div ui-title>{{ notCheckedLabel | translate }}</div>
    <div class="text-muted" ui-subtitle>{{ checkLabel | translate }}</div>
</ui-info-list-item>

<ng-template #checkedState>
    <ui-info-list-item [attr.data-testid]="'diagnostic-item-' + checkLabel">
        <mat-icon class="text-success" ui-icon>check_circle</mat-icon>
        <div ui-title>{{ checkedLabel | translate: checkedParams }}</div>
        <div class="text-muted" ui-subtitle>{{ checkLabel | translate }}</div>
    </ui-info-list-item>
</ng-template>
