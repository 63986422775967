import { createAction, props } from '@ngrx/store';
import { TerminalKeysConfig, Timezone } from '@mona/models';

/**
 * Device actions
 */
export class DeviceActions {
    static initDeviceData = createAction('DEVICE:INIT_DEVICE_DATA');
    static getKeys = createAction('DEVICE:GET_KEYS');
    static getKeysSuccess = createAction('DEVICE:GET_KEYS_SUCCESS', props<{ keys: TerminalKeysConfig }>());
    static getBrightness = createAction('DEVICE:GET_BRIGHTNESS');
    static getBrightnessSuccess = createAction('DEVICE:GET_BRIGHTNESS_SUCCESS', props<{ value: number }>());
    static setBrightness = createAction('DEVICE:SET_BRIGHTNESS', props<{ value: number }>());
    static setBrightnessSuccess = createAction('DEVICE:SET_BRIGHTNESS_SUCCESS');
    static sendAppReload = createAction('DEVICE:SEND_APP_RELOAD');
    static sendSystemReboot = createAction('DEVICE:SEND_SYSTEM_REBOOT');
    static sendSystemShutdown = createAction('DEVICE:SEND_SYSTEM_SHUTDOWN');
    static invokePreCallScript = createAction('DEVICE:INVOKE_PRE_CALL_SCRIPT');
    static invokePostCallScript = createAction('DEVICE:INVOKE_POST_CALL_SCRIPT');
    static resetConfig = createAction('DEVICE:RESET_CONFIG');
    static getCurrentTimezone = createAction('DEVICE:GET_CURRENT_TIMEZONE');
    static getCurrentTimezoneSuccess = createAction(
        'DEVICE:GET_CURRENT_TIMEZONE_SUCCESS',
        props<{ value: Timezone }>(),
    );
    static setTimezone = createAction('DEVICE:SET_TIMEZONE', props<{ value: Timezone }>());
    static generateTelemedicineDeviceKeys = createAction('DEVICE:GENERATE_TELEMEDICINE_DEVICE_KEYS');
    static toggleCameraSuccess = createAction('DEVICE:TOGGLE_CAMERA_SUCCESS', props<{ value: boolean }>());
    static setOSKLanguage = createAction('DEVICE:SET_OSK_LANGUAGE', props<{ value: string }>());
    static setOSKAvailability = createAction('DEVICE:SET_OSK_AVAILABILITY', props<{ value: boolean }>());
}
