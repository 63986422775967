/**
 * Detects if element has class
 *
 * @param el HTMLElement
 * @param className string
 */
export const elementHasClass = (el: HTMLElement, className: string): boolean => {
    return el?.classList.contains(className);
};

/**
 * closest implementation that is able to start from non-Element Nodes.
 *
 * @param element
 * @param selector
 */
export function closest(element: EventTarget | Element | null | undefined, selector: string): Element | null {
    if (!(element instanceof Node)) {
        return null;
    }

    let curr: Node | null = element;
    while (curr != null && !(curr instanceof Element)) {
        curr = curr.parentNode;
    }

    return (curr as any)?.closest(selector) ?? null;
}

/**
 * Utility to dispatch any event on a Node.
 *
 * @param node - The Node that should dispatch the event
 * @param event - The event to be dispatched
 * @returns The dispatched event
 * @example
 * dispatchEvent(myNativeElement, 'blur');
 */
export function dispatchEvent(node: Node | Window, event: Event): Event {
    node.dispatchEvent(event);
    return event;
}
