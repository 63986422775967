import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { WoundStatusType } from '@mona/models';
import { WoundStatusState } from '../../entities';
import { WoundStatusAction } from '../actions';

export const woundStatusAdapter: EntityAdapter<WoundStatusType> = createEntityAdapter<WoundStatusType>();

export const initialState: WoundStatusState = woundStatusAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const woundStatusReducer = createReducer(
    initialState,
    on(WoundStatusAction.loadWoundStatus, state => ({ ...state, isLoading: true })),
    on(WoundStatusAction.loadWoundStatusSuccess, (state, { data }) =>
        woundStatusAdapter.setAll(data, {
            ...state,
            error: null,
            isLoading: false,
        }),
    ),
    on(WoundStatusAction.loadWoundStatusFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(WoundStatusAction.updateWoundStatus, (state, action) => woundStatusAdapter.updateMany(action.update, state)),
    on(WoundStatusAction.upsertWoundStatus, (state, action) => woundStatusAdapter.upsertMany(action.data, state)),
    on(WoundStatusAction.clearWoundStatus, state => woundStatusAdapter.removeAll(state)),
    on(WoundStatusAction.addWoundStatusChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = woundStatusAdapter.removeMany(toRemoveIds, state);

        return woundStatusAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
