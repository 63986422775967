import { ApiModels } from '@mona/api';

/** INFO: InfectionStatusModel */
export interface InfectionStatus extends ApiModels.InfectionStatus.Model {
    /** FE only property to have a last change by name */
    lastChangedByName?: string;
    /** FE only prop to indicate if entry control has changes */
    hasChanges?: boolean;
    /** FE only prop to indicate if entry control has changes */
    isStageRemoved?: boolean;
}

/**
 * The verification status to support or decline the clinical status of the condition or diagnosis.
 */
export enum InfectionStatuses {
    ACTIVE = 'active',
    RECURRENCE = 'recurrence',
    RELAPSE = 'relapse',
    INACTIVE = 'inactive',
    REMISSION = 'remission',
    RESOLVED = 'resolved',
    UNKNOWN = 'unknown',
}

/** INFO: InfectionStatusType */
export type InfectionStatusType = InfectionStatus;
