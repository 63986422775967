import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { UtilsModule } from '@mona/shared/utils';

/**
 * Root settings component
 */
@Component({
    selector: 'ui-settings-layout',
    templateUrl: './settings-layout.component.html',
})
export class SettingsLayoutComponent {}

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [SettingsLayoutComponent],
    imports: [UtilsModule],
    exports: [SettingsLayoutComponent],
})
export class UiSettingsLayoutModule {}
