import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeLogEntry, SurgeryPrescription } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { applyLastChangedByToPrescriptions, withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { SurgeryPrescriptionApi } from '../../infrastructure';
import { SurgeryPrescriptionAction } from '../actions';
import { selectSurgeryPrescriptionAll } from '../selectors';

/**
 * SurgeryPrescription effects
 */
@Injectable()
export class SurgeryPrescriptionEffects {
    changeLogMap$ = this.store.select(ChangeLogSelectors.getChangesMap);
    selectSurgeryPrescriptionAll$ = this.store.select(selectSurgeryPrescriptionAll);

    /** Load Surgery Prescription */
    loadSurgeryPrescription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SurgeryPrescriptionAction.loadSurgeryPrescription),
            withCurrentEncounterId(),
            concatMap(([, encounterId]) => this.surgeryPrescriptionApi.getSurgeryPrescription(encounterId)),
            withLatestFrom(this.changeLogMap$, this.practitionersFacade.practitionersMap$),
            map(([data, changesMap, practitionersMap]) => {
                const changes = changesMap['SurgeryPrescription'] || [];

                if (changes.length) {
                    data = applyInstancesChanges(data, changes) as SurgeryPrescription[];
                }

                data = applyLastChangedByToPrescriptions(data, practitionersMap);

                return SurgeryPrescriptionAction.loadSurgeryPrescriptionSuccess({ data });
            }),
            catchError(() => EMPTY),
        ),
    );

    /** Listen change save success & upsert entity + show message */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(this.selectSurgeryPrescriptionAll$, this.changeLogMap$),
                tap(([, data, changesMap]) => {
                    const changes: ChangeLogEntry<SurgeryPrescription>[] = changesMap['SurgeryPrescription'] || [];

                    if (changes.length) {
                        const surgeryPrescription = applyInstancesChanges(data, changes);

                        this.store.dispatch(
                            SurgeryPrescriptionAction.upsertSurgeryPrescription({ data: surgeryPrescription }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.selectSurgeryPrescriptionAll$,
                    this.changeLogMap$,
                    this.practitionersFacade.practitionersMap$,
                ),
                tap(([, data, changesMap, practitionersMap]) => {
                    const changes = changesMap['SurgeryPrescription'] || [];

                    if (changes.length) {
                        const removeUpdateData = getPersistedChangesData(data, changes);
                        removeUpdateData.toUpdate = applyLastChangedByToPrescriptions(
                            removeUpdateData.toUpdate,
                            practitionersMap,
                        );

                        this.store.dispatch(
                            SurgeryPrescriptionAction.addSurgeryPrescriptionChanges({
                                toUpdateEntities: removeUpdateData.toUpdate || [],
                                toRemoveIds: removeUpdateData.toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store
     * @param actions$
     * @param surgeryPrescriptionApi
     * @param practitionersFacade
     */
    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private surgeryPrescriptionApi: SurgeryPrescriptionApi,
        private practitionersFacade: PractitionersFacade,
    ) {}
}
