import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncounterInfo, ENCOUNTER_INFO } from '@mona/pdms/data-access-combined';
import { PLATFORM, Platform } from '@mona/shared/utils';

/**
 * Encounter location component
 */
@Component({
    selector: 'mona-encounter-info',
    templateUrl: './encounter-info.component.html',
    styleUrls: ['./encounter-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterInfoComponent {
    /** Hide icons */
    @Input() hideIcons = false;
    /** Encounter location data */
    @Input() encounterInfo: EncounterInfo;
    /** Is for sidenav */
    @Input() isForSidenav = false;
    /** Is bed not assigned to the terminal */
    @Input() isNotAssignedBedSelected = false;
    /** Is review mode */
    @Input() isReviewMode = false;
    /** Has edit review mode permissions */
    @Input() hasEditViewModePermissions = false;
    /** Host css class */
    @HostBinding('class.mona-encounter-info') cmpClass = true;

    /**
     * Component constructor
     *
     * @param router
     * @param route
     * @param encounterInfo
     * @param platform
     */
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Optional() @Inject(ENCOUNTER_INFO) encounterInfo: EncounterInfo,
        @Inject(PLATFORM) public platform: Platform,
    ) {
        this.encounterInfo = encounterInfo;
    }

    /**
     * Navigate to patient management
     */
    navigateToPatientManagement(): void {
        this.router.navigate(['patient-management'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
    }
}
