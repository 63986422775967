import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { fromRelativeContacts } from './state';
import { RelativeContactsEffects } from './state/effects';

/**
 * DataAccessRelativeContactsModule
 */
@NgModule({
    imports: [
        StoreModule.forFeature(fromRelativeContacts.relativeContactsFeatureKey, fromRelativeContacts.reducer),
        EffectsModule.forFeature([RelativeContactsEffects]),
    ],
})
export class DataAccessRelativeContactsModule {}
