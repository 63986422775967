import { createFeatureSelector, createSelector } from '@ngrx/store';
import { patientsFeatureKey, PatientsState } from '../../entities/patient.state';
import { selectIds, selectEntities, selectAll } from '../reducers/patients.reducer';

export const selectPatientsState = createFeatureSelector<PatientsState>(patientsFeatureKey);

export const selectPatientsStateIsLoading = createSelector(selectPatientsState, x => x.isLoading);
export const selectPatientsIds = createSelector(selectPatientsState, selectIds as (state) => string[]);

export const selectPatientsEntities = createSelector(selectPatientsState, selectEntities);

export const selectAllPatients = createSelector(selectPatientsState, selectAll);

/**
 * Select patient by id
 *
 * @param patientId
 */
export const selectPatientById = (patientId?) =>
    createSelector(selectAllPatients, patients => patients.filter(p => p.id === patientId)?.shift());
