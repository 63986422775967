/* eslint jsdoc/require-param: 0 */
import { Component, HostListener, Input } from '@angular/core';
import { AuthService } from '@mona/auth';

/**
 * Rfid emulator
 */
@Component({
    selector: 'mona-rfid-emulator',
    template: '', // No template needed, should stay invisible
})
export class RfidEmulatorComponent {
    @Input() enabled = false;
    /**
     * Constructor
     * @param authService AuthService
     */
    constructor(private authService: AuthService) {}

    /**
     * ALT+1
     */
    @HostListener('window:keydown.f1', ['$event'])
    onKeyDown1($event: any) {
        this.onHotkeyPress(1);
    }

    /**
     * ALT+2
     */
    @HostListener('window:keydown.f2', ['$event'])
    onKeyDown2($event: any) {
        this.onHotkeyPress(2);
    }

    /**
     * ALT+3
     */
    @HostListener('window:keydown.f3', ['$event'])
    onKeyDown3($event: any) {
        this.onHotkeyPress(3);
    }

    /**
     * ALT+4
     */
    @HostListener('window:keydown.f4', ['$event'])
    onKeyDown4($event: any) {
        this.onHotkeyPress(4);
    }

    /**
     * ALT+5
     */
    @HostListener('window:keydown.f5', ['$event'])
    onKeyDown5($event: any) {
        this.onHotkeyPress(5);
    }

    /**
     * ALT+6
     */
    @HostListener('window:keydown.f6', ['$event'])
    onKeyDown6($event: any) {
        this.onHotkeyPress(6);
    }

    /**
     * ALT+7
     */
    @HostListener('window:keydown.f7', ['$event'])
    onKeyDown7($event: any) {
        this.onHotkeyPress(7);
    }

    /**
     * ALT+8
     */
    @HostListener('window:keydown.f8', ['$event'])
    onKeyDown8($event: any) {
        this.onHotkeyPress(8);
    }

    /**
     * ALT+9
     */
    @HostListener('window:keydown.f9', ['$event'])
    onKeyDown9($event: any) {
        this.onHotkeyPress(9);
    }

    /**
     * Sends the pressed number to node
     * @param key number
     */
    onHotkeyPress(key: number) {
        this.enabled && this.authService.emulateRfid(key);
    }
}
