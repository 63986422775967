/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { ExternalResource } from '@mona/models';

export namespace ExternalResourcesActions {
    export const loadExternalResources = createAction('EXTERNAL_RESOURCES:LOAD_EXTERNAL_RESOURCES');
    export const loadExternalResourcesSuccess = createAction(
        'EXTERNAL_RESOURCES:LOAD_EXTERNAL_RESOURCES_SUCCESS',
        props<{ data: ExternalResource[] }>(),
    );
    export const loadExternalResourcesFailure = createAction(
        'EXTERNAL_RESOURCES:LOAD_EXTERNAL_RESOURCES_FAILURE',
        props<{ error: any }>(),
    );
    export const clearExternalResources = createAction('EXTERNAL_RESOURCES:CLEAR_EXTERNAL_RESOURCES');

    export const selectExternalResource = createAction(
        'EXTERNAL_RESOURCES:SELECT_EXTERNAL_RESOURCES',
        props<{ selected: ExternalResource }>(),
    );
    export const clearSelectExternalResource = createAction('EXTERNAL_RESOURCES:CLEAR_SELECT_EXTERNAL_RESOURCES');

    // Browser actions
    export const browserNavigateBackAction = createAction('EXTERNAL_RESOURCES:BROWSER_NAVIGATE_BACK_ACTION');
    export const browserNavigateBackActionSuccess = createAction(
        'EXTERNAL_RESOURCES:BROWSER_NAVIGATE_BACK_ACTION_SUCCESS',
    );
    export const browserNavigateForwardAction = createAction('EXTERNAL_RESOURCES:BROWSER_NAVIGATE_FORWARD_ACTION');
    export const browserNavigateForwardActionSuccess = createAction(
        'EXTERNAL_RESOURCES:BROWSER_NAVIGATE_FORWARD_ACTION_SUCCESS',
    );
    export const browserReloadAction = createAction('EXTERNAL_RESOURCES:BROWSER_RELOAD_ACTION');
    export const browserReloadActionSuccess = createAction('EXTERNAL_RESOURCES:BROWSER_RELOAD_ACTION_SUCCESS');
    export const browserEndSessionAction = createAction('EXTERNAL_RESOURCES:BROWSER_END_SESSION_ACTION');
    export const browserEndSessionActionSuccess = createAction('EXTERNAL_RESOURCES:BROWSER_END_SESSION_ACTION_SUCCESS');
    export const browserSetWindowShownAction = createAction('EXTERNAL_RESOURCES:BROWSER_SET_WINDOW_SHOWN_ACTION');
    export const browserSetWindowShownActionSuccess = createAction(
        'EXTERNAL_RESOURCES:BROWSER_SET_WINDOW_SHOWN_ACTION_SUCCESS',
    );
    export const browserSetWindowHiddenAction = createAction('EXTERNAL_RESOURCES:BROWSER_SET_WINDOW_HIDDEN_ACTION');
    export const browserSetWindowHiddenActionSuccess = createAction(
        'EXTERNAL_RESOURCES:BROWSER_SET_WINDOW_HIDDEN_ACTION_SUCCESS',
    );
    export const browserViewResize = createAction(
        'DEVICE:BROWSER_VIEW_RESIZE',
        props<{ width: number; height: number }>(),
    );
    export const browserViewResizeSuccess = createAction('DEVICE:BROWSER_VIEW_RESIZE_SUCCESS');
    export const browserEnterFullScreenAction = createAction('EXTERNAL_RESOURCES:BROWSER_ENTER_FULL_SCREEN_ACTION');
    export const browserExitFullScreenAction = createAction('EXTERNAL_RESOURCES:BROWSER_EXIT_FULL_SCREEN_ACTION');
}
