import { Pipe, PipeTransform } from '@angular/core';
import { isString } from '../types';

export const DEFAULT_LIMIT = 40;
/**
 * Truncate pipe
 */
@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    /**
     * An interface that is implemented by pipes
     *
     * @param value
     * @param limit
     * @param trail
     */
    transform(value: string | undefined, limit?: string | number, trail?: string): string {
        limit = +limit ? parseInt(limit as any, 10) : DEFAULT_LIMIT;
        trail = trail || '...';
        return isString(value) && value.length > limit ? value.substring(0, limit) + trail : value;
    }
}
