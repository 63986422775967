<div class="ui-date-time__wrapper" [formGroup]="dateTimeFormGroup">
    <ui-date-picker
        [class.d-none]="timeOnly"
        class="ui-date-time__date"
        [pickerId]="pickerId"
        id="{{ pickerId + '-date-input' }}"
        name="{{ name + '-date-input' }}"
        [placeholder]="placeholder"
        formControlName="date"
        (dateChange)="onDateTimeChange()"
        [required]="required"
        [max]="max"
        [min]="min"
    ></ui-date-picker>
    <div class="ui-date-time__spacer" [class.d-none]="timeOnly"></div>
    <div class="ui-date-time__time" [class.ui-date-time__time--only]="timeOnly">
        <span class="mdc-floating-label mat-mdc-floating-label mdc-floating-label--float-above">
            <label class="mat-mdc-form-field-label" for="{{ pickerId + '-time-input' }}">
                <mat-label class="time-picker__label">
                    {{ timelabel }}
                </mat-label>
            </label>
        </span>
        <input
            matInput
            #timeInput
            id="{{ pickerId + '-time-input' }}"
            name="{{ name + '-time-input' }}"
            class="ui-date-time__time-input"
            mask="Hh:m0"
            [leadZeroDateTime]="true"
            [dropSpecialCharacters]="true"
            formControlName="time"
            (change)="onDateTimeChange()"
            (keydown.enter)="onDateTimeChange()"
            [required]="required"
        />
        <div class="mat-datepicker-toggle ui-date-time__btn" [class.d-none]="timeOnly">
            <button mat-icon-button (click)="focusTimeInput($event)" [disabled]="disabled">
                <mat-icon svgIcon="clock"></mat-icon>
            </button>
        </div>
    </div>
</div>
<!-- TODO: decide if needed -->
<div class="mat-form-field-suffix ui-date-time__btn" *ngIf="false">
    <button
        mat-icon-button
        (click)="clearDateTime()"
        class="ui-date-time__btn--clear"
        [ngClass]="{ hidden: disabled || !focused || (!dateTimeFormGroup.value.date && !dateTimeFormGroup.value.time) }"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
