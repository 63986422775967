/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of, zip } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import {
    ChangeLogEntry,
    MedicationPrescription,
    Practitioner,
    PrescriptionSet,
    ProcedurePrescription,
} from '@mona/models';
import { ChangeLogSelectors, ChangeLogService } from '@mona/pdms/data-access-changelog';
import { StandardMedicationsApi } from '@mona/pdms/data-access-medications';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { notEmpty, toSuccessActionResult } from '@mona/shared/utils';
import { MessageService } from '@mona/ui';
import { PrescriptionSetMedicationsApi, PrescriptionSetProceduresApi, PrescriptionSetsApi } from '../../infrastructure';
import * as PrescriptionSetActions from '../actions/prescription-set.actions';

/**
 * PrescriptionSetEffects
 */
@Injectable()
export class PrescriptionSetEffects {
    changeLogMap$ = this.store.select(ChangeLogSelectors.getChangesMap);
    practitioner$: Observable<Practitioner> = this.authService.user$;

    /** Load PrescriptionSet */
    loadPrescriptionSets$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PrescriptionSetActions.loadPrescriptionSets),
                switchMap(() => this.prescriptionSetsApi.getPrescriptionSets()),
                map((prescriptionSets: PrescriptionSet[]) =>
                    PrescriptionSetActions.loadPrescriptionSetsSuccess({
                        prescriptionSets,
                    }),
                ),
                catchError(error => of(PrescriptionSetActions.loadPrescriptionSetsFailure({ error }))),
            ),
        { dispatch: true },
    );

    /**
     * Load terminology when prescription sets are loaded
     */
    loadPrescriptionSetsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    PrescriptionSetActions.loadPrescriptionSetsSuccess,
                    PrescriptionSetActions.loadPrescriptionSetByIdSuccess,
                ),
                tap(() => {
                    this.terminologyService.loadPrescriptionTerminology();
                }),
            ),
        { dispatch: false },
    );

    /**
     * load and set active prescription set
     */
    loadAndSetActivePrescriptionSet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PrescriptionSetActions.loadAndSetActivePrescriptionSet),
            switchMap(({ id }) => {
                return this.prescriptionSetsApi.getPrescriptionSetById(id).pipe(
                    map((prescriptionSet: PrescriptionSet) =>
                        PrescriptionSetActions.upsertActivePrescriptionSetAsCopy({ prescriptionSet }),
                    ),
                    catchError(error => of(PrescriptionSetActions.loadPrescriptionSetByIdFailure({ error }))),
                );
            }),
        ),
    );

    /**
     * load prescription set failure
     */
    loadPrescriptionSetFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PrescriptionSetActions.loadPrescriptionSetByIdFailure),
                tap(() => {
                    this.messageService.errorToast('apps.settings.prescriptionSets.messages.errorLoading');
                }),
            ),
        { dispatch: false },
    );

    /**
     * Create prescription set effect
     */
    createPrescriptionSet$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PrescriptionSetActions.addPrescriptionSet),
                withLatestFrom(this.practitioner$),
                switchMap(([{ name }, { id: practitionerId }]) => {
                    return this.prescriptionSetsApi.createPrescriptionSet(name, practitionerId).pipe(
                        map((prescriptionSet: PrescriptionSet) => {
                            this.messageService.successToast('Prescription Set Added');

                            return PrescriptionSetActions.addPrescriptionSetSuccess({
                                prescriptionSet,
                            });
                        }),
                        catchError(error => of(PrescriptionSetActions.addPrescriptionSetFailure({ error }))),
                    );
                }),
            ),
        { dispatch: true },
    );

    /**
     * Load prescription set by id effect
     */
    loadPrescriptionSetById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PrescriptionSetActions.loadPrescriptionSetById),
            notEmpty(),
            filter(({ id }) => !!id),
            switchMap(({ id }) => {
                return this.prescriptionSetsApi.getPrescriptionSetById(id);
            }),
            map((prescriptionSet: PrescriptionSet) =>
                PrescriptionSetActions.loadPrescriptionSetByIdSuccess({ prescriptionSet }),
            ),
            catchError(error => of(PrescriptionSetActions.loadPrescriptionSetByIdFailure({ error }))),
        ),
    );

    /**
     * Update prescription set effect
     */
    updatePrescriptionSet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PrescriptionSetActions.updatePrescriptionSet),
            withLatestFrom(this.practitioner$),
            switchMap(([{ update }, { id: practitionerId }]) => {
                const { id, changes } = update;
                return this.prescriptionSetsApi.updatePrescriptionSet(id as string, changes.name, practitionerId).pipe(
                    map((updatedPrescriptionSet: PrescriptionSet) => {
                        this.messageService.successToast('apps.settings.prescriptionSets.messages.updated');
                        return PrescriptionSetActions.updatePrescriptionSetSuccess({
                            update: {
                                id: id as string,
                                changes: updatedPrescriptionSet,
                            },
                        });
                    }),
                    catchError(error => of(PrescriptionSetActions.updatePrescriptionSetFailure({ error }))),
                );
            }),
        ),
    );

    /**
     * Remove prescription set effect
     */
    removePrescriptionSet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PrescriptionSetActions.deletePrescriptionSet),
            switchMap(({ id }) =>
                this.prescriptionSetsApi.removePrescriptionSet(id).pipe(
                    map(() => {
                        this.messageService.successToast('apps.settings.prescriptionSets.messages.deleted');
                        return PrescriptionSetActions.deletePrescriptionSetSuccess({
                            id,
                        });
                    }),
                    catchError(error => of(PrescriptionSetActions.deletePrescriptionSetFailure({ error }))),
                ),
            ),
        ),
    );

    /**
     * Create prescriptions bulk from set
     */
    createPrescriptionsBulk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PrescriptionSetActions.createPrescriptionsBulk),
            switchMap(({ prescriptionSet }) => {
                const { medications, procedures } = prescriptionSet;
                // map medications ^ procedures to payloads
                const medicationPrescriptions: MedicationPrescription[] = medications.map(medication => {
                    const { standardMedicationSet } = medication;
                    const frequencyTimes =
                        environment.customFrequencyCode === standardMedicationSet.frequency
                            ? standardMedicationSet.frequencyTimes || []
                            : [];
                    const payload = {
                        id: undefined,
                        category: standardMedicationSet.medication.categoryCode,
                        code: standardMedicationSet.medicationCode,
                        rate: standardMedicationSet.rate,
                        description: standardMedicationSet.medication.displayName,
                        dosageForm: standardMedicationSet.dosageForm,
                        method: standardMedicationSet.method,
                        solutionCode: standardMedicationSet.solutionCode,
                        frequency: standardMedicationSet.frequency,
                        frequencyTimes,
                        amount: standardMedicationSet.amount,
                        unitAmount: standardMedicationSet.unitAmount,
                        unitRate: standardMedicationSet.unitRate,
                        unitVolume: standardMedicationSet.unitVolume,
                        volume: standardMedicationSet.volume,
                        // instruction: formValue.instruction,
                        startDate: new Date(),
                        isStopped: false,
                        isApproved: false,
                    };
                    return payload;
                });
                const procedurePrescriptions: ProcedurePrescription[] = procedures.map(procedure => {
                    const frequencyTimes =
                        environment.customFrequencyCode === procedure.frequency ? procedure.frequencyTimes || [] : [];
                    return {
                        id: undefined,
                        category: procedure.category,
                        description: procedure.description,
                        instructions: procedure.instructions,
                        frequency: procedure.frequency,
                        frequencyTimes,
                        startDate: new Date(),
                        isApproved: false,
                        isRevoked: false,
                    };
                });

                // TODO: safe check if medicationPrescriptions or procedurePrescriptions are not empty / valid
                // call changelogservice createPrescriptionsBulk with payloads
                this.changeLogService.createPrescriptionsBulk(medicationPrescriptions, procedurePrescriptions);

                /** TODO: how to wait for all changes actions to finish */
                return this.changeLogService.getSaveChangesAction();
            }),
            toSuccessActionResult(),
            map((result: ChangeLogEntry<MedicationPrescription | ProcedurePrescription>[]) => {
                return PrescriptionSetActions.createPrescriptionsBulkSuccess({ result });
            }),
            catchError(error => of(PrescriptionSetActions.createPrescriptionsBulkFailure({ error }))),
        ),
    );

    /**
     * Create prescriptions bulk from set
     */
    createPrescriptionsBulkSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PrescriptionSetActions.createPrescriptionsBulkSuccess),
                tap(({ result }) => {
                    const medicationsCount = result.filter(r => r.model === 'MedicationPrescription')?.length;
                    const proceduresCount = result.filter(r => r.model === 'ProcedurePrescription')?.length;
                    const addedStr = this.translateService.instant(
                        'apps.patient.prescriptions.addPrescriptionSet.success.added',
                    );
                    const medicationsStr = medicationsCount
                        ? `${medicationsCount} ${this.translateService.instant(
                              'apps.patient.prescriptions.addPrescriptionSet.success.medications',
                          )}`
                        : '';
                    const proceduresStr = proceduresCount
                        ? `${proceduresCount} ${this.translateService.instant(
                              'apps.patient.prescriptions.addPrescriptionSet.success.procedures',
                          )}`
                        : '';

                    const message = `${addedStr} ${medicationsStr}${
                        medicationsCount && proceduresCount ? ', ' : ''
                    }${proceduresStr}`;

                    this.messageService.successToast(message);
                }),
            ),
        {
            dispatch: false,
        },
    );

    upsertPrescriptionSetMedication$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PrescriptionSetActions.upsertPrescriptionSetMedication),
                switchMap(({ setId, standardMedication }) => {
                    let apiObs$ = of(null);
                    if (standardMedication.id) {
                        apiObs$ = this.prescriptionSetMedicationsApi.createPrescriptionSetMedication(
                            setId,
                            standardMedication.id,
                        );
                    } else {
                        apiObs$ = this.standardMedicationsApi.createStandardMedication(standardMedication).pipe(
                            switchMap(createdStandardMedication => {
                                return this.prescriptionSetMedicationsApi.createPrescriptionSetMedication(
                                    setId,
                                    createdStandardMedication.id,
                                );
                            }),
                        );
                    }

                    return zip(of(setId), apiObs$);
                }),
                tap(([id]) => {
                    this.store.dispatch(
                        PrescriptionSetActions.loadPrescriptionSetById({
                            id,
                        }),
                    );
                }),
                catchError(() => EMPTY),
            ),
        { dispatch: false },
    );

    // TODO: what a mess on API side 😔😔😔
    deletePrescriptionSetMedication$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PrescriptionSetActions.deletePrescriptionSetMedication),
                switchMap(({ setId, standardMedicationSetId }) => {
                    return this.prescriptionSetMedicationsApi
                        .deletePrescriptionSetMedication(standardMedicationSetId)
                        .pipe(
                            tap(() =>
                                this.store.dispatch(
                                    PrescriptionSetActions.loadPrescriptionSetById({
                                        id: setId,
                                    }),
                                ),
                            ),
                        );
                }),
                catchError(() => EMPTY),
            ),
        { dispatch: false },
    );

    deletePrescriptionSetProcedure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PrescriptionSetActions.deletePrescriptionSetProcedure),
                switchMap(({ setId, procedureId }) => {
                    return this.prescriptionSetProceduresApi.deletePrescriptionSetProcedure(procedureId).pipe(
                        tap(() =>
                            this.store.dispatch(
                                PrescriptionSetActions.loadPrescriptionSetById({
                                    id: setId,
                                }),
                            ),
                        ),
                    );
                }),
                catchError(() => EMPTY),
            ),
        { dispatch: false },
    );

    upsertPrescriptionSetProcedure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PrescriptionSetActions.upsertPrescriptionSetProcedure),
                switchMap(({ setId, procedure }) => {
                    return this.prescriptionSetProceduresApi
                        .createPrescriptionSetProcedure(setId, procedure)
                        .pipe(
                            tap(() =>
                                this.store.dispatch(PrescriptionSetActions.loadPrescriptionSetById({ id: setId })),
                            ),
                        );
                }),
                catchError(() => EMPTY),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store Store
     * @param actions$ Actions
     * @param prescriptionSetsApi PrescriptionSetsApi
     * @param terminologyService TerminologyService
     * @param authService AuthService
     * @param translateService
     * @param messageService MessageService
     * @param changeLogService ChangeLogService
     * @param prescriptionSetMedicationsApi PrescriptionSetMedicationsApi
     * @param standardMedicationsApi StandardMedicationsApi
     * @param prescriptionSetProceduresApi PrescriptionSetProceduresApi
     */
    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private prescriptionSetsApi: PrescriptionSetsApi,
        private terminologyService: TerminologyService,
        private authService: AuthService,
        private translateService: TranslateService,
        private messageService: MessageService,
        private changeLogService: ChangeLogService,
        private prescriptionSetMedicationsApi: PrescriptionSetMedicationsApi,
        private standardMedicationsApi: StandardMedicationsApi,
        private prescriptionSetProceduresApi: PrescriptionSetProceduresApi,
    ) {}
}
