import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MonaTask } from '@mona/models';
import { TaskListState } from '../../entities';
import { TaskListActions } from '../actions/task-list.actions';

export const taskListFeatureKey = 'taskList';

export const tasksAdapter = createEntityAdapter<MonaTask>({
    selectId: task => task.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id),
});

export const initialState: TaskListState = tasksAdapter.getInitialState({
    taskList: {},
    overdueTime: 30,
    isLoading: false,
    filter: null,
    error: null,
    completedOnDemandMedPrIds: [],
});

export const reducer = createReducer(
    initialState,
    on(TaskListActions.loadTasks, state => ({
        ...state,
        isLoading: true,
        error: null,
    })),
    on(TaskListActions.loadTasksSuccess, (state, { data, tasks }) =>
        tasksAdapter.setAll(tasks, {
            ...state,
            taskList: data,
            isLoading: false,
        }),
    ),
    on(TaskListActions.updateTasks, (state, { updateTasks: tasks }) => tasksAdapter.updateMany(tasks, state)),
    on(TaskListActions.loadTasksFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),
    on(TaskListActions.setFilter, (state, { filter }) => ({ ...state, filter })),
    on(TaskListActions.toggleIsLoading, (state, { isLoading }) => ({ ...state, isLoading })),
    on(TaskListActions.clearTaskListState, () => ({ ...initialState })),
    on(TaskListActions.setOverdueTime, (state, { overdueTime }) => ({ ...state, overdueTime })),
    on(TaskListActions.upsertCompleteOnDemandMedPrescriptions, (state, { completedOnDemandMedPrIds }) => ({
        ...state,
        completedOnDemandMedPrIds,
    })),
);
