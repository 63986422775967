import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsModule } from '@mona/shared/utils';
import { UiDirectivesModule } from '@mona/ui/directives';
import { UiInfoListItemModule } from '../../info-list-item';
import { DrawerBodyComponent } from './drawer-body.component';
import { DrawerNavGroupComponent } from './nav-group/drawer-nav-group.component';
import { DrawerNavGroupModule } from './nav-group/drawer-nav-group.module';
import { DrawerNavItemModule } from './nav-item/drawer-nav-item.module';

/**
 * DrawerBodyModule
 */
@NgModule({
    declarations: [DrawerBodyComponent],
    imports: [
        UtilsModule,
        UiDirectivesModule,
        UiInfoListItemModule,
        DrawerNavGroupModule,
        DrawerNavItemModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: [DrawerBodyComponent, DrawerNavGroupComponent, DrawerNavGroupModule, DrawerNavItemModule],
})
export class DrawerBodyModule {}
