/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { SurgeryPrescription } from '@mona/models';

export namespace SurgeryPrescriptionAction {
    export const loadSurgeryPrescription = createAction('ENCOUNTER:LOAD_SURGERY_PRESCRIPTION');
    export const loadSurgeryPrescriptionSuccess = createAction(
        'ENCOUNTER:LOAD_SURGERY_PRESCRIPTION_SUCCESS',
        props<{ data: SurgeryPrescription[] }>(),
    );
    export const loadSurgeryPrescriptionFailure = createAction(
        'ENCOUNTER:LOAD_SURGERY_PRESCRIPTION_FAILURE',
        props<{ error: any }>(),
    );

    export const updateSurgeryPrescription = createAction(
        'ENCOUNTER:UPDATE_SURGERY_PRESCRIPTION',
        props<{ update: Update<SurgeryPrescription>[] }>(),
    );

    export const upsertSurgeryPrescription = createAction(
        'ENCOUNTER:UPSERT_SURGERY_PRESCRIPTION',
        props<{ data: SurgeryPrescription[] }>(),
    );

    export const clearSurgeryPrescription = createAction('ENCOUNTER:CLEAR_SURGERY_PRESCRIPTION');

    export const addSurgeryPrescriptionChanges = createAction(
        'ENCOUNTER:ADD_CHANGES',
        props<{ toUpdateEntities: SurgeryPrescription[]; toRemoveIds: string[] }>(),
    );
}
