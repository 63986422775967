<form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [isEnabled]="isBedSideMode && isActive"
    (ngSubmit)="keyboardEnterClicked($event)"
>
    <div class="app-infection-status-form__row">
        <mat-form-field
            class="app-input-field app-infection-status-form__field"
            appearance="fill"
            data-testid="ff-infectionName"
        >
            <mat-label>{{ 'apps.admission.infectionStatus.name.textLabel' | translate }}</mat-label>
            <input
                [formControl]="formGroup.controls.infectionName"
                required
                matInput
                data-testid="ff-infectionStatusNameTextarea"
            />
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.infectionName"></ui-validation-message>
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="app-input-field app-infection-status-form__field"
            appearance="fill"
            data-testid="ff-infectionStatusText"
        >
            <mat-label>{{ 'apps.admission.infectionStatus.status.textLabel' | translate }}</mat-label>
            <mat-select
                [formControl]="formGroup.controls.infectionStatus"
                required
                panelClass="app-input-select-panel scrollbar-visible"
                data-testid="ff-infectionStatusTextarea"
            >
                <mat-option *ngFor="let option of infectionStates" [value]="option">
                    <div class="autocomplete-option">{{ option }}</div>
                </mat-option>
            </mat-select>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.infectionStatus"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="app-infection-status-form__row app-infection-status-form__row-single">
        <mat-form-field
            class="app-input-field app-infection-status-form__field"
            appearance="fill"
            data-testid="ff-infectionStatusAnnotation"
        >
            <mat-label>{{ 'apps.admission.infectionStatus.annotation.textLabel' | translate }}</mat-label>
            <textarea
                [formControl]="formGroup.controls.infectionAnnotation"
                matInput
                [cdkAutosizeMaxRows]="textareaMaxRows"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize
                data-testid="ff-infectionStatusAnnotationTextarea"
            ></textarea>
            <mat-error>
                <ui-validation-message [control]="formGroup.controls.infectionAnnotation"></ui-validation-message>
            </mat-error>
        </mat-form-field>
    </div>
    <ng-content></ng-content>
</form>
