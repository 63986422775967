import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, Provider, Self, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServerUrlDialogModule, DiagnosticsModule } from './components';
import { apiFeatureKey } from './entities';
import { CustomHeaderInterceptor, HttpErrorInterceptor, LanguageInterceptor } from './services';
import { ApiEffects, apiReducer, API_HEALTH_REDUCER_TOKEN } from './state';
import { CUSTOM_INTERCEPTOR_HEADERS } from './tokens';

const PROVIDERS: Provider[] = [
    //
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LanguageInterceptor,
        multi: true,
    },
    {
        provide: CUSTOM_INTERCEPTOR_HEADERS,
        useValue: {},
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CustomHeaderInterceptor,
        multi: true,
    },
    {
        provide: API_HEALTH_REDUCER_TOKEN,
        useFactory: () => apiReducer,
    },
];

const UI_MODULES = [ServerUrlDialogModule, DiagnosticsModule];
/**
 * ApiModule
 */
@NgModule({
    imports: [
        HttpClientModule,
        StoreModule.forFeature(apiFeatureKey, API_HEALTH_REDUCER_TOKEN),
        EffectsModule.forFeature([ApiEffects]),
        ...UI_MODULES,
    ],
    exports: [HttpClientModule, ...UI_MODULES],
})
export class ApiModule {
    /** forRoot(): ModuleWithProviders<ApiModule> */
    static forRoot(): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: PROVIDERS,
        };
    }
    /**
     * Constructor
     *
     * @param parentModule
     * @param storemodule
     */
    constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() @Self() storemodule: StoreModule) {
        if (parentModule) {
            throw new Error('ApiModule already loaded; import in root module only.');
        }
        if (!storemodule) {
            // throw new Error('Storemodule should be loaded before');
        }
    }
}
