import { ApiModels } from '@mona/api';
import { MedicationSolution } from '@mona/models';

/**
 * Transforms api medication
 * @param apiMedicationSolution Medication
 */
export const transformApiMedicationSolution = (
    apiMedicationSolution: ApiModels.MedicationSolution,
): MedicationSolution => {
    return {
        id: apiMedicationSolution.code,
        code: apiMedicationSolution.code,
        displayName: apiMedicationSolution.display_name,
        snomedCode: apiMedicationSolution.snomed_code,
    };
};

/**
 * Transforms api medication
 * @param medication Medication
 */
export const transformToApiMedicationSolution = (medication: MedicationSolution): ApiModels.MedicationSolution => {
    return {
        code: medication.code,
        display_name: medication.displayName,
        snomed_code: medication.snomedCode,
    };
};

/**
 * Transform api medication
 * @param apiMedicationSolutions ApiModels.Medication[]
 */
export const transformApiMedicationSolutions = (
    apiMedicationSolutions: ApiModels.MedicationSolution[],
): MedicationSolution[] => {
    return apiMedicationSolutions.map(apiMedicationSolution => transformApiMedicationSolution(apiMedicationSolution));
};
