import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMessages from '../reducers/message.reducer';
import { MessagesState } from '../state';

export const selectMessagesState = createFeatureSelector<MessagesState>(fromMessages.messagesFeatureKey);

export const selectMessagesIds = createSelector(selectMessagesState, fromMessages.selectIds as (state) => string[]);

export const selectMessagesEntities = createSelector(selectMessagesState, fromMessages.selectEntities);

export const selectAllMessages = createSelector(selectMessagesState, fromMessages.selectAll);

export const selectMessagesTotal = createSelector(selectMessagesState, fromMessages.selectTotal);
