import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule, Provider, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaskModule } from 'ngx-mask';
import { KeyboardModule } from '@mona/keyboard';
import { UtilsModule } from '@mona/shared/utils';
import { UiTemplateRefModule } from '../utils';
import {
    UiAutocompleteModule,
    UiDatePickerModule,
    UiDateTimePickerModule,
    UiDynamicCheckboxComponent,
    UiDynamicDatepickerComponent,
    UiDynamicDateTimePickerComponent,
    UiDynamicElementComponent,
    UiDynamicElementDirective,
    UiDynamicFormsErrorTemplateDirective,
    UiDynamicInputComponent,
    UiDynamicListComponent,
    UiDynamicRadioComponent,
    UiDynamicAutocompleteComponent,
    UiDynamicSelectComponent,
    UiDynamicSliderComponent,
    UiDynamicTextareaComponent,
    UiFormValidationMessageComponent,
    UiDynamicButtonComponent,
} from './components';
import { UiDynamicFormDialogComponent, UiDynamicFormsComponent } from './containers';
import {
    MatInputCommaDotDirective,
    UiContenteditableDirective,
    UiFormElementInjectorDirective,
    UiFormRestrictInputDirective,
    UiKeyboardSpacingDirective,
    UiValidatorDirective,
} from './directives';
import { DYNAMIC_FORMS_PROVIDER } from './services';

const UI_DYNAMIC_FORMS: Type<any>[] = [
    UiDynamicFormsComponent,
    UiDynamicElementComponent,
    UiDynamicFormDialogComponent,
    UiFormValidationMessageComponent,
    UiDynamicElementDirective,
    UiDynamicFormsErrorTemplateDirective,
    UiFormElementInjectorDirective,
    UiValidatorDirective,
    UiFormRestrictInputDirective,
    MatInputCommaDotDirective,
    UiContenteditableDirective,
    UiKeyboardSpacingDirective,
];

const UI_DYNAMIC_FORMS_ENTRY_COMPONENTS: Type<any>[] = [
    UiDynamicButtonComponent,
    UiDynamicCheckboxComponent,
    UiDynamicDatepickerComponent,
    UiDynamicDateTimePickerComponent,
    UiDynamicInputComponent,
    UiDynamicListComponent,
    UiDynamicRadioComponent,
    UiDynamicSelectComponent,
    UiDynamicSliderComponent,
    UiDynamicTextareaComponent,
    UiDynamicAutocompleteComponent,
];

const MATERIAL_MODULES = [
    A11yModule,
    PortalModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
];

const MODULES = [UiDatePickerModule, UiDateTimePickerModule, UiAutocompleteModule];

export const UI_FORMS_PROVIDERS: Provider[] = [
    DYNAMIC_FORMS_PROVIDER,
    {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
            appearance: 'fill',
        },
    },
];

/**
 * Ui Forms Module
 */
@NgModule({
    declarations: [UI_DYNAMIC_FORMS, UI_DYNAMIC_FORMS_ENTRY_COMPONENTS],
    imports: [
        //
        ...MATERIAL_MODULES,
        UtilsModule,
        NgxMaskModule.forChild(),
        KeyboardModule,
        UiTemplateRefModule,
        ...MODULES,
    ],
    exports: [UI_DYNAMIC_FORMS, UI_DYNAMIC_FORMS_ENTRY_COMPONENTS, MODULES, NgxMaskModule],
    providers: [...UI_FORMS_PROVIDERS],
})
export class UiFormsModule {}
