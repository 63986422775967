import { EventEmitter, Injectable } from '@angular/core';

/**
 * Picture in picture dialog service
 */
@Injectable({
    providedIn: 'root',
})
export class PipDialogService {
    /**
     * Video Stream
     */
    setRemoteStream = new EventEmitter<MediaStream>();

    /**
     * User has hung up
     */
    hungUp = new EventEmitter();

    /**
     * Toggle Microphone
     */
    toggleMic = new EventEmitter();

    /**
     * Toggle camera
     */
    toggleCamera = new EventEmitter();

    /**
     * Remote video toggled
     */
    remoteVideoToggled = new EventEmitter<boolean>();

    /**
     * Call partner status change
     */
    hasCallPartnerChange = new EventEmitter<boolean>();

    /**
     * Is Screen Capture change
     */
    isScreenCaptureChange = new EventEmitter<boolean>();

    /**
     * Start screen capture
     */
    startScreenCapture = new EventEmitter();

    /**
     * End screen capture
     */
    stopScreenCapture = new EventEmitter();
}
