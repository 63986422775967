<img [alt]="data.imgSrc" class="mona-dialog__illustration" [src]="imgSrc" />
<mat-dialog-content class="mona-dialog__content">
    <button
        class="mona-dialog__close"
        mat-dialog-close
        mat-icon-button
        type="button"
        *ngIf="!dialogRef.disableClose || data.enableClose"
    >
        <mat-icon>close</mat-icon>
    </button>
    <div>
        <h2 class="mona-dialog__title" mat-dialog-title>
            {{ data.title | translate }}
        </h2>
        <p class="mona-dialog__description" [innerHTML]="data.description | translate | safeHtml">
            <br />
            <br />
            {{ data.additionalDescription | translate }}
        </p>
    </div>

    <div class="mona-dialog__actions">
        <button color="primary" mat-button mat-dialog-close *ngIf="data.cancelBtn">
            {{ data.cancelBtn | translate }}
        </button>
        <button [mat-dialog-close]="true" cdkFocusInitial color="primary" mat-raised-button>
            {{ data.confirmBtn | translate }}
        </button>
    </div>
</mat-dialog-content>
