import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TherapyLimitations } from '@mona/models';
import { TherapyLimitationForm } from '../../models';

/**
 * TherapyLimitationDialogComponent dialog
 */
@Component({
    selector: 'app-therapy-limitation-dialog',
    templateUrl: './therapy-limitation-dialog.component.html',
    styleUrls: ['./therapy-limitation-dialog.component.scss'],
})
export class TherapyLimitationDialogComponent {
    /**
     * therapyLimitation form
     */
    therapyLimitationForm = new FormGroup<TherapyLimitationForm>({
        documented: new FormControl<string>('', Validators.required),
        rejectedActions: new FormControl<string>('', Validators.required),
    });

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<TherapyLimitationDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<TherapyLimitationDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            therapyLimitation: TherapyLimitations;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-therapy-limitation-dialog');

        if (data.therapyLimitation.id) {
            this.therapyLimitationForm.patchValue(data.therapyLimitation);
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.therapyLimitationForm.markAsTouched();

        if (this.therapyLimitationForm.valid) {
            this.dialogRef.close({
                id: this.data.therapyLimitation.id,
                documented: this.therapyLimitationForm.controls.documented.value,
                rejectedActions: this.therapyLimitationForm.controls.rejectedActions.value,
            } as TherapyLimitations);
        }
    }
}
