import { InjectionToken } from '@angular/core';
import { Action, ActionReducerMap, combineReducers, createReducer, on } from '@ngrx/store';
import { CallSession } from '@mona/models';
import { makeAsyncActionReducer, SucceededAction } from '@mona/store';
import { CallState } from '../../entities';
import { CallActions } from '../actions';

/**
 * The initial state
 */
export const initialCallSessionState: CallSession = null;

/**
 * Reduces session creating
 *
 * @param state CallSession
 * @param action SucceededAction<CallSession>
 */
export const reduceSessionLoaded = (state: CallSession, action: SucceededAction<CallSession>) => {
    return action.payload;
};

/**
 * Reduces session clear
 */
export const reduceSessionCleared = () => {
    return initialCallSessionState;
};

/**
 * Call session reducer
 */
export const callSessionReducer = createReducer(
    initialCallSessionState,

    on(CallActions.loadSession.succeededAction, reduceSessionLoaded),

    on(CallActions.createSession.succeededAction, reduceSessionLoaded),

    on(CallActions.loadSession.clearAction, reduceSessionCleared),

    on(CallActions.createSession.clearAction, reduceSessionCleared),
);

/**
 * Call reducer map
 */
const callReducerMap: ActionReducerMap<CallState> = {
    loadCallSessionAction: makeAsyncActionReducer(CallActions.loadSession),
    createCallSessionAction: makeAsyncActionReducer(CallActions.createSession),
    session: callSessionReducer,
} as any;
/**
 * Call reducer map
 */
export const callFeatureReducer = combineReducers(callReducerMap);

/**
 * Call feature reducer token
 */
export const CALL_FEATURE_REDUCER = new InjectionToken<ActionReducerMap<CallState>>('CALL_FEATURE_REDUCER');
