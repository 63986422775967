import { ApiModels } from '@mona/api';
import { BaseProcedurePrescription } from '@mona/models';

/**
 * Transforms api prescription set procedure
 *
 * @param apiProcedure ApiModels.PrescriptionSetProcedure
 */
export const transformApiPrescriptionSetProcedure = (
    apiProcedure: ApiModels.PrescriptionSetProcedure,
): BaseProcedurePrescription => {
    return {
        id: apiProcedure.id,
        frequency: apiProcedure.frequency,
        frequencyTimes: apiProcedure.frequency_times,
        category: apiProcedure.category,
        description: apiProcedure.description,
        instructions: apiProcedure.instructions,
        prescriptionSetId: apiProcedure.prescription_set_id,
    };
};

/**
 * Transform api prescription set procedures
 *
 * @param apiProcedures ApiModels.PrescriptionSetProcedure[]
 */
export const transformApiPrescriptionSetProcedures = (
    apiProcedures: ApiModels.PrescriptionSetProcedure[],
): BaseProcedurePrescription[] => {
    return apiProcedures.map(apiProcedure => transformApiPrescriptionSetProcedure(apiProcedure));
};

/**
 * Transforms to api prescription set procedure
 *
 * @param procedure PrescriptionSetProcedure
 * @param setId string
 */
export const transformToApiPrescriptionSetProcedure = (
    procedure: BaseProcedurePrescription,
    setId?: string,
): ApiModels.PrescriptionSetProcedure => {
    const apiProcedure: ApiModels.PrescriptionSetProcedure = {
        id: procedure.id as string,
        frequency: procedure.frequency,
        frequency_times: procedure.frequencyTimes,
        category: procedure.category,
        description: procedure.description,
        prescription_set_id: setId as string,
        instructions: procedure.instructions,
    };

    return apiProcedure;
};
