import { ApiModels } from '@mona/api';
import { PrescriptionFrequency } from '@mona/models';

/**
 * Transforms api prescription frequency
 *
 * @param apiPrescriptionFrequency PrescriptionFrequency
 */
export const transformApiPrescriptionFrequency = (
    apiPrescriptionFrequency: ApiModels.PrescriptionFrequency,
): PrescriptionFrequency => {
    const model = ApiModels.PrescriptionFrequency.DTO.toModel(apiPrescriptionFrequency) as PrescriptionFrequency;
    model.id = model.code;
    return model;
};

/**
 * Transform api prescription frequencies
 *
 * @param apiPrescriptionFrequencies ApiModels.PrescriptionFrequency[]
 */
export const transformApiPrescriptionFrequencies = (
    apiPrescriptionFrequencies: ApiModels.PrescriptionFrequency[],
): PrescriptionFrequency[] => {
    return [
        ...apiPrescriptionFrequencies.map(apiPrescriptionFrequency =>
            transformApiPrescriptionFrequency(apiPrescriptionFrequency),
        ),
    ];
};
