import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { startOfDay } from 'date-fns';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, pluck, shareReplay, tap } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import {
    Allergy,
    Anamnesis,
    ChangeLogEntry,
    Diagnosis,
    DiagnosisRole,
    Encounter,
    EncounterHistoryEntry,
    HistoryEntryType,
    Patient,
    PhysicalExamination,
    PreMedication,
} from '@mona/models';
import { applyInstanceChanges, applyInstancesChanges, ChangeLogService } from '@mona/pdms/data-access-changelog';
import { getEncounterViewDateRange } from '@mona/pdms/data-access-combined';
import { EncountersSelectors } from '@mona/pdms/data-access-encounters';
import { notEmpty } from '@mona/shared/utils';
import { AsyncActionState, selectRouteParam } from '@mona/store';
import { encounterDataFeatureKey, EncounterDataState } from '../entities';
import {
    AllergiesAction,
    AnamnesisAction,
    DiagnosesAction,
    EncounterAction,
    PhysicalExaminationAction,
    PreMedicationsAction,
} from '../state';

/**
 * Get configured keyboard status
 *
 * @param encounterService
 */
export function disableWhenEncounterReviewModeFn(encounterService: EncounterService): Observable<boolean> {
    const permissionsService = inject(NgxPermissionsService);

    return encounterService
        .getEncounterIsReviewMode()
        .pipe(map(isReview => isReview && !permissionsService.getPermission('review_mode_edit')));
}

/**
 * Encounter service
 */
@Injectable({ providedIn: 'root' })
export class EncounterService {
    encounterBase = '/pdms/fhir/Encounter';
    /**
     * Get current encounter id static
     */
    currentEncounterId: string = undefined;
    /**
     * Get current encounter id as Obs
     */
    encounterId$: Observable<string> = this.store.select<string>(selectRouteParam('encounterId')).pipe(
        distinctUntilChanged(),
        notEmpty(),
        tap(encounterId => (this.currentEncounterId = encounterId)),
    );
    /**
     * Get current encounter startDate
     */
    startDate$ = this.store.select(EncountersSelectors.selectSelectedEncounter).pipe(
        map(({ startDate } = {} as any) => {
            return startOfDay(startDate || new Date());
        }),
    );

    encounterViewDateRange$ = getEncounterViewDateRange();

    /**
     * Constructor
     *
     * @param store store
     * @param changeLogService ChangeLogService
     * @param http
     */
    constructor(
        private store: Store<EncounterDataState>,
        private changeLogService: ChangeLogService,
        private http: HttpService,
    ) {}

    //#region Selectors

    /**
     * Get load anamnesis async action
     */
    getLoadAnamnesisAction(): Observable<AsyncActionState<Anamnesis[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].anamnesis.entities.loadAction,
        );
    }

    /**
     * Get load allergies async action
     */
    getLoadAllergiesAction(): Observable<AsyncActionState<Allergy[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].allergies.entities.loadAction,
        );
    }

    /**
     * Get load diagnoses async action
     */
    getLoadDiagnosesAction(): Observable<AsyncActionState<Diagnosis[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].diagnoses.entities.loadAction,
        );
    }

    /**
     * Get load premedication async action
     */
    getLoadPreMedicationAction(): Observable<AsyncActionState<PreMedication[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].preMedications.entities.loadAction,
        );
    }

    /**
     * Get load physical examination async action
     */
    getLoadPhysicalExaminationAction(): Observable<AsyncActionState<PhysicalExamination[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].physicalExamination.entities.loadAction,
        );
    }

    /**
     * Get load encounter history async action
     */
    getLoadEncounterHistoryAction(): Observable<AsyncActionState<EncounterHistoryEntry[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].encounterHistory.loadAction,
        );
    }

    /**
     * Get loaded encounter
     */
    getEncounter(): Observable<Encounter> {
        return this.store.select(EncountersSelectors.selectSelectedEncounter).pipe(notEmpty(), distinctUntilChanged());
    }

    /**
     * Get loaded encounter history
     */
    getEncounterHistory(): Observable<EncounterHistoryEntry[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].encounterHistory.rawEntities,
        );
    }

    /**
     * Get encounter history with staged changes
     */
    getEncounterHistoryWithChanges(): Observable<EncounterHistoryEntry[]> {
        return combineLatest([
            this.getEncounterHistory(),
            this.changeLogService.getModelChanges('PatientHistory'),
        ]).pipe(
            map(([history, changes]: [EncounterHistoryEntry[], ChangeLogEntry<EncounterHistoryEntry>[]]) =>
                applyInstancesChanges<EncounterHistoryEntry>(history, changes, true),
            ),
            // NOTE: for better performance there is no one more sorting, but by inserting into beginning order should be correct
            // The only edge case when order can be slightly incorrect is when later CREATE is applied and earlier is failed to apply after persist changes request)
            // Can be discussed
        );
    }

    /**
     * Get loaded encounter history filter
     */
    getEncounterHistoryFilter(): Observable<HistoryEntryType> {
        return this.store.select((state: EncounterDataState) => state[encounterDataFeatureKey].encounterHistoryFilter);
    }

    /**
     * Get loaded encounter history filter
     */
    getEncounterIsReviewMode(): Observable<boolean> {
        return this.store.select((state: EncounterDataState) => state[encounterDataFeatureKey].isReviewMode);
    }

    /**
     * Get filtered encounter history
     */
    getFilteredEncounterHistory(): Observable<EncounterHistoryEntry[]> {
        return combineLatest([this.getEncounterHistoryFilter(), this.getEncounterHistoryWithChanges()]).pipe(
            map(([historyFilter, history]) =>
                historyFilter ? history.filter(item => item.type === historyFilter) : history,
            ),
        );
    }

    /**
     * Get encounter with changes
     */
    getEncounterWithChanges(): Observable<Encounter> {
        return combineLatest([
            this.getEncounter(),
            this.changeLogService.getModelChanges('Patient'),
            this.changeLogService.getModelChanges('Encounter'),
            this.changeLogService.getModelChanges('VitalSign'),
        ]).pipe(
            map(([encounter, patientChanges, encounterChanges, vitalChanges]) => {
                // TODO: improve to not executes multiple times
                const encounterWithChanges = {
                    ...applyInstanceChanges<Encounter>(encounter, encounterChanges),
                    patient: {
                        ...applyInstanceChanges<Patient>(encounter.patient, patientChanges),
                        staysList: encounter.staysList.filter(sl => !sl.active),
                    },
                    vitalSigns: [
                        ...applyInstancesChanges<ApiModels.VitalSign.Model>(encounter.vitalSigns, vitalChanges),
                    ],
                };
                return encounterWithChanges;
            }),
            shareReplay(1),
        );
    }

    /**
     * Get physical examination
     */
    getPhysicalExamination(): Observable<PhysicalExamination[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].physicalExamination.entities.rawEntities,
        );
    }

    /**
     * Get physical examination with changes
     */
    getPhysicalExaminationWithChanges(): Observable<PhysicalExamination> {
        return combineLatest([
            this.getPhysicalExamination(),
            this.changeLogService.getModelChanges('PhysicalExamination'),
        ]).pipe(
            map(([physicalExamination, changes]) =>
                applyInstancesChanges<PhysicalExamination>(physicalExamination, changes),
            ),
            map((physicalExamination: PhysicalExamination[]) => physicalExamination?.[0]),
        );
    }

    /**
     * Get anamnesis with changes
     */
    getAnamnesisWithChanges(): Observable<Anamnesis> {
        return combineLatest([this.getAnamnesis(), this.changeLogService.getModelChanges('Anamnesis')]).pipe(
            map(([anamnesis, changes]) => applyInstancesChanges<Anamnesis>(anamnesis, changes)),
            map((anamnesis: Anamnesis[]) => anamnesis?.[0]),
        );
    }

    /**
     * Get anamnesis
     */
    getAnamnesis(): Observable<Anamnesis[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].anamnesis.entities.rawEntities,
        );
    }

    /**
     * Get allergies with changes
     *
     */
    getAllergiesWithChanges(): Observable<Allergy[]> {
        return combineLatest([this.getAllergies(), this.changeLogService.getModelChanges('AllergyIntolerance')]).pipe(
            map(([allergies, changes]) => applyInstancesChanges<Allergy>(allergies, changes)),
        );
    }

    /**
     * Get diagnoses with change
     */
    // tslint:disable-next-line: completed-docs
    getDiagnosesWithChanges(): Observable<{ current: Diagnosis[]; progress: Diagnosis[] }> {
        return combineLatest([this.getDiagnoses(), this.changeLogService.getModelChanges('Diagnosis')]).pipe(
            map(([diagnoses, changes]) => applyInstancesChanges<Diagnosis>(diagnoses, changes)),
            map(changeDiagnoses =>
                changeDiagnoses?.reduce(
                    (
                        // tslint:disable-next-line: completed-docs
                        accumulator: { current: Diagnosis[]; progress: Diagnosis[] },
                        diagnosis: Diagnosis,
                    ) => {
                        if (diagnosis.role === DiagnosisRole.AD) {
                            accumulator.current.push(diagnosis);
                        } else {
                            accumulator.progress.push(diagnosis);
                        }

                        return accumulator;
                    },
                    {
                        current: [],
                        progress: [],
                    },
                ),
            ),
        );
    }

    /**
     * Get pre medications with change
     */
    getPreMedicationsWithChanges(): Observable<PreMedication[]> {
        return combineLatest([this.getPreMedications(), this.changeLogService.getModelChanges('PreMedication')]).pipe(
            map(([preMedications, changes]) => applyInstancesChanges<PreMedication>(preMedications, changes)),
        );
    }

    /**
     * Get allergies
     *
     */
    getAllergies(): Observable<Allergy[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].allergies.entities.rawEntities,
        );
    }

    /**
     * Get diagnoses
     */
    getDiagnoses(): Observable<Diagnosis[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].diagnoses.entities.rawEntities,
        );
    }

    /**
     * Get diagnoses map
     */
    getDiagnosesMap(): Observable<EntityMap<Diagnosis>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].diagnoses.entities.entities,
        );
    }

    /**
     * Get pre medications
     */
    getPreMedications(): Observable<PreMedication[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].preMedications.entities.rawEntities,
        );
    }

    /**
     * Get pre medications map
     */
    getPreMedicationsMap(): Observable<EntityMap<PreMedication>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].preMedications.entities.entities,
        );
    }

    //#endregion Selectors

    //#region Actions

    /**
     * Triggers encounter history loading
     *
     * @param encounterId EntityId<Encounter>
     */
    loadEncounterHistory(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(EncounterAction.loadEncounterHistoryAction.action({ id: encounterId }));
    }

    /**
     * Set encounter history filter
     *
     * @param historyType HistoryEntryType
     */
    setEncounterHistoryFilter(historyType: HistoryEntryType | null): void {
        this.store.dispatch(EncounterAction.setHistoryFilterAction({ historyType }));
    }

    /**
     * Load anamnesis
     *
     * @param encounterId EntityId<Encounter>
     */
    loadAnamnesis(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(AnamnesisAction.loadAnamnesisAction.action({ encounterId }));
    }

    /**
     * Loads allergies
     *
     * @param encounterId EntityId<Encounter>
     */
    loadAllergies(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(AllergiesAction.loadAllergiesAction.action({ encounterId }));
    }

    /**
     * Load diagnoses
     *
     * @param encounterId EntityId<Encounter>
     */
    loadDiagnoses(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(DiagnosesAction.loadDiagnosesAction.action({ encounterId }));
    }

    /**
     * Load pre medications
     *
     * @param encounterId EntityId<Encounter>
     */
    loadPreMedications(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(PreMedicationsAction.loadPreMedicationsAction.action({ encounterId }));
    }

    /**
     * Load physical examination
     *
     * @param encounterId EntityId<Encounter>
     */
    loadPhysicalExamination(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(PhysicalExaminationAction.loadPhysicalExaminationAction.action({ encounterId }));
    }

    /**
     * Clears encounter history
     */
    clearLoadEncounterHistoryAction(): void {
        this.store.dispatch(EncounterAction.loadEncounterHistoryAction.clearAction());
    }

    /**
     * Set encounter review mode true
     */
    setEncounterReviewModeAction(): void {
        this.store.dispatch(EncounterAction.setEncounterReviewMode());
    }

    /**
     * Set encounter review mode false
     */
    unsetEncounterReviewModeAction(): void {
        this.store.dispatch(EncounterAction.unsetEncounterReviewMode());
    }

    /**
     * Clears anamnesis
     */
    clearLoadAnamnesisAction(): void {
        this.store.dispatch(AnamnesisAction.loadAnamnesisAction.clearAction());
    }

    /**
     * Clears allergies
     */
    clearLoadAllergiesAction(): void {
        this.store.dispatch(AllergiesAction.loadAllergiesAction.clearAction());
    }

    /**
     * Clears diagnoses
     */
    clearLoadDiagnosesAction(): void {
        this.store.dispatch(DiagnosesAction.loadDiagnosesAction.clearAction());
    }

    /**
     * Clears pre medications
     */
    clearLoadPreMedicationsAction(): void {
        this.store.dispatch(PreMedicationsAction.loadPreMedicationsAction.clearAction());
    }

    /**
     * Clears physical examination
     */
    clearLoadPhysicalExaminationAction(): void {
        this.store.dispatch(PhysicalExaminationAction.loadPhysicalExaminationAction.clearAction());
    }
    /**
     * INFO: add comment
     * @param encounterId
     */
    getPatientIdFromEncounter(encounterId: string): Observable<string | null> {
        return this.http
            .get<any>(`${this.encounterBase}/${encounterId}`)
            .pipe(map(encounter => encounter?.subject?.reference?.split('/').pop() || null));
    }

    //#endregion Actions
}
