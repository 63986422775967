import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MedicationPrescription } from '@mona/models';
import { MedicationsPrescriptionState } from '../../entities';
import {
    addMedicationPrescription,
    addMedicationPrescriptionChanges,
    addMedicationPrescriptions,
    clearMedicationPrescriptions,
    deleteMedicationPrescription,
    deleteMedicationPrescriptions,
    loadMedicationPrescriptionsFailure,
    updateMedicationPrescription,
    updateMedicationPrescriptions,
    upsertMedicationPrescription,
    upsertMedicationPrescriptions,
} from '../actions';

export const medicationPrescriptionAdapter: EntityAdapter<MedicationPrescription> =
    createEntityAdapter<MedicationPrescription>();

export const initialState: MedicationsPrescriptionState = medicationPrescriptionAdapter.getInitialState({
    error: null,
});

export const medicationPrescriptionReducer = createReducer(
    initialState,
    // ENTITY ADAPTER ACTIONS
    on(addMedicationPrescription, (state, action) =>
        medicationPrescriptionAdapter.addOne(action.medicationPrescription, state),
    ),
    on(upsertMedicationPrescription, (state, action) =>
        medicationPrescriptionAdapter.upsertOne(action.medicationPrescription, state),
    ),
    on(addMedicationPrescriptions, (state, action) =>
        medicationPrescriptionAdapter.addMany(action.medicationPrescriptions, state),
    ),
    on(upsertMedicationPrescriptions, (state, action) =>
        medicationPrescriptionAdapter.upsertMany(action.medicationPrescriptions, state),
    ),
    on(updateMedicationPrescription, (state, action) =>
        medicationPrescriptionAdapter.updateOne(action.medicationPrescription, state),
    ),
    on(updateMedicationPrescriptions, (state, action) =>
        medicationPrescriptionAdapter.updateMany(action.medicationPrescriptions, state),
    ),
    on(deleteMedicationPrescription, (state, action) => medicationPrescriptionAdapter.removeOne(action.id, state)),
    on(deleteMedicationPrescriptions, (state, action) => medicationPrescriptionAdapter.removeMany(action.ids, state)),
    on(loadMedicationPrescriptionsFailure, (state, { error }) => ({ ...state, error })),
    on(clearMedicationPrescriptions, state => medicationPrescriptionAdapter.removeAll(state)),
    on(addMedicationPrescriptionChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = medicationPrescriptionAdapter.removeMany(toRemoveIds, state);

        return medicationPrescriptionAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
