import { createReducer, on } from '@ngrx/store';
import { RegisterDeviceResult } from '@mona/models';
import { SucceededAction } from '@mona/store';
import { TelemedicineSetupAction } from '../actions/telemedicine-setup.action';

/**
 * The initial state
 */
export const telemedicineRegisterDeviceResultInitialState: RegisterDeviceResult = {
    deviceMac: null,
    isActivated: null,
};

/**
 * Reducer for register device succeeded action
 *
 * @param state state
 * @param action action
 */
export const reduceTelemedicineRegisterDeviceSucceeded = (
    state: RegisterDeviceResult,
    action: SucceededAction<RegisterDeviceResult>,
) => action.payload;

/**
 * Reducer for check is device activated succeeded action
 *
 * @param state state
 * @param action action
 */
export const reduceTelemedicineCheckIsDeviceActivatedSucceeded = (
    state: RegisterDeviceResult,
    action: SucceededAction<RegisterDeviceResult>,
) => action.payload;

/**
 * Reducer for register device data clear action
 */
export const reduceTelemedicineRegisterDeviceClear = () => telemedicineRegisterDeviceResultInitialState;

/**
 * Telemedicine register device result reducer
 */
export const telemedicineRegisterDeviceResultReducer = createReducer(
    telemedicineRegisterDeviceResultInitialState,

    on(TelemedicineSetupAction.registerDevice.succeededAction, reduceTelemedicineRegisterDeviceSucceeded),

    on(
        TelemedicineSetupAction.checkIsDeviceActivated.succeededAction,
        reduceTelemedicineCheckIsDeviceActivatedSucceeded,
    ),

    on(TelemedicineSetupAction.registerDevice.clearAction, reduceTelemedicineRegisterDeviceClear),
);
