import { NgModule, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsModule } from '@mona/shared/utils';
import { UiSpacerModule } from '../utils';
import {
    UiAlertsComponent,
    UiAlertsPagerComponent,
    UiMessageComponent,
    UiMessageContainerDirective,
    UiSnackbarComponent,
} from './components';

const COMPONENTS: Type<any>[] = [
    UiAlertsComponent,
    UiAlertsPagerComponent,
    UiSnackbarComponent,
    UiMessageContainerDirective,
    UiMessageComponent,
];

/**
 * Ui messages module
 */
@NgModule({
    imports: [
        UtilsModule,
        UiSpacerModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
})
export class UiMessageModule {}
