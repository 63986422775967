import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomApiModels, HttpService, IS_TELEMEDICINE_CONTEXT } from '@mona/api';
import { CallConfig } from '@mona/models';
import { transformApiTelemedicineConfig } from './transforms/telemedicine-config.transforms';

/**
 * API Service for the telemedicine config API
 */
@Injectable({
    providedIn: 'root',
})
export class TelemedicineConfigApi {
    apiBase = '/telemedicine/api/config/';
    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get stun & turn config
     */
    getCallConfig(): Observable<CallConfig> {
        const context = new HttpContext().set(IS_TELEMEDICINE_CONTEXT, true);
        return this.http
            .get<CustomApiModels.CallConfig>(`${this.apiBase}`, { context })
            .pipe(map(apiConfig => transformApiTelemedicineConfig(apiConfig)));
    }
}
