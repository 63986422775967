import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

/**
 * UiDynamicInputComponent
 */
@Component({
    selector: 'ui-dynamic-input',
    styleUrls: ['./dynamic-input.component.scss'],
    templateUrl: './dynamic-input.component.html',
})
export class UiDynamicInputComponent {
    @ViewChild(MatInput, { read: ElementRef, static: true }) elementRef: ElementRef;

    control: UntypedFormControl = new UntypedFormControl();

    appearance: MatFormFieldAppearance;

    label = '';

    floatLabel = undefined;

    valueKey = undefined;

    hint = '';

    type: string = undefined;

    required: boolean = undefined;

    name = '';

    min: number = undefined;

    max: number = undefined;

    minLength: number = undefined;

    maxLength: number = undefined;

    errorMessageTemplate: TemplateRef<any> = undefined;

    hideValidationMessage: boolean = undefined;

    hideRequiredMarker: boolean = undefined;

    placeholder = '';

    customClass = '';

    restrictPattern = '';
}
