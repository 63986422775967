import { Portal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Animations } from '../../../animations';
import { isEmptyView } from '../../../utils/utils';
import { DrawerService } from '../drawer.service';
import { StateListener } from '../state-listener.component';

/**
 * [DrawerHeader Component](https://brightlayer-ui-components.github.io/angular/?path=/info/components-drawer--readme)
 *
 * The `<ui-drawer-header>` contains the content found at the top of the `<ui-drawer>`.
 */
@Component({
    selector: 'ui-drawer-header',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    template: `
        <mat-toolbar
            class="ui-drawer-header-content"
            [class.rail]="isRail()"
            [class._d-none]="!show"
            [color]="color"
            [class.ui-drawer-header-no-icon]="isEmpty(iconEl)"
        >
            <ng-content select="[ui-title-buttons-left]"></ng-content>
            <ng-container
                [ngTemplateOutlet]="(customHeaderPortal$ | async) ? customTemplate : defaultTemplate"
            ></ng-container>
            <ng-template #customTemplate>
                <ng-container [cdkPortalOutlet]="customHeaderPortal$ | async"></ng-container>
            </ng-template>
            <ng-template #defaultTemplate>
                <div class="ui-drawer-header-background"></div>
                <div class="ui-drawer-header-text">
                    <div #icon class="ui-drawer-header-icon-wrapper">
                        <ng-content select="[ui-icon]"></ng-content>
                    </div>
                    <div *ngIf="title" class="ui-drawer-header-title-wrapper">
                        <div class="ui-drawer-header-title">{{ title | translate }}</div>
                        <div *ngIf="subtitle" class="ui-drawer-header-subtitle mat-subtitle-1">
                            {{ subtitle | translate }}
                        </div>
                    </div>
                    <ng-content select="[ui-title-content]"></ng-content>
                    <ng-content select="[ui-title-buttons-right]"></ng-content>
                </div>
            </ng-template>
        </mat-toolbar>
        <mat-divider *ngIf="divider"></mat-divider>
        <ng-content select=".ui-alert-message"></ng-content>
        <div class="ui-drawer-header-custom" id="ui-header-portal" *ngIf="isOpen()"></div>
    `,
    styleUrls: ['./drawer-header.component.scss'],
    host: {
        class: 'ui-drawer-header',
    },
    animations: [Animations.fadeInOut],
})
export class DrawerHeaderComponent extends StateListener {
    /**
     * Mat toolbar color variant
     *
     * @default primary
     */
    @Input() color: 'primary' | 'accent' | 'warn' | undefined = undefined;
    /**
     * Show a divider below footer
     *
     * @default false
     */
    @Input() divider = false;
    /** The text to show on the second line */
    @Input() subtitle: string;
    /** The text to show on the first line */
    @Input() title: string;
    /** Show or hide toolbar */
    @Input() show: boolean;

    @ViewChild('icon', { static: true }) iconEl: ElementRef;
    /** Custom header portal */
    customHeaderPortal$: Observable<Portal<any>> = this.drawerService.getDrawerPortal('customHeader');

    isEmpty = (el: ElementRef): boolean => isEmptyView(el);

    /**
     * INFO: add comment
     *
     * @param drawerService
     * @param cdRef
     */
    constructor(drawerService: DrawerService, cdRef: ChangeDetectorRef) {
        super(drawerService, cdRef);
    }

    /**
     * INFO: add comment
     */
    isRail(): boolean {
        return this.drawerService.getDrawerVariant() === 'rail';
    }
}
