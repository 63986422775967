import { EntityState } from '@ngrx/entity';
import { Patient } from '@mona/models';

export const patientsFeatureKey = 'patientsData';

/**
 * Patients State
 */
export interface PatientsState extends EntityState<Patient> {
    /**
     * selected id
     */
    selectedPatientId?: string | null;
    /**
     * error
     */
    error?: any;
    /**
     * loading
     */
    isLoading: boolean;
}
