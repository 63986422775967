// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating Diagnosis model structure.
 * @see #/components/schemas/Diagnosis - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Diagnosis {
    /**
     * Defines if the diagnosis was taken during admission or for other purposes.  * `AD` - AD * `DD` - DD * `CC` - CC * `CM` - CM * `pre-op` - PRE_OP * `post-op` - POST_OP * `billing` - BILLING
     *
     */
    role: Diagnosis.RoleEnum;
    /**
     * The code of the Diagnosis in ICD10.
     *
     */
    icd_10_code?: string;
    /**
     * Descriptive text of diagnosis.
     *
     */
    description: string;
    /**
     * The status of the Diagnosis.  * `unconfirmed` - UNCONFIRMED * `provisional` - PROVISIONAL * `differential` - DIFFERENTIAL * `confirmed` - CONFIRMED * `refuted` - REFUTED * `entered-in-error` - ERROR
     *
     */
    verification_status: Diagnosis.VerificationStatusEnum;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Diagnosis unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Diagnosis
 */
export namespace Diagnosis {
    export type RoleEnum = 'AD' | 'DD' | 'CC' | 'CM' | 'pre-op' | 'post-op' | 'billing';
    export const RoleEnum = {
        Ad: 'AD' as RoleEnum,
        Dd: 'DD' as RoleEnum,
        Cc: 'CC' as RoleEnum,
        Cm: 'CM' as RoleEnum,
        PreOp: 'pre-op' as RoleEnum,
        PostOp: 'post-op' as RoleEnum,
        Billing: 'billing' as RoleEnum,
    };
    export type VerificationStatusEnum =
        | 'unconfirmed'
        | 'provisional'
        | 'differential'
        | 'confirmed'
        | 'refuted'
        | 'entered-in-error';
    export const VerificationStatusEnum = {
        Unconfirmed: 'unconfirmed' as VerificationStatusEnum,
        Provisional: 'provisional' as VerificationStatusEnum,
        Differential: 'differential' as VerificationStatusEnum,
        Confirmed: 'confirmed' as VerificationStatusEnum,
        Refuted: 'refuted' as VerificationStatusEnum,
        EnteredInError: 'entered-in-error' as VerificationStatusEnum,
    };

    /**
     * Diagnosis Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Defines if the diagnosis was taken during admission or for other purposes.  * `AD` - AD * `DD` - DD * `CC` - CC * `CM` - CM * `pre-op` - PRE_OP * `post-op` - POST_OP * `billing` - BILLING
         * @type string (`dataFormat` is missing)
         */
        role: Diagnosis.RoleEnum;
        /**
         * The code of the Diagnosis in ICD10.
         * @type string (`dataFormat` is missing)
         */
        icd10Code?: string;
        /**
         * Descriptive text of diagnosis.
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * The status of the Diagnosis.  * `unconfirmed` - UNCONFIRMED * `provisional` - PROVISIONAL * `differential` - DIFFERENTIAL * `confirmed` - CONFIRMED * `refuted` - REFUTED * `entered-in-error` - ERROR
         * @type string (`dataFormat` is missing)
         */
        verificationStatus: Diagnosis.VerificationStatusEnum;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Diagnosis unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Diagnosis DTO
     * Transforms Diagnosis model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Diagnosis model */
        static toModel(obj: Diagnosis): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Diagnosis model */
        static toApi(obj: Model): Diagnosis {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Diagnosis;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
