import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { getHttpHeaderByKey, HttpService } from '@mona/api';
import { ConfigService } from '@mona/config';
import { EnovacomConfig } from '@mona/models';
import { isEmptyObject } from '@mona/shared/utils';
import { EnovacomWsCredentialsGetter, ENOVACOM_WS_CREDENTIALS_GETTER } from '../entities';

/**
 * Enovacom Api
 */
@Injectable({ providedIn: 'root' })
export class EnovacomApi {
    /**
     * Constructor
     *
     * @param enovacomWsCredsGetterFn
     * @param http
     * @param configService
     */
    constructor(
        @Inject(ENOVACOM_WS_CREDENTIALS_GETTER) public readonly enovacomWsCredsGetterFn: EnovacomWsCredentialsGetter,
        private http: HttpService,
        private configService: ConfigService,
    ) {}

    /**
     * Get enovacom token
     */
    getToken(): Observable<string> {
        const config: EnovacomConfig = this.configService.get('enovacom');
        const enovacomWsCreds: string = this.enovacomWsCredsGetterFn() || window.btoa(config?.credentials);

        if (!enovacomWsCreds || isEmptyObject(config)) {
            return throwError('Enovacom base url or config was not provided');
        }
        const { baseUrl, authPath, loginHeader, loginHeaderValue, tokenHeader } = config;

        const headers = new HttpHeaders()
            .append('Authorization', `Basic ${enovacomWsCreds}`)
            .append(loginHeader, loginHeaderValue);

        // NOTE: baseUrl should end with /enovacom to make proxy work
        // otherwise CORS error will be thrown
        return this.http
            .request('GET', `${baseUrl}${authPath}`, {
                headers,
                observe: 'response',
                reportProgress: false,
            })
            .pipe(
                map(({ headers: resHeaders }) => {
                    const token: string = getHttpHeaderByKey(resHeaders, tokenHeader.toLowerCase());
                    return token;
                }),
            );
    }
}
