/* eslint-disable jsdoc/require-jsdoc */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { medicationsFeatureKey, MedicationsState, standardMedicationsFeatureKey } from '../../entities';
import { standardMedicationAdapter } from '../reducers/standard-medication.reducer';

const selectMedicationState = createFeatureSelector<MedicationsState>(medicationsFeatureKey);

const selectStandardMedicationState = createSelector(
    selectMedicationState,
    state => state[standardMedicationsFeatureKey],
);

export const selectSelectedStandardMedicationId = createSelector(
    selectStandardMedicationState,
    state => state.selectedId,
);

export const selectStandardMedicationsIsLoading = createSelector(
    selectStandardMedicationState,
    state => state.isLoading,
);

export const selectStandardMedicationsError = createSelector(selectStandardMedicationState, state => state.error);

export const selectSelectedStandardMedication = createSelector(
    selectStandardMedicationState,
    state => state.entities?.[state.selectedId],
);

export const selectSelectedStandardMedicationById = (id: string) =>
    createSelector(selectStandardMedicationState, state => state.entities?.[id]);

export const {
    selectIds,
    selectEntities: selectStandardMedicationsMap,
    selectAll: selectStandardMedications,
    selectTotal,
} = standardMedicationAdapter.getSelectors(selectStandardMedicationState);
