import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
    getRequestWithBaseApiUrl,
    isAuthLoginRequest,
    isConfigRequest,
    isDevicesRequest,
    isHealthRequest,
    isLocalRequest,
    isRfidLoginRequest,
    isTelemedicineRequest,
    isTokenRefreshRequest,
} from '@mona/api';
import { ConfigService } from '@mona/config';
import { Logger } from '@mona/shared/logger';
import { isEmpty, isNonNullable, notEmpty, Platform, PLATFORM } from '@mona/shared/utils';
import { AuthState, TokenStatus } from '../models';
import { AuthActions, AuthSelectors } from '../state';
import { AuthService } from './auth.service';
import { JwtHelperService } from './jwt-helper.service';
import { TokenStorageService } from './token-storage.service';

/**
 * Authorization token interceptor
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private logger = new Logger('AUTH:TOKENINTERCEPTOR');
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private refreshTokenStatus$ = this.store.select(AuthSelectors.selectAuthState).pipe(
        filter(
            auth =>
                auth.refreshTokenStatus === TokenStatus.INVALID ||
                (auth.refreshTokenStatus === TokenStatus.VALID && !!auth.user),
        ),
        map((auth: AuthState) => auth.refreshTokenStatus),
    );

    count = 0;
    /**
     * Constructor
     *
     * @param platform
     * @param store
     * @param actionsObserver$
     * @param configService
     * @param tokenStorage
     * @param jwtHelper
     * @param authService
     */
    constructor(
        @Inject(PLATFORM) private platform: Platform,
        private store: Store<AuthState>,
        private actionsObserver$: ActionsSubject,
        private configService: ConfigService,
        private tokenStorage: TokenStorageService,
        private jwtHelper: JwtHelperService,
        private authService: AuthService,
    ) {}

    /**
     * Identifies and handles a given HTTP request by adding `Authorization` header
     *
     * @param request request
     * @param next next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (isLocalRequest(request) || isConfigRequest(request)) {
            return next.handle(request);
        }
        return this.configService.configLoaded$.pipe(
            exhaustMap(() => {
                const config = this.configService.config;
                if (isEmpty(config)) {
                    return next.handle(request);
                }
                if (this.skipTokenHeader(request)) {
                    return next.handle(getRequestWithBaseApiUrl(request, config));
                }
                const req = getRequestWithBaseApiUrl(request, config);
                return from(this.getAccessToken(req)).pipe(
                    switchMap(accessToken => {
                        const authRequest = this.addTokenHeader(req, accessToken);
                        return next.handle(authRequest);
                    }),
                    catchError(err => {
                        // in case of 401 http error
                        if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.Unauthorized) {
                            // get refresh tokens
                            const refreshToken = this.tokenStorage.getRefreshToken();

                            // if there are tokens then send refresh token request
                            if (isNonNullable(refreshToken) && !this.jwtHelper.isTokenExpired(refreshToken)) {
                                return this.refreshToken(req, next);
                            }

                            // otherwise logout and redirect to login page
                            return this.logOut(err);
                        }

                        // in case of 403 http error (refresh token failed)
                        if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.Forbidden) {
                            // logout and redirect to login page
                            return this.logOut(err);
                        }
                        // if error has status neither 401 nor 403 then just return this error
                        return throwError(err);
                    }),
                );
            }),
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

    /**
     *  Do not add token for these request urls
     *
     * @param request
     */
    private skipTokenHeader(request?: HttpRequest<any>): boolean {
        return (
            isDevicesRequest(request) ||
            isHealthRequest(request) ||
            isConfigRequest(request) ||
            isAuthLoginRequest(request) ||
            isTokenRefreshRequest(request)
        );
    }

    /**
     * Auth token getter - return either user token or device token
     * Skip user token if req is RFID login OR if req is for Telemedicine server
     *
     *  @param request
     */
    private async getAccessToken(request: HttpRequest<any>): Promise<string> {
        const userToken = this.tokenStorage.getAccessToken(),
            rfidLoginRequest = isRfidLoginRequest(request),
            telemedicineRequest = isTelemedicineRequest(request);
        if (!this.platform.isElectron || (!isEmpty(userToken) && !rfidLoginRequest && !telemedicineRequest)) {
            return Promise.resolve(userToken);
        } else {
            // logger.debug(`TokenGetterFactory: use DEVICE token for "${req?.url || 'anything'}"`);
            return this.authService.getDeviceAccessToken(telemedicineRequest);
        }
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);

            this.store.dispatch(AuthActions.refreshTokenRequest());
            return this.actionsObserver$.pipe(
                ofType(AuthActions.refreshTokenSuccess),
                take(1),
                switchMap(({ token: accessToken }) => {
                    this.refreshTokenSubject.next(accessToken);
                    // repeat failed request with new token
                    return next.handle(this.addTokenHeader(request, accessToken));
                }),
                tap(() => (this.refreshTokenInProgress = false)),
            );
        } else {
            // wait while getting new token
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    // repeat failed request with new token
                    return next.handle(this.addTokenHeader(request, token));
                }),
            );
        }
    }

    private logOut(err): Observable<HttpEvent<any>> {
        this.logger.log('logoutAndRedirect', err);
        this.authService.logOut();
        return throwError(err);
    }
}
