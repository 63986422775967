import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@mona/shared/utils';
import { ConfirmDialogComponent, ErrorDetailsDialogComponent, UiDialogContainer } from './components';

const DIALOG_COMPONENTS = [
    /* prettier-ignore */
    ConfirmDialogComponent,
    ErrorDetailsDialogComponent,
    UiDialogContainer,
];

/**
 * DialogModule
 */
@NgModule({
    declarations: DIALOG_COMPONENTS,
    imports: [UtilsModule, A11yModule, MatDialogModule, MatButtonModule, MatIconModule],
    exports: [DIALOG_COMPONENTS],
})
export class UiDialogModule {}
