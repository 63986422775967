import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { environment } from '@environment';
import { PrescriptionFrequency, PrescriptionFrequencyTime, ProcedureCategory } from '@mona/models';
import { validateFrequency } from '@mona/pdms/shared';
import { BaseProcedurePrescriptionForm, buildBaseProcedurePrescriptionForm } from './procedure-form.model';

/**
 * Base Procedure Form Component
 */
@Component({
    selector: 'mona-base-procedure-prescription-form',
    templateUrl: './base-procedure-prescription-form.component.html',
    styleUrls: ['./base-procedure-prescription-form.component.scss'],
})
export class BaseProcedurePrescriptionFormComponent implements OnInit {
    readonly NoRequiredFrequencyCode: string = environment.noRequiredFrequencyCode;
    readonly CustomFrequencyCode: string = environment.customFrequencyCode;

    /** ElementRef */
    @Input() submitBtnRef: ElementRef;
    /** Frequency dictionary */
    @Input() prescriptionFrequencies: PrescriptionFrequency[] = [];
    /** Frequency dictionary */
    @Input() procedureCategories: ProcedureCategory[] = [];
    /** Frequency dictionary */
    @Input() prescriptionFrequencyTimes: PrescriptionFrequencyTime[] = [];
    /** start date of procedure prescription */
    @Input() startDate: string;
    /** Procedure form  */
    formGroup: BaseProcedurePrescriptionForm;

    validateFrequency = validateFrequency;

    /** The controls of the form */
    get controls(): BaseProcedurePrescriptionForm['controls'] {
        return this.formGroup.controls;
    }
    /**
     * Constructor
     *
     * @param controlContainer
     * @param cdr
     */
    constructor(@Optional() private controlContainer: ControlContainer, private cdr: ChangeDetectorRef) {}

    /**
     * INFO: add comment
     */
    ngOnInit() {
        if (this.controlContainer?.control) {
            this.formGroup = this.controlContainer.control as any;
        } else {
            this.formGroup = buildBaseProcedurePrescriptionForm();
        }
    }

    /**
     * Track by code
     *
     * @param index number
     * @param item terminology model which has code
     */
    trackByCode(index, item): string {
        return item.code;
    }
    /**
     * Set custom frequency
     */
    setCustomFrequency() {
        this.controls.frequency.patchValue(environment.customFrequencyCode);
        this.controls.frequency.markAsDirty();
        this.cdr.detectChanges();
    }

    /**
     * Checks if the frequency times should be displayed.
     */
    isShowFrequencyTimes() {
        const frequencyTimes = this.controls.frequencyTimes.value;
        const frequencyCode = this.controls.frequency.value;

        return (
            (frequencyTimes?.length && frequencyCode !== environment.customFrequencyCode) ||
            frequencyCode === environment.customFrequencyCode
        );
    }
}
