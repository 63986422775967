/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DailyGoalsState } from '../../entities';
import { taskListStateKey, TaskListStateMap } from '../reducers';
import { dailyGoalsAdapter, dailyGoalsFeatureKey } from '../reducers/daily-goals.reducer';

export const selectTaskListFeatureState = createFeatureSelector<TaskListStateMap>(taskListStateKey);

const selectDailyGoalsState = createSelector(selectTaskListFeatureState, state => state[dailyGoalsFeatureKey]);

export const {
    selectIds: selectDailyGoalIds,
    selectEntities: selectDailyGoalEntities,
    selectAll: selectDailyGoalAll,
    selectTotal: selectDailyGoalTotal,
} = dailyGoalsAdapter.getSelectors(selectDailyGoalsState);

export const selectDailyGoalsEntities = createSelector(
    selectDailyGoalsState,
    (state: DailyGoalsState) => state.entities,
);

export const selectIsLoading = createSelector(selectDailyGoalsState, (state: DailyGoalsState) => state.isLoading);
