import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhysicalExamination } from '@mona/models';
import { PhysicalExaminationForm } from '../../models';

/**
 * Physical examination component
 */
@Component({
    selector: 'app-physical-examination-dialog',
    templateUrl: './physical-examination-dialog.component.html',
    styleUrls: ['./physical-examination-dialog.component.scss'],
})
export class PhysicalExaminationDialogComponent {
    /**
     * Physical examination form
     */
    physicalExaminationForm = new FormGroup<PhysicalExaminationForm>({
        text: new FormControl<string>('', [Validators.required]),
    });

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<AnamnesisDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<PhysicalExaminationDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            physicalExamination: PhysicalExamination;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-physical-examination-dialog');
        this.physicalExaminationForm.controls.text.setValue(this.data.physicalExamination.text);
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.physicalExaminationForm.markAsTouched();

        if (this.physicalExaminationForm.valid) {
            this.dialogRef.close(this.physicalExaminationForm.controls.text.value);
        }
    }
}
