import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { Practitioner, PractitionerRole } from '@mona/models';
import { transformApiPractitioner, transformApiPractitioners } from './transforms';

/**
 * API abstraction layer for the Practitioner API
 */
@Injectable({
    providedIn: 'root',
})
export class PractitionersApi {
    apiBase = '/pdms/practitioners/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get practitioner by id
     *
     * @param id EntityId<Practitioner>
     */
    getPractitioner(id: string): Observable<Practitioner> {
        return this.http
            .get<ApiModels.Practitioner>(`${this.apiBase}${id}/`)
            .pipe(map(practitioner => transformApiPractitioner(practitioner)));
    }

    /**
     * Get practitioners list, optionally filtered by role.
     *
     * @param role PractitionerRole
     * @param ids list of ids
     */
    getPractitioners(role?: PractitionerRole, ids?: string[]): Observable<Practitioner[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (role?.length) {
            params.role = role;
        }

        if (ids?.length) {
            params.ids = ids.join(',');
        }

        return this.http
            .get<ApiModels.Practitioner[]>(this.apiBase, {
                params,
            })
            .pipe(map(practitioners => transformApiPractitioners(practitioners)));
    }
}
