// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.fe1.stage.monatest.icu/auto-open-api-spec.yaml 1.3.6
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating Patient payload used for admission.
 * @see #/components/schemas/AdmissionPatient - reference to schema in open-api-spec.yaml
 * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
 * @version 1.3.6
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionPatient {
    /**
     * The patient number.
     *
     */
    patient_number: string;
    /**
     * The prefix to the name, such as \"Dr.\" or \"Prof.\".
     *
     */
    prefix?: string | null;
    /**
     * The first or given name.
     *
     */
    first_name: string;
    /**
     * The last or family name.
     *
     */
    last_name: string;
    /**
     * Defines the gender fo the patient.  * `male` - MALE * `female` - FEMALE * `other` - OTHER * `unknown` - UNKNOWN
     *
     */
    gender: AdmissionPatient.GenderEnum;
    /**
     * The date of birth (and time, if available) of the patient.
     * @dataFormat date-time
     */
    date_of_birth: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionPatient
 */
export namespace AdmissionPatient {
    export type GenderEnum = 'male' | 'female' | 'other' | 'unknown';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum,
        Other: 'other' as GenderEnum,
        Unknown: 'unknown' as GenderEnum,
    };

    /**
     * AdmissionPatient Frontend Model with camelCase properties
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The patient number.
         * @type string (`dataFormat` is missing)
         */
        patientNumber: string;
        /**
         * The prefix to the name, such as \"Dr.\" or \"Prof.\".
         * @type string (`dataFormat` is missing)
         */
        prefix?: string | null;
        /**
         * The first or given name.
         * @type string (`dataFormat` is missing)
         */
        firstName: string;
        /**
         * The last or family name.
         * @type string (`dataFormat` is missing)
         */
        lastName: string;
        /**
         * Defines the gender fo the patient.  * `male` - MALE * `female` - FEMALE * `other` - OTHER * `unknown` - UNKNOWN
         * @type string (`dataFormat` is missing)
         */
        gender: AdmissionPatient.GenderEnum;
        /**
         * The date of birth (and time, if available) of the patient.
         * @dataFormat date-time
         */
        @TransformDate() dateOfBirth: Date;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionPatient DTO
     * Transforms AdmissionPatient model from/to API object
     * @since 2023-08-09T20:01:36.017052+02:00[Europe/Berlin]
     * @version 1.3.6
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionPatient model */
        static toModel(obj: AdmissionPatient): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionPatient model */
        static toApi(obj: Model): AdmissionPatient {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionPatient;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
