// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for Output.
 * @see #/components/schemas/Output - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Output {
    /**
     * The actual value of the output.
     * @dataFormat double
     */
    value: number;
    /**
     * The date when value was created.
     * @dataFormat date-time
     */
    date: string;
    /**
     * The code.
     *
     */
    code: string;
    /**
     * The code description.
     *
     */
    description: string;
    /**
     * The unit of the value.
     *
     */
    unit: string;
    /**
     * The identifier of related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Timestamp when this entity was last modified.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * Output unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The identifier of the related Practitioner.
     * @dataFormat uuid
     */
    author_id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Output
 */
export namespace Output {
    /**
     * Output Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The actual value of the output.
         * @dataFormat double
         */
        value: number;
        /**
         * The date when value was created.
         * @dataFormat date-time
         */
        @TransformDate() date: Date;
        /**
         * The code.
         * @type string (`dataFormat` is missing)
         */
        code: string;
        /**
         * The code description.
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * The unit of the value.
         * @type string (`dataFormat` is missing)
         */
        unit: string;
        /**
         * The identifier of related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Timestamp when this entity was last modified.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * Output unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The identifier of the related Practitioner.
         * @dataFormat uuid
         */
        authorId: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Output DTO
     * Transforms Output model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Output model */
        static toModel(obj: Output): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Output model */
        static toApi(obj: Model): Output {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Output;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
