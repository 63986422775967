import { createReducer, on } from '@ngrx/store';
import { wardsAdapter, WardsState } from '../../entities';
import { WardsActions } from '../actions';

/**
 * Wards reducer map
 */
export const initialState: WardsState = wardsAdapter.getInitialState({
    assignedId: null,
    selectedId: null,
    isLoading: false,
    error: null,
});

export const wardsReducer = createReducer(
    initialState,
    on(WardsActions.selectWard, (state, { wardId }) => ({ ...state, selectedId: wardId })),
    on(WardsActions.load, state => ({ ...state, isLoading: true })),
    on(WardsActions.loadSuccess, (state, { wards }) =>
        wardsAdapter.setAll(wards, { ...state, isLoading: false, error: null }),
    ),
    on(WardsActions.loadFailure, (state, { error }) => wardsAdapter.setAll([], { ...state, isLoading: false, error })),
    on(WardsActions.upsertWard, (state, { ward }) => wardsAdapter.upsertOne(ward, { ...state })),
    on(WardsActions.removeWard, (state, { ward }) => wardsAdapter.removeOne(ward.id, { ...state })),
    on(WardsActions.clear, state => wardsAdapter.removeAll({ ...state, error: null })),
    on(WardsActions.setAssignedWardId, (state, { wardId }) => ({ ...state, assignedWardId: wardId || null })),
);
