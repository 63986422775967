import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const DIAGNOSTICS_DIALOG_ID = 'diagnostics-dialog';

/**
 * Diagnostics dialog component
 */
@Component({
    selector: 'mona-diagnostics-dialog',
    template: `
        <div mat-dialog-content class="mona-diagnostics-dialog__content scrollbar-visible">
            <div class="mona-diagnostics-dialog__head">
                <h2 mat-dialog-title class="mona-diagnostics-dialog__title">
                    {{ 'diagnostics.title' | translate }}
                </h2>
                <button class="mona-diagnostics-dialog__close" (click)="close(true)" mat-icon-button type="button">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <mona-diagnostics
                #diagnostics
                class="mona-diagnostics-dialog__main"
                [isCoreOnlyCheck]="data?.isCoreOnlyCheck"
                [allowChangeUrls]="data?.allowChangeUrls"
                (coreAvailableChange)="data?.closeWhenAvailableChange && close($event)"
            ></mona-diagnostics>
        </div>
        <div mat-dialog-actions align="end">
            <button
                *ngIf="diagnostics.selectedTabIndex === 0"
                [disabled]="diagnostics.isLoading$ | async"
                (click)="diagnostics.runDiagnostics()"
                mat-raised-button
                data-testid="btn-retry"
            >
                {{ 'apps.telemedicine.server.retry' | translate }}
            </button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class DiagnosticsDialogComponent {
    /**
     * Constructor
     *
     * @param dialogRef
     * @param dialogService
     */
    constructor(
        public dialogRef: MatDialogRef<DiagnosticsDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { isCoreOnlyCheck: boolean; allowChangeUrls: boolean; closeWhenAvailableChange: boolean },
    ) {}

    /**
     * Close dialog
     *
     * @param event
     */
    close(event: boolean) {
        if (event) {
            this.dialogRef.close();
        }
    }
}
