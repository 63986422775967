/* eslint-disable prefer-spread, @typescript-eslint/ban-types, @typescript-eslint/no-empty-function, @angular-eslint/no-empty-lifecycle-method, @angular-eslint/contextual-lifecycle, jsdoc/require-jsdoc */
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, isObservable, Observable, of, ReplaySubject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { TableDataItem, VitalSign } from '@mona/models';
import { Logger } from '@mona/shared/logger';
import { TakeUntilDestroy, takeUntilDestroy, tapOnce } from '@mona/shared/utils';
import { UiEchartsDirective } from '../../directives';
import {
    getLineChartAxisFormatter,
    getLineChartOptions,
    LegendItem,
    UiEchartsData,
    UiEChartsInitOpts,
    UiEchartsOption,
    UiEchartsSeriesOption,
} from '../../models';

/**
 * UiEcharts Line-Time Container interface for components
 */
export interface UiEchartsLineTimeContainer extends AfterViewInit, OnDestroy {
    readonly chartRef: UiEchartsDirective;
    // readonly chartInstance: ECharts;
    readonly chartInitialized$: Observable<any>;
    readonly data$: Observable<UiEchartsData>;
    readonly options: UiEchartsOption;
    readonly legendItems$: Observable<LegendItem[]>;
    readonly chartSelectedCodes$: BehaviorSubject<Set<string>>;
    preselectedChartCodes: number;

    transformRowToSeriesOption(
        row: { code: string; data?: any[]; color?: string },
        firstDate?: Date,
        lastDate?: Date,
        parent?: AnyObject,
    ): UiEchartsSeriesOption;
    scrollChart: (startValue: number | Date, endValue: number | Date) => void;
    toggleLegendItem: (legendItem: LegendItem) => void;
}

@TakeUntilDestroy
@Component({
    selector: 'ui-line-time-chart',
    template: '',
})
export class UiEchartsLineTimeComponent implements UiEchartsLineTimeContainer {
    @ViewChild(UiEchartsDirective, { static: false }) readonly chartRef: UiEchartsDirective;
    private _chartinitialized = new ReplaySubject();
    readonly chartInitialized$ = this._chartinitialized.asObservable();
    readonly data$: Observable<UiEchartsData> = of({ series: [], empty: true, xAxisData: [] });
    readonly initOpts: UiEChartsInitOpts = {
        renderer: 'svg',
        height: 270,
    };
    readonly options: UiEchartsOption = getLineChartOptions();
    private _legendItems$ = new BehaviorSubject<LegendItem[]>([]);
    readonly legendItems$ = this._legendItems$.asObservable();
    readonly chartSelectedCodes$ = new BehaviorSubject<Set<string>>(new Set());
    readonly existingCodes$ = new BehaviorSubject<Set<string>>(new Set());
    preselectedChartCodes = 3;

    private logger = new Logger();

    ngAfterViewInit() {
        this.chartRef?.chartInit.pipe(take(1)).subscribe(() => {
            this._chartinitialized.next();
            this._chartinitialized.complete();

            if (!this.chartRef?.chart) {
                return;
            }
            this.chartRef.toggleLoading(true);
            this.subscribeOnDataChange();
        });
    }

    ngOnDestroy(): void {}

    subscribeOnDataChange(): void {
        if (!isObservable(this.data$)) {
            return;
        }

        let firstNotEmptyData = true;
        this.data$
            .pipe(debounceTime(50), takeUntilDestroy(this))
            .subscribe(({ series, empty, intervalForXAxisFormatter, preselectedChartCodes, xAxisData }) => {
                if (preselectedChartCodes) {
                    this.preselectedChartCodes = preselectedChartCodes;
                }
                if (series.length > 0) {
                    if (firstNotEmptyData) {
                        firstNotEmptyData = false;
                        // select first 3 codes only for first subscribe
                        this.updateSelectedCodes(series);
                    } else {
                        // when new item is added to the chart it should be
                        // added to this.existingCodes$ and unselected because
                        // by default all the newly added series are selected
                        // and it creates unconsistency as legend item is marked unselected
                        // and corresponding data is shown in the chart
                        this.addNewCode(series);
                    }
                }
                this.options.xAxis['data'] = xAxisData; // set xAxis labels timestamps
                this.setXAxisFormatter(intervalForXAxisFormatter);
                this.updateLegendItems(series);
                this.chartRef.toggleLoading(empty);
            });
    }

    transformRowToSeriesOption(
        row: {
            code: string;
            name: string;
            data?: any[];
            color?: string;
        },
        firstDate?: Date,
        lastDate?: Date,
        parent?: AnyObject,
        customPatchingFunction?: (so: UiEchartsSeriesOption, firstDate: Date, lastDate: Date, logger: Logger) => void,
    ): UiEchartsSeriesOption {
        const { data = [] } = row;
        const so: UiEchartsSeriesOption = {
            id: row.code,
            name: row.name,
            type: 'line',
            legendHoverLink: false,
            silent: true,
            smooth: 0.05,
            lineStyle: { width: 2 },
            clip: false,
            connectNulls: false,
            showSymbol: true,
            symbolSize: 7,
            code: parent?.code || row.code,
            data: data.slice().sort((a, b) => a[0]?.getTime() - b[0]?.getTime()),
            dataPatched: false,
        };
        // modify chart data
        if (firstDate && lastDate) {
            customPatchingFunction
                ? customPatchingFunction(so, firstDate, lastDate, this.logger)
                : this.addBoundaryValuestoSeriesData(so, firstDate, lastDate, this.logger);
        }
        return so;
    }

    scrollChart(startValue: number | Date, endValue: number | Date, silent = false): void {
        if (!startValue || !endValue) {
            return;
        }
        this.chartRef.dispatchAction({ type: 'dataZoom', startValue, endValue }, { silent });
    }

    setXAxisFormatter(interval: number) {
        this.chartRef.setOption({
            xAxis: {
                axisLabel: {
                    formatter: getLineChartAxisFormatter(interval),
                },
            },
        });
    }

    updateLegendItems(series: UiEchartsSeriesOption[]): void {
        const legendItems = [];
        series.forEach(({ code, name, color }) => {
            const found = legendItems.find(li => li.name === name);
            if (!found) {
                legendItems.push({ id: code, name, color } as LegendItem);
            }
        });
        this._legendItems$.next(legendItems);
    }

    addNewCode(series: UiEchartsSeriesOption[]): void {
        const existingCodes = this.existingCodes$.getValue();
        series.forEach(s => {
            if (!existingCodes.has(s.code)) {
                existingCodes.add(s.code);

                this.chartRef.dispatchAction({
                    type: 'legendToggleSelect',
                    name: s.name,
                });
            }
        });

        this.existingCodes$.next(existingCodes);
    }

    updateSelectedCodes(series: UiEchartsSeriesOption[]): void {
        const selectedCodes = new Set<string>();
        // some of them has the same name but different id(code)
        // we need to keep track of the names
        const childrenSet = new Set<string>();
        const existingCodes = new Set<string>();

        for (const { code, name } of series) {
            const siblingAlreadyAdded = childrenSet.has(name as string);

            if (selectedCodes.size < this.preselectedChartCodes) {
                // for first 3 codes we just add them
                if (!siblingAlreadyAdded) {
                    selectedCodes.add(code as string);
                } else {
                    continue;
                }
            } else if (!siblingAlreadyAdded) {
                // every code which is more than 3 we unselect it
                this.chartRef.dispatchAction({
                    type: 'legendToggleSelect',
                    name: name,
                });
            }
            existingCodes.add(code);
            childrenSet.add(name as string);
        }

        this.existingCodes$.next(existingCodes);
        this.chartSelectedCodes$.next(new Set(selectedCodes));
    }

    toggleLegendItem(legendItem: LegendItem): void {
        const selectedCodes = this.chartSelectedCodes$.getValue();
        selectedCodes.has(legendItem.id) ? selectedCodes.delete(legendItem.id) : selectedCodes.add(legendItem.id);
        this.chartSelectedCodes$.next(selectedCodes);
        this.chartRef.dispatchAction({
            type: 'legendToggleSelect',
            name: legendItem.name,
        });
    }

    /**
     * Add boundary values so lines aren't drawn into the future
     * @param so
     * @param firstDate
     * @param lastDate
     * @param logger
     */
    private addBoundaryValuestoSeriesData(
        so: UiEchartsSeriesOption,
        firstDate: Date,
        lastDate: Date,
        logger: Logger,
    ): void {
        try {
            if (so.data?.length && !so.dataPatched) {
                const prevDate = new Date(so.data.at(0)[0]);
                prevDate.setSeconds(-1);
                so.data.unshift([prevDate, null]); // Todo: find more performant way
                if (so.data.at(0)?.[0] > firstDate) {
                    so.data.unshift([firstDate, null]);
                }
                so.data.push([lastDate, null]);
                so.dataPatched = true;
            }
            // eslint-disable-next-line no-empty
        } catch (error) {
            logger.error(`Error patching data for ${so.code + ' ' + so.name}`, error);
        }
    }
}
