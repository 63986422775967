import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment';
import { RootInjector } from '@mona/shared/utils';
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, {
        preserveWhitespaces: false,
        ngZoneEventCoalescing: true,
        ngZoneRunCoalescing: true,
    })
    .then(ngModuleRef => {
        RootInjector.setInjector(ngModuleRef.injector);
    })
    .catch(err => console.error(err));
