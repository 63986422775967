import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { RegisterDeviceResult } from '@mona/models';
import { AppError } from '@mona/shared/utils';
import { transformApiDeviceResponse } from './setup-transforms';

/**
 * API Service for the API
 */
@Injectable({
    providedIn: 'root',
})
export class SetupApi {
    apiBase = '/auth/devices';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Registers the device at core
     *
     * @param publicKey string
     * @param deviceMac string
     */
    registerDevice(publicKey: string, deviceMac: string): Observable<RegisterDeviceResult> {
        const payload: ApiModels.DeviceRegistrationRequest = {
            public_key: publicKey,
            id: deviceMac,
        };

        return this.http.post<ApiModels.DeviceResponse>(this.apiBase, payload).pipe(
            map(result => transformApiDeviceResponse(result)),
            catchError(err => {
                return throwError(err);
            }),
        );
    }

    /**
     * Check if device is activated
     *
     * @param deviceMac string
     */
    checkIsDeviceActivated(deviceMac: string): Observable<RegisterDeviceResult> {
        return this.http.get<ApiModels.DeviceResponse>(`${this.apiBase}/${deviceMac}`).pipe(
            timeout(environment.httpTimeout),
            map(result => transformApiDeviceResponse(result)),
            catchError((err: AppError) => {
                if (err.errorCode == 'device.not_found') {
                    return of({ isActivated: false } as RegisterDeviceResult);
                }
                return throwError(err);
            }),
        );
    }

    /**
     * Generates device activation id code
     *
     * @deprecated
     */
    private generateActivationIdCode(): number {
        return Math.floor(100000 + Math.random() * 900000);
    }
}
