import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProcedurePrescription } from '@mona/models';
import { ProceduresPrescriptionState } from '../../entities';
import * as ProcedurePrescriptionActions from '../actions/procedure-prescription.actions';

export const proceduresPrescriptionAdapter: EntityAdapter<ProcedurePrescription> =
    createEntityAdapter<ProcedurePrescription>();

export const initialState: ProceduresPrescriptionState = proceduresPrescriptionAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const reducer = createReducer(
    initialState,
    // ENTITY ADAPTER ACTIONS
    on(ProcedurePrescriptionActions.upsertProcedurePrescription, (state, action) =>
        proceduresPrescriptionAdapter.upsertOne(action.procedurePrescription, state),
    ),
    on(ProcedurePrescriptionActions.addProcedurePrescriptions, (state, action) =>
        proceduresPrescriptionAdapter.addMany(action.procedurePrescriptions, state),
    ),
    on(ProcedurePrescriptionActions.upsertProcedurePrescriptions, (state, action) =>
        proceduresPrescriptionAdapter.upsertMany(action.procedurePrescriptions, { ...state, isLoading: false }),
    ),
    on(ProcedurePrescriptionActions.updateProcedurePrescription, (state, action) =>
        proceduresPrescriptionAdapter.updateOne(action.update, state),
    ),
    on(ProcedurePrescriptionActions.updateProcedurePrescriptions, (state, action) =>
        proceduresPrescriptionAdapter.updateMany(action.updates, state),
    ),
    on(ProcedurePrescriptionActions.loadProcedurePrescriptions, state => ({
        ...state,
        isLoading: true,
    })),
    on(ProcedurePrescriptionActions.loadProcedurePrescriptionsFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),
    on(ProcedurePrescriptionActions.clearProcedurePrescriptions, state =>
        proceduresPrescriptionAdapter.removeAll({
            ...state,
            error: null,
        }),
    ),
    on(ProcedurePrescriptionActions.addProcedurePrescriptionsChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = proceduresPrescriptionAdapter.removeMany(toRemoveIds, state);

        return proceduresPrescriptionAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
