import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { ProcedureDialogComponent } from './procedure-dialog.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [ProcedureDialogComponent],
    exports: [ProcedureDialogComponent],
    imports: [UiModule, KeyboardModule],
})
export class ProcedureDialogModule {}
