import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { TelemedicineConfigApi } from '../../infrastructure';
import { TelemedicineConfigAction } from '../actions/telemedicine-config.action';

/**
 * Telemedicine config effects
 */
@Injectable()
export class TelemedicineConfigEffects {
    /* Effect Declarations */

    /**
     * Get call config effect
     */

    loadCallConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TelemedicineConfigAction.loadCallConfig.action),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.telemedicineConfigApi.getCallConfig(),
                    TelemedicineConfigAction.loadCallConfig,
                ),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param telemedicineConfigApi TelemedicineConfigApi
     */
    constructor(private actions$: Actions, private telemedicineConfigApi: TelemedicineConfigApi) {}
}
