/* eslint-disable no-prototype-builtins */
import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { AppErrorHandler } from '@mona/core';
import { compactObject } from '@mona/shared/utils';

const defaultOptions: CustomReqOptions<any> = {
    headers: {
        'Content-Type': 'application/json',
        // timeout: `${environment.httpTimeout}`,
    },
};

interface BaseParamsModel {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
}

/**
 * Params Model
 */
interface ParamsModel extends BaseParamsModel {
    /**
     * Page Number
     */
    page?: number;
    /**
     * Page Size
     */
    page_size?: number;
    /**
     * Start date
     */
    start_date?: string;
    /**
     * End date
     */
    end_date?: string;
}

/**
 * Base Http Service wrapper
 */
@Injectable({ providedIn: 'root' })
export class HttpService {
    /**
     * Constructor
     *
     * @param http
     * @param appErrorHandler
     */
    constructor(private http: HttpClient, private appErrorHandler: ErrorHandler) {}

    /**
     * @description creates HttpParams from key-value pairs
     */
    static buildHttpParams(paramsObj: { [key: string]: any }): HttpParams {
        const obj = compactObject(paramsObj);
        return new HttpParams({ fromObject: obj });
    }

    /**
     * Parse hash as query params
     *
     * @param hash
     */
    static parseHashAsQueryParams(hash: string): { [key: string]: string } {
        return hash
            ? hash.split('&').reduce((acc: any, part: string) => {
                  const item = part.split('=');
                  acc[item[0]] = decodeURIComponent(item[1]);
                  return acc;
              }, {})
            : {};
    }

    /**
     * Url encode parameters
     *
     * @param params
     */
    static urlEncodeParameters(params: any): string {
        return Object.keys(params)
            .map(k => {
                return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
            })
            .join('&');
    }

    /**
     * GET method
     *
     * @param url
     * @param options
     */
    get<T = any>(url: string, options?: CustomReqOptions<T>): Observable<T> {
        return this.http.get<T>(url, { ...defaultOptions, ...options }).pipe(
            timeout(environment.httpTimeout), // Cancel try after x milliseconds
            catchError(e => {
                (this.appErrorHandler as AppErrorHandler).handleApiError(e);
                // Makes sure that a connection timeout causes an error to be thrown
                return throwError(e);
            }),
        );
    }

    /**
     * POST method
     *
     * @param url
     * @param body
     * @param options
     */
    post<T = any>(url: string, body: any, options?: CustomReqOptions<T>): Observable<T> {
        return this.http.post<T>(url, body, { ...defaultOptions, ...options }).pipe(
            timeout(environment.httpTimeout), // Cancel try after x milliseconds
            catchError(e => {
                (this.appErrorHandler as AppErrorHandler).handleApiError(e);
                // Makes sure that a connection timeout causes an error to be thrown
                return throwError(e);
            }),
        );
    }

    /**
     * PUT method
     *
     * @param url
     * @param body
     * @param options
     */
    put<T = any>(url: string, body: any, options?: CustomReqOptions<T>): Observable<T> {
        return this.http.put<T>(url, body, { ...defaultOptions, ...options }).pipe(
            timeout(environment.httpTimeout), // Cancel try after x milliseconds
            catchError(e => {
                (this.appErrorHandler as AppErrorHandler).handleApiError(e);
                // Makes sure that a connection timeout causes an error to be thrown
                return throwError(e);
            }),
        );
    }

    /**
     * DELETE method
     *
     * @param url
     * @param options
     */
    delete<T = any>(url: string, options?: CustomReqOptions<T>): Observable<void | any> {
        return this.http.request<T>('DELETE', url, { ...defaultOptions, ...options }).pipe(
            timeout(environment.httpTimeout), // Cancel try after x milliseconds
            catchError(e => {
                (this.appErrorHandler as AppErrorHandler).handleApiError(e);
                // Makes sure that a connection timeout causes an error to be thrown
                return throwError(e);
            }),
        );
    }

    /**
     * PATCH method
     *
     * @param url
     * @param body
     */
    patch<T>(url: string, body: any): Observable<T | any> {
        return this.http.patch<T>(url, body).pipe(
            timeout(environment.httpTimeout), // Cancel try after x milliseconds
            catchError(e => {
                (this.appErrorHandler as AppErrorHandler).handleApiError(e);
                // Makes sure that a connection timeout causes an error to be thrown
                return throwError(e);
            }),
        );
    }

    /**
     * Generic request
     *
     * @param method
     * @param url
     * @param options
     */
    request<T extends any, O extends CustomReqOptionsWithObserve<T> = unknown>(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        url: string,
        options?: O,
    ): Observable<
        O['observe'] extends CustomReqOptionsWithObserve<T>['observe']
            ? O['responseType'] extends 'blob'
                ? HttpResponse<Blob>
                : HttpResponse<T>
            : T
    > {
        return (
            this.http.request<T>(method, url, {
                ...defaultOptions,
                ...options,
            } as any) as Observable<any>
        ).pipe(
            map((res: HttpResponse<any>) => {
                if (options.observe) {
                    // Complete the lazy initialization of this object (needed before reading).
                    res.headers['lazyInit'](); // INFO: response headers are not initialized when we observe
                }
                return res;
            }),
        );
    }
}

/**
 * Custom request options based on {@link HttpRequest}
 */

/* eslint-disable jsdoc/require-jsdoc */
export interface CustomReqOptions<T> {
    body?: T | null;
    params?: HttpParams | ParamsModel;
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    /**
     * Shared and mutable context that can be used by interceptors
     */
    context?: HttpContext;
    withCredentials?: boolean;
}

export interface CustomReqOptionsWithObserve<T> extends CustomReqOptions<T> {
    observe?: 'body' | 'events' | 'response' | any;
    responseType?: HttpRequest<T>['responseType'];
    reportProgress?: boolean;
}
