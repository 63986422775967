import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UtilsModule } from '@mona/shared/utils';
import { DrawerHeaderComponent } from './drawer-header.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [DrawerHeaderComponent],
    imports: [UtilsModule, PortalModule, MatToolbarModule, MatDividerModule],
    exports: [DrawerHeaderComponent],
})
export class DrawerHeaderModule {}
