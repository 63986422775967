/**
 * Vital sign codes
 */
export enum VitalSignCodes {
    // Non Invasive blood pressure
    NBIP = 'MONA-NIBP',

    // Non Invasive Systolic blood pressure
    NIBP_SYS = '8480-6',

    // Non Invasive Diastolic blood pressure
    NIBP_DIA = '8462-4',

    // Invasive blood pressure
    IBP = '76212-0',

    // Invasive Systolic blood pressure
    IBP_SYS = '76215-3',

    // Invasive Diastolic blood pressure
    IBP_DIA = '76213-8',

    // Heart rate
    HEART_RATE = '8867-4',

    // Head Occipital-frontal circumference
    OFC = '9843-4',

    // Body mass index (BMI)
    BMI = '39156-5',

    // Body weight
    WEIGHT = '29463-7',

    // Body height
    HEIGHT = '8302-2',

    // Intracranial Pressure
    ICP = '60956-0',

    // Body temperature
    TEMP = '8310-5',

    // Central Venous Pressure
    CVP = '60985-9',

    // Oxygen saturation in Arterial blood
    OX_SAT = '2710-2',

    // Respiratory rate
    RESP_RATE = '9279-1',

    // Mean Non IBP
    M_NIBP = '8478-0',

    // Mean IBP
    M_IBP = '76214-6',

    ABP = 'MONA-ABP',

    ABP_SYS = '0002-4a15',

    ABP_DIA = '0002-4a16',

    M_ABP = '0002-4a17',

    ART = 'MONA-ART',

    ART_SYS = '0002-4a11',

    ART_DIA = '0002-4a12',

    M_ART = '0002-4a13',
}
