/**
 * Diagnosis role
 */
export enum DiagnosisRole {
    // Admission diagnosis
    AD = 'AD',

    // Discharge diagnosis
    DD = 'DD',

    // Chief complaint
    CC = 'CC',

    // Comorbidity diagnosis
    CM = 'CM',

    // pre-op diagnosis
    PRE_OP = 'pre-op',

    // post-op diagnosis
    POST_OP = 'post-op',

    // Billing
    BILLING = 'billing',
}
