import fastDeepEqual from 'fast-deep-equal';
import { isDate, isEmpty, isEmptyObject, isNullOrUndefined, isObject } from '../types';
import { constant, identity } from './function.helpers';

/**
 * Creates a shallow clone of `value`.
 *
 * @param value
 */
export function clone<T>(value: T): T {
    if (Array.isArray(value)) {
        return value.slice() as any;
    } else if (value instanceof Object) {
        return Object.assign({}, value);
    } else {
        return value;
    }
}

/**
 * This method is like `set()` except that it accepts `updater` to produce the value to set.
 *
 * **Note:** This method mutates `object`.
 *
 * Differences from lodash:
 * - does not handle `customizer` returning `undefined`
 *
 * @param obj
 * @param path
 * @param updater
 */
export function update<T extends AnyObject, K extends Path<T>>(obj: T, path: K, updater: (val: any) => any): T {
    const keys = (path as string).split('.');
    if (obj && keys.length) {
        let current: any = obj;
        const length = keys.length;
        for (let i = 0; i < length; ++i) {
            const key = keys[i];
            let value = current[key];
            if (i < length - 1) {
                if (!(value instanceof Object)) {
                    value = Number.isInteger(keys[i + 1] as any) ? [] : {};
                }
            } else {
                value = updater(value);
            }
            current = current[key] = value;
        }
    }
    return obj;
}

/**
 * Get nested property
 *
 * @param obj object to get props
 * @param path string separated with dots or array of keys
 */
export function get<T extends AnyObject, K extends Path<T>>(obj: T, path: K): PathValue<T, K> {
    const keys = (path as string).split('.');
    return keys.reduce((xs, x) => (isObject(xs) && x in xs ? xs[x] : undefined), obj);
}

/**
 * Set nested property
 *
 * **Note:** This method mutates `object`.
 *
 * @param obj object to get props
 * @param path string separated with dots or array of keys
 * @param value to be set
 */
export function set<T extends AnyObject, K extends Path<T>>(obj: T, path: K, value: unknown): T {
    return update(obj, path, constant(value));
}

/**
 * Delete nested property
 *
 * **Note:** This method mutates `object`.
 *
 * @param obj object to get props
 * @param path string separated with dots or array of keys
 */
export function unset<T extends AnyObject, K extends Path<T>>(obj: T, path: K): Omit<T, K> {
    const keys = (path as string).split('.');
    keys.reduce((xs, x, index) => {
        if (index === keys.length - 1) {
            delete xs[x];
            return;
        }
        return xs[x];
    }, obj);
    return obj;
}

/**
 * The opposite of `pick`; this method creates an object composed of the own enumerable string properties of object that are not omitted.
 *
 * Differences from lodash:
 * - `paths` must be direct keys of `object` (they cannot refer to deeper properties)
 * - does not work with arrays
 *
 * @param object
 * @param {...any} paths
 */
export function omit<T extends AnyObject, K extends PropertyNames<T>>(object: T, ...paths: K[] | string[]): Omit<T, K> {
    const obj: any = clone(object) || {};
    for (const path of paths) {
        delete obj[path];
    }
    return obj;
}

/**
 * Creates an object composed of the picked `object` properties.
 *
 * Differences from lodash:
 * - `paths` must be direct properties of `object` (they cannot references deep properties)
 *
 * @param object
 * @param {...any} paths
 */
export function pick<T extends AnyObject, K extends keyof T>(
    object: T | Nil,
    ...paths: K[]
): T extends Nil ? Record<string, never> : Pick<T, K> {
    const result: any = {};
    if (object != null) {
        for (const path of paths) {
            result[path] = object[path];
        }
    }
    return result;
}

/**
 * Creates an array of the own enumerable property names of object.
 *
 * Differences from lodash:
 * - does not give any special consideration for arguments objects, strings, or prototype objects (e.g. many will have `'length'` in the returned array)
 
 *
 * @param object
 */
export function keys<T>(object: T | Nil): Array<StringifiedKey<T>> {
    let val = keysOfNonArray(object);
    if (Array.isArray(object)) {
        val = val.filter(item => item !== 'length');
    }
    return val as any;
}

/**
 * keysOfNonArray
 * @ignore
 * @internal
 */
export function keysOfNonArray<T>(object: T | Nil): Array<StringifiedKey<T>> {
    let val: string[] = [];
    if (object) {
        val = Object.getOwnPropertyNames(object);
    }
    return val as any;
}

/**
 * Creates an object with all empty values removed. The values [], `null`, `""`, `undefined`, and `NaN` are empty.
 *
 * @param obj Object
 * @param skipPropertyNames - pass property names to allow empty value
 */
export function compactObject<T = AnyObject>(obj: T, skipPropertyNames?: string[]): Partial<T> {
    if (isEmpty(obj)) {
        return obj;
    }
    if (Array.isArray(obj)) {
        return compact(obj as any) as any;
    }
    return Object.entries(obj)
        .filter(([key, value]: [string, any]) =>
            skipPropertyNames?.includes(key) ? true : !isNullOrUndefined(identity(value)) && !isEmpty(value),
        )
        .reduce((acc: Partial<T>, [key, val]: [string, any]) => ({ ...acc, [key]: val }), {});
}

/**
 * Creates an object with {@link compactObject} recursively
 *
 * @param obj Object
 */
export function deepCompactObject<T = AnyObject>(obj: T): Partial<T> {
    return Object.entries(obj).reduce((acc: Partial<T>, [key, val]: [string, any]) => {
        return typeof val === 'object' && !Array.isArray(val)
            ? { ...acc, [key]: deepCompactObject(compactObject(val)) }
            : { ...acc, ...compactObject(obj) };
    }, {});
}

/**
 * Creates an array with all falsey values removed. The values `false`, `null`, `0`, `""`, `undefined`, and `NaN` are falsey.
 *
 * @param array
 */
export function compact<T>(array: Array<T>): Array<Exclude<T, Falsy>> {
    return array.filter(identity) as Array<Exclude<T, Falsy>>;
}

/**
 * Creates an array with all thruthy values, except passed falsy values that allowed
 *
 * @param array
 * @param allowedFalsyValues
 */
export function compactWithAllowedFalsyValues<T>(
    array: Array<T>,
    allowedFalsyValues: unknown[],
): Array<Exclude<T, Falsy>> {
    return array.filter(item => item || allowedFalsyValues.includes(item)) as Array<Exclude<T, Falsy>>;
}

/**
 * returns array with numbers from first argument to bound.
 *
 * @param from
 * @param to
 * @param  producer
 */
export const rangeFromTo = <T>(from: number, to = 0, producer: AnyFn): T[] => {
    const arr = [];

    for (let i = from; i < to; i++) {
        arr.push(producer(i));
    }

    return arr;
};

/**
 * returns array with numbers from zero to bound.
 *
 * @param bound
 * @param producer
 */
export const fillArray = <T>(bound: number, producer: AnyFn): T[] => {
    return rangeFromTo(0, bound, producer);
};

/**
 * Returns arrayof unique attributes from each object in array<
 *
 * @param array
 * @param key string
 * @param updater
 */
export const uniqArrayAttrs = <T extends AnyObject, K extends keyof T, U extends ((v: T[K]) => unknown) | undefined>(
    array: Array<T> | ReadonlyArray<T>,
    key: K,
    updater?: U,
): ReturnType<U extends undefined ? typeof identity : typeof updater>[] => {
    let uniqArray = [];
    if (!Array.isArray(array) || !isObject(array[0])) {
        return uniqArray;
    }
    uniqArray = [...new Set(array.map(a => (updater || identity)(a[key])))] as ReturnType<typeof updater>[];
    return compact(uniqArray);
};

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 *
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(
    array: Array<T> | ReadonlyArray<T>,
    predicate: (value: T, index: number, obj: Array<T> | ReadonlyArray<T>) => boolean,
): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array)) {
            return l;
        }
    }
    return -1;
}

/**
 * Creates an object composed of keys generated from the results of running each element of `collection` thru `iteratee`.
 * The order of grouped values is determined by the order they occur in `collection`
 *
 * @param collection
 * @param iteratee
 * @param reverse
 * @param asMap
 */
export function groupBy<T, K extends any, A extends boolean>(
    collection: T[],
    iteratee: ValueIteratee<T, K>,
    reverse = false,
    asMap: A,
): A extends true ? Map<string, T[]> : { [k: string]: T[] } {
    const result: { [k: string]: T[] } = collection.reduce((acc, val) => {
        (acc[iteratee(val)] = acc[iteratee(val)] || [])[reverse ? 'unshift' : 'push'](val);
        return acc;
    }, {} as any);
    if (asMap) {
        return new Map(Object.entries<T[]>(result)) as any;
    }
    return result as any;
}

/**
 * Creates an array of unique values that are included in all given arrays using SameValueZero for equality comparisons. The order and references of result values are determined by the first array.
 *
 * @param {...any} arrays
 */
export function intersection<T>(...arrays: Array<Nil | readonly T[]>): T[] {
    const sets = arrays.map(array => new Set(array));
    return [...sets[0]].filter(value => sets.every(set => set.has(value)));
}

/**
 * Converts date to date only string
 *
 * @param date Date
 * Note: separated as used in transforms to allow to avoid date service injection
 */
export const dateToDateOnlyString = (date: Date): string => {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
};

/**
 * Compare deep equeal usin `fast-deep-equal`
 *
 * @param a
 * @param b
 */
export const compareDeepEqual = (a, b) => fastDeepEqual(a, b);

/**
 * Deep object diff
 *
 * @borrows https://github.com/mattphillips/deep-object-diff
 * @param lhs
 * @param rhs
 */
export const diffDeepEqual = <T extends any>(lhs: T, rhs: T): Partial<T> => {
    /* eslint-disable curly */
    if (lhs === rhs) return {}; // equal return no diff

    if (!isObject(lhs) || !isObject(rhs)) return rhs; // return updated rhs

    const l = lhs;
    const r = rhs;

    const deletedValues = Object.keys(l).reduce((acc, key) => {
        if (!(key in r)) {
            acc[key] = undefined;
        }

        return acc;
    }, {});

    if (isDate(l) || isDate(r)) {
        if (l.valueOf() == r.valueOf()) return {};
        return r;
    }

    return Object.keys(r).reduce((acc, key) => {
        if (!(key in l)) {
            acc[key] = r[key]; // return added r key
            return acc;
        }

        const difference = diffDeepEqual(l[key], r[key]);

        // If the difference is empty, and the lhs is an empty object or the rhs is not an empty object
        if (isEmptyObject(difference) && !isDate(difference) && (isEmptyObject(l[key]) || !isEmptyObject(r[key])))
            return acc; // return no diff

        acc[key] = difference; // return updated key
        return acc; // return updated key
    }, deletedValues);
    /* eslint-enable curly */
};

/**
 * Extends the add method on the native JavaScript Set object to compare using fast-deep-equal
 */
export class UniqueSet extends Set {
    /** @internal */
    has(o) {
        for (const i of this) {
            if (fastDeepEqual(o, i)) {
                return true;
            }
        }
        return false;
    }

    /** @internal */
    add(o) {
        if (!this.has(o)) {
            Set.prototype.add.call(this, o);
        }
        return this;
    }
}
