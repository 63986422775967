<fieldset [formGroup]="formGroup">
    <mat-form-field class="app-input-field app-input-field--fullwidth">
        <mat-label>
            {{ 'apps.patient.balance.inputs.bloodProducts.batchNumber' | translate }}
        </mat-label>
        <input
            matInput
            class="app-set-medication__input"
            [placeholder]="'apps.patient.balance.inputs.bloodProducts.batchNumber' | translate"
            [formControl]="controls.bloodBatchNumber"
        />
        <mat-error>
            <ui-validation-message [control]="controls.bloodBatchNumber"></ui-validation-message>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="app-input-field app-input-field--fullwidth">
        <mat-label>
            {{ 'apps.patient.balance.inputs.bloodProducts.condition' | translate }}
        </mat-label>
        <mat-select
            [formControl]="controls.bloodAdministrationReason"
            [placeholder]="'apps.patient.balance.inputs.bloodProducts.condition' | translate"
            class="mat-column-value__select scrollbar-visible"
            [compareWith]="compareFn"
        >
            <mat-option *ngFor="let reason of bloodAdministrations; trackBy: trackByReason" [value]="reason.reason">
                {{ reason.displayName }}
            </mat-option>
        </mat-select>
        <mat-error>
            <ui-validation-message [control]="controls.bloodAdministrationReason"></ui-validation-message>
        </mat-error>
    </mat-form-field>
</fieldset>
