// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating Bed model structure.
 * @see #/components/schemas/Bed - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Bed {
    /**
     * Bed unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The name of the bed.
     *
     */
    name: string;
    /**
     * Reference to the ward the bed belongs to.
     * @dataFormat uuid
     */
    ward_id: string;
    /**
     * Status of the bed.  * `active` - ACTIVE * `suspended` - SUSPENDED * `inactive` - INACTIVE
     *
     */
    status: Bed.StatusEnum;
    /**
     * The display name of the Bed.
     *
     */
    display_name: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Bed
 */
export namespace Bed {
    export type StatusEnum = 'active' | 'suspended' | 'inactive';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Suspended: 'suspended' as StatusEnum,
        Inactive: 'inactive' as StatusEnum,
    };

    /**
     * Bed Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Bed unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The name of the bed.
         * @type string (`dataFormat` is missing)
         */
        name: string;
        /**
         * Reference to the ward the bed belongs to.
         * @dataFormat uuid
         */
        wardId: string;
        /**
         * Status of the bed.  * `active` - ACTIVE * `suspended` - SUSPENDED * `inactive` - INACTIVE
         * @type string (`dataFormat` is missing)
         */
        status: Bed.StatusEnum;
        /**
         * The display name of the Bed.
         * @type string (`dataFormat` is missing)
         */
        displayName: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Bed DTO
     * Transforms Bed model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Bed model */
        static toModel(obj: Bed): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Bed model */
        static toApi(obj: Model): Bed {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Bed;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
