import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceState } from '../../entities';

export const selectDeviceState = createFeatureSelector<DeviceState>('device');
export const selectDeviceMacAddress = createSelector(
    selectDeviceState,
    (state: DeviceState) => state.network.macAddress,
);
export const selectDeviceNetworkDetails = createSelector(
    selectDeviceState,
    (state: DeviceState) => state.network.details,
);
export const selectDeviceKeys = createSelector(selectDeviceState, (state: DeviceState) => state.keys);
export const selectDeviceBrightness = createSelector(selectDeviceState, (state: DeviceState) => state.brightness);
export const selectDeviceTimezone = createSelector(selectDeviceState, (state: DeviceState) => state.timeZone);
export const selectDeviceCameraActivation = createSelector(
    selectDeviceState,
    (state: DeviceState) => state.isCameraActivated,
);
