import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiPopoverComponent } from './popover-component/popover.component';

/**
 * UiOverlayContainer Module
 */
@NgModule({
    imports: [CommonModule, OverlayModule, PortalModule, DragDropModule],
    declarations: [UiPopoverComponent],
    exports: [UiPopoverComponent],
})
export class UiOverlayContainerModule {}
