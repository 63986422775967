import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';

/**
 * UiDynamicTextareaComponent
 */
@Component({
    selector: 'ui-dynamic-textarea',
    styleUrls: ['./dynamic-textarea.component.scss'],
    templateUrl: './dynamic-textarea.component.html',
})
export class UiDynamicTextareaComponent {
    @ViewChild(MatInput, { read: ElementRef, static: true }) elementRef: ElementRef;

    control: UntypedFormControl;

    label = '';

    valueKey = undefined;

    hint = '';

    name = '';

    minLength: number = undefined;

    maxLength: number = undefined;

    required: boolean = undefined;

    errorMessageTemplate: TemplateRef<any> = undefined;

    placeholder = '';

    customClass = '';

    rows = 3;
}
