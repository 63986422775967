import { noop } from '@mona/shared/utils';
import { VHSTableScrollStrategy } from './vhs-table-scroll-abstract.strategy';

/**
 * A custom scroll strategy that updates Viewport rendered columns to render based on column width and buffer size BY SCROLLING BY VISIBLE ITEMS aka PAGE
 */
export class VHSTableScrollByViewportStrategy extends VHSTableScrollStrategy {
    /** @ignore */
    initLoading = noop;
    /** Update rendered range */
    updateRenderedRange() {
        if (!this.viewport) {
            return;
        }

        const dataLength = this.viewport.getDataLength();
        const scrollOffset = this.viewport.measureScrollOffset();
        const currentRange = this.viewport.getRenderedRange();

        // the index of the first item that would be at least partially visible in the viewport
        const firstVisibleIndex = Math.floor(scrollOffset / this.columnWidth);
        // skip if same range
        if (this.previousVisibleIndex === firstVisibleIndex) {
            return;
        }

        // Clamp the new range between 0 and dataLength
        const newStart = Math.max(0, firstVisibleIndex - this.itemsInViewport * 2);
        const newEnd = Math.min(dataLength, firstVisibleIndex + this.itemsInViewport * 2);
        const newRange = {
            start: newStart,
            end: newEnd,
        };
        const newOffset = this.columnWidth * newStart;

        const shouldUpdateRenderedRange = true;
        /* TODO: we have additional opportunity to optimize rendering in viewport, if we calculate correctly

         * // if not initialized & has not-empty range
         * !this.isRenderedRangeInitialized;
         * // if new range end is less than itemsInViewport
         * (currentRange.end >= 0 && currentRange.end - firstVisibleIndex - this.* itemsInViewport < this.itemsInViewport) ||
         * // if new range start is less than itemsInViewport
         * (newRange.start !== currentRange.start && * firstVisibleIndex - currentRange.start < this.* itemsInViewport);
         */
        if (shouldUpdateRenderedRange && !isNaN(newRange.start) && !isNaN(newRange.end)) {
            this.viewport.setRenderedRange(newRange);
        }
        this.previousVisibleIndex = firstVisibleIndex;
        this._scrolledIndexChange.next(firstVisibleIndex);
        this.isRenderedRangeInitialized = true;
        this.viewport.setRenderedContentOffset(newOffset);
    }
}
