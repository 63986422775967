import { ApiModels } from '@mona/api';
import { MedicationAdministrationMethod } from '@mona/models';

/**
 * Transforms api medication administration method
 * @param apiMedicationAdministrationMethod MedicationAdministrationMethod
 */
export const transformApiMedicationAdministrationMethod = (
    apiMedicationAdministrationMethod: ApiModels.AdministrationMethod,
): MedicationAdministrationMethod => {
    return {
        id: apiMedicationAdministrationMethod.code,
        code: apiMedicationAdministrationMethod.code,
        displayName: apiMedicationAdministrationMethod.display_name,
        routeCode: apiMedicationAdministrationMethod.route_code,
        routeDisplayName: apiMedicationAdministrationMethod.route_display_name,
    };
};

/**
 * Transform api medication administration methods
 * @param apiMedicationAdministrationMethods ApiModels.MedicationAdministration[]
 */
export const transformApiMedicationAdministrationMethods = (
    apiMedicationAdministrationMethods: ApiModels.AdministrationMethod[],
): MedicationAdministrationMethod[] => {
    return apiMedicationAdministrationMethods.map(apiMedicationAdministrationMethod =>
        transformApiMedicationAdministrationMethod(apiMedicationAdministrationMethod),
    );
};
