import { NgModule } from '@angular/core';
import { UiModule } from '@mona/ui';
import { UpdateDialogComponent } from './update-dialog.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [UpdateDialogComponent],
    imports: [UiModule],
    exports: [UpdateDialogComponent],
})
export class UpdateDialogModule {}
