export const UI_CHART_COLORS = [
    '#b71c1c',
    '#4caf50',
    '#fdd835',
    '#0288d1',
    '#fb8c00',
    '#7e57c2',
    '#880e4f',
    '#1a237e',
    '#0d47a1',
    '#0097a7',
    '#004d40',
    '#33691e',
    '#827717',
    '#ff6f00',
    '#bf360c',
    '#795548',
    '#757575',
    '#546e7a',
    '#ff5300',
    '#00aaff',
    '#ffff00',
    '#27ae60',
    '#9b59b6',
    '#6a3d9a',
    '#f26d84',
    '#0f9d58',
    '#00bfff',
    '#ff9900',
    '#ffdd00',
    '#adff00',
    '#00b3ff',
    '#a020f0',
    '#ff66ff',
    '#fff400',
    '#7fff00',
    '#00ff7f',
    '#9000ff',
    '#ff0090',
    '#4d004d',
    '#0000ff',
    '#00ffff',
    '#6060ff',
    '#ff0000',
    '#ffbb00',
    '#f7f700',
    '#32cd32',
    '#3f51b5',
    '#000000',
];
