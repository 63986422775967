/* eslint-disable max-classes-per-file */
import { inject, Inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

/**
 * Browser feature key
 */
export enum BrowserFeatureKey {
    ServiceWorker = 'Service Worker',
    Cache = 'Offline Capabilities',
    PushAPI = 'Push data',
    NotificationsAPI = 'Notifications',
    NotificationsTriggerAPI = 'Notification Trigger API ',
    BeforeInstallPromptEvent = 'Add to Homescreen',
    BackgroundSync = 'Background sync',
    NavigationPreloadManager = 'Navigation Preload',
    BudgetAPI = 'Budget API',
    StorageEstimation = 'Storage Estimation',
    PersistentStorage = 'Persistent Storage',
    WebShareAPI = 'Web Share',
    MediaSessionAPI = 'Media Session',
    MediaCapabilities = 'Media Capabilities',
    DeviceMemory = 'Device Memory',
    RelatedApps = 'Getting Installed Related Apps',
    PaymentRequestAPI = 'Payment Request',
    CredentialManagement = 'Credential Management',
    WebBluetoothAPI = 'Web Bluetooth',
    SpeechSynthesis = 'Speech Synthesis',
    SpeechRecognition = 'Speech Recognition',
    IntersectionObserver = 'Intersection Observer',
}

/**
 * Navigator Extended
 */
type NavigatorEx = Navigator & {
    standalone?: boolean;
    budget?: any;
    storage?: any;
};

/**
 * BrowserFeatures
 */
export interface BrowserFeatures {
    /**
     * Navigator Extended
     */
    readonly navigator: NavigatorEx;
    /**
     * Browser features map
     */
    readonly features: Map<string, boolean>;
}

export const BROWSER = new InjectionToken<any>('BrowserFeatureService', {
    providedIn: 'root',
    factory: () => new BrowserFeatureService(inject(WINDOW)),
});

class BrowserFeatureService implements BrowserFeatures {
    readonly navigator: NavigatorEx;
    readonly features: Map<string, boolean>;

    constructor(@Inject(WINDOW) readonly _win: Window) {
        this.navigator = _win.navigator;

        this.features = new Map([
            [BrowserFeatureKey.ServiceWorker, 'serviceWorker' in _win.navigator],
            [BrowserFeatureKey.Cache, 'caches' in _win],
            [BrowserFeatureKey.PushAPI, 'PushManager' in _win],
            [BrowserFeatureKey.NotificationsAPI, 'Notification' in _win],
            [
                BrowserFeatureKey.NotificationsTriggerAPI,
                false, // 'showTrigger' in _win['Notification'],
            ],
            [BrowserFeatureKey.BeforeInstallPromptEvent, 'onbeforeinstallprompt' in _win],
            [BrowserFeatureKey.BackgroundSync, 'SyncManager' in _win],
            [BrowserFeatureKey.NavigationPreloadManager, 'NavigationPreloadManager' in _win],
            [BrowserFeatureKey.BudgetAPI, 'budget' in this.navigator && 'reserve' in this.navigator.budget],
            [BrowserFeatureKey.StorageEstimation, 'storage' in this.navigator && 'estimate' in this.navigator.storage],
            [BrowserFeatureKey.PersistentStorage, 'storage' in this.navigator && 'persist' in this.navigator.storage],
            [BrowserFeatureKey.WebShareAPI, 'share' in this.navigator],
            [BrowserFeatureKey.MediaSessionAPI, 'mediaSession' in this.navigator],
            [BrowserFeatureKey.MediaCapabilities, 'mediaCapabilities' in this.navigator],
            [BrowserFeatureKey.DeviceMemory, 'deviceMemory' in this.navigator],
            [BrowserFeatureKey.RelatedApps, 'getInstalledRelatedApps' in this.navigator],
            [BrowserFeatureKey.PaymentRequestAPI, 'PaymentRequest' in _win],
            [BrowserFeatureKey.CredentialManagement, 'credentials' in this.navigator],
            [BrowserFeatureKey.WebBluetoothAPI, 'bluetooth' in this.navigator],
            [BrowserFeatureKey.SpeechSynthesis, 'speechSynthesis' in _win],
            [
                BrowserFeatureKey.SpeechRecognition,
                'webkitSpeechRecognition' in _win ||
                    'mozSpeechRecognition' in _win ||
                    'msSpeechRecognition' in _win ||
                    'oSpeechRecognition' in _win,
            ],
            [BrowserFeatureKey.IntersectionObserver, 'IntersectionObserver' in _win],
        ]);
    }
}
