<mat-card class="mona-procedures" [class.mona-procedures__as-list]="showAsList" [uiLoadingOverlay]="isLoading">
    <ui-table
        class="mona-procedures__table"
        [class.mona-procedures__table__as-list]="showAsList"
        [class.mona-procedures__table__as-table]="!showAsList"
        [dataSource]="dataSource"
        [isLoading]="isLoading"
        [showNoData]="showNoData"
        [hideTheadIfNoData]="true"
        [noDataConfig]="noDataConfig"
        [showHeaderRow]="showTableHeader"
        [isStriped]="tableStriped"
        [isBorderless]="tableBorderless"
        (tableEvent)="handleTableEvent($event)"
        [rowClassGetterFn]="rowClassGetterFn(selected)"
        [rowClickable]="rowClickable"
        [rowMenuItems]="showActions ? rowMenuItems : []"
        (tableEvent)="onTableEvent($event)"
    >
        <!-- ------------------------------------------------->
        <!-- COL.TYPE-->
        <!-- ------------------------------------------------->
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'apps.settings.prescriptionSet.procedureTable.type' | translate }}
            </th>
            <td mat-cell class="ui-table-td text-left" *matCellDef="let row">
                <div class="cell-with-icon" *ngIf="showAsList">
                    <mat-icon svgIcon="rebase"></mat-icon>
                </div>
                <span class="cell-text">{{ getProcedureCategory(row?.category) }}</span>
            </td>
        </ng-container>

        <!-- ------------------------------------------------->
        <!-- COL.DESCRIPTION-->
        <!-- ------------------------------------------------->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'apps.settings.prescriptionSet.procedureTable.description' | translate }}
            </th>
            <td mat-cell class="ui-table-td text-left" *matCellDef="let row">
                {{ row?.description }}
            </td>
        </ng-container>

        <!-- TODO: add condition here to hide elements inside ng-container -->
        <ng-container *ngIf="!showAsList">
            <!-- ------------------------------------------------->
            <!-- COL.INSTRUCTION-->
            <!-- ------------------------------------------------->
            <ng-container matColumnDef="instructions">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'apps.settings.prescriptionSet.procedureTable.instruction' | translate }}
                </th>
                <td mat-cell class="ui-table-td text-left" *matCellDef="let row">
                    {{ row?.instructions }}
                </td>
            </ng-container>

            <!-- ------------------------------------------------->
            <!-- COL.FREQUENCY -->
            <!-- ------------------------------------------------->
            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'apps.settings.prescriptionSet.procedureTable.frequency' | translate }}
                </th>
                <td mat-cell class="ui-table-td text-left" *matCellDef="let row">
                    {{ getMedicationFrequency(row, prescriptionFrequencies) }}
                </td>
            </ng-container>

            <!-- ------------------------------------------------->
            <!-- COL.ACTIONS -->
            <!-- ------------------------------------------------->
            <ng-container matColumnDef="actions" *ngIf="showActionsColumn">
                <th
                    class="ui-table-th"
                    [class.ui-table-th--hidden]="!dataSource.length"
                    mat-cell
                    *matHeaderCellDef
                    style="width: 100px"
                ></th>
                <td class="ui-table-td" mat-cell *matCellDef="let row">
                    <div class="mona-medication__action-buttons">
                        <button mat-icon-button color="primary" *ngIf="showDelete" (click)="delete.emit(row.id)">
                            <mat-icon class="mona-medication__icon">delete</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
        </ng-container>
    </ui-table>
</mat-card>
