import { NgModule } from '@angular/core';
import { LuminosityControlModule } from './luminosity-control/luminosity-control.module';
import { RfidEmulatorModule } from './rfid-emulator/rfid-emulator.module';
import { TimeZoneModule } from './time-zone/time-zone.module';
import { UpdateDialogModule } from './update-dialog/update-dialog.module';

const DEVICE_SHARED_MODULES = [
    /* prettier-ignore */
    RfidEmulatorModule,
    UpdateDialogModule,
    TimeZoneModule,
    LuminosityControlModule,
];

/** DeviceSharedModule */
@NgModule({
    exports: DEVICE_SHARED_MODULES,
})
export class DeviceSharedModule {}
