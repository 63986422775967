import { EntriesIntervalNames } from '../models';
import { addOrSubstractInterval } from './add-or-substract-interval.helper';

/**
 * Check if date is in same time intervval from provided date by adding interval to date
 *
 * @param selectedInterval
 * @param date
 * @param dateToMatch
 */
export function isSameInterval(selectedInterval: EntriesIntervalNames, date: Date, dateToMatch: Date) {
    const intervalDate = addOrSubstractInterval(selectedInterval, date);
    return dateToMatch.getTime() >= date.getTime() && dateToMatch.getTime() <= intervalDate.getTime();
}
