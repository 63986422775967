import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { medicationsFeatureKey } from './entities';
import {
    MedicationPrescriptionsEffects,
    medicationsReducer,
    MEDICATIONS_REDUCER_TOKEN,
    StandardMedicationsEffects,
} from './state';

/**
 * Data access medications module
 */
@NgModule({
    imports: [
        //
        StoreModule.forFeature(medicationsFeatureKey, MEDICATIONS_REDUCER_TOKEN),
        EffectsModule.forFeature([MedicationPrescriptionsEffects, StandardMedicationsEffects]),
    ],
    providers: [
        {
            provide: MEDICATIONS_REDUCER_TOKEN,
            useFactory: () => medicationsReducer,
        },
    ],
})
export class DataAccessMedicationsModule {
    /** @ignore */
    constructor(@Optional() @SkipSelf() parentModule: DataAccessMedicationsModule) {
        if (parentModule) {
            const msg = `DataAccessMedicationsModule has already been loaded.
                Import DataAccessMedicationsModule once, only, in the root AppModule.`;
            throw new Error(msg);
        }
    }
}
