// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validation the physical examination part of the admission payload
 * @see #/components/schemas/AdmissionPhysicalExaminationRequest - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionPhysicalExaminationRequest {
    /**
     * The text part of the physical examination
     *
     */
    text: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionPhysicalExaminationRequest
 */
export namespace AdmissionPhysicalExaminationRequest {
    /**
     * AdmissionPhysicalExaminationRequest Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The text part of the physical examination
         * @type string (`dataFormat` is missing)
         */
        text: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionPhysicalExaminationRequest DTO
     * Transforms AdmissionPhysicalExaminationRequest model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionPhysicalExaminationRequest model */
        static toModel(obj: AdmissionPhysicalExaminationRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionPhysicalExaminationRequest model */
        static toApi(obj: Model): AdmissionPhysicalExaminationRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionPhysicalExaminationRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
