import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomApiModels, WsService } from '@mona/api';
import { TokenStorageService } from '@mona/auth';
import { ConfigService } from '@mona/config';
import { DataUpdateMessage, DataUpdateMessageModel, Encounter } from '@mona/models';
import { transformApiDataUpdateMessage } from './transforms/data-update.transforms';

/**
 * Data updates ws service
 */
@Injectable({ providedIn: 'root' })
export class DataUpdatesWsService extends WsService<
    CustomApiModels.DataUpdateMessage,
    DataUpdateMessage<DataUpdateMessageModel>
> {
    /**
     * Constructor
     *
     * @param configService ConfigService
     * @param tokenStorage
     */
    constructor(private configService: ConfigService, private tokenStorage: TokenStorageService) {
        super();
    }

    /**
     * Connects to ws for notifications
     *
     * @param encounterId EntityId<Encounter>
     */
    connect(encounterId: EntityId<Encounter>): Observable<DataUpdateMessage<DataUpdateMessageModel>> {
        return of(this.tokenStorage.getAccessToken()).pipe(
            switchMap(accessToken => {
                const url = `${this.configService.get('api.coreWsUrl')}/ws/pdms/${encounterId}/`;
                this.setConfig(url, accessToken);
                return super.connect();
            }),
            map((message: CustomApiModels.DataUpdateMessage) => transformApiDataUpdateMessage(message)),
            catchError(e => {
                this.logger.error('DataUpdates WebSocket connection error', e);
                return EMPTY;
            }),
        );
    }
}
