import { createSelector, DefaultProjectorFn } from '@ngrx/store';
/* eslint-disable */
import {
    selectAllEncounters,
    selectAllEncountersGroupedByBed,
} from 'libs/pdms/data-access-encounters/src/lib/state/selectors';
/* eslint-disable */
import { WardWithBeds } from '@mona/models';
import { compact, isEmpty, isObject } from '@mona/shared/utils';
import {
    selectAllBeds,
    selectAssignedBedId,
    selectBedsEntities,
    selectBedsGroupedByWardId,
    selectSelectedBedId,
} from './beds.selectors';
import { selectAllWards, selectSelectedWardId, selectWardsEntities } from './wards.selectors';

/** Get beds with encounters */
export const selectAllBedsWithEncounters = createSelector(
    selectAllBeds,
    selectAllEncountersGroupedByBed,
    (beds, encMap) => {
        return beds.map(b => ({ ...b, encounter: isEmpty(encMap) ? null : encMap[b.id] }));
    },
);

/** Get empty beds - without encounters end not current selected or assigned */
export const selectEmptyBeds = createSelector(
    selectAllBedsWithEncounters,
    selectSelectedBedId,
    selectAssignedBedId,
    (beds, selectedId, assignedId) => {
        return beds.filter(b => isEmpty(b?.encounter) && b.id !== selectedId && b.id !== assignedId);
    },
);

/**
 * Get bed by id with ward
 *
 * @param bedId
 */
export const selectBedByIdWithWard = bedId =>
    createSelector(selectWardsEntities, selectBedsEntities, (wards, beds) => {
        const bed = beds[bedId];
        if (!bed) {
            return null;
        }
        return {
            ...bed,
            ward: wards[bed.wardId],
        };
    });

/**
 * Get ward with corresponding beds
 *
 * @param onlyEmpty - without encounters end not current selected or assigned
 */
export const selectAllWardsWithBeds: (onlyEmpty?: boolean) => DefaultProjectorFn<WardWithBeds[]> = onlyEmpty =>
    createSelector(
        selectAllWards,
        selectAllBeds,
        selectAllEncounters,
        selectSelectedBedId,
        selectAssignedBedId,
        (wards, beds, encounters = [], selectedId, assignedId) => {
            if (!wards?.length || !beds.length) {
                return [];
            }

            const getEncounterByBed = bedId => {
                return compact(encounters).find(e => e.active && e.bedId === bedId);
            };

            const result = compact(wards)
                .map(w => ({
                    ...w,
                    // Extend ward with beds by ward id, also filter if only empty
                    beds: compact(beds).reduce((acc, b) => {
                        if (b.wardId === w.id) {
                            if (onlyEmpty) {
                                if (isEmpty(getEncounterByBed(b.id)) && b.id !== selectedId) {
                                    acc.push(b);
                                }
                            } else {
                                acc.push({ ...b, encounter: getEncounterByBed(b.id) });
                            }
                        }
                        return acc;
                    }, []),
                }))
                // Return only wards which have beds
                .filter(w => w.beds.length);
            return result;
        },
    );

export * as BedsSelectors from './beds.selectors';
export * as WardsSelectors from './wards.selectors';
export * from './beds.selectors';
export * from './wards.selectors';
