import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { ConfigPipeModule } from '@mona/config';
import { UtilsModule } from '@mona/shared/utils';
import { UiInfoListItemModule } from '@mona/ui';
import { ServerUrlDialogModule } from '../server-url-dialog';
import {
    DiagnosticsComponent,
    DiagnosticsDialogComponent,
    DiagnosticsItemComponent,
    DiagnosticsItemManualComponent,
} from './components';

/**
 * DiagnosticsModule
 */
@NgModule({
    declarations: [
        DiagnosticsComponent,
        DiagnosticsDialogComponent,
        DiagnosticsItemComponent,
        DiagnosticsItemManualComponent,
    ],
    exports: [DiagnosticsComponent, DiagnosticsDialogComponent],
    imports: [
        UtilsModule,
        UiInfoListItemModule,
        MatCardModule,
        MatTabsModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        ConfigPipeModule,
        ServerUrlDialogModule,
    ],
})
export class DiagnosticsModule {}
