import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsModule } from '@mona/shared/utils';
import { UiInfoListItemModule } from '@mona/ui/components';
import { PatientPipesModule } from '../patient-pipes';
import { EncounterInfoComponent } from './encounter-info.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [EncounterInfoComponent],
    imports: [UtilsModule, UiInfoListItemModule, PatientPipesModule, MatTooltipModule],
    exports: [EncounterInfoComponent],
})
export class EncounterInfoModule {}
