import { createAction, props } from '@ngrx/store';
import { Encounter, PageableModel, ParamsModel, VitalSign } from '@mona/models';
import { DateRangeInterval, EntriesInterval } from '@mona/shared/date';

/**
 * Vital signs actions
 */
export class VitalSignsAction {
    /**
     * Reset table view mode
     */
    static resetViewMode = createAction('ENCOUNTER:RESET_VITAL_SIGN_VIEW_MODE');

    /**
     * Set vital sign interval
     */
    static setInterval = createAction(
        'ENCOUNTER:SET_VITAL_SIGN_INTERVAL',
        props<{
            interval: EntriesInterval;
        }>(),
    );

    /**
     * Set vital sign date range
     */
    static setDateRange = createAction(
        'ENCOUNTER:SET_VITAL_SIGN_DATE_RANGE',
        props<{
            dateRange: DateRangeInterval;
        }>(),
    );

    static setLoadingAction = createAction(
        'ENCOUNTER:SET_VITAL_SIGN_LOADING',
        props<{
            isLoading: boolean;
        }>(),
    );

    static loadVitalSigns = createAction(
        'ENCOUNTER:LOAD_VITAL_SIGNS_PAGINATED',
        props<{ params?: ParamsModel & { encounter_id?: EntityId<Encounter> } }>(),
    );
    static loadVitalSignsSuccess = createAction(
        'ENCOUNTER:LOAD_VITAL_SIGNS_PAGINATED_SUCCESS',
        props<PageableModel<VitalSign> & { codes: string[] }>(),
    );
    static loadVitalSignsFailed = createAction(
        'ENCOUNTER:LOAD_VITAL_SIGNS_PAGINATED_FAILED',
        props<{ error: unknown }>(),
    );
    static upsertVitalSigns = createAction('ENCOUNTER:UPSERT_VITAL_SIGNS', props<{ data: VitalSign[] }>());
    static removeVitalSigns = createAction('ENCOUNTER:REMOVE_VITAL_SIGNS', props<{ ids: string[] }>());
    static clearVitalSigns = createAction('ENCOUNTER:CLEAR_VITAL_SIGNS');
    static addChanges = createAction(
        'ENCOUNTER:ADD_VITAL_SIGNS_CHANGES',
        props<{ toUpdateEntities: VitalSign[]; toRemoveIds: string[] }>(),
    );
}
