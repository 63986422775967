import { ApiModels } from '@mona/api';
import { BaseMedication, PrescriptionSet, PrescriptionSetMedication } from '@mona/models';

/**
 * Transforms api prescription set medication
 *
 * @param apiMedication ApiModels.PrescriptionSetMedication
 */
export const transformApiPrescriptionSetMedication = (
    apiMedication: ApiModels.PrescriptionSetMedication,
): PrescriptionSetMedication => {
    const model: PrescriptionSetMedication = {
        id: apiMedication.id,
        prescriptionSetId: apiMedication.prescription_set_id,
        standardMedicationSetId: (apiMedication.standard_medication_set as any)?.id,
        instructions: apiMedication.instructions,
    };
    return model;
};

/**
 * Transform api prescription set medications
 *
 * @param apiMedications ApiModels.PrescriptionSetMedication[]
 */
export const transformApiPrescriptionSetMedications = (
    apiMedications: ApiModels.PrescriptionSetMedication[],
): PrescriptionSetMedication[] => {
    return apiMedications.map(apiMedication => transformApiPrescriptionSetMedication(apiMedication));
};

/**
 * Transforms to api prescription set medication
 *
 * @deprecated
 * @param prescriptionSetMedication PrescriptionSetMedication
 * @param setId EntityId<PrescriptionSet>
 */
export const transformToApiPrescriptionSetMedication = (
    prescriptionSetMedication: PrescriptionSetMedication,
    setId?: EntityId<PrescriptionSet>,
): ApiModels.PrescriptionSetMedication => {
    /** required fields */
    const apiMedication: ApiModels.PrescriptionSetMedication = {
        standard_medication_set: prescriptionSetMedication.standardMedicationSet.id,
        prescription_set_id: setId as string,
    } as any;

    if (prescriptionSetMedication.instructions) {
        apiMedication.instructions = prescriptionSetMedication.instructions;
    }
    return apiMedication;
};
