import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from '@environment';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { INITIAL_REDUCERS, INITIAL_STATE, REDUCER_FACTORY, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ErrorEffects, InitEffects, RouterEffects, UiEffects } from './effects';
import { CustomRouterSerializer } from './helpers';
import { CORE_REDUCER_MAP, coreReducerMap } from './reducers';
import { ACTIONS_BLOCK_LIST, initialStateFactory, META_REDUCERS } from './store-setup';

/** Array of effects */
const STORE_EFFECTS = [
    //
    ErrorEffects,
    InitEffects,
    RouterEffects,
    UiEffects,
];

/**
 * Root Store Module
 *
 * Encapsulates all declarations & providers to keep clean structure of CoreModule
 */
@NgModule({
    imports: [
        StoreModule.forRoot(CORE_REDUCER_MAP, { metaReducers: META_REDUCERS }),
        EffectsModule.forRoot([...STORE_EFFECTS]),
        StoreDevtoolsModule.instrument({
            features: {
                test: false,
            },
            name: 'MONA-TERMINAL',
            maxAge: 1000,
            logOnly: environment.production || environment.environment === 'PROD',
            actionsBlocklist: ACTIONS_BLOCK_LIST,
        }),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomRouterSerializer,
            navigationActionTiming: NavigationActionTiming.PostActivation,
        }),
    ],
})
export class RootStoreModule {
    /**
     * INFO: add comment
     */
    static forRoot(): ModuleWithProviders<RootStoreModule> {
        return {
            ngModule: RootStoreModule,
            providers: [
                { provide: INITIAL_STATE, useFactory: initialStateFactory, deps: [INITIAL_REDUCERS, REDUCER_FACTORY] },
                { provide: CORE_REDUCER_MAP, useValue: coreReducerMap },
            ],
        };
    }
}
