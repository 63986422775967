import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { filter, startWith, take, takeWhile } from 'rxjs/operators';
import { Async, AsyncComponent } from '@mona/shared/utils';

/**
 * Dialog that pops up when a call is active and the user tries to logout
 */
@Async()
@Component({
    selector: 'app-active-call-dialog',
    templateUrl: './active-call-dialog.component.html',
    styleUrls: ['./active-call-dialog.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ActiveCallDialog extends AsyncComponent implements OnInit, OnDestroy {
    /**
     * Defines if the timer should run
     */
    isTimerActive = true;

    /**
     * Progress for countdown button indicator
     */
    timer$ = interval(40) // ~ 6 seconds until 100 %
        .pipe(
            // Ensure that the first value is not delayed
            startWith(() => 0),
            take(102),
            takeWhile(() => this.isTimerActive),
        );

    /**
     * Constructor
     * @param dialogRef MatDialogRef<ActiveCallDialog>
     */
    constructor(private dialogRef: MatDialogRef<ActiveCallDialog>) {
        super();
    }

    /**
     * NG Hook
     */
    ngOnInit(): void {
        // Subscribe to the timer
        this.async(
            this.timer$
                .pipe(
                    filter(val => val === 100),
                    take(1),
                )
                .subscribe(() => this.hold()),
        );
    }

    /**
     * Ng Hook
     */
    ngOnDestroy(): void {
        // Ensure to stop timer on modal close
        this.stopTimer();

        super.ngOnDestroy();
    }

    /**
     * Hang up the active call
     */
    hangUp(): void {
        // Stop countdown
        this.stopTimer();
        this.dialogRef.close();
    }

    /**
     * Hold the active call
     */
    hold(): void {
        // Stop countdown
        this.stopTimer();
        this.dialogRef.close(true);
    }

    /**
     * Stops the timer
     */
    stopTimer() {
        this.isTimerActive = false;
    }
}
