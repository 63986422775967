import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { Encounter, Procedure } from '@mona/models';
import { transformApiProcedures } from './transforms';

/**
 * API abstraction layer for the procedures
 */
@Injectable({
    providedIn: 'root',
})
export class ProceduresApi {
    apiBase = '/pdms/procedures/';

    /**
     * Constructor
     *
     * @param http HttpClient
     * HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get procedures
     *
     * @param encounterId string
     * EncounterId
     */
    getProcedures(encounterId: string): Observable<Procedure[]> {
        return this.http
            .get<ApiModels.Procedure[]>(this.apiBase, {
                params: {
                    encounter_id: `${encounterId}`,
                },
            })
            .pipe(map(apiProcedures => transformApiProcedures(apiProcedures)));
    }
}
