import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsModule } from '@mona/shared/utils';
import { UiInfoListItemModule } from '../../../info-list-item';
import { DrawerNavItemComponent } from './drawer-nav-item.component';
import { DrawerNavSubItemComponent } from './nav-subitem/drawer-nav-subitem.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [DrawerNavItemComponent, DrawerNavSubItemComponent],
    imports: [
        UtilsModule,
        MatCardModule,
        MatButtonModule,
        MatDividerModule,
        MatExpansionModule,
        MatIconModule,
        MatRippleModule,
        MatTooltipModule,
        UiInfoListItemModule,
        NgOptimizedImage,
    ],
    exports: [DrawerNavItemComponent],
})
export class DrawerNavItemModule {}
