import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { UiOverlayContainerContent } from '../models/overlay-container-content.type';
import { UiPopoverRenderMethod } from '../models/popover-render-method.type';
import { UiPopoverRef } from './popover-reference';

/**
 * The host component that embeds the provided content
 */
@Component({
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UiPopoverComponent implements OnInit {
    ngRenderMethod: UiPopoverRenderMethod;
    content: UiOverlayContainerContent;
    context: { data: any; close: any };
    isDraggable?: boolean;
    panelClass?: string | string[];

    /**
     * Constructor
     *
     * @param popoverRef
     */
    constructor(private popoverRef: UiPopoverRef) {}

    /**
     * Lifecycle
     */
    ngOnInit() {
        // this.panelClass = this.popoverRef.;
        this.content = this.popoverRef.content;
        this.isDraggable = this.popoverRef.isDraggable;

        if (typeof this.content === 'string') {
            this.ngRenderMethod = 'text';
        } else if (this.content instanceof TemplateRef) {
            this.ngRenderMethod = 'template';
            this.context = {
                data: this.popoverRef.data,
                close: this.popoverRef.close.bind(this.popoverRef),
            };
        } else {
            this.ngRenderMethod = 'component';
            this.context = {
                data: this.popoverRef.data,
                close: this.popoverRef.close.bind(this.popoverRef),
            };
        }
    }
}
