import { createAction, props } from '@ngrx/store';
import { RegisterDeviceResult } from '@mona/models';
import { FailedAction } from '@mona/store';

/**
 * Setup actions
 */
export class SetupActions {
    /**
     * Register the device at core
     */
    static registerDevice = createAction('SETUP:REGISTER_DEVICE');
    static registerDeviceSuccess = createAction('SETUP:REGISTER_DEVICE_SUCCEEDED', props<RegisterDeviceResult>());
    static registerDeviceFailure = createAction('SETUP:REGISTER_DEVICE_FAILED', props<FailedAction>());

    /**
     * Check device is activated
     */
    static checkIsDeviceActivated = createAction('SETUP:CHECK_IS_DEVICE_ACTIVATED');
    static checkIsDeviceActivatedSuccess = createAction(
        'SETUP:CHECK_IS_DEVICE_ACTIVATED_SUCCEEDED',
        props<RegisterDeviceResult>(),
    );
    static checkIsDeviceActivatedFailure = createAction(
        'SETUP:CHECK_IS_DEVICE_ACTIVATED_FAILED',
        props<FailedAction>(),
    );
    static clearDeviceActivatedStatus = createAction('SETUP:CLEAR_DEVICE_ACTIVATED_STATUS');
}
