import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { Bed, Encounter, Ward } from '@mona/models';
import { notEmpty } from '@mona/shared/utils';
import { EncountersState, RelocateEncounterData } from '../entities';
import { EncountersActions, EncountersSelectors } from '../state';

/**
 * Encounters service
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessEncountersFacade {
    isLoading$: Observable<boolean> = this.store.select(EncountersSelectors.selectEncountersIsLoading);
    /** Encounters ids */
    encounterIds$: Observable<EntityId<Encounter>[]> = this.store.select(
        EncountersSelectors.selectEncountersIds,
    ) as Observable<EntityId<Encounter>[]>;
    /** Encounters array */
    encounters$: Observable<Encounter[]> = this.store.select(EncountersSelectors.selectAllEncounters);
    /** Get encounters by bed */
    encountersGroupedByBed$: Observable<{ [bedId: string]: Encounter }> = this.store.select(
        EncountersSelectors.selectAllEncountersGroupedByBed,
    );
    /** Select selected encounter */
    selectedEncounter$: Observable<Encounter> = this.store
        .select(EncountersSelectors.selectSelectedEncounter)
        .pipe(distinctUntilChanged(), notEmpty());
    /** Select selected encounter, allow empty value */
    selectedEncounterNotFiltered$: Observable<Encounter> = this.store
        .select(EncountersSelectors.selectSelectedEncounter)
        .pipe(distinctUntilChanged());
    /** Select selected encounter ID */
    selectedEncounterId$: Observable<EntityId<Encounter>> = this.store
        .select(EncountersSelectors.selectSelectedEncounterId)
        .pipe(distinctUntilChanged(), notEmpty());
    /** Select assigned encounter ID */
    assignedEncounterId$: Observable<EntityId<Encounter>> = this.store
        .select(EncountersSelectors.selectAssignedEncounterId)
        .pipe(distinctUntilChanged());
    /**  Select relocate encounter data */
    relocateEncounterData$: Observable<RelocateEncounterData> = this.store.select(
        EncountersSelectors.selectRelocateEncounterData,
    );
    /**  Emits when single encounter load finished */
    singleEncounterLoaded$ = this.actionsObserver$.pipe(ofType(EncountersActions.loadSingleEncounterSuccess));

    /**
     * Constructor
     *
     * @param store Store
     * @param actionsObserver$ ActionsSubject
     */
    constructor(private store: Store<EncountersState>, private actionsObserver$: ActionsSubject) {}

    /**
     * Get encounter by id
     *
     * @param encounterId encounterId
     */
    getEncounter(encounterId: EntityId<Encounter>): Observable<Encounter> {
        return this.store.select(EncountersSelectors.selectEncounterById(encounterId));
    }

    //#region Actions

    /**
     * Set assigned encounter
     *
     * @param encounterId
     */
    setAssignedEncounter(encounterId: EntityId<Encounter>) {
        this.store.dispatch(EncountersActions.setAssignedEncounter({ encounterId }));
    }
    /**
     * Triggers ALL encounters loading
     *
     * @param active
     */
    loadAllEncounters(active = true) {
        this.store.dispatch(EncountersActions.loadEncounters({ active }));
    }

    /**
     * Triggers encounters loading
     *
     * @param wardId
     * @param active
     */
    loadEncounters(wardId: EntityId<Ward>, active: boolean) {
        this.store.dispatch(EncountersActions.loadEncounters({ wardId, active }));
    }

    /**
     * Select encounter by id
     *
     * @param encounterId encounterId
     */
    selectEncounter(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(EncountersActions.selectEncounter({ encounterId }));
    }

    /**
     * Loads the current encounter
     *
     * @param encounterId EntityId<Encounter>
     */
    loadSingleEncounter(encounterId: EntityId<Encounter>): void {
        this.store.dispatch(EncountersActions.loadSingleEncounter({ encounterId }));
    }

    /**
     * Triggers encounter relocation
     *
     * @param encounter encounter
     * @param fromBedId bedId
     * @param bedId bedId
     * @param wardId wardId
     * @param shouldIncludeEncountersReloading (reload from patient list, do not reload from encounter screen)
     */
    relocateEncounter(
        encounter: Encounter,
        fromBedId: EntityId<Bed>,
        bedId: EntityId<Bed>,
        wardId: EntityId<Ward>,
        shouldIncludeEncountersReloading = false,
    ) {
        this.store.dispatch(
            EncountersActions.relocateEncounter({
                encounter,
                fromBedId,
                bedId,
                wardId,
                shouldIncludeEncountersReloading,
            }),
        );
    }

    /**
     * Clears current relocate data
     */
    clearRelocateData(): void {
        this.store.dispatch(EncountersActions.relocateEncounterClear());
    }

    /**
     * Triggers ending an encounter by discharging the patient
     *
     * @param encounterId encounterId
     * @param discharge_reason
     * @param custom_text
     */
    endEncounter(encounterId: EntityId<Encounter>, discharge_reason: string, custom_text: string) {
        this.store.dispatch(EncountersActions.endEncounter({ encounterId, discharge_reason, custom_text }));
    }

    /**
     * Clears encounters
     */
    clearEncounters(): void {
        this.store.dispatch(EncountersActions.clearEncounters());
    }

    /**
     * Clears encounters
     */
    clearRelocateEncounter(): void {
        // this.store.dispatch(EncountersActions.clearEncounters());
    }

    /**
     * Clears encounter
     */
    clearCurrentEncounter() {
        this.store.dispatch(EncountersActions.selectEncounter(null));
    }

    /**
     * Update the encounters state by extending current state with payload from Subscription message
     *
     * @param encounter
     */
    insertOrUpdateEncounter(encounter: Encounter) {
        // TODO: remove taking 'vitalSigns' from local store, when BE will send 'vitalSigns' form WebSockets handlePDMSUpdatesFromSubscription() method
        this.getEncounter(encounter.id)
            .pipe(first())
            .subscribe(selectedEncounter => {
                (encounter['vitalSigns'] as any) = selectedEncounter['vitalSigns'];
                this.store.dispatch(EncountersActions.upsertEncounter({ encounter }));
            });
    }

    /**
     * Remove specific encounter (from state)
     *
     * @param encounter
     */
    removeEncounter(encounter: Encounter) {
        this.store.dispatch(EncountersActions.removeEncounter({ encounter }));
    }

    //#endregion Actions
}
