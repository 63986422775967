import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { LabValue, PageableModel } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    ChangeLogState,
    getChangesData,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { Logger } from '@mona/shared/logger';
import { LabValuesApi } from '../../infrastructure';
import { LabValuesAction } from '../actions';
import { selectAllLabValues } from '../selectors';

/**
 * Lab values effects
 */
@Injectable()
export class LabValuesEffects {
    private logger = new Logger();

    /**
     * Load lab values effect
     */
    loadLabValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LabValuesAction.loadLabValues),
            withCurrentEncounterId(),
            concatMap(([{ params, url }, encounter_id]) =>
                this.labValuesApi.getPageableLabValues({ ...params, encounter_id }, url).pipe(
                    catchError(error => {
                        this.logger.error('Error loading lab values', error);
                        return of({ results: [] } as PageableModel<LabValue>);
                    }),
                ),
            ),
            concatLatestFrom(() => this.store$.select(ChangeLogSelectors.getChangesMap)),
            map(([data, changesMap]) => {
                const changes = changesMap['LabValue'] || [];
                if (changes.length) {
                    const { results } = data;
                    data.results = applyInstancesChanges(results, changes) as any;
                }
                return LabValuesAction.loadLabValuesSuccess(data);
            }),
            catchError(error => of(LabValuesAction.loadLabValuesFailed({ error }))),
        ),
    );

    /** Listen change save success & add one - realistic update */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.saveChangeAction.succeededAction),
                withLatestFrom(
                    this.store$.select(selectAllLabValues),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, labValues, changesMap]) => {
                    const changes = changesMap['LabValue'] || [];
                    if (changes.length) {
                        const data = getChangesData(labValues, changes);
                        this.store$.dispatch(LabValuesAction.upsertLabValues({ data }));
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.store$.select(selectAllLabValues),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, data, changesMap]) => {
                    const changes = changesMap['LabValue'] || [];

                    if (changes.length) {
                        const { toRemove, toUpdate } = getPersistedChangesData(data, changes);

                        this.store$.dispatch(
                            LabValuesAction.addChanges({
                                toUpdateEntities: toUpdate || [],
                                toRemoveIds: toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Request lab value types dictionary for lab values
     */
    loadLabValueTypesSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LabValuesAction.loadLabValuesSuccess),
                tap(({ results }) => {
                    // INFO: this is not needed anymore, because we are loading the dictionary on app load
                    // Check with BE/Arch team if this is still needed
                    /* const codes = uniqArrayAttrs(results, 'code');
                    if (codes.length) {
                        this.store$.dispatch(TerminologyActions.loadLabValueTypesAction.action({ params: { codes } }));
                    } */
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param labValuesApi LabValuesApi
     * @param store$
     */
    constructor(
        private actions$: Actions,
        private labValuesApi: LabValuesApi,
        private store$: Store<{ changelogData: ChangeLogState }>,
    ) {}
}
