/* eslint-disable jsdoc/require-jsdoc */
import camelcaseKeys from 'camelcase-keys';
import { plainToInstance } from 'class-transformer';
import { User } from './user.model';

/**
 * Auth Flow type
 */
export enum AuthFlow {
    'signin' = 'signin',
    'verify' = 'verify',
}

/**
 * SignIn Type Enum
 */
export enum AuthTypeEnum {
    Rfid = 0,
    Credentials = 1,
    All = 2,
}
/**
 * Token Status
 */
export enum TokenStatus {
    PENDING = 'PENDING',
    VALIDATING = 'VALIDATING',
    VALID = 'VALID',
    INVALID = 'INVALID',
}

/** PractitionerTokenResponse schema from OpenApi */
export interface ApiTokenResponse {
    token: string;
    refresh_token: string;
    rfid?: string;
}

/**
 * Token response from PractitionerTokenResponse
 */
export class TokenResponse {
    token: string;
    refreshToken: string;
    rfid?: string;
    /**
     * DTO
     * @param res
     */
    static toModel(res: ApiTokenResponse): TokenResponse {
        return plainToInstance(TokenResponse, camelcaseKeys(res));
    }
}

export const AUTH_SIGNIN_DIALOG_ID = 'authenticate';
export const AUTH_LOCKSCREEN_DIALOG_ID = 'lockscreen';

/**
 * App auth state
 */
export interface AuthState {
    /**
     * Is RFID card been activated
     */
    activatedRFIDCard: boolean;
    /**
     * Is authenticated
     */
    isAuthenticated: boolean;
    /**
     * Current user
     */
    user: User;
    /**
     * Access token status
     */
    accessTokenStatus: TokenStatus;
    /**
     * Refresh token status
     */
    refreshTokenStatus: TokenStatus;
    /**
     * Current user permissions
     */
    permissions: string[];
    /**
     * Current user roles
     */
    roles: string[];
    /**
     * Is loading
     */
    isLoading: boolean;
    /**
     * Error
     */
    error: any;
    /**
     * Last scanned RFID of any user
     */
    rfid: string;
}
