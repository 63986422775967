import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '@mona/config';
import {
    isAuthLoginRequest,
    isConfigRequest,
    isLocalRequest,
    isPermissionsRequest,
    isTokenRefreshRequest,
} from '../helpers';

/**
 * Language interceptor which adds custom header
 */
@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    /**
     * Constructor
     *
     * @param configService
     */
    constructor(private readonly configService: ConfigService) {}

    /**
     * Interception logic
     *
     * @param request HttpRequest
     * @param next HttpHandler
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // No need to add header for assets loading and when config is not requested yet
        if (
            isLocalRequest(request) ||
            isConfigRequest(request) ||
            isAuthLoginRequest(request) ||
            isTokenRefreshRequest(request) ||
            isPermissionsRequest(request)
        ) {
            return next.handle(request);
        }
        return next.handle(
            request.clone({
                setHeaders: {
                    'Accept-Language': this.configService.get('uiLanguage') || 'en-GB',
                },
            }),
        );
    }
}
