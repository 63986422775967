import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureFlagsDirective } from './feature-flags.directive';

/**
 * FeatureFlag Module
 */
@NgModule({
    declarations: [FeatureFlagsDirective],
    imports: [CommonModule],
    exports: [FeatureFlagsDirective],
})
export class FeatureFlagsModule {}
