import { ApiModels } from '@mona/api';
import { MedicationUnit } from '@mona/models';

/**
 * Transforms api medication
 * @param apiMedicationUnit Medication
 */
export const transformApiMedicationUnit = (apiMedicationUnit: ApiModels.MedicationUnit): MedicationUnit => {
    return {
        unit: apiMedicationUnit.unit,
        categoryCode: apiMedicationUnit.medication_category,
        id: `${apiMedicationUnit.medication_category.toLocaleLowerCase()}_${apiMedicationUnit.unit.toLowerCase()}`,
    };
};

/**
 * Transforms api medication
 * @param medication Medication
 */
export const transformToApiMedicationUnit = (medication: MedicationUnit): Partial<ApiModels.MedicationUnit> => {
    return {
        medication_category: medication.categoryCode,
        unit: medication.unit,
    };
};

/**
 * Transform api medication
 * @param apiMedicationUnits ApiModels.Medication[]
 */
export const transformApiMedicationUnits = (apiMedicationUnits: ApiModels.MedicationUnit[]): MedicationUnit[] => {
    return apiMedicationUnits.map(apiMedicationUnit => transformApiMedicationUnit(apiMedicationUnit));
};
