import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { WithPermissions } from '@mona/auth';
import { DynamicPdmsPermissionEnum, ProcedurePrescription } from '@mona/models';
import { transformApiProcedurePrescriptions } from './transforms';

/**
 * API abstraction layer for the procedures prescriptions API
 */
@Injectable({
    providedIn: 'root',
})
export class ProcedurePrescriptionsApi {
    apiBase = '/pdms/procedure-prescriptions/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get procedure prescriptions
     *
     * @param encounterId string
     */
    @WithPermissions<Observable<ProcedurePrescription[]>>(DynamicPdmsPermissionEnum.CARE_PROCEDURES_ACCESS, of([]))
    getProcedurePrescriptions(encounterId: string): Observable<ProcedurePrescription[]> {
        return this.http
            .get<ApiModels.ProcedurePrescription[]>(this.apiBase, {
                params: {
                    encounter_id: `${encounterId}`,
                },
            })
            .pipe(map(apiProcedures => transformApiProcedurePrescriptions(apiProcedures)));
    }
}
