import { Transform } from 'class-transformer';
import { isValid, lightFormat } from 'date-fns';

/**
 * Transform Date Decorator
 *
 * @descr Defines a custom logic for Date value transformation used in `class-transformer`
 * - can be applied to properties only
 * - does not work on plain objects, only on class instances
 * @param format - locale-agnostic string format accepted by date-fns, e.g. yyyy-MM-dd
 * @param resetTime
 * @augments Transform
 * @example
 * @TransformDate('yyyy-MM-dd') datetime: Date;
 */
export function TransformDate(format = '', resetTime = false) {
    const toPlain = Transform(
        ({ value }) => {
            if (!isValid(value)) {
                return;
            }
            if (format) {
                try {
                    return lightFormat(value, format);
                } catch (error) {
                    return;
                }
            }
            return value.toISOString();
        },
        { toPlainOnly: true },
    );

    const toClass = Transform(
        ({ value }) => {
            if (!value) {
                return;
            }
            const date = new Date(value);
            if (resetTime) {
                date.setHours(0, 0, 0, 0);
            }
            return date;
        },
        { toClassOnly: true },
    );

    return function (target: any, key: string) {
        toPlain(target, key);
        toClass(target, key);
    };
}
