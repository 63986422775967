import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mapTo, take } from 'rxjs/operators';
import { BaseMedication, BaseProcedurePrescription, PrescriptionSet, Procedure } from '@mona/models';
import { PrescriptionSetState } from '../entities';
import { PrescriptionSetActions } from '../state';
import {
    selectPrescriptionSets,
    selectSelectedPrescriptionSetLoading,
    selecteActivePrescriptionSet,
    selectedPrescriptionSetById,
} from '../state/selectors';

/**
 * DataAccessPrescriptionSetsFacade
 *
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessPrescriptionSetsFacade {
    /** is loading */
    readonly isLoading$: Observable<boolean> = this.store.select(selectSelectedPrescriptionSetLoading);

    /** active prescription set */
    readonly activePrescriptionSet$ = this.store.select(selecteActivePrescriptionSet);

    /** prescription sets */
    readonly prescriptionSets$ = this.store.select(selectPrescriptionSets);

    /**
     * Constructor
     *
     * @param actionsObserver$ ActionsSubject
     * @param store Store<PrescriptionSetState>
     */
    constructor(private actionsObserver$: ActionsSubject, private store: Store<PrescriptionSetState>) {}

    /**
     * Load prescription sets
     */
    loadPrescriptionSets(): void {
        this.store.dispatch(PrescriptionSetActions.loadPrescriptionSets());
    }

    /**
     * load prescription set by id
     *
     * @param id
     */
    loadPrescriptionSetById(id: string): void {
        this.store.dispatch(PrescriptionSetActions.loadPrescriptionSetById({ id }));
    }

    /**
     * get prescription set by id
     *
     * @param id
     */
    getPrescriptionSetById(id: string): Observable<PrescriptionSet | undefined> {
        return this.store.select(selectedPrescriptionSetById(id));
    }

    /**
     * Create prescription set
     *
     * @param name string
     */
    createPrescriptionSet(name: string): void {
        this.store.dispatch(PrescriptionSetActions.addPrescriptionSet({ name }));
    }

    /**
     * Delete prescription set
     *
     * @param id Prescription set id
     */
    deletePrescriptionSet(id: string): void {
        this.store.dispatch(PrescriptionSetActions.deletePrescriptionSet({ id }));
    }

    /**
     * Delete prescription set procedure
     *
     * @param setId string
     * @param procedureId string
     */
    deletePrescriptionSetProcedure(setId: string, procedureId: string): void {
        this.store.dispatch(PrescriptionSetActions.deletePrescriptionSetProcedure({ setId, procedureId }));
    }

    /**
     * Update prescription set
     *
     * @param id string
     * @param name string
     */
    updatePrescriptionSet(id: string, name: string): void {
        this.store.dispatch(
            PrescriptionSetActions.updatePrescriptionSet({
                update: {
                    id,
                    changes: { name },
                },
            }),
        );
    }

    /**
     * Delete prescription set medication
     *
     * @param setId string
     * @param standardMedicationSetId string
     */
    deletePrescriptionSetMedication(setId: string, standardMedicationSetId: string): void {
        this.store.dispatch(PrescriptionSetActions.deletePrescriptionSetMedication({ setId, standardMedicationSetId }));
    }

    /**
     * Practitioner is able to add the prescription set to the prescription list
     *
     * @param prescriptionSet
     */
    createPrescriptionsBulk(prescriptionSet: PrescriptionSet): Observable<boolean> {
        this.store.dispatch(PrescriptionSetActions.createPrescriptionsBulk({ prescriptionSet }));

        return this.actionsObserver$.pipe(
            ofType(PrescriptionSetActions.createPrescriptionsBulkSuccess),
            take(1),
            mapTo(true),
        );
    }

    /**
     * Upsert prescription set medication
     *
     * @param setId string
     * @param procedure BaseProcedure
     */
    upsertPrescriptionSetProcedure(setId: string, procedure: BaseProcedurePrescription): void {
        this.store.dispatch(PrescriptionSetActions.upsertPrescriptionSetProcedure({ setId, procedure }));
    }

    /**
     * Upsert prescription set medication
     *
     * @param setId string
     * @param standardMedication MedicationFormPayload
     */
    upsertPrescriptionSetMedication(setId: string, standardMedication: BaseMedication): void {
        this.store.dispatch(PrescriptionSetActions.upsertPrescriptionSetMedication({ setId, standardMedication }));
    }

    /**
     * load and upsert active prescription set
     *
     * @param id
     */
    loadAndSetActivePrescriptionSet(id: string): void {
        this.store.dispatch(PrescriptionSetActions.loadAndSetActivePrescriptionSet({ id }));
    }

    /**
     * Upsert active prescription set for
     *
     * @param prescriptionSet
     */
    upsertActivePrescriptionSet(prescriptionSet: PrescriptionSet): void {
        this.store.dispatch(PrescriptionSetActions.upsertActivePrescriptionSetAsCopy({ prescriptionSet }));
    }

    /** clear active prescription set */
    clearActivePrescriptionSet(): void {
        this.store.dispatch(PrescriptionSetActions.clearActivePrescriptionSet());
    }
}
