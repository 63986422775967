import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
    DeviceEffects,
    deviceReducersFactory,
    DEVICE_REDUCERS_TOKEN,
    NetworkManagerEffects,
    SetupEffects,
} from './state';

/**
 * DeviceDataAccessDeviceModule
 */
@NgModule({
    imports: [
        StoreModule.forFeature('device', DEVICE_REDUCERS_TOKEN),
        EffectsModule.forFeature([
            //
            DeviceEffects,
            NetworkManagerEffects,
            SetupEffects,
        ]),
    ],
    providers: [
        {
            provide: DEVICE_REDUCERS_TOKEN,
            useFactory: deviceReducersFactory,
        },
    ],
})
export class DataAccessDeviceModule {}
