import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChangeLogEntry, ChangeLogModel, ChangeLogModelKey } from '@mona/models';
import { ChangeLogState, changeLogFeatureKey } from '../../entities';

export const selectChangeLogState = createFeatureSelector<ChangeLogState>(changeLogFeatureKey);

/**
 * Get load changes async action
 */
export const getLoadChangesAction = createSelector(selectChangeLogState, s => s.loadChangesAction);

/**
 * Get save change async action
 */
export const getSaveChangeAction = createSelector(selectChangeLogState, s => s.saveChangeAction);
/**
 * Get save changes async action
 */
export const getSaveChangesAction = createSelector(selectChangeLogState, s => s.saveChangesAction);

/**
 * Get persist change async action
 */
export const getPersistChangeAction = createSelector(selectChangeLogState, s => s.persistChangesAction);

/**
 * Get discard change async action
 */
export const getDiscardChangeAction = createSelector(selectChangeLogState, s => s.discardChangesAction);

/**
 * Checks if there are pending changes
 */
export const hasChanges = createSelector(selectChangeLogState, s => s.changeLog.sortedChanges?.length > 0);

/**
 * Get pending changes
 */
export const getChanges = createSelector(selectChangeLogState, s => s?.changeLog.sortedChanges || []);

/**
 * Get changes map
 */
export const getChangesMap = createSelector(selectChangeLogState, s => s.changeLog.changesMap || {});

/**
 * Get model changes
 *
 * @param model ChangeLogModelKey
 */
export const getModelChanges = (model: ChangeLogModelKey) =>
    createSelector(selectChangeLogState, s => {
        const changesMap = s.changeLog.changesMap;
        return changesMap && (changesMap[model] as ChangeLogEntry<ChangeLogModel>[]);
    });

/**
 * get sorted changes
 */
export const getSortedChanges = createSelector(selectChangeLogState, s => s.changeLog.sortedChanges || []);
