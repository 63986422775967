import { ApiModels } from '@mona/api';

/**
 * History event codes
 * @deprecated
 */
export enum _HistoryEventCode {
    /** encounter_started */
    ENCOUNTER_STARTED = 'encounter_started',
    /** ventilation_started */
    VENTILATION_STARTED = 'ventilation_started',
    /** ventilation_stopped */
    VENTILATION_STOPPED = 'ventilation_stopped',
    /** body_examination */
    BODY_EXAMINATION = 'body_examination',
}

/**
 * History event codes - copy of {@link ApiModels.PatientHistory.EventCodeEnum }
 *
 * IMPORTANT: Copy is needed to prevent JIT compliation error
 */
export type HistoryEventCode =
    | 'ventilation_started'
    | 'ventilation_stopped'
    | 'encounter_started'
    | 'body_examination'
    | 'encounter_ended';
export const HistoryEventCode = {
    EncounterStarted: 'encounter_started',
    EncounterEnded: 'encounter_ended',
    BodyExamination: 'body_examination',
    Started: 'ventilation_started' as HistoryEventCode,
    Stopped: 'ventilation_stopped' as HistoryEventCode,
};
