import { NgModule, Provider } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataAccessChangelogModule } from '@mona/pdms/data-access-changelog';
import { PrescriptionTerminologyGetter, PRESCRIPTION_TERMINOLOGY_GETTER } from '@mona/pdms/data-access-combined';
import { DataAccessEncounterDataModule } from '@mona/pdms/data-access-encounter-data';
import { DataAccessEncountersModule } from '@mona/pdms/data-access-encounters';
import {
    DataAccessEnovacomModule,
    EnovacomWsCredentialsGetter,
    ENOVACOM_WS_CREDENTIALS_GETTER,
} from '@mona/pdms/data-access-enovacom';
import { DataAccessExternalResourcesModule } from '@mona/pdms/data-access-external-resources';
import { DataAccessFhirModule } from '@mona/pdms/data-access-fhir';
import { DataAccessMedicationsModule } from '@mona/pdms/data-access-medications';
import { DataAccessNotificationsModule } from '@mona/pdms/data-access-notifications';
import { DataAccessPatientsModule } from '@mona/pdms/data-access-patients';
import { DataAccessPatientsAdmissionModule } from '@mona/pdms/data-access-patients-admission';
import { DataAccessPractitionersModule } from '@mona/pdms/data-access-practitioners';
import { DataAccessPrescriptionSetsModule } from '@mona/pdms/data-access-prescription-set';
import { DataAccessProceduresModule } from '@mona/pdms/data-access-procedures';
import { DataAccessRelativeContactsModule } from '@mona/pdms/data-access-relative-contacts';
import { DataAccessReportsModule } from '@mona/pdms/data-access-reports';
import { DataAccessTaskListModule } from '@mona/pdms/data-access-task-list';
import { DataAccessTerminologyModule, TerminologyService } from '@mona/pdms/data-access-terminology';
import { DataAccessWardsModule } from '@mona/pdms/data-access-wards';
import { UtilsModule } from '@mona/shared/utils';
import { PdmsShellRoutingModule } from './pdms-shell-routing.module';
import { PdmsShellComponent } from './pdms-shell.component';

const PDMS_MODULES = [
    DataAccessWardsModule,
    DataAccessEncountersModule,
    DataAccessEncounterDataModule,
    DataAccessChangelogModule,
    DataAccessMedicationsModule,
    DataAccessNotificationsModule,
    DataAccessPatientsModule,
    DataAccessPatientsAdmissionModule,
    DataAccessPractitionersModule,
    DataAccessReportsModule,
    DataAccessFhirModule,
    DataAccessTerminologyModule,
    DataAccessEnovacomModule,
    DataAccessProceduresModule,
    DataAccessPrescriptionSetsModule,
    DataAccessTaskListModule,
    DataAccessExternalResourcesModule,
    DataAccessRelativeContactsModule,
];
/**
 * Shared providers for PDMS data, which we need to use in PDMS shared ui modules and
 * where we don't need to include direct data-access dependency to be able
 * to properly setup testing & isolated development
 * e.g, {@link PrescriptionTerminologyGetter} used in presentational UI component {@link MedicationListComponent}
 * responsible just to display list of medicaments
 */
const PDMS_PROVIDERS: Provider[] = [
    {
        provide: PRESCRIPTION_TERMINOLOGY_GETTER,
        useFactory: medicationTerminologyGetterFn,
        deps: [TerminologyService],
    },
    {
        provide: ENOVACOM_WS_CREDENTIALS_GETTER,
        useFactory: enovacomWsCredsGetterFn,
        deps: [WINDOW],
    },
];

/**
 * Get terminology data - groups, categories, units, administrationMethods, solutions, frequencies, dosage
 *
 * @param terminologyFacade
 */
export function medicationTerminologyGetterFn(terminologyFacade: TerminologyService): PrescriptionTerminologyGetter {
    return () =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // FIXME: combineLatest only supports 6 args
        combineLatest<any>([
            terminologyFacade.getMedicationGroups(),
            terminologyFacade.getMedicationCategories(),
            terminologyFacade.getProcedureCategories(),
            terminologyFacade.getMedicationUnits(),
            terminologyFacade.getMedicationAdministrationMethods(),
            terminologyFacade.getMedicationSolutions(),
            terminologyFacade.getPrescriptionFrequencies(),
            terminologyFacade.getPrescriptionFrequencyTimes(),
            terminologyFacade.getDosageForms(),
        ]).pipe(
            map(
                ([
                    groups,
                    medicationCategories,
                    procedureCategories,
                    units,
                    administrationMethods,
                    solutions,
                    frequencies,
                    frequencyTimes,
                    dosageForms,
                ]) => ({
                    medicationGroups: groups,
                    medicationCategories,
                    procedureCategories,
                    medicationUnits: units,
                    medicationAdministrationMethods: administrationMethods,
                    medicationSolutions: solutions,
                    prescriptionFrequencies: frequencies,
                    prescriptionFrequencyTimes: frequencyTimes,
                    medicationDosageForms: dosageForms,
                }),
            ),
        );
}

/**
 * Get Enovacom basic authorization string
 *
 * @param window
 */
export function enovacomWsCredsGetterFn(window: Window): EnovacomWsCredentialsGetter {
    return () => window.btoa(process.env.ENOVACOM_WS_CREDENTIALS);
}

/**
 * Pdms shell module
 *
 * Loads all data-access libs which need to init state
 */
@NgModule({
    declarations: [PdmsShellComponent],
    imports: [UtilsModule, PdmsShellRoutingModule, ...PDMS_MODULES],
    providers: PDMS_PROVIDERS,
})
export class PdmsShellModule {}
