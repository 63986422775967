import { NgModule } from '@angular/core';
import { UiModule } from '@mona/ui';
import { LuminosityControlComponent } from './luminosity-control.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [LuminosityControlComponent],
    imports: [UiModule],
    exports: [LuminosityControlComponent],
    providers: [],
})
export class LuminosityControlModule {}
