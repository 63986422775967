/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { DateRange, MatCalendarCellClassFunction, MatDatepicker, MatMonthView } from '@angular/material/datepicker';
import { environment } from '@environment';
import { addDays, eachDayOfInterval, isAfter, isBefore, isSameDay, isValid, startOfDay, subDays } from 'date-fns';
import startOfToday from 'date-fns/startOfToday';
import { BehaviorSubject } from 'rxjs';
import { compact, isFunction, isNullOrUndefined, uiPure } from '@mona/shared/utils';
import { CALENDAR_CELL_WIDTH, UiCalendarCell } from './calendar-cell';

/**
 * Calendar slider component for date range
 *
 * - uses some concepts from {@link MatMonthView} and DatePicker
 * - used NativeDateAdapter for date processing & formatting
 */
@Component({
    selector: 'ui-calendar-slider',
    templateUrl: './calendar-slider.component.html',
    styleUrls: ['./calendar-slider.component.scss'],
    host: {
        class: 'ui-calendar-slider',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: DateAdapter,
            useClass: NativeDateAdapter,
        },
    ],
})
export class UiCalendarSliderComponent {
    /** Reference to {@link MatDatepicker} */
    @ViewChild('datepicker') datepicker: MatDatepicker<Date>;
    /**  Date range */
    private _dateRange: DateRange<Date>;
    /** Array of days to highlight in the current range */
    private _datesToHighlight: Set<number> = new Set();
    /** Active date */
    private _activeDate: Date;
    /**
     * Array of days in the current range
     */
    calendarCells$: BehaviorSubject<UiCalendarCell[]> = new BehaviorSubject([]);
    /** Dummy date at start of slider */
    startDay: UiCalendarCell;
    /** Dummy date at end of slider */
    endDay: UiCalendarCell;

    /** Use date picker when click on active date or via contextmenu */
    @Input() useDatePicker = coerceBooleanProperty(
        (localStorage['calendarSliderUseDatePicker'] ??= coerceBooleanProperty(
            environment.calendarSliderUseDatePicker,
        )),
    );

    /**  Date range */
    @Input() get dateRange(): DateRange<Date> {
        return this._dateRange;
    }
    set dateRange(value: DateRange<Date>) {
        if (
            isNullOrUndefined(value) ||
            (isNaN(value.start?.getTime()) && isNaN(value.end?.getTime()) && value.start > value.end)
        ) {
            return;
        }

        this._dateRange = value;
        this.init();
    }

    /** Min date */
    get minDate() {
        return this.dateRange?.start;
    }
    /** Max date */
    get maxDate() {
        return this.dateRange?.end;
    }

    /** Dates to highlight */
    @Input() set datesToHighlight(value: Date[]) {
        if (Array.isArray(value)) {
            this._datesToHighlight = new Set(value.map(startOfDay).map(d => d.getTime()));
        }
    }

    /** Active date */
    @Input() get activeDate(): Date {
        return this._activeDate;
    }
    /**
     * Active date setter
     * - updates {@link activeDate}
     * - updates {@link calendarCells$} to highlight active date
     * - emits {@link activeDateChange}
     */
    set activeDate(value: Date) {
        if (!isValid(value) || isSameDay(value, this._activeDate)) {
            return;
        }
        if (isFunction(this.validateBeforeDateChangeFn)) {
            const isValid = this.validateBeforeDateChangeFn(this.activeDate);
            if (!isValid) {
                return;
            }
        }
        this._activeDate = startOfDay(value) || startOfToday();
        this.updateActiveCalendarCell();
        // emit active date change in setter
        this.activeDateChange.emit(this.activeDate);
    }

    /**
     * Validator function to run before date change - SYNC !!!
     */
    @Input() validateBeforeDateChangeFn: (...args: any[]) => boolean;

    /** Emits when any date is activated. */
    @Output() readonly activeDateChange: EventEmitter<Date> = new EventEmitter<Date>();

    /** Active date index in array */
    activeDateIndex: number;

    /**
     * Function that can generate the extra classes that should be added to a calendar cell.
     *
     * @param cellDate
     * @param view
     */
    dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
        if (this._datesToHighlight.has(cellDate.getTime())) {
            return 'mat-calendar-body-cell-higlighted';
        }
        return '';
    };

    /**
     * Sets correct transform styles depend on current offset
     *
     * @param activeItemIndex
     * @returns NgStyle value
     */
    @uiPure
    getTranslateX(activeItemIndex: number): Record<string, string> {
        const scrollOffsetPx = activeItemIndex * CALENDAR_CELL_WIDTH;
        return {
            transform: `translateX(-${scrollOffsetPx}px)`,
        };
    }

    /** Handles user clicks on the previous button. */
    todayClicked(): void {
        this.activeDate = startOfToday();
    }

    /** Handles user clicks on the previous button. */
    previousClicked(): void {
        this.activeDate = subDays(this.activeDate, 1);
    }

    /** Handles user clicks on the next button. */
    nextClicked(): void {
        this.activeDate = addDays(this.activeDate, 1);
    }

    /** Whether the previous period button is enabled. */
    previousEnabled(): boolean {
        return isAfter(this.activeDate, this.minDate) && !isSameDay(this.activeDate, this.minDate);
    }

    /** Whether the next period button is enabled. */
    nextEnabled(): boolean {
        return isBefore(this.activeDate, this.maxDate) && !isSameDay(this.activeDate, this.maxDate);
    }
    /**
     * Called when a cell is clicked.
     *
     * @param cell
     * @param event
     */
    cellClicked(cell: UiCalendarCell, event: MouseEvent): void {
        if (cell.active) {
            this.openDatePicker(event);
        } else if (cell.enabled) {
            this.activeDate = cell.rawValue;
        }
    }

    /**
     * Open date picker
     *
     * @param event
     */
    openDatePicker(event: Event) {
        if (!this.useDatePicker) {
            return;
        }
        event.preventDefault();
        this.datepicker.open();
    }

    /** Creates instances of {@link UiCalendarCell} for the dates in this range. */
    private init() {
        if (this.dateRange) {
            this.startDay = UiCalendarCell.build(subDays(this.dateRange.start, 1), false);
            this.endDay = UiCalendarCell.build(addDays(this.dateRange.end, 1), false);
            const days = eachDayOfInterval(this.dateRange).map(date => UiCalendarCell.build(date));
            this.calendarCells$.next(days);
            if (!this.activeDate) {
                this._activeDate = this.dateRange.start;
            }
            this.updateActiveCalendarCell();
        }
    }

    /** Updates the active date inside days list, also in same loop finds active index */
    private updateActiveCalendarCell() {
        let days = this.calendarCells$.getValue();
        if (!days?.length) {
            return;
        }
        days = days.map((day, index) => {
            day.active = isSameDay(day.rawValue!, this._activeDate);
            if (day.active) {
                this.activeDateIndex = index;
            }
            return day;
        });
        this.calendarCells$.next(days);
    }
}
