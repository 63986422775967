/**
 * Api available services
 */
export enum ApiService {
    Core = 'core',
    Telemedicine = 'telemedicine',
}

/**
 * Apis config
 */
export interface ApiUrls {
    /**
     * Api base url
     */
    baseUrl: string;

    /**
     * Api telemedicine url
     */
    telemedicineUrl: string;

    /**
     * Telemedicine web socket url
     */
    telemedicineWsUrl: string;

    /**
     * Core ws url
     */
    coreWsUrl: string;
}
