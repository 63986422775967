import { InjectionToken } from '@angular/core';
import { Bed, Patient, Ward } from '@mona/models';

/**
 * Encounter patient & location data
 */
export type EncounterInfo = {
    bed: Bed;
    ward?: Ward;
    patient?: Patient;
};

/**
 * Encounter patient & location data token
 */
export const ENCOUNTER_INFO = new InjectionToken<EncounterInfo>('Encounter Info');
