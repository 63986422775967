import {
    MedicationPrescription,
    Practitioner,
    PractitionerRole,
    PrescriptionAdditionalFields,
    PrescriptionBadgeValues,
} from '@mona/models';
import { DialogData } from '@mona/ui';

type MandatoryPrescriptionFields = PrescriptionAdditionalFields & {
    /**
     * is approved
     */
    isApproved?: boolean;
    /**
     * practitioner id who last changed prescription
     */
    lastChangedBy?: string;
};

/**
 * Helper function to get unconfirmed prescriptions
 *
 * Prescription should be confirmed only if: Prescription is not approved (they
 * come already merged with stage changes) OR if current practitioner is not nurse (is doctor) we
 * check if last change was by nurse
 *
 * @param prescriptions
 * @param practitioner
 */
export function getUnconfirmedMedPrescriptions(
    prescriptions: MedicationPrescription[],
    practitioner: Practitioner,
): MedicationPrescription[] {
    return prescriptions.filter(prescription => isMedPrescriptionUnconfirmed(prescription, practitioner));
}

/**
 * check if prescriptions is unconfirmed
 *
 * Prescription should be confirmed only if: Prescription is not approved (they
 * come already merged with stage changes) OR if current practitioner is not nurse (is doctor) we
 * check if last change was by nurse
 *
 * @param prescription
 * @param practitioner
 */
export function isMedPrescriptionUnconfirmed(
    prescription: MedicationPrescription,
    practitioner: Practitioner,
): boolean {
    if (!prescription.isApproved) {
        return true;
    }

    if (prescription.isStopped && prescription.isApproved) {
        return false;
    }

    const lastChangedByNurse =
        prescription.lastChangedByRole === PractitionerRole.Nurse &&
        practitioner?.role.includes(PractitionerRole.Doctor);

    return !prescription.isApproved || lastChangedByNurse;
}

/**
 * Resolves prescriptions combined with last changed by role
 *
 * @param prescriptions T[]
 * @param practitioners EntityMap<Practitioner>
 */
export function applyLastChangedByToPrescriptions<T extends MandatoryPrescriptionFields>(
    prescriptions: T[],
    practitioners: EntityMap<Practitioner> = {},
): T[] {
    return prescriptions.map(prescription => {
        prescription = extendPrescriptionLastChangedBy(prescription, practitioners);

        if (prescription.isApproved && !prescription.hasChanges) {
            if (prescription.lastChangedByRole === PractitionerRole.Nurse) {
                // show exclamation mark if prescription is not approved && has changes && if nurse create it
                prescription.badge = PrescriptionBadgeValues.exclamationMark;
            } else {
                // show shield if prescription is saved
                prescription.badge = PrescriptionBadgeValues.shieldCheck;
            }
        } else {
            prescription.badge = PrescriptionBadgeValues.asterisk;
        }

        return prescription;
    });
}

/**
 * extend prescriptions with practitioner who last changed it
 *
 * @param prescriptions
 * @param practitionersMap
 */
export function extendPrescriptionsLastChangedBy<T extends MandatoryPrescriptionFields>(
    prescriptions: T[],
    practitionersMap: EntityMap<Practitioner>,
): T[] {
    return prescriptions.map(p => extendPrescriptionLastChangedBy(p, practitionersMap));
}

/**
 * extend prescription with practitioner who last changed it
 *
 * @param p
 * @param practitionersMap
 */
export function extendPrescriptionLastChangedBy<T extends MandatoryPrescriptionFields>(
    p: T,
    practitionersMap: EntityMap<Practitioner>,
): T {
    const mappedPrescription = { ...p };
    const lastChangeByPractitioner = practitionersMap[p.lastChangedBy];
    mappedPrescription.lastChangedByRole = lastChangeByPractitioner?.role.find(r => r !== PractitionerRole.Admin);
    mappedPrescription.lastChangedByName = lastChangeByPractitioner?.displayName;

    return mappedPrescription;
}

/**
 * Data for dialog
 */
export function confirmDialogData(): Partial<DialogData> {
    return {
        title: 'apps.patient.prescriptions.futurePrescription.title',
        description: 'apps.patient.prescriptions.futurePrescription.description',
        imgSrc: 'assets/images/question-date.svg',
        cancelBtn: '',
        confirmBtn: 'apps.patient.prescriptions.futurePrescription.ok',
    };
}
