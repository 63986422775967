import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TableDataItem } from '@mona/models';

/**
 * Detects if cell is removed
 */
@Pipe({
    name: 'isCellRemoved',
})
@Injectable({
    providedIn: 'root',
})
export class IsCellRemovedPipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param cell TableDataItem
     * @param columnTime string
     */
    transform(cell: TableDataItem<any>, columnTime: string): boolean {
        return !!(
            (cell.values && cell.values[columnTime]?.isStageRemoved) ||
            cell.children?.find(child => child.values[columnTime]?.isStageRemoved)
        );
    }
}
