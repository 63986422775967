import { Component, Input } from '@angular/core';
import { startOfToday } from 'date-fns';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    getEncounterViewDateRange,
    getEncounterViewSelectedDate,
    setEncounterViewSelectedDate,
} from '@mona/pdms/data-access-combined';
import { DateRangeInterval } from '@mona/shared/date';

/**
 * Shared encounter date filter component to be used in all views which need to filter entities by 1 Day
 */
@Component({
    selector: 'mona-encounter-date-filter',
    template: `
        <ui-calendar-slider
            [dateRange]="dateRange$ | async"
            [datesToHighlight]="datesToHighlight$ | async"
            [activeDate]="selectedDate$ | async"
            (activeDateChange)="setSelectedDate($event)"
            [validateBeforeDateChangeFn]="validateBeforeDateChangeFn"
        ></ui-calendar-slider>
    `,
})
export class EncounterDateFilterComponent {
    /**
     * Array of dates to highlight
     */
    @Input() datesToHighlight$ = of([]);
    /**
     * Validate before date change fn
     */
    @Input() validateBeforeDateChangeFn;

    /**
     * Encounter View Date range used to filter all views which use day calendar
     */
    @Input() dateRange$: Observable<DateRangeInterval> = getEncounterViewDateRange();

    /**
     * Currently selected date
     */
    selectedDate$ = getEncounterViewSelectedDate().pipe(
        // NOTE: fallback value when no selected date
        map(date => (date ? date : startOfToday())),
    );

    /**
     * Sets selected UI date
     */
    setSelectedDate = setEncounterViewSelectedDate();
}
