<div class="ui-drawer-navbar__container bg-primary">
    <mona-logo class="ui-drawer-navbar__logo" (dblclick)="logoDoubleClick($event)"></mona-logo>

    <time class="ui-drawer-navbar__time">
        {{ time$ | async | date: 'HH:mm' }}
    </time>

    <ng-template #navItem let-item>
        <button
            mat-button
            class="ui-drawer-navbar__button"
            (click)="handleItemClick($event, item)"
            [routerLink]="item.link"
            [queryParams]="item.queryParams"
            routerLinkActive="ui-drawer-navbar__button--active"
            [routerLinkActiveOptions]="rlaMatchOptions"
            matRipple
            [disabled]="item.disabled"
            [class.d-none]="item.hidden"
            [attr.data-testid]="'monaNavbarLink-' + item.link"
            ariaCurrentWhenActive="page"
        >
            <mat-icon
                class="ui-drawer-navbar__button-icon"
                [class.ui-drawer-navbar__button-icon--has-badge]="item.badgeIcon"
                ui-icon
                [svgIcon]="item.svgIcon || undefined"
                [matBadgeHidden]="!item.badgeIcon"
                matBadge="{{ item.badgeIcon }}"
                matBadgeColor="warn"
                matBadgePosition="above after"
                aria-hidden="false"
            >
                {{ item.icon }}
            </mat-icon>
            <span class="ui-drawer-navbar__button-label">{{ item.title | translate }}</span>
        </button>
    </ng-template>

    <div class="ui-drawer-navbar__nav" data-testid="monaNavbarLinks">
        <ng-template
            ngFor
            let-item
            let-count="count"
            [ngForOf]="items | slice: 0:(items.length >= 2 ? items.length - 2 : undefined)"
            [ngForTemplate]="navItem"
        ></ng-template>
        <ui-spacer></ui-spacer>
        <ng-template
            ngFor
            let-item
            [ngForOf]="items.length >= 2 ? (items | slice: items.length - 2:items.length) : []"
            [ngForTemplate]="navItem"
        ></ng-template>

        <ng-content select="[ui-drawer-navbar-user]"></ng-content>
    </div>
</div>
