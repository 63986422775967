import { ApiModels } from '@mona/api';
import { TherapyLimitations } from '@mona/models';

/**
 * Transforms API TherapyLimitation
 * @param apiTherapyLimitation ApiModels.TherapyLimitation
 */
export const transformApiTherapyLimitation = (
    apiTherapyLimitation: ApiModels.TherapyLimitations,
): TherapyLimitations => {
    return ApiModels.TherapyLimitations.DTO.toModel(apiTherapyLimitation);
};

/**
 * Transforms API TherapyLimitations
 * @param apiTherapyLimitations ApiModels.TherapyLimitations[]
 */
export const transformApiTherapyLimitations = (
    apiTherapyLimitations: ApiModels.TherapyLimitations[],
): TherapyLimitations[] => {
    return apiTherapyLimitations.map(apiTherapyLimitation => transformApiTherapyLimitation(apiTherapyLimitation));
};
