<ui-card
    *ngLet="{
        isLoading: isLoading$ | async,
        selectedTab: selectedTab$ | async,
        prescriptionsSets: prescriptionsSets$ | async,
        selectedPrescriptionSet: selectedPrescriptionSet$ | async,
        procedureCategories: procedureCategories$ | async,
        prescriptionFrequencies: prescriptionFrequencies$ | async,
        prescriptionFrequencyTimes: prescriptionFrequencyTimes$ | async,
        bloodAdministrations: bloodAdministrations$ | async,
        medicationSolutions: medicationSolutions$ | async,
        medicationDosageForms: medicationDosageForms$ | async,
        medicationUnits: medicationUnits$ | async,
        medicationAdministrationMethods: medicationAdministrationMethods$ | async
    } as $"
    class="add-prescription-set"
    [fullHeight]="true"
    [isLoading]="$.isLoading"
    [title]="getCardTitle($.selectedTab) | translate"
    [showBackBtn]="$.selectedTab > 0"
    [showCloseBtn]="true"
    (backClick)="activateTab($.selectedTab - 1)"
    (closeClick)="onClose()"
>
    <ng-container ui-card-content>
        <!-- Represent navigation inside sidenav outlet as tabs with hidden labels -->
        <mat-tab-group [selectedIndex]="$.selectedTab" mat-stretch-tabs="false">
            <!-- ADD PRESCRIPTIONS OPTIONS -->
            <mat-tab label="">
                <ng-template matTabContent>
                    <div class="add-prescription-set__list prescription-set-list-container">
                        <ui-info-list-item
                            class="mona-prescription-sets__list-item"
                            (click)="navigateToPrescriptionForm()"
                            [chevron]="true"
                            [divider]="true"
                            mat-ripple
                            data-testid="add-prescription-new"
                            id="add-prescription-new"
                        >
                            <div ui-title>{{ getListTitle() | translate }}</div>
                        </ui-info-list-item>
                        <ui-info-list-item
                            class="mona-prescription-sets__list-item"
                            [disabled]="!$.prescriptionsSets.length"
                            (click)="activateTab(tabsEnum.PrescriptionSets)"
                            [chevron]="true"
                            [divider]="true"
                            mat-ripple
                            id="add-prescription-set"
                            data-testid="add-prescription-set"
                        >
                            <div ui-title>{{ 'apps.patient.prescriptions.addPrescription.set' | translate }}</div>
                        </ui-info-list-item>
                    </div>
                </ng-template>
            </mat-tab>
            <!-- PRESCRIPTIONS SETS LIST -->
            <mat-tab label="">
                <ng-template matTabContent>
                    <div class="prescription-set-list-container" *ngIf="$.prescriptionsSets.length; else noSets">
                        <ui-info-list-item
                            [id]="'prescription-set-' + set.id"
                            [attr.data-testid]="'prescription-set-' + set.id"
                            *ngFor="let set of $.prescriptionsSets; let i = index"
                            class="mona-prescription-sets__list-item"
                            (click)="activateTab(tabsEnum.SelectPrescriptionSet, set)"
                            [chevron]="true"
                            [dense]="true"
                            [divider]="true"
                            mat-ripple
                        >
                            <div ui-title>{{ set.name }}</div>
                        </ui-info-list-item>
                    </div>
                    <ng-template #noSets>
                        {{ 'apps.settings.prescriptionSets.empty' | translate }}
                    </ng-template>
                </ng-template>
            </mat-tab>
            <!-- PRESCRIPTIONS SETS ITEM -->
            <mat-tab label="">
                <ng-template matTabContent>
                    <ng-container *ngIf="$.selectedPrescriptionSet?.id">
                        <h3 class="prescription-set__title" data-testid="selectedPrescriptionSetName">
                            {{ $.selectedPrescriptionSet.name }}
                        </h3>
                        <div class="prescription-set__content-wrapper">
                            <!-- 1 -->
                            <h4 class="pl-5">{{ 'apps.patient.prescriptions.medication' | translate }}</h4>
                            <section>
                                <mona-medication-list-as-table
                                    data-testid="selectedPrescriptionSet-medications"
                                    [data]="$.selectedPrescriptionSet.medications | uiMapper: mapToMedications"
                                    (edit)="openEditMedication($event, $.selectedPrescriptionSet)"
                                    (delete)="deleteMedication($event, $.selectedPrescriptionSet)"
                                ></mona-medication-list-as-table>
                                <p class="p-5" *ngIf="!$.selectedPrescriptionSet.medications.length">
                                    {{ 'apps.settings.prescriptionSet.empty' | translate }}
                                </p>
                            </section>
                            <!-- 2 -->
                            <h4 class="pl-5">{{ 'apps.patient.prescriptions.procedures' | translate }}</h4>
                            <section>
                                <mona-procedure-list
                                    [data]="$.selectedPrescriptionSet.procedures || []"
                                    [showTableHeader]="false"
                                    [showActionsColumn]="false"
                                    [tableStriped]="false"
                                    [tableBorderless]="true"
                                    [showNoData]="false"
                                    [showAsList]="true"
                                    [showActions]="true"
                                    (edit)="openAddOrEditProcedure($event, $.selectedPrescriptionSet)"
                                    (delete)="deleteProcedure($event, $.selectedPrescriptionSet)"
                                    data-testid="selectedPrescriptionSet-procedures"
                                ></mona-procedure-list>
                                <p class="p-5" *ngIf="!$.selectedPrescriptionSet.procedures.length">
                                    {{ 'apps.settings.prescriptionSet.procedureTable.emptyTitle' | translate }}
                                </p>
                            </section>
                        </div>
                    </ng-container>
                </ng-template>
            </mat-tab>
            <!-- 3 -->
            <mat-tab label="">
                <ng-template matTabContent>
                    <ng-container *ngIf="selectedSetProcedure">
                        <mona-base-procedure-prescription-form
                            [formGroup]="procedureFormGroup"
                            [submitBtnRef]="formSubmitBtn?.['_elementRef']"
                            [procedureCategories]="$.procedureCategories"
                            [prescriptionFrequencies]="$.prescriptionFrequencies"
                            [prescriptionFrequencyTimes]="$.prescriptionFrequencyTimes"
                        ></mona-base-procedure-prescription-form>
                    </ng-container>
                    <ng-container *ngIf="selectedSetMedication">
                        <fieldset *ngIf="medicationCategories$ | async as medicationCategories">
                            <!-- MEDICATION -->
                            <mat-form-field id="medForm-medication" class="app-input-field app-input-field--fullwidth">
                                <mat-label>
                                    {{ 'apps.patient.prescriptions.medication' | translate }}
                                </mat-label>
                                <input
                                    matInput
                                    [disabled]="true"
                                    [placeholder]="'apps.patient.prescriptions.medication' | translate"
                                    [(ngModel)]="selectedSetMedication.medication.displayName"
                                />
                            </mat-form-field>

                            <!-- CATEGORY -->
                            <mat-form-field
                                id="medForm-medicationCategory"
                                class="app-input-field app-input-field--fullwidth"
                                appearance="fill"
                            >
                                <mat-label>
                                    {{ 'apps.settings.medications.customMedication.category.label' | translate }}
                                </mat-label>
                                <mat-select
                                    panelClass="app-input-select-panel scrollbar-visible"
                                    [required]="true"
                                    [value]="selectedSetMedication.medication.categoryCode"
                                    (selectionChange)="medicationCategoryChange($event, medicationCategories)"
                                >
                                    <mat-option disabled>
                                        {{ 'apps.settings.medications.customMedication.category.none' | translate }}
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let category of medicationCategories || []"
                                        [value]="category?.code"
                                    >
                                        {{ category.displayName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <ui-validation-message
                                        [control]="medicationFormGroup.medication"
                                    ></ui-validation-message>
                                </mat-error>
                            </mat-form-field>
                            <mona-base-medication-form
                                [formGroup]="medicationFormGroup"
                                [selectedCategoryCode]="medicationFormGroup.value.medication.categoryCode"
                                [medicationCategories]="medicationCategories"
                                [bloodAdministrations]="$.bloodAdministrations"
                                [medicationFrequencyTimes]="$.prescriptionFrequencyTimes"
                                [medicationFrequencies]="$.prescriptionFrequencies"
                                [medicationSolutions]="$.medicationSolutions"
                                [medicationDosageForms]="$.medicationDosageForms"
                                [medicationUnits]="$.medicationUnits"
                                [medicationAdministrationMethods]="$.medicationAdministrationMethods"
                            ></mona-base-medication-form>
                        </fieldset>
                    </ng-container>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
    <ng-container ui-card-actions>
        <button
            *ngIf="$.selectedTab === tabsEnum.SelectPrescriptionSet && $.selectedPrescriptionSet"
            [disabled]="isPrescriptionSetEmpty($.selectedPrescriptionSet)"
            (click)="createPrescriptionsFromSet($.selectedPrescriptionSet)"
            mat-raised-button
            class="add-prescription-set-btn"
            color="primary"
            data-testid="prescriptionSets-add"
        >
            <mat-icon>add</mat-icon>
            {{ 'apps.settings.prescriptionSets.add' | translate }}
        </button>

        <ng-container *ngIf="$.selectedTab === tabsEnum.AddEditPrescription && $.selectedPrescriptionSet">
            <button
                mat-button
                class="mona-prescription-sidenav__close"
                color="primary"
                type="button"
                (click)="activateTab(tabsEnum.SelectPrescriptionSet)"
                data-testid="medForm-goBack"
                id="medForm-goBack"
            >
                {{ 'apps.settings.medications.addDialog.cancel' | translate }}
            </button>

            <button
                #formSubmitBtn
                [disabled]="isFormInvalid(medicationFormGroup.invalid, procedureFormGroup.invalid)"
                mat-raised-button
                class="add-edit-prescription-set-item"
                color="primary"
                data-testid="prescriptionSets-add-edit-prescription"
                (click)="editPrescription($.selectedPrescriptionSet)"
            >
                {{ 'apps.settings.prescriptionSets.addChanges' | translate }}
            </button>
        </ng-container>
    </ng-container>
</ui-card>
