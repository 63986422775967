import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { isEmpty, isNullOrUndefined, REGEXPS } from '@mona/shared/utils';

// TODO: use dependency on smth from '@mona/config' to re-use schema ?
type TerminalConfig = {
    api: { telemedicineUrl: string; baseUrl: string };
};

/**
 * Replace url protocol
 *
 * @param url
 * @param prevUrl
 */
export function replaceUrlProtocol(url: string, prevUrl: string): string {
    try {
        const n = new URL(url),
            p = new URL(prevUrl);
        return p.protocol + '//' + p.host + '/' + n.pathname + n.search;
    } catch (error) {
        return url;
    }
}

/**
 * Get http header by key
 *
 * @param headers
 * @param key
 */
export function getHttpHeaderByKey(headers: HttpHeaders, key: string): string {
    return headers.get(key);
}

/**
 * Add base url to request (partial) url
 *
 * @param request request
 * @param config TerminalConfig
 */
export function getRequestWithBaseApiUrl(request: HttpRequest<any>, config: TerminalConfig): HttpRequest<any> {
    let _urlObj: URL;
    // skip if config is empty
    if (isNullOrUndefined(config) || isEmpty(config)) {
        return request;
    }
    // DO not prepend base url if already valid url
    if (request.url.match(REGEXPS.url) || request.url.match(REGEXPS.host)) {
        return request;
    }
    const { url } = request;
    const {
        api: { telemedicineUrl, baseUrl },
    } = config;
    let newUrl = url.startsWith('/telemedicine/')
        ? url.replace('/telemedicine', telemedicineUrl)
        : url.startsWith('/api/')
        ? baseUrl.concat(url)
        : baseUrl.concat(url);
    if (!newUrl.startsWith('http')) {
        newUrl = location.origin.concat('/', newUrl);
    }
    try {
        _urlObj = new URL(newUrl);
    } catch (e) {
        throw new TypeError('Invalid URL after processing with `getRequestWithBaseApiUrl`');
    }

    return request.clone({ url: _urlObj.href });
}
