import { ApiModels } from '@mona/api';
import { VentilationParameterType } from '@mona/models';

/**
 * Transforms api ventilation parameter type
 * @param apiVentilationParameterType ApiModels.TerminologyVentilationParameter
 */
export const transformApiVentilationParameterType = (
    apiVentilationParameterType: ApiModels.TerminologyVentilationParameter,
): VentilationParameterType => {
    const item: VentilationParameterType = {
        id: apiVentilationParameterType.code,
        code: apiVentilationParameterType.code,
        displayName: apiVentilationParameterType.display_name,
        type: apiVentilationParameterType.type,
        unit: apiVentilationParameterType.unit,
        isAutomated: apiVentilationParameterType.is_automated,
        isAlwaysVisible: apiVentilationParameterType.is_always_visible,
    };

    if (apiVentilationParameterType.components) {
        item.components = transformApiVentilationParameterTypes(
            apiVentilationParameterType.components as ApiModels.TerminologyVentilationParameter[],
        );
    }

    return item;
};

/**
 * Transform api ventilation parameter types
 * @param apiVentilationParameterTypes  ApiModels.TerminologyVentilationParameter[]
 */
export const transformApiVentilationParameterTypes = (
    apiVentilationParameterTypes: ApiModels.TerminologyVentilationParameter[],
): VentilationParameterType[] => {
    return apiVentilationParameterTypes.map(apiVentilationParameterType =>
        transformApiVentilationParameterType(apiVentilationParameterType),
    );
};
