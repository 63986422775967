import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * INFO: add comment
 */
@Injectable({
    providedIn: 'root',
})
export class LockScreenPortalService {
    /**
     * Holds the current template
     */
    private lockScreenInfoPortalSubject = new BehaviorSubject(undefined);
    /**
     * Holds the encounter location template
     */
    private lockScreenLocationPortalSubject = new BehaviorSubject(undefined);

    /**
     * Gets the portal
     */
    get lockScreenInfoPortal$() {
        return this.lockScreenInfoPortalSubject.asObservable();
    }

    /**
     * Sets the portal
     *
     * @param portal Portal<any>
     */
    setlockScreenInfo(portal: Portal<any>) {
        this.lockScreenInfoPortalSubject.next(portal);
    }

    /**
     * Clears the portal
     */
    clearlockScreenInfo() {
        this.lockScreenInfoPortalSubject.next(undefined);
    }

    /**
     * Gets the portal
     */
    get lockScreenLocationPortal$() {
        return this.lockScreenLocationPortalSubject.asObservable();
    }

    /**
     * Sets the portal
     *
     * @param portal Portal<any>
     */
    setLockScreenLocation(portal: Portal<any>) {
        this.lockScreenLocationPortalSubject.next(portal);
    }

    /**
     * Clears the portal
     */
    clearLockScreenLocation() {
        this.lockScreenLocationPortalSubject.next(undefined);
    }
}
