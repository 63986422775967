import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormGroup, Validators } from '@angular/forms';
import { BloodAdministrationType } from '@mona/models';
import { BloodControlsForm, buildBloodControls } from '../../models';

/**
 * Blood form controls component
 */
@Component({
    selector: 'mona-blood-controls',
    templateUrl: './blood-controls.component.html',
    styleUrls: ['./blood-controls.component.scss'],
})
export class BloodControlsComponent implements OnInit {
    /** Medications solutions */
    @Input() bloodAdministrations: BloodAdministrationType[];
    /** form of MedicationForm */
    formGroup: FormGroup<BloodControlsForm>;
    /** The controls of the form */
    get controls(): FormGroup<BloodControlsForm>['controls'] {
        return this.formGroup?.controls;
    }

    /**
     * Constructor
     *
     * @param controlContainer ControlContainer
     */
    constructor(@Optional() private controlContainer: ControlContainer) {}

    /**
     * Lifecycle Hook
     */
    ngOnInit() {
        if (this.controlContainer.control) {
            this.formGroup = this.controlContainer.control as any;
        } else {
            this.formGroup = buildBloodControls();
        }
        this.formGroup.controls.bloodBatchNumber.setValidators(Validators.required);
        this.formGroup.controls.bloodAdministrationReason.setValidators(Validators.required);

        // preselect first option if empty
        if (!this.controls.bloodAdministrationReason.value && this.bloodAdministrations.length) {
            this.controls.bloodAdministrationReason.patchValue(this.bloodAdministrations[0]?.reason);
        }
    }

    trackByReason = (_, item) => item.reason;

    /**
     * Function to compare the option values with the selected values
     *
     * @param o1
     * @param o2
     */
    compareFn(o1: BloodAdministrationType, o2: BloodAdministrationType) {
        return o1?.reason === o2?.reason;
    }
}
