import {
    addHours,
    addMinutes,
    addYears,
    eachDayOfInterval,
    eachMinuteOfInterval,
    endOfYear,
    format,
    isAfter,
    isBefore,
    isSameMinute,
    isWithinInterval,
    roundToNearestMinutes,
    startOfDay,
    startOfHour,
    startOfMinute,
    startOfYear,
    subMinutes,
} from 'date-fns';
import {
    DateColumn,
    DateRangeInterval,
    DateTimeColumnsResponse,
    EntriesInterval,
    EntriesIntervalColumnsAdd,
    EntriesIntervalInMinutes,
    TimeColumn,
} from '../models';
import { isValidDateRange } from './date-utils.helper';

/**
 * Converts given date to a corresponding point of time depends on given interval
 *
 * @param date Date
 * @param selectedInterval EntriesInterval
 */
export const roundToNearestInterval = (date: Date, selectedInterval: EntriesInterval): Date => {
    const newDate: Date = new Date(date);
    const hours: number = newDate.getHours();
    const minutes: number = newDate.getMinutes();
    const hourIntervalsMap: Record<string, number> = {
        MINUTES_1: hours % 1,
        MINUTES_5: hours % 1,
        MINUTES_30: hours % 1,
        HOUR_1: hours % 1,
        HOUR_6: hours % 6,
        DAY_1: hours % 24,
    };
    const minutesIntervalsMap: Record<string, number> = {
        MINUTES_1: minutes % 1,
        MINUTES_5: minutes % 5,
        MINUTES_30: minutes % 30,
        HOUR_1: minutes % 60,
        HOUR_6: minutes % (60 * 6),
        DAY_1: minutes % (60 * 24),
    };

    newDate.setHours(hours - hourIntervalsMap[selectedInterval], minutes - minutesIntervalsMap[selectedInterval], 0, 0);

    return newDate;
};

/**
 * Round to nearest interval with count of DST shifts
 *
 * @param date Date
 * @param selectedInterval EntriesInterval
 */
export const roundToNearestIntervalWithDstShift = (date: Date, selectedInterval: EntriesInterval): Date => {
    let newDate: Date = new Date(date);
    const hours: number = newDate.getHours();
    const minutes: number = newDate.getMinutes();
    const hourIntervalsMap: Record<string, number> = {
        MINUTES_1: hours % 1,
        MINUTES_5: hours % 1,
        MINUTES_30: hours % 1,
        HOUR_1: hours % 1,
        HOUR_6: hours % 6,
        DAY_1: hours % 24,
    };
    const minutesIntervalsMap: Record<string, number> = {
        MINUTES_1: minutes % 1,
        MINUTES_5: minutes % 5,
        MINUTES_30: minutes % 30,
        HOUR_1: minutes % 60,
        HOUR_6: minutes % (60 * 6),
        DAY_1: minutes % (60 * 24),
    };

    const capturedInitialTimeZoneOffset = newDate.getTimezoneOffset();

    newDate.setHours(hours - hourIntervalsMap[selectedInterval], minutes - minutesIntervalsMap[selectedInterval], 0, 0);

    if (
        capturedInitialTimeZoneOffset > newDate.getTimezoneOffset() &&
        hourIntervalsMap[selectedInterval] <= hourIntervalsMap[EntriesInterval.HOUR_1]
    ) {
        newDate = addHours(newDate, 1);
    }

    return newDate;
};

/**
 * Generates date and time columns data using plain Javascript Date
 *
 * @param selectedInterval EntriesInterval
 * @param start Date
 * @param end Date
 * @param withAdditionalIntervals boolean
 */
export const generateDateTimesData = (
    selectedInterval: EntriesInterval,
    start: Date,
    end: Date = new Date(),
    withAdditionalIntervals?: boolean,
): DateTimeColumnsResponse => {
    const dateRange: DateRangeInterval = {
        start: getStartDate(start, selectedInterval),
        end: withAdditionalIntervals
            ? addMinutes(end, EntriesIntervalInMinutes[selectedInterval] * EntriesIntervalColumnsAdd[selectedInterval])
            : end,
    };

    const timeColumns: TimeColumn[] = getTimeColumns(dateRange, selectedInterval);
    const dateColumns: DateColumn[] = getDateColumns(dateRange, timeColumns);

    return {
        uId: `${selectedInterval}_${start.getTime()}`,
        selectedInterval,
        dateColumns,
        timeColumns,
    };
};

/**
 * Generate time columns by range
 *
 * @param rangeDates
 * @param timeInterval
 */
export const getTimeColumns = (rangeDates: DateRangeInterval, timeInterval: EntriesInterval): TimeColumn[] => {
    const yearRangedIntervals = splitRangeByYear(rangeDates);
    return yearRangedIntervals.reduce((acc, range) => {
        if (!isValidDateRange(range)) {
            return [];
        }
        let timeRange: Date[] = [];

        const { start: startOfSummerTimezone, end: endOfSummerTimezone } = getDaylightSavingSwitchDates(
            range.start.getFullYear(),
        );
        const hasSummerToWinterShift = isWithinInterval(endOfSummerTimezone, {
            start: startOfHour(range.start),
            end: range.end,
        });
        const hasWinterToSummerShift = isWithinInterval(startOfSummerTimezone, {
            start: startOfHour(range.start),
            end: range.end,
        });

        // Needed for DST shifts (if patient goes through 2 DST shifts it's not gonna work)
        if (
            hasSummerToWinterShift &&
            EntriesIntervalInMinutes[timeInterval] !== EntriesIntervalInMinutes[EntriesInterval.MINUTES_1]
        ) {
            timeRange = dstShift(range, timeInterval, true);
        } else if (
            hasWinterToSummerShift &&
            EntriesIntervalInMinutes[timeInterval] !== EntriesIntervalInMinutes[EntriesInterval.MINUTES_1]
        ) {
            timeRange = dstShift(range, timeInterval, false);
        } else {
            timeRange = eachMinuteOfInterval(range, { step: EntriesIntervalInMinutes[timeInterval] });

            // In order to avoid duplication of columns on summer -> winter DST, for 1 minutes interval
            if (
                EntriesIntervalInMinutes[timeInterval] === EntriesIntervalInMinutes[EntriesInterval.MINUTES_1] &&
                isWithinInterval(addMinutes(endOfSummerTimezone, 30), range)
            ) {
                timeRange = timeRange.slice(0, 31);
            }
        }

        const timeColumns = timeRange.map((date: Date) => {
            const is1MinuteInterval =
                EntriesIntervalInMinutes[timeInterval] === EntriesIntervalInMinutes[EntriesInterval.MINUTES_1];

            const isChangedToWinterTime = is1MinuteInterval
                ? isDstHourFor1MinuteInterval(range, date)
                : (hasWinterToSummerShift || hasSummerToWinterShift) && isSwitchHourOfDst(date);
            return new TimeColumn(date, isChangedToWinterTime);
        });
        // assign isNow flag to the time column by searching from the end of the array
        // thus we can stop comparing dates as soon as we find the first match
        for (let i = timeColumns.length - 1; i >= 0; i--) {
            const isDateNow: boolean = isSameMinute(
                createDSTIndependentDate(roundToNearestInterval(new Date(), timeInterval)),
                createDSTIndependentDate(timeColumns[i].date),
            );

            if (isDateNow) {
                // To handle timezone change edge case
                const isSummerToWinterChange =
                    endOfSummerTimezone.getMonth() === timeColumns[i].date?.getMonth() &&
                    endOfSummerTimezone.getDate() === timeColumns[i].date?.getDate() &&
                    endOfSummerTimezone.getHours() === timeColumns[i].date?.getHours();

                const isSameTimezoneOffsets =
                    new Date().getTimezoneOffset() === timeColumns[i].date.getTimezoneOffset();

                if (!isSummerToWinterChange || isSameTimezoneOffsets) {
                    timeColumns[i].isNow = true;
                    break;
                }
            }
        }
        return acc.concat(timeColumns);
    }, []);
};

/**
 * split multi-year interval by in-year ranges
 *
 * @param dateRange
 */
function splitRangeByYear({ start, end }: DateRangeInterval): DateRangeInterval[] {
    const ranges = [];

    while (isBefore(start, end)) {
        const currentYearStart = start;
        const currentYearEnd = isBefore(end, endOfYear(currentYearStart)) ? end : endOfYear(currentYearStart);
        ranges.push({
            start: new Date(currentYearStart),
            end: new Date(currentYearEnd),
        });

        start = startOfYear(addYears(currentYearStart, 1));
    }

    return ranges;
}

/**
 * is dst hour falls into 30 mins range
 *
 * @param range
 * @param date
 */
function isDstHourFor1MinuteInterval(range: DateRangeInterval, date: Date): boolean {
    const { start: startOfSummerTimezone, end: endOfSummerTimezone } = getDaylightSavingSwitchDates(
        range.start.getFullYear(),
    );

    // adding 30 and 90 mins shift for 1 minute intervals to fall into 30 mins timerange, when showing only 30 mins timeframes
    return (
        (isWithinInterval(addMinutes(endOfSummerTimezone, 30), range) ||
            isWithinInterval(addMinutes(startOfSummerTimezone, 90), range)) &&
        isSwitchHourOfDst(date)
    );
}

/**
 * Calculates summer to winter DST shift
 *
 * @param range
 * @param timeInterval
 * @param isSummerToWinterShift
 */
export function dstShift(
    range: DateRangeInterval,
    timeInterval: EntriesInterval,
    isSummerToWinterShift = false,
): Date[] {
    const { start: startOfSummerTimezone, end: endOfSummerTimezone } = getDaylightSavingSwitchDates(
        range.start.getFullYear(),
    );

    // Edge case, when first interval of the start is dst shift (like in care&procedures)
    const timezoneShiftEdge = isSummerToWinterShift ? endOfSummerTimezone : startOfSummerTimezone;
    const noDateBeforeShift = !isAfter(startOfDay(timezoneShiftEdge), range.start);
    if (noDateBeforeShift) {
        return eachMinuteOfInterval(range, { step: EntriesIntervalInMinutes[timeInterval] });
    }

    const timeRangeBefore = eachMinuteOfInterval(
        { start: range.start, end: startOfDay(timezoneShiftEdge) },
        { step: EntriesIntervalInMinutes[timeInterval] },
    );

    const offsetDueToDst = addMinutes(
        startOfDay(timezoneShiftEdge),
        EntriesIntervalInMinutes[timeInterval] > EntriesIntervalInMinutes[EntriesInterval.HOUR_1]
            ? EntriesIntervalInMinutes[timeInterval] + (isSummerToWinterShift ? 60 : -60)
            : EntriesIntervalInMinutes[timeInterval],
    );

    try {
        const timeRangeAfter = eachMinuteOfInterval(
            { start: offsetDueToDst, end: range.end },
            { step: EntriesIntervalInMinutes[timeInterval] },
        );

        return timeRangeBefore.concat(timeRangeAfter);
    } catch (e) {
        return timeRangeBefore;
    }
}

/**
 * Checks if selected date is on edge (by hour) of switched timezones due to DST
 *
 * @param date
 */
export function isSwitchHourOfDst(date: Date): boolean {
    const { start: startOfSummerTimezone, end: endOfSummerTimezone } = getDaylightSavingSwitchDates(date.getFullYear());

    const isSummerToWinterChange =
        endOfSummerTimezone.getMonth() === date.getMonth() &&
        endOfSummerTimezone.getDate() === date.getDate() &&
        endOfSummerTimezone.getHours() === date.getHours() &&
        endOfSummerTimezone.getTimezoneOffset() !== date.getTimezoneOffset();

    const isWinterToSummerChange =
        startOfSummerTimezone.getMonth() === date.getMonth() &&
        startOfSummerTimezone.getDate() === date.getDate() &&
        startOfSummerTimezone.getHours() === addHours(date, -1).getHours();

    return isSummerToWinterChange || isWinterToSummerChange;
}

/**
 * Generate date columns by range
 *
 * @param range
 * @param timeColumns
 */
export const getDateColumns = (range: DateRangeInterval, timeColumns: TimeColumn[]): DateColumn[] => {
    if (!isValidDateRange(range)) {
        return [];
    }

    const childrenMap: Record<string, TimeColumn[]> = getTimeColumnsMap(timeColumns);

    return eachDayOfInterval(range).map((date: Date) => {
        const day = format(date, 'yyyy-MM-dd');
        return new DateColumn(date, childrenMap[day]);
    });
};

/**
 * Get Time Columns Map
 *
 * @param timeColumns
 * @description To avoid n^2 {@link getDateColumns eachDayOfInterval} complexity
 */
export const getTimeColumnsMap = (timeColumns: TimeColumn[]): Record<string, TimeColumn[]> => {
    return timeColumns.reduce((acc: Record<string, TimeColumn[]>, curr: TimeColumn) => {
        const dayFormat: string = format(curr.date, 'yyyy-MM-dd');
        const timeColumns: TimeColumn[] = acc[dayFormat];

        timeColumns ? timeColumns.push(curr) : (acc[dayFormat] = [curr]);

        return acc;
    }, {});
};

const getStartDate = (date: Date, selectedInterval: EntriesInterval): Date => {
    const newDate: Date = isSameMinute(date, startOfHour(date))
        ? new Date(date)
        : subMinutes(date, EntriesIntervalInMinutes[selectedInterval]);

    if (selectedInterval === EntriesInterval.HOUR_1) {
        return startOfHour(newDate);
    }

    if (selectedInterval === EntriesInterval.HOUR_6 || selectedInterval === EntriesInterval.DAY_1) {
        return startOfDay(newDate);
    }

    return roundToNearestMinutes(newDate, { nearestTo: EntriesIntervalInMinutes[selectedInterval] });
};

/**
 * Generate date times data by processing defined list of dates
 *
 * @param dates string[]
 * @param currentTime Date
 */
export function generateDateTimesDataByList(dates: string[] = [], currentTime = new Date()): DateTimeColumnsResponse {
    // Protection for data in future
    const datesToIterate = dates.filter(date => isBefore(new Date(date), currentTime));

    // adds current time
    const currentTimeIso = startOfMinute(currentTime).toISOString();
    if (datesToIterate[datesToIterate.length - 1] !== currentTimeIso) {
        datesToIterate.push(currentTimeIso);
    }

    // results
    const timeColumns: TimeColumn[] = [];
    const dateColumns: DateColumn[] = [];

    for (const dateIso of datesToIterate) {
        const newDate = new Date(dateIso);

        const newTimeCol = new TimeColumn(newDate, isSwitchHourOfDst(new Date(dateIso)));

        if (newTimeCol.isoDateString === currentTimeIso) {
            newTimeCol.isNow = true;
        }

        // Last parsed day start
        const currentDay = dateColumns[dateColumns.length - 1];
        // Day start of new entry
        const newDateDay = startOfDay(newDate);

        // If entry belongs to already available day extend colspan, else add new day
        if (newDateDay.getTime() === currentDay?.date.getTime()) {
            currentDay.colspan++;
            currentDay.children.push(newTimeCol);
        } else {
            dateColumns.push(new DateColumn(newDateDay, [newTimeCol]));
        }

        timeColumns.push(newTimeCol);
    }

    return {
        uId: `datesList_${currentTime.getTime()}`,
        selectedInterval: undefined,
        timeColumns,
        dateColumns,
    };
}

/**
 * Calculates day and hour of DST shift
 *
 * @param year
 */
export function getDaylightSavingSwitchDates(year: number): { start: Date; end: Date } {
    // DST starts on the last Sunday in March
    const start = new Date(year, 2, 31);
    start.setDate(31 - start.getDay());
    start.setHours(-(start.getTimezoneOffset() / 60), 0, 0, 0);

    // DST ends on the last Sunday in October
    const end = new Date(year, 9, 31);
    end.setDate(31 - end.getDay());
    end.setHours(-(end.getTimezoneOffset() / 60), 0, 0, 0);

    return { start, end };
}

/**
 * Creates date which isn't dependent on timeshifts
 * @param date
 */
export function createDSTIndependentDate(date: Date): Date {
    const dstIndependentDate = new Date();
    dstIndependentDate.setTime(date.getTime());
    dstIndependentDate.setHours(date.getHours());
    dstIndependentDate.setMinutes(date.getMinutes());
    dstIndependentDate.setSeconds(date.getSeconds());
    return dstIndependentDate;
}
