import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SetOptional } from 'type-fest';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '@mona/auth';
import {
    Allergy,
    Anamnesis,
    BalanceTarget,
    BasicCareProcedure,
    Diagnosis,
    Encounter,
    EncounterHistoryEntry,
    EntryControl,
    HistoryEntryType,
    LabValue,
    MedicationAdministration,
    MedicationPrescription,
    Patient,
    PatientOutput,
    PhysicalExamination,
    PreMedication,
    Procedure,
    ProcedurePrescription,
    VentilationModeCode,
    VentilationParameter,
    VentilationProcedure,
    VitalSign,
    Workflow,
    ChangeLogEntry,
    ChangeLogModel,
    ChangeLogModelKey,
    ChangeLogOperation,
    ChangeLogPermissionsModel,
    PersistChangesResult,
    DailyGoal,
    InfectionStatus,
    TherapyLimitations,
    SurgeryPrescription,
    VaccinationStatus,
    WoundStatus,
    Valuables,
} from '@mona/models';
import { compactObject, diffDeepEqual, omit, pick } from '@mona/shared/utils';
import { AsyncActionState } from '@mona/store';
import { ChangeLogMap, ChangeLogState } from '../entities';
import { ChangeLogAction, ChangeLogSelectors } from '../state';

/**
 * Change log service
 */
@Injectable({ providedIn: 'root' })
export class ChangeLogService {
    /**
     * Constructor
     *
     * @param store Store<ChangeLogState>
     * @param authService AuthService,
     * @param permissionsService NgxPermissionsService
     */
    constructor(
        private store: Store<ChangeLogState>,
        private authService: AuthService,
        private permissionsService: NgxPermissionsService,
    ) {}

    //#region Selectors

    /**
     * Get load changes async action
     */
    getLoadChangesAction(): Observable<AsyncActionState<ChangeLogEntry<ChangeLogModel>[]>> {
        return this.store.select(ChangeLogSelectors.getLoadChangesAction);
    }

    /**
     * Get save change async action
     */
    getSaveChangeAction(): Observable<AsyncActionState<ChangeLogEntry<ChangeLogModel>>> {
        return this.store.select(ChangeLogSelectors.getSaveChangeAction);
    }

    /**
     * Get save changes async action
     */
    getSaveChangesAction(): Observable<AsyncActionState<ChangeLogEntry<ChangeLogModel>[]>> {
        return this.store.select(ChangeLogSelectors.getSaveChangesAction);
    }

    /**
     * Get persist change async action
     */
    getPersistChangeAction(): Observable<AsyncActionState<PersistChangesResult>> {
        return this.store.select(ChangeLogSelectors.getPersistChangeAction);
    }

    /**
     * Get discard change async action
     */
    getDiscardChangeAction(): Observable<AsyncActionState<void>> {
        return this.store.select(ChangeLogSelectors.getDiscardChangeAction);
    }

    /**
     * Checks if there are pending changes
     */
    hasChanges(): Observable<boolean> {
        return this.store.select(ChangeLogSelectors.hasChanges);
    }

    /**
     * Get changes map
     */
    getChangesMap(): Observable<ChangeLogMap> {
        return this.store.select(ChangeLogSelectors.getChangesMap);
    }

    /**
     * Get model changes
     *
     * @param model ChangeLogModelKey
     */
    getModelChanges(model: ChangeLogModelKey): Observable<ChangeLogEntry<ChangeLogModel>[]> {
        return this.store.select(ChangeLogSelectors.getModelChanges(model));
    }

    //#endregion Selectors

    //#region Actions

    /**
     * Triggers change log loading
     *
     * @param encounterId
     */
    loadChanges(encounterId?: EntityId<Encounter>): void {
        this.store.dispatch(ChangeLogAction.loadChangesAction.action({ encounterId }));
    }

    /**
     * Updates patient personal info
     *
     * @param currentPatient Patient
     * @param personalInfoUpdate Partial<Patient>
     */
    updatePersonalInfo(currentPatient: Patient, personalInfoUpdate: Partial<Patient>): void {
        const updatedPropertyName = Object.keys(personalInfoUpdate)[0];
        if (currentPatient[updatedPropertyName] !== personalInfoUpdate[updatedPropertyName]) {
            this.saveChange({
                model: 'Patient',
                modelId: currentPatient.id as string,
                operation: ChangeLogOperation.Update,
                payload: personalInfoUpdate,
            });
        }
    }

    /**
     * Updates encounter case
     *
     * @param encounterId EntityId<Encounter>
     * @param admissionDiagnosis
     */
    updateEncounterCase(encounterId: EntityId<Encounter>, admissionDiagnosis: string): void {
        this.saveChange({
            model: 'Encounter',
            modelId: encounterId as string,
            operation: ChangeLogOperation.Update,
            payload: {
                admissionDiagnosis: admissionDiagnosis,
            },
        });
    }

    /**
     * Persist changes
     */
    persistChanges(): void {
        this.store.dispatch(ChangeLogAction.persistChangesAction.action());
    }

    /**
     * Clears the discard changes action
     */
    clearDiscardChangesAction(): void {
        this.store.dispatch(ChangeLogAction.discardChangesAction.clearAction());
    }

    /**
     * Clears the save change action
     */
    clearSaveChangeAction(): void {
        this.store.dispatch(ChangeLogAction.saveChangeAction.clearAction());
    }

    /**
     * Clears the persist changes action
     */
    clearPersistChangesAction(): void {
        this.store.dispatch(ChangeLogAction.persistChangesAction.clearAction());
    }

    /**
     * Discard changes
     *
     * @param ids
     */
    discardChanges(ids?: string[]): void {
        this.store.dispatch(ChangeLogAction.discardChangesAction.action({ ids }));
    }

    /**
     * Check pending changes models permissions
     *
     * @param sortedChanges ChangeLogEntry<ChangeLogModel>[]
     * @returns boolean
     */
    checkPendingChangesModelsPermissions(sortedChanges: ChangeLogEntry<ChangeLogModel>[]): boolean {
        for (let i = 0; i < sortedChanges?.length; i++) {
            const change = sortedChanges[i];
            const unsavedChangeModelPermission = ChangeLogPermissionsModel[change.model];

            if (change.model === 'DailyGoal') {
                continue;
            }

            const permission = this.permissionsService.getPermission(unsavedChangeModelPermission)?.name;

            if (!permission) {
                return false;
            }
        }

        return true;
    }

    /**
     * Creates anamnesis
     *
     * @param anamnesis Anamnesis
     * @param encounterId EntityId<Encounter>
     * @param patientId EntityId<Patient>
     */
    createAnamnesis(anamnesis: Anamnesis, encounterId: EntityId<Encounter>, patientId: EntityId<Patient>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'Anamnesis',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                ...anamnesis,
                encounterId,
                patientId,
            },
        });
    }

    /**
     * Updates anamnesis
     *
     * @param id EntityId<Anamnesis>
     * @param anamnesis Anamnesis
     */
    updateAnamnesis(id: EntityId<Anamnesis>, anamnesis: Anamnesis): void {
        this.saveChange({
            model: 'Anamnesis',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: anamnesis,
        });
    }

    /**
     * Creates physical examination
     *
     * @param text string
     * @param encounterId EntityId<Encounter>
     * @param patientId EntityId<Patient>
     */
    createPhysicalExamination(text: string, encounterId: EntityId<Encounter>, patientId: EntityId<Patient>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'PhysicalExamination',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                text,
                encounterId,
                patientId,
            },
        });
    }

    /**
     * Updates physical examination
     *
     * @param id EntityId<Anamnesis>
     * @param text string
     */
    updatePhysicalExamination(id: EntityId<PhysicalExamination>, text: string): void {
        this.saveChange({
            model: 'PhysicalExamination',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                text,
            },
        });
    }

    /**
     * Removes allergy
     *
     * @param id  EntityId<Allergy>
     */
    removeAllergy(id: EntityId<Allergy>): void {
        this.saveChange({
            model: 'AllergyIntolerance',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Updates allergy
     *
     * @param id EntityId<Allergy>
     * @param allergenic string
     */
    updateAllergy(id: EntityId<Allergy>, allergenic: string): void {
        this.saveChange({
            model: 'AllergyIntolerance',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                allergenic,
            },
        });
    }

    /**
     * Creates allergy
     *
     * @param allergy Allergy
     * @param encounterId EntityId<Encounter>
     * @param patientId EntityId<Patient>
     */
    createAllergy(allergy: Allergy, encounterId: EntityId<Encounter>, patientId: EntityId<Patient>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'AllergyIntolerance',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                ...allergy,
                encounterId,
                patientId,
            },
        });
    }

    /**
     * Removes diagnosis
     *
     * @param id  EntityId<Allergy>
     */
    removeDiagnosis(id: EntityId<Diagnosis>): void {
        this.saveChange({
            model: 'Diagnosis',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Updates diagnosis
     *
     * @param prevValue Diagnosis
     * @param currentValue Diagnosis
     */
    updateDiagnosis(prevValue: Diagnosis, currentValue: Diagnosis): void {
        const changes: Partial<Diagnosis> = {};

        if (prevValue.verificationStatus !== currentValue.verificationStatus) {
            changes.verificationStatus = currentValue.verificationStatus;
        }

        if (prevValue.description !== currentValue.description) {
            changes.description = currentValue.description;
        }

        if (prevValue.icd10Code !== currentValue.icd10Code) {
            changes.icd10Code = currentValue.icd10Code;
        }

        if (Object.keys(changes).length) {
            this.saveChange({
                model: 'Diagnosis',
                modelId: currentValue.id as string,
                operation: ChangeLogOperation.Update,
                payload: changes,
            });
        }
    }

    /**
     * Creates diagnosis
     *
     * @param diagnosis Diagnosis
     * @param encounterId EntityId<Encounter>
     * @param patientId EntityId<Patient>
     */
    createDiagnosis(diagnosis: Diagnosis, encounterId: EntityId<Encounter>, patientId: EntityId<Patient>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'Diagnosis',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                ...diagnosis,
                encounterId,
                patientId,
            },
        });
    }

    /**
     * Removes pre medication
     *
     * @param id  EntityId<Allergy>
     */
    removePreMedication(id: EntityId<PreMedication>): void {
        this.saveChange({
            model: 'PreMedication',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Updates pre medication
     *
     * @param prevValue PreMedication
     * @param currentValue PreMedication
     */
    updatePreMedication(prevValue: PreMedication, currentValue: PreMedication): void {
        const changes: Partial<PreMedication> = diffDeepEqual(prevValue, currentValue);
        // debugger;
        if (prevValue.description !== currentValue.description) {
            changes.description = currentValue.description;
        }

        if (prevValue.code !== currentValue.code) {
            changes.code = currentValue.code;
        }

        if (prevValue.dose !== currentValue.dose) {
            changes.dose = currentValue.dose;
        }

        if (prevValue.unit !== currentValue.unit) {
            changes.unit = currentValue.unit;
        }

        if (prevValue.frequency !== currentValue.frequency) {
            changes.frequency = currentValue.frequency;
        }

        if (Object.keys(changes).length) {
            this.saveChange({
                model: 'PreMedication',
                modelId: currentValue.id as string,
                operation: ChangeLogOperation.Update,
                payload: changes,
            });
        }
    }

    /**
     * Creates pre medication
     *
     * @param preMedication PreMedication
     * @param encounterId EntityId<Encounter>
     * @param patientId EntityId<Patient>
     */
    createPreMedication(
        preMedication: PreMedication,
        encounterId: EntityId<Encounter>,
        patientId: EntityId<Patient>,
    ): void {
        const id = uuidv4();
        // FIXME: remove authservice dependancy
        this.authService.user$.pipe(take(1)).subscribe(practitioner => {
            this.saveChange({
                model: 'PreMedication',
                modelId: id as string,
                operation: ChangeLogOperation.Create,
                payload: {
                    ...preMedication,
                    encounterId,
                    patientId,
                    authorId: practitioner.id,
                },
            });
        });
    }

    /**
     * Creates history entry
     *
     * @param text string
     * @param type HistoryEntryType
     */
    createHistoryEntry(text: string, type: HistoryEntryType): void {
        const id = uuidv4();

        this.saveChange({
            model: 'PatientHistory',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                type,
                text,
                date: new Date(),
            },
        });
    }

    /**
     * Updates history entry
     *
     * @param id EntityId<EncounterHistoryEntry>
     * @param text string
     */
    updateHistoryEntry(id: EntityId<EncounterHistoryEntry>, text: string): void {
        this.saveChange({
            model: 'PatientHistory',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                text,
            },
        });
    }

    /**
     * Creates vital sign
     *
     * @param vitalSign VitalSign
     */
    createVitalSign(vitalSign: VitalSign): void {
        const id = uuidv4();

        const vitalSingCreate = { ...vitalSign };
        delete vitalSingCreate.id;

        this.saveChange({
            model: 'VitalSign',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                ...vitalSingCreate,
            },
        });
    }

    /**
     * Creates vital signs
     *
     * @param vitalSigns VitalSign[[
     */
    createVitalSigns(vitalSigns: VitalSign[]): void {
        const changes: ChangeLogEntry<VitalSign>[] = vitalSigns.map(vitalSign => {
            const id = uuidv4();

            const vitalSingCreate = { ...vitalSign };
            delete vitalSingCreate.id;

            return {
                model: 'VitalSign',
                modelId: id as string,
                operation: ChangeLogOperation.Create,
                payload: {
                    ...vitalSingCreate,
                },
            };
        });

        this.saveChanges(changes);
    }

    /**
     * Removes vital sign
     *
     * @param id EntityId<VitalSign>
     */
    removeVitalSign(id: EntityId<VitalSign>): void {
        this.saveChange({
            model: 'VitalSign',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Removes vital signs
     *
     * @param ids EntityId<VitalSign>[]
     */
    removeVitalSigns(ids: EntityId<VitalSign>[]): void {
        const changes: ChangeLogEntry<ChangeLogModel>[] = ids.map(id => ({
            model: 'VitalSign',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        }));

        this.saveChanges(changes);
    }

    /**
     * Updates vital sign
     *
     * @param id EntityId<VitalSign>
     * @param value number
     */
    updateVitalSign(id: EntityId<VitalSign>, value: number): void {
        this.saveChange({
            model: 'VitalSign',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                value,
            },
        });
    }

    /**
     * Updates vital signs
     *
     * @param updates objects with ids and values
     */
    // tslint:disable-next-line: completed-docs
    updateVitalSigns(updates: { id: EntityId<VitalSign>; value: number }[]): void {
        const changes: ChangeLogEntry<VitalSign>[] = updates.map(update => {
            return {
                model: 'VitalSign',
                modelId: update.id as string,
                operation: ChangeLogOperation.Update,
                payload: {
                    value: update.value,
                },
            };
        });

        this.saveChanges(changes);
    }

    /**
     * Creates lab value
     *
     * @param labValue VitalSign
     */
    createLabValue(labValue: SetOptional<LabValue, 'patientId' | 'encounterId' | 'lastChangedBy'>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'LabValue',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                ...labValue,
            },
        });
    }

    /**
     * Updates lab value
     *
     * @param id EntityId<LabValue>
     * @param value number
     */
    updateLabValue(id: EntityId<LabValue>, value: string): void {
        this.saveChange({
            model: 'LabValue',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                value,
            },
        });
    }

    /**
     * Removes lab value
     *
     * @param id EntityId<LabValue>
     */
    removeLabValue(id: EntityId<LabValue>): void {
        this.saveChange({
            model: 'LabValue',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Creates ventilation param
     *
     * @param ventilationParameter VentilationParameter
     */
    createVentilationParameter(ventilationParameter: VentilationParameter): void {
        const id = uuidv4();

        const ventilationParameterCreate = { ...ventilationParameter };
        delete ventilationParameterCreate.id;

        this.saveChange({
            model: 'VentilationParameter',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                ...ventilationParameterCreate,
            },
        });
    }

    /**
     * Creates ventilation params
     *
     * @param ventilationParameters VentilationParameter[[
     */
    createVentilationParameters(ventilationParameters: VentilationParameter[]): void {
        const changes: ChangeLogEntry<VitalSign>[] = ventilationParameters.map(vitalSign => {
            const id = uuidv4();

            const ventilationParameterCreate = { ...vitalSign };
            delete ventilationParameterCreate.id;

            return {
                model: 'VentilationParameter',
                modelId: id as string,
                operation: ChangeLogOperation.Create,
                payload: {
                    ...ventilationParameterCreate,
                },
            };
        });

        this.saveChanges(changes);
    }

    /**
     * Removes ventilation param
     *
     * @param id EntityId<VentilationParameter>
     */
    removeVentilationParameter(id: EntityId<VentilationParameter>): void {
        this.saveChange({
            model: 'VentilationParameter',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Removes ventilation params
     *
     * @param ids EntityId<VentilationParameter>[]
     */
    removeVentilationParameters(ids: EntityId<VentilationParameter>[]): void {
        const changes: ChangeLogEntry<ChangeLogModel>[] = ids.map(id => ({
            model: 'VentilationParameter',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        }));

        this.saveChanges(changes);
    }

    /**
     * Updates ventilation param
     *
     * @param id EntityId<VentilationParameter>
     * @param value number
     */
    updateVentilationParameter(id: EntityId<VentilationParameter>, value: number): void {
        this.saveChange({
            model: 'VentilationParameter',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                value,
            },
        });
    }

    /**
     * Updates ventilation params
     *
     * @param updates objects with ids and values
     */
    // tslint:disable-next-line: completed-docs
    updateVentilationParameters(updates: { id: EntityId<VentilationParameter>; value: number }[]): void {
        const changes: ChangeLogEntry<VitalSign>[] = updates.map(update => {
            return {
                model: 'VentilationParameter',
                modelId: update.id as string,
                operation: ChangeLogOperation.Update,
                payload: {
                    value: update.value,
                },
            };
        });

        this.saveChanges(changes);
    }

    /**
     * Creates ventilation procedure
     *
     * @param ventilationProcedure Partial<VentilationProcedure>
     * @param procedureToStopId EntityId<VentilationProcedure>
     * @param procedureToStopTime Date
     */
    createVentilationProcedure(
        ventilationProcedure: Partial<VentilationProcedure>,
        procedureToStopId: EntityId<VentilationProcedure>,
        procedureToStopTime: Date,
    ): void {
        const id = uuidv4();
        const createChange: ChangeLogEntry<VentilationProcedure> = {
            model: 'VentilationProcedure',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: ventilationProcedure,
        };

        if (procedureToStopId) {
            this.saveChanges([
                {
                    model: 'VentilationProcedure',
                    modelId: procedureToStopId as string,
                    operation: ChangeLogOperation.Update,
                    payload: {
                        endDate: procedureToStopTime,
                    },
                },
                createChange,
            ]);
        } else {
            this.saveChange(createChange);
        }
    }

    /**
     * Updates ventilation procedure
     *
     * @param id EntityId<VentilationParameter>
     * @param endDate Date
     * @param mode VentilationModeCode
     */
    updateVentilationProcedure(id: EntityId<VentilationProcedure>, endDate: Date, mode?: VentilationModeCode): void {
        this.saveChange({
            model: 'VentilationProcedure',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                endDate,
                mode,
            },
        });
    }

    /**
     * Removes ventilation procedure
     *
     * @param id EntityId<VentilationParameter>
     */
    removeVentilationProcedure(id: EntityId<VentilationProcedure>): void {
        this.saveChange({
            model: 'VentilationProcedure',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Creates medication prescription
     *
     * @param payload MedicationPrescription
     */
    createMedicationPrescription(payload: MedicationPrescription): void {
        const id = uuidv4();
        this.saveChange({
            model: 'MedicationPrescription',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: compactObject(payload), // cuz BE doesn't accept fields with null value in changelog requests
        });
    }

    /**
     * Creates medication prescriptions
     *
     * @param medicationPrescriptions MedicationPrescription[]
     */
    createMedicationPrescriptions(medicationPrescriptions: MedicationPrescription[]): void {
        const changes: ChangeLogEntry<ChangeLogModel>[] = medicationPrescriptions.map(payload => {
            const id = uuidv4();
            return {
                model: 'MedicationPrescription',
                modelId: id as string,
                operation: ChangeLogOperation.Create,
                payload,
            };
        }, []);
        if (changes.length) {
            this.saveChanges(changes);
        }
    }

    /**
     * Updates medication prescription
     *
     * @param updatedPrescriptions
     * @param updateRate
     */
    updateMedicationPrescriptions(updatedPrescriptions: MedicationPrescription[], updateRate?: boolean): void {
        const changes: ChangeLogEntry<ChangeLogModel>[] = updatedPrescriptions.map(item => {
            const payload = {
                ...(updateRate && item.rate && { rate: item.rate }),
                ...pick(
                    item,
                    'isStopped',
                    'isApproved',
                    'endDate',
                    'instructions',
                    'lastChangedAt',
                    'lastChangedBy',
                    'lastChangedByRole',
                ),
            };
            return {
                model: 'MedicationPrescription',
                modelId: item.id as string,
                operation: ChangeLogOperation.Update,
                payload,
            };
        });

        if (changes.length) {
            this.saveChanges(changes);
        }
    }

    /**
     * Updates medication prescription
     *
     * @param id of medication to remove
     */
    removeMedicationPrescription(id: EntityId<MedicationPrescription>): void {
        this.saveChange({
            model: 'MedicationPrescription',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Creates procedure prescription
     *
     * @param procedurePrescription ProcedurePrescription
     */
    createProcedurePrescription(procedurePrescription: ProcedurePrescription): void {
        const id = uuidv4();

        this.saveChange({
            model: 'ProcedurePrescription',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: procedurePrescription,
        });
    }

    /**
     * Creates procedure prescriptions
     *
     * @deprecated
     * @param procedurePrescriptions ProcedurePrescription[]
     */
    createProcedurePrescriptions(procedurePrescriptions: ProcedurePrescription[]): void {
        const changes: ChangeLogEntry<ChangeLogModel>[] = procedurePrescriptions.map(procedurePrescription => {
            const id = uuidv4();
            return {
                model: 'ProcedurePrescription',
                modelId: id as string,
                operation: ChangeLogOperation.Create,
                payload: {
                    ...procedurePrescription,
                },
            };
        }, []);

        if (changes.length) {
            this.saveChanges(changes);
        }
    }

    /**
     * Creates prescriptions bulk from Set
     *
     * Practitioner is able to add the prescription set to the prescription list
     *
     * @param medicationPrescriptions MedicationPrescription[]
     * @param procedurePrescriptions ProcedurePrescription[]
     */
    createPrescriptionsBulk(
        medicationPrescriptions: MedicationPrescription[],
        procedurePrescriptions: ProcedurePrescription[],
    ): void {
        const medicationChanges: ChangeLogEntry<ChangeLogModel>[] = medicationPrescriptions?.map(payload => {
            const id = uuidv4();
            return {
                model: 'MedicationPrescription',
                modelId: id,
                operation: ChangeLogOperation.Create,
                payload,
            };
        });

        const procedureChanges: ChangeLogEntry<ChangeLogModel>[] = procedurePrescriptions?.map(payload => {
            const id = uuidv4();
            return {
                model: 'ProcedurePrescription',
                modelId: id as string,
                operation: ChangeLogOperation.Create,
                payload,
            };
        });

        if (medicationChanges?.length || procedureChanges?.length) {
            this.saveChanges(medicationChanges.concat(procedureChanges));
        }
    }

    /**
     * Updates procedure prescriptions
     *
     * @param data ProcedurePrescription[]
     */
    updateProcedurePrescriptions(data: Partial<ProcedurePrescription>[]): void {
        const changes: ChangeLogEntry<ChangeLogModel>[] = data.map(pp => ({
            model: 'ProcedurePrescription',
            modelId: pp.id as string,
            operation: ChangeLogOperation.Update,
            payload: pp,
        }));

        this.saveChanges(changes);
    }

    /**
     * Updates procedure prescription
     *
     * @param data ProcedurePrescription
     */
    updateProcedurePrescription(data: Partial<ProcedurePrescription>): void {
        const { id: modelId, ...payload } = data;
        const change: ChangeLogEntry<ChangeLogModel> = {
            model: 'ProcedurePrescription',
            modelId,
            operation: ChangeLogOperation.Update,
            payload,
        };

        this.saveChange(change);
    }

    /**
     * Creates output
     *
     * @param output Output
     */
    createOutput(output: PatientOutput): void {
        const id = uuidv4();

        this.saveChange({
            model: 'Output',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                ...output,
            },
        });
    }

    /**
     * Updates output
     *
     * @param id EntityId<PatientOutput>
     * @param value number
     */
    updateOutput(id: EntityId<PatientOutput>, value: number): void {
        this.saveChange({
            model: 'Output',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                value,
            },
        });
    }

    /**
     * Removes output
     *
     * @param id EntityId<PatientOutput>
     */
    removeOutput(id: EntityId<PatientOutput>): void {
        this.saveChange({
            model: 'Output',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Creates medication administration
     *
     * @param payload MedicationAdministration
     */
    createMedicationAdministration(payload: MedicationAdministration): void {
        const id = uuidv4();
        const createChange: ChangeLogEntry<MedicationAdministration> = {
            model: 'MedicationAdministration',
            modelId: id,
            operation: ChangeLogOperation.Create,
            payload: compactObject(omit(payload, 'previousRunningValueId', 'previousRunningValueEndDate')), // cuz BE doesn't accept fields with null value in changelog requests
        };

        if (payload.previousRunningValueId) {
            this.saveChanges([
                {
                    model: 'MedicationAdministration',
                    modelId: payload.previousRunningValueId,
                    operation: ChangeLogOperation.Update,
                    payload: {
                        endDate: payload.previousRunningValueEndDate,
                    },
                },
                createChange,
            ]);
        } else {
            this.saveChange(createChange);
        }
    }

    /**
     * create non running medication administration
     *
     * @param medicationAdministrations
     */
    createNonRunningMedicationAdministrations(medicationAdministrations: MedicationAdministration[]): void {
        const changes: ChangeLogEntry<ChangeLogModel>[] = medicationAdministrations.map(payload => {
            const id = uuidv4();
            return {
                model: 'MedicationAdministration',
                modelId: id as string,
                operation: ChangeLogOperation.Create,
                payload,
            };
        }, []);

        if (changes.length) {
            this.saveChanges(changes);
        }
    }

    /**
     * Updates medication administration
     *
     * @param id EntityId<PatientOutput>
     * @param changes Partial<MedicationAdministration>
     */
    updateMedicationAdministration(
        id: EntityId<MedicationAdministration>,
        changes: Partial<MedicationAdministration>,
    ): void {
        // cuz BE doesn't accept fields with null value in changelog requests
        changes = compactObject(changes);
        this.saveChange({
            model: 'MedicationAdministration',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                ...changes,
            },
        });
    }

    /**
     * Removes medication administration
     *
     * @param id EntityId<PatientOutput>
     */
    removeMedicationAdministration(id: EntityId<MedicationAdministration>): void {
        this.saveChange({
            model: 'MedicationAdministration',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * remove medication administrations
     *
     * @param ids
     */
    removeMedicationAdministrations(ids: string[]): void {
        // TODO: implement after BE will provide endpoint
    }

    /**
     * Creates balance target
     *
     * @param balanceTarget BalanceTarget
     */
    createBalanceTarget(balanceTarget: BalanceTarget): void {
        const id = uuidv4();

        this.saveChange({
            model: 'BalanceTarget',
            modelId: id,
            operation: ChangeLogOperation.Create,
            payload: {
                ...balanceTarget,
            },
        });
    }

    /**
     * Updates balance target
     *
     * @param id EntityId<BalanceTarget>
     * @param value number
     */
    updateBalanceTarget(id: EntityId<BalanceTarget>, value: number): void {
        this.saveChange({
            model: 'BalanceTarget',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                value,
            },
        });
    }

    /**
     * Removes balance target
     *
     * @param id EntityId<BalanceTarget>
     */
    removeBalanceTarget(id: EntityId<BalanceTarget>): void {
        this.saveChange({
            model: 'BalanceTarget',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Creates basic care procedure
     *
     * @param basicCareProcedure BasicCareProcedure
     */
    createBasicCareProcedure(basicCareProcedure: BasicCareProcedure): void {
        const id = uuidv4();

        this.saveChange({
            model: 'BasicCare',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(basicCareProcedure), // cuz BE doesn't accept fields with null value in changelog requests
            },
        });
    }

    /**
     * Updates basic care procedure
     *
     * @param id EntityId<BasicCareProcedure>
     * @param basicCareProcedureUpdate Partial<BasicCareProcedure>
     */
    updateBasicCareProcedure(
        id: EntityId<BasicCareProcedure>,
        basicCareProcedureUpdate: Partial<BasicCareProcedure>,
    ): void {
        this.saveChange({
            model: 'BasicCare',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                ...basicCareProcedureUpdate,
            },
        });
    }

    /**
     * Removes basic care procedure
     *
     * @param id EntityId<BasicCareProcedure>
     */
    removeBasicCareProcedure(id: EntityId<BasicCareProcedure>): void {
        this.saveChange({
            model: 'BasicCare',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Creates procedure
     *
     * @param procedure Procedure
     */
    createProcedure(procedure: Procedure): void {
        const id = uuidv4();

        this.saveChange({
            model: 'Procedure',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(procedure), // cuz BE doesn't accept fields with null value in changelog requests,
            },
        });
    }

    /**
     * Creates medication prescriptions
     *
     * @param procedures Procedure[]
     */
    createProcedures(procedures: Procedure[]): void {
        const changes: ChangeLogEntry<ChangeLogModel>[] = procedures.map(payload => {
            const id = uuidv4();
            return {
                model: 'Procedure',
                modelId: id as string,
                operation: ChangeLogOperation.Create,
                payload,
            };
        }, []);

        if (changes.length) {
            this.saveChanges(changes);
        }
    }

    /**
     * Updates procedure
     *
     * @param id EntityId<Procedure>
     * @param procedureUpdate Partial<BasicCareProcedure>
     */
    updateProcedure(id: EntityId<BasicCareProcedure>, procedureUpdate: Partial<Procedure>): void {
        this.saveChange({
            model: 'Procedure',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                ...procedureUpdate,
            },
        });
    }

    /**
     * Removes procedure
     *
     * @param id EntityId<Procedure>
     */
    removeProcedure(id: EntityId<Procedure>): void {
        this.saveChange({
            model: 'Procedure',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Remove procedures
     * @param ids
     */
    removeProcedures(ids: string[]): void {
        // FIXME: implement after BE will provide endpoint
    }

    /**
     * Creates entry control
     *
     * @param entryControl BasicCareProcedure
     */
    createEntryControl(entryControl: SetOptional<EntryControl, 'patientId' | 'encounterId'>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'EntryControl',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // bc BE will convert null to false if we send it, but allow some values to be able to clear them
                ...compactObject(entryControl, ['careChecks', 'glasgowComaScale', 'careReport', 'cuffCmH2o']), // bc BE will convert null to false if we send it
            },
        });
    }

    /**
     * Updates entry control
     *
     * @param id EntityId<EntryControl>
     * @param entryControlUpdate Partial<EntryControl>
     */
    updateEntryControl(id: EntityId<EntryControl>, entryControlUpdate: Partial<EntryControl>): void {
        this.saveChange({
            model: 'EntryControl',
            modelId: id as string,
            operation: ChangeLogOperation.Update,
            payload: {
                id,
                // bc BE will convert null to false if we send it, but allow some values to be able to clear them
                ...compactObject(entryControlUpdate, ['careChecks', 'glasgowComaScale', 'careReport', 'cuffCmH2o']),
            },
        });
    }

    /**
     * Updates workflow
     *
     * @param {string} id - id
     * @param {Partial<Workflow>} workflow - workflow
     */
    updateWorkflow(id: string, workflow: Partial<Workflow>): void {
        this.saveChange({
            model: 'WorkflowQuestionnaireResponse',
            modelId: id || uuidv4(),
            operation: ChangeLogOperation.Update,
            payload: {
                ...workflow,
            },
        });
    }

    /**
     * Remove workflow
     *
     * @param {string} id - id
     */
    removeWorkflow(id: string): void {
        this.saveChange({
            model: 'WorkflowQuestionnaireResponse',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Creates daily goal
     *
     * @param goal DailyGoal
     */
    createDailyGoal(goal: Partial<DailyGoal>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'DailyGoal',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(goal), // cuz BE doesn't accept fields with null value in changelog requests,
            },
        });
    }

    /**
     * Creates infection
     *
     * @param infection Partial<InfectionStatus>
     */
    createInfection(infection: Partial<InfectionStatus>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'InfectionStatus',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(infection), // cuz BE doesn't accept fields with null value in changelog requests,
            },
        });
    }

    /**
     * Creates wound
     *
     * @param wound Partial<WoundStatus>
     */
    createWound(wound: Partial<WoundStatus>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'WoundStatus',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(wound), // cuz BE doesn't accept fields with null value in changelog requests,
            },
        });
    }

    /**
     * Creates vaccination
     *
     * @param vaccination Partial<VaccinationStatus>
     */
    createVaccination(vaccination: Partial<VaccinationStatus>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'VaccinationStatus',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(vaccination), // cuz BE doesn't accept fields with null value in changelog requests,
            },
        });
    }

    /**
     * Creates valuable
     *
     * @param valuable Partial<Valuables>
     */
    createValuable(valuable: Partial<Valuables>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'Valuables',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(valuable), // cuz BE doesn't accept fields with null value in changelog requests,
            },
        });
    }

    /**
     * Creates surgery
     *
     * @param surgery Partial<SurgeryPrescription>
     */
    createSurgeryPrescription(surgery: Partial<SurgeryPrescription>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'SurgeryPrescription',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(surgery), // cuz BE doesn't accept fields with null value in changelog requests,
            },
        });
    }

    /**
     * Creates therapyLimitation
     *
     * @param therapyLimitation Partial<TherapyLimitations>
     */
    createTherapyLimitation(therapyLimitation: Partial<TherapyLimitations>): void {
        const id = uuidv4();

        this.saveChange({
            model: 'TherapyLimitations',
            modelId: id as string,
            operation: ChangeLogOperation.Create,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(therapyLimitation), // cuz BE doesn't accept fields with null value in changelog requests,
            },
        });
    }

    /**
     * Updates daily goal
     *
     * @param goal DailyGoal
     */
    updateDailyGoal(goal: Partial<DailyGoal>): void {
        this.saveChange({
            model: 'DailyGoal',
            modelId: goal.id,
            operation: ChangeLogOperation.Update,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(goal),
            },
        });
    }

    /**
     * Updates infection
     *
     * @param infection InfectionStatus
     */
    updateInfection(infection: Partial<InfectionStatus>): void {
        this.saveChange({
            model: 'InfectionStatus',
            modelId: infection.id,
            operation: ChangeLogOperation.Update,
            payload: {
                infectionAnnotation: '',
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(infection),
            },
        });
    }

    /**
     * Updates wound
     *
     * @param wound WoundStatus
     */
    updateWound(wound: Partial<WoundStatus>): void {
        this.saveChange({
            model: 'WoundStatus',
            modelId: wound.id,
            operation: ChangeLogOperation.Update,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(wound),
            },
        });
    }

    /**
     * Updates vaccination
     *
     * @param vaccination VaccinationStatus
     */
    updateVaccination(vaccination: Partial<VaccinationStatus>): void {
        this.saveChange({
            model: 'VaccinationStatus',
            modelId: vaccination.id,
            operation: ChangeLogOperation.Update,
            payload: {
                verificationManufacturer: '',
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(vaccination),
            },
        });
    }

    /**
     * Updates valuable
     *
     * @param valuable Valuables
     */
    updateValuable(valuable: Partial<Valuables>): void {
        this.saveChange({
            model: 'Valuables',
            modelId: valuable.id,
            operation: ChangeLogOperation.Update,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(valuable),
            },
        });
    }

    /**
     * Updates surgery
     *
     * @param surgery SurgeryPrescription
     */
    updateSurgeryPrescription(surgery: Partial<SurgeryPrescription>): void {
        this.saveChange({
            model: 'SurgeryPrescription',
            modelId: surgery.id,
            operation: ChangeLogOperation.Update,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(surgery),
            },
        });
    }

    /**
     * Updates TherapyLimitation
     *
     * @param therapyLimitation TherapyLimitations
     */
    updateTherapyLimitation(therapyLimitation: Partial<TherapyLimitations>): void {
        this.saveChange({
            model: 'TherapyLimitations',
            modelId: therapyLimitation.id,
            operation: ChangeLogOperation.Update,
            payload: {
                // TODO: remove after BE make changes to accept nullable duration field
                ...compactObject(therapyLimitation),
            },
        });
    }

    /**
     * Removes daily goal
     *
     * @param id string
     */
    removeDailyGoal(id: string): void {
        this.saveChange({
            model: 'DailyGoal',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Removes infection
     *
     * @param id string
     */
    removeInfection(id: string): void {
        this.saveChange({
            model: 'InfectionStatus',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Removes wound
     *
     * @param id string
     */
    removeWound(id: string): void {
        this.saveChange({
            model: 'WoundStatus',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Removes vaccination
     *
     * @param id string
     */
    removeVaccination(id: string): void {
        this.saveChange({
            model: 'VaccinationStatus',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Removes valuable
     *
     * @param id string
     */
    removeValuable(id: string): void {
        this.saveChange({
            model: 'Valuables',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Removes surgery prescription
     *
     * @param id string
     */
    removeSurgeryPrescription(id: string): void {
        this.saveChange({
            model: 'SurgeryPrescription',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Remove therapy limitation
     *
     * @param id string
     */
    removeTherapyLimitation(id: string): void {
        this.saveChange({
            model: 'TherapyLimitations',
            modelId: id as string,
            operation: ChangeLogOperation.Delete,
        });
    }

    /**
     * Triggers to save a single change
     *
     * @param change
     */
    private saveChange(change: ChangeLogEntry<ChangeLogModel>): void {
        this.store.dispatch(ChangeLogAction.saveChangeAction.action({ change }));
    }

    /**
     * Triggers to save multiple changes
     *
     * @param changes
     */
    private saveChanges(changes: ChangeLogEntry<ChangeLogModel>[]): void {
        this.store.dispatch(ChangeLogAction.saveChangesAction.action({ changes }));
    }

    //#endregion Actions
}
