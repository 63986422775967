import { Action } from '@ngrx/store';
import { Observable, ObservableInput } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AsyncAction } from '../models';
import { handleEffectError } from './error.helper';

/**
 * Default implementation of async action effect
 * @param apiCall apiCall
 * @param asyncAction asyncAction
 * @param expectedErrorCodes (do not show unexpected error alert for these codes)
 * @param ignoreUnexpectedErrors (do not show unexpected error alert at all)
 */
export const makeDefaultAsyncActionEffect = <T>(
    apiCall: Observable<T>,
    asyncAction: AsyncAction<any, T>,
    expectedErrorCodes?: string[],
    ignoreUnexpectedErrors = false,
): ObservableInput<Action> => {
    return apiCall.pipe(
        switchMap((result: T) => {
            return [
                asyncAction.succeededAction({
                    payload: result,
                }),
            ];
        }),
        handleEffectError(asyncAction.failedAction, expectedErrorCodes, ignoreUnexpectedErrors),
    );
};
