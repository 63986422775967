/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { ReportType } from '@mona/models';

export const loadReports = createAction('REPORTS:LOAD_AVAILABLE_REPORTS');

export const loadReportsSuccess = createAction(
    'REPORTS:LOAD_AVAILABLE_REPORTS_SUCCEEDED',
    props<{ reports: ReportType[] }>(),
);

export const loadReportsFailure = createAction('REPORTS:LOAD_AVAILABLE_REPORTS_FAILED', props<{ error?: any }>());

export const clearReports = createAction('REPORTS:LOAD_AVAILABLE_REPORTS_CLEAR');

export const exportFullReport = createAction('REPORTS:EXPORT_FULL_REPORT');
export const exportFullReportSuccess = createAction('REPORTS:EXPORT_FULL_REPORT_SUCCEEDED', props<{ file: File }>());
export const exportFullReportFailed = createAction('REPORTS:EXPORT_FULL_REPORT_FAILED', props<{ error?: any }>());
