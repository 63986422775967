import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels } from '../api-models';
import { transformApiHealthSettings } from '../helpers';

/**
 * API for the health check API
 */
@Injectable({
    providedIn: 'root',
})
export class ApiHealthService {
    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpClient) {}

    /**
     * Check server availability
     *
     * @param serverUrl string
     */
    checkServerAvailability(serverUrl: string): Observable<any> {
        return this.http.get(`${serverUrl}/api/health/`);
    }

    /**
     * Load api health
     *
     * @param serverUrl
     */
    loadApiHealth(serverUrl: string): Observable<ApiModels.HealthSettings.Model> {
        return this.http
            .get<ApiModels.HealthSettings>(`${serverUrl}/api/health/`)
            .pipe(map(transformApiHealthSettings));
    }
}
