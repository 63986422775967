import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { MedicationPrescription, Procedure, ProcedurePrescription, TableDataItem } from '@mona/models';

/**
 * Checks if cell should is suggested for entering values
 */
@Pipe({
    name: 'isCellSuggested',
})
@Injectable({
    providedIn: 'root',
})
export class IsCellSuggestedPipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param inputsSuggestions Map<EntityId<MedicationPrescription | ProcedurePrescription>, Set<string>>
     * @param cell TableDataItem<MedicationAdministration  | Procedure>
     * @param columnIsoDate string
     */
    transform(
        inputsSuggestions: Map<EntityId<MedicationPrescription | ProcedurePrescription>, Set<string>>,
        cell: TableDataItem<MedicationPrescription | Procedure>,
        columnIsoDate: string,
    ): boolean {
        return !cell.values[columnIsoDate] && inputsSuggestions?.get(cell.code)?.has(columnIsoDate);
    }
}
