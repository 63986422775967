import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { noop, Observable, of } from 'rxjs';
import { IpcMainEvent, IpcRendererEvent } from '@mona/events';
import { MonaRpcService } from './rpc.abstract.service';

/**
 * Overrides
 */
@Injectable({ providedIn: 'root' })
export class MonaRpcBrowserService extends MonaRpcService {
    /**
     * Communicate asynchronously from a renderer process to the main process.
     */
    protected readonly ipcRenderer: any;

    /**
     * Possible values: 'aix', 'darwin', 'freebsd', 'linux', 'openbsd', 'sunos', and 'win32'
     */
    readonly platform: NodeJS.Platform;

    /**
     * INFO: add comment
     *
     * @param window
     */
    constructor(@Inject(WINDOW) protected window: Window) {
        super(window);
        this.platform = 'aix';
        this.ipcRenderer = {};
    }

    /**
     * getIsLinux
     */
    getIsLinux(): boolean {
        return false;
    }

    /**
     * Get desktop capturer sources
     *
     * @param opts
     */
    getDesktopCapturerSources(opts): Promise<any[]> {
        return Promise.resolve([]);
    }

    /**
     * INFO: add comment
     *
     * @param event
     * @param payload
     */
    send(event: IpcMainEvent, payload?: any) {
        noop();
    }

    /**
     * INFO: add comment
     *
     * @param event
     * @param payload
     */
    sendSync<T>(event: IpcMainEvent, payload?: any): T {
        return null;
    }

    /**
     * INFO: add comment
     *
     * @param event
     * @param payload
     */
    invoke<T>(event: IpcMainEvent, payload?: any): Promise<any> {
        switch (event) {
            case IpcMainEvent.GET_JWT_TOKEN:
                return Promise.resolve('mock-token');
            case IpcMainEvent.GET_MAC_ADDRESS:
                return Promise.resolve(this.window.navigator.userAgent.replace(/\s/g, '_'));
            case IpcMainEvent.EMULATE_RFID_HANDLE:
            case IpcMainEvent.AWAIT_RFID_SCAN:
                return Promise.resolve('326a84eb5bdb498c9e469340135e1d86');
            case IpcMainEvent.GET_KEYS:
                return Promise.resolve({
                    version: 1,
                    sshKey: {
                        privateKey: 'string',
                        publicKey: 'string',
                    },
                    telemedicineSshKey: {
                        privateKey: 'string',
                        publicKey: 'string',
                    },
                });
            default:
                return Promise.resolve(null);
        }
    }

    /**
     * INFO: add comment
     *
     * @param event
     * @param payload
     */
    invokeWithCustomErrors<T>(event: IpcMainEvent, payload?: any): Promise<T> {
        return Promise.resolve(null);
    }

    /**
     * INFO: add comment
     *
     * @param event
     */
    listen<T>(event: IpcRendererEvent): Observable<T> {
        console.warn('Using MonaRpcService.listen might result in a thread blocking! Consider using invoke instead.');

        return of();
    }

    /**
     * INFO: add comment
     *
     * @param event
     */
    onMessage<T>(event: IpcRendererEvent): Observable<T> {
        console.warn('Using MonaRpcService.listen might result in a thread blocking! Consider using invoke instead.');

        return of();
    }

    /**
     * INFO: add comment
     */
    reload() {
        super.reload();
        location.href = '/';
    }
    /**
     * Catch calling from web instance
     *
     * @param method The method name that was invoked
     */
    ensurePlatform(method: string): void {
        noop();
    }
}
