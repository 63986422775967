import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import { AdmissionType } from '@mona/models';
import { AnamnesisForm } from '../../models';

/**
 * Anamnesis form
 */
@Component({
    selector: 'app-anamnesis-form',
    templateUrl: './anamnesis-form.component.html',
    styleUrls: ['./anamnesis-form.component.scss'],
})
export class AnamnesisFormComponent {
    /**
     * Form group
     */
    @Input() formGroup: FormGroup<AnamnesisForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is form active / visible
     */
    @Input() isActive: boolean;

    /**
     * Max textarea rows count size
     */
    @Input() textareaMaxRows = 40;

    /**
     * Keyboard enter clicked
     */
    @Output() keyboardEnterClicked: EventEmitter<void> = new EventEmitter();

    /**
     * Admission type options
     */
    admissionTypeOptions: { value: AdmissionType; labelKey: string }[] = [
        {
            value: AdmissionType.PLANNED_SURGICAL,
            labelKey: this.translateService.instant('apps.admission.anamnesis.admissionTypes.plannedSurgical'),
        },
        {
            value: AdmissionType.EMERGENCY_SURGICAL,
            labelKey: this.translateService.instant('apps.admission.anamnesis.admissionTypes.emergencySurgical'),
        },
        {
            value: AdmissionType.GENERAL_MEDICAL,
            labelKey: this.translateService.instant('apps.admission.anamnesis.admissionTypes.general'),
        },
    ];

    /**
     * Constructor
     *
     * @param translateService translateService
     */
    constructor(private translateService: TranslateService) {}
}
