import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Interface for components that use a can deactivate guard
 */
export interface CanComponentDeactivate {
    /**
     * Returns if this component can deactivate or not
     */
    canDeactivate: (navigationState: { [key: string]: any }) => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * Prevents navigation out of a component
 */
@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard {
    /**
     * Constructor
     *
     * @param router Router
     */
    constructor(private router: Router) {}

    /**
     * Returns if navigation is allowed
     *
     * @param component CanComponentDeactivate
     * @param route ActivatedRouteSnapshot
     * @param state RouterStateSnapshot
     */
    canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentNavigation = this.router.getCurrentNavigation();
        return component?.canDeactivate ? component.canDeactivate(currentNavigation?.extras?.state || {}) : true;
    }
}
