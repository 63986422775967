import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NgControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { DateFilterFn, MatDatepicker, MatDatepickerInput, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { toDate } from '@mona/shared/date';
import { isNullOrUndefined } from '@mona/shared/utils';
import { DynamicControlValue } from '@mona/ui/components/forms';

/**
 * @ignore
 */
const componentName = 'ui-date-picker';

/**
 * Component to display the ui date-picker
 */
@Component({
    selector: 'ui-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    // We need to use host instead of @HostBinding: https://github.com/NationalBankBelgium/ui/issues/664
    host: {
        // The `mat-form-field-flex` class is necessary to apply `mat-form-field` styles on this date-picker component.
        class: componentName + ' d-flex',
    },
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: UiDatePickerComponent,
            multi: true,
        },
        {
            // This implementation has been made thanks to the official documentation.
            // See: https://v7.material.angular.io/guide/creating-a-custom-form-field-control
            provide: MatFormFieldControl,
            useExisting: UiDatePickerComponent,
        },
    ],
})
export class UiDatePickerComponent
    implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor, Validator, MatFormFieldControl<Date>
{
    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    static nextId = 0;

    /**
     * Color theme
     */
    @Input()
    color?: string; // Needs to be for Angular to be able to read this property inside the template.

    /**
     * Part of {@link MatFormFieldControl} API
     *
     * @ignore
     */
    @HostBinding()
    id = `ui-date-picker-input-${UiDatePickerComponent.nextId++}`;

    /**
     * Part of {@link MatFormFieldControl} API
     *
     * @ignore
     */
    @HostBinding('attr.aria-describedby')
    describedBy = '';

    /**
     * Part of {@link MatFormFieldControl} API
     *
     * @ignore
     */
    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    /**
     * Filter function or a string
     * Whenever this value is changed, we set the dateFilter
     */
    @Input()
    get dateFilter(): DateFilterFn<any> | undefined {
        return this._dateFilter;
    }

    set dateFilter(value: DateFilterFn<any> | undefined) {
        this._dateFilter = value;
    }

    /**
     * @ignore
     * @internal
     */
    private _dateFilter?: DateFilterFn<any>;

    /**
     * Whether the datepicker is disabled
     */
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    /**
     * @ignore
     * @internal
     */
    private _disabled = false;

    /**
     * Maximum date of the date picker
     *
     * Supported types: `Date | undefined | null`
     */
    @Input()
    set max(value: Date | null) {
        if (isNullOrUndefined(value)) {
            this._max = null;
        } else {
            this._max = toDate(value);
        }
    }

    /**
     * INFO: add comment
     */
    get max(): Date | null {
        return this._max;
    }

    /**
     * @ignore
     * Angular expects a Date or null value.
     */
    private _max: Date | null = null;

    /**
     * Minimum date of the date picker
     *
     * Supported types: `Date | undefined | null`
     */
    @Input()
    set min(value: Date | null) {
        if (isNullOrUndefined(value)) {
            this._min = null;
        } else {
            this._min = toDate(value);
        }
    }

    /**
     * INFO: add comment
     */
    get min(): Date | null {
        return this._min;
    }

    /**
     * @ignore
     * Angular expects a Date or null value.
     */
    _min: Date | null = null;

    /**
     * The HTML `id` attribute of the date picker's calendar popup.
     * This id is also used, suffixed with `"-input"`, as the HTML `id` attribute of the date picker's input field.
     */
    @Input()
    pickerId = '';

    /**
     * HTML `name` attribute of the element.
     */
    @Input()
    name = '';

    /**
     * touchUi attribute for material
     */
    @Input()
    touchUi = false;

    /**
     * Placeholder to be displayed in the datepicker.
     * This is dynamically translated via the @ngx-translate service if the provided text is defined in the translation keys).
     */
    @Input()
    set placeholder(value: string) {
        this.originalPlaceholder = value || '';
        // Handle translation internally because mat-form-field uses the value of `@Input placeholder` to display the label / placeholder
        this._placeholder = this.originalPlaceholder
            ? this.translateService.instant(this.originalPlaceholder)
            : this.originalPlaceholder;
        this.stateChanges.next();
    }

    /**
     * INFO: add comment
     */
    get placeholder(): string {
        return this._placeholder;
    }

    /**
     * @ignore
     */
    private _placeholder = '';

    /**
     * If the date-picker is required or not.
     *
     * Default: `false` (the date-picker is not required)
     */
    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
    }

    /**
     * @ignore
     * @internal
     */
    private _required = false;

    /**
     * Source Date to be bound to the datepicker model
     */
    @Input()
    get value(): Date | null {
        return this._value;
    }

    set value(value: Date | DynamicControlValue | string | null) {
        if (value) {
            const dateValue = new Date(value['display'] ? value['display'] : value);

            this._value = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
            this.stateChanges.next();
        }
    }

    /**
     * @ignore
     * @internal
     */
    private _value: Date | null = null;

    /**
     * Output that will emit a specific date whenever the selection has changed
     */
    @Output()
    readonly dateChange = new EventEmitter<Date | undefined>();

    /**
     * Output that will emit a specific date whenever the input has changed
     */
    @Output()
    readonly dateInput = new EventEmitter<Date | undefined>();

    /**
     * Reference to the MatDatepicker embedded in this component
     */
    @ViewChild(MatDatepicker, { static: true })
    picker!: MatDatepicker<Date>;

    /**
     * Reference to the MatDatepickerInput embedded in this component
     */
    @ViewChild(MatDatepickerInput, { static: true })
    pickerInput!: MatDatepickerInput<Date>;

    /**
     * @ignore
     * @internal
     */
    inputMaskEnabled = false;

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    ngControl: NgControl | null = null;

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    public controlType = componentName;

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    stateChanges: Subject<void> = new Subject<void>();

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    focused = false;

    /**
     * @ignore
     * @internal
     */
    pickerInputTouched = false;

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    get errorState(): boolean {
        // the control can be in an error state as long as one of these conditions is met:
        // 1) the user has interacted with it
        // 2) the control is programmatically marked as 'touched' or 'dirty'
        const newErrorState =
            this.ngControl !== null &&
            this.ngControl.control !== null &&
            (this.pickerInputTouched || !!this.ngControl.touched || !!this.ngControl.dirty) &&
            (!!this.ngControl.errors || !!this.pickerInput.validate(this.ngControl.control));

        // IMPORTANT: emit a state change when the errorState changes
        // This is needed to force the MatFormFieldControl to refresh and render the MatError's
        if (this._errorState !== newErrorState) {
            this._errorState = newErrorState;
            this.stateChanges.next();
        }

        return this._errorState;
    }

    /**
     * The current error state
     * @ignore
     * @internal
     */
    _errorState = false;

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    get empty(): boolean {
        return !this.value;
    }

    /**
     * @ignore
     * @internal
     * Original placeholder translation key to keep in memory to translate again when language changes.
     */
    private originalPlaceholder = '';

    /**
     * @ignore
     * @internal
     */
    private translateOnLangChangeSubscription!: Subscription;

    /**
     * Class constructor
     *
     * @param renderer - Angular `Renderer2` wrapper for DOM manipulations.
     * @param elementRef - Reference to the DOM element where this component is attached to.
     * @param cdRef - Reference to the change detector attached to this component
     * @param fm - The Focus Monitor Service
     * @param injector - The Injector of the application
     * @param translateService - The `TranslateService` instance of the application.
     */
    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef<HTMLElement>,
        private cdRef: ChangeDetectorRef,
        private fm: FocusMonitor,
        private injector: Injector,
        private translateService: TranslateService,
    ) {
        fm.monitor(elementRef, true).subscribe((origin: FocusOrigin) => {
            this.focused = !!origin && !this.disabled;
            this.stateChanges.next();
        });
    }

    /**
     * Component lifecycle hook
     */
    ngOnInit(): void {
        this.ngControl = this.injector.get<NgControl>(NgControl, <any>null);

        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }

        this.translateOnLangChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            // re-assign the placeholder to refresh the translation (see 'placeholder' setter)
            this.placeholder = this.originalPlaceholder;
        });

        if (this.color) {
            this.renderer.addClass(this.elementRef.nativeElement, 'ui-' + this.color);
        }
    }

    /**
     * Component lifecycle hook
     */
    ngAfterViewInit(): void {
        const markPickerInputAsTouched = (): void => {
            this.pickerInputTouched = true;
            this.stateChanges.next();
        };

        // the picker input should be marked as touched when it has actually been touched or when the calendar is closed
        // this way we ensure that the errors are displayed properly when the user interacted with the picker (and not when the picker is pristine)
        this.pickerInput.registerOnTouched(markPickerInputAsTouched);
        this.picker.closedStream.subscribe(markPickerInputAsTouched);
    }

    /**
     * Component lifecycle hook
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['max'] || changes['min'] || changes['required']) {
            this.cdRef.detectChanges();
            // IMPORTANT: the '_onValidatorChange()' callback from Validator API should not be called here to update the validity of the control because it triggers a valueChange event!
            // therefore we call 'updateValueAndValidity()' manually on the control instead and without emitting the valueChanges event :)
            if (this.ngControl && this.ngControl.control) {
                this.ngControl.control.updateValueAndValidity();
            }
            this.stateChanges.next();
            this.cdRef.detectChanges();
        }
    }

    /**
     * Component lifecycle hook
     */
    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elementRef.nativeElement);

        if (this.translateOnLangChangeSubscription) {
            this.translateOnLangChangeSubscription.unsubscribe();
        }
    }

    /**
     * @ignore
     * @internal
     * The registered callback function called when an input event occurs on the input element.
     */
    private _onChange: (_: any) => void = (_: any) => {
        /*noop*/
    };

    /**
     * @ignore
     * @internal
     * The registered callback function called when a blur event occurs on the input element.
     */
    private _onTouched: () => void = () => {
        /*noop*/
    };

    /**
     * Part of {@link ControlValueAccessor} API
     * Registers a function to be called when the control value changes.
     * @ignore
     * @internal
     */
    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    /**
     * Part of {@link ControlValueAccessor} API
     * Registers a function to be called when the control is touched.
     * @ignore
     * @internal
     */
    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    /**
     * Part of {@link ControlValueAccessor} API
     * Sets the "disabled" property on the input element.
     * @ignore
     * @internal
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.stateChanges.next();
    }

    /**
     * Part of {@link ControlValueAccessor} API
     * Sets the "value" property on the input element.
     * @ignore
     * @internal
     */
    writeValue(obj: any): void {
        this.value = obj;
    }

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    /**
     * Part of {@link MatFormFieldControl} API
     * @ignore
     * @internal
     */
    onContainerClick(event: MouseEvent): void {
        if ((<Element>event.target).tagName.toLowerCase() !== 'input') {
            const inputElement: HTMLElement = this.elementRef.nativeElement.querySelector('input');
            if (inputElement) {
                inputElement.focus();
            }
        }
    }

    /**
     * Part of {@link Validator} API
     * @ignore
     * @internal
     */
    registerOnValidatorChange(_fn: () => void): void {
        // we don't need to keep a reference to the callback function (i.e. in a '_onValidatorChange' property)
        // because such callback, when it is called to update the validity of the control, it triggers a valueChange event too!
    }

    /**
     * Part of {@link Validator} API
     * @ignore
     * @internal
     */
    validate(control: AbstractControl): ValidationErrors | null {
        return this.pickerInput.validate(control);
    }

    /**
     * Wrap the dateFilter function
     * We use the DateFnsModule, so the MatDatepicker will return a Date object.
     * To keep consistency with the old code, the end user should be able to specify a custom dateFilter accepting a Date object as a parameter
     *
     * @param date - The date to be checked
     * @returns Whether the date is filtered or not
     */
    dateFilterFnWrapper = (date: Date | null): boolean => {
        if (date === null) {
            return false;
        }

        if (typeof this._dateFilter === 'function') {
            return this._dateFilter(toDate(date));
        }

        return true;
    };

    /**
     * Filter only the weekend days
     *
     * @param date - The date to be checked
     * @returns Whether the date is a weekend day or not
     */
    filterOnlyWeekends(date: Date): boolean {
        const day: number = date.getDay();
        return day === 0 || day === 6;
    }

    /**
     * Filter only the week days
     *
     * @param date - The date to be checked
     * @returns Whether the date is a week day or not
     */
    filterOnlyWeekdays(date: Date): boolean {
        const day: number = date.getDay();
        return day !== 0 && day !== 6;
    }

    /**
     * Handled when a `change` event is fired on the `<input [matDatepicker]="picker">` element, then emits on `dateChange`.
     *
     * @param event - The MatDatepickerInputEvent to re-emit
     */
    onDateChange(event: MatDatepickerInputEvent<Date> | undefined): void {
        this._onTouched();

        this.value = event.value ? toDate(event.value) : null;

        const value: Date | undefined = this.value ? this.value : undefined;
        this._onChange(value);
        // emit after the model has actually changed
        this.dateChange.emit(value);
    }

    /**
     * Handled when an `input` event is fired on this `<input [matDatepicker]="picker">` element, then emits the `dateInput`.
     *
     * @param event - The MatDatepickerInputEvent to re-emit
     */
    onDateInput(event: MatDatepickerInputEvent<Date> | undefined): void {
        const value: Date | undefined = event.value ? toDate(event.value) : undefined;
        this.dateInput.emit(value);
    }

    /**
     * Method triggered when the date-picker input is blurred.
     * This method disables the starkTimestampInput directive.
     */
    onBlur(): void {
        this.inputMaskEnabled = false;
    }
}
