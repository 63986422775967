import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouteWithData } from '@environment';

const routes: RouteWithData[] = [
    {
        path: '',
        loadChildren: () => import('@mona/telemedicine/call').then(m => m.TelemedicineModule),
    },
];

/**
 * INFO: add comment
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TelemedicineShellRoutingModule {}
