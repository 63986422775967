import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { InfectionStatusType } from '@mona/models';
import { InfectionStatusState } from '../../entities';
import { InfectionStatusActions } from '../actions';

export const infectionStatusAdapter: EntityAdapter<InfectionStatusType> = createEntityAdapter<InfectionStatusType>();

export const initialState: InfectionStatusState = infectionStatusAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const infectionStatusReducer = createReducer(
    initialState,
    on(InfectionStatusActions.loadInfectionStatus, state => ({ ...state, isLoading: true })),
    on(InfectionStatusActions.loadInfectionStatusSuccess, (state, { data }) =>
        infectionStatusAdapter.setAll(data, {
            ...state,
            error: null,
            isLoading: false,
        }),
    ),
    on(InfectionStatusActions.loadInfectionStatusFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(InfectionStatusActions.updateInfectionStatus, (state, action) =>
        infectionStatusAdapter.updateMany(action.update, state),
    ),
    on(InfectionStatusActions.upsertInfectionStatus, (state, action) =>
        infectionStatusAdapter.upsertMany(action.data, state),
    ),
    on(InfectionStatusActions.clearInfectionStatus, state => infectionStatusAdapter.removeAll(state)),
    on(InfectionStatusActions.addInfectionStatusChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = infectionStatusAdapter.removeMany(toRemoveIds, state);

        return infectionStatusAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
