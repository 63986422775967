import { ApiModels } from '@mona/api';
import { PractitionerShiftType, PractitionerShift } from '@mona/models';

/**
 * Transforms api practitioner shift
 * @param apiPractitionerShifts ApiModels.PractitionerShift
 */
export const transformApiPractitionerShift = (
    apiPractitionerShifts: ApiModels.PractitionerShift,
): PractitionerShift => {
    return {
        id: apiPractitionerShifts.shift,
        shift: apiPractitionerShifts.shift as PractitionerShiftType,
        start: apiPractitionerShifts.start,
    };
};

/**
 * Transform api practitioner shifts
 * @param apiPractitionerShifts ApiModels.PractitionerShift[]
 */
export const transformApiPractitionerShifts = (
    apiPractitionerShifts: ApiModels.PractitionerShift[],
): PractitionerShift[] => {
    return apiPractitionerShifts.map(apiPractitionerShift => transformApiPractitionerShift(apiPractitionerShift));
};
