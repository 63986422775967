import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addMinutes } from 'date-fns';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PageableModel, ParamsModel, VitalSign } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    ChangeLogState,
    getChangesData,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { isValidDateRange } from '@mona/shared/date';
import { Logger } from '@mona/shared/logger';
import { VitalSignsApi } from '../../infrastructure';
import { VitalSignsAction } from '../actions';
import { selectAllVitals } from '../selectors';

/**
 * Vital signs effects
 */
@Injectable()
export class VitalSignsEffects {
    private logger = new Logger();
    /**
     * Load Vital signs effect
     */
    loadVitalSigns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VitalSignsAction.loadVitalSigns),
            withCurrentEncounterId(),
            switchMap(([{ params }, encounter_id]) =>
                this.vitalSignsApi.getPageableVitalSigns({ ...params, encounter_id }).pipe(
                    catchError(error => {
                        this.logger.error('Error loading vital signs', error);
                        return of({ results: [] } as PageableModel<VitalSign>);
                    }),
                ),
            ),
            concatLatestFrom(() => this.store$.select(ChangeLogSelectors.getChangesMap)),
            map(([data, changesMap]) => {
                const changes = changesMap['VitalSign'] || [];
                if (changes.length) {
                    const { results } = data;
                    data.results = applyInstancesChanges(results, changes) as any;
                }
                return VitalSignsAction.loadVitalSignsSuccess(data);
            }),
            catchError(error => {
                return of(VitalSignsAction.loadVitalSignsFailed({ error }));
            }),
        ),
    );

    /**
     * Load Vital signs limited for date range if date range is valid, otherwise reload all
     */
    onDateRangeChanged$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VitalSignsAction.setDateRange),
                withCurrentEncounterId(),
                map(([{ dateRange }, encounter_id]) => {
                    const params: ParamsModel = {
                        encounter_id,
                    };
                    if (isValidDateRange(dateRange)) {
                        params.start_date = dateRange.start.toISOString();
                        params.end_date = addMinutes(dateRange.end, 1).toISOString();
                        this.store$.dispatch(VitalSignsAction.loadVitalSigns({ params }));
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(
                    this.store$.select(selectAllVitals),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, allVitalsData, changesMap]) => {
                    const changes = changesMap['VitalSign'] || [];

                    if (changes.length) {
                        const data = getChangesData(allVitalsData, changes);
                        this.store$.dispatch(VitalSignsAction.upsertVitalSigns({ data }));
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.store$.select(selectAllVitals),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, data, changesMap]) => {
                    const changes = changesMap['VitalSign'] || [];

                    if (changes.length) {
                        const { toRemove, toUpdate } = getPersistedChangesData(data, changes);

                        this.store$.dispatch(
                            VitalSignsAction.addChanges({
                                toUpdateEntities: toUpdate || [],
                                toRemoveIds: toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    // FIXME;
    /* onChangeFailed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeLogAction.persistChangesAction.failedAction),
            withLatestFrom(
                this.store$.select(selectAllVitalsMap),
                this.store$.select(ChangeLogSelectors.getChangesMap),
            ),
            map(([, vitalsMap, changesMap]) => {
                return VitalSignsAction.removeVitalSigns({ ids: [] });
            }),
        ),
    ); */

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param store$
     * @param vitalSignsApi VitalSignsApi
     */
    constructor(
        private actions$: Actions,
        private store$: Store<{ changelogData: ChangeLogState }>,
        private vitalSignsApi: VitalSignsApi,
    ) {}
}
