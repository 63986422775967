import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { UtilsModule } from '@mona/shared/utils';
import { DrawerFooterComponent } from './drawer-footer.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [DrawerFooterComponent],
    imports: [UtilsModule, MatDividerModule],
    exports: [DrawerFooterComponent],
})
export class DrawerFooterModule {}
