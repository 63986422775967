import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseMedication } from '@mona/models';
import { StandardMedicationstate } from '../entities';
import { StandardMedicationsApi } from '../infrastructure';
import {
    selectSelectedStandardMedicationById,
    selectStandardMedications,
    selectStandardMedicationsError,
    selectStandardMedicationsIsLoading,
    selectStandardMedicationsMap,
    StandardMedicationsActions,
} from '../state';

/**
 * Data access standard medications facade
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessStandardMedicationsFacade {
    /** Select standard medications is loading */
    isLoading$: Observable<boolean> = this.store.select(selectStandardMedicationsIsLoading);
    /** Select standard medications error */
    error$: Observable<boolean> = this.store.select(selectStandardMedicationsError);
    /**
     * Constructor
     *
     * @param store store
     * @param standardMedicationsApi
     */
    constructor(
        private store: Store<StandardMedicationstate>,
        private standardMedicationsApi: StandardMedicationsApi,
    ) {}

    /**
     * Get standard medication sets map
     */
    getStandardMedicationsMap(): Observable<EntityMap<BaseMedication>> {
        return this.store.select(selectStandardMedicationsMap);
    }

    /**
     * Get standard medication sets
     */
    getStandardMedications(): Observable<BaseMedication[]> {
        return this.store.select(selectStandardMedications);
    }

    /**
     * Get standard medication sets\
     *
     * @param id
     */
    getStandardMedication(id: string): Observable<BaseMedication> {
        return this.store.select(selectSelectedStandardMedicationById(id));
    }

    /**
     * Load standard medication sets
     */
    loadStandardMedications(): void {
        this.store.dispatch(StandardMedicationsActions.loadStandardMedications());
    }

    /**
     * Load standard medication set by medication code
     *
     * @param code
     * @todo currently - directly from API, consider refactoring to effect?
     */
    searchStandardMedicationsByMedicationCode(code: string): Observable<BaseMedication[]> {
        return this.standardMedicationsApi.getStandardMedicationsByMedication(code, true);
    }

    /**
     * Clear standard medication sets
     */
    clearStandardMedications(): void {
        this.store.dispatch(StandardMedicationsActions.clearStandardMedications());
    }

    /**
     * Create standard medication set
     *
     * @param payload
     */
    createStandardMedication(payload: Omit<BaseMedication, 'id' | 'medication'>): void {
        this.store.dispatch(
            StandardMedicationsActions.createStandardMedication({
                payload,
            }),
        );
    }

    /**
     * Update standard medication set
     *
     * @param id EntityId<StandardMedications>
     * @param payload StandardMedications
     */
    updateStandardMedication(id: string, payload: BaseMedication): void {
        this.store.dispatch(
            StandardMedicationsActions.updateStandardMedication({
                update: {
                    id,
                    changes: payload,
                },
            }),
        );
    }

    /**
     * Removes standard medication set
     *
     * @param id EntityId<StandardMedications>
     */
    removeStandardMedication(id: string): void {
        this.store.dispatch(StandardMedicationsActions.removeStandardMedication({ id }));
    }
}
