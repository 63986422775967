import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Allergy, Encounter } from '@mona/models';
import { transformApiAllergies } from './transforms';

/**
 * API abstraction layer for the allergies API
 */
@Injectable({
    providedIn: 'root',
})
export class AllergiesApi {
    apiBase = '/pdms/allergies-intolerances/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get allergies
     *
     * @param encounterId EntityId<Encounter>
     */
    getAllergies(encounterId?: EntityId<Encounter>): Observable<Allergy[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (encounterId) {
            params.encounter_id = `${encounterId}`;
        }

        return this.http
            .get<ApiModels.AllergyIntolerance[]>(this.apiBase, {
                params,
            })
            .pipe(map(allergies => transformApiAllergies(allergies)));
    }
}
