import { MatCalendarCell } from '@angular/material/datepicker';

export const CALENDAR_CELL_WIDTH = 64;
export const CALENDAR_CELL_CLASS = 'ui-calendar-slider__day';
export const CALENDAR_CELL_CLASS_ACTIVE = 'ui-calendar-slider__day--active';

/**
 * UiCalendarCell extends {@link MatCalendarCell}
 */
export class UiCalendarCell extends MatCalendarCell<Date> {
    private _active: boolean;
    /** Flag for active cell */
    get active(): boolean {
        return this._active;
    }
    /** Toggle classes for active cell */
    set active(value: boolean) {
        this._active = value;
        this.cssClasses = value ? [CALENDAR_CELL_CLASS, CALENDAR_CELL_CLASS_ACTIVE] : [CALENDAR_CELL_CLASS];
    }

    /**
     * Creates an instance of UiCalendarCell
     *
     * @param date
     * @param enabled
     */
    static build(date: Date, enabled = true): UiCalendarCell {
        return new UiCalendarCell(
            date.getDate(),
            date.getDate().toString(),
            date.getDate().toString(),
            enabled,
            CALENDAR_CELL_CLASS,
            date.getDate(),
            date,
        );
    }
}

/**
 * UiCalendarCellBase
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UiCalendarCellBase extends Pick<UiCalendarCell, keyof UiCalendarCell> {}
