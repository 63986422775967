/* eslint-disable @typescript-eslint/no-namespace, jsdoc/check-param-names */
import { AbstractControl, FormArray, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { add, Duration, format, isAfter, isBefore, toDate as _toDate, addMinutes } from 'date-fns';
import { getDaylightSavingSwitchDates } from '@mona/shared/date';

function toDate(date: Date | number | string): Date {
    if (!date) {
        return;
    }

    if (typeof date === 'string') {
        return new Date(date);
    }

    return _toDate(date as Date | number);
}

/**
 * Custom Validators for form arrays.
 */
export namespace ArrayValidators {
    /**
     * Validates a min length for a form array
     *
     * @param min Min amount of entries
     */
    export function minLength(min: number): ValidatorFn | any {
        return (control: FormArray) => {
            if (!(control instanceof FormArray)) {
                throw new Error('Must be a FormArray');
            }

            return control.controls.length < min ? { arrayMinLength: { min } } : null;
        };
    }
}

/**
 * Custom Validators
 */
export namespace CustomValidators {
    /**
     * Validates a decimal by pattern
     */
    export function decimal(): ValidatorFn | any {
        return Validators.pattern(/^-?\d*(\.?\d+)?$/);
    }

    /**
     * Validates positive number
     */
    export function positiveDecimal(): ValidatorFn | any {
        return Validators.pattern(/^\d*(\.?\d+)?$/);
    }

    /**
     * Validates positive number
     *
     * @param limit
     */
    export function maxNumbersAfterDot(limit = 2): ValidatorFn | any {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value || control.errors?.required) {
                return null;
            }
            const value = control.value?.toString();
            const numbersAfterDot = value?.match(/^\d+\.([^.]*)$/) && value.match(/^\d+\.([^.]*)$/)[1].length;
            return numbersAfterDot <= limit
                ? null
                : {
                      maxAfterDot: {
                          limit,
                      },
                  };
        };
    }

    /**
     * Validates a integer by pattern
     */
    export function integer(): ValidatorFn | any {
        return Validators.pattern(/^[-+]?\d+$/);
    }

    /**
     * Validates a symbols of length by pattern
     *
     * @param length
     */
    export function symbolsOfLength(length: number): ValidatorFn | any {
        return Validators.pattern(new RegExp(`^[a-zA-Z]{${length}}$`));
    }

    /**
     * Validates a url by pattern
     */
    export function url(): ValidatorFn | any {
        return Validators.pattern(
            // eslint-disable-next-line no-useless-escape
            /^((http|https):\/\/)(([\da-z\.-]+\.[a-z\.]{2,6}|[\d\.]+)|localhost)([\/:?=&#]{1}[\da-z\.-]+)*[\/\?]?$/,
        );
    }

    /**
     * Validates a min length for a form array
     *
     * @param min Min amount of entries
     */
    export function minArrayLength(min: number): ValidatorFn | any {
        return (control: FormArray) => {
            if (!(control instanceof FormArray)) {
                throw new Error('Must be a FormArray');
            }

            return control.controls.length < min ? { arrayMinLength: { min } } : null;
        };
    }

    /**
     * Validates control value is greater than given date
     *
     * @param dateToCompare
     * @param duration
     */
    export function minDate(dateToCompare: Date | string, duration: Duration = { hours: 0 }): ValidatorFn | any {
        return (control: AbstractControl): ValidationErrors | null => {
            const date = add(toDate(dateToCompare), duration);
            const ctrlDate = toDate(control.value);

            if (control.errors?.required) {
                return null;
            }
            if (!ctrlDate || (date && ctrlDate && isAfter(ctrlDate, date))) {
                return null;
            }
            return {
                minDate: {
                    min: format(date, 'dd/MM/yyyy HH:mm'),
                    actual: control.value,
                },
            };
        };
    }

    /**
     * Validates if selected date and hour is on edge of dst shift
     */
    export function isDstHourEdge(): ValidatorFn | any {
        return (control: AbstractControl): ValidationErrors | null => {
            const selectedDate = new Date(control.value);

            if (selectedDate && selectedDate?.getTime()) {
                const { end: endOfSummerTimezone } = getDaylightSavingSwitchDates(selectedDate?.getFullYear());
                const isSummerToWinterChange =
                    endOfSummerTimezone.getMonth() === selectedDate?.getMonth() &&
                    endOfSummerTimezone.getDate() === selectedDate?.getDate() &&
                    endOfSummerTimezone.getHours() === selectedDate?.getHours();

                return isSummerToWinterChange ? { dstEdge: true } : null;
            }

            return null;
        };
    }

    /**
     * Validates control value is lower than given date
     *
     * @param dateToCompare
     * @param duration
     */
    export function maxDate(dateToCompare: Date | string, duration: Duration = { hours: 0 }): ValidatorFn | any {
        return (control: AbstractControl): ValidationErrors | null => {
            const date = add(toDate(dateToCompare), duration);
            const ctrlDate = toDate(control.value);

            if (control.errors?.required) {
                return null;
            }
            if (!ctrlDate || (date && ctrlDate && isBefore(ctrlDate, date))) {
                return null;
            }
            return {
                maxDate: {
                    max: format(date, 'dd/MM/yyyy HH:mm'),
                    actual: control.value,
                },
            };
        };
    }

    /**
     * Validates control value is lower than currentDate
     */
    export function maxDateNotMoreThanNow(): ValidatorFn | any {
        return (control: AbstractControl): ValidationErrors | null => {
            const date = addMinutes(new Date(), 1);
            const ctrlDate = toDate(control.value);

            if (control.errors?.required) {
                return null;
            }
            if (!ctrlDate || (date && ctrlDate && isBefore(ctrlDate, date))) {
                return null;
            }
            return {
                maxDate: {
                    max: format(date, 'dd/MM/yyyy HH:mm'),
                    actual: control.value,
                },
            };
        };
    }

    /**
     * Validates control value is correct time input
     *
     * @param control
     */
    export function checkCorrectTime(control: AbstractControl): ValidatorFn | any {
        if (control.value) {
            const [hh, mm] = control.value.split(':');

            if (!hh?.match(/(0?[0-9]|1[0-2])/) || !mm?.match(/[0-5][0-9]/)) {
                return { mask: true };
            }
        }
        return null;
    }

    /**
     * Validates a ws url by pattern
     */
    export function wsUrl(): ValidatorFn | any {
        return Validators.pattern(
            // eslint-disable-next-line no-useless-escape
            /^((ws|wss):\/\/)(([\da-z\.-]+\.[a-z\.]{2,6}|[\d\.]+)|localhost)([\/:?=&#]{1}[\da-z\.-]+)*[\/\?]?$/,
        );
    }

    /**
     * Validates an IP by pattern
     *
     * @see https://stackoverflow.com/a/25969006/4115894
     */
    export function ip(): ValidatorFn {
        return Validators.pattern(
            /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        );
    }

    /**
     * Angular Material Autocomplete Component Force Selection
     *
     * @param control
     * @tutorial https://onthecode.co.uk/force-selection-angular-material-autocomplete/
     */
    export function requireMatch(control: AbstractControl) {
        if (control.errors?.required) {
            return null;
        }
        const selection: any = control.value;
        if (typeof selection === 'string') {
            return { requireMatch: true };
        }
        return null;
    }
}
