<img alt="illustration" src="assets/images/discard.svg" class="app-update-dialog__illustration" />
<mat-dialog-content *ngIf="data.description" class="app-update-dialog__content">
    <div>
        <h2 mat-dialog-title class="app-update-dialog__title">{{ data.title | translate }}</h2>
        <p class="app-update-dialog__description">
            {{ data.description }}
            <ng-container *ngIf="data.additionalDescription">
                <br />
                {{ data.additionalDescription | translate }}
                <br />
                <br />
            </ng-container>
        </p>
        <br />
        <ng-container [ngSwitch]="updateStatus$ | async">
            <ng-container *ngSwitchCase="updateStates.SEARCHING">
                <br />
                <mat-spinner [color]="'primary'" [diameter]="24"></mat-spinner>
                <br />
                <span>{{ 'oobe.steps.update.searching' | translate }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="updateStates.DOWNLOADING">
                <br />
                <mat-spinner [color]="'primary'" [diameter]="24"></mat-spinner>
                <br />
                <span>{{ 'oobe.steps.update.downloading' | translate }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="updateStates.NO_UPDATE">
                <p>
                    {{ 'oobe.steps.update.incompatibleVersionHint' | translate }}
                    <br />
                    <br />
                    <br />
                    <br />
                    <button (click)="searchForUpdates()" [color]="'primary'" mat-raised-button>
                        {{ 'oobe.steps.update.tryAgain' | translate }}
                    </button>
                </p>
            </ng-container>

            <ng-container *ngSwitchCase="updateStates.READY_TO_INSTALL" class="step-update__pending">
                <br />
                <mat-spinner [color]="'primary'" [diameter]="24"></mat-spinner>
                <br />
                <span>{{ 'oobe.steps.update.updateInProgress' | translate }}</span>
            </ng-container>
        </ng-container>
    </div>
</mat-dialog-content>
