<div #wrapper class="medication-autocomplete__wrapper">
    <input
        #autocompleteInput
        type="text"
        class="medication-autocomplete__input"
        cdkFocusInitial
        matInput
        [matAutocomplete]="auto"
        [matAutocompleteDisabled]="disabled"
        matAutocompletePosition="auto"
        [formControl]="inputControl"
        [placeholder]="placeholder | translate"
        [required]="required"
        (focusin)="onFocusIn($event)"
        (focusout)="onFocusOut($event)"
    />
    <mat-spinner
        class="medication-autocomplete__icon medication-autocomplete__icon-spinner"
        diameter="24"
        *ngIf="searchInProgress | async"
    ></mat-spinner>
    <!-- (click)="onAutocompleteReset()" -->
    <button
        *ngIf="!hideResetBtn"
        [class.d-none]="(searchInProgress | async) || readonly || !parentControl?.value"
        class="medication-autocomplete__icon medication-autocomplete__icon-close"
        mat-icon-button
        type="button"
        id="reset-popover-id"
        [matMenuTriggerFor]="menu"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
<!-- ----------------------------------------------------------------------- -->
<!-- Autocomplete suggestions panel template -->
<!-- ----------------------------------------------------------------------- -->
<mat-autocomplete
    id="medicationAutocomplete"
    #auto="matAutocomplete"
    autoActiveFirstOption="true"
    [displayWith]="displayFn"
    (optionSelected)="onAutoCompleteSelect($event)"
    class="medication-autocomplete__panel scrollbar-visible"
    [ngClass]="{ 'medication-autocomplete__panel--empty': noResults }"
>
    <mat-option
        #matOption
        *ngFor="let option of medicationsOptions | async; trackBy: trackByFn"
        [value]="option"
        class="medication-autocomplete__option"
    >
        {{ option.displayName }}
        <mat-hint
            *ngIf="option.code && showOptionsInfo"
            class="medication-autocomplete__option-hint medication-autocomplete__option-hint--right"
        >
            {{ option.code }}
        </mat-hint>
    </mat-option>

    <mat-option
        [id]="'no-results'"
        *ngIf="shouldShowAddOption(searchInProgress | async, inputControl.value)"
        [value]="inputControl.value"
        class="medication-autocomplete__option medication-autocomplete__option--empty"
    >
        {{ 'apps.settings.medications.addDialog.customOption' | translate }}:
        <em>{{ inputControl.value }}</em>
        <br />
        <mat-hint class="medication-autocomplete__option-hint">
            {{ 'apps.settings.medications.addDialog.emptyDescription' | translate }}
        </mat-hint>
    </mat-option>
</mat-autocomplete>

<mat-menu #menu="matMenu" panelClass="medication-autocomplete__confirm-overlay">
    <mat-card class="medication-autocomplete__confirm-card mat-elevation-z0" (click)="$event.preventDefault()">
        <mat-card-content>
            {{ 'apps.settings.medications.addDialog.delete.message' | translate }}
        </mat-card-content>
        <mat-card-actions class="medication-autocomplete__confirm-card-actions">
            <button mat-menu-item [disableRipple]="true" class="medication-autocomplete__confirm-btn">
                {{ 'apps.settings.medications.addDialog.delete.cancel' | translate }}
            </button>
            <button
                mat-menu-item
                [disableRipple]="true"
                color="primary"
                class="medication-autocomplete__confirm-btn medication-autocomplete__confirm-btn--primary mat-button mat-raised-button mat-primary"
                (click)="onAutocompleteReset()"
            >
                {{ 'apps.settings.medications.addDialog.delete.confirm' | translate }}
            </button>
        </mat-card-actions>
    </mat-card>
</mat-menu>
