import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Merge, SetOptional } from 'type-fest';
import { ControlsOf, BaseProcedurePrescription, BaseProcedureAdministration } from '@mona/models';
import { NotGivenReason, NotGivenReasonForm } from '../../../not-given-reason';

/**
 * ProcedureFormType Enum
 */
export enum ProcedureFormTypeEnum {
    ProcedurePrescription = 'ProcedurePrescription',
    ProcedureAdministration = 'ProcedureAdministration',
    SetProcedure = 'SetProcedure',
}

/* BASE PROCEDURE PRESCRIPTION FORM */
/**
 * Base procedure prescription form controls interface
 */
export type BaseProcedurePrescriptionControls = Merge<
    ControlsOf<SetOptional<BaseProcedurePrescription, 'id'>>,
    { frequencyTimes: FormControl<string[]> }
>;

/**
 * BaseProcedurePrescription Form type
 */
export type BaseProcedurePrescriptionForm = FormGroup<BaseProcedurePrescriptionControls>;

/** Build BaseProcedurePrescription */
export const buildBaseProcedurePrescriptionForm = (): BaseProcedurePrescriptionForm => {
    return new FormGroup<BaseProcedurePrescriptionControls>({
        category: new FormControl<string>('', Validators.required),
        description: new FormControl<string>('', [Validators.required, Validators.maxLength(256)]),
        instructions: new FormControl<string>('', [Validators.required, Validators.maxLength(256)]),
        frequency: new FormControl<string>('', Validators.required),
        frequencyTimes: new FormControl<string[]>([]),
    });
};

/* BASE PROCEDURE FORM */
/**
 * Base procedure form controls interface
 */
export type BaseProcedureAdministrationControls = ControlsOf<
    Merge<
        Pick<
            BaseProcedureAdministration,
            'category' | 'description' | 'durationMinutes' | 'note' | 'date' | 'prescriptionId' | 'id'
        >,
        Pick<BaseProcedurePrescription, 'instructions'>
    >
>;

/**
 * BaseProcedure Form type
 */
export type BaseProcedureAdministrationForm = FormGroup<BaseProcedureAdministrationControls>;

/** Build medication form */
export const buildBaseProcedureAdministrationForm = (): BaseProcedureAdministrationForm => {
    return new FormGroup<BaseProcedureAdministrationControls>({
        id: new FormControl<string>(''),
        category: new FormControl<string>('', Validators.required),
        description: new FormControl<string>('', [Validators.required, Validators.maxLength(256)]),
        instructions: new FormControl<string>('', [Validators.required, Validators.maxLength(256)]),
        note: new FormControl<string>(''),
        durationMinutes: new FormControl<number>('' as unknown as number), // hack to allow having N/A as default value
        date: new FormControl<Date>(null),
        prescriptionId: new FormControl<string>('', Validators.required),
    });
};
/* PROCEDURE FORM */
/**
 *  Procedure form controls
 */
export type ProcedureFormControls = Merge<
    ControlsOf<ProcedureFormPayload>,
    {
        procedurePrescription: BaseProcedurePrescriptionForm;
        procedureAdministration: BaseProcedureAdministrationForm;
        startDate: FormControl<Date>;
        endDate: FormControl<Date>;
        notAdministeredReason?: NotGivenReasonForm;
    }
>;

/**
 * Procedure form payload
 */
export interface ProcedureFormPayload {
    /** procedure prescription */
    procedurePrescription?: BaseProcedurePrescription;
    /** procedure administration */
    procedureAdministration?: BaseProcedureAdministration;
    /** start date */
    startDate?: Date;
    /** end date */
    endDate?: Date;
    /** not given reason */
    notAdministeredReason?: NotGivenReason;
}

/**
 *  medication form interface
 */
export type ProcedureForm = FormGroup<ProcedureFormControls>;

/** Build medication form */
export const buildProcedureForm = (): ProcedureForm => {
    return new FormGroup<ProcedureFormControls>({
        procedurePrescription: buildBaseProcedurePrescriptionForm(),
        procedureAdministration: buildBaseProcedureAdministrationForm(),
        startDate: new FormControl<Date>(null),
        endDate: new FormControl<Date>(null),
    });
};
