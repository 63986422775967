import { ApiModels } from '@mona/api';
import { SearchDiagnosisResult } from '@mona/models';

/**
 * Transforms API DiagnosisSearchResult
 * @param apiDiagnosisDB DiagnosisDB
 */
export const transformApiDiagnosisSearchResult = (apiDiagnosisDB: ApiModels.DiagnosisDB): SearchDiagnosisResult => {
    return {
        code: apiDiagnosisDB.code,
        displayName: apiDiagnosisDB.display_name,
    };
};

/**
 * Transforms API DiagnosisSearchResult
 * @param apiDiagnosisDB DiagnosisDB
 */
export const transformApiDiagnosisSearchResults = (
    apiDiagnosisDB: ApiModels.DiagnosisDB[],
): SearchDiagnosisResult[] => {
    return apiDiagnosisDB.map(apiDiagnosis => transformApiDiagnosisSearchResult(apiDiagnosis));
};
