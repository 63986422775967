import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { UiModule } from '@mona/ui';
import { SuggestionButtonComponent } from './suggestion-button/suggestion-button.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [SuggestionButtonComponent, SuggestionsComponent],
    exports: [SuggestionsComponent],
    imports: [UiModule, MatRippleModule],
})
export class SuggestionsModule {}
