import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { isSameDay } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BasicCareProcedure, ChangeLogEntry, ChangeLogOperation, EntryControl } from '@mona/models';
import { applyEntityMapChanges, applyInstancesChanges, ChangeLogService } from '@mona/pdms/data-access-changelog';
import { getEncounterViewSelectedDate } from '@mona/pdms/data-access-combined';
import { EntriesInterval } from '@mona/shared/date';
import { groupBy, withLoading } from '@mona/shared/utils';
import { AsyncActionState, FormActions } from '@mona/store';
import {
    BasicCareProceduresMap,
    DateShiftEntryControls,
    encounterDataFeatureKey,
    EncounterDataState,
    EntryControlForm,
} from '../entities';
import { CareReportsAction } from '../state';
import { EncounterService } from './encounter.service';

/**
 * Encounter care reports service
 */
@Injectable({ providedIn: 'root' })
export class CareReportsService {
    private _isLoading$ = new BehaviorSubject<boolean>(true);
    /** Loading state */
    isLoading$: Observable<boolean> = this._isLoading$.asObservable();
    /**
     * Holds entry controls stage changes
     */
    private entryControlsChanges$: Observable<ChangeLogEntry<EntryControl>[]> =
        this.changeLogService.getModelChanges('EntryControl');

    /**
     * Currently selected date
     */
    selectedDate$: Observable<Date> = getEncounterViewSelectedDate().pipe(withLoading(this));

    /**
     * Constructor
     *
     * @param actionsObserver$
     * @param store Store<EncounterFeatureState>
     * @param changeLogService ChangeLogService
     * @param encounterService EncounterService
     */
    constructor(
        private actionsObserver$: ActionsSubject,
        private store: Store<EncounterDataState>,
        private changeLogService: ChangeLogService,
        private encounterService: EncounterService,
    ) {}

    /**
     * Get UI start date
     *
     * @description
     * Encounter start date
     */
    getStartDate(): Observable<Date> {
        return this.encounterService.startDate$;
    }

    /**
     * Get date range from start till now
     *
     * @description
     * Encounter start date - Today
     */
    getDateRange(): Observable<{ start: Date; end: Date }> {
        return this.encounterService.encounterViewDateRange$;
    }

    /**
     * Get last end day date
     */
    getEndDate(): Observable<Date> {
        return this.store.select((state: EncounterDataState) => state[encounterDataFeatureKey].careReports.endDate);
    }

    /**
     * Get selected interval
     */
    getInterval(): Observable<EntriesInterval> {
        return this.store.select((state: EncounterDataState) => state[encounterDataFeatureKey].careReports.interval);
    }

    /**
     * Basic care procedures loading action
     */
    getLoadBasicCareProceduresAction(): Observable<AsyncActionState<BasicCareProcedure[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].careReports.basicCareProcedures.loadAction,
        );
    }

    /**
     * Entry controls loading action
     */
    getLoadEntryControlsAction(): Observable<AsyncActionState<EntryControl[]>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].careReports.entryControls.loadAction,
        );
    }

    /**
     * Entry controls persisted action
     */
    getPersistedEntryControls(): Observable<{ date: Date | null }> {
        return this.actionsObserver$.pipe(
            ofType(CareReportsAction.setPersistedEntryControls),
            // map(action => action.date),
        );
    }

    /**
     * Basic care procedures grouped map
     */
    getGroupedBasicCareProcedures(): Observable<BasicCareProceduresMap> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].careReports.basicCareProceduresMap,
        );
    }

    /**
     * Basic care procedures
     */
    getBasicCareProceduresAll(): Observable<BasicCareProcedure[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].careReports.basicCareProcedures.rawEntities,
        );
    }

    /**
     * Basic care procedures filtered by selected date
     */
    getBasicCareProceduresFiltered(): Observable<BasicCareProcedure[]> {
        return combineLatest([this.getBasicCareProceduresAll(), this.selectedDate$]).pipe(
            map(([basicCareProcedures, selectedDate]) =>
                basicCareProcedures.filter(bcp => isSameDay(bcp.date, selectedDate)),
            ),
        );
    }

    /**
     * Basic care procedures grouped map with stage changes
     */
    getGroupedBasicCareProceduresWithChanges(): Observable<BasicCareProceduresMap> {
        return combineLatest([
            this.getBasicCareProceduresFiltered(),
            this.changeLogService.getModelChanges('BasicCare'),
        ]).pipe(
            map(([basicCareProcedures, changes]) => {
                const data: BasicCareProcedure[] = applyInstancesChanges(basicCareProcedures, changes) as any;
                return groupBy(data, bcp => bcp.code, null, null) as BasicCareProceduresMap;
            }),
        );
    }

    /**
     * Entry controls as array
     */
    getEntryControls(): Observable<EntryControl[]> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].careReports.entryControls.rawEntities,
        );
    }

    /**
     * Entry controls as amp
     */
    getEntryControlsMap(): Observable<EntityMap<EntryControl>> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].careReports.entryControls.entities,
        );
    }

    /**
     * Entry controls form state
     */
    getEntryControlFormState(): Observable<EntryControlForm> {
        return this.store.select(
            (state: EncounterDataState) => state[encounterDataFeatureKey].careReports.entryControlForm,
        );
    }

    /**
     * Entry controls grouped map with stage changes
     */
    getEntryControlsWithChanges(): Observable<EntityMap<EntryControl>> {
        return combineLatest([this.getEntryControlsMap(), this.entryControlsChanges$]).pipe(
            map(([entityMap, changes]: [EntityMap<EntryControl>, ChangeLogEntry<EntryControl>[]]) => {
                const entryControls = applyEntityMapChanges<EntryControl>(entityMap, changes);

                return entryControls;
            }),
            // NOTE: Do not share replay, causes problems with `take(1)`
        );
    }

    /**
     * Resolves map of date & shift relation to entry controls id
     */
    getDateShiftEntryControls(): Observable<DateShiftEntryControls> {
        return combineLatest([
            this.store.select(
                (state: EncounterDataState) => state[encounterDataFeatureKey].careReports.dateShiftEntryControls,
            ),
            this.entryControlsChanges$,
        ]).pipe(
            map(([dateShiftEntryControls, changes]: [DateShiftEntryControls, ChangeLogEntry<EntryControl>[]]) => {
                const extendedDateShiftEntryControls = {
                    ...dateShiftEntryControls,
                };

                // Extend with changes
                const createChanges = changes?.filter(change => change.operation === ChangeLogOperation.Create);
                if (createChanges?.length) {
                    for (const change of createChanges) {
                        extendedDateShiftEntryControls[`${change.payload.date.toDateString()}${change.payload.shift}`] =
                            change.modelId;
                    }
                }

                return extendedDateShiftEntryControls;
            }),
        );
    }

    /**
     * Get has unconfirmed entry controls
     */
    getHasUnconfirmedEntryControls(): Observable<boolean> {
        return this.getEntryControlsWithChanges().pipe(
            map(
                entryControls =>
                    !!Object.keys(entryControls).find(entryControlId => {
                        const entryControl = entryControls[entryControlId];
                        return this.handleIfEntryControlShouldBeConfirmed(entryControl);
                    }),
            ),
        );
    }

    /**
     * Resolves days that have unconfirmed entry controls
     */
    getDaysWithUnconfirmedEntryControls(): Observable<Set<string>> {
        return this.getEntryControlsWithChanges().pipe(
            map(entryControls => {
                const resultSet = new Set<string>();

                for (const entryControlId of Object.keys(entryControls || {})) {
                    const entryControl = entryControls[entryControlId];
                    if (this.handleIfEntryControlShouldBeConfirmed(entryControl) && 'date' in entryControl) {
                        // Add beginning of the date
                        resultSet.add(new Date(entryControl.date.toDateString()).toISOString());
                    }
                }

                return resultSet;
            }),
        );
    }

    /**
     * Resolves days that have entry controls which have stage changes
     */
    getDaysWithStageChanges(): Observable<Set<string>> {
        return this.getEntryControlsWithChanges().pipe(
            switchMap((entryControls: EntityMap<EntryControl>) =>
                this.entryControlsChanges$.pipe(
                    take(1),
                    map(changes => {
                        const resultSet = new Set<string>();

                        if (!changes?.length) {
                            return resultSet;
                        }

                        for (const change of changes) {
                            const relatedEntryControl = entryControls[change.modelId];
                            // Add beginning of the date
                            if ('date' in relatedEntryControl) {
                                resultSet.add(new Date(relatedEntryControl.date.toDateString()).toISOString());
                            }
                        }

                        return resultSet;
                    }),
                ),
            ),
        );
    }

    //#endregion Selectors

    /**
     * Sets interval
     *
     * @param interval EntriesInterval
     */
    setInterval(interval: EntriesInterval): void {
        this.store.dispatch(CareReportsAction.setIntervalAction({ interval }));
    }

    /**
     * Set end date
     *
     * @param date Date
     */
    setEndDate(date: Date): void {
        this.store.dispatch(CareReportsAction.setEndDateAction({ date }));
    }

    /**
     * Sets loading spinner value
     *
     * @param value
     */
    setLoading(value: boolean) {
        this._isLoading$.next(value);
    }

    /**
     * Load basic care procedures
     *
     */
    loadBasicCareProcedures(): void {
        this.store.dispatch(CareReportsAction.loadBasicCareProceduresAction.action({}));
    }

    /**
     * Clear basic care procedures
     */
    clearBasicCareProcedures(): void {
        this.store.dispatch(CareReportsAction.loadBasicCareProceduresAction.clearAction());
    }

    /**
     * Load entry controls
     *
     */
    loadEntryControls(): void {
        this.store.dispatch(CareReportsAction.loadEntryControlsAction.action({}));
    }

    /**
     * Clear entry controls
     */
    clearEntryControls(): void {
        this.store.dispatch(CareReportsAction.loadEntryControlsAction.clearAction());
    }

    /**
     * Clear entry control form state
     */
    clearEntryControlFormState(): void {
        this.store.dispatch(FormActions.formResetAction({ path: 'currentEncounterData.careReports.entryControlForm' }));
    }

    //#endregion Actions

    /**
     * Helper function to handle if entry control should be confirmed
     *
     * @param entryControl EntryControl
     */
    private handleIfEntryControlShouldBeConfirmed(entryControl: EntryControl): boolean {
        // EntryControl should be confirmed only if:
        return (
            !entryControl.isApproved ||
            !!entryControl.careChecks?.some(item => !item.isApproved) ||
            !!entryControl.glasgowComaScale?.some(item => !item.isApproved)
        );
    }
}
