// tslint:disable: directive-selector completed-docs
import { Directive, forwardRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { noop } from '@mona/shared/utils';

/**
 * `uiValidator` allows set validators for form control on the fly
 */
@Directive({
    selector: '[uiValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => UiValidatorDirective),
            multi: true,
        },
    ],
})
export class UiValidatorDirective implements Validator, OnChanges, OnDestroy {
    private onChange = noop;

    @Input()
    uiValidator: ValidatorFn | ValidatorFn[] = Validators.nullValidator;

    /**
     * @description
     * Method that performs synchronous validation against the provided control.
     * @param control The control to validate against.
     * @returns A map of validation errors if validation fails,
     * otherwise null.
     */
    validate(control: AbstractControl): ValidationErrors | null {
        if (Array.isArray(this.uiValidator)) {
            const errors = this.uiValidator.map(validator => validator(control)).filter(Boolean);
            return errors?.[0] || null;
        }
        return this.uiValidator(control);
    }

    /**
     * @description
     * Registers a callback function to call when the validator inputs change.
     * @param fn The callback function
     */
    registerOnValidatorChange(fn: () => undefined) {
        this.onChange = fn;
    }

    /**
     * INFO: add comment
     */
    ngOnChanges() {
        this.onChange();
    }

    /**
     * INFO: add comment
     */
    ngOnDestroy() {
        this.uiValidator = Validators.nullValidator;
        this.onChange();
    }
}
