// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for Medication Group Model Structure.
 * @see #/components/schemas/MedicationGroup - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface MedicationGroup {
    /**
     * The Medication Group Mona unique code
     * @dataFormat uuid
     */
    readonly code: string;
    /**
     * The name of the Medication Group.
     *
     */
    display_name: string;
    /**
     * Whether the medication group is user defined.
     *
     */
    is_user_defined?: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for MedicationGroup
 */
export namespace MedicationGroup {
    /**
     * MedicationGroup Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The Medication Group Mona unique code
         * @dataFormat uuid
         */
        readonly code: string;
        /**
         * The name of the Medication Group.
         * @type string (`dataFormat` is missing)
         */
        displayName: string;
        /**
         * Whether the medication group is user defined.
         * @type boolean (`dataFormat` is missing)
         */
        isUserDefined?: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * MedicationGroup DTO
     * Transforms MedicationGroup model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend MedicationGroup model */
        static toModel(obj: MedicationGroup): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend MedicationGroup model */
        static toApi(obj: Model): MedicationGroup {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as MedicationGroup;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
