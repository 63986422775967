import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { Encounter, PatientOutput } from '@mona/models';
import { transformApiOutputs } from './transforms';

/**
 * API abstraction layer for the outputs API
 */
@Injectable({
    providedIn: 'root',
})
export class OutputsApi {
    apiBase = '/pdms/outputs/';

    /**
     * Constructor
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get outputs
     * @param encounterId
     */
    getOutputs(encounterId: EntityId<Encounter>): Observable<PatientOutput[]> {
        return this.http
            .get<ApiModels.Output[]>(this.apiBase, {
                params: {
                    encounter_id: `${encounterId}`,
                },
            })
            .pipe(map(outputs => transformApiOutputs(outputs)));
    }
}
