<form
    #formRef
    #f="ngForm"
    [name]="name"
    [formGroup]="form"
    novalidate
    autocomplete="off"
    [attr.data-testid]="'dynamic-form-' + name || $any(form).name"
    class="ui-dynamic-form"
    [class.ui-dynamic-form--submitted]="f.submitted"
    (ngSubmit)="onSubmitForm($event)"
    (keydown.enter)="f.ngSubmit.emit($event)"
>
    <div class="ui-dynamic-form-wrapper">
        <ng-template let-element ngFor [ngForOf]="elements">
            <div
                class="ui-dynamic-element-wrapper"
                [attr.hidden]="element.hidden"
                [style.max-width.%]="element.flex ? element.flex : 100"
                [style.flex]="'1 1 ' + (element.flex ? element.flex : 100) + '%'"
                [style.-ms-flex]="'1 1 ' + (element.flex ? element.flex : 100) + '%'"
                [style.-webkit-box-flex]="1"
            >
                <ui-dynamic-element
                    #dynamicElement
                    *ngIf="controls[element.name]"
                    [formControlName]="element.name"
                    [dynamicControl]="controls[element.name]"
                    [id]="element.name"
                    [appearance]="element.appearance"
                    [name]="element.name"
                    [label]="element.label || element.name"
                    [placeholder]="element.placeholder"
                    [hint]="element.hint"
                    [type]="element.type"
                    [required]="element.required"
                    [min]="element.min"
                    [max]="element.max"
                    [minLength]="element.minLength"
                    [maxLength]="element.maxLength"
                    [dataSource]="element.dataSource"
                    [multiple]="element.multiple"
                    [hideValidationMessage]="element.hideValidationMessage"
                    [hideRequiredMarker]="element.hideRequiredMarker"
                    [submitFormOnValueChange]="element.submitFormOnValueChange"
                    [customConfig]="element.customConfig"
                    [errorMessageTemplate]="getErrorTemplateRef(element.name)"
                ></ui-dynamic-element>
            </div>
        </ng-template>
    </div>
    <ng-content></ng-content>
    <button #formSubmitRef hidden type="submit"></button>
</form>
