import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ErrorActions } from '@mona/store';
import { WardsApi } from '../../infrastructure';
import { WardsActions } from '../actions';

/**
 * Wards effects
 */
@Injectable()
export class WardsEffects {
    loadWards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WardsActions.load),
            exhaustMap(() =>
                this.wardsApi.getWards().pipe(
                    map(wards => {
                        return WardsActions.loadSuccess({ wards });
                    }),
                    catchError(error =>
                        merge([
                            WardsActions.loadFailure({ error: error.error?.error || error.error || error }),
                            ErrorActions.handleUnexpectedServerError(error),
                        ]),
                    ),
                ),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param {Actions} actions$ - actions
     * @param {WardsApi} wardsApi - wards api
     */
    constructor(private actions$: Actions, private wardsApi: WardsApi) {}
}
