import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { ExternalResource } from '@mona/models';
import { compareDeepEqual, isNullOrUndefined, uiPure } from '@mona/shared/utils';
import { DrawerNavItem } from '../drawer-nav-item.model';
import { DrawerService } from '../drawer.service';
import { StateListener } from '../state-listener.component';

/**
 * The `<ui-drawer-body>` is a wrapper for the main content of the Drawer.
 * The typical use case is to display `<ui-drawer-nav-group>` elements, but custom elements (e.g., for spacing) are accepted as well.
 */
@Component({
    selector: 'ui-drawer-body',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    template: `
        <div
            class="ui-drawer-body-content"
            [class.ui-drawer-body-closed]="!isOpen()"
            [ngStyle]="{ 'background-image': background ? 'url(' + background + ')' : '' }"
        >
            <!-- TODO: cleanup when double-check req & disableWhen directive works as expected -->
            <!--    [matTooltip]="getTootltipMessage(item, navItem.disabled)"
                    [matTooltipDisabled]="isOpen() && !navItem.disabled"
                    matTooltipPosition="after"
                    matTooltipClass="ui-drawer-nav-item-tooltip" -->

            <!-- Main Nav group -->
            <ui-drawer-nav-group>
                <ui-drawer-nav-item
                    #navItem
                    *ngFor="let item of items; trackBy: trackByFn"
                    [title]="item.title"
                    [subtitle]="item.subtitle"
                    [link]="item.link"
                    [hidden]="item.hidden"
                    [forbiddenText]="item.forbiddenText"
                    [hideWhen]="item.hideWhen"
                    [hasMark]="(item?.hasMark$ | async) || item.hasMark"
                    [counter]="item?.counter$ | async"
                    [counterColor]="item?.counterColor"
                    [uiDisableWhen]="item.disabled"
                    [checkPermission]="item.checkPermission"
                    [subItems]="item.items"
                    [activeSubItemId]="activeSubItemId"
                    [validationError]="item.validationError$ ? (item.validationError$ | async) : false"
                    activeItemBackgroundShape="round"
                >
                    <mat-icon *ngIf="item.svgIcon" ui-icon [svgIcon]="item.svgIcon || undefined"></mat-icon>
                    <mat-icon *ngIf="!item.svgIcon" ui-icon>
                        {{ item.icon }}
                    </mat-icon>
                </ui-drawer-nav-item>
            </ui-drawer-nav-group>
            <!-- Add Nav group -->
            <ng-content select="[ui-drawer-nav-group]"></ng-content>
        </div>
        <button
            mat-mini-fab
            color="primary"
            class="ui-drawer-body-close-btn"
            (click)="drawerService.toggleDrawer()"
            aria-label="Toggle Sidenav"
            data-testid="btn-toggleDrawer"
            *ngIf="collapsible"
        >
            <mat-icon>
                {{ drawerService.isDrawerOpen() ? 'navigate_before' : 'navigate_next' }}
            </mat-icon>
        </button>
    `,
    styleUrls: ['./drawer-body.component.scss'],
    host: {
        class: 'ui-drawer-body',
    },
})
export class DrawerBodyComponent extends StateListener implements OnChanges {
    /**
     * Navbar items
     */
    private _items: DrawerNavItem[] = [];
    private _prev_items: DrawerNavItem[];
    /**
     * Navbar items
     */
    public get items(): DrawerNavItem[] {
        return this._items;
    }
    @Input()
    public set items(value: DrawerNavItem[]) {
        if (isNullOrUndefined(value)) {
            this._items = [];
            return;
        }

        if (compareDeepEqual(value, this._prev_items)) {
            return;
        }
        this._prev_items = value;
        this._items = value;
    }
    /**
     * Navbar background path
     */
    @Input() background: string;
    /**
     * Navbar Heading
     */
    @Input() title: string;
    /**
     * Navbar SubHeading
     */
    @Input() subtitle: string;
    /**
     * Can collapse drawer
     */
    @Input() collapsible: boolean;
    /**
     * Selected external resource
     */
    @Input() selectedExternalResource$: Observable<ExternalResource | null>;

    trackByFn = item => item.link;

    /** activeSubItemId */
    activeSubItemId: string;

    /**
     * Constructor
     *
     * @param drawerService
     * @param changeDetectorRef
     * @param translateService
     */
    constructor(
        public drawerService: DrawerService,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
    ) {
        super(drawerService, changeDetectorRef);
    }

    /**
     * INFO: add comment
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (
            !changes['selectedExternalResource$']?.previousValue &&
            changes['selectedExternalResource$']?.currentValue
        ) {
            this.selectedExternalResource$.subscribe(
                (selectedExternalResources: ExternalResource) =>
                    (this.activeSubItemId = selectedExternalResources?.id
                        ? String(selectedExternalResources?.id)
                        : null),
            );
        }
    }

    /**
     * Get tootltip message
     *
     * @param item
     * @param navItemDisabled
     */
    getTootltipMessage(item: DrawerNavItem, navItemDisabled: boolean) {
        return navItemDisabled && item.checkPermission
            ? `${this.translateService.instant(item.title)}: ${this.translateService.instant(
                  'warnings.areaRestricted',
              )}`
            : this.translateService.instant(item.title);
    }

    /**
     * has mark
     *
     * @param item
     */
    @uiPure
    hasMark$(item: DrawerNavItem): Observable<boolean> {
        if (item?.hasMark$) {
            return item?.hasMark$;
        }

        return of(item?.hasMark);
    }
}
