<div class="mona-auth-form mona-auth-form--credentials">
    <div class="mona-auth-form__title">
        <!-- Can be changed depends on authFlow -->
        <span>{{ title | translate }}</span>
    </div>

    <div class="mona-auth-form__template">
        <ng-content></ng-content>
    </div>

    <form [formGroup]="loginForm" #f="ngForm" (ngSubmit)="submit()" class="mona-auth-form__content">
        <div class="input-row">
            <mat-form-field appearance="fill" id="ff-username" data-testid="ff-username">
                <input
                    type="text"
                    [placeholder]="'auth.signInDialog.placeholder.username' | translate"
                    matInput
                    formControlName="username"
                />
                <mat-error>
                    <ui-validation-message [control]="loginForm.controls.username"></ui-validation-message>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="input-row">
            <mat-form-field appearance="fill" id="ff-password" data-testid="ff-password">
                <input
                    type="password"
                    [placeholder]="'auth.signInDialog.placeholder.password' | translate"
                    formControlName="password"
                    matInput
                />
                <mat-error>
                    <ui-validation-message [control]="loginForm.controls.password"></ui-validation-message>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input-row" *ngIf="loginError">
            <p class="text-center text-small text-accent" data-testid="ff-loginError">{{ errorMessage }}</p>
        </div>

        <div class="mona-auth-form__actions">
            <button
                [disabled]="loginForm.invalid || loginForm.pristine || isLoading"
                color="primary"
                mat-raised-button
                type="submit"
                data-testid="btn-submit"
            >
                {{ submitTitle | translate }}
            </button>
        </div>
    </form>
</div>
