import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { ServerUrlDialogComponent } from './server-url-dialog.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [ServerUrlDialogComponent],
    exports: [ServerUrlDialogComponent],
    imports: [UiModule, KeyboardModule],
})
export class ServerUrlDialogModule {}
