import { ApiModels } from '@mona/api';
import { MedicationPrescription } from '@mona/models';

/**
 * Transforms api medication prescription
 *
 * @param apiMedication ApiModels.MedicationPrescription
 */
export const transformApiMedicationPrescription = (
    apiMedication: ApiModels.MedicationPrescription,
): MedicationPrescription => {
    const model = ApiModels.MedicationPrescription.DTO.toModel(apiMedication);
    return model;
};

/**
 * Transform api medication prescriptions
 *
 * @param apiMedications ApiModels.MedicationPrescription[]
 */
export const transformApiMedicationPrescriptions = (
    apiMedications: ApiModels.MedicationPrescription[],
): MedicationPrescription[] => {
    return apiMedications.map(apiMedication => transformApiMedicationPrescription(apiMedication));
};
