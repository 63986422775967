import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PageableModel, VentilationParameter, VentilationProcedure } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';
import { VentilationParameterState, VentilationProcedureState, VentilationState } from '../../entities';
import { VentilationAction } from '../actions';

export const adapterVentilationParameter: EntityAdapter<VentilationParameter> =
    createEntityAdapter<VentilationParameter>({
        selectId: (item: VentilationParameter) => item.id,
    });

export const adapterVentilationProcedure: EntityAdapter<VentilationProcedure> =
    createEntityAdapter<VentilationProcedure>({
        selectId: (item: VentilationProcedure) => item.id,
    });

const ventilationParameterInit: VentilationParameterState = adapterVentilationParameter.getInitialState({
    startDate: null,
    pageableData: null,
});
const adapterVentilationProcedureInit: VentilationProcedureState = adapterVentilationProcedure.getInitialState();

export const initialState: VentilationState = {
    isLoading: false,
    interval: EntriesInterval.HOUR_1,
    parameters: ventilationParameterInit,
    procedures: adapterVentilationProcedureInit,
};

export const ventilationReducer = createReducer(
    initialState,
    on(VentilationAction.setIntervalAction, (state, action) => ({ ...state, interval: action.interval })),

    on(VentilationAction.setLoadingAction, (state, action) => ({ ...state, isLoading: action.isLoading })),

    // Ventilation Parameters

    on(VentilationAction.loadVentilationParameters, state => ({
        ...state,
        isLoading: true,
        parameters: { ...state.parameters },
    })),

    on(
        VentilationAction.loadVentilationParametersSuccess,
        VentilationAction.loadVentilationParametersFailed,
        state => ({
            ...state,
            isLoading: false,
            parameters: { ...state.parameters },
        }),
    ),

    on(VentilationAction.loadVentilationParametersSuccess, (state, action: PageableModel<VentilationParameter>) => {
        const { results, ...pageableData } = action;
        return {
            ...state,
            parameters: adapterVentilationParameter.upsertMany(results, {
                ...state.parameters,
                pageableData,
                startDate: results?.at(-1)?.date,
            }),
        };
    }),

    on(VentilationAction.upsertVentilationParameters, (state, { data }) => {
        return {
            ...state,
            isLoading: false,
            parameters: adapterVentilationParameter.upsertMany(data, { ...state.parameters }),
        };
    }),

    on(VentilationAction.addParameterChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = adapterVentilationParameter.removeMany(toRemoveIds, { ...state.parameters });

        return {
            ...state,
            parameters: adapterVentilationParameter.upsertMany(toUpdateEntities, newState),
        };
    }),

    on(VentilationAction.removeVentilationParameters, (state, { ids }) => {
        return {
            ...state,
            isLoading: false,
            parameters: adapterVentilationParameter.removeMany(ids, { ...state.parameters }),
        };
    }),

    on(VentilationAction.clearVentilationData, () => initialState),

    // Ventilation Procedures

    on(VentilationAction.loadVentilationProcedures, state => ({
        ...state,
        isLoading: true,
        procedures: { ...state.procedures },
    })),

    on(
        VentilationAction.loadVentilationProceduresSuccess,
        VentilationAction.loadVentilationProceduresFailed,
        state => ({
            ...state,
            isLoading: false,
            procedures: { ...state.procedures },
        }),
    ),

    on(VentilationAction.upsertVentilationProcedures, (state, { data }) => {
        return {
            ...state,
            isLoading: false,
            procedures: adapterVentilationProcedure.upsertMany(data, { ...state.procedures }),
        };
    }),

    on(VentilationAction.removeVentilationProcedures, (state, { ids }) => {
        return {
            ...state,
            isLoading: false,
            procedures: adapterVentilationProcedure.removeMany(ids, { ...state.procedures }),
        };
    }),

    on(VentilationAction.addProcedureChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = adapterVentilationProcedure.removeMany(toRemoveIds, { ...state.procedures });

        return {
            ...state,
            procedures: adapterVentilationProcedure.upsertMany(toUpdateEntities, newState),
        };
    }),

    on(VentilationAction.loadVentilationProceduresSuccess, (state, action) => {
        return {
            ...state,
            procedures: adapterVentilationProcedure.addMany(action.data, { ...state.procedures }),
        };
    }),
);
