import { ApiModels } from '@mona/api';
import { EntryControl } from '@mona/models';

/**
 * Transforms api basic care procedure
 *
 * @param apiEntryControl ApiModels.EntryControl
 */
export const transformApiEntryControl = (apiEntryControl: ApiModels.EntryControl): EntryControl => {
    let model = ApiModels.EntryControl.DTO.toModel(apiEntryControl);
    // TODO: The logic bellow should be made by BE, remove it when will be done by BE
    model = { ...model, cuffCmH2o: model['cuffCmH2O'] };
    delete model['cuffCmH2O'];

    return model;
};

/**
 * Transform api basic care procedures
 *
 * @param apiEntryControls ApiModels.EntryControl[]
 */
export const transformApiEntryControls = (apiEntryControls: ApiModels.EntryControl[]): EntryControl[] => {
    return apiEntryControls.map(apiEntryControl => transformApiEntryControl(apiEntryControl));
};
