import { DateRange } from '@angular/material/datepicker';
import {
    endOfDay,
    formatISO,
    parse as _parse,
    set,
    startOfDay,
    startOfToday,
    startOfYesterday,
    subDays,
    toDate as _toDate,
    eachMinuteOfInterval,
    addMinutes,
} from 'date-fns';
import { DateRangeInterval } from '../models';

/**
 * generates empty array from range
 *
 * @param length
 */
function range(length: number) {
    return Array.from({ length });
}

/**
 * Array of DateRange intervals
 * ```
 *  [
 * ...
 *   {
 *       "start": "2023-03-02T09:00:00.000Z",
 *       "end": "2023-03-02T09:30:00.000Z"
 *   },
 *   {
 *       "start": "2023-03-02T09:30:00.000Z",
 *       "end": "2023-03-02T10:00:00.000Z"
 *   }
 *   ...
 *   ]
 * ```
 *
 * @param day
 */
export function generate30MinutesRangesFor1Day(day: Date = new Date()): DateRange<Date>[] {
    const slicedInterval = eachMinuteOfInterval({ start: startOfDay(day), end: endOfDay(day) }, { step: 30 });
    return slicedInterval.map((intervalDateItem, index) => {
        const end = index === slicedInterval.length - 1 ? endOfDay(day) : addMinutes(intervalDateItem, 30);
        return new DateRange(intervalDateItem, end);
    });
}

/**
 * formats number to 2v string
 *
 * @param n
 * @returns string
 */
export function paddToTwoSymbols(n: number): string {
    if (n < 10) {
        return '0' + n;
    }

    return n.toString();
}

/**
 * Return the formatted date string in ISO 8601 format. Options may be passed to control the parts and notations of the date.
 *
 * @param format
 */
export function getISOString(format: 'extended' | 'basic' = 'basic'): string {
    return formatISO(new Date(), { format });
}

/**
 * Get Start Of Today
 *
 */
export const getStartOfToday = (): Date => {
    return startOfToday();
};

/**
 * Get Start Of Yesterday
 *
 */
export const getStartOfYesterday = (): Date => {
    return startOfYesterday();
};

/**
 * Get Start Of Day Days Ago
 *
 * @param date
 * @param subDaysCount
 */
export const getStartOfDaysAgo = (date: Date, subDaysCount = 0): Date => {
    return startOfDay(subDays(date, subDaysCount));
};

/**
 * Is valid date range
 *
 * @param range
 */
export function isValidDateRange(range: DateRangeInterval): boolean {
    if (!range) {
        return false;
    }

    const start = toDate(range.start);
    const end = toDate(range.end);

    return !isNaN(start.getTime()) && !isNaN(end.getTime()) && !(start > end);
}

/**
 * Conver to valid Date object
 *
 * @param date
 */
export function toDate(date: Date | number | string): Date {
    if (!date) {
        return;
    }

    if (typeof date === 'string') {
        return new Date(date);
    }
    return _toDate(date as Date | number);
}

/**
 * Return the date parsed from string using the given format string.
 *
 * `parse('2019-01-01', 'yyyy-MM-dd', new Date());`
 *
 * @param dateString
 * @param formatString
 * @param referenceDate
 * @param options
 */
export function parseDate(
    dateString: string,
    formatString: string,
    referenceDate = new Date(),
    options?: any,
): Date | null {
    // if parsing failed, `Invalid Date` will be returned. let's catch RangeError and return null instead
    try {
        return _parse(dateString, formatString, referenceDate, options);
    } catch (error) {
        return null;
    }
}

/**
 * Converts an array of UTC times to local time.
 *
 * @param {string[]} utcTimes - An array of UTC times.
 * @param {string} createdAt - date of adding times to track DST
 * @returns {string[]} - The converted array of local times.
 */
export function convertUTCHoursToLocalTime(utcTimes: string[], createdAt?: string): string[] {
    return utcTimes?.slice()?.map(t => {
        let utcHour = parseInt(t.split(':')[0], 10); // Extract the hour part and convert it to a number
        if (createdAt && new Date(createdAt).getTimezoneOffset() > new Date().getTimezoneOffset()) {
            utcHour++;
        }

        const utcDate = new Date().setUTCHours(utcHour);
        const localHour = new Date(utcDate).getHours();
        return localHour.toString().padStart(2, '0') + ':00:00';
    });
}

/**
 Converts a UTC hour number to a local time hour number.
 * @param utcHour - The UTC hour number to be converted.
 * @returns The local time hour number.
 */
export function convertUTCHourToLocalHour(utcHour: number): number {
    const utcDate = new Date().setUTCHours(utcHour);
    const localHour = new Date(utcDate).getHours();
    return localHour;
}

/**
 Converts a UTC hour number to a local time hour number.
 * @param time
 * @returns The local time hour number.
 */
export function convertLocalHourToUTCHour(time: string): string {
    const hour = parseInt(time.split(':')[0], 10); // Extract the hour part and convert it to a number
    const date = new Date().setHours(hour);
    const utcHour = new Date(date).getUTCHours();
    return utcHour.toString().padStart(2, '0') + ':00';
}

/**
 * Converts an array of UTC times to local time.
 *
 * @param times - An array of local times.
 * @returns {string[]} - The converted array of UTC times.
 */
export function convertLocalHoursToUTC(times: string[]): string[] {
    return times?.slice()?.map(t => set(new Date(), { hours: +t.split(':')[0] }).getUTCHours() + ':00:00');
}
