import { ApiModels } from '@mona/api';
import { Medication, MedicationGroup } from '@mona/models';

/**
 * Transforms api medication
 *
 * @param apiMedication Medication
 */
export const transformApiMedication = (apiMedication: ApiModels.Medication): Medication => {
    return {
        id: apiMedication.code,
        code: apiMedication.code,
        group: apiMedication.group,
        categoryCode: apiMedication.category_code,
        displayName: apiMedication.display_name,
        isUserDefined: apiMedication.is_user_defined,
        lastChangedBy: apiMedication.last_changed_by && apiMedication.last_changed_by,
        lastChangedAt: apiMedication.last_changed_at && new Date(apiMedication.last_changed_at),
    };
};

/**
 * Transforms api medication
 *
 * @param medication Medication
 */
export const transformToApiMedication = (medication: Medication): ApiModels.Medication => {
    return {
        code: medication.code,
        category_code: medication.categoryCode,
        display_name: medication.displayName,
        is_user_defined: medication.isUserDefined,
        last_changed_by: medication.lastChangedBy as string,
        // last_changed_at: medication.lastChangedAt as string,
    } as any; // FIXME
};

/**
 * Transform api medication
 *
 * @param apiMedications ApiModels.Medication[]
 */
export const transformApiMedications = (apiMedications: ApiModels.Medication[]): Medication[] => {
    return apiMedications.map(apiMedication => transformApiMedication(apiMedication));
};

/**
 * Transform api medication groups
 *
 * @param apiMedications ApiModels.MedicationGroup[]
 */
export const transformApiMedicationGroups = (apiMedications: ApiModels.MedicationGroup[]): MedicationGroup[] => {
    return apiMedications.map(apiMedication => ({
        id: apiMedication.code,
        code: apiMedication.code,
        displayName: apiMedication.display_name,
        isUserDefined: apiMedication.is_user_defined,
    }));
};
