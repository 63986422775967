import { ApiModels } from '@mona/api';
import { SurgeryPrescription } from '@mona/models';

/**
 * Transforms API apiSurgeryPrescription
 * @param apiSurgeryPrescription ApiModels.SurgeryPrescription
 */
export const transformApiSurgeryPrescription = (
    apiSurgeryPrescription: ApiModels.SurgeryPrescription,
): SurgeryPrescription => {
    return ApiModels.SurgeryPrescription.DTO.toModel(apiSurgeryPrescription);
};

/**
 * Transforms API apiSurgeryPrescriptions
 * @param apiSurgeryPrescriptions ApiModels.SurgeryPrescription[]
 */
export const transformApiSurgeryPrescriptions = (
    apiSurgeryPrescriptions: ApiModels.SurgeryPrescription[],
): SurgeryPrescription[] => {
    return apiSurgeryPrescriptions.map(apiSurgeryPrescription =>
        transformApiSurgeryPrescription(apiSurgeryPrescription),
    );
};
