<mat-card
    class="mona-medication-list__wrapper"
    [uiLoadingOverlay]="isLoading"
    *ngLet="{
        medicationCategories: medicationCategories$ | async,
        medicationSolutions: medicationSolutions$ | async,
        medicationAdministrationMethods: medicationAdministrationMethods$ | async,
        medicationFrequencies: medicationFrequencies$ | async,
        medicationDosageForms: medicationDosageForms$ | async,
    } as $"
>
    <ui-table
        class="mona-medication-list__table"
        [showNoData]="showNoData"
        [isBorderless]="true"
        [showHeaderRow]="false"
        [isStriped]="false"
        [dataSource]="dataSource"
        [noDataConfig]="noDataConfig"
        [isLoading]="isLoading"
        [rowMenuItems]="rowMenuItems"
        (tableEvent)="onTableEvent($event)"
    >
        <!-- ------------------------------------------------->
        <!-- COL.MEDICATION-->
        <!-- ------------------------------------------------->
        <ng-container matColumnDef="medication">
            <th class="ui-table-th" [class.ui-table-th--hidden]="!dataSource.length" mat-cell *matHeaderCellDef></th>
            <td mat-cell class="ui-table-td text-left" *matCellDef="let row">
                <mona-medication-item
                    [item]="row"
                    [medicationCategories]="$.medicationCategories"
                    [medicationSolutions]="$.medicationSolutions"
                    [medicationAdministrationMethods]="$.medicationAdministrationMethods"
                    [medicationFrequencies]="$.medicationFrequencies"
                    [medicationDosageForms]="$.medicationDosageForms"
                ></mona-medication-item>
            </td>
        </ng-container>
    </ui-table>
</mat-card>
