import { forwardRef, Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { isFunction } from '@mona/shared/utils';
import { DialogService } from '@mona/ui';
import { ConfigUpdater, CONFIG_UPDATER } from '../../models';
import { ConfigService } from '../../services';
import * as ConfigActions from '../actions/config.actions';

/**
 * ConfigEffects
 */
@Injectable()
export class ConfigEffects {
    updateConfig$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ConfigActions.updateConfig),
                concatLatestFrom(() => this.service.configLoaded$),
                tap(([{ config }]) => {
                    try {
                        // INFO: override protected property here
                        // to be able to `set` directly to store first
                        (this.service as any)._config.set(config);
                        this.store.dispatch(ConfigActions.updateConfigSuccess({ config: this.service.config }));
                    } catch (error) {
                        this.store.dispatch(ConfigActions.updateConfigFailure({ error }));
                    }
                }),
            );
        },
        { dispatch: false },
    );

    /** If updater function avalable - run side-effect (e.g. write to file) */
    updateConfigSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ConfigActions.updateConfigSuccess),
                tap(({ config }) => {
                    if (isFunction(this.configUpdater)) {
                        this.configUpdater(config);
                    }
                }),
            );
        },
        { dispatch: false },
    );

    initConfig$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ConfigActions.initConfig),
                tap(({ config }) => {
                    if (isFunction(this.configUpdater)) {
                        this.configUpdater(config);
                    }
                }),
            );
        },
        { dispatch: false },
    );

    initConfigFailure = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ConfigActions.initConfigFailure),
                tap(({ error }) => {
                    this.dialogService.showErrorDialog(
                        {
                            originalError: `Validation error while trying to initialize mona config.\nError cause: ${error.message}`,
                        },
                        true,
                        true,
                    );
                }),
            );
        },
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store
     * @param actions$
     * @param dialogService
     * @param service
     * @param configUpdater
     */
    constructor(
        private readonly store: Store,
        private actions$: Actions,
        private dialogService: DialogService,
        @Inject(forwardRef(() => ConfigService)) private service: ConfigService,
        @Inject(CONFIG_UPDATER) private configUpdater: ConfigUpdater,
    ) {}
}
