import { CallWsEvent } from './call-ws-event.enum';

/**
 * Call ws message
 */
export interface CallWsMessage {
    /**
     * Event name
     */
    event: CallWsEvent;

    /**
     * Data
     */
    data?: any;
}
/* eslint-disable jsdoc/require-jsdoc */
export class CallWsMessage {
    constructor(public event: CallWsEvent, public data?: any) {}
}
