import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@mona/shared/utils';
import { DialogService, getRouteConfigForDialogComponent } from '@mona/ui';
import { DiagnosticsDialogComponent, DIAGNOSTICS_DIALOG_ID } from '../diagnostics';

export const SERVER_UNAVAILABLE_DIALOG_ID = 'server-unavailable-dialog';

/**
 * Server unavailable dialog
 */
@Component({
    standalone: true,
    selector: 'mona-server-unavailable-dialog',
    templateUrl: './server-unavailable-dialog.component.html',
    styleUrls: ['./server-unavailable-dialog.component.scss'],
    imports: [UtilsModule, MatDialogModule, MatButtonModule, MatIconModule],
})
export class ServerUnavailableDialogComponent {
    /**
     * INFO: add comment
     * @param dialogService
     */
    constructor(private dialogService: DialogService) {}

    /**
     * Opens diagnostics dialog
     */
    openDiagnostics(): void {
        this.dialogService.open(
            DiagnosticsDialogComponent,
            {
                isCoreOnlyCheck: true,
                allowChangeUrls: true,
                closeWhenAvailableChange: false,
            },
            { id: DIAGNOSTICS_DIALOG_ID },
        );
    }
}

export const SERVER_UNAVAILABLE_DIALOG_ROUTES = getRouteConfigForDialogComponent(ServerUnavailableDialogComponent, {
    config: {
        disableClose: true,
        panelClass: 'mona-server-unavailable-dialog',
    },
});
