// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for Questionnaire Item Type Model.
 * @see #/components/schemas/QuestionnaireItemType - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface QuestionnaireItemType {
    /**
     * The Questionnaire Item Type Id
     * @dataFormat uuid
     */
    readonly id: string;
    name: string;
    display_name?: string | null;
    repeats: boolean;
    is_extension?: boolean;
    answer_options_required?: boolean;
    fhir_type: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for QuestionnaireItemType
 */
export namespace QuestionnaireItemType {
    /**
     * QuestionnaireItemType Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The Questionnaire Item Type Id
         * @dataFormat uuid
         */
        readonly id: string;
        name: string;
        displayName?: string | null;
        repeats: boolean;
        isExtension?: boolean;
        answerOptionsRequired?: boolean;
        fhirType: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * QuestionnaireItemType DTO
     * Transforms QuestionnaireItemType model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend QuestionnaireItemType model */
        static toModel(obj: QuestionnaireItemType): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend QuestionnaireItemType model */
        static toApi(obj: Model): QuestionnaireItemType {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as QuestionnaireItemType;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
