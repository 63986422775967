import { ApiModels } from '@mona/api';
import { LabValue } from '@mona/models';

/**
 * Transforms api lab value
 *
 * @param apiLabValue ApiModels.LabValue
 */
export const transformApiLabValue = (apiLabValue: ApiModels.LabValue): LabValue => {
    const model = ApiModels.LabValue.DTO.toModel(apiLabValue);
    return model;
};

/**
 * Transform api lab values
 *
 * @param apiLabValues apiVitalSigns
 */
export const transformApiLabValues = (apiLabValues: ApiModels.LabValue[]): LabValue[] => {
    return apiLabValues.map(apiLabValue => transformApiLabValue(apiLabValue));
};
