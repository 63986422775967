import { ApiModels } from '@mona/api';
import { CareCheckType } from '@mona/models';

/**
 * Transforms api care check type
 * @param apiCareCheckType ApiModels.CareCheckType
 */
export const transformApiCareCheckType = (apiCareCheckType: ApiModels.CareCheckType): CareCheckType => {
    return {
        id: apiCareCheckType.code,
        code: apiCareCheckType.code,
        displayName: apiCareCheckType.display_name,
    };
};

/**
 * Transform api care check types
 * @param apiCareCheckTypes ApiModels.CareCheckTypes[]
 */
export const transformApiCareCheckTypes = (apiCareCheckTypes: ApiModels.CareCheckType[]): CareCheckType[] => {
    return apiCareCheckTypes.map(apiCareCheckType => transformApiCareCheckType(apiCareCheckType));
};
