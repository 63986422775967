/* eslint-disable jsdoc/require-jsdoc, @typescript-eslint/ban-types, no-empty */
/**
 * Console Styles
 *
 * @see formatting - https://developer.chrome.com/docs/devtools/console/format-style/
 * @see clolors - https://github.com/jorgebucaran/colorette/blob/main/index.js
 */
export const CONSOLE_STYLES = {
    reset: [0, 0],
    bold: [1, 22],
    dim: [2, 22],
    italic: [3, 23],
    underline: [4, 24],
    blink: [5, 25],
    inverse: [7, 27],
    hidden: [8, 28],
    strikethrough: [9, 29],
    doubleunderline: [21, 24],
    black: [30, 39],
    red: [31, 39],
    green: [32, 39],
    yellow: [33, 39],
    blue: [34, 39],
    magenta: [35, 39],
    cyan: [36, 39],
    white: [37, 39],
    bgBlack: [40, 49],
    bgRed: [41, 49],
    bgGreen: [42, 49],
    bgYellow: [43, 49],
    bgBlue: [44, 49],
    bgMagenta: [45, 49],
    bgCyan: [46, 49],
    bgWhite: [47, 49],
    framed: [51, 54],
    overlined: [53, 55],
    grey: [90, 39],
    gray: [90, 39],
    redBright: [91, 39],
    greenBright: [92, 39],
    yellowBright: [93, 39],
    blueBright: [94, 39],
    magentaBright: [95, 39],
    cyanBright: [96, 39],
    whiteBright: [97, 39],
    bgGray: [100, 49],
    bgRedBright: [101, 49],
    bgGreenBright: [102, 49],
    bgYellowBright: [103, 49],
    bgBlueBright: [104, 49],
    bgMagentaBright: [105, 49],
    bgCyanBright: [106, 49],
    bgWhiteBright: [107, 49],
};

function colorizeStart(style: keyof typeof CONSOLE_STYLES) {
    return style ? `\x1B[${CONSOLE_STYLES[style][0]}m` : '';
}

function colorizeEnd(style: keyof typeof CONSOLE_STYLES) {
    return style ? `\x1B[${CONSOLE_STYLES[style][1]}m` : '';
}

export function colorize(str: string, style: keyof typeof CONSOLE_STYLES) {
    return colorizeStart(style) + str + colorizeEnd(style);
}
