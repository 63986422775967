import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { environment } from '@environment';
import {
    MedicationAdministration,
    MedicationCategory,
    MedicationCategoryConfig,
    MedicationCategoryCode,
    MedicationPrescription,
} from '@mona/models';
import { getFormControlsMap } from '@mona/shared/utils';
import { BaseMedicationForm } from './base-medication-form';

/**
 * Detect instance of MedicationPrescription or MedicationAdministration
 *
 * @param medication
 */
export const isMedicationAdministration = (
    medication: MedicationPrescription | MedicationAdministration,
): medication is MedicationAdministration => {
    return (medication as MedicationAdministration).prescriptionId !== undefined;
};

/**
 * Run through each control and check if it should be required
 *
 * @param formGroup
 * @param selectedCategoryCode
 * @param medicationCategories
 * @private
 */
export const configRequiredFields = (
    formGroup: BaseMedicationForm,
    selectedCategoryCode: MedicationCategoryCode,
    medicationCategories: MedicationCategory[],
): void => {
    getFormControlsMap(formGroup).forEach((control, key) => {
        const fieldInConfig = getFieldConfigKey(key);
        const category = selectedCategoryCode || formGroup.get('medication')?.value?.categoryCode;
        if (!category) {
            return;
        }

        const required = getFieldStatusFromConfig(fieldInConfig, category, medicationCategories, 'required_create');
        if (required) {
            control.addValidators(Validators.required);
        } else {
            control.removeValidators(Validators.required);
        }
        control.updateValueAndValidity({ onlySelf: false, emitEvent: false });
    });
};

/**
 * Return proper field name for config
 *
 * @param key
 * @private
 */
export const getFieldConfigKey = (key: string): string => {
    switch (key) {
        case 'administrationMethodCode':
            return 'method';
        case 'frequencyCode':
            return 'frequency';
        default:
            return key;
    }
};

/**
 * Return whether or not to display/disable field
 *  required_form property is responsible for displaying field for category
 *  required_create property answers is field is required for creating prescription
 *
 * @param field
 * @param categoryCode
 * @param medicationCategories
 * @param property
 */
export const getFieldStatusFromConfig = (
    field: string,
    categoryCode: string,
    medicationCategories: MedicationCategory[],
    property = 'required_form',
): boolean => {
    if (!categoryCode) {
        return false;
    }

    const categoryConfig = medicationCategories.find(c => c.code === categoryCode);

    // FIXME: TODO: figure out why we are not retrieving config from the first time
    if (!categoryConfig?.fieldConfig) {
        return false;
    }

    const fields = Object.entries(categoryConfig.fieldConfig)
        .map(([key, value]: [string, MedicationCategoryConfig]) => {
            if (value[property]) {
                return key;
            }
        })
        .filter(Boolean);

    return fields.includes(field);
};

/**
 * Find specific frequency by BE uuid and adjust validators for freq times
 * For `Daily as required` id is `feadd04c-14ae-43f8-983c-71eaf431cd90`
 * @param controlFrequency
 */
export const validateFrequency = (controlFrequency: FormControl<string>): ValidatorFn => {
    return (controlFrequencyTimes: FormControl<string[]>) => {
        if (controlFrequency.hasError('required')) {
            return;
        }

        if (
            controlFrequency.value === environment.noRequiredFrequencyCode ||
            controlFrequency.value === environment.continuousFrequencyCode ||
            controlFrequencyTimes.value?.length
        ) {
            controlFrequencyTimes.removeValidators(Validators.minLength(1));
            controlFrequencyTimes.setErrors(null);
            controlFrequencyTimes.markAsPristine();

            return null;
        } else {
            controlFrequencyTimes.addValidators(Validators.minLength(1));
            controlFrequencyTimes.setErrors({ required: true });
            controlFrequencyTimes.markAsPristine();

            return controlFrequencyTimes.errors || null;
        }
    };
};
