import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { isEmptyView, requireContent } from '../../utils/utils';

type IconAlignType = 'left' | 'center' | 'right' | undefined;
type DividerType = 'full' | 'partial' | undefined;

/**
 * [InfoListItem Component](https://brightlayer-ui-components.github.io/angular/?path=/info/components-info-list-item--readme)
 *
 * The `<ui-info-list-item>` is intended to be used in List views.
 * It positions a title as well as optional subtitle(s), icon, and status stripe.
 */
@Component({
    selector: 'ui-info-list-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    template: `
        <mat-list-item
            class="ui-info-list-item-content"
            [class.ui-info-list-item-wrap]="wrapSubtitle || wrapTitle || wrapInfo"
            [class.ui-info-list-item-dense]="dense"
            [class.ui-info-list-item-status]="statusColor"
            [style.border-left-color]="statusColor"
            [style.border-right-color]="statusColor"
            [disabled]="disabled"
        >
            <div
                mat-list-icon
                class="ui-info-list-item-icon-wrapper"
                [class.ui-info-list-item-hide-padding]="hidePadding"
                [class.ui-info-list-item-avatar]="avatar"
                [style.justify-content]="
                    iconAlign === 'right' ? 'flex-end' : iconAlign === 'center' ? 'center' : 'flex-start'
                "
            >
                <ng-content select="[ui-icon]"></ng-content>
            </div>
            <div class="ui-info-list-item-left-content-wrapper" matListItemLine>
                <ng-content select="[ui-left-content]"></ng-content>
            </div>
            <div
                class="mat-subtitle-2 ui-info-list-item-title-wrapper"
                matListItemTitle
                [class.ui-info-list-item-wrap]="wrapTitle"
                #title
            >
                <ng-content select="[ui-title]"></ng-content>
                <ng-content select="[ui-subtitle]"></ng-content>
                <ng-content select="[ui-info]"></ng-content>
            </div>
            <ui-spacer class="ui-info-list-item-spacer"></ui-spacer>
            <div class="ui-info-list-item-right-content" matListItemLine>
                <div #right class="ui-info-list-item-right-content-wrapper">
                    <ng-content select="[ui-right-content]"></ng-content>
                </div>
                <mat-icon *ngIf="chevron && isEmpty(rightEl)" class="ui-chevron">chevron_right</mat-icon>
            </div>
        </mat-list-item>
        <mat-divider
            *ngIf="divider"
            class="ui-info-list-item-divider"
            [class.ui-info-list-item-partial-divider]="divider === 'partial'"
        ></mat-divider>
    `,
    styleUrls: ['./info-list-item.component.scss'],
    host: {
        class: 'ui-info-list-item',
    },
})
export class InfoListItemComponent implements AfterViewInit {
    /**
     * Show a colored background for the icon
     *
     * @default false
     */
    @Input() avatar = false;
    /**
     * Add a chevron icon on the right
     *
     * @default false
     */
    @Input() chevron = false;
    /**
     * Smaller height row with less padding
     *
     * @default false
     */
    @Input() dense = false;
    /**
     * Show a row separator below the row.  Can be `partial` | `full`
     *
     * `partial` - Divider does not expand full width of the ListItem
     *
     * `full`- Divider spans full width of the ListItem
     */
    @Input() divider: DividerType;
    /**
     * Disable the list item
     *
     * @default false
     */
    @Input() disabled = false;
    /** Remove left padding if no icon is used */
    @Input() hidePadding = false;
    /**
     * Icon alignment when avatar is set to false. Can be `left` | `right` | `center`
     *
     * `left` - Icon appears at the `flex-start` of the icon container.
     *
     * `right` - Icon appears at the `flex-end` of the icon container.
     *
     * `center` - Icon appears at the center of the icon container.
     *
     * @default left
     */
    @Input() iconAlign: IconAlignType = 'left';
    /** Left border color */
    @Input() statusColor: string;
    /**
     * When true, 3rd-line text overflow breaks onto the next line instead of using an ellipsis
     *
     * @default false
     */
    @Input() wrapInfo = false;
    /**
     * When true, 2nd-line text overflow breaks onto the next line instead of using an ellipsis
     *
     * @default false
     */
    @Input() wrapSubtitle = false;
    /**
     * When true, 1st-line text overflow breaks onto the next line instead of using an ellipsis
     *
     * @default false
     */
    @Input() wrapTitle = false;

    @ViewChild('title') titleEl: ElementRef;
    @ViewChild('right') rightEl: ElementRef;

    isEmpty = (el: ElementRef): boolean => isEmptyView(el);

    /**
     * INFO: add comment
     */
    ngAfterViewInit(): void {
        const required = { selector: 'title', ref: this.titleEl };
        requireContent([required], this);
    }
}
