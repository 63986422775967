import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { OnChange } from '@mona/shared/utils';

/**
 * Makes a progress button from simple one
 */
@Directive({
    selector: 'button[uiButtonProgress]',
})
export class UiButtonProgressDirective {
    /**
     * Percentage of the progress
     */
    @OnChange('onProgressChange')
    @Input()
    progress: number;

    /**
     * Constructor
     *
     * @param el ElementRef<HTMLButtonElement>
     * @param renderer Renderer2
     */
    constructor(private el: ElementRef<HTMLButtonElement>, private renderer: Renderer2) {
        const overlayElement: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.appendChild(this.el.nativeElement, overlayElement);
        this.renderer.addClass(overlayElement, 'ui-button-progress-overlay');
        this.renderer.setStyle(overlayElement, 'width', `${this.progress}%`);
    }

    /**
     * Updates the progress bar width on change
     *
     * @param value Percentage
     */
    onProgressChange(value: number): void {
        const overlayElement = this.el.nativeElement.querySelector('.ui-button-progress-overlay');

        if (overlayElement) {
            if (value < 0) {
                value = 0;
            }

            if (value > 100) {
                value = 100;
            }

            this.renderer.setStyle(overlayElement, 'width', `${value}%`);
        }
    }
}
