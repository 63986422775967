import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { selectQueryParam } from '@mona/store';
import { PatientsAdmissionApi } from '../../infrastructure';
import { PatientsAdmissionActions } from '../actions';

/**
 * Patients effects
 */
@Injectable()
export class PatientsAdmissionEffects {
    loadPatientsAdmission$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientsAdmissionActions.loadAllAdmissionPatients),
            withLatestFrom(this.store.select(selectQueryParam('wardId'))),
            exhaustMap(([, wardId]) =>
                this.patientsAdmissionApi.getPatientsForAdmission(wardId).pipe(
                    map(patients => PatientsAdmissionActions.loadAllAdmissionPatientsSuccess({ patients })),
                    catchError(error => of(PatientsAdmissionActions.loadAllAdmissionPatientsFailure({ error }))),
                ),
            ),
        );
    });

    /**
     * Constructor
     *
     * @param {Actions} actions$ - actions
     * @param store
     * @param {PatientsAdmissionApi} patientsAdmissionApi - patients api
     */
    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private patientsAdmissionApi: PatientsAdmissionApi,
    ) {}
}
