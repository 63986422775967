<mat-dialog-content class="mona-camera-dialog__content scrollbar-visible">
    <h2 mat-dialog-title class="mona-camera-dialog__title">{{ 'cameraDialog.title' | translate }}</h2>

    <video
        class="mona-camera-dialog__video"
        [class.mona-camera-dialog__video--hidden]="!isVideoCaptured"
        [loop]="true"
        [muted]="true"
        autoplay="autoplay"
        #localVideo
    ></video>

    <div *ngIf="!isVideoCaptured" class="mona-camera-dialog__placeholder">
        <mat-icon class="mona-camera-dialog__placeholder-icon">videocam_off</mat-icon>
    </div>

    <div *ngIf="mediaError" class="mona-camera-dialog__error">
        <mat-icon class="text-warn">warning</mat-icon>
        <div class="mona-camera-dialog__error-text">{{ 'cameraDialog.cameraFailed' | translate }}</div>
        <button mat-button class="mona-camera-dialog__error-details" color="primary" (click)="showErrorDetails()">
            {{ 'cameraDialog.failedDetails' | translate }}
        </button>
    </div>

    <div class="mona-camera-dialog__actions">
        <button mat-button class="mona-camera-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'cameraDialog.cancel' | translate }}
        </button>
        <button [disabled]="!isVideoCaptured" mat-raised-button color="primary" [mat-dialog-close]="true">
            {{ 'cameraDialog.confirm' | translate }}
        </button>
    </div>
</mat-dialog-content>
