/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';

export const formUpdateAction = createAction('UI:FORM_UPDATE_VALUE', props<{ path; value }>());

export const formErrorAction = createAction('UI:FORM_SUBMIT_ERROR', props<{ path; error }>());

export const formSuccessAction = createAction('UI:FORM_SUBMIT_SUCCESS', props<{ path }>());

export const formResetAction = createAction('UI:FORM_RESET', props<{ path }>());
