import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreMedication } from '@mona/models';
import { buildPreMedicationFormGroup } from '../../models';

/**
 * Pre medication dialog
 */
@Component({
    selector: 'app-pre-medication-dialog',
    templateUrl: './pre-medication-dialog.component.html',
    styleUrls: ['./pre-medication-dialog.component.scss'],
})
export class PreMedicationDialogComponent {
    /**
     * PreMedications form
     */
    preMedicationForm = buildPreMedicationFormGroup();
    /**
     * Is edit mode
     */
    isEditMode: boolean;

    /**
     * Constructor
     * @param dialogRef MatDialogRef<AnamnesisDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<PreMedicationDialogComponent>,
        /* prettier-ignore */
        @Inject(MAT_DIALOG_DATA)
        // tslint:disable-next-line: completed-docs
        public data: { preMedication: PreMedication; isBedSideMode: boolean; },
    ) {
        dialogRef.addPanelClass('app-pre-medication-dialog');

        if (data?.preMedication?.id) {
            this.isEditMode = true;
            this.preMedicationForm.patchValue({
                ...data.preMedication,
            });
            this.preMedicationForm.controls.description.disable();
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.preMedicationForm.markAllAsTouched();
        if (this.preMedicationForm.valid) {
            const data = this.preMedicationForm.getRawValue();
            this.dialogRef.close({
                ...data,
                id: this.data?.preMedication?.id,
            } as PreMedication);
        }
    }
}
