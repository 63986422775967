import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Encounter, SurgeryPrescription } from '@mona/models';
import { transformApiSurgeryPrescriptions } from '@mona/pdms/data-access-encounter-data';

/**
 * API abstraction layer for the surgery prescription API
 */
@Injectable({
    providedIn: 'root',
})
export class SurgeryPrescriptionApi {
    apiBase = '/pdms/surgery-prescription/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get surgery prescription
     *
     * @param encounterId EntityId<Encounter>
     */
    getSurgeryPrescription(encounterId?: EntityId<Encounter>): Observable<SurgeryPrescription[]> {
        const params: { [param: string]: string | string[] } = {};

        if (encounterId) {
            params.encounter_id = `${encounterId}`;
        }

        return this.http
            .get<ApiModels.SurgeryPrescription[]>(this.apiBase, { params })
            .pipe(map(surgeries => transformApiSurgeryPrescriptions(surgeries)));
    }
}
