/* eslint-disable  @typescript-eslint/ban-ts-comment, jsdoc/require-jsdoc, @typescript-eslint/ban-types, no-empty, curly */

import { configure } from 'safe-stable-stringify';

type MapType = Record<any, any> & { size: number; has: AnyFn };

const MAXDEPTH = Infinity;

export const isFunction = (x: any): x is Function => !!(x && x.constructor && x.call && x.apply);
export const isPromiseLike = (x: any): x is PromiseLike<any> => !!(x && x.then && isFunction(x.then));
export const isSubscribable = (x: any): x is Subscribable<any> => !!(x && x.subscribe && isFunction(x.subscribe));
export const isMapLike = (x: any): x is MapType => !!(x && 'size' in x && isFunction(x.has));

export const noop = (): undefined => void 0;

export function isInBrowser() {
    return typeof window !== 'undefined' && typeof XMLHttpRequest === 'function';
}

/** https://github.com/angular/components/blob/main/src/cdk/platform/features/test-environment.ts */
export function isTestEnvironment(): boolean {
    return (
        // @ts-ignore
        (typeof __karma__ !== 'undefined' && !!__karma__) ||
        // @ts-ignore
        (typeof jasmine !== 'undefined' && !!jasmine) ||
        // @ts-ignore
        (typeof jest !== 'undefined' && !!jest) ||
        // @ts-ignore
        (typeof Mocha !== 'undefined' && !!Mocha)
    );
}

export function getLocationString() {
    return typeof location !== 'undefined' ? location.toString() : '';
}

export function keysOfNonArray<T>(object: T | Nil): Array<StringifiedKey<T>> {
    let val: string[] = [];
    if (object) {
        val = Object.getOwnPropertyNames(object);
    }
    return val as any;
}

export function intersection<T>(...arrays: Array<Nil | readonly T[]>): T[] {
    const sets = arrays.map(array => new Set(array));
    return [...sets[0]].filter(value => sets.every(set => set.has(value)));
}

/**
 *
 * Safe, deterministic and fast serialization alternative to JSON.stringify.
 * Gracefully handles circular structures and bigint instead of throwing.
 *
 * NOTE: may use `fast-redact` if need obfuscation in logs in future
 *
 * @param obj
 */
export function safeStringify(obj: AnyObject): string {
    let serialized = '';
    const stringify = configure({
        circularValue: '[Circular]',
        deterministic: false,
        maximumDepth: 2,
        // maximumBreadth: 4,
    });
    try {
        serialized = stringify(obj);
    } catch (error) {}
    return serialized;
}
