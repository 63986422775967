/* eslint-disable @typescript-eslint/triple-slash-reference, no-console */
//// <reference path="../../../../../../typings.d.ts" />
/// <reference path="../../../../../../node_modules/@types/requestidlecallback/index.d.ts" />
import { _isTestEnvironment } from '@angular/cdk/platform';
import { isDevMode } from '@angular/core';
import { difference as _difference } from 'lodash/fp';
import { defer, EMPTY, isObservable, Observable, of, OperatorFunction, Subject, throwError } from 'rxjs';
import {
    concatAll,
    defaultIfEmpty,
    filter,
    finalize,
    first,
    isEmpty as isEmpty$,
    map,
    mergeMap,
    reduce,
    shareReplay,
    startWith,
    take,
    tap,
} from 'rxjs/operators';
import { Constructor, IterableElement } from 'type-fest';
import { colorize, Logger } from '@mona/shared/logger';
import { identity } from '../helpers';
import { isEmpty, isObject } from '../types/type-guards';
import { Suspense } from './supensify';

/**
 * Unlike the `animationFrames` observable, `idle` observable emits only once when the browser
 * becomes idle and then completes. It does this so that it doesn’t continuously emit notifications
 * whenever the browser happens to be idle.
 *
 * @tutorial https://ncjamieson.com/how-to-use-requestidlecallback/
 * @returns {*}  {Observable<void>}
 * @example ```ts
 *    source.pipe(
 *        bufferWhen(() => idle()),
 *        mergeMap((buffer) => buffer.map(work))
 *    );
 * ```
 */
export function idle(): Observable<void> {
    return new Observable<void>(observer => {
        const handle = requestIdleCallback(() => {
            observer.next();
            observer.complete();
        });
        return () => cancelIdleCallback(handle);
    });
}

// let returnObs$: Observable<any>;
function createReturnObs<U>(obs: Observable<U>, time: number, bufferReplays: number) {
    return obs.pipe(shareReplay(bufferReplays, time));
}

/**
 * Operator create cached observable
 *
 * @param obs
 * @param time
 * @param bufferReplays
 */
export function cachedWithTimer<T>(obs: Observable<T>, time: number, bufferReplays = 1) {
    return createReturnObs<T>(obs, time, bufferReplays).pipe(
        first(
            null,
            defer(() => createReturnObs(obs, time, bufferReplays)),
        ),
        mergeMap(d => (isObservable(d) ? d : of(d))),
    );
}

/**
 * Operator to act as a tap, but only once
 *
 * @param callback AnyFn
 */
export const tapOnce = <T>(callback: () => void) => {
    return (source: Observable<T>): Observable<T> =>
        defer(() => {
            callback();
            return source;
        });
};

/**
 * Operator to defer callback on condition
 *
 * @param condition
 * @param cb
 */
export function conditionalStartWith<T>(condition: () => boolean, cb: () => T): OperatorFunction<unknown, T> {
    return function (source: Observable<any>) {
        return defer(() => {
            return condition() ? source.pipe(startWith(cb())) : source;
        });
    };
}

/**
 * Operator to toggle loading indicator
 *
 * @param indicator Subject<boolean>
 */
export const indicate = <T>(indicator: Subject<boolean>) => {
    return (source: Observable<T>): Observable<T> =>
        source.pipe(
            tapOnce(() => indicator.next(true)),
            finalize(() => indicator.next(false)),
        );
};

/**
 * Operator to toggle loading indicator
 *
 * @param indicator Subject<boolean>
 * @param ms timeout in ms
 * @param swtch
 */
export const indicateWhen = <T>(indicator: Subject<boolean>, ms = 1000, swtch = false) => {
    return (source: Observable<T>): Observable<T> =>
        source.pipe(
            tapOnce(() => indicator.next(true)),
            tap(() => {
                indicator.next(true);
                setTimeout(() => {
                    indicator.next(false);
                }, ms);
            }),
            // takeUntil(source),
            // swtch ? (switchMap(() => indicator) as any) : identity,
        );
};

/**
 * Operator to toggle loading indicator with service implementing `setLoading` method
 *
 * @param loadingSetter
 * @param ms
 */
export const withLoading = <T>(loadingSetter: { setLoading: AnyFn }, ms = 500): OperatorFunction<T, T> => {
    return (source: Observable<T>) =>
        defer(() => {
            loadingSetter.setLoading(true);
            return source.pipe(
                tap(() => {
                    loadingSetter.setLoading(true);
                    setTimeout(() => {
                        loadingSetter.setLoading(false);
                    }, ms);
                }),
                finalize(() => loadingSetter.setLoading(false)),
            );
        });
};

/**
 * Operator to map data to CLass
 *
 * @param ClassType
 */
export const toClass =
    <T>(ClassType: Constructor<T>) =>
    (source: Observable<T>) =>
        source.pipe(map(val => Object.assign(new ClassType(), val)));

/**
 * Operator to map to data
 *
 * @default also returns empty array if data is undefined
 */
export function toData<T extends Array<any>>(): OperatorFunction<TypedMessageEvent<T>, T> {
    return (source: Observable<TypedMessageEvent<T>>) =>
        source.pipe(
            defaultIfEmpty({ data: [] } as TypedMessageEvent<T>),
            map<TypedMessageEvent<T>, T>(({ data }) => data),
            defaultIfEmpty([] as T),
        );
}

/**
 * Operator to log debug (in dev mode only)
 *
 * @param tag
 * @see https://gist.github.com/NetanelBasal/46d8266f3fa6d9be15dc1ea6ed6cbe3e#file-oper-16-ts
 */
export function debug<T>(tag = 'Event'): OperatorFunction<T, T> {
    return function debugFn<T>(source: Observable<T>) {
        if (!isDevMode() || _isTestEnvironment()) {
            return source;
        }
        const logger = new Logger('Observable:' + tag);
        return source.pipe(
            tap({
                next(value: T) {
                    logger.log(colorize('Next', 'green'), value);
                },
                error(error) {
                    logger.log(colorize('Error', 'red'), error.message);
                },
                complete() {
                    logger.log(colorize('Complete', 'blue'));
                },
            }),
        );
    };
}

/**
 * A pipe-able operator to filter empty objects
 *
 * @returns Observable if not empty
 * @example
 * source.pipe(notEmpty()).subscribe...
 */
export function notEmpty<T>(): OperatorFunction<T, T> {
    return filter<T>(value => !isEmpty(value));
}

/**
 * A pipe-able operator to filter some objects by loading prop
 *
 * @returns Observable boolean
 * @example
 * source.pipe(someIsLoading()).subscribe...
 */
export function someIsLoading<T>(): OperatorFunction<T, boolean> {
    return map<T, boolean>(
        value => Array.isArray(value) && value.some(item => (isObject(item) ? (item as AnyObject).inProgress : !!item)),
    );
}

/**
 * Operator to throwError if source is empty
 *
 * @param source
 */
export const errorIfEmpty = (source: Observable<any>) =>
    source.pipe(
        isEmpty$(),
        mergeMap((empty: boolean) => (empty ? throwError(new Error('Result of source observable is Empty!')) : EMPTY)),
    );

/**
 * A pipe-able operator to filter objects difference from arrays
 *
 * @returns Observable if not empty
 * @example
 * combine([arr1, arr2]).pipe(difference<string[]>()).subscribe...
 */
export function diffTwoSources<R>(): OperatorFunction<any, R[]> {
    return (
        filter(([arr1, arr2]: [R[], R[]]) => Array.isArray(arr1) && Array.isArray(arr2)),
        map(([arr1, arr2]: [R[], R[]]) => _difference(arr1, arr2))
    );
}

/**
 * A pipe-able operator to filter inner observable which is array
 *
 * @param predicate
 * @returns Observable if not empty
 */
export function filterInside<T = any[]>(
    predicate: (value: IterableElement<T>, index: number) => boolean,
): OperatorFunction<T, T> {
    return (source: Observable<T>) =>
        source.pipe(
            notEmpty(),
            map(a => (a as any).filter(predicate)),
        );
}

/**
 * Operator to filter and map success action
 *
 * @param once
 */
export function toSuccessActionResult<T>(once = false): (source$: Observable<Suspense<T>>) => Observable<T> {
    return source$ =>
        source$.pipe(
            filterSuccessAction(),
            map(action => action.result),
            once ? first() : identity,
        );
}
/**
 * Operator to filter success action
 */
export function filterSuccessAction<T>(): (source$: Observable<Suspense<T>>) => Observable<Suspense<T>> {
    return source$ => source$.pipe(filter(action => action.succeeded && action.finished));
}
/**
 * Operator to filter (skip) source which is loading
 */
export function onlyLoaded<T extends AnyObject>(): (source$: Observable<T>) => Observable<T> {
    return source$ =>
        source$.pipe(
            filter(s => {
                if (!isObject(s)) {
                    return true;
                }
                if (s.hasOwnProperty('inProgress')) {
                    return !s.inProgress;
                }
                if (s.hasOwnProperty('isLoading')) {
                    return !s.isLoading;
                }
            }),
        );
}
/**
 * Operator to reduce all result arrays to `lastChangedBy` unique values
 *
 * @param key
 */
export function reduceAllToUniqueByKey<T>(key: string): (source$: Observable<T[][]>) => Observable<Set<string>> {
    return source$ =>
        source$.pipe(
            // flattens an observable-of-observables by putting one inner observable after the other.
            concatAll(),
            /* reduce all result arrays to `lastChangedBy` unique values */
            reduce((acc, result = []) => {
                result.forEach(r => r[key] && acc.add(r[key]));
                return acc;
            }, new Set<string>()),
        );
}
