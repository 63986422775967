import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CallSession, CallStatus, Practitioner } from '@mona/models';
import { AsyncActionState } from '@mona/store';
import { TelemedicineFeatureState } from '../entities';
import { CallActions } from '../state';

/**
 * Call service
 */
@Injectable({
    providedIn: 'root',
})
export class CallService {
    /**
     * Constructor
     *
     * @param store Store
     */
    constructor(private store: Store<TelemedicineFeatureState>) {}

    //#region Selectors

    /**
     * Get create call session async action
     */
    getCreateSessionAction(): Observable<AsyncActionState<CallSession>> {
        return this.store.select((state: TelemedicineFeatureState) => state.call?.createCallSessionAction);
    }

    /**
     * Get call session
     */
    getCallSession(): Observable<CallSession> {
        return this.store.select((state: TelemedicineFeatureState) => state.call?.session);
    }

    /**
     * Returns the current status of session
     */
    getCallStatus(): Observable<CallStatus> {
        return this.store.select(state => state.call?.session?.status);
    }

    //#endregion Selectors

    //#region Actions

    /**
     * Creates call session
     *
     */
    createSession() {
        this.store.dispatch(CallActions.createSession.action());
    }

    /**
     * Loads call session
     */
    loadCurrentSession() {
        this.store.dispatch(CallActions.loadSession.action());
    }

    /**
     * Clears crate call session action
     */
    clearCreateCallSession() {
        this.store.dispatch(CallActions.createSession.clearAction());
    }

    /**
     * Clears load call session action
     */
    clearLoadCallSession() {
        this.store.dispatch(CallActions.loadSession.clearAction());
    }

    //#endregion Actions
}
