import { createAction, props } from '@ngrx/store';
import { PageableModel, ParamsModel, VentilationParameter, VentilationProcedure } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';

/**
 * Ventilation actions
 */
export class VentilationAction {
    static setIntervalAction = createAction(
        'ENCOUNTER:SET_VENTILATION_INTERVAL',
        props<{ interval: EntriesInterval }>(),
    );

    static setLoadingAction = createAction('ENCOUNTER:SET_VENTILATION_LOADING', props<{ isLoading: boolean }>());

    static loadVentilationParameters = createAction(
        'ENCOUNTER:LOAD_VENTILATION_PARAMETERS_PAGINATED',
        props<{ params?: ParamsModel; url?: string }>(),
    );

    static loadVentilationParametersSuccess = createAction(
        'ENCOUNTER:LOAD_VENTILATION_PARAMETERS_PAGINATED_SUCCESS',
        props<PageableModel<VentilationParameter>>(),
    );

    static loadVentilationParametersFailed = createAction(
        'ENCOUNTER:LOAD_VENTILATION_PARAMETERS_PAGINATED_FAILED',
        props<{ error: unknown }>(),
    );

    static loadVentilationProcedures = createAction('ENCOUNTER:LOAD_VENTILATION_PROCEDURES');

    static loadVentilationProceduresSuccess = createAction(
        'ENCOUNTER:LOAD_VENTILATION_PROCEDURES_SUCCESS',
        props<{ data: VentilationProcedure[] }>(),
    );

    static loadVentilationProceduresFailed = createAction(
        'ENCOUNTER:LOAD_VENTILATION_PROCEDURES_FAILED',
        props<{ error: unknown }>(),
    );

    static removeVentilationProcedures = createAction(
        'ENCOUNTER:REMOVE_VENTILATION_PROCEDURES',
        props<{ ids: string[] }>(),
    );

    static removeVentilationParameters = createAction(
        'ENCOUNTER:REMOVE_VENTILATION_PARAMETERS',
        props<{ ids: string[] }>(),
    );

    static upsertVentilationProcedures = createAction(
        'ENCOUNTER:UPSERT_VENTILATION_PROCEDURES',
        props<{ data: VentilationProcedure[] }>(),
    );

    static upsertVentilationParameters = createAction(
        'ENCOUNTER:UPSERT_VENTILATION_PARAMETERS',
        props<{ data: VentilationParameter[] }>(),
    );

    static addParameterChanges = createAction(
        'ENCOUNTER:ADD_VENTILATION_PARAMETER_CHANGES',
        props<{ toUpdateEntities: VentilationParameter[]; toRemoveIds: string[] }>(),
    );
    static addProcedureChanges = createAction(
        'ENCOUNTER:ADD_VENTILATION_PROCEDURE_CHANGES',
        props<{ toUpdateEntities: VentilationProcedure[]; toRemoveIds: string[] }>(),
    );

    static clearVentilationData = createAction('ENCOUNTER:CLEAR_VENTILATION_PARAMETERS_AND_PROCEDURES');
}
