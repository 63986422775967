import { ChangeLogEntry } from './change-log-entry.model';

/** Task Type */
export enum TaskType {
    ProcedurePrescriptions = 'procedurePrescriptions',
    MedicationPrescriptions = 'medicationPrescriptions',
}

/** Task interface */
export interface MonaTask<T = any, K = any> {
    /** id (timestamp + prescriptionId + prescription type) */
    id: string;
    /** timestamp */
    timestamp: string;
    /** prescriptionId */
    prescriptionId: string;
    /** type */
    type: TaskType;
    /** category display name */
    categoryDisplayName?: string;

    /** isCompleted */
    isCompleted?: boolean;
    /** hasChanges */
    hasChanges?: boolean;
    /** isStageRemoved */
    isStageRemoved?: boolean;
    /** change */
    change?: ChangeLogEntry<K>;
    /** prescription */
    prescription?: T;
}

type DateKey = string;

/** PrescriptionsGroup */
export interface PrescriptionsGroup {
    /** medicationPrescriptions */
    [TaskType.MedicationPrescriptions]: MonaTask[];
    /** procedurePrescriptions */
    [TaskType.ProcedurePrescriptions]: MonaTask[];
}

/** Task list map string({@link DateKey}) -> {@link PrescriptionsGroup}*/
export type TaskList<T = PrescriptionsGroup> = Record<DateKey, T>;

/** task ids lists */
export type TaskIdsList = Record<TaskType, string[]>;

/** Filtered task list with IDs only */
export type FilteredTaskList<T = PrescriptionsGroup> = {
    overdue: TaskList<T>;
    actual: TaskList<T>;
};
