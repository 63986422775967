/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { UiMessage } from '@mona/ui';

export const loadMessages = createAction('MESSAGES:LOAD_MESSAGES', props<{ messages: UiMessage[] }>());

export const addMessage = createAction('MESSAGES:ADD_MESSAGE', props<{ message: UiMessage }>());

export const selectMessage = createAction('MESSAGES:SELECT_MESSAGE', props<{ id: string }>());

export const upsertMessage = createAction('MESSAGES:UPSERT_MESSAGE', props<{ message: UiMessage }>());

export const addMessages = createAction('MESSAGES:ADD_MESSAGES', props<{ messages: UiMessage[] }>());

export const upsertMessages = createAction('MESSAGES:UPSERT_MESSAGES', props<{ messages: UiMessage[] }>());

export const updateMessage = createAction('MESSAGES:UPDATE_MESSAGE', props<{ message: Update<UiMessage> }>());

export const updateMessages = createAction('MESSAGES:UPDATE_MESSAGES', props<{ messages: Update<UiMessage>[] }>());

export const deleteMessage = createAction('MESSAGES:DELETE_MESSAGE', props<{ id: string }>());

export const deleteMessages = createAction('MESSAGES:DELETE_MESSAGES', props<{ ids: string[] }>());

export const clearMessages = createAction('MESSAGES:CLEAR_MESSAGES', (all = false) => ({ all }));
