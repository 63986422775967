import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Patient } from '@mona/models';
import { PatientsAdmissionActions } from '../actions';

/**  EntityAdapter<Patient> */
export const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>({
    selectId: (patient: Patient) => patient.parentEncounterId,
    sortComparer: (a: Patient, b: Patient) => a.patientNumber.localeCompare(b.patientNumber),
});

interface PatientsAdmissionState extends EntityState<Patient> {
    isLoading: boolean;
    filter: string;
}

export const initialState: PatientsAdmissionState = adapter.getInitialState({
    isLoading: false,
    filter: '',
});
export const patientsAdmissionReducer = createReducer(
    initialState,
    on(PatientsAdmissionActions.loadAllAdmissionPatients, state => ({ ...state, isLoading: true })),
    on(PatientsAdmissionActions.loadAllAdmissionPatientsSuccess, (state, action) => {
        return adapter.setAll(action.patients, { ...state, error: null, isLoading: false });
    }),
    on(PatientsAdmissionActions.loadAllAdmissionPatientsFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(PatientsAdmissionActions.clearSearchPatientAdmission, state => ({
        ...adapter.removeAll(state),
        error: null,
        isLoading: false,
        filter: '',
    })),
    on(PatientsAdmissionActions.searchFilterChanged, (state, action) => ({ ...state, filter: action.searchQuery })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
