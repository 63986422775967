import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseDialogComponent } from '../abstract-dialog.component';

/**
 * Confirm dialog component
 */
@Component({
    selector: 'mona-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent extends BaseDialogComponent {
    /**
     * get img src string
     */
    get imgSrc(): string {
        return this.data.imgSrc || 'assets/images/question.svg';
    }
}
