/* eslint-disable @typescript-eslint/ban-ts-comment */
import { InjectionToken } from '@angular/core';
import { routerReducer } from '@ngrx/router-store';
import { Action, ActionReducerMap } from '@ngrx/store';
import { AppState } from '../state';
import { messageReducer } from './message.reducer';
import { uiReducer } from './ui.reducer';

/**
 * Feature ActionReducerMap definition
 */
export const coreReducerMap: ActionReducerMap<AppState> = {
    router: routerReducer,
    ui: uiReducer,
    messages: messageReducer,
};

export const CORE_REDUCER_MAP = new InjectionToken<ActionReducerMap<AppState, Action>>('CORE_REDUCER_MAP');
