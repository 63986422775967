import { SetOptional } from 'type-fest';
import { ApiModels } from '@mona/api';
import { ActiveShiftData } from './active-shift-data.model';

/**
 * Workflow questionnaire mode
 */
export enum WorkflowQuestionnaireMode {
    View = 'view',
    Edit = 'edit',
}

/**
 * Workflow
 */
export interface Workflow
    extends SetOptional<
        ApiModels.WorkflowQuestionnaireResponse.Model,
        'authorId' | 'lastChangedBy' | 'lastChangedAt' | 'encounterId' | 'patientId'
    > {
    /**
     * Indicates if entry is stage removed
     * FE only prop
     */
    isStageRemoved?: boolean;

    /**
     * Indicates if entry is changed
     * FE only prop
     */
    isChanged?: boolean;
}

/**
 * Create workflow metadata
 * @deprecated
 * @todo remove ?!
 */
export interface CreateWorkflowMetadata {
    /**
     * Questionnaire id
     */
    questionnaireId: string;

    /**
     * Type url
     */
    typeUrl: string;

    /**
     * Encounter id
     */
    encounterId: string;

    /**
     * Patient id
     */
    patientId: string;

    /**
     * Active shift data
     */
    activeShiftData: ActiveShiftData;
}
