import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Encounter, PageableModel, ParamsModel, VentilationParameter } from '@mona/models';
import { transformApiVentilationParameters } from './transforms';

const baseApi = `/pdms`;

/**
 * API abstraction layer for the Ventilation Parameters Api
 */
@Injectable({
    providedIn: 'root',
})
export class VentilationParametersApi {
    private readonly apiV1 = `${baseApi}/ventilation-parameters/`;
    private readonly apiV2 = `${baseApi}/v2/ventilation-parameters/`;

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get ventilation parameters
     *
     * @param encounterId EntityId<Encounter>
     */
    getVentilationParameters(encounterId: EntityId<Encounter>): Observable<VentilationParameter[]> {
        const params = {
            encounter_id: `${encounterId}`,
        };

        return this.http
            .get<ApiModels.PdmsVentilationParameter[]>(this.apiV1, {
                params,
            })
            .pipe(map(apiVentilationParameters => transformApiVentilationParameters(apiVentilationParameters)));
    }

    /**
     * Get Pageable Vital Signs
     *
     * @param params
     * @param url
     */
    getPageableVentilationParameters(
        params?: ParamsModel,
        url: string = this.apiV2,
    ): Observable<PageableModel<VentilationParameter>> {
        return this.http.get<PageableModel<ApiModels.PdmsVentilationParameter>>(url, { params }).pipe(
            map(({ results, ...rest }) => {
                return {
                    ...rest,
                    results: transformApiVentilationParameters(results),
                };
            }),
        );
    }
}
