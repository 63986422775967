import { createReducer, on } from '@ngrx/store';
import { CareReportsAction } from '../actions/care-reports.action';

/**
 * The initial state
 */
export const initialCareReportsEndDateState = null;

/**
 * Reducer for the set care reports end date action
 *
 * @param state Date
 * @param action action
 */
export const reduceSetCareReportsEndDate = (state: Date, action: { date: Date }): Date => {
    return action.date;
};

/**
 * Care reports end date reducer
 */
export const careReportsEndDateReducer = createReducer(
    initialCareReportsEndDateState,

    on(CareReportsAction.setEndDateAction, reduceSetCareReportsEndDate),
    on(CareReportsAction.setPersistedEntryControls, reduceSetCareReportsEndDate),
);
