import { NavigationExtras, Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';

/**
 * Action to navigate to a different route
 */
export const navigateAction = createAction(
    'ROUTER:NAVIGATE',
    props<{ path: any[]; query?: Params | null; extras?: NavigationExtras; force?: boolean }>(),
);
/**
 * Action to navigate back
 */
export const backAction = createAction('ROUTER:BACK');
/**
 * Action to navigate forward
 */
export const forwardAction = createAction('ROUTER:FORWARD');
