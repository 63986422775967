import { Component, Inject, LOCALE_ID } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BasicCareProcedure, Practitioner, Procedure } from '@mona/models';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { Async, AsyncComponent } from '@mona/shared/utils';
import { ProcedureDailogForm } from './models/procedure-form.model';

/**
 * Procedure dialog
 */
@Async()
@Component({
    selector: 'app-procedure-dialog',
    templateUrl: './procedure-dialog.component.html',
    styleUrls: ['./procedure-dialog.component.scss'],
})
// TODO: replace with BaseProcedureForm with sidenav editing
export class ProcedureDialogComponent extends AsyncComponent {
    /**
     * Procedure form
     */
    procedureForm = new FormGroup<ProcedureDailogForm>({
        responsibleNurseId: new FormControl<string>(null),
        durationMinutes: new FormControl<number | string>(''),
        note: new FormControl<string>(''),
    });

    /**
     * Possible duration options
     */
    durationsOptions = this.generateDurationOptions();

    /**
     * Practitioners dictionary
     */
    practitioners$: Observable<EntityMap<Practitioner>> = this.practitionersFacade.practitionersMap$;

    /**
     * Constructor
     *
     * @param translateService TranslateService
     * @param practitionersFacade PractitionersFacade
     * @param dialogRef MatDialogRef
     * @param data MAT_DIALOG_DATA
     * @param locale string
     */
    constructor(
        private translateService: TranslateService,
        private practitionersFacade: PractitionersFacade,
        private dialogRef: MatDialogRef<ProcedureDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            initialDuration: number;
            procedure: Partial<BasicCareProcedure> | Partial<Procedure>;
            description: string;
            instruction: string;
        },
        @Inject(LOCALE_ID) private locale: string,
    ) {
        super();

        dialogRef.addPanelClass('app-procedure-dialog');

        if (data.procedure) {
            this.procedureForm.patchValue({
                responsibleNurseId: data.procedure.responsibleNurseId as string,
                durationMinutes: data.procedure.durationMinutes || '',
                note: data.procedure.note,
            });
        } else {
            const initialDuration = data.initialDuration;
            if (initialDuration && !!this.durationsOptions.find(duration => duration.value === initialDuration)) {
                this.procedureForm.controls.durationMinutes.setValue(initialDuration);
            }
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        if (this.procedureForm.valid) {
            this.dialogRef.close(this.procedureForm.value);
        }
    }

    /**
     * Generates possible duration options
     */
    generateDurationOptions(): {
        value: number;
        name: string;
    }[] {
        return Array.from({ length: 30 }, (_, i) => i + 1).map(numberMinutes => {
            return {
                value: numberMinutes,
                name: `${numberMinutes} ${this.translateService.instant(
                    `apps.patient.careReports.procedureDialog.${numberMinutes === 1 ? 'minute' : 'minutes'}`,
                )}`,
            };
        });
    }
}
