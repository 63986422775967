import { createReducer, on } from '@ngrx/store';
import { HistoryEntryType } from '@mona/models';
import { EncounterAction } from '../actions/encounter.action';

/**
 * The initial state
 */
export const initialHistoryFilterState: HistoryEntryType = null;

/**
 * Reducer for the set history filter action
 *
 * @param state ChangeLog
 * @param action SucceededAction<ChangeLogEntry[]>
 */
export const reduceSetHistoryFilter = (
    state: HistoryEntryType,
    action: { historyType: HistoryEntryType },
): HistoryEntryType => {
    return action.historyType;
};

/**
 * Change log reducer
 */
export const historyFilterReducer = createReducer(
    initialHistoryFilterState,

    on(EncounterAction.setHistoryFilterAction, reduceSetHistoryFilter),
);
