/* eslint-disable @typescript-eslint/member-ordering, jsdoc/require-jsdoc */
import {
    animate,
    animateChild,
    AnimationTriggerMetadata,
    AUTO_STYLE,
    group,
    keyframes,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

/**
 * INFO: add comment
 */
export interface IAnimationOptions {
    anchor?: string;
    duration?: number;
    delay?: number;
}

/**
 * INFO: add comment
 */
export interface ICollapseAnimation extends IAnimationOptions {
    easeOnClose?: string;
    easeOnOpen?: string;
}

/**
 * Animations helper
 */
export class Animations {
    /**
     * Simple fade in animation
     */
    static fadeIn = trigger('fadeIn', [
        transition('void => *', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
    ]);

    /**
     * Simple fade in out animation
     */
    static fadeInOut = trigger('fadeInOut', [
        transition('void => *', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
        transition('* => void', [style({ opacity: 1 }), animate(300, style({ opacity: 0 }))]),
    ]);

    /**
     * Pulse animation
     *
     * Parameter Options:
     * duration: Duration the animation will run in milliseconds. Defaults to 500 ms.
     * delay: Delay before the animation will run in milliseconds. Defaults to 0 ms.
     * ease: Animation accelerate and decelerate style. Defaults to ease-out.
     *
     * Returns an [AnimationTriggerMetadata] object with boolean states for a pulse animation.
     *
     * usage: [@pulse]="{ value: true | false, params: { duration: 200 }}"
     */
    static pulseAnimation: AnimationTriggerMetadata = trigger('pulse', [
        state(
            '0',
            style({
                transform: 'scale3d(1, 1, 1)',
            }),
        ),
        state(
            '1',
            style({
                transform: 'scale3d(1, 1, 1)',
            }),
        ),
        transition(
            '0 <=> 1',
            [
                group([
                    query('@*', animateChild(), { optional: true }),
                    animate(
                        '{{ duration }}ms {{ delay }}ms {{ ease }}',
                        keyframes([
                            style({ transform: 'scale3d(1, 1, 1)', offset: 0 }),
                            style({ transform: 'scale3d(1.05, 1.05, 1.05)', offset: 0.5 }),
                            style({ transform: 'scale3d(1, 1, 1)', offset: 1.0 }),
                        ]),
                    ),
                ]),
            ],
            { params: { duration: 500, delay: '0', ease: 'ease-out' } },
        ),
    ]);

    /**
     * Creates fade animation between two routes
     *
     * @param firstRoute firstRoute
     * @param secondRoute secondRoute
     */
    static twoRoutesFadeAnimation(firstRoute: string, secondRoute: string): AnimationTriggerMetadata {
        return trigger('fadeAnimation', [
            transition(`${firstRoute} <=> ${secondRoute}`, [
                query(':enter, :leave', [
                    style({
                        position: 'absolute',
                        bottom: 0,
                    }),
                ]),

                query(':enter', [style({ opacity: 0 })], { optional: true }),

                query(':leave', [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))], { optional: true }),

                query(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))], { optional: true }),
            ]),
        ]);
    }

    /**
     * const tdCollapseAnimation
     *
     * Parameter Options:
     * duration: Duration the animation will run in milliseconds. Defaults to 150 ms.
     * delay: Delay before the animation will run in milliseconds. Defaults to 0 ms.
     * easeOnClose: Animation accelerates and decelerates when closing. Defaults to ease-in.
     * easeOnOpen: Animation accelerates and decelerates when opening. Defaults to ease-out.
     *
     * Returns an [AnimationTriggerMetadata] object with boolean states for a collapse/expand animation.
     *
     * usage: [@collapse]="{ value: true | false, params: { duration: 500 }}"
     */
    static collapseAnimation: AnimationTriggerMetadata = trigger('collapse', [
        state(
            '1',
            style({
                height: '0',
                // overflow: 'hidden',
            }),
        ),
        state(
            '0',
            style({
                height: AUTO_STYLE,
                // overflow: AUTO_STYLE,
            }),
        ),
        transition(
            '0 => 1',
            [
                style({
                    // overflow: 'hidden',
                    height: AUTO_STYLE,
                }),
                group([
                    query('@*', animateChild(), { optional: true }),
                    animate(
                        '{{ duration }}ms {{ delay }}ms {{ ease }}',
                        style({
                            height: '0',
                            // overflow: 'hidden',
                        }),
                    ),
                ]),
            ],
            { params: { duration: 150, delay: '0', ease: 'ease-in' } },
        ),
        transition(
            '1 => 0',
            [
                style({
                    height: '0',
                    // overflow: 'hidden',
                }),
                group([
                    query('@*', animateChild(), { optional: true }),
                    animate(
                        '{{ duration }}ms {{ delay }}ms {{ ease }}',
                        style({
                            // overflow: 'hidden',
                            height: AUTO_STYLE,
                        }),
                    ),
                ]),
            ],
            { params: { duration: 150, delay: '0', ease: 'ease-out' } },
        ),
    ]);
}
