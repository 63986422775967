import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UiButtonLoadingDirective } from './button-loading.directive';
import { UiButtonProgressDirective } from './button-progress.directive';

/**
 * Button Extension Module
 */
@NgModule({
    declarations: [UiButtonProgressDirective, UiButtonLoadingDirective],
    imports: [CommonModule, MatProgressSpinnerModule, MatButtonModule],
    exports: [UiButtonProgressDirective, UiButtonLoadingDirective],
})
export class UiButtonExtensionModule {}
