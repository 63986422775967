import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WARDS_DATA_REDUCERS_TOKEN, WardsEffects, BedsEffects, wardsDataReducer } from './state';

/**
 * Data access wards module
 */
@NgModule({
    imports: [
        StoreModule.forFeature(WARDS_DATA_REDUCERS_TOKEN, wardsDataReducer),
        EffectsModule.forFeature([WardsEffects, BedsEffects]),
    ],
})
export class DataAccessWardsModule {}
