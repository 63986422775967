import { Data, Route } from '@angular/router';
import { NgxPermissionsData } from 'ngx-permissions';

enum DrawerSizeEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}
// FIXME: re-place to correct folder
interface LayoutConfig {
    /**
     * Show left side navigation panel, aka `sidenav`
     */
    hasSidenav?: boolean;
    /**
     * Can collapse left side navigation panel, aka `sidenav`
     */
    collapsible?: boolean;
    /**
     * Show toolbar in left side navigation panel - title etc.
     */
    hasSidenavBar?: boolean;
    /**
     * Show application bar
     */
    hasAppbar?: boolean;
    /**
     * Show navigation bar
     */
    hasNavbar?: boolean;
    /**
     * Can navigate back in the platform's history
     *
     * Back icon location ('left' means left sidenav, 'right' means icon in the appBar, will show 'right' if no value provided)
     */
    canGoBack?: 'appBar' | 'navBar' | 'sideNav' | null;
    /**
     * Label
     */
    title?: string;
    /**
     * Sub title
     */
    subtitle?: string;
    /**
     * Size of title
     */
    titleSize?: number;
    /**
     * Size of left sidenav
     */
    size?: DrawerSizeEnum;
    /**
     * Background image for left sidenav
     */
    bgImageUrl?: string;
    /**
     * Sidenav items
     */
    items?: any[];
    /**
     * Navbar items
     */
    navbarItems?: any[];
    /**
     * Merge config with parent
     */
    mergeWithParent?: boolean;
}

type TerminalConfig = any;

/** Route With Data */
export interface RouteWithData extends Route {
    /**
     * Data with LayoutConfig
     */
    data?: Data & {
        layoutConfig?: LayoutConfig;
        permissions?: NgxPermissionsData;
        dialog?: {
            data?: AnyObject; // DialogData;
            config?: AnyObject; // MatDialogConfig;
        };
    };
    /**
     * An array of child `RouteWithData` objects that specifies a nested route configuration.
     */
    children?: RouteWithData[];
}

/**
 * 🛤️ Map of main navigation auxiliary outlets
 */
export const OUTLETS_INITIAL: Record<string, unknown> = { dialog: null, side: null, overlay: null } as const;

/**
 * Default redirect path
 */
export const DEFAULT_REDIRECT_PATH = '/auth/lock-screen';

/**
 * Map of main navigation routes
 */
export const ROUTES_MAP_BY_FEATURE: {
    [key: string]: string;
} = {
    DEVICE_SETUP: '/device/setup',
    TELEMEDICINE: '/telemedicine',
    SETTINGS: '/settings/device',
    PDMS: '/pdms',
    /** ⚠️ redirects to lockscreen & clear any open outlet(s) */
    LOCK_SCREEN: 'auth/lock-screen',
    UNAUTHORIZED: '/auth/error',
    /** ⚠️ redirects to lockscreen as outlet as overlay and keep any other open outlet(s) */
    // LOGOUT_OVERLAY: [{ outlets: { overlay: ['auth', 'lock-screen'] } }],
};

/**
 * Navigate to start page based on licenses
 *
 * @param {TerminalConfig} config - Terminal config
 */
export function getRedirectPath(config: { license: any; features: any }): string {
    // Setup has run, device is registered
    const { license, features } = config;
    let path = '/';

    if (license.pdms && features.pdms) {
        path = ROUTES_MAP_BY_FEATURE.PDMS;
    } else if (license.telemedicine && features.telemedicine) {
        path = ROUTES_MAP_BY_FEATURE.TELEMEDICINE;
    } else {
        path = ROUTES_MAP_BY_FEATURE.LOCK_SCREEN;
    }
    return path;
}

/** Default Navbar Items to be provided to layout */
export const DEFAULT_NAVBAR_ITEMS: LayoutConfig['navbarItems'] = [
    {
        title: 'apps.patient.nav.allPatients',
        link: '/pdms/wards',
        icon: 'apps',
        key: 'pdms',
    },
    {
        title: 'apps.patient.patientSearch.screenTitle',
        link: '/pdms/patients/review',
        svgIcon: 'patient-folder',
        key: 'pdms',
    },
    {
        title: 'apps.telemedicine.screenTitle',
        link: '/telemedicine',
        icon: 'call',
        key: 'telemedicine',
    },
    {
        link: '/settings',
        icon: 'settings',
        title: 'apps.settings.screenTitle',
        // badgeIcon: '!',
    },
    {
        svgIcon: 'exit-to-app',
        title: 'auth.actions.logout',
        event: 'logout' as any, // FIXME: enum causes test failing, LayoutEventType.LogOut,
    },
];
