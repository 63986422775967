/** only durations strings   */
export type DurationArg =
    | 'year'
    | 'years'
    | 'y'
    | 'month'
    | 'months'
    | 'M'
    | 'week'
    | 'weeks'
    | 'w'
    | 'day'
    | 'days'
    | 'd'
    | 'hour'
    | 'hours'
    | 'h'
    | 'minute'
    | 'minutes'
    | 'm'
    | 'second'
    | 'seconds'
    | 's'
    | 'millisecond'
    | 'milliseconds'
    | 'ms';

/** Day Period */
export const enum DayPeriod {
    AM = 'AM', // before midday, 0 - 11 in 24-hour format.
    PM = 'PM', // after midday, 12 - 23 in 24-hour format.
}

/** miliseconds in 1 day */
export const MLS_DAY = 24 * 60 * 60 * 1000;

/**
 * Entries interval enum
 */
export enum EntriesInterval {
    MINUTES_1 = 'MINUTES_1',
    MINUTES_5 = 'MINUTES_5',
    MINUTES_30 = 'MINUTES_30',
    HOUR_1 = 'HOUR_1',
    HOUR_6 = 'HOUR_6',
    DAY_1 = 'DAY_1',
}

/**
 * Entries interval as const
 */
export const enum ENTRIES_INTERVAL {
    MINUTES_5 = 'MINUTES_5',
    MINUTES_30 = 'MINUTES_30',
    HOUR_1 = 'HOUR_1',
    HOUR_6 = 'HOUR_6',
    DAY_1 = 'DAY_1',
}

/**
 * Entries interval as literal type
 */
export type EntriesIntervalNames = keyof typeof EntriesInterval;

/**
 * Moment duration inputs in relation to interval
 */
export const INTERVAL_TIMES_MAP: Map<EntriesIntervalNames, [number, DurationArg]> = new Map([
    [EntriesInterval.MINUTES_1, [1, 'minutes']],
    [EntriesInterval.MINUTES_5, [5, 'minutes']],
    [EntriesInterval.MINUTES_30, [30, 'minutes']],
    [EntriesInterval.HOUR_1, [1, 'hour']],
    [EntriesInterval.HOUR_6, [6, 'hours']],
    [EntriesInterval.DAY_1, [1, 'day']],
]);

/**
 * Moment duration intervals which defines additional cells after current time in table
 */
export const EXTENDED_INTERVAL_TIMES_MAP: Map<EntriesIntervalNames, [number, DurationArg]> = new Map([
    [EntriesInterval.MINUTES_5, [5 * 3, 'minutes']], // 4 cells in advance
    [EntriesInterval.MINUTES_30, [30 * 3, 'minutes']], // 4 cells in advance
    [EntriesInterval.HOUR_1, [1 * 3, 'hours']], // 4 cells in advance
    [EntriesInterval.HOUR_6, [6 * 2, 'hours']], // 3 cells in advance
    [EntriesInterval.DAY_1, [5, 'days']], // 6 cells in advance
]);

export const EntriesIntervalInMinutes: Record<EntriesInterval, number> = {
    [EntriesInterval.MINUTES_1]: 1,
    [EntriesInterval.MINUTES_5]: 5,
    [EntriesInterval.MINUTES_30]: 30,
    [EntriesInterval.HOUR_1]: 60,
    [EntriesInterval.HOUR_6]: 60 * 6,
    [EntriesInterval.DAY_1]: 60 * 24,
};

export const EntriesIntervalColumnsAdd: Record<EntriesInterval, number> = {
    [EntriesInterval.MINUTES_1]: 0,
    [EntriesInterval.MINUTES_5]: 4,
    [EntriesInterval.MINUTES_30]: 4,
    [EntriesInterval.HOUR_1]: 4,
    [EntriesInterval.HOUR_6]: 3,
    [EntriesInterval.DAY_1]: 6,
};
