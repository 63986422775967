import { ApiModels } from '@mona/api';
import { Allergy } from '@mona/models';

/**
 * Transforms API allergy
 * @param apiAllergy ApiModels.AllergyIntolerance
 */
export const transformApiAllergy = (apiAllergy: ApiModels.AllergyIntolerance): Allergy => {
    return ApiModels.AllergyIntolerance.DTO.toModel(apiAllergy);
};

/**
 * Transforms API allergies
 * @param apiAllergies ApiModels.AllergyIntolerance[]
 */
export const transformApiAllergies = (apiAllergies: ApiModels.AllergyIntolerance[]): Allergy[] => {
    return apiAllergies.map(apiDiagnosis => transformApiAllergy(apiDiagnosis));
};
