import { ComponentRef, Injectable } from '@angular/core';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { isBoolean } from '@mona/shared/utils';

/**
 * Based on Angular `DefaultRouteReuseStrategy`.
 * Reuses routes as long as their route config is the same OR until future route data has pattribute `noReuse: true`
 */
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
    routesToCache: string[] = ['balance', 'inputs', 'outputs', 'wards'];
    detachedTrees = new Map<string, DetachedRouteHandle & { componentRef: ComponentRef<any> }>();

    /**
     * Decides if the route should be stored
     *
     * @param route
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return this.routesToCache.indexOf(route.data['cache']) > -1;
    }

    /**
     * Store the information for the route we're destructing
     *
     * @param route
     * @param detachedTree
     */
    store(
        route: ActivatedRouteSnapshot,
        detachedTree: DetachedRouteHandle & { componentRef: ComponentRef<any> },
    ): void {
        this.detachedTrees.set(route.data['cache'], detachedTree);
    }

    /**
     * Return true if we have a stored route object for the next route
     *
     * @param route
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return this.detachedTrees.has(route.data['cache']);
    }
    /**
     * If we returned true in shouldAttach(), now return the actual route data for  restoration
     *
     * @param route
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.detachedTrees.get(route.data['cache']);
    }
    /**
     * Reuse the route if we're going to and from the same route
     *
     * @param future
     * @param curr
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (future.data && isBoolean(future.data['noReuse'])) {
            return !future.data.noReuse;
        }
        return future.routeConfig === curr.routeConfig;
    }
}
