<div class="mona-sign-in-welcome">
    <div class="mona-sign-in-welcome__title">{{ 'lockScreen.welcome.title' | translate }}</div>
    <div class="mona-sign-in-welcome__user">
        {{ user?.displayName }}
    </div>
    <div class="mona-sign-in-welcome__message">
        {{ 'lockScreen.welcome.message' | translate }}
    </div>

    <div class="mona-sign-in-welcome__rfid">
        <div class="mona-sign-in-welcome__rfid-trigger">
            <div *ngIf="isLoading; else scanIcon" class="mona-sign-in-welcome__rfid-spinner">
                <mat-spinner color="primary" diameter="64"></mat-spinner>
            </div>

            <ng-template #scanIcon>
                <mat-icon [inline]="true" class="mona-sign-in-welcome__rfid-icon">wifi_tethering</mat-icon>
            </ng-template>
        </div>
        <div class="mona-sign-in-welcome__rfid-message">
            {{ 'lockScreen.rfid.scanBadge' | translate }}
        </div>
    </div>
</div>
