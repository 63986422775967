// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for procedure list operations
 * @see #/components/schemas/Procedure - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Procedure {
    /**
     * A free-text comment.
     *
     */
    note?: string | null;
    /**
     * The status of the procedure.  * `not-done` - NOT_DONE * `completed` - COMPLETED
     *
     */
    status?: Procedure.StatusEnum;
    /**
     * The reason why the procedure not performed.
     *
     */
    status_reason?: string;
    /**
     * The identifier of a prescription this procedure is based on.
     * @dataFormat uuid
     */
    prescription_id: string;
    /**
     * The code of the medication category. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/category
     *
     */
    category: string;
    /**
     * The amount of minutes the procedure took to perform.
     * @dataFormat double
     */
    duration_minutes?: number;
    /**
     * The identifier of a practitioner that is set as the responsible nurse.
     * @dataFormat uuid
     */
    responsible_nurse_id: string;
    /**
     * Timestamp when the procedure was performed.
     * @dataFormat date-time
     */
    date: string;
    /**
     * The textual representation of the code.
     *
     */
    description: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Timestamp when this entity was last modified.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * Procedure unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Procedure
 */
export namespace Procedure {
    export type StatusEnum = 'not-done' | 'completed';
    export const StatusEnum = {
        NotDone: 'not-done' as StatusEnum,
        Completed: 'completed' as StatusEnum,
    };

    /**
     * Procedure Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * A free-text comment.
         * @type string (`dataFormat` is missing)
         */
        note?: string | null;
        /**
         * The status of the procedure.  * `not-done` - NOT_DONE * `completed` - COMPLETED
         * @type string (`dataFormat` is missing)
         */
        status?: Procedure.StatusEnum;
        /**
         * The reason why the procedure not performed.
         * @type string (`dataFormat` is missing)
         */
        statusReason?: string;
        /**
         * The identifier of a prescription this procedure is based on.
         * @dataFormat uuid
         */
        prescriptionId: string;
        /**
         * The code of the medication category. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/category
         * @type string (`dataFormat` is missing)
         */
        category: string;
        /**
         * The amount of minutes the procedure took to perform.
         * @dataFormat double
         */
        durationMinutes?: number;
        /**
         * The identifier of a practitioner that is set as the responsible nurse.
         * @dataFormat uuid
         */
        responsibleNurseId: string;
        /**
         * Timestamp when the procedure was performed.
         * @dataFormat date-time
         */
        @TransformDate() date: Date;
        /**
         * The textual representation of the code.
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Timestamp when this entity was last modified.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * Procedure unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Procedure DTO
     * Transforms Procedure model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Procedure model */
        static toModel(obj: Procedure): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Procedure model */
        static toApi(obj: Model): Procedure {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Procedure;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
