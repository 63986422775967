/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-rest-params */
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constructor } from 'type-fest';

/** WithTakeUntil */
export type WithTakeUntil = {
    /** obs */
    componentDestroy: undefined | (() => Observable<any>);
};

/**
 * Decorator to unsubscribe from observables when the component destroyed
 
 * @param constructor
 */
export function TakeUntilDestroy<T extends Constructor<{}>>(constructor: T): T & WithTakeUntil {
    const originalDestroy = constructor.prototype.ngOnDestroy;

    if (
        (typeof ngDevMode === 'undefined' || ngDevMode) &&
        typeof originalDestroy !== 'function' &&
        !constructor.name.toLowerCase().includes('base')
    ) {
        console.warn(`DEV:${constructor.name} is using @TakeUntilDestroy but does not implement OnDestroy`);
    }

    constructor.prototype.componentDestroy = function (): Observable<any> {
        this._takeUntilDestroy$ = this._takeUntilDestroy$ || new Subject();
        return this._takeUntilDestroy$.asObservable();
    };

    constructor.prototype.ngOnDestroy = function () {
        originalDestroy && typeof originalDestroy === 'function' && originalDestroy.apply(this, arguments);
        this._takeUntilDestroy$ && this._takeUntilDestroy$.next() && this._takeUntilDestroy$.complete();
    };

    return constructor as T & WithTakeUntil;
}

/**
 * A pipe-able operator to unsubscribe during OnDestroy lifecycle event
 *
 * @param component - The component class (`this` context)
 * @returns The component wrapped in an Observable
 * @example
 * source.pipe(takeUntilDestroy(this)).subscribe...
 */
export const takeUntilDestroy =
    <T>(component): ((source: Observable<T>) => Observable<T>) =>
    (source: Observable<T>) =>
        source.pipe(takeUntil(component.componentDestroy()));
