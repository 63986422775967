import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, ObservableInput } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * App init effects
 */
@Injectable()
export class InitEffects {
    /* Effect Declarations */

    /**
     * ROOT_EFFECTS_INIT handler
     */

    initializeStore$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            switchMap(action => this.onInitializeStore()),
        ),
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     */
    constructor(private actions$: Actions) {}

    /**
     * Effect handler called upon the Dispatcher.INIT action
     */
    onInitializeStore(): ObservableInput<Action> {
        return EMPTY;
    }
}
