import { createAction, props } from '@ngrx/store';
import { Theme } from '@mona/ui';

/**
 * Action to set the UI theme
 */
export const setTheme = createAction('UI:SET_THEME', props<{ theme: Theme }>());

/**
 * Action to toggle the UI theme
 */
export const toggleTheme = createAction('UI:TOGGLE_THEME');

/**
 * Action to set notifications side nav opening nav state
 *
 * @deprecated
 */
export const setIsSideNavOpened = createAction('UI:SET_IS_SIDE_NAV_OPENED', props<{ isOpened: boolean }>());

/**
 * Action to set is conversation overlay opened
 * TODO: remove from here ?
 */
export const setIsConversationOverlayOpened = createAction(
    'UI:SET_IS_CONVERSATION_OVERLAY_OPENED',
    props<{ isOpened: boolean }>(),
);
