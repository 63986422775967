import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import RecordRTC from 'recordrtc';
import { AppError, MEDIA_ERRORS, UtilsModule } from '@mona/shared/utils';
import { getRouteConfigForDialogComponent } from '@mona/ui/components';
import { MicButtonComponent } from '../mic-button';

/**
 * Sound dialog component
 */
@Component({
    standalone: true,
    selector: 'mona-sound-dialog',
    templateUrl: './sound-dialog.component.html',
    styleUrls: ['./sound-dialog.component.scss'],
    imports: [UtilsModule, MatDialogModule, MatButtonModule, MatIconModule, MicButtonComponent],
})
export class SoundDialogComponent implements OnInit, OnDestroy {
    /**
     * Holds a reference to the local audio element
     */
    @ViewChild('localAudio') localAudio: ElementRef<HTMLAudioElement>;

    /**
     * Stream for the microphone device
     */
    stream: MediaStream;

    /**
     * Media error
     */
    mediaError: AppError;

    /**
     * Is sound captured
     */
    isSoundCaptured: boolean;

    /**
     * Stream recorder
     */
    recorder: RecordRTC;

    /**
     * Recording options
     */
    recordingOptions: RecordRTC.Options = {
        type: 'audio',
        // Enforce support for wav files in chrome
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        checkForInactiveTracks: true,
        mimeType: 'audio/wav',
    };

    /**
     * Is recording
     */
    isRecording: boolean;

    /**
     * Stream constraints
     */
    private mediaConstraints: MediaStreamConstraints = {
        audio: {
            echoCancellation: false,
        },
        video: false,
    };

    /**
     * NG hook
     */
    ngOnInit(): void {
        this.captureMicrophone();
    }

    /**
     * NG hook
     */
    async ngOnDestroy(): Promise<void> {
        if (this.recorder) {
            await this.stopRecorder();
            this.destroyRecorder();
        }

        this.releaseMicrophone();
    }

    /**
     * Show error details
     */
    showErrorDetails(): void {
        // FIXME: after
        // this.errorDetailsDialogService.openDialog(this.mediaError);
    }

    /**
     * Toggles the recorder
     */
    toggleRecording() {
        if (this.isRecording) {
            this.stopRecording();
        } else {
            this.startRecording();
        }
    }

    /**
     * Starts recording
     */
    startRecording(): void {
        if (this.recorder) {
            this.destroyRecorder();
        }

        this.recorder = this.createRecorder();
        this.recorder.startRecording();

        this.isRecording = true;
    }

    /**
     * Stops recording
     */
    async stopRecording(): Promise<void> {
        if (!this.recorder) {
            this.isRecording = false;
            return;
        }

        await this.stopRecorder();
        this.localAudio.nativeElement.src = URL.createObjectURL(this.recorder.getBlob());
        this.isSoundCaptured = true;
        this.destroyRecorder();
        this.releaseMicrophone();
    }

    /**
     * Creates recorder
     */
    createRecorder(): RecordRTC {
        return new RecordRTC(this.stream, this.recordingOptions);
    }

    /**
     * Capture mic
     */
    private async captureMicrophone(): Promise<void> {
        try {
            const stream = await navigator.mediaDevices.getUserMedia(this.mediaConstraints);
            this.stream = stream;
        } catch (error) {
            this.mediaError = {
                errorCode: MEDIA_ERRORS.CAN_NOT_GET_MEDIA,
                originalError: error,
            };
            console.warn('Could not get user media', error);
        }
    }

    /**
     * Clear the recorder and it's buffer
     */
    private destroyRecorder() {
        this.recorder.destroy();
        this.recorder = null;
        this.isRecording = false;
    }

    /**
     * Deactivates the microphone
     */
    private releaseMicrophone() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
        }
    }

    /**
     * Stops current recorder
     */
    private stopRecorder(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.recorder.stopRecording(() => {
                return resolve();
            });
        });
    }
}

export const SOUND_DIALOG_ROUTES = getRouteConfigForDialogComponent(SoundDialogComponent, {
    config: {
        panelClass: 'mona-sound-dialog',
    },
});
