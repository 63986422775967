import { createAction, props } from '@ngrx/store';
import { Encounter, PreMedication } from '@mona/models';
import { AsyncAction, SucceededAction, FailedAction } from '@mona/store';

/**
 * Pre medications actions
 */
export class PreMedicationsAction {
    /**
     * Load pre medications action
     */
    static loadPreMedicationsAction: AsyncAction<
        {
            encounterId: EntityId<Encounter>;
        },
        PreMedication[]
    > = {
        action: createAction(
            'ENCOUNTER:LOAD_PRE_MEDICATIONS',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction(
            'ENCOUNTER:LOAD_PRE_MEDICATIONS_SUCCEEDED',
            props<SucceededAction<PreMedication[]>>(),
        ),

        failedAction: createAction('ENCOUNTER:LOAD_PRE_MEDICATIONS_FAILED', props<FailedAction>()),

        clearAction: createAction('ENCOUNTER:LOAD_PRE_MEDICATIONS_CLEAR'),
    };
}
