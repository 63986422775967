import { of } from 'rxjs';
import { DynamicPdmsPermissionEnum } from '@mona/models';
import { DrawerNavItem } from '@mona/ui';

/**
 * get encounter drawer menu items
 *
 * @param encounterId
 */
export const getEncounterDrawerMenuItems = (encounterId: string): DrawerNavItem[] => [
    {
        title: 'apps.patient.nav.items.overview',
        link: `pdms/encounter/${encounterId}/overview`,
        key: 'overview',
        icon: 'assignment_ind',
        hasMark: false,
        validationError$: of(false),
    },
    {
        title: 'apps.patient.tasklist.title',
        link: `pdms/encounter/${encounterId}/task-list`,
        key: 'taskList',
        icon: 'checklist',
        hasMark: false,
        checkPermission: DynamicPdmsPermissionEnum.TASK_LIST_ACCESS,
        counter$: of(null),
        counterColor: '#ff6f00',
    },
    {
        title: 'apps.patient.nav.items.vitalSigns',
        link: `pdms/encounter/${encounterId}/vital-signs`,
        key: 'vitalSigns',
        icon: 'show_chart',
        checkPermission: 'vital_signs_view',
        hasMark: false,
    },
    {
        title: 'apps.patient.nav.items.ventilation',
        link: `pdms/encounter/${encounterId}/ventilation`,
        svgIcon: 'air-filter',
        key: 'ventilation',
        checkPermission: 'ventilation_view',
        hasMark: false,
    },
    {
        title: 'apps.patient.nav.items.balance',
        link: `pdms/encounter/${encounterId}/balance`,
        key: 'balance',
        icon: 'opacity',
        checkPermission: DynamicPdmsPermissionEnum.INPUT_OUTPUTS_ACCESS,
        hasMark: false,
    },
    {
        title: 'apps.patient.nav.items.prescriptions',
        link: `pdms/encounter/${encounterId}/prescriptions`,
        key: 'prescriptions',
        icon: 'list',
        checkPermission: 'prescription_medication_view',
        hasMark: false,
    },
    {
        title: 'apps.patient.nav.items.labValues',
        link: `pdms/encounter/${encounterId}/lab-values`,
        svgIcon: 'flask-empty',
        key: 'labValues',
        checkPermission: 'lab_values_view',
        hasMark: false,
    },
    {
        title: 'apps.patient.nav.items.careReports',
        link: `pdms/encounter/${encounterId}/care-reports`,
        key: 'careReports',
        icon: 'assignment',
        checkPermission: DynamicPdmsPermissionEnum.CARE_PROCEDURES_ACCESS,
        hasMark: false,
    },
    {
        title: 'apps.patient.nav.items.treatmentInterventions',
        link: `pdms/encounter/${encounterId}/treatment-interventions`,
        key: 'treatmentInterventions',
        svgIcon: 'health-metrics',
        checkPermission: 'treatment_interventions_view',
        hasMark: false,
    },
    {
        title: 'apps.patient.nav.items.workflows',
        link: `pdms/encounter/${encounterId}/workflows`,
        svgIcon: 'order-bool-ascending-variant',
        key: 'workflows',
        checkPermission: 'workflows_view',
        hasMark: false,
    },
    {
        title: 'apps.patient.externalResources.pageTitle',
        link: `pdms/encounter/${encounterId}/external-resources`,
        svgIcon: 'open-in-new',
        key: 'externalResources',
        hasMark: false,
        hidden: true,
    },
];
