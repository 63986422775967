/**
 * Lookup List for common hex colors
 */
export const colorCodes = {
    common: {
        black: '#000000',
        white: '#ffffff',
    },
    material: {
        red: {
            h50: '#FFEBEE',
            h100: '#FFCDD2',
            h200: '#EF9A9A',
            h300: '#E57373',
            h400: '#EF5350',
            h500: '#F44336',
            h600: '#E53935',
            h700: '#D32F2F',
            h800: '#C62828',
            h900: '#B71C1C',
            a100: '#FF8A80',
            a200: '#FF5252',
            a400: '#FF1744',
            a700: '#D50000',
        },
        pink: {
            h50: '#FCE4EC',
            h100: '#F8BBD0',
            h200: '#F48FB1',
            h300: '#F06292',
            h400: '#EC407A',
            h500: '#E91E63',
            h600: '#D81B60',
            h700: '#C2185B',
            h800: '#AD1457',
            h900: '#880E4F',
            a100: '#FF80AB',
            a200: '#FF4081',
            a400: '#F50057',
            a700: '#C51162',
        },
        purple: {
            h50: '#F3E5F5',
            h100: '#E1BEE7',
            h200: '#CE93D8',
            h300: '#BA68C8',
            h400: '#AB47BC',
            h500: '#9C27B0',
            h600: '#8E24AA',
            h700: '#7B1FA2',
            h800: '#6A1B9A',
            h900: '#4A148C',
            a100: '#EA80FC',
            a200: '#E040FB',
            a400: '#D500F9',
            a700: '#AA00FF',
        },
        deepPurple: {
            h50: '#EDE7F6',
            h100: '#D1C4E9',
            h200: '#B39DDB',
            h300: '#9575CD',
            h400: '#7E57C2',
            h500: '#673AB7',
            h600: '#5E35B1',
            h700: '#512DA8',
            h800: '#4527A0',
            h900: '#311B92',
            a100: '#B388FF',
            a200: '#7C4DFF',
            a400: '#651FFF',
            a700: '#6200EA',
        },
        indigo: {
            h50: '#E8EAF6',
            h100: '#C5CAE9',
            h200: '#9FA8DA',
            h300: '#7986CB',
            h400: '#5C6BC0',
            h500: '#3F51B5',
            h600: '#3949AB',
            h700: '#303F9F',
            h800: '#283593',
            h900: '#1A237E',
            a100: '#8C9EFF',
            a200: '#536DFE',
            a400: '#3D5AFE',
            a700: '#304FFE',
        },
        blue: {
            h50: '#E3F2FD',
            h100: '#BBDEFB',
            h200: '#90CAF9',
            h300: '#64B5F6',
            h400: '#42A5F5',
            h500: '#2196F3',
            h600: '#1E88E5',
            h700: '#1976D2',
            h800: '#1565C0',
            h900: '#0D47A1',
            a100: '#82B1FF',
            a200: '#448AFF',
            a400: '#2979FF',
            a700: '#2962FF',
        },
        lightBlue: {
            h50: '#E1F5FE',
            h100: '#B3E5FC',
            h200: '#81D4FA',
            h300: '#4FC3F7',
            h400: '#29B6F6',
            h500: '#03A9F4',
            h600: '#039BE5',
            h700: '#0288D1',
            h800: '#0277BD',
            h900: '#01579B',
            a100: '#80D8FF',
            a200: '#40C4FF',
            a400: '#00B0FF',
            a700: '#0091EA',
        },
        cyan: {
            h50: '#E0F7FA',
            h100: '#B2EBF2',
            h200: '#80DEEA',
            h300: '#4DD0E1',
            h400: '#26C6DA',
            h500: '#00BCD4',
            h600: '#00ACC1',
            h700: '#0097A7',
            h800: '#00838F',
            h900: '#006064',
            a100: '#84FFFF',
            a200: '#18FFFF',
            a400: '#00E5FF',
            a700: '#00B8D4',
        },
        teal: {
            h50: '#E0F2F1',
            h100: '#B2DFDB',
            h200: '#80CBC4',
            h300: '#4DB6AC',
            h400: '#26A69A',
            h500: '#009688',
            h600: '#00897B',
            h700: '#00796B',
            h800: '#00695C',
            h900: '#004D40',
            a100: '#A7FFEB',
            a200: '#64FFDA',
            a400: '#1DE9B6',
            a700: '#00BFA5',
        },
        green: {
            h50: '#E8F5E9',
            h100: '#C8E6C9',
            h200: '#A5D6A7',
            h300: '#81C784',
            h400: '#66BB6A',
            h500: '#4CAF50',
            h600: '#43A047',
            h700: '#388E3C',
            h800: '#2E7D32',
            h900: '#1B5E20',
            a100: '#B9F6CA',
            a200: '#69F0AE',
            a400: '#00E676',
            a700: '#00C853',
        },
        lightGreen: {
            h50: '#F1F8E9',
            h100: '#DCEDC8',
            h200: '#C5E1A5',
            h300: '#AED581',
            h400: '#9CCC65',
            h500: '#8BC34A',
            h600: '#7CB342',
            h700: '#689F38',
            h800: '#558B2F',
            h900: '#33691E',
            a100: '#CCFF90',
            a200: '#B2FF59',
            a400: '#76FF03',
            a700: '#64DD17',
        },
        lime: {
            h50: '#F9FBE7',
            h100: '#F0F4C3',
            h200: '#E6EE9C',
            h300: '#DCE775',
            h400: '#D4E157',
            h500: '#CDDC39',
            h600: '#C0CA33',
            h700: '#AFB42B',
            h800: '#9E9D24',
            h900: '#827717',
            a100: '#F4FF81',
            a200: '#EEFF41',
            a400: '#C6FF00',
            a700: '#AEEA00',
        },
        yellow: {
            h50: '#FFFDE7',
            h100: '#FFF9C4',
            h200: '#FFF59D',
            h300: '#FFF176',
            h400: '#FFEE58',
            h500: '#FFEB3B',
            h600: '#FDD835',
            h700: '#FBC02D',
            h800: '#F9A825',
            h900: '#F57F17',
            a100: '#FFFF8D',
            a200: '#FFFF00',
            a400: '#FFEA00',
            a700: '#FFD600',
        },
        amber: {
            h50: '#FFF8E1',
            h100: '#FFECB3',
            h200: '#FFE082',
            h300: '#FFD54F',
            h400: '#FFCA28',
            h500: '#FFC107',
            h600: '#FFB300',
            h700: '#FFA000',
            h800: '#FF8F00',
            h900: '#FF6F00',
            a100: '#FFE57F',
            a200: '#FFD740',
            a400: '#FFC400',
            a700: '#FFAB00',
        },
        orange: {
            h50: '#FFF3E0',
            h100: '#FFE0B2',
            h200: '#FFCC80',
            h300: '#FFB74D',
            h400: '#FFA726',
            h500: '#FF9800',
            h600: '#FB8C00',
            h700: '#F57C00',
            h800: '#EF6C00',
            h900: '#E65100',
            a100: '#FFD180',
            a200: '#FFAB40',
            a400: '#FF9100',
            a700: '#FF6D00',
        },
        deepOrange: {
            h50: '#FBE9E7',
            h100: '#FFCCBC',
            h200: '#FFAB91',
            h300: '#FF8A65',
            h400: '#FF7043',
            h500: '#FF5722',
            h600: '#F4511E',
            h700: '#E64A19',
            h800: '#D84315',
            h900: '#BF360C',
            a100: '#FF9E80',
            a200: '#FF6E40',
            a400: '#FF3D00',
            a700: '#DD2C00',
        },
        brown: {
            h50: '#EFEBE9',
            h100: '#D7CCC8',
            h200: '#BCAAA4',
            h300: '#A1887F',
            h400: '#8D6E63',
            h500: '#795548',
            h600: '#6D4C41',
            h700: '#5D4037',
            h800: '#4E342E',
            h900: '#3E2723',
        },
        gray: {
            h50: '#FAFAFA',
            h100: '#F5F5F5',
            h200: '#EEEEEE',
            h300: '#E0E0E0',
            h400: '#BDBDBD',
            h500: '#9E9E9E',
            h600: '#757575',
            h700: '#616161',
            h800: '#424242',
            h900: '#212121',
        },
        blueGray: {
            h50: '#ECEFF1',
            h100: '#CFD8DC',
            h200: '#B0BEC5',
            h300: '#90A4AE',
            h400: '#78909C',
            h500: '#607D8B',
            h600: '#546E7A',
            h700: '#455A64',
            h800: '#37474F',
            h900: '#263238',
        },
    },
};

/**
 * Colors selection for lab value types
 */
export const labValueColors = [
    colorCodes.material.red.h900,
    colorCodes.material.green.h500,
    colorCodes.material.yellow.h600,
    colorCodes.material.lightBlue.h700,
    colorCodes.material.orange.h600,
    colorCodes.material.deepPurple.h400,
    colorCodes.material.pink.h900,
    colorCodes.material.indigo.h900,
    colorCodes.material.blue.h900,
    colorCodes.material.cyan.h700,
    colorCodes.material.teal.h900,
    colorCodes.material.lightGreen.h900,
    colorCodes.material.lime.h900,
    colorCodes.material.amber.h900,
    colorCodes.material.deepOrange.h900,
    colorCodes.material.brown.h500,
    colorCodes.material.gray.h600,
    colorCodes.material.blueGray.h600,
];

/**
 * Colors selection for ventilation parameters types
 */
export const ventilationParametersColors = [
    colorCodes.material.red.h900,
    colorCodes.material.green.h500,
    colorCodes.material.yellow.h600,
    colorCodes.material.lightBlue.h700,
    colorCodes.material.orange.h600,
    colorCodes.material.deepPurple.h400,
    colorCodes.material.pink.h900,
    colorCodes.material.indigo.h900,
    colorCodes.material.blue.h900,
    colorCodes.material.cyan.h700,
    colorCodes.material.teal.h900,
    colorCodes.material.lightGreen.h900,
    colorCodes.material.lime.h900,
    colorCodes.material.amber.h900,
    colorCodes.material.deepOrange.h900,
    colorCodes.material.brown.h500,
    colorCodes.material.gray.h600,
    colorCodes.material.blueGray.h600,
];

/**
 * Balance chart colors
 */
export const balanceChartColors = {
    target: colorCodes.material.pink.h500,
    current: colorCodes.material.orange.h800,
    total: colorCodes.material.deepPurple.h800,
};
