import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UtilsModule } from '@mona/shared/utils';
import { ErrorPageRoutingModule } from './error-page-routing.module';
import { ErrorPageComponent } from './error-page.component';

/**
 * ErrorPage Module
 */
@NgModule({
    declarations: [ErrorPageComponent],
    imports: [UtilsModule, ErrorPageRoutingModule, MatButtonModule],
    exports: [ErrorPageComponent],
})
export class ErrorPageModule {}
