import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { UtilsModule } from '@mona/shared/utils';
import { UiSpacerModule } from '../utils';
import { InfoListItemComponent } from './info-list-item.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [InfoListItemComponent],
    imports: [UtilsModule, UiSpacerModule, MatIconModule, MatListModule, MatDividerModule],
    exports: [InfoListItemComponent, MatIconModule, MatListModule, MatDividerModule],
})
export class UiInfoListItemModule {}
