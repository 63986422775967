/* eslint-disable @angular-eslint/directive-selector */
import { Directive, EventEmitter, Input, Optional, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { identity, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { Logger } from '@mona/shared/logger';
import { compareDeepEqual, isEmpty, isFormGroupDirective, notEmpty, patchFormGroupValue } from '@mona/shared/utils';
import { FormActions } from '../actions';

/**
 * Directive to connect Angular Forms to `@ngrx/store`
 *
 * @tutorial https://netbasal.com/connect-angular-forms-to-ngrx-store-c495d17e129
 */
@Directive({
    selector: '[form2store]',
    standalone: true,
    providers: [Actions],
})
export class Form2StoreDirective implements OnInit, OnDestroy {
    @Input('form2store') path = '';
    /** private reference to correct parent FormGroup */
    private _formGroup: FormGroup;
    /** FormGroup */
    get formGroup(): FormGroup {
        return this._formGroup;
    }

    @Input() set formGroup(value: FormGroup) {
        this._formGroup = value;
    }

    @Input() debounce = 300;
    @Input() updateFromStoreOnce = true;

    @Output() formError = new EventEmitter();
    @Output() formSuccess = new EventEmitter();

    private logger = new Logger('UI:FORM');
    private destroy$: Subject<boolean> = new Subject();

    /**
     * Constructor
     *
     * @param controlContainer
     * @param actions$
     * @param store
     */
    constructor(
        @Optional() private controlContainer: ControlContainer,
        private actions$: Actions,
        private store: Store<any>,
    ) {
        // try to get correct parent FormGroup from ControlContainer instance
        if (isFormGroupDirective(controlContainer)) {
            this._formGroup = controlContainer.form;
        }
    }

    /**
     * Subscribe to form value changes on init
     */
    ngOnInit(): void {
        const pathKeys = this.path.split('.');

        if (!pathKeys.length || !this.formGroup) {
            this.logger.warn('Form2StoreDirective: no path or formGroup provided');
            return;
        }

        // always sync 1st time to store any existing/empty value
        this.store.dispatch(
            FormActions.formUpdateAction({
                value: this.formGroup.getRawValue(),
                path: this.path,
            }),
        );

        this.store
            .select(...(pathKeys as [string])) // cast to tuple
            .pipe(
                this.updateFromStoreOnce ? take(1) : identity,
                filter(
                    stateValue => !isEmpty(stateValue) && !compareDeepEqual(stateValue, this.formGroup.getRawValue()),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(formValue => {
                patchFormGroupValue(this.formGroup, formValue, {
                    onlyExistingFields: true,
                    onlySelf: true,
                    emitEvent: false,
                });
                this.logger.log('Form2StoreDirective: patchFormGroupValue', this._formGroup.getRawValue());
            });

        this.formGroup.valueChanges
            .pipe(debounceTime(this.debounce), distinctUntilChanged(compareDeepEqual), takeUntil(this.destroy$))
            .subscribe(value => {
                this.store.dispatch(
                    FormActions.formUpdateAction({
                        value,
                        path: this.path,
                    }),
                );
            });

        this.actions$
            .pipe(
                ofType(FormActions.formSuccessAction),
                filter(({ path }) => path === this.path),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.formGroup.reset();
                this.formGroup.markAsPristine();
                this.formSuccess.emit();
            });

        this.actions$
            .pipe(
                ofType(FormActions.formResetAction),
                filter(({ path }) => path === this.path),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.formGroup.reset();
                this.formGroup.markAsPristine();
            });

        this.actions$
            .pipe(
                ofType(FormActions.formErrorAction),
                filter(({ path }) => path === this.path),
                takeUntil(this.destroy$),
            )
            .subscribe(({ path, error }) => this.formError.emit(error));
    }

    // eslint-disable-next-line jsdoc/require-jsdoc
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
