/* eslint-disable @angular-eslint/no-output-rename */
import { DOCUMENT } from '@angular/common';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    Output,
    QueryList,
    Renderer2,
} from '@angular/core';
import { takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { Animations } from '../../../../animations';
import { UiMessageType } from '../../models';
import { MessageService } from '../../services';
import { UiMessageComponent } from '../message';

/**
 * UiAlerts Component
 */
@TakeUntilDestroy
@Component({
    selector: 'ui-alerts',
    template: `
        <mat-toolbar
            class="ui-alerts-toolbar mat-elevation-z4"
            [ngClass]="'ui-alerts--' + currentAlertType"
            [color]="undefined"
            [@collapse]="{ value: !hasAlerts, params: { duration: 250 } }"
            *ngIf="count > 0 as hasAlerts"
        >
            <ui-alerts-pager [uiCurrentAlertIndex]="currentAlertIndex"></ui-alerts-pager>
            <ng-content select="ui-message"></ng-content>
        </mat-toolbar>
    `,
    animations: [Animations.collapseAnimation],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAlertsComponent implements AfterContentInit, OnDestroy {
    /**
     * Binding host class
     */
    @HostBinding('class.ui-alerts') cmpClass = true;
    /**
     * All alerts as QueryList
     */
    @ContentChildren(UiMessageComponent)
    set allAlerts(value: QueryList<UiMessageComponent>) {
        this.service.manage(value); // provide alerts
    }

    /**
     * Input/Output to support two way binding on current alert index
     */
    @Input('uiCurrentAlertIndex')
    set _inputCurrentIndex(index: number) {
        if (Number.isInteger(index) && index >= 0) {
            this.service.currentIndex = index;
        }
    }

    @Output('uiCurrentAlertIndexChange') currentAlertIndexChange = new EventEmitter<number>(false);

    set currentAlertIndex(index: number) {
        this.service.currentIndex = index;
    }
    /**
     * Current alert index
     */
    get currentAlertIndex() {
        return this.service.currentIndex;
    }

    @Input('uiCurrentAlert')
    set currentAlert(alert: UiMessageComponent) {
        if (alert) {
            this.service.currentAlert = alert;
        }
    }
    /**
     * Input/Output to support two way binding on current alert instance
     */
    get currentAlert() {
        return this.service.currentAlert;
    }

    @Output('uiCurrentAlertChange') currentAlertChange = new EventEmitter<UiMessageComponent>(false);

    /**
     * Ensure we are only dealing with alerts that have not been closed yet
     */
    get alerts() {
        return this.allAlerts?.filter(alert => {
            return alert.hidden === false;
        });
    }

    /**
     * Alerts count
     */
    get count(): number {
        return this.service.totalAlerts;
    }

    /**
     * Current alert type
     */
    get currentAlertType(): UiMessageType {
        if (this.service.currentAlert) {
            return this.service.currentAlert.type;
        }
        return undefined;
    }

    /**
     * Creates an instance of UiAlertsComponent.
     *
     * @param {MessageService} service
     */
    constructor(public readonly service: MessageService) {}

    /**
     * Subscribe to changes in lifecicle
     */
    ngAfterContentInit() {
        this.service.changes.pipe(takeUntilDestroy(this)).subscribe(index => {
            this.currentAlertIndexChange.next(index);
            this.currentAlertChange.next(this.service.currentAlert);
        });
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, jsdoc/require-jsdoc, @typescript-eslint/no-empty-function
    ngOnDestroy(): void {}
}
