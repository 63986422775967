/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Logger } from '@mona/shared/logger';
import { MonaFeatureAndPlatform, FEATURE_FLAGS, FeatureFlagsService } from './feature-flags.service';

/**
 * FeatureFlag Directive
 */
@Directive({
    selector: '[ifFeatureFlag]',
    // standalone: true,
})
export class FeatureFlagsDirective implements OnInit {
    @Input() ifFeatureFlag!: MonaFeatureAndPlatform;
    @Input() ifFeatureFlagElse?: TemplateRef<unknown>;

    private readonly logger = new Logger();

    /**
     * Constructor
     *
     * @param featureFlagService
     * @param templateRef
     * @param viewContainerRef
     */
    constructor(
        @Inject(FEATURE_FLAGS) private featureFlagService: FeatureFlagsService,
        private templateRef: TemplateRef<unknown>,
        private viewContainerRef: ViewContainerRef,
    ) {}

    /**
     * Initialize structural template
     */
    ngOnInit() {
        try {
            const featureFlag = this.featureFlagService.check(this.ifFeatureFlag);
            featureFlag ? this.onIf() : this.onElse();
        } catch (error) {
            this.onElse();
            this.logger.error(error);
        }
    }

    private onIf(): void {
        this.createView(this.templateRef);
    }

    private onElse(): void {
        if (!this.ifFeatureFlagElse) {
            return;
        }

        this.createView(this.ifFeatureFlagElse);
    }

    private createView(templateRef: TemplateRef<unknown>): void {
        this.viewContainerRef.createEmbeddedView(templateRef);
    }
}
