import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ForModule } from '@rx-angular/template/for';
import { IfModule } from '@rx-angular/template/if';
import { LetModule } from '@rx-angular/template/let';
import { PushModule } from '@rx-angular/template/push';
import { UnpatchModule } from '@rx-angular/template/unpatch';
import { PipesModule } from './pipes';

const UTILS_MODULES = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // 3rd-party
    TranslateModule,
    IfModule,
    LetModule,
    ForModule,
    PushModule,
    UnpatchModule,
    // Custom
    PipesModule,
];

/**
 * UtilsModule
 */
@NgModule({
    exports: UTILS_MODULES,
})
export class UtilsModule {}
