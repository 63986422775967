import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EncountersActions } from '@mona/pdms/data-access-encounters'; // eslint-disable-line
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ConfigService } from '@mona/config';
import { RouterActions } from '@mona/store';
import { BedsApi } from '../../infrastructure';
import { BedsActions } from '../actions';
import { selectAllWards } from '../selectors';

/**
 * Wards effects
 */
@Injectable()
export class BedsEffects {
    /** Load beds effect */
    loadBeds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BedsActions.load),
            exhaustMap(action =>
                this.bedsApi.getBeds(action.wardId).pipe(
                    map(beds => BedsActions.loadSuccess({ beds })),
                    catchError(error =>
                        of(BedsActions.loadFailure({ error: error.error?.error || error.error || error })),
                    ),
                ),
            ),
        ),
    );

    /** Load beds success effect - Select assigned bed id after all load */
    loadBedsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BedsActions.loadSuccess),
            withLatestFrom(this.store.select(selectAllWards)),
            filter(([, wards]) => !!wards.length),
            map(() => {
                const bedId = this.configService.get('bedId');
                return BedsActions.setAssignedBedId({ bedId });
            }),
        );
    });

    /**
     * Navigate to encounter or admission when bed was selected
     *
     * @memberof BedsEffects
     */
    selectBed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BedsActions.selectBed),
            filter(action => !!action.bed),
            switchMap(({ bed, isReviewMode, fromEncounter }) => {
                const { encounter } = bed;
                if (encounter) {
                    return [
                        EncountersActions.selectEncounter({ encounterId: encounter.id }),
                        RouterActions.navigateAction({
                            path: ['pdms/encounter', encounter.id],
                            query: {
                                fromEncounter,
                                forceReload: isReviewMode,
                            },
                            extras: null,
                        }),
                    ];
                } else {
                    return [
                        RouterActions.navigateAction({
                            path: ['pdms/patients/search'],
                            query: {
                                wardId: bed.wardId,
                                bedId: bed.id,
                            },
                        }),
                    ];
                }
            }),
        );
    });

    /**
     * Constructor
     *
     * @param {Actions} actions$ - actions
     * @param {WardsApi} bedsApi - beds api
     * @param store Store<any>
     * @param configService
     */
    constructor(
        private actions$: Actions,
        private bedsApi: BedsApi,
        private store: Store<any>,
        private configService: ConfigService,
    ) {}
}
