import { Component, TemplateRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * INFO: add comment
 */
@Component({
    selector: 'ui-dynamic-date-time-picker',
    templateUrl: './ui-dynamic-date-time-picker.component.html',
    styleUrls: ['./ui-dynamic-date-time-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UiDynamicDateTimePickerComponent {
    control: UntypedFormControl;

    timeControl: UntypedFormControl = new UntypedFormControl();

    label = '';

    timeLabel = '';

    hint = '';

    name = '';

    type: string = undefined;

    required: boolean = undefined;

    min: Date = undefined;

    max: Date = undefined;

    errorMessageTemplate: TemplateRef<never> = undefined;

    placeholder = '';

    customClass = '';

    touchUi = false;
}
