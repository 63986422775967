import { FormControl, FormGroup } from '@angular/forms';

/**
 * Blood controls
 */
export type BloodControlsForm = {
    bloodBatchNumber: FormControl<string>;
    bloodAdministrationReason: FormControl<string>;
};

/**
 * Blood controls
 */
export type BloodControls = {
    bloodBatchNumber: string;
    bloodAdministrationReason: string;
};

/** Build blood controls */
export const buildBloodControls = (): FormGroup<BloodControlsForm> => {
    return new FormGroup<any>({
        bloodBatchNumber: new FormControl<string>(null),
        bloodAdministrationReason: new FormControl<string>(null),
    });
};
