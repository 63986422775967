import { addMinutes, endOfToday, isToday, isTomorrow, subMilliseconds, addHours } from 'date-fns';
import {
    EntriesInterval,
    EntriesIntervalInMinutes,
    TimeColumn,
    getDaylightSavingSwitchDates,
    roundToNearestInterval,
} from '@mona/shared/date';

/**
 * get new end to calculate columns for the future just for preview
 *
 * @param dateRange
 * @param selectedInterval
 * @returns
 */
export function getColumnsIntervalForDateRange(dateRange: Interval, selectedInterval: EntriesInterval): Interval {
    const start = dateRange.start;
    let end = roundToNearestInterval(dateRange.end as Date, selectedInterval);

    const { end: endOfSummerTimezone } = getDaylightSavingSwitchDates(new Date().getFullYear());

    if (isToday(subMilliseconds(dateRange.end, 1))) {
        let endOfTodayRange = new Date();

        const isSummerToWinterChange =
            endOfSummerTimezone.getMonth() === end.getMonth() &&
            endOfSummerTimezone.getDate() === end.getDate() &&
            endOfSummerTimezone.getHours() === end.getHours();

        // In order to always show all time of dst change hour
        if (isSummerToWinterChange) {
            endOfTodayRange = addHours(endOfSummerTimezone, 2);
        }

        const withAddedColumns = addMinutes(
            roundToNearestInterval(endOfTodayRange, selectedInterval),
            6 * EntriesIntervalInMinutes[selectedInterval],
        );

        if (isTomorrow(withAddedColumns)) {
            end = addMinutes(
                roundToNearestInterval(endOfToday() as Date, selectedInterval),
                EntriesIntervalInMinutes[selectedInterval],
            );
        } else {
            end = withAddedColumns;
        }
    }

    return { start, end };
}

/**
 * slice table columns based on date range(pagination interval)
 * and selected interval included
 *
 * @param cols
 * @param dateRange
 * @param selectedInterval
 */
export function sliceColumsBasedOnDateRangeAndInterval(
    cols: TimeColumn[],
    dateRange: Interval,
    selectedInterval: EntriesInterval,
): TimeColumn[] {
    const { start, end } = getColumnsIntervalForDateRange(dateRange, selectedInterval);

    let startIdx = -1;
    let endIdx = -1;
    const startIso = (start as Date).toISOString();
    const endIso = (end as Date).toISOString();

    for (let i = cols.length - 1; i >= 0; i--) {
        if (cols[i]?.isoDateString === startIso) {
            startIdx = i;
        }

        if (cols[i]?.isoDateString === endIso) {
            endIdx = i;
        }

        if (startIdx !== -1 && endIdx !== -1) {
            break;
        }
    }

    return cols.slice(startIdx, endIdx);
}
