import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UiLoadingOverlayComponent } from './loading-overlay.component';
import { UiLoadingOverlayDirective } from './loading-overlay.directive';

/** UiLoadingOverlayModule  */
@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    declarations: [UiLoadingOverlayComponent, UiLoadingOverlayDirective],
    exports: [UiLoadingOverlayDirective],
})
export class UiLoadingOverlayModule {}
