import { SelectionModel } from '@angular/cdk/collections';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import {
    BaseMedication,
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationDosageForm,
    MedicationGroup,
    MedicationSolution,
    MedicationUnit,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
} from '@mona/models';
import { PrescriptionTerminologyGetter, PRESCRIPTION_TERMINOLOGY_GETTER } from '@mona/pdms/data-access-combined';
import { isEmpty, TakeUntilDestroy, takeUntilDestroy } from '@mona/shared/utils';
import { FilterPredicateFn, UiTableDataSource } from '@mona/ui/components/table';

/**
 * @ignore
 */
export const filterPredicate: FilterPredicateFn<BaseMedication> = (data, query) => {
    return data?.medication.displayName.toLowerCase().includes(query.trim().toLowerCase());
};

/**
 * MedicationListComponent
 */
@TakeUntilDestroy
@Component({
    selector: 'mona-medication-list',
    templateUrl: './medication-list.component.html',
    styleUrls: ['./medication-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class MedicationListComponent implements OnInit, OnDestroy {
    /** Selection of medications */
    readonly selection = new SelectionModel<BaseMedication>();
    /** Component class */
    @HostBinding('class') cmpClass = 'mona-medication-list';
    /** Medication item datasource */
    dataSource!: UiTableDataSource<BaseMedication>;
    /** Deleted medications set */
    @Input() deletedMedicationsSet: Set<string>;
    /** Show as list */
    @Input() showAsList = false;
    /** Show edit */
    @Input() showEdit = true;
    /** Row clickable */
    @Input() selectable = false;
    /** Show no data */
    @Input() showTableHeader = true;
    /** Show delete */
    @Input() showDelete = true;
    /** Show no data */
    @Input() showNoData = true;
    /** Is loading */
    @Input() isLoading?: boolean;
    /** No data message block config */
    @Input() noDataConfig = {
        title: 'apps.settings.medications.standardMedicationSets.emptyTitle',
        subTitle: 'apps.settings.medications.standardMedicationSets.emptyDescription',
        ctaTitle: 'apps.settings.medications.standardMedicationSets.add',
    };
    /** Selected medication setter, used also to clear selection */
    @Input() set selectedItem(medication: BaseMedication) {
        if (isEmpty(medication)) {
            this.selection.clear();
        } else {
            this.selection.select(medication);
        }
    }
    /** Select medication event */
    @Output() readonly selectedChange = this.selection.changed.pipe(
        map(event => event.source.selected?.[0]),
        tap(item => this.onItemSelected(item)),
    );
    /** Edit event */
    @Output() readonly edit = new EventEmitter();
    /** Delete event */
    @Output() readonly delete = new EventEmitter();
    /** Medication group */
    medicationGroups: MedicationGroup[];
    /** Frequency array */
    medicationFrequencies: PrescriptionFrequency[];
    /** Frequency times array */
    medicationFrequencyTimes: PrescriptionFrequencyTime[];
    /** Medications categories */
    medicationCategories: MedicationCategory[];
    /** Medication administration method */
    medicationAdministrationMethods: MedicationAdministrationMethod[];
    /** Medications units */
    medicationUnits: MedicationUnit[];
    /** Medications solutions */
    medicationSolutions: MedicationSolution[];
    /** Dosage forms */
    medicationDosageForms: MedicationDosageForm[];
    /** Search form */
    searchForm = new FormGroup({
        query: new FormControl<string>(''),
    });

    /**
     * Constructor
     *
     * @param cdRef ChangeDetectorRef,
     * @param medicationTerminologyGetter
     */
    constructor(
        private cdRef: ChangeDetectorRef,
        @Inject(PRESCRIPTION_TERMINOLOGY_GETTER) private medicationTerminologyGetter: PrescriptionTerminologyGetter,
    ) {}

    /** Medication array */
    @Input() set data(data: BaseMedication[]) {
        this.dataSource = new UiTableDataSource(data, filterPredicate);
        this.dataSource.filter = this.searchForm?.controls?.query?.value;
    }

    /** Lifecycle */
    ngOnInit(): void {
        this.getTerminologyData();
        if (this.showAsList) {
            this.showTableHeader = false;
            this.showNoData = false;
        }
        this.searchForm.controls.query.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                startWith(''),
                tap(query => {
                    this.dataSource.filter = query;
                    this.cdRef.markForCheck();
                }),
                takeUntilDestroy(this),
            )
            .subscribe();
    }

    /** Lifecycle */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnDestroy() {
        this.dataSource?.disconnect();
    }

    /**
     * Compare item with selected
     *
     * @param item
     */
    getItemSelected(item: BaseMedication): boolean {
        return this.selection.isSelected(item);
    }

    /**
     * Listen to selection change
     *
     * @param event
     */
    onItemSelected(event: any) {
        //
    }

    /** Get terminology data once and assign to sync properties to avoid many async pipes */
    private getTerminologyData() {
        this.medicationTerminologyGetter()
            .pipe(takeUntilDestroy(this))
            .subscribe(
                ({
                    medicationGroups,
                    medicationCategories,
                    medicationUnits,
                    medicationAdministrationMethods,
                    medicationSolutions,
                    prescriptionFrequencies,
                    prescriptionFrequencyTimes,
                    medicationDosageForms,
                }) => {
                    this.medicationGroups = medicationGroups;
                    this.medicationCategories = medicationCategories;
                    this.medicationUnits = medicationUnits;
                    this.medicationAdministrationMethods = medicationAdministrationMethods;
                    this.medicationSolutions = medicationSolutions;
                    this.medicationFrequencies = prescriptionFrequencies;
                    this.medicationFrequencyTimes = prescriptionFrequencyTimes;
                    this.medicationDosageForms = medicationDosageForms;
                    this.cdRef.markForCheck();
                },
            );
    }
}
