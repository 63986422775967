import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { MedicationAutocompleteModule } from '../medication-autocomplete';
import { PatientPipesModule } from '../patient-pipes';
import {
    AllergiesFormComponent,
    AllergyDialogComponent,
    AnamnesisDialogComponent,
    AnamnesisFormComponent,
    DiagnosesFormComponent,
    DiagnosisDialogComponent,
    HistoryEntryDialogComponent,
    PersonalDataFormComponent,
    PhysicalExaminationDialogComponent,
    PhysicalExaminationFormComponent,
    PreMedicationDialogComponent,
    PreMedicationFormComponent,
    PreMedicationsFormComponent,
    SingleDiagnosisFormComponent,
    AdmissionDiagnosisFormComponent,
    InfectionFormComponent,
    InfectionDialogComponent,
    TherapyLimitationFormComponent,
    TherapyLimitationDialogComponent,
    SurgeryPrescriptionDialogComponent,
    SurgeryPrescriptionFormComponent,
    VaccinationDialogComponent,
    VaccinationFormComponent,
    WoundDialogComponent,
    WoundFormComponent,
    ValuableDialogComponent,
    ValuableFormComponent,
} from './components';
import { PersonalDataDialogComponent } from './components/personal-data-dialog/personal-data-dialog.component';

const COMPONENTS = [
    AllergiesFormComponent,
    AllergyDialogComponent,
    AnamnesisFormComponent,
    AnamnesisDialogComponent,
    DiagnosesFormComponent,
    DiagnosisDialogComponent,
    HistoryEntryDialogComponent,
    PersonalDataFormComponent,
    PersonalDataDialogComponent,
    PhysicalExaminationDialogComponent,
    PhysicalExaminationFormComponent,
    PreMedicationDialogComponent,
    PreMedicationFormComponent,
    PreMedicationsFormComponent,
    SingleDiagnosisFormComponent,
    AdmissionDiagnosisFormComponent,
    InfectionFormComponent,
    InfectionDialogComponent,
    TherapyLimitationFormComponent,
    TherapyLimitationDialogComponent,
    SurgeryPrescriptionDialogComponent,
    SurgeryPrescriptionFormComponent,
    VaccinationDialogComponent,
    VaccinationFormComponent,
    WoundDialogComponent,
    WoundFormComponent,
    ValuableDialogComponent,
    ValuableFormComponent,
];

/**
 * Encounter forms module
 */
@NgModule({
    declarations: COMPONENTS,
    imports: [
        /* prettier-ignore */
        UiModule,
        KeyboardModule,
        MedicationAutocompleteModule,
        PatientPipesModule,
    ],
    exports: COMPONENTS,
})
export class EncounterFormsModule {}
