import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { PLATFORM, Platform } from '@mona/shared/utils';
import { MonaRpcBrowserService, MonaRpcElectronService, MonaRpcService } from './services';

/**
 * Get monaRpc service by platform
 *
 * @param platform
 */
export function monaRpcFactory(platform: Platform): MonaRpcService {
    return platform.isElectron ? inject(MonaRpcElectronService) : inject(MonaRpcBrowserService);
}

/**
 * Mona Rpc Module
 */
@NgModule()
export class MonaRpcModule {
    /**
     * static forRoot
     */
    static forRoot(): ModuleWithProviders<MonaRpcModule> {
        return {
            ngModule: MonaRpcModule,
            providers: [
                {
                    provide: MonaRpcService,
                    useFactory: monaRpcFactory,
                    deps: [PLATFORM],
                },
            ],
        };
    }
}
