// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for representing MedicationAdministration model structure.
 * @see #/components/schemas/MedicationAdministration - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface MedicationAdministration {
    /**
     * The status of the administration.  * `not-done` - NOT_DONE * `completed` - COMPLETED
     *
     */
    status?: MedicationAdministration.StatusEnum;
    /**
     * The reason why the administration not given.
     *
     */
    status_reason?: string;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    amount?: number;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    rate?: number;
    /**
     * The batch number of the blood product if the administration is a blood product.
     *
     */
    blood_batch_number?: string;
    /**
     * The code of the blood administration reason.  * `active-bleeding` - ACTIVE_BLEEDING * `hypoxia` - HYPOXIA * `cardiac` - CARDIAC * `other` - OTHER * `assist-system` - ASSIST_SYSTEM
     *
     */
    blood_administration_reason?: MedicationAdministration.BloodAdministrationReasonEnum;
    /**
     * The date when the period started.
     * @dataFormat date-time
     */
    start_date: string;
    /**
     * The date when the period ended.
     * @dataFormat date-time
     */
    end_date?: string;
    /**
     * The code of the medication category. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/category
     *
     */
    category: string;
    /**
     * The code to identify the medication.
     *
     */
    code: string;
    /**
     * The textual representation of the code.
     *
     */
    description: string;
    /**
     * The code of the medication dosage form. Custom-Mona-Coding-System https://fhir.mona.icu/CodeSystem/mona-medication-dosage-form
     *
     */
    dosage_form?: string;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit_amount?: string;
    /**
     * Units codes for amount, ratio and volume.
     *
     */
    unit_amount_code?: string;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit_rate?: string;
    /**
     * Units codes for amount, ratio and volume.
     *
     */
    unit_rate_code?: string;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    volume?: number;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit_volume?: string;
    /**
     * Units codes for amount, ratio and volume.
     *
     */
    unit_volume_code?: string;
    /**
     * The solution code for the volume and unit_volume
     *
     */
    solution_code?: string;
    /**
     * The code of the medication administration method that describes how the medication should be administered. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/MedicationAdministration/method
     *
     */
    method: string;
    /**
     * The ID of the medication prescription this administration is based on.
     * @dataFormat uuid
     */
    prescription_id: string;
    /**
     * The identifier of the related patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Timestamp when this entity was last modified.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * Medication Administration unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for MedicationAdministration
 */
export namespace MedicationAdministration {
    export type StatusEnum = 'not-done' | 'completed';
    export const StatusEnum = {
        NotDone: 'not-done' as StatusEnum,
        Completed: 'completed' as StatusEnum,
    };
    export type BloodAdministrationReasonEnum = 'active-bleeding' | 'hypoxia' | 'cardiac' | 'other' | 'assist-system';
    export const BloodAdministrationReasonEnum = {
        ActiveBleeding: 'active-bleeding' as BloodAdministrationReasonEnum,
        Hypoxia: 'hypoxia' as BloodAdministrationReasonEnum,
        Cardiac: 'cardiac' as BloodAdministrationReasonEnum,
        Other: 'other' as BloodAdministrationReasonEnum,
        AssistSystem: 'assist-system' as BloodAdministrationReasonEnum,
    };

    /**
     * MedicationAdministration Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The status of the administration.  * `not-done` - NOT_DONE * `completed` - COMPLETED
         * @type string (`dataFormat` is missing)
         */
        status?: MedicationAdministration.StatusEnum;
        /**
         * The reason why the administration not given.
         * @type string (`dataFormat` is missing)
         */
        statusReason?: string;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        amount?: number;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        rate?: number;
        /**
         * The batch number of the blood product if the administration is a blood product.
         * @type string (`dataFormat` is missing)
         */
        bloodBatchNumber?: string;
        /**
         * The code of the blood administration reason.  * `active-bleeding` - ACTIVE_BLEEDING * `hypoxia` - HYPOXIA * `cardiac` - CARDIAC * `other` - OTHER * `assist-system` - ASSIST_SYSTEM
         * @type string (`dataFormat` is missing)
         */
        bloodAdministrationReason?: MedicationAdministration.BloodAdministrationReasonEnum;
        /**
         * The date when the period started.
         * @dataFormat date-time
         */
        @TransformDate() startDate: Date;
        /**
         * The date when the period ended.
         * @dataFormat date-time
         */
        @TransformDate() endDate?: Date;
        /**
         * The code of the medication category. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/category
         * @type string (`dataFormat` is missing)
         */
        category: string;
        /**
         * The code to identify the medication.
         * @type string (`dataFormat` is missing)
         */
        code: string;
        /**
         * The textual representation of the code.
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * The code of the medication dosage form. Custom-Mona-Coding-System https://fhir.mona.icu/CodeSystem/mona-medication-dosage-form
         * @type string (`dataFormat` is missing)
         */
        dosageForm?: string;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitAmount?: string;
        /**
         * Units codes for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitAmountCode?: string;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitRate?: string;
        /**
         * Units codes for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitRateCode?: string;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        volume?: number;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitVolume?: string;
        /**
         * Units codes for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitVolumeCode?: string;
        /**
         * The solution code for the volume and unit_volume
         * @type string (`dataFormat` is missing)
         */
        solutionCode?: string;
        /**
         * The code of the medication administration method that describes how the medication should be administered. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/MedicationAdministration/method
         * @type string (`dataFormat` is missing)
         */
        method: string;
        /**
         * The ID of the medication prescription this administration is based on.
         * @dataFormat uuid
         */
        prescriptionId: string;
        /**
         * The identifier of the related patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Timestamp when this entity was last modified.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * Medication Administration unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * MedicationAdministration DTO
     * Transforms MedicationAdministration model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend MedicationAdministration model */
        static toModel(obj: MedicationAdministration): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend MedicationAdministration model */
        static toApi(obj: Model): MedicationAdministration {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as MedicationAdministration;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
