/* eslint-disable jsdoc/require-jsdoc, @typescript-eslint/ban-ts-comment */
import { KeyValuePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const unsort = (a: any, b: any) => 0;
/**
 * KeyValue Unsorted pipe
 */
@Pipe({
    name: 'keyvalueus',
})
export class KeyValueUnsortedPipe extends KeyValuePipe implements PipeTransform {
    // @ts-ignore
    transform(value: any, compareFn = unsort) {
        return super.transform(value, compareFn);
    }
}
