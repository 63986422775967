import { Pipe, PipeTransform } from '@angular/core';
import { TableDataItem, TableDataItemDataType, VitalSign } from '@mona/models';
import { VitalSignsMap } from '@mona/pdms/data-access-encounter-data';

/**
 * Detects if cell has prev value
 */
@Pipe({
    name: 'hasPrev',
})
export class HasPrevPipe implements PipeTransform {
    /**
     * Transform implementation
     *
     * @param cell TableDataItem
     * @param cellDateTime Date
     * @param orderedItemsByType
     */
    transform(cell: TableDataItem<VitalSign>, cellDateTime: Date, orderedItemsByType: VitalSignsMap): boolean {
        if (cell.dataType === TableDataItemDataType.GROUP) {
            return !!cell.children.find(child => this.transform(child, cellDateTime, orderedItemsByType));
        }

        const entries = orderedItemsByType[cell.code];

        if (!entries?.length) {
            return false;
        }

        return entries[0].date < cellDateTime;
    }
}
