import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Observable } from 'rxjs';
import { ConfigService } from '@mona/config';
import { Platform, PLATFORM } from '@mona/shared/utils';

/* eslint-disable jsdoc/require-jsdoc */
export type MonaFeature = 'pdms' | 'telemedicine' | 'voice';
export type MonaPlatform = 'mona' | 'isBrowser';
export type MonaFeatureAndPlatform = `${MonaFeature}:${MonaPlatform}`;
/* eslint-enable jsdoc/require-jsdoc */

/**
 * Feature-Flags Service
 */
export interface FeatureFlagsService {
    /** Ready to check as Obs */
    ready$: Observable<any>;
    /**
     * Get feature licensed by its name
     */
    licensed(feature: MonaFeature): boolean;
    /**
     * Get feature enabled by its name
     */
    has(feature: MonaFeature): boolean;
    /**
     * Is Platform by its name
     */
    is(platform: MonaPlatform): boolean;
    /**
     * Check feature licensed and enabled and platfrom
     */
    check(featureAndPlatform: MonaFeatureAndPlatform): boolean;
}
/**
 * Feature-Flags Injection Token
 */
export const FEATURE_FLAGS = new InjectionToken<FeatureFlagsService>('Feature-Flags Service', {
    providedIn: 'root',
    factory: () => new FeatureFlags(inject(WINDOW), inject(PLATFORM), inject(ConfigService)),
});

class FeatureFlags implements FeatureFlagsService {
    /** Ready to check as Obs */
    ready$ = this.configService.configLoaded$;
    /**
     * Creates an instance of FeatureFlagsService
     *
     * @param {Window} window
     * @param {Platform} platform
     * @param {ConfigService} configService
     */
    constructor(public window: Window, public platform: Platform, private readonly configService: ConfigService) {}

    licensed(feature: MonaFeature): boolean {
        if (feature && feature in this.configService.get(`license`)) {
            return this.configService.get(`license.${feature}`);
        }
        // Default
        return true;
    }

    has(feature: MonaFeature): boolean {
        if (feature && feature in this.configService.get(`license`)) {
            return this.configService.get(`license.${feature}`) && this.configService.get(`features.${feature}`);
        }
        // Default
        return true;
    }

    is(platform: MonaPlatform): boolean {
        if (platform == 'mona') {
            return this.platform.isElectron; //  && !!process?.versions?.node;
        } else if (platform && this.platform.hasOwnProperty(platform)) {
            return this.platform[platform];
        }
        // Default
        return true;
    }

    check(featureAndPlatform: MonaFeatureAndPlatform): boolean {
        let enabled = true;

        const [feature, platform] = featureAndPlatform.split(':') as [MonaFeature, MonaPlatform];

        if (platform) {
            enabled = this.is(platform);
        }

        if (enabled && feature) {
            enabled = this.has(feature);
        }

        return enabled;
    }
}
