import {
    ChangeLogEntry,
    MedicationCategory,
    MedicationPrescription,
    MonaTask,
    ProcedureCategory,
    ProcedurePrescription,
    TaskType,
} from '@mona/models';
import { mergeChangesOnModelId } from '@mona/pdms/data-access-changelog';

type TaskId = string;

/**
 * apply prescription entities and categories display values to tasks
 *
 * @param tasks
 * @param medPrescriptionsMap
 * @param procPrescriptionsMap
 * @param medCategoriesMap
 * @param procCategoriesMap
 */
export function applyPrescriptionEntitiesToTasks(
    tasks: MonaTask[],
    medPrescriptionsMap: EntityMap<MedicationPrescription>,
    procPrescriptionsMap: EntityMap<ProcedurePrescription>,
    medCategoriesMap: EntityMap<MedicationCategory>,
    procCategoriesMap: EntityMap<ProcedureCategory>,
): MonaTask[] {
    return tasks.map(t => {
        const prescription = getPrescriptionByTaskType(t, medPrescriptionsMap, procPrescriptionsMap);
        const categoryDisplayName = getPrescriptionCategoryByTaskType(
            t,
            prescription,
            medCategoriesMap,
            procCategoriesMap,
        );

        return {
            ...t,
            prescription,
            categoryDisplayName,
        };
    });
}

/**
 * apply tasks changes
 *
 * @param tasks
 * @param prCh
 * @param maCh
 */
export function applyTasksChanges(tasks: MonaTask[], prCh: ChangeLogEntry[], maCh: ChangeLogEntry[]): MonaTask[] {
    const taskIdToChangeMap: Record<TaskId, ChangeLogEntry> = {};

    if (prCh?.length) {
        prCh = mergeChangesOnModelId(prCh);
        Object.assign(taskIdToChangeMap, createTaskIdToTaskChangeObj(prCh));
    }

    if (maCh?.length) {
        maCh = mergeChangesOnModelId(maCh);
        Object.assign(taskIdToChangeMap, createTaskIdToTaskChangeObj(maCh));
    }

    return tasks.map(t => {
        const change = taskIdToChangeMap[t.id];
        const task = structuredClone(t);

        if (change && change.operation !== 'delete') {
            task.change = taskIdToChangeMap[t.id];
            task.hasChanges = true;
        }

        return task;
    });
}

/**
 * get prescription category by task type
 *
 * @param task
 * @param prescription
 * @param medCategoriesMap
 * @param procCategoriesMap
 */
export function getPrescriptionCategoryByTaskType(
    task: MonaTask,
    prescription: MonaTask['prescription'],
    medCategoriesMap: Record<string, MedicationCategory>,
    procCategoriesMap: Record<string, ProcedureCategory>,
) {
    switch (task.type) {
        case TaskType.MedicationPrescriptions:
            return medCategoriesMap?.[prescription?.category]?.displayName;
        case TaskType.ProcedurePrescriptions:
            return procCategoriesMap?.[prescription?.category]?.displayName;
        default:
            return prescription?.category;
    }
}

/**
 *
 * @param task
 * @param medPrescriptionsMap
 * @param procPrescriptionsMap
 */
export function getPrescriptionByTaskType(
    task: MonaTask,
    medPrescriptionsMap: Record<string, MedicationPrescription>,
    procPrescriptionsMap: Record<string, ProcedurePrescription>,
) {
    switch (task.type) {
        case TaskType.MedicationPrescriptions:
            return medPrescriptionsMap[task.prescriptionId];
        case TaskType.ProcedurePrescriptions:
            return procPrescriptionsMap[task.prescriptionId];
        default:
            return null;
    }
}

/**
 * create task id to task change map
 *
 * @param changesMap
 * @returns
 */
export function createTaskIdToTaskChangeObj(changesMap: ChangeLogEntry[]): Record<string, ChangeLogEntry> {
    const map: Record<string, ChangeLogEntry> = {};

    for (const ch of changesMap) {
        let date: Date;
        let type: TaskType;
        const prescriptionId = ch.payload?.prescriptionId;
        if (ch.model === 'Procedure') {
            date = ch.payload?.date;
            type = TaskType.ProcedurePrescriptions;
        } else if (ch.model === 'MedicationAdministration') {
            date = ch.payload?.startDate;
            type = TaskType.MedicationPrescriptions;
        } else {
            return;
        }

        const timestamp = date?.toISOString()?.split('.')[0] + 'Z';
        map[`${timestamp}_${prescriptionId}_${type}`] = ch;
    }

    return map;
}
