<mat-dialog-content class="app-vaccination-dialog__content">
    <div>
        <h2 mat-dialog-title class="app-vaccination-dialog__title">
            {{
                data.vaccination.id
                    ? ('apps.patient.overview.editVaccination' | translate)
                    : ('apps.patient.overview.addVaccination' | translate)
            }}
        </h2>
        <app-vaccination-form
            [formGroup]="vaccinationForm"
            [isBedSideMode]="data.isBedSideMode"
            [vaccineSearchText]="vaccineSearchText"
            [isActive]="true"
        ></app-vaccination-form>
    </div>

    <div class="app-vaccination-dialog__actions">
        <button mat-button class="app-vaccination-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'apps.patient.overview.dialogCancel' | translate }}
        </button>
        <button mat-raised-button color="primary" cdkFocusInitial (click)="submitChange()">
            {{ 'apps.patient.overview.dialogSave' | translate }}
        </button>
    </div>
</mat-dialog-content>
