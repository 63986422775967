import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ApiModels } from '@mona/api';
import { Encounter, PhysicalExamination } from '@mona/models';
import { transformApiPhysicalExaminations } from './transforms';

/**
 * API abstraction layer for the physical examination API
 */
@Injectable({
    providedIn: 'root',
})
export class PhysicalExaminationApi {
    apiBase = '/pdms/physical-examinations/';

    /**
     * Constructor
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get physical examinations
     * @param encounterId
     */
    getPhysicalExaminations(encounterId?: EntityId<Encounter>): Observable<PhysicalExamination[]> {
        const params: {
            [param: string]: string | string[];
        } = {};

        if (encounterId) {
            params.encounter_id = `${encounterId}`;
        }

        return this.http
            .get<ApiModels.PhysicalExamination[]>(this.apiBase, {
                params,
            })
            .pipe(map(apiPhysicalExaminations => transformApiPhysicalExaminations(apiPhysicalExaminations)));
    }
}
