import { createReducer, on } from '@ngrx/store';
import { Theme } from '@mona/ui';
import { UiActions } from '../actions';
import { UiState } from '../state';

/**
 * The initial state
 */
export const initialUiState: UiState = {
    theme: Theme.light,
    nightShiftTimerActive: true,
    isConversationDialogOpened: false,
    isSideNavOpened: false,
};

/**
 *
 * Sets theme
 *
 * @param state UiState
 * @param action theme
 * @param action.theme
 */
export const reduceSetTheme = (
    state: UiState,
    action: {
        /**
         * Theme
         */
        theme: Theme;
    },
): UiState => ({
    ...state,
    theme: action.theme,
    nightShiftTimerActive: false,
});

/**
 *
 * Sets is right sidenav opened
 *
 * @param state state
 * @param action action
 * @param action.isOpened
 */
export const reduceSetIsSideNavOpenedAction = (
    state: UiState,
    action: {
        /**
         * Is opened
         */
        isOpened: boolean;
    },
): UiState => ({
    ...state,
    isSideNavOpened: action.isOpened,
});

/**
 *
 * Sets is conversation overlay opened
 *
 * @param state state
 * @param action action
 * @param action.isOpened
 */
export const reduceSetIsConversationOverlayOpened = (
    state: UiState,
    action: {
        /**
         * Is opened
         */
        isOpened: boolean;
    },
): UiState => ({
    ...state,
    isConversationDialogOpened: action.isOpened,
});

/**
 * Reducer definition
 */
export const uiReducer = createReducer(
    initialUiState,

    on(UiActions.setTheme, reduceSetTheme),

    on(UiActions.setIsSideNavOpened, reduceSetIsSideNavOpenedAction),

    on(UiActions.setIsConversationOverlayOpened, reduceSetIsConversationOverlayOpened),
);
