import { Component, Input, OnInit } from '@angular/core';

/**
 * Diagnostics item manual check component
 */
@Component({
    selector: 'mona-diagnostics-item-manual',
    templateUrl: './diagnostics-item-manual.component.html',
    styleUrls: ['./diagnostics-item-manual.component.scss'],
})
export class DiagnosticsItemManualComponent {
    /**
     * Is checked
     */
    @Input() isChecked: boolean;

    /**
     * Check label translation key
     */
    @Input() checkLabel: string;

    /**
     * Checked label translation key
     */
    @Input() checkedLabel: string;

    /**
     * Checked label translation params
     */
    @Input() checkedParams: any;

    /**
     * Not checked label
     */
    @Input() notCheckedLabel: string;
}
