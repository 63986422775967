import { BestMotorResponse } from './best-motor-response.enum';
import { BestVerbalResponse } from './best-verbal-response.enum';
import { GlasgowComaScaleOptions } from './glassgow-coma-scale-dataflows.model';
import { OpenEyes } from './open-eyes.enum';

/**
 * Get possible options for open eyes field
 */
export const getOpenEyesOptions = (): GlasgowComaScaleOptions<OpenEyes> => [
    {
        value: OpenEyes.SPONTANEOUSLY,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.openEyesValues.spontaneously',
    },
    {
        value: OpenEyes.TO_SPEECH,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.openEyesValues.toSpeech',
    },
    {
        value: OpenEyes.TO_PAIN,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.openEyesValues.toPain',
    },
    {
        value: OpenEyes.NO_RESPONSE,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.openEyesValues.noResponse',
    },
];

/**
 * Get possible options for best motor response field
 */
export const getBestMotorResponseOptions = (): GlasgowComaScaleOptions<BestMotorResponse> => [
    {
        value: BestMotorResponse.OBEYS_COMMAND,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestMotorResponseValues.obeysCommand',
    },
    {
        value: BestMotorResponse.LOCALISED_PAIN,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestMotorResponseValues.localisedPain',
    },
    {
        value: BestMotorResponse.WITHDRAWAL_FROM_PAIN,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestMotorResponseValues.withdrawFromPain',
    },
    {
        value: BestMotorResponse.FLEXION,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestMotorResponseValues.flexion',
    },
    {
        value: BestMotorResponse.EXTENSION,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestMotorResponseValues.extension',
    },
    {
        value: BestMotorResponse.NO_RESPONSE,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestMotorResponseValues.noResponse',
    },
];

/**
 * Get possible options for best verbal response field
 */
export const getBestVerbalResponseOptions = (): GlasgowComaScaleOptions<BestVerbalResponse> => [
    {
        value: BestVerbalResponse.ORIENTED,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestVerbalResponseValues.oriented',
    },
    {
        value: BestVerbalResponse.CONFUSED,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestVerbalResponseValues.confused',
    },
    {
        value: BestVerbalResponse.INAPPROPRIATE_WORDS,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestVerbalResponseValues.inappropriateWords',
    },
    {
        value: BestVerbalResponse.INCOMPREHENSIBLE_SOUNDS,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestVerbalResponseValues.incomprehensibleSounds',
    },
    {
        value: BestVerbalResponse.NO_RESPONSE,
        labelKey: 'apps.patient.careReports.sections.glasgowComaScale.bestVerbalResponseValues.noResponse',
    },
];
