import { Component } from '@angular/core';

/**
 * Auth Host Component
 */
@Component({
    selector: 'mona-auth',
    template: `
        <router-outlet></router-outlet>
    `,
})
export class AuthHostComponent {}
