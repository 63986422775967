import { ApiModels } from '@mona/api';
import { PatientOutput } from '@mona/models';

/**
 * Transforms api output
 * @param apiOutput ApiModels.PatientOutput
 */
export const transformApiOutput = (apiOutput: ApiModels.Output): PatientOutput => {
    return {
        id: apiOutput.id,
        code: apiOutput.code,
        description: apiOutput.description,
        unit: apiOutput.unit,
        value: apiOutput.value,
        date: new Date(apiOutput.date),
    };
};

/**
 * Transform api outputs
 * @param apiOutputs ApiModels.OutputFactorTerminologyType[]
 */
export const transformApiOutputs = (apiOutputs: ApiModels.Output[]): PatientOutput[] => {
    return apiOutputs.map(apiOutput => transformApiOutput(apiOutput));
};
