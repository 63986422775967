import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { BehaviorSubject } from 'rxjs';
import { SyncStorage } from '@mona/shared/utils';

/**
 * Token Storage Service
 */
@Injectable({ providedIn: 'root' })
export class TokenStorageService extends SyncStorage {
    readonly accessTokenKey = 'mona.accessToken';
    readonly refreshTokenKey = 'mona.refreshToken';
    /** Token subject */
    private _token$ = new BehaviorSubject<string>(null);
    /** Token as observable */
    get token$() {
        return this._token$.asObservable();
    }

    /** @ignore */
    constructor(@Inject(WINDOW) window: Window) {
        super(window.localStorage);
    }

    /**
     * Get access token
     */
    getAccessToken(): string {
        return this.getItem(this.accessTokenKey) as string;
    }

    /**
     * Save access token
     *
     * @param token
     */
    saveAccessToken(token: string) {
        this.setItem(this.accessTokenKey, token);
        this._token$.next(token);
    }

    /**
     * Get refresh token
     */
    getRefreshToken(): string {
        return this.getItem(this.refreshTokenKey) as string;
    }

    /**
     * Save refresh token
     *
     * @param token
     */
    saveRefreshToken(token: string) {
        this.setItem(this.refreshTokenKey, token);
    }

    /**
     * Save tokens
     *
     * @param accessToken
     * @param refreshToken
     */
    saveTokens(accessToken: string, refreshToken?: string) {
        this.saveAccessToken(accessToken);
        refreshToken && this.saveRefreshToken(refreshToken); // avoid writing nullable value
        this._token$.next(accessToken);
    }

    /**
     * Remove tokens
     */
    removeTokens() {
        this.removeItem(this.accessTokenKey);
        this.removeItem(this.refreshTokenKey);
        this._token$.next(null);
    }
}
