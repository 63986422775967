import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WoundStatus } from '@mona/models';
import { WoundForm } from '../../models';

/**
 * Wound dialog
 */
@Component({
    selector: 'app-wound-dialog',
    templateUrl: './wound-dialog.component.html',
    styleUrls: ['./wound-dialog.component.scss'],
})
export class WoundDialogComponent {
    /**
     * wound form
     */
    woundForm = new FormGroup<WoundForm>({
        location: new FormControl<string>(null, Validators.required),
        description: new FormControl<string>('', Validators.required),
    });

    /**
     * Constructor
     *
     * @param dialogRef MatDialogRef<WoundDialogComponent>
     * @param data dialog data
     */
    constructor(
        private dialogRef: MatDialogRef<WoundDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            wound: WoundStatus;
            isBedSideMode: boolean;
        },
    ) {
        dialogRef.addPanelClass('app-wound-dialog');

        if (data.wound.id) {
            this.woundForm.patchValue(data.wound);
        }
    }

    /**
     * Submit change
     */
    submitChange(): void {
        this.woundForm.markAsTouched();

        if (this.woundForm.valid) {
            this.dialogRef.close({
                id: this.data.wound.id,
                location: this.woundForm.controls.location.value,
                description: this.woundForm.controls.description.value,
            } as WoundStatus);
        }
    }
}
