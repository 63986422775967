import { createAction, props } from '@ngrx/store';
import { Encounter, Allergy } from '@mona/models';
import { AsyncAction, SucceededAction, FailedAction } from '@mona/store';

/**
 * Allergies actions
 */
export class AllergiesAction {
    /**
     * Load allergies action
     */
    static loadAllergiesAction: AsyncAction<
        {
            encounterId: EntityId<Encounter>;
        },
        Allergy[]
    > = {
        action: createAction(
            'ENCOUNTER:LOAD_ALLERGIES',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction('ENCOUNTER:LOAD_ALLERGIES_SUCCEEDED', props<SucceededAction<Allergy[]>>()),

        failedAction: createAction('ENCOUNTER:LOAD_ALLERGIES_FAILED', props<FailedAction>()),

        clearAction: createAction('ENCOUNTER:LOAD_ALLERGIES_CLEAR'),
    };
}
