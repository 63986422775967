import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { isEmpty, Platform, PLATFORM, takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { AuthFlow, AuthTypeEnum, LoginPayloadCreds } from '../../../../models';
import { AuthService } from '../../../../services';
import { AuthActions } from '../../../../state';

type SignInDialogData = {
    authFlow: AuthFlow;
    authType: AuthTypeEnum;
    enableClose?: boolean;
};

/**
 * Component
 */
@TakeUntilDestroy
@Component({
    selector: 'mona-sign-in',
    templateUrl: './sign-in.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MAT_TABS_CONFIG, useValue: { disablePagination: true } }],
})
export class SignInComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly authType = AuthTypeEnum;
    readonly authFlow = AuthFlow;
    isLoading$ = this.authFacade.isLoading$;
    authError$ = this.authFacade.error$;
    selectedTab: AuthTypeEnum = this.data?.authType == 2 ? AuthTypeEnum.Rfid : this.data?.authType;
    flow: AuthFlow = this.data?.authFlow || (AuthFlow.signin as any);
    loginTitle: string;
    submitTitle: string; // 'confirmStagedChangesDialog.confirm'
    changeLogTags: string[] = [];
    tagsTitle$: Observable<string> = this.authFacade.isDiscardInProgress$.pipe(
        map(inDiscardProgress => {
            return inDiscardProgress ? 'auth.changelog-tags.title.canceled' : 'auth.changelog-tags.title.changed';
        }),
    );

    /** Component helper to check if the flow is signin */
    get isFlowSignin(): boolean {
        return this.data.authFlow === AuthFlow.signin;
    }

    /** Component helper to check if the folow is verify */
    get isFlowVerify(): boolean {
        return this.data.authFlow === AuthFlow.verify;
    }

    /** Show close btn always in Mona app or in Web when verify  */
    get showCloseBtn() {
        return this.platform.isElectron || (!this.platform.isElectron && this.isFlowVerify);
    }

    /**
     * Constructor
     *
     * @param platform
     * @param data
     * @param dialogRef
     * @param authFacade
     * @param actionsObserver$
     */
    constructor(
        @Inject(PLATFORM) public platform: Platform,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: SignInDialogData,
        @Optional() private dialogRef: MatDialogRef<any>,
        private authFacade: AuthService,
        private actionsObserver$: ActionsSubject,
    ) {}

    /** Lifecycle */
    ngOnInit(): void {
        if (!isEmpty(this.data) && this.dialogRef) {
            // verify title for log-in-form based on authFlow
            this.loginTitle = this.isFlowSignin ? 'auth.signInDialog.signInTitle' : 'auth.signInDialog.verifyTitle';
            this.submitTitle = this.isFlowSignin ? 'auth.actions.login' : 'confirmStagedChangesDialog.confirm';
        }
        // Enforce Credentials only if Web
        if (!this.platform.isElectron) {
            this.data.authType = AuthTypeEnum.Credentials;
            this.selectedTab = 1;
        }
        this.watchRfidScan();
        this.watchLogoutEvent();
    }

    /** ng after view init hook */
    ngAfterViewInit(): void {
        const tags = [];
        document.querySelectorAll('ui-drawer-nav-item').forEach(el => {
            const item = el?.querySelector('.ui-drawer-nav-item-depth-1');
            if (item?.querySelector('.ui-drawer-nav-item-asterisk-icon')) {
                const text = item.children[0].textContent;
                tags.push(text?.replaceAll('*', '').trim());
            }
        });

        this.changeLogTags = tags;
    }

    /**
     * Close dialog on autolock, skip if relogin or if not electron app
     *
     * @private
     */
    private watchLogoutEvent() {
        this.authFacade.isLoggedOut$
            .pipe(
                take(1),
                filter(action => !action.isRelogin && this.platform.isElectron),
            )
            .subscribe(() => this.close(null));
    }

    /** Listens rfid event (1) end closes dialog */
    watchRfidScan() {
        this.authFacade.rfidScanned$
            .pipe(
                filter(() => this.selectedTab === AuthTypeEnum.Rfid),
                take(1),
                takeUntilDestroy(this),
            )
            .subscribe((rfid: string) => {
                if (this.isFlowSignin) {
                    // Maybe check if already loggedin to re-login
                    this.authFacade.loginWithRfid(rfid);
                    this.close('');
                } else {
                    this.authFacade.dispatchVerifyRfid(rfid);
                    this.actionsObserver$.pipe(ofType(AuthActions.verifyRfidSuccess), take(1)).subscribe(({ rfid }) => {
                        this.close(rfid);
                    });
                }
            });
    }

    /** Listens form submit end closes dialog */
    onSubmitForm({ username, password }: LoginPayloadCreds) {
        // Login with creds everytime - to get `rfid`
        this.authFacade.loginWithCredentials(username, password, this.data?.authFlow);
        if (this.isFlowSignin || this.isFlowVerify) {
            // FIXME: TODO: INFO: here it was wrong as it was not signinFlow for verify persistChanges with creds
            this.actionsObserver$.pipe(ofType(AuthActions.loginSuccess), take(1)).subscribe(({ rfid }) => {
                this.close(rfid);
            });
        } else {
            // FIXME: dangerous subscription to actionsObserver because `verifyRfidSucceeded` was `{ dispatch: false }` and it was not emitted further
            this.actionsObserver$.pipe(ofType(AuthActions.verifyRfidSuccess), take(1)).subscribe(({ rfid }) => {
                this.close(rfid);
            });
        }
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, jsdoc/require-jsdoc, @typescript-eslint/no-empty-function
    ngOnDestroy(): void {}

    /**
     * Close dialog
     *
     * @param rfid
     */
    close(rfid?: string) {
        this.dialogRef.close();
        this.authFacade.authenticateClose(rfid);
    }
}
