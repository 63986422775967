import { createReducer, on } from '@ngrx/store';
import { EntriesInterval } from '@mona/shared/date';
import { CareReportsAction } from '../actions/care-reports.action';

/**
 * The initial state
 */
export const initialCareReportsIntervalState: EntriesInterval = EntriesInterval.HOUR_1 as any;

/**
 * Reducer for the set interval action
 *
 * @param state EntriesInterval
 * @param action action to set the interval
 */
export const reduceSetCareReportsInterval = (
    state: EntriesInterval,
    action: { interval: EntriesInterval },
): EntriesInterval => {
    return action.interval;
};

/**
 * Care reports interval reducer
 */
export const careReportsIntervalReducer = createReducer(
    initialCareReportsIntervalState,

    on(CareReportsAction.setIntervalAction, reduceSetCareReportsInterval),
);
