import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Patient, VaccinationStatus } from '@mona/models';
import { transformApiVaccinations } from '@mona/pdms/data-access-encounter-data';

/**
 * API abstraction layer for the vaccination status API
 */
@Injectable({
    providedIn: 'root',
})
export class VaccinationStatusApi {
    apiBase = '/pdms/vaccination-status/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get vaccination status
     *
     * @param patientId EntityId<Patient>
     */
    getVaccinationStatus(patientId?: EntityId<Patient>): Observable<VaccinationStatus[]> {
        const params: { [param: string]: string | string[] } = {};

        if (patientId) {
            params.patient_id = `${patientId}`;
        }

        return this.http
            .get<ApiModels.VaccinationStatus[]>(this.apiBase, { params })
            .pipe(map(vaccinations => transformApiVaccinations(vaccinations)));
    }
}
