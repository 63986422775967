import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler, inject, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MONA_CONFIG_FILENAME } from '@environment';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ApiModule } from '@mona/api';
import {
    ConfigHttpLoader,
    ConfigLoader,
    ConfigMergeLoader,
    ConfigModule,
    ConfigPipeModule,
    ConfigStaticLoader,
    transformApiHospitalConfig,
} from '@mona/config';
import { i18nModule } from '@mona/i18n';
import { TerminalConfig } from '@mona/models';
import { MonaRpcModule } from '@mona/rpc';
import { DateModule } from '@mona/shared/date';
import { PLATFORM, Platform } from '@mona/shared/utils';
import { RootStoreModule } from '@mona/store';
import { AppErrorHandler, MenderUpdatesService, UPDATER, UpdatesService, WebUpdatesService } from './services';

/**
 * Config factory to load remote config in series:
 * 1) local 2) remote
 *
 * @param platform
 * @param storageService
 * @param http
 */
export function configSeriesFactory(platform: Platform, storageService: Storage, http: HttpClient): ConfigLoader {
    const { origin } = window.location;
    const monaConfigUrl = platform.isElectron
        ? `mona://${MONA_CONFIG_FILENAME}`
        : `${origin}/assets/${MONA_CONFIG_FILENAME}`;

    const monaConfig = new ConfigHttpLoader(http, monaConfigUrl);
    const localConfig = new ConfigStaticLoader(JSON.parse(storageService.getItem(MONA_CONFIG_FILENAME)));
    const localLoaders = platform.isElectron ? [monaConfig] : [monaConfig, localConfig];

    const remoteConfigLicenses = (res: TerminalConfig) =>
        new ConfigHttpLoader(http, res.api?.baseUrl + '/configuration/licenses');
    const remoteConfigHospital = (res: TerminalConfig) =>
        new ConfigHttpLoader(http, res.api?.baseUrl + '/configuration/hospital', transformApiHospitalConfig);
    const remoteLoaders = [remoteConfigLicenses, remoteConfigHospital];

    return new ConfigMergeLoader(localLoaders).next(remoteLoaders);
}

/**
 * Get UpdatesService by platform
 *
 * @param platform
 */
export function updaterFactory(platform: Platform): UpdatesService {
    return platform.isElectron ? inject(MenderUpdatesService) : inject(WebUpdatesService);
}

/**
 * Core Module (really, **Core**)
 *
 * Keep as few dependecies here as possible, split to modules if needed (e.g. RootStoreModule)
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        i18nModule.forRoot(),
        // libs
        ConfigModule.forRoot({
            provide: ConfigLoader,
            useFactory: configSeriesFactory,
            deps: [PLATFORM, LOCAL_STORAGE, HttpClient],
        }),
        MonaRpcModule.forRoot(),
        DateModule.forRoot(),
        RootStoreModule.forRoot(),
        ApiModule.forRoot(),
    ],
    exports: [StoreModule, EffectsModule, ConfigPipeModule],
})
export class CoreModule {
    /** static forRoot */
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                { provide: ErrorHandler, useClass: AppErrorHandler },
                {
                    provide: UPDATER,
                    useFactory: updaterFactory,
                    deps: [PLATFORM],
                },
            ],
        };
    }

    /**
     * Constructor
     *
     * @param parentModule
     */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule already loaded; import in root module only.');
        }
    }
}
