import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { UtilsModule } from '@mona/shared/utils';
import { UiDirectivesModule, UiFormsModule } from '@mona/ui';
import { WorkflowQuestionnaireComponent } from './components';

/**
 * WorkflowsModule
 */
@NgModule({
    declarations: [WorkflowQuestionnaireComponent],
    imports: [UtilsModule, UiDirectivesModule, UiFormsModule, MatTableModule, MatRadioModule],
    exports: [WorkflowQuestionnaireComponent],
})
export class WorkflowsModule {}
