import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EnovacomEffects, fromEnovacom } from './state';

/**
 * DataAccessEnovacomModule
 */
@NgModule({
    imports: [
        StoreModule.forFeature(fromEnovacom.enovacomFeatureKey, fromEnovacom.reducer),
        EffectsModule.forFeature([EnovacomEffects]),
    ],
})
export class DataAccessEnovacomModule {}
