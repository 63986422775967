import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { isFunction } from '@mona/shared/utils';
import { LayoutEventType } from '../drawer-layout-event.model';
import { DrawerNavItem } from '../drawer-nav-item.model';
import { DrawerService } from '../drawer.service';

/**
 * Drawer Navbar Component
 */
@Component({
    selector: 'ui-drawer-navbar',
    templateUrl: './drawer-navbar.component.html',
    styleUrls: ['./drawer-navbar.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerNavbarComponent {
    time$: Observable<Date> = timer(0, 1000).pipe(
        map(() => new Date()),
        takeUntil(this.drawerService.destroy$),
    );
    /**
     * Default navbar items
     */
    @Input() items: DrawerNavItem[] = [];
    /**
     * Host css class
     */
    @HostBinding('class') cmpClass = 'ui-drawer-navbar';

    /**
     * RouterLink Match Options
     */
    rlaMatchOptions: IsActiveMatchOptions = {
        matrixParams: 'ignored',
        paths: 'subset',
        fragment: 'ignored',
        queryParams: 'ignored',
    };

    /**
     * Constructor
     *
     * @param drawerService
     */
    constructor(private drawerService: DrawerService) {}

    /**
     * Handle DrawerNavItem Click
     *
     * @param {Event} event
     * @param {DrawerNavItem} item
     */
    handleItemClick(event: Event, item: DrawerNavItem): void {
        if (item.event) {
            event.preventDefault();
            return this.drawerService.emit(item.event);
        }
        if (isFunction(item.click)) {
            event.preventDefault();
            item.click();
            return;
        }
    }

    /**
     * Handle logo double click
     *
     * @param event
     */
    logoDoubleClick(event: Event): void {
        event.stopPropagation();
        return this.drawerService.emit(LayoutEventType.DblCkickLogo);
    }
}
