import { AfterViewInit, Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Async, AsyncComponent } from '@mona/shared/utils';
import { PipDialogService } from './pip-dialog.service';

/**
 * Reason for dialog Close
 */
export enum PipCallDialogComponentCloseReason {
    HANG_UP = 'HANG_UP',
    FULL_SCREEN = 'FULL_SCREEN',
}

/**
 * Picture in picture call dialog
 */
@Async()
@Component({
    selector: 'app-pip-call-dialog',
    templateUrl: './pip-call-dialog.component.html',
    styleUrls: ['./pip-call-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PipCallDialogComponent extends AsyncComponent implements AfterViewInit {
    /**
     * Holds a reference to the remote video element
     */
    @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;

    /**
     * Is mic enabled
     */
    microphoneEnabled = this.data.microphoneEnabled;

    /**
     * Is video enabled
     */
    videoEnabled = this.data.videoEnabled;

    /**
     * Is video enabled
     */
    remoteVideoDisabled = this.data.remoteVideoDisabled;

    /**
     * URL to call component
     */
    callUrl = '/telemedicine/call';

    /**
     * Has Call Partner
     */
    hasCallPartner = this.data.hasCallPartner;

    /**
     * Is screen capture
     */
    isScreenCapture = this.data.isScreenCapture;

    /**
     * constructor
     * @param dialogRef MatDialogRef<PipCallDialogComponent>
     * @param pipDialogService PipDialogService
     * @param data Any
     * @param router Router
     * @param route ActivatedRoute
     */
    constructor(
        public dialogRef: MatDialogRef<PipCallDialogComponent>,
        public pipDialogService: PipDialogService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            videoEnabled: boolean;
            microphoneEnabled: boolean;
            remoteVideoDisabled: boolean;
            hasCallPartner: boolean;
            isScreenCapture: boolean;
        },
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super();
    }

    /**
     * NgHook
     */
    ngAfterViewInit() {
        // "Mount" video stream
        this.async(
            this.pipDialogService.setRemoteStream.subscribe(
                stream => (this.remoteVideo.nativeElement.srcObject = stream),
            ),
        );

        // Close on hangup from outside
        this.async(
            this.pipDialogService.hungUp.subscribe(() => {
                this.dialogRef.close();
            }),
        );

        // Change to icon when no calling partner is there
        this.async(
            this.pipDialogService.hasCallPartnerChange.subscribe(hasCallPartner => {
                this.hasCallPartner = hasCallPartner;
            }),
        );

        // Handle screen capture start / end
        this.async(
            this.pipDialogService.isScreenCaptureChange.subscribe(isScreenCapture => {
                this.isScreenCapture = isScreenCapture;
            }),
        );

        // Remote video toggled
        this.async(
            this.pipDialogService.remoteVideoToggled.subscribe(value => {
                this.remoteVideoDisabled = value;
            }),
        );
    }

    /**
     * Hang up call
     */
    hangUp() {
        // We only need to close this one, as that triggers a hangup anyway
        this.dialogRef.close(PipCallDialogComponentCloseReason.HANG_UP);
    }

    /**
     * Back to fullscreen
     */
    fullscreen() {
        if (this.isAtCallUrl()) {
            this.dialogRef.close(PipCallDialogComponentCloseReason.FULL_SCREEN);
        } else {
            this.router.navigateByUrl(this.callUrl).then(() => {
                this.dialogRef.close(PipCallDialogComponentCloseReason.FULL_SCREEN);
            });
        }
    }

    /**
     * Returns true if navigated to call url
     */
    isAtCallUrl() {
        return this.router.url === this.callUrl;
    }

    /**
     * Toggles the microphone
     */
    toggleMicrophone() {
        this.pipDialogService.toggleMic.emit();
        this.microphoneEnabled = !this.microphoneEnabled;
    }

    /**
     * Toggles the video
     */
    toggleVideo() {
        this.pipDialogService.toggleCamera.emit();
        this.videoEnabled = !this.videoEnabled;
    }

    /**
     * Starts screen sharing
     */
    startScreenCapture() {
        this.pipDialogService.startScreenCapture.emit();
    }

    /**
     * Stops screen sharing
     */
    stopScreenCapture() {
        this.pipDialogService.stopScreenCapture.emit();
    }
}
