import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { DiagnosesApi } from '../../infrastructure';
import { DiagnosesAction } from '../actions';

/**
 * Diagnoses effects
 */
@Injectable()
export class DiagnosesEffects {
    /* Effect Declarations */

    /**
     * Load diagnoses effect
     */

    loadDiagnoses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiagnosesAction.loadDiagnosesAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.diagnosesApi.getDiagnoses(action.encounterId),
                    DiagnosesAction.loadDiagnosesAction,
                ),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param diagnosesApi DiagnosesApi
     */
    constructor(private actions$: Actions, private diagnosesApi: DiagnosesApi) {}
}
