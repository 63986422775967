/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { Bed, Encounter, Ward } from '@mona/models';

export const selectEncounter = createAction(
    'ENCOUNTERS:SELECT_ENCOUNTER',
    props<{
        encounterId: EntityId<Encounter>;
    }>(),
);
export const setAssignedEncounter = createAction(
    'ENCOUNTERS:INIT_ASSIGNED_ENCOUNTER',
    props<{
        encounterId: EntityId<Encounter>;
    }>(),
);
export const loadSingleEncounter = createAction(
    'ENCOUNTERS:LOAD_SINGLE_ENCOUNTER',
    props<{
        encounterId: EntityId<Encounter>;
    }>(),
);
export const loadSingleEncounterSuccess = createAction(
    'ENCOUNTERS:LOAD_SINGLE_ENCOUNTER_SUCCEEDED',
    props<{ encounter: Encounter }>(),
);
export const loadSingleEncounterFailed = createAction(
    'ENCOUNTERS:LOAD_SINGLE_ENCOUNTER_FAILED',
    props<{ error: any }>(),
);
/**
 * Sets the current encounter
 */
export const setCurrentEncounter = createAction('ENCOUNTERS:SET_CURRENT_ENCOUNTER', props<{ encounter: Encounter }>());
export const clearCurrentEncounter = createAction('ENCOUNTERS:CLEAR_CURRENT_ENCOUNTER');
/**
 * Encounters load action
 */
export const loadEncounters = createAction(
    'ENCOUNTERS:LOAD_ENCOUNTERS',
    props<{
        wardId?: EntityId<Ward>;
        active: boolean;
    }>(),
);
export const loadEncountersSuccess = createAction(
    'ENCOUNTERS:LOAD_ENCOUNTERS_SUCCEEDED',
    props<{ encounters: Encounter[] }>(),
);
export const loadEncountersFailed = createAction('ENCOUNTERS:LOAD_ENCOUNTERS_FAILED', props<{ error: any }>());
export const clearEncounters = createAction('ENCOUNTERS:CLEAR_ENCOUNTERS');

/**
 * Relocate encounter action
 */
export const relocateEncounter = createAction(
    'ENCOUNTERS:RELOCATE_ENCOUNTER',
    props<{
        encounter: Encounter;
        fromBedId: EntityId<Bed>;
        bedId: EntityId<Bed>;
        wardId: EntityId<Ward>;
        shouldIncludeEncountersReloading: boolean;
        rfid?: string;
    }>(),
);
export const relocateEncounterSuccess = createAction(
    'ENCOUNTERS:RELOCATE_ENCOUNTER_SUCCEEDED',
    props<{ encounterId: EntityId<Encounter>; bedId: EntityId<Bed>; wardId: EntityId<Ward> }>(),
);
export const relocateEncounterFailed = createAction('ENCOUNTERS:RELOCATE_ENCOUNTER_FAILED', props<{ error: any }>());
export const relocateEncounterClear = createAction('ENCOUNTERS:RELOCATE_ENCOUNTER_CLEAR');

/**
 * End encounter action
 */
export const endEncounter = createAction(
    'ENCOUNTERS:END_ENCOUNTER',
    props<{
        encounterId: EntityId<Encounter>;
        rfid?: string;
        discharge_reason: string;
        custom_text?: string;
    }>(),
);
export const endEncounterSuccess = createAction(
    'ENCOUNTERS:END_ENCOUNTER_SUCCEEDED',
    props<{
        encounterId: EntityId<Encounter>;
    }>(),
);
export const endEncounterFailed = createAction('ENCOUNTERS:END_ENCOUNTER_FAILED', props<{ error: any }>());

/**
 * Remove encounter action
 */
export const removeEncounter = createAction(
    'ENCOUNTERS:REMOVE_ENCOUNTER',
    props<{
        encounter: Encounter;
    }>(),
);

/**
 * Upsert encounter action
 */
export const upsertEncounter = createAction(
    'ENCOUNTERS:UPSERT_ENCOUNTER',
    props<{
        encounter: Encounter;
    }>(),
);
