import { ApiModels } from '@mona/api';
import { MedicationCategory, MedicationCategoryConfig } from '@mona/models';
import { camelize, compactObject } from '@mona/shared/utils';

/**
 * Transforms api medication category
 *
 * @param apiCategory ApiModels.MedicationCategoryTerminology
 */
export const transformApiMedicationsCategory = (apiCategory: ApiModels.MedicationCategory): MedicationCategory => {
    return compactObject({
        id: apiCategory.code,
        code: apiCategory.code,
        displayName: apiCategory.display_name,
        fieldConfig: transformApiFieldsConfig(apiCategory.field_config),
        icon: apiCategory.icon,
    }) as any;
};

/**
 * INFO: add comment
 *
 * @param apiFieldsConfig
 */
export const transformApiFieldsConfig = (
    apiFieldsConfig: ApiModels.MedicationCategoryConfig,
): MedicationCategoryConfig => {
    const fieldsConfig = {} as MedicationCategoryConfig;
    Object.entries(apiFieldsConfig).forEach(([key, value]) => {
        const newKey = camelize(key.replace('config_', ''));
        fieldsConfig[newKey] = value;
    });
    return fieldsConfig;
};

/**
 * Transforms api medication categories
 *
 * @param apiCategories (ApiModels.MedicationCategoryTerminology | ApiModels.ProcedureCategoryTerminology)[]
 */
export const transformApiMedicationCategories = (
    apiCategories: ApiModels.MedicationCategory[],
): MedicationCategory[] => {
    return apiCategories.map(apiCategory => transformApiMedicationsCategory(apiCategory));
};
