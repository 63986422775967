import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationDosageForm,
    MedicationItem,
    MedicationSolution,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
} from '@mona/models';
import { getFormattedMedicationData, getMedicationCategory } from '@mona/pdms/data-access-combined';
import { requireInput } from '@mona/ui/utils';

/**
 * Medication Item Component
 *
 * @example
 *
 * ```html
 * <mona-medication-item
 *      [item]="item"
 *      [selectable]="true"
 *      [selected]="selected?.id === item.id"
 *      [medicationCategories]="medicationCategories"
 *      [medicationSolutions]="medicationSolutions"
 *      [medicationAdministrationMethods]="medicationAdministrationMethods"
 *      [medicationFrequencies]="medicationFrequencies"
 *      [medicationDosageForms]="medicationDosageForms"
 *  ></mona-medication-item>
 * ```
 */
@Component({
    selector: 'mona-medication-item',
    templateUrl: './medication-item.component.html',
    styleUrls: ['./medication-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class MedicationItemComponent implements OnInit {
    /** Component class */
    @HostBinding('class') cmpClass = 'mona-medication-item';
    /** Show medication as list item */
    @HostBinding('class.mona-medication-item--inline')
    @Input()
    inline = false;
    /** Show medication list item with border */
    @HostBinding('class.mona-medication-item--has-border')
    @Input()
    selectable = false;
    /** Show medication list item as selected border */
    @HostBinding('class.mona-medication-item--selected')
    @Input()
    selected = false;
    /** Show medication list item divider */
    @Input() divider = false;
    /** Category field config key */
    @Input() fieldConfigKey = undefined;
    /** Medication item */
    private _item: MedicationItem;
    /** Medication item */
    get item(): MedicationItem {
        return this._item;
    }
    @Input() set item(value: MedicationItem) {
        if (value) {
            this._item = new MedicationItem(value);
        }
    }
    /** Medication category */
    @Input() medicationCategories: MedicationCategory[];
    /** medicationAdministrationMethods */
    @Input() medicationAdministrationMethods: MedicationAdministrationMethod[];
    /** Medications solutions */
    @Input() medicationSolutions: MedicationSolution[];
    /** Frequency dictionary */
    @Input() medicationFrequencies: PrescriptionFrequency[] = [];
    /** Frequency dictionary */
    @Input() medicationFrequencyTimes: PrescriptionFrequencyTime[] = [];
    /** Dosage forms */
    @Input() medicationDosageForms: MedicationDosageForm[];
    /** Get formatted medication data, e.g ` Amrinon, 6 ml, 100 ml, 30 ml/h, Continuous, NaCl ` */
    details!: string;
    /** Get medication category name */
    category!: string;
    /** Get medication category icon */
    icon!: string;

    /** * Assign details **ONCE** on init when all inputs ready */
    ngOnInit(): void {
        requireInput<MedicationItemComponent>(
            [
                'item',
                'medicationCategories',
                // 'medicationAdministrationMethods',
                // 'medicationSolutions',
                // 'medicationFrequencies',
                // 'medicationDosageForms',
            ],
            this,
        );

        this.category = getMedicationCategory(this.item, this.medicationCategories, 'displayName');
        this.icon = getMedicationCategory(this.item, this.medicationCategories, 'icon');
        this.details = getFormattedMedicationData(
            this.item,
            this.medicationAdministrationMethods,
            this.medicationSolutions,
            this.medicationFrequencies,
            this.medicationCategories,
            this.medicationDosageForms,
            this.fieldConfigKey,
        );
    }
}
