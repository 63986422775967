import { MonaTask, TaskIdsList, TaskList, TaskType } from '@mona/models';

/**
 * create tasks from task ids lists
 *
 * @param taskIdsLists
 */
export function createTasksList(taskIdsLists: TaskList<TaskIdsList>): MonaTask[] {
    const tasks: MonaTask[] = [];

    for (const timestamp in taskIdsLists) {
        for (const type of Object.values(TaskType)) {
            taskIdsLists[timestamp][type]?.forEach(prescriptionId => {
                tasks.push({
                    id: `${timestamp}_${prescriptionId}_${type}`,
                    timestamp,
                    prescriptionId,
                    type,
                });
            });
        }
    }

    return tasks;
}
