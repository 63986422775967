/** UiColumn interface  */
import { ElementRef, TemplateRef } from '@angular/core';

/**
 * ActiveColumnPredicate Function
 *
 * compares id column and its cells should be marked with css class `--active`
 */
export type ActiveColumnPredicateFn = (col: UiTableColumn) => boolean;

/**
 * Ui Column implmentation
 */
export class UiTableColumn implements UiTableColumn {
    /** Column name  */
    name: string;
    /** Column meta data (Context which will be passed to table cell template) */
    meta?: Record<string, any>;
    /** Column headerText  */
    headerText?: string;
    /** Column format  */
    format?: { name: 'date' | 'number' | any; option?: any };
    /** Column data accessor key to use with pipe */
    dataAccessorKey?: string;
    /** Column css class */
    class?: string;
    /** Column width */
    width?: number;
    /** Column span */
    colspan?: number;
    /** Column is active */
    isActive?: boolean;
    /** Column is active */
    isVisible?: boolean;
    /** Column is sortable  */
    sortable?: boolean;
    /** Is timezone shift */
    isDtsShift?: boolean;
    /** Column has value  */
    hasValue?: (...args) => boolean;
    /** Column is disabled  */
    disabled?: (...args) => boolean;
    /** Cell template */
    cellTemplate?: TemplateRef<any>;
    /** @ignore */
    constructor(data: UiTableColumn) {
        this.name = data.name;
        this.meta = data.meta;
        this.headerText = data.headerText;
        this.format = data.format;
        this.dataAccessorKey = data.dataAccessorKey;
        this.class = data.class;
        this.width = data.width;
        this.colspan = data.colspan;
        this.isActive = data.isActive;
        this.isVisible = data.isVisible;
        this.sortable = data.sortable;
        this.isDtsShift = data.isDtsShift;
        this.hasValue = data.hasValue;
        this.disabled = data.disabled;
    }
}
