import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Patient } from '@mona/models';
import { PatientsSelectors, PatientsActions } from '../state';

/**
 * Patients facade
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessPatientsFacade {
    /** Is loading */
    isLoading$: Observable<boolean> = this.store.select(PatientsSelectors.selectPatientsStateIsLoading);

    /**
     * Get practitioners map
     */
    patients$: Observable<Patient[]> = this.store.select(PatientsSelectors.selectAllPatients);

    /**
     * Constructor
     *
     * @param {Store} store - store
     */
    constructor(private store: Store<any>) {}

    /**
     * Get search patients
     *
     * @param searchQuery
     * @param isAdmissionSearch
     */
    getSearchPatient(searchQuery: string, isAdmissionSearch = false): void {
        this.store.dispatch(PatientsActions.searchPatient({ searchQuery, isAdmissionSearch }));
    }

    /**
     * Clears patients
     */
    clearPatientSearchResult() {
        this.store.dispatch(PatientsActions.clearSearchPatient());
    }
}
