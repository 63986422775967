import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Async, AsyncComponent, OnChange, SimpleChange } from '@mona/shared/utils';
import { AllergiesForm, AllergyItemForm } from '../../models';

/**
 * Allergies form
 */
@Async()
@Component({
    selector: 'app-allergies-form',
    templateUrl: './allergies-form.component.html',
    styleUrls: ['./allergies-form.component.scss'],
})
export class AllergiesFormComponent extends AsyncComponent {
    /**
     * The form for added items
     */
    @Input() formGroup: FormGroup<AllergiesForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Is form active / visible
     */
    @OnChange(function (this: AllergiesFormComponent, value: boolean, change: SimpleChange<boolean>) {
        if (value === false && change.previousValue === true) {
            this.addAllergy();
        }
    })
    @Input()
    isActive: boolean;

    /**
     * Is read only
     */
    @Input() isReadOnly: boolean;

    /**
     * Reference to the new item input html element
     */
    @ViewChild('newItemInput') newItemInput: ElementRef<HTMLInputElement>;

    /**
     * The internal form group for new items
     */
    newItem = new FormGroup<AllergyItemForm>({
        text: new FormControl<string>(null),
    });

    /**
     * Constructor
     */
    constructor() {
        super();
    }

    /**
     * Removes an allergy from the array
     *
     * @param index index of the item that should be removed
     */
    removeAllergy(index: number) {
        this.formGroup.controls.items.removeAt(index);
        this.formGroup.controls.items.updateValueAndValidity();
    }

    /**
     * React on native blur event
     */
    onNewItemNativeBlur() {
        // Check for BedSideMode is needed as the on screen keyboard blurs the input in every keystroke.
        if (this.isBedSideMode === false) {
            this.addAllergy();
        }
    }

    /**
     * Adds a new allergy to the array.
     */
    addAllergy() {
        // There is no validator set on the form control to ensure, that the field is not displayed in error state on
        // submit.
        if (this.newItem.value.text && this.newItem.value.text.trim().length > 0) {
            if (!this.isBedSideMode || !this.isActive) {
                this.addArrayItem(this.newItem.value);

                // Ensure that the new input stays focussed
                if (this.newItemInput && this.isActive) {
                    this.newItemInput.nativeElement.focus();
                }
            }
        }
    }

    /**
     * Adds a new form control item for an allergy
     *
     * @param item AllergyItemForm
     */
    addArrayItem(item: any) {
        // Create a new form group with validator
        const itemToAdd = new FormGroup<AllergyItemForm>({
            text: new FormControl<string>(null, Validators.required),
        });

        // Fill new form group
        itemToAdd.reset(item);

        // Add to list
        this.formGroup.controls.items.push(itemToAdd);
        this.formGroup.controls.items.updateValueAndValidity();

        // Reset the new item form
        this.newItem.reset();
    }

    /**
     * Called on form submit.
     * Adds a new allergy on form submit
     *
     * @param $event
     */
    onSubmit($event?: Event) {
        // If this is a real form submit dont act as normal
        if ($event) {
            $event.preventDefault();
        }

        // Add the new allergy if filled out
        this.addAllergy();
    }
}
