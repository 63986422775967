import { inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { OnInitEffects } from '@ngrx/effects/src/lifecycle_hooks';
import { Action, Store } from '@ngrx/store';
import { Logger } from '@mona/shared/logger';
import { MessageService } from '@mona/ui';
import { LoginPayload, LoginPayloadRfid } from '../../models';
import { AuthApi } from '../../services';
import { AuthActions } from '../actions';

/**
 * Base class for auth effects(abstract)
 */
export abstract class BaseAuthEffects {
    protected logger = new Logger('AUTH');
    protected actions$: Actions = inject(Actions);
    protected authApi: AuthApi = inject(AuthApi);
    protected store = inject(Store);
    protected messageService: MessageService = inject(MessageService);

    /**
     * Login Payload type-guard
     *
     * @param p payload
     * @example
     * ```ts
     * const isLoginPayloadRfid = (p: LoginPayload): p is LoginPayloadRfid => !!p && p.hasOwnProperty('rfid');
     * ```
     */
    static isLoginPayloadRfid = (p: LoginPayload): p is LoginPayloadRfid => !!p && p.hasOwnProperty('rfid');
}
