import { MedicationAdministrationMethodCode } from './medication-administration-method.model';
import { MedicationCategoryCode } from './medication-category.model';
import { MedicationCode } from './medication.model';
import { PrescriptionFrequencyTimesArray } from './prescription-frequency-time.model';
import { PrescriptionFrequencyCode } from './prescription-frequency.model';
import { BaseMedication } from './standard-medication.model';

function isBaseMedication(item: AnyObject): item is BaseMedication {
    return item && item.hasOwnProperty('medicationCode');
}

/**
 * Medication item - as BASE INTERFACE
 */
export interface MedicationItem {
    /** ID of medication */
    id: string;
    /** The code of related medication category */
    category: MedicationCategoryCode;
    /** Medication code */
    code: MedicationCode;
    /** Description of medication */
    description: string;
    /** Amount */
    amount?: number;
    /** Unit amount */
    unitAmount?: string;
    /** Unit amount code */
    unitAmountCode?: string;
    /** Rate */
    rate?: number;
    /** Unit rate */
    unitRate?: string;
    /** Unit rate code */
    unitRateCode?: string;
    /** Volume */
    volume?: number;
    /** Unit volume  */
    unitVolume?: string;
    /** Unit volume code */
    unitVolumeCode?: string;
    /** Administration method code */
    method: MedicationAdministrationMethodCode;
    /** Frequency code */
    frequency: PrescriptionFrequencyCode;
    /** Frequency times */
    frequencyTimes?: PrescriptionFrequencyTimesArray;
    /** Solution code */
    solutionCode?: string;
    /** Dosage form for oral medications */
    dosageForm?: string;
}
/**
 * Medication item - as BASE INTERFACE
 */
export class MedicationItem implements MedicationItem {
    /**
     * Constructor
     *
     * @param obj MedicationItem
     */
    constructor(obj: MedicationItem | BaseMedication) {
        if (isBaseMedication(obj)) {
            this.category = obj.medication.categoryCode;
            this.code = obj.medicationCode;
            this.description = obj.medication.displayName;
            this.amount = obj.amount;
            this.unitAmount = obj.unitAmount;
            this.rate = obj.rate;
            this.unitRate = obj.unitRate;
            this.volume = obj.volume;
            this.unitVolume = obj.unitVolume;
            this.method = obj.method;
            this.frequency = obj.frequency;
            this.frequencyTimes = obj.frequencyTimes;
            this.solutionCode = obj.solutionCode;
            this.dosageForm = obj.dosageForm;
        } else {
            Object.assign(this, obj);
        }
    }
}
