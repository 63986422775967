import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isEmpty, isObject } from '@mona/shared/utils';
import { CUSTOM_INTERCEPTOR_HEADERS } from '../tokens';

/**
 * INFO: add comment
 */
@Injectable()
export class CustomHeaderInterceptor implements HttpInterceptor {
    /**
     * INFO: add comment
     * @param customHeaders
     */
    constructor(@Inject(CUSTOM_INTERCEPTOR_HEADERS) protected customHeaders: Record<string, string>) {}

    /**
     * INFO: add comment
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!isObject(this.customHeaders) || isEmpty(this.customHeaders)) {
            return next.handle(req);
        } else {
            req = req.clone();
            Object.entries(this.customHeaders).forEach(([key, value]) => req.headers.append(key, value));
            return next.handle(req);
        }
    }
}
