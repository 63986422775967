import { ChangeDetectorRef, Directive, ElementRef, HostBinding, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { isEmpty, isEmptyObject } from '@mona/shared/utils';
import { UiTableCell, UiTableCellValue, UiTableColumn, UiTableRow } from '../models';

/**
 * Ui table cell SVG directive
 */
@Directive({
    selector: '[uiTableCellSvg]',
})
export class UiTableCellSvg implements OnInit {
    @Input() cellContext: UiTableCell;

    /** @internal */
    constructor(private elRef: ElementRef, private render: Renderer2) {}

    /** @internal */
    ngOnInit(): void {
        const svg = this.elRef.nativeElement as HTMLElement;
        const td = this.elRef.nativeElement.parentElement as HTMLTableCellElement;
        const width = td.clientWidth;
        const height = td.parentElement.clientHeight;
        // this.render.setAttribute(svg, 'width', width as any);
        // this.render.setAttribute(svg, 'height', height as any);
        this.render.setAttribute(svg, 'viewBox', `0 0 ${width} ${height}`);
        const line = this.render.createElement('line');
        this.render.setAttribute(line, 'x1', '0');
        this.render.setAttribute(line, 'y1', (height / 2) as any);
        this.render.setAttribute(line, 'x2', width as any);
        this.render.setAttribute(line, 'y2', (height / 2) as any);
        this.render.setAttribute(line, 'stroke', '#9575cd');
        this.render.setAttribute(line, 'stroke-width', '2');
        this.render.addClass(line, 'rangesvg__line');
        this.render.addClass(line, 'rangesvg__line--full');
        this.render.appendChild(svg, line);

        // <line [visibleIf]="isShowFullLine" [attr.x1]="0" [attr.y1]="height / 2" [attr.x2]="width" [attr.y2]="height / 2" class="rangesvg__line rangesvg__line--full" />
    }
}
