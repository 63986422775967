import { NgModule } from '@angular/core';
import { UtilsModule } from '@mona/shared/utils';
import { UiClickZonelessDirective } from './click-zoneless.directive';
import { UiDisableWhenDirective } from './disable-when.directive';
import { NgLetDirective } from './ngLet.directive';
import { VisibleIfDirective } from './visible-if.directive';

const DIRECTIVES = [NgLetDirective, UiClickZonelessDirective, UiDisableWhenDirective, VisibleIfDirective];

/**
 * UiDirectivesModule - common shared directives
 */
@NgModule({
    declarations: DIRECTIVES,
    imports: [UtilsModule],
    exports: DIRECTIVES,
})
export class UiDirectivesModule {}
