/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { FhirConfig } from '@mona/models';

export const loadFhirConfigs = createAction('FHIR_CONFIG:LOAD_FHIR_CONFIGs');
export const loadFhirConfigsSuccess = createAction(
    'FHIR_CONFIG:LOAD_FHIR_CONFIGS_SUCCESS',
    props<{ data: FhirConfig }>(),
);
export const loadFhirConfigsFailure = createAction('FHIR_CONFIG:LOAD_FHIR_CONFIGS_FAILURE', props<{ error: any }>());
