import { ApiModels } from '@mona/api';
import { Encounter, EncounterHistoryEntry, HistoryEntryType, HistoryEventCode } from '@mona/models';

/**
 * Transforms API Encounter
 *
 * @param apiEncounter apiEncounter
 */
export const transformApiEncounter = (apiEncounter: ApiModels.Encounter): Encounter => {
    const model = ApiModels.Encounter.DTO.toModel(apiEncounter);
    return model;
};

/**
 * Transforms API Encounters
 *
 * @param apiEncounters apiEncounters
 */
export const transformApiEncounters = (apiEncounters: ApiModels.Encounter[]): Encounter[] => {
    return apiEncounters.map(encounter => transformApiEncounter(encounter));
};

/**
 * Transforms API encounter history entry
 *
 * @param apiHistoryEntry ApiModels.History
 */
export const transformApiEncounterHistoryEntry = (apiHistoryEntry: ApiModels.PatientHistory): EncounterHistoryEntry => {
    const model = ApiModels.PatientHistory.DTO.toModel(apiHistoryEntry);
    (model as EncounterHistoryEntry).text = model.value;
    return model as EncounterHistoryEntry;
};

/**
 * Transforms API encounter history entries
 *
 * @param apiHistoryEntries ApiModels.History[]
 */
export const transformApiEncounterHistoryEntries = (
    apiHistoryEntries: ApiModels.PatientHistory[],
): EncounterHistoryEntry[] => {
    return apiHistoryEntries.map(entry => transformApiEncounterHistoryEntry(entry));
};
