<mat-dialog-content class="app-surgery-dialog__content">
    <div>
        <h2 mat-dialog-title class="app-surgery-dialog__title">
            {{
                data.surgery.id
                    ? ('apps.patient.overview.editSurgery' | translate)
                    : ('apps.patient.overview.addSurgery' | translate)
            }}
        </h2>
        <app-surgery-prescription-form
            [formGroup]="surgeryForm"
            [isBedSideMode]="data.isBedSideMode"
            [isActive]="true"
        ></app-surgery-prescription-form>
    </div>

    <div class="app-surgery-dialog__actions">
        <button mat-button class="app-surgery-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'apps.patient.overview.dialogCancel' | translate }}
        </button>
        <button mat-raised-button color="primary" cdkFocusInitial (click)="submitChange()">
            {{ 'apps.patient.overview.dialogSave' | translate }}
        </button>
    </div>
</mat-dialog-content>
