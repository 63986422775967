<h2 mat-dialog-title class="mona-dialog__title">
    {{ 'errorDetailsDialog.title' | translate }}
    <button class="mona-dialog__close" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<div mat-dialog-content class="mona-dialog__content">
    <div>
        <div class="mona-dialog__details scrollbar-visible" *ngIf="!data.isCustomErrorMessage">
            <pre><code class="mona-dialog__code">Code:</code></pre>
            <pre><code class="mona-dialog__code">{{data.error?.errorCode}}</code></pre>
            <pre><code class="mona-dialog__code">Plain:</code></pre>
            <pre><code class="mona-dialog__code">{{data.error?.originalError}}</code></pre>
            <pre><code class="mona-dialog__code">JSON:</code></pre>
            <pre><code class="mona-dialog__code">{{ parsedError | json}}</code></pre>

            <ng-container *ngIf="mediaDevices">
                <pre><code class="mona-dialog__code">Media devices:</code></pre>
                <ng-container *ngIf="mediaDevices.length; else noDevices">
                    <pre *ngFor="let device of mediaDevices"><code
                        class="mona-dialog__code">{{device.kind}}: {{device.label}}
                        id = {{device.deviceId}}</code></pre>
                </ng-container>

                <ng-template #noDevices>
                    <pre><code class="mona-dialog__code">Not found</code></pre>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="mediaDevicesError">
                <pre><code class="mona-dialog__code">Enumerate Devices Error Plain:</code></pre>
                <pre><code class="mona-dialog__code">{{mediaDevicesError}}</code></pre>
                <pre><code class="mona-dialog__code">Enumerate Devices Error JSON:</code></pre>
                <pre><code class="mona-dialog__code">{{mediaDevicesError}}</code></pre>
            </ng-container>
        </div>
        <div class="mona-dialog__details scrollbar-visible" *ngIf="data.isCustomErrorMessage">
            <span class="mona-dialog__custom">{{ data.error?.originalError }}</span>
        </div>
    </div>
</div>
<div class="mona-dialog__contact" *ngIf="data.hasContactInformation">
    Please contact
    <span>support@clinomic.ai</span>
</div>
<!-- <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
</div> -->
