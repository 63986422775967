import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * On screen keypad button
 */
@Component({
    selector: 'mona-on-screen-keypad-button',
    templateUrl: './on-screen-keypad-button.component.html',
    styleUrls: ['./on-screen-keypad-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnScreenKeypadButtonComponent {
    /**
     * Submit button has different styles
     */
    @Input() isSubmit: boolean;

    /**
     * Click output
     */
    @Output() clicked: EventEmitter<void> = new EventEmitter();
}
