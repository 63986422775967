import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, pluck } from 'rxjs/operators';
import { FhirConfig, FhirConfigEntry, SearchDiagnosisResult, VerificationStatus } from '@mona/models';
import { DataAccessFhirConfigFacade } from '@mona/pdms/data-access-fhir';
import { TerminologyService } from '@mona/pdms/data-access-terminology';
import { DiagnosesItemForm } from '@mona/pdms/shared';
import { Async, AsyncComponent } from '@mona/shared/utils';

/**
 * Single diagnosis form
 */
@Async()
@Component({
    selector: 'app-single-diagnosis-form',
    templateUrl: './single-diagnosis-form.component.html',
    styleUrls: ['./single-diagnosis-form.component.scss'],
})
export class SingleDiagnosisFormComponent extends AsyncComponent implements OnInit, OnDestroy {
    /**
     * The form for added items
     */
    @Input() formGroup: FormGroup<DiagnosesItemForm>;

    /**
     * Is bed side mode
     */
    @Input() isBedSideMode: boolean;

    /**
     * Can change diagnosis
     */
    @Input() canChangeDiagnosis: boolean;

    /**
     * Available verification states
     */
    verificationStates = Object.keys(VerificationStatus)
        .map(key => VerificationStatus[key])
        .filter(value => typeof value === 'string')
        .map((state: string) => {
            return {
                enumMember: state,
                label: this.translateService.instant('verificationStatus.' + state.replace(/-/g, '').toLowerCase()),
            };
        });

    /**
     * Min length for autocomplete
     */
    autoCompleteMinLength = 3;

    /**
     * Rendered autocomplete controls
     * Prepared as ViewChildren in case that later there will be more autocompletes
     */
    @ViewChildren(MatAutocomplete)
    private autocompletes: QueryList<MatAutocomplete>;

    /**
     * Search results for the autocomplete
     */
    foundDiagnoses$ = this.terminologyService.getSearchDiagnosisAction().pipe(
        map(state => {
            const result = state?.result && [...state.result];
            const controlValue = this.formGroup.controls.text.value;
            if (result && !result.find(item => item.displayName === controlValue)) {
                result.unshift({
                    displayName: controlValue,
                    code: null,
                });
            }
            return result;
        }),
    );

    /**
     * Status of the autocomplete search
     */
    searchDiagnosisInProgress$ = this.terminologyService
        .getSearchDiagnosisAction()
        .pipe(map(state => state && state.inProgress));

    /**
     * Diagnoses fhir config
     */
    diagnosesFhirConfig$: Observable<FhirConfigEntry> = this.fhirConfigFacade.fhirConfig$.pipe(
        filter<FhirConfig>(Boolean),
        pluck('diagnoses'),
    );

    /**
     * Constructor
     *
     * @param translateService
     * @param terminologyService
     * @param fhirConfigFacade
     */
    constructor(
        private translateService: TranslateService,
        private terminologyService: TerminologyService,
        private fhirConfigFacade: DataAccessFhirConfigFacade,
    ) {
        super();
    }

    /**
     * NG Hook
     */
    ngOnInit() {
        if (!this.canChangeDiagnosis) {
            this.formGroup.controls.icd10Code.disable();
        }

        // New item autocomplete field
        this.async(
            this.formGroup.controls.text.valueChanges
                .pipe(
                    debounceTime(400),
                    filter(() => !this.formGroup.controls.text.disabled),
                )
                .subscribe(value => {
                    if (value && value.length >= this.autoCompleteMinLength) {
                        this.terminologyService.searchDiagnosis(value);
                    } else {
                        this.clearAutoCompleteResults();
                    }
                }),
        );
    }

    /**
     * NG hook
     */
    ngOnDestroy() {
        this.clearAutoCompleteResults();
    }

    /**
     * Reset diagnosis to empty
     */
    resetDiagnosis() {
        this.formGroup.patchValue({
            text: '',
            status: VerificationStatus.CONFIRMED,
            icd10Code: null,
        });
        this.formGroup.controls.text.enable();
    }

    /**
     * Formats the output for autocomplete
     *
     * @param result DiagnosisSearchResult | string
     */
    autoCompleteDisplayFn(result: SearchDiagnosisResult | string): string {
        return typeof result === 'string' ? result : result?.displayName || '';
    }

    /**
     * Adds an autocomplete item as new entry
     *
     * @param $event MatAutocompleteSelectedEvent
     */
    onNewItemAutoCompleteSelect($event: MatAutocompleteSelectedEvent) {
        this.formGroup.patchValue(
            {
                icd10Code: $event.option.value.code,
                text: $event.option.value.displayName,
            },
            {
                emitEvent: false,
            },
        );

        // Close all open autocomplete panels
        this.autocompletes.toArray().forEach(autocomplete => {
            autocomplete.showPanel = false;
        });

        this.clearAutoCompleteResults();
    }

    /**
     * Clears the auto complete search results
     */
    clearAutoCompleteResults() {
        this.terminologyService.clearSearchDiagnoses();
    }
}
