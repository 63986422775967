import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, StaticProvider } from '@angular/core';
import {
    DEFAULT_OVERLAY_CONFIGURATION,
    UiOverlayContainerConfiguration,
} from './models/overlay-container-configuration.interface';
import { UiOverlayContainerParameters } from './models/overlay-container-parameters.interface';
import { UiPopoverRef } from './popover-component/popover-reference';
import { UiPopoverComponent } from './popover-component/popover.component';

/**
 * The OverlayContainerService is an injectable service to open a {@link UiPopoverComponent} that behaves as a parent
 * where anything from text, component or template can be embedded dynamically.
 *
 */
@Injectable({ providedIn: 'root' })
export class UiOverlayContainerService {
    /**
     * Constructor
     *
     * @param overlay
     * @param injector
     */
    constructor(private overlay: Overlay, private injector: Injector) {}

    /**
     * Opens an popover relative to the `origin` with the provided `content`.
     * _param T The data passed into the container to be available in the embedded e.g. component
     * _param R The response data type returned from the afterClosed$ observable when calling close(data?: R)
     *
     * @param params.content The dynamic content to be rendered: 'template' | 'component' | 'text'
     * @param params.origin The origin to which the popover is attached. Not needed if used in combination with OverlayContainerConfiguration.useGlobalPositionStrategy = true. If the overlay can't be displayed on the screen, fallback positions are used
     * @param params.data Any data that is needed in the rendered component (accessible from the component constructor via the PopoverRef (DI)) or in the template via template variable let-data
     * @param params.configuration Any custom overlay configuration
     * @returns The reference to the PopoverRef
     */
    open<T = any, R = any>({
        content,
        origin,
        data,
        configuration,
    }: UiOverlayContainerParameters<T>): UiPopoverRef<T, R> {
        const configurationApplied = { ...DEFAULT_OVERLAY_CONFIGURATION, ...configuration };
        const overlayRef = this.overlay.create(this.getOverlayConfig(origin, configurationApplied));

        if (configuration?.panelClass) {
            overlayRef.addPanelClass(configuration.panelClass);
        }

        if (configurationApplied?.isResizable) {
            overlayRef.addPanelClass('isResizable');
        }

        const popoverRef = new UiPopoverRef<T, R>(
            this.overlay,
            overlayRef,
            content,
            data,
            configuration?.isDraggable ?? DEFAULT_OVERLAY_CONFIGURATION.isDraggable,
            configuration?.disableBackdropClose ?? DEFAULT_OVERLAY_CONFIGURATION.disableBackdropClose,
        );

        const injector = this.createInjector(popoverRef, this.injector);
        overlayRef.attach(new ComponentPortal(UiPopoverComponent, null, injector));

        return popoverRef;
    }

    private getOverlayConfig(origin: HTMLElement, configuration: UiOverlayContainerConfiguration): OverlayConfig {
        const config = new OverlayConfig({
            width: configuration.width,
            height: configuration.height,
            hasBackdrop: configuration.hasBackdrop,
            panelClass: DEFAULT_OVERLAY_CONFIGURATION.panelClass,
            backdropClass: configuration.backdropClass,
            positionStrategy: this.getOverlayPosition(origin, configuration),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });

        return Object.assign(config, {
            ...(configuration.minWidth && { minWidth: configuration.minWidth }),
            ...(configuration.minHeight && { minHeight: configuration.minHeight }),
            // ...(configuration.maxWidth && { maxWidth: configuration.maxWidth }),
            // ...(configuration.maxHeight && { maxHeight: configuration.maxHeight })
        });
    }

    private getOverlayPosition(origin: HTMLElement, configuration: UiOverlayContainerConfiguration): PositionStrategy {
        const positionStrategy = configuration.useGlobalPositionStrategy
            ? this.overlay
                  .position()
                  .global()
                  .centerHorizontally(configuration.offsetX.toString())
                  .centerVertically(configuration.offsetY.toString())
            : this.overlay
                  .position()
                  .flexibleConnectedTo(origin)
                  .withPositions(this.getPositions(configuration))
                  .withPush(false);

        return positionStrategy;
    }

    private getPositions(configuration: UiOverlayContainerConfiguration): ConnectionPositionPair[] {
        return [
            {
                originX: configuration.originX,
                originY: configuration.originY,
                overlayX: configuration.overlayX,
                overlayY: configuration.overlayY,
                offsetX: configuration.offsetX,
                offsetY: configuration.offsetY,
            }, // Fallback positions if provided position is not possible
            {
                // Bottom
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
            },
            {
                // Right
                originX: 'end',
                originY: 'center',
                overlayX: 'start',
                overlayY: 'center',
            },
            {
                // Left
                originX: 'start',
                originY: 'center',
                overlayX: 'end',
                overlayY: 'center',
            },
            {
                // Top
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
            },
        ];
    }

    private createInjector(popoverRef: UiPopoverRef, injector: Injector): Injector {
        const providers: StaticProvider[] = [{ provide: UiPopoverRef, useValue: popoverRef }];
        return Injector.create({ providers });
    }
}
