// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializes Prescription Not Given Reasons from database.
 * @see #/components/schemas/PrescriptionNotGivenReason - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PrescriptionNotGivenReason {
    readonly id: string;
    /**
     * The text for the prescription not given reason.
     *
     */
    text?: string | null;
    /**
     * `administration` - Input * `procedure` - Procedure
     *
     */
    prescription_type: PrescriptionNotGivenReason.PrescriptionTypeEnum;
    is_default?: boolean;
    show_textbox?: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PrescriptionNotGivenReason
 */
export namespace PrescriptionNotGivenReason {
    export type PrescriptionTypeEnum = 'administration' | 'procedure';
    export const PrescriptionTypeEnum = {
        Administration: 'administration' as PrescriptionTypeEnum,
        Procedure: 'procedure' as PrescriptionTypeEnum,
    };

    /**
     * PrescriptionNotGivenReason Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        readonly id: string;
        /**
         * The text for the prescription not given reason.
         * @type string (`dataFormat` is missing)
         */
        text?: string | null;
        /**
         * `administration` - Input * `procedure` - Procedure
         * @type string (`dataFormat` is missing)
         */
        prescriptionType: PrescriptionNotGivenReason.PrescriptionTypeEnum;
        isDefault?: boolean;
        showTextbox?: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PrescriptionNotGivenReason DTO
     * Transforms PrescriptionNotGivenReason model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PrescriptionNotGivenReason model */
        static toModel(obj: PrescriptionNotGivenReason): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PrescriptionNotGivenReason model */
        static toApi(obj: Model): PrescriptionNotGivenReason {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PrescriptionNotGivenReason;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
