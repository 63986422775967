/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { DailyGoal } from '@mona/models';

export namespace DailyGoalsActions {
    export const loadDailyGoals = createAction('DAILY_GOALS:LOAD_DAILY_GOALS');
    export const loadDailyGoalsSuccess = createAction(
        'DAILY_GOALS:LOAD_DAILY_GOALS_SUCCESS',
        props<{ data: DailyGoal[] }>(),
    );
    export const loadDailyGoalsFailure = createAction('DAILY_GOALS:LOAD_DAILY_GOALS_FAILURE', props<{ error: any }>());

    export const updateDailyGoals = createAction(
        'DAILY_GOALS:UPDATE_DAILY_GOALS',
        props<{ update: Update<DailyGoal>[] }>(),
    );

    export const upsertDailyGoals = createAction('DAILY_GOALS:UPSERT_DAILY_GOALS', props<{ data: DailyGoal[] }>());

    export const clearDailyGoals = createAction('DAILY_GOALS:CLEAR_DAILY_GOALS');

    export const addDailyGoalsChanges = createAction(
        'DAILY_GOALS:ADD_CHANGES',
        props<{ toUpdateEntities: DailyGoal[]; toRemoveIds: string[] }>(),
    );
}
