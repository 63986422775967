/* eslint-disable @typescript-eslint/ban-types */
import { Platform as CdkPlatform } from '@angular/cdk/platform';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';
import { environment } from '@environment';
import { WINDOW } from '@ng-web-apis/common';

/**
 * Service to detect the current platform by comparing the userAgent strings and
 * checking browser-specific global properties.
 */
export const PLATFORM = new InjectionToken<Platform>('Platform DI Token', {
    providedIn: 'root',
    factory: () => new Platform(inject(PLATFORM_ID), inject(WINDOW)),
});

/**
 * testUserAgent helper
 *
 * @param win
 * @param expr
 */
export const testUserAgent = (win: Window, expr: RegExp) => expr.test(win.navigator.userAgent);

/**
 * matchMedia helper
 *
 * @param win
 * @param query
 */
export const matchMedia = (win: Window, query: string): boolean => win.matchMedia(query).matches;

/**
 * @param win
 * @borrows https://gist.github.com/esedic/39a16a7521d42ae205203e3d40dc19f5
 * Detection logic:
 * 1. If Pointer Events are supported, it will just check the navigator.maxTouchPoints property
 * 2. If Pointer Events are not supported, it checks the any-pointer:coarse interaction media feature using window.matchMedia.
 * 3. Check for Touch Events support
 */
export const detectTouchscreen = (win: Window & any /* { PointerEvent: any; TouchEvent: any } */) => {
    let result = false;
    if (win.PointerEvent && 'maxTouchPoints' in win.navigator) {
        // if Pointer Events are supported, just check maxTouchPoints
        if (win.navigator.maxTouchPoints > 0) {
            result = true;
        }
    } else {
        // no Pointer Events...
        if (win.matchMedia && win.matchMedia('(any-pointer:coarse)').matches) {
            // check for any-pointer:coarse which mostly means touchscreen
            result = true;
        } else if (win.TouchEvent || 'ontouchstart' in win) {
            // last resort - check for exposed touch events API / event handler
            result = true;
        }
    }
    return result;
};

/**
 * Extended Platform DI
 *
 * @augments https://github.com/angular/components/blob/master/src/cdk/platform/ts
 * @borrows https://github.com/ionic-team/ionic-framework/blob/master/core/src/utils/ts
 * @borrows https://github.com/docluv/add-to-homescreen/blob/master/src/addtohomescreen.js#L141-L160
 */
export class Platform extends CdkPlatform {
    /** NodeJS.Platform - OS level */
    readonly platform: NodeJS.Platform = environment.platform;
    /**
     * Navigator
     */
    get navigator() {
        return this._win.navigator as Navigator & { standalone: boolean };
    }

    /**
     * Constructor
     *
     * @param _platformId
     * @param _win
     */
    constructor(_platformId: Object, private _win: Window) {
        super(_platformId);
    }

    isLinux = this.platform === 'linux';

    isMobile = detectTouchscreen(this._win);
    isDesktop = !this.isMobile;

    isCordova = !!(this._win['cordova'] || this._win['phonegap'] || this._win['PhoneGap']);
    isCapacitorNative = !!(this._win['Capacitor'] && this._win['Capacitor'].isNative);
    isHybrid = this.isCordova || this.isCapacitorNative;

    isElectron: boolean = testUserAgent(this._win, /electron/i);
    isChromium = this.isBrowser && 'onbeforeinstallprompt' in this._win;

    isInWebAppiOS = this.isBrowser && this.navigator.standalone === true;
    isInWebAppChrome =
        this.isBrowser &&
        (this._win.matchMedia('(display-mode: fullscreen)').matches ||
            this._win.matchMedia('(display-mode: standalone)').matches ||
            this._win.matchMedia('(display-mode: minimal-ui)').matches);

    isMobileSafari = this.IOS && testUserAgent(this._win, /Safari/i) && testUserAgent(this._win, /CriOS/i);
    isiPad = this.isMobileSafari && testUserAgent(this._win, /iPad/i);
    isiPhone = this.isMobileSafari && testUserAgent(this._win, /iPad/i);

    isStandalone = this.isInWebAppiOS || this.isInWebAppChrome;
    // https://stackoverflow.com/questions/54580414/how-can-i-detect-if-my-website-is-opened-inside-a-trusted-web-actvity
    isTWA = this.isInWebAppChrome && this._win.document.referrer.includes('android-app://');

    isPwaCompatible = !this.isHybrid && (this.isChromium || this.isMobileSafari || this.IOS);
}
