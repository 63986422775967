import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

/**
 * Defines if the component can lock the screen
 */
export interface ComponentConfirmLockScreen {
    /**
     * Return true to allow lock screen, otherwise lock screen wont show
     */
    confirmLockScreen: () => Observable<boolean>;
}

/**
 * Holds a reference to a component that can block the manuel lock screen
 */
@Injectable({
    providedIn: 'root',
})
export class ConfirmLockScreenService {
    /**
     * Reference to the component using the can deactivate interface just like the router
     */
    private component: ComponentConfirmLockScreen = null;

    /**
     * Registers a component
     * @param component ComponentConfirmLockScreen
     */
    register(component: ComponentConfirmLockScreen) {
        this.component = component;
    }

    /**
     * Removes a component registration
     */
    deregister() {
        this.component = null;
    }

    /**
     * Runs confirmLockScreen method on component
     */
    confirmLockScreen(): Observable<boolean> {
        if (!this.component) {
            return of(true);
        }

        return this.component.confirmLockScreen();
    }
}
