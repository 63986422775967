import { ApiModels } from '@mona/api';
import { DischargeReason } from '@mona/models';

/**
 * Transforms api discharge
 *
 * @param apiDischargeReason
 */
export const transformApiDischargeReason = (apiDischargeReason: ApiModels.DischargeReason): DischargeReason => {
    return ApiModels.DischargeReason.DTO.toModel(apiDischargeReason);
};

/**
 * Transform api reports
 *
 * @param apiDischargeReasons any
 */
export const transformApiDischargeReasons = (apiDischargeReasons: any[]): DischargeReason[] => {
    return apiDischargeReasons.map(apiDischargeReason => transformApiDischargeReason(apiDischargeReason));
};
