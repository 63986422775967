import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * INFO: add comment
 */
@Pipe({
    name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
    /**
     * Constructor
     *
     * @param sanitizer DomSanitizer
     */
    constructor(private sanitizer: DomSanitizer) {}

    /**
     * Implementation
     *
     * @param html
     */
    transform(html: string): SafeHtml {
        return this.sanitizer.sanitize(1, html);
    }
}
