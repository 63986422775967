import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DailyGoalType } from '@mona/models';
import { DailyGoalsState } from '../../entities';
import { DailyGoalsActions } from '../actions/daily-goals.actions';

export const dailyGoalsFeatureKey = 'dailyGoals';

export const dailyGoalsAdapter: EntityAdapter<DailyGoalType> = createEntityAdapter<DailyGoalType>();

export const initialState: DailyGoalsState = dailyGoalsAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const reducer = createReducer(
    initialState,
    on(DailyGoalsActions.loadDailyGoals, state => ({ ...state, isLoading: true })),
    on(DailyGoalsActions.loadDailyGoalsSuccess, (state, { data }) =>
        dailyGoalsAdapter.setAll(data, {
            ...state,
            error: null,
            isLoading: false,
        }),
    ),
    on(DailyGoalsActions.loadDailyGoalsFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(DailyGoalsActions.updateDailyGoals, (state, action) => dailyGoalsAdapter.updateMany(action.update, state)),
    on(DailyGoalsActions.upsertDailyGoals, (state, action) => dailyGoalsAdapter.upsertMany(action.data, state)),
    on(DailyGoalsActions.clearDailyGoals, state => dailyGoalsAdapter.removeAll(state)),
    on(DailyGoalsActions.addDailyGoalsChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = dailyGoalsAdapter.removeMany(toRemoveIds, state);

        return dailyGoalsAdapter.upsertMany(toUpdateEntities, newState);
    }),
);
