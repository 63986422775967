import { CustomApiModels } from '@mona/api';
import { CallConfig } from '@mona/models';

/**
 * Transforms API call config
 *
 * @param apiConfig CustomApiModels.CallConfig
 */
export const transformApiTelemedicineConfig = (apiConfig: CustomApiModels.CallConfig): CallConfig => {
    return {
        iceServers: apiConfig.ice_servers,
    };
};
