import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FhirConfig } from '@mona/models';
import { FhirConfigState } from '../entities';
import { FhirSelectors, FhirActions } from '../state';

/**
 * FHIR settings service
 */
@Injectable({
    providedIn: 'root',
})
export class DataAccessFhirConfigFacade {
    /**
     * Get fhir config
     */
    fhirConfig$: Observable<FhirConfig> = this.store.select(FhirSelectors.selectFhirConfig);
    /**
     * Get fhir config loading
     */
    isLoading$: Observable<boolean> = this.store.select(FhirSelectors.selectFhirConfigIsLoading);
    /**
     * Constructor
     *
     * @param store Store
     */
    constructor(private store: Store<{ fhir: FhirConfigState }>) {}

    /**
     * Load fhir config
     */
    loadFhirConfig() {
        this.store.dispatch(FhirActions.loadFhirConfigs());
    }
}
