import { Update } from '@ngrx/entity';
import { ChangeLogEntry, MonaTask, TaskType } from '@mona/models';
import { ChangeLogMap, mergeChangesOnModelId } from '@mona/pdms/data-access-changelog';

/**
 * get tasks to upsert
 *
 * @param changeLogMap
 */
export function getUpdateTasksToComplete(changeLogMap: ChangeLogMap): Update<MonaTask>[] {
    const procChanges = changeLogMap?.['Procedure'];
    const medChanges = changeLogMap?.['MedicationAdministration'];

    if (!procChanges && !medChanges) {
        return [];
    }

    const tasks: Update<MonaTask>[] = [];

    tasks.push(...createTypeTasksFromChanges(procChanges, TaskType.ProcedurePrescriptions));
    tasks.push(...createTypeTasksFromChanges(medChanges, TaskType.MedicationPrescriptions));

    return tasks;
}

/**
 * create completed tasks from changes
 *
 * @param changes
 * @param taskType
 */
export function createTypeTasksFromChanges(changes: ChangeLogEntry[], taskType: TaskType): Update<MonaTask>[] {
    const mergedChanges = mergeChangesOnModelId(changes);

    return mergedChanges?.map(({ payload: prescription }) => {
        const date = taskType === TaskType.MedicationPrescriptions ? prescription.startDate : prescription.date;
        const timestamp = date?.toISOString().split('.')[0] + 'Z';

        return {
            id: `${timestamp}_${prescription.prescriptionId}_${taskType}`,
            changes: {
                isCompleted: true,
                change: { payload: prescription },
            },
        } as Update<MonaTask>;
    });
}

/**
 * create {@link Update<MonaTask>[]} from {@link MonaTask[]}
 *
 * @param tasks
 * @returns
 */
export function createUpdateTasks(tasks: MonaTask[]): Update<MonaTask>[] {
    return tasks.map(task => ({
        id: task.id,
        changes: task,
    }));
}
