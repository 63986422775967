import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

/**
 * Show maintenance mode
 */
@Component({
    selector: 'mona-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceComponent {
    /**
     * Host css class
     */
    @HostBinding('class.mona-maintenance') cmpClass = true;
}
