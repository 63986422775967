<div
    [visibleIf]="!!controls.times.value?.length || frequencyCode === customFrequencyCode"
    class="frequency-times__wrapper"
    [formGroup]="viewModelControl"
>
    <form novalidate *ngIf="!disabled" (ngSubmit)="$event.preventDefault()">
        <mat-form-field id="time-input-field" class="app-input-field" appearance="fill">
            <mat-label>
                {{ 'apps.settings.medications.frequencyTimes.time' | translate }}
            </mat-label>
            <input
                type="text"
                class="frequency-times__input"
                matInput
                [formControl]="controls.timeInput"
                placeholder="e.g, 10:00"
                mask="Hh:m0"
                [leadZeroDateTime]="true"
                [dropSpecialCharacters]="false"
                id="time-input"
                data-testid="time-input"
                data-keyboard-layout="numeric"
                (keydown.enter)="addTime($event)"
            />
            <mat-hint *ngIf="hasMaximumLength">
                {{ 'apps.settings.medications.frequencyTimes.cannotAddTime' | translate }}
            </mat-hint>
            <mat-error>
                <ui-validation-message [control]="controls.timeInput"></ui-validation-message>
            </mat-error>
            <mat-hint>
                <ui-validation-message [control]="ngControl" class="text-accent"></ui-validation-message>
            </mat-hint>
        </mat-form-field>

        <button
            class="frequency-times__button--add"
            mat-button
            color="primary"
            [disabled]="addBtnDisabled"
            (click)="addTime($event)"
        >
            {{ 'apps.settings.medications.frequencyTimes.addTime' | translate }}
        </button>
    </form>

    <div class="frequency-times__list">
        <mat-chip-listbox #chipList multiple [formControl]="controls.times">
            <mat-chip-option
                *ngFor="
                    let time of convertUTCHoursToLocalTimeFn(controls.times.value);
                    let i = index;
                    trackBy: trackByFn
                "
                [selectable]="false"
                [removable]="true"
                [disabled]="disabled"
                (removed)="removeTime(i, time)"
            >
                {{ time.slice(0, -3) }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
