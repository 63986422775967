<div
    class="mona-encounter-info__container"
    [class.not-assigned-bed]="isNotAssignedBedSelected"
    [class.is-review-mode]="isReviewMode"
    *ngIf="encounterInfo && isForSidenav"
    (click)="navigateToPatientManagement()"
>
    <div
        class="mona-encounter-info__sidenav-item mona-encounter-info__sidenav-item--title"
        *ngIf="encounterInfo.patient"
    >
        <span>{{ encounterInfo.patient | patientDisplayName }}</span>
    </div>
    <div
        *ngIf="encounterInfo.patient?.dateOfBirth"
        class="mona-encounter-info__sidenav-item mona-encounter-info__sidenav-item--date"
    >
        <span>*{{ encounterInfo.patient?.dateOfBirth | date: 'dd.MM.YYYY' }}</span>
        <i>({{ encounterInfo.patient?.dateOfBirth | patientAge }})</i>
    </div>
    <div
        class="mona-encounter-info__sidenav-item mona-encounter-info__sidenav-item--subtitle"
        *ngIf="encounterInfo.ward && encounterInfo.bed"
    >
        <span>{{ encounterInfo.ward.name }}, {{ encounterInfo.bed.name }}</span>
    </div>
    <div
        class="mona-encounter-info__exclamation"
        [class.not-assigned-bed-font]="isNotAssignedBedSelected"
        [class.is-review-mode-font]="isReviewMode"
        *ngIf="isNotAssignedBedSelected || isReviewMode"
        [matTooltip]="
            isReviewMode
                ? ('warnings.encounterInReviewMode' | translate) +
                  (hasEditViewModePermissions ? '' : ' ' + ('warnings.encounterCantEdit' | translate))
                : ('warnings.bedEncounterAssignmentDialog' | translate)
        "
        matTooltipPosition="below"
        matTooltipClass="mona-encounter-info__exclamation-tooltip"
        (click)="$event.stopPropagation()"
    >
        !
    </div>
</div>

<ng-container *ngIf="encounterInfo && !isForSidenav">
    <div class="mona-encounter-info__item mona-encounter-info__item--title" *ngIf="encounterInfo.patient">
        <mat-icon [inline]="true" [class.d-none]="hideIcons">face</mat-icon>
        <span>{{ encounterInfo.patient | patientDisplayName }}</span>
    </div>
    <div
        class="mona-encounter-info__item mona-encounter-info__item--subtitle"
        *ngIf="encounterInfo.ward && encounterInfo.bed"
    >
        <mat-icon [inline]="true" [class.d-none]="hideIcons">gps_fixed</mat-icon>
        <span>{{ encounterInfo.ward.name }}, {{ encounterInfo.bed.name }}</span>
    </div>
</ng-container>
