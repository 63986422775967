import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { timeZonesNames } from '@vvo/tzdb';
import { Observable } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import {
    DeviceSetupStatus,
    ManualConnection,
    ManualConnectionSettings,
    ManualConnectionType,
    TerminalKeysConfig,
    Timezone,
    TimezonesMap,
    WifiEntry,
} from '@mona/models';
import { AsyncActionState } from '@mona/store';
import { DeviceState } from '../entities';
import { DeviceActions, DeviceSelectors, NetworkManagerActions, SetupActions, SetupSelectors } from '../state';

/**
 * Device Facade Service
 */
@Injectable({ providedIn: 'root' })
export class DeviceFacade {
    /** Get device setup is loading */
    setupLoading$: Observable<boolean> = this.store.select(SetupSelectors.selectDeviceSetupLoading);
    /** Get device setup error */
    setupError$: Observable<any> = this.store.select(SetupSelectors.selectDeviceSetupError);
    /** Get device setup status */
    setupStatus$: Observable<DeviceSetupStatus> = this.store.select(SetupSelectors.selectDeviceSetupStatus);
    /** Get device activated */
    isActivated$: Observable<boolean> = this.store.select(SetupSelectors.selectDeviceActivated);
    /** Get network details */
    networkDetails$: Observable<string> = this.store.select(DeviceSelectors.selectDeviceNetworkDetails);
    /** Get MAC */
    macAddress$: Observable<string> = this.store.select(DeviceSelectors.selectDeviceMacAddress);
    /** Get brightness */
    brightness$: Observable<number> = this.store.select(DeviceSelectors.selectDeviceBrightness);
    /** Get current timeZone */
    timeZone$: Observable<Timezone> = this.store.select(DeviceSelectors.selectDeviceTimezone);
    /** Get keys */
    keys$: Observable<TerminalKeysConfig> = this.store.select(DeviceSelectors.selectDeviceKeys);
    /** Get camera state */
    isCameraActivated$: Observable<boolean> = this.store.select(DeviceSelectors.selectDeviceCameraActivation);
    /** Device activated event */
    deviceActivatedEvent$: Observable<boolean> = this.actionsObserver$.pipe(
        ofType(SetupActions.checkIsDeviceActivatedSuccess),
        filter(status => status.isActivated),
        mapTo(true),
    );

    /**
     * Constructor
     *
     * @param store DeviceState
     * @param actionsObserver$
     */
    constructor(private store: Store<{ device: DeviceState }>, private actionsObserver$: ActionsSubject) {}

    // =========================================================================
    // SETUP
    // =========================================================================

    /**
     * Register a device at backend
     */
    registerDevice(): void {
        this.store.dispatch(SetupActions.registerDevice());
    }

    /**
     * Checks device is activated
     */
    checkIsDeviceActivated(): void {
        this.store.dispatch(SetupActions.checkIsDeviceActivated());
    }
    /**
     * Clear device is activated
     */
    clearDeviceActivatedStatus(): void {
        this.store.dispatch(SetupActions.clearDeviceActivatedStatus());
    }

    // =========================================================================
    // NETWORK
    // =========================================================================

    /**
     * Gets the load wifi connections action
     */
    getLoadWifiConnectionsAction(): Observable<AsyncActionState<WifiEntry[]>> {
        return this.store.select(state => state?.device.network?.loadWifiEndpointsAction);
    }

    /**
     * Return available wifi entries
     */
    getWifiConnections(): Observable<WifiEntry[]> {
        return this.getLoadWifiConnectionsAction().pipe(
            map(action => {
                return action.result;
            }),
        );
    }

    /**
     * Return if wifi connections are current loading
     */
    isLoadingWifiConnections(): Observable<boolean> {
        return this.getLoadWifiConnectionsAction().pipe(
            map(action => {
                return action.inProgress;
            }),
        );
    }

    /**
     * Gets the load wifi connections action
     */
    getConnectToWifiEndpointAction(): Observable<AsyncActionState<string>> {
        return this.store.select(state => state?.device?.network?.connectToWifiEndpointAction);
    }

    /**
     * Whether connecting to wifi endpoint or not
     */
    isConnectingToWifiEndpoint(): Observable<boolean> {
        return this.getConnectToWifiEndpointAction().pipe(
            map(action => {
                return action.inProgress;
            }),
        );
    }

    /**
     * Is to wifi successfully or not. True for yes and not for false. This is not a real error.
     */
    isConnectedToWifiEndpointStatus(): Observable<string> {
        return this.getConnectToWifiEndpointAction().pipe(
            map(action => {
                return action.result;
            }),
        );
    }

    /**
     * Gets the load manual network settings action
     */
    getLoadManualNetworkSettingsAction(): Observable<AsyncActionState<ManualConnectionSettings>> {
        return this.store.select(state => state?.device?.network?.loadManuelNetworkSettings);
    }

    /**
     * Return the manual network settings
     */
    getManualNetworkSetting(): Observable<ManualConnectionSettings> {
        return this.getLoadManualNetworkSettingsAction().pipe(map(data => data.result));
    }

    /**
     * Trigger loading wifi endpoints
     */
    loadWifi() {
        this.store.dispatch(NetworkManagerActions.loadWifiEndpoints.action());
    }

    /**
     * Connect to to wifi endpoint
     *
     * @param ssid string
     * @param passphrase string
     */
    connectToWifiEndpoint(ssid: string, passphrase: string) {
        this.store.dispatch(
            NetworkManagerActions.connectToWifiEndpoint.action({
                ssid,
                passphrase,
            }),
        );
    }

    /**
     * Load Manual network settings
     *
     * @param deviceType ManualConnectionType
     */
    loadManualNetworkSettings(deviceType: ManualConnectionType) {
        this.store.dispatch(
            NetworkManagerActions.loadManualConnectionSettings.action({
                deviceType,
            }),
        );
    }

    /**
     * Connect to specific wifi
     *
     * @param ssid string
     * @param passphrase string
     */
    connectWifi(ssid: string, passphrase: string): void {
        this.store.dispatch(NetworkManagerActions.connectToWifiEndpoint.action({ ssid, passphrase }));
    }

    /**
     * Loads the available wifi endpoints on the system
     */
    loadWifiEndpointsFromSystem(): void {
        this.store.dispatch(NetworkManagerActions.loadWifiEndpoints.action());
    }

    /**
     * Set Manual Connection
     *
     * @param data ManualConnection
     */
    setManualConnection(data: ManualConnection): void {
        this.store.dispatch(NetworkManagerActions.setManualConnection({ data }));
    }

    /**
     * Invoke the manual connection settings
     *
     * @param connectionType
     */
    getManualConnectionSettings(connectionType: ManualConnectionType): void {
        this.store.dispatch(NetworkManagerActions.getManualConnectionSettings({ connectionType }));
    }

    /**
     * Invoke active network device information
     */
    getNetworkDetails(): void {
        this.store.dispatch(NetworkManagerActions.getNetworkDetails());
    }

    /**
     * Invoke active network device macaddress
     */
    getMacAddress(): void {
        this.store.dispatch(NetworkManagerActions.getMacAddress());
    }

    /**
     * Invoke active network device information
     */
    clearNetworkDetails(): void {
        this.store.dispatch(NetworkManagerActions.clearNetworkDetails());
    }

    /**
     * Invoke reset DHCP
     */
    invokeResetDHCP(): void {
        this.store.dispatch(NetworkManagerActions.invokeResetDHCP());
    }

    // =========================================================================
    // SYSTEM
    // =========================================================================

    /**
     * Get brightness
     */
    getBrightness(): void {
        this.store.dispatch(DeviceActions.getBrightness());
    }

    /**
     * Set brightness
     *
     * @param value
     */
    setBrightness(value: number): void {
        this.store.dispatch(DeviceActions.setBrightness({ value }));
    }

    /**
     * Sends system reboot event for rebooting the whole system (not only the app, it will restart the OS)
     */
    sendSystemReboot(): void {
        this.store.dispatch(DeviceActions.sendSystemReboot());
    }

    /**
     * Sends system shutdown
     */
    sendSystemShutdown(): void {
        this.store.dispatch(DeviceActions.sendSystemShutdown());
    }

    /**
     * Sends command to reload appimage
     */
    sendAppReload(): void {
        this.store.dispatch(DeviceActions.sendAppReload());
    }

    /**
     * Runs the pre-call bash script
     */
    invokePreCallScript() {
        this.store.dispatch(DeviceActions.invokePreCallScript());
    }

    /**
     * Runs the post-call bash script
     */
    invokePostCallScript() {
        this.store.dispatch(DeviceActions.invokePostCallScript());
    }

    /**
     * Resets config
     */
    resetConfig(): void {
        this.store.dispatch(DeviceActions.resetConfig());
    }

    /**
     * Get timezone data and return it in a structured way
     *
     */
    getTimezones(): TimezonesMap {
        const timeZonesMap = new Map<string, string[]>();
        timeZonesNames.reduce((zonesMap, tzName) => {
            // eslint-disable-next-line prefer-const
            let [zone, city, citySplit] = tzName.split('/');
            if (citySplit) {
                city = `${city}/${citySplit}`;
            }
            if (zonesMap.has(zone)) {
                zonesMap.set(zone, [...zonesMap.get(zone), city]);
            } else {
                zonesMap.set(zone, [city]);
            }

            return zonesMap;
        }, timeZonesMap);
        return Object.fromEntries(timeZonesMap);
    }

    /**
     * Get current timezone
     */
    getCurrentTimezone(): void {
        this.store.dispatch(DeviceActions.getCurrentTimezone());
    }

    /**
     * Set timezone
     *
     * @param value
     */
    setTimezone(value: Timezone): void {
        this.store.dispatch(DeviceActions.setTimezone({ value }));
    }

    /**
     * Set system language
     *
     * @param value
     */
    setOSKLanguage(value: string): void {
        this.store.dispatch(DeviceActions.setOSKLanguage({ value }));
    }

    /**
     * Set system OSK availability
     *
     * @param value
     */
    setOSKAvailability(value: boolean): void {
        this.store.dispatch(DeviceActions.setOSKAvailability({ value }));
    }

    /**
     * Generates telemedicine key pair and reloads config
     */
    generateTelemedicineDeviceKeys(): void {
        this.store.dispatch(DeviceActions.generateTelemedicineDeviceKeys());
    }
}
