<mat-dialog-content class="app-infection-dialog__content">
    <div>
        <h2 mat-dialog-title class="app-infection-dialog__title">
            {{
                data.infection.id
                    ? ('apps.patient.overview.editInfection' | translate)
                    : ('apps.patient.overview.addInfection' | translate)
            }}
        </h2>
        <app-infection-form
            [formGroup]="infectionForm"
            [isBedSideMode]="data.isBedSideMode"
            [isActive]="true"
        ></app-infection-form>
    </div>

    <div class="app-infection-dialog__actions">
        <button mat-button class="app-infection-dialog__cancel" color="primary" mat-dialog-close>
            {{ 'apps.patient.overview.dialogCancel' | translate }}
        </button>
        <button mat-raised-button color="primary" cdkFocusInitial (click)="submitChange()">
            {{ 'apps.patient.overview.dialogSave' | translate }}
        </button>
    </div>
</mat-dialog-content>
