/* eslint-disable @typescript-eslint/no-empty-function */
import { Direction } from '@angular/cdk/bidi';
import { CdkVirtualScrollViewport, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { VHSVirtualScrollViewport } from '../components/vhs-viewport/vhs-viewport.component';
import { TableScrollStrategyEnum } from './table-scroll-strategy.enum';

export const DEFAULT_COLUMN_WIDTH = 100;
export const DEFAULT_STICKY_COLUMN_WIDTH = 340;
export const DEFAULT_COLUMN_BUFFER = 10;
/**
 * Get table scroll s trategy type from string, prevent unknown strategy type
 *
 * @param strategyType
 */
export function getTableScrollSTrategyTypeFromString(
    strategyType: TableScrollStrategyEnum | string,
): TableScrollStrategyEnum {
    return TableScrollStrategyEnum[strategyType] || TableScrollStrategyEnum.default;
}
/** ScrollStrategyType Token */
export const TABLE_SCROLL_STRATEGY_TYPE = new InjectionToken<TableScrollStrategyEnum>(
    'Table Scroll Strategy Type String',
);
/** Table scroll page size Token */
export const TABLE_SCROLL_PAGE_SIZE = new InjectionToken<number>('Table scroll page size String');
/**
 * Abstract VirtualScrollStrategy for horizontal scrolling, with basic methods implementations
 */
export abstract class VHSTableScrollStrategy implements VirtualScrollStrategy {
    /** Scrolled index */
    protected _scrolledIndexChange = new Subject<number>();
    /** Observable of scrolled index */
    scrolledIndexChange = this._scrolledIndexChange.pipe();
    /** Reference to viewport */
    protected viewport: VHSVirtualScrollViewport;
    /** The size of the columns */
    protected columnWidth: number;
    /** The number of buffered columns rendered beyond the viewport. */
    protected columnBuffer = DEFAULT_COLUMN_BUFFER;
    /** Items in viewport (calc by columnWidth without stickyColumn) */
    public get itemsInViewport(): number {
        return this.viewport?.itemsInViewport || 0;
    }
    /** Previous visible index */
    protected previousVisibleIndex?: number;
    /** Is rendered range initialized */
    protected isRenderedRangeInitialized?: boolean;

    /**
     * Constructor
     *
     * @param  columnWidth Column width number
     * @param columnBuffer
     */
    constructor(columnWidth = DEFAULT_COLUMN_WIDTH, columnBuffer = DEFAULT_COLUMN_BUFFER) {
        this.updateColumnWidths(columnWidth, columnBuffer);
    }

    /** Calculate new range to render  */
    abstract initLoading?(dir: Direction): void;

    /**
     * Update the item size and buffer size.
     *
     * @param columnWidth The height of a row in the virtually scrolling table.
     * @param columnBuffer
     */
    updateColumnWidths(columnWidth: number, columnBuffer: number) {
        this.columnWidth = columnWidth;
        this.columnBuffer = columnBuffer;
        this.updateTotalContentSize();
        this.updateRenderedRange();
    }
    /** Calculate new range to render  */
    abstract updateRenderedRange(): void;
    /** Set total content size */
    updateTotalContentSize() {
        if (!this.viewport) {
            return;
        }
        this.viewport.setTotalContentSize(this.viewport.getDataLength() * this.columnWidth);
        this.previousVisibleIndex = undefined;
        this.isRenderedRangeInitialized = undefined;
    }
    /**
     * Attaches this scroll strategy to a viewport.
     *
     * @param viewport The viewport to attach this strategy to.
     */
    attach(viewport: CdkVirtualScrollViewport): void {
        this.viewport = viewport as unknown as VHSVirtualScrollViewport;
        this.updateTotalContentSize();
        this.updateRenderedRange();
        this.initLoading('rtl');
    }

    /**
     * Detaches this scroll strategy from the currently attached viewport.
     */
    detach(): void {
        this._scrolledIndexChange.complete();
        this.viewport = null;
    }

    /** Called when the viewport is scrolled (debounced using requestAnimationFrame). */
    onContentScrolled(): void {
        this.updateRenderedRange();
    }

    /** Called when the length of the data changes. */
    onDataLengthChanged(): void {
        this.updateTotalContentSize();
        this.updateRenderedRange();
    }

    /**
     * This method is useful for virtual scroll strategies that measure the rendered
     * content to determine its height. This scroll strategy assumes that columns have
     * a static height, so there's no need to implement this method.
     */
    onContentRendered(): void {}

    /**
     * Similar to onContentRendered, this method is useful for virtual scroll strategies that
     * need to track the offset as it changes. This scroll strategy is always able to
     * calculate the correct offset from other values, so there's no need to implement this method.
     */
    onRenderedOffsetChanged(): void {}

    /**
     * Scroll to the offset for the given index.
     *
     * INFO: this method is implented in Directive using cutom scroll service & overrirdes CdkViewport method to skip calling SrollStrategy
     *
     * @param index
     */
    scrollToIndex(index: number): void {}
}
