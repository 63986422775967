import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

/**
 * Enovacom Component
 */
@Component({
    template: `
        <div class="mona-enovacom-dialog__container">
            <div mat-dialog-content>
                <ng-container *ngIf="isLoaded$ | async; else loading">
                    <button
                        mat-icon-button
                        data-testid="btn-close-enovacom-dialog"
                        type="button"
                        class="mona-enovacom-dialog__close"
                        (click)="dialogRef.close()"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <iframe id="ui-iframe" class="mona-enovacom-dialog__iframe" [src]="iframeUrl"></iframe>
                </ng-container>

                <ng-template #loading>
                    <div class="mona-enovacom-dialog__spinner">
                        <mat-spinner color="primary" diameter="64"></mat-spinner>
                    </div>
                </ng-template>
            </div>
        </div>
    `,
    styleUrls: ['./enovacom.component.scss'],
})
export class EnovacomComponent implements OnInit, AfterViewInit {
    /**
     * Url for embeded view with all devices linked to a patient in enovacom
     */
    iframeUrl: SafeResourceUrl;

    /**
     * Hard coded flag to show spinner during iframe is loading
     */
    isLoaded$: Observable<boolean> = of(true).pipe(delay(2000));

    /**
     * constructor
     *
     * @param data
     * @param dialogRef
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { safeUrl: SafeResourceUrl },
        public dialogRef: MatDialogRef<EnovacomComponent>,
    ) {}

    /**
     * ngOnInit lifecycle hook
     */
    ngOnInit(): void {
        this.iframeUrl = this.data?.safeUrl || [];
    }

    /** */
    ngAfterViewInit(): void {
        this.isLoaded$.subscribe(() => {
            const iframe = document.getElementById('ui-iframe') as HTMLIFrameElement;

            // hide camera button as we don't use enovacom from phone
            const buttonWrappers = iframe.contentWindow.document.getElementsByClassName('input-group-btn');
            const cameraButton = Array.from(buttonWrappers).find(el =>
                el.getElementsByClassName('fa-camera'),
            ) as HTMLElement;

            if (cameraButton) {
                cameraButton.style.display = 'none';
            }

            // add mona custom keyboard to iframe search field
            const input = iframe.contentWindow.document.getElementsByClassName(
                'ui-select-search',
            )[0] as HTMLInputElement;
        });
    }
}
