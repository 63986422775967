<ng-container *ngFor="let num of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
    <mona-on-screen-keypad-button (clicked)="numClicked.emit(num)" class="mona-on-screen-keypad__button">
        {{ num }}
    </mona-on-screen-keypad-button>
</ng-container>

<mona-on-screen-keypad-button
    (clicked)="rfidButtonClicked.emit()"
    [class.mona-on-screen-keypad__button--disabled]="!isLoading && !isRfidEnabled"
    class="mona-on-screen-keypad__button"
>
    <mat-icon [inline]="true">wifi_tethering</mat-icon>
</mona-on-screen-keypad-button>

<mona-on-screen-keypad-button (clicked)="numClicked.emit(0)" class="mona-on-screen-keypad__button">
    0
</mona-on-screen-keypad-button>

<mona-on-screen-keypad-button
    [class.mona-on-screen-keypad__button--disabled]="!isLoading && isInvalid"
    [isSubmit]="true"
    class="mona-on-screen-keypad__button"
>
    <ng-container *ngIf="!isLoading; else spinner">
        <mat-icon [inline]="true">arrow_forward</mat-icon>
    </ng-container>

    <ng-template #spinner>
        <mat-spinner class="mona-on-screen-keypad__button-spinner" diameter="60"></mat-spinner>
    </ng-template>
</mona-on-screen-keypad-button>
