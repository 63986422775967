import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { UtilsModule } from '@mona/shared/utils';
import { SettingsDialogComponent } from './settings-dialog.component';

/**
 * SettingsDialogModule
 */
@NgModule({
    declarations: [SettingsDialogComponent],
    exports: [SettingsDialogComponent],
    imports: [
        //
        UtilsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
    ],
})
export class SettingsDialogModule {}
