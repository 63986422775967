/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable jsdoc/require-jsdoc */
import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { Encounter, EncounterHistoryEntry, HistoryEntryType } from '@mona/models';
import { AsyncAction, FailedAction, SucceededAction } from '@mona/store';

/**
 * Set Selected Date
 *
 * @description used to filter ALL VIEWS which use DAY CALENDAR
 */
export const setEncounterSelectedDate = createAction('ENCOUNTER:SET_SELECTED_DATE', props<{ selectedDate: Date }>());

/**
 * Set history type
 */
export const navigateInsideSelectedEncounter = createAction(
    'ENCOUNTER:NAVIGATE_INSIDE_SELECTED_ENCOUNTER',
    props<{
        slug: string;
        query?: Params | null;
    }>(),
);
/**
 * Set history type
 */
export const setHistoryFilterAction = createAction(
    'ENCOUNTER:SET_HISTORY_FILTER',
    props<{
        historyType: HistoryEntryType;
    }>(),
);

/**
 * Set encounter review mode true
 */
export const setEncounterReviewMode = createAction('ENCOUNTERS:SET_ENCOUNTER_REVIEW_MODE');

/**
 * Set encounter review mode false
 */
export const unsetEncounterReviewMode = createAction('ENCOUNTERS:UNSET_ENCOUNTER_REVIEW_MODE');

/**
 * Load encounter history action
 */
export const loadEncounterHistoryAction: AsyncAction<
    {
        id: EntityId<Encounter>;
    },
    EncounterHistoryEntry[]
> = {
    action: createAction(
        'ENCOUNTER:LOAD_ENCOUNTER_HISTORY',
        props<{
            id: EntityId<Encounter>;
        }>(),
    ),

    succeededAction: createAction(
        'ENCOUNTER:LOAD_ENCOUNTER_HISTORY_SUCCEEDED',
        props<SucceededAction<EncounterHistoryEntry[]>>(),
    ),

    failedAction: createAction('ENCOUNTER:LOAD_ENCOUNTER_HISTORY_FAILED', props<FailedAction>()),

    clearAction: createAction('ENCOUNTER:LOAD_ENCOUNTER_HISTORY_CLEAR'),
};

export const EncounterAction = {
    navigateInsideSelectedEncounter,
    setEncounterSelectedDate,
    setHistoryFilterAction,
    setEncounterReviewMode,
    unsetEncounterReviewMode,
    loadEncounterHistoryAction,
};
