// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.3
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating Question.
 * @see #/components/schemas/Question - reference to schema in open-api-spec.yaml
 * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
 * @version 1.6.3
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Question {
    /**
     * The identifier of Question-ID.
     *
     */
    id: string;
    /**
     * The title of the Workflow-Item-Answer.
     *
     */
    title?: string | null;
    /**
     * The description of the Workflow-Item-Answer.
     *
     */
    description?: string | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Question
 */
export namespace Question {
    /**
     * Question Frontend Model with camelCase properties
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The identifier of Question-ID.
         * @type string (`dataFormat` is missing)
         */
        id: string;
        /**
         * The title of the Workflow-Item-Answer.
         * @type string (`dataFormat` is missing)
         */
        title?: string | null;
        /**
         * The description of the Workflow-Item-Answer.
         * @type string (`dataFormat` is missing)
         */
        description?: string | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Question DTO
     * Transforms Question model from/to API object
     * @since 2024-10-08T15:50:17.992821+03:00[Europe/Kiev]
     * @version 1.6.3
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Question model */
        static toModel(obj: Question): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Question model */
        static toApi(obj: Model): Question {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Question;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
