// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.be1.stage.monatest.icu/auto-open-api-spec.yaml 1.3.15
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2023-10-30T17:46:07.899+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serialize Task List Filter from database
 * @see #/components/schemas/TaskListFilter - reference to schema in open-api-spec.yaml
 * @since 2023-10-30T17:46:07.899+02:00[Europe/Kiev]
 * @version 1.3.15
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface TaskListFilter {
    /**
     * The task list filter display name.
     *
     */
    display_name: string;
    /**
     * The task list filter interval.
     *
     */
    interval: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for TaskListFilter
 */
export namespace TaskListFilter {
    /**
     * TaskListFilter Frontend Model with camelCase properties
     * @since 2023-10-30T17:46:07.899+02:00[Europe/Kiev]
     * @version 1.3.15
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The task list filter display name.
         * @type string (`dataFormat` is missing)
         */
        displayName: string;
        /**
         * The task list filter interval.
         * @type string (`dataFormat` is missing)
         */
        interval: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * TaskListFilter DTO
     * Transforms TaskListFilter model from/to API object
     * @since 2023-10-30T17:46:07.899+02:00[Europe/Kiev]
     * @version 1.3.15
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend TaskListFilter model */
        static toModel(obj: TaskListFilter): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend TaskListFilter model */
        static toApi(obj: Model): TaskListFilter {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as TaskListFilter;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
