import { TerminalConfig } from '@mona/models';
import { Schema } from './config.types';

export const SCHEMA: Schema<TerminalConfig> = {
    isBedSide: {
        type: 'boolean',
        default: true,
    },
    autoLock: {
        type: 'number',
        enum: [60000, 180000, 300000, 600000],
        default: 180000,
    },
    license: {
        type: 'object',
        properties: {
            pdms: { type: 'boolean' },
            voice: { type: 'boolean' },
            telemedicine: { type: 'boolean' },
        },
        additionalProperties: false,
        default: {
            pdms: false,
            telemedicine: false,
            voice: false,
        },
    },
    features: {
        type: 'object',
        properties: {
            pdms: { type: 'boolean' },
            voice: { type: 'boolean' },
            telemedicine: { type: 'boolean' },
        },
        additionalProperties: false,
        default: {
            pdms: false,
            telemedicine: false,
            voice: false,
        },
    },
    uiLanguage: {
        type: 'string',
        default: 'en-GB',
    },
    keyboardLocale: {
        type: 'string',
        default: 'en-GB',
    },
    hospitalLocale: {
        type: 'string',
    },
    isHospitalLocaleApplied: {
        type: 'boolean',
    },
    api: {
        type: 'object',
        properties: {
            baseUrl: { type: 'string' },
            telemedicineUrl: { type: 'string' },
            telemedicineWsUrl: { type: 'string' },
            coreWsUrl: { type: 'string' },
        },
        additionalProperties: true,
        default: {
            baseUrl: 'https://mona.icuconnect.eu',
            coreWsUrl: 'wss://mona.icuconnect.eu',
        },
    },
    enovacom: {
        type: 'object',
        properties: {
            baseUrl: { type: 'string' },
            credentials: { type: 'string' },
            loginHeader: { type: 'string' },
            loginHeaderValue: { type: 'string' },
            tokenHeader: { type: 'string' },
            authPath: { type: 'string' },
        },
        additionalProperties: false,
        default: {
            baseUrl: 'http://localhost:8181',
            loginHeader: 'X-EAS-LOGIN',
            loginHeaderValue: 'nurse',
            tokenHeader: 'X-EAS-TOKEN',
            authPath: '/ws-token/api/auth',
        },
    },
    telemedicineHospitalCode: {
        type: 'string',
    },
    bedId: {
        type: ['string', 'null'],
        default: null,
    },
    defaultInterval: {
        type: 'string',
        enum: ['MINUTES_5', 'MINUTES_30', 'HOUR_1', 'HOUR_6', 'DAY_1'],
    },
    chartSelectionMax: {
        type: ['number', 'null'],
    },
    lastSetupWizardStep: {
        type: ['string', 'null'],
    },
    skipNetworkOnLinux: {
        type: 'boolean',
    },
    isRfidEnabled: {
        type: 'boolean',
    },
    emulateRFIDReader: {
        type: 'boolean',
    },
    tableScrollPageSize: {
        type: 'number',
    },
    webBrowserPositionParams: {
        type: 'object',
        additionalProperties: false,
        properties: {
            x: { type: 'number' },
            y: { type: 'number' },
            width: { type: 'number' },
            height: { type: 'number' },
        },
    },
    webBrowserFullScreenParams: {
        type: 'object',
        additionalProperties: false,
        properties: {
            x: { type: 'number' },
            y: { type: 'number' },
            width: { type: 'number' },
            height: { type: 'number' },
        },
    },
};
