import { createAction, props } from '@ngrx/store';
import { Patient } from '@mona/models';

/**
 * Search patient action
 */
export const searchPatient = createAction(
    'PATIENTS:SEARCH_PATIENT',
    props<{ searchQuery: string; isAdmissionSearch: boolean }>(),
);

/**
 * Search patient success action
 */
export const searchPatientSuccess = createAction('PATIENTS:SEARCH_PATIENT_SUCCEEDED', props<{ patients: Patient[] }>());

/**
 * Search patient failure action
 */
export const searchPatientFailure = createAction('PATIENTS:SEARCH_PATIENT_FAILED', props<{ error: unknown }>());

/**
 * Search patient action
 */
export const clearSearchPatient = createAction('PATIENTS:SEARCH_PATIENT_CLEAR');
