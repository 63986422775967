import { Component, OnInit, ViewEncapsulation } from '@angular/core';

/**
 * (Two) Panel Layout Component
 *
 * - based on css grid
 * - head, nav blocks will collapse if empty
 * - main block will fill the remaining space
 *
 *
 * @example ```html
 * <ui-drawer-layout>
 *   <ui-drawer-content>
 *     <div ui-drawer-content-head>Head</div>
 *     <div ui-drawer-content-nav>Nav</div>
 *     <div ui-drawer-content-main>Main</div>
 *   </ui-drawer-content>
 * </ui-drawer-layout>
 * ```
 */
@Component({
    selector: 'ui-drawer-content',
    host: {
        class: 'ui-drawer-content',
    },
    template: `
        <section class="ui-drawer-content--head">
            <ng-content select="[ui-drawer-content-head]"></ng-content>
        </section>
        <section class="ui-drawer-content--nav">
            <ng-content select="[ui-drawer-content-nav]"></ng-content>
        </section>
        <section class="ui-drawer-content--main">
            <ng-content select="[ui-drawer-content-main]"></ng-content>
        </section>
    `,
    styleUrls: ['./drawer-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class DrawerContentComponent {}
